/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { XaltInputBase, XaltInputAdornment, XaltIconButton, XaltClear, XaltFormattedInput } from 'xalt-react-atoms';
// [MVP-TODO] -> Show masked placeholders for date and timepickers.
// Example here - https://codesandbox.io/s/material-demo-forked-015vcw

/**
 * A platform component for building a text label
 */
export default class RWDateField extends Component {
    static propTypes = {
        style: PropTypes.object,
        value: PropTypes.string,
        isReadMode: PropTypes.bool,
        isFocused: PropTypes.bool,
        onValueChanged: PropTypes.func,
        xStyle: PropTypes.object,
        propertyRef: PropTypes.object,
        placeholder: PropTypes.string,
    };

    static defaultProps = {
        style: {},
    };

    constructor(props) {
        super(props);
        this.textRef = props.propertyRef || React.createRef();
    }

    render() {
        const {
            style,
            value,
            isReadMode,
            isFocused,
            onValueChanged,
            xStyle,
            placeholder } = this.props;
        const { input, clearIcon = {} } = xStyle || { input: {} };
        return (
            <XaltInputBase
                style={ { textOverflow: 'ellipsis', ...style, ...input } }
                endAdornment={ this.renderEndAdornment(isReadMode, clearIcon, value) }
                disabled={ isReadMode }
                placeholder={ placeholder }
                onValueChanged={ onValueChanged }
                value={ value }
                autoFocus={ isFocused }
                inputRef={ this.textRef }
                inputComponent={ XaltFormattedInput }
                inputProps={ {
                    format: placeholder.replace(/[a-zA-Z]/gi, '#'),
                    mask: placeholder.replace(/[^a-zA-Z]/gi, '').split(''),
                } } />
        );
    }

    renderEndAdornment(isReadOnly, iconStyle, value) {
        const { fontSize } = iconStyle;
        return (
            <XaltInputAdornment style={ { visibility: (isReadOnly || !value) ? 'hidden' : 'visible' } }>
                <XaltIconButton
                    onClick={ this.handleOnClearClick }
                    style={ iconStyle }>
                    <XaltClear
                        style={ { fontSize } } />
                </XaltIconButton>
            </XaltInputAdornment>
        );
    }

    handleOnClearClick = () => {
        const { onValueChanged } = this.props;
        this.textRef.current.focus();
        onValueChanged(null);
    }
}