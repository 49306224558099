import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { TypeNames } from 'cv-dialog-sdk';
import { constants } from '../constants';
import SaltComponent from './SaltComponent';
import SaltContext from './SaltContext';
import engineConstants from './engineConstants';
import engine from './engine';
import Panel from './Panel';
import FormLayout from './form/FormLayout';
import dialogComponentFactory from '../provider/dialogComponentFactory';
import uiHelper from '../utilities/uiHelper';

@observer
export default class Layout extends SaltComponent {
    static propTypes = {
        viewId: PropTypes.string,
        noPanel: PropTypes.bool,
        type: PropTypes.string,
        // specified as 'children' in Salt
        saltChildren: PropTypes.array,
    };

    static typeName = engineConstants.component.name.salt;

    render() {
        const { saltChildren, type } = this.props;
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore } = this.context;
        const formDialogStore = saltStore.getDialogStoreForViewId(viewId);
        const props = {
            formDialogStore,
            elementCallback: this.getPanelElement,
            type,
        };
        // Custom Form - if children were provided, this is our view list
        if (saltChildren && saltChildren.length) {
            const components = saltChildren.map(engine.render);
            return <Fragment>{ components }</Fragment>;
        }
        // otherwise our view list is the default
        // use the built in Layouts
        props.childDialogStores = formDialogStore.childDialogStores;
        return <FormLayout { ...props } />;
    }

    getPanelElement = (childDialogStore, formViewMode) => {
        const { noPanel, hideDefaultPanelHeader } = this.props;
        const { saltStore } = this.context;
        const { uiStore } = saltStore;
        const { isWriteMode, view } = childDialogStore.dialog;
        // @TODO alias, viewAlias - we may also want to allow salt to reference user defined aliases
        const { type } = view;
        const dialogViewId = uiHelper.getViewId(view);
        const isDetailsView = type === TypeNames.DetailsTypeName;
        const componentToRender = this.buildDialogComponent(childDialogStore);

        // If directed, don't wrap content with a panel
        if (noPanel) return componentToRender;

        // If in case their is any Old Salt associated with Form Dialog where 'hideDefaultPanelHeader' property is not included, then we are including it and setting it as 'True'.
        // And note, this logic should/can be removed once all the SALT's are updated.
        let hideDefaultPanelHeaderBool = hideDefaultPanelHeader;
        if (hideDefaultPanelHeader === undefined) {
            hideDefaultPanelHeaderBool = true;
        }

        // only show the panel menu if there are multiple panels
        const showMenu = formViewMode === FormLayout.FORM_VIEW_MODE.MULTI_VIEW;
        const showTitle = formViewMode === FormLayout.FORM_VIEW_MODE.MULTI_VIEW || formViewMode === FormLayout.FORM_VIEW_MODE.EXPANDED_VIEW;
        const showTools = true;
        const viewSectionId = uiHelper.getViewId(view, true);

        // If there's a saved dimension from resizing the panel
        const dimensions = {
            width: uiStore.getValueForUIObject(viewSectionId, constants.ui.DIALOG_WIDTH),
            height: uiStore.getValueForUIObject(viewSectionId, constants.ui.DIALOG_HEIGHT),
        };

        const props = {
            dimensions,
            formViewMode,
            isDetailsView,
            isWriteMode,
            showTitle,
            showMenu,
            showTools,
            viewId: dialogViewId,
            hideDefaultPanelHeader: hideDefaultPanelHeaderBool,
            // @TODO alias, viewAlias - we may want to allow salt to reference user defined aliases
            // viewId: dialogAlias || dialogViewId,
        };
        return <Panel { ...props }>{ componentToRender }</Panel>;
    }

    buildDialogComponent = (childDialogStore) => {
        const { saltStore, onTransition, onError } = this.context;
        const { uiStore } = saltStore;
        const { dialog } = childDialogStore;
        const { saltDocument } = childDialogStore;
        const PageComponent = dialogComponentFactory.getDialogPageComponent(dialog);
        const params = { dialogStore: childDialogStore, uiStore, onTransition, onError, saltDocument };
        return React.createElement(PageComponent, params);
    }
}

Layout.contextType = SaltContext;
