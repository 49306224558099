import React from 'react';
import * as PropTypes from 'prop-types';
import MUICheckbox from '@material-ui/core/Checkbox';
import makeStyles from '@material-ui/core/styles/makeStyles';
import getStyles from './Checkbox.styles';

const useStyles = (muiStyles) => makeStyles({
    root: muiStyles.check,
});

/**
 * A control that allows a user to select one or more items from a set.
 * @see https://material-ui.com/components/checkboxes/
 */
const Checkbox = (props) => {
    const {
        checked,
        contextStyles,
        disabled,
        onChange,
        onClick,
        testID,
        checkedIcon,
        icon,
    } = props;

    // Override core styles with context styles, separating MUI styles
    const {
        container: containerStyles,
        ...muiStyles
    } = getStyles(contextStyles);

    // Create dynamic class names and injected DOM styles for MUI component
    // * makeStyles returns a function
    // const styles = makeStyles(muiStyles)();
    const styles = useStyles(muiStyles);

    return (
        <div
            className="c-checkbox"
            style={ containerStyles }>
            <MUICheckbox
                checked={ checked }
                classes={ styles() }
                checkedIcon={ checkedIcon }
                icon={ icon }
                className="c-checkbox__container"
                data-test-id={ `${testID}__checkbox__container` }
                disabled={ disabled }
                onChange={ onChange && ((event) => {
                    onChange(event, props);
                }) }
                onClick={ onClick && ((event) => {
                    onClick(event);
                }) } />
        </div>
    );
};

Checkbox.propTypes = {
    /** The checked value */
    checked: PropTypes.bool,

    /** Extended styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the container surrounding the control */
        container: PropTypes.object,

        /** Styles for the root button element */
        check: PropTypes.object,

        /** Styles for the root button element when checked */
        checked: PropTypes.object,

        /** Styles for the root button element when disabled */
        disabled: PropTypes.object,
    }),

    /** Disables the control */
    disabled: PropTypes.bool,

    checkedIcon: PropTypes.object,
    icon: PropTypes.object,

    /**
     * Called when the checkbox was changed
     * @param {Object} event - The native change event
     * @param {Object} props - The component instance props
     */
    onChange: PropTypes.func,
    onClick: PropTypes.func,

    /** Id used for testing */
    testID: PropTypes.string,
};

Checkbox.defaultProps = {
    contextStyles: {},
    testID: 'Checkbox',
};

export default Checkbox;
