// Core component styles
const coreStyles = {
    adornmentButton: {
        // Reduce default button padding to keep input same size as non-adorned inputs
        padding: '0px',
        marginRight: '10px',
    },

    adornmentIcon: {
    },
};


/**
 * Helper method to get styles for this component
 * @param {Object} contextStyles - Styles for this component
 */
const getStyles = (contextStyles = {}) => {
    const {
        adornmentButton,
        adornmentIcon,
        ...restContextStyles
    } = contextStyles;

    return {
        adornmentButton: {
            ...coreStyles.adornmentButton,
            ...adornmentButton,
        },

        adornmentIcon: {
            ...coreStyles.adornmentIcon,
            ...adornmentIcon,
        },

        ...restContextStyles,
    };
};

export default getStyles;
