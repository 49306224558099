import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';

import getStyles from './CvModal.styles';

export default class CvModal extends PureComponent {
    static defaultProps = {
        contextStyles: {},
        isTransparentOverlay: false,
        onPressOverlay: () => {},
    };

    static propTypes = {
        /** Styles for this component */
        contextStyles: PropTypes.shape({
            /** Styles for the main wrapping modal */
            container: PropTypes.object,
        }),

        titleComponent: PropTypes.element,

        onPressOverlay: PropTypes.func,

        /** Modal contents */
        children: PropTypes.element,

        /** Changes the overlay darkened fill to transparent */
        isTransparentOverlay: PropTypes.bool,

        // show: PropTypes.bool,

        /** Id used for testing */
        testID: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.handleOnOverlayClick = this.handleOnOverlayClick.bind(this);
    }

    render() {
        const {
            contextStyles,
            isTransparentOverlay,
            testID,
            titleComponent,
            children,
        } = this.props;

        // Override core styles with context styles, separating MUI styles
        const styles = getStyles(contextStyles, isTransparentOverlay);

        return (
            <div
                id={ `${testID}cv-modal__form-overlay` }
                style={ styles.overlay }>
                <div
                    id="cv-modal-row__container"
                    style={ styles.modalRowContainer }>
                    <div
                        id="cv-modal-column__container"
                        style={ styles.modalColumnContainer }>
                        { /* <div
                            id="cv-modal-row__title-container"
                            style={ styles.titleRowContainer }> */ }
                        { titleComponent }
                        { /* </div>
                        <div
                            id="cv-modal-row__children-container"
                            style={ styles.childrenRowContainer }>
                            <div
                                id="cv-modal__column-container"
                                style={ styles.modalContent }> */ }
                        { children }
                        { /* </div>
                        </div> */ }
                    </div>
                </div>
            </div>
        );
    }

    handleOnOverlayClick() {
        const { onPressOverlay } = this.props;
        if ( onPressOverlay) onPressOverlay();
    }
}
