const coreStyles = {
    container: {
    },

    header: {
    },

    content: {
        overflow: 'auto',
    },

    footer: {
    },

    ribbon: {
        backgroundColor: '#000000',
        backgroundImage: 'linear-gradient(90deg, #3BB0C9 0.06%, #028CCD 12.5%, #028CCD 71.53%, #A5CF67 100%)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 5px',
        height: '15px',
    },
};

const getStyles = (contextStyles = {}) => {
    return {
        content: {
            ...coreStyles.content,
            ...contextStyles.content,
        },

        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        footer: {
            ...coreStyles.footer,
            ...contextStyles.footer,
        },

        header: {
            ...coreStyles.header,
            ...contextStyles.header,
        },

        ribbon: {
            ...coreStyles.ribbon,
            ...contextStyles.ribbon,
        },
    };
};

export default getStyles;
