export class DialogException {
    constructor(iconName, message, name, stackTrace, title, cause, userMessages) {
        this.iconName = iconName;
        this.message = message;
        this.name = name;
        this.stackTrace = stackTrace;
        this.title = title;
        this.cause = cause;
        this.userMessages = userMessages;
    }
}
