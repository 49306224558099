var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { JsonClientResponse } from "../client/JsonClientResponse";
import { storage as clientStorage } from "../storage";
import { Base64 } from "../util/Base64";
import { FetchClient } from "../ws/FetchClient";
import { ContentRedirectionVisitor } from "./ContentRedirectionVisitor";
import { DialogRedirectionVisitor } from "./DialogRedirectionVisitor";
import { DialogVisitor } from "./DialogVisitor";
import { LargePropertyVisitor } from "./LargePropertyVisitor";
import { ReadLargePropertyParametersVisitor } from "./ReadLargePropertyParametersVisitor";
import { RecordSetVisitor } from "./RecordSetVisitor";
import { RecordVisitor } from "./RecordVisitor";
import { RedirectionVisitor } from "./RedirectionVisitor";
// @FIX_THIS
// remove this:
const storage = clientStorage;
// uncomment this
// const storage = clientStorage.getSecureInstance();
// Set the secret key like this:
// storage.secretKey = 'secret_key';
/**
 *
 */
export class DialogProxyTools {
    static captureDialog(userId, baseUrl, tenantId, sessionId, dialogId, referringDialogId) {
        return __awaiter(this, void 0, void 0, function* () {
            const thisMethod = 'DialogProxyTools::captureDialog';
            // GET DIALOG //
            const resourcePath = `tenants/${tenantId}/sessions/${sessionId}/dialogs/${dialogId}`;
            // Log.trace(`${thisMethod} -- capturing online dialog: ${resourcePath}`);
            const dialogJcr = yield DialogProxyTools.commonFetchClient().getJson(baseUrl, resourcePath);
            if (dialogJcr.statusCode !== 200) {
                throw new Error(`Unexpected result when getting dialog ${dialogId}: ${dialogJcr.statusCode}`);
            }
            // Log.trace(`${thisMethod} -- dialog: ${JSON.stringify(dialogJcr.value)}`);
            // WRITE DIALOG //
            const dialogVisitor = new DialogVisitor(dialogJcr.value);
            const beforeDialog = dialogVisitor.copyAsJsonObject();
            dialogVisitor.deriveDialogIdsFromDialogNameAndRecordId();
            // Not all referring objects are dialogs, so we must check that a dialog id is present
            if (referringDialogId) {
                dialogVisitor.visitAndSetReferringDialogId(referringDialogId);
            }
            // Log.trace(`${thisMethod} -- writing online dialog to offline dialog id: ${dialogVisitor.visitId()}`);
            // Log.trace(`${thisMethod} -- writing online dialog to offline storage: ${dialogVisitor.copyAsJsonString()}`);
            yield this.writeDialog(userId, tenantId, dialogVisitor);
            return { beforeDialog, afterDialog: dialogVisitor.enclosedJsonObject() };
        });
    }
    static captureMenuActionRedirectionAndDialog(userId, baseUrl, tenantId, sessionId, dialogId, offlineDialogId, actionId, targetId) {
        return __awaiter(this, void 0, void 0, function* () {
            const thisMethod = 'DialogProxyTools::captureMenuActionRedirectionAndDialog';
            const resourcePath = `tenants/${tenantId}/sessions/${sessionId}/dialogs/${dialogId}/actions/${actionId}`;
            // Log.trace(`${thisMethod} -- capturing menu redirection and dialog: ${resourcePath}`);
            // GET REDIRECTION //
            const actionParameters = {
                targets: [targetId],
                type: "hxgn.api.dialog.ActionParameters"
            };
            // Log.trace(`${thisMethod} -- capturing online dialog redirection: ${resourcePath}`);
            const dialogRedirectionJcr = yield DialogProxyTools.commonFetchClient().postJson(baseUrl, resourcePath, actionParameters);
            if (dialogRedirectionJcr.statusCode !== 303) {
                let actionIdAtTargetIdError = actionId;
                if (targetId) {
                    const targetIdEncoded = Base64.encodeUrlSafeString(targetId);
                    actionIdAtTargetIdError = actionIdAtTargetIdError + '@' + targetIdEncoded;
                }
                yield this.writeErrorDialogRedirection(userId, tenantId, offlineDialogId, actionIdAtTargetIdError, dialogRedirectionJcr);
                return null;
            }
            // Log.trace(`${thisMethod} -- menu action redirection: ${JSON.stringify(dialogRedirectionJcr.value)}`);
            // WRITE REDIRECTION //
            const dialogRedirectionVisitor = new DialogRedirectionVisitor(dialogRedirectionJcr.value);
            const beforeDialogRedirection = dialogRedirectionVisitor.copyAsJsonObject();
            dialogRedirectionVisitor.deriveDialogIdsFromDialogNameAndRecordId();
            dialogRedirectionVisitor.visitAndSetReferringDialogId(offlineDialogId);
            let actionIdAtTargetId = actionId;
            if (targetId) {
                const targetIdEncoded = Base64.encodeUrlSafeString(targetId);
                actionIdAtTargetId = actionIdAtTargetId + '@' + targetIdEncoded;
            }
            // Log.trace(`${thisMethod} -- writing online dialog redirection with dialog id: ${dialogRedirectionVisitor.visitDialogId()}`);
            // Log.trace(`${thisMethod} -- writing online dialog redirection with referring dialog id: ${dialogRedirectionVisitor.visitReferringDialogId()}`);
            // Log.trace(`${thisMethod} -- writing online dialog redirection with record id: ${dialogRedirectionVisitor.visitRecordId()}`);
            // Log.trace(`${thisMethod} -- writing online dialog redirection to offline redirection id: ${dialogRedirectionVisitor.visitId()}`);
            // Log.trace(`${thisMethod} -- writing online dialog redirection to offline storage: ${dialogRedirectionVisitor.copyAsJsonString()}`);
            const referringDialogId = dialogRedirectionVisitor.visitReferringDialogId();
            yield this.writeDialogRedirection(userId, tenantId, referringDialogId, actionIdAtTargetId, dialogRedirectionVisitor);
            // CAPTURE DIALOG //
            const beforeDialogId = beforeDialogRedirection['dialogId'];
            const beforeAndAfterDialog = yield this.captureDialog(userId, baseUrl, tenantId, sessionId, beforeDialogId, referringDialogId);
            return { beforeDialogRedirection, afterDialogRedirection: dialogRedirectionVisitor.enclosedJsonObject(),
                beforeDialog: beforeAndAfterDialog['beforeDialog'], afterDialog: beforeAndAfterDialog['afterDialog'] };
        });
    }
    static captureRecord(userId, baseUrl, tenantId, sessionId, beforeAndAfterValues, listDialogName) {
        return __awaiter(this, void 0, void 0, function* () {
            const thisMethod = 'DialogProxyTools::captureRecord';
            // ONLINE
            const onlineRootDialogVisitor = new DialogVisitor(beforeAndAfterValues.beforeDialog);
            const onlineEditorDialogVisitor = onlineRootDialogVisitor.visitChildAtName(listDialogName);
            const onlineEditorDialogId = onlineEditorDialogVisitor.visitId();
            const onlineEditorRecordPath = `tenants/${tenantId}/sessions/${sessionId}/dialogs/${onlineEditorDialogId}/record`;
            // Log.trace(`${thisMethod} -- capturing online record: ${onlineEditorRecordPath}`);
            const onlineEditorRecordJcr = yield DialogProxyTools.commonFetchClient().getJson(baseUrl, onlineEditorRecordPath);
            if (onlineEditorRecordJcr.statusCode !== 200) {
                throw new Error(`Unexpected result when getting record: ${onlineEditorRecordJcr.statusCode}`);
            }
            const onlineEditorRecordVisitor = new RecordVisitor(onlineEditorRecordJcr.value);
            // OFFLINE
            const offlineRootDialogVisitor = new DialogVisitor(beforeAndAfterValues.afterDialog);
            const offlineEditorDialogVisitor = offlineRootDialogVisitor.visitChildAtName(listDialogName);
            const offlineEditorDialogId = offlineEditorDialogVisitor.visitId();
            // WRITE TO STORAGE
            // Log.trace(`${thisMethod} -- writing online record to offline editor dialog id: ${offlineEditorDialogId}`);
            // Log.trace(`${thisMethod} -- writing online record to offline storage: ${onlineEditorRecordVisitor.copyAsJsonString()}`);
            yield DialogProxyTools.writeRecord(userId, tenantId, offlineEditorDialogId, onlineEditorRecordVisitor);
            return onlineEditorRecordVisitor;
        });
    }
    static genericCaptureDialog(userId, baseUrl, tenantId, sessionId, dialogId, referringDialogId, newCopy = '') {
        return __awaiter(this, void 0, void 0, function* () {
            const thisMethod = 'DialogProxyTools::genericCaptureDialog';
            if (dialogId) {
                // GET DIALOG //
                const resourcePath = `tenants/${tenantId}/sessions/${sessionId}/dialogs/${dialogId}`;
                // Log.trace(`${thisMethod} -- capturing online dialog: ${resourcePath}`);
                const dialogJcr = yield DialogProxyTools.commonFetchClient().getJson(baseUrl, resourcePath);
                if (dialogJcr.statusCode !== 200) {
                    yield this.writeErrorDialog(DialogProxyTools.processStorageUserId(newCopy, userId), tenantId, dialogId, dialogJcr);
                    return { beforeDialog: null, afterDialog: null, dialogError: true };
                }
                // Log.trace(`${thisMethod} -- dialog: ${JSON.stringify(dialogJcr.value)}`);
                // WRITE DIALOG //
                const dialogVisitor = new DialogVisitor(dialogJcr.value);
                const beforeDialog = dialogVisitor.copyAsJsonObject();
                // Not all referring objects are dialogs, so we must check that a dialog id is present
                if (referringDialogId) {
                    dialogVisitor.visitAndSetReferringDialogId(referringDialogId);
                }
                // Log.trace(`${thisMethod} -- writing online dialog to offline dialog id: ${dialogVisitor.visitId()}`);
                // Log.trace(`${thisMethod} -- writing online dialog to offline storage: ${dialogVisitor.copyAsJsonString()}`);
                yield this.writeDialog(DialogProxyTools.processStorageUserId(newCopy, userId), tenantId, dialogVisitor);
                return { beforeDialog, afterDialog: dialogVisitor.enclosedJsonObject(), dialogError: false };
            }
        });
    }
    static genericCaptureRecord(userId, baseUrl, tenantId, sessionId, id, referringDialogId, referringRootDialogId, newCopy = '') {
        return __awaiter(this, void 0, void 0, function* () {
            const thisMethod = 'DialogProxyTools::genericCaptureRecord';
            // ONLINE
            const onlineEditorRecordPath = `tenants/${tenantId}/sessions/${sessionId}/dialogs/${id}/record`;
            // Log.trace(`${thisMethod} -- capturing online record: ${onlineEditorRecordPath}`);
            const onlineEditorRecordJcr = yield DialogProxyTools.commonFetchClient().getJson(baseUrl, onlineEditorRecordPath);
            if (onlineEditorRecordJcr.statusCode !== 200) {
                throw new Error(`Unexpected result when getting record. \n Error: ${JSON.stringify(onlineEditorRecordJcr)} \n Code: ${onlineEditorRecordJcr.statusCode}`);
            }
            const onlineEditorRecordVisitor = new RecordVisitor(onlineEditorRecordJcr.value);
            // WRITE TO STORAGE
            if (referringDialogId) {
                onlineEditorRecordVisitor.visitAndSetParentDialogId(referringDialogId);
                onlineEditorRecordVisitor.visitAndSetRootParentDialogId(referringRootDialogId);
            }
            // Log.trace(`${thisMethod} -- writing online record to offline editor dialog id: ${id}`);
            // Log.trace(`${thisMethod} -- writing online record to offline storage: ${onlineEditorRecordVisitor.copyAsJsonString()}`);
            if (onlineEditorRecordVisitor.visitRecordId()) {
                yield DialogProxyTools.genericWriteRecordWithRecordId(DialogProxyTools.processStorageUserId(newCopy, userId), tenantId, id, onlineEditorRecordVisitor.visitRecordId(), onlineEditorRecordVisitor);
            }
            else {
                yield DialogProxyTools.writeRecord(DialogProxyTools.processStorageUserId(newCopy, userId), tenantId, id, onlineEditorRecordVisitor);
            }
            return onlineEditorRecordVisitor;
        });
    }
    static genericCaptureMenuActionRedirectionAndDialog(userId, baseUrl, tenantId, sessionId, dialogId, offlineDialogId, actionId, targetId, newCopy = '') {
        return __awaiter(this, void 0, void 0, function* () {
            const thisMethod = 'DialogProxyTools::genericCaptureMenuActionRedirectionAndDialog';
            const resourcePath = `tenants/${tenantId}/sessions/${sessionId}/dialogs/${dialogId}/actions/${actionId}`;
            // Log.trace(`${thisMethod} -- capturing menu redirection and dialog: ${resourcePath}`);
            // GET REDIRECTION //
            const actionParameters = {
                targets: [targetId],
                type: "hxgn.api.dialog.ActionParameters"
            };
            // Log.trace(`${thisMethod} -- capturing online dialog redirection: ${resourcePath}`);
            const dialogRedirectionJcr = yield DialogProxyTools.commonFetchClient().postJson(baseUrl, resourcePath, actionParameters);
            if (dialogRedirectionJcr.statusCode !== 303) {
                let actionIdAtTargetId = actionId;
                if (targetId) {
                    const targetIdEncoded = Base64.encodeUrlSafeString(targetId);
                    actionIdAtTargetId = actionIdAtTargetId + '@' + targetIdEncoded;
                }
                yield this.writeErrorDialogRedirection(DialogProxyTools.processStorageUserId(newCopy, userId), tenantId, offlineDialogId, actionIdAtTargetId, dialogRedirectionJcr);
                return null;
            }
            // Log.trace(`${thisMethod} -- menu action redirection: ${JSON.stringify(dialogRedirectionJcr.value)}`);
            if (dialogRedirectionJcr.value['type'] === 'hxgn.api.dialog.ContentRedirection') {
                const nextDocumentIdEncoded = Base64.encodeUrlSafeString(targetId);
                // Log.trace(`${thisMethod} -- document content redirection: ${JSON.stringify(dialogRedirectionJcr.value)}`);
                // CLARIFICATION: We can navigate to the same document from multiple locations, therefore documents are shared
                // among entities by reference. Specifically a work package and tag can share the same document. We capitalize
                // on this fact by rewriting the content redirection identifier as derivative of the document id. The effect
                // allows us to determine if we have already downloaded the content, and if we have, to reference the same
                // content from multiple redirections.
                const contentRedirectionVisitor = new ContentRedirectionVisitor(dialogRedirectionJcr.value);
                const onlineContentId = contentRedirectionVisitor.visitId();
                const offlineContentId = `content_redirection_${nextDocumentIdEncoded}`;
                contentRedirectionVisitor.visitAndSetId(offlineContentId);
                const actionIdAtRecordId = `${actionId}@${nextDocumentIdEncoded}`;
                yield DialogProxyTools.writeContentRedirection(DialogProxyTools.processStorageUserId(newCopy, userId), tenantId, dialogId, actionIdAtRecordId, contentRedirectionVisitor);
                const largePropertyExists = yield DialogProxyTools.readSessionContentAsVisitor(DialogProxyTools.processStorageUserId(newCopy, userId), tenantId, offlineContentId, 0);
                if (largePropertyExists) {
                    // If the document has been previously captured from a shared reference, then we are done
                    return null;
                }
                // GET AND WRITE CONTENT TO LOCAL STORAGE //
                let nextSequence = 0;
                while (true) {
                    const contentPath = `tenants/${tenantId}/sessions/${sessionId}/content/${onlineContentId}`;
                    const readLargePropertyParametersJson = {
                        maxBytes: 131072,
                        sequence: nextSequence,
                        type: "hxgn.api.dialog.ReadLargePropertyParameters"
                    };
                    const largePropertyJcr = yield DialogProxyTools.commonFetchClient().postJson(baseUrl, contentPath, readLargePropertyParametersJson);
                    if (largePropertyJcr.statusCode !== 200) {
                        throw new Error(`Unexpected result when reading content: ${onlineContentId}`);
                    }
                    const largePropertyVisitor = new LargePropertyVisitor(largePropertyJcr.value);
                    yield DialogProxyTools.writeContentChunk(DialogProxyTools.processStorageUserId(newCopy, userId), tenantId, offlineContentId, nextSequence, largePropertyVisitor);
                    if (!largePropertyVisitor.visitHasMore()) {
                        break;
                    }
                    nextSequence++;
                }
                return null;
            }
            else if (dialogRedirectionJcr.value['type'] === "hxgn.api.dialog.NullRedirection") {
                // WRITE NUll REDIRECTION //
                const dialogRedirectionVisitor = new DialogRedirectionVisitor(dialogRedirectionJcr.value);
                dialogRedirectionVisitor.visitAndSetReferringDialogId(offlineDialogId);
                let actionIdAtTargetId = actionId;
                if (targetId) {
                    const targetIdEncoded = Base64.encodeUrlSafeString(targetId);
                    actionIdAtTargetId = actionIdAtTargetId + '@' + targetIdEncoded;
                }
                // Log.trace(`${thisMethod} -- writing online dialog redirection with dialog id: ${dialogRedirectionVisitor.visitDialogId()}`);
                // Log.trace(`${thisMethod} -- writing online dialog redirection with referring dialog id: ${dialogRedirectionVisitor.visitReferringDialogId()}`);
                // Log.trace(`${thisMethod} -- writing online dialog redirection with record id: ${dialogRedirectionVisitor.visitRecordId()}`);
                // Log.trace(`${thisMethod} -- writing online dialog redirection to offline redirection id: ${dialogRedirectionVisitor.visitId()}`);
                const referringDialogId = dialogRedirectionVisitor.visitReferringDialogId();
                yield this.writeDialogRedirection(DialogProxyTools.processStorageUserId(newCopy, userId), tenantId, referringDialogId, actionIdAtTargetId, dialogRedirectionVisitor);
                return null;
            }
            else {
                // WRITE REDIRECTION //
                const dialogRedirectionVisitor = new DialogRedirectionVisitor(dialogRedirectionJcr.value);
                const beforeDialogRedirection = dialogRedirectionVisitor.copyAsJsonObject();
                // dialogRedirectionVisitor.deriveDialogIdsFromDialogNameAndRecordId();
                dialogRedirectionVisitor.visitAndSetReferringDialogId(offlineDialogId);
                let actionIdAtTargetId = actionId;
                if (targetId) {
                    const targetIdEncoded = Base64.encodeUrlSafeString(targetId);
                    actionIdAtTargetId = actionIdAtTargetId + '@' + targetIdEncoded;
                }
                // Log.trace(`${thisMethod} -- writing online dialog redirection with dialog id: ${dialogRedirectionVisitor.visitDialogId()}`);
                // Log.trace(`${thisMethod} -- writing online dialog redirection with referring dialog id: ${dialogRedirectionVisitor.visitReferringDialogId()}`);
                // Log.trace(`${thisMethod} -- writing online dialog redirection with record id: ${dialogRedirectionVisitor.visitRecordId()}`);
                // Log.trace(`${thisMethod} -- writing online dialog redirection to offline redirection id: ${dialogRedirectionVisitor.visitId()}`);
                // Log.trace(`${thisMethod} -- writing online dialog redirection to offline storage: ${dialogRedirectionVisitor.copyAsJsonString()}`);
                const referringDialogId = dialogRedirectionVisitor.visitReferringDialogId();
                yield this.writeDialogRedirection(DialogProxyTools.processStorageUserId(newCopy, userId), tenantId, referringDialogId, actionIdAtTargetId, dialogRedirectionVisitor);
                // CAPTURE DIALOG //
                const beforeDialogId = beforeDialogRedirection['dialogId'];
                const beforeAndAfterDialog = yield this.genericCaptureDialog(userId, baseUrl, tenantId, sessionId, beforeDialogId, referringDialogId, newCopy);
                return { beforeDialogRedirection, afterDialogRedirection: dialogRedirectionVisitor.enclosedJsonObject(),
                    beforeDialog: beforeAndAfterDialog['beforeDialog'], afterDialog: beforeAndAfterDialog['afterDialog'], dialogError: beforeAndAfterDialog['dialogError'] };
            }
        });
    }
    static genericCaptureRecordSet(userId, baseUrl, tenantId, sessionId, id, newCopy = '') {
        return __awaiter(this, void 0, void 0, function* () {
            const thisMethod = 'DialogProxyTools::genericCaptureRecordSet';
            // ONLINE
            const onlineQueryRecordsPath = `tenants/${tenantId}/sessions/${sessionId}/dialogs/${id}/records`;
            const onlineQueryParameters = {
                fetchDirection: "FORWARD",
                fetchMaxRecords: 999,
                type: "hxgn.api.dialog.QueryParameters"
            };
            // Log.trace(`${thisMethod} -- capturing online record set: ${onlineQueryRecordsPath}`);
            const onlineQueryRecordsJcr = yield DialogProxyTools.commonFetchClient().postJson(baseUrl, onlineQueryRecordsPath, onlineQueryParameters);
            if (onlineQueryRecordsJcr.statusCode !== 200) {
                throw new Error(`Unexpected result when getting records. \n Error: ${JSON.stringify(onlineQueryRecordsJcr)} \n Code: ${onlineQueryRecordsJcr.statusCode}`);
            }
            const onlineQueryRecordSetVisitor = new RecordSetVisitor(onlineQueryRecordsJcr.value);
            // WRITE TO STORAGE
            // Log.trace(`${thisMethod} -- writing online record set to offline query dialog id: ${id}`);
            // Log.trace(`${thisMethod} -- writing online record set to offline storage: ${onlineQueryRecordSetVisitor.copyAsJsonString()}`);
            yield DialogProxyTools.writeRecordSet(DialogProxyTools.processStorageUserId(newCopy, userId), tenantId, id, onlineQueryRecordSetVisitor);
            return onlineQueryRecordSetVisitor;
        });
    }
    /**
     * Logic to fetch the Dropdown avaialble values from server and store in Asyncstorage for Offline
     */
    static genericCaptureDropDownProperties(userId, baseUrl, tenantId, sessionId, dialogId, recordId, property, newCopy = '') {
        return __awaiter(this, void 0, void 0, function* () {
            const thisMethod = 'DialogProxyTools::genericCaptureDropDownProperties';
            const onlineQueryPropertiesPath = `tenants/${tenantId}/sessions/${sessionId}/dialogs/${dialogId}/record/${property}/availableValues`;
            const onlineQueryParameters = {
                "pendingWrites": {
                    "id": recordId,
                    "properties": [],
                    "type": "hxgn.api.dialog.Record"
                },
                "type": "hxgn.api.dialog.AvailableValuesParameters"
            };
            // Log.trace(`${thisMethod} -- capturing dropdown properties: ${onlineQueryPropertiesPath}`);
            const onlineQueryRecordsJcr = yield DialogProxyTools.commonFetchClient().postJson(baseUrl, onlineQueryPropertiesPath, onlineQueryParameters);
            if (onlineQueryRecordsJcr.statusCode !== 200) {
                throw new Error(`Unexpected result when getting availableValues. \n Error: ${JSON.stringify(onlineQueryRecordsJcr)} \n Code: ${onlineQueryRecordsJcr.statusCode}`);
            }
            // WRITE TO STORAGE
            // Log.trace(`${thisMethod} -- writing online property result to offline async storage of property ${property} of record: ${recordId}`);
            yield DialogProxyTools.writeDropDownProperty(DialogProxyTools.processStorageUserId(newCopy, userId), tenantId, dialogId, recordId, property, onlineQueryRecordsJcr);
            return onlineQueryRecordsJcr;
        });
    }
    /**
     * Logic to fetch the large property values from server and store in Asyncstorage for Offline
     */
    static genericCaptureLargeProperties(userId, baseUrl, tenantId, sessionId, dialogId, recordID, property, queryType, newCopy = '') {
        return __awaiter(this, void 0, void 0, function* () {
            const thisMethod = 'DialogProxyTools::genericCaptureLargeProperties';
            const recordType = queryType === 'Edit' ? 'record' : 'records';
            const recordId = queryType === 'Edit' ? undefined : recordID;
            // GET AND WRITE FLAG PROPERTIES CONTENT TO LOCAL STORAGE //
            let nextSequence = 0;
            while (true) {
                // commented below line code for testing purpose, will validate and remove in next commit
                // const onlineQueryPropertiesPath = `tenants/${tenantId}/sessions/${sessionId}/dialogs/${dialogId}/${recordType}/flag`;
                const onlineQueryPropertiesPath = `tenants/${tenantId}/sessions/${sessionId}/dialogs/${dialogId}/${recordType}/${property.propertyName}`;
                const readLargePropertyParametersJson = {
                    maxBytes: 131072,
                    recordId,
                    sequence: nextSequence,
                    type: "hxgn.api.dialog.ReadLargePropertyParameters"
                };
                const largePropertyJcr = yield DialogProxyTools.commonFetchClient().postJson(baseUrl, onlineQueryPropertiesPath, readLargePropertyParametersJson);
                if (largePropertyJcr.statusCode !== 200) {
                    throw new Error(`Unexpected result when reading content: ${recordId}`);
                }
                const largePropertyVisitor = new LargePropertyVisitor(largePropertyJcr.value);
                yield DialogProxyTools.writeContentFlagChunk(DialogProxyTools.processStorageUserId(newCopy, userId), tenantId, dialogId, recordId, nextSequence, largePropertyVisitor);
                if (!largePropertyVisitor.visitHasMore()) {
                    break;
                }
                nextSequence++;
            }
            return null;
        });
    }
    static genericCaptureWorkbenchActionRedirectionAndDialog(userId, baseUrl, tenantId, sessionId, workbenchId, actionId, newCopy = '') {
        return __awaiter(this, void 0, void 0, function* () {
            const thisMethod = 'DialogProxyTools::captureWorkbenchActionRedirectionAndDialog';
            const resourcePath = `tenants/${tenantId}/sessions/${sessionId}/workbenches/${workbenchId}/actions/${actionId}`;
            // Log.trace(`${thisMethod} -- capturing workbench redirection and dialog: ${resourcePath}`);
            // GET REDIRECTION //
            // Log.trace(`${thisMethod} -- capturing online dialog redirection: ${resourcePath}`);
            const dialogRedirectionJcr = yield DialogProxyTools.commonFetchClient().postJson(baseUrl, resourcePath, {});
            if (dialogRedirectionJcr.statusCode !== 303) {
                yield this.writeErrorDialogRedirection(DialogProxyTools.processStorageUserId(newCopy, userId), tenantId, workbenchId, actionId, dialogRedirectionJcr);
                return null;
            }
            // Log.trace(`${thisMethod} -- workbench action redirection: ${JSON.stringify(dialogRedirectionJcr.value)}`);
            // WRITE REDIRECTION //
            const dialogRedirectionVisitor = new DialogRedirectionVisitor(dialogRedirectionJcr.value);
            const beforeDialogRedirection = dialogRedirectionVisitor.copyAsJsonObject();
            // Log.trace(`${thisMethod} -- writing online dialog redirection to offline redirection id: ${dialogRedirectionVisitor.visitId()}`);
            // Log.trace(`${thisMethod} -- writing online dialog redirection to offline storage: ${dialogRedirectionVisitor.copyAsJsonString()}`);
            yield this.writeDialogRedirection(DialogProxyTools.processStorageUserId(newCopy, userId), tenantId, workbenchId, actionId, dialogRedirectionVisitor);
            // CAPTURE DIALOG //
            const beforeDialogId = beforeDialogRedirection['dialogId'];
            const beforeAndAfterDialog = yield this.genericCaptureDialog(userId, baseUrl, tenantId, sessionId, beforeDialogId, null, newCopy);
            return { beforeDialogRedirection, afterDialogRedirection: dialogRedirectionVisitor.enclosedJsonObject(),
                beforeDialog: beforeAndAfterDialog['beforeDialog'], afterDialog: beforeAndAfterDialog['afterDialog'], dialogError: beforeAndAfterDialog['dialogError'] };
        });
    }
    static captureRecordSet(userId, baseUrl, tenantId, sessionId, beforeAndAfterValues, listDialogName) {
        return __awaiter(this, void 0, void 0, function* () {
            const thisMethod = 'DialogProxyTools::captureRecordSet';
            // ONLINE
            const onlineRootDialogVisitor = new DialogVisitor(beforeAndAfterValues.beforeDialog);
            const onlineQueryDialogVisitor = onlineRootDialogVisitor.visitChildAtName(listDialogName);
            const onlineQueryDialogId = onlineQueryDialogVisitor.visitId();
            const onlineQueryRecordsPath = `tenants/${tenantId}/sessions/${sessionId}/dialogs/${onlineQueryDialogId}/records`;
            const onlineQueryParameters = {
                fetchDirection: "FORWARD",
                fetchMaxRecords: 999,
                type: "hxgn.api.dialog.QueryParameters"
            };
            // Log.trace(`${thisMethod} -- capturing online record set: ${onlineQueryRecordsPath}`);
            const onlineQueryRecordsJcr = yield DialogProxyTools.commonFetchClient().postJson(baseUrl, onlineQueryRecordsPath, onlineQueryParameters);
            if (onlineQueryRecordsJcr.statusCode !== 200) {
                throw new Error(`Unexpected result when getting records: ${onlineQueryRecordsJcr.statusCode}`);
            }
            const onlineQueryRecordSetVisitor = new RecordSetVisitor(onlineQueryRecordsJcr.value);
            // OFFLINE
            const offlineRootDialogVisitor = new DialogVisitor(beforeAndAfterValues.afterDialog);
            const offlineQueryDialogVisitor = offlineRootDialogVisitor.visitChildAtName(listDialogName);
            const offlineQueryDialogId = offlineQueryDialogVisitor.visitId();
            // WRITE TO STORAGE
            // Log.trace(`${thisMethod} -- writing online record set to offline query dialog id: ${offlineQueryDialogId}`);
            // Log.trace(`${thisMethod} -- writing online record set to offline storage: ${onlineQueryRecordSetVisitor.copyAsJsonString()}`);
            yield DialogProxyTools.writeRecordSet(userId, tenantId, offlineQueryDialogId, onlineQueryRecordSetVisitor);
            return onlineQueryRecordSetVisitor;
        });
    }
    static captureWorkbenchActionRedirectionAndDialog(userId, baseUrl, tenantId, sessionId, workbenchId, actionId) {
        return __awaiter(this, void 0, void 0, function* () {
            const thisMethod = 'DialogProxyTools::captureWorkbenchActionRedirectionAndDialog';
            const resourcePath = `tenants/${tenantId}/sessions/${sessionId}/workbenches/${workbenchId}/actions/${actionId}`;
            // Log.trace(`${thisMethod} -- capturing workbench redirection and dialog: ${resourcePath}`);
            // GET REDIRECTION //
            // Log.trace(`${thisMethod} -- capturing online dialog redirection: ${resourcePath}`);
            const dialogRedirectionJcr = yield DialogProxyTools.commonFetchClient().postJson(baseUrl, resourcePath, {});
            if (dialogRedirectionJcr.statusCode !== 303) {
                throw new Error(`Unexpected result when posting workbench ${workbenchId} action ${actionId}: ${dialogRedirectionJcr.statusCode}`);
            }
            // Log.trace(`${thisMethod} -- workbench action redirection: ${JSON.stringify(dialogRedirectionJcr.value)}`);
            // WRITE REDIRECTION //
            const dialogRedirectionVisitor = new DialogRedirectionVisitor(dialogRedirectionJcr.value);
            const beforeDialogRedirection = dialogRedirectionVisitor.copyAsJsonObject();
            dialogRedirectionVisitor.deriveDialogIdsFromDialogNameAndRecordId();
            // Log.trace(`${thisMethod} -- writing online dialog redirection to offline redirection id: ${dialogRedirectionVisitor.visitId()}`);
            // Log.trace(`${thisMethod} -- writing online dialog redirection to offline storage: ${dialogRedirectionVisitor.copyAsJsonString()}`);
            yield this.writeDialogRedirection(userId, tenantId, workbenchId, actionId, dialogRedirectionVisitor);
            // CAPTURE DIALOG //
            const beforeDialogId = beforeDialogRedirection['dialogId'];
            const beforeAndAfterDialog = yield this.captureDialog(userId, baseUrl, tenantId, sessionId, beforeDialogId, null);
            return { beforeDialogRedirection, afterDialogRedirection: dialogRedirectionVisitor.enclosedJsonObject(),
                beforeDialog: beforeAndAfterDialog['beforeDialog'], afterDialog: beforeAndAfterDialog['afterDialog'] };
        });
    }
    static retrieveFilteredKeys(userId, tenantId) {
        return __awaiter(this, void 0, void 0, function* () {
            let prefix = this.STORAGE_KEY_PREFIX.replace('${userId}', userId);
            prefix = prefix.replace('${tenantId}', tenantId);
            const allKeys = yield storage.getAllKeys();
            const filteredKeys = allKeys.filter((k) => k.startsWith(prefix));
            return filteredKeys;
        });
    }
    static clearAllStoragekeys(keys) {
        return __awaiter(this, void 0, void 0, function* () {
            const thisMethod = 'DialogProxyTools::clearAllStoragekeys';
            // Log.trace(`${thisMethod} -- ************** BEGIN CLEAR ALL STORAGE KEYS **************`);
            yield storage.multiRemove(keys);
            // Log.trace(`${thisMethod} -- ************** END CLEAR ALL STORAGE KEYS **************`);
        });
    }
    static clearAllStorageAt(userId, tenantId) {
        return __awaiter(this, void 0, void 0, function* () {
            const thisMethod = 'DialogProxyTools::clearAllStorageAt';
            // Log.trace(`${thisMethod} -- ************** BEGIN CLEAR ALL STORAGE AT **************`);
            yield storage.multiRemove(yield this.retrieveFilteredKeys(userId, tenantId));
            // await storage.multiRemove(await this.retrieveFilteredKeys(`.${userId}`, tenantId));
            // Log.trace(`${thisMethod} -- ************** END CLEAR ALL STORAGE AT **************`);
        });
    }
    static updateAllFilteredKeys(userId, tenantId, copy) {
        return __awaiter(this, void 0, void 0, function* () {
            const allKeys = yield this.retrieveFilteredKeys(`${copy}.${userId}`, tenantId);
            for (const k of allKeys) {
                yield storage.getJson(k).then((jsonObject) => __awaiter(this, void 0, void 0, function* () {
                    yield storage.setJson(k.replace(`${copy}.`, ''), jsonObject);
                }));
            }
        });
    }
    static commonFetchClient() {
        return this.COMMON_FETCH_CLIENT;
    }
    static constructDialogMessageModel(message) {
        return { type: this.DIALOG_MESSAGE_MODEL_TYPE, message };
    }
    static constructLoginModel(userId, password) {
        return {
            "userId": userId,
            "password": password,
            "clientType": "MOBILE",
            "deviceProperties": {},
            "type": this.LOGIN_MODEL_TYPE
        };
    }
    static constructRedirectionStorageKey(userId, tenantId, stateId, actionId) {
        let key = this.REDIRECTION_STORAGE_KEY.replace('${userId}', userId);
        key = key.replace('${tenantId}', tenantId);
        key = key.replace('${stateId}', stateId);
        key = key.replace('${actionId}', actionId);
        return key;
    }
    static constructRequestNotValidDuringOfflineMode(action, resourcePath) {
        return new JsonClientResponse(this.constructDialogMessageModel(`${action} at ${resourcePath} is not valid during offline mode: `), 400);
    }
    /**
     * Construct an empty null redirection with the following defaults:
     * referringObject will be a type of "hxgn.api.dialog.ReferringDialog"
     *      dialogAlias=null
     *      dialogName=null
     *      dialogMode='READ'
     *      actionId=null
     *      dialogId=null
     * refreshNeeded=true
     * id=randomly generated value
     */
    static constructNullRedirection(tenantId, sessionId) {
        const nullRedirectionId = DialogProxyTools.constructNullRedirectionId();
        return {
            "tenantId": tenantId,
            "referringObject": {
                "dialogMode": "READ",
                "dialogAlias": null,
                "dialogName": null,
                "actionId": null,
                "type": "hxgn.api.dialog.ReferringDialog",
                "dialogId": null
            },
            "refreshNeeded": true,
            "sessionId": sessionId,
            "id": nullRedirectionId,
            "type": "hxgn.api.dialog.NullRedirection"
        };
    }
    static constructNullRedirectionId() {
        return `null_redirection__offline_${Date.now()}`;
    }
    // ----- MODEL QUERY METHODS ----- //
    static isActionParametersModel(jsonObject) {
        if (!jsonObject || !jsonObject['type']) {
            return false;
        }
        return jsonObject['type'] === this.ACTION_PARAMETERS_MODEL_TYPE;
    }
    static isAnnotationModel(jsonObject) {
        if (!jsonObject || !jsonObject['type']) {
            return false;
        }
        return jsonObject['type'] === this.ANNOTATION_MODEL_TYPE;
    }
    static isDialogModel(jsonObject) {
        if (!jsonObject || !jsonObject['type']) {
            return false;
        }
        return jsonObject['type'] === this.EDITOR_DIALOG_MODEL_TYPE ||
            jsonObject['type'] === this.QUERY_DIALOG_MODEL_TYPE;
    }
    static isLoginModel(jsonObject) {
        if (!jsonObject || !jsonObject['type']) {
            return false;
        }
        return jsonObject['type'] === this.LOGIN_MODEL_TYPE;
    }
    static isPropertyModel(jsonObject) {
        if (!jsonObject || !jsonObject['type']) {
            return false;
        }
        return jsonObject['type'] === this.PROPERTY_MODEL_TYPE;
    }
    static isPropertyDefModel(jsonObject) {
        if (!jsonObject || !jsonObject['type']) {
            return false;
        }
        return jsonObject['type'] === this.PROPERTY_DEF_MODEL_TYPE;
    }
    static isRecordModel(jsonObject) {
        if (!jsonObject || !jsonObject['type']) {
            return false;
        }
        return jsonObject['type'] === this.RECORD_MODEL_TYPE;
    }
    static isRecordSetModel(jsonObject) {
        if (!jsonObject || !jsonObject['type']) {
            return false;
        }
        return jsonObject['type'] === this.RECORD_SET_MODEL_TYPE;
    }
    static isReferringDialogModel(jsonObject) {
        if (!jsonObject || !jsonObject['type']) {
            return false;
        }
        return jsonObject['type'] === this.REFERRING_DIALOG_MODEL_TYPE;
    }
    static isReferringWorkbenchModel(jsonObject) {
        if (!jsonObject || !jsonObject['type']) {
            return false;
        }
        return jsonObject['type'] === this.REFERRING_WORKBENCH_MODEL_TYPE;
    }
    static isSessionModel(jsonObject) {
        if (!jsonObject || !jsonObject['type']) {
            return false;
        }
        return jsonObject['type'] === this.SESSION_MODEL_TYPE;
    }
    // ----- PATH QUERY METHODS ----- //
    static readDialogAsOfflineResponse(userId, request) {
        return this.readDialogAsVisitor(userId, request).then(dialogVisitor => {
            /**
             * logic to handle the failure scenario while crawling bothin read an write of storage call -- needs refining further
             */
            if (dialogVisitor) {
                if (dialogVisitor instanceof DialogVisitor || !dialogVisitor.statusCode) {
                    const diloagRes = JSON.parse(JSON.stringify(dialogVisitor));
                    return new JsonClientResponse(diloagRes._enclosedJsonObject, 200);
                }
                else {
                    return dialogVisitor;
                }
            }
            return this.constructRequestNotValidDuringOfflineMode('readDialogAsOfflineResponse', request.resourcePath());
        });
    }
    static readDialogAsVisitor(userId, request) {
        const thisMethod = 'DialogProxyTools::readDialogAsVisitor';
        const pathFields = request.deconstructGetDialogPath();
        const tenantId = pathFields.tenantId;
        const dialogId = pathFields.dialogId;
        let key = this.DIALOG_STORAGE_KEY.replace('${tenantId}', tenantId);
        key = key.replace('${userId}', userId);
        key = key.replace('${dialogId}', dialogId);
        // Log.trace(`${thisMethod} -- reading for dialog at key: ${key}`);
        return storage.getJson(key).then(jsonObject => {
            if (jsonObject) {
                if (!jsonObject.statusCode) {
                    return new DialogVisitor(jsonObject);
                }
                else {
                    return new JsonClientResponse(jsonObject.value, jsonObject.statusCode);
                }
            }
            return null;
        });
    }
    static readDialogRedirectionAsVisitor(userId, tenantId, stateId, actionId) {
        const thisMethod = 'DialogProxyTools::readDialogRedirectionAsVisitor';
        const key = this.constructRedirectionStorageKey(userId, tenantId, stateId, actionId);
        // Log.trace(`${thisMethod} -- reading for redirection at key: ${key}`);
        return storage.getJson(key).then(jsonObject => {
            if (jsonObject) {
                if (!jsonObject.statusCode) {
                    return new DialogRedirectionVisitor(jsonObject);
                }
                else {
                    return new JsonClientResponse(jsonObject.value, jsonObject.statusCode);
                }
            }
            return null;
        });
    }
    static readMenuActionRedirectionAsOfflineResponse(userId, request) {
        return this.readMenuActionRedirectionAsVisitor(userId, request).then(dialogRedirectionVisitor => {
            /**
             * logic to handle the failure scenario while crawling bothin read an write of storage call -- needs refining further
             */
            if (dialogRedirectionVisitor) {
                if (dialogRedirectionVisitor instanceof DialogRedirectionVisitor || dialogRedirectionVisitor instanceof RedirectionVisitor && !dialogRedirectionVisitor['statusCode']) {
                    return new JsonClientResponse(dialogRedirectionVisitor.enclosedJsonObject(), 303);
                }
                else {
                    return dialogRedirectionVisitor;
                }
            }
            return this.constructRequestNotValidDuringOfflineMode('readMenuActionRedirectionAsOfflineResponse', request.resourcePath());
        });
    }
    static readMenuActionRedirectionAsVisitor(userId, request) {
        return __awaiter(this, void 0, void 0, function* () {
            const pathFields = request.deconstructPostMenuActionPath();
            let actionIdAtTargetId = request.actionId();
            const targetId = request.targetId();
            if (targetId) {
                const targetIdEncoded = Base64.encodeUrlSafeString(targetId);
                actionIdAtTargetId = `${request.actionId()}@${targetIdEncoded}`;
            }
            else {
                return this.readDialogRedirectionAsVisitorWithoutTargetId(userId, pathFields.tenantId, pathFields.dialogId, actionIdAtTargetId);
            }
            return this.readDialogRedirectionAsVisitor(userId, pathFields.tenantId, pathFields.dialogId, actionIdAtTargetId);
        });
    }
    static readDialogRedirectionAsVisitorWithoutTargetId(userId, tenantId, dialogId, actionIdAtTargetId) {
        return __awaiter(this, void 0, void 0, function* () {
            const allKeys = yield storage.getAllKeys();
            let redirectionRes = null;
            let key = this.REDIRECTION_SUB__STORAGE_KEY.replace('${userId}', userId);
            key = key.replace('${tenantId}', tenantId);
            key = key.replace('${stateId}', dialogId);
            key = key.replace('${actionId}', actionIdAtTargetId);
            for (const k of allKeys) {
                if (k.startsWith(key) && k.endsWith('.redirection')) {
                    redirectionRes = yield storage.getJson(k).then(jsonObject => {
                        if (jsonObject) {
                            if (!jsonObject.statusCode) {
                                return new DialogRedirectionVisitor(jsonObject);
                            }
                            else {
                                return new JsonClientResponse(jsonObject.value, jsonObject.statusCode);
                            }
                        }
                    });
                    break;
                }
            }
            return redirectionRes;
        });
    }
    static readAndUpdateExistingDialogRedirection(userId, tenantId, dialogId, actionIdAtTargetId, redirectionResponse) {
        return __awaiter(this, void 0, void 0, function* () {
            const allKeys = yield storage.getAllKeys();
            let key = this.REDIRECTION_SUB__STORAGE_KEY.replace('${userId}', userId);
            key = key.replace('${tenantId}', tenantId);
            key = key.replace('${stateId}', dialogId);
            key = key.replace('${actionId}', actionIdAtTargetId);
            for (const k of allKeys) {
                if (k.startsWith(key) && k.endsWith('.redirection')) {
                    yield storage.getJson(k).then((jsonObject) => __awaiter(this, void 0, void 0, function* () {
                        if (jsonObject) {
                            yield storage.removeItem(k);
                            yield storage.setJson(k, redirectionResponse._enclosedJsonObject);
                        }
                    }));
                    break;
                }
            }
            return;
        });
    }
    static readPropertyCommit(userId, tenantId, dialogId, propertyName, propertyTimestamp) {
        let key = this.PROPERTY_COMMIT_STORAGE_KEY.replace('${userId}', userId);
        key = key.replace('${tenantId}', tenantId);
        key = key.replace('${dialogId}', dialogId);
        key = key.replace('${propertyName}', `${propertyName}${propertyTimestamp}`);
        return storage.getJson(key);
    }
    static readRecordAsOfflineResponse(userId, request) {
        return this.readRecordAsVisitorFromRequest(userId, request).then(recordVisitor => {
            return recordVisitor ?
                new JsonClientResponse(recordVisitor.enclosedJsonObject(), 200) :
                this.constructRequestNotValidDuringOfflineMode('readRecordAsOfflineResponse', request.resourcePath());
        });
    }
    static readRecordAsVisitor(userId, tenantId, dialogId) {
        return __awaiter(this, void 0, void 0, function* () {
            let key = this.RECORD_START_STORAGE_KEY.replace('${userId}', userId);
            key = key.replace('${tenantId}', tenantId);
            key = key.replace('${dialogId}', dialogId);
            key = `${key}.`;
            const allKeys = yield storage.getAllKeys();
            let recordRes = null;
            for (const k of allKeys) {
                if (k.startsWith(key) && k.endsWith('.record')) {
                    recordRes = yield storage.getJson(k).then(jsonObject => jsonObject ? new RecordVisitor(jsonObject) : null);
                }
            }
            return recordRes;
        });
    }
    static readRecordAsVisitorFromRequest(userId, request) {
        const pathFields = request.deconstructGetRecordPath();
        const tenantId = pathFields.tenantId;
        const dialogId = pathFields.dialogId;
        return this.readRecordAsVisitor(userId, tenantId, dialogId);
    }
    static readRecordCommitAsVisitor(userId, tenantId, dialogId) {
        let key = this.RECORD_COMMIT_STORAGE_KEY.replace('${userId}', userId);
        key = key.replace('${tenantId}', tenantId);
        key = key.replace('${dialogId}', dialogId);
        return storage.getJson(key).then(jsonObject => jsonObject ? new RecordVisitor(jsonObject) : null);
    }
    static readRecordSetAsOfflineResponse(userId, request) {
        return this.readRecordSetAsVisitor(userId, request).then(recordSetVisitor => {
            if (recordSetVisitor) {
                if (recordSetVisitor.visitHasMore) {
                    recordSetVisitor.visitAndSetHasMore(false);
                }
                return new JsonClientResponse(recordSetVisitor.enclosedJsonObject(), 200);
            }
            return this.constructRequestNotValidDuringOfflineMode('readRecordSetAsOfflineResponse', request.resourcePath());
        });
    }
    static readRecordSetAsVisitor(userId, request) {
        return __awaiter(this, void 0, void 0, function* () {
            const pathFields = request.deconstructPostRecordsPath();
            const tenantId = pathFields.tenantId;
            const dialogId = pathFields.dialogId;
            let key = this.RECORD_SET_STORAGE_KEY.replace('${tenantId}', tenantId);
            key = key.replace('${userId}', userId);
            key = key.replace('${dialogId}', dialogId);
            const jsonObject = yield storage.getJson(key);
            if (!jsonObject) {
                return null;
            }
            const recordSetVisitor = new RecordSetVisitor(jsonObject);
            if (request.body().fromRecordId) {
                recordSetVisitor.fromRecordId(request.body().fromRecordId);
            }
            return recordSetVisitor;
        });
    }
    static readSessionContentAsOfflineResponse(userId, request) {
        const pathFields = request.deconstructPostSessionContentPath();
        const tenantId = pathFields.tenantId;
        const contentId = pathFields.contentId;
        const parametersVisitor = new ReadLargePropertyParametersVisitor(request.body());
        const sequence = parametersVisitor.visitSequence();
        return this.readSessionContentAsVisitor(userId, tenantId, contentId, sequence).then(largePropertyVisitor => {
            return largePropertyVisitor ?
                new JsonClientResponse(largePropertyVisitor.enclosedJsonObject(), 200) :
                this.constructRequestNotValidDuringOfflineMode('readSessionContentAsOfflineResponse', request.resourcePath());
        });
    }
    static readSessionContentAsVisitor(userId, tenantId, contentId, sequence) {
        let key = this.CONTENT_STORAGE_KEY.replace('${userId}', userId);
        key = key.replace('${tenantId}', tenantId);
        key = key.replace('${contentId}', contentId);
        key = key.replace('${sequence}', sequence.toString());
        return storage.getJson(key).then(jsonObject => jsonObject ? new LargePropertyVisitor(jsonObject) : null);
    }
    static readContentFlagAsOfflineResponse(userId, request) {
        const pathFields = request.deconstructPostAvailableValuesPath();
        const tenantId = pathFields.tenantId;
        const dialogId = pathFields.dialogId;
        const contentId = request.body().recordId;
        const parametersVisitor = new ReadLargePropertyParametersVisitor(request.body());
        const sequence = parametersVisitor.visitSequence();
        return this.readContentFlagAsVisitor(userId, tenantId, dialogId, contentId, sequence).then(largePropertyVisitor => {
            return largePropertyVisitor ?
                new JsonClientResponse(largePropertyVisitor.enclosedJsonObject(), 200) :
                this.constructRequestNotValidDuringOfflineMode('readContentFlagAsOfflineResponse', request.resourcePath());
        });
    }
    static readContentFlagAsVisitor(userId, tenantId, dialogId, contentId, sequence) {
        let key = this.CONTENT_FLAG_STORAGE_KEY.replace('${userId}', userId);
        key = key.replace('${tenantId}', tenantId);
        key = key.replace('${dialogId}', dialogId);
        key = key.replace('${contentId}', contentId);
        key = key.replace('${sequence}', sequence.toString());
        return storage.getJson(key).then(jsonObject => jsonObject ? new LargePropertyVisitor(jsonObject) : null);
    }
    static readWorkbenchActionRedirectionAsOfflineResponse(userId, request) {
        const pathFields = request.deconstructPostWorkbenchActionPath();
        return this.readDialogRedirectionAsVisitor(userId, pathFields.tenantId, pathFields.workbenchId, pathFields.actionId).then(dialogRedirectionVisitor => {
            /**
             * logic to handle the failure scenario while crawling bothin read an write of storage call -- needs refining further
             */
            if (dialogRedirectionVisitor) {
                if (dialogRedirectionVisitor instanceof DialogRedirectionVisitor || dialogRedirectionVisitor instanceof RedirectionVisitor && !dialogRedirectionVisitor.statusCode) {
                    return new JsonClientResponse(dialogRedirectionVisitor.enclosedJsonObject(), 303);
                }
                else {
                    return dialogRedirectionVisitor;
                }
            }
            return this.constructRequestNotValidDuringOfflineMode('readWorkbenchActionRedirectionAsOfflineResponse', request.resourcePath());
        });
    }
    /**
     * Logic to read the Dropdown available values from Asyncstorage
     */
    static readPropertyAvailableValuesAsOfflineResponse(userId, request) {
        return __awaiter(this, void 0, void 0, function* () {
            const pathFields = request.deconstructPostAvailableValuesPath();
            const tenantId = pathFields.tenantId;
            const dialogId = pathFields.dialogId;
            const property = pathFields.property;
            const recordId = request.body().pendingWrites.id;
            let key = this.PROPERTY_CODE_DEF_STORAGE_KEY.replace('${userId}', userId);
            key = key.replace('${tenantId}', tenantId);
            key = key.replace('${dialogId}', dialogId);
            key = key.replace('${recordId}', recordId);
            key = key.replace('${property}', property);
            return storage.getJson(key).then(jsonObject => jsonObject ? new JsonClientResponse(jsonObject.value, 200) : null);
        });
    }
    static showStoredDialogNavigation() {
        return __awaiter(this, void 0, void 0, function* () {
            const thisMethod = 'DialogProxyTools::showAllRedirectionStorageKeys';
            // Log.trace(`${thisMethod} -- ************** BEGIN SHOW ALL REDIRECTION STORAGE KEYS **************`);
            const allKeys = yield storage.getAllKeys();
            for (const k of allKeys) {
                if (k.endsWith('.redirection')) {
                    const redirection = yield storage.getJson(k);
                    // Log.trace(`${thisMethod} -- ${k} => \n${redirection.dialogName}(id=${redirection.id})\n`);
                }
            }
            // Log.trace(`${thisMethod} -- ************** END SHOW ALL REDIRECTION STORAGE KEYS **************`);
        });
    }
    static showAllStorageKeys() {
        return __awaiter(this, void 0, void 0, function* () {
            const thisMethod = 'DialogProxyTools::showAllStorageKeys';
            // Log.trace(`${thisMethod} -- ************** BEGIN SHOW ALL STORAGE KEYS **************`);
            const allKeys = yield storage.getAllKeys();
            for (const k of allKeys) {
                const v = yield clientStorage.getItem(k);
                // Log.trace(`${thisMethod} -- ${k}`);
            }
            // Log.trace(`${thisMethod} -- ************** END SHOW ALL STORAGE KEYS **************`);
        });
    }
    static showAllStorageKeysAndValues() {
        return __awaiter(this, void 0, void 0, function* () {
            const thisMethod = 'DialogProxyTools::showAllStorageKeysAndValues';
            // Log.trace(`${thisMethod} -- ************** BEGIN SHOW ALL STORAGE KEYS AND VALUES **************`);
            const allKeys = yield storage.getAllKeys();
            for (const k of allKeys) {
                const v = yield clientStorage.getItem(k);
                // Log.trace(`${thisMethod} -- ${k}: ${v}`);
            }
            // Log.trace(`${thisMethod} -- ************** END SHOW ALL STORAGE KEYS AND VALUES **************`);
        });
    }
    static writeContentChunk(userId, tenantId, contentId, sequence, largePropertyVisitor) {
        let key = this.CONTENT_STORAGE_KEY.replace('${userId}', userId);
        key = key.replace('${tenantId}', tenantId);
        key = key.replace('${contentId}', contentId);
        key = key.replace('${sequence}', sequence.toString());
        return storage.setJson(key, largePropertyVisitor.enclosedJsonObject());
    }
    static writeContentFlagChunk(userId, tenantId, dialogId, contentId, sequence, largePropertyVisitor) {
        let key = this.CONTENT_FLAG_STORAGE_KEY.replace('${userId}', userId);
        key = key.replace('${tenantId}', tenantId);
        key = key.replace('${dialogId}', dialogId);
        key = key.replace('${contentId}', contentId);
        key = key.replace('${sequence}', sequence.toString());
        return storage.setJson(key, largePropertyVisitor.enclosedJsonObject());
    }
    static writeContentRedirection(userId, tenantId, stateId, actionId, contentRedirectionVistor) {
        const key = this.constructRedirectionStorageKey(userId, tenantId, stateId, actionId);
        return storage.setJson(key, contentRedirectionVistor.enclosedJsonObject());
    }
    static writeDialog(userId, tenantId, dialogVisitor) {
        let key = this.DIALOG_STORAGE_KEY.replace('${userId}', userId);
        key = key.replace('${tenantId}', tenantId);
        key = key.replace('${dialogId}', dialogVisitor.visitId());
        return storage.setJson(key, dialogVisitor.enclosedJsonObject());
    }
    static writeErrorDialog(userId, tenantId, dialogID, dialogVisitor) {
        let key = this.DIALOG_STORAGE_KEY.replace('${userId}', userId);
        key = key.replace('${tenantId}', tenantId);
        key = key.replace('${dialogId}', dialogID);
        return storage.setJson(key, dialogVisitor);
    }
    static writeDialogRedirection(userId, tenantId, stateId, actionId, dialogRedirectionVistor) {
        const key = this.constructRedirectionStorageKey(userId, tenantId, stateId, actionId);
        return storage.setJson(key, dialogRedirectionVistor.enclosedJsonObject());
    }
    static writeErrorDialogRedirection(userId, tenantId, stateId, actionId, dialogRedirectionVistor) {
        const key = this.constructRedirectionStorageKey(userId, tenantId, stateId, actionId);
        return storage.setJson(key, dialogRedirectionVistor);
    }
    static writePropertyCommit(userId, tenantId, dialogId, propertyName, writeLargePropertyParametersVisitor, propertyTimestamp) {
        return __awaiter(this, void 0, void 0, function* () {
            let key = this.PROPERTY_COMMIT_STORAGE_KEY.replace('${userId}', userId);
            key = key.replace('${tenantId}', tenantId);
            key = key.replace('${dialogId}', dialogId);
            key = key.replace('${propertyName}', `${propertyName}${propertyTimestamp}`);
            let writeHistory = [];
            if (writeLargePropertyParametersVisitor.visitAppend()) {
                const jsonObject = yield storage.getJson(key);
                if (jsonObject) {
                    writeHistory = jsonObject;
                }
            }
            writeHistory.push(writeLargePropertyParametersVisitor.enclosedJsonObject());
            return storage.setJson(key, writeHistory);
        });
    }
    static writeRecord(userId, tenantId, dialogId, recordVisitor) {
        let key = this.RECORD_STORAGE_KEY.replace('${userId}', userId);
        key = key.replace('${tenantId}', tenantId);
        key = key.replace('${dialogId}', dialogId);
        return storage.setJson(key, recordVisitor.enclosedJsonObject());
    }
    static writeRecordCommit(userId, tenantId, dialogId, recordVisitor) {
        let key = this.RECORD_COMMIT_STORAGE_KEY.replace('${userId}', userId);
        key = key.replace('${tenantId}', tenantId);
        key = key.replace('${dialogId}', dialogId);
        return storage.setJson(key, recordVisitor.enclosedJsonObject());
    }
    static writeRecordSet(userId, tenantId, dialogId, recordSetVisitor) {
        let key = this.RECORD_SET_STORAGE_KEY.replace('${userId}', userId);
        key = key.replace('${tenantId}', tenantId);
        key = key.replace('${dialogId}', dialogId);
        return storage.setJson(key, recordSetVisitor.enclosedJsonObject());
    }
    static writeBreadCrumb(userId, tenantId, dialogId, breadCrumbs) {
        let key = this.BREADCRUMB_STORAGE_KEY.replace('${userId}', userId);
        key = key.replace('${tenantId}', tenantId);
        key = key.replace('${dialogId}', dialogId);
        return storage.setJson(key, breadCrumbs);
    }
    static readBreadCrumbForTheDialog(userId, tenantId, dialogId) {
        return __awaiter(this, void 0, void 0, function* () {
            let key = this.BREADCRUMB_STORAGE_KEY.replace('${userId}', userId);
            key = key.replace('${tenantId}', tenantId);
            key = key.replace('${dialogId}', dialogId);
            return yield storage.getJson(key).then(breadCrumbs => breadCrumbs ? breadCrumbs : null);
        });
    }
    static genericWriteRecordWithRecordId(userId, tenantId, dialogId, recordId, recordVisitor) {
        let key = this.RECORD_WITH_RECORDID_STORAGE_KEY.replace('${userId}', userId);
        key = key.replace('${tenantId}', tenantId);
        key = key.replace('${dialogId}', dialogId);
        key = key.replace('${recordId}', recordId);
        return storage.setJson(key, recordVisitor.enclosedJsonObject());
    }
    static writeRecordEdit(userId, tenantId, dialogId, recordVisitor) {
        return __awaiter(this, void 0, void 0, function* () {
            if (recordVisitor && recordVisitor.enclosedJsonObject().id) {
                let key = this.RECORD_WITH_RECORDID_STORAGE_KEY.replace('${userId}', userId);
                key = key.replace('${tenantId}', tenantId);
                key = key.replace('${dialogId}', dialogId);
                key = key.replace('${recordId}', recordVisitor.enclosedJsonObject().id);
                const actualRecord = yield storage.getJson(key).then(jsonObject => jsonObject ? new RecordVisitor(jsonObject) : null);
                const rootDailogId = actualRecord != null ? actualRecord.enclosedJsonObject().rootParentDialogId : null; // rootDialogId
                let rootKey = this.RECORD_START_STORAGE_KEY.replace('${userId}', userId);
                rootKey = rootKey.replace('${tenantId}', tenantId);
                rootKey = rootKey.replace('${dialogId}', rootDailogId);
                const allKeys = yield storage.getAllKeys();
                let recordRes = null;
                let actualRecordRes = null;
                const recordID = recordVisitor.enclosedJsonObject().id; // can be moved out
                const recordProperties = recordVisitor.enclosedJsonObject().properties;
                for (const k of allKeys) {
                    if (k.endsWith(`${recordID}.record`)) {
                        recordRes = yield storage.getJson(k).then(jsonObject => jsonObject ? new RecordVisitor(jsonObject) : null);
                        for (let i = 0, len = recordProperties.length; i < len; i++) {
                            if (recordProperties[i].name && recordProperties[i].value) {
                                recordRes.visitAndSetPropertyValueAt(recordProperties[i].name, recordProperties[i].value);
                            }
                        }
                        actualRecordRes = recordRes;
                        yield storage.setJson(k, recordRes.enclosedJsonObject());
                    }
                    else if (k.endsWith(`${rootKey}.recordset`)) {
                        if (recordProperties.length > 0) {
                            const recordSetRes = yield storage.getJson(k).then(jsonObject => jsonObject ? new RecordSetVisitor(jsonObject) : null);
                            if (recordSetRes && recordSetRes.enclosedJsonObject().records) {
                                recordRes = recordSetRes.visitRecordAtId(recordID);
                                if (recordRes) {
                                    recordSetRes.addOrUpdateRecordProperties(recordVisitor);
                                }
                            }
                            yield storage.setJson(k, recordSetRes.enclosedJsonObject());
                        }
                    }
                }
                return actualRecordRes;
            }
            else {
                let key = this.RECORD_STORAGE_KEY.replace('${userId}', userId);
                key = key.replace('${tenantId}', tenantId);
                key = key.replace('${dialogId}', dialogId);
                // For Storing the created record with suffic .commit => need to work on this since if user creates record multiple times it will be overrided. 
                DialogProxyTools.writeRecordCommit(userId, tenantId, dialogId, recordVisitor);
                return yield storage.getJson(key).then(jsonObject => jsonObject ? new RecordVisitor(jsonObject) : null);
            }
        });
    }
    static writeOutBoundQueue(userId, tenantId, editedRecords) {
        let key = this.OUTBOUND_QUEUE_STORAGE_KEY.replace('${userId}', userId);
        key = key.replace('${tenantId}', tenantId);
        return storage.setJson(key, editedRecords);
    }
    static readOutBoundQueue(userId, tenantId) {
        return __awaiter(this, void 0, void 0, function* () {
            let key = this.OUTBOUND_QUEUE_STORAGE_KEY.replace('${userId}', userId);
            key = key.replace('${tenantId}', tenantId);
            return storage.getJson(key).then(OutBoundQueue => OutBoundQueue ? OutBoundQueue : null);
        });
    }
    /**
     * Logic to write the Dropdown available values to Asyncstorage
     */
    static writeDropDownProperty(userId, tenantId, dialogId, recordId, property, dropDownProperty) {
        let key = this.PROPERTY_CODE_DEF_STORAGE_KEY.replace('${userId}', userId);
        key = key.replace('${tenantId}', tenantId);
        key = key.replace('${dialogId}', dialogId);
        key = key.replace('${recordId}', recordId);
        key = key.replace('${property}', property);
        return storage.setJson(key, dropDownProperty);
    }
    static replayContext(userId, baseUrl, tenantId, sessionId, breadCrumbs, dialogId) {
        return __awaiter(this, void 0, void 0, function* () {
            const thisMethod = 'DialogProxyTools::replayContext';
            let currentDialogId = dialogId ? dialogId : null;
            if (breadCrumbs && breadCrumbs.length) {
                const breadCrumbObject = breadCrumbs.shift();
                const breadCrumb = breadCrumbObject.breadcrumb;
                try {
                    switch (breadCrumb.type) {
                        case 'wbAction':
                            let resourcePath = `tenants/${tenantId}/sessions/${sessionId}/workbenches/${breadCrumb.wbId}/actions/${breadCrumb.actionId}`;
                            // GET REDIRECTION //
                            // Log.trace(`${thisMethod} -- calling ${breadCrumb.actionId} on wokbench: ${breadCrumb.wbId} --- breadcrumbs`, JSON.stringify(breadCrumbs));
                            let dialogRedirectionJcr = yield DialogProxyTools.commonFetchClient().postJson(baseUrl, resourcePath, {});
                            if (dialogRedirectionJcr.statusCode !== 303) {
                                if (dialogRedirectionJcr.statusCode === 400 && dialogRedirectionJcr.value['message'].includes('Session')) {
                                    return Promise.reject({ type: 'InvalidSession', message: 'Invalid session' });
                                }
                                return new Error(`ReplayContext:: Unexpected result when posting workbench ${breadCrumb.wbId} action ${breadCrumb.actionId}: ${dialogRedirectionJcr.statusCode}`);
                            }
                            let dialogRedirectionVisitor = new DialogRedirectionVisitor(dialogRedirectionJcr.value);
                            currentDialogId = dialogRedirectionVisitor.visitDialogId();
                            currentDialogId = yield this.replayContext(userId, baseUrl, tenantId, sessionId, breadCrumbs, currentDialogId);
                            break;
                        case 'menuAction':
                            // Get Dialog and its children
                            let dialogIdForMenuAction = dialogId;
                            if (dialogId) {
                                resourcePath = `tenants/${tenantId}/sessions/${sessionId}/dialogs/${dialogId}`;
                                // Log.trace(`${thisMethod} -- getting online dialog: ${dialogId}`);
                                const dialogJcr = yield DialogProxyTools.commonFetchClient().getJson(baseUrl, resourcePath);
                                if (dialogJcr.statusCode !== 200) {
                                    return new Error(`Unexpected result when getting dialog ${dialogId}: ${dialogJcr.statusCode}`);
                                }
                                const dialogVisitor = new DialogVisitor(dialogJcr.value);
                                const dialogObject = dialogVisitor.enclosedJsonObject();
                                // Find among children dialog with persistentId same as in breadCrumb.
                                if (dialogObject.children) {
                                    dialogObject.children.forEach(childDialog => {
                                        if (childDialog.persistentId === breadCrumb.persistentId) {
                                            dialogIdForMenuAction = childDialog.id;
                                        }
                                    });
                                }
                            }
                            // Post action to the child dialog containing the record for the action.
                            resourcePath = `tenants/${tenantId}/sessions/${sessionId}/dialogs/${dialogIdForMenuAction}/actions/${breadCrumb.actionId}`;
                            // Log.trace(`${thisMethod} -- post Action ResourcePath: ${resourcePath}\n DialogAlias: ${breadCrumb.dialogAlias}`);
                            const actionParameters = {
                                targets: breadCrumb.recordId,
                                type: "hxgn.api.dialog.ActionParameters"
                            };
                            // GET REDIRECTION //
                            dialogRedirectionJcr = yield DialogProxyTools.commonFetchClient().postJson(baseUrl, resourcePath, actionParameters);
                            if (dialogRedirectionJcr.statusCode !== 303) {
                                return new Error(`Unexpected result when posting menuAction ${breadCrumb.actionId} on record ${breadCrumb.recordId}: ${dialogRedirectionJcr.statusCode}`);
                            }
                            dialogRedirectionVisitor = new DialogRedirectionVisitor(dialogRedirectionJcr.value);
                            currentDialogId = dialogRedirectionVisitor.visitDialogId();
                            currentDialogId = yield this.replayContext(userId, baseUrl, tenantId, sessionId, breadCrumbs, currentDialogId);
                            break;
                        default:
                            return currentDialogId;
                    }
                }
                catch (error) {
                    // Log.trace(`ERROR while replaying: ${error}`)
                }
                return currentDialogId;
            }
            else {
                return currentDialogId;
            }
        });
    }
    static intializeGoOfflineProcess(userId, baseUrl, tenantId, sessionId, actionId) {
        return __awaiter(this, void 0, void 0, function* () {
            const thisMethod = 'DialogProxyTools::intializeGoOfflineProcess';
            const resourcePath = `tenants/${tenantId}/sessions/${sessionId}/actions/${actionId}`;
            // Log.trace(`${thisMethod} -- intializing the GoOffline process with path: ${resourcePath}`);
            const goOfflineResponse = yield DialogProxyTools.commonFetchClient().postJson(baseUrl, resourcePath, {});
            return goOfflineResponse;
        });
    }
    static processStorageUserId(newCopy, userId) {
        if (newCopy !== '') {
            return `${newCopy}.${userId}`;
        }
        return `${userId}`;
    }
    static writeBackActionPerformed(tenantId, sessionId, dialogId, explicitDialogDestroy) {
        let key = this.BACKACTIONTYPE_STORAGE_KEY.replace('${tenantId}', tenantId);
        key = key.replace('${sessionId}', sessionId);
        key = key.replace('${dialogId}', dialogId);
        return storage.setJson(key, explicitDialogDestroy);
    }
    static readBackActionPerformed(tenantId, sessionId, dialogId) {
        return __awaiter(this, void 0, void 0, function* () {
            let key = this.BACKACTIONTYPE_STORAGE_KEY.replace('${tenantId}', tenantId);
            key = key.replace('${sessionId}', sessionId);
            key = key.replace('${dialogId}', dialogId);
            return yield storage.getJson(key);
        });
    }
}
// Model Types
DialogProxyTools.ACTION_PARAMETERS_MODEL_TYPE = 'hxgn.api.dialog.ActionParameters';
DialogProxyTools.ANNOTATION_MODEL_TYPE = 'hxgn.api.dialog.Annotation';
DialogProxyTools.DIALOG_MESSAGE_MODEL_TYPE = 'hxgn.api.dialog.DialogMessage';
DialogProxyTools.EDITOR_DIALOG_MODEL_TYPE = 'hxgn.api.dialog.EditorDialog';
DialogProxyTools.LOGIN_MODEL_TYPE = 'hxgn.api.dialog.Login';
DialogProxyTools.PROPERTY_MODEL_TYPE = 'hxgn.api.dialog.Property';
DialogProxyTools.PROPERTY_DEF_MODEL_TYPE = 'hxgn.api.dialog.PropertyDef';
DialogProxyTools.QUERY_DIALOG_MODEL_TYPE = 'hxgn.api.dialog.QueryDialog';
DialogProxyTools.RECORD_MODEL_TYPE = 'hxgn.api.dialog.Record';
DialogProxyTools.RECORD_SET_MODEL_TYPE = 'hxgn.api.dialog.RecordSet';
DialogProxyTools.REFERRING_DIALOG_MODEL_TYPE = 'hxgn.api.dialog.ReferringDialog';
DialogProxyTools.REFERRING_WORKBENCH_MODEL_TYPE = 'hxgn.api.dialog.ReferringWorkbench';
DialogProxyTools.SESSION_MODEL_TYPE = 'hxgn.api.dialog.Session';
// Storage Keys
DialogProxyTools.BREADCRUMB_STORAGE_KEY = '${userId}.${tenantId}.${dialogId}.breadcrumb';
DialogProxyTools.BACKACTIONTYPE_STORAGE_KEY = '${tenantId}.${sessionId}.${dialogId}.backactiontype';
DialogProxyTools.CONTENT_STORAGE_KEY = '${userId}.${tenantId}.${contentId}.${sequence}.content';
DialogProxyTools.CONTENT_FLAG_STORAGE_KEY = '${userId}.${tenantId}.${dialogId}.${contentId}.${sequence}.content';
DialogProxyTools.DIALOG_STORAGE_KEY = '${userId}.${tenantId}.${dialogId}.dialog';
DialogProxyTools.PROPERTY_COMMIT_STORAGE_KEY = '${userId}.${tenantId}.${dialogId}.${propertyName}.propertycommit';
DialogProxyTools.RECORD_COMMIT_STORAGE_KEY = '${userId}.${tenantId}.${dialogId}.recordcommit';
DialogProxyTools.RECORD_SET_STORAGE_KEY = '${userId}.${tenantId}.${dialogId}.recordset';
DialogProxyTools.RECORD_STORAGE_KEY = '${userId}.${tenantId}.${dialogId}.record';
DialogProxyTools.REDIRECTION_STORAGE_KEY = '${userId}.${tenantId}.${stateId}.${actionId}.redirection';
DialogProxyTools.STORAGE_KEY_PREFIX = '${userId}.${tenantId}.';
DialogProxyTools.RECORD_WITH_RECORDID_STORAGE_KEY = '${userId}.${tenantId}.${dialogId}.${recordId}.record';
DialogProxyTools.RECORD_START_STORAGE_KEY = '${userId}.${tenantId}.${dialogId}';
DialogProxyTools.RECORDS_STORAGE_KEY = '${userId}.${tenantId}.${dialogId}.records';
DialogProxyTools.RECORD_SET_WITH_ALIAS__STORAGE_KEY = '${userId}.${tenantId}.${dialogId}.recordset';
DialogProxyTools.OUTBOUND_QUEUE_STORAGE_KEY = '${userId}.${tenantId}.outBoundQueue';
DialogProxyTools.REDIRECTION_SUB__STORAGE_KEY = '${userId}.${tenantId}.${stateId}.${actionId}';
DialogProxyTools.PROPERTY_CODE_DEF_STORAGE_KEY = '${userId}.${tenantId}.${dialogId}.${recordId}.${property}.codedef';
DialogProxyTools.COMMON_FETCH_CLIENT = new FetchClient();
