const coreStyles = {
    root: {
        width: '140px',
        height: '6px',
        borderRadius: '4px',
        backgroundColor: '#F4F4F4',
    },

    bar: {
        borderRadius: '4px',
        margin: '1px',
    },
};

/**
* Helper method to get the styles for Linear Progress component
* @param {Object} contextStyles - Context styles for Linear Progress Component
*/
const getStyles = (contextStyles = {}) => {
    return {
        bar: {
            ...coreStyles.bar,
            ...contextStyles.bar,
        },

        root: {
            ...coreStyles.root,
            ...contextStyles.root,
        },
    };
};

export default getStyles;
