import { rootStore } from 'cv-react-core';

// Core component styles
const coreStyles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexShrink: 0,
        flexGrow: 0,
        flexBasis: 'auto',
        flexWrap: 'wrap',
        paddingTop: 16,
        paddingRight: 16,
        paddingBottom: 4,
        paddingLeft: 16,
        justifyContent: 'flex-end',
        marginBottom: 16,
    },

    icon: {
        color: '#000',
        fontSize: 45,
    },

    iconAndTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexShrink: 1,
        marginRight: '12px',
    },

    iconTextContainer: {
    },

    searchContainer: {
        display: 'flex',
        justifyContent: 'center',
        minWidth: '100px',
        maxWidth: '440px',
        flexGrow: 1,
        flexShrink: 1,
        marginRight: '12px',
    },

    titleText: {
        color: '#000000',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '150%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        minWidth: '100px',
    },
};

/**
 * Helper method to get the styles for the component
 * @param {Object} contextStyles - Context styles for the Component
 */
const getStyles = (contextStyles = {}) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    return {
        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        icon: {
            ...coreStyles.icon,
            color: theme.panelIconColor,
            ...contextStyles.icon,
        },

        iconAndTitleContainer: {
            ...coreStyles.iconAndTitleContainer,
            ...contextStyles.iconAndTitleContainer,
        },

        iconTextContainer: {
            ...coreStyles.iconTextContainer,
            ...contextStyles.iconTextContainer,
        },

        searchContainer: {
            ...coreStyles.searchContainer,
            ...contextStyles.searchContainer,
        },

        titleText: {
            ...theme.fonts.panelTitle,
            ...coreStyles.titleText,
            ...contextStyles.titleText,
        },
    };
};

export default getStyles;
