// Core component styles
const coreStyles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        height: '100%',
        overflow: 'auto',
        width: '100%',
    },

    row: {
        borderWidth: '0px',
        verticalAlign: 'top',
    },

    rowError: {
        borderBottom: '2px solid',
        borderTop: '2px solid',
    },

    rowSelected: {
        backgroundColor: '#dedede',
    },
};

/**
* Helper method to get the styles for the Grid component
* @param {Object} contextStyles - Context styles for the Grid Component
*/
const getStyles = (contextStyles = {}) => {
    return {
        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        row: {
            ...coreStyles.row,
            ...contextStyles.row,
        },

        rowError: {
            ...coreStyles.rowError,
            ...contextStyles.rowError,
        },

        rowSelected: {
            ...coreStyles.rowSelected,
            ...contextStyles.rowSelected,
        },
    };
};

export default getStyles;
