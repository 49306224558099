const coreStyles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        maxHeight: '100vh',
    },

    header: {
    },

    content: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },

    footer: {
        zIndex: 1200,
    },
};

const getStyles = (contextStyles = {}) => {
    return {
        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        header: {
            ...coreStyles.header,
            ...contextStyles.header,
        },

        content: {
            ...coreStyles.content,
            ...contextStyles.content,
        },

        footer: {
            ...coreStyles.footer,
            ...contextStyles.footer,
        },
    };
};

export default getStyles;
