import { DialogProxyTools } from "./DialogProxyTools";
/**
 *
 */
export class LoginVisitor {
    constructor(value) {
        if (!value) {
            throw new Error('LoginVisitor -- null value exception');
        }
        if (typeof value === 'string') {
            this._enclosedJsonObject = JSON.parse(value);
        }
        else {
            this._enclosedJsonObject = value;
        }
        if (!DialogProxyTools.isLoginModel(this._enclosedJsonObject)) {
            throw new Error("Object passed to LoginVisitor is not a Login");
        }
    }
    // --- State Management Helpers --- //
    // --- State Import/Export --- //
    copyAsJsonObject() {
        return JSON.parse(this.copyAsJsonString());
    }
    copyAsJsonString() {
        return JSON.stringify(this.enclosedJsonObject());
    }
    enclosedJsonObject() {
        return this._enclosedJsonObject;
    }
    // --- State Management --- //
    visitPassword() {
        return this.enclosedJsonObject().password;
    }
    visitUserId() {
        return this.enclosedJsonObject().userId;
    }
}
