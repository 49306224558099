import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import MUICheckbox from '@material-ui/core/Checkbox';

const propDefinition = {
    /** Focus on the checkbox */
    autoFocus: PropTypes.bool,

    /** The checked value */
    checked: PropTypes.bool,

    /** Extended styles for this component */
    style: PropTypes.object,

    /** Disables the control */
    disabled: PropTypes.bool,

    /**
     * Called when the checkbox was changed
     * @param {Object} event - The native change event
     * @param {Object} props - The component instance props
     */
    onValueChanged: PropTypes.func,

    /** Reference to the input element */
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),

    /** Extended styles for Onfocus on component*/
    focusStyles: PropTypes.object,

    defaultThemeColor: PropTypes.string,
};

/**
 * A control that allows a user to select one or more items from a set.
 * @see https://material-ui.com/components/checkboxes/
 */
class XaltCheckbox extends Component {
    static propTypes = propDefinition;

    static defaultProps = {
        style: {},
        onValueChanged: () => {},
        focusStyles: {},
        defaultThemeColor: 'default',
    };

    constructor(props) {
        super(props);
        const { style } = props;
        this.state = {
            // we should remove/recheck on this style color addition after core component is implemented
            newStyle: { color: '#000000', ...style },
        };
    }

    render() {
        const {
            autoFocus,
            checked, 
            disabled,
            inputRef,
            defaultThemeColor,
        } = this.props;

        const { newStyle: style } = this.state;

        const inputEventProps = {
            onFocus: this.handleFocus,
            onBlur: this.handleBlur,
            onMouseOver: this.handleFocus,
            onMouseOut: this.handleBlur,
        };

        return (
            <MUICheckbox
                autoFocus={ autoFocus }
                className="xalt-checkbox__container"
                color={ defaultThemeColor }
                style={ style }
                checked={ checked }
                inputRef={ inputRef }
                disabled={ disabled }
                onChange={ this.handleChange }
                disableRipple
                inputProps={ inputEventProps } />
        );
    }

    handleFocus= () => {
        const { style, focusStyles } = this.props;
        this.setState({
            newStyle: {
                ...style,
                ...focusStyles,
            },
        });
    }

    handleBlur= () => {
        const { style } = this.props;
        this.setState({
            newStyle: {
                // we should remove/recheck on this style color addition after core component is implemented
                color: '#000000',
                ...style,
            },
        });
    }

    handleChange = (event) => {
        const { onValueChanged } = this.props;
        const { checked } = event.target;
        onValueChanged(checked);
    }
};

export default XaltCheckbox;
