/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import { TextLabel } from 'cv-library-react-web';
import { constants } from 'cv-react-core';

import TableHeaderCellContentMenu from './TableHeaderCellContentMenu';

import getStyles from './TableHeaderCellContent.styles';

const CARRIAGE_RETURN_REPLACEMENT = '\r\n';
const { searchSort } = constants;
const { DOT_REPLACEMENT } = searchSort;
export const SORT_DIRECTIONS = {
    ASC: 'asc',
    DESC: 'desc',
    NONE: null,
};

class TableHeaderCellContent extends PureComponent {
    static propTypes = {
        onColumnFrozen: PropTypes.func,
        onShowSortForm: PropTypes.func,
        isCustomSortEnabled: PropTypes.bool,
        isAscending: PropTypes.bool,
        priority: PropTypes.number,
        fixedColumn: PropTypes.bool,
        title: PropTypes.string,
        name: PropTypes.string,
        isSearchFormOpen: PropTypes.bool,
        isFiltered: PropTypes.bool,

    };

    static defaultProps = {
        isAscending: false,
        isSearchFormOpen: false,
        onColumnFrozen: () => {},
        onShowSortForm: () => {},
        isFiltered: false,
    };

    constructor(props) {
        super(props);
        const { name } = this.props;
        this.columnName = name.replace(DOT_REPLACEMENT, '.');

        this.state = {
            showMenu: false,
        };
    }

    render() {
        const {
            fixedColumn,
            title,
            priority,
            isAscending,
            isSearchFormOpen,
            isFiltered,
            isCustomSortEnabled,
        } = this.props;

        const {
            showMenu,
        } = this.state;

        const styles = getStyles();

        if (!isSearchFormOpen) {
            this.isAscending = isAscending;
            this.priority = priority;
        }
        // To have carriage returns in header, replace \r or \n or \r\n with \r\n
        const cTitle = title.replace(/\r\n|\n\r|\n|\r/g, CARRIAGE_RETURN_REPLACEMENT);
        const numberOfLines = cTitle.split(CARRIAGE_RETURN_REPLACEMENT).length;

        return (
            <div
                style={ { display: 'flex', alignItems: 'center', flexGrow: 1 } }
                onFocus={ () => {} }
                onMouseEnter={ () => {
                    if (!showMenu) {
                        this.setState({
                            showMenu: true,
                        });
                    }
                } }
                onBlur={ () => {} }
                onMouseLeave={ () => {
                    if (showMenu) {
                        this.setState({
                            showMenu: false,
                        });
                    }
                } }>

                <TextLabel
                    contextStyles={ {
                        container: { ...styles.headerCellTextContainer },
                        text: { ...styles.headerCellText }} }
                    numberOfLines={ numberOfLines }>
                    { cTitle }
                </TextLabel>
                { (showMenu || priority !== undefined || isFiltered) &&
                    <TableHeaderCellContentMenu
                        isColumnLocked={ fixedColumn }
                        isCustomSortEnabled={ isCustomSortEnabled }
                        isSortActive={ priority !== undefined }
                        priority={ priority }
                        isAscending={ isAscending }
                        onHandleColumnFrozen={ this.handleColumnFrozen }
                        onHandleSorting={ this.handleSorting }
                        onHandleDirectionalSorting={ this.handleDirectionalSorting }
                        isFiltered={ isFiltered } />
                }
                <div style={ styles.verticalDivider } />
            </div>
        );
    }

    /**
     * Called from the lock column menu
     * @param {SyntheticEvent} event
     * @returns {void}
     */
    handleColumnFrozen = (event) => {
        event.stopPropagation();
        const { onColumnFrozen, fixedColumn } = this.props;
        // const { name } = column;
        onColumnFrozen(this.columnName, fixedColumn);
    }

    /**
     * Called on menu icon click
     * @param {SyntheticEvent} event
     * @returns {void}
     */
     handleColumnVisibility = (event) => {
        const {
            onColumnVisibility,
        } = this.props;
        event.stopPropagation();

        if (onColumnVisibility) {
            onColumnVisibility(event);
        }
    }

    handleDirectionalSorting = (event, proposedDirection) => {
        event.stopPropagation();
        const { onSortColumn } = this.props;

        const { nativeEvent } = event;
        onSortColumn(this.columnName, proposedDirection, nativeEvent.ctrlKey || nativeEvent.metaKey);
    }

    /**
     * Called on sorting
     * @returns {void}
     */
    handleSorting = (event) => {
        event.stopPropagation();
        const { onShowSortForm } = this.props;

        onShowSortForm();
    }
}

export default TableHeaderCellContent;
