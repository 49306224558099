/* @flow */
export default {
    loading: 'Please wait ...',
    noData: '<No applicable data>',
    noRecords: 'No records found...',
    copyToClipboard: 'Copy To Clipboard',
    quickSearchPlaceHolder: 'What are you looking for?',
    clearSelection: 'Clear Selection(s)',
    selected: 'Record(s) Selected',
    failedToUpdate: 'Record(s) failed to update',
    cannotCopyOrPaste: 'Copy/Paste not allowed in this section',
    cannotDelete: 'Delete not allowed',
    cannotPasteOnNonMaintainable: 'Paste not allowed on non maintainable fields',
    noDataAvailableToPaste: 'No data available to paste. Copy some fields from Data section in order to paste',
    cannotPaste: '{0} column cannot be pasted into {1} column because the property types are incompatible.',
    invalidDropdownPaste: '{0} is not a valid value for {1} column',
    pagingItemTitle: 'Items per page:',
};
