import SimpleStringAttribute from '../SimpleStringAttribute';

export default class AllowShrink extends SimpleStringAttribute {
    exportTo(context, styleSet) {
        const { flexPolicy } = styleSet;
        if (this.value === 'true') {
            flexPolicy.setStandardShrink();
        } else {
            flexPolicy.setNoShrink();
        }
    }
}
