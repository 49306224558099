import { TypeNames } from './types';
export class RedirectionUtil {
    static isRedirection(o) {
        return [
            TypeNames.DialogRedirectionTypeName,
            TypeNames.ContentRedirectionTypeName,
            TypeNames.NullRedirectionTypeName,
            TypeNames.WebRedirectionTypeName,
            TypeNames.WorkbenchRedirectionTypeName
        ].some(n => n === o.type);
    }
    static isDialogRedirection(o) {
        return o.type === TypeNames.DialogRedirectionTypeName;
    }
    static isContentRedirection(o) {
        return o.type === TypeNames.ContentRedirectionTypeName;
    }
    static isNullRedirection(o) {
        return o.type === TypeNames.NullRedirectionTypeName;
    }
    static isWebRedirection(o) {
        return o.type === TypeNames.WebRedirectionTypeName;
    }
    static isWorkbenchRedirection(o) {
        return o.type === TypeNames.WorkbenchRedirectionTypeName;
    }
}
