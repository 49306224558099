/*       */
import AbstractControl from './AbstractControl';
import FormUtil from '../FormUtil';

export default class ValuePicker extends AbstractControl {
    newReadOnlyPropertyStyleSet() {
        const result = super.newReadOnlyPropertyStyleSet();
        const containerStyle = result.xStyle.container || {};
        containerStyle.borderWidth = 0;
        const ta = this.textAlignment;
        if (ta) {
            containerStyle.justifyContent = FormUtil.getAlignment(ta.toLowerCase());
        }
        result.xStyle.container = containerStyle;
        result.xStyle.dropDown = {
            borderWidth: 0,
            borderRadius: 0,
        };
        return result;
    }
}
