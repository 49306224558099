import { Base64 } from "../util/Base64";
import { DialogProxyTools } from "./DialogProxyTools";
import { RedirectionVisitor } from "./RedirectionVisitor";
/**
 *
 */
export class DialogRedirectionVisitor extends RedirectionVisitor {
    constructor(value) {
        super(value);
    }
    // --- State Management Helpers --- //
    static propagateDialogId(dialogRedirection, dialogId) {
        (new DialogRedirectionVisitor(dialogRedirection)).propagateDialogId(dialogId);
    }
    static propagateTenantIdAndSessionId(dialogRedirection, tenantId, sessionId) {
        (new DialogRedirectionVisitor(dialogRedirection)).propagateTenantIdAndSessionId(tenantId, sessionId);
    }
    static visitId(dialogRedirection) {
        return (new DialogRedirectionVisitor(dialogRedirection)).visitId();
    }
    // --- State Management --- //
    deriveDialogIdsFromDialogNameAndRecordId() {
        let derivedDialogId = this.enclosedJsonObject()['dialogName'];
        if (!derivedDialogId) {
            throw new Error("Cannot propagate dialog name -- dialog name not found");
        }
        const referringObject = this.visitReferringObject();
        if (DialogProxyTools.isReferringDialogModel(referringObject)) {
            const referringDialogName = referringObject['dialogName'];
            if (referringDialogName) {
                referringObject['dialogId'] = referringDialogName;
            }
            // WARNING: Although this code sets the id on the dialog redirection, it is conditioned on the
            // referring object being a dialog redirection. This is because workbench redirections have a
            // synthetic record id that we do NOT want to include as part of the redirection id.
            const recordId = this.visitRecordId();
            // TODO: Fix the error in Dialog Service that returns null record ids as a string literal of "null"
            if (recordId && recordId !== 'null') {
                const recordIdEncoded = Base64.encodeUrlSafeString(recordId);
                derivedDialogId = `${derivedDialogId}@${recordIdEncoded}`;
            }
        }
        this.propagateDialogId(derivedDialogId);
    }
    deriveDialogIdsFromDialogNameAndSuffix(suffix) {
        // NOTE: If it's ever needed, this suffix scheme could also append the record id using an '@' sign
        let derivedDialogId = this.enclosedJsonObject()['dialogName'];
        if (!derivedDialogId) {
            throw new Error("Cannot propagate dialog name -- dialog name not found");
        }
        if (suffix) {
            derivedDialogId = derivedDialogId + '$' + suffix;
        }
        this.propagateDialogId(derivedDialogId);
    }
    propagateDialogId(dialogId) {
        this.enclosedJsonObject()['id'] = dialogId;
        this.enclosedJsonObject()['dialogId'] = dialogId;
    }
    propagateTenantIdAndSessionId(tenantId, sessionId) {
        this.enclosedJsonObject()['tenantId'] = tenantId;
        this.enclosedJsonObject()['sessionId'] = sessionId;
    }
    visitDialogId() {
        return this.enclosedJsonObject().dialogId;
    }
    visitAndSetDialogId(dialogId) {
        this.enclosedJsonObject().dialogId = dialogId;
    }
    visitDialogName() {
        return this.enclosedJsonObject().dialogName;
    }
    visitAndSetDialogName(dialogName) {
        this.enclosedJsonObject().dialogName = dialogName;
    }
    visitRecordId() {
        return this.enclosedJsonObject().recordId;
    }
    visitAndSetRecordId(recordId) {
        this.enclosedJsonObject().recordId = recordId;
    }
}
