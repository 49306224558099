// Core component styles
const coreStyles = {
    container: {
        display: 'flex',
        position: 'relative',
    },

    image: {
        // width: 50,
        // height: 50,
    },

    loadingIndicator: {
    },
};

/**
 * Helper method to get styles for the Menu Component
 * @param {Object} contextStyles - Custom styles for the Menu component
 * @param {Boolean} isDisabled
 */
const getStyles = (contextStyles = {}, isDisabled = false) => {
    return {
        container: {
            ...coreStyles.container,
            ...contextStyles.container,

            // pointer event should be disabled if False
            pointerEvents: isDisabled ? 'none' : 'auto',
        },

        image: {
            ...coreStyles.image,
            ...contextStyles.image,
        },

        loadingIndicator: {
            ...coreStyles.loadingIndicator,
            ...contextStyles.loadingIndicator,
        },
    };
};

export default getStyles;
