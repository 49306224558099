import React, { Component } from 'react';
import * as PropTypes from 'prop-types';

import {
    Appointments,
} from '@devexpress/dx-react-scheduler-material-ui';

// Styles
import getStyles from './Appointment.styles';

class Appointment extends Component {
    static propTypes = {
        /** Array of children to render the event */
        children: PropTypes.array,
        /** Styles for this component */
        contextStyles: PropTypes.shape({
            /** Styles for the event container */
            container: PropTypes.object,
        }),

        data: PropTypes.shape({
            title: PropTypes.string,
            objectId: PropTypes.string,
            startDate: PropTypes.instanceOf(Date),
            endDate: PropTypes.instanceOf(Date),
            style: PropTypes.object,
        }),

        /** Handler method for right click/context menu event */
        onEventContextMenu: PropTypes.func,

        /** Handler methods for click event */
        onEventClick: PropTypes.func,

        /** Handler method for double click event */
        onEventDoubleClick: PropTypes.func,
    };

    static defaultProps = {
        contextStyles: {},
    };

    render() {
        const {
            children,
            contextStyles,
            data,
            onEventContextMenu,
            onEventClick,
            onEventDoubleClick,
        } = this.props;

        const {
            objectId,
            style: eventStyles,
        } = data;

        // Generate component styles
        this.styles = getStyles(contextStyles);

        const lightenColor = (hexCode, opacity) => {
            let hex = hexCode.replace('#', '');

            if (hex.length === 3) {
                hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
            }

            const r = parseInt(hex.substring(0, 2), 16);
            const g = parseInt(hex.substring(2, 4), 16);
            const b = parseInt(hex.substring(4, 6), 16);

            return `rgba(${r},${g},${b},${opacity / 100})`;
        };

        const {
            backgroundColor: bgColor,
        } = { ...this.styles.container, ...eventStyles };

        const lighterColor = lightenColor(bgColor, 10);

        const containerProps = {
            className: 'c-calendar__event',
            style: {
                ...this.styles.container,
                ...eventStyles,
                backgroundColor: lighterColor,
                borderLeft: `2px solid ${bgColor}`,
            },
        };

        // Render layout container and contents
        return (
            <Appointments.Appointment
                { ...containerProps }
                data={ data }
                objectid={ objectId }
                onContextMenu={ onEventContextMenu }
                onDoubleClick={ onEventDoubleClick }
                onClick={ onEventClick }>
                { children }
            </Appointments.Appointment>
        );
    }
}

export default Appointment;
