import { rootStore } from 'cv-react-core';

const getStyles = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    // Populate the component parts with theme styles
    return {
        modal: {
            padding: '32px 20px',
            backgroundColor: 'rgba(255, 255, 255, 0.75)',
            backdropFilter: 'blur(80px)',
            borderRadius: '6px',
            width: '342px',
            boxSizing: 'border-box',
            margin: '0px',
            alignItems: 'center',
            boxShadow: 'none',
        },

        overlay: {
        },

        majorText: {
            flexWrap: 'wrap',
            marginTop: '15px',
            whiteSpace: 'pre-wrap',
            lineHeight: '21px',
            overflowWrap: 'anywhere',
            ...theme.fonts.loadingStatusMajor,
        },

        minorText: {
            marginTop: '10px',
            whiteSpace: 'pre-wrap',
            lineHeight: '16px',
            ...theme.fonts.loadingStatusMinor,
        },
    };
};

export default getStyles;
