import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import SaltComponent from './SaltComponent';
import SaltContext from './SaltContext';
import RefUtil from './ref/RefUtil';
import engineConstants from './engineConstants';

@observer
export default class When extends SaltComponent {
    static propTypes = {
        children: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.arrayOf(PropTypes.element),
        ]),
        elseChildren: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.arrayOf(PropTypes.element),
        ]),
        assert: PropTypes.shape({
            refName: PropTypes.string,
            expr: PropTypes.string,
        }),
        viewId: PropTypes.string,
    };

    static typeName = engineConstants.component.name.when;

    render() {
        const { assert, children, elseChildren } = this.props;
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore, scopeManager } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        if (dialogStore && dialogStore.dataChange) {
            return RefUtil.evalAssertion(assert, dialogStore, scopeManager, saltStore) ? children : elseChildren || null;
        }
        return null;
    }
}

When.contextType = SaltContext;
