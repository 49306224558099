import { View } from './View';
/**
 * Columns, filter and sorts for a UI list component.
 */
export class List extends View {
    getColumnAt(propName) {
        if (this.columns) {
            return this.columns.find((column) => {
                return propName === column.propertyName;
            });
        }
        return null;
    }
    get isDefaultStyle() {
        return this.style && this.style === 'DEFAULT';
    }
    get isDetailsFormStyle() {
        return this.style && this.style === 'DETAILS_FORM';
    }
    get isFormStyle() {
        return this.style && this.style === 'FORM';
    }
    get isTabularStyle() {
        return this.style && this.style === 'TABULAR';
    }
    get isDataStyle() {
        return this.style && this.style === 'DATA';
    }
    get isHeaderStyle() {
        return this.style && this.style === 'HEADER';
    }
    get isFooterStyle() {
        return this.style && this.style === 'FOOTER';
    }
    get isMaintainableTableStyle() {
        return this.style && this.style === 'MAINTAINABLE_TABLE';
    }
    get columnHeadings() {
        return this.columns.map((c) => {
            return c.heading;
        });
    }
    get columnData() {
        return this.columns.map((column, index) => {
            const { spacer } = column;
            if (spacer) {
                // If it is a spacer column, add a propertyName.
                return Object.assign(Object.create(Object.getPrototypeOf(column)), column, { propertyName: `spacer_${index}` });
            }
            return column;
        });
    }
    rowValues(record) {
        return this.columns.map((c) => {
            return record.valueAtName(c.propertyName);
        });
    }
}
