import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';

const VirtualTableRow = VirtualTable.Row;

// eslint-disable-next-line react/prefer-stateless-function
class DataTableRow extends PureComponent {
    render() {
        // console.log('DATA TABLE RXG: TABLE ROW: RENDER: ', this.props); // eslint-disable-line
        const {
            children,
            onClick,
            onDoubleClick,
            style,
            tableRow,
        } = this.props;

        return (
            <VirtualTableRow
                onClick={ onClick }
                onDoubleClick={ onDoubleClick }
                row={ tableRow.row }
                tableRow={ tableRow }
                onContextMenu={ this.handleContextMenu }
                style={ style }>
                { children }
            </VirtualTableRow>
        );
    };

    componentDidMount() {
        const {
            onVisible,
            tableRow,
        } = this.props;

        if (onVisible) {
            onVisible(tableRow.row);
        }
    }

    handleContextMenu = (event) => {
        const {
            onContextMenu,
            tableRow,
        } = this.props;

        event.preventDefault();
        event.stopPropagation();

        if (event.type === 'contextmenu' && onContextMenu) {
            onContextMenu(event, tableRow);
        }
    };
}

DataTableRow.propTypes = {
    /** React node(s) used to render the cell content */
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.node,
        PropTypes.element,
    ]),

    /** The row click handler */
    onClick: PropTypes.func,

    /** The row double click handler */
    onDoubleClick: PropTypes.func,

    /**
     * Called on row right click
     * @param {SyntheticEvent} event
     * @param {TableRow} tableRow
     * @returns {void}
     */
    onContextMenu: PropTypes.func,

    /**
     * Called on mount
     * @param {TableRow} tableRow
     * @returns {void}
     */
    onVisible: PropTypes.func,

    /** Styles added to the cell <td> container */
    style: PropTypes.object,

    /** Specifies a DXG table row */
    tableRow: PropTypes.object.isRequired,
};

DataTableRow.defaultProps = {};

export default DataTableRow;
