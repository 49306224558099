import React from 'react';
import { LinearProgress, TextLabel } from 'cv-library-react-web';
import * as PropTypes from 'prop-types';
import { serviceFactory } from 'cv-react-core';

import getStyles from './ListActivityIndicator.styles';

const ListActivityIndicator = (props) => {
    const { contextStyles } = props;
    const styles = getStyles(contextStyles);
    const { lang } = serviceFactory;
    return (
        <div
            style={ styles.container }>
            <LinearProgress
                contextStyles={ {
                    root: styles.root,
                    bar: styles.bar,
                } } />
            <TextLabel contextStyles={ {
                container: styles.labelContainer,
                text: styles.labelText,
            } }>
                { lang.loadingStatus.loading }
            </TextLabel>
        </div>
    );
};

ListActivityIndicator.propTypes = {
    contextStyles: PropTypes.object,
};
export default ListActivityIndicator;