// Core component styles
const coreStyles = {
    container: {
        display: 'inline-flex',
    },

    badge: {
    },

    icon: {
    },

    iconStyles: {
        display: 'inherit',
    }
};

/**
* Helper method to get the styles for Icon component
* @param {Object} contextStyles - Context styles for Icon Component
*/
const getStyles = (contextStyles = {}) => {
    return {
        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        badge: {
            ...coreStyles.badge,
            ...contextStyles.badge,
        },

        icon: {
            ...coreStyles.icon,
            ...contextStyles.icon,
        },
        
        iconStyles: {
            ...coreStyles.iconStyles,
        }
    };
};

export default getStyles;
