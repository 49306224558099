// Core component styles
const coreStyles = {
    container: {
    },
};

/**
 * Helper method to get styles for the Menu Component
 * @param {Object} contextStyles - Custom styles for the Menu component
 */
const getStyles = (contextStyles = {}) => {
    return {
        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },
    };
};

export default getStyles;
