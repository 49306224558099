// import { rootStore } from 'cv-react-core';

const getStyles = () => {
    // const { themeStore } = rootStore;
    // const theme = themeStore.getSanitizedTheme();

    return {
        container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center' },
        check: {
            padding: '0px',
            width: '18px',
            height: '18px',
            color: '#121212'},
    };
};

export default getStyles;