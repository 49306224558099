const getStyles = () => {
    return {
        modal: {
            width: '580px',
            height: '470px',
            backgroundColor: 'white',
            borderRadius: '6px',
            padding: '0',
            boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.35)',
        },
        modalColumnContainer: {
            width: '100%',
        },
        modalContent: {
            height: '100%',
            flexDirection: 'column',
        },
        headingContainer: {
            padding: '20px 28px 20px 28px',
            flexDirection: 'row',
            alignItems: 'center',
        },
        headingText: {
            fontFamily: 'Roboto',
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontSize: '20px',
            lineHeight: '30px',
            color: 'black',
            flexGrow: 1,
        },
        closeIconButton: {
            padding: '0px',
        },
        closeIcon: {
            color: '#FFF',
            backgroundColor: '#000',
            fontSize: '24px',
            padding: '0px',
            borderRadius: '50%',
        },
        bodyContainer: {
            flexDirection: 'column',
            height: '280px',
            padding: '0 28px 20px 28px',
        },
        footerContainer: {
            justifyContent: 'flex-end',
            borderTop: '1px solid #E0E0E0',
            padding: '16px 28px',
        },
        applyButton: {
            backgroundColor: '#006E96',
            color: '#FFF',
            padding: '14px',
            borderRadius: '4px',
        },
        applyButtonHover: {
            backgroundColor: '#1C8EA9',
        },
        resetButton: {
            backgroundColor: 'white',
            borderColor: '#006E96',
            border: '1px solid #006E96',
            borderRadius: '4px',
            padding: '14px',
            marginRight: '10px',
            color: '#006E96',
        },
        resetButtonHover: {
            backgroundColor: '#1C8EA9',
            color: '#FFF',
        },
    };
};

export default getStyles;
