import React from 'react';
import PropTypes from 'prop-types';
import { default as IconButton } from '../IconButton/IconButton';

import getStyles from './EmailActionIcon.styles';

/**
 * A icon which launches the default email program
 * with the specified value in the to email address.
 */
const EmailActionIcon = (props) => {
    const {
        contextStyles,
        disabled,
        size,
        testID,
        toolTip,
        value,
    } = props;

    const styles = getStyles(contextStyles);

    // Generate icon props
    const iconProps = {
        contextStyles: {
            container: styles.container,
            icon: styles.icon,
        },
        iconName: 'email',
        iconSize: size,
        onClick: () => window.location.assign(`mailto:${value}`),
        title: toolTip,
    };
    if (disabled) { iconProps.disabled = disabled; }
    if (testID) { iconProps.testID = testID; }

    return (
        <IconButton { ...iconProps } />
    );
};

EmailActionIcon.defaultProps = {
    contextStyles: {},
    size: 25,
};

EmailActionIcon.propTypes = {
    /** Styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the container surrounding the icon */
        container: PropTypes.object,

        /** Styles for the icon */
        icon: PropTypes.object,
    }),

    /** Prevent press events */
    disabled: PropTypes.bool,

    /** The size of the icon (fontSize can also be used in the icon styles) */
    size: PropTypes.number,

    /** Id used for testing */
    testID: PropTypes.string,

    /** toolTip string */
    toolTip: PropTypes.string,

    /** The value to populate the phone application number field */
    value: PropTypes.string.isRequired,
};

export default EmailActionIcon;
