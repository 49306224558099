import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { XaltDateTimeField } from 'xalt-react-atoms';
import RWFieldAction from './RWFieldAction';

/**
 * A platform component for building a client action
 */
class RWFieldActionDateTimePicker extends PureComponent {
    static propTypes = {
        style: PropTypes.object,
        action: PropTypes.object,
        onFieldAction: PropTypes.func,
        value: PropTypes.instanceOf(Date),
        firstDayOfWeek: PropTypes.number,
        is24Hour: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.handleOnActionClick = this.handleOnActionClick.bind(this);
        this.dateTimePickerRef = React.createRef();
    }

    render() {
        const {
            is24Hour,
            firstDayOfWeek,
            value,
            ...restProps } = this.props;

        const standardActionPrps = {
            ...restProps,
            onFieldAction: this.handleOnActionClick, // override standard action click
        };

        return (
            <>
                <RWFieldAction { ...standardActionPrps } />
                <XaltDateTimeField
                    style={ { visibility: 'hidden', width: '0px' } }
                    inputRef={ this.dateTimePickerRef }
                    is24Hour={ is24Hour }
                    firstDayOfWeek={ firstDayOfWeek }
                    onValueChanged={ this.handleOnChange }
                    value={ value } />
            </>
        );
    }

    handleOnChange = (date) => {
        const { action } = this.props;
        const { clientFunction } = action;
        if (clientFunction) {
            clientFunction(date);
        }
    };

    handleOnActionClick(event) {
        if (this.dateTimePickerRef && this.dateTimePickerRef.current) {
            this.dateTimePickerRef.current.click(event);
        }
    }
}

export default RWFieldActionDateTimePicker;
