import React from 'react';
import * as PropTypes from 'prop-types';

import { withAdditionalStyles } from '../hoc/withAdditionalStyles';

const propDefinition = {
    /** Disables the control */
    disabled: PropTypes.bool,

    disabledStyle: PropTypes.object,

    /** Styling applied on focus */
    focusStyle: PropTypes.object,

    /** Styling applied on hover */
    hoverStyle: PropTypes.object,

    /**
     * Called when the button is clicked.
     * @param {Object} event - The native click event
     * @param {Object} props - The component instance props
     */
     onClick: PropTypes.func,

    /** provided by the HOC */
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onMouseOut: PropTypes.func,
    onMouseOver: PropTypes.func,

    /** Extended styles for this component */
    style: PropTypes.object,

    /** content displayed inside the button */
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),
};

const XaltButton = (props) => {
    const {
        children,
        disabled,
        disabledStyle,
        focusStyle,
        hoverStyle,
        onBlur,
        onClick,
        onFocus,
        onMouseOut,
        onMouseOver,
        style,
    } = props;

    return (
        <button
            className="xalt-button__base"
            disabled={ disabled }
            onBlur={ onBlur }
            onClick={ onClick }
            onFocus={ onFocus }
            onMouseOut={ onMouseOut }
            onMouseOver={ onMouseOver }
            style={ { ...style, ...(disabled ? disabledStyle : {}), ...hoverStyle, ...focusStyle } }
            type="button">
            { children }
        </button>
    );
};

XaltButton.propTypes = propDefinition;
XaltButton.defaultProps = {
    disabledStyle: {},
    onClick: () => {},
    style: {},
};

export default withAdditionalStyles(XaltButton);
