/*       */


import { GAUGECHART, BARCHART } from '../../exportClassConstants';
import AbstractLayoutFactory from './AbstractLayoutFactory';
import SimpleXStyleBarChartPropAttribute from '../SimpleXStyleBarChartPropAttribute';
import SimpleContainerBarChartPropAttribute from '../SimpleContainerBarChartPropAttribute';
import SimpleXStyleGuageChartPropAttribute from '../SimpleXStyleGaugeChartPropAttribute';

class ChartPropLayoutFactory extends AbstractLayoutFactory {
    constructor() {
        // Skipped: barProps, gaugeProps
        super();
        const attributesAndAliases = [
            'actionMargin',
            'barBorderThickness',
            'barBorderColor',
            'barDrawBack',
            'barMaxValue',
            'barMinValue',
            'barOrientation',
            'barReverse',
            'barValueColor',
            'barValueFormat',
            'barValuePlacement',
            'barValueCenter',
            'barValueTextSize',
            'barBackColor',
            'barForeColor',
            'barHeight',
            'barWidth',
            'barAnimate',
            'gaugeValueTextSize',
            'gaugeValueColor',
            'gaugeValuePlacement',
            'gaugeValueFormat',
            'gaugeProps',
            'gaugePointerPivotY',
            'gaugePointerPivotX',
            'gaugePointerThickness',
            'gaugePointerColor',
            'gaugeMinValue',
            'gaugeMinDegrees',
            'gaugeMaxValue',
            'gaugeMaxDegrees',
            'gaugeImageURL',
            'gaugeImageMode',
            'gaugeAnimate',
            'gaugeReverse',
            'gaugeWidth',
            'gaugeHeight',
            'gaugePointerHorizontalLength',
            'gaugePointerVerticalLength',
            'gaugePointerStyle',
        ];
        this.init(attributesAndAliases);
    }

    initFactory() {
        super.initFactory();
        const { factory } = this;
        factory.barBorderThickness = (json, key) => {
            return new SimpleContainerBarChartPropAttribute('borderWidth', this.getValue(json, key, 'float'), [ BARCHART ]);
        };
        factory.barBackColor = (json, key) => {
            return new SimpleContainerBarChartPropAttribute('backgroundColor', this.getValue(json, key), [ BARCHART ], true);
        };
        factory.barBorderColor = (json, key) => {
            return new SimpleContainerBarChartPropAttribute('borderColor', this.getValue(json, key), [ BARCHART ], true);
        };
        factory.barDrawBack = (json, key) => {
            return new SimpleContainerBarChartPropAttribute('drawBack', this.getValue(json, key, 'boolean'), [ BARCHART ], true);
        };
        factory.barMaxValue = (json, key) => {
            return new SimpleXStyleBarChartPropAttribute('maxValue', this.getValue(json, key, 'float'), [ BARCHART ], true);
        };
        factory.barMinValue = (json, key) => {
            return new SimpleXStyleBarChartPropAttribute('minValue', this.getValue(json, key, 'float'), [ BARCHART ], true);
        };
        factory.barOrientation = (json, key) => {
            return new SimpleXStyleBarChartPropAttribute('orientation', this.getValue(json, key), [ BARCHART ], true);
        };
        factory.barReverse = (json, key) => {
            return new SimpleXStyleBarChartPropAttribute('reverse', this.getValue(json, key, 'boolean'), [ BARCHART ], true);
        };
        factory.barValueColor = (json, key) => {
            return new SimpleXStyleBarChartPropAttribute('valueColor', this.getValue(json, key), [ BARCHART ], true);
        };
        factory.barValueFormat = (json, key) => {
            return new SimpleXStyleBarChartPropAttribute('valueFormat', this.getValue(json, key), [ BARCHART ], true);
        };
        factory.barValuePlacement = (json, key) => {
            return new SimpleXStyleBarChartPropAttribute('valuePlacement', this.getValue(json, key), [ BARCHART ], true);
        };
        factory.barValueCenter = (json, key) => {
            return new SimpleXStyleBarChartPropAttribute('valueCenter', this.getValue(json, key, 'float'), [ BARCHART ], true);
        };
        factory.barValueTextSize = (json, key) => {
            return new SimpleXStyleBarChartPropAttribute('valueTextSize', this.getValue(json, key, 'float'), [ BARCHART ], true);
        };
        factory.barForeColor = (json, key) => {
            return new SimpleXStyleBarChartPropAttribute('barColor', this.getValue(json, key), [ BARCHART ], true);
        };
        factory.barAnimate = (json, key) => {
            return new SimpleXStyleBarChartPropAttribute('animate', this.getValue(json, key, 'boolean'), [ BARCHART ], true);
        };

        factory.gaugeValueTextSize = (json, key) => {
            return new SimpleXStyleGuageChartPropAttribute('valueTextSize', this.getValue(json, key, 'float'), [ GAUGECHART ], true);
        };

        factory.gaugeValueColor = (json, key) => {
            return new SimpleXStyleGuageChartPropAttribute('valueColor', this.getValue(json, key), [ GAUGECHART ], true);
        };

        factory.gaugeValuePlacement = (json, key) => {
            return new SimpleXStyleGuageChartPropAttribute('valuePlacement', this.getValue(json, key), [ GAUGECHART ], true);
        };

        factory.gaugeValueFormat = (json, key) => {
            return new SimpleXStyleGuageChartPropAttribute('valueFormat', this.getValue(json, key), [ GAUGECHART ], true);
        };

        factory.gaugeValuePlacement = (json, key) => {
            return new SimpleXStyleGuageChartPropAttribute('valuePlacement', this.getValue(json, key), [ GAUGECHART ], true);
        };

        factory.gaugePointerPivotY = (json, key) => {
            return new SimpleXStyleGuageChartPropAttribute('pointerPivotY', this.getValue(json, key, 'float'), [ GAUGECHART ], true);
        };

        factory.gaugePointerPivotX = (json, key) => {
            return new SimpleXStyleGuageChartPropAttribute('pointerPivotX', this.getValue(json, key, 'float'), [ GAUGECHART ], true);
        };

        factory.gaugePointerThickness = (json, key) => {
            return new SimpleXStyleGuageChartPropAttribute('pointerThickness', this.getValue(json, key, 'float'), [ GAUGECHART ], true);
        };

        factory.gaugePointerColor = (json, key) => {
            return new SimpleXStyleGuageChartPropAttribute('pointerColor', this.getValue(json, key), [ GAUGECHART ], true);
        };

        factory.gaugeMinValue = (json, key) => {
            return new SimpleXStyleGuageChartPropAttribute('minValue', this.getValue(json, key, 'float'), [ GAUGECHART ], true);
        };

        factory.gaugeMaxValue = (json, key) => {
            return new SimpleXStyleGuageChartPropAttribute('maxValue', this.getValue(json, key, 'float'), [ GAUGECHART ], true);
        };

        factory.gaugeMinDegrees = (json, key) => {
            return new SimpleXStyleGuageChartPropAttribute('minDegrees', this.getValue(json, key, 'float'), [ GAUGECHART ], true);
        };

        factory.gaugeMaxDegrees = (json, key) => {
            return new SimpleXStyleGuageChartPropAttribute('maxDegrees', this.getValue(json, key, 'float'), [ GAUGECHART ], true);
        };

        factory.gaugeImageURL = (json, key) => {
            return new SimpleXStyleGuageChartPropAttribute('gaugeImageURL', this.getValue(json, key), [ GAUGECHART ], true);
        };

        factory.gaugeImageMode = (json, key) => {
            return new SimpleXStyleGuageChartPropAttribute('gaugeImageMode', this.getValue(json, key), [ GAUGECHART ], true);
        };

        factory.gaugeAnimate = (json, key) => {
            return new SimpleXStyleGuageChartPropAttribute('animate', this.getValue(json, key, 'boolean'), [ GAUGECHART ], true);
        };

        factory.gaugeReverse = (json, key) => {
            return new SimpleXStyleGuageChartPropAttribute('reverse', this.getValue(json, key, 'boolean'), [ GAUGECHART ], true);
        };

        factory.gaugeWidth = (json, key) => {
            return new SimpleXStyleGuageChartPropAttribute('width', this.getValue(json, key, 'float'), [ GAUGECHART ], true);
        };

        factory.gaugeHeight = (json, key) => {
            return new SimpleXStyleGuageChartPropAttribute('height', this.getValue(json, key, 'float'), [ GAUGECHART ], true);
        };

        factory.gaugePointerHorizontalLength = (json, key) => {
            return new SimpleXStyleGuageChartPropAttribute('pointerHorizontalLength', this.getValue(json, key, 'float'), [ GAUGECHART ], true);
        };

        factory.gaugePointerVerticalLength = (json, key) => {
            return new SimpleXStyleGuageChartPropAttribute('pointerVerticalLength', this.getValue(json, key, 'float'), [ GAUGECHART ], true);
        };

        factory.gaugePointerStyle = (json, key) => {
            return new SimpleXStyleGuageChartPropAttribute('pointerStyle', this.getValue(json, key), [ GAUGECHART ], true);
        };
    }

    getValue = (json, key, type) => {
        const jsonValue = this.getAsString(json, key);
        if (!jsonValue) return jsonValue;
        if (type === 'boolean') return jsonValue === 'true';
        if (type === 'float') return parseFloat(jsonValue);
        return jsonValue;
    }
}

const chartPropLayoutFactory = new ChartPropLayoutFactory();
export default chartPropLayoutFactory;
