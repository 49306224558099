import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import { default as Paper } from '@material-ui/core/Paper/Paper';

import { default as IconButton } from '../IconButton/IconButton';
import { default as TextLabel } from '../TextLabel/TextLabel';

class DataTableSelectionTray extends PureComponent {
    render() {
        // console.log('DATA TABLE RXG: TABLE SELECTION TRAY: RENDER: ', this.props); // eslint-disable-line
        const {
            contextStyles,
            count,
            formatter,
            onClear,
        } = this.props;

        return (
            <Paper style={ contextStyles.container }>
                <TextLabel contextStyles={ {
                    text: contextStyles.text,
                } }>
                    { formatter(count) }
                </TextLabel>
                <IconButton
                    contextStyles={ {
                        button: contextStyles.closeButton,
                        icon: contextStyles.closeButtonIcon,
                    } }
                    onClick={ onClear }
                    iconSize={ 12 }
                    iconName="clear"
                    title="Clear Selections" />
            </Paper>
        );
    };
}

DataTableSelectionTray.propTypes = {
    /** Extended styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the row selection tray close button */
        closeButton: PropTypes.object,

        /** Styles for the row selection tray close button icon */
        closeButtonIcon: PropTypes.object,

        /** Styles for the row selection tray */
        container: PropTypes.object,

        /** Styles for the row selection tray text */
        text: PropTypes.object,
    }),

    /** Number of rows selected */
    count: PropTypes.number,

    /**
     * Creates tray text
     * @param {Number} count
     * @returns {String} Tray text to display
     */
    formatter: PropTypes.func,

    /** Called on click of clear selections button */
    onClear: PropTypes.func,
};

DataTableSelectionTray.defaultProps = {
    count: 0,
    formatter: (count) => (`${count} Record${count === 1 ? '' : 's'} Selected`),
    onClear: () => {},
};

export default DataTableSelectionTray;
