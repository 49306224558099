import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import TextLabel from '../TextLabel/TextLabel';

const CARRIAGE_RETURN_REPLACEMENT = '\r\n';

export const SORT_DIRECTIONS = {
    ASC: 'asc',
    DESC: 'desc',
    NONE: null,
};

class DataTableHeaderCell extends PureComponent {
    static propTypes = {
        columnLocked: PropTypes.bool,
        columnName: PropTypes.string.isRequired,
        columnTitle: PropTypes.string.isRequired,

        /** Extended styles for this component */
        contextStyles: PropTypes.shape({
            /** Styles for filter buttons */
            filterButton: PropTypes.object,

            /** Styles for filter button badges */
            filterButtonBadge: PropTypes.object,

            /** Styles for filter button icons */
            filterButtonIcon: PropTypes.object,

            /** Styles for lock buttons */
            lockButton: PropTypes.object,

            /** Styles for lock button badges */
            lockButtonBadge: PropTypes.object,

            /** Styles for lock button icons */
            lockButtonIcon: PropTypes.object,

            /** Styles for column names */
            text: PropTypes.object,
        }),
        onColumnFrozen: PropTypes.func,
        onColumnVisibility: PropTypes.func,
        onSortColumn: PropTypes.func,
        onShowSortForm: PropTypes.func,
        sortDirection: PropTypes.string,
        sortOrder: PropTypes.number,
        menuComponent: PropTypes.elementType,
        showMenuComponent: PropTypes.bool,
        isCustomSortEnabled: PropTypes.bool,
    };

    static defaultProps = {
        contextStyles: {},
    };

    render() {
        const {
            columnLocked,
            columnTitle,
            contextStyles,
            sortDirection,
            sortOrder,
            menuComponent: TableHeaderCellMenu,
            showMenuComponent,
            isCustomSortEnabled,
        } = this.props;

        // To have carriage returns in header, replace \r or \n or \r\n with \r\n
        const cTitle = columnTitle.replace(/\r\n|\n\r|\n|\r/g, CARRIAGE_RETURN_REPLACEMENT);
        const numberOfLines = cTitle.split(CARRIAGE_RETURN_REPLACEMENT).length;

        const textLabelProps = {
            contextStyles: {
                text: { ...contextStyles.text, paddingBottom: '0' },
            },
        };

        if (numberOfLines > 1) {
            textLabelProps.numberOfLines = numberOfLines;
        }
        return (
            /* eslint-disable jsx-a11y/click-events-have-key-events */
            /* eslint-disable jsx-a11y/no-static-element-interactions */
            <div
                style={ {
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    alignItems: 'center',
                    overflow: 'hidden',
                    justifyContent: 'space-evenly',
                } }>
                <TextLabel { ...textLabelProps }>
                    { cTitle.toUpperCase() }
                </TextLabel>
                { showMenuComponent && <TableHeaderCellMenu
                    isColumnLocked={ columnLocked }
                    isSortActive={ sortOrder && sortOrder > 0 }
                    isCustomSortEnabled={ isCustomSortEnabled }
                    priority={ sortOrder }
                    isAscending={ sortDirection === SORT_DIRECTIONS.ASC }
                    onHandleColumnFrozen={ this.handleColumnFrozen }
                    onHandleSorting={ this.handleSorting }
                    onHandleDirectionalSorting={ this.handleDirectionalSorting } /> }
                <div style={ contextStyles.verticalDivider } />
            </div>
        );
    }

    /**
     * Called on lock icon click
     * @param {SyntheticEvent} event
     * @returns {void}
     */
    handleColumnFrozen = (event) => {
        const {
            columnName,
            onColumnFrozen,
        } = this.props;
        event.stopPropagation();

        if (onColumnFrozen) {
            onColumnFrozen(columnName);
        }
    }

    /**
     * Called on menu icon click
     * @param {SyntheticEvent} event
     * @returns {void}
     */
    handleColumnVisibility = (event) => {
        const {
            onColumnVisibility,
        } = this.props;
        event.stopPropagation();

        if (onColumnVisibility) {
            onColumnVisibility(event);
        }
    }

    /**
     * Called on sorting
     * @returns {void}
     */
     handleDirectionalSorting = (event, proposedDirection) => {
         event.stopPropagation();
         const {
             columnName,
             onSortColumn,
             sortDirection,
         } = this.props;

         const direction = sortDirection ? SORT_DIRECTIONS.ASC : SORT_DIRECTIONS.DESC;

         // if the proposedDirection is the current direction, remove the sorting behaviour.
         if (proposedDirection === direction) {
             onSortColumn(columnName, SORT_DIRECTIONS.NONE);
         }

         if (event?.ctrlKey || event?.metaKey) {
             onSortColumn(columnName, proposedDirection);
         }
         else {
             onSortColumn(columnName, proposedDirection, false);
         }
     }

     /**
     *
     */
     handleSorting = (event) => {
         event.stopPropagation();

         const { onShowSortForm } = this.props;
         onShowSortForm();
     }
}

export default DataTableHeaderCell;
