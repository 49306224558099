var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { FetchClient } from '../ws';
export class CatavoltAuth {
    static getOAuthUrl(tenantId, proofKey, oAuthUrl, clientType = 'MOBILE', webRedirectURL) {
        return __awaiter(this, void 0, void 0, function* () {
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            };
            const postPayload = { clientState: proofKey };
            const jsonResponse = yield new FetchClient().postJson(oAuthUrl || this._getOAuthReqUrl(tenantId), null, clientType === 'MOBILE' ? postPayload : Object.assign(Object.assign({}, postPayload), { xhaRedirectURL: webRedirectURL }));
            if (jsonResponse.statusCode !== 200) {
                throw new Error(`CatavoltAuth::getOAuthUrl failed with status code: ${jsonResponse.statusCode}`);
            }
            return jsonResponse.value['redirection'];
        });
    }
    static _getOAuthReqUrl(tenantId) {
        return `https://oauth.catavolt.net/oauth/${tenantId}/v1/authorize?callback=true&redirection=false`;
    }
}
