import FlexDirection from './FlexDirection';

const expand = (layout, parentNode) => {
    if (layout.expand) {
        const direction = layout.expand;
        if (direction === 'horizontal' || direction === 'vertical') {
            if (parentNode) {
                const flexParentNode = parentNode.getNearestFlexNode();
                // Parent is column
                if (FlexDirection.isColumnFlexDirectionForNode(flexParentNode)) {
                    if (direction === 'horizontal') {
                        return { alignSelf: 'stretch' };
                    }
                    return { flexGrow: 1, flexShrink: 1 };
                }
                // Parent is row
                if (direction === 'horizontal') {
                    return { flexGrow: 1 };
                }
                return { alignSelf: 'stretch' };
            }
        }
        // direction = "both"
        if (parentNode) {
            const flexParentNode = parentNode.getNearestFlexNode();
            // Parent is column
            if (FlexDirection.isColumnFlexDirectionForNode(flexParentNode)) {
                return {
                    alignSelf: 'stretch',
                    flexGrow: 1,
                    flexShrink: 1,
                };
            }
            return {
                alignSelf: 'stretch',
                flexGrow: 1,
            };
        }
        // Fall back for no parent or unknown direction
        return {};
    }
    return {};
};

export default expand;
