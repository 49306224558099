import React from 'react';
import PropTypes from 'prop-types';
import { default as IconButton } from '../IconButton/IconButton';

import getStyles from './WebActionIcon.styles';

/**
 * A icon which launches the url in new tab
 */
const WebActionIcon = (props) => {
    const {
        contextStyles,
        disabled,
        size,
        testID,
        toolTip,
        value,
    } = props;

    const styles = getStyles(contextStyles);

    let url = value;

    const pattern = /^((http|https|ftp):\/\/)/;

    // Need to append protocal if missing to open url properly otherwise it treats as relative url.
    if (!pattern.test(url)) {
        url = `http://${ url}`;
    }

    // Generate icon props
    const iconProps = {
        contextStyles: {
            container: styles.container,
            icon: styles.icon,
        },
        iconName: 'web',
        iconSize: size,
        onClick: () => window.open(url, '_blank'),
        title: toolTip,
    };
    if (disabled) { iconProps.disabled = disabled; }
    if (testID) { iconProps.testID = testID; }

    return (
        <IconButton { ...iconProps } />
    );
};

WebActionIcon.defaultProps = {
    contextStyles: {},
    size: 25,
};

WebActionIcon.propTypes = {
    /** Styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the container surrounding the icon */
        container: PropTypes.object,

        /** Styles for the icon */
        icon: PropTypes.object,
    }),

    /** Prevent press events */
    disabled: PropTypes.bool,

    /** The size of the icon (fontSize can also be used in the icon styles) */
    size: PropTypes.number,

    /** Id used for testing */
    testID: PropTypes.string,

    /** toolTip string */
    toolTip: PropTypes.string,

    /** The value to populate the phone application number field */
    value: PropTypes.string,
};

export default WebActionIcon;
