/* @flow */
import briefcase from './briefcase';
import calendar from './calendar';
import errors from './errors';
import generic from './generic';
import gps from './gps';
import goOffline from './goOffline';
import goOnline from './goOnline';
import dialog from './dialog';
import home from './home';
import imageAnnotations from './imageAnnotations';
import list from './list';
import loadingStatus from './loadingStatus';
import logo from './logo';
import login from './login';
import mainMenu from './mainMenu';
import nfc from './nfc';
import imagePicker from './imagePicker';
import salt from './salt';
import searchSort from './searchSort';
import settings from './settings';
import details from './details';
import viz from './viz';
import applicationInfo from './applicationInfo';

const englishStrings = {
    briefcase,
    calendar,
    errors,
    generic,
    gps,
    goOffline,
    goOnline,
    home,
    imageAnnotations,
    list,
    loadingStatus,
    logo,
    login,
    mainMenu,
    nfc,
    dialog,
    imagePicker,
    salt,
    searchSort,
    settings,
    details,
    viz,
    applicationInfo,
};

export const languages = {
    english: {
        'en-US': englishStrings,
        en: englishStrings,
    },
};
