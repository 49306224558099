import { action } from 'mobx';
import { TypeNames } from 'cv-dialog-sdk';
import rootStore from '../stores/rootStore';
import SaltEditorStore from '../stores/SaltEditorStore';
import { constants } from '../constants';
import { utilities } from '../utilities';
import serviceFactory from '../services/serviceFactory';
import engineConstants from '../engine/engineConstants';
import lang from '../nls/i18n';
import componentFactory from '../engine/componentFactory';
import searchController from './searchController';
import BuildGlobals from '../provider/BuildGlobals';

// TODO: https://dev.azure.com/HexagonXalt/Xalt%20Mobility/_workitems/edit/19344
// This was added due to the bug above as a temporary solution until the redirection
// changes can be made to direct the client to perform a similar action. Once that is in
// place and implemented, we need to remove this. It is a bad idea to have this kind of
// logic programmed in the client and not allow users to override.
const tempHijackedRoutes = [ 'launchDiagnosticsNSR' ];

/**
 * PSA - No react-native in here!  This must be x-platform!
 */
class PageController {
    setGloballyBusy = (dialogId, uiStore, params = {}) => { // { loadingText, detailText, menu }
        const existingStatusObj = uiStore.getValueForUIObject(dialogId, constants.ui.GLOBAL_SDK_BUSY);
        const alreadyBusy = !!existingStatusObj;
        const newStatus = new utilities.SdkBusyStatus(dialogId, params, existingStatusObj ? existingStatusObj.status : null, serviceFactory.lang);
        if (!existingStatusObj) {
            const statusObj = { status: newStatus };
            uiStore.setValueForUIObject(dialogId, constants.ui.GLOBAL_SDK_BUSY, statusObj);
        } else if (!newStatus.differsFrom(existingStatusObj.status)) {
            action(() => {
                existingStatusObj.status = newStatus;
            })();
        }
        return alreadyBusy;
    };

    changeDefaultAction(listStore, uiStore, eventParams) {
        const { defaultActionId } = eventParams.actionParams;
        const { dialog } = listStore;
        // If requested, move into selection mode.
        if (defaultActionId === engineConstants.action.clientActions.select) {
            this.setSelectionMode(uiStore, listStore, true);
            uiStore.setValueForUIObject(dialog.id, constants.ui.LIST_OVERRIDE_DEFAULT_ACTION_ID, engineConstants.action.clientActions.select);
        } else {
            // If in selection mode, change out so that tapping will fire the action.
            if (this.getSelectionMode(listStore, uiStore)) {
                this.setSelectionMode(uiStore, listStore, false);
            }
            // Set the override default tap action.
            if (defaultActionId === dialog.defaultActionId) {
                uiStore.setValueForUIObject(dialog.id, constants.ui.LIST_OVERRIDE_DEFAULT_ACTION_ID, null);
            } else {
                uiStore.setValueForUIObject(dialog.id, constants.ui.LIST_OVERRIDE_DEFAULT_ACTION_ID, defaultActionId);
            }
        }
    }

    clearGloballyBusy = (dialogId, uiStore) => {
        uiStore.setValueForUIObject(dialogId, constants.ui.GLOBAL_SDK_BUSY, null);
    };

    // newChangeDefaultAction = (dialogStore, uiStore) => {
    //     const defaultTapActionId = dialogStore.dialog.defaultActionId;
    //     const defaultTapActionLabel = defaultTapActionId && dialogStore.dialog.findMenuAt(defaultTapActionId).label;
    //     const currentTapActionId = uiStore.getValueForUIObject(dialogStore.dialog.id, constants.ui.LIST_OVERRIDE_DEFAULT_ACTION_ID);
    //     let currentTapActionLabel;
    //     if (currentTapActionId !== engineConstants.action.clientActions.select) {
    //         currentTapActionLabel = currentTapActionId && dialogStore.dialog.findMenuAt(currentTapActionId).label;
    //     } else {
    //         // the label of (select) is not used from this source
    //     }
    //     return {
    //         id: engineConstants.action.clientActions.changeDefaultAction,
    //         defaultTapActionId,
    //         defaultTapActionLabel,
    //         currentTapActionId,
    //         currentTapActionLabel,
    //     };
    // }

    /*
        Extended actions for different ViewTypes go here
    */
    getAvailableExtendedActions(viewType, dialogStore, uiStore) {
        if (viewType === TypeNames.ListTypeName) {
            const selectActions = dialogStore.hasMultiSelectActions()
                ? { id: engineConstants.action.clientActions.toggleSelectMode, isSelectMode: !!this.getSelectionMode(dialogStore, uiStore) } : {};
            return [
                { id: engineConstants.action.clientActions.toggleExpand },
                { id: engineConstants.action.clientActions.listDensity },
                { id: engineConstants.action.clientActions.copyToClipboard },
                { ...selectActions },
                // { id: engineConstants.action.clientActions.editSalt },
                // { ...this.newChangeDefaultAction(dialogStore, uiStore) },
            ];
        }
        return [
            { id: engineConstants.action.clientActions.toggleExpand },
            { id: engineConstants.action.clientActions.copyToClipboard },
            // { id: engineConstants.action.clientActions.editSalt },
        ];
    }

    getSelectionMode = (dialogStore, uiStore) => {
        return uiStore.getValueForUIObject(dialogStore.dialog.id, constants.ui.SELECTION_MODE);
    }

    setSelectionMode = (uiStore, dialogStore, value) => {
        const dialogId = dialogStore.dialog.id;
        if (!value) {
            utilities.listHelper.clearSelectedRecords(uiStore, dialogStore);
        }
        uiStore.setValueForUIObject(dialogId, constants.ui.SELECTION_MODE, value);
    }

    performClientAction = (params) => {
        // actionParams
        const { actionId, dialogStore, uiStore, onTransition, onError, selectedArray, ...eventParams } = params;
        if (actionId === engineConstants.action.clientActions.refresh) {
            return dialogStore.refresh();
        } if (actionId === engineConstants.action.clientActions.search) {
            return this.performSearch({ dialogStore, onError });
        } if (actionId === engineConstants.action.clientActions.sort) {
            return this.performSort({ dialogStore });
        } if (actionId === engineConstants.action.clientActions.save) {
            return this.performWrite({ dialogStore, uiStore, onTransition, onError });
        } if (actionId === engineConstants.action.clientActions.cancel) {
            return this.performCancel({ dialogStore, uiStore, onTransition, onError });
        } if (actionId === engineConstants.action.clientActions.edit) {
            return this.openWriteMode({ dialogStore, uiStore, onTransition, onError });
        } if (actionId === engineConstants.action.clientActions.toggleExpand) {
            return this.toggleExpandCollapse(dialogStore);
        } if (actionId === engineConstants.action.clientActions.toggleSelectMode) {
            return this.toggleSelectionMode(dialogStore, uiStore);
        } if (actionId === engineConstants.action.clientActions.changeDefaultAction) {
            return this.changeDefaultAction(dialogStore, uiStore, eventParams);
        } if (actionId === engineConstants.action.clientActions.close) {
            return this.fireSoftNavigation({ actionId, dialogStore, uiStore, onTransition, onError });
        } if (actionId === engineConstants.action.clientActions.home) {
            return this.fireSoftNavigation({ actionId, dialogStore, uiStore, onTransition, onError });
        } if (actionId === engineConstants.action.clientActions.copyToClipboard) {
            return this.performClipboardCopy(dialogStore, selectedArray);
        } if (actionId === engineConstants.action.clientActions.select) {
            return this.performSelect(params);
        } if (actionId === engineConstants.action.clientActions.openImage) {
            return this.performOpenImage(params);
        } if (actionId === engineConstants.action.clientActions.calculateStatistics) {
            return this.performCalculateStatistics({ dialogStore, onError });
        } if (actionId === engineConstants.action.clientActions.editSalt) {
            return this.performShowSaltEditor({ dialogStore, uiStore, onError });
        } if (actionId === engineConstants.action.clientActions.listDensity) {
            return this.performListToggler();
        }
        // add addtional client actions here
        const title = serviceFactory.lang.formatString(serviceFactory.lang.dialog.errors.clientActionNotFound, actionId);
        onError({ title });
        return Promise.resolve();
    };

    performShowSaltEditor = ({ dialogStore, uiStore, onError }) => {
        const dialogId = dialogStore.dialog.id;
        const rootDialogId = dialogStore.getRootDialogStore().dialog.id;
        const editorProps = uiStore.getValueForUIObject(dialogId, constants.ui.SALT_EDITOR_PROPS);
        const saltEditorStore = editorProps ? editorProps.saltEditorStore : new SaltEditorStore(dialogStore);
        return saltEditorStore.refreshStore().then(() => {
            // REFACTOR: Dialogstore should not be saved in uiStore. This leads to memory leak where this dialogstore will never be garbage collected.
            uiStore.setValueForUIObject(dialogId, constants.ui.SALT_EDITOR_PROPS, { dialogStore, saltEditorStore }, false);
            uiStore.setValueForUIObject(rootDialogId, constants.ui.SALT_EDITOR_VISIBLE_DIALOG_ID, dialogId, false);
        }).catch(err => {
            onError({
                title: err.message,
                err,
            });
        });
    }

    performListToggler = () => {
        const { sessionStore, themeStore } = rootStore;
        themeStore.processListToggler(sessionStore.session);
        return Promise.resolve();
    }

    /*
    performShowSaltEditor = ({ dialogStore, uiStore, onError }) => {
        const dialogId = dialogStore.dialog.id;
        const rootDialogId = dialogStore.getRootDialogStore().dialog.id;
        const editorProps = uiStore.getValueForUIObject(dialogId, constants.ui.SALT_EDITOR_PROPS);
        const saltEditorStore = editorProps ? editorProps.saltEditorStore : new SaltEditorStore(dialogStore);
        return saltEditorStore.refreshStore().then(() => {
            uiStore.setValueForUIObject(dialogId, constants.ui.SALT_EDITOR_PROPS, { dialogStore, saltEditorStore, onError }, false);
            uiStore.setValueForUIObject(rootDialogId, constants.ui.SALT_EDITOR_VISIBLE_DIALOG_ID, dialogId, false);
        }).catch(err => {
            onError({
                title: err.message,
                err,
            });
        });
    }
    */

    performOpenImage = ({ dialogStore, uiStore }) => {
        const dialogId = dialogStore.getRootDialogStore().dialog.id;
        uiStore.setValueForUIObject(dialogId, constants.ui.OPEN_IMAGE, true, false);
        return Promise.resolve();
    }

    performClipboardCopy = (dialogStore, selectedArray) => {
        serviceFactory.clipboard.performClipboardCopy(dialogStore, selectedArray);
        return Promise.resolve();
    }

    fireSoftNavigation = ({ actionId, dialogStore, uiStore, onTransition, onError }) => {
        const dialogId = dialogStore.getRootDialogStore().dialog.id;
        if (!this.setGloballyBusy(dialogId, uiStore, { detailText: serviceFactory.lang.loadingStatus.pleaseWaitOpening })) {
            return onTransition({ dialogId: actionId })
                .then(() => {
                    this.clearGloballyBusy(dialogId, uiStore);
                })
                .catch((err) => {
                    this.clearGloballyBusy(dialogId, uiStore);
                    const title = serviceFactory.lang.formatString(serviceFactory.lang.dialog.errors.errorPerformingActionTitle, actionId);
                    onError({
                        title,
                        err,
                    });
                });
        }
        return Promise.resolve();
    };

    refreshDialog = (params) => {
        const { dialogStore, uiStore, onError } = params;
        const dialogId = dialogStore.getRootDialogStore().dialog.id;
        if (!this.setGloballyBusy(dialogId, uiStore, { loadingText: serviceFactory.lang.loadingStatus.processingRequest })) {
            return dialogStore.refresh()
                .then(() => { this.clearGloballyBusy(dialogId, uiStore); })
                .catch(err => {
                    this.clearGloballyBusy(dialogId, uiStore);
                    const title = serviceFactory.lang.formatString(serviceFactory.lang.dialog.errors.errorChangingToReadTitle);
                    onError({
                        title,
                        err,
                        type: constants.error.errorTypes.hard,
                    });
                });
        }
        return Promise.resolve();
    };

    toggleViewMode = (params) => {
        const { dialogStore } = params;
        if (dialogStore.dialog.isWriteMode) {
            return this.openReadMode(params);
        }
        return this.openWriteMode(params);
    };

    openReadMode = ({ dialogStore, uiStore, onTransition, onError }) => {
        const dialogId = dialogStore.getRootDialogStore().dialog.id;
        if (dialogStore.dialog.isWriteMode
            && !this.setGloballyBusy(dialogId, uiStore, { loadingText: serviceFactory.lang.loadingStatus.processingRequest })) {
            return dialogStore.openReadMode()
                .then(dialogOrRedirection => {
                    this.setGloballyBusy(dialogId, uiStore, { detailText: serviceFactory.lang.loadingStatus.pleaseWaitOpening });
                    return dialogOrRedirection;
                }).then(onTransition)
                .then(() => { this.clearGloballyBusy(dialogId, uiStore); })
                .catch(err => {
                    this.clearGloballyBusy(dialogId, uiStore);
                    const title = serviceFactory.lang.formatString(serviceFactory.lang.dialog.errors.errorChangingToReadTitle);
                    onError({
                        title,
                        err,
                        type: constants.error.errorTypes.hard,
                    });
                });
        }
        return Promise.resolve();
    };

    openWriteMode = ({ dialogStore, uiStore, onTransition, onError }) => {
        const dialogId = dialogStore.getRootDialogStore().dialog.id;
        if (dialogStore.dialog.isReadMode
            && !this.setGloballyBusy(dialogId, uiStore, { loadingText: serviceFactory.lang.loadingStatus.processingRequest })) {
            return dialogStore.openWriteMode()
                .then(dialogOrRedirection => {
                    this.setGloballyBusy(dialogId, uiStore, { detailText: serviceFactory.lang.loadingStatus.pleaseWaitOpening });
                    return dialogOrRedirection;
                }).then(onTransition)
                .then(() => { this.clearGloballyBusy(dialogId, uiStore); })
                .catch(err => {
                    this.clearGloballyBusy(dialogId, uiStore);
                    const title = serviceFactory.lang.formatString(serviceFactory.lang.dialog.errors.errorChangingToWriteTitle);
                    onError({
                        title,
                        err,
                        type: constants.error.errorTypes.hard,
                    });
                });
        }
        return Promise.resolve();
    };

    // Confirmation flow does not return a promise.  If the need arises to 'wait' for this method to complete,
    // we'll need to create and return a Promise here that is resolved with the completion of the action
    performActionWithConfirmation = (params) => {
        // short circuit for platforms that don't implement this
        if (!componentFactory.getPlatformSaveConfirmationPolicy()) {
            this.performAction(params);
        } else {
            const { actionId, dialogStore, uiStore } = params;
            const { settingsStore } = rootStore;
            const rootDialogId = dialogStore.getRootDialogId();
            const onCancel = () => {
                uiStore.removeValueForUIObject(rootDialogId, constants.ui.MODAL_OPEN_PROPS);
            };
            const onConfirm = () => {
                uiStore.removeValueForUIObject(rootDialogId, constants.ui.MODAL_OPEN_PROPS);
                this.performAction(params);
            };
            const message = lang.generic.saveConfirmation;
            const modalProps = { onCancel, onConfirm, message };

            let showConfirmationPrompt = false;
            const noSaveRequiredActions = settingsStore.getValue(constants.settings.NO_SAVE_REQUIRED_ACTIONS);
            if (!noSaveRequiredActions || !noSaveRequiredActions.includes(actionId)) {
                const { storesWithUnsavedChanges } = dialogStore.getRootDialogStore();
                if (storesWithUnsavedChanges.length > 0) {
                    const thisStoreHasUnsavedChanges = storesWithUnsavedChanges.includes(dialogStore);
                    if (storesWithUnsavedChanges.length === 1 && thisStoreHasUnsavedChanges) {
                        const menu = actionId && dialogStore.dialog.findMenuAt(actionId);
                        if (menu) {
                            const { isPresaveDirective } = menu;
                            showConfirmationPrompt = !isPresaveDirective;
                        }
                    } else {
                        showConfirmationPrompt = true;
                    }
                }
            }
            if (showConfirmationPrompt) {
                uiStore.setValueForUIObject(rootDialogId, constants.ui.MODAL_OPEN_PROPS, modalProps);
            } else {
                this.performAction(params);
            }
        }
    }

    performAction = (params) => {
        const { actionId, selectedArray, dialogStore, uiStore, onTransition, onError, modifiers } = params;
        const dialogId = dialogStore.getRootDialogStore().dialog.id;
        this.saveScrollPosition(uiStore, dialogId);
        if (engineConstants.isClientAction(actionId)) {
            return this.performClientAction(params);
        }
        const menu = actionId && dialogStore.dialog.findMenuAt(actionId);

        // The dialogStore.performMenuAction expects EITHER targets for a list or nothing for an editor.
        // We provide both as named parameters and it takes what it wants.
        let targets = null;
        if (selectedArray) {
            targets = selectedArray;
        }
        if (actionId && !this.setGloballyBusy(dialogId, uiStore, { menu })) {
            return dialogStore.performMenuAction({
                actionId,
                targets,
            })
                .then((r) => {
                    this.setGloballyBusy(dialogId, uiStore, { detailText: serviceFactory.lang.loadingStatus.pleaseWaitOpening });
                    return r;
                })
                .then(r => {
                    // TODO: https://dev.azure.com/HexagonXalt/Xalt%20Mobility/_workitems/edit/19344
                    // This was added due to the bug above as a temporary solution until the redirection
                    // changes can be made to direct the client to perform a similar action. Once that is in
                    // place and implemented, we need to remove this. It is a bad idea to have this kind of
                    // logic programmed in the client and not allow users to override.
                    let modifiersHijack = modifiers;
                    const foundHijectedAction = tempHijackedRoutes.find(name => name === actionId);
                    if (foundHijectedAction) {
                        const { transitionModifiers: { OPEN_IN_TAB } } = constants;
                        if (modifiersHijack) {
                            modifiersHijack[OPEN_IN_TAB] = true;
                        } else {
                            modifiersHijack = {
                                OPEN_IN_TAB: true,
                            };
                        }
                    }
                    /* eslint-disable no-unused-expressions */
                    onTransition(r, modifiersHijack)
                        ?.finally(() => {
                            this.clearGloballyBusy(dialogId, uiStore);
                        });
                })
                .catch((err) => {
                    this.clearGloballyBusy(dialogId, uiStore);
                    const title = serviceFactory.lang.formatString(serviceFactory.lang.dialog.errors.errorPerformingActionTitle, actionId);
                    if (err.message === serviceFactory.lang.errors.passwordNotSetMessage) {
                        // Invoke settings action to allow setting of password.
                        this.performAction({ ...params, actionId: 'settings' });
                    } else if (err.type === 'InvalidSession') {
                        return Promise.reject(err);
                    } else {
                        onError({
                            title,
                            err,
                        });
                    }
                    return {
                        title,
                        err,
                    };
                });
        }
        return Promise.resolve();
    };
    performDefaultAction = ({ objectId, dialogStore, uiStore, onTransition, onError, modifiers }) => {
        const { defaultActionId } = dialogStore.dialog;
        return this.performAction({
            actionId: defaultActionId,
            selectedArray: [ objectId ],
            dialogStore,
            uiStore,
            onTransition,
            onError,
            modifiers,
        });
    };
    performWrite = ({ dialogStore, uiStore, onTransition, onError }) => {
        const dialogId = dialogStore.getRootDialogStore().dialog.id;
        if (!this.setGloballyBusy(dialogId, uiStore, { loadingText: serviceFactory.lang.loadingStatus.processingRequest })) {
            return dialogStore.writeRecord()
                .then((r) => {
                    this.setGloballyBusy(dialogId, uiStore, { detailText: serviceFactory.lang.loadingStatus.pleaseWaitOpening });
                    return r;
                })
                .then(onTransition)
                .then(() => {
                    this.clearGloballyBusy(dialogId, uiStore);
                })
                .catch((err) => {
                    this.clearGloballyBusy(dialogId, uiStore);
                    onError({
                        title: serviceFactory.lang.generic.error,
                        err,
                        id: dialogId,
                    });
                });
        }
        return Promise.resolve();
    };

    performCancel = ({ dialogStore, uiStore, onTransition, onError }) => {
        return this.openReadMode({ dialogStore, uiStore, onTransition, onError });
    };

    performMoreRecords = ({ dialogStore, title, onError }) => {
        if (dialogStore.hasMoreRecords() && !dialogStore.queryInProgress) {
            return dialogStore.moreRecords()
                .catch((err) => {
                    onError({
                        title,
                        err,
                        type: constants.error.errorTypes.hard,
                    });
                });
        }
        return Promise.resolve();
    };

    performReadRecord = ({ dialogStore, title, onError }) => {
        return dialogStore.readRecord()
            .catch((err) => {
                onError({
                    title,
                    err,
                    type: constants.error.errorTypes.hard,
                });
            });
    };

    performRefreshList = ({ dialogStore, title, onError }) => {
        return dialogStore.refreshList()
            .catch((err) => {
                onError({
                    title,
                    err,
                    type: constants.error.errorTypes.hard,
                });
            });
    };

    performResetList = ({ dialogStore, title, onError, recordId }) => {
        return dialogStore.resetList(recordId)
            .catch((err) => {
                onError({
                    title,
                    err,
                    type: constants.error.errorTypes.hard,
                });
            });
    };

    performSearch = ({ dialogStore: listDialogStore, onError }) => {
        const onCatchError = (err) => {
            listDialogStore.setSearchFormOpen(false);
            const title = serviceFactory.lang.formatString(serviceFactory.lang.dialog.errors.failedToOpenSearchDialog, listDialogStore.dialog.id);
            onError({
                title,
                err,
                type: constants.error.errorTypes.hard,
            });
        };

        return searchController.openSearchForm({ listDialogStore, onError })
            .then(searchDialogStore => {
                return searchDialogStore;
            })
            .catch(onCatchError);
    };

    /**
     * Let the pagecontroller initiate the sorting modal flow
     */
     performSort = ({ dialogStore: listDialogStore, onError }) => {
         const onCatchError = (err) => {
             listDialogStore.setSortModalOpen(false);
             const title = serviceFactory.lang.formatString(serviceFactory.lang.dialog.errors.failedToOpenSortModal, listDialogStore.dialog.id);
             onError({
                 title,
                 err,
                 type: constants.error.errorTypes.hard,
             });
         };

         return searchController.openSortModal({ listDialogStore, onError })
             .then(searchDialogStore => {
                 return searchDialogStore;
             })
             .catch(onCatchError);
     };

    performCalculateStatistics = ({ dialogStore: listDialogStore, onError }) => {
        return listDialogStore.getOrOpenCalculateStatisticsFormStore()
            .catch((err) => {
                const title = serviceFactory.lang.formatString(serviceFactory.lang.dialog.errors.failedToFetchCalculateStatistics, listDialogStore.dialog.id);
                onError({
                    title,
                    err,
                    type: constants.error.errorTypes.hard,
                });
            });
    };

    performSelect = (params) => {
        const newParams = { ...params };
        newParams.actionId = 'getFindSelection'; // Send this action ID to the dialog server
        return this.performAction(newParams);
    }

    toggleExpandCollapse(childDialogStore) {
        const formDialogStore = childDialogStore.getRootDialogStore();
        if (formDialogStore) {
            if (formDialogStore.expandedDialogId) {
                formDialogStore.setExpandedDialogId(null);
            } else {
                formDialogStore.setExpandedDialogId(childDialogStore.dialog.id);
            }
        }
    }

    toggleSelectionMode(listStore, uiStore) {
        const newMode = !this.getSelectionMode(listStore, uiStore);
        this.setSelectionMode(uiStore, listStore, newMode);
        const { dialog } = listStore;
        if (newMode) {
            // If we are in select mode, reflect that on the tap tool
            uiStore.setValueForUIObject(dialog.id, constants.ui.LIST_OVERRIDE_DEFAULT_ACTION_ID, engineConstants.action.clientActions.select);
        } else {
            // If not in select mode, revert the tap tool action.
            uiStore.setValueForUIObject(dialog.id, constants.ui.LIST_OVERRIDE_DEFAULT_ACTION_ID, null);
        }
    }


    logout = () => {
        const { sessionStore } = rootStore;
        return sessionStore.logout().then(result => {
            if (result && result.postLogoutURL) {
                return serviceFactory.oAuth.openInCustomTab(result.postLogoutURL, result.additionalPostLogoutURLs);
            }
            return undefined;
        });
    };

    performOpenViewWithConfirmation(params) {
        if (!componentFactory.getPlatformSaveConfirmationPolicy()) {
            this.openView(params);
        } else {
            const { dialogStore, uiStore } = params;
            const rootDialogStore = dialogStore.getRootDialogStore();
            const dialogId = dialogStore.getRootDialogStore().dialog.id;
            let showConfirmationPrompt = false;
            const onConfirm = () => {
                uiStore.removeValueForUIObject(dialogId, constants.ui.MODAL_OPEN_PROPS);
            };

            if (rootDialogStore.childDialogStores.length > 0) {
                rootDialogStore.childDialogStores.forEach((childStore) => {
                    if (childStore.dialog.viewMode === 'WRITE') {
                        showConfirmationPrompt = true;
                    }
                });
            }

            const message = lang.generic.unsavedPrompt;
            const yesText = lang.generic.ok;
            const modalProps = { onConfirm, onCancel: onConfirm, message, yesText };

            if (showConfirmationPrompt) {
                uiStore.setValueForUIObject(dialogId, constants.ui.MODAL_OPEN_PROPS, modalProps);
            } else {
                this.openView(params);
            }
        }
    }


    openView({ viewDescriptor, dialogStore, uiStore, onTransition, onError }) {
        const dialogId = dialogStore.getRootDialogStore().dialog.id;
        if (viewDescriptor
            && !this.setGloballyBusy(dialogId, uiStore, { loadingText: serviceFactory.lang.loadingStatus.processingRequest })) {
            return dialogStore.openView(viewDescriptor)
                .then(dialogOrRedirection => {
                    this.setGloballyBusy(dialogId, uiStore, { detailText: serviceFactory.lang.loadingStatus.pleaseWaitOpening });
                    return dialogOrRedirection;
                }).then(onTransition)
                .then(() => { this.clearGloballyBusy(dialogId, uiStore); })
                .catch(err => {
                    this.clearGloballyBusy(dialogId, uiStore);
                    const title = serviceFactory.lang.formatString(serviceFactory.lang.dialog.errors.errorChangingView);
                    onError({
                        title,
                        err,
                        type: constants.error.errorTypes.hard,
                    });
                });
        }
        return Promise.resolve();
    }

    saveScrollPosition(uiStore, dialogId) {
        // logic to store the scroll position of an Section and applicable to XHA
        if (BuildGlobals.isXHA()) {
            const scrollableElement = document.querySelector(`div[data-scroll-identifier=${constants.ui.SCROLL_CONTAINER}]`);

            if (scrollableElement) {
                uiStore.setValueForUIObject(dialogId, constants.ui.SCROLL_POSITION, scrollableElement.scrollTop);
            }
        }
    }
}

const pageController = new PageController();
export default pageController;
// Debug Proxy Example
// export default createDebugProxy( { target: pageController, logLevel: LogLevel.TRACE, logConfig: Log.green, loggerArgs: [ 'page:controller' ], },);
