// Core component styles
const coreStyles = {
    container: {
        display: 'inline-flex',
    },

    thumb: {
    },

    thumbContainer: {
    },

    thumbSelected: {
    },

    track: {
    },

    trackContainer: {
    },

    trackSelected: {
    },
};

/**
 * Combines coreStyles and contextStyles
 * @param {Object} contextStyles
 */
const getStyles = (contextStyles = {}) => {
    return {
        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        thumb: {
            ...coreStyles.thumb,
            ...contextStyles.thumb,
        },

        thumbContainer: {
            ...coreStyles.thumbContainer,
            ...contextStyles.thumbContainer,

            // For a 'checked' switch
            '&.Mui-checked': {
                ...contextStyles.thumbContainerSelected,

                // Target the track
                '& + $track': {
                    ...coreStyles.trackSelected,
                    ...contextStyles.trackSelected,
                },

                // Target the thumb
                '& $thumb': {
                    ...coreStyles.thumbSelected,
                    ...contextStyles.thumbSelected,
                },
            },
        },

        track: {
            ...coreStyles.track,
            ...contextStyles.track,
        },

        trackContainer: {
            ...coreStyles.trackContainer,
            ...contextStyles.trackContainer,
        },
    };
};

export default getStyles;
