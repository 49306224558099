import SimpleStringAttribute from '../SimpleStringAttribute';
import SimpleXStyleAttribute from '../SimpleXStyleAttribute';

export default class MarkupSelection extends SimpleXStyleAttribute {
    exportTo(context, styleSet) {
        if (SimpleStringAttribute.hasConstantRef(this.value)) {
            SimpleStringAttribute.reportExportIssue(this.overrideName, this.value);
        } else if (this.value === 'true') {
            const { xStyle } = styleSet;
            xStyle['list-selected-underlay-color'] = false;
            // xStyle['disable-item-taps'] = true;
        }
    }
}
