/**
 * *****************************************************
 */
export class DateTimeValue {
    constructor(dateObj) {
        this.dateObj = dateObj;
    }
    toString() {
        this.dateObj.toISOString();
    }
}
