/*       */

import FormUtil from '../FormUtil';
import ModelCache from '../ModelCache';

export default class AbstractModel {
    json ;
    constructor(json) {
        this.json = json;
        this.cache = new ModelCache();
    }

    get id() {
        return this.valueAt('Id');
    }

    get isFillParent() { return !!this.valueAt('Layout.FillParent'); }

    get isFillParentHeight() {
        const test = this.valueAt('Layout.Size.Height');
        return this.isFillParent || !!(test && test.FillParent);
    }
    get isFillParentWidth() {
        const test = this.valueAt('Layout.Size.Width');
        return this.isFillParent || !!(test && test.FillParent);
    }
    get layoutSizeWidth() { return this.floatAt('Layout.Size.Width'); }
    get layoutSizeWidths() { return this.floatArrayAt('Layout.Size.Width'); }
    get layoutSizeHeight() { return this.floatAt('Layout.Size.Height'); }
    get layoutSizeHeights() { return this.floatArrayAt('Layout.Size.Height'); }
    get layoutOriginX() { return this.floatAt('Layout.Origin.X'); }
    get layoutOriginY() { return this.floatAt('Layout.Origin.Y'); }
    get paddingTop() { return this.floatAt('Padding.Top'); }
    get paddingLeft() { return this.floatAt('Padding.Left'); }
    get paddingBottom() { return this.floatAt('Padding.Bottom'); }
    get paddingRight() { return this.floatAt('Padding.Right'); }
    get defaultScale() { return this.floatAt('Scale.Default'); }
    get minimumScale() { return this.floatAt('Scale.Minimum'); }
    get maximumScale() { return this.floatAt('Scale.Maximum'); }

    allChildren() {
        const children = this.arrayAt('Children');
        // Handle an empty tag <Children/> results in a one child array with {}... weed that out.
        if (children.length === 1 && Object.keys(children[0]).length === 0) {
            return [];
        }
        return children;
    }
    arrayAt(expression) {
        return FormUtil.arrayAt(this.json, expression);
    }
    childrenOfType(type) {
        return this.allChildren().filter(e => FormUtil.nameOf(e) === type);
    }
    color(name) {
        const r = this.valueAt(`${name}.Red`);
        const g = this.valueAt(`${name}.Green`);
        const b = this.valueAt(`${name}.Blue`);
        const a = this.valueAt(`${name}.Alpha`);
        return (r && `rgba(${r}, ${g}, ${b}, ${a})`) || undefined;
    }
    floatAt(expression, defaultValue = undefined) {
        const result = parseFloat(this.valueAt(expression));
        return Number.isNaN(result) ? defaultValue : result;
    }
    floatArrayAt(expression, parentSize = 1) {
        return this.arrayAt(expression).map(m => {
            let result;
            if (m['#name'] === 'PercentOfParent') {
                result = parseFloat(m._) * 0.01 * parentSize;
            } else if (m.PercentOfParent) {
                result = parseFloat(m.PercentOfParent[0]) * 0.01 * parentSize;
            } else {
                result = parseFloat(m);
            }
            return Number.isNaN(result) ? undefined : result;
        });
    }
    valueAt(expression) {
        return FormUtil.valueAt(this.json, expression);
    }

    updateContext = (context) => {
        return context.newContext((this));
    }
}
