// Core component styles
const coreStyles = {
    button: {
    },

    buttonText: {
    },
};

/**
 * Helper method to get styles for this component
 * @param {Object} contextStyles - Styles for this component
 */
const getStyles = (contextStyles = {}) => {
    return {
        button: {
            ...coreStyles.button,
            ...contextStyles.button,
        },

        buttonText: {
            ...coreStyles.buttonText,
            ...contextStyles.buttonText,
        },
    };
};

export default getStyles;