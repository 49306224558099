import React from 'react';
import PropTypes from 'prop-types';
import { default as ChartWrapper } from '../ChartWrapper/ChartWrapper';

// Import styles
import getStyles from './GaugeChart.styles';

/**
 * Gauge charts use needles to show information as a reading on a dial.
 * @see https://echarts.apache.org/examples/en/editor.html?c=gauge
 */
const GaugeChart = (props) => {
    const {
        animation,
        axisLabelColor,
        axisLineColors,
        axisLabelFormatter,
        axisLabelSize,
        contextStyles,
        gaugeBackgroundImage,
        gaugeName,
        gaugeNameColor,
        gaugeNameHeight,
        gaugeNameHorizontalPosition,
        gaugeNameTextSize,
        gaugeNameVerticalPosition,
        gaugeNameWidth,
        gaugeValue,
        gaugeValueColor,
        gaugeValueFormatter,
        gaugeValueHeight,
        gaugeValueHorizontalPosition,
        gaugeValueTextSize,
        gaugeValueVerticalPosition,
        gaugeValueWidth,
        minValue,
        maxValue,
        title,
        startAngle,
        endAngle,
        showDetail,
        pointerWidth,
        pointerLength,
        showPrimaryTicks,
        primaryTickIntervals,
        primaryAxisTickLength,
        showSecondaryTicks,
        secondaryTickIntervals,
        secondaryAxisTickLength,
        radius,
        reverse,
        testID,
    } = props ;

    const styles = getStyles(contextStyles);
    const {
        container,
        axisLine,
        primaryAxisTick,
        secondaryAxisTick,
        pointer,
    } = styles;

    // Build gauge options
    const gaugeOptions = {
        type: 'gauge',
        startAngle,
        endAngle,
        min: minValue,
        max: maxValue,
        radius,

        // Axis line
        axisLine: {
            lineStyle: {
                // Axis line styles
                ...axisLine,
            },
        },

        // Set primary tick intervals
        splitNumber: primaryTickIntervals,

        // Primary ticks
        splitLine: {
            show: showPrimaryTicks,

            // // Apply styles
            lineStyle: primaryAxisTick,
        },

        // Secondary ticks
        axisTick: {
            show: showSecondaryTicks,

            // Set secondary tick intervals
            splitNumber: secondaryTickIntervals,

            // Apply styles for the secondary tick
            lineStyle: secondaryAxisTick,
        },

        // Set Length and Width to Pointer
        pointer: {
            width: pointerWidth,
            length: pointerLength,
        },

        // Styles for the pointer
        itemStyle: pointer,

        // Apply styles for Axis label
        axisLabel: {
            formatter: axisLabelFormatter,
            color: axisLabelColor,
            fontSize: axisLabelSize,
        },

        // Value text
        detail: {
            show: showDetail,

            // Apply styles for the value text
            textStyle: {
                color: gaugeValueColor,
                fontSize: gaugeValueTextSize,
            },

            // Format string for the value text
            formatter: gaugeValueFormatter ? gaugeValueFormatter.replace(/\$\{[0-9]\}/g, gaugeValue) : gaugeValue,

            /**
             * Position of the value text relative to the center of gauge chart.
             * 0 and '40%' are the default values provided by gauge chart
             */
            offsetCenter: [
                gaugeValueHorizontalPosition || 0,
                gaugeValueVerticalPosition || '40%',
            ],
            width: gaugeValueWidth,
            height: gaugeValueHeight,

            // Width and height will work only when rich specified
            rich: {},
        },

        // Title text
        title: {
            // Apply styles for the title text
            textStyle: {
                color: gaugeNameColor,
                fontSize: gaugeNameTextSize,
            },
            /**
             * Position of the title text relative to the center of gauge chart.
             * 0 and '-40%' are the default values provided by gauge chart.
             */
            offsetCenter: [
                gaugeNameHorizontalPosition || 0,
                gaugeNameVerticalPosition || '-40%',
            ],
            width: gaugeNameWidth,
            height: gaugeNameHeight,

            // Width and height will work only when rich specified
            rich: {},
        },

        // Gauge value and title
        data: [
            {
                value: gaugeValue,
                name: gaugeName,
            },
        ],
    };

    // Color gradients for the axis line
    if (axisLineColors) {
        gaugeOptions.axisLine.color = axisLineColors;
    }

    // Set primary ticks length
    if (primaryAxisTickLength) {
        gaugeOptions.splitLine.length = primaryAxisTickLength;
    }

    // Set secondary ticks length
    if (secondaryAxisTickLength) {
        gaugeOptions.axisTick.length = secondaryAxisTickLength;
    }

    // Counter clockwise
    if (reverse) {
        gaugeOptions.clockwise = false;
        gaugeOptions.min = maxValue;
        gaugeOptions.max = minValue;
    }

    // Build chart options
    const chartOptions = {
        animation,
        backgroundColor: 'transparent',
        title: {
            text: title,
        },
        series: [ gaugeOptions ],
    };

    // Generate props
    const chartProps = {
        contextStyles: {
            container,
        },
        option: chartOptions,
    };
    if (testID) { chartProps.testID = testID; }

    let content = <ChartWrapper { ...chartProps } />;

    // Wrap the content within div with a background image if it is available.
    if (gaugeBackgroundImage) {
        const imageStyle = {
            backgroundImage: `url(${gaugeBackgroundImage})`,
            height: '100%',
            width: '100%',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        };

        content = (
            <div
                style={ imageStyle }>
                { content }
            </div>
        );
    }

    return content;
};

GaugeChart.propTypes = {
    /** Enables/disables chart animations */
    animation: PropTypes.bool,

    /** Styles for the component */
    contextStyles: PropTypes.shape({
        /** Styles for the container */
        container: PropTypes.object,

        /**
         * Styles for the axis line. Use all the styles except color. Color is available as separate prop.
         * @see https://echarts.apache.org/en/option.html#series-gauge.axisLine for all the available options for axis line.
         */
        axisLine: PropTypes.object,

        /**
         * Styles for the primary axis tick
         * @see https://echarts.apache.org/en/option.html#series-gauge.splitLine.lineStyle for all the available styles
         */
        primaryAxisTick: PropTypes.object,

        /**
         * Styles for the secondary axis tick
         * @see https://echarts.apache.org/en/option.html#series-gauge.axisTick.lineStyle for all the available styles
         */
        secondaryAxisTick: PropTypes.object,

        /**
         * Styles for the pointer
         * @see https://echarts.apache.org/en/option.html#series-gauge.itemStyle for all the available styles for the pointer
         */
        pointer: PropTypes.object,
    }),

    /** Title text for the chart */
    title: PropTypes.string,

    /** Starting angle of gauge chart */
    startAngle: PropTypes.number,

    /** End angle of gauge chart */
    endAngle: PropTypes.number,

    /** Minimum value of the gauge chart */
    minValue: PropTypes.number,

    /** Maximum value of the gauge chart */
    maxValue: PropTypes.number,

    /** Whether to show the primary split line */
    showPrimaryTicks: PropTypes.bool,

    /** The number of split segments of gauge chart scale. */
    primaryTickIntervals: PropTypes.number,

    /** Length of Primary Axis tick */
    primaryAxisTickLength: PropTypes.number,

    /** The width of pointer. */
    pointerWidth: PropTypes.number,

    /** The length of pointer */
    pointerLength: PropTypes.oneOfType([
        /** Absolute value of the pointer */
        PropTypes.number,

        /** Percentage relative to radius */
        PropTypes.string,
    ]),

    /**
     * Colors for the axis line
     * The axis line of gauge chart can be divided to several segments in different colors.
     * The end position and color of each segment can be expressed by an array.
     * Ex: [[0.2, '#91c7ae'], [0.8, '#63869e'], [1, '#c23531']]
     */
    axisLineColors: PropTypes.array,

    /** Counter clockwise direction */
    reverse: PropTypes.bool,

    /** Id used for testing */
    testID: PropTypes.string,

    /** Color for the axis label text */
    axisLabelColor: PropTypes.string,

    /** Font size of axis label */
    axisLabelSize: PropTypes.number,

    /** Format string for axis label */
    axisLabelFormatter: PropTypes.string,

    /** Whether to show the current value details below the indicator */
    showDetail: PropTypes.bool,

    /** Current value of gauge chart */
    gaugeValue: PropTypes.number.isRequired,

    /** Width of the current value text block */
    gaugeValueWidth: PropTypes.oneOfType([
        /** Width of the content block */
        PropTypes.number,

        /** Percentage of the content width */
        PropTypes.string,
    ]),

    /** Height of the current value text block */
    gaugeValueHeight: PropTypes.oneOfType([
        /** Height of the content block */
        PropTypes.number,

        /** Percentage of the content height */
        PropTypes.string,
    ]),

    /** Font color of the current value text */
    gaugeValueColor: PropTypes.string,

    /** Format string for the current value text */
    gaugeValueFormatter: PropTypes.string,

    /** The horizontal position relative to the center of gauge chart */
    gaugeValueHorizontalPosition: PropTypes.oneOfType([
        /** The offset position relative to the center of gauge chart. */
        PropTypes.number,

        /** Percentage relative to the radius of gauge chart. */
        PropTypes.string,
    ]),

    /** The vertical position relative to the center of gauge chart */
    gaugeValueVerticalPosition: PropTypes.oneOfType([
        /** The offset position relative to the center of gauge chart. */
        PropTypes.number,

        /** Percentage relative to the radius of gauge chart. */
        PropTypes.string,
    ]),

    /** Font size of the current value text */
    gaugeValueTextSize: PropTypes.number,

    /** Name of the gauge chart that needs to be displayed above the pointer */
    gaugeName: PropTypes.string,

    /** Width of the name of the gauge chart */
    gaugeNameWidth: PropTypes.oneOfType([
        /** Width of the content block */
        PropTypes.number,

        /** Percentage of the content width */
        PropTypes.string,
    ]),

    /** Height of the name of the gauge chart */
    gaugeNameHeight: PropTypes.oneOfType([
        /** Height of the content block */
        PropTypes.number,

        /** Percentage of the content height */
        PropTypes.string,
    ]),

    /** The horizontal position of the title text relative to the center of gauge chart */
    gaugeNameHorizontalPosition: PropTypes.oneOfType([
        /** The offset position relative to the center of gauge chart. */
        PropTypes.number,

        /** Percentage relative to the radius of gauge chart. */
        PropTypes.string,
    ]),

    /** The vertical position of the title text relative to the center of gauge chart */
    gaugeNameVerticalPosition: PropTypes.oneOfType([
        /** The offset position relative to the center of gauge chart. */
        PropTypes.number,

        /** Percentage relative to the radius of gauge chart. */
        PropTypes.string,
    ]),

    /** Font color of the title text */
    gaugeNameColor: PropTypes.string,

    /** Font size of the title text */
    gaugeNameTextSize: PropTypes.number,

    /** Background image url */
    gaugeBackgroundImage: PropTypes.string,

    /** Background image mode */
    gaugeBackgroundImageMode: PropTypes.oneOf([
        'cover',
        'contain',
        'stretch',
        'repeat',
        'center',
    ]),

    /** Radius of Guage chart component in a container */
    radius: PropTypes.string,
};

GaugeChart.defaultProps = {
    animation: true,
    contextStyles: {},
    startAngle: 225,
    endAngle: -45,
    minValue: 0,
    maxValue: 100,
    primaryTickIntervals: 10,
    secondaryTickIntervals: 5,
    showPrimaryTicks: true,
    showSecondaryTicks: true,
    showDetail: true,
    pointerLength: '100%',
    radius: '75%',
};

export default GaugeChart;
