import { Dialog, TypeNames } from 'cv-dialog-sdk';
import {
    CalendarStore,
    DetailsDialogStore,
    FormDialogStore,
    ListDialogStore,
    SearchDialogStore,
    GraphDialogStore,
    ListPageDialogStore,
} from 'cv-react-core';
import SaltPage from '../pages/SaltPage';

const storeMap = {};
storeMap[TypeNames.FormTypeName] = FormDialogStore;
storeMap[TypeNames.PowerBITypeName] = FormDialogStore;
storeMap[TypeNames.ListTypeName] = ListDialogStore;
storeMap[TypeNames.MapTypeName] = ListDialogStore;
storeMap[TypeNames.CalendarTypeName] = CalendarStore;
storeMap[TypeNames.GraphTypeName] = GraphDialogStore;
storeMap[TypeNames.DetailsTypeName] = DetailsDialogStore;
storeMap[TypeNames.BarcodeScanTypeName] = DetailsDialogStore;
storeMap[TypeNames.GpsReadingTypeName] = DetailsDialogStore;
storeMap[TypeNames.MapLocationTypeName] = DetailsDialogStore;
storeMap[TypeNames.ImagePickerTypeName] = ListDialogStore;
storeMap[TypeNames.VizTypeName] = DetailsDialogStore;
storeMap[TypeNames.VizGraphicIdTypeName] = DetailsDialogStore;
storeMap[TypeNames.NFCScanTypeName] = DetailsDialogStore;

/* eslint-disable class-methods-use-this */
class SaltProvider {
    provideDialogStoreInstance(dialog, sessionStore, parentDialogStore) {
        if (Dialog.isSearchDialog(dialog)) {
            return new SearchDialogStore(sessionStore, parentDialogStore);
        }
        // Adding this check until a paging type can be defined.
        if (Dialog.isListPageDialog(dialog)) {
            return new ListPageDialogStore(sessionStore, parentDialogStore);
        }
        const { type } = dialog.view;
        if (typeof storeMap[type] === 'undefined') {
            throw Error(`defaultProvider::provideDialogStore No type found for view ${type} in storeMap`);
        }
        return new storeMap[type](sessionStore, parentDialogStore);
    }

    provideDialogPageComponent() {
        return SaltPage;
    }
}

const saltProvider = new SaltProvider();
export default saltProvider;
