import View from './View';
import Property from './Property';
import Action from './Action';
import Menu from './Menu';
import Ref from './Ref';
import Box from './Box';
import Calendar from './Calendar';
import Image from './Image';
import Text from './Text';
import Label from './Label';
import Iterator from './Iterator';
import When from './When';
import Table from './Table';
import List from './List';
import Layout from './Layout';
import Panel from './Panel';
import SearchSort from './SearchSort';
import SortForm from './SortForm';
import Gps from './Gps';
import Salt from './Salt';
import Viz from './Viz';
import PowerBI from './PowerBI';
import Graph from './Graph';
import Barcode from './Barcode';
import Map from './Map';
import QuickSearch from './QuickSearch';
import ImagePicker from './ImagePicker';
import SaltEditor from './SaltEditor';
import NFC from './NFC';
import FieldAction from './properties/FieldAction';
import ViewRef from './ViewRef';
import AvailableViews from './AvailableViews';
import Title from './Title';
import QuickActions from './QuickActions';

/**
 *       _   _         _                  _       __            _                ___                                             _
 *      /_\ | |__  ___| |_ _ __ __ _  ___| |_    /__\ __   __ _(_)_ __   ___    / __\___  _ __ ___  _ __   ___  _ __   ___ _ __ | |_ ___
 *     //_\\| '_ \/ __| __| '__/ _` |/ __| __|  /_\| '_ \ / _` | | '_ \ / _ \  / /  / _ \| '_ ` _ \| '_ \ / _ \| '_ \ / _ \ '_ \| __/ __|
 *    /  _  \ |_) \__ \ |_| | | (_| | (__| |_  //__| | | | (_| | | | | |  __/ / /__| (_) | | | | | | |_) | (_) | | | |  __/ | | | |_\__ \
 *    \_/ \_/_.__/|___/\__|_|  \__,_|\___|\__| \__/|_| |_|\__, |_|_| |_|\___| \____/\___/|_| |_| |_| .__/ \___/|_| |_|\___|_| |_|\__|___/
 *                                                        |___/                                    |_|
 *    Abstract Engine Components are registered here
 */


const engineAbstractComponentProvider = {
    view: View,
    viewRef: ViewRef,
    property: Property,
    action: Action,
    calendar: Calendar,
    menu: Menu,
    gps: Gps,
    ref: Ref,
    box: Box,
    image: Image,
    text: Text,
    label: Label,
    iterator: Iterator,
    when: When,
    table: Table,
    list: List,
    salt: Salt,
    layout: Layout,
    searchSort: SearchSort,
    sortForm: SortForm,
    panel: Panel,
    viz: Viz,
    powerbi: PowerBI,
    graph: Graph,
    barcode: Barcode,
    map: Map,
    quickSearch: QuickSearch,
    imagePicker: ImagePicker,
    saltEditor: SaltEditor,
    nfc: NFC,
    fieldAction: FieldAction,
    availableViews: AvailableViews,
    title: Title,
    quickActions: QuickActions,
};
export default engineAbstractComponentProvider;
