import { Log } from 'cv-dialog-sdk';
import React from 'react';

import { LogLabels } from '../utilities/logUtils';
import EngineContext from './EngineContext';
import generator from './generator';
import SaltError from './SaltError';
import SaltErrorBoundary from './SaltErrorBoundary';

// @TODO use state or store to prevent generating the salt tree more than once
// Logger Example
const logger = Log.debug.bind(Log, LogLabels.SALT);
const logColor = Log.magenta;
class Engine {
    render(tree, engineParams) {
        logger('Salt Tree:', tree, logColor);
        // logger('Salt Tree:', Log.prettyPrint(tree), logColor);
        try {
            const renderedTree = generator.generate(tree);
            return (
                <EngineContext engineParams={ engineParams }>
                    <SaltErrorBoundary>
                        {renderedTree}
                    </SaltErrorBoundary>
                </EngineContext>
            );
        } catch (e) {
            const message = 'Salt generation failed with:';
            Log.error(message, e);
            return (
                <SaltError
                    message={ message }
                    error={ e } />
            );
        }
    }
}

const engine = new Engine();
export default engine;
