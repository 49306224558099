import { rootStore } from 'cv-react-core';

const getStyles = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();
    return {
        closeIcon: {
            color: theme.buttonPrimaryBackgroundColor,
        },
        closeIconButton: {
            padding: '0px',
        },
        infoIcon: {
            color: '#FF9500',
        },
        logout: {
            actions: {
                padding: '0px',
            },
            content: {
                textAlign: 'center',
                marginBottom: '50px',
                padding: '0px 12px',
            },
            divider: {
                display: 'none',
            },
            header: {
                padding: '0px',
            },
            modal: {
                borderRadius: '10px',
                boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.45)',
                boxSizing: 'border-box',
                padding: '20px',
                width: '360px',
            },
            text: {
                lineHeight: '26px',
                ...theme.fonts.logoutPrompt,
            },
            title: {
            },
        },
        titleContainer: {
            display: 'flex',
            flexDirection: 'row-reverse',
        },
    };
};

export default getStyles;
