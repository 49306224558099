import { RecordVisitor } from "../proxy/RecordVisitor";
/**
 *
 */
export class SelectedOfflineItemsVisitor extends RecordVisitor {
    constructor(value) {
        super(value);
    }
    // --- State Management Helpers --- //
    static createFromOfflineItemsVisitor(offlineItemsVisitor, status, elementName) {
        const extractedData = offlineItemsVisitor.split('@');
        const id = extractedData[extractedData.length - 1];
        const selectedWorkPackageState = new SelectedOfflineItemsVisitor({
            id,
            properties: [],
            annotations: [],
            type: "hxgn.api.dialog.Record"
        });
        selectedWorkPackageState.setId(id);
        selectedWorkPackageState.setName(elementName ? elementName : id);
        selectedWorkPackageState.setDownloadStatus(status);
        return selectedWorkPackageState;
    }
    // --- State Management --- //
    creationDate() {
        return this.visitPropertyValueAt(SelectedOfflineItemsVisitor.CREATION_DATE_PROPERTY_NAME);
    }
    setCreationDate(value) {
        this.visitAndSetPropertyValueAt(SelectedOfflineItemsVisitor.CREATION_DATE_PROPERTY_NAME, value);
    }
    description() {
        return this.visitPropertyValueAt(SelectedOfflineItemsVisitor.DESCRIPTION_PROPERTY_NAME);
    }
    setDescription(value) {
        this.visitAndSetPropertyValueAt(SelectedOfflineItemsVisitor.DESCRIPTION_PROPERTY_NAME, value);
    }
    disciplines() {
        return this.visitPropertyValueAt(SelectedOfflineItemsVisitor.DISCIPLINES_PROPERTY_NAME);
    }
    setDisciplines(value) {
        this.visitAndSetPropertyValueAt(SelectedOfflineItemsVisitor.DISCIPLINES_PROPERTY_NAME, value);
    }
    id() {
        return this.visitPropertyValueAt(SelectedOfflineItemsVisitor.ID_PROPERTY_NAME);
    }
    setId(value) {
        this.visitAndSetPropertyValueAt(SelectedOfflineItemsVisitor.ID_PROPERTY_NAME, value);
    }
    lastUpdateDate() {
        return this.visitPropertyValueAt(SelectedOfflineItemsVisitor.LAST_UPDATE_DATE_PROPERTY_NAME);
    }
    setLastUpdateDate(value) {
        this.visitAndSetPropertyValueAt(SelectedOfflineItemsVisitor.LAST_UPDATE_DATE_PROPERTY_NAME, value);
    }
    name() {
        return this.visitPropertyValueAt(SelectedOfflineItemsVisitor.NAME_PROPERTY_NAME);
    }
    downloaded() {
        return this.visitPropertyValueAt(SelectedOfflineItemsVisitor.DOWNLOAD_STATUS_PROPERTY_NAME);
    }
    setName(value) {
        this.visitAndSetPropertyValueAt(SelectedOfflineItemsVisitor.NAME_PROPERTY_NAME, value);
    }
    setDownloadStatus(value) {
        this.visitAndSetPropertyValueAt(SelectedOfflineItemsVisitor.DOWNLOAD_STATUS_PROPERTY_NAME, value);
    }
}
SelectedOfflineItemsVisitor.CREATION_DATE_PROPERTY_NAME = "Creation_Date";
SelectedOfflineItemsVisitor.DESCRIPTION_PROPERTY_NAME = "Description";
SelectedOfflineItemsVisitor.DISCIPLINES_PROPERTY_NAME = "Disciplines";
SelectedOfflineItemsVisitor.ID_PROPERTY_NAME = "Id";
SelectedOfflineItemsVisitor.LAST_UPDATE_DATE_PROPERTY_NAME = "Last_Update_Date";
SelectedOfflineItemsVisitor.NAME_PROPERTY_NAME = "Name";
SelectedOfflineItemsVisitor.DOWNLOAD_STATUS_PROPERTY_NAME = "Downloaded";
