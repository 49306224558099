import React from 'react';
import MUIDialog from '@material-ui/core/Dialog';
import * as PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import getStyles from './Modal.styles';

/**
 * A modal that displays content in the middle of the entire viewport and over all other content.
 * @see https://material-ui.com/components/dialogs/
 * @see https://material-ui.com/api/dialog/
 */
const Modal = (props) => {
    const {
        children,
        contextStyles,
        disableEnforceFocus,
        isModalOpen,
        isTransparentOverlay,
        onClose,
        onSubmit,
        testID,
    } = props;

    // Override core styles with context styles, separating MUI styles
    const muiStyles = getStyles(contextStyles, isTransparentOverlay);

    // Create dynamic class names and injected DOM styles for MUI component
    // * makeStyles returns a function
    const styles = makeStyles(muiStyles)();

    return (
        <MUIDialog
            className="c-dialog-modal"
            classes={ {
                container: styles.overlay, // MUI styles
                paper: styles.modal, // MUI styles
            } }
            disableEnforceFocus={ disableEnforceFocus }
            onClose={ onClose }
            onKeyPress={ (event) => {
                if (event.key === 'Enter' && onSubmit) {
                    onSubmit(event, props);
                }
            } }
            open={ isModalOpen }
            scroll="paper"
            BackdropProps={ { invisible: true } }
            data-test-id={ `${testID}__dialog-modal` }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            { children }
        </MUIDialog>
    );
};

Modal.propTypes = {
    /** Modal contents */
    children: PropTypes.node,

    /** Extended styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the modal container */
        modal: PropTypes.object,

        /** Styles for the overlay */
        overlay: PropTypes.object,
    }),

    /** Disable enforce focus on the modal */
    disableEnforceFocus: PropTypes.bool,

    /** Defines open or closed state of modal */
    isModalOpen: PropTypes.bool,

    /** Changes the overlay darkened fill to transparent */
    isTransparentOverlay: PropTypes.bool,

    /** Called when modal is closable and user closes the modal. */
    onClose: PropTypes.func,

    /**
     * Called when enter key is pressed on Modal component
     * @param {Object} event - The native key press event
     * @param {Object} props - The component instance props
     */
    onSubmit: PropTypes.func,

    /** Id used for testing */
    testID: PropTypes.string,
};

Modal.defaultProps = {
    contextStyles: {},
    disableEnforceFocus: true,
    testID: 'Modal',
};

export default Modal;
