// Core component styles
const coreStyles = {
    container: {
        width: 'fit-content',
    },

    disabled: {
        opacity: 0.5,
    },

    primary: {
        borderColor: '#006e96',
        backgroundColor: '#006e96',
    },

    secondary: {
        borderColor: '#006e96',
        backgroundColor: '#fff',
    },

    tertiary: {
    },
};

/**
* Helper method to get the styles for the component
* @param {Object} contextStyles - Context styles for the Button Component
*/
const getStyles = (contextStyles = {}) => {
    return {
        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        disabled: {
            ...coreStyles.disabled,
            ...contextStyles.disabled,
        },

        primary: {
            ...coreStyles.primary,
            ...contextStyles.primary,
            '&$disabled': {
                ...coreStyles.primary,
                ...contextStyles.primary,
            },
        },

        secondary: {
            ...coreStyles.secondary,
            ...contextStyles.secondary,
            '&$disabled': {
                ...coreStyles.secondary,
                ...contextStyles.secondary,
            },
        },

        tertiary: {
            ...coreStyles.tertiary,
            ...contextStyles.tertiary,
            '&$disabled': {
                ...coreStyles.tertiary,
                ...contextStyles.tertiary,
            },
        },
    };
};

export default getStyles;
