/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import makeStyles from '@material-ui/core/styles/makeStyles';

import getStyles from './BaseToolTip.styles';

const BaseTooltip = (props) => {
    const {
        contextStyles,
        tooltip,
        children,
    } = props;

    // Override core styles with context styles, separating MUI styles
    const {
        ...muiStyles
    } = getStyles(contextStyles);

    // eslint-disable-next-line no-unused-vars
    const styles = makeStyles(muiStyles)();
    const toolTipProps = {
        classes: {
            // popper: styles.popper,
            tooltip: styles.tooltip, // MUI styles
        },
        // enterDelay: 1000,
        // leaveDelay: 500,
        className: 'c-tooltip__tooltip',
        title: tooltip,
    };

    // Tooltips require a ref handler to show the tooltip. Since the
    // We don't know if the component will be a class or a MUI component we have to wrap with a div
    // so the tooltip can show the message based on the ref.
    return (
        <Tooltip { ...toolTipProps }>
            <div className="tooltip-ref-handler" style={ muiStyles.tooltipRef }>
                { children }
            </div>
        </Tooltip>
    );
};

BaseTooltip.propTypes = {
    contextStyles: PropTypes.shape({
        container: PropTypes.object,
        toolTipText: PropTypes.object,
    }),
    /** Tooltip for hoovering over image */
  tooltip: PropTypes.string,

  /** Wrapped child elements the tooltip pertains too*/
  children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.element),
      PropTypes.element,
  ]),
};

BaseTooltip.defaultProps = {
    tooltip: '',
    contextStyles: {
        container: {},
        toolTipText: {},
    },
};

export default BaseTooltip;