
class Utils {
    /**
     * Convert a tree of '-' style key names to camelCase
     * Flexbox standard uses '-'.  RN uses camelcase
     * @param node
     * @returns {*}
     */
    camelCase = (node) => {
        if (Array.isArray(node)) {
            return node.map(this.camelCase);
        } if (typeof node === 'object' && (node !== null)) {
            const newObj = {};
            const keys = Object.keys(node);
            keys.forEach((key) => {
                const newKey = this.camelize(key);
                newObj[newKey] = this.camelCase(node[key]);
            });
            return newObj;
        }
        return node;
    }

    camelize(key) {
        let newKey = key;
        const index = key.indexOf('-');
        if (index > -1) {
            const front = key.substr(0, index);
            const back = this.camelize(key.substr(index + 1));
            newKey = front + back.charAt(0)
                .toUpperCase() + back.slice(1);
        }
        return newKey;
    }
}

export const utils = new Utils();
export default utils;
