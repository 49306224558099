/*       */


import { LIST } from '../../exportClassConstants';
import SimpleXStyleAttribute from '../SimpleXStyleAttribute';
import MarkupSelection from '../list/MarkupSelection';
import AbstractLayoutFactory from './AbstractLayoutFactory';

class ListLayoutFactory extends AbstractLayoutFactory {
    constructor() {
        super();
        const attributesAndAliases = [
            'listAlternatingColors',
            'markupSelection',
        ];
        this.init(attributesAndAliases);
    }

    initFactory() {
        super.initFactory();
        const { factory } = this;
        factory.listAlternatingColors = (json, key) => {
            return new SimpleXStyleAttribute('listAlternatingColors', this.getAsString(json, key), [ LIST ]);
        };
        factory.markupSelection = (json, key) => {
            return new MarkupSelection('markupSelection', this.getAsString(json, key), [ LIST ]);
        };
    }
}

const listLayoutFactory = new ListLayoutFactory();
export default listLayoutFactory;
