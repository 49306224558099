/**
 * Core component style object that contains style objects
 * for all the styles for inner elements.
 */
const coreStyles = {
    button: {},

    buttonHover: {},

    buttonText: {},

    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0 2rem 2rem 2rem',
        border: '1px solid #ccc',
        borderRadius: '0.5rem',
        boxShadow: '0.25rem 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.5)',
        width: '50%',
        minWidth: '300px',
    },

    error: {
        marginBottom: '1rem',
    },

    heading: {},
};

/**
 * Combine all styles, including context styles,
 * into a single style object for
 * @param {Object} contextStyles
 */
const getStyles = (contextStyles) => {
    return {
        button: {
            ...coreStyles.button,
            ...contextStyles.button,
        },

        buttonHover: {
            ...coreStyles.buttonHover,
            ...contextStyles.buttonHover,
        },

        buttonText: {
            ...coreStyles.buttonText,
            ...contextStyles.buttonText,
        },

        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        error: {
            ...coreStyles.error,
            ...contextStyles.error,
        },

        heading: {
            ...coreStyles.heading,
            ...contextStyles.heading,
        },
    };
};

export default getStyles;
