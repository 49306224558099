/*       */
import AbstractControl from './AbstractControl';

export default class CheckBox extends AbstractControl {
    get checkedColor() { return this.color('CheckedColor'); }
    get uncheckedColor() { return this.color('UncheckedColor'); }
    get lineColor() { return this.color('LineColor'); }
    get showLines() { return !!this.lineColor && !!this.valueAt('LineColor') !== 'Clear'; }
    get propertyName() {
        const pn = this.valueAt('Binding');
        const i = pn.indexOf('[');
        return i === -1 ? pn : pn.substr(0, i);
    }
    get checkedValue() {
        const pn = this.valueAt('Binding');
        // Pluck out the value, from this format: propertyName[value]
        const i = pn.indexOf('[');
        return i === -1 ? undefined : pn.substr(i + 1, pn.length - i - 2);
    }


    init(cell) {
        super.init(cell);
        this.width = this.isFillParentWidth ? cell.width - (this.paddingLeft + this.paddingRight)
            : this.layoutSizeWidth - (this.paddingLeft + this.paddingRight);
        this.height = this.isFillParentHeight ? cell.height - (this.paddingTop + this.paddingBottom)
            : this.layoutSizeHeight - (this.paddingTop + this.paddingBottom);
    }

    newReadOnlyPropertyStyleSet() {
        // This styleSet will be used for both read and maintenance.
        const result = super.newReadOnlyPropertyStyleSet();
        // Tell the system to use the paper checkbox and not the standard boolean control.
        result.xStyle.paperCheckbox = {
            checkedValue: this.checkedValue,
            width: this.width,
            height: this.height,
            checkedColor: this.checkedColor,
            uncheckedColor: this.uncheckedColor,
            lineColor: this.lineColor,
            showLines: this.showLines,
        };
        return result;
    }
}
