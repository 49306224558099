import { TypeNames } from 'cv-dialog-sdk';
import calendarTranspiler from './classic/CalendarTranspiler';
import detailsTranspiler from './classic/DetailsTranspiler';
import listTranspiler from './classic/ListTranspiler';
import formTranspiler from './classic/FormTranspiler';
import vizTranspiler from './classic/VizTranspiler';
import pbiTranspiler from './classic/PbiTranspiler';
import gpsTranspiler from './classic/GpsTranspiler';
import graphTranspiler from './classic/GraphsTranspiler';
import barcodeTranspiler from './classic/BarcodeTranspiler';
import mapTranspiler from './classic/MapsTranspiler';
import imagePickerTranspiler from './classic/ImagePickerTranspiler';
import nfcTranspiler from './classic/NFCTranspiler';

const typeMap = {};
typeMap[TypeNames.CalendarTypeName] = calendarTranspiler;
typeMap[TypeNames.DetailsTypeName] = detailsTranspiler;
typeMap[TypeNames.ListTypeName] = listTranspiler;
typeMap[TypeNames.GpsReadingTypeName] = gpsTranspiler;
typeMap[TypeNames.FormTypeName] = formTranspiler;
typeMap[TypeNames.PowerBITypeName] = pbiTranspiler;
typeMap[TypeNames.GraphTypeName] = graphTranspiler;
typeMap[TypeNames.BarcodeScanTypeName] = barcodeTranspiler;
typeMap[TypeNames.MapTypeName] = mapTranspiler;
typeMap[TypeNames.ImagePickerTypeName] = imagePickerTranspiler;
typeMap[TypeNames.VizTypeName] = vizTranspiler;
typeMap[TypeNames.NFCScanTypeName] = nfcTranspiler;

class ClassicTranspiler {
    generateSalt(dialog) {
        const { view: { type }, dialogAlias: alias } = dialog;
        const transpiler = typeMap[type];
        if (!transpiler) {
            throw Error(`No transpiler found for view type ${type}`);
        }

        // Lets short circute the GVC view pages until it is fully integrated.
        if (alias
            && (type === TypeNames.DetailsTypeName
                || type === TypeNames.ListTypeName)
            && alias.includes('ViewerAlanResha')) {
            return vizTranspiler.generateSalt(dialog);
        }

        return transpiler.generateSalt(dialog);
    }
}

const classicTranspiler = new ClassicTranspiler();
export default classicTranspiler;
