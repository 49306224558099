import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';

import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';

const VirtualTableCell = VirtualTable.Cell;

class DataTableCell extends PureComponent {
    // removed the shouldComponentUpdate, as it is restricting the images, largeImages to re-render.
    // If we add check for children, then all props are compared in shouldComponentUpdate, which results for no need to shouldComponentUpdate.
    render() {
        // console.log('DATA TABLE RXG: TABLE CELL: RENDER: ', this.props); // eslint-disable-line
        const {
            value,
            ...rest
        } = this.props;

        return (
            <VirtualTableCell
                { ...rest }
                value={ value }
                title={ typeof value === 'string' || typeof value === 'number' ? value : '' } />
        );
    };
}

DataTableCell.propTypes = {
    /** React node(s) used to render the cell content */
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.node,
        PropTypes.element,
    ]),

    /** Classes added to the cell <td> container */
    className: PropTypes.string,

    /** The count of columns that the root cell element spans */
    colSpan: PropTypes.number,

    /** Specifies the cell's column */
    column: PropTypes.shape({
        /** The column reference name */
        name: PropTypes.string,

        /** The column display text */
        title: PropTypes.string,
    }),

    /** Specifies the cell's row */
    row: PropTypes.object,

    /** The count of rows that the root cell element spans */
    rowSpan: PropTypes.number,

    /** Styles added to the cell <td> container */
    style: PropTypes.object,

    /** Specifies a DXG table column */
    tableColumn: PropTypes.object,

    /** Specifies a DXG table row */
    tableRow: PropTypes.object,

    /** Specifies a value to be rendered within the cell */
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
        PropTypes.element,
    ]),
};

DataTableCell.defaultProps = {};

export default DataTableCell;
