import { reaction } from 'mobx';
import PropTypes from 'prop-types';
import React from 'react';
import isEqual from 'react-fast-compare';
import engineConstants from './engineConstants';
import RefUtil from './ref/RefUtil';
import SaltComponent from './SaltComponent';
import SaltContext from './SaltContext';


/*
    Ref param configurations:
    1) A name and (value or expr) - this is a write operation (with optional rendering of the resulting value)
*/
export default class WriteRef extends SaltComponent {
    static propTypes = {
        // unique name to identify the reference
        name: PropTypes.string.isRequired,
        // Sets a value. with the jsonata expression to be applied to the dialog associated with the specified viewId
        // if expr is omitted , the assumption is that we are referencing a name already in scope
        expr: PropTypes.string,
        // Sets a value directly
        /* eslint-disable react/forbid-prop-types */
        value: PropTypes.any,
        // if children are included, scope is assumed to be local unless overridden
        children: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.arrayOf(PropTypes.element),
        ]),
        viewId: PropTypes.string,
        // if children is set, scope is assumed to be local unless otherwise specified
        // scope is assumed to be global in all other cases
        scope: PropTypes.string,
    };

    static typeName = engineConstants.component.name.writeRef;

    // -------------------------------------------------------------------------------------
    // Refs may write observable values (WriteRefs) that other Refs (ReadRefs) react to
    // therefore we don't want to do those 'writes' (side-effects) inside the render method
    // so we use lifecycle methods and maintain local state (resolvedValue)
    // -------------------------------------------------------------------------------------

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.resetReaction();
    }

    componentDidUpdate(prevProps) {
        const { resolvedValue } = this.state;
        const { name } = this.props;
        const { scopeManager } = this.context;
        const value = scopeManager.getRef(name);
        // When dialog refreshes, scopeManager data will be wiped out as new context is created with different scopeManager.
        // Because of this, though Ref value is already resolved, RefUtil will be unable to evaluate because of missing data in scopeManaager.
        // In this situation, we have to reset the data into scopeManager.
        // Work Item: https://dev.azure.com/HexagonXalt/Xalt%20Mobility/_workitems/edit/31944
        if (!isEqual(prevProps, this.props) || (resolvedValue && !value)) {
            this.resetReaction();
        }
    }

    componentWillUnmount() {
        if (this.disposer) this.disposer();
    }

    render() {
        const { children } = this.props;
        const { resolvedValue } = this.state;
        if (!resolvedValue) {
            return null;
        }
        return children ? this.renderChildren(resolvedValue) : null;
    }

    renderChildren(resolvedValue) {
        const { name, children } = this.props;
        // global write will be handled by the resolve value method
        if (this.isGlobalWrite()) return children;

        // handle a local write
        const { scopeManager } = this.context;
        const newContext = {
            ...this.context,
            scopeManager: scopeManager.newScopeWithLocal(name, resolvedValue),
        };
        return (
            <SaltContext.Provider value={ newContext }>
                { children }
            </SaltContext.Provider>
        );
    }


    // -------------------------------------------------------------------------------------
    //  - A global write is handled here.  A local write is handled by creating a new context in the renderChildren method
    //  - It is necessary to track changes to the scopeManager and dialogStore in the case of an 'expr'.  Since the expr
    //    must be re-evaluated when those stores change, we run that portion in the 'tracking function' (1st function) of a 'reaction' block.
    //    If any dependencies of 'evaluateExpression' change, the expression will rerun and update the resolved value
    //    in the 'side effect' function (2nd function)
    //  - The observables here are saltStore, dialogStore, and scopeManager
    // -------------------------------------------------------------------------------------
    resetReaction() {
        const { name, expr, value } = this.props;
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore, scopeManager } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        if (this.disposer) this.disposer();
        this.disposer = reaction(
            // tracking function
            () => this.resolveValue(value, expr, dialogStore, scopeManager, saltStore),
            // side effect function
            (resolvedValue) => {
                // update the result if changed
                if (this.state.resolvedValue !== resolvedValue) {
                    // write the value (if global)
                    this.globalWrite(name, resolvedValue, scopeManager);
                    this.setState({ resolvedValue });
                }
            },
            { fireImmediately: true },
        );
    }

    resolveValue = (value, expr, dialogStore, scopeManager, saltStore) => {
        if (value) return value;
        if (expr && dialogStore) RefUtil.evaluateExpression(expr, dialogStore, scopeManager, saltStore);
        return null;
    }

    globalWrite(name, value, scopeManager) {
        if (this.isGlobalWrite()) RefUtil.setGlobalRef(name, value, scopeManager);
    }

    isGlobalWrite() {
        return !this.props.children || this.props.scope === 'global';
    }
}

WriteRef.contextType = SaltContext;
