import SimpleFloatAttribute from '../SimpleFloatAttribute';
import SimpleStringAttribute from '../SimpleStringAttribute';

export default class Height extends SimpleFloatAttribute {
    exportTo(context, styleSet) {
        const { style } = styleSet;
        if (SimpleStringAttribute.hasConstantRef(this.value)) {
            SimpleStringAttribute.exportConstantAsRef(style, this.overrideName, this.value);
        } else {
            if (this.isPercentBased()) { // eslint-disable-line no-lonely-if
                if (context.parentContext && !context.parentContext.isColumnBound()) {
                    context.warnings.addUnpredictableUnboundHeight();
                }
                style[this.overrideName] = this.value;
            } else {
                style[this.overrideName] = parseFloat(this.value);
            }
        }
    }

    isPercentBased = () => {
        return this.value.indexOf('%') > -1;
    }
}
