import { RecordVisitor } from "../proxy/RecordVisitor";
/**
 *
 */
export class DownloadManagerVisitor extends RecordVisitor {
    constructor(value) {
        super(value);
    }
    // --- State Management Helpers --- //
    static visitAndSetOnline(jsonObject, online) {
        return (new DownloadManagerVisitor(jsonObject)).visitAndSetOnline(online);
    }
    // --- State Management --- //
    visitOnline() {
        return this.visitPropertyValueAt(DownloadManagerVisitor.ONLINE_PROPERTY_NAME);
    }
    visitPassword() {
        return this.visitPropertyValueAt(DownloadManagerVisitor.PASSWORD_PROPERTY_NAME);
    }
    visitSessionExpiry() {
        return this.visitPropertyValueAt(DownloadManagerVisitor.IS_SESSION_EXPIRED);
    }
    visitDownloads() {
        return this.visitPropertyValueAt(DownloadManagerVisitor.HAS_DOWNLOADED_DATA);
    }
    visitDownloadDate() {
        return this.visitPropertyValueAt(DownloadManagerVisitor.LAST_DOWNLOAD_DATE);
    }
    visitAndSetPassword(passwordValue) {
        this.visitAndSetPropertyValueAt(DownloadManagerVisitor.PASSWORD_PROPERTY_NAME, passwordValue);
    }
    visitAndSetOnline(onlineValue) {
        this.visitAndSetPropertyValueAt(DownloadManagerVisitor.ONLINE_PROPERTY_NAME, onlineValue);
    }
    visitAndSetSessionExpiry(sessionExpirationValue) {
        this.visitAndSetPropertyValueAt(DownloadManagerVisitor.IS_SESSION_EXPIRED, sessionExpirationValue);
    }
    visitAndSetDownloads(hasDownloads) {
        this.visitAndSetPropertyValueAt(DownloadManagerVisitor.HAS_DOWNLOADED_DATA, hasDownloads);
    }
    visitAndSetDownloadDate(lastDownloadDate) {
        this.visitAndSetPropertyValueAt(DownloadManagerVisitor.LAST_DOWNLOAD_DATE, lastDownloadDate);
    }
}
DownloadManagerVisitor.ONLINE_PROPERTY_NAME = 'online';
DownloadManagerVisitor.PASSWORD_PROPERTY_NAME = 'password';
DownloadManagerVisitor.IS_SESSION_EXPIRED = 'isSessionExpired';
DownloadManagerVisitor.HAS_DOWNLOADED_DATA = 'hasDownloadedData';
DownloadManagerVisitor.LAST_DOWNLOAD_DATE = 'downloadDate';
