/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { XaltDropDown } from 'xalt-react-atoms';

/**
 * A platform component for building a text label
 */
export default class RWDropDownField extends Component {
    static propTypes = {
        style: PropTypes.object,
        value: PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
        }),
        tipText: PropTypes.string,
        onValueChanged: PropTypes.func,
        allowFreeformEntry: PropTypes.bool,
        disableClearable: PropTypes.bool,
        xStyle: PropTypes.object,
        optionValues: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                ]),
            }),
        ),
        propertyRef: PropTypes.object,
        isFocused: PropTypes.bool,
        placeholder: PropTypes.string,
    };

    static defaultProps = {
        style: {},
        optionValues: [],
        allowFreeformEntry: false,
        disableClearable: false,
    };

    render() {
        const {
            style,
            xStyle,
            value,
            tipText,
            onValueChanged,
            optionValues,
            allowFreeformEntry,
            propertyRef,
            disableClearable,
            isFocused,
            placeholder,
        } = this.props;

        const {
            clearIcon = {},
            dropdown = {},
            option,
            expandIcon,
            optionSelected,
            optionHover,
        } = xStyle;

        return (
            <XaltDropDown
                style={ {
                    textOverflow: 'ellipsis',
                    ...style,
                    ...dropdown,
                } }
                disableClearable={ disableClearable }
                autoFocus={ isFocused }
                allowFreeformEntry={ allowFreeformEntry }
                onValueChanged={ onValueChanged }
                tipText={ tipText }
                adornmentStyle={ clearIcon }
                optionStyles={ option }
                expandIconStyles={ expandIcon }
                selectedStyles={ optionSelected }
                hoverStyles={ optionHover }
                inputRef={ propertyRef }
                value={ value }
                items={ optionValues }
                placeholder={ placeholder } />
        );
    }
}
