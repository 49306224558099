import { rootStore } from 'cv-react-core';

const getTheme = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    return {
        buttonText: {
            ...theme.fonts.default,
        },

        buttonPrimary: {
            backgroundColor: theme.loginButtonPrimaryBackgroundColor,
        },

        buttonPrimaryHover: {
            backgroundColor: theme.loginButtonPrimaryBackgroundColor,
        },

        buttonPrimaryText: {
            ...theme.fonts.loginButtonPrimary,
        },

        buttonSecondary: {
            backgroundColor: theme.buttonSecondaryBackgroundColor,
            borderColor: theme.buttonSecondaryBorderColor,
            borderRadius: theme.selectorBorderRadius,
        },

        buttonSecondaryHover: {
            borderColor: theme.buttonUnderlayColor,
        },

        buttonSecondaryText: {
            ...theme.fonts.buttonSecondary,
        },

        errorText: {
            ...theme.fonts.error,
            textAlign: 'unset',
        },

        input: {
            ...theme.fonts.input,
        },

        textField: {
            errorTextColor: theme.colors.error,
            activeBorderColor: theme.textInputActiveBorderColor,
            borderColor: theme.textInputUnderlineColor,
        },

        textLabel: {
            ...theme.fonts.loginSettingsLabel,
        },
    };
};

export default getTheme;
