import { DialogModeEnum, RedirectionUtil } from 'cv-dialog-sdk';
import { action, computed, observable, toJS } from 'mobx';

import dialogComponentFactory from '../provider/dialogComponentFactory';
import transpiler from '../transpiler/transpiler';
import AvailableValuesStore from './AvailableValuesStore';

export default class DialogStore {
    /**
     * @type {Dialog}
     */
    @observable.ref dialog = null;
    /**
     * @type {Map<string, Dialog>}
     */
    @observable.shallow childDialogStoresMap = new Map();
    /**
     * @type {boolean}
     */
    @observable isDestroyed = false;
    /**
     * @type {boolean}
     */
    @observable isRefreshNeeded = false;
    /**
     * @type {boolean}
     */
    @observable isRefreshTriggeredByTimer = false;

    @observable.ref activeSaltDocument = null;

    @observable sessionValues = {};

    /**
     * @type number
     * timestamp of last data change (reads/writes) to allow for generic observation of new data
     */
    @observable dataChange = new Date().getTime();

    @observable isSearchFormOpen = false;

    @observable isSortModalOpen = false;

    /**
     * @deprecated - use the new available values approach (AvailableValuesStore)
     *
     * @type {Map<any, any>}
     */
     @observable availableValuesMap = new Map();

     // v2 available values implementation - use this for new development
     availableValuesStore = new AvailableValuesStore();

     constructor(sessionStore, parentDialogStore) {
         this.sessionStore = sessionStore;
         this.parentDialogStore = parentDialogStore;
     }

     /**
     * Retrieve the current set of values for a propertyName
     * @param propertyName
     * @returns {*}
     */
     getAvailableValues = (propertyName) => {
         return this.availableValuesMap.get(propertyName);
     };

    refreshAvailableValuesStore = () => {
        return Promise.all(
            this.availableValuesStore.propNames.map(propName => this.dialog.getAvailableValues(propName).then(
                values => this.availableValuesStore.setAvailableValues(propName, values),
            )),
        );
    }

    /**
     * Commit the buffer and refresh the current set of values for propertyName
     * @param propertyName
     * @param recordId
     * @returns {*|PromiseLike<T | never>|Promise<T | never>}
     */
     @action updateAvailableValues = (propertyName, recordId) => {
         this.commitBuffer();
         return this.dialog.getAvailableValues(propertyName, recordId)
             .then(values => {
                 this.availableValuesMap.set(propertyName, values);
                 this.availableValuesStore.setAvailableValues(propertyName, values);
             });
     };

    @computed get childDialogStores() {
         if (this.childDialogStoresMap) {
             return [ ...this.childDialogStoresMap.values() ];
         }
         return [];
     }

    @computed get saltDocument() {
        const saltDocument = this.activeSaltDocument || this.dialog.view.saltDocument;
        // add default salt if view is missing a salt document
        if (!saltDocument) {
            this.dialog.view.saltDocument = transpiler.generateSalt(this.dialog);
            return this.dialog.view.saltDocument;
        }
        return saltDocument;
    }

    getSaltDocumentName() {
        return this.dialog.view.saltDocumentName;
    }

    @action setSaltDocument(saltDocument) {
        this.activeSaltDocument = saltDocument;
    }

    getChildDialogStore = (dialogId) => {
        return this.childDialogStoresMap.get(dialogId);
    };

    @action asTransaction(transaction) {
        return transaction();
    }

    @action setDialog = (dialog, setRefreshNeeded = true) => {
        this.dialog = dialog;
        if (setRefreshNeeded) {
            this.isRefreshNeeded = dialog?.isRefreshNeeded;
        }
        if (this.dialog) {
            const { children } = this.dialog;
            if (children) {
                // be careful here as this is an async callback (forEach) which is NOT tracked by the @action on this method
                // changes to state should be done through other methods marked with @action
                const childDialogStores = children.map((childDialog) => {
                    const childDialogStore = this.getChildDialogStore(childDialog.id)
                         || dialogComponentFactory.getDialogStoreInstance(childDialog, this.sessionStore, this);
                    childDialogStore.setDialog(childDialog);
                    return childDialogStore;
                });
                this.childDialogStoresMap.clear();
                childDialogStores.forEach(childDialogStore => {
                    this.setChildDialogStore(childDialogStore.dialog.id, childDialogStore);
                });
            }
        }
    };

    @action setChildDialogStore(id, childDialogStore) {
        this.childDialogStoresMap.set(id, childDialogStore);
    }

    @action updateDialogObservables() {
        this.isDestroyed = this.dialog.dialogMode === DialogModeEnum.DESTROYED;
        // Due to our server-side refresh logic:
        // If one dialog in the tree (this one) has been told to refresh (isRefreshNeeded=true), then we need
        // to notify all dialogs in the tree that has been a possible change
        // if isRefreshNeeded=false, then this dialog has refreshed itself, but the others
        // in the tree may still need to refresh, so we DO NOT notify the whole tree
        if (!this.isDestroyed) {
            if (this.dialog.isRefreshNeeded) {
                this.setAllIsRefreshNeeded(this.dialog.isRefreshNeeded);
            } else {
                this.isRefreshNeeded = false;
            }
        }
    }

    getStoreForViewPersistentId(viewId) {
        const { id, persistentId, persistentViewId, persistentViewSectionId } = this.dialog.view;
        if (persistentViewId && persistentViewId === viewId) {
            return this;
        }

        // this is for backward compatibility until we have persistentViewId in every environment
        if (persistentId && persistentId === viewId) {
            return this;
        }

        // this is used in case of viewRef
        if (persistentViewSectionId && persistentViewSectionId === viewId) {
            return this;
        }

        // this is for backward compatibility until we have persistentIds in every environment
        if (id && id === viewId) {
            return this;
        }
        return this.childDialogStores.find(childDialogStore => {
            return childDialogStore.getStoreForViewPersistentId(viewId);
        });
    }

    get storesWithUnsavedChanges() {
        let stores = [];
        this.childDialogStores.forEach(childDialogStore => {
            stores = stores.concat(childDialogStore.storesWithUnsavedChanges);
        });
        return stores;
    }

    @action setDataChange() {
        this.dataChange = new Date().getTime();
    }

    @action setAllIsRefreshNeeded = (isRefreshNeeded) => {
        this.getRootDialogStore().setIsRefreshNeeded(isRefreshNeeded);
    };

    @action setIsRefreshNeeded = (isRefreshNeeded) => {
        this.isRefreshNeeded = isRefreshNeeded;
        // propagate this to all sub dialogs in the hierarchy
        this.childDialogStores.forEach((dialogStore) => {
            dialogStore.setIsRefreshNeeded(isRefreshNeeded);
        });
    };

    @action setRefreshTriggerByTimer = (isRefreshTriggeredByTimer) => {
        this.isRefreshTriggeredByTimer = isRefreshTriggeredByTimer;
    };

    // by, default refresh the entire form. Subclasses should override this method to do a more 'local' refresh
    @action refresh = () => {
        return this.sessionStore.openOrRefreshDialogStore(this.dialog.id);
    };

    /**
     * Destroy the Store
     */
    @action destroy = (isExplicitDialogDestroy) => {
        // Destroy the dialog
        return this.dialog.destroy(isExplicitDialogDestroy).then(() => {
            this.updateDialogObservables();
        });
    }

    @action clearAll = () => {
        // Clean up observables
        this.setDialog(undefined, false);
        this.childDialogStoresMap.clear();
    };

    getRootDialogStore = () => {
        return (this.parentDialogStore && this.parentDialogStore.getRootDialogStore()) || this;
    };

    getRootDialogId = () => {
        return this.getRootDialogStore().dialog.id;
    };

    getSanitizedDialog = () => {
        return toJS(this.dialog);
    };

    getRefreshTriggeredByTimer = () => {
        return this.isRefreshTriggeredByTimer;
    }


    hasNotificationsAction = () => {
        return this.sessionStore.notificationsAction !== undefined;
    }

    /**
     * Handles opening a view based on viewDescriptor
     * @param {object} viewDescriptor
     */
    openView(viewDescriptor) {
        if (!viewDescriptor) return Promise.reject();
        return this.dialog.openView(viewDescriptor)
            .then((dialogOrRedirection) => {
                // A dialog result is the top-level form dialog with any changes merged in so we update the top-level dialog
                if (!RedirectionUtil.isRedirection(dialogOrRedirection)) {
                    this.setDialog(dialogOrRedirection, false);
                }
                // Update any dialog observables (i.e. isDestroyed, etc.)
                // Note the existing dialog may have been modified 'in place' so we need to update
                this.updateDialogObservables();
                return dialogOrRedirection;
            });
    }

    @action setIsSearchFormOpen(value) {
        this.isSearchFormOpen = value;
    }

    getIsSearchFormOpen() {
        return this.isSearchFormOpen;
    }

    @action setSessionValues(sessionValues) {
        this.sessionValues = sessionValues;
    }

    sessionValueWithName(name) {
        return this.sessionValues[name];
    }

    @action clearData() {
        Object.getOwnPropertyNames(this).forEach((key) => {
            try {
                this[key] = null;
            } catch {
                // Ignore
            }
        });
    }
}
