import React from 'react';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { utilities } from '../utilities';
import SaltContext from './SaltContext';
import componentFactory from './componentFactory';
import SaltComponent from './SaltComponent';


@observer
export default class CompactList extends SaltComponent {
    static propTypes = {
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        viewId: PropTypes.string,
        onChooseItem: PropTypes.func,
        onSelectItem: PropTypes.func,
        onMenuAction: PropTypes.func,
        onChooseNone: PropTypes.func,
        onRefresh: PropTypes.func,
        onRequestMore: PropTypes.func,
        availableMenuItems: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            icon: PropTypes.string,
            menuText: PropTypes.string,
        })),
        orientation: PropTypes.string,
        asyncDataCallback: PropTypes.func,
    };

    render() {
        const {
            onChooseItem, onSelectItem,
            onMenuAction, onRefresh,
            onRequestMore, onChooseNone,
            style, xStyle, availableMenuItems,
            asyncDataCallback,
            ...rest
        } = this.props;
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        const { dialog, refreshInProgress, records, isRefreshTriggeredByTimer } = dialogStore;
        const { uiStore } = saltStore;
        const { view, recordDef } = dialog;

        const listProps = {
            uiStore,
            dialogStore,
            style,
            xStyle,
            columns: view.columns,
            viewType: view.type,
            fixedColumnCount: view.fixedColumnCount,
            hasMoreRecords: dialogStore.hasMoreRecords(),
            records: toJS(records),
            recordDef,
            keyExtractor: utilities.listHelper.getKeyExtractor,
            onRequestMore,
            isRefreshing: isRefreshTriggeredByTimer ? false : refreshInProgress,
            onRefresh,
            onChooseItem,
            onSelectItem,
            onMenuAction,
            onChooseNone,
            availableMenuItems,
            selectedRecords: utilities.listHelper.getSelectedRecords(uiStore, dialogStore),
            asyncDataCallback,
            isRefreshTriggeredByTimer,
            ...rest,
        };

        return React.createElement(componentFactory.getPlatformComponent('compactList'), listProps);
    }
}

CompactList.contextType = SaltContext;
