import { Menu, propertyFormatter, Catavolt } from 'cv-dialog-sdk';
import { constants } from '../constants';
import serviceFactory from '../services/serviceFactory';
import MenuSpec from './MenuSpec';
import BuildGlobals from '../provider/BuildGlobals';

/**
 * A collection of utility functions.
 */

const isMenuVisible = menu => menu.visible !== false;

const ui = {
    // Misc.
    addFocusableField: (control, tabSeq) => {
        if (control) { tabSeq.push(control); }
    },

    binaryDataAsUri: (data, mimeType) => {
        return data ? { uri: `data:${mimeType};base64,${data}` } : null;
    },

    focusNextOrFireButton: (control, tabSeq, buttonAction) => {
        // Walk the tab array.  Give focus to the field after control.
        const len = tabSeq.length;
        for (let i = 0; i < len; i += 1) {
            const c = tabSeq[i];
            if (control === c) {
                if (i < len - 1 && tabSeq[i + 1] && tabSeq[i + 1].setFocus) {
                    tabSeq[i + 1].setFocus();
                } else if (buttonAction) {
                    buttonAction();
                }
                break;
            }
        }
    },

    // Formatting.
    formatForRead: (record, recordDef, propName) => {
        return propertyFormatter.formatForRead(record.propAtName(propName), recordDef.propDefAtName(propName));
    },

    formatForWrite: (record, recordDef, propName) => {
        return propertyFormatter.formatForWrite(record.propAtName(propName), recordDef.propDefAtName(propName));
    },

    formatPropertyForRead: (property, propDef) => {
        if (propDef.isBooleanType) {
            // Null is a valid boolean field in our system meaning empty for read only.
            const boolValue = propertyFormatter.formatForRead(property, propDef);
            // Convert boolean display values to Yes/No instead of true/false
            const { lang: { generic: { yes, no } } } = serviceFactory;
            const { booleanFalseAlias, booleanTrueAlias } = propDef;
            if (boolValue) {
                return boolValue === 'true' ? booleanTrueAlias || yes : booleanFalseAlias || no;
            }
            return booleanFalseAlias || no;
        }
        return propertyFormatter.formatForRead(property, propDef);
    },

    formatPropertyForWrite: (property, propDef) => {
        return propertyFormatter.formatForWrite(property, propDef);
    },

    formatPropertyForAction: (property, propDef) => {
        return propertyFormatter.formatForAction(property, propDef);
    },

    parse: (property, propDef) => {
        if (property === null || property === undefined || property.value === null || property.value === undefined) {
            return null;
        }
        return propertyFormatter.parse(property.value, propDef);
    },

    isMenuVisible,

    setShortTitle: (uiStore, uiObjectId, longTitle) => {
        const i = longTitle.indexOf(':');
        if (i > 0) {
            uiStore.setValueForUIObject(uiObjectId, constants.ui.DIALOG_PAGE_TITLE, longTitle.substring(0, i));
        }
    },

    asGenericMenu: (dialogId, dialogMenu, additionalMenuItems) => {
        const toGeneric = (menu) => {
            return menu instanceof Menu ? new MenuSpec(
                menu.actionId,
                dialogId,
                menu.iconUrl,
                menu.label,
                menu.multiSelectAvailable,
                menu.selectionRequired,
                menu.isSeparator,
            ) : menu;
        };
        const combinedMenu = [];
        if (dialogMenu) {
            combinedMenu.push(...dialogMenu.children.filter(isMenuVisible).map(toGeneric));
        }
        if (additionalMenuItems) {
            combinedMenu.push(...additionalMenuItems.filter(isMenuVisible)
                .map(toGeneric));
        }
        return combinedMenu;
    },

    assignMenuKeys: (menuItems) => {
        // Assign an initial value of the id to the key
        menuItems.forEach(mi => {
            mi.key = mi.id || 'uniqueMenuKey'; // eslint-disable-line
            return mi;
        });
        menuItems.forEach((eachOuter, iOuter) => {
            // Look at remaining options, and update their key if needed
            menuItems.slice(iOuter + 1).forEach((eachInner, iInner) => {
                if (eachOuter.key === eachInner.key) {
                    eachInner.key += iInner; // eslint-disable-line
                }
            });
        });
        return menuItems;
    },

    findDialogStoreWithMenu: (dialogStore, menu) => {
        // menu may be { id } or it may be the full blown menu object.  If it is the full blown menu
        // object it will have the dialog id directly.
        if (menu.dialogId) {
            return dialogStore.dialog.id === menu.dialogId ? dialogStore : dialogStore.getChildDialogStore(menu.dialogId);
        }

        // Search it out as best as is possible.
        const actionId = menu.id;
        const { dialog: { view } } = dialogStore;
        const contextMenu = view.menu && dialogStore.dialog.view.menu.findContextMenu();
        const item = contextMenu ? contextMenu.findAtActionId(actionId) : null;
        if (item) {
            return dialogStore;
        }
        if (dialogStore.childDialogStores) {
            return dialogStore.childDialogStores.find((childDialogStore) => {
                return ui.findDialogStoreWithMenu(childDialogStore, menu);
            });
        }
        return null;
    },

    referenceTheUnreferenced(a, b, c, d, e, f, g, h, i, j, k, l, m = false) {
        return l === m;
    },

    /**
     * Helper method to collection general client information used throught the app.
     * @param {SessionStore} sessionStore
     */
    getClientInformation(sessionStore) {
        const { lang } = serviceFactory;
        const { applicationInfo } = lang;
        const { session } = sessionStore;
        const {
            dialogServerVersion,
            serverVersion,
            tenantId,
            userId,
        } = session || {};
        const clientInfo = [
            {
                name: applicationInfo.ClientReleaseVersion,
                value: BuildGlobals.getClientReleaseVersion(),
            },
            {
                name: applicationInfo.ClientSemanticVersion,
                value: BuildGlobals.getClientSemanticVersion(),
            },
            {
                name: applicationInfo.DialogServerVersion,
                value: dialogServerVersion,
            },
            {
                name: applicationInfo.CloudServerVersion,
                value: serverVersion,
            },
            {
                name: applicationInfo.TenantId,
                value: tenantId,
            },
            {
                name: applicationInfo.UserId,
                value: userId,
            },
        ];
        return clientInfo;
    },

    /**
     * Helper method to get format string of a date for a given locale.
     */
    getDateFormatString: () => {
        const { locale: { langCountryString } } = Catavolt;
        const formatObj = new Intl.DateTimeFormat(langCountryString).formatToParts(new Date());
        return formatObj
            .reduce((accumulator, currentValue) => {
                const { type, value } = currentValue;
                const formats = {
                    day: 'DD',
                    month: 'MM',
                    year: 'YYYY',
                };
                return `${accumulator}${formats[type] || value}`;
            }, '');
    },

    /**
     * Helper method to get 24 hour format imformation for a given locale.
     */
    getIs24HourFormat: () => {
        const { locale: { langCountryString } } = Catavolt;
        const date = new Date();
        const formatted = date.toLocaleString(langCountryString);
        return !(formatted.match(/am|pm/i) || date.toString().match(/am|pm/i));
    },

    /**
     *
     * @param {Object} view
     * @param {Boolean} forSection
     * @returns String
     */
    getViewId: (view = {}, forSection) => {
        const {
            id,
            persistentId,
            persistentViewId,
            persistentViewSectionId,
        } = view;

        // This is for backward compatibility to support all the tenants.
        const viewId = persistentId || persistentViewId || id;

        if (forSection) {
            return persistentViewSectionId || viewId;
        }

        return viewId;
    },
};

export default ui;
