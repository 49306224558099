import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Paper from '@material-ui/core/Paper';

import styleParser from '../utilities/styleHelpers/styleParser';
import XaltInputBase from '../XaltInputBase/XaltInputBase';
import XaltImage from '../XaltImage/XaltImage';
import { XaltExpandMore, XaltCheck } from '../XaltIconButton/XaltIconButtonTypes';
import XaltDropDownList from './XaltDropDownList';
import XaltBox from '../XaltBox/XaltBox';
import XaltTextLabel from '../XaltTextLabel/XaltTextLabel';

import { withAdditionalStyles } from '../hoc/withAdditionalStyles';

const propDefinition = {
    allowFreeformEntry: PropTypes.bool,

    autoFocus: PropTypes.bool,

    /** Styles for this component */
    style: PropTypes.object,

    /** Styling applied on hover */
    hoverStyle: PropTypes.object,

    /** Styling applied on focus */
    focusStyle: PropTypes.object,

    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
        }),
    ),

    adornmentStyle: PropTypes.object,

    optionStyles: PropTypes.object,

    expandIconStyles: PropTypes.object,

    selectedStyles: PropTypes.object,

    hoverStyles: PropTypes.object,

    /** Prevents the text field from accepting input */
    disabled: PropTypes.bool,

    disableClearable: PropTypes.bool,

    /** Reference to the input element */
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),

    loading: PropTypes.bool,

    onOpen: PropTypes.func,

    /**
     * Called when the TextField's input changes
     * @param {Object} event - The native change event
     * @param {Object} props - The component instance props
     */
     onValueChanged: PropTypes.func,

    /** The text to populate in the input */
    value: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
    }),

    tipText: PropTypes.string,
};

/**
 * ******************************** SPECIAL NOTE WHEN CREATING NEW COMPONENTS **************************
 * @todo This componet should deliver base functionality and not reference any other imported component.
 *      We will need to extract this out and create an atom componet for reuse in other apps. Do not
 *      use this as an example of how to create molecule components.
 * ******************************** SPECIAL NOTE WHEN CREATING NEW COMPONENTS **************************
 *
 * A platform component for building a Drop Down
 */
class XaltDropDown extends Component {
    static propTypes = propDefinition;

    static defaultProps = {
        style: {},
        value: null,
        adornmentStyle: {},
        optionStyles: {},
        expandIconStyles: {},
        selectedStyles: {},
        hoverStyles: {},
        items: [],
        loading: false,
        disabled: false,
        disableClearable: false,
        onValueChanged: () => {},
        onOpen: () => {},
    };

    render() {
        const {
            style,
            items,
            disabled,
            value,
            onOpen,
            loading,
            allowFreeformEntry,
            hoverStyle,
            focusStyle,
            disableClearable,
            expandIconStyles } = this.props;

        const autoCompleteStyles = styleParser.parseContainerStyles(style);
        return (
            <Autocomplete
                    // debug={ true }
                disableClearable={ disableClearable }
                autoHighlight
                style={ {
                        minWidth: autoCompleteStyles.width,
                        maxWidth: autoCompleteStyles.width,
                        flexGrow: autoCompleteStyles.flexGrow, ...hoverStyle, ...focusStyle,
                    } }
                className="x-drop-down__menu"
                disabled={ disabled }
                freeSolo={ allowFreeformEntry }
                value={ value }
                options={ items }
                getOptionLabel={ this.handleGetOptionLabel }
                getOptionSelected={ this.handleGetOptionSelected }
                onChange={ this.handleOnChange }
                onOpen={ onOpen }
                loading={ loading }
                PaperComponent={ this.renderPaperComponent }
                renderOption={ this.handleOnRenderOption }
                renderInput={ this.handleOnRenderInput }
                ListboxComponent={ XaltDropDownList }
                popupIcon={ <XaltExpandMore style={ expandIconStyles } /> }
                ListboxProps={ { ...style } } />

        );
    };

    renderPaperComponent = (params) => (
        <Paper
            { ...params }
            style={ { minWidth: '250px' } }
            elevation={ 10 } />
        )

    renderEndAdornment = (endAdornment) => {
        const { adornmentStyle, disableClearable } = this.props;

        // Apply width and padding to compensate for the extra component that gets inserted if we include the clearable property.
        const styles = {
            display: 'flex',
            flexBasis: '20%',
            ...adornmentStyle,
            width: disableClearable ? null : '60px',
            paddingRight: disableClearable ? null : '5px',
        };
        return (
            <div style={ styles }>
                { endAdornment }
            </div>
        );
    }

    handleOnRenderInput = (params) => {
        const {
            autoFocus,
            style,
            tipText,
            allowFreeformEntry,
            inputRef,
            placeholder,
        } = this.props;
        const { InputProps, ...restParams } = params;
        const {
            ref,
            endAdornment,
            startAdornment,
        } = InputProps;
        return (
            <XaltInputBase
                { ...restParams }
                autoFocus={ autoFocus }
                anchorRef={ ref }
                inputRef={ inputRef }
                endAdornment={ this.renderEndAdornment(endAdornment) }
                startAdornment={ startAdornment }
                disableUnderline
                tipText={ tipText }
                style={ style }
                placeholder={ placeholder }
                // If it is a freeform entry we need to capture the inputs. Otherwise selection handles this.
                onValueChanged={ (newValue) => allowFreeformEntry && this.handleOnChange(null, { label: newValue, value: newValue }) }
                fullWidth />
        );
    }

    handleGetOptionSelected = (option, valueTest) => option.value === valueTest?.value

    handleGetOptionLabel = (option) => {
        // This is in place to handle freesolo entry. All other scenario's present an object.
        // You can catch the input value change, but it's not optimal
        // as the value gets destorted between object and value. This
        // was a simpe routine that essentially is overriden by the change handler.
        if (option && typeof option === 'string') {
            return option;
        }
        return option && option.label ? option.label : '';
    }

    handleOnRenderOption = (item) => {
        const {
            style,
            value,
            optionStyles,
            selectedStyles,
            hoverStyles,
        } = this.props;
        const textStyles = styleParser.parseTextStyles(style);
        const selected = this.handleGetOptionSelected(item, value);
        // Note: Styles are hardcoded here for now. Selected style needs to be driven from themes.
        const selectedStyle = selected ? selectedStyles : {};
        return (
            <XaltBox
                style={ {
                    ...optionStyles,
                    ...selectedStyle,
                } }
                hoverStyle={ hoverStyles }>
                { item.imageSrc &&
                    <XaltImage
                        imageSrc={ item.imageSrc }
                        // Adjust image size to 2x times the font size
                        style={ { height: '2em' } }
                        toolTip={ item.label } />
                }
                <XaltTextLabel
                    style={ {
                        cursor: 'pointer',
                        marginRight: '12px',
                        ...textStyles,
                    } }>
                    { item.label }
                </XaltTextLabel>
                { selected && <XaltCheck /> }
            </XaltBox>
        );
    }

    handleOnChange = (event, newValue) => {
        const { onValueChanged } = this.props;
        onValueChanged(newValue);
    };
}

export default withAdditionalStyles(XaltDropDown);