import React from 'react';
import PropTypes from 'prop-types';

import Route from './Route';
import Navigator from './Navigator';
import LoginPage from '../components/pages/LoginPage/LoginPage';
import routeNames from './routeNames';
import lang from '../nls/i18n';

class LoginRoute extends Route {
    static propTypes = {
        navigator: PropTypes.instanceOf(Navigator),
        sessionStore: PropTypes.object,
        settingsStore: PropTypes.object,
        uiStore: PropTypes.object,
        themeStore: PropTypes.object,
    };

    render() {
        const {
            navigator,
            settingsStore,
            sessionStore,
            uiStore,
            themeStore } = this.props;

        const params = {
            sessionStore,
            settingsStore,
            uiStore,
            themeStore,
            navigator,
            onPostLogin: this.handleOnLogin,
            onSettings: this.handleSettingsPress,
        };
        // Set browser tab name to the workbench name.
        // Doing this on each route for consistency for now.
        document.title = lang.login.defaultBrowserTitle;
        return React.createElement(LoginPage, params);
    }

    handleSettingsPress = () => {
        const { navigator } = this.props;
        const { tenantId } = navigator.match.params;
        navigator.history.replace(`/${tenantId || '#'}/${routeNames.SETTINGS}`);
    }
}

export default LoginRoute;
