// Core component styles
const coreStyles = {
    overlay: {
        display: 'flex',
        width: '100%',
        height: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000,
    },
    modalRowContainer: {
        display: 'flex',
        flexDirection: 'row',
        maxWidth: '75%',
        maxHeight: '75%',
        minWidth: '35%',
    },
    modalColumnContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
};

/**
 * Helper method to get the styles for Button component
 * @param {Object} contextStyles - Context styles for the Button Component
 */
const getStyles = (contextStyles = {}, isTransparentOverlay = false) => {
    // Set default overlay background color
    const overlayBackgroundColor = 'rgba(0,0,0,0.4)';

    // Check for overlay background color context style override and apply
    // if (contextStyles.overlay && contextStyles.overlay.shadowColor) {
    //     overlayBackgroundColor = contextStyles.overlay.shadowColor;
    // }
    return {
        overlay: {
            ...coreStyles.overlay,
            ...contextStyles.overflow,
            backgroundColor: isTransparentOverlay ? 'transparent' : overlayBackgroundColor,
        },
        modalRowContainer: {
            ...coreStyles.modalRowContainer,
            ...contextStyles.modal,
        },
        modalColumnContainer: {
            ...coreStyles.modalColumnContainer,
            ...contextStyles.modalColumnContainer,
        },
    };
};

export default getStyles;
