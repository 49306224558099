/* @flow */
export default {
    ClientReleaseVersion: 'Client Version',
    ClientSemanticVersion: 'Client Semantic Version',
    DialogServerVersion: 'Dialog Server Version',
    CloudServerVersion: 'Cloud Server Version',
    TenantId: 'Tenant Id',
    UserId: 'User ID',
    copyright: '© Copyright 2017-2022, Hexagon AB',
};
