// Core component styles
const coreStyles = {
    body: {
    },

    button: {
    },

    buttonText: {
    },

    container: {
    },

    itemText: {
    },

    media: {
        height: '200px',
    },

    subtitle: {
    },

    title: {
        textAlign: 'center',
    },
};

/**
 * Helper method to get the styles for Activity Indicator component
 * @param {Object} contextStyles - Context styles for Activity Indicator Component
 */
const getStyles = (contextStyles = {}) => {
    return {
        body: {
            ...coreStyles.body,
            ...contextStyles.body,
        },

        button: {
            ...coreStyles.button,
            ...contextStyles.button,
        },

        buttonText: {
            ...coreStyles.buttonText,
            ...contextStyles.buttonText,
        },

        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        itemText: {
            ...coreStyles.itemText,
            ...contextStyles.itemText,
        },

        media: {
            ...coreStyles.media,
            ...contextStyles.media,
        },

        subtitle: {
            ...coreStyles.subtitle,
            ...contextStyles.subtitle,
        },

        title: {
            ...coreStyles.title,
            ...contextStyles.title,
        },
    };
};

export default getStyles;
