import React from 'react';
import * as PropTypes from 'prop-types';
import getStyles from './Box.styles';

const Box = (props) => {
    const {
        children,
        contextStyles,
        scroll,
        ...rest
    } = props;
    const {
        container,
    } = getStyles(contextStyles);

    const dftOverflowX = container.overflow || container.overflowX || 'hidden';
    const dftOverflowY = container.overflow || container.overflowY || 'hidden';
    return (
        <div
            className="abs-box__container"
            style={ {
                ...container,
                overflowX: `${scroll === 'horizontal' ? 'auto' : dftOverflowX}`,
                overflowY: `${scroll === 'vertical' ? 'auto' : dftOverflowY}`,
            } }
            { ...rest }>
            { children }
        </div>
    );
};

Box.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]),
    contextStyles: PropTypes.shape({
        container: PropTypes.object,
    }),
    scroll: PropTypes.oneOf([
        'horizontal',
        'vertical',
    ]),
};

Box.defaultProps = {
    contextStyles: {},
};

export default Box;
