/* eslint-disable class-methods-use-this */
/* eslint-disable react/jsx-indent */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Image, ButtonBase, BaseTooltip } from 'cv-library-react-web';
import getStyles from './ActionButtonImage.styles';

export default class ActionButtonImage extends PureComponent {
    static defaultProps = {
        contextStyles: {},
        imageSource: null,
    };

  	static propTypes = {
  	    /** Styles for this component */
  	    contextStyles: PropTypes.object,
  	    /** Location of the image */
  	    imageSource: PropTypes.string,
  	    /** Tooltip for hoovering over image */
  	    tooltip: PropTypes.string,
  	    /** Handler for pressing the image */
  	    onAction: PropTypes.func,
  	};

  	render() {
  	    const {
            contextStyles,
            imageSource,
            tooltip,
            // onAction,
        } = this.props;

        const styles = this.formatStyleForButtonBase(contextStyles);
  	    return (
            <BaseTooltip tooltip={ tooltip }>
                <ButtonBase
                    onClick={ this.handleOnAction }
                    contextStyles={ styles }>
                    { !!imageSource && this.renderImage(styles) }
                </ButtonBase>
            </BaseTooltip>
  	    );
  	}

  	renderImage(styles) {
  	    const { imageSource } = this.props;
  	    const imageProps = {
            contextStyles: { image: { ...styles.image } },
  	        imageSrc: imageSource,
  	        showLoadingIndicator: false,
  	    };
  	    return (
            <Image { ...imageProps } />
  	    );
      }

    handleOnAction = (nativeEvent) => {
        const {
            onAction,
            ...restProps
        } = this.props;
        if (onAction) onAction(restProps, nativeEvent);
    }

    formatStyleForButtonBase(contextStyles) {
        const styles = {
            ...getStyles(),
            ...contextStyles,
        };
        // TODO: Have to be explicit until we remove all the specific styling
        return {
            container: {
                margin: styles.margin,
            },
            primary: {
                backgroundColor: styles.backgroundColor,
                padding: styles.padding,
                borderRadius: styles.borderRadius,
                minWidth: styles.minWidth,
                boxShadow: 'none',
            },
            primaryHover: {
                backgroundColor: styles.backgroundColor,
                padding: styles.padding,
                borderRadius: styles.borderRadius,
            },
            image: {
                width: styles.width,
                height: styles.height,
            },
        };
    }
}