import React from 'react';
import * as PropTypes from 'prop-types';

import { withAdditionalStyles } from '../hoc/withAdditionalStyles';

const propDefinition = {
    /** Styles for this component */
    style: PropTypes.object,

    /** Styling applied on hover */
    hoverStyle: PropTypes.object,

    /** Styling applied on focus */
    focusStyle: PropTypes.object,

    /** provided by the HOC */
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,

    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),
};

/**
 * A platform component for building a Drop Down
 */
const XaltBox = (props) => {
    const {
        style,
        hoverStyle,
        focusStyle,
        children,
        ...restProps
    } = props;
    const boxProps = {
        ...restProps,
        style: { display: 'flex', ...style, ...hoverStyle, ...focusStyle },
    };

    return (
        <div { ...boxProps }>
            { children }
        </div>
    );
};

XaltBox.propTypes = propDefinition;
XaltBox.defaultProps = {
    style: {},
    focusStyle: {},
    hoverStyle: {},
};

export default withAdditionalStyles(XaltBox);