import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import { TextLabel } from 'cv-library-react-web';
import { constants } from 'cv-react-core';

import getStyles from './TableHeaderCellContent.styles';

const CARRIAGE_RETURN_REPLACEMENT = '\r\n';
const { searchSort } = constants;
const { DOT_REPLACEMENT } = searchSort;
export const SORT_DIRECTIONS = {
    ASC: 'asc',
    DESC: 'desc',
    NONE: null,
};

class LoadingTableHeaderCellContent extends PureComponent {
    static propTypes = {
        column: PropTypes.object,
    };

    static defaultProps = { };

    constructor(props) {
        super(props);
        const { column } = this.props;
        const { name } = column;
        this.columnName = name.replace(DOT_REPLACEMENT, '.');
    }

    render() {
        const { column } = this.props;
        const { title } = column;

        const styles = getStyles();
        // To have carriage returns in header, replace \r or \n or \r\n with \r\n
        const cTitle = title.replace(/\r\n|\n\r|\n|\r/g, CARRIAGE_RETURN_REPLACEMENT);
        const numberOfLines = cTitle.split(CARRIAGE_RETURN_REPLACEMENT).length;
        return (
            <>
                <TextLabel
                    contextStyles={ {
                        container: { ...styles.headerCellTextContainer },
                        text: { ...styles.headerCellText, ...{ 'cursor': 'unset ' } }} }
                    numberOfLines={ numberOfLines }>
                    { cTitle.toUpperCase() }
                </TextLabel>
            </>
        );
    }
}

export default LoadingTableHeaderCellContent;
