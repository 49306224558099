import React, { Component } from 'react';
import * as PropTypes from 'prop-types';

/**
 * A control that is represented by a colored square and may be selected or unselected.
 */

class PlainCheckbox extends Component {
    render() {
        const { style } = this.props;
        const {
            width,
            height,
        } = style;
        return (
            <canvas
                ref={ (r) => { this.canvas = r; } }
                width={ width }
                height={ height } />
        );
    }

    componentDidUpdate() {
        this.updateCanvas();
    }

    componentDidMount() {
        this.updateCanvas();
    }

    updateCanvas() {
        const {
            checked,
            style,
        } = this.props;
        const {
            checkedColor,
            uncheckedColor,
            showLines,
            lineColor,
            lineWidth,
        } = style;
        const fillColor = checked ? checkedColor : uncheckedColor;
        const {
            width,
            height,
        } = style;
        const ctx = this.canvas.getContext('2d');
        ctx.clearRect(0, 0, width, height); // Necessary for transparent colors
        ctx.fillStyle = fillColor;
        ctx.fillRect(0, 0, width, height);
        // Draw a border around checkbox and optional selection X.
        if (showLines) {
            ctx.lineWidth = lineWidth;
            ctx.strokeStyle = lineColor;
            const half = lineWidth / 2;
            ctx.strokeRect(half, half, width - lineWidth, height - lineWidth);
            if (checked) {
                ctx.beginPath();
                ctx.moveTo(0, 0);
                ctx.lineTo(width, height);
                ctx.moveTo(0, height);
                ctx.lineTo(width, 0);
                ctx.stroke();
            }
        }
    }
}

PlainCheckbox.propTypes = {
    /** Styles for this component */
    checked: PropTypes.bool,
    style: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
        checkedColor: PropTypes.string,
        unCheckedColor: PropTypes.string,
        showLines: PropTypes.bool,
        lineWidth: PropTypes.number,
        lineColor: PropTypes.string,

    }),
};

export default PlainCheckbox;
