import React from 'react';
import PropTypes from 'prop-types';
import componentFactory from './componentFactory';
import SaltContext from './SaltContext';
import SaltComponent from './SaltComponent';
import engineConstants from './engineConstants';
import RefUtil from './ref/RefUtil';

export default class Text extends SaltComponent {
    static propTypes = {
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
    };

    static typeName = engineConstants.component.name.text;

    render() {
        const resolvedProps = this.resolveProperties();
        const { children } = resolvedProps;
        const labelProps = {
            ...resolvedProps,
            value: (typeof children === 'number' || typeof children === 'boolean') ? String(children) : children,
        };
        return React.createElement(componentFactory.getPlatformPropertyComponent('textLabel'), labelProps);
    }

    // override the base to retrive substituted children that may contain translation / session value.
    getPropsWithSubstitution() {
        const { children } = this.props;
        const { saltStore, scopeManager } = this.context;
        const viewId = this.getViewId(this.props.viewId);
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        return {
            ...super.getPropsWithSubstitution(),
            ...(typeof children === 'string' ? RefUtil.substituteValues({ children }, scopeManager, dialogStore, saltStore) : {}),
        };
    }
}

Text.contextType = SaltContext;
