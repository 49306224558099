/*       */

/**
 * An Attribute may or may not have an alias.
 */
export default class Attribute {
    privateName ;
    privateAlias ;
    constructor(name, alias) {
        this.privateName = name;
        this.privateAlias = alias;
    }

    alias() {
        return this.privateAlias;
    }

    name() {
        return this.privateName;
    }

    hasAlias() {
        return !!this.privateAlias;
    }
}
