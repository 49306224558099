/* eslint-disable class-methods-use-this */
/* eslint-disable putout/multiple-properties-destructuring */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { serviceFactory } from 'cv-react-core';
import { observer } from 'mobx-react';

import FilterSelector from './FilterSelector';

@observer
class FilterValueSelectorComponent extends Component {
    static defaultProps = {
        // styles: {},
        onSetFilterValue: () => {},
    }

    static propTypes = {
        // styles: PropTypes.object,
        propertyName: PropTypes.string.isRequired,
        onSetFilterValue: PropTypes.func,
        searchDialogStore: PropTypes.object,
    }

    constructor(props) {
        super(props);
        this.handleOnDropDownChange = this.handleOnDropDownChange.bind(this);
    }

    render() {
        const dropDownProps = {
            ...this.getPropData(),
            onChange: this.handleOnDropDownChange,
        };

        return React.createElement(FilterSelector, dropDownProps);
    }

    componentDidMount = () => {
        const { propertyName, searchDialogStore } = this.props;
        const propertyValueName = this.getPropertyValueName(propertyName, searchDialogStore);
        searchDialogStore.updateAvailableValues(propertyValueName);
    };

    getPropData = () => {
        const { propertyName, searchDialogStore } = this.props;
        const value = this.getValue(propertyName, searchDialogStore);

        const { availableValuesStore } = searchDialogStore;
        const propertyValueName = this.getPropertyValueName(propertyName, searchDialogStore);
        const { lang } = serviceFactory;

        return {
            propertyName,
            placeholderText: lang.searchSort.filterValuePlaceholderText,
            isMultiselect: this.getIsMultiselect(propertyName, searchDialogStore),
            allSelectedText: lang.details.dropdown.allSelected,
            multipleSelectionsText: lang.details.dropdown.multipleSelections,
            value: this.formatItems(value),
            items: this.formatItems(availableValuesStore.getAvailableValues(propertyValueName)),
        };
    }

    getValue(propertyName, searchDialogStore) {
        const filterValueProperty = searchDialogStore.getSearchValuePropertyForName(propertyName);
        const { value } = filterValueProperty;
         return value;
    }

    getIsMultiselect(propertyName, searchDialogStore) {
        const propertyValueDefinition = this.getPropertyValueDefinition(propertyName, searchDialogStore);
        const { isCodeRefType, cardinality } = propertyValueDefinition;
        return isCodeRefType && cardinality === '*';
    }

    getPropertyValueDefinition(propertyName, searchDialogStore) {
        return searchDialogStore.getSearchValuePropertyDefForName(propertyName);
    }

    getPropertyValueName(propertyName, searchDialogStore) {
        const propertyValueDefinition = this.getPropertyValueDefinition(propertyName, searchDialogStore);
        const { propertyName: propertyValueName } = propertyValueDefinition;
        return propertyValueName;
    }

    getItems(propertyName, searchDialogStore) {
        const propertyValueName = this.getPropertyValueName(propertyName, searchDialogStore);
        const { availableValuesStore } = searchDialogStore;
        return availableValuesStore.getAvailableValues(propertyValueName);
    }

    formatItems(items) {
        if (!items || items.length === 0) return [];
        if (typeof items === 'object') {
            if (Array.isArray(items)) {
                return items.map((item) => this.formatItems(item) );
                // return items.map((arrayItem) => {
                //     const found = values.findIndex((valueItem) => valueItem.code === arrayItem.code ) >= 0;
                //     return this.createItem(arrayItem.description, arrayItem.code, found);
                // });
            }
            return {
                label: items.description,
                value: items.code,
            };
            // this.createItem(items.description, items.code, false);
        }
        return {
            label: items,
            value: items,
        };
    }

    handleOnDropDownChange(selectedItems) {
        const { onSetFilterValue, propertyName, searchDialogStore } = this.props;
        const isMultiselect = this.getIsMultiselect(propertyName, searchDialogStore);
        if (isMultiselect) {
            const items = this.getItems(propertyName, searchDialogStore);
            const foundItems = selectedItems.map((selectedItem) => items.find((item) => item.code === selectedItem.value));
            return onSetFilterValue(propertyName, foundItems );
        }
        const items = this.getItems(propertyName, searchDialogStore);
        const foundItem = items.find((item) => item.code === selectedItems.value);
        return onSetFilterValue(propertyName, foundItem);
    }
}

export default FilterValueSelectorComponent;