/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import componentFactory from '../componentFactory';
import componentPropTypes from './abstractComponentPropTypes';

const propDefinitions = {
    style: componentPropTypes.style,
    value: componentPropTypes.value,
    name: componentPropTypes.name,
    tipText: componentPropTypes.tipText,
    isFocused: componentPropTypes.isFocused,
    onValueChanged: componentPropTypes.onValueChanged,
    xStyle: componentPropTypes.xStyle,
};

export default class FilePickerInput extends Component {
    static propTypes = propDefinitions;

    render() {
        const {
            style,
            value,
            tipText,
            isFocused,
            xStyle,
        } = this.props;
        
        const filePickerInputProps = {
            style,
            value,
            tipText,
            isReadMode: true, // This is always read mode.
            isFocused,
            xStyle,
        };
        return React.createElement(componentFactory.getPlatformPropertyComponent('textField'), { ...filePickerInputProps });
    }
}
