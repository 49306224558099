import { rootStore } from 'cv-react-core';

const getTheme = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    // Populate the component parts with theme styles
    return {
        clearCacheText: {
            ...theme.fonts.loginSettingsInfo,
        },

        errorText: {
            ...theme.fonts.error,
            textAlign: 'unset',
        },

        heading: {
            ...theme.fonts.loginSettingsHeading,
        },

        input: {
            ...theme.fonts.input,
        },

        primaryButton: {
            backgroundColor: theme.buttonPrimaryBackgroundColor,
            borderColor: theme.buttonPrimaryBorderColor,
        },

        primaryButtonHover: {
            backgroundColor: theme.buttonPrimaryBackgroundColor,
        },

        primaryButtonText: {
            ...theme.fonts.buttonPrimary,
        },

        secondaryButton: {
            backgroundColor: theme.buttonSecondaryBackgroundColor,
            borderColor: theme.buttonSecondaryBorderColor,
        },

        secondaryButtonHover: {
            backgroundColor: theme.buttonSecondaryBackgroundColor,
            borderColor: theme.buttonSecondaryBorderColor,
        },

        secondaryButtonText: {
            ...theme.fonts.buttonSecondary,
        },

        textField: {
            errorTextColor: theme.colors.error,
            activeBorderColor: theme.textInputActiveBorderColor,
            borderColor: theme.textInputUnderlineColor,
        },

        textLabel: {
            ...theme.fonts.loginSettingsLabel,
        },
    };
};

export default getTheme;
