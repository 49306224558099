export class Menu {
    constructor() {
        this.children = [];
    }
    static findSubMenu(md, matcher) {
        if (matcher(md)) {
            return md;
        }
        if (md.children) {
            for (const child of md.children) {
                const result = Menu.findSubMenu(child, matcher);
                if (result) {
                    return result;
                }
            }
        }
        return null;
    }
    findAtActionId(actionId) {
        if (this.actionId === actionId) {
            return this;
        }
        let result = null;
        if (this.children) {
            this.children.some((md) => {
                result = md.findAtActionId(actionId);
                return result != null;
            });
        }
        return result;
    }
    findContextMenu() {
        return Menu.findSubMenu(this, (md) => {
            return md.id === 'CONTEXT_MENU';
        });
    }
    findActionBarMenu() {
        return Menu.findSubMenu(this, (md) => {
            return md.id === 'ACTION_BAR';
        });
    }
    get isPresaveDirective() {
        return this.directive && (this.directive === 'presave' || this.directive === 'PRESAVE');
    }
    get isRead() {
        return Menu.isRead(this);
    }
    get isSeparator() {
        return Menu.isSeparator(this);
    }
    get isWrite() {
        return Menu.isWrite(this);
    }
    static isRead(menu) {
        return menu.modes && menu.modes.indexOf('READ') > -1;
    }
    static isSeparator(menu) {
        return menu.type && menu.type === 'separator';
    }
    static isWrite(menu) {
        return menu.modes && menu.modes.indexOf('WRITE') > -1;
    }
}
