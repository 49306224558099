/**
 */
export class Download_Manager_Details_RECORD {
    static copyOfResponse() {
        return JSON.parse(JSON.stringify(this.RESPONSE));
    }
}
Download_Manager_Details_RECORD.PATH = 'tenants/${tenantId}/sessions/${sessionId}/dialogs/10/record';
Download_Manager_Details_RECORD.RESPONSE = {
    "dialogAlias": "Download_Manager_Details",
    "annotations": [],
    "id": "1",
    "type": "hxgn.api.dialog.Record",
    "dialogName": "Download_Manager_Details",
    "properties": [{
            "name": "password",
            "format": null,
            "annotations": [],
            "type": "hxgn.api.dialog.Property",
            "value": null
        }, {
            "name": "briefcaseid",
            "format": null,
            "annotations": [],
            "type": "hxgn.api.dialog.Property",
            "value": "1"
        }, {
            "name": "online",
            "format": null,
            "annotations": [],
            "type": "hxgn.api.dialog.Property",
            "value": true
        }, {
            "name": "ZZREPEAT_ACTION_PROPERTY_NAMEZZ",
            "format": null,
            "annotations": [],
            "type": "hxgn.api.dialog.Property",
            "value": true
        }]
};
