/* @flow */

/**
 * Type of message that can help drive messaging.
 */
const errorTypes = {
    /**
     * Dialog may remain open (probably should be popup)
     */
    soft: 'SOFT',
    /**
     * Dialog must close
     */
    hard: 'HARD',
};

/**
 * Constants used for error handling.
 * errorTypes - Type of message that can help drive messaging
 */
export const error = {
    /**
    * Type of message that can help drive messaging.
    */
    errorTypes,
    MODAL_STATE: 'MODAL_STATE'
};
