/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import componentFactory from '../componentFactory';
import componentPropTypes from './abstractComponentPropTypes';
import engineConstants from '../engineConstants';

const propDefinitions = {
    style: componentPropTypes.style,
    value: componentPropTypes.value,
    xStyle: componentPropTypes.xStyle,
};

export default class TextLabel extends Component {
    static propTypes = propDefinitions;
    static typeName = engineConstants.component.name.textLabel;

    /** TODO
     * 1. In the TextLabelComponent builder a numberOfLines was hard coded for lists.
     *      Need to determine the significance of that value and how it should be passed.
    */

    render() {
        const { style, value, xStyle } = this.props;
        const { numberOfLines } = xStyle;
        const labelProps = {
            numberOfLines,
            style,
            value,
        };
        return React.createElement(componentFactory.getPlatformPropertyComponent('textLabel'), { ...labelProps });
    }
}
