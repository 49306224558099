import React from 'react';
import * as PropTypes from 'prop-types';
import MUIDivider from '@material-ui/core/Divider';
import makeStyles from '@material-ui/core/styles/makeStyles';
import getStyles from './Divider.styles';

const Divider = (props) => {
    const {
        contextStyles,
        testID,
    } = props;

    // Override core styles with context styles, separating MUI styles
    const {
        ...muiStyles
    } = getStyles(contextStyles);

    // Create dynamic class names and injected DOM styles for MUI component
    // * makeStyles returns a function
    const styles = makeStyles(muiStyles)();

    const divider = (
        <MUIDivider
            classes={ { root: styles.divider } }
            className="c-divider__container"
            data-test-id={ `${testID}__divider` } />
    );

    return divider;
};

Divider.propTypes = {

    /** Styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the container */
        divider: PropTypes.object,
    }),

    /** Id used for testing */
    testID: PropTypes.string,
};

Divider.defaultProps = {
    contextStyles: {},
    testID: 'Divider',
};

export default Divider;
