import { action, observable } from 'mobx';
import ListDialogStore from './ListDialogStore';

export default class ListPageDialogStore extends ListDialogStore {
    @observable pageRecords = [];
    @observable pagesRetrieved = 1;
    fetchedRecordCount = 0;
    pageNumber = 1;
    pageSize = undefined;
    recordIds = new Map();

    /**
     * Set the page size for paging
     * @param {number} pageSize
     */
    setPageSize(pageSize) {
        this.pageSize = pageSize;
    }

    /**
     * set the page number default is 1
     * @param {number} pageNumber
     */
    setPageNumber(pageNumber = 1) {
        this.pageNumber = pageNumber;
    }

    /**
     * Restore recordIds from cache
     * @param {Object} recordIds
     */
    setRecordIds(recordIds) {
        Object.keys(recordIds).forEach((key) => {
            this.recordIds.set(+key, recordIds[key]);
        });
    }

    /**
     * Set the current number of records fetched from the server.
     * Since we purge records out of the buffer we need to track how
     * many batches of records the user has gone through incase they
     * want to jump around paging using the number of pages we retrieved.
     * This is necessary because we do not know how many possible records are available.
     * @param {number} count
     */
    setFetchedRecordCount(count = 0) {
        const currentPageFetchCount = this.pageSize * this.pageNumber;
        // If we have already retrieved x number of records that is less than the fetchRecord count
        // we do not need to update the fetchedRecord count. This means we have already been to this page
        // and the count is not moving up. If zero is passed we know we are resetting.
        if (count === 0 || count > currentPageFetchCount) {
            this.fetchedRecordCount = count;
            this.updatePagesRetrieved();
        } else if (currentPageFetchCount >= (this.fetchedRecordCount + count)) {
            this.fetchedRecordCount += count;
            this.updatePagesRetrieved();
        }
    }

    /**
     * This value is caluculated based on the number of records we have fetched
     * and the current page size. This is reset when resetList is called.
     */
    @action updatePagesRetrieved() {
        this.pagesRetrieved = Math.ceil(this.fetchedRecordCount / this.pageSize);
    }

    /**
     * The results of rows retrieved while paging forwards, backwards or jumping to a page.
     * @param {array} pageRecords
     */
    @action setPageRecords(pageRecords) {
        this.pageRecords = pageRecords || [];
    }

    /**
     * Go backwards in the list of items (based on pageSize)
     * @returns {Promise}
    */
    @action pageForward = (pageNumber) => {
        // No need to fetch anything if we are already fetching.
        if (this.refreshInProgress) return Promise.resolve();
        if (this.queryInProgress) return Promise.resolve();

        if (this.hasMoreRecords()) {
            this.asTransaction(() => {
                this.setQueryInProgress(true);
                this.setRefreshInProgress(true);
            });
            return this.handleQueryProgress(this.dialog.scroller.pageForward(this.pageSize))
                .then((results) => {
                    // page number is used when a user selects a page we have already retrieved.
                    if (pageNumber) {
                        this.setPageNumber(pageNumber);
                    } else this.setPageNumber(this.pageNumber += 1); // bump the page count

                    // If the page number is one, no need to store the record index as default is page 1.
                    if (this.pageNumber !== 1) this.recordIds.set(this.pageNumber, this.pageRecords[this.pageRecords.length - 1].id);
                    this.asTransaction(() => {
                        // Update fetched count.
                        this.setFetchedRecordCount(results.length);
                        // Trim the buffer
                        this.dialog.scroller.trimFirst(this.pageSize);
                        // Broadcast updated records.
                        this.setPageRecords(results);
                        this.setQueryInProgress(false);
                        this.setRefreshInProgress(false);
                    });
                }).catch((err) => {
                    this.setQueryInProgress(false);
                    this.setRefreshInProgress(false);
                    throw err;
                });
        }
        return Promise.resolve();
    };

    /**
     * Go backwards in the list of items (based on pageSize)
     * @returns {Promise}
    */
    @action pageBackward = () => {
        // No need to fetch anything if we are already fetching.
        if (this.pageNumber === 1) return Promise.resolve();
        if (this.refreshInProgress) return Promise.resolve();

        this.asTransaction(() => {
            this.setQueryInProgress(true);
            this.setRefreshInProgress(true);
        });

        return this.handleQueryProgress(this.dialog.scroller.pageBackward(this.pageSize))
            .then((results) => {
                // backup the page number
                this.setPageNumber(this.pageNumber -= 1);
                // Trim off the last batch of records.
                this.dialog.scroller.trimLast(this.pageSize);
                // Broadcast updated records. We get then in backwards order
                // so we need to reverse the array.
                // @TODO: This should be fixed on the dialog server.
                this.asTransaction(() => {
                    this.setPageRecords(results.reverse());
                    this.setQueryInProgress(false);
                    this.setRefreshInProgress(false);
                });
                return results.reverse();
            }).catch((err) => {
                throw err;
            });
    };

    @action gotToPage = (pageNumber) => {
        // Update the page number.
        this.setPageNumber(pageNumber);
        // If the page number is greater than the page we are on, we just page forward.
        if (pageNumber > this.pagesRetrieved) {
            return this.pageForward(pageNumber);
        }
        // Otherwise we get the record id of the last record stored of the page
        return this.refreshList(this.recordIds.get(pageNumber));
    }
    /**
     * Reset the buffer and refresh the list using the original pageSize value
     * @returns {Promise}
     */
    @action resetList = (recordId = null) => {
        this.asTransaction(() => {
            this.setQueryInProgress(true);
            this.setRefreshInProgress(true);
        });
        // Apply or update page sizing
        // This comes from either the persisted value based on user "Items per page" selection or default page size 1
        this.setPageSize(this.pageSize || this.dialog.view.pageSize1);
        this.setFetchedRecordCount(this.fetchedRecordCount); // Reset the fetch count
        this.setPageNumber(this.pageNumber); // Reset page number to 1
        const overrideRecordId = recordId || this.recordIds.get(this.pageNumber);
        return this.handleQueryProgress(this.dialog.scroller.refresh(this.pageSize, overrideRecordId))
            .then((results) => {
                // These are all listners so we want to batch the update to reduce renders.
                this.asTransaction(() => {
                    this.setFetchedRecordCount(results.length);
                    if (!this.isRefreshNeeded) this.setIsListInitialized(true);
                    this.setRefreshInProgress(false);
                    this.setQueryInProgress(false);
                    this.setPageRecords(results);
                });
                return results;
            }).catch((err) => {
                this.setRefreshInProgress(false);
                this.setIsRefreshNeeded(false);
                this.setQueryInProgress(false);
                throw err;
            });
    };

    /**
     * Refresh the list, maintaining the current number of records
     * i.e. getting multiple pages at once
     * @returns {Promise}
     */
    @action refreshList = (recordId = null) => {
        // Don't trigger another refresh if refresh is already in progress.
        if (!this.refreshInProgress) {
            this.asTransaction(() => {
                this.setQueryInProgress(true);
                this.setRefreshInProgress(true);
            });
            // Re-query buffer.length + 1 if there are no more records so moreRecords is set correctly on return.
            let numRows = this.pageSize || this.dialog.view.pageSize1;
            if (!numRows || numRows === 0) {
                numRows = (this.dialog.scroller.buffer.length < this.dialog.scroller.pageSize
                    ? this.dialog.scroller.pageSize : this.dialog.scroller.buffer.length) + (this.hasMoreRecords() ? 0 : 1);
            }
            const overrideRecordId = recordId || this.recordIds.get(this.pageNumber);
            return this.handleQueryProgress(this.dialog.scroller.refresh(numRows, overrideRecordId))
                .then((results) => {
                    // These are all listners so we want to batch the update to reduce renders.
                    this.asTransaction(() => {
                        this.setRefreshTriggerByTimer(false);
                        this.setRefreshInProgress(false);
                        this.setQueryInProgress(false);
                        if (!this.isListInitialized) this.setIsListInitialized(true);
                        this.setPageRecords(results);
                    });
                    return results;
                }).catch((err) => {
                    this.setRefreshInProgress(false);
                    this.setQueryInProgress(false);
                    throw err;
                });
        }
        return Promise.resolve();
    };

    @action restorePaginationDefaults = () => {
        this.recordIds.clear();
        this.setPageSize(this.pageSize || this.dialog.view.pageSize1);
        this.setFetchedRecordCount();
        this.setPageNumber(1);
    }
}
