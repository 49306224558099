import { GENERAL, GENERAL_FLEX } from '../../exportClassConstants';
import SimpleStringAttribute from '../SimpleStringAttribute';

const VALUES = {
    LEFT: 'left',
    RIGHT: 'right',
    TOP: 'top',
    BOTTOM: 'bottom',
    FLEX_START: 'flexStart',
    FLEX_END: 'flexEnd',
    CENTER: 'center',
    STRETCH: 'stretch',
};


export default class AlignItems extends SimpleStringAttribute {
    isLegacy ;
    constructor(modernValue, legacyValue) {
        const value = ((modernValue || legacyValue));
        super('align-items', (value), [ GENERAL, GENERAL_FLEX ], false);
        this.isLegacy = !!legacyValue;
    }

    static newAttribute = (warnings, modernValue, legacyValue) => {
        const result = new AlignItems(modernValue, legacyValue);
        if (result.asResolvedValue() === null) {
            warnings.addInvalidValue(result.isLegacy ? 'align' : 'alignItems', result.value);
            return null;
        }
        return result;
    }

    asResolvedValue = () => {
        if (this.value === VALUES.LEFT || this.value === VALUES.TOP || this.value === VALUES.FLEX_START) {
            return 'flex-start';
        } if (this.value === VALUES.RIGHT || this.value === VALUES.BOTTOM || this.value === VALUES.FLEX_END) {
            return 'flex-end';
        } if (this.value === VALUES.CENTER) {
            return 'center';
        } if (this.value === VALUES.STRETCH) {
            return 'stretch';
        }
        return null;
    }

    exportTo(context, styleSet) {
        if (SimpleStringAttribute.hasConstantRef(this.value)) {
            // Would not need to report if we converted left/right/top/bottom to start/end.
            SimpleStringAttribute.reportExportIssue('alignItems', this.value);
        }
        const value = this.asResolvedValue();
        if (value) {
            styleSet.flexPolicy.assignAlignItems(value);
        }
    }

    isCenter = () => {
        return this.value === VALUES.CENTER;
    }
}
