// Core component styles
const coreStyles = {
    chartTextStyle: {
    },

    container: {
    },

    errorTooltipStyle: {
        color: 'red',
        fontSize: 12,
    },

    legendTextStyle: {
    },

    titleTextStyle: {
    },

    tooltipTextStyle: {
    },

    xAxisTextStyle: {
    },

    yAxisTextStyle: {
    },
};

const getStyles = (contextStyles = {}) => {
    return {
        chartTextStyle: {
            ...coreStyles.chartTextStyle,
            ...contextStyles.chartTextStyle,
        },

        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        errorTooltipStyle: {
            ...coreStyles.errorTooltipStyle,
            ...contextStyles.errorTooltipStyle,
        },

        legendTextStyle: {
            ...coreStyles.legendTextStyle,
            ...contextStyles.legendTextStyle,
        },

        titleTextStyle: {
            ...coreStyles.titleTextStyle,
            ...contextStyles.titleTextStyle,
        },

        tooltipTextStyle: {
            ...coreStyles.tooltipTextStyle,
            ...contextStyles.tooltipTextStyle,
        },

        xAxisTextStyle: {
            ...coreStyles.xAxisTextStyle,
            ...contextStyles.xAxisTextStyle,
        },

        yAxisTextStyle: {
            ...coreStyles.yAxisTextStyle,
            ...contextStyles.yAxisTextStyle,
        },
    };
};

export default getStyles;
