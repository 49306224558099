import React from 'react';
import PropTypes from 'prop-types';
import { autorun, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { Dialog } from 'cv-dialog-sdk';
import serviceFactory from '../services/serviceFactory';
import componentFactory from './componentFactory';
import pageController from '../controllers/pageController';
import SaltContext from './SaltContext';
import Layout from './xStyle/Layout';
import SaltComponent from './SaltComponent';
import RefreshTimer from '../utilities/RefreshTimer';
import { constants } from '../constants';

@observer
export default class ListView extends SaltComponent {
    static propTypes = {
        id: PropTypes.string,
        children: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.arrayOf(PropTypes.element),
        ]),
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xStyle: PropTypes.oneOfType([
            Layout.layoutPropType,
            PropTypes.arrayOf(Layout.layoutPropType),
        ]),
        scroll: PropTypes.string,
        assert: PropTypes.shape({
            refName: PropTypes.string,
            expr: PropTypes.string,
        }),
    };

    render() {
        const { id, style, scroll, assert, xStyle, children, ...rest } = this.props;
        const component = componentFactory.getAbstractComponent('box');
        const boxProps = { style, scroll, assert, xStyle, ...rest };
        return React.createElement(component, boxProps, children);
    }

    componentDidUpdate() {
        const { id } = this.props;
        const { saltStore, onError } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(id);
        if (dialogStore !== this.dialogStore) {
            // retain the reference for an identity comparison later
            this.dialogStore = dialogStore;
            this.createRefreshTimer(dialogStore, onError);
            this.registerAutorun(dialogStore, onError);
        }
    }

    componentDidMount() {
        this.isMountedNow = true;
        const { id } = this.props;
        const { saltStore, onError } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(id);
        if (dialogStore !== this.dialogStore) {
            // retain the reference for an identity comparison later
            this.dialogStore = dialogStore;
            this.createRefreshTimer(dialogStore, onError);
            this.registerAutorun(dialogStore, onError);
        }
    }

    componentWillUnmount() {
        this.isMountedNow = false;
        if (this.disposer) {
            this.disposer();
        }

        delete this.dialogStore;
        if (this.refreshTimer) this.refreshTimer.destroy();
    }

    get isPagingEnabled() {
        const { id } = this.props;
        const { saltStore } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(id);
        if (!dialogStore) return false;
        const { dialog } = dialogStore;
        const { view: { pagingMethod } } = dialog;
        return pagingMethod === Dialog.PAGING_DEFINED_DIALOG_CLASS;
    }

    registerAutorun(dialogStore, onError) {
        const title = serviceFactory.lang.dialog.errors.errorRefreshingQueryTitle;
        const { dateRange: recordId, dialog } = this.dialogStore;
        if (this.isPagingEnabled) {
            const { saltStore } = this.context;
            const { uiStore } = saltStore;
            const {
                pageNumber,
                recordIds,
                fetchedRecordCount,
                pageSize,
            } = toJS(uiStore.getValueForUIObject(dialog.id, constants.ui.LIST_PAGINATION_DATA)) || {};
            if (recordIds && Object.keys(recordIds).length) {
                dialogStore.setPageNumber(pageNumber);
                dialogStore.setFetchedRecordCount(fetchedRecordCount);
                dialogStore.setRecordIds(recordIds);
                dialogStore.setPageSize(pageSize);
            }
        }
        pageController.performResetList({ dialogStore, title, onError, recordId }).then(() => {
            if (this.disposer) {
                this.disposer();
            }
            if (this.isMountedNow) {
                this.disposer = autorun(() => {
                    if (dialogStore.isRefreshNeeded) {
                        pageController.performRefreshList({ dialogStore, title, onError });
                    }
                });
            }
        });
    }

    createRefreshTimer(dialogStore, onError) {
        if (this.refreshTimer) this.refreshTimer.destroy();

        // Timer refresh in milliseconds is only available on the parent form dialog
        const { parentDialogStore } = dialogStore;
        this.refreshTimer = new RefreshTimer(parentDialogStore);
        const title = serviceFactory.lang.dialog.errors.errorReadingRecordTitle;
        this.refreshTimer.onRefresh = pageController.performRefreshList.bind(this, { dialogStore, title, onError });
        this.refreshTimer.start(dialogStore);
    }
}

ListView.contextType = SaltContext;
