/**
 * A collection of utility functions.
 */
const EMPTY = {};
const all = 'all';
const allList = 'allList'; // All, but know it's on a list and record level background is already applied.
const none = 'none';

const asColor = (color) => {
    if (color && (color === '#')) {
        return '#000';
    }
    return color;
};

const getBackgroundColor = (record, property) => {
    const rec = record || EMPTY;
    const prop = property || EMPTY;
    return asColor(prop.backgroundColor) || asColor(rec.backgroundColor);
};

const getBackgroundAsStyle = (record, property, style) => {
    const newStyle = style ? Object.assign({}, style) : {}; // Do not use EMPTY as target as it will be changed
    const rec = record || EMPTY;
    const prop = property || EMPTY;
    const backgroundColor = asColor(prop.backgroundColor) || asColor(rec.backgroundColor);
    if (backgroundColor) {
        newStyle.backgroundColor = backgroundColor;
    }
    return newStyle;
};

const getAsStyle = (record, property, style) => {
    const newStyle = style ? Object.assign({}, style) : {}; // Do not use EMPTY as target as it will be changed
    const rec = record || EMPTY;
    const prop = property || EMPTY;
    const foregroundColor = asColor(prop.foregroundColor) || asColor(rec.foregroundColor);
    if (foregroundColor) {
        newStyle.color = foregroundColor;
    }
    if (prop.isBoldText || rec.isBoldText) {
        newStyle.fontWeight = 'bold';
    }
    if (prop.isItalicText || rec.isItalicText) {
        newStyle.fontStyle = 'italic';
    }
    if (prop.isUnderline || rec.isUnderline) {
        newStyle.textDecorationLine = 'underline';
    }
    return newStyle;
};

const getOverrideText = (record, property, inText) => {
    const rec = record || EMPTY;
    const prop = property || EMPTY;
    if (typeof prop.overrideText === 'string') {
        return prop.overrideText;
    }
    if (typeof rec.overrideText === 'string') {
        return rec.overrideText;
    }
    if (inText && typeof inText === 'object') {
        return inText.description;
    }
    // return (prop.overrideText || rec.overrideText || inText); // Doesn't handle empty string
    return inText;
};

const isAll = (request) => {
    return request === all;
};

const isAllList = (request) => {
    return request === allList;
};

const isNone = (request) => {
    return request === none;
};

const annotationHelper = {
    all,
    allList,
    none,
    getAsStyle,
    getBackgroundAsStyle,
    getBackgroundColor,
    getOverrideText,
    isAll,
    isAllList,
    isNone,
};

export default annotationHelper;
