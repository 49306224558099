/*       */
import StyleSet from '../StyleSet';
import AbstractModel from './AbstractModel';
import Page from './Page';
import { pageSeparatorExtraWidth, spaceBetweenPages } from '../paperConstants';
import PageSeparator from './PageSeparator';
import { PAGES } from '../ModelCache';
import FormUtil from '../FormUtil';

export default class Form extends AbstractModel {
    constructor(json) {
        super(json);
        this.fontSizes = this.initFontSizes();
    }
    get hideSaveCancelButtons() { return this.valueAt('HideSaveCancelButtons') === 'true'; }
    get pages() { return this.cache.get(PAGES, this.initPages); }
    get designFonts() { return this.arrayAt('Design.Font'); }
    asSalt = (formContext, viewId) => {
        const { pages } = this;
        const extraHeight = pages.length > 1 ? spaceBetweenPages : 0;
        const lastPage = pages[pages.length - 1];
        const totalHeight = lastPage.top + lastPage.height + extraHeight;
        const viewStyleSet = new StyleSet({
            paperId: 'view',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            flexShrink: 1,
            overflow: 'hidden',
        });
        const scrollingStyleSet = new StyleSet({
            paperId: 'scrolling',
            display: 'flex',
            flexDirection: 'column',
            flexShrink: 1,
            flexGrow: 1,
            overflow: 'auto',
        });
        const centeringStyleSet = new StyleSet({
            paperId: 'centering',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flexShrink: 1,
            flexGrow: 1,
            minWidth: this.layoutSizeWidth + pageSeparatorExtraWidth,
        });
        const formStyleSet = new StyleSet({
            paperId: 'form',
            display: 'flex',
            flexDirection: 'column',
            width: this.layoutSizeWidth + pageSeparatorExtraWidth,
            height: totalHeight,
            position: 'relative',
            backgroundColor: this.color('BackgroundColor'),
        });
        const footerStyleSet = new StyleSet({
            paperId: 'footer',
            display: 'flex',
            flexDirection: 'column',
        });
        const children = [];
        // Add the page separators first so the pages will draw on top of them if necessary (grid lines overflow).
        pages.forEach((e, i) => {
            // Add a separator view after each page (do not add if only one page)
            if (pages.length !== 1) {
                children.push(new PageSeparator(e, i, pages.length, spaceBetweenPages));
            }
        });
        pages.forEach((e) => { children.push(e); });
        // Sort children based on their position (top). This helps in tabbing through forms in order.
        children.sort((a, b) => a.top - b.top);

        // Below styles are required in case of XNA. If we have any other better way of identifying XNA and XHA, we can follow that.
        if (FormUtil.isXNA()) {
            scrollingStyleSet.style.overflow = 'scroll'; // overfow: 'auto' is not supported in react native
            scrollingStyleSet.style.minWidth = this.layoutSizeWidth + pageSeparatorExtraWidth; // needed for setting initial zoom in XNA
            scrollingStyleSet.style.minHeight = totalHeight;
            scrollingStyleSet.style.defaultScale = this.defaultScale;
            scrollingStyleSet.style.minimumScale = this.minimumScale;
            scrollingStyleSet.style.maximumScale = this.maximumScale;
        }

        return {
            salt: {
                version: '1.0.0',
                children: [
                    {
                        view: {
                            id: viewId,
                            ...viewStyleSet.asStyleAttribute(),
                            ...viewStyleSet.asXStyleAttribute(),
                            children: [
                                {
                                    box: {
                                        ...scrollingStyleSet.asStyleAttribute(),
                                        ...scrollingStyleSet.asXStyleAttribute(),
                                        zoom: true,
                                        children: [
                                            {
                                                box: {
                                                    ...centeringStyleSet.asStyleAttribute(),
                                                    ...centeringStyleSet.asXStyleAttribute(),
                                                    children: [
                                                        {
                                                            box: {
                                                                ...formStyleSet.asStyleAttribute(),
                                                                ...formStyleSet.asXStyleAttribute(),
                                                                children: children.map(m => m.asSalt(formContext)),
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    box: {
                                        ...footerStyleSet.asStyleAttribute(),
                                        ...footerStyleSet.asXStyleAttribute(),
                                        children: [
                                            {
                                                when: {
                                                    assert: { expr: `${this.hideSaveCancelButtons} != true` },
                                                    children: [
                                                        {
                                                            when: {
                                                                assert: { expr: '$dialog.view.editable' },
                                                                children: [
                                                                    {
                                                                        when: {
                                                                            assert: { expr: '$dialog.isWriteMode and $dialog.view.editable' },
                                                                            children: [ this.newButtonBox([ this.newCancelButton(), this.newSaveButton() ]) ],
                                                                            'else-children': [ this.newButtonBox([ this.newEditButton() ]) ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                    'else-children': [
                                                        {
                                                            when: {
                                                                assert: { expr: '$dialog.isReadMode and $dialog.view.editable' },
                                                                children: [ this.newButtonBox([ this.newEditButton() ]) ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        };
    }
    initFontSizes() {
        const result = {};
        this.designFonts.forEach(f => {
            const id = f.Id[0];
            const size = f.Size[0];
            result[id] = parseFloat(size);
        });
        return result;
    }
    initPages = () => {
        let prevPage = null;
        const pages = this.childrenOfType('Page').map((m, i) => {
            prevPage = new Page(m, this, spaceBetweenPages, i, prevPage);
            return prevPage;
        });
        // If there are pages, return them...
        if (pages.length) return pages;

        // ...if there are no pages, create a single page holding the direct children.
        return [ Page.createWithChildren(this.allChildren(), this) ];
    }

    newButtonBox(children) {
        return {
            box: {
                style: {
                    backgroundColor: '#fff',
                    'flex-direction': 'row',
                },
                children,
            },
        };
    }

    newEditButton() {
        /** remove x-style while implementing XNA NUX */
        return {
            action: {
                id: '#edit',
                type: 'button',
                'x-style': {
                    button: {
                        marginLeft: 5,
                        marginRight: 5,
                        marginBottom: 10,
                        flexGrow: 1,
                    },
                },
                style: {
                    marginLeft: 5,
                    marginRight: 5,
                    marginBottom: 10,
                    flexGrow: 1,
                },
            },
        };
    }

    newCancelButton() {
        /** remove x-style while implementing XNA NUX */
        return {
            action: {
                id: '#cancel',
                type: 'button',
                tooltip: 'Esc',
                hotkey: {
                    eventType: 'keydown',
                    eventCodes: [
                        {
                            key: 'keyCode',
                            value: 27,
                        },
                    ],
                },
                'x-style': {
                    buttonType: 'secondary',
                    button: {
                        marginLeft: 5,
                        marginRight: 5,
                        marginBottom: 10,
                    },
                },
                style: {
                    marginLeft: 5,
                    marginRight: 5,
                    marginBottom: 10,
                },
            },
        };
    }

    newSaveButton() {
        /** remove x-style while implementing XNA NUX */
        return {
            action: {
                id: '#save',
                type: 'button',
                tooltip: 'Ctrl + Enter',
                hotkey: {
                    eventType: 'keydown',
                    eventCodes: [
                        {
                            key: 'ctrlKey',
                            value: true,
                        },
                        {
                            key: 'keyCode',
                            value: 13,
                        },
                    ],
                },
                'x-style': {
                    button: {
                        marginLeft: 5,
                        marginRight: 5,
                        marginBottom: 10,
                    },
                },
                style: {
                    marginLeft: 5,
                    marginRight: 5,
                    marginBottom: 10,
                },
            },
        };
    }
}
