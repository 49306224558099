import React from 'react';
import SaltComponent from './RWSaltComponent';
import GridTable from './RWGridTable';

class RWCompactList extends SaltComponent {
    static propTypes = GridTable.propTypes;
    static defaultProps = GridTable.defaultProps;

    render() {
        return (
            <GridTable { ...this.props } />
        );
    }
}

export default RWCompactList;
