import React from 'react';
import * as PropTypes from 'prop-types';

import getStyles from './PaperCheckbox.styles';
import { default as PlainCheckbox } from './PlainCheckbox';
import { default as ButtonBase } from '../ButtonBase/ButtonBase';

/**
 * A control that is represented by a colored square and may be selected or unselected.
 */
const PaperCheckbox = (props) => {
    const {
        checked,
        style,
        contextStyles,
        onChange,
        testID,
    } = props;

    // Override core styles with context styles, separating MUI styles
    const {
        container: containerStyles,
        button: buttonStyles,
        paperCheckbox: checkboxStyles,
    } = getStyles(style, contextStyles);

    // Generate Checkbox props
    const checkboxProps = {
        checked,
        style: {
            ...checkboxStyles,
        },
        className: 'c-paper-checkbox__container',
    };

    if (testID) { checkboxProps['data-test-id'] = `${testID}__paper__checkbox__container`; }

    if (onChange) {
        return (
            <ButtonBase
                contextStyles={ buttonStyles }
                onClick={ (event) => (onChange(event, props)) }>
                <div
                    style={ containerStyles }>
                    <PlainCheckbox { ...checkboxProps } />
                </div>
            </ButtonBase>
        );
    }

    return (
        <div
            style={ containerStyles }>
            <PlainCheckbox { ...checkboxProps } />
        </div>
    );
};

PaperCheckbox.propTypes = {
    /** The checked value */
    checked: PropTypes.bool,
    style: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
        checkedColor: PropTypes.string,
        unCheckedColor: PropTypes.string,
        showLines: PropTypes.bool,
        lineWidth: PropTypes.number,
        lineColor: PropTypes.string,
    }),

    /** Styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the container surrounding the control */
        container: PropTypes.object,

        /** Styles for the paper checkbox */
        paperCheckbox: PropTypes.shape({
            checkedColor: PropTypes.string,
            unCheckedColor: PropTypes.string,
            showLines: PropTypes.bool,
        }),
    }),

    /**
     * Called when the checkbox was changed
     * @param {Object} event - The native change event
     * @param {Object} props - The component instance props
     */
    onChange: PropTypes.func,

    /** Id used for testing */
    testID: PropTypes.string,
};

PaperCheckbox.defaultProps = {
    contextStyles: {},
};

export default PaperCheckbox;
