import React from 'react';
import * as PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';

/**
 * A platform component for building a html input
 */
const XaltIconButton = (props) => {
    const {
        style,
        tipText,
        disabled,
        onClick,
        children,
    } = props;

    const iconButtonProps = {
        className: 'x-iconbutton__base',
        style,
        'aria-label': tipText,
        onClick,
        disabled,
        children,
    };

    return React.createElement(IconButton, { ...iconButtonProps } );
};

XaltIconButton.propTypes = {
    style: PropTypes.object,

    /** Button tooltip */
    tipText: PropTypes.string,

    /** Callback for clicking button */
    onClick: PropTypes.func,

    disabled: PropTypes.bool,

    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.node,
        PropTypes.element,
    ]),
};

XaltIconButton.defaultProps = {
    style: {},
    tipText: '',
    onClick: () => {},
};

export default XaltIconButton;
