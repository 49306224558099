/*       */
import AbstractGml from './AbstractGml';
import GmlUtil from '../GmlUtil';
import { CONDITIONAL_A, CONST_PREFIX_GML, REF_PREFIX, SESSION_PREFIX_GML } from '../gmlConstants';

import Base from './Base';
import SimpleStringAttribute from '../layoutAttribute/SimpleStringAttribute';

export default class Conditional extends AbstractGml {
    operatorType ;
    constructor(jsonObject, operatorType = 'and') {
        super(jsonObject);
        this.operatorType = operatorType;
    }

    asSalt(parentContext, assertExpr = '') {
        const myContext = this.updateContext(parentContext);
        // > 0 because conditional properties are not returned by the factories.
        if (this.getAttributes(myContext).length > 0) {
            const { warnings } = myContext;
            const type = Base.pvtElementName(this.json); // Could be conditional/and/or/else
            warnings.addInvalidConditionalExtras(type);
        }
        // Just return an array of our children.  Each child will receive this conditional in the form of an expr
        // string and will be conditionally rendered in the SALT via an assert.
        const result = [];
        const expr = this.asConditionalExprString(myContext);
        const exprWithQualifiers = this.asQualifierExprString(assertExpr ? `${assertExpr} and ${expr}` : expr);
        Base.children(myContext).forEach(c => result.push(c.asSalt(myContext, exprWithQualifiers)));
        Base.elseChildren(myContext).forEach(c => {
            // For each else, add the else children with a negated expression.
            const childContext = c.updateContext(myContext);
            const elseExprWithQualifiers = this.asQualifierExprString(assertExpr ? `${assertExpr} and ((${expr}) = false)` : `((${expr}) = false)`);
            Base.children(childContext).forEach(c2 => {
                result.push(c2.asSalt(myContext, elseExprWithQualifiers));
            });
        });
        return result;
    }

    asConditionalExprString(context) {
        if (!this.hasAllNeededProps(context)) {
            return 'true'; // Do not enhance the expression... i.e. condition passed
        }
        let myExpr = this.buildConditionalExprString();
        // Process any operator children.  These are children <and.../> <or.../>
        Base.operatorChildren(this.json).forEach(e => {
            if (e.hasAllNeededProps(context)) { // If the operator is valid, add it to the expression.
                myExpr = `${myExpr} ${e.operatorType} (${e.asConditionalExprString(context)})`;
            }
        });

        return myExpr;
    }

    buildConditionalExprString() {
        const propName = this.getPropertyValue() || ''; // Will always have a propertyValue at this point.
        let propExpr;
        if (propName.startsWith(CONST_PREFIX_GML)) {
            const asRef = `${REF_PREFIX}${propName.substring(CONST_PREFIX_GML.length)}`;
            propExpr = `$ref('${asRef}')`;
        } else {
            propExpr = `$prop('${propName}')`;
        }
        return `${this.getResolvedOperatorFunction()}(${propExpr}, ${this.getResolvedOperand()})`;
    }

    getPropertyValue() {
        return GmlUtil.getValueForExpr(this.json, [ CONDITIONAL_A.property ]);
    }

    getOperatorValue() {
        return GmlUtil.getValueForExpr(this.json, [ CONDITIONAL_A.operator ]);
    }

    getOperandConstantValue() {
        return GmlUtil.getValueForExpr(this.json, [ CONDITIONAL_A.operandConstant ]);
    }

    getOperandPropertyValue() {
        return GmlUtil.getValueForExpr(this.json, [ CONDITIONAL_A.operandProperty ]);
    }

    getResolvedOperand() {
        let oc = this.getOperandConstantValue() || '';
        if (SimpleStringAttribute.hasConstantRef(oc)) {
            oc = `$ref('${SimpleStringAttribute.asRefName(oc)}')`;
        } else if (oc?.includes(SESSION_PREFIX_GML)) {
            oc = this.getResolvedSessionValue(oc);
        } else if (oc) {
            oc = `'${oc}'`; // Add quotes around the constant
        }
        const op = this.getOperandPropertyValue() || '';
        if (op?.includes(SESSION_PREFIX_GML)) {
            oc = this.getResolvedSessionValue(op);
        }
        return oc ? `${oc}` : `$prop('${op}')`;
    }

    getResolvedSessionValue(param) {
        const sessionPropName = param.substring(SESSION_PREFIX_GML.length);
        return `$sessionValue('${sessionPropName}')`;
    }

    getResolvedOperatorFunction() {
        let operator = '$eq';
        switch (this.getOperatorValue()) {
            case 'EQ':
                operator = '$eq';
                break;
            case 'NE':
                operator = '$ne';
                break;
            case 'LT':
                operator = '$lt';
                break;
            case 'LE':
                operator = '$lte';
                break;
            case 'GT':
                operator = '$gt';
                break;
            case 'GE':
                operator = '$gte';
                break;
            default: // use the previously assigned default
        }
        return operator;
    }

    hasAllNeededProps(context) {
        const isValid = !!this.getPropertyValue() && !!this.getOperatorValue()
            && (!!(typeof this.getOperandConstantValue() === 'string') || !!this.getOperandPropertyValue());
        if (!isValid) {
            const { warnings } = context;
            const type = Base.pvtElementName(this.json); // Could be conditional/and/or/else
            warnings.addInvalidConditional(type);
        }
        return isValid;
    }
}
