import SimpleStringAttribute from '../SimpleStringAttribute';

const VALUES = {
    TRUE: 'true',
    FALSE: 'false',
};

// see: https://www.w3.org/TR/css-color-3/#svg-color
//      https://facebook.github.io/react-native/docs/colors
// const DEBUG_COLORS_LEGACY = [ 'blue', 'red', 'green', 'cyan', 'yellow', 'grey', 'magenta', 'lightGrey' ];
const DEBUG_COLORS_BROWNS = [ 'cornsilk', 'bisque', 'navajowhite', 'burlywood', 'tan', 'rosybrown', 'sandybrown', 'goldenrod', 'darkgoldenrod', 'peru', 'chocolate', 'saddlebrown', 'sienna' ];
const DEBUG_COLORS_REDS = [ 'indianred', 'lightcoral', 'salmon', 'darksalmon', 'lightsalmon', 'crimson', 'red', 'firebrick', 'darkred' ];
const DEBUG_COLORS = DEBUG_COLORS_BROWNS.concat(DEBUG_COLORS_REDS);


export default class DebugColor extends SimpleStringAttribute {
    static debugColorIndex = 0;

    nextColor = () => {
        const nextColor = DEBUG_COLORS[DebugColor.debugColorIndex];
        DebugColor.debugColorIndex += 1;
        if (DebugColor.debugColorIndex >= DEBUG_COLORS.length) {
            DebugColor.debugColorIndex = 0;
        }
        return nextColor;
    }

    exportTo(context, styleSet) {
        const { style } = styleSet;
        if (this.value === VALUES.TRUE) {
            style.backgroundColor = this.nextColor();
        }
    }
}
