import React from 'react';
import * as PropTypes from 'prop-types';
import { Polyline } from '../MapsWrapper';

/**
 * MapRoutes component allows a user to display Routes on Google map.
 * @see https://github.com/fullstackreact/google-maps-react#polygon
 * @see https://developers.google.com/maps/documentation/javascript/reference/polygon#Polyline
 */
const MapRoutes = (props) => {
    const {
        routes,
        strokeWidth,
        ...remainingProps
    } = props;

    const getGoogleCoordinates = (arr) => {
        const points = [];

        arr.forEach((obj) => {
            points.push({
                lat: obj.latitude,
                lng: obj.longitude,
            });
        });
        return points;
    };

    return routes.map((route, idx) => (
        <Polyline
            key={ `line_${idx + 1}` }
            path={ getGoogleCoordinates(route.coordinates) }
            strokeWeight={ strokeWidth }
            strokeColor={ route.color }
            { ...remainingProps } />
    ));
};

MapRoutes.propTypes = {
    /** Array of routes that contains information to display paths between selected points */
    routes: PropTypes.arrayOf(PropTypes.shape({
        /** Coordinates array of the route to be displayed */
        coordinates: PropTypes.array,

        /** Color of the route to be displayed */
        color: PropTypes.string,
    })),

    /** Width of the route */
    strokeWidth: PropTypes.number,
};

MapRoutes.defaultProps = {
    routes: [],
    strokeWidth: 1,
};

export default MapRoutes;