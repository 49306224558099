// Core component styles
const coreStyles = {
    actions: {
    },

    content: {
    },

    divider: {
    },

    header: {
    },

    modal: {
    },

    overlay: {
    },

    title: {
    },
};

/**
 * Combines coreStyles and contextStyles
 * @param {Object} contextStyles
 */
const getStyles = (contextStyles = {}) => {
    return {
        actions: {
            ...coreStyles.actions,
            ...contextStyles.actions,
        },

        content: {
            ...coreStyles.content,
            ...contextStyles.content,
        },

        divider: {
            ...coreStyles.divider,
            ...contextStyles.divider,
        },

        header: {
            ...coreStyles.header,
            ...contextStyles.header,
        },

        modal: {
            ...coreStyles.modal,
            ...contextStyles.modal,
        },

        overlay: {
            ...coreStyles.overlay,
            ...contextStyles.overlay,
        },

        title: {
            ...coreStyles.title,
            ...contextStyles.title,
        },
    };
};

export default getStyles;
