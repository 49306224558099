import {
    FILTER_OPERATOR_SUFFIX,
    FILTER_VALUE_SUFFIX,
    KEYWORD_PROPERTY_NAME,
    SORT_DIRECTION_SUFFIX,
    SORT_SEQUENCE_SUFFIX,
    SortDirectionEnum,
} from 'cv-dialog-sdk';
import { action, computed, observable } from 'mobx';

import DetailsDialogStore from './DetailsDialogStore';

export default class SearchDialogStore extends DetailsDialogStore {
    @observable submitInProgress = false;

    @action clearAllValues() {
        this.clearSearchValues();
        this.clearSortValues();
    }
    /**
     * Clear the search values currently in the buffer.  Does not submit search.
     */
    @action clearSearchValues() {
        this.propertyNames.filter((propName) => {
            return propName.endsWith(FILTER_OPERATOR_SUFFIX)
                || propName.endsWith(FILTER_VALUE_SUFFIX) || propName === KEYWORD_PROPERTY_NAME;
        })
            .forEach((propName) => {
                this.setPropertyValue(propName, null);
            });
    }

    /**
     * Clear the sort values currently in the buffer. Does not submit search.
     */
    @action clearSortValues() {
        this.propertyNames.filter((propName) => {
            return propName.endsWith(SORT_DIRECTION_SUFFIX)
                || propName.endsWith(SORT_SEQUENCE_SUFFIX);
        })
            .forEach((propName) => {
                this.setPropertyValue(propName, null);
            });
    }

    /**
     * Get the currently set sort values
     * @return [ {name, direction, priority} ]
     */
    @computed get sortValues() {
        const sortValues = [];
        this.propertyNames.forEach((propName) => {
            if (propName.endsWith(SORT_DIRECTION_SUFFIX)) {
                const actualPropName = this.dialog.getPropertyNameForSearchPropertyName(propName);
                const isAscending = this.isAscending(actualPropName);
                if (isAscending !== null) {
                    const sortPriorityProperty = this.getSortPriorityPropertyForName(actualPropName);
                    const priority = sortPriorityProperty ? sortPriorityProperty.value : 0;
                    sortValues.push({
                        name: actualPropName,
                        isAscending,
                        priority,
                    });
                }
            }
        });
        sortValues.sort((a, b) => a.priority - b.priority);
        return sortValues;
    }

    /**
     * Get a list of property names that are 'searchable'
     * @return {*}
     */
    getSearchablePropertyNames() {
        return this.dialog.getSearchablePropertyNames();
    }

    /**
     * Get a list of all property names that are 'sortable'
     * @return {*}
     */
    getSortablePropertyNames() {
        return this.dialog.getSortablePropertyNames();
    }

    /**
     * Get the current search value for a property name, as a Property
     * @param propName
     * @param useBuffer
     */
    getSearchValuePropertyForName(propName, useBuffer) {
        return this.getProperty(propName + FILTER_VALUE_SUFFIX, useBuffer);
    }

    /**
     * Get the PropertyDef associated with the search value Property
     * @param propName
     */
    getSearchValuePropertyDefForName(propName) {
        return this.dialog.propDefAtName(propName + FILTER_VALUE_SUFFIX);
    }

    /**
     * Get the current keyword search value.  This is a global substring search
     * across all properties
     */
    getKeywordSearchValueProperty(useBuffer = true) {
        return this.getProperty(KEYWORD_PROPERTY_NAME, useBuffer);
    }

    /**
     * Get the current search operator for a property name, as a Property
     * @param propName
     */
    getSearchOpPropertyForName(propName) {
        return this.getProperty(propName + FILTER_OPERATOR_SUFFIX);
    }

    /**
     * Get the PropertyDef associated with the search operator Property
     * @param propName
     */
    getSearchOpPropertyDefForName(propName) {
        return this.dialog.propDefAtName(propName + FILTER_OPERATOR_SUFFIX);
    }

    /**
     * Get the current sort direction for a property name, as a Property
     * @param propName
     */
    getSortDirectionPropertyForName(propName) {
        return this.getProperty(propName + SORT_DIRECTION_SUFFIX);
    }

    /**
     * Get the PropertyDef associated with the sort direction
     * @param propName
     */
    getSortDirectionPropertyDefForName(propName) {
        return this.dialog.propDefAtName(propName + SORT_DIRECTION_SUFFIX);
    }

    /**
     * Get the current sort priority for a property name, as a Property
     * @param propName
     */
    getSortPriorityPropertyForName(propName) {
        return this.getProperty(propName + SORT_SEQUENCE_SUFFIX);
    }

    /**
     * Get the PropertyDef associated with the sort priority
     * @param propName
     */
    getSortPriorityPropertyDefForName(propName) {
        return this.dialog.propDefAtName(propName + SORT_SEQUENCE_SUFFIX);
    }

    /**
     * Returns whether or not the sort direction for a property is set to ascending
     * @param propName
     */
    isAscending(propName) {
        const p = this.getProperty(propName + SORT_DIRECTION_SUFFIX);
        if (!p || !p.value) {
            return null;
        }

        // If the value is returned as a coderef we check the value code
        if (typeof p.value === 'object') {
            return p.value.code ? p.value.code === 'ASC' : false;
        }

        // If it is returned as a string we just check the value
        return p.value.startsWith('A');
    }

    /**
     * Returns whether or not the sort direction for a property is set to descending
     * @param propName
     */
    isDescending(propName) {
        const p = this.isAscending(propName);
        return p === null ? null : !p;
    }

    /**
     * Reopen the search dialog for writing
     */
    @action reopenSearch() {
        return this.openWriteMode();
    }

    /**
     * Set the sort direction for a property to ascending
     * @param propName
     * @param sortFieldPriority
     */
    @action setAscending(propName, sortFieldPriority = 0) {
        this.setSortValue(propName, SortDirectionEnum.ASC, sortFieldPriority);
    }

    /**
     * Set the sort direction for a property to descending
     * @param propName
     * @param sortFieldPriority
     */
    @action setDescending(propName, sortFieldPriority = 0) {
        this.setSortValue(propName, SortDirectionEnum.DESC, sortFieldPriority);
    }

    /**
     * Set the search value for a property
     * @param propName
     * @param searchValue
     */
    @action setSearchValue(propName, searchValue) {
        this.setPropertyValue(propName + FILTER_VALUE_SUFFIX, searchValue);
    }

    /**
     * Set the search value for the keyword search.  This is a global substring search
     * across all properties
     * @param keyword
     */
    @action setKeywordSearchValue(keyword) {
        this.setPropertyValue(KEYWORD_PROPERTY_NAME, keyword);
    }

    /**
     * Set the filter operator type for a property to one of [[FilterOperatorEnum]]
     * @param propName
     * @param operator
     */
    @action setSearchValueOperation(propName, operator) {
        this.setPropertyValue(propName + FILTER_OPERATOR_SUFFIX, operator);
    }

    /**
     * Set the sort direction for a property to one of [[SortDirectionEnum]]
     * @param propName
     * @param sortDirection
     * @param sortFieldPriority
     */
    @action setSortValue(propName, sortDirection = SortDirectionEnum.ASC, sortFieldPriority = 0) {
        this.setPropertyValue(propName + SORT_DIRECTION_SUFFIX, sortDirection);
        this.setPropertyValue(propName + SORT_SEQUENCE_SUFFIX, sortFieldPriority);
    }

    /**
     * Submit the search dialog (write)
     */
    @action submitSearch() {
        return this.writeRecord();
    }
    
    @action setSubmitInProgress(value) {
        this.submitInProgress = value;
    }

    getSubmitInProgress() {
        return this.submitInProgress;
    }

    isQuickSearchAllowed() {
        const { quickSearchAllowed } = this.dialog;
        // Handle missing property for backwards compatibility.
        if (quickSearchAllowed === undefined) return true;
        return quickSearchAllowed === 'true' ? true : false;
    }
}
