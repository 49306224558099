import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import ParseHTML, { convertNodeToElement } from 'react-html-parser';

import XaltInputBase from '../XaltInputBase/XaltInputBase';
import styleParser from '../utilities/styleHelpers/styleParser';

const SUPPORTED_TAGS = [
    'a',
    'b',
    'br',
    'font',
    'img',
    'i',
    'li',
    'p',
    'span',
    'u',
];

const propDefinition = {

    /** Styles for this component */
    style: PropTypes.object,

    /** Prevents the text field from editing input */
    readOnly: PropTypes.bool,

    /** The text to populate in the input */
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
};

/**
 * A platform component for building an html element or input field.
 */
export default class XaltHtmlTextField extends Component {
    static propTypes = propDefinition;

    static defaultProps = {
        style: {},
        readOnly: false,
    };

    render() {
        const {
            style,
            readOnly,
            value,
        } = this.props;

        if (readOnly) {
            return this.renderHtml(style, value);
        }

        // If this component is not read-only simply render a standard input field.
        return React.createElement(XaltInputBase, { ...this.props });
    };

    renderHtml = (style, value) => {
        const textStyles = styleParser.parseTextStyles(style);
        return (
            <div style={ textStyles }>
                { this.formatAsHtml(value) }
            </div>
        );
    }

    /**
     * Adding support for legacy HTML tags
     * @see https://help.adobe.com/en_US/FlashPlatform/reference/actionscript/3/flash/text/TextField.html#htmlText
     *
     * @typedef {Object} HtmlParserNode
     * @property {String} name
     * @property {String} type
     *
     * @param {HtmlParserNode} node
     * @param {Number} index
     * @return {undefined|React.Element}
     */
     parseHtmlNode = (node, index) => {
        const {
            name,
            type,
        } = node;

        // Unsupported tag, get and display any text nodes
        if (
            !SUPPORTED_TAGS.includes(name) &&
            (type === 'tag' || type === 'script' || type === 'style')
        ) {
            // Convert all unsupported tags to spans
            return convertNodeToElement({
                ...node,
                name: 'span',
                type: 'tag',
            }, index, this.parseHtmlNode);
        }

        // No changes, continue processing
        return undefined;
    };

    formatAsHtml(textString) {
        try {
            return ParseHTML(textString, {
                decodeEntities: true,
                transform: this.parseHtmlNode,
            });
        }
        catch (e) {
            // Log.warn('Error parsing HTML: ', e, textString);
            return '';
        }
    }
}
