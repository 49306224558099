// Core component styles
const coreStyles = {
    expandContainer: {
        flexShrink: 0,
    },
    menuContainer: {
        padding: '5px',
        flexShrink: 0,
        borderColor: '#E0E0E0',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '4px',
    },
    listToggler: {
        flexShrink: 0,
    },
};

/**
 * Helper method to get the styles for the component
 * @param {Object} contextStyles - Context styles for the Component
 */
const getStyles = (contextStyles = {}) => {
    return {
        expandContainer: {
            ...coreStyles.expandContainer,
            ...contextStyles.expandContainer,
        },
        menuContainer: {
            ...coreStyles.menuContainer,
            ...contextStyles.menuContainer,
        },
        listToggler: {
            ...coreStyles.listToggler,
            ...contextStyles.listToggler,
        },
    };
};

export default getStyles;
