import { LINE } from '../../exportClassConstants';
import SimpleStringAttribute from '../SimpleStringAttribute';

export default class LineColor extends SimpleStringAttribute {
    constructor(value) {
        super('line-color', value, [ LINE ], true);
    }
    exportTo(context, styleSet) {
        const { style } = styleSet;
        if (SimpleStringAttribute.hasConstantRef(this.value)) {
            SimpleStringAttribute.exportConstantAsRef(style, 'backgroundColor', this.value);
        } else {
            style.backgroundColor = this.value;
        }
    }
}
