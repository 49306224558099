import { TypeNames } from './types';
export class CodeRef {
    constructor(code, description, type = TypeNames.CodeRefTypeName) {
        this.code = code;
        this.description = description;
        this.type = type;
    }
    toString() {
        return this.code + ':' + this.description;
    }
    static fromString(codeRef) {
        if (!codeRef) {
            return null;
        }
        const [code, description] = codeRef.split(':');
        return new CodeRef(code, description);
    }
}
