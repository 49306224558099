const getStyles = () => {
    return {
        modal: {
            paddingTop: '0px',
            paddingBottom: '0px',
            paddingLeft: '0px',
            paddingRight: '0px',
            minWidth: '25vw',
        },
        iconContainer: {
            paddingTop: '2px',
            paddingBottom: '2px',
            paddingLeft: '2px',
            paddingRight: '2px',
            marginLeft: '8px',
            backgroundColor: '#121212',
        },
        icon: {
            fontSize: 14,
            color: '#ffffff',
        },
    };
};

export default getStyles;
