import React from 'react';
import * as PropTypes from 'prop-types';
import getStyles from './HorizontalForm.styles';
import Resizable from '../Resizable/Resizable';

const HorizontalForm = (props) => {
    const {
        contextStyles,
        formElements,
        onResizeStop,
        scrollIdentifier,
    } = props;

    const {
        anchorScrollColumn,
        mainContentRowSizingContainer,
        column,
    } = getStyles(contextStyles);

    let equalWidth;
    if (formElements.length < 4) {
        equalWidth = `${100 / formElements.length}%`;
    }

    return (
        <div
            className="anchor-column-scollar-container"
            data-scroll-identifier={ scrollIdentifier }
            style={ anchorScrollColumn }>
            <div
                className="horizontal-content-row"
                style={ mainContentRowSizingContainer }>
                {
                    formElements.map((formElement, index) => {
                        const {
                            props: {
                                dimensions,
                                viewId,
                                isDetailsView,
                            },
                        } = formElement;
                        let defaultWidth;
                        const minWidth = isDetailsView ? { minWidth: 'max-content' } : undefined;
                        if (dimensions.width) {
                            defaultWidth = dimensions.width;
                        }
                        else {
                            defaultWidth = isDetailsView ? 'max-content' : '600px';
                        }
                        if (equalWidth) {
                            defaultWidth = equalWidth;
                        }
                        const marginLeftStyle = index < 1 ? undefined : { marginLeft: '16px' };
                        return (
                            <Resizable
                                className="horizontal-column-content"
                                defaultSize={ {
                                    width: defaultWidth,
                                    height: dimensions.height,
                                } }
                                direction="horizontal"
                                key={ `horizontal-column-content-${viewId}` }
                                onResizeStop={ onResizeStop }
                                style={ { ...marginLeftStyle, ...column, ...minWidth } }
                                viewid={ viewId }>
                                { formElement }
                            </Resizable>
                        );
                    })
                }
            </div>
        </div>
    );
};

HorizontalForm.propTypes = {
    contextStyles: PropTypes.shape({
        anchorScrollColumn: PropTypes.object,
        mainContentRowSizingContainer: PropTypes.object,
        column: PropTypes.object,
    }),
    formElements: PropTypes.arrayOf(PropTypes.element),
    scrollIdentifier: PropTypes.string,
    onResizeStop: PropTypes.func,
};

HorizontalForm.defaultProps = {
    contextStyles: {},
    formElements: [],
};

export default HorizontalForm;
