import React, { useState } from 'react';
import * as PropTypes from 'prop-types';

import getStyles from './PasswordField.styles';

import TextField, { INPUT_TYPES } from '../TextField/TextField';
import IconButton, { ICON_SIZE } from '../IconButton/IconButton';


/**
 * A component that allows users to add or modify password input with a show/hide control.
 * @see https://material-ui.com/demos/text-fields/
 */
const PasswordField = (props) => {
    const {
        contextStyles,
        disabled,
        iconSize,
        onShowPasswordChange,
        showPassword,
        testID,
        ...rest
    } = props;
    const [
        visible,
        setVisibility,
    ] = useState(false);

    // Override core styles with context styles
    const {
        adornmentButton,
        adornmentIcon,
        ...restContextStyles
    } = getStyles(contextStyles);
    const passwordVisible = showPassword || visible;

    return (
        <TextField
            contextStyles={ restContextStyles }
            disabled={ disabled }
            endAdornment={
                <IconButton
                    contextStyles={ {
                        button: adornmentButton,
                        icon: adornmentIcon,
                    } }
                    disabled={ disabled }
                    iconName={ passwordVisible ? 'visibility_off' : 'visibility' }
                    iconSize={ iconSize }
                    onClick={ () => {
                        if (onShowPasswordChange) {
                            onShowPasswordChange(!showPassword);
                        }
                        else {
                            setVisibility((prevShow) => (!prevShow));
                        }
                    } } />
            }
            iconOffsetWidth={ 15 }
            multiline={ false }
            testID={ testID }
            { ...rest }
            type={ passwordVisible ? INPUT_TYPES.TEXT : INPUT_TYPES.PASSWORD } />
    );
};

PasswordField.propTypes = {
    /** Focuses the input */
    autoFocus: PropTypes.bool,

    /** Grows/shrinks the input based on the size of its text value */
    autoGrow: PropTypes.bool,

    /** Styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the container surrounding the text field */
        container: PropTypes.object,

        /** Styles for the text field */
        input: PropTypes.object,

        /** Styles for the adornment button */
        adornmentButton: PropTypes.object,

        /** Styles for the adornment icon */
        adornmentIcon: PropTypes.object,
    }),

    /** Prevents the text field from accepting input */
    disabled: PropTypes.bool,

    /** Size of the icon to be rendered. */
    iconSize: PropTypes.oneOfType([
        /** Font size of the SVG icon */
        PropTypes.number,

        /** Icon size presets */
        PropTypes.oneOf([
            ICON_SIZE.DEFAULT,
            ICON_SIZE.INHERIT,
            ICON_SIZE.LARGE,
            ICON_SIZE.SMALL,
        ]),
    ]),

    /** Limits the maximum number of characters that can be entered */
    maxLength: PropTypes.number,

    /**
     * Called when the TextField's input changes
     * @param {Object} event - The native change event
     * @param {Object} props - The component instance props
     */
    onChange: PropTypes.func.isRequired,

    /**
     * Called when the password visibility is toggled
     * @param {Boolean} showPassword - The current value of showPassword
     */
    onShowPasswordChange: PropTypes.func,

    /**
     * Called when enter is pressed on TextField
     * @param {Object} event - The native key press event
     * @param {Object} props - The component instance props
     */
    onSubmit: PropTypes.func,

    /** Indicates the password visibility */
    showPassword: PropTypes.bool,

    /** Elements that needs to be placed at the beginning of the TextField */
    startAdornment: PropTypes.node,

    /** Id used for testing */
    testID: PropTypes.string,

    /** The text to populate in the input */
    value: PropTypes.string.isRequired,
};

PasswordField.defaultProps = {
    contextStyles: {},
    iconSize: 18,
    testID: 'PasswordTextField',
};

export default PasswordField;
export {
    ICON_SIZE,
    INPUT_TYPES,
};
