import PropTypes from 'prop-types';
import React, { Component } from 'react';

import SaltStore from '../stores/SaltStore';
import Globals from './ref/Globals';
import SaltContext from './SaltContext';
import ScopeManager from './ScopeManager';


export default class EngineContext extends Component {
    static propTypes = {
        children: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.arrayOf(PropTypes.element),
        ]),
        engineParams: PropTypes.shape({
            dialogStore: PropTypes.object,
            uiStore: PropTypes.object,
            onTransition: PropTypes.func,
            onError: PropTypes.func,
        }),
    };

    render() {
        const { engineParams, children } = this.props;
        // if there are no parameters, assume we already have a context
        if (!engineParams) {
            return children;
        }
        // if we already have a top-level context then don't create another
        // We only want one SaltStore for each FormDialog and it's children
        const { saltStore: saltStoreIsAlreadyThere } = this.context;
        if (saltStoreIsAlreadyThere) {
            return children;
        }

        const {
            dialogStore,
            uiStore,
            onTransition,
            onError,
            ...rest
        } = engineParams;

        const saltStore = new SaltStore(dialogStore, uiStore, new Globals(dialogStore.sessionStore, uiStore));
        const scopeManager = new ScopeManager();
        const contextParams = {
            saltStore,
            scopeManager,
            onTransition,
            onError,
            ...rest,
        };
        return <SaltContext.Provider value={ contextParams }>{children}</SaltContext.Provider>;
    }
}

EngineContext.contextType = SaltContext;
