/*       */


import LineColor from '../line/LineColor';
import SimpleXStyleLayoutAttribute from '../SimpleXStyleLayoutAttribute';
import { LINE } from '../../exportClassConstants';
import AbstractLayoutFactory from './AbstractLayoutFactory';

class LineLayoutFactory extends AbstractLayoutFactory {
    constructor() {
        super();
        const attributesAndAliases = [
            'lineColor',
            'lineWidth:lineThickness',
        ];
        this.init(attributesAndAliases);
    }

    initFactory() {
        super.initFactory();
        const { factory } = this;
        factory.lineColor = (json, key) => {
            return new LineColor(this.getAsString(json, key));
        };
        factory.lineWidth = (json, key) => {
            return new SimpleXStyleLayoutAttribute('lineWidth', this.getAsString(json, key), [ LINE ], true);
        };
    }
}

const lineLayoutFactory = new LineLayoutFactory();
export default lineLayoutFactory;
