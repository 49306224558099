/**
 * Defines how to present a business-value in a UI component
 */
export class Column {
    constructor(propertyName, entryMethod, heading, displayInline, defaultActionId, maintainable, spacer, initialDisplay, headingGroup) {
        this.propertyName = propertyName;
        this.entryMethod = entryMethod;
        this.heading = heading;
        this.displayInline = displayInline;
        this.defaultActionId = defaultActionId;
        this.maintainable = maintainable;
        this.spacer = spacer;
        this.initialDisplay = initialDisplay;
        this.headingGroup = headingGroup;
    }
    get isComboBoxEntryMethod() {
        return this.entryMethod && this.entryMethod === 'ENTRY_METHOD_COMBO_BOX';
    }
    get isDropDownEntryMethod() {
        return this.entryMethod && this.entryMethod === 'ENTRY_METHOD_DROP_DOWN';
    }
    get isIconEntryMethod() {
        return this.entryMethod && this.entryMethod === 'ENTRY_METHOD_ICON_CHOOSER';
    }
    get isTextFieldEntryMethod() {
        return !this.entryMethod || this.entryMethod === 'ENTRY_METHOD_TEXT_FIELD';
    }
}
