// Core component styles
const coreStyles = {
    container: {
        display: 'inline-block',
    },

    check: {
    },

    checked: {
    },

    disabled: {
    },
};

/**
 * Combines coreStyles and contextStyles
 * @param {Object} contextStyles
 */
const getStyles = (contextStyles = {}) => {
    // Pull out any width/height styles for the icon size
    const checkStyles = {
        ...coreStyles.check,
        ...contextStyles.check,
    };
    const {
        width,
        height,
        ...restCheckStyles
    } = checkStyles;

    return {
        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        check: {
            ...restCheckStyles,

            // For a 'checked' state
            '&.Mui-checked': {
                ...coreStyles.checked,
                ...contextStyles.checked,
            },

            // For a 'disabled' state
            '&.Mui-disabled': {
                ...coreStyles.disabled,
                ...contextStyles.disabled,
            },

            '& .MuiSvgIcon-root': {
                width,
                height,
            },
        },
    };
};

export default getStyles;
