
// Not enable flow at this time due to bad getter/setter error thrown by flow.
const namespace = 'cv-react-native';

export class storage {
    static get USER_CREDS() { return `${namespace}.USER_CREDS`; }
    static get TENANT_ID() { return `${namespace}.TENANT_ID`; }
    static get SESSION_ID() { return `${namespace}.session_ID`; }
    static get WORKBENCH_ID() { return `${namespace}.WORKBENCH_ID`; }
    static get SELECTED_WORKBENCH_ID() { return `${namespace}.SELECTED_WORKBENCH_ID`; }
    static get OPEN_DIALOG_IDS() { return `${namespace}.OPEN_DIALOG_IDS`; }
    static get UI_STATE() { return `${namespace}.UI_STATE`; }
    static get SETTINGS() { return `${namespace}.settings`; }
    static get THEME() { return `${namespace}.THEME`; }
    static get FCM_TOKEN() { return `${namespace}.FCM_DEVICE_ID`; }
    static get APP() {
        return {
            get META() { return `${namespace}.APP_META`; },
            get VERSION() { return `${namespace}.APP_VERSION`; },
        };
    }
    static get CACHE() { return `${namespace}.cache`; }
    static get CACHE_STORES() {
        return {
            SESSION: 'session',
            SETTINGS: 'settings',
            WORKBENCH: 'workbench',
            UI: 'ui',
            THEME: 'theme',
        };
    }
    static get SESSION() { return `${namespace}.SESSION`; }
}
