/*       */

import AbstractGml from './AbstractGml';
import { GENERAL_MARGIN, LINE } from '../exportClassConstants';
import StyleSet from '../StyleSet';

export default class Line extends AbstractGml {
    asSalt(parentContext, assertExpr = '') {
        const myContext = this.updateContext(parentContext);
        const styleSet = new StyleSet();
        this.exportStyleTo(styleSet, myContext, [ LINE, GENERAL_MARGIN ]);

        return {
            box: {
                ...this.asAssertAttribute(this.asQualifierExprString(assertExpr)),
                ...styleSet.asStyleAttribute(),
                ...styleSet.asXStyleAttribute(),
                children: [],
            },
        };
    }
}
