import { constants } from '../constants';
import { utilities } from '.';
import { serviceFactory } from '../services';
/**
 * A collection of utility functions.
 */
// TODO: This logic is now more in line with the gridlist formatting. This needs to be pulled into a common location
// for formatting grid data for accurate column sizing and consistency.
const formatGridData = (row, property, propDef, value, viewDef = {}) => {
    const { annotationHelper, uiHelper } = utilities;
    const { lang } = serviceFactory;
    let newValue = value;
    if (propDef.isBooleanType) {
        const booleanValue = uiHelper.parse(property, propDef);
        if (booleanValue) {
            newValue = lang.generic.yes;
        } else {
            newValue = (booleanValue === null) ? '' : lang.generic.no;
        }
    } else {
        // Get display value
        newValue = uiHelper.formatPropertyForRead(property, propDef) || '\u00A0'; // hexcode for non-breaking space; Helps to keep the row from shrinking really small
    }
    // Apply text annotation styles
    newValue = annotationHelper.getOverrideText(row, property, newValue);
    if (property.isPlacementCenter || row.isPlacementCenter || (viewDef.displayMediaInline && propDef.isURLType)) {
        newValue = '';
    }
    return newValue;
};

const getDialogId = (dialogStore) => {
    return dialogStore.dialog.id;
};
const getKeyExtractor = (record) => {
    return record.id;
};
const getSelectedRecords = (uiStore, dialogStore) => {
    return dialogStore.selectedRecords; // uiStore.getValueForUIObject(id, constants.ui.SELECTED_RECORDS);
};
const selectedAsArray = (selected) => {
    const array = [];
    Object.entries(selected).forEach(entry => {
        if (entry[1]) { array.push(entry[0]); }
    });
    return array;
};

const formatColumnSizeFontStyles = (style) => {
    const fontStyle = style.fontStyle ? `${style.fontStyle} ` : '';
    const fontVariant = style.fontVariant ? `${style.fontVariant} ` : '';
    const fontWeight = style.fontWeight ? `${style.fontWeight} ` : '';
    const fontSize = style.fontSize ? `${style.fontSize}px ` : '16px ';
    const fontFamily = style.fontFamily || 'Roboto';
    const textTransform = style.textTransform || '';
    const font = style.font || `${fontStyle}${fontVariant}${fontWeight}${fontSize}${fontFamily}`;
    return {
        font,
        textTransform,
    };
};

// Get value size based on 2D canvas
const getColumnSize = (value, font, textTransform) => {
    // re-use canvas object for better performance
    const canvas = getColumnSize.canvas || (getColumnSize.canvas = document.createElement('canvas'));
    const context = canvas.getContext('2d');
    let text = value;
    if (textTransform) {
        text = text.toUpperCase();
    }
    context.fillText(text, 0, 0);
    context.font = font;
    const metrics = context.measureText(text);
    // Helper to offset the size of the text based on font size and value size.
    const offsetAdjustment = font && font.fontSize ? font.fontSize / 10 : 1;
    return Math.ceil((metrics.width * offsetAdjustment) + 18);
};

const single = 'single';
const multi = 'multi';
const none = 'none';
const ignore = 'ignore';
const DOT_REPLACEMENT = '__$__';

/**
 * @todo - Need to deprecate the usage of this helper class to manage selection criteria for a list.
 * Please do not extend this any furthter to perform selection routines as we now have a store to more
 * effectively manage record selection state.
 */
const listHelper = {
    // Misc.
    getDialogId,
    getKeyExtractor,

    // Selected record logic.
    single,
    multi,
    none,
    ignore,
    formatGridData,
    initSelectedRecords: (uiStore, dialogStore) => {
        const id = getDialogId(dialogStore);
        // Only initialize if currently undefined.  This should only be called from a constructor,
        // but sometimes a constructor is called again based on I don't know what.
        if (!uiStore.getValueForUIObject(id, constants.ui.SELECTED_RECORDS)) {
            uiStore.setValueForUIObject(id, constants.ui.SELECTED_RECORDS, {});
        }
    },
    getSelectedAsArray: (uiStore, dialogStore) => {
        const selected = getSelectedRecords(uiStore, dialogStore);
        return selected ? selectedAsArray(selected) : [];
    },
    getSelectedRecords,
    getSelectedRange: (uiStore, dialogStore) => {
        const id = getDialogId(dialogStore);
        return uiStore.getValueForUIObject(id, constants.ui.SELECTED_RANGE) || { records: {}, columns: [] };
    },
    clearSelectedRecords: (uiStore, dialogStore) => {
        const id = getDialogId(dialogStore);
        uiStore.setValueForUIObject(id, constants.ui.SELECTED_RECORDS, {});
        dialogStore.deselectAllRecord();
    },
    selectRecords: (uiStore, dialogStore, objectIds = {}) => {
        const id = getDialogId(dialogStore);
        uiStore.setValueForUIObject(id, constants.ui.SELECTED_RECORDS, objectIds);
    },
    clearRange: (uiStore, dialogStore) => {
        const id = getDialogId(dialogStore);
        uiStore.removeValueForUIObject(id, constants.ui.SELECTED_RANGE);
    },
    selectRange: (uiStore, dialogStore, selectionRange) => {
        const id = getDialogId(dialogStore);
        uiStore.setValueForUIObject(id, constants.ui.SELECTED_RANGE, selectionRange);
    },
    isSelected: (uiStore, dialogStore, id) => {
        const selectedRows = getSelectedRecords(uiStore, dialogStore);
        return !!selectedRows[id];
    },
    selectRecord: (uiStore, dialogStore, objectId, selectionType) => {
        const id = getDialogId(dialogStore);
        let selected;
        switch (selectionType) {
            case multi:
                selected = Object.assign({}, getSelectedRecords(uiStore, dialogStore));
                if (!selected[objectId]) {
                    selected[objectId] = true;
                    dialogStore.setRecordSelectionState(objectId, true);
                }
                break;
            case none:
                selected = {};
                dialogStore.deselectAllRecord();
                break;
            case single: // single is default
                selected = {};
                selected[objectId] = true;
                dialogStore.deselectAllRecord();
                dialogStore.setRecordSelectionState(objectId, true);
                break;
            default: // ignore does nothing
        }
        if (selectionType !== ignore) {
            uiStore.setValueForUIObject(id, constants.ui.SELECTED_RECORDS, selected);
        }
    },
    deselectRecord: (dialogStore, objectId) => {
        dialogStore.setRecordSelectionState(objectId, false, false);
    },
    toggleSelectedRecord: (uiStore, dialogStore, objectId, selectionType) => {
        const id = getDialogId(dialogStore);
        let selected;
        // TODO: Need to refactor if a record is selected into a single entry to reduce
        // client rerenders based on a selection. Needs to be replaced with IS_RECORD_SELECTED
        // const selectionId = `${id}_${objectId}_selected`;
        switch (selectionType) {
            case multi:
                selected = Object.assign({}, getSelectedRecords(uiStore, dialogStore));

                if (selected[objectId]) {
                    delete selected[objectId];
                    dialogStore.setRecordSelectionState(objectId, false);
                } else {
                    selected[objectId] = true;
                    dialogStore.setRecordSelectionState(objectId, true);
                }
                break;
            case none:
                selected = {};
                dialogStore.deselectAllRecord();
                break;
            case single: // single is default
                // Uncomment this code to cause a single selection to toggle.  You can make a case either way,
                // but I'm thinking tapping a selected record keeps it selected.  As of this writing (10/2/2018)
                // there is no hard requirement for it to be one way or the other.
                // selected = Object.assign({}, getSelectedRecords(uiStore, dialogStore));
                // if (selected[objectId]) {
                //     delete selected[objectId];
                // } else {
                selected = {};
                selected[objectId] = true;
                dialogStore.deselectAllRecord();
                dialogStore.setRecordSelectionState(objectId, true);
                // }
                break;
            default: // ignore does nothing
        }
        if (selectionType !== ignore) {
            uiStore.setValueForUIObject(id, constants.ui.SELECTED_RECORDS, selected);
        }
    },
    // Return max column size for each record property
    getColumnSizes: (records, columns, font, maxSize = 700, recordDef, listDialogStore) => {
        const columnDataSizes = [];
        const { font: sizingFontFormat, textTransform } = formatColumnSizeFontStyles(font);
        records.forEach((row) => {
            columns.forEach((viewCol) => {
                const { propertyName: name, spacer } = viewCol;
                const foundColumn = columnDataSizes.find(col => col.name === name);
                if (spacer) {
                    if (!foundColumn) {
                        columnDataSizes.push({
                            name,
                            spacer,
                        });
                    }
                } else {
                    const property = listDialogStore.getProperty(row, name) || {};
                    const { value: propValue } = property;
                    const propDef = recordDef.propDefAtName(name) || {};
                    const isImageWithURL = viewCol.displayMediaInline && propDef.isURLType;
                    const isTextWithImage = !!(property.imageName || row.imageName) || isImageWithURL;
                    // TODO: This logic is now more in line with the gridlist formatting. This needs to be pulled into a common location
                    // for formatting grid data for accurate column sizing and consistency.
                    const value = formatGridData(row, property, propDef, propValue, viewCol);
                    let columnLength = getColumnSize(value, sizingFontFormat);
                    if (isTextWithImage) {
                        columnLength += 50;
                    }
                    if (foundColumn) {
                        foundColumn.columnLengths.push(columnLength);
                    } else {
                        columnDataSizes.push({
                            name,
                            columnLengths: [ columnLength ],
                        });
                    }
                }
            });
        });

        // For now I'm having to hard code this value as we are not driving style to the client to know
        // what type of offset to apply for icons. Just making a rough guess at the moment based on icon and padding size.
        const iconBuffer = 40;
        const columnData = columnDataSizes.map((column) => {
            // Get max column size for each property
            const { name, columnLengths, spacer } = column;
            if (spacer) {
                return {
                    columnName: name,
                    width: 20,
                };
            }
            const columnInfo = columns.find(c => c.propertyName === name);
            const { heading } = columnInfo || { heading: name };
            // We check the heading to see if it is the largest value
            const columnNameWidth = getColumnSize(heading, sizingFontFormat, textTransform);
            const width = Math.max(...columnLengths, columnNameWidth + iconBuffer);
            return {
                columnName: name.replace('.', DOT_REPLACEMENT),
                width: width > maxSize ? maxSize : width,
                wordWrapEnabled: true, // width > maxSize,
            };
        });
        return columnData;
    },
};

export default listHelper;
