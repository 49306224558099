import PropTypes from 'prop-types';
import React from 'react';

import engineConstants from './engineConstants';
import ReadRef from './ReadRef';
import SaltComponent from './SaltComponent';
import SaltContext from './SaltContext';
import WriteRef from './WriteRef';


/*
    Ref param configurations:
    1) A name only - this is a read operation (no write)
    2) A value or expression only - this is a read operation
    3) A name and (value or expr) - this is a write operation (with optional rendering of the resulting value)
*/
export default class Ref extends SaltComponent {
    static propTypes = {
        // unique name to identify the reference
        // if omitted, the resolved value is not stored, but may be returned (if no children)
        name: PropTypes.string,
        // Sets a value. with the jsonata expression to be applied to the dialog associated with the specified viewId
        // if expr is omitted , the assumption is that we are referencing a name already in scope
        expr: PropTypes.string,
        // Sets a value directly
        /* eslint-disable react/forbid-prop-types */
        value: PropTypes.any,
        // if children are included, scope is assumed to be local unless overridden
        children: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.arrayOf(PropTypes.element),
        ]),
        viewId: PropTypes.string,
        // if children is set, scope is assumed to be local unless otherwise specified
        // scope is assumed to be global in all other cases
        scope: PropTypes.string,
    };

    static typeName = engineConstants.component.name.ref;

    render() {
        return this.isWriteOp() ? <WriteRef { ...this.props } /> : <ReadRef { ...this.props } />;
    }

    isWriteOp() {
        const { name, expr, value } = this.props;
        return name && (expr || value);
    }
}

Ref.contextType = SaltContext;
