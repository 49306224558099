
class LayoutComponentFactory {
    getLayoutComponent(name) {
        if (!this.layoutComponentProvider) {
            throw new Error('No layout component provider set on engine layout component factory');
        }
        return this.layoutComponentProvider[name];
    }

    setLayoutComponentProvider(provider) {
        this.layoutComponentProvider = provider;
    }
}

const layoutComponentFactory = new LayoutComponentFactory();
export default layoutComponentFactory;
