import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import componentFactory from './componentFactory';
import SaltComponent from './SaltComponent';
import searchController from '../controllers/searchController';
import serviceFactory from '../services/serviceFactory';

@observer
export default class SearchSort extends SaltComponent {
    static propTypes = {
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        onError: PropTypes.func,
        viewId: PropTypes.string,
    };

    render() {
        const listDialogStore = this.getListDialogStore();
        const { searchDialogStore, searchDialogFormStore } = listDialogStore;

        // If we don't have any stores, just close up shop
        if (!searchDialogStore) return null;

        // If the search form is not open, just return nothing.
        const { isSearchFormOpen, readInProgress } = searchDialogStore;
        if (!isSearchFormOpen || readInProgress) return null;

        // Build filter term object for the clients to use.
        const { lang } = serviceFactory;
        const { dialog: listDialog } = listDialogStore;
        const { view: listView } = listDialog;
        const { columns } = listView;

        // Sort the property names so they match the order of the columns.
        const propertyNames = searchDialogStore.getSearchablePropertyNames();
        // Only need to build this once. Want to push this to the store.
        // NOTE: this is now a bad name after a refactor. This is simply a sorted list of property names.
        if (!this.filterTerms) {
            const sortedPropertyNames = propertyNames.sort((current, next) => {
                const start = columns.findIndex(c => c.propertyName === current);
                const end = columns.findIndex(c => c.propertyName === next);
                if (start > end) {
                    return 1;
                }
                return -1;
            });

            this.filterTerms = sortedPropertyNames.map((propertyName, index) => {
                // Get User friendly name.
                const column = columns.find(c => c.propertyName === propertyName);
                const { heading } = column;
                return {
                    key: `${propertyName}_${index}`,
                    propertyName,
                    propertyLabel: {
                        heading: heading || propertyName,
                    },
                };
            });
        };

        // TODO: Make this area more optimized and consistent for delivering needed sort info.
        // Build sort information
        const sortTerms = searchDialogStore.sortValues || [];
        sortTerms.forEach((term) => {
            const column = columns.find((col) => col.propertyName === term.name);
            // eslint-disable-next-line no-param-reassign
            term.label = column && column.heading ? column.heading : term.name;
        });

        // Only need to build this once. Want to push this to the store.
        if (!this.sortableValues) {
            const sortableNames = searchDialogStore.getSortablePropertyNames();
            const sortableColumns = columns.filter((col) => sortableNames.findIndex((sortName) => col.propertyName === sortName) > -1);
            this.sortableValues = sortableColumns.map(sortableColumn => {
                return {
                    name: sortableColumn.propertyName,
                    label: sortableColumn && sortableColumn.heading ? sortableColumn.heading : sortableColumn.propertyName,
                    isAscending: true,
                };
            });
        }

        const resolvedProps = this.resolveProperties();
        const { style, xStyle } = resolvedProps;
        const { dialog } = searchDialogFormStore;
        const { description } = dialog;
        const title = description || lang.searchSort.title;

        const props = {
            onCancel: this.handleCancel,
            onClear: this.handleClear,
            onSortClear: this.handleSortClear,
            onSubmit: this.handleSubmit,
            onSearchTermChange: this.handleSearchTermChange,
            onSortTermsChange: this.handleSortTermsChange,
            onSetFilterOperation: this.handleOnSetSearchPropertyOperation,
            onSetFilterValue: this.handleOnSetSearchPropertyValue,
            searchDialogStore,
            filterTerms: this.filterTerms, // NOTE: this is now a bad name after a refactor. This is simply a sorted list of property names.
            searchTerm: searchDialogStore.getKeywordSearchValueProperty(),
            showSearch: isSearchFormOpen,
            sortableValues: this.sortableValues,
            sortTerms,
            title,
            style,
            xStyle,
        };
        return React.createElement(componentFactory.getPlatformComponent('searchSort'), props);
    }

    getAvailableValues = (propertyName) => {
        const searchDialogStore = this.getSearchDialogStore();
        return searchDialogStore.getAvailableValues(propertyName);
    }

    getListDialogStore() {
        const {
            viewId,
        } = this.props;

        const { saltStore } = this.context;
        return saltStore.getDialogStoreForViewId(viewId);
    }

    getSearchDialogStore() {
        const listDialogStore = this.getListDialogStore();
        const { searchDialogStore } = listDialogStore;
        return searchDialogStore;
    }

    loadAvailablePropertyValues = (propertyName) => {
        const searchDialogStore = this.getSearchDialogStore();
        return searchDialogStore.updateAvailableValues(propertyName)
            .then(() => searchDialogStore.getAvailableValues(propertyName));
    }

    handleSubmit = () => {
        const { onError } = this.props;
        const listDialogStore = this.getListDialogStore();
        const { saltStore } = this.context;
        const { uiStore } = saltStore;
        searchController.submitChanges({ listDialogStore, onError, uiStore });
    };

    handleClear = () => {
        const { onError } = this.props;
        const listDialogStore = this.getListDialogStore();
        searchController.clearSearchValues({ listDialogStore, onError });
    };

    handleCancel = () => {
        const listDialogStore = this.getListDialogStore();
        listDialogStore.setSearchFormOpen(false);
        // Refresh the buffer to clear unsaved changes.
        const { searchDialogStore } = listDialogStore;
        searchDialogStore.refresh();
    };

    handleSearchTermChange = (term) => {
        const searchDialogStore = this.getSearchDialogStore();
        searchDialogStore.setKeywordSearchValue(term);
    };

    handleSortClear = () => {
        const { onError } = this.props;
        const listDialogStore = this.getListDialogStore();
        searchController.clearSortValues({ listDialogStore, onError });
    };

    handleSortTermsChange = (sortTerms) => {
        const { onError } = this.props;
        const listDialogStore = this.getListDialogStore();
        searchController.setSortTerms({ listDialogStore, sortTerms, onError });
    };

    handleOnSetSearchPropertyOperation = (propertyName, value) => {
        const searchDialogStore = this.getSearchDialogStore();
        searchDialogStore.setSearchValueOperation(propertyName, value);
        const propertyValueDefinition = searchDialogStore.getSearchValuePropertyDefForName(propertyName);
        const { value: propertyvalue } = searchDialogStore.getSearchValuePropertyForName(propertyName);
        if (propertyValueDefinition.propertyType === 'boolean' && !propertyvalue) {
            searchDialogStore.setSearchValue(propertyName, false);
        }
    }

    handleOnSetSearchPropertyValue = (propertyName, value) => {
        const searchDialogStore = this.getSearchDialogStore();
        if (!value && value !== '' && value !== false) {
            searchDialogStore.setSearchValueOperation(propertyName, null);
            searchDialogStore.setSearchValue(propertyName, null);
            return;
        }
        const filterOperationProperty = searchDialogStore.getSearchOpPropertyForName(propertyName);
        if (filterOperationProperty) {
            const { name, value: filterOperationValue } = filterOperationProperty;
            if (!filterOperationValue) {
                const propertyOperations = searchDialogStore.getAvailableValues(name);
                if (propertyOperations) {
                    searchDialogStore.setSearchValueOperation(propertyName, propertyOperations[0]);
                }
                else {
                    this.loadAvailablePropertyValues(name)
                        .then((availableValues) => searchDialogStore.setSearchValueOperation(propertyName, availableValues[0]));
                }
            }
        }

        searchDialogStore.setSearchValue(propertyName, value);
    }
}
