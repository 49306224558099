import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ListActivityIndicator from '../ListActivityIndicator/ListActivityIndicator';

@observer
class GridActivityIndicator extends PureComponent {
    render() {
        const { isLoading } = this.props;

        if (isLoading()) {
            return <ListActivityIndicator />;
        }
        return null;
    }
};

GridActivityIndicator.propTypes = {
    isLoading: PropTypes.func,
};

export default GridActivityIndicator;
