import { Component } from 'react';
import * as PropTypes from 'prop-types';

// eslint-disable-next-line react/prefer-stateless-function
export default class RWSaltComponent extends Component {
    static propTypes = {
        // eslint-disable-next-line react/no-unused-prop-types
        style: PropTypes.oneOfType([
            PropTypes.object,
            // eslint-disable-next-line react/no-unused-prop-types
            PropTypes.arrayOf(PropTypes.object),
        ]),
        params: PropTypes.shape({
            viewId: PropTypes.string,
        }),
    };

    static extractCombinedStyles = (style) => {
        // Combine style arrays
        let combinedStyles = {};
        if (Array.isArray(style)) {
            style.forEach((styleObj) => {
                combinedStyles = {
                    ...combinedStyles,
                    ...styleObj,
                };
            });
        }
        else {
            combinedStyles = style || {};
        }
        return combinedStyles;
    }
}
