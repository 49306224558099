/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { XaltCheckbox, XaltToolTip } from 'xalt-react-atoms';

/**
 * A platform component for building a Checkbox component
 */
export default class RWCheckbox extends Component {
    static propTypes = {
        isFocused: PropTypes.bool,
        isReadMode: PropTypes.bool,
        onValueChanged: PropTypes.func,
        style: PropTypes.object,
        xStyle: PropTypes.object,
        tipText: PropTypes.string,
        propertyRef: PropTypes.object,
        value: PropTypes.bool,
    };

    static defaultProps = {
        style: {},
    };

    render() {
        const {
            isFocused,
            isReadMode,
            onValueChanged,
            propertyRef,
            style,
            xStyle,
            tipText,
            value,
        } = this.props;
        const { focus, hover } = xStyle || {};
        const checkboxStyles = {
            color: style.color,
            padding: '0px',
        };

        return (
            <XaltToolTip
                style={ style }
                tipText={ tipText }>
                <XaltCheckbox
                    autoFocus={ isFocused }
                    checked={ value }
                    disabled={ isReadMode }
                    inputRef={ propertyRef }
                    onValueChanged={ onValueChanged }
                    style={ checkboxStyles }
                    focusStyle={ focus }
                    hoverStyle={ hover } />
            </XaltToolTip>
        );
    }
}
