import { ArrayUtil } from '../util/ArrayUtil';
export class DataAnnotation {
    static backgroundColor(annotations) {
        const result = ArrayUtil.find(annotations, anno => {
            return anno.isBackgroundColor;
        });
        return result ? result.backgroundColor : null;
    }
    static foregroundColor(annotations) {
        const result = ArrayUtil.find(annotations, anno => {
            return anno.isForegroundColor;
        });
        return result ? result.foregroundColor : null;
    }
    static imageName(annotations) {
        const result = ArrayUtil.find(annotations, anno => {
            return anno.isImageName;
        });
        return result ? result.value : null;
    }
    static imagePlacement(annotations) {
        const result = ArrayUtil.find(annotations, anno => {
            return anno.isImagePlacement;
        });
        return result ? result.value : null;
    }
    static isBoldText(annotations) {
        return annotations.some(anno => {
            return anno.isBoldText;
        });
    }
    static isItalicText(annotations) {
        return annotations.some(anno => {
            return anno.isItalicText;
        });
    }
    static isPlacementCenter(annotations) {
        return annotations.some(anno => {
            return anno.isPlacementCenter;
        });
    }
    static isPlacementLeft(annotations) {
        return annotations.some(anno => {
            return anno.isPlacementLeft;
        });
    }
    static isPlacementRight(annotations) {
        return annotations.some(anno => {
            return anno.isPlacementRight;
        });
    }
    static isPlacementStretchUnder(annotations) {
        return annotations.some(anno => {
            return anno.isPlacementStretchUnder;
        });
    }
    static isPlacementUnder(annotations) {
        return annotations.some(anno => {
            return anno.isPlacementUnder;
        });
    }
    static isUnderlineText(annotations) {
        return annotations.some(anno => {
            return anno.isUnderlineText;
        });
    }
    static overrideText(annotations) {
        const result = ArrayUtil.find(annotations, anno => {
            return anno.isOverrideText;
        });
        return result ? result.value : null;
    }
    static tipText(annotations) {
        const result = ArrayUtil.find(annotations, anno => {
            return anno.isTipText;
        });
        return result ? result.value : null;
    }
    constructor(name, value, type) {
        this.name = name;
        this.value = value;
        this.type = type;
    }
    get backgroundColor() {
        return this.isBackgroundColor ? this.value : null;
    }
    get foregroundColor() {
        return this.isForegroundColor ? this.value : null;
    }
    equals(dataAnno) {
        return this.name === dataAnno.name;
    }
    get isBackgroundColor() {
        return this.name === DataAnnotation.BACKGROUND_COLOR;
    }
    get isBoldText() {
        return this.name === DataAnnotation.BOLD_TEXT && this.value === DataAnnotation.TRUE_VALUE;
    }
    get isForegroundColor() {
        return this.name === DataAnnotation.FOREGROUND_COLOR;
    }
    get isImageName() {
        return this.name === DataAnnotation.IMAGE_NAME;
    }
    get isImagePlacement() {
        return this.name === DataAnnotation.IMAGE_PLACEMENT;
    }
    get isItalicText() {
        return this.name === DataAnnotation.ITALIC_TEXT && this.value === DataAnnotation.TRUE_VALUE;
    }
    get isOverrideText() {
        return this.name === DataAnnotation.OVERRIDE_TEXT;
    }
    get isPlacementCenter() {
        return this.isImagePlacement && this.value === DataAnnotation.PLACEMENT_CENTER;
    }
    get isPlacementLeft() {
        return this.isImagePlacement && this.value === DataAnnotation.PLACEMENT_LEFT;
    }
    get isPlacementRight() {
        return this.isImagePlacement && this.value === DataAnnotation.PLACEMENT_RIGHT;
    }
    get isPlacementStretchUnder() {
        return this.isImagePlacement && this.value === DataAnnotation.PLACEMENT_STRETCH_UNDER;
    }
    get isPlacementUnder() {
        return this.isImagePlacement && this.value === DataAnnotation.PLACEMENT_UNDER;
    }
    get isTipText() {
        return this.name === DataAnnotation.TIP_TEXT;
    }
    get isUnderlineText() {
        return this.name === DataAnnotation.UNDERLINE && this.value === DataAnnotation.TRUE_VALUE;
    }
    toJSON() {
        return { name: this.name, value: this.value, type: this.type };
    }
}
DataAnnotation.BOLD_TEXT = 'BOLD_TEXT';
DataAnnotation.BACKGROUND_COLOR = 'BGND_COLOR';
DataAnnotation.FOREGROUND_COLOR = 'FGND_COLOR';
DataAnnotation.IMAGE_NAME = 'IMAGE_NAME';
DataAnnotation.IMAGE_PLACEMENT = 'IMAGE_PLACEMENT';
DataAnnotation.ITALIC_TEXT = 'ITALIC_TEXT';
DataAnnotation.OVERRIDE_TEXT = 'OVRD_TEXT';
DataAnnotation.TIP_TEXT = 'TIP_TEXT';
DataAnnotation.UNDERLINE = 'UNDERLINE';
DataAnnotation.TRUE_VALUE = '1';
DataAnnotation.PLACEMENT_CENTER = 'CENTER';
DataAnnotation.PLACEMENT_LEFT = 'LEFT';
DataAnnotation.PLACEMENT_RIGHT = 'RIGHT';
DataAnnotation.PLACEMENT_UNDER = 'UNDER';
DataAnnotation.PLACEMENT_STRETCH_UNDER = 'STRETCH_UNDER';
