/**
* Helper method to get the styles for Icon component
* @param {Object} contextStyles - Context styles for Icon Component
*/
const getStyles = (contextStyles = {}) => {
    return {
        popper: {
            ...contextStyles.container,
        },

        tooltip: {
            ...contextStyles.toolTipText,
        },

        tooltipRef: {
            ...contextStyles.tooltipRef,
        }
    };
};

export default getStyles;