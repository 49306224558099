import { rootStore } from 'cv-react-core';

const coreStyles = {
    button: {
        width: 'unset',
        marginBottom: '10px',
    },

    buttonPrimary: {
        width: '100%',
        padding: '13px',
        boxShadow: 'none',
    },

    buttonPrimaryHover: {
        boxShadow: 'none',
    },

    buttonPrimaryText: {
    },

    buttonText: {
    },

    buttonSecondary: {
        width: '100%',
        padding: '13px',
    },

    buttonSecondaryHover: {
    },

    buttonSecondaryText: {},

    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '342px',
    },

    error: {
        background: '#ffffff',
        borderRadius: '6px',
        padding: '15px',
        boxSizing: 'border-box',
        marginBottom: '20px',
        whiteSpace: 'pre-line',
    },

    errorText: {
        lineHeight: '20px',
    },

    input: {
        lineHeight: '26px',
        padding: '0px 8px',
    },

    password: {
        marginBottom: '20px',
        height: '50px',
        flexGrow: 0,
    },

    textLabel: {},
};

const getStyles = (contextStyles = {}, error) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    // This is a temporary solution here to ahieve hover, focused and error styles for textfields.
    const passwordFieldStyles = {
        '& label': {
            color: error ? theme.colors.error : theme.fonts.input.color,
        },
        '& label.Mui-focused': {
            color: error ? theme.colors.error : theme.textInputActiveBorderColor,
        },
        '&:hover label': {
            color: error ? theme.colors.error : theme.textInputActiveBorderColor,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: `${error ? theme.colors.error : theme.textInputUnderlineColor}`,
            },
            '&:hover fieldset': {
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: `${error ? theme.colors.error : theme.textInputActiveBorderColor}`,
            },
            '&.Mui-focused fieldset': {
                borderWidth: 2,
                borderStyle: 'solid',
                borderColor: `${error ? theme.colors.error : theme.textInputActiveBorderColor}`,
            },
        },
    };
    return {
        button: {
            ...coreStyles.button,
            ...contextStyles.button,
        },

        buttonPrimary: {
            ...coreStyles.buttonPrimary,
            backgroundColor: theme.loginButtonPrimaryBackgroundColor,
            ...contextStyles.buttonPrimary,
        },

        buttonPrimaryHover: {
            ...coreStyles.buttonPrimaryHover,
            backgroundColor: theme.loginButtonPrimaryBackgroundColor,
            ...contextStyles.buttonPrimaryHover,
        },

        buttonPrimaryText: {
            ...coreStyles.buttonPrimaryText,
            ...theme.fonts.loginButtonPrimary,
            ...contextStyles.buttonPrimaryText,
        },

        buttonText: {
            ...coreStyles.buttonText,
            ...contextStyles.buttonText,
        },

        buttonSecondary: {
            ...coreStyles.buttonSecondary,
            ...contextStyles.buttonSecondary,
        },

        buttonSecondaryHover: {
            ...coreStyles.buttonSecondaryHover,
            ...contextStyles.buttonSecondaryHover,
        },

        buttonSecondaryText: {
            ...coreStyles.buttonSecondaryText,
            ...theme.fonts.buttonSecondary,
            ...contextStyles.buttonSecondaryText,
        },

        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        error: {
            ...coreStyles.error,
            ...contextStyles.error,
        },

        errorText: {
            ...coreStyles.errorText,
            ...theme.fonts.error,
            textAlign: 'unset',
            ...contextStyles.errorText,
        },

        input: {
            ...coreStyles.input,
            ...theme.fonts.input,
            ...contextStyles.input,
        },

        password: {
            ...coreStyles.password,
            ...passwordFieldStyles,
            ...contextStyles.password,
        },

        textLabel: {
            ...coreStyles.textLabel,
            ...contextStyles.textLabel,
        },
    };
};

export default getStyles;
