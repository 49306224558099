import React from 'react';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { Graph as sdkGraph } from 'cv-dialog-sdk';
import engineConstants from './engineConstants';
import { constants } from '../constants';
import componentFactory from './componentFactory';
import SaltContext from './SaltContext';
import SaltComponent from './SaltComponent';
import pageController from '../controllers/pageController';
import { utilities } from '../utilities';
import serviceFactory from '../services/serviceFactory';
import BarChartPropsFactory from './graph/BarChartPropsFactory';
import BubbleChartPropsFactory from './graph/BubbleChartPropsFactory';
import LineChartPropsFactory from './graph/LineChartPropsFactory';
import PieChartPropsFactory from './graph/PieChartPropsFactory';
import ScatterChartPropsFactory from './graph/ScatterChartPropsFactory';
import SwimlaneChartPropsFactory from './graph/SwimlanePropsFactory';

/**
 * This class is used to route user to the graph component feature
 */
@observer
export default class Graph extends SaltComponent {
    static propTypes = {
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        viewId: PropTypes.string,
    };

    render() {
        const { dialogStore, uiStore } = this.contextParams;
        const { dialog, records, chartFilteredItem, queryInProgress, refreshInProgress, isRefreshTriggeredByTimer } = dialogStore;
        const { view, isReadMode, recordDef } = dialog;
        let resolvedProps = this.resolveProperties();
        const { orientation } = uiStore.getValueForUIObject(constants.ui.APPLICATION_UI_ID, constants.ui.DEVICE_PROPERTIES);
        const isLoading = isRefreshTriggeredByTimer ? false : (queryInProgress || refreshInProgress);
        const { plotType } = this;

        let graphProps;
        if (!isLoading) {
            const { propertyDefs } = recordDef;
            if (plotType === sdkGraph.PLOT_TYPE_BAR || plotType === sdkGraph.PLOT_TYPE_STACKED) {
                graphProps = BarChartPropsFactory.create(view, records, propertyDefs, chartFilteredItem);
            } else if (plotType === sdkGraph.PLOT_TYPE_BUBBLE) {
                graphProps = BubbleChartPropsFactory.create(view, records, propertyDefs, chartFilteredItem);
            } else if (plotType === sdkGraph.PLOT_TYPE_LINE) {
                graphProps = LineChartPropsFactory.create(view, records, propertyDefs, chartFilteredItem);
            } else if (plotType === sdkGraph.PLOT_TYPE_SCATTER) {
                graphProps = ScatterChartPropsFactory.create(view, records, propertyDefs, chartFilteredItem);
            } else if (plotType === sdkGraph.PLOT_TYPE_SWIMLANE) {
                graphProps = SwimlaneChartPropsFactory.create(view, records, propertyDefs, chartFilteredItem);
            } else if (plotType === sdkGraph.GRAPH_TYPE_PIE) {
                graphProps = PieChartPropsFactory.create(view, records, propertyDefs, orientation, chartFilteredItem);
            } else {
                const { lang } = serviceFactory;
                graphProps = { errorText: lang.errors.genericChartError };
            }
        }

        resolvedProps = {
            isLoading,
            isReadMode,
            orientation,
            onPress: this.handleOnChooseItem,
            onSelectItem: this.handleOnSelectItem,
            onFilterPress: this.handleOnFilterSelection,
            onDragDrop: this.handleOnDragDrop,
            plotType,
            ...graphProps,
            ...resolvedProps,
        };
        return React.createElement(componentFactory.getPlatformComponent('graph'), resolvedProps);
    }

    get contextParams() {
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore, onTransition, onError } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        const { uiStore } = saltStore;
        return { saltStore, onTransition, onError, dialogStore, uiStore };
    }

    get plotType() {
        const { dialogStore } = this.contextParams;
        const { dialog } = dialogStore;
        const { view: viewDefinition } = dialog;

        if (viewDefinition.graphType === 'GRAPH_TYPE_PIE' && viewDefinition.dataPoints) {
            return sdkGraph.GRAPH_TYPE_PIE;
        }

        let isBar = false;
        let isStackedBar = false;
        let isLine = false;
        let isScatter = false;
        let isSwimlane = false;
        let isBubble = false;
        let isPie = false;

        // Get data points
        if (Array.isArray(viewDefinition.dataPoints)) {
            // Gather chart plot types
            const plotTypes = viewDefinition.dataPoints.map((dataPoint) => {
                return dataPoint.plotType || sdkGraph.PLOT_TYPE_BAR;
            });

            // Check that every plot type is the same
            isBar = plotTypes.every((plotType) => (plotType === sdkGraph.PLOT_TYPE_BAR));
            isStackedBar = plotTypes.every((plotType) => (plotType === 'STACKED_BAR'));
            isSwimlane = plotTypes.every((plotType) => (plotType === sdkGraph.PLOT_TYPE_SWIMLANE));
            isLine = plotTypes.every((plotType) => (plotType === sdkGraph.PLOT_TYPE_LINE));
            isScatter = plotTypes.every((plotType) => (plotType === sdkGraph.PLOT_TYPE_SCATTER));
            isBubble = plotTypes.every((plotType) => (plotType === sdkGraph.PLOT_TYPE_BUBBLE));
            isPie = plotTypes.every((plotType) => (plotType === sdkGraph.GRAPH_TYPE_PIE));
        }

        if (!viewDefinition.dataPoints) {
            const {
                startDateTimeDataPoint,
                endDateTimeDataPoint,
                startDateDataPoint,
                endDateDataPoint,
            } = viewDefinition;
            if (startDateDataPoint || endDateDataPoint || startDateTimeDataPoint || endDateTimeDataPoint) {
                isSwimlane = true;
            }
        }

        // Check that every plot type is the same
        if (isBar) {
            return sdkGraph.PLOT_TYPE_BAR;
        }
        if (isStackedBar) {
            return sdkGraph.PLOT_TYPE_STACKED;
        }
        if (isLine) {
            return sdkGraph.PLOT_TYPE_LINE;
        }
        if (isScatter) {
            return sdkGraph.PLOT_TYPE_SCATTER;
        }
        if (isSwimlane) {
            return sdkGraph.PLOT_TYPE_SWIMLANE;
        }
        if (isBubble) {
            return sdkGraph.PLOT_TYPE_BUBBLE;
        } 
        if (isPie) {
            return sdkGraph.GRAPH_TYPE_PIE;
        }

        return null;
    }

    /**
     * Handles field actions
     * @param {Object} action
     */
    handleOnChooseItem = (action) => {
        const { dialogStore, uiStore, onTransition, onError } = this.contextParams;
        const { dialog } = dialogStore;
        const { view } = dialog;
        const { defaultActionId: actionId } = view;
        const { id } = action;
        pageController.performActionWithConfirmation({ actionId, selectedArray: [ id ], dialogStore, uiStore, onTransition, onError });
    };

    handleOnDragDrop = (updatedProperties, propsMap) => {
        const { dialogStore, uiStore, onTransition, onError } = this.contextParams;
        const { dialog } = dialogStore;
        const { view: viewDefinition } = dialog;
        const {
            startTimeDataPoint,
            endTimeDataPoint
        } = viewDefinition;
        const {
            propertyName: startTimeValueProp,
        } = startTimeDataPoint || {};
        const {
            propertyName: endTimeValueProp,
        } = endTimeDataPoint || {};
        const {
            startValueProp,
            endValueProp,
        } = propsMap;
        const record = dialogStore.records.find((rec) => rec.id === updatedProperties.id);
        dialogStore.setPropertyValue(startValueProp, updatedProperties[startValueProp], record);
        dialogStore.setPropertyValue(endValueProp, updatedProperties[endValueProp], record);
        if (startTimeValueProp) {
            dialogStore.setPropertyValue(startTimeValueProp, updatedProperties[startValueProp], record);
        }
        if (endTimeValueProp) {
            dialogStore.setPropertyValue(endTimeValueProp, updatedProperties[endValueProp], record);
        }
        return pageController.performActionWithConfirmation({
            actionId: engineConstants.action.clientActions.save,
            dialogStore,
            uiStore,
            onTransition,
            onError,
        });
    };

    /**
     * Handles choosing a chart item
     * @param {Object} selectedData
     */
    handleOnSelectItem = (selectedData, selectionMode = false) => {
        const { id: objectId } = selectedData;
        const { dialogStore, uiStore } = this.contextParams;
        // We either want to add to the current selected items or unselect all and select
        // only one depending on the 'selection mode'
        // If not in selection mode, then un-select all before selecting the long press record.
        // We don't have option to switch to selection mode in XHA. So,  getSelectionMode from pageController always returns false.
        // This clears already selected items in XHA. To avoid this, we send selectionMode property.
        if (!pageController.getSelectionMode(dialogStore, uiStore) && !selectionMode) {
            utilities.listHelper.clearSelectedRecords(uiStore, dialogStore);
        }
        utilities.listHelper.selectRecord(uiStore, dialogStore, objectId, utilities.listHelper.single);
    };

    /**
     * Handles filtering the chart items
     */
    handleOnFilterSelection = (event, props) => {
        const { dialogStore } = this.contextParams;
        const {
            name,
            filter,
        } = props;
        const {
            chartFilteredItem,
        } = dialogStore;
        const chartFilter = toJS(chartFilteredItem);
        const foundIndex = chartFilter.findIndex(filtered => filtered.code === filter.code || filtered.description === name);

        if (foundIndex < 0) {
            filter.checked = false;
        } else {
            chartFilter.splice(foundIndex, 1);
            filter.checked = !filter.checked;
        }

        chartFilter.push(filter);
        return dialogStore.setChartFilteredItem(chartFilter);
    }
}

Graph.contextType = SaltContext;
