import Layout from './Layout';
// NOTE: This code is duplicated in gml/GmlUtil.js
const size = (layout) => {
    if (layout.size) {
        // size = w&H    or size = w, h
        const style = {};
        const multi = Layout.parseMultiPartValue(layout.size);
        switch (multi.length) {
            case 2:
                style.width = Layout.parseValue(multi[0]);
                style.height = Layout.parseValue(multi[1]);
                break;
            case 1:
                style.width = Layout.parseValue(multi[0]);
                style.height = Layout.parseValue(multi[0]);
                break;
            default: // Just work with the first value
                style.width = Layout.parseValue(multi[0]);
                style.height = Layout.parseValue(multi[0]);
        }
        return style;
    }
    return {};
};

export default size;
