import React from 'react';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { TypeNames, Catavolt } from 'cv-dialog-sdk';
import rootStore from '../stores/rootStore';
import { utilities } from '../utilities';
import componentFactory from './componentFactory';
import pageController from '../controllers/pageController';
import serviceFactory from '../services/serviceFactory';
import SaltContext from './SaltContext';
import ScopeManager from './ScopeManager';
import SaltComponent from './SaltComponent';
import engineConstants from './engineConstants';
import Layout from './xStyle/Layout';
import { constants } from '../constants';
import BuildGlobals from '../provider/BuildGlobals';
import propertyComponentFactory from './properties/propertyComponentFactory';

@observer
export default class Property extends SaltComponent {
    static propTypes = {
        name: PropTypes.string,
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        assert: PropTypes.shape({
            refName: PropTypes.string,
            expr: PropTypes.string,
        }),
        viewId: PropTypes.string,
    };

    static typeName = engineConstants.component.name.property;

    render() {
        const { assert } = this.props;
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore, scopeManager } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);

        if (this.determineVisibility(assert, dialogStore, scopeManager, saltStore)) {
            if (dialogStore) {
                const propName = this.getPropName();
                if (propName) {
                    const resolvedProps = this.resolveProperties(Property.typeName);
                    if (dialogStore.dialog.view.type === TypeNames.DetailsTypeName) {
                        return this.renderDetailsProp(propName, resolvedProps);
                    }
                    if (dialogStore.dialog.view.type === TypeNames.ListTypeName) {
                        return this.renderListProp(propName, resolvedProps);
                    }
                }
            } else {
                // @TODO report view not found
            }
        }
        return null;
    }

    getPropName() {
        const { name } = this.props;
        const { scopeManager } = this.context;
        if (!name) {
            const prop = scopeManager.getRef(ScopeManager.SCOPED_PROPERTY_REF_NAME);
            return prop ? prop.name : null;
        }
        return name;
    }

    /**
        @deprecated - this should be removed when V2 is complete
        @TODO - to figure out what's going on here - why are charts at the property level and not the view level?
    */
    renderPropertyMissing(propName, props) {
        const { style, xStyle } = props;
        const themeStyle = this.getDetailsThemeStyle();
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        const { record } = dialogStore;
        const useBarChartProp = !!(xStyle && xStyle.barChartProp);
        const useGaugeChartProp = !!(xStyle && xStyle.gaugeChartProp);
        const {
            errorHelper: {
                PROPS_ERROR,
                ERROR_MESSAGES,
            },
        } = utilities;
        if (useBarChartProp || useGaugeChartProp) {
            const barChartProp = {
                ...xStyle.barChartProp,
                barColor: PROPS_ERROR.barColor,
                valueColor: PROPS_ERROR.valueColor,
                animate: PROPS_ERROR.animate,
                maxValue: PROPS_ERROR.maxValue,
                valueFormat: PROPS_ERROR.valueFormat,
            };
            const defaultProps = {
                property: {
                    name: propName,
                    errorMessage: `${ERROR_MESSAGES.BINDING_ERROR}: ${propName}`,
                    value: PROPS_ERROR.value,
                },
                propertyDef: {
                    propertyName: propName,
                },
                viewDef: {
                    propertyName: propName,
                },
                record,
                isReadMode: true,
                style: Layout.combineStyles(themeStyle, style),
                xStyle: { ...xStyle, barChartProp },
            };
            return React.createElement(componentFactory.getPlatformComponent('property'), defaultProps);
        }
        return null;
    }

    /** @deprecated - this should be removed when V2 is complete */
    // observables - dialogStore (fields and methods), saltStore.getDialogStoreForViewId
    renderDetailsProp(propName, props) {
        const { style, xStyle, toolTip } = props;
        const themeStyle = this.getDetailsThemeStyle();
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        // < ----observables
        const { dialog, record, availableValuesStore, tabSequence } = dialogStore;
        const property = dialogStore.getProperty(propName);
        // @TODO - report property not found!
        if (!property) {
            // Handle report property not found
            return this.renderPropertyMissing(propName, props);
        }
        // observables----->
        const { view: details } = dialog;
        const { focusPropertyName = this.getFocusPropertyName(propName, dialog, saltStore.uiStore) } = details;
        // this is a misnomer - this should be called cellDef
        const viewDef = details.getAttributeCellValue(property.name);
        const propertyDef = dialog.propDefAtName(property.name);
        const annotationMode = dialog.isReadViewMode ? utilities.annotationHelper.all : utilities.annotationHelper.none;
        const isReadMode = dialog.isReadModeFor(property.name) || (propertyDef.isObjRefType && viewDef.isTextFieldEntryMethod);
        const viewType = details.type;
        const { propertyNames = [] } = details;

        // This is a temporary hook to see if we've created a 'Version 2' component yet
        const element = this.renderDetailsV2({ cellDef: viewDef, propertyDef, property, record, isReadMode, xStyle, style, themeStyle, focusPropertyName, toolTip, availableValuesStore, annotationMode, tipText: toolTip || property.tipText || viewDef.toolTip });
        if (element) return element;

        /** @deprecated - please use new (V2) component approach */
        const propertyProps = {
            property,
            propertyDef,
            focusPropertyName,
            isLastProperty: propertyNames[propertyNames.length - 1] === propName,
            viewDef,
            record,
            tabSequence,
            annotationMode,
            isReadMode,
            onFieldAction: this.handleFieldAction,
            onValueChanged: this.handleValueChanged,
            asyncDataCallback: this.loadAsyncDataDetails,
            availableValues: this.getAvailableValues,
            availableValuesLoader: this.loadAvailableValues,
            style: Layout.combineStyles(themeStyle),
            toolTip: property.tipText || toolTip,
            xStyle,
            viewType,
        };

        return React.createElement(componentFactory.getPlatformComponent('property'), propertyProps);
    }

    // observables - availableValuesStore.getAvailableValues
    renderDetailsV2({ cellDef, propertyDef, propertyRef, property, record, isReadMode, xStyle, style, themeStyle, focusPropertyName, availableValuesStore, annotationMode, tipText }) {
        const component = propertyComponentFactory.getComponent({ cellDef, propertyDef, property, record, isReadMode, xStyle });
        if (component) {
            // style precedence (lowest to highest):  theme -> annotations -> salt style
            const backgroundStyle = (property.value || property.overrideText) && !utilities.annotationHelper.isNone(annotationMode) ? utilities.annotationHelper.getBackgroundAsStyle(record, property) : {};
            const annotationStyle = (property.value || property.overrideText) ? utilities.annotationHelper.getAsStyle(record, property, backgroundStyle) : backgroundStyle;
            // TODO: Move the imageSrc to somewhere meaningful so that only image based components receive it
            const imageSrc = property.imageName || record.imageName;
            const overrideText = isReadMode ? utilities.annotationHelper.getOverrideText(record, property) : undefined;
            const themedStyles = Layout.combineStyles(themeStyle, style);
            const resolvedStyle = isReadMode ? Layout.combineStyles(themedStyles, annotationStyle) : themedStyles;
            // Modified logic to look for length vaidation from propertyDef.
            // And we will be removing validation on displayLength once every validation is passed through Length property.
            const displayLength = propertyDef.length || propertyDef.displayLength;
            const availableValues = (availableValuesStore && availableValuesStore.getAvailableValues(property.name)) || [];
            const optionValues = this.buildOptionValues(availableValues);
            const xStyleValues = this.addPropertyDrivenXstyles(component.type.typeName, xStyle, property, record, cellDef, propertyDef);
            const componentValue = isReadMode ? utilities.uiHelper.formatPropertyForRead(property, propertyDef)
                : this.resolveWriteValue(property, propertyDef, cellDef);
            const newProps = {
                imageSrc,
                isReadMode,
                isFocused: focusPropertyName === property.name,
                onValueChanged: this.handleValueChangedV2,
                asyncDataCallback: this.loadAsyncDataDetails,
                onLoadOptionValues: this.handleLoadAvailableValues,
                optionValues,
                xStyle: xStyleValues,
                style: resolvedStyle,
                maxLength: displayLength,
                name: property.name,
                value: overrideText || componentValue,
                propertyRef,
                tipText,
            };
            // Note: these components are memoized by factory, so that newProps will be deeply compared
            return React.createElement(component, newProps);
        }
        return null;
    }

    /**
     * THIS IS A TEMP SOLUTION UNTIL WE MERGE AND DO AWAY WITH OLD BUILDER PATTERN. DO NOT KEEP THIS.
     * @returns File Name for large property
     */
    getLargePropertyFileName = (propertyName, cellDef = undefined) => {
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        const { dialog } = dialogStore;
        // imageName is the actuall name of the property, but to avoid hard coded name we have to check
        const property = dialogStore.getProperty(propertyName || 'imageName');
        const propertyDef = dialog.propDefAtName(property.name);
        return this.resolveWriteValue(property, propertyDef, [], cellDef);
    }

    resolveWriteValue(property, propertyDef, cellDef) {
        // TODO: Once we merge we can do away with this and use the correct properties to pass in here. We can simply take
        // from the record the fileName property and use it for the value.
        // By Rajesh - Instead of hard coding the property name, as suggested bu scott we are taking it from action ID.
        if (propertyDef.isFileMimeType) {
            if (cellDef) {
                const largePropetyName = this.getLargePropertyName(cellDef);
                if (largePropetyName !== '') {
                    return this.getLargePropertyFileName(largePropetyName);
                }
                return this.getLargePropertyFileName();
            }
            return this.getLargePropertyFileName();
        }

        if (!property.value && (propertyDef.isDateType || propertyDef.isDateTimeType || propertyDef.isTimeType)) {
            return '';
        }
        return utilities.uiHelper.formatPropertyForWrite(property, propertyDef);
    }

    getLargePropertyName = (cellDef) => {
        const { actions } = cellDef;
        if (actions) {
            const fieldActionName = actions[0].actionId.split(':');
            if (fieldActionName[1]) {
                return fieldActionName[1];
            }
        }
        return '';
    }

    // @TODO handle array value
    // option values should be in the format { label, value }
    buildOptionValues(availableValues) {
        return availableValues.map(value => {
            if (value.type === TypeNames.CodeRefTypeName) {
                return { label: value.description, value: value.code };
            }
            if (value.type === TypeNames.ObjectRefTypeName) {
                return { label: value.description, value: value.objectId };
            }
            return { label: value, value };
        });
    }

    addPropertyDrivenXstyles(componentName = '', _xStyle = {}, property, record, cellDef, propertyDef) {
        let xStyle = _xStyle;
        // Convert Array to object
        if (Array.isArray(xStyle)) {
            xStyle = Object.assign({}, ...xStyle);
        }
        if (componentName === engineConstants.component.name.textWithImage) {
            if (property.imageName || record.imageName) {
                const { imageAlignment } = constants;
                let alignImage = imageAlignment.Left;
                if (property.isPlacementRight || record.isPlacementRight) {
                    alignImage = imageAlignment.Right;
                } else if (property.isPlacementUnder || record.isPlacementUnder) {
                    alignImage = imageAlignment.Under;
                } else if (property.isPlacementStretchUnder || record.isPlacementStretchUnder) {
                    alignImage = imageAlignment.StretchUnder;
                } else if (property.isPlacementCenter || record.isPlacementCenter) {
                    alignImage = imageAlignment.Center;
                }

                return {
                    ...xStyle,
                    alignImage,
                };
            }
        } else if (componentName === engineConstants.component.name.switch) {
            const { entryMethod } = cellDef || {};
            const { booleanTrueAlias, booleanFalseAlias } = propertyDef;
            return {
                ...xStyle,
                booleanTrueAlias,
                booleanFalseAlias,
                entryMethod,
            };
        } else if (componentName === engineConstants.component.name.radio || componentName === engineConstants.component.name.checkbox) {
            const { booleanTrueAlias, booleanFalseAlias } = propertyDef;
            return {
                ...xStyle,
                booleanTrueAlias,
                booleanFalseAlias,
            };
        } else if (propertyDef.isDateType || propertyDef.isDateTimeType || propertyDef.isTimeType) {
            return {
                ...xStyle,
                formatString: propertyDef.getDateFormatString(Catavolt.locale.langCountryString),
            };
        } else if (componentName === engineConstants.component.name.textLabel && cellDef.type === TypeNames.ColumnTypeName) {
            return {
                numberOfLines: 3,
                ...xStyle,
            };
        }
        return xStyle;
    }

    handleLoadAvailableValues = () => {
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore, scopeManager } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        const { dialog } = dialogStore;
        const propName = this.getPropName();
        let record = {};
        if (dialog.view.type === TypeNames.ListTypeName) {
            record = scopeManager.getRef(ScopeManager.SCOPED_RECORD_REF_NAME);
        }
        dialogStore.updateAvailableValues(propName, record.id);
    };

    handleValueChangedV2 = (changedValue, mimeType = undefined) => {
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore, scopeManager, onError } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        const { dialog, availableValuesStore } = dialogStore;
        const propertyName = this.getPropName();
        const propDef = dialog.propDefAtName(propertyName);
        const { canCauseSideEffects } = propDef;
        let record;
        if (dialog.view.type === TypeNames.ListTypeName) {
            record = scopeManager.getRef(ScopeManager.SCOPED_RECORD_REF_NAME);
        }
        let resolvedValue = changedValue;

        if (changedValue) {
            const availableValues = availableValuesStore && availableValuesStore.getAvailableValues(propertyName);
            if (Array.isArray(changedValue)) {
                if (availableValues && availableValues.length) {
                    if (availableValues[0].type === TypeNames.CodeRefTypeName) {
                        resolvedValue = changedValue.map((change) => availableValues.find(item => item.code === change.value));
                    }
                    if (availableValues[0].type === TypeNames.ObjectRefTypeName) {
                        resolvedValue = changedValue.map((change) => availableValues.find(item => item.objectId === change.value));
                    }
                }
            } else if (availableValues && availableValues.length) {
                if (availableValues[0].type === TypeNames.CodeRefTypeName) {
                    resolvedValue = availableValues.find(item => item.code === changedValue.value);
                } else if (availableValues[0].type === TypeNames.ObjectRefTypeName) {
                    resolvedValue = availableValues.find(item => item.objectId === changedValue.value);
                } else if (!availableValues[0].type) {
                    // Case of plain strings -> Example: Display as: Table/Chart/Map in Data query
                    resolvedValue = changedValue.value || changedValue;
                }
            }
        }

        if (mimeType) {
            if (propDef.isFileMimeType) {
                dialogStore.setLargePropertyWithEncodedData(propertyName, resolvedValue, mimeType);
                const { view: details } = dialog;
                const viewDef = details.getAttributeCellValue(propertyName);
                const largePorpertyName = this.getLargePropertyName(viewDef);
                dialogStore.setPropertyValue(largePorpertyName || 'imageName', mimeType.name);
            } else {
                dialogStore.setLargePropertyWithEncodedData(propertyName, resolvedValue, mimeType);
            }
        } else {
            dialogStore.setPropertyValue(propertyName, resolvedValue, record);
        }

        // @TODO - need a better solution here - is this still needed?
        if (canCauseSideEffects) {
            // Debounce the sideeffects so that requests will not go on every key stroke.
            this.debounce(() => dialogStore.processSideEffects(propertyName, resolvedValue).catch((err) => {
                const title = serviceFactory.lang.dialog.errors.errorProcessingPropertyChange;
                onError({
                    title,
                    err,
                });
            }), 500);
        }
    }

    /** @deprecated - this should be removed when V2 complete */
    renderListProp(propName, props) {
        const { style, xStyle, propertyRef, isReadMode = true, allowAnnotations = true } = props;
        const themeStyle = this.getListThemeStyle();
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore, scopeManager } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        // < ----observables
        const { dialog, availableValuesStore } = dialogStore;
        const record = scopeManager.getRef(ScopeManager.SCOPED_RECORD_REF_NAME) || {};
        const property = dialogStore.getProperty(record, propName);
        if (!isReadMode) dialogStore.setLastFocusedProperty(propertyRef);
        // @TODO - report property not found!
        if (!property) return null;
        // observables----->
        const { view: list } = dialog;
        const propertyDef = dialog.propDefAtName(property.name);
        // this is a misnomer - this should be called column
        const viewDef = list.getColumnAt(property.name);
        const annotationMode = dialog.isReadViewMode && allowAnnotations ? utilities.annotationHelper.all : utilities.annotationHelper.none;
        // const isReadMode = true;
        const asyncDataCallback = this.loadAsyncDataList;
        const onValueChanged = this.handleValueChanged;
        const availableValues = this.getAvailableValues;
        const availableValuesLoader = this.loadAvailableValues;
        const viewType = list.type;

        // This is a temporary hook to see if we've created a 'Version 2' component yet
        const element = this.renderDetailsV2({ cellDef: viewDef, propertyDef, propertyRef, property, record, isReadMode, xStyle, style, themeStyle, availableValuesStore, annotationMode });
        if (element) return element;

        /** @deprecated - please use new (V2) component approach */
        const propertyProps = {
            property,
            propertyDef,
            propertyRef,
            viewDef,
            record,
            annotationMode,
            isReadMode,
            asyncDataCallback,
            onValueChanged,
            availableValues,
            availableValuesLoader,
            style: Layout.combineStyles(themeStyle, style),
            xStyle,
            viewType,
        };
        return React.createElement(componentFactory.getPlatformComponent('property'), propertyProps);
    }

    // handleOnRenderFieldAction = (action) => {
    //     const resolvedProps = this.resolveProperties();
    //     const { xStyle, toolTip } = resolvedProps;
    //     const viewId = this.getViewId(this.props.viewId);
    //     const { saltStore } = this.context;
    //     const dialogStore = saltStore.getDialogStoreForViewId(viewId);
    //     const { dialog } = dialogStore;
    //     const propName = this.getPropName();
    //     const property = dialogStore.getProperty(propName);
    //     const propertyDef = dialog.propDefAtName(propName);
    //     const { semanticType } = propertyDef;
    //     const isReadMode = dialog.isReadModeFor(propName);
    //     const { id } = action;
    //     const { actionStyles } = xStyle || {};
    //     const actionProps = {
    //         actionStyles: actionStyles ? { ...actionStyles } : null,
    //         toolTip,
    //         onFieldAction: this.handleFieldAction,
    //         isReadMode,
    //         action,
    //         key: id,
    //         semanticType,
    //         value: utilities.uiHelper.formatPropertyForRead(property, propertyDef),
    //     };
    //     return React.createElement(componentFactory.getAbstractPropertyComponent('fieldAction'), actionProps);
    // }

    handleFieldAction = (action) => {
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore, onTransition, onError } = this.context;
        const { uiStore } = saltStore;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        const { actionId } = action;
        const { clientFunction } = action;

        if (engineConstants.isClientAction(clientFunction)) {
            this.performClientAction(dialogStore, clientFunction);
        } else {
            pageController.performActionWithConfirmation({ actionId, selectedArray: null, dialogStore, uiStore, onTransition, onError });
        }
    };

    performClientAction = (dialogStore, clientFunction) => {
        const propertyName = this.getPropName();
        switch (clientFunction) {
            case engineConstants.action.clientActions.selectAll:
                {
                    const { availableValuesStore } = dialogStore;
                    const availableValues = availableValuesStore.getAvailableValues(propertyName);
                    const entireRow = availableValues.find((item) => item.description === '(Entire Row)');
                    this.handleValueChanged(propertyName, [ entireRow ]);
                }
                break;
            case engineConstants.action.clientActions.selectNone:
                this.handleValueChanged(propertyName, []);
                break;
            case engineConstants.action.clientActions.clearValue:
                this.handleValueChanged(propertyName, null);
                break;
            default:
                break;
        }
    }

    handleValueChanged = (propertyName, changedValue, mimeType = undefined) => {
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore, onError, scopeManager } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        let record;
        if (dialogStore.dialog.view.type === TypeNames.ListTypeName) {
            record = scopeManager.getRef(ScopeManager.SCOPED_RECORD_REF_NAME);
        }
        const { dialog } = dialogStore;
        const propDef = dialog.propDefAtName(propertyName);
        const { canCauseSideEffects } = propDef;

        if (mimeType) {
            dialogStore.setLargePropertyWithEncodedData(propertyName, changedValue, mimeType);
        } else {
            dialogStore.setPropertyValue(propertyName, changedValue, record);
        }

        if (canCauseSideEffects) {
            // Debounce the sideeffects so that requests will not go on every key stroke.
            this.debounce(() => dialogStore.processSideEffects(propertyName, changedValue).catch((err) => {
                const title = serviceFactory.lang.dialog.errors.errorProcessingPropertyChange;
                onError({
                    title,
                    err,
                });
            }), 500);
        }
    };

    debounce = (func, delay) => {
        clearTimeout(this.timerId);
        this.timerId = setTimeout(func, delay);
    }

    loadAsyncData = (recordId = undefined) => {
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        const propName = this.getPropName();
        return dialogStore.dialog.readLargeProperty(propName, recordId);
    };

    loadAsyncDataDetails = () => {
        return this.loadAsyncData();
    }

    loadAsyncDataList = () => {
        const { scopeManager } = this.context;
        const record = scopeManager.getRef(ScopeManager.SCOPED_RECORD_REF_NAME);
        return this.loadAsyncData(record.id);
    }

    getDetailsThemeStyle() {
        const { themeStore } = rootStore;
        const theme = themeStore.getSanitizedTheme();
        return theme.fonts.detailsAttribute;
    }

    getListThemeStyle() {
        const { themeStore } = rootStore;
        const theme = themeStore.getSanitizedTheme();
        return theme.fonts.compactListColN;
    }

    getFocusPropertyName = (propertyName, dialog, uiStore) => {
        const { id, isReadViewMode } = dialog;
        // We will do autofocus on the first field only in XHA.
        if (isReadViewMode || !BuildGlobals.isXHA()) return undefined;

        const firstFocusableProperty = uiStore.getValueForUIObject(id, constants.ui.FIRST_FOCUSABLE_PROPERTY);
        if (firstFocusableProperty) return firstFocusableProperty;

        const propertyDef = dialog.propDefAtName(propertyName);
        const isReadMode = dialog.isReadModeFor(propertyName);
        // Set the focus on the first maintainable property
        if (propertyDef.isWritable && !isReadMode) {
            uiStore.setValueForUIObject(id, constants.ui.FIRST_FOCUSABLE_PROPERTY, propertyName);
            return propertyName;
        }
        return undefined;
    }

    /** @deprecated - please use new (V2) component approach */
    loadAvailableValues = () => {
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore, scopeManager } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        const propName = this.getPropName();
        const { dialog } = dialogStore;
        let record = {};
        if (dialog.view.type === TypeNames.ListTypeName) {
            record = scopeManager.getRef(ScopeManager.SCOPED_RECORD_REF_NAME);
        }
        return dialogStore.updateAvailableValues(propName, record.id)
            .then(() => toJS(dialogStore.getAvailableValues(propName, record.id)));
    };

    /** @deprecated - please use new (V2) component approach */
    getAvailableValues = () => {
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore, scopeManager } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        const propName = this.getPropName();
        const { dialog } = dialogStore;
        let record = {};
        if (dialog.view.type === TypeNames.ListTypeName) {
            record = scopeManager.getRef(ScopeManager.SCOPED_RECORD_REF_NAME);
        }
        return toJS(dialogStore.getAvailableValues(propName, record.id));
    }
}

Property.contextType = SaltContext;
