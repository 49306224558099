/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import componentFactory from '../componentFactory';
import componentPropTypes from './abstractComponentPropTypes';
import engineConstants from '../engineConstants';

const propDefinitions = {
    style: componentPropTypes.style,
    value: componentPropTypes.value,
    tipText: componentPropTypes.tipText,
    imageSrc: componentPropTypes.imageSrc,
    isReadMode: componentPropTypes.isReadMode,
    isFocused: componentPropTypes.isFocused,
    onValueChanged: componentPropTypes.onValueChanged,
    xStyle: componentPropTypes.xStyle,
    maxLength: componentPropTypes.maxLength,
};

export default class TextWithImage extends Component {
    static propTypes = propDefinitions;
    static typeName = engineConstants.component.name.textWithImage;

    render() {
        const {
            style,
            value,
            tipText,
            imageSrc,
            isReadMode,
            isFocused,
            onValueChanged,
            xStyle,
            maxLength,
        } = this.props;
        const textImageProps = {
            style,
            value,
            tipText,
            imageSrc,
            isReadMode,
            isFocused,
            onValueChanged,
            xStyle,
            maxLength,
        };
        return React.createElement(componentFactory.getPlatformPropertyComponent('textImage'), { ...textImageProps });
    }
}
