// Core component styles
const coreStyles = {
    container: {
        display: 'flex',
        padding: '10px',
    },
    icon: {
        maxHeight: '35px',
        maxWidth: '100px',
        objectFit: 'contain',
    },
    iconContainer: {
        display: 'inline-flex',
        alignItems: 'center',
        padding: '0 10px',
    },
    image: {
        maxHeight: '35px',
        maxWidth: '100px',
        objectFit: 'contain',
    },
    imageContainer: {
        display: 'inline-flex',
        alignItems: 'center',
        padding: '0 10px',
    },
    text: {
        display: 'inline-flex',
        padding: '0 10px',
        alignItems: 'center',
        cursor: 'inherit',
        fontFamily: 'inherit',
    },
    typography: {},
};

/**
* Helper method to get the styles for Icon component
* @param {Object} contextStyles - Context styles for Icon Component
*/
const getStyles = (contextStyles = {}) => {
    return {
        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        iconContainer: {
            ...coreStyles.iconContainer,
            ...contextStyles.iconContainer,
        },

        imageContainer: {
            ...coreStyles.imageContainer,
            ...contextStyles.imageContainer,
        },

        icon: {
            ...coreStyles.icon,
            ...contextStyles.icon,
        },

        image: {
            ...coreStyles.image,
            ...contextStyles.image,
        },

        text: {
            ...coreStyles.text,
            ...contextStyles.text,
            ...contextStyles.typography,
        },
    };
};

export default getStyles;
