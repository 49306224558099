import { action, observable } from 'mobx';
import ListDialogStore from './ListDialogStore';

export default class CalendarStore extends ListDialogStore {
    /**
     * To maintain the drag-drop record
     */
    @observable updatedRecord = null;

    /**
     * Save dateRange for overriding record id
     */
    
    @observable dateRange = null;

    @action setDateRange(dateRange) {
        this.dateRange = dateRange;
    }

    @action setUpdatedRecord(record) {
        this.updatedRecord = record;
    }

    @action refresh = () => {
        const recordId = this.getDateRange();
        return this.refreshList(recordId);
    }

    getDateRange = () => {
        return this.dateRange;
    }

    getUpdatedRecord = () => {
        return this.updatedRecord;
    }
}
