/* @flow */
export default {
    progress: {
        title: 'Go Offline',
        upperText: 'Downloading files for offline.',
        lowerText: 'This may take some time.  A strong wifi connection is recommended.  Be aware this is a data intensive process.',
    },
    prompt: {
        title: 'Go Offline',
        message: 'Are you sure you would like to go offline?',
        confirm: 'Go Offline',
    },
    deletePrompt: {
        title: 'Clear Downloads',
        message: 'Are you sure you want to delete the downloaded items?',
        confirm: 'Delete All',
    },
    downloadPrompt: {
        title: 'Download All?',
        message: 'Are you sure you want to download the selected items?',
        confirm: 'Download All',
    },
};
