import { Property, propertyFormatter } from 'cv-dialog-sdk';

const comparable = (value, propertyDef) => {
    const parsedValue = propertyFormatter.parse(value, propertyDef);
    return Property.valueForCompare(parsedValue);
};

/*
    All plugins should have an exported function with this signature that returns a bindings object.
    Bindings is an object with names/values and/or names/functions to bind
 */
/* eslint-disable no-unused-vars */
export default (rootObject, expression) => {
    // for performance sake, return the initial instance of the function
    return { comparable };
};
