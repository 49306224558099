import React, { useRef } from 'react';
import * as PropTypes from 'prop-types';
import { Resizable as ResizableBox } from 're-resizable';

import getStyles from './Resizable.styles';

const Resizable = (props) => {
    const {
        children,
        contextStyles,
        direction,
        minWidth,
        minHeight,
        ...rest
    } = props;
    const {
        container,
    } = getStyles(contextStyles);

    const enableDirections = {
        right: (!!(direction === 'horizontal' || direction === 'both')),
        left: (!!(direction === 'horizontalAlt' || direction === 'bothAlt')),
        bottom: (direction === 'vertical'),
        bottomRight: (direction === 'both'),
        bottomLeft: (direction === 'bothAlt'),
    };

    const parentScrollOffset = useRef({ left: 0, top: 0 });

    const handleResizeChart = (_e, _direction, ref) => {
        // Save parent element scroll position when we start the resize process.
        const { scrollLeft = 0, scrollTop = 0 } = ref.parentElement || {};
        parentScrollOffset.current.left = scrollLeft;
        parentScrollOffset.current.top = scrollTop;
    };

    const handleResize = (_e, _direction, ref) => {
        // Set parent element scroll position to the value what it had when the resize started.
        // This will stop the scroll of parent container when resize is being done.
        ref.parentElement.scrollTo(parentScrollOffset.current.left, parentScrollOffset.current.top);
    };

    return (
        <ResizableBox
            enable={ enableDirections }
            className="abs-box__container"
            minWidth={ minWidth }
            minHeight={ minHeight }
            style={ {
                ...container,
            } }
            onResizeStart={ handleResizeChart }
            onResize={ handleResize }
            { ...rest }>
            { children }
        </ResizableBox>
    );
};

Resizable.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]),
    contextStyles: PropTypes.shape({
        container: PropTypes.object,
    }),
    direction: PropTypes.oneOf([
        'both',
        'bothAlt',
        'horizontal',
        'horizontalAlt',
        'vertical',
    ]),
    minWidth: PropTypes.number,
    minHeight: PropTypes.number,
};

Resizable.defaultProps = {
    contextStyles: {},
    minWidth: 100,
    minHeight: 100,
};

export default Resizable;
