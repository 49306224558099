/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import {
    Grid,
    TableHeaderRow,
    Table,
} from '@devexpress/dx-react-grid-material-ui';
import TableMUI from '@material-ui/core/Table';

/**
 * An extensive table grid control
 * @see https://devexpress.github.io/devextreme-reactive/react/grid/
 */
class DeTableLoading extends PureComponent {
    render() {
        const {
            columns,
            headerCell: LoadingTableHeaderCell,
            headerCellContent: LoadingTableHeaderCellContent,
            overrideMessages } = this.props;

        return (
            <div style={ { display: 'flex', flexDirection: 'column', overflow: 'hidden', flex: '1 0 300px' } }>
                <Grid
                    columns={ columns }
                    rows={ [] }>
                    <Table
                        height="auto"
                        messages={ { noData: overrideMessages.noData } } 
                        tableComponent={ this.renderTableComponent } />
                    <TableHeaderRow
                        cellComponent={ LoadingTableHeaderCell }
                        contentComponent={ LoadingTableHeaderCellContent } />
                </Grid>
            </div>
        );
    };
    
    // its wraps the table with an enclosing container which can be styled
    renderTableComponent = ( args ) => {
        return (
            <TableMUI
                ref={ args.tableRef }
                style={ { ...args.style, width: '-webkit-fill-available' } }>
                { args.children }
            </TableMUI>
        )
    }
}

/**
 * @typedef {Function} LocalizeMessage
 * @param {String} value
 * @returns {String}
 */

DeTableLoading.propTypes = {

    /** An array containing column data */
    columns: PropTypes.arrayOf(PropTypes.shape({
        /** The name identifier of the column */
        name: PropTypes.string,

        /** The column header display text */
        title: PropTypes.string,

        /** Overrides the default table column width in pixels or CSS-accepted units (auto, px, %, em, rem, vm, vh, vmin, vmax) */
        width: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
    })).isRequired,

    headerCell: PropTypes.func,
    headerCellContent: PropTypes.func,

    overrideMessages: PropTypes.shape({
        noData: PropTypes.string,
    }),
};

DeTableLoading.defaultProps = {
    overrideMessages: {
        noData: 'No records found...',
    },
};

export default DeTableLoading;
