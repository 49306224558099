import React from 'react';
import * as PropTypes from 'prop-types';

import LauncherItem from '../LauncherItem/LauncherItem';

import getStyles from './LauncherList.styles';

/**
 * A listing of workbench action launchers
 */
const LauncherList = (props) => {
    const {
        gridView,
        launchers,
        onLauncherClick,
        contextStyles,
        testID,
        filteredValue,
    } = props;

    const styles = getStyles(contextStyles);

    // Generate container props
    const containerProps = {
        className: 'c-launcher-list__container',
        style: styles.container,
    };

    // TODO: do the mobile equivalent
    if (!gridView) { containerProps.style = {}; }
    if (testID) { containerProps['test-id'] = `${testID}__launcher-list__container`; }

    return (
        <div { ...containerProps }>
            { launchers.map((launcher) => {
                const {
                    iconBase,
                    id,
                    name,
                } = launcher;

                // Generate launcher props
                const launcherProps = {
                    className: 'c-launcher-list__launcher-item',
                    contextStyles: {
                        actionAreaContainer: styles.launcherActionAreaContainer,
                        container: styles.launcher,
                        contentContainer: styles.launcherContentContainer,
                        icon: styles.launcherIcon,
                        iconContainer: styles.launcherIconContainer,
                        iconLoadingIndicator: styles.launcherIconLoadingIndicator,
                        text: styles.launcherText,
                        textContainer: styles.launcherTextContainer,
                    },
                    icon: iconBase,
                    text: name,
                };
                if (onLauncherClick) {
                    launcherProps.onClick = (event) => { onLauncherClick(launcher, event); };
                }
                if (testID) { launcherProps.testID = `${testID}__launcher-list__launcher-item`; }

                return (
                    <LauncherItem
                        key={ id }
                        filteredValue={ filteredValue }
                        { ...launcherProps } />
                );
            }) }
        </div>
    );
};

LauncherList.propTypes = {
    /** Styles for this component */
    contextStyles: PropTypes.shape({
        /** Styling for the main container */
        container: PropTypes.object,

        /** Styling for each launcher container */
        launcher: PropTypes.object,

        /** Styling for each launcher icon/image */
        launcherIcon: PropTypes.object,

        /** Styling for each launcher icon/image container */
        launcherIconContainer: PropTypes.object,

        /** Styling for each launcher text */
        launcherText: PropTypes.object,

        /** Styling for each launcher text container */
        launcherTextContainer: PropTypes.object,
    }),

    /** */
    gridView: PropTypes.bool,

    /** */
    launchers: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        iconBase: PropTypes.string,
    })).isRequired,

    /** */
    onLauncherClick: PropTypes.func,

    /** Id used for testing */
    testID: PropTypes.string,

    filteredValue: PropTypes.string,
};

LauncherList.defaultProps = {
    contextStyles: {},
    gridView: true,
};

export default LauncherList;
