// Core component styles
const coreStyles = {
    option: {
        flexGrow: 1,
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        alignItems: 'center',
        letterSpacing: 0.02,
        color: '#000000',
        paddingLeft: 8,
        paddingRight: 8,
        borderStyle: 'solid',
        borderColor: '#E0E0E0',
        width: 180,
        borderWidth: 0,
        borderRadius: 0,
        marginRight: 0,
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 6,
        paddingBottom: 6,
    },
    expandIcon: {
        color: '#000000',
    },
    optionSelected: {
        backgroundColor: 'rgba(28, 142, 169, 0.2)',
    },
    optionHover: {
        backgroundColor: '#F5F5F5',
    },
};

/**
 * Helper method to get the styles for the component
 * @param {Object} contextStyles - Context styles for the Component
 */
const getStyles = (contextStyles = {}) => {
    return {
        option: {
            ...coreStyles.option,
            ...contextStyles.option,
        },
        expandIcon: {
            ...coreStyles.expandIcon,
        },
        optionSelected: {
            ...coreStyles.optionSelected,
        },
        optionHover: {
            ...coreStyles.optionHover,
        },
    };
};

export default getStyles;