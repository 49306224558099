import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { constants } from 'cv-react-core';
import { XaltToolTip, XaltBox, XaltImage, XaltInputBase } from 'xalt-react-atoms';

/** @todo This needs to be added to a new atoms library for external use. */
/**
 * A platform component for building a text label
 */
export default class RWTextWithImage extends PureComponent {
    static propTypes = {
        style: PropTypes.object,
        value: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
            PropTypes.bool,
        ]),
        tipText: PropTypes.string,
        imageSrc: PropTypes.string,
        isReadMode: PropTypes.bool,
        isFocused: PropTypes.bool,
        xStyle: PropTypes.object,
        maxLength: PropTypes.number,
        alignImage: PropTypes.string,
    };

    static defaultProps = {
        style: {
            container: {},
            text: {},
        },
        value: '',
    };

    render() {
        // Get children prop for text
        const {
            style,
            value,
            tipText,
            imageSrc,
            isReadMode,
            isFocused,
            xStyle,
            maxLength,
        } = this.props;

        const {
            alignImage,
            imageHeight,
            imageWidth } = xStyle;

        // By default set the wrapper as a row.
        const componentStyle = { display: 'flex', flexDirection: 'row', ...style};

        const positioningStyles = { justifyContent: 'center' };
        const { imageAlignment } = constants;
        if (alignImage === imageAlignment.Under) {
            positioningStyles.backgroundImage = `url(${imageSrc})`;
            positioningStyles.backgroundSize = 'contain';
            positioningStyles.backgroundRepeat = 'no-repeat';
            positioningStyles.backgroundPosition = 'center';
        }
        else if (alignImage === imageAlignment.StretchUnder) {
            positioningStyles.backgroundImage = `url(${imageSrc})`;
            positioningStyles.backgroundSize = 'cover';
            positioningStyles.backgroundRepeat = 'no-repeat';
            positioningStyles.backgroundPosition = 'center';
        }

        const imagePadding = imageAlignment.Left ? { paddingRight: 6 } : { paddingLeft: 6 };
        const imageProps = {
            style: {
                height: imageHeight || 25,
                width: imageWidth || 25,
                ...imagePadding,
            },
            imageSrc,
        };

        const inputProps = {
            disabled: isReadMode,
            readOnly: isReadMode,
            value,
            isFocused,
            style: { textOverflow: 'ellipsis', ...style, backgroundColor: 'unset', borderWidth: 0 },
            maxLength,
        };

        const {
            display,
            flexGrow,
            flexShrink,
            width,
            height } = style;
        const tipStyle = {
            display: display || 'flex',
            flexGrow,
            flexShrink,
            width,
            height,
        };

        return (
            <XaltToolTip
                style={ { ...tipStyle } }
                tipText={ tipText }>
                <XaltBox style={ { ...positioningStyles, ...componentStyle } }>
                    { imageSrc && (alignImage === imageAlignment.Left || alignImage === imageAlignment.Center) &&
                        <XaltImage { ...imageProps } />
                    }
                    { alignImage !== imageAlignment.Center && <XaltInputBase { ...inputProps } /> }
                    { imageSrc && alignImage === imageAlignment.Right &&
                        <XaltImage { ...imageProps } />
                    }
                </XaltBox>
            </XaltToolTip>
        );
    }
}
