import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import * as PropTypes from 'prop-types';

import getStyles from './GridEditor.styles';

const GridEditor = forwardRef((props, ref) => {
    const inputRef = useRef();
    const {
        cellStyle,
        column: { colDef },
        contextStyles,
        onRenderItem,
        records,
        rowIndex,
        value,
    } = props;

    useImperativeHandle(ref, () => {
        return {
            getValue: () => value,
            afterGuiAttached: () => inputRef.current && inputRef.current.focus(),
        };
    });

    const {
        container,
    } = getStyles(contextStyles);
    const record = records[rowIndex];
    const propertyName = colDef.field;
    const propertyProps = {
        isReadMode: false,
        name: propertyName,
        propertyRef: inputRef,
        style: cellStyle,
    };

    return (
        <span
            key={ `${rowIndex}_${propertyName}` }
            style={ container }>
            { onRenderItem(record, propertyProps) }
        </span>
    );
});

GridEditor.propTypes = {
    /** Styles for cell */
    cellStyle: PropTypes.object,

    /** Column properties */
    column: PropTypes.object,

    /** Extended styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the cell container */
        container: PropTypes.object,
    }),

    /** Called to render property */
    onRenderItem: PropTypes.func,

    /** Array of records */
    records: PropTypes.array,

    /** Row index of the record */
    rowIndex: PropTypes.number,

    /** Value of the input field */
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.object,
        PropTypes.array,
    ]),
};

GridEditor.defaultProps = {
    contextStyles: {},
};

GridEditor.displayName = 'GridEditor';

export default GridEditor;
