import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import componentFactory from './componentFactory';
import SaltComponent from './SaltComponent';
import SaltContext from './SaltContext';
import pageController from '../controllers/pageController';
import FormLayout from './form/FormLayout';
import { utilities } from '../utilities';
import uiHelper from '../utilities/uiHelper';

@observer
export default class QuickActions extends SaltComponent {
    static propTypes = {
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        viewId: PropTypes.string,
    };

    render() {
        const { dialogStore, uiStore } = this.scopedContext;
        if (dialogStore) {
            const resolvedProps = this.resolveProperties();
            const { parentDialogStore: formDialogStore, dialog } = dialogStore;
            let formViewMode;
            if (formDialogStore.isExpandedOrSinglePanelView) {
                formViewMode = formDialogStore.isExpandedState ? FormLayout.FORM_VIEW_MODE.EXPANDED_VIEW : FormLayout.FORM_VIEW_MODE.SINGLE_VIEW;
            } else {
                formViewMode = FormLayout.FORM_VIEW_MODE.MULTI_VIEW;
            }
            const { view } = dialog;
            const { type: viewType, menu: topMenu } = view;
            const menu = topMenu ? uiHelper.asGenericMenu(dialog.id, topMenu.findContextMenu()) : [];
            const applicationBarMenu = topMenu ? uiHelper.asGenericMenu(dialog.id, topMenu.findActionBarMenu()) : [];
             // let the implementing panel know if we are in expanded mode
             const isExpanded = formViewMode && formViewMode === FormLayout.FORM_VIEW_MODE.EXPANDED_VIEW;
             // let the implementing panel know if we're layout managed with multiple views
            const showFormTools = formViewMode && (formViewMode === FormLayout.FORM_VIEW_MODE.MULTI_VIEW || formViewMode === FormLayout.FORM_VIEW_MODE.EXPANDED_VIEW);
            

            const menuProps = {
                ...resolvedProps,
                menu,
                applicationBarMenu,
                // xMenu should be used for client side menu operations that are not modeled in extender
                xMenu: this.getExtendedMenu(viewType, dialogStore, uiStore),
                viewType,
                showFormTools,
                isExpanded,
                onMenuAction: this.handleMenuAction,
            };
            return React.createElement(componentFactory.getPlatformComponent('quickActions'), menuProps);
        }
        return null;
    }

    get scopedContext() {
        const { saltStore, viewId, onError, onTransition } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        const { uiStore } = saltStore;
        return { dialogStore, viewId, onError, uiStore, onTransition };
    }

    getExtendedMenu(viewType, dialogStore, uiStore) {
        // build extended menu objects with the necessary pieces for each
        return pageController.getAvailableExtendedActions(viewType, dialogStore, uiStore);
    }

    handleMenuAction = (menuEvent) => {
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId); // RB/TJ - There is a dialogId in the event
        const { uiStore } = saltStore;
        const { onTransition, onError } = this.context;
        const { id, modifiers, ...rest } = menuEvent;
        pageController.performActionWithConfirmation({
            actionId: id,
            selectedArray: utilities.listHelper.getSelectedAsArray(uiStore, dialogStore),
            dialogStore,
            uiStore,
            onTransition,
            onError,
            actionParams: rest,
            modifiers,
        });
    };
}

QuickActions.contextType = SaltContext;
