import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';
import { constants } from '../constants';
import componentFactory from './componentFactory';
import SaltContext from './SaltContext';
import Layout from './xStyle/Layout';
import SaltComponent from './SaltComponent';
import uiHelper from '../utilities/uiHelper';
import rootStore from '../stores/rootStore';
import BuildGlobals from '../provider/BuildGlobals';

@observer
export default class FormView extends SaltComponent {
    static propTypes = {
        id: PropTypes.string,
        children: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.arrayOf(PropTypes.element),
        ]),
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xStyle: PropTypes.oneOfType([
            Layout.layoutPropType,
            PropTypes.arrayOf(Layout.layoutPropType),
        ]),
        scroll: PropTypes.string,
        assert: PropTypes.shape({
            refName: PropTypes.string,
            expr: PropTypes.string,
        }),
    };

    componentDidUpdate() {
        const { id } = this.props;
        const { saltStore } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(id);
        this.updateMenus(dialogStore);
        if (dialogStore !== this.dialogStore) {
            // retain the reference for an identity comparison later
            this.dialogStore = dialogStore;
            this.registerAutorun(dialogStore);
        }
    }

    componentDidMount() {
        const { id } = this.props;
        const { saltStore } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(id);
        this.updateMenus(dialogStore);
        if (dialogStore !== this.dialogStore) {
            // retain the reference for an identity comparison later
            this.dialogStore = dialogStore;
            this.registerAutorun(dialogStore);
        }
    }

    componentWillUnmount() {
        this.disposer();
        delete this.dialogStore;
    }

    render() {
        const { id, style, scroll, assert, xStyle, children, ...rest } = this.props;
        const boxProps = { style, scroll, assert, xStyle, ...rest };

        // ------------------------SPECIAL NOTE------------------------
        // ----------------------------TODO----------------------------
        // TODO: This is not a pattern anyone should follow. It is a temporary
        // Work around until work item: https://dev.azure.com/HexagonXalt/Xalt%20Mobility/_workitems/edit/17252
        // can be completed. The theme styling for XHA is different than XNA and until those two are merged,
        // this is a temporary work around. Please do not follow this pattern if you can.
        // All pages should have a consistent background color. For now we will follow what the workbench laucher has
        // set for it's background.
        if (BuildGlobals.isXHA()) {
            const { themeStore } = rootStore;
            const theme = themeStore.getSanitizedTheme();
            boxProps.style = {
                backgroundColor: theme.workbenchLauncherBackgroundColor,
                ...style,
            };
        }
        // ----------------------------TODO----------------------------
        // ------------------------SPECIAL NOTE------------------------

        const component = componentFactory.getAbstractComponent('box');
        return React.createElement(component, boxProps, children);
    }

    /*
        For single panes (only 1 child pane OR any pane is expanded),
        we add panel menu items to navigation menu and show ONLY the navigation menu
     */
    updateMenus(dialogStore) {
        const { saltStore: { uiStore } } = this.context;
        const {
            dialog,
        } = dialogStore;
        const { view: formView, availableViews, selectedViewId } = dialog;
        const { menu: formViewMenu } = formView;
        const formMenuItems = formViewMenu ? uiHelper.asGenericMenu(dialog.id, formViewMenu.findContextMenu()) : [];
        const formAppBarMenu = formViewMenu ? uiHelper.asGenericMenu(dialog.id, formViewMenu.findActionBarMenu()) : [];
        const selectedViewDescriptor = availableViews ? availableViews.find((f) => (f.id === selectedViewId)) : {};
        const availableViewsObj = { viewDescriptors: availableViews, selectedViewDescriptor };
        this.updateFormControls(dialogStore, uiStore, formMenuItems, formAppBarMenu, availableViewsObj);
    }

    updateFormControls(dialogStore, uiStore, menuItems = [], appBarMenu = [], formAvailableViews) {
        const formControlsProps = {
            menuItems: uiHelper.assignMenuKeys(menuItems),
            applicationBarMenu: appBarMenu,
            availableViews: formAvailableViews,
        };
        // no need to persist this value - it will be rebuilt with page (hence the 'false' parameter)
        uiStore.setValueForUIObject(dialogStore.dialog.id, constants.ui.FORM_CONTROLS_PROPS, formControlsProps, false);
    }

    registerAutorun(formDialogStore) {
        if (this.disposer) {
            this.disposer();
        }
        this.disposer = autorun(() => {
            // We need to watch for any of these values changing. This is our indicator that we need to rebuild the menus. For
            // tab views we have a tabsSingleDialogId or we don't. Previouse we just checked to see if it was valid, but this caused
            // issues with the first tab that contained multiple views. If our first tab contains multiple views, we set the tabSingleId
            // to null because it contains multiple dialogId's.
            // Work Item Reference: https://dev.azure.com/HexagonXalt/Xalt%20Mobility/_workitems/edit/13554
            if (formDialogStore.expandedDialogId || formDialogStore.tabSingleDialogId || !formDialogStore.tabSingleDialogId) {
                this.updateMenus(formDialogStore);
            }
        });
    }
}

FormView.contextType = SaltContext;
