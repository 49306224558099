import React from 'react';
import { BaseComponentBuilder } from 'cv-react-core';
import { LargeProperty } from 'cv-dialog-sdk';

import FilePickerInput from '../../components/base/FilePickerInput';

/**
 * A builder for creating file picker components
 */
/**
 * @deprecated This component is being deprecated after  component refactoring.
 */
class FilePickerInputComponentBuilder extends BaseComponentBuilder {
    /**
     * COMPONENT BASED PROPERTIES
     */

    /** Begin Context Styles */
    setStyle(style) {
        if (style && typeof style === 'object') {
            this.props.style = style;
        }
        return this;
    }
    getStyle() {
        return this.props.style || {};
    }

    setInputStyles(inputStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            input: inputStyles,
        });
    }
    getInputStyles() {
        const { input } = this.getContextStyles();
        return input || {};
    }
    /** End Context Styles */

    setOnFileUploadHandler(onChangeHandler) {
        if (typeof onChangeHandler === 'function') {
            this.data.onChangeFileUpdate = onChangeHandler;
        }
        return this;
    }
    getOnFileUploadHandler() {
        return this.data.onChangeFileUpdate;
    }

    setDisabled(disabled = true) {
        this.props.disabled = disabled;
        return this;
    }
    getDisabled() {
        return this.props.disabled;
    }

    setMaxLength(maxLength) {
        if (typeof maxLength === 'number' && maxLength >= 0) {
            this.props.maxLength = maxLength;
        }
        return this;
    }
    getMaxLength() {
        return this.props.maxLength;
    }

    /** End Props */

    processStyle() {
        const categorizedStyles = BaseComponentBuilder.styleHelper.categorizeNativeStyles(this.getStyle());

        this.setContainerStyles({
            ...categorizedStyles.container,
            ...this.getContainerStyles(),
        });
        this.setInputStyles({
            ...categorizedStyles.text,
            ...this.getInputStyles(),
        });
    }

    buildProps() {
        // Update the display text style
        this.processStyle();

        // Update change handler to support property / value data
        this.processChangeHandler();

        // Set input properties based on dialog data
        this.processInputProps();
    }

    processInputProps() {
        const propDef = this.getPropDef();

        // Modified logic to look for length vaidation from propertyDef.
        // And we will be removing validation on displayLength once every validation is passed through Length property.
        const displayLength = propDef.length || propDef.displayLength;
        if (displayLength) { this.setMaxLength(displayLength); }
    }


    /**
     * BUILD AND RETURN COMPONENT AS REACT ELEMENT
     */

    build() {
        // Process and construct final props
        this.buildProps();

       // Wrap with menu for available actions
       return (
           <FilePickerInput
               { ...this.getProps() }
               onChange={ this.getOnFileUploadHandler() } />
    );
    }

    processChangeHandler() {
        const onValueChangeHandler = this.getOnValueChangeHandler();
        const isReadMode = this.getReadMode();
        const property = this.getProperty();
        const { name: propertyName = '' } = property;
        const { properties } = this.getRecord();
        const propertyFieldName = this.extractPropertyFieldToStoreName();
        const uploadFieldNameProperty = properties.find((prop) => prop.name === propertyFieldName);

        if (!isReadMode && !!propertyName && !!onValueChangeHandler) {
            this.setOnFileUploadHandler( (event) => {
                const file = event.target.files[0];

                const readFileDataAsBase64 = () => new Promise((resolve, reject) => {
                    const reader = new FileReader();

                    reader.onload = (fileEvent) => {
                        resolve(fileEvent.target.result);
                    };

                    reader.onerror = (err) => {
                        reject(err);
                    };

                    reader.readAsDataURL(file);
                });

                const promise = readFileDataAsBase64(file);

                promise.then((dataURI) => {
                    const fileType = file.type || LargeProperty.DEFAULT_MIME_TYPE;
                    const data = dataURI.replace(`data:${fileType};base64,`, '');

                    onValueChangeHandler(propertyName, data, fileType);

                    // uploadFieldNameProperty
                    if (uploadFieldNameProperty){
                        // uploadFieldName porperty's name is updated statically
                        onValueChangeHandler(uploadFieldNameProperty.name, file.name);
                    }
                });
            });
        }
    }

    // logic to extract the upload action associate with property field
    extractPropertyFieldToStoreName() {
        const viewDef = this.getViewDef();
        const { actions } = viewDef;

        if (Array.isArray(actions)) {
            const action = actions.find((a) => a.clientFunction === 'UPLOAD_FILE');
            if (action) {
                // Set tooltip data
                this.setToolTip(action.label);
                return action.actionId.split(':')[1];
            }
        }

        return undefined;
    }
}

export default FilePickerInputComponentBuilder;
