import React, { Component, Fragment } from 'react';
import * as PropTypes from 'prop-types';
import { PhotoshopPicker } from 'react-color';
import Popover from '@material-ui/core/Popover';
import IconButton from '../IconButton/IconButton';

import getStyles from './ColorPicker.styles';

/**
 * A control that allows a user to select colors.
 */
class ColorPicker extends Component {
    constructor(props) {
        super(props);
        const { value } = props;
        this.state = {
            anchorEl: null,
            color: value,
        };
    }

    render() {
        const {
            anchorEl,
            color,
        } = this.state;
        const {
            contextStyles,
            disabled,
            showColorPicker,
            size,
            testID,
            toolTip,
            value,
        } = this.props;
        const styles = getStyles(contextStyles);

        // Generate preview style
        const previewStyle = {
            ...styles.preview,
            backgroundColor: value,
        };

        // Generate icon props
        const iconProps = {
            contextStyles: {
                container: styles.container,
                icon: styles.icon,
            },
            iconName: 'colorize',
            iconSize: size,
            onClick: this.showColorPicker,
            title: toolTip,
        };

        if (disabled) { iconProps.disabled = disabled; }
        if (testID) { iconProps.testID = testID; }

        // Check for the validity of the color in order to show preview
        const isValidColor = (strColor) => {
            const s = new Option().style;
            s.color = strColor;
            return s.color !== '';
        };

        return (
            <Fragment>
                { isValidColor(value) && <span style={ previewStyle } /> }
                { showColorPicker && <IconButton { ...iconProps } /> }
                <Popover
                    anchorEl={ anchorEl }
                    anchorOrigin={ {
                        vertical: 'bottom',
                        horizontal: 'center',
                    } }
                    onClose={ this.handleCancel }
                    open={ !!anchorEl }
                    transformOrigin={ {
                        horizontal: 'center',
                        vertical: 'top',
                    } }>
                    <PhotoshopPicker
                        color={ color || '' }
                        onAccept={ this.handleAccept }
                        onCancel={ this.handleCancel }
                        onChange={ this.handleColorChange } />
                </Popover>
            </Fragment>
        );
    }

    componentDidUpdate(prevProps) {
        const { value } = this.props;

        if (prevProps.value !== value) {
            this.handleColorReset(value);
        }
    }

    /**
     * Helper method to open the color picker
     * @param {SyntheticEvent} event
     * @returns {void}
     */
    showColorPicker = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    }

    /**
     * Called on accepting the color
     * @returns {void}
     */
    handleAccept = () => {
        const {
            color,
        } = this.state;
        const { onChange } = this.props;

        this.setState({
            anchorEl: null,
        }, () => {
            if (onChange) {
                onChange(color);
            }
        });
    }

    /**
     * Called on clicking cancel on color picker
     * @returns {void}
     */
    handleCancel = () => {
        const { value } = this.props;
        this.setState({
            anchorEl: null,
            color: value,
        });
    }

    /**
     * Called on color change in color picker
     * @returns {void}
     */
    handleColorChange = (color) => {
        this.setState({
            color: color.hex,
        });
    }

    /**
     * Helper method to reset the color in color picker when prop changes
     * @param {String} value - color value
     * @returns {void}
     */
    handleColorReset = (value) => {
        this.setState({
            color: value,
        });
    }
}

ColorPicker.propTypes = {
    /** Styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the container surrounding the icon */
        container: PropTypes.object,

        /** Styles for the icon */
        icon: PropTypes.object,

        /** Styles for the color preview component */
        preview: PropTypes.object,
    }),

    /** Prevent press events */
    disabled: PropTypes.bool,

    /**
     * Called when color is changed
     * @param {String} value - The color value
     */
    onChange: PropTypes.func,

    /** Sets color picker icon visibility */
    showColorPicker: PropTypes.bool,

    /** The size of the icon (fontSize can also be used in the icon styles) */
    size: PropTypes.number,

    /** Id used for testing */
    testID: PropTypes.string,

    /** toolTip string */
    toolTip: PropTypes.string,

    /** The value to populate in the color picker */
    value: PropTypes.string,
};

ColorPicker.defaultProps = {
    contextStyles: {},
    size: 25,
    value: '',
};

export default ColorPicker;
