// Core component styles
const coreStyles = {
    bar: {
    },

    modal: {
        padding: '32px 20px',
        backgroundColor: 'rgba(255, 255, 255, 0.75)',
        backdropFilter: 'blur(80px)',
        borderRadius: '6px',
        width: '342px',
        boxSizing: 'border-box',
        margin: '0px',
        alignItems: 'center',
        boxShadow: 'none',
    },

    overlay: {
    },

    text: {
        flexWrap: 'wrap',
        marginTop: '15px',
        whiteSpace: 'pre-wrap',
        lineHeight: '16px',
    },

    textContainer: {
        textAlign: 'center',
        marginBottom: '1rem',
    },
};

/**
 * Helper method to get the styles for the component
 * @param {Object} contextStyles - Context styles for the Button Component
 */
const getStyles = (contextStyles = {}) => {
    return {
        bar: {
            ...coreStyles.bar,
            ...contextStyles.bar,
        },
        
        overlay: {
            ...coreStyles.overlay,
            ...contextStyles.overlay,
        },
        
        modal: {
            ...coreStyles.modal,
            ...contextStyles.modal,
        },

        text: {
            ...coreStyles.text,
            ...contextStyles.text,
        },

        textContainer: {
            ...coreStyles.textContainer,
            ...contextStyles.textContainer,
        },
    };
};

export default getStyles;
