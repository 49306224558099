import React from 'react';
import * as PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';

/**
 * A platform component for building a html input
 */
const XaltInputAdornment = (props) => {
    const {
        position,
        children,
        style,
    } = props;

    const adornmentProps = {
        className: 'x-adornment__base',
        position,
        children,
        style,
    };

    return React.createElement(InputAdornment, { ...adornmentProps } );
};

XaltInputAdornment.propTypes = {
    /** where to position the adornment inside an input */
    position: PropTypes.string,

    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.node,
        PropTypes.element,
    ]),
    
    style: PropTypes.object,
};

XaltInputAdornment.defaultProps = {
    position: 'end',
};

export default XaltInputAdornment;
