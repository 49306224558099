// Core component styles
const coreStyles = {
    actions: {
    },

    content: {
    },

    divider: {
    },

    errorIcon: {
        color: '#f00',
    },

    header: {
    },

    headerIcon: {
        marginRight: '8px',
    },

    infoIcon: {
        color: '#000',
    },

    message: {
        fontSize: '16px',
    },

    messageTitle: {
        fontSize: '20px',
    },

    modal: {
    },

    overlay: {
    },

    stackTrace: {
        fontSize: '16px',
    },

    title: {
        display: 'flex',
        alignItems: 'center',
    },

    warningIcon: {
        color: '#ffa500',
    },
};

/**
 * Combines coreStyles and contextStyles
 * @param {Object} contextStyles
 */
const getStyles = (contextStyles = {}) => {
    return {
        actions: {
            ...coreStyles.actions,
            ...contextStyles.actions,
        },

        content: {
            ...coreStyles.content,
            ...contextStyles.content,
        },

        divider: {
            ...coreStyles.divider,
            ...contextStyles.divider,
        },

        errorIcon: {
            ...coreStyles.headerIcon,
            ...contextStyles.headerIcon,
            ...coreStyles.errorIcon,
            ...contextStyles.errorIcon,
        },

        header: {
            ...coreStyles.header,
            ...contextStyles.header,
        },

        infoIcon: {
            ...coreStyles.headerIcon,
            ...contextStyles.headerIcon,
            ...coreStyles.infoIcon,
            ...contextStyles.infoIcon,
        },

        message: {
            ...coreStyles.message,
            ...contextStyles.message,
        },

        messageTitle: {
            ...coreStyles.messageTitle,
            ...contextStyles.messageTitle,
        },

        modal: {
            ...coreStyles.modal,
            ...contextStyles.modal,
        },

        overlay: {
            ...coreStyles.overlay,
            ...contextStyles.overlay,
        },

        stackTrace: {
            ...coreStyles.stackTrace,
            ...contextStyles.stackTrace,
        },

        title: {
            ...coreStyles.title,
            ...contextStyles.title,
        },

        warningIcon: {
            ...coreStyles.headerIcon,
            ...contextStyles.headerIcon,
            ...coreStyles.warningIcon,
            ...contextStyles.warningIcon,
        },
    };
};

export default getStyles;
