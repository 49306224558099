/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import componentFactory from '../componentFactory';
import componentPropTypes from './abstractComponentPropTypes';

// Note: these should be a subset of abstractComponentPropTypes
const propDefinitions = {
    style: componentPropTypes.style,
    value: componentPropTypes.value,
    tipText: componentPropTypes.tipText,
    isReadMode: componentPropTypes.isReadMode,
    isFocused: componentPropTypes.isFocused,
    onValueChanged: componentPropTypes.onValueChanged,
    xStyle: componentPropTypes.xStyle,
    asyncDataCallback: componentPropTypes.asyncDataCallback,
};

/**
 * Note: We'll likely need to share some code with other image types here
 * We'll need a static image propery helper (or something like that)
 */

// this class should handle all image types for display
// 1) a url string, 2) preloaded encoded data 3) data that needs to be loaded by an asynchronous callback
// platform component does not need to be responsible for loading the data
// platform can show a placeholder if it wants to
export default class Image extends Component {
    static propTypes = propDefinitions;

    render() {
        const {
            style,
            tipText,
            isReadMode,
            isFocused,
            onValueChanged,
            xStyle,
        } = this.props;

        const imageSrc = this.getImageSrc();

        const imageProps = {
            style,
            imageSrc,
            tipText,
            isReadMode,
            isFocused,
            onValueChanged,
            xStyle,
        };
        return React.createElement(componentFactory.getPlatformPropertyComponent('image'), { ...imageProps });
    }

    // @TODO load async data here
    // when loaded, update state and run getImageSrc again
    componentDidMount() {
        const { asyncDataCallback } = this.props;
        if (asyncDataCallback) asyncDataCallback();
    }

    // @TODO determine image src from value
    // could be url, data url from inline content, or none until loaded asynchronously if large prop
    // eslint-disable-next-line no-unused-vars
    getImageSrc(value) {
        return null;
    }
}
