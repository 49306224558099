import { constants } from 'cv-react-core';
import * as PropTypes from 'prop-types';
import React from 'react';

import QuickActions from '../components/base/QuickActions/QuickActions';
import RWSaltComponent from './RWSaltComponent';

class RWQuickActions extends RWSaltComponent {
    static propTypes = {
        // Allows styling of the 'exterior' scroll pane itself
        // Some values don't make sense here and are not allowed (i.e. align-items)
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),

        // Standard Menu - Array of normalized actions to shown on menu
        menu: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            icon: PropTypes.string,
            menuText: PropTypes.string,
        })),
        // Application Bar menu's that are button type menu's to display in the app
        // Contains an array of menu's
        applicationBarMenu: PropTypes.array,

        onMenuAction: PropTypes.func,

        // Is this panel in a form with other panels? (i.e. should it show an expansion tool?)
        showFormTools: PropTypes.bool,

        // One of view type names found in { TypeNames } from cv-dialog-sdk
        viewType: PropTypes.string,

        // Extended Menu - Additional available actions that may be OPTIONALLY shown elsewhere (i.e. toolbar)
        xMenu: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            icon: PropTypes.string,
            menuText: PropTypes.string,
        })),

        // to know whether current section is in expanded view
        isExpanded: PropTypes.bool,
    };
    render() {
        const {
            applicationBarMenu,
            menu,
            xMenu,
            showFormTools,
            viewType,
            isExpanded,
        } = this.props;
        return (
            <QuickActions
                menu={ menu }
                xMenu={ xMenu }
                viewType={ viewType }
                showFormTools={ showFormTools }
                isExpanded={ isExpanded }
                actions={ applicationBarMenu }
                onMenuAction={ this.handleMenuAction } />
        );
    }

    handleMenuAction = (groupEvent, nativeEvent) => {
        const { onMenuAction } = this.props;
        const modifiers = (nativeEvent && nativeEvent.altKey) ? { [constants.transitionModifiers.OPEN_IN_TAB]: true } : undefined;
        const event = {
            ...groupEvent,
            modifiers,
        };
        onMenuAction(event);
    }
}

export default RWQuickActions;
