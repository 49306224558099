const coreStyles = {
    container: {
        display: 'flex',
        flexGrow: 1,
        flexShrink: 1,
        height: '100%',
        minHeight: '290px',
        minWidth: '290px',
    },
};

/**
 * Helper method to get the styles for Icon component
 * @param {Object} contextStyles
 * @return {Object}
 */
const getStyles = (contextStyles = {}) => {
    return {
        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },
    };
};

export default getStyles;
