import { storage } from './localStorageConstants';
import { imagePickerMenuIds } from './imagePickerMenu';
import { imageAlignment } from './imageAlignment';
import { devices } from './deviceNames';
import { routes, displayHints } from './routeNamesConstants';
import { session } from './sessionConstants';
import { settings } from './settingsConstants';
import { ui } from './uiStoreKeys';
import { error } from './error';
import { map } from './mapConstants';
import { clientType } from './clientType';
import { transitionModifiers } from './transitionModifiers';
import { searchSort } from './searchSort';
import { viz } from './vizConstants';
import { dateTypes, dateViews, pickerVariants } from './dateConstants';
import { oauth } from './oAuthConstants';

export const constants = {
    storage,
    devices,
    dateTypes,
    dateViews,
    pickerVariants,
    imagePickerMenuIds,
    routes,
    displayHints,
    session,
    settings,
    ui,
    error,
    map,
    clientType,
    transitionModifiers,
    searchSort,
    viz,
    oauth,
    imageAlignment,
};
