import SimpleStringAttribute from './SimpleStringAttribute';

export default class SimpleXStyleBarChartPropAttribute extends SimpleStringAttribute {
    exportTo(context, styleSet) {
        const { xStyle } = styleSet;
        if (!xStyle.gaugeChartProp) {
            xStyle.gaugeChartProp = {};
        }

        if (!xStyle.container) {
            xStyle.container = {};
        }

        const style = styleSet.xStyle.gaugeChartProp;
        if (typeof this.value === 'string' && SimpleStringAttribute.hasConstantRef(this.value)) {
            SimpleStringAttribute.exportConstantAsRef(style, this.overrideName, this.value);
        } else {
            style[this.overrideName] = this.value;
        }
    }
}
