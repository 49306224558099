import { action, computed, observable } from 'mobx';

/**
 * The purpose of this class is to help orchestrate the selection state of an array
 * of records that are loaded into the store.
 */
export default class SelectedRecordsStore {
    @observable recordSelectionState = { };
    lastSelectedRecordId = undefined;

    /**
     * Get the last item mark as selected.
     */
    @computed get lastSelectedRecord() {
        return this.lastSelectedRecordId;
    }

    /**
     * Returns all the records current selection state.
     * @returns Map
     */
    getRecordsSelectionState() {
        return this.recordSelectionState;
    }

    /**
     * Returns an array of only records who state value is true/selected
     * @returns Array
     */
    getSelectedRecords() {
        return Object.fromEntries(
            // eslint-disable-next-line no-unused-vars
            Object.entries(this.recordSelectionState).filter(([ key, value ]) => value === true),
        );
    }

    /**
     * Set the selection state of all records to false
     */
    @action clearSelectedRecords() {
        const selectedRecords = this.getSelectedRecords();
        Object.entries(selectedRecords).forEach(([ key ]) => {
            this.setRecordSelectionState(key, false, false);
        });
    }

    /**
     * @param {string} id record Id
     * @returns {undefined} indicator the value was not set.
     */
    @action setLastSelectedRecordId(id) {
        if (!id) return;
        this.lastSelectedRecordId = id;
    }

    /**
     * @param {string} id Record Id
     * @param {boolean} isSelected selection state
     * @returns {undefined} indicator the value was not set.
     */
    @action setRecordSelectionState(id, isSelected = false, setLastRecordSelection = true) {
        if (!id) return;

        this.recordSelectionState[id] = isSelected;
        if (setLastRecordSelection) {
            this.lastSelectedRecordId = id;
        }
    }

    /**
     * Sets up the map of records to be observed
     * @param {array} records
     */
    @action refreshSelectedRecord(records) {
        const reSelectRecordsIfExists = this.getSelectedRecords();
        this.recordSelectionState = {}; // Reset all selection content.
        records.forEach((record) => {
            const retainSelection = reSelectRecordsIfExists[record.id];
            this.setRecordSelectionState(record.id, retainSelection, false);
        });
    }

    /**
     * Adds presaved records to the map so that selection is retained
     * @param {array} savedRecords
     */
    @action restoreSavedSelections(savedRecords = []) {
        savedRecords.forEach(record => {
            this.setRecordSelectionState(record.id, true);
        });
    }

    /**
     * Refresh the counter of selected records
     */
    @computed get selectedRecordCount() {
        return Object.keys(this.getSelectedRecords()).length;
    }

    /**
     * Returns boolean indicating if the record selection state of true or false
     * @param {string} id Record Id
     * @returns {boolean} indicating the record is selected or not
     */
    isRecordSelected(id) {
        return this.recordSelectionState[id] || false;
    }
}
