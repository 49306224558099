import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Log } from 'cv-dialog-sdk';
import { constants } from '../constants';
import componentFactory from './componentFactory';
import SaltComponent from './SaltComponent';
import SaltContext from './SaltContext';
import pageController from '../controllers/pageController';
import uiHelper from '../utilities/uiHelper';


/**
 * Note:
 * The SaltEditorStore manages all state here EXCEPT for the salt document itself.
   The 'active' (the one being edited) is stored in the DialogStore so that we can see changes applied in real time.
   The 'documentName' (in the SaltEditorStore) and the salt document itself (in the DialogStore) MUST be kept in sync
 */
@observer
export default class SaltEditor extends SaltComponent {
    static propTypes = {
        rootDialogStore: PropTypes.object,
        uiStore: PropTypes.object,
        onError: PropTypes.func,
    };

    render() {
        const { rootDialogStore, uiStore } = this.props;
        const rootDialogId = rootDialogStore.dialog.id;
        const visibleDialogId = uiStore.getValueForUIObject(rootDialogId, constants.ui.SALT_EDITOR_VISIBLE_DIALOG_ID);
        if (visibleDialogId) {
            const editorProps = uiStore.getValueForUIObject(visibleDialogId, constants.ui.SALT_EDITOR_PROPS);
            if (editorProps) {
                const { dialogStore, saltEditorStore } = editorProps;
                const documentNames = saltEditorStore.saltDocumentNames.map(documentName => documentName.name);
                const platformProps = {
                    show: true,
                    title: `${dialogStore.dialog.paneTitle} Salt Editor`,
                    onSave: this.onSave,
                    onTest: this.onTest,
                    onRestore: this.onRestore,
                    onClose: this.onClose,
                    onSelectDocument: this.onSelectDocument,
                    onDocumentChange: this.onDocumentChange,
                    onDocumentNameChange: this.onDocumentNameChange,
                    onDialogIdChange: this.onDialogIdChange,
                    salt: saltEditorStore.activeDocumentString,
                    saltDocumentName: saltEditorStore.activeDocumentName,
                    currentDialogId: visibleDialogId,
                    dialogNames: this.createDialogSelectionList(),
                    documentNames,
                    testText: 'Test Salt',
                    restoreText: 'Restore',
                    saveText: 'Save And Apply',
                    closeText: 'Close',
                    errorMessage: saltEditorStore.editorError,
                };
                return React.createElement(componentFactory.getPlatformComponent('saltEditor'), platformProps);
            }
        }
        return null;
    }

    createDialogSelectionList() {
        const { rootDialogStore } = this.props;
        return [
            { id: rootDialogStore.dialog.id, name: uiHelper.getViewId(rootDialogStore.dialog.view) },
            ...rootDialogStore.childDialogStores.map(childDialogStore => {
                return { id: childDialogStore.dialog.id, name: uiHelper.getViewId(childDialogStore.dialog.view) };
            }),
        ];
    }

    getProps() {
        const { rootDialogStore, uiStore, onError } = this.props;
        const rootDialogId = rootDialogStore.dialog.id;
        const visibleDialogId = uiStore.getValueForUIObject(rootDialogId, constants.ui.SALT_EDITOR_VISIBLE_DIALOG_ID);
        const editorProps = visibleDialogId && uiStore.getValueForUIObject(visibleDialogId, constants.ui.SALT_EDITOR_PROPS);
        return { uiStore, editorProps, onError };
    }

    hide() {
        const { rootDialogStore, uiStore } = this.props;
        const rootDialogId = rootDialogStore.dialog.id;
        uiStore.removeValueForUIObject(rootDialogId, constants.ui.SALT_EDITOR_VISIBLE_DIALOG_ID);
    }

    onDialogIdChange = (dialogId) => {
        const { rootDialogStore, uiStore, onError } = this.props;
        const dialogStore = dialogId === rootDialogStore.dialog.id ? rootDialogStore : rootDialogStore.getChildDialogStore(dialogId);
        pageController.performShowSaltEditor({ dialogStore, uiStore, onError });
    }

    onDocumentChange = (saltDocumentString) => {
        const { editorProps } = this.getProps();
        if (editorProps) {
            const { saltEditorStore } = editorProps;
            saltEditorStore.setActiveDocumentString(saltDocumentString);
        }
    }

    onDocumentNameChange = (documentName) => {
        const { editorProps } = this.getProps();
        if (editorProps) {
            const { saltEditorStore } = editorProps;
            saltEditorStore.setActiveDocumentName(documentName);
        }
    }

    onSelectDocument = (name) => {
        const { editorProps, onError } = this.getProps();
        if (editorProps) {
            const { dialogStore, saltEditorStore } = editorProps;
            saltEditorStore.loadSaltDocument(name).then((saltDocument) => {
                dialogStore.setSaltDocument(saltDocument);
            }).catch(err => {
                onError({ title: 'Failed To Load Salt Document', err });
            });
        }
    }

    // Save if there is a name and document.  If the saltString is empty (falsey), delete it
    onSave = () => {
        const { editorProps, onError } = this.getProps();
        if (editorProps) {
            const { dialogStore, saltEditorStore } = editorProps;
            const { activeDocumentString, activeDocumentName } = saltEditorStore;
            if (!activeDocumentName) {
                saltEditorStore.setEditorError('Document Name Cannot Be Empty');
            } else if (activeDocumentString === '') {
                saltEditorStore.deleteDocument(activeDocumentName).then(() => {
                    this.onRestore(); // reset the live, visible salt document in case we were previewing this one
                }).catch(err => {
                    onError({ title: 'Failed To Delete Document', err });
                });
            } else {
                try {
                    const saltDocument = JSON.parse(activeDocumentString);
                    saltEditorStore.saveOrUpdateDocument(saltDocument, activeDocumentName).then(() => {
                        /**
                         * Ref styles are not applying correctly when the same salt document is applied.
                         * For now, as a work around we are nullify the salt first and then applying the actual salt.
                         * we ( Rob) will relook onto it later again.
                         */
                        dialogStore.setSaltDocument(null);
                        dialogStore.setSaltDocument(saltDocument); // set the live, visible salt document to this one
                        this.hide();
                    }).catch(err => {
                        onError({ title: 'Save Failed', err });
                    });
                } catch (e) {
                    saltEditorStore.setEditorError(e.message);
                    Log.error(e);
                }
            }
        }
    };

    // set the active Salt document on the DialogStore, but don't save anything
    onTest = () => {
        const { editorProps } = this.getProps();
        if (editorProps) {
            const { dialogStore, saltEditorStore } = editorProps;
            const { activeDocumentString } = saltEditorStore;
            try {
                saltEditorStore.clearEditorError();
                const saltDocument = JSON.parse(activeDocumentString);
                // set the live, visible salt document to this one
                dialogStore.setSaltDocument(saltDocument);
                // format the string and refresh the editor
                saltEditorStore.setActiveDocumentString(JSON.stringify(saltDocument, null, 2));
            } catch (e) {
                saltEditorStore.setEditorError(e.message);
                Log.error(e);
            }
        }
    };

    // close the modal but don't change any state (i.e. the active Salt document)
    onClose = () => {
        const { editorProps } = this.getProps();
        const { saltEditorStore } = editorProps;
        saltEditorStore.clearEditorError();
        this.hide();
    };

    // clear all state, falling back to the attached Salt (or default generated)
    onRestore = () => {
        const { editorProps } = this.getProps();
        if (editorProps) {
            const { dialogStore, saltEditorStore } = editorProps;
            try {
                dialogStore.setSaltDocument(undefined);
                saltEditorStore.resetStore();
            } catch (e) {
                Log.error(e);
            }
        }
        // have to close and reopen so that SaltPage has a chance to generate Salt again if needed
        this.hide();
    };
}

SaltEditor.contextType = SaltContext;
