import { CellValue } from './CellValue';
/**
 * Defines how to present a business-value in a UI component
 */
export class AttributeCellValue extends CellValue {
    constructor(propertyName, entryMethod, readOnlyDisplayMethod, hint, tooltip, mask, autoFillCapable, actions, style) {
        super(style);
        this.propertyName = propertyName;
        this.entryMethod = entryMethod;
        this.readOnlyDisplayMethod = readOnlyDisplayMethod;
        this.hint = hint;
        this.tooltip = tooltip;
        this.mask = mask;
        this.autoFillCapable = autoFillCapable;
        this.actions = actions;
    }
    get isComboBoxEntryMethod() {
        return this.entryMethod && this.entryMethod === 'ENTRY_METHOD_COMBO_BOX';
    }
    get isDropDownEntryMethod() {
        return this.entryMethod && this.entryMethod === 'ENTRY_METHOD_DROP_DOWN';
    }
    get isIconEntryMethod() {
        return this.entryMethod && this.entryMethod === 'ENTRY_METHOD_ICON_CHOOSER';
    }
    get isTextFieldEntryMethod() {
        return !this.entryMethod || this.entryMethod === 'ENTRY_METHOD_TEXT_FIELD';
    }
    get isCheckBoxEntryMethod() {
        return this.entryMethod && this.entryMethod === 'ENTRY_METHOD_CHECKBOX';
    }
    get isRadioEntryMethod() {
        return this.entryMethod && this.entryMethod === 'ENTRY_METHOD_RADIO_BUTTONS';
    }
    get isToggleEntryMethod() {
        return this.entryMethod && this.entryMethod === 'ENTRY_METHOD_TOGGLE';
    }
    get isToggleWithLabelsEntryMethod() {
        return this.entryMethod && this.entryMethod === 'ENTRY_METHOD_TOGGLE_WITH_LABELS';
    }
    get isToggleLeftLabelEntryMethod() {
        return this.entryMethod && this.entryMethod === 'ENTRY_METHOD_TOGGLE_ACTIVE_LABEL_LEFT';
    }
    get isToggleRightLabelEntryMethod() {
        return this.entryMethod && this.entryMethod === 'ENTRY_METHOD_TOGGLE_ACTIVE_LABEL_RIGHT';
    }
    get isPlainLabelDisplayMethod() {
        return this.readOnlyDisplayMethod && this.readOnlyDisplayMethod === 'DISPLAY_METHOD_LABEL';
    }
    get isEntryTypeDisplayMethod() {
        return this.readOnlyDisplayMethod && this.readOnlyDisplayMethod === 'DISPLAY_METHOD_USE_ENTRY_METHOD';
    }
}
