// Core component styles
const coreStyles = {
    actions: {
    },

    camera: {
        width: '320px',
        height: '240px',
    },

    cancelButton: {
    },

    cancelButtonText: {
    },

    canvas: {
        display: 'none',
    },

    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },

    divider: {
    },

    header: {
    },

    modal: {
    },

    output: {
        backgroundColor: '#ccc',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '320px',
        height: '240px',
    },

    overlay: {
    },

    preview: {
        width: '320px',
        height: '240px',
    },

    previewText: {
    },

    takePhotoButton: {
    },

    takePhotoButtonText: {
    },

    title: {
    },

    video: {
        width: '320px',
        height: '240px',
    },
};

/**
 * Combines coreStyles and contextStyles
 * @param {Object} contextStyles
 * @param {Number} newWidth
 * @param {Number} newHeight
 */
const getStyles = (contextStyles = {}, newWidth = 320, newHeight = 240) => {
    const width = `${newWidth}px`;
    const height = `${newHeight}px`;
    return {
        actions: {
            ...coreStyles.actions,
            ...contextStyles.actions,
        },

        camera: {
            ...coreStyles.camera,
            ...contextStyles.camera,
            width,
            height,
        },

        cancelButton: {
            ...coreStyles.cancelButton,
            ...contextStyles.cancelButton,
        },

        cancelButtonText: {
            ...coreStyles.cancelButtonText,
            ...contextStyles.cancelButtonText,
        },

        canvas: {
            ...coreStyles.canvas,
            ...contextStyles.canvas,
        },

        content: {
            ...coreStyles.content,
            ...contextStyles.content,
        },

        divider: {
            ...coreStyles.divider,
            ...contextStyles.divider,
        },

        header: {
            ...coreStyles.header,
            ...contextStyles.header,
        },

        modal: {
            ...coreStyles.modal,
            ...contextStyles.modal,
        },

        output: {
            ...coreStyles.output,
            ...contextStyles.output,
            width,
            height,
        },

        overlay: {
            ...coreStyles.overlay,
            ...contextStyles.overlay,
        },

        preview: {
            ...coreStyles.preview,
            ...contextStyles.preview,
            width,
            height,
        },

        previewText: {
            ...coreStyles.previewText,
            ...contextStyles.previewText,
        },

        takePhotoButton: {
            ...coreStyles.takePhotoButton,
            ...contextStyles.takePhotoButton,
        },

        takePhotoButtonText: {
            ...coreStyles.takePhotoButtonText,
            ...contextStyles.takePhotoButtonText,
        },

        title: {
            ...coreStyles.title,
            ...contextStyles.title,
        },

        video: {
            ...coreStyles.video,
            ...contextStyles.video,
            width,
            height,
        },
    };
};

export default getStyles;
