import React from 'react';
import MuiDialogActions from '@material-ui/core/DialogActions';
import * as PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import getStyles from './ModalActions.styles';

/**
 * A styled actions container that displays well in a modal.
 * @see https://material-ui.com/components/dialogs/
 * @see https://material-ui.com/api/dialog-actions/
 */
const ModalActions = (props) => {
    const {
        children,
        contextStyles,
        testID,
    } = props;

    // Override core styles with context styles, separating MUI styles
    const muiStyles = getStyles(contextStyles);

    // Create dynamic class names and injected DOM styles for MUI component
    // * makeStyles returns a function
    const styles = makeStyles(muiStyles)();

    return (
        <MuiDialogActions
            className="c-modal-actions"
            classes={ {
                root: styles.container, // MUI styles
            } }
            data-test-id={ `${testID}__modal-actions` }>
            { children }
        </MuiDialogActions>
    );
};

ModalActions.propTypes = {
    /** Content displayed in a styled modal actions container */
    children: PropTypes.node,

    /** Extended styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the actions */
        container: PropTypes.object,
    }),

    /** Id used for testing */
    testID: PropTypes.string,
};

ModalActions.defaultProps = {
    contextStyles: {},
    testID: 'ModalActions',
};

export default ModalActions;
