// Core component styles
const coreStyles = {
    container: {
        display: 'block',
        // width: '100%'
    },
    body: {
        width: '100%'
    },
    row: {
        width: '100%'
    },

    column: {
    },
};

/**
 * Combine component context styles with core styles
 * @param {Object} contextStyles
 * @return {Object}
 */
const getStyles = (contextStyles = {}) => {
    return {
        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },
        body: {
            ...coreStyles.body,
            ...contextStyles.body,
        },
        row: {
            ...coreStyles.row,
            ...contextStyles.row,
        },

        column: {
            ...coreStyles.column,
            ...contextStyles.column,
        },
    };
};

export default getStyles;
