import { DialogProxyTools } from "./DialogProxyTools";
/**
 *
 */
export class ActionParametersVisitor {
    constructor(value) {
        if (!value) {
            throw new Error('ActionParametersVisitor -- null value exception');
        }
        if (typeof value === 'string') {
            this._enclosedJsonObject = JSON.parse(value);
        }
        else {
            this._enclosedJsonObject = value;
        }
        if (!DialogProxyTools.isActionParametersModel(this._enclosedJsonObject)) {
            throw new Error("Object passed to ActionParametersVisitor is not an ActionParameters");
        }
    }
    // --- State Management Helpers --- //
    static visitTargetsValue(actionParameters) {
        return (new ActionParametersVisitor(actionParameters)).visitTargetsValue();
    }
    // --- State Import/Export --- //
    copyAsJsonObject() {
        return JSON.parse(this.copyAsJsonString());
    }
    copyAsJsonString() {
        return JSON.stringify(this.enclosedJsonObject());
    }
    enclosedJsonObject() {
        return this._enclosedJsonObject;
    }
    // --- State Management --- //
    visitTargetsValue() {
        return this._enclosedJsonObject.targets;
    }
}
