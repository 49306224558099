/*       */

import Document from './Document';

/**
 * TraverseLevel holds data that is specific to the traverse of the GML document.  i.e. not global.
 */
class TraverseLevel {
    model ;
    // coordinates: ....

    constructor(
        model,
    ) {
        this.model = model;
    }
}


/**
 * A Context holds state information for the level of PaperForm that is being traversed.
 */
export default class Context {
    parentContext ;
    form ;
    warnings ;
    document ;
    traverseLevel ;
    constructor(
        parentContext,
        form,
        traverseLevel,
        document,
        warnings,
    ) {
        this.parentContext = parentContext || this;
        this.form = form;
        this.traverseLevel = traverseLevel;
        this.document = document;
        this.warnings = warnings;
    }

    get model() {
        return this.traverseLevel.model;
    }

    static starterContext = (
        form,
        warnings,
    ) => {
        const traverseLevel = new TraverseLevel(form);
        return new Context(
            null,
            form,
            traverseLevel,
            new Document(),
            warnings,
        );
    }

    newContext = (model) => {
        // Update the context with information from this level of json.
        const traverseLevel = new TraverseLevel(
            model,
        );

        return new Context(
            this,
            this.form,
            traverseLevel,
            this.document,
            this.warnings,
        );
    }
}
