import { Property, RedirectionUtil, ViewModeEnum } from 'cv-dialog-sdk';
import { action, computed, observable } from 'mobx';

import DialogStore from './DialogStore';

export default class DetailsDialogStore extends DialogStore {
    /**
     * @type {boolean}
     */
    @observable readInProgress = false;

    /**
     * @type {boolean}
     */
    @observable writeInProgress = false;
    /**
     * @type {Record}
     */
    @observable record = null;

    /**
     *
     * @type {Map<any, any>}
     */
    @observable properties = new Map();

    /**
     * Holds edits made to properties prior to 'write'
     * @type {Map<any, any>}
     */
    @observable propBuffer = new Map();

    /** Holds reference to the sequence of elements */
    tabSequence = [];

    @observable adsToken;

    @computed get propertyNames() {
        return [ ...this.properties.keys() ];
    }

    @action refresh = () => {
        return this.readRecord();
    };

    /**
     * Read the record
     * @returns {Promise}
     */
    @action readRecord = () => {
        this.setReadInProgress(true);
        return this.handleReadProgress(this.dialog.read());
    };

    /**
     * Change the View Mode to READ
     * @returns {Promise}
     */
    @action openReadMode = () => {
        return this.dialog.changeViewMode(ViewModeEnum.READ)
            .then((dialogOrRedirection) => {
                return this.asTransaction(() => {
                    // A dialog result is the top-level form dialog with any changes merged in so we update the top-level dialog
                    if (!RedirectionUtil.isRedirection(dialogOrRedirection)) {
                        this.setDialog(dialogOrRedirection);
                    }
                    // Note the existing dialog may have been modified 'in place' so we need to update
                    this.updateDialogObservables();
                    return dialogOrRedirection;
                });
            });
    };

    /**
     * Change the View Mode to WRITE
     * @returns {Promise}
     */
    @action openWriteMode = () => {
        return this.dialog.changeViewMode(ViewModeEnum.WRITE)
            .then((dialogOrRedirection) => {
                return this.asTransaction(() => {
                    // A dialog result is the top-level form dialog with any changes merged in so we update the top-level dialog
                    if (!RedirectionUtil.isRedirection(dialogOrRedirection)) {
                        this.setDialog(dialogOrRedirection);
                    }
                    // Note the existing dialog may have been modified 'in place' so we need to update
                    this.updateDialogObservables();
                    return dialogOrRedirection;
                });
            });
    };

    /**
     * @param actionId
     * @returns {Promise<Redirection>}
     */
    @action performMenuAction = ({ actionId }) => {
        this.commitBuffer();
        return this.dialog.performMenuActionWithId(actionId)
            .then((result) => {
                // Update any dialog observables (i.e. isDestroyed, etc.)
                this.updateDialogObservables();
                return result;
            });
    };

    @action processSideEffects(propertyName, value) {
        this.setReadInProgress(true);
        this.commitBuffer();
        return this.handleReadProgress(this.dialog.processSideEffects(propertyName, value))
            .then(this.refreshAvailableValuesStore);
    }

    getProperty(propertyName, useBuffer = true) {
        if (this.propBuffer.has(propertyName) && useBuffer) {
            return this.propBuffer.get(propertyName);
        }
        return this.properties.get(propertyName);
    }

    /**
     * Set a property value to be written on the next write()
     * @param propName
     * @param value
     */
    @action setPropertyValue = (propName, value) => {
        const property = this.dialog.record.propAtName(propName);
        const propertyDef = this.dialog.propDefAtName(propName);
        // keep the raw value in a new property in the buffer for use in 'reactions'

        if (property && propertyDef) {
            const { canCauseSideEffects } = propertyDef;
            const newProperty = new Property(propName, value, propertyDef.format, property.annotations);
            this.propBuffer.set(propName, newProperty);
            // @TODO why do we need this when processSideEffects commits the buffer?
            if (canCauseSideEffects) {
                this.dialog.setPropertyValue(propName, newProperty.value);
            }
        }
    };

    /**
     * @param {Object} propsAndValues
     */
    @action setMultiplePropertyValues = (propsAndValues) => {
        Object.keys(propsAndValues).forEach((key) => {
            this.setPropertyValue(key, propsAndValues[key]);
        });
    };

    /**
     * Set a LargeProperty to be written on the next write()
     * @param propName
     * @param encodedData
     * @param mimeType
     */
    @action setLargePropertyWithEncodedData = (propName, encodedData, mimeType) => {
        const largeProperty = this.dialog.newLargePropertyWithEncodedData(encodedData, mimeType);
        this.setPropertyValue(propName, largeProperty);
    };

    get storesWithUnsavedChanges() {
        let stores = this.hasUnsavedChanges ? [ this ] : [];
        stores = stores.concat(super.storesWithUnsavedChanges);
        return stores;
    }

    get hasUnsavedChanges() {
        return this.propBuffer.size > 0;
    }

    /**
     * @deprecated - use the new available values approach (AvailableValuesStore)
     * Refresh the current set of values for a set of propertyNames
     * @param {Array.<String>} propertyNames
     * @returns {*|PromiseLike<T | never>|Promise<T | never>}
     */
    @action batchUpdateAvailableValues = (propertyNames) => {
        this.commitBuffer();
        if (propertyNames.length) {
            const transactions = propertyNames.map((propertyName) => {
                return new Promise((resolve) => { resolve(this.dialog.getAvailableValues(propertyName)); });
            });
            return Promise.all(transactions)
                .then((filterOperationRefs) => {
                    const valuesMap = {};
                    propertyNames.forEach((propertyName, idx) => {
                        valuesMap[propertyName] = filterOperationRefs[idx];
                    });
                    this.availableValuesMap.merge(valuesMap);
                });
        }
        return Promise.resolve();
    };

    /**
     * Write the current changes to the record back to the server
     * May return a redirection, otherwise null
     * @returns {Promise<Redirection|null>}
     */
    @action writeRecord = () => {
        this.setWriteInProgress(true);
        this.commitBuffer();
        return this.handleWriteProgress(this.dialog.write());
    };

    // ******************************************************************************************
    // private methods
    // ******************************************************************************************

    handleReadProgress = (readPr) => {
        return readPr.then(() => {
            return this.asTransaction(() => {
                this.setRecord(this.dialog.buffer);
                this.setSessionValues(this.dialog.sessionValues);
                // Note the existing dialog may have been modified 'in place' so we need to update
                this.updateDialogObservables();
                this.setDataChange();
                this.setReadInProgress(false);
            });
        }).catch((err) => {
            this.setReadInProgress(false);
            throw err;
        });
    };

    handleWriteProgress = (writePr) => {
        return writePr.then((dialogOrRedirection) => {
            return this.asTransaction(() => {
                if (!RedirectionUtil.isRedirection(dialogOrRedirection)) {
                    this.setDialog(dialogOrRedirection);
                }
                // Note the existing dialog may have been modified 'in place' so we need to update
                this.updateDialogObservables();
                this.setDataChange();
                this.setReadInProgress(false);
                return dialogOrRedirection;
            });
        }).catch((err) => {
            this.setReadInProgress(false);
            this.refresh();
            throw err;
        });
    };

    @action setRecord(record) {
        this.record = record;
        this.record.properties.forEach((property) => {
            this.properties.set(property.name, property);
        });
        // reset the buffer
        this.propBuffer.clear();
    }

    commitBuffer() {
        this.propBuffer.forEach((property, propName) => {
            const trimedValue = typeof property.value === 'string' ? property.value.trimRight() : property.value;
            this.dialog.setPropertyValue(propName, trimedValue);
        });
    }

    @action setReadInProgress(inProgress) {
        this.readInProgress = inProgress;
    }

    @action setWriteInProgress(inProgress) {
        this.writeInProgress = inProgress;
    }

    @action setAdsToken(adsToken) {
        if (adsToken) {
            this.adsToken = adsToken;
        }
    }
}
