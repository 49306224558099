import React from 'react';
import PropTypes from 'prop-types';
import componentFactory from './componentFactory';
import SaltContext from './SaltContext';
import engineConstants from './engineConstants';
import SaltComponent from './SaltComponent';

export default class PowerBI extends SaltComponent {
    static propTypes = {
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        viewId: PropTypes.string,
    };

    static typeName = engineConstants.component.name.powerbi;

    render() {
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        const { dialog } = dialogStore;
        const { view } = dialog;
        let resolvedProps = this.resolveProperties();
        resolvedProps = {
            accessToken: view.accessToken,
            embedUrl: view.embedUrl,
            id: view.embedId,
            ...resolvedProps,
        };
        return React.createElement(componentFactory.getPlatformComponent('powerbi'), resolvedProps);
    }
}

PowerBI.contextType = SaltContext;
