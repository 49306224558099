/**
 * A MutedLayoutAttribute does not export.  It only hods a value to be examined by the GML rendering engine.
 */
export default class MutedLayoutAttribute {
    name ;
    value ;
    exportClass ;
    isCascading ;
    constructor(
        name,
        value,
        exportClass,
        isCascading = false,
    ) {
        this.name = name;
        this.value = value;
        this.exportClass = exportClass;
        this.isCascading = isCascading;
    }

    // eslint-disable-next-line no-unused-vars
    exportTo(context, styleSet) {
        // Do nothing
    }
}
