import { FLEX_DIRECTION } from '../../gmlConstants';
import { GENERAL, GENERAL_FLEX } from '../../exportClassConstants';
import SimpleStringAttribute from '../SimpleStringAttribute';

/**
 * There is no special behavior in a FlexDirection.  This class exists only to formalize the type
 * as it is used as a parameter through out the system.
 */

const VALUES = {
    ROW: 'row',
    COLUMN: 'column',
};
export default class FlexDirection {
    name ;
    value ;
    exportClass ;
    isCascading ;
    constructor(value) {
        this.name = FLEX_DIRECTION;
        this.value = value;
        this.exportClass = [ GENERAL, GENERAL_FLEX ];
        this.isCascading = true;
    }
    static VALUES = VALUES;
    static column = new FlexDirection(VALUES.COLUMN);
    static row = new FlexDirection(VALUES.ROW);

    isColumn = () => {
        return !this.isRow();
    }

    isRow = () => {
        return this.value === VALUES.ROW;
    }

    opposite = () => {
        return this.isRow() ? FlexDirection.column : FlexDirection.row;
    }

    exportTo(context, styleSet) {
        if (SimpleStringAttribute.hasConstantRef(this.value)) {
            SimpleStringAttribute.reportExportIssue('orientation', this.value);
        }
        if (this.isRow()) {
            styleSet.flexPolicy.setFlexRow();
        } else {
            styleSet.flexPolicy.setFlexColumn();
        }
    }
}
