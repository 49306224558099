/*       */
import Label from './Label';

export default class UnknownComponent extends Label {
    get backgroundColorOverride() { return 'magenta'; }
    get text() { return 'Unknown'; }
    getFontSize() {
        return 12;
    }
}
