
import { Property, RedirectionUtil } from 'cv-dialog-sdk';
import { action, observable, computed } from 'mobx';
import DialogStore from './DialogStore';
import SelectedRecordsStore from './SelectedRecordsStore';

export default class ListDialogStore extends DialogStore {
    @observable searchDialogFormStore = null;
    @observable queryInProgress = false;
    @observable refreshInProgress = false;
    @observable records = [];
    @observable loadedZeroRecords = false;
    @observable calculateStatisticsFormStore = null;
    @observable isListInitialized = false;
    @observable propBuffer = new Map();
    @observable errorRecordIds = [];
    propertiesChanged = false;
    lastFocusedProperty = null;

    /**
     * Intialize the list
     * @param pageSize
     */
    @action setPageSize(pageSize) {
        this.dialog.initScroller(pageSize);
    }

    @computed get searchDialogStore() {
        return this.searchDialogFormStore ? this.searchDialogFormStore.firstChildStore : null;
    }

    @action setIsListInitialized(value = true) {
        this.isListInitialized = value;
    }

    get overrideInitialPageSize() {
        return this.overrideInitialPageSizeValue;
    }

    set overrideInitialPageSize(value) {
        this.overrideInitialPageSizeValue = value;
    }

    get isMaintainableQuery() {
        return this.getRootDialogStore().dialog.view.isMaintainableQuery;
    }

    /**
     * Returns whether or not there are more records
     * in the forward direction
     * @returns {boolean}
     */
    hasMoreRecords() {
        return this.dialog.scroller.hasMoreForward;
    }

    /**
     * Returns whether or not any action supports multiple selections
     * @returns {boolean}
     */
    hasMultiSelectActions() {
        const isMenuVisible = menu => menu.visible !== false && !menu.isSeparator;
        const { menu } = this.dialog.view;
        const contextMenu = menu.findContextMenu();
        if (!contextMenu) return [];
        return !!(contextMenu.children.filter(m =>
            // Exclude the export action from this test, otherwise all lists will support multi-select.
            // eslint-disable-next-line implicit-arrow-linebreak
            m.multiSelectAvailable && isMenuVisible(m) && m.id !== 'export')
        ).length;
    }

    /**
     * Request more records be added to the buffer (based on pageSize)
     * @returns {Promise}
     */
    @action moreRecords = () => {
        this.setQueryInProgress(true);
        this.overrideInitialPageSize = undefined;
        return this.handleQueryProgress(this.dialog.scroller.pageForward())
            .then((result) => {
                return result;
            }).catch((err) => {
                throw err;
            });
    };

    /**
     * @param actionId
     * @param targets
     * @returns {Promise<Redirection>}
     */
    @action performMenuAction = ({ actionId, targets = [] }) => {
        return this.dialog.performMenuActionWithId(actionId, targets)
            .then((result) => {
                // Update any dialog observables (i.e. isDestroyed, etc.)
                this.updateDialogObservables();
                return result;
            });
    };

    @action refresh = () => {
        // Refresh all the lists when it is a maintainable query.
        if (this.isMaintainableQuery) {
            return this.setAllIsRefreshNeeded(true);
        }
        return this.setIsRefreshNeeded(true);
    };

    /**
     * Reset the buffer and refresh the list using the original pageSize value
     * @returns {Promise}
     */
    @action resetList = (recordId = null) => {
        this.asTransaction(() => {
            this.setQueryInProgress(true);
            this.setRefreshInProgress(true);
        });
        return this.handleQueryProgress(this.dialog.scroller.refresh(this.overrideInitialPageSize, recordId))
            .then((result) => {
                this.asTransaction(() => {
                    if (!this.isRefreshNeeded) this.setIsListInitialized(true);
                    this.setRefreshInProgress(false);
                });
                return result;
            }).catch((err) => {
                this.setRefreshInProgress(false);
                this.setIsRefreshNeeded(false);
                throw err;
            });
    };

    /**
     * Refresh the list, maintaining the current number of records
     * i.e. getting multiple pages at once
     * @returns {Promise}
     */
    @action refreshList = (recordId = null) => {
        // Don't trigger another refresh if refresh is already in progress.
        if (!this.refreshInProgress) {
            this.asTransaction(() => {
                this.setQueryInProgress(true);
                this.setRefreshInProgress(true);
            });
            // Re-query buffer.length + 1 if there are no more records so moreRecords is set correctly on return.
            let numRows = this.overrideInitialPageSize;
            if (!numRows || numRows === 0) {
                numRows = (this.dialog.scroller.buffer.length < this.dialog.scroller.pageSize
                    ? this.dialog.scroller.pageSize : this.dialog.scroller.buffer.length) + (this.hasMoreRecords() ? 0 : 1);
            }
            return this.handleQueryProgress(this.dialog.scroller.refresh(numRows, recordId))
                .then((result) => {
                    this.asTransaction(() => {
                        this.setRefreshTriggerByTimer(false);
                        this.setRefreshInProgress(false);
                        if (!this.isListInitialized) this.setIsListInitialized(true);
                    });
                    return result;
                }).catch((err) => {
                    this.setRefreshInProgress(false);
                    throw err;
                });
        }
        return Promise.resolve();
    };

    @action removeSearchDialogFormStore() {
        this.searchDialogFormStore = null;
    }

    /**
     * Handles opening a view based on viewDescriptor
     * @param {object} viewDescriptor
     */
    openView(viewDescriptor) {
        return super.openView(viewDescriptor)
            .then((dialogOrRedirection) => {
                this.removeSearchDialogFormStore();
                return dialogOrRedirection;
            });
    }

    /**
     * Get the search form associated with this List
     * Returns the top-level FormDialogStore with a single child SearchDialogStore
     * @returns {Promise<FormDialogStore>}
     */
    @action getOrOpenSearchDialogFormStore = () => {
        this.setIsLoadingSearchStore(true);
        const searchDialogId = this.sessionStore.getSearchDialogId(this.dialog.id, this.dialog.selectedViewId);
        if (searchDialogId) {
            return this.openSearchDialogFormStore(searchDialogId)
                .then((searchDialogFormStore) => {
                    this.setSearchDialogFormStore(searchDialogFormStore);
                    this.setIsLoadingSearchStore(false);
                    return searchDialogFormStore;
                });
        }

        return this.performSearchAction()
            .then(redirection => {
                this.sessionStore.setSearchDialogId(this.dialog.id, this.dialog.selectedViewId, redirection.dialogId);
                return this.openSearchDialogFormStore(redirection.dialogId);
            })
            .then((searchDialogFormStore) => {
                this.setSearchDialogFormStore(searchDialogFormStore);
                this.setIsLoadingSearchStore(false);
                return searchDialogFormStore;
            });
    };

    @action setSearchDialogFormStore(searchDialogFormStore) {
        this.searchDialogFormStore = searchDialogFormStore;
    }

    @action setSearchFormOpen(value) {
        if (this.searchDialogStore) this.searchDialogStore.isSearchFormOpen = value;
    }

    @action setSortModalOpen(value) {
        if (this.searchDialogStore) this.searchDialogStore.isSortModalOpen = value;
    }

    /**
     * @returns {Promise<Redirection>}
     */
    @action performSearchAction = () => {
        return this.dialog.openSearch().then((redirection) => {
            this.updateDialogObservables();
            return redirection;
        });
    };

    /**
     * Returns the top-level FormDialogStore with a single child SearchDialogStore
     * @param dialogId
     * @returns {Promise<FormDialogStore>}
     */
    @action openSearchDialogFormStore = (dialogId) => {
        return this.sessionStore.openOrRefreshDialogStore(dialogId);
    };


    /**
     * Returns the calculateStatisticsFormStore
     * @returns {Promise<FormDialogStore>}
     */
    @action getOrOpenCalculateStatisticsFormStore = () => {
        return this.dialog.openCalculateStatistics()
            .then((calculateStatisticsFormStore) => {
                this.setCalculateStatisticsFormStore(calculateStatisticsFormStore);
            });
    };

    @action setCalculateStatisticsFormStore(calculateStatisticsFormStore) {
        this.calculateStatisticsFormStore = calculateStatisticsFormStore;
    }

    /**
     * Close and destoy the search dialog
     * @return {*}
     */
    @action destroySearchDialogFormStore = () => {
        if (this.searchDialogFormStore) {
            const dialogId = this.searchDialogFormStore.dialog.id;
            this.searchDialogFormStore = null;
            return this.sessionStore.destroyDialogStore(dialogId);
        }
        return Promise.resolve();
    };

    handleQueryProgress = (queryPr) => {
        return queryPr.then((results) => {
            return this.asTransaction(() => {
                this.setRecords(this.dialog.scroller.buffer);
                this.setSessionValues(this.dialog.scroller.sessionValues);
                this.updateDialogObservables();
                this.setDataChange();
                this.setQueryInProgress(false);
                this.selectedRecordsStore.refreshSelectedRecord(this.records);
                return results;
            });
        }).catch((err) => {
            this.setQueryInProgress(false);
            throw err;
        });
    };

    @action setErrorRecords(errorRecords) {
        this.errorRecordIds = errorRecords;
    }

    @action clearErrorRecords() {
        if (this.errorRecordIds.length) {
            this.errorRecordIds = [];
        }
        this.propBuffer.clear();
    }

    setLastFocusedProperty = (propertyRef) => {
        // Property loses focus when busy loading indicator appears on top of maintainable query.
        // To refocus on to the property, we maintain the property's ref here.
        this.lastFocusedProperty = propertyRef;
    };

    // ***********************************************************************************************
    // private methods
    // ***********************************************************************************************

    @action setRecords(records) {
        this.records.replace(records);
        this.loadedZeroRecords = this.dialog.scroller.isCompleteAndEmpty;
    }

    @action setQueryInProgress(inProgress) {
        this.queryInProgress = inProgress;
    }

    @action setRefreshInProgress(inProgress) {
        this.refreshInProgress = inProgress;
    }

    setIsLoadingSearchStore(inProgress) {
        this.isLoadingSearchStore = inProgress;
    }

    getIsLoadingSearchStore() {
        return this.isLoadingSearchStore || false;
    }

    // ***********************************************************************************************
    // Write logic
    // ***********************************************************************************************
    /**
     * Set a property value to be written on the next write()
     * @param propName
     * @param value
     * @param record
     */
    @action setPropertyValue = (propName, value, record) => {
        this.propertiesChanged = true;
        const property = record && record.propAtName(propName);
        const propertyDef = this.dialog.propDefAtName(propName);

        if (property && propertyDef) {
            const newProperty = new Property(propName, value, propertyDef.format, property.annotations);
            this.propBuffer.set(`${record.id}:${propName}`, newProperty);
        }
    };

    getProperty(record = {}, propName) {
        const bufferId = `${record.id}:${propName}`;
        if (this.propBuffer.has(bufferId)) {
            return this.propBuffer.get(bufferId);
        }
        const latestRecord = this.records.find((rec) => rec.id === record.id);
        return latestRecord && latestRecord.propAtName(propName);
    }

    commitBuffer() {
        this.propBuffer.forEach((property, bufferId) => {
            const [ recordId, propName ] = bufferId.split(':');
            this.dialog.setPropertyValue(recordId, propName, property.value);
        });
    }

    /**
     * Write the current changes to the record back to the server
     * May return a redirection, otherwise null
     * @returns {Promise<Redirection|null>}
     */
    @action writeRecord = () => {
        if (this.propertiesChanged) {
            this.propertiesChanged = false;
            this.commitBuffer();
            return this.handleWriteProgress(this.dialog.write());
        }
        return Promise.resolve();
    };

    handleWriteProgress = (writePr) => {
        return writePr
            .then((dialogOrRedirection) => {
                // Clear any error records and local buffer.
                this.clearErrorRecords();

                if (!dialogOrRedirection) return Promise.resolve();
                return this.asTransaction(() => {
                    if (!RedirectionUtil.isRedirection(dialogOrRedirection)) {
                        this.setDialog(dialogOrRedirection);
                    }
                    // this will refresh all the records based on the isRefreshNeeded flag.
                    this.updateDialogObservables();
                    this.setDataChange();
                    return dialogOrRedirection;
                });
            }).catch((err) => {
                const { children } = err;
                const bufferKeys = Array.from(this.propBuffer.keys());
                let { errorRecordIds } = this;

                if (children && children.length) {
                    errorRecordIds = children.map((child) => child.objectId);
                    this.setErrorRecords(errorRecordIds);
                }
                bufferKeys.forEach((key) => {
                    const [ recordId ] = key.split(':');
                    // keep error values in buffer as it is and remove success values from buffer.
                    if (!errorRecordIds.includes(recordId)) {
                        this.propBuffer.delete(key);
                    }
                });
                // Always refresh in case of any errors.
                this.refresh();
                throw err;
            });
    };

    /**
     * Returns all the records current selection state for all records.
     * @returns Map
     */
    get recordsSelectionState() {
        return this.selectedRecordsStore.getRecordsSelectionState();
    }

    /**
     * Returns an array of only records who state value is true/selected
     * @returns Array
     */
    get selectedRecords() {
        return this.selectedRecordsStore.getSelectedRecords();
    }

    /**
     * Get a count of how many records are selected
     */
    get selectedRecordCount() {
        return this.selectedRecordsStore.selectedRecordCount;
    }

    /**
     * Get the last recorded updated
     */
    get lastRecordSelected() {
        return this.selectedRecordsStore.lastSelectedRecord;
    }

    /**
     * Deselect all records
     */
    deselectAllRecord() {
        this.selectedRecordsStore.clearSelectedRecords();
    }

    /**
     * Set the selection state of a record to update the observable and notify listners
     * @param {string} recordId
     * @param {boolean} isSelected
     */
    setRecordSelectionState(recordId, isSelected, setLastRecordSelection = true) {
        this.selectedRecordsStore.setRecordSelectionState(recordId, isSelected, setLastRecordSelection);
    }

    /**
     * Record Id
     * @param {string} id
     * @returns {boolean} set value
     */
    setLastSelectedRecordId(id) {
        this.selectedRecordsStore.setLastSelectedRecordId(id);
    }

    /**
     * Pass in the record id to check the state of a records selection status
     * @param {string} id
     * @returns {boolean} selection status
     */
    isRecordSelected(id) {
        return this.selectedRecordsStore.isRecordSelected(id);
    }

    selectedRecordsStore = new SelectedRecordsStore();
}
