import { rootStore } from 'cv-react-core';

const getStyles = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    return {
        container: {
            maxHeight: '375px',
            width: '310px',
        },
        text: {
            ...theme.fonts.dataTableHeading,
            textAlign: 'center',
            color: 'rgba(0, 0, 0, 0.6)',
            cursor: 'pointer',
            padding: '2px',
            textTransform: 'uppercase',
        },
    };
};

export default getStyles;
