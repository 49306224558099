import React from 'react';
import * as PropTypes from 'prop-types';
import { default as Icon } from '../Icon/Icon';
import { default as Image } from '../Image/Image';
import { default as TextLabel } from '../TextLabel/TextLabel';

import getStyles from './IconText.styles';

const ICON_TYPES = {
    ICON: 'icon',
    IMAGE: 'image',
};

const getIcon = (iconType, iconSrc, styles, testID) => {
    let icon;
    if (iconType === ICON_TYPES.ICON) {
        icon = (
            <Icon
                contextStyles={ {
                    container: styles.iconContainerStyles,
                    image: styles.iconStyles,
                } }
                iconName={ iconSrc }
                testID={ `${testID}__icon` } />
        );
    }
    if (iconType === ICON_TYPES.IMAGE) {
        icon = (
            <Image
                contextStyles={ {
                    container: styles.imageContainerStyles,
                    image: styles.imageStyles,
                } }
                showLoadingIndicator={ false }
                imageSrc={ iconSrc }
                testID={ `${testID}__logo-image` } />
        );
    }
    return icon;
};

// TODO: refactor this abstraction away in favor of inline logic
const IconText = (props) => {
    const {
        contextStyles,
        iconType,
        iconSrc,
        label,
        onClick,
        testID,
    } = props;

    // Override core styles with context styles, separating MUI styles
    const {
        ...muiStyles
    } = getStyles(contextStyles);

    // Create dynamic class names and injected DOM styles for MUI component
    // * makeStyles returns a function
    // const styles = makeStyles(muiStyles)();

    const {
        container: containerStyles,
        iconContainer: iconContainerStyles,
        icon: iconStyles,
        imageContainer: imageContainerStyles,
        image: imageStyles,
        text: textStyles,
    } = muiStyles;

    const icon = getIcon(iconType, iconSrc, {
        iconContainerStyles,
        iconStyles,
        imageContainerStyles,
        imageStyles,
    }, testID);

    return (
        <div
            style={ containerStyles }
            { ...(onClick && { onClick: () => onClick() }) }>
            { icon }
            <TextLabel
                contextStyles={ {
                    container: textStyles,
                    text: textStyles,
                } }
                testID={ `${testID}__text-label` }>
                { label }
            </TextLabel>
        </div>
    );
};

IconText.propTypes = {

    /** Styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the container surrounding menu items */
        container: PropTypes.object,
        icon: PropTypes.object,
        image: PropTypes.object,
        text: PropTypes.object,
    }),

    onClick: PropTypes.func,

    /** Tenant logo */
    iconSrc: PropTypes.string.isRequired,

    /** Type of icon. Accepts 'icon' and 'image' */
    iconType: PropTypes.string,

    /** Logged in userId */
    label: PropTypes.string.isRequired,

    /** Id used for testing */
    testID: PropTypes.string,
};

IconText.defaultProps = {
    contextStyles: {},
    iconType: ICON_TYPES.ICON,
    testID: 'AppDrawer',
};

export {
    ICON_TYPES,
};
export default IconText;
