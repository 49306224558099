/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { XaltBox, XaltHtmlTextField, XaltToolTip } from 'xalt-react-atoms';

/**
 * A platform component for building an html view
 */
class RWHtmlTextField extends Component {
    static propTypes = {
        style: PropTypes.object,
        value: PropTypes.string,
        tipText: PropTypes.string,
        isReadMode: PropTypes.bool,
        isFocused: PropTypes.bool,
        onValueChanged: PropTypes.func,
        xStyle: PropTypes.object,
        maxLength: PropTypes.number,
        propertyRef: PropTypes.object,
    };

    static defaultProps = {
        style: {},
    };

    render() {
        const {
            style,
            value,
            tipText,
            isReadMode,
            isFocused,
            onValueChanged,
            xStyle,
            maxLength,
            propertyRef } = this.props;

        const { input, nonSaltPropertyBGColor = {} } = xStyle || { input: {} };
        const nonSaltPropBGColor = isReadMode ? {} : nonSaltPropertyBGColor;
        const {
            display,
            flexGrow,
            flexShrink,
            width,
            height } = style;
        const tipStyle = {
            display: display || 'flex',
            flexGrow,
            flexShrink,
            width,
            height,
        };
        return (
            <XaltToolTip
                style={ { ...tipStyle } }
                tipText={ tipText }>
                <XaltBox style={ style }>
                    <XaltHtmlTextField
                        style={ { display: 'flex', textOverflow: 'ellipsis', ...nonSaltPropBGColor, ...style, ...input } }
                        disabled={ isReadMode }
                        maxLength={ maxLength }
                        multiline
                        fullWidth
                        onValueChanged={ onValueChanged }
                        readOnly={ isReadMode }
                        tipText={ tipText }
                        value={ value }
                        inputRef={ propertyRef }
                        autoFocus={ isFocused } />
                </XaltBox>
            </XaltToolTip>
        );
    }
}

export default RWHtmlTextField;
