/**
 * Core component style object that contains style objects
 * for all the styles for inner elements.
 */
 const coreStyles = {
    advancedSettingsTitle: {
        marginBottom: '30px',
    },

    button: {
        display: 'flex',
        width: 'unset',
        marginBottom: '16px',
    },

    buttonText: {
        textTransform: 'none',
    },

    clearCacheButton: {
        alignSelf: 'flex-end',
        marginBottom: '30px',
    },

    clearCacheText: {
        lineHeight: '16px',
        marginBottom: '20px',
    },

    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '342px',
    },

    error: {
        background: '#ffffff',
        borderRadius: '6px',
        padding: '15px',
        boxSizing: 'border-box',
        marginBottom: '20px',
    },

    errorText: {
    },

    footer: {
        marginTop: '20px',
    },

    heading: {
        lineHeight: '20px',
        marginBottom: '40px',
    },

    input: {
        lineHeight: '26px',
        padding: '0px 8px 2px',
    },

    primaryButton: {
        width: '100%',
    },

    primaryButtonHover: {
    },

    primaryButtonText: {
    },

    secondaryButton: {
    },

    secondaryButtonHover: {
    },

    secondaryButtonText: {
    },

    textField: {
        flexShrink: 1,
        flexGrow: 0,
        height: '50px',
        marginBottom: '30px',
    },

    textLabel: {
        lineHeight: '20px',
        paddingLeft: '8px',
    },

    useGridContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '30px',
    },

    useServerContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px',
    },
};

const getTextFieldStyles = (contextStyles, error) => {
    const { textField } = contextStyles;
    const {
        errorTextColor,
        activeBorderColor,
        borderColor,
        ...restTextFieldStyles
    } = textField;

    // This is a temporary solution here to ahieve hover and focused styles for textfields.
    return {
        '& label': {
            color: error ? errorTextColor : '#000000',
        },
        '& label.Mui-focused': {
            color: error ? errorTextColor : activeBorderColor,
        },
        '&:hover label': {
            color: error ? errorTextColor : activeBorderColor,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: `${error ? errorTextColor : borderColor}`,
            },
            '&:hover fieldset': {
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: `${error ? errorTextColor : activeBorderColor}`,
            },
            '&.Mui-focused fieldset': {
                borderWidth: 2,
                borderStyle: 'solid',
                borderColor: `${error ? errorTextColor : activeBorderColor}`,
            },
        },
        ...restTextFieldStyles,
    };
};

/**
 * Combine all styles, including context styles,
 * into a single style object for
 * @param {Object} contextStyles
 * @param {Boolean} error
 */
const getStyles = (contextStyles = {}, error) => {
    return {
        advancedSettingsTitle: {
            ...coreStyles.heading,
            ...coreStyles.advancedSettingsTitle,
            ...contextStyles.heading,
        },

        button: {
            ...coreStyles.button,
            ...contextStyles.button,
        },

        buttonText: {
            ...coreStyles.buttonText,
            ...contextStyles.buttonText,
        },

        clearCacheButton: {
            ...coreStyles.clearCacheButton,
            ...contextStyles.clearCacheButton,
        },

        clearCacheText: {
            ...coreStyles.clearCacheText,
            ...contextStyles.clearCacheText,
        },

        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        error: {
            ...coreStyles.error,
            ...contextStyles.error,
        },

        errorText: {
            ...coreStyles.errorText,
            ...contextStyles.errorText,
        },

        footer: {
            ...coreStyles.footer,
            ...contextStyles.footer,
        },

        heading: {
            ...coreStyles.heading,
            ...contextStyles.heading,
        },

        input: {
            ...coreStyles.input,
            ...contextStyles.input,
        },

        primaryButton: {
            ...coreStyles.primaryButton,
            ...contextStyles.primaryButton,
        },

        primaryButtonHover: {
            ...coreStyles.primaryButtonHover,
            ...contextStyles.primaryButtonHover,
        },

        primaryButtonText: {
            ...coreStyles.primaryButtonText,
            ...contextStyles.primaryButtonText,
        },

        secondaryButton: {
            ...coreStyles.secondaryButton,
            ...contextStyles.secondaryButton,
        },

        secondaryButtonHover: {
            ...coreStyles.secondaryButtonHover,
            ...contextStyles.secondaryButtonHover,
        },

        secondaryButtonText: {
            ...coreStyles.secondaryButtonText,
            ...contextStyles.secondaryButtonText,
        },

        tenantTextField: {
            ...coreStyles.textField,
            ...getTextFieldStyles(contextStyles, error),
        },

        textField: {
            ...coreStyles.textField,
            ...getTextFieldStyles(contextStyles),
        },

        textLabel: {
            ...coreStyles.textLabel,
            ...contextStyles.textLabel,
        },

        useGridContainer: {
            ...coreStyles.useGridContainer,
            ...contextStyles.useGridContainer,
        },

        useServerContainer: {
            ...coreStyles.useServerContainer,
            ...contextStyles.useServerContainer,
        },
    };
};

export default getStyles;
