class DialogComponentFactory {
    getDialogStoreInstance(dialog, sessionStore, parentDialogStore) {
        let instance = null;
        if (!this.providers) {
            throw new Error('No provider list set for component factory');
        }
        this.providers.find((provider) => {
            instance = provider.provideDialogStoreInstance(dialog, sessionStore, parentDialogStore);
            return !!instance;
        });
        return instance;
    }

    getDialogPageComponent(dialog) {
        let component = null;
        if (!this.providers) {
            throw new Error('No provider list set for component factory');
        }
        this.providers.find((provider) => {
            component = provider.provideDialogPageComponent(dialog);
            return !!component;
        });
        return component;
    }

    addProvider(provider) {
        if (!this.providers) {
            throw new Error('No provider list set for component factory');
        }
        this.providers.push(provider);
    }

    setProviderList(providers) {
        this.providers = providers;
    }
}

const dialogComponentFactory = new DialogComponentFactory();
export default dialogComponentFactory;
