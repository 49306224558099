import { rootStore, constants } from 'cv-react-core';
import { toJS } from 'mobx';
import { TypeNames } from 'cv-dialog-sdk';
import routeNames from './routeNames';

const { WORKBENCH, DIALOG } = routeNames;
const { ui: { BREAD_CRUMBS, ACTIVE_LAUNCHER_ID, WORKBENCH_UI_OBJECT_ID } } = constants;
const UiStorageKey = 'crumbs';
/**
 * Internal history object we do not want to share.
 */
const history = [];

const saveActiveLauncher = (dialogModel) => {
    const {
        referringObjectActionId,
        referringObjectType,
    } = dialogModel;
    // Set Active launcher id only when dialog is generated from workbench
    if (referringObjectType === TypeNames.ReferringWorkbenchTypeName) {
        const { uiStore } = rootStore;
        uiStore.setValueForUIObject(WORKBENCH_UI_OBJECT_ID, ACTIVE_LAUNCHER_ID, referringObjectActionId, false);
    }
};

/**
 * Internal method used to save the hstory. We do not want to share this externally.
 * @param {string} locationKey location
 * @param {array} dialogRouteModels dialog routes
 * @param {object} workbenchRouteModel workbech model
 */
const saveHistory = (locationKey, dialogRouteModels, workbenchRouteModel) => {
    history.push({
        locationKey,
        localDialogRouteModels: [ ...dialogRouteModels ],
        localWorkbenchRouteModel: Object.assign({}, workbenchRouteModel),
    });
    const { uiStore } = rootStore;
    uiStore.setValueForUIObject(BREAD_CRUMBS, UiStorageKey, history, true);
};

/**
 * The intent for this class it to manage our recursive routing pattern to move from dialog to dialog.
 * This class will keep a history of the dialog locations and try to restore to any previous locations and/or
 * dialog routes that it finds in history. We keep track of Workbench id to determine if we need to reset, dialogId to
 * manage where we have gone and a react-router-dom location key incase the browser navigates around using back/forward
 * arrows or keyboard shortcuts.
 */
class RecursiveRouteManager {
    constructor() {
        this.locationKey = '';
        this.localDialogRouteModels = [];
        this.localWorkbenchRouteModel = [];
        this.isHistoryRestored = false;
    }

    addRoute(match, location) {
        const { params: { workbenchId, dialogId, tenantId }, url } = match;
        if (!workbenchId) return;

        const { sessionStore: { workbenches } } = rootStore;
        if (!this.localWorkbenchRouteModel.id || this.localWorkbenchRouteModel.id !== workbenchId) {
            this.reset();
            // eslint-disable-next-line no-shadow
            const workbench = workbenches.find(( workbench ) => workbench.id === workbenchId);
            const newWorkbenchRouteModel = {
                type: WORKBENCH,
                id: workbench.id,
                description: workbench.name,
                url: `/${tenantId}/${WORKBENCH}/${workbench.id}`,
            };
            this.workbenchRouteModel = newWorkbenchRouteModel;
        }

        const { key } = location;
        this.locationKey = key;
        this.url = url;

        // If we are missing a dialog id this means we have moved away from dialog routing.
        if (!dialogId) this.reset();
    }

    /**
     * This method is used to add new routes to the stack for building breadcrumbs or understanding of
     * how we got to the location using recursive dialog routes.
     * @param {string} key unique key provided by the router
     * @param {string} url url path constructed by the router
     * @param {string} dialogId dialog id associated with route
     */
    // addNewDialogRoute(key, url, dialogId) {
    addNewDialogRoute(dialogStore) {
        // react-router-dom key used for tracking locations.
        // this.locationKey = key;

        // If we find history of the location key, this means we are navigating back or forward
        // and do not need to reconstruct the content for the breadcrumb.
        if (this.restoredFromHistory(this.locationKey)) {
            return;
        }

        const { dialog } = dialogStore;
        const {
            id: dialogId,
            description,
            referringObject = {},
        } = dialog;

        // If we are missing a dialog id this means we have moved away from dialog routing.
        if (!dialogId) this.reset();

        if (dialogId) {
            // Add new route
            if (dialogStore) {
                const newDialogRouteModel = {};
                newDialogRouteModel.type = DIALOG;
                newDialogRouteModel.id = dialogId;
                newDialogRouteModel.url = this.url;
                newDialogRouteModel.description = description;
                // In order to get active launcher id, we need to set referring object type and actionId in breadcrumbs data.
                newDialogRouteModel.referringObjectType = referringObject.type;
                newDialogRouteModel.referringObjectActionId = referringObject.actionId;
                // When we open a launcher in a new tab, we will not have any location key.
                // So, breadcrumbs data will not be saved to uiStore which in turn makes it unable to get active launcher id.
                // To overcome this, we will set active launcher id to uiStore when we don't have any location key.
                // Note, this applies only when we open in a new tab, in normal cases we don't need this.
                if (!this.locationKey) saveActiveLauncher(newDialogRouteModel);
                this.push(newDialogRouteModel);
            }
        }
    }

    /**
     * Push a new dialog route model onto the stack.
     * @param {object} dialogRoute dialog route object
     */
    push(dialogRoute) {
        // Look and see if we already have the dialog model on the stack. If so
        // we can use it and reduce the stack. This would occure when going back by
        // clicking on the breadcrumb link.
        const foundDialog = this.find(dialogRoute);
        if (foundDialog) {
            this.reducer(dialogRoute);
        }
        else {
            this.localDialogRouteModels.push(dialogRoute);
        }
        this.save();
    }

    /**
     * Pop the last item off the dialog route stack.
     * @param {string} idToPop Dialog Id
     */
     pop(idToPop = '-1') {
        // To provide better control, we want to find the id's index and only pop it if it is the last one
        // in the stack.
        const foundIndex = this.localDialogRouteModels.findIndex((modals) => modals.id === idToPop);
        if (foundIndex >= 0 && foundIndex === (this.localDialogRouteModels.length - 1)) {
            this.localDialogRouteModels.pop();
        }
    }

    /**
     * Try and find the dialog route in the stack by dialog id
     * @param {object} dialogRouteModel dialog route model containing id, name and url
     */
    find(dialogRouteModel) {
        return this.localDialogRouteModels.find( (dialog) => dialog.id === dialogRouteModel.id);
    }

    /**
     * Find the dialog route model index
     * @param {object} dialogRouteModel dialog route model containing id, name and url
     */
    findIndex(dialogRouteModel) {
        return this.localDialogRouteModels.findIndex( (dialog) => dialog.id === dialogRouteModel.id);
    }

    /**
     * Reduce the array size eqal to the index found. This is faster than resizing the array.
     * @param {object} dialogRouteModel dialog route model containing id, name and url
     */
    reducer(dialogRouteModel) {
        const foundDialog = this.findIndex(dialogRouteModel);
        if (foundDialog > -1) this.localDialogRouteModels.length = foundDialog + 1;
    }

    /**
     * Reset our current state.
     */
    reset() {
        this.dialogRouteModels = [];
        this.locationKey = '';
        this.workbenchRouteModel = {};
    }

    /** Clear Dialog level breadcrumbs */
    clearDialogRouteModels() {
        this.dialogRouteModels = [];
    }

    set dialogRouteModels(newDialogRouteModels = []) {
        this.localDialogRouteModels = newDialogRouteModels;
    }

    get dialogRouteModels() {
        return this.localDialogRouteModels;
    }

    set workbenchRouteModel( workbenchRouteModel ) {
        this.localWorkbenchRouteModel = workbenchRouteModel;
    }

    get workbenchRouteModel() {
        return this.localWorkbenchRouteModel;
    }

    /**
     * Save the current state to history.
     */
    save() {
        // Save history only when we have location key
        if (this.locationKey) {
            saveHistory(this.locationKey, this.localDialogRouteModels, this.localWorkbenchRouteModel);
        }
    }

    /**
     * This method is used to see if we already have the location key available in history
     * @param {string} locationKey key used to search history to see if we have been here before.
     */
    restoredFromHistory(locationKey) {
        if (!this.isHistoryRestored) this.restoreHistoryFromCache();
        // eslint-disable-next-line no-shadow
        const foundHistory = history.find((history) => history.locationKey === locationKey);
        if (foundHistory) {
            this.reset();
            this.locationKey = foundHistory.locationKey;
            this.dialogRouteModels = [ ...foundHistory.localDialogRouteModels ];
            this.workbenchRouteModel = Object.assign({}, foundHistory.localWorkbenchRouteModel);
            return true;
        }
        return false;
    }

    /**
     * The purpose of this method is to handle a refresh. We are preserving our route history in our uiStore,
     * and want to restore it if a refresh is invoked. Timing is essential here so that the uiStore is hydrated
     * before we try and restore the history object with the stored history. We only have to do this once when we
     * initialize because this class is a singleton.
     */
    restoreHistoryFromCache() {
        const { uiStore } = rootStore;
        const historyObject = toJS(uiStore.getValueForUIObject(BREAD_CRUMBS, UiStorageKey));
        if (historyObject) history.push(...historyObject);
        this.isHistoryRestored = true;
    }

    /**
     * This method gives the current active launcher id in any given browser tab.
     * We get active launcher id based on the breadcrumbs data.
     * This active launcher id is needed for showing active launcher in Side Drawer.
     * @returns {String} Actibe Launcher Id
     */
     getActiveLauncherId() {
        const { uiStore } = rootStore;
        const historyObject = toJS(uiStore.getValueForUIObject(BREAD_CRUMBS, UiStorageKey));
        if (historyObject) {
            const foundHistory = historyObject.find((his) => his.locationKey === this.locationKey);
            if (foundHistory) {
                // ACTIVE_LAUNCHER_ID is set when we don't have any location key.
                // If we find history with the given location key, we don't need ACTIVE_LAUNCHER_ID any more. So, remove it.
                uiStore.removeValueForUIObject(WORKBENCH_UI_OBJECT_ID, ACTIVE_LAUNCHER_ID);
                const firstDialogModel = foundHistory.localDialogRouteModels[0];
                if (firstDialogModel.referringObjectType === TypeNames.ReferringWorkbenchTypeName) {
                    return firstDialogModel.referringObjectActionId;
                }
            }
        }
        // If we can't get active launcher from history, check for the ACTIVE_LAUNCHER_ID from uiStore.
        return uiStore.getValueForUIObject(WORKBENCH_UI_OBJECT_ID, ACTIVE_LAUNCHER_ID);
    }
}
const recursiveRouteManager = new RecursiveRouteManager();
export default recursiveRouteManager;