export class CellValue {
    constructor(style) {
        this.style = style;
    }
    get isHeading1Style() {
        return this.style && this.style === CellValue.STYLE_HEADING1;
    }
    get isHeading2Style() {
        return this.style && this.style === CellValue.STYLE_HEADING2;
    }
    get isHeading3Style() {
        return this.style && this.style === CellValue.STYLE_HEADING3;
    }
    get isHeading4Style() {
        return this.style && this.style === CellValue.STYLE_HEADING4;
    }
    get isSectionHeadingStyle() {
        return this.style && this.style === CellValue.STYLE_SECTION_HEADING;
    }
    get isInlineMediaStyle() {
        return (this.style && (this.style === CellValue.STYLE_INLINE_MEDIA || this.style === CellValue.STYLE_INLINE_MEDIA2));
    }
}
CellValue.STYLE_HEADING1 = 'textHeading1';
CellValue.STYLE_HEADING2 = 'textHeading2';
CellValue.STYLE_HEADING3 = 'textHeading3';
CellValue.STYLE_HEADING4 = 'textHeading4';
CellValue.STYLE_SECTION_HEADING = 'textSectionHeading';
CellValue.STYLE_INLINE_MEDIA = 'inlineMedia';
CellValue.STYLE_INLINE_MEDIA2 = 'Image/Video';
