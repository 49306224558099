import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import componentFactory from './componentFactory';
import SaltComponent from './SaltComponent';
import searchController from '../controllers/searchController';

@observer
export default class SortForm extends SaltComponent {
    static propTypes = {
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        onError: PropTypes.func,
        viewId: PropTypes.string,
    };

    render() {
        const listDialogStore = this.getListDialogStore();
        const { searchDialogStore } = listDialogStore;

        // If we don't have any stores, just close up shop
        if (!searchDialogStore) return null;

        // If the sort modal is not open, just return nothing.
        const { isSortModalOpen, readInProgress } = searchDialogStore;

        if (!isSortModalOpen || readInProgress) return null;

        // Build filter term object for the clients to use.
        const { dialog: listDialog } = listDialogStore;
        const { view: listView } = listDialog;
        const { columns } = listView;

        const sortTerms = searchDialogStore.sortValues || [];

        const sortTermsWithLabels = sortTerms.map((term) => {
            const column = columns.find((col) => col.propertyName === term.name);

            const labelFromColumn = column?.heading ? column.heading : term.name;

            return { ...term, label: labelFromColumn };
        });

        // Only need to build this once. Want to push this to the store.
        if (!this.sortableValues) {
            const sortableNames = searchDialogStore.getSortablePropertyNames();
            const sortableColumns = columns.filter((col) => sortableNames.findIndex((sortName) => col.propertyName === sortName) > -1);
            this.sortableValues = sortableColumns.map(sortableColumn => {
                return {
                    name: sortableColumn.propertyName,
                    label: sortableColumn && sortableColumn.heading ? sortableColumn.heading : sortableColumn.propertyName,
                    isAscending: true,
                };
            });
        }

        const resolvedProps = this.resolveProperties();
        const { style, xStyle } = resolvedProps;

        const props = {
            onSubmit: this.handleSubmit,
            onCancel: this.handleCancel,
            sortableValues: this.sortableValues,
            onSortTermsChange: this.handleSortTermsChange,
            onSortClear: this.handleSortClear,
            sortTerms: sortTermsWithLabels,
            style,
            xStyle,
        };

        return React.createElement(componentFactory.getPlatformComponent('sortForm'), props);
    }

    getListDialogStore() {
        const {
            viewId,
        } = this.props;

        const { saltStore } = this.context;
        return saltStore.getDialogStoreForViewId(viewId);
    }

    handleSubmit = (newSortTerms) => {
        const { onError } = this.props;
        const listDialogStore = this.getListDialogStore();
        const { searchDialogStore } = listDialogStore;
        const { saltStore } = this.context;
        const { uiStore } = saltStore;
        const sortTerms = newSortTerms || searchDialogStore.sortValues || [];
        searchController.submitSortTerms({ listDialogStore, sortTerms, onError, uiStore });
    };

    handleCancel = () => {
        const listDialogStore = this.getListDialogStore();
        listDialogStore.setSortModalOpen(false);
        // Refresh the buffer to clear unsaved changes.
        const { searchDialogStore } = listDialogStore;
        searchDialogStore.refresh();
    };

    handleSortTermsChange = (sortTerms) => {
        const { onError } = this.props;
        const listDialogStore = this.getListDialogStore();
        searchController.setSortTerms({ listDialogStore, sortTerms, onError });
    };

    handleSortClear = () => {
        const { onError } = this.props;
        const listDialogStore = this.getListDialogStore();
        searchController.clearSortValues({ listDialogStore, onError });
    };
}
