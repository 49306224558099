/* @flow */
export default {
    home: 'Home',
    about: 'About',
    exitApp: ' Exit app',
    logout: 'Logout',
    logoutConfirmation: {
        title: 'Log out',
        msg: 'Are you sure you want to exit the app?',
        cancelBtn: 'Cancel',
        confirmBtn: 'Exit',
    },
    userWelcome: 'Welcome',
};
