
import React from 'react';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import { pageController, utilities, constants, rootStore } from 'cv-react-core';
import componentFactory from './componentFactory';
import SaltComponent from './SaltComponent';
import SaltContext from './SaltContext';
import serviceFactory from '../services/serviceFactory';
import FormLayout from './form/FormLayout';

// We can see this one day being a possible configuration in extender.
// For now we want to simply add this as a constaint her and later repurpose as a metadata
const DefaultZoomLevel = 14;

export default class Map extends SaltComponent {
    static propTypes = {
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        viewId: PropTypes.string,
    };

    render() {
        const { saltStore, dialogStore, onTransition, onError } = this.contextParams;
        const {
            uiStore,
        } = saltStore;
        let resolvedProps = this.resolveProperties();
        resolvedProps = {
            dialogStore,
            onTransition,
            onError,
            uiStore,
            defaultZoomLevel: DefaultZoomLevel,
            onMenuAction: this.handleMenuAction,
            onSetGeoLocation: this.handleOnSetGeoLocation,
            onSetSelectedMarker: this.handleOnSetSelectedMarker,
            ...this.extractExpandedViewParams(),
            ...resolvedProps,
        };
        return React.createElement(componentFactory.getPlatformComponent('map'), resolvedProps);
    }

    get contextParams() {
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore, onTransition, onError } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        const { uiStore, sessionStore } = saltStore;
        return { saltStore, onTransition, onError, dialogStore, uiStore, sessionStore };
    }

    extractExpandedViewParams = () => {
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore } = this.context;
        const { uiStore } = saltStore;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        const { dialog } = dialogStore;
        const formDialogStore = dialogStore.parentDialogStore;
        let formViewMode = FormLayout.FORM_VIEW_MODE.MULTI_VIEW;
        if (formDialogStore.isExpandedOrSinglePanelView) {
            formViewMode = formDialogStore.isExpandedState ? FormLayout.FORM_VIEW_MODE.EXPANDED_VIEW : FormLayout.FORM_VIEW_MODE.SINGLE_VIEW;
        }
        const { view } = dialog;
        const { type: viewType } = view;
        // let the implementing panel know if we are in expanded mode
        const isExpanded = formViewMode && formViewMode === FormLayout.FORM_VIEW_MODE.EXPANDED_VIEW;
        // let the implementing panel know if we're layout managed with multiple views
        const showFormTools = formViewMode && (formViewMode === FormLayout.FORM_VIEW_MODE.MULTI_VIEW || formViewMode === FormLayout.FORM_VIEW_MODE.EXPANDED_VIEW);
        // xMenu should be used for client side menu operations that are not modeled in extender
        const xMenu = this.getExtendedMenu(viewType, dialogStore, uiStore);
        return { isExpanded, showFormTools, xMenu };
    }

    getExtendedMenu(viewType, dialogStore, uiStore) {
        // build extended menu objects with the necessary pieces for each
        return pageController.getAvailableExtendedActions(viewType, dialogStore, uiStore);
    }

    handleOnSetSelectedMarker = (marker) => {
        const { uiStore } = this.contextParams;
        // TODO: Need to change this to a marker and not just corrdinates. Mobile and Web
        // are for some reason doing all this different.
        uiStore.setValueForUIObject(constants.ui.APPLICATION_UI_ID, constants.ui.MARKER_COORDINATES, {
            latitude: marker.latitude,
            longitude: marker.longitude,
        }, false);
    }

    handleMenuAction = (menu) => {
        const { dialogStore, uiStore, onTransition, onError } = this.contextParams;

        pageController.performActionWithConfirmation({
            actionId: menu.id,
            selectedArray: utilities.listHelper.getSelectedAsArray(uiStore, dialogStore),
            dialogStore,
            uiStore,
            onTransition: onTransition,
            onError,
        });
    };

    handleOnSetGeoLocation = (redirection) => {
        const { sessionStore, uiStore, onTransition, onError } = this.contextParams;
        const { dialogId } = redirection;
        sessionStore.openOrRefreshDialogStore(dialogId)
            .then((store) => {
                const detailsDialogStore = store.childDialogStores[0];
                const title = serviceFactory.lang.dialog.errors.errorReadingRecordTitle;
                const markerCoordinates = uiStore.getValueForUIObject(constants.ui.APPLICATION_UI_ID, constants.ui.MARKER_COORDINATES);
                pageController.performReadRecord({ dialogStore: detailsDialogStore, title, onError }).then(() => {
                    let value = toJS(markerCoordinates);
                    if (value) {
                        value = `${value.latitude}:${value.longitude}`;
                    }
                    detailsDialogStore.setPropertyValue('GEOLOCATION_PROP', value);
                    pageController.performWrite({ dialogStore: detailsDialogStore, uiStore, onTransition, onError });
                });
            });
    }
}

Map.contextType = SaltContext;
