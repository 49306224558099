import { ObjUtil } from './ObjUtil';
export var LogLevel;
(function (LogLevel) {
    LogLevel[LogLevel["ERROR"] = 0] = "ERROR";
    LogLevel[LogLevel["WARN"] = 1] = "WARN";
    LogLevel[LogLevel["INFO"] = 2] = "INFO";
    LogLevel[LogLevel["DEBUG"] = 3] = "DEBUG";
    LogLevel[LogLevel["TRACE"] = 4] = "TRACE";
})(LogLevel || (LogLevel = {}));
const LogLevelNameMap = {
    'ERROR': LogLevel.ERROR,
    'WARN': LogLevel.WARN,
    'INFO': LogLevel.INFO,
    'DEBUG': LogLevel.DEBUG,
    'TRACE': LogLevel.TRACE
};
/* tslint:disable */
export class LogConfig {
    constructor(style) {
        this.style = style;
    }
}
;
export class Log {
    static setRegExFilter(regExFilter, flags) {
        if (regExFilter) {
            this._regExFilter = new RegExp(regExFilter, flags);
        }
    }
    static setLevelByName(name) {
        if (name) {
            const level = LogLevelNameMap[name.toUpperCase()];
            if (level === undefined) {
                throw new Error(`Invalid Logging Level: ${name}`);
            }
            Log.logLevel(level);
        }
    }
    static logLevel(level) {
        if (level >= LogLevel.TRACE) {
            Log.trace = (...messages) => {
                Log.log((...o) => {
                    console.log(...o);
                }, messages, 'TRACE');
            };
        }
        else {
            Log.trace = () => { };
        }
        if (level >= LogLevel.DEBUG) {
            Log.debug = (...messages) => {
                Log.log((...o) => {
                    console.debug(...o);
                }, messages, 'DEBUG');
            };
        }
        else {
            Log.debug = () => { };
        }
        if (level >= LogLevel.INFO) {
            Log.info = (...messages) => {
                Log.log((...o) => {
                    console.info(...o);
                }, messages, 'INFO');
            };
        }
        else {
            Log.info = () => { };
        }
        if (level >= LogLevel.WARN) {
            Log.warn = (...messages) => {
                Log.log((...o) => {
                    console.warn(...o);
                }, messages, 'WARN');
            };
        }
        else {
            Log.warn = () => { };
        }
        if (level >= LogLevel.ERROR) {
            Log.error = (...messages) => {
                Log.log((...o) => {
                    console.error(...o);
                }, messages, 'ERROR');
            };
        }
        else {
            Log.error = () => { };
        }
        Log._logLevel = level;
    }
    static loggerForLevel(logLevel) {
        if (logLevel === LogLevel.TRACE) {
            return Log.trace;
        }
        if (logLevel === LogLevel.DEBUG) {
            return Log.debug;
        }
        if (logLevel === LogLevel.INFO) {
            return Log.info;
        }
        if (logLevel === LogLevel.WARN) {
            return Log.warn;
        }
        return Log.error;
    }
    static callStack(message) {
        console.trace(message);
    }
    static isEnabled(level) {
        return Log._logLevel >= level;
    }
    static log(logger, messages, level) {
        if (this._regExFilter) {
            if (!messages.some(message => typeof message === 'string' ? message.match(this._regExFilter) : false)) {
                return;
            }
        }
        let style = undefined;
        let resolvedMessages = messages;
        if (messages.length > 1) {
            const message = messages[messages.length - 1];
            if (message instanceof LogConfig) {
                style = message.style;
                resolvedMessages = messages.slice(0, -1);
            }
        }
        if (style) {
            logger(`%c ${level}>>`, `${style}`, ...resolvedMessages);
        }
        else {
            logger(`${level}>>`, ...resolvedMessages);
        }
    }
    static config(style) {
        return new LogConfig(style);
    }
    static prettyPrint(o) {
        return ObjUtil.formatRecAttr(o, true);
    }
    static formatRecString(o) {
        return ObjUtil.formatRecAttr(o);
    }
}
Log.blue = Log.config('color: blue');
Log.royalblue = Log.config('color: royalblue');
Log.deepskyblue = Log.config('color: deepskyblue');
Log.green = Log.config('color: green');
Log.red = Log.config('color: red');
Log.yellow = Log.config('color: yellow');
Log.purple = Log.config('color: purple');
Log.cyan = Log.config('color: cyan');
Log.lime = Log.config('color: lime');
Log.magenta = Log.config('color: magenta');
Log.hotpink = Log.config('color: hotpink');
Log.gold = Log.config('color: gold');
Log.orange = Log.config('color: orange');
//set default log level here
Log.init = Log.logLevel(LogLevel.INFO);
