import FlexDirection from './FlexDirection';

const lineWidth = (layout, parentNode) => {
    if (layout.lineWidth) {
        if (parentNode) {
            const result = { alignSelf: 'stretch' };
            const flexParentNode = parentNode.getNearestFlexNode();
            if (FlexDirection.isColumnFlexDirectionForNode(flexParentNode)) {
                result.height = parseFloat(layout.lineWidth);
            } else {
                result.width = parseFloat(layout.lineWidth);
            }
            return result;
        }
    }
    return {};
};

export default lineWidth;
