// Core component styles
const coreStyles = {

    anchorScrollColumn: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 0 0',
        overflowY: 'auto',
    },

    mainContentRowSizingContainer: {
        display: 'flex',
        flexDirection: 'row',
        flex: '1 0 auto',
        overflowY: 'hidden',
        overflowX: 'auto',
        maxHeight: '90vh',
    },

    column: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        minWidth: '600px',
        overflow: 'hidden',
    },

    overflowColumn: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 0 0',
        overflow: 'hidden',
    },
};

/**
 * Helper method to get the styles and merge with context styles
 * @param {Object} contextStyles
 */
const getStyles = (contextStyles = {}) => {
    return {
        anchorScrollColumn: {
            ...coreStyles.anchorScrollColumn,
            ...contextStyles.anchorScrollColumn,
        },

        mainContentRowSizingContainer: {
            ...coreStyles.mainContentRowSizingContainer,
            ...contextStyles.mainContentRowSizingContainer,
        },

        column: {
            ...coreStyles.column,
            ...contextStyles.column,
        },

        overflowColumn: {
            ...coreStyles.overflowColumn,
            ...contextStyles.overflowColumn,
        },
    };
};

export default getStyles;