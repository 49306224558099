import { rootStore } from 'cv-react-core';

const coreStyles = {
    avatar: {
        cursor: 'pointer',
        height: '32px',
        width: '32px',
    },
    avatarIcon: {
        color: '#fff',
        fontSize: '35px',
    },
    closeIcon: {
        color: '#424242',
        fontSize: '24px',
    },
    closeIconButton: {
        padding: '0px',
    },
    container: {
        boxSizing: 'border-box',
        width: '244px',
        backgroundColor: 'rgba(255, 255, 255)',
        backdropFilter: 'blur(10px)',
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'row',
        borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
        justifyContent: 'space-between',
        padding: '10px 16px',
        marginBottom: '12px',
    },
    imageContainer: {
        cursor: 'pointer',
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '12px 0px',
    },
    imageStyle: {
        objectFit: 'contain',
        verticalAlign: 'middle',
        fontSize: '21px',
    },
    labelContainer: {
        alignItems: 'center',
    },
    labelText: {
        color: '#333333',
        fontFamily: 'Roboto',
        fontSize: '12px',
        fontWeight: 'normal',
        letterSpacing: '0.02em',
        lineHeight: '15px',
        fontStyle: 'normal',
        cursor: 'pointer',
    },
    menuItem: {
        backgroundColor: 'transparent',
        padding: '0px 8px',
        margin: '0px 8px 8px',
    },
    menuItemFocused: {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        borderRadius: '5px',
    },
    menuItemSelected: {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        borderRadius: '5px',
    },
    profileText: {
        fontSize: '14px',
        marginLeft: '10px',
    },
    userContainer: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
    },
    valueContainer: {
        alignItems: 'center',
        marginTop: '2px',
        overflow: 'auto',
    },
    valueText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '18px',
        lineHeight: '21px',
        letterSpacing: '0.02em',
        color: '#000000',
        overflow: 'auto',
    },
};

const getStyles = (contextStyles = {}) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();
    const textStyles = {
        ...theme.fonts.default,
        color: '#000000',
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 400,
        letterSpacing: '0.02em',
        lineHeight: '20px',
    };
    return {
        avatar: {
            ...coreStyles.avatar,
            ...contextStyles.avatar,
        },
        avatarIcon: {
            ...coreStyles.avatarIcon,
            ...contextStyles.avatarIcon,
        },
        closeIcon: {
            ...coreStyles.closeIcon,
            ...contextStyles.closeIcon,
        },
        closeIconButton: {
            ...coreStyles.closeIconButton,
            ...contextStyles.closeIconButton,
        },
        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },
        contentContainer: {
            ...coreStyles.contentContainer,
            ...contextStyles.contentContainer,
        },
        imageContainer: {
            ...coreStyles.imageContainer,
            ...contextStyles.imageContainer,
        },
        imageStyle: {
            ...coreStyles.imageStyle,
            ...contextStyles.imageStyle,
        },
        labelContainer: {
            ...coreStyles.labelContainer,
            ...contextStyles.labelContainer,
        },
        labelText: {
            ...coreStyles.labelText,
            ...contextStyles.labelText,
        },
        menuItem: {
            ...textStyles,
            ...coreStyles.menuItem,
            ...contextStyles.menuItem,
        },
        menuItemFocused: {
            ...textStyles,
            ...coreStyles.menuItemFocused,
            ...contextStyles.menuItemFocused,
        },
        menuItemSelected: {
            ...textStyles,
            ...coreStyles.menuItemSelected,
            ...contextStyles.menuItemSelected,
        },
        profileText: {
            ...coreStyles.valueText,
            ...contextStyles.valueText,
            ...coreStyles.profileText,
        },
        userContainer: {
            ...coreStyles.userContainer,
            ...contextStyles.userContainer,
        },
        valueContainer: {
            ...coreStyles.valueContainer,
            ...contextStyles.valueContainer,
        },
        valueText: {
            ...coreStyles.valueText,
            ...contextStyles.valueText,
        },
    };
};

export default getStyles;
