// Core component styles
const coreStyles = {
    calendarCellStyles: {
    },
};

/**
 * Combine component context styles with core styles
 * @param {Object} contextStyles
 * @return {Object}
 */
const getStyles = (contextStyles = {}) => {
    return {
        calendarCellStyles: {
            ...coreStyles.calendarCellStyles,
            ...contextStyles.calendarCellStyles,
        },
    };
};

export default getStyles;
