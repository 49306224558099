import { TypeNames } from "./types";
export class ObjectRef {
    constructor(objectId, description, type = TypeNames.ObjectRefTypeName) {
        this.objectId = objectId;
        this.description = description;
        this.type = type;
    }
    toString() {
        return this.objectId + ':' + this.description;
    }
    static fromString(objectRef) {
        if (!objectRef) {
            return null;
        }
        const [objectId, description] = objectRef.split(':');
        return new ObjectRef(objectId, description);
    }
}
