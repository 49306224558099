const baseMegaSize = 25;
const baseFontSize = 16;
const baseMetaSize = 10;

export const calcLineHeight = (fontSize) => Math.round(fontSize * 1.4);

export default {
    baseFontFamily: 'Open Sans',
    baseFontSize,
    baseMetaSize,
    baseMegaSize,
    baseLineHeight: calcLineHeight(baseFontSize),
    metaLineHeight: calcLineHeight(baseMetaSize),
    megaLineHeight: calcLineHeight(baseMegaSize),
};
