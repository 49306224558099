/* @flow */
export const routes = {
    LOGIN: 'LOGIN',
    WORKBENCH: 'hxgn.api.dialog.Workbench',
    DIALOG: 'hxgn.api.dialog.Dialog',
    ABOUT: 'ABOUT',
    SETTINGS: 'settings', // global, pre-login
    PREFS: 'PREFS', // post-login, per user
    GO_OFFLINE: 'GO_OFFLINE',
    GO_ONLINE: 'GO_ONLINE',
    BRIEFCASE_MANAGER: 'BriefcaseManager',
};

export const displayHints = {
    HINT_DIALOG: 'DIALOG',
    HINT_NO_VIEW: 'NO_VIEW',
    HINT_IN_COMPONENT: 'IN_COMPONENT',
}