import React from 'react';
import MuiDialogContent from '@material-ui/core/DialogContent';
import * as PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import getStyles from './ModalContent.styles';

/**
 * A styled content container that displays well in a modal.
 * @see https://material-ui.com/components/dialogs/
 * @see https://material-ui.com/api/dialog-content/
 */
const ModalContent = (props) => {
    const {
        children,
        contextStyles,
        testID,
    } = props;

    // Override core styles with context styles, separating MUI styles
    const muiStyles = getStyles(contextStyles);

    // Create dynamic class names and injected DOM styles for MUI component
    // * makeStyles returns a function
    const styles = makeStyles(muiStyles)();

    return (
        <MuiDialogContent
            className="c-modal-content"
            classes={ {
                root: styles.content, // MUI styles
            } }
            data-test-id={ `${testID}__modal-content` }>
            { children }
        </MuiDialogContent>
    );
};

ModalContent.propTypes = {
    /** Content displayed in a styled modal content container */
    children: PropTypes.node,

    /** Extended styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the content */
        content: PropTypes.object,
    }),

    /** Id used for testing */
    testID: PropTypes.string,
};

ModalContent.defaultProps = {
    contextStyles: {},
    testID: 'ModalContent',
};

export default ModalContent;
