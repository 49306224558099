// Core component styles
const coreStyles = {
    container: {
    },

    title: {
    },
};

/**
 * Combines coreStyles and contextStyles
 * @param {Object} contextStyles
 */
const getStyles = (contextStyles = {}) => {
    return {
        container: {
            ...coreStyles.container,
            ...contextStyles.container,

            '& .MuiTypography-root': {
                ...coreStyles.title,
                ...contextStyles.title,
            },
        },
    };
};

export default getStyles;
