// Core component styles
const coreStyles = {
    modal: {
        maxHeight: '90%',
        maxWidth: '95%',
        borderRadius: '5px',
        padding: '5px',
        backgroundColor: 'transparent',
        overflow: 'auto',
    },
    overlay: {},
};

/**
 * Combines coreStyles and contextStyles
 * @param {Object} contextStyles
 * @param {Boolean} isTransparentOverlay - Enables fully transparent overlay
 */
const getStyles = (contextStyles = {}, isTransparentOverlay = false) => {
    // Set default overlay background color
    let overlayBackgroundColor = 'rgba(0,0,0,0.15)';

    // Check for overlay background color context style override and apply
    if (contextStyles.overlay && contextStyles.overlay.backgroundColor) {
        overlayBackgroundColor = contextStyles.overlay.backgroundColor;
    }

    return {
        modal: {
            ...coreStyles.modal,
            ...contextStyles.modal,
        },

        overlay: {
            ...coreStyles.overlay,
            ...contextStyles.overlay,
            backgroundColor: isTransparentOverlay ? 'transparent' : overlayBackgroundColor,
        },
    };
};

export default getStyles;
