import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';

import { default as IconButton } from '../IconButton/IconButton';
import { default as TextField } from '../TextField/TextField';
import { default as getStyles } from './MapAddressSearch.styles';

/**
 * A control that allows search for locations in a map.
 */
const MapAddressSearch = (props) => {
    const {
        contextStyles,
        onAddressSearch,
        placeholder,
        iconName,
        iconSize,
        value,
    } = props;
    const {
        container: containerStyles,
        icon,
        input: inputStyles,
    } = getStyles(contextStyles);

    const [
        text,
        setText,
    ] = useState(value);

    return (
        <TextField
            contextStyles={ {
                container: containerStyles,
                input: inputStyles,
            } }
            disableUnderline
            placeholder={ placeholder }
            endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        contextStyles={ {
                            icon,
                        } }
                        iconName={ iconName }
                        iconSize={ iconSize }
                        onClick={ () => onAddressSearch(text) } />
                </InputAdornment>
            }
            value={ value }
            onChange={ (e) => setText(e.target.value) }
            onSubmit={ () => onAddressSearch(text) } />
    );
};

MapAddressSearch.propTypes = {
    /** Styles for this component */
    contextStyles: PropTypes.object,

    /**
    * Called when address is searched
    * @param {String} text - address text
    */
    onAddressSearch: PropTypes.func,

    /** Place holder text to be displayed in textfield */
    placeholder: PropTypes.string,

    /** Icon name to be displayed */
    iconName: PropTypes.string,

    /** Icon size */
    iconSize: PropTypes.number,

    /** The text to populate in the input */
    value: PropTypes.string,
};

MapAddressSearch.defaultProps = {
    contextStyles: {},
    iconName: 'search',
    iconSize: 20,
    placeholder: 'What are you looking for?',
};

export default MapAddressSearch;
