import React from 'react';
import * as PropTypes from 'prop-types';
import MuiSlider from '@material-ui/core/Slider';

import makeStyles from '@material-ui/core/styles/makeStyles';
import getStyles from './Slider.styles';

/**
 * A control that allow users to make selections from a range of values.
 * @see https://material-ui.com/components/slider/
 */
const Slider = (props) => {
    const {
        contextStyles,
        disabled,
        max,
        min,
        onChange,
        testID,
        value,
        valueLabelDisplay,
    } = props;

    // Override core styles with context styles, separating MUI styles
    const {
        container: containerStyles,
        ...muiStyles
    } = getStyles(contextStyles);

    // Create dynamic class names and injected DOM styles for MUI component
    // * makeStyles returns a function
    const styles = makeStyles(muiStyles)();

    // Generate slider props
    const sliderProps = {
        classes: {
            rail: styles.rail,
            root: styles.trackContainer,
            thumb: styles.thumb,
            track: styles.track,
        },
        className: 'c-slider__container',
        disabled,
        max,
        min,
        onChangeCommitted: (event, newValue) => {
            onChange(event, newValue, props);
        },
        value,
        valueLabelDisplay,
    };
    if (testID) { sliderProps['data-test-id'] = `${testID}__Slider__container`; }

    return (
        <div
            className="c-Slider"
            style={ containerStyles }>
            <MuiSlider { ...sliderProps } />
        </div>
    );
};

Slider.propTypes = {
    /** Extended styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the container surrounding the control */
        container: PropTypes.object,

        /** Styles for the root button element when disabled */
        disabled: PropTypes.object,

        /** Styles for slider rail */
        rail: PropTypes.object,

        /** Styles for the thumb button */
        thumb: PropTypes.object,

        /** Styles for the slider track */
        track: PropTypes.object,

        /** Styles for container surrounding the slider */
        trackContainer: PropTypes.object,
    }),

    /** Disables the control */
    disabled: PropTypes.bool,

    /** max value of slider */
    max: PropTypes.number,

    /** min value of slider */
    min: PropTypes.number,

    /**
     * Called when the slider was changed
     * @param {Object} event - The native change event
     * @param {Object} newValue - The selected new value
     * @param {Object} props - The component instance props
     */
    onChange: PropTypes.func,

    /** Id used for testing */
    testID: PropTypes.string,

    /** The Selected value */
    value: PropTypes.array,

    /** to show the vaue label on focused or hovered on thumb */
    valueLabelDisplay: PropTypes.string,
};

Slider.defaultProps = {
    contextStyles: {},
    max: 100,
    min: 0,
    testID: 'Slider',
    valueLabelDisplay: 'auto',
};

export default Slider;
