import React from 'react';
import FieldAction from './FieldAction';
import TextLabel from './TextLabel';
import TextField from './TextField';
import TextBlock from './TextBlock';
import TextWithImage from './TextWithImage';
import TextFieldWithColorPalette from './TextFieldWithColorPalette';
import EmailField from './EmailField';
import PasswordField from './PasswordField';
import NumberField from './NumberField';
import TelephoneField from './TelephoneField';
import ComboBoxField from './ComboBoxField';
import DropDownField from './DropDownField';
import DropDownTextwithImageField from './DropDownTextwithImageField';
import DropDownMultiSelectField from './DropDownMultiSelectField';
import isPropertyEqual from './isPropertyEqual';
import Image from './Image';
import ImagePicker from './ImagePicker';
import SignatureImage from './SignatureImage';
import PaperImage from './PaperImage';
import Switch from './Switch';
import FilePickerInput from './FilePickerInput';
import DateField from './DateField';
import DateTimeField from './DateTimeField';
import TimeField from './TimeField';
import HtmlTextField from './HtmlTextField';
import Checkbox from './Checkbox';
import Radio from './Radio';

/**
 *       _   _         _                  _       ___                           _             ___                                             _
 *      /_\ | |__  ___| |_ _ __ __ _  ___| |_    / _ \_ __ ___  _ __   ___ _ __| |_ _   _    / __\___  _ __ ___  _ __   ___  _ __   ___ _ __ | |_ ___
 *     //_\\| '_ \/ __| __| '__/ _` |/ __| __|  / /_)/ '__/ _ \| '_ \ / _ \ '__| __| | | |  / /  / _ \| '_ ` _ \| '_ \ / _ \| '_ \ / _ \ '_ \| __/ __|
 *    /  _  \ |_) \__ \ |_| | | (_| | (__| |_  / ___/| | | (_) | |_) |  __/ |  | |_| |_| | / /__| (_) | | | | | | |_) | (_) | | | |  __/ | | | |_\__ \
 *    \_/ \_/_.__/|___/\__|_|  \__,_|\___|\__| \/    |_|  \___/| .__/ \___|_|   \__|\__, | \____/\___/|_| |_| |_| .__/ \___/|_| |_|\___|_| |_|\__|___/
 *                                                             |_|                  |___/                       |_|
 *      Abstract Property Components are registered here
 *      All other component types (non-property) are registered in the top-level engine/abstractComponentProvider
 */
const propertyAbstractComponentProvider = {
    textLabel: React.memo(TextLabel, isPropertyEqual),
    textField: React.memo(TextField, isPropertyEqual),
    numberField: React.memo(NumberField, isPropertyEqual),
    passwordField: React.memo(PasswordField, isPropertyEqual),
    emailField: React.memo(EmailField, isPropertyEqual),
    telephoneField: React.memo(TelephoneField, isPropertyEqual),
    textBlock: React.memo(TextBlock, isPropertyEqual),
    textWithImage: React.memo(TextWithImage, isPropertyEqual),
    textFieldWithColorPalette: React.memo(TextFieldWithColorPalette, isPropertyEqual),
    fieldAction: React.memo(FieldAction, isPropertyEqual),
    comboBoxField: React.memo(ComboBoxField, isPropertyEqual),
    dropDownField: React.memo(DropDownField, isPropertyEqual),
    dropDownTextwithImageField: React.memo(DropDownTextwithImageField, isPropertyEqual),
    dropDownMultiSelectField: React.memo(DropDownMultiSelectField, isPropertyEqual),
    image: React.memo(Image, isPropertyEqual),
    imagePicker: React.memo(ImagePicker, isPropertyEqual),
    signatureImage: React.memo(SignatureImage, isPropertyEqual),
    paperImage: React.memo(PaperImage, isPropertyEqual),
    switch: React.memo(Switch, isPropertyEqual),
    filePickerInput: React.memo(FilePickerInput, isPropertyEqual),
    dateField: React.memo(DateField, isPropertyEqual),
    dateTimeField: React.memo(DateTimeField, isPropertyEqual),
    timeField: React.memo(TimeField, isPropertyEqual),
    htmlTextField: React.memo(HtmlTextField, isPropertyEqual),
    checkbox: React.memo(Checkbox, isPropertyEqual),
    radio: React.memo(Radio, isPropertyEqual),

    /*
    html: Html,
    image: Image,
    icon: Icon
    urlImage: UrlImage
    */

};

export default propertyAbstractComponentProvider;
