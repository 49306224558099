import { ArrayUtil } from '../util/ArrayUtil';
import { NullRecord } from './NullRecord';
import { RecordBuffer } from './RecordBuffer';
import { QueryDirectionEnum } from './types';
/**
 * *********************************
 */
class HasMoreQueryMarker extends NullRecord {
}
HasMoreQueryMarker.singleton = new HasMoreQueryMarker();
class IsEmptyQueryMarker extends NullRecord {
}
IsEmptyQueryMarker.singleton = new IsEmptyQueryMarker();
export var QueryMarkerOption;
(function (QueryMarkerOption) {
    QueryMarkerOption[QueryMarkerOption["None"] = 0] = "None";
    QueryMarkerOption[QueryMarkerOption["IsEmpty"] = 1] = "IsEmpty";
    QueryMarkerOption[QueryMarkerOption["HasMore"] = 2] = "HasMore";
})(QueryMarkerOption || (QueryMarkerOption = {}));
export class QueryScroller {
    constructor(_dialog, _defaultPageSize, _firstRecordId, _markerOptions = []) {
        this._dialog = _dialog;
        this._defaultPageSize = _defaultPageSize;
        this._firstRecordId = _firstRecordId;
        this._markerOptions = _markerOptions;
        this.clear();
    }
    get buffer() {
        return this._buffer;
    }
    get sessionValues() {
        return this._sessionValues;
    }
    get bufferWithMarkers() {
        const result = ArrayUtil.copy(this._buffer);
        if (this.isComplete) {
            if (this._markerOptions.indexOf(QueryMarkerOption.IsEmpty) > -1) {
                if (this.isEmpty) {
                    result.push(new RecordBuffer(IsEmptyQueryMarker.singleton));
                }
            }
        }
        else if (this._markerOptions.indexOf(QueryMarkerOption.HasMore) > -1) {
            if (result.length === 0) {
                result.push(new RecordBuffer(HasMoreQueryMarker.singleton));
            }
            else {
                if (this._hasMoreBackward) {
                    result.unshift(new RecordBuffer(HasMoreQueryMarker.singleton));
                }
                if (this._hasMoreForward) {
                    result.push(new RecordBuffer(HasMoreQueryMarker.singleton));
                }
            }
        }
        return result;
    }
    get dialog() {
        return this._dialog;
    }
    get firstRecordId() {
        return this._firstRecordId;
    }
    get hasMoreBackward() {
        return this._hasMoreBackward;
    }
    get hasMoreForward() {
        return this._hasMoreForward;
    }
    get isComplete() {
        return !this._hasMoreBackward && !this._hasMoreForward;
    }
    get isCompleteAndEmpty() {
        return this.isComplete && this._buffer.length === 0;
    }
    get isEmpty() {
        return this._buffer.length === 0;
    }
    pageBackward(pageSize = this.pageSize) {
        if (!this._hasMoreBackward) {
            return Promise.resolve([]);
        }
        if (this._prevPagePromise) {
            this._prevPagePromise = this._prevPagePromise.then((recordSet) => {
                const fromRecordId = this._buffer.length === 0 ? null : this._buffer[0].id;
                return this._dialog.query(pageSize, QueryDirectionEnum.BACKWARD, fromRecordId);
            })
                .catch(error => {
                // Reset the promise handler and rethrow exception for the client.
                this._prevPagePromise = undefined;
                return Promise.reject(error);
            });
        }
        else {
            const fromRecordId = this._buffer.length === 0 ? null : this._buffer[0].id;
            this._prevPagePromise = this._dialog.query(pageSize, QueryDirectionEnum.BACKWARD, fromRecordId);
        }
        return this._prevPagePromise.then((queryResult) => {
            this._hasMoreBackward = queryResult.hasMore;
            if (queryResult.records.length > 0) {
                const newBuffer = [];
                for (let i = queryResult.records.length - 1; i > -1; i--) {
                    newBuffer.push(new RecordBuffer(queryResult.records[i]));
                }
                this._buffer.forEach((record) => {
                    newBuffer.push(record);
                });
                this._buffer = newBuffer;
            }
            if (queryResult.sessionValues) {
                this._sessionValues = queryResult.sessionValues;
            }
            return queryResult.records;
        })
            .catch(error => {
            // Reset the promise handler and rethrow exception for the client.
            this._prevPagePromise = undefined;
            return Promise.reject(error);
        });
    }
    pageForward(pageSize = this.pageSize, overrideRecordId = null) {
        if (!this._hasMoreForward) {
            return Promise.resolve([]);
        }
        if (this._nextPagePromise) {
            this._nextPagePromise = this._nextPagePromise.then((recordSet) => {
                const fromRecordId = this._buffer.length === 0 ? null : this._buffer[this._buffer.length - 1].id;
                return this._dialog.query(pageSize, QueryDirectionEnum.FORWARD, overrideRecordId || fromRecordId);
            })
                .catch(error => {
                // Reset the promise handler and rethrow exception for the client.
                this._nextPagePromise = undefined;
                return Promise.reject(error);
            });
        }
        else {
            const fromRecordId = this._buffer.length === 0 ? null : this._buffer[this._buffer.length - 1].id;
            this._nextPagePromise = this._dialog.query(pageSize, QueryDirectionEnum.FORWARD, overrideRecordId || fromRecordId);
        }
        return this._nextPagePromise.then((queryResult) => {
            this._hasMoreForward = queryResult.hasMore;
            if (queryResult.records.length > 0) {
                const newBuffer = [];
                this._buffer.forEach((record) => {
                    newBuffer.push(record);
                });
                queryResult.records.forEach((record) => {
                    newBuffer.push(new RecordBuffer(record));
                });
                this._buffer = newBuffer;
            }
            if (queryResult.sessionValues) {
                this._sessionValues = queryResult.sessionValues;
            }
            return queryResult.records;
        })
            .catch(error => {
            // Reset the promise handler and rethrow exception for the client.
            this._nextPagePromise = undefined;
            return Promise.reject(error);
        });
    }
    get pageSize() {
        return this._defaultPageSize;
    }
    refresh(numRows = this.pageSize, recordId = null) {
        if (this._refreshPromise) {
            this._refreshPromise = this._refreshPromise.then((records) => {
                this.clear();
                return this.pageForward(numRows, recordId);
            })
                .catch(error => {
                // Reset the promise handler and rethrow exception for the client.
                this._refreshPromise = undefined;
                return Promise.reject(error);
            });
        }
        else {
            this.clear();
            this._refreshPromise = this.pageForward(numRows, recordId);
        }
        return this._refreshPromise.then((records) => {
            if (records.length > 0) {
                this._firstResultRecordId = records[0].id;
                if (!recordId) {
                    this._firstRecordId = records[0].id;
                }
            }
            return records;
        })
            .catch(error => {
            // Reset the promise handler and rethrow exception for the client.
            this._refreshPromise = undefined;
            return Promise.reject(error);
        });
    }
    trimFirst(n) {
        const newBuffer = [];
        for (let i = n; i < this._buffer.length; i++) {
            newBuffer.push(this._buffer[i]);
        }
        this._buffer = newBuffer;
        this.setFirstResultRecordId();
        this._hasMoreBackward = true;
    }
    trimLast(n) {
        const newBuffer = [];
        for (let i = 0; i < n; i++) {
            newBuffer.push(this._buffer[i]);
        }
        this._buffer = newBuffer;
        this.setFirstResultRecordId();
        this._hasMoreForward = true;
    }
    setFirstResultRecordId() {
        if (this._buffer.length) {
            this._firstResultRecordId = this._buffer[0].id;
        }
    }
    clear() {
        this._hasMoreBackward = true;
        this._hasMoreForward = true;
        this._buffer = [];
        this._firstResultRecordId = null;
    }
}
