
class ComponentFactory {
    getAbstractComponent(name) {
        if (!this.abstractComponentProvider) {
            throw new Error('No component provider set on engine component factory');
        }
        return this.abstractComponentProvider[name];
    }

    getPlatformComponent(name) {
        if (!this.platformComponentProvider) {
            throw new Error('No platform component provider set on engine component factory');
        }
        return this.platformComponentProvider[name];
    }

    setAbstractComponentProvider(provider) {
        this.abstractComponentProvider = provider;
    }

    setPlatformComponentProvider(provider) {
        this.platformComponentProvider = provider;
    }

    getAbstractPropertyComponent(name) {
        if (!this.abstractPropertyComponentProvider) {
            throw new Error('No abstract property component provider set on engine component factory');
        }
        return this.abstractPropertyComponentProvider[name];
    }

    getPlatformPropertyComponent(name) {
        if (!this.platformPropertyComponentProvider) {
            throw new Error('No platform property component provider set on engine component factory');
        }
        return this.platformPropertyComponentProvider[name];
    }

    setAbstractPropertyComponentProvider(provider) {
        this.abstractPropertyComponentProvider = provider;
    }

    setPlatformPropertyComponentProvider(provider) {
        this.platformPropertyComponentProvider = provider;
    }

    getPlatformDefaultFlexDirection() {
        return this.platformComponentProvider.PLATFORM_DEFAULT_FLEX_DIRECTION;
    }

    getPlatformFormOverflowPolicy() {
        return this.platformComponentProvider.PLATFORM_FORM_OVERFLOW_POLICY;
    }

    getPlatformSaveConfirmationPolicy() {
        return this.platformComponentProvider.PLATFORM_SAVE_CONFIRMATION_POLICY;
    }
}

const componentFactory = new ComponentFactory();
export default componentFactory;
