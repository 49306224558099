import React, { Fragment } from 'react';
import * as PropTypes from 'prop-types';
import getStyles from './GridForm.styles';

const GridForm = (props) => {
    const {
        contextStyles,
        formElements,
        scrollIdentifier,
    } = props;
    const { column, container } = getStyles(contextStyles);
    return (
        <div
            className="l-grid-form_container"
            data-scroll-identifier={ scrollIdentifier }
            style={ container }>
            <div 
                className="l-grid-form_column"
                style={ column }>
                { formElements.map((formElement, index) => <Fragment key={ `Grid_form_${index}` }>{ formElement }</Fragment>) }
            </div>
        </div>
    );
};

GridForm.propTypes = {
    contextStyles: PropTypes.shape({
        column: PropTypes.object,
        container: PropTypes.object,
    }),
    formElements:  PropTypes.arrayOf(PropTypes.element),
    scrollIdentifier: PropTypes.string,
};

GridForm.defaultProps = {
    contextStyles: {},
    formElements: [],
};

export default GridForm;
