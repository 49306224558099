import { Catavolt, cryptoUtil } from 'cv-dialog-sdk';
import { action, computed, configure, observable, toJS } from 'mobx';

import { constants } from '../constants';
import dialogComponentFactory from '../provider/dialogComponentFactory';
import serviceFactory from '../services/serviceFactory';
import WorkbenchStore from './WorkbenchStore';

configure({ enforceActions: 'observed' });

const secretKey = 'ab062d7d!f19fx4cc5*9445%3a2f134bf470';

// TODO: Need to refactor how we are handling use credentials. We are getting too much logic in this store for users
// Idea is to make a serveice to help administor user data and features.
export default class SessionStore {
    static SESSION_TIMEOUT_LIMIT = 60000 * 60;

    @observable.ref session = null; // Catavolt Session
    @observable credentials = new Map();
    @observable.ref selectedWorkbenchStore = new WorkbenchStore(this);
    @observable.shallow openDialogStoresMap = new Map();

    // used to maintain the changeCredentials ( current, new, re-new password fields)
    @observable changeCredentials = new Map();

    // used to maintian the Password expired condition when login fails because of password expired
    @observable passwordExpired = false;

    // used to maintian the Password expiries in X Days condition when login and session has changePasswordMessage property
    @observable passwordExpiryInXDays = false;

    /**
     * used to maintian the manage prompt windows on login page, where which prompt window to show
     * ( whether the first prompt where it asks user do you want change ? yes/No or to show second prompt when user selects yes in first prompt).
     */
    @observable changePasswordPrompt = false;

    /** Holds the oauth token info when new workbench oauth 2.0 workflow is performed */
    oAuthToken = null;

    /** Maps search dialog ids with list dialog ids */
    searchDialogIds = new Map();

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.cacheStore = this.rootStore.cacheStore;
    }

    @computed get loginSalt() {
        return null; // TODO: Come back and uncomment when engine moved. env.SALT_TEST && false ? utils.camelCase(sample) : null;
    }

    /**
     * Method to validate whether user logged in and
     * also if logged in whether his/her account is in Password expiry in X Days scenario.
     */

     isLoggedIn = () => {
         return (Catavolt.isLoggedIn && !this.passwordExpiryInXDays);
     };

    preLogin = (deviceProps) => {
        Object.keys(deviceProps).forEach((key) => {
            if (typeof deviceProps[key] === 'function') {
                Catavolt.addDynamicDeviceProp(key, deviceProps[key]);
            } else {
                Catavolt.addStaticDeviceProp(key, deviceProps[key]);
            }
        });
    };

    login = async (tenantId, deviceProps, clientType = 'MOBILE') => {
        // Verify before each login that the password has not expired.
        if (this.hasActiveUserPasswordExpired(this.getActiveUserExpirationDate())) {
            this.clearPasswordContent();
            throw Error(serviceFactory.lang.errors.passwordHasExpired);
        }

        this.preLogin(deviceProps);
        this.setActiveUserValue(constants.session.TENANT_ID, tenantId);
        return Catavolt.login(
            tenantId,
            clientType,
            this.credentials.get(constants.session.USER_ID).trimRight(),
            this.credentials.get(constants.session.PASSWORD),
        ).then(session => {
            return this.postLogin(session);
        });
    };

    loginWithSessionToken = (permissionToken, proofKey, tenantId, deviceProps, clientType = 'MOBILE') => {
        this.preLogin(deviceProps);
        this.setActiveUserValue(constants.session.TENANT_ID, tenantId);
        return Catavolt.loginWithToken(
            tenantId,
            clientType,
            permissionToken,
            proofKey,
        ).then(session => {
            return this.postLogin(session);
        });
    };

    /**
     * Method to handle the ChangePassword and create the new session when user Password is expired
     */
    changePasswordAndCreateSession = async (tenantId, deviceProps, clientType = 'MOBILE') => {
        this.preLogin(deviceProps);
        this.setActiveUserValue(constants.session.TENANT_ID, tenantId);
        return Catavolt.changePasswordAndLogin(
            tenantId,
            clientType,
            this.credentials.get(constants.session.USER_ID),
            this.changeCredentials.get(constants.session.CURRENT_PASSWORD),
            this.changeCredentials.get(constants.session.NEW_PASSWORD),
        ).then(session => {
            return this.postLogin(session);
        });
    };

    /**
     * Method to handle the Change Password when User's account expires in X days
     */
    changePassword = async () => {
        return Catavolt.changePassword(
            this.changeCredentials.get(constants.session.CURRENT_PASSWORD),
            this.changeCredentials.get(constants.session.NEW_PASSWORD),
        ).then(result => {
            return result;
        });
    };

    async applyTenantCapabilities(tenantId, clientType = 'MOBILE') {
        return this.getTenantCapabilities(tenantId, clientType).then((tenantCapabilities) => {
            const { themeStore, settingsStore } = this.rootStore;
            themeStore.processTenantBrandingThemeData(tenantCapabilities, tenantId);
            settingsStore.setValue(constants.settings.TENANT_CAPABILITIES, tenantCapabilities);
            return tenantCapabilities;
        });
    }

    async getTenantCapabilities(tenantId, clientType = 'MOBILE') {
        if (!tenantId || tenantId.trim().length === 0) return {};
        return Catavolt.retrieveTenantCapabilities(tenantId, clientType).then((tenantCapabilities) => {
            return tenantCapabilities;
        });
    }

    @action postLogin = (session, fromReresh = false) => {
        this.saveActiveUserCredentials();
        this.setSession(session);

        /** Checking for the session and is it password expiry in X days, then accordinlgy
         * set the password expiry observable in sessionstore to handle in loginpage.
         * And using fromRefresh argument to differentiate whether it is from Refershsession call or not.
         */
        if (session && this.session.changePasswordMessage && !fromReresh) {
            this.setPasswordExpiryInXDays(true);
        }

        const { themeStore, settingsStore } = this.rootStore;
        themeStore.processTenantLoginThemeData(this.session);
        const noSaveRequiredActionsString = session.tenantProperties.noSaveRequiredActions;
        const noSaveRequiredActions = noSaveRequiredActionsString ? noSaveRequiredActionsString.split(' ') : [];
        settingsStore.setValue(constants.settings.NO_SAVE_REQUIRED_ACTIONS, noSaveRequiredActions);

        return session;
    }

    logout = () => {
        // Modified the logic to handle the sessiontimeout issue ( tenantproperties, session error) when multiple tabs are opened.
        if (this.session) {
            const { tenantId } = this.session;
            this.setSession(undefined, true);
            return this.applyTenantCapabilities(tenantId).then(() => {
                return this.clearLogout();
            }).catch(() => {
                return this.clearLogout();
            });
        }
        return Promise.resolve();
    };

    clearLogout = () => {
        // Caching gets handled with calling setSession with undefined on previous statement.
        // Just need to clear the selected workbench.
        this.setSelectedWorkbench(undefined, false);
        this.openDialogStoresMap.clear();

        // User has to preset when logged out so that it had not been modified/viewed.
        this.credentials.set(constants.session.IS_DIRTY, false);

        this.searchDialogIds.clear();

        return Catavolt.logout();
    }

    @action destroyDialogStore = (dialogId, isExplicitDialogDestroy = false) => {
        const dialogStore = this.getOpenDialogStore(dialogId);
        if (dialogStore) {
            return dialogStore.destroy(isExplicitDialogDestroy).then(() => {
                this.removeOpenDialogStore(dialogStore.dialog.id);
            });
        }
        return Promise.resolve();
    };

    getOpenDialogStore = (dialogId) => {
        return this.openDialogStoresMap.get(dialogId);
    };

    /**
     * @param dialogId
     * @returns {Promise<Dialog>}
     */
    @action getOrOpenDialogStore = (dialogId) => {
        if (!this.openDialogStoresMap.has(dialogId)) {
            return this.openOrRefreshDialogStore(dialogId);
        }
        return Promise.resolve(this.getOpenDialogStore(dialogId));
    };

    /**
     * @param dialogId
     * @returns {Promise<Dialog>}
     */
    @action openOrRefreshDialogStore = (dialogId) => {
        let dialogStore = this.openDialogStoresMap.get(dialogId);
        // be careful here as this is an async callback which is NOT tracked by the @action on this method
        // changes to state should be done through other methods marked with @action
        return Catavolt.openDialogWithId(dialogId)
            .then((dialog) => {
                dialogStore = dialogStore || dialogComponentFactory.getDialogStoreInstance(dialog, this);
                dialogStore.setDialog(dialog);
                this.addOpenDialogStore(dialogId, dialogStore);
                return dialogStore;
            });
    };

    @action updateDialogStore = (dialog) => {
        const dialogStore = dialogComponentFactory.getDialogStoreInstance(dialog, this);
        dialogStore.setDialog(dialog);
        this.addOpenDialogStore(dialog.id, dialogStore);
        return dialogStore;
    }

    @action addOpenDialogStore(dialogId, dialogStore, cacheData = true) {
        this.openDialogStoresMap.set(dialogId, dialogStore);
        if (cacheData) {
            this.cacheStore.setValueForCacheType(this.cacheStore.cacheStores.SESSION, constants.storage.OPEN_DIALOG_IDS, toJS([ ...this.openDialogStoresMap.keys() ]));
        }
    }

    getSearchDialogKey(dialogId, selectedViewId) {
        return `${dialogId}-${selectedViewId}`;
    }

    getSearchDialogId(dialogId, selectedViewId) {
        return this.searchDialogIds.get(this.getSearchDialogKey(dialogId, selectedViewId));
    }

    setSearchDialogId(dialogId, selectedViewId, searchDialogId) {
        this.searchDialogIds.set(this.getSearchDialogKey(dialogId, selectedViewId), searchDialogId);
    }

    clearData(dialogStore) {
        if (dialogStore && dialogStore.clearData) {
            dialogStore.clearData();
        }
    }

    @action removeOpenDialogStore(rootDialogId, cacheData = true) {
        this.getOrOpenDialogStore(rootDialogId).then(dialogStore => {
            const childDialogIds = dialogStore.childDialogStores.map(childDialogStore => {
                const childDialogId = childDialogStore.dialog.id;
                const searchFormDialogStore = childDialogStore.searchDialogFormStore;
                if (searchFormDialogStore) {
                    const searchDialogId = searchFormDialogStore.dialog.id;
                    this.openDialogStoresMap.delete(searchDialogId);
                    this.clearData(searchFormDialogStore);
                }
                this.clearData(childDialogStore);
                return childDialogId;
            });
            this.clearData(dialogStore);
            this.openDialogStoresMap.delete(rootDialogId);
            // clean up additional cached items that do not need to persist between navigations
            const { uiStore } = this.rootStore;
            uiStore.removeValueForUIObject(rootDialogId, constants.ui.SALT_EDITOR_VISIBLE_DIALOG_ID);
            childDialogIds.forEach(childDialogId => uiStore.removeValueForUIObject(childDialogId, constants.ui.SALT_EDITOR_PROPS));
            if (this.session) {
                if (cacheData) {
                    this.cacheStore.setValueForCacheType(this.cacheStore.cacheStores.SESSION, constants.storage.OPEN_DIALOG_IDS, toJS([ ...this.openDialogStoresMap.keys() ]));
                }
            }
        });
    }

    @action setSelectedWorkbench = (workbench, cacheData = true) => {
        this.selectedWorkbenchStore.setWorkbench(workbench, cacheData);
    };

    setSelectedWorkbenchById = (workbenchId, cacheData = true) => {
        const { workbench } = this.selectedWorkbenchStore;
        if (workbench && workbenchId === workbench.id) return;
        const selectedWorkbench = this.workbenches.find(workbenchF => workbenchF.id === workbenchId);
        this.setSelectedWorkbench(selectedWorkbench, cacheData);
    };

    @action setSession = (session, cacheData = true) => {
        this.session = session;
        if (cacheData) {
            // TODO: This object data needs to be encrypted
            this.cacheStore.setCacheType(this.cacheStore.cacheStores.SESSION, session);
        }
    };

    /**
     * passwordExpired property is used to maintain the Password Expired scenario when user login fails.
     */
    @action setPasswordExpired = (expired = false) => {
        this.passwordExpired = expired;
    };

    getPasswordExpired = () => {
        return this.passwordExpired;
    };

    /**
     * passwordExpiryInXDays property is used to maintain the Password expiries in X Days condition when user logins and session has changePasswordMessage property.
     */
    @action setPasswordExpiryInXDays = (expiry = false) => {
        this.passwordExpiryInXDays = expiry;
    };

    getPasswordExpiryInXDays = () => {
        return this.passwordExpiryInXDays;
    };

    /**
     * changePasswordPrompt property is used to maintain and manage prompt windows on login page, on which prompt window to show
     * ( whether the first prompt where it asks user do you want change ? yes/No or to show second prompt when user selects yes in first prompt).
     */
    @action setChangePasswordPrompt = (show = false) => {
        this.changePasswordPrompt = show;
    };

    getChangePasswordPrompt = () => {
        return this.changePasswordPrompt;
    };

    /**
     * this method is used return the session.changePasswordMessage if it available, to show title in PasswordExpiryPrompt component.
     */
    getPasswordExpriyXDaysFromSession = () => {
        if (!this.session) {
            return true;
        }

        const { changePasswordMessage } = this.session;
        if (!changePasswordMessage) {
            return false;
        }

        return changePasswordMessage;
    }


    /**
     * This function will set active user values based on key provided.
     * @param key
     * @param value
     */
    @action setActiveUserValue = (key, value) => {
        if (!key) return;

        if (!value) {
            this.credentials.set(key, '');
        } else {
            this.credentials.set(key, value);
        }
        this.credentials.set(constants.session.IS_DIRTY, true);
    }

    /**
     * This function will set Change Credentials values based on key provided.
     * @param key
     * @param value
     */
    @action setActiveUserChangePasswordValue = (key, value) => {
        if (!key) return;

        if (!value) {
            this.changeCredentials.set(key, '');
        } else {
            this.changeCredentials.set(key, value);
        }
        this.changeCredentials.set(constants.session.IS_DIRTY, true);
    }

    /**
     * Function to determine if the passed in password expiration date is greater than now.
     * @param savedDate - Date to check
     * @return {Boolean} - Has expired
     */
    hasActiveUserPasswordExpired = (savedDate) => {
        if (!savedDate || Date.parse(savedDate) === 0) return false;
        return new Date(Date.now()) > new Date(savedDate);
    }

    decryptCreds = (userCreds) => {
        if (userCreds) {
            userCreds.password = cryptoUtil.decrypt(userCreds.password, secretKey); // eslint-disable-line
        }
        return userCreds;
    }

    /**
     * Function that determines what the users expiration date should be.
     * @return {Date} - Expiration Date
     */
    getActiveUserExpirationDate = () => {
        const { settingsStore } = this.rootStore;
        const userCreds = this.decryptCreds(settingsStore.getValue(constants.storage.USER_CREDS));
        // If the password didn't change just get the existing expiration date
        if (userCreds && this.credentials.get(constants.session.PASSWORD).localeCompare(userCreds[constants.session.PASSWORD]) === 0) {
            return this.credentials.get(constants.session.PASSWORD_EXPIRATION);
        }
        // Create a new expiration date that is 14 days out.
        const date = new Date(Date.now());
        date.setDate(date.getDate() + 14);
        return date.toISOString();
    }

    /**
     * This function will setup the active user based on tenant id provided
     * @param tenantId
     */
    @action setActiveUserByTenantId = (tenantId) => {
        if (!tenantId) return;

        // Did tenantId change for the active user. If the active user has no tenant id
        // we know the user has never been set and this is the first time in so it is not a
        // new tenant id
        if (this.credentials.get(constants.session.TENANT_ID) !== tenantId && this.credentials.get(constants.session.TENANT_ID) !== '') {
            this.credentials.clear();
            const { settingsStore } = this.rootStore;
            settingsStore.setValue(constants.session.TENANT_ID, tenantId);
            // Check and see if we have saved credentials
            const userCreds = this.decryptCreds(settingsStore.getValue(constants.storage.USER_CREDS));
            if (userCreds) {
                this.setUserCredentials(userCreds);
            }
        }

        // Set the active tenat id
        this.setActiveUserValue(constants.session.TENANT_ID, tenantId);

        // If password has expired clear the user object password so it isn't displayed
        if (this.hasActiveUserPasswordExpired(this.credentials.get(constants.session.PASSWORD_EXPIRATION))) {
            this.clearPasswordContent();
        }

        // User has be preset from cache so it has not been modified.
        this.credentials.set(constants.session.IS_DIRTY, false);
    }

    /**
     * This function will save and set the active user credentials based on Active User.
     */
    @action saveActiveUserCredentials = () => {
        // If save password isn't checked don't save the password.
        const savePassword = this.credentials.get(constants.session.SAVE_PASSWORD);
        const password = savePassword ? this.credentials.get(constants.session.PASSWORD) : '';

        this.credentials.set(constants.session.PASSWORD, password);

        // Never save show password as true
        this.credentials.set(constants.session.SHOW_PASSWORD, false);

        // Get the password experation date
        const passwordExpirationDate = savePassword ? this.getActiveUserExpirationDate() : new Date(Date.now());
        this.credentials.set(constants.session.PASSWORD_EXPIRATION, passwordExpirationDate);

        // Save credentials to local cache
        const { settingsStore } = this.rootStore;
        const sanitizedCredentials = this.getSanitizedCredentials();
        // Encrypt password before storing in localStorage
        sanitizedCredentials.password = cryptoUtil.encrypt(sanitizedCredentials.password, secretKey);
        settingsStore.setValue(constants.storage.USER_CREDS, sanitizedCredentials);
    }

    /**
     * This function will save and set the active user credentials based on Active User.
     * @param creds - object containing possible credentials.
     */
    @action setUserCredentials = (creds) => {
        if (!creds) return;

        // Clone to prevent original data from becoming observable
        Object.keys(creds).forEach((key) => {
            this.credentials.set(key, creds[key]);
        });
    };

    /**
     * @param contentId
     * @param streamConsumer ({done:boolean, value:any}) => void
     * @returns {Promise<LargeProperty>}
     */
    streamContent = (contentId, streamConsumer) => {
        return Catavolt.streamContent(contentId, streamConsumer);
    };

    @computed get workbenches() {
        // Filter empty workbenches out of the list.  The only exception is if there are no
        // workbenches with non-empty launchers.  In that singular case, return the workbench
        // list as is.
        if (!this.session) {
            return [];
        }

        const { appWindow } = this.session;
        if (!appWindow) {
            return [];
        }

        const { workbenches } = this.session.appWindow;
        if (!workbenches.find(f => f.actions.length)) {
            return workbenches; // All workbenches have no launchers.
        }
        return workbenches.filter(f => f.actions.length); // Filter empty workbenches out of list
    }

    @computed get initialAction() {
        return this.session ? this.session.appWindow.initialAction : undefined;
    }

    @computed get notificationsAction() {
        return this.session ? this.session.appWindow.notificationsAction : undefined;
    }


    // not yet implemented
    // This is for restoring a session from local persistence
    // The sdk handles the session expiration with a callback (see loginController.js login())
    checkSessionExpired = () => {
        return false;
    };

    // This is for restoring a session from local persistence
    // The sdk handles the session expiration with a callback (see loginController.js login())
    async refreshSession(tenantID = this.session.tenantId, sessionId = this.session.id) {
        return Catavolt.refreshSession(tenantID, sessionId).then((session) => {
            return this.postLogin(session, true);
        });
    }

    async performDeepLink(deeplinkingID) {
        return Catavolt.performDeepLink(this.session.tenantId, this.session.id, deeplinkingID).then((dialogOrRedirection) => {
            return dialogOrRedirection;
        });
    }

    clearAll = () => {
        // Clean up observables
        this.setSession(undefined, false);
        this.setPasswordExpired(false);
        this.setPasswordExpiryInXDays(null);
        this.setUserCredentials(undefined, false);
        this.setSelectedWorkbench(undefined, false);
        this.openDialogStoresMap.clear();
        this.credentials.clear();
        this.changeCredentials.clear();
        const { settingsStore } = this.rootStore;
        settingsStore.clearAllSettings();

        // Clean up local storage;
        this.cacheStore.clearCache();
    };

    getTenantLevelGML = () => {
        // TODO This should be retrieved by a pre-login call, not the tenant properties.
        // The pre-login call will be coming soon.
        if (!this.session || !this.session.tenantProperties) return '';
        return this.session.tenantProperties.GMLDefaults || '';
    }

    getGMLAssetsURL = () => {
        return this.session.tenantProperties.GMLAssetsURL || 'https:\\\\'; // Should always have a value
    }

    getNoSaveRequiredActions = () => {
    }

    getSanitizedCredentials = () => {
        return toJS(this.credentials);
    };

    getSanitizedSession = () => {
        return toJS(this.session);
    };

    /**
     * Clear password information and save.
     */
    clearPasswordContent = () => {
        this.setActiveUserValue(constants.session.PASSWORD, '');
        this.setActiveUserValue(constants.session.SAVE_PASSWORD, false);
        this.saveActiveUserCredentials();
    }

    restoreFromCache = (cacheData) => {
        const userCreds = this.decryptCreds(cacheData.settings[constants.storage.USER_CREDS]);
        if (userCreds) {
            this.setUserCredentials(userCreds, false);
        }
        this.setSession(cacheData.session, false);
        return cacheData;
    };

    setOAuthToken = (oauthToken) => {
        this.oAuthToken = oauthToken;
    }

    getOAuthToken = () => {
        return this.oAuthToken;
    }
}
