import React, { Fragment } from 'react';
import * as PropTypes from 'prop-types';
import { serviceFactory, utilities, engineConstants, constants } from 'cv-react-core';

import RWSaltComponent from './RWSaltComponent';
import Grid, { GridHeaderCell, GridActivityIndicator, GridCell, GridEditor, GridSelectionTray, SpacerCell } from '../components/base/Grid';
import CvContextMenu from '../components/menu/CvContextMenu';
import MenuItem from '../components/base/MenuItem';

const { annotationHelper } = utilities;

class RWMaintainableList extends RWSaltComponent {
    static propTypes = {
        availableMenuItems: PropTypes.arrayOf(PropTypes.shape({
            icon: PropTypes.string,
            id: PropTypes.string,
            menuText: PropTypes.string,
        })),
        cellStyle: PropTypes.object,
        columns: PropTypes.array,
        firstVisibleColumnIndex: PropTypes.number,
        hasMoreRecords: PropTypes.bool,
        isLoading: PropTypes.func,
        loadedZeroRecords: PropTypes.bool,
        onChooseItems: PropTypes.func,
        onChooseNone: PropTypes.func,
        onMenuAction: PropTypes.func,
        onPaste: PropTypes.func,
        onRenderItem: PropTypes.func,
        onRequestMore: PropTypes.func,
        onSave: PropTypes.func,
        onSelectRange: PropTypes.func,
        records: PropTypes.array,
        selectedRecords: PropTypes.object,
        xMenu: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
        })),
    };

    constructor(props) {
        super(props);
        const {
            availableMenuItems,
            xMenu,
            onMenuAction,
        } = props;

        const { lang } = serviceFactory;
        const extendedMenu = xMenu.find((f) => (f.id === engineConstants.action.clientActions.copyToClipboard));
        this.extendedMenuItems = [ ...availableMenuItems ];

        if (extendedMenu) {
            this.extendedMenuItems.push({
                ...extendedMenu,
                menuText: lang.list.copyToClipboard,
            });
        }
        this.menuItems = this.extendedMenuItems.map((item) => (
            <MenuItem
                key={ `${item.id}${item.menuText}` }
                id={ item.id }
                onClick={ () => {
                    if (onMenuAction) {
                        onMenuAction(item);
                    }
                } }
                text={ item.menuText } />
        ));
        this.menuRef = React.createRef();
        this.gridRef = React.createRef();
    }

    render() {
        const {
            alignedGrids,
            columns,
            firstVisibleColumnIndex,
            hasMoreRecords,
            isDataViewStyle,
            isLoading,
            lastRecordSelected,
            lastSection,
            loadedZeroRecords,
            onRequestMore,
            records,
            selectedRecordCount,
            selectedRecords,
            suppressHeading,
        } = this.props;
        const { lang } = serviceFactory;
        const gridProps = {
            alignedGrids,
            autoSizeColumns: isDataViewStyle,
            contextStyles: this.getContextStyles(),
            columns: this.getColumnData(columns),
            localeText: { noRowsToShow: loadedZeroRecords ? lang.list.noRecords : ' ' },
            getRowStyle: this.getRowStyle,
            onCellClick: this.handleCellEditing,
            onCellDoubleClick: this.handleCellEditing,
            onCellEditingStart: this.handleCellEditing,
            onCellEditingStop: this.handleCellEditing,
            onContextMenu: this.handleContextMenu,
            onCopy: this.handleCopy,
            onEnter: this.handleEnter,
            onPaste: this.handlePaste,
            onRowDataUpdated: this.handleRowDataUpdated,
            rows: this.getRowData(records),
            ref: this.gridRef,
            selectedRecords,
        };

        if (selectedRecordCount) gridProps.firstVisibleRow = records.findIndex((record) => record.id === lastRecordSelected);
        if (isDataViewStyle) {
            gridProps.onDelete = this.handleDelete;
            gridProps.onFirstDataRendered = this.handleFirstDataRendered;
            if (firstVisibleColumnIndex) gridProps.firstVisibleColumn = this.getColId(firstVisibleColumnIndex);
        }
        else {
            gridProps.domLayout = 'autoHeight';
        }
        if (hasMoreRecords) gridProps.onRequestMore = onRequestMore;
        if (lastSection) {
            gridProps.gridActivityIndicator = <GridActivityIndicator isLoading={ isLoading } />;
            gridProps.scrollbarWidth = 12;
            gridProps.suppressHorizontalScroll = false;
        }
        if (suppressHeading) gridProps.headerHeight = 0;

        return (
            <Fragment>
                <Grid { ...gridProps } />
                <CvContextMenu ref={ this.menuRef }>
                    { this.menuItems }
                </CvContextMenu>
                { this.renderSelectionTray() }
                { this.renderErrorTray() }
            </Fragment>
        );
    }

    getColId = (index) => {
        const { colIdPrefix } = this.props;
        return `${colIdPrefix}_${index}`;
    }

    getColumnData = (columns) => {
        const {
            cellStyle,
            doubleClickForDefaultAction,
            hiddenColumnIndices,
            onRenderItem,
            pinnedColumnIndices,
            records,
        } = this.props;
        const newCellStyle = {
            textOverflow: 'ellipsis',
            ...cellStyle,
        };
        const columnDefinitions = [];
        const defaultColumnDefinition = {
            cellRendererFramework: GridCell,
            cellRendererParams: { cellStyle: newCellStyle, onRenderItem, records },
            cellEditorFramework: GridEditor,
            cellEditorParams: { cellStyle: newCellStyle, onRenderItem, records },
            headerComponentFramework: GridHeaderCell,
            // headerComponentParams: { getHeaderProps, onSortColumn },
        };
        const isColumnVisible = this.isColumnVisible();
        columns.forEach((column, index) => {
            const {
                defaultActionId,
                heading,
                headingGroup,
                maintainable,
                propertyName = '',
                spacer,
            } = column;
            const colId = this.getColId(index);
            const pinned = pinnedColumnIndices.includes(index);

            const columnDefinition = {
                ...defaultColumnDefinition,
                headerName: heading,
                hide: hiddenColumnIndices.includes(index) || !(isColumnVisible(colId)),
                field: propertyName,
                lockVisible: spacer || pinned,
                suppressMovable: !!spacer,
                editable: maintainable,
                colId,
                pinned,
                singleClickEdit: !doubleClickForDefaultAction && !defaultActionId,
            };

            if (spacer) {
                columnDefinition.cellRendererFramework = SpacerCell;
                columnDefinition.headerComponentFramework = SpacerCell;
                columnDefinition.width = 20;
            }
            if (headingGroup) {
                const headerDef = columnDefinitions.find((colDef) => colDef.headerName === headingGroup);
                if (headerDef) {
                    headerDef.children.push(columnDefinition);
                }
                else {
                    columnDefinitions.push({
                        children: [ columnDefinition ],
                        headerGroupComponentFramework: GridHeaderCell,
                        headerName: headingGroup,
                    });
                }
            }
            else {
                columnDefinitions.push(columnDefinition);
            }
        });
        return columnDefinitions;
    };

    getContextStyles = () => {
        const { spacerLines } = this.props;
        return { container: { marginBottom: `${spacerLines * 10}px`} };
    }

    getNextColumn = (currentColId) => {
        let nextColumn = this.gridRef.current.getDisplayedColAfter(currentColId);

        if (nextColumn) {
            const { colDef: { field, colId } } = nextColumn;
            if (!field) {
                // It implies a spacer cell. So, get the next column after spacer cell.
                nextColumn = this.getNextColumn(colId);
            }
        }
        return nextColumn;
    }

    getNextMaintainableColumn = (currentColId) => {
        let nextColumn = this.getNextColumn(currentColId);

        if (nextColumn) {
            const { colDef: { editable, colId } } = nextColumn;
            if (!editable) {
                nextColumn = this.getNextMaintainableColumn(colId);
            }
        }
        return nextColumn;
    }

    getRowData = (rows) => rows.map((record, idx) => {
        const {
            id,
            properties,
        } = record;
        const row = { id: id || idx };

        properties.forEach((property) => {
            const {
                name,
                value,
            } = property;
            row[name] = value;
        });

        return row;
    });

    getRowStyle = (params, baseStyles) => {
        const {
            errorRecordIds,
            records,
            selectedRecords,
        } = this.props;
        const {
            rowErrorStyles,
            rowSelectedStyles,
            rowStyles,
        } = baseStyles;
        const { alternatingColors } = rowStyles;
        const { node: { rowIndex, id } } = params;
        const record = records[rowIndex];
        const currentRowIndex = records.findIndex((rec) => rec.id === id);
        const colors = alternatingColors && alternatingColors.split(',').map((m) => m.trim()) || '';
        let styles = {
            ...rowStyles,
        };

        if (selectedRecords[id]) {
            styles = {
                ...styles,
                ...rowSelectedStyles,
            };
        }
        else {
            const colorIndex = currentRowIndex % colors.length;
            styles.backgroundColor = colors[colorIndex];
            styles = annotationHelper.getBackgroundAsStyle(record, null, styles);
        }

        if (errorRecordIds && errorRecordIds.includes(id)) {
            styles = {
                ...styles,
                ...rowErrorStyles,
            };
        }
        return styles;
    }

    handleCellEditing = (nativeEvent) => {
        const {
            doubleClickForDefaultAction,
            onChooseItem,
        } = this.props;
        const {
            colDef: { field },
            column,
            event,
            node: { id },
            rowIndex,
            type,
        } = nativeEvent;

        if (type === 'cellEditingStarted') {
            this.cellEditing = { rowIndex };
        }

        // Below case is for - When the user clicks off the row to another row it is an implied Enter.
        if (type === 'cellClicked' && this.cellEditing) {
            if (rowIndex !== this.cellEditing.rowIndex) {
                this.handleEnter();
            }
        }

        if (!this.cellEditing &&
            ((type === 'cellClicked' && !doubleClickForDefaultAction) ||
            (type === 'cellDoubleClicked' && doubleClickForDefaultAction))) {
            const modifiers = event.altKey ? { [constants.transitionModifiers.OPEN_IN_TAB]: true } : undefined;
            onChooseItem(id, false, 'DESKTOP', modifiers, field, true);
        }

        // Below case is for - When the user is in the last maintainable column in a row and presses tab,
        // An implicit enter should be performed to save all the data in that row
        if (type === 'cellEditingStopped') {
            const { colId } = column;
            const nextMaintainableColumn = this.getNextMaintainableColumn(colId);
            if (!nextMaintainableColumn) {
                this.handleEnter();
            }
        }
    }

    handleContextMenu = (clickEvent) => {
        const { event: { clientX, clientY }, node: { id } } = clickEvent;
        const {
            onSelectItem,
            isRecordSelected,
        } = this.props;

        if (onSelectItem && id) {
            if (isRecordSelected(id)) {
                onSelectItem(id, true, 'DESKTOP');
            }
            else {
                onSelectItem(id, false, 'DESKTOP');
            }
        }

        this.menuRef.current.show({ top: clientY, left: clientX });
    };

    handleCopy = (range, isCutAction) => {
        const {
            onSelectRange,
            records,
        } = this.props;

        if (!range[0]) {
            const { lang, notify } = serviceFactory;
            notify.showWarning(lang.list.cannotCopyOrPaste);
            return;
        };
        const {
            columns,
            endRow: { rowIndex: endRowIndex },
            startRow: { rowIndex: startRowIndex },
        } = range[0];
        const rows = {};
        const selectedRange = {
            columns: columns.map((column) => {
                const { colId, colDef: { editable, field } } = column;
                return {
                    colId,
                    editable,
                    propertyName: field,
                };
            }),
            isCutAction,
        };
        for (let i = Math.min(startRowIndex, endRowIndex); i <= Math.max(startRowIndex, endRowIndex); i += 1 ) {
            const record = records[i];
            if (record) {
                rows[record.id] = true;
            }
        }
        selectedRange.records = rows;

        if (onSelectRange) {
            onSelectRange(selectedRange);
        }
    }

    handleDelete = (range) => {
        const {
            onPaste,
            onPasteError,
            records,
        } = this.props;
        const { lang } = serviceFactory;
        const recordsToBeDeleted = [];
        const {
            columns,
            endRow: { rowIndex: endRowIndex },
            startRow: { rowIndex: startRowIndex },
        } = range[0];

        for (let i = Math.min(startRowIndex, endRowIndex); i <= Math.max(startRowIndex, endRowIndex); i += 1 ) {
            const record = records[i];
            if (record) {
                const recordToBeDeleted = { id: record.id, properties: [] };
                columns.forEach((column) => {
                    const { colDef: { editable, field } } = column;
                    if (editable) {
                        recordToBeDeleted.properties.push({ propName: field, value: null });
                    }
                });

                if (recordToBeDeleted.properties.length) {
                    recordsToBeDeleted.push(recordToBeDeleted);
                }
            }
        }

        if (recordsToBeDeleted.length) {
            onPaste(recordsToBeDeleted);
        }
        else {
            onPasteError([ lang.list.cannotDelete ]);
        }
    }

    handleEnter = () => {
        const { onSave } = this.props;

        if (this.cellEditing && onSave) {
            this.cellEditing = null;
            onSave();
        }
    }

    handleFirstDataRendered = (gridContainer) => {
        // logic to update the minHeight of Box container to the Grid actual data container when minHeight of Box container is
        // more than grid container such that their is no empty space created on view.
        const actualGridDataBodyHeight = gridContainer.querySelector('.ag-body-viewport .ag-center-cols-clipper').offsetHeight;
        if (gridContainer.parentElement.offsetHeight > actualGridDataBodyHeight) {
            const container = gridContainer;
            container.parentElement.style.minHeight = `${actualGridDataBodyHeight}px`;
        }
    }

    handlePaste = (range) => {
        const { lang } = serviceFactory;
        const {
            getPasteValue,
            getSelectedRecord,
            onPaste,
            onPasteError,
            records,
            selectedRange,
        } = this.props;
        const {
            columns,
            endRow: { rowIndex: originalEndRowIndex },
            startRow: { rowIndex: originalStartRowIndex },
        } = range[0];
        const startRowIndex = Math.min(originalStartRowIndex, originalEndRowIndex);
        const startColumn = columns[0];
        // Don't allow paste when it starts on a non editable column.
        if (!startColumn.colDef.editable) {
            onPasteError([ lang.list.cannotPasteOnNonMaintainable ]);
            return;
        };
        const {
            columns: selectedColumns,
            isCutAction,
            records: selectedRecords,
        } = selectedRange || { records: {} };
        const modifiedRecords = [];
        let currentRowIndex = startRowIndex;
        const rowNodes = [];
        const columnNodes = [];
        const errors = [];
        Object.keys(selectedRecords).forEach((selectedRecordId, index) => {
            const selectedRecord = getSelectedRecord(selectedRecordId);
            const currentRecord = records[currentRowIndex];
            if (selectedRecord && currentRecord) {
                const recordToBeUpdated = { id: currentRecord.id, properties: [] };
                rowNodes.push(currentRowIndex);
                let currentColumn = startColumn;
                selectedColumns.forEach((column) => {
                    if (currentColumn) {
                        const { colId, colDef: { editable, field } } = currentColumn;
                        if (editable) {
                            try {
                                const {
                                    value,
                                    type,
                                    params,
                                } = getPasteValue(column.propertyName, field, selectedRecord);
                                recordToBeUpdated.properties.push({ propName: field, value, type, params });
                            }
                            catch (e) {
                                errors.push(e.message);
                            }
                        }
                        const nextColumn = this.getNextColumn(colId);
                        currentColumn = nextColumn;
                        if (index === 0 && editable) {
                            columnNodes.push(colId);
                        }
                    }
                });
                if (recordToBeUpdated.properties.length) {
                    modifiedRecords.push(recordToBeUpdated);
                }
            }
            if (isCutAction) {
                const recordToBeUpdatedForCut = { id: selectedRecord.id, properties: [] };
                selectedColumns.forEach((column) => {
                    if (column.editable) {
                        recordToBeUpdatedForCut.properties.push({ propName: column.propertyName, value: null });
                    }
                });
                if (recordToBeUpdatedForCut.properties.length) {
                    modifiedRecords.push(recordToBeUpdatedForCut);
                }
            }
            currentRowIndex += 1;
        });

        if (onPaste) {
            onPaste(modifiedRecords, isCutAction, errors);
            if (modifiedRecords.length) {
                this.gridRef.current.flashCells(rowNodes, columnNodes);
            }
        }
    }

    handleRowDataUpdated = () => {
        const {
            isDataViewStyle,
            onReFocus,
        } = this.props;
        const lastFocusedCell = this.gridRef.current.getLastFocusedCell();
        // Cell range is cleared, when row data is updated. Set the cell range from previously known focused cell.
        if (isDataViewStyle && lastFocusedCell) {
            const { column: { colId }, rowIndex } = lastFocusedCell;
            this.gridRef.current.addCellRange(rowIndex, rowIndex, colId, colId);
        }
        if (onReFocus) {
            onReFocus();
        }
    }

    handleRowSelectionClear = () => {
        const {
            onChooseNone,
        } = this.props;

        if (onChooseNone) {
            onChooseNone();
            this.gridRef.current.clearSelections();
        }
    };

    isColumnVisible = () => {
        const visibleColumns = (this.gridRef && this.gridRef.current && this.gridRef.current.getAllDisplayedColumns()) || [];
        return (colId) => {
            if (visibleColumns.length) {
                const visibleColumn = visibleColumns.find((col) => col.colId === colId);
                if (visibleColumn) {
                    return visibleColumn.visible;
                }
                return false;
            }
            return true;
        };
    };

    renderErrorTray = () => {
        const { errorRecordIds, onClearErrors } = this.props;
        if (!errorRecordIds || !errorRecordIds.length) return null;
        const { lang } = serviceFactory;
        const props = {
            clearTitle: 'Clear Errors',
            error: true,
            text: `${errorRecordIds.length} ${lang.list.failedToUpdate}`,
            onClear: onClearErrors,
        };

        return this.renderTray(props);
    }

    renderSelectionTray = () => {
        const { selectedRecordCount } = this.props;
        if (!selectedRecordCount) return null;
        const { lang } = serviceFactory;
        const props = {
            clearTitle: 'Clear Selections',
            text: `${selectedRecordCount} ${lang.list.selected}`,
            onClear: this.handleRowSelectionClear,
        };

        return this.renderTray(props);
    }

    renderTray = (props) => <GridSelectionTray { ...props } />;
}

export default RWMaintainableList;
