import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { InfoWindow } from '../MapsWrapper';

/**
 * Callout component allows a user to view content of markers on Google map.
 * @see https://github.com/fullstackreact/google-maps-react#infowindow
 * @see https://developers.google.com/maps/documentation/javascript/infowindows
 */
class MapCallout extends Component {
    render() {
        const {
            children,
            position,
            testID,
            marker,
            visible,
            onClose,
            onOpen,
            ...remainingProps
        } = this.props;

        const calloutProps = {
            position,
            marker,
            visible,
            onClose,
            onOpen,
            testID,
            ...remainingProps,
        };

        return (
            <InfoWindow
                ref={ (ref) => {
                    this.infoWindowRef = ref;
                } }
                { ...calloutProps }>
                { children }
            </InfoWindow>
        );
    }
}

MapCallout.propTypes = {
    /** Callout content */
    children: PropTypes.element.isRequired,

    /** Defines marker of callout */
    marker: PropTypes.object,

    /** Defines the position of callout */
    position: PropTypes.shape({
        /** Latitude of the point */
        lat: PropTypes.number,

        /** Longitude of the point */
        lng: PropTypes.number,
    }),

    /** Defines the visibility of callout */
    visible: PropTypes.bool,

    /** Called when callout is closed */
    onClose: PropTypes.func,

    /** Called when callout is open */
    onOpen: PropTypes.func,

    /** Id used for testing */
    testID: PropTypes.string,
};

MapCallout.defaultProps = {
};

export default MapCallout;