import React, { Component } from 'react';
import * as PropTypes from 'prop-types';

// Local components
import CvModal from '../components/base/SimpleModal';
import SearchSortForm from '../components/forms/SearchSortForm';

/**
 * Search component that is a PureComponent for handling search routines.
 */
class RWSearchSort extends Component {
    static propTypes = {
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        title: PropTypes.string,
        showSearch: PropTypes.bool,
        sortTerms: PropTypes.array,
        filterTerms: PropTypes.array,
        sortableValues: PropTypes.array,
        onSubmit: PropTypes.func,
        onClear: PropTypes.func,
        onCancel: PropTypes.func,
        onSearchTermChange: PropTypes.func,
        onSortClear: PropTypes.func,
        onSortTermsChange: PropTypes.func,
        onSetFilterOperation: PropTypes.func,
    };

    render() {
        const {
            showSearch,
            onCancel,
            ...restProps } = this.props;
        const contextStyles = {
            overflow: {
                top: '100px',
                alignItems: 'flex-start',
                justifyContent: 'center',
            },
            modal: {
                display: 'flex',
                flexDirection: 'row',
                maxWidth: '98%',
                maxHeight: '75%',
                minWidth: '35%',
                boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
                borderRadius: '6px',
            },
            modalColumnContainer: {
                width: '100%',
            },
        };
        const searchSortFormProps = {
            onCancel,
            ...restProps,
        };
        return (
            showSearch
            && (
                <CvModal
                    isTransparentOverlay
                    onPressOverlay={ onCancel }
                    contextStyles={ contextStyles }>
                    { React.createElement(SearchSortForm, { ...searchSortFormProps }) }
                </CvModal>
            )
        );
    }
}

export default RWSearchSort;