/* eslint-disable no-plusplus */
import { SaltDocument } from '../SaltDocument';

const vizTemplate = require('./viz_simple');

class VizTranspiler {
    generateSalt(dialog) {
        const document = new SaltDocument(vizTemplate);
        const viewEl = document.getByExpr('**.view');
        document.singleSelectNode(viewEl);
        // @TODO alias, viewAlias - we may want to allow salt to also reference user defined aliases
        const { view } = dialog;
        const viewId = SaltDocument.getViewId(view);
        document.setAttribute('id', viewId);
        return document.rootNode;
    }
}

const vizTranspiler = new VizTranspiler();
export default vizTranspiler;
