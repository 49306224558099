// Core component styles
const coreStyles = {
    container: {
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
    },

    image: {
    },

    loadingIndicator: {
    },

    text: {
    },
};

/**
 * Combine component context styles with core styles
 * @param {Object} contextStyles
 * @param {Boolean} reverse
 * @param {Boolean} stacked
 * @return {Object}
 */
const getStyles = (contextStyles = {}, reverse = false, stacked = false) => {
    const rev = reverse ? '-reverse' : '';

    // Assume stacked
    let marginLeft = 0;
    let marginRight = 0;
    if (!stacked) {
        marginLeft = reverse ? 8 : 0;
        marginRight = reverse ? 0 : 8;
    }

    return {
        container: {
            ...coreStyles.container,
            flexDirection: stacked ? `column${rev}` : `row${rev}`,
            justifyContent: !stacked && reverse ? 'flex-end' : 'flex-start',
            ...contextStyles.container,
        },

        text: {
            ...coreStyles.text,
            marginLeft,
            marginRight,
            ...contextStyles.text,
        },

        loadingIndicator: {
            ...coreStyles.loadingIndicator,
            ...contextStyles.loadingIndicator,
        },

        image: {
            ...coreStyles.image,
            ...contextStyles.image,
        },
    };
};

export default getStyles;
