import React, { forwardRef } from 'react';
import * as PropTypes from 'prop-types';
import MUIMenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { default as TextLabelWithImage } from '../TextLabelWithImage/TextLabelWithImage';

import getStyles from './MenuItem.styles';

// eslint-disable-next-line react/display-name
const MenuItem = forwardRef((props, ref) => {
    const {
        contextStyles,
        imageHeight,
        imageName,
        imageSrc,
        imageWidth,
        isTextWithImage,
        onClick,
        selected,
        testID,
        text,
        ...rest
    } = props;

    // Override core styles with context styles, separating MUI styles
    const {
        image: imageStyles,
        imageLoadingIndicator: imageLoadingIndicatorStyles,
        ...muiStyles
    } = getStyles(contextStyles);

    // Create dynamic class names and injected DOM styles for MUI component
    // * makeStyles returns a function
    const styles = makeStyles(muiStyles)();

    let menuText = text;
    if (isTextWithImage) {
        menuText = (
            <TextLabelWithImage
                contextStyles={ {
                    image: imageStyles,
                    loadingIndicator: imageLoadingIndicatorStyles,
                } }
                imageHeight={ imageHeight }
                imageWidth={ imageWidth }
                imageSrc={ imageSrc }
                reverse
                text={ imageName } />
        );
    }

    return (
        <MUIMenuItem
            classes={ {
                root: styles.container, // MUI styles
                selected: styles.selected, // MUI styles
            } }
            className="c-menu-item__container"
            data-test-id={ `${testID}__menu-item__container` }
            selected={ selected }
            onClick={ (event) => { onClick(event, props); } }
            ref={ ref }
            { ...rest }>
            { menuText }
        </MUIMenuItem>
    );
});

MenuItem.propTypes = {
    /** Styles for this component */
    contextStyles: PropTypes.shape({
        /** Styling for the item */
        container: PropTypes.object,

        /** Styles for the menu item image */
        image: PropTypes.object,

        /** Styles for loading indicator of image */
        imageLoadingIndicator: PropTypes.object,

        /** Styling for the selected item */
        selected: PropTypes.object,

        /** Styling for the focused item */
        focusContainer: PropTypes.object,
    }),

    /** Height of the image */
    imageHeight: PropTypes.number,

    /** Name of the image */
    imageName: PropTypes.string,

    /** The image source (either a remote URL or a local file resource) */
    imageSrc: PropTypes.string,

    /** Width of the image */
    imageWidth: PropTypes.number,

    /** Indicator to identify text with image in menu item */
    isTextWithImage: PropTypes.bool,

    /**
     * Called on click of the menu item
     * @param {Object} event - The native click event
     * @param {Object} props - The component instance props
     */
    onClick: PropTypes.func.isRequired,

    /** Enables selected styling */
    selected: PropTypes.bool,

    /** Id used for testing */
    testID: PropTypes.string,

    /** Menu item text */
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]).isRequired,
};

MenuItem.defaultProps = {
    contextStyles: {},
    testID: 'MenuItem',
};

export default MenuItem;
