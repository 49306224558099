import React from 'react';
import * as PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import getStyles from './Panel.styles';

const Panel = (props) => {
    const {
        children,
        contextStyles,
        header,
    } = props;
    const {
        container,
        content,
    } = getStyles(contextStyles);

    return (
        <Paper
            className="c-panel__container"
            style={ container }>
            { header }
            <div
                className="c-panel__content"
                style={ content }>
                { children }
            </div>
        </Paper>
    );
};

Panel.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]),
    contextStyles: PropTypes.shape({
        container: PropTypes.object,
        content: PropTypes.object,
    }),
    header: PropTypes.element,
};

Panel.defaultProps = {
    contextStyles: {},
};

export default Panel;
