import DialogStore from '../../stores/DialogStore';
import DetailsDialogStore from '../../stores/DetailsDialogStore';
import Expr from './Expr';

export default class RefUtil {
    static setGlobalRef(name, value, scopeManager) {
        if (name) {
            scopeManager.setGlobalRef(name, value);
        }
    }

    static getRef(name, expr, modelStore, scopeManager, saltStore) {
        if (expr) {
            return RefUtil.evaluateExpression(expr, modelStore, scopeManager, saltStore);
        }
        if (name) {
            return RefUtil.getRefByName(name, scopeManager);
        }
        return null;
    }

    static evaluateExpression(expr, modelStore, scopeManager, saltStore) {
        // @change this to use $dialog from accessors
        const rootObject = { modelStore, _: RefUtil.resolveRefType(modelStore), ref: scopeManager.allRefs, globals: saltStore.globals, scopeManager };
        const value = Expr.getValueForExpr(rootObject, expr);
        if (modelStore instanceof DetailsDialogStore && typeof value === 'string' && value.startsWith('${p_')) {
            const propertName = value.slice(0, -1).substring(4);
            const property = modelStore.getProperty(propertName);
            if (property) {
                return property.value;
            }
        }
        return value;
    }

    static getRefByName(name, scopeManager) {
        return scopeManager.getRef(name);
    }

    /*
        Careful, this returns true if there's no assertion
     */
    static evalAssertion(assertion, modelStore, scopeManager, saltStore) {
        return assertion ? RefUtil.getRef(assertion.refName, assertion.expr, modelStore, scopeManager, saltStore) : true;
    }

    static asRegEx(s) {
        if (s.startsWith('/')) {
            return s.substring(1, s.length - 1);
        }
        return s;
    }

    static getMatchesByTarget(patterns, targets) {
        return targets.reduce((prev, target) => {
            if (patterns.some((pattern) => {
                return target.match(RefUtil.asRegEx(pattern));
            })) {
                prev.push(target);
            }
            return prev;
        }, []);
    }

    static getMatchesByPattern(patterns, targets) {
        const results = new Set();
        patterns.forEach((pattern) => {
            targets.forEach((target) => {
                if (target.match(RefUtil.asRegEx(pattern))) {
                    results.add(target);
                }
            });
        });
        return [ ...results ];
    }

    static getValueForTranslation(name, modelStore, scopeManager) {
        const translations = scopeManager.getRef(`translations::${modelStore.dialog.id}`) || {};
        return translations[name];
    }

    // runs substitution on a single object or array
    // refs can be of the form { '$ref' : 'ref_name' } or { 'some_key' : '$ref("ref_name")' }
    // exprs can be of the form { '$expr' : 'some_exp' } or { 'some_key' : '$expr("some_expr")' }
    static getValueForSubstitution(params) {
        const { param, scopeManager, modelStore, saltStore } = params;
        if (param === null || param === undefined) {
            return undefined;
        }
        if (Array.isArray(param)) {
            return param.map(p => RefUtil.getValueForSubstitution({ ...params, param: p }));
        } if (typeof param === 'object') {
            let newParams = {};
            Object.keys(param)
                .forEach((key) => {
                    if (key === '$ref') {
                        newParams = {
                            ...newParams,
                            ...RefUtil.getValueForSubstitution({ ...params, param: RefUtil.getRefByName(param[key], scopeManager) }),
                        };
                    } else if (key === '$expr') {
                        newParams = {
                            ...newParams,
                            ...RefUtil.getValueForSubstitution({ ...params, param: RefUtil.evaluateExpression(param[key], modelStore, scopeManager, saltStore) }),
                        };
                    } else {
                        newParams[key] = RefUtil.getValueForSubstitution({ ...params, param: param[key] });
                    }
                });
            return newParams;
        } if (typeof param === 'string') {
            if (param.startsWith('$ref(')) {
                return RefUtil.getValueForSubstitution({ ...params, param: RefUtil.getRef(null, param, modelStore, scopeManager, saltStore) });
            }
            if (param.startsWith('$expr(')) {
                const expr = RefUtil.extractExpr(param);
                return RefUtil.getValueForSubstitution({ ...params, param: RefUtil.evaluateExpression(expr, modelStore, scopeManager, saltStore) });
            }
            if (param.startsWith('$translate(')) {
                const name = RefUtil.extractExpr(param);
                return RefUtil.getValueForSubstitution({ ...params, param: RefUtil.getValueForTranslation(name, modelStore, scopeManager) });
            }
            if (param.startsWith('$sessionValue(')) {
                return RefUtil.getValueForSubstitution({ ...params, param: RefUtil.evaluateExpression(param, modelStore, scopeManager, saltStore) });
            }
        }
        return param;
    }

    static extractExpr(exprString) {
        if (exprString.startsWith("$expr('") || exprString.startsWith('$expr("')) {
            return exprString.substring(7, exprString.length - 2);
        }
        if (exprString.startsWith("$translate('") || exprString.startsWith('$translate("')) {
            return exprString.substring(12, exprString.length - 2);
        }
        return exprString.substring(6, exprString.length - 1);
    }

    // runs substitution on value for each key and returns the replaced value
    static substituteValues(params, scopeManager, modelStore, saltStore) {
        const newParams = {};
        Object.keys(params)
            .forEach((key) => {
                newParams[key] = RefUtil.getValueForSubstitution({ param: params[key], scopeManager, modelStore, saltStore });
            });
        return newParams;
    }

    static resolveRefType(obj) {
        if (obj) {
            if (obj instanceof DialogStore) {
                return obj.dialog;
            }
        }
        return null;
    }
}
