const getStyles = () => {
    return {
        orderContainer: {
            display: 'flex',
            flexGrow: '1',
            justifyContent: 'space-evenly',
        },
        dropDownTitleWrapper: {
            flexDirection: 'column',
            alignContent: 'center',
            border: '1px solid #E0E0E0',
            borderRadius: '4px',
            padding: '8px',
            paddingLeft: '0',
        },
        dropDown: {
            paddingLeft: '8px',
        },
        title: {
            fontSize: '12px',
            color: '#000',
            paddingLeft: '8px',
        },
        sortingRow: {
            flexGrow: '1',
            marginBottom: '12px',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        directionLabel: {
            alignSelf: 'center',
        },
        iconStyle: {
            color: '#000',
        },
        option: {
            flexGrow: 1,
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 16,
            alignItems: 'center',
            letterSpacing: 0.02,
            color: '#121212',
            paddingLeft: 8,
            paddingRight: 8,
            borderStyle: 'solid',
            borderColor: 'rgb(224, 224, 224)',
            width: 180,
            borderWidth: 0,
            borderRadius: 0,
            marginRight: 0,
            display: 'flex',
            flexDirection: 'row',
            paddingTop: 13,
            paddingBottom: 13,
        },
        expandIcon: {
            color: '#000000',
        },
        optionSelected: {
            backgroundColor: 'rgba(28, 142, 169, 0.2)',
        },
        optionHover: {
            backgroundColor: '#F5F5F5',
        },
    };
};

export default getStyles;
