import { rootStore } from 'cv-react-core';

const getTheme = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    // Populate the component parts with theme styles
    return {
        container: {
        },

        bar: {
            // backgroundColor: theme.colors.primary,
            backgroundColor: '#000000',
        },

        menuButton: {
            padding: '5px',
        },

        menuIcon: {
            color: theme.buttonIconPrimaryColor,
        },

        title: {
            ...theme.fonts.navigationBarHeader,
        },

        titleContainer: {
        },

        toolbar: {
        },

        dividerTop: {
            borderTopColor: theme.drawerMenuSeparatorLineColor,
        },

        drawerContainer: {
            backgroundColor: theme.drawerBackgroundColor,
        },

        drawerMenuItem: {
            backgroundColor: theme.drawerMenuItemBackgroundColor,
            color: theme.drawerMenuItemTextColor,
            fontSize: theme.drawerMenuItemTextSize,
            fontWeight: theme.drawerMenuItemFontWeight,
            borderColor: theme.drawerMenuItemBorderColor,
            borderWidth: theme.drawerMenuBorderWidth,
        },

        drawerMenuItemSelected: {
            color: theme.drawerMenuItemActiveTextColor,
            backgroundColor: `${theme.drawerMenuItemActiveBackgroundColor} !important`,
        },

        drawerHeader: {
            backgroundColor: theme.drawerMenuItemBackgroundColor,
            color: theme.drawerMenuItemTextColor,
        },

        drawerFooter: {
            backgroundColor: theme.drawerMenuItemBackgroundColor,
            color: theme.drawerMenuItemTextColor,
        },

        imageLoadingIndicator: {
            color: theme.statusLoadingIndicatorColor,
        },

        menuItem: {
            backgroundColor: theme.drawerMenuItemBackgroundColor,
            color: theme.drawerMenuItemTextColor,
            fontSize: theme.drawerMenuItemTextSize,
            fontWeight: theme.drawerMenuItemFontWeight,
            borderColor: theme.drawerMenuItemBorderColor,
            borderWidth: theme.drawerMenuBorderWidth,
        },

        menuItemFocused: {
            backgroundColor: theme.fonts.menu.color,
            color: theme.menuBackgroundColor,
        },

        menuItemSelected: {
            color: theme.drawerMenuItemActiveTextColor,
            backgroundColor: `${theme.drawerMenuItemActiveBackgroundColor} !important`,
        },
    };
};

export default getTheme;
