import { rootStore } from 'cv-react-core';

/**
* Helper method to get the styles for Icon component
* @param {Object} contextStyles - Context styles for Icon Component
*/
const getStyles = (annotationStyles = {}) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();
    return {
        userSelect: 'none',
        ...theme.fonts.dataTableRow,
        color: '#121212',
        fontFamily: 'Roboto',
        fontSize: 14,
        lineHeight: '20px',
        ...annotationStyles,
    };
};

export default getStyles;
