// Core component styles
const coreStyles = {
    customDayContainerStyles: {
        backgroundColor: '#D2D2D2',
    },
    secondDatePickerCellStyles: {
    },
    selectedDayStyles: {
    },
};

/**
 * Combine component context styles with core styles
 * @param {Object} contextStyles
 * @return {Object}
 */
const getStyles = (contextStyles = {}) => {
    return {
        customDayContainerStyles: {
            ...coreStyles.customDayContainerStyles,
            ...contextStyles.customDayContainerStyles,
        },
        secondDatePickerCellStyles: {
            ...coreStyles.secondDatePickerCellStyles,
            ...contextStyles.secondDatePickerCellStyles,
        },
        selectedDayStyles: {
            ...coreStyles.selectedDayStyles,
            ...contextStyles.selectedDayStyles,
        },
    };
};

export default getStyles;
