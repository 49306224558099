import React from 'react';
import * as PropTypes from 'prop-types';
import { default as Modal } from '../Modal/Modal';
import { default as TextLabel } from '../TextLabel/TextLabel';
import { default as LinearProgress } from '../LinearProgress/LinearProgress';

import getStyles from './PageActivityIndicator.styles';

/**
 * A control that indicates to the user that a page level action is currently being performed with a loading text and an activity indicator.
 * @see https://material-ui.com/demos/dialogs/
 * @see https://material-ui.com/components/progress/
 */
const PageActivityIndicator = (props) => {
    const {
        contextStyles,
        isOpen,
        message,
        testID,
    } = props;

    const styles = getStyles(contextStyles);

    // Generate modal props
    const modalProps = {
        contextStyles: {
            modal: styles.modal,
            overlay: styles.overlay,
        },
        isModalOpen: isOpen,
    };
    if (testID) { modalProps.testID = `${testID}__page-activity-indicator__modal`; }

    // Generate text props
    const textProps = {
        contextStyles: {
            container: styles.textContainer,
            text: styles.text,
        },
    };

    const indicatorStyles = {
        contextStyles: {
            bar: styles.bar,
        },
    };

    if (testID) { textProps.testID = `${testID}__page-activity-indicator__text`; }

    return (
        <Modal { ...modalProps }>
            <LinearProgress { ...indicatorStyles } />
            <TextLabel { ...textProps }>
                { message || 'One moment please as the page is being constructed...' }
            </TextLabel>
        </Modal>
    );
};

PageActivityIndicator.propTypes = {
    /** Styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the modal container */
        modal: PropTypes.object,

        /** Styles for the linear progress */
        bar: PropTypes.object,

        /** Styles for the overlay */
        overlay: PropTypes.object,

        /** Styles for loading text */
        text: PropTypes.object,

        /** Styles for loading text */
        textContainer: PropTypes.object,
    }),

    /** Defines open or closed state of page activity indicator */
    isOpen: PropTypes.bool.isRequired,

    message: PropTypes.string,

    /** Id used for testing */
    testID: PropTypes.string,
};

PageActivityIndicator.defaultProps = {
    contextStyles: {},
};

export default PageActivityIndicator;
