import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { XaltDropDown, XaltBox } from 'xalt-react-atoms';
import getStyles from './ActionDropDown.styles';

export default class ActionDropDown extends PureComponent {
    static defaultProps = {
        items: [],
    };

    static propTypes = {
        /** Items to present in the Drop Down */
        items: PropTypes.array,
        /** ItemSelectedId */
        itemSelected: PropTypes.object,
        /** Handler for pressing the image */
        onAction: PropTypes.func,
    };

    render() {
        const {
            items,
            itemSelected,
        } = this.props;
        const styles = getStyles();
        const dropdownStyles = {
            paddingLeft: 8,
            paddingRight: 8,
            paddingBottom: 8,
            paddingTop: 8,
            textOverflow: 'ellipsis',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: 14,
            alignItems: 'center',
            letterSpacing: 0.02,
            color: '#000000',
        };
        const boxStyles = {
            display: 'flex',
            alignItems: 'center',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: '#E0E0E0',
            boxSizing: 'border-box',
            borderRadius: '4px',
            marginRight: '12px',
            height: '32px',
        };
        const formattedItems = this.formatItems(items);
        const selectedValue = itemSelected ? formattedItems.find((item) => item.value === itemSelected.id) : undefined;

        return (
            <XaltBox style={ boxStyles }>
                <XaltDropDown
                    style={ dropdownStyles }
                    onValueChanged={ this.handleOnAction }
                    disableClearable
                    value={ selectedValue }
                    items={ formattedItems }
                    optionStyles={ styles.option }
                    expandIconStyles={ styles.expandIcon }
                    selectedStyles={ styles.optionSelected }
                    hoverStyles={ styles.optionHover } />
            </XaltBox>
        );
    }

    handleOnAction = (item) => {
        const { onAction, items } = this.props;
        if (item) {
            const foundItem = items.find((i) => i.id === item.value);
            onAction(foundItem);
        }
    }

    formatItems = (items) => items.map((item) => {
        return {
            label: item.title,
            value: item.id,
        };
    });
}
