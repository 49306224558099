import componentFactory from '../componentFactory';

export default class FlexDirection {
    static isColumnFlexDirection(props) {
        if (
            props && props.style
        ) {
            if (Array.isArray(props.style)) {
                const style = Object.assign({}, ...props.style);
                if (style.flexDirection) {
                    return style.flexDirection === 'column';
                }
            } else if (props.style.flexDirection) {
                return props.style.flexDirection === 'column';
            }
        }
        return componentFactory.getPlatformDefaultFlexDirection() === 'column';
    }

    static isColumnFlexDirectionForNode(node) {
        if (!node) {
            return componentFactory.getPlatformDefaultFlexDirection() === 'column';
        }
        return FlexDirection.isColumnFlexDirection(node.props);
    }
}
