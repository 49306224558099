import { rootStore } from 'cv-react-core';

/**
* Helper method to get the styles for Icon component
* @param {Object} contextStyles - Context styles for Icon Component
*/
// eslint-disable-next-line
const getStyles = (backgroundColor, fixedColumn, position, columnOrder, rowId, selected, spacer, ishovered) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();
    const fixedAlternatingBackgroundColors = theme.dataTableListFixedAlternatingColors;

    const colors = fixedAlternatingBackgroundColors.split(',');
    const colorIndex = rowId % colors.length;

    let baseCellBackgroundColor = backgroundColor;
    const spacerCellStyles = spacer ? {
        backgroundColor: '#ffffff',
        borderBottomWidth: 0,
        minWidth: 20,
        maxWidth: 20,
        paddingLeft: 0,
        paddingRight: 0,
    } : {};

    if (fixedColumn && columnOrder !== 0 && !selected && !ishovered) {
        baseCellBackgroundColor = colors[colorIndex];
    }

    return {
        display: 'table-cell',
        userSelect: 'none',
        padding: '0px',
        position: fixedColumn ? 'sticky' : 'unset',
        zIndex: fixedColumn ? '300' : 'unset',
        left: fixedColumn ? position : 'unset',
        backgroundColor: baseCellBackgroundColor || 'unset',
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: theme.dataTableGridBorderColor,
        ...theme.space,
        ...spacerCellStyles,
    };
};

export default getStyles;
