import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Log, TypeNames } from 'cv-dialog-sdk';

import { viz } from '../constants/vizConstants';
import componentFactory from './componentFactory';
import SaltContext from './SaltContext';
import SaltComponent from './SaltComponent';
import pageController from '../controllers/pageController';
import { utilities } from '../utilities';
import { constants } from '../constants';
import rootStore from '../stores/rootStore';
import serviceFactory from '../services/serviceFactory';
import engineConstants from './engineConstants';

@observer
export default class Viz extends SaltComponent {
    static propTypes = {
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        viewId: PropTypes.string,
    };

    static typeName = engineConstants.component.name.viz;

    render() {
        const { uiStore, dialogStore } = this.contextParams;
        const { dialog: { id, view }, adsToken } = dialogStore;

        const models = Object.assign({}, view);
        const { modelProperties, componentProperties, menu: topMenu, type: viewType } = view;
        delete models.modelProperties;
        delete models.componentProperties;
        delete models.saltDocument;

        const modelPropertiesConverted = this.convertPropertyType(modelProperties);
        const componentPropertiesConverted = uiStore.getValueForUIObject(id, viz.uiStorage.IsViewerLoaded) ? this.convertPropertyType(componentProperties) : {};

        const resolvedProps = this.resolveProperties();
        const viewHW = uiStore.getValueForUIObject(id, viz.uiStorage.ViewSizeHW);
        const { height, width } = viewHW || { height: undefined, width: undefined };

        const vizProps = {
            adsToken,
            isLibraryLoaded: uiStore.getValueForUIObject(id, viz.uiStorage.IsLibraryLoaded),
            isViewerLoaded: uiStore.getValueForUIObject(id, viz.uiStorage.IsViewerLoaded),
            height,
            width,
            componentProperties: componentPropertiesConverted,
            modelProperties: modelPropertiesConverted,
            graphicProperties: uiStore.getValueForUIObject(id, viz.uiStorage.GraphicPropertyObject),
            models,
            availableMenuItems: topMenu ? utilities.uiHelper.asGenericMenu(id, topMenu.findContextMenu()) : [],
            xMenu: this.getExtendedMenu(viewType, dialogStore, uiStore),
            onWebGVCLibLoaded: this.handleOnWebGVCLibLoaded,
            onViewerLoaded: this.handleOnViewerLoaded,
            onSelectionChanged: this.handleSelectionChanged,
            onWindowResize: this.handleOnWindowResize,
            onMenuAction: this.handleOnMenuAction,
            onLoadGraphicProperties: this.handleOnLoadGraphicProperties,
            onADSToken: this.handleADSToken,
            onADSTokenError: this.handleADSTokenError,
            ...resolvedProps,
        };

        return React.createElement(componentFactory.getPlatformComponent('viz'), vizProps);
    }

    componentWillUnmount() {
        clearInterval(this.refreshTimer);
    }

    /**
     * @todo Need to move this conversion process into sdk
     */
    convertPropertyType = (propertyArray = []) => {
        const convertedObject = {};
        propertyArray.forEach((object) => {
            const { name, value, valueType } = object;
            if (!value || !name) return;
            if (valueType === 'BOOLEAN') {
                convertedObject[name] = JSON.parse(value);
            } else if (valueType === 'NUMBER' && !Number.isNaN(value)) {
                convertedObject[name] = Number(value);
            } else if (value.trim().startsWith('[')) {
                // Remove any tick marks, brackets and spaces then split into array.
                const splitValues = value.replace(/'/g, '').replace(/\[(.*)\]/, '$1').replace(/ /g, '').split(',');
                convertedObject[name] = splitValues;
            } else { convertedObject[name] = value; }
        });
        return convertedObject;
    }

    get contextParams() {
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore, onTransition, onError } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        const { uiStore } = saltStore;
        return { saltStore, onTransition, onError, dialogStore, uiStore };
    }

    handleOnViewerLoaded = (value = true) => {
        this.setUIStoreContent(viz.uiStorage.IsViewerLoaded, value);
    }

    handleOnWebGVCLibLoaded = (height, width, value = true) => {
        this.setUIStoreContent(viz.uiStorage.IsLibraryLoaded, value);
        this.handleOnWindowResize(height, width);
    }

    handleOnWindowResize = (height, width) => {
        this.setUIStoreContent(viz.uiStorage.ViewSizeHW, { height, width });
    }

    handleSelectionChanged = (selectedIds) => {
        const { dialogStore, uiStore, onTransition, onError } = this.contextParams;
        const { dialog: { id, view } } = dialogStore;

        if (selectedIds) {
            const { defaultActionId } = view;
            if (!defaultActionId) return;

            this.setLoadingPropertyObject(id, uiStore);
            uiStore.setValueForUIObject(id, constants.viz.uiStorage.SelectedGraphicIds, selectedIds);

            pageController.performAction({ actionId: defaultActionId, selectedArray: '', dialogStore, uiStore, onTransition, onError });
        } else {
            // Remove properties object to clear view.
            uiStore.removeValueForUIObject(id, constants.viz.uiStorage.GraphicPropertyObject);

            // Clear selected id as it has been removed.
            uiStore.removeValueForUIObject(id, constants.viz.uiStorage.SelectedGraphicIds);
        }
    }

    handleOnLoadGraphicProperties = (redirection) => {
        const { dialogStore, uiStore, onTransition } = this.contextParams;
        const { dialog: { id } } = dialogStore;

        const { sessionStore } = rootStore;
        const { dialogId, displayHint, type } = redirection;
        const { displayHints } = constants;
        if (displayHint === displayHints.HINT_NO_VIEW) {
            sessionStore.openOrRefreshDialogStore(dialogId)
                .then((FormDialogStore) => {
                    const detailsDialogStore = FormDialogStore.childDialogStores[0];
                    const selectedIds = uiStore.getValueForUIObject(id, constants.viz.uiStorage.SelectedGraphicIds);
                    if (!selectedIds) return;
                    detailsDialogStore.readRecord()
                        .then(() => {
                            detailsDialogStore.setPropertyValue(constants.viz.redirectionPropSetter.GraphicIdProp, selectedIds);
                            pageController.performWrite({ dialogStore: detailsDialogStore, uiStore, onTransition, onError: this.handleActionSettingGraphicId });
                        });
                });
        } else if (displayHint === displayHints.HINT_IN_COMPONENT) {
            sessionStore.openOrRefreshDialogStore(dialogId)
                .then((FormDialogStore) => {
                    const detailsDialogStore = FormDialogStore.childDialogStores[0];
                    detailsDialogStore.readRecord().then(() => {
                        const propertyData = this.getGraphicPropertyObject(detailsDialogStore);
                        uiStore.setValueForUIObject(id, constants.viz.uiStorage.GraphicPropertyObject, propertyData);
                    });
                });
        } else if (type === TypeNames.NullRedirectionTypeName) {
            this.handleActionSettingGraphicId();
        }
    }

    // Handle fetching property data independently. Just remove the property modal and continue at this point.
    handleActionSettingGraphicId = (error) => {
        // Log the issue so we have something to go by. It was not possible to verify
        // the content before fetching properties so we are catching it from the server.
        Log.info(JSON.stringify(error));
        this.handleSelectionChanged(undefined);
    }

    handleOnMenuAction = (menu) => {
        const { id, modifiers, ...rest } = menu;
        const { dialogStore, uiStore, onTransition, onError } = this.contextParams;
        return pageController.performActionWithConfirmation({
            actionId: id,
            selectedArray: utilities.listHelper.getSelectedAsArray(uiStore, dialogStore),
            dialogStore,
            uiStore,
            onTransition,
            onError,
            actionParams: rest,
            modifiers,
        });
    };

    setUIStoreContent = (storeKey, value) => {
        const { dialogStore, uiStore } = this.contextParams;
        const { dialog: { id } } = dialogStore;
        uiStore.setValueForUIObject(id, storeKey, value);
    }

    getGraphicPropertyObject = (dialogStore) => {
        const { dialog } = dialogStore;
        const { parentDialog, view } = dialog;
        const { propertyNames } = view;
        const { description } = parentDialog || { description: '' };
        const { lang } = serviceFactory;
        const { viz: { propertyListTitle } } = lang;
        const formattedProperty = {
            title: description,
            properties: { [`${propertyListTitle}`]: {} },
            documents: [],
        };
        propertyNames.forEach((propertyName) => {
            const property = dialogStore.getProperty(propertyName);
            const propertyDef = dialog.propDefAtName(propertyName);
            const propertyValue = utilities.uiHelper.formatPropertyForRead(property, propertyDef);
            const labelCellValue = view.getLabelForProperty(propertyName);
            if (labelCellValue) {
                formattedProperty.properties[`${propertyListTitle}`][labelCellValue.value] = propertyValue;
            }
        });
        return formattedProperty;
    }

    getExtendedMenu(viewType, dialogStore, uiStore) {
        return pageController.getAvailableExtendedActions(viewType, dialogStore, uiStore);
    }

    setLoadingPropertyObject(dialogId, uiStore) {
        const { lang } = serviceFactory;
        const { viz: { fetchingProperties } } = lang;
        const properties = {
            title: fetchingProperties,
            properties: { property: { status: fetchingProperties } },
            documents: null,
        };
        uiStore.setValueForUIObject(dialogId, constants.viz.uiStorage.GraphicPropertyObject, properties);
    }

    handleADSToken = (tokenData, authClient, serviceURL) => {
        const { accessToken, refreshToken, expiresIn } = tokenData;
        const { dialogStore } = this.contextParams;
        dialogStore.setAdsToken(accessToken);
        const expireTime = expiresIn || tokenData.expires_in;
        // Logic to get refresh token
        if (expireTime) {
            this.refreshTimer = setInterval(async () => {
                try {
                    const authResponse = await authClient.getNewToken(serviceURL, refreshToken);

                    if (authResponse && authResponse.accessToken) {
                        dialogStore.setAdsToken(authResponse.accessToken);
                    }
                } catch (e) {
                    Log.error(e);
                }
            }, expireTime * 1000);
        }
    }

    handleADSTokenError = (error) => {
        Log.error(error);
        const { dialogStore, uiStore } = this.contextParams;
        const { parentDialogStore } = dialogStore;
        const { dialog: { id } } = parentDialogStore;
        const { lang } = serviceFactory;
        uiStore.setValueForUIObject(id, constants.error.MODAL_STATE, {
            isModalOpen: true,
            errors: [
                {
                    title: lang.errors.authenticationFailed,
                    err: {
                        message: lang.errors.adsAccessTokenFailureMessage,
                    },
                    type: constants.error.errorTypes.hard,
                },
            ],
        });
    }
}

Viz.contextType = SaltContext;
