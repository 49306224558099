import { SaltDocument } from '../SaltDocument';

const gpsTemplate = require('./gps_simple');

class GpsTranspiler {
    generateSalt(dialog) {
        const document = new SaltDocument(gpsTemplate);
        const viewEl = document.getByExpr('**.view');
        document.singleSelectNode(viewEl);
        const { view } = dialog;
        const viewId = SaltDocument.getViewId(view);
        document.setAttribute('id', viewId);
        return document.rootNode;
    }
}

const gpsTranspiler = new GpsTranspiler();
export default gpsTranspiler;
