/* eslint-disable no-plusplus */
import { SaltDocument } from '../SaltDocument';

const listTemplate = require('./list_simple');

class ListTranspiler {
    generateSalt(dialog) {
        const document = new SaltDocument(listTemplate);
        const viewEl = document.getByExpr('**.view');
        document.singleSelectNode(viewEl);
        // @TODO alias, viewAlias - we may want to allow salt to also reference user defined aliases
        const { view, parentDialog } = dialog;
        const { view: { isMaintainableQuery } } = parentDialog;
        const viewId = SaltDocument.getViewId(view);
        document.setAttribute('id', viewId);
        if (isMaintainableQuery) {
            const additionalStyles = this.getMaintainableStyles(dialog, parentDialog);
            viewEl.style = { ...viewEl.style, ...additionalStyles };
        }
        return document.rootNode;
    }
    getMaintainableStyles(dialog, parentDialog) {
        const { view: { isDataStyle, isHeaderStyle, isFooterStyle } } = dialog;
        const hasFooters = parentDialog.children.some((childDialog) => childDialog.view.isFooterStyle);
        let refStyles = { $ref: 'maintainable_grid_with_no_footer_style' };
        if (isHeaderStyle || isFooterStyle) {
            refStyles = { $ref: 'maintainable_grid_header_footer_style' };
        } else if (isDataStyle && hasFooters) {
            refStyles = { $ref: 'maintainable_grid_style' };
        }
        return refStyles;
    }
}
const listTranspiler = new ListTranspiler();
export default listTranspiler;
