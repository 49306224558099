import AbstractControl from './AbstractControl';

export default class BarChart extends AbstractControl {
    get animate() { return this.valueAt('Animate') === 'true'; }
    get backgroundColorOverride() {
        return this.drawBackground ? this.color('BackColor') : super.backgroundColorOverride;
    }
    get borderColor() { return this.color('BorderColor'); }
    get borderWidth() { return this.floatAt('BorderThickness'); }
    get drawBackground() { return this.valueAt('DrawBack') === 'true'; }
    get foregroundColor() { return this.color('ForeColor'); }
    get maxValue() { return this.floatAt('MaxValue'); }
    get minValue() { return this.floatAt('MinValue'); }
    get orientation() { return this.valueAt('Orientation'); }
    get reverse() { return this.valueAt('Reverse') === 'true'; }
    get valueCenterX() { return this.floatAt('ValueCenterX'); }
    get valueCenterY() { return this.floatAt('ValueCenterY'); }
    get valueColor() { return this.color('ValueColor'); }
    get valuePlacement() { return this.valueAt('ValuePlacement'); }
    get valueFormat() { return this.valueAt('ValueFormat'); }
    get valueTextSize() { return this.floatAt('ValueTextSize'); }
    get valueWidth() { return this.floatAt('ValueWidth'); }

    newReadOnlyBoxStyleSet() {
        const result = super.newReadOnlyBoxStyleSet();
        result.style.top = 0;
        result.style.left = 0;
        result.style.paddingLeft = this.paddingLeft;
        result.style.paddingRight = this.paddingRight;
        result.style.paddingTop = this.paddingTop;
        result.style.paddingBottom = this.paddingBottom;
        result.style.boxSizing = 'border-box';
        return result;
    }

    newReadOnlyPropertyStyleSet() {
        const result = super.newReadOnlyPropertyStyleSet();
        const barChartProp = { };
        const container = {};
        barChartProp.barColor = this.foregroundColor;
        barChartProp.animate = this.animate;
        barChartProp.reverse = this.reverse;
        barChartProp.orientation = this.orientation;
        if ((this.minValue || this.maxValue) && (this.minValue !== this.maxValue)) {
            barChartProp.minValue = this.minValue;
            barChartProp.maxValue = this.maxValue;
        }
        if (this.valueFormat) {
            barChartProp.valueCenterX = this.valueCenterX;
            barChartProp.valueCenterY = this.valueCenterY;
            barChartProp.valueColor = this.valueColor;
            barChartProp.valuePlacement = this.valuePlacement;
            barChartProp.valueFormat = this.valueFormat;
            barChartProp.valueTextSize = this.valueTextSize;
            if (this.valueWidth) {
                barChartProp.valueWidth = this.valueWidth;
            }
        }
        // Container styles
        if (this.borderWidth) {
            container.borderWidth = this.borderWidth;
            container.borderColor = this.borderColor;
            container.borderStyle = 'solid';
        }
        if (this.drawBackground) {
            container.backgroundColor = this.backgroundColorOverride;
        }
        result.xStyle.barChartProp = barChartProp;
        result.xStyle.container = container;
        return result;
    }
}
