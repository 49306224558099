const getStyles = () => {
    return {
        menu: {
            margin: '0px',
            padding: '0px',
        },
        menuRow: {
            padding: '0px',
            minHeight: '36px',
        },
        menuRowHover: {
            backgroundColor: '#EEEEEE',
            borderRadius: '4px',
        },
        menuItem: {
            padding: '0px',
            paddingLeft: '10px',
            paddingRight: '10px',
            margin: '0px 4px',
        },
        rowWrapper: {
            color: 'black',
            display: 'flex',
            padding: '0',
            fontSize: '14px',
            alignItems: 'center',
            paddingTop: '4px',
            paddingBottom: '4px',
        },
        icon: {
            padding: '0',
            marginRight: '4px',
            fontSize: '18px',
        },
        title: {
            cursor: 'pointer',
        },
        actionButtonExpand: {
            borderRadius: '2px',
            fontSize: '18px',
            cursor: 'pointer',
        },
        actionButtonWrapper: {
            display: 'flex',
            alignItems: 'center',
            borderRadius: '2px',
            padding: '2px',
            paddingRight: '3px',
            backgroundColor: '#006E96',
            color: '#FFF',
        },
        actionButtonIcon: {
            padding: '0',
            fontSize: '14px',
        },
        actionButtonLabel: {
            fontSize: '12px',
            fontWeight: 'normal',
            lineHeight: '14px',
            cursor: 'pointer',
        },
    };
};

export default getStyles;
