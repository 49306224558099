// Core component styles
const coreStyles = {
    container: {
        alignItems: 'center',
        backgroundColor: '#ffffff',
        borderRight: '1px solid rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100%',
        top: 'auto',
    },
};

/**
 * Helper method to get styles for the Menu Component
 * @param {Object} contextStyles - Custom styles for the Menu component
 */
const getStyles = (contextStyles = {}) => {
    return {
        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },
    };
};

export default getStyles;
