import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import { withAdditionalStyles } from '../hoc/withAdditionalStyles';
import getStyles from './XaltSwitch.styles';

const propDefinition = {
    /** Focus on the switch */
    autoFocus: PropTypes.bool,

    /** The checked value */
    checked: PropTypes.bool,

    /** Disables the control */
    disabled: PropTypes.bool,

    /** Reference to the input element */
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),

    /**
     * Called when the Toggle Switch is changed
     * @param {Boolean} checked - The checked value of switch
     */
    onValueChanged: PropTypes.func,

    /** Extended styles for this component */
    style: PropTypes.object,

    /** Styling applied to thumb control */
    thumbStyle: PropTypes.object,

    /** Styling applied on hover */
    hoverStyle: PropTypes.object,

    /** Styling applied on focus */
    focusStyle: PropTypes.object,

    /** Styling applied on thumbIcon */
    thumbIconStyle: PropTypes.object,

    /** provided by the HOC */
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
};

/**
 * A control that allows a user to toggle between binary values (on/off, yes/no, true/false)
 */
class XaltSwitch extends Component {
    static propTypes = propDefinition;

    static defaultProps = {
        onValueChanged: () => {},
        checked: false,
        hoverStyle: {
            borderColor: '#1C8EA9',
            backgroundColor: '#1C8EA9',
        },
        focusStyle: {
            borderColor: '#1C8EA9',
            backgroundColor: '#1C8EA9',
        },
        thumbIconStyle: {},
    };

    render() {
        const {
            autoFocus,
            checked,
            disabled,
            inputRef,
            onMouseOut,
            onMouseOver,
            onFocus,
            onBlur,
            style,
            thumbStyle,
            hoverStyle,
            focusStyle,
            thumbIconStyle,
        } = this.props;
        const {
            container,
            thumb,
            thumbIcon,
        } = getStyles({ style,
            thumbStyle,
            hoverStyle,
            focusStyle,
            thumbIconStyle,
            checked });
        return (
            <div
                style={ {
                    ...container,
                    justifyContent: checked ? 'flex-end' : 'flex-start',
                    pointerEvents: disabled ? 'none' : 'auto' } }
                onClick={ this.handleOnValueChanged }
                onFocus={ onFocus }
                onBlur={ onBlur }
                onMouseOver={ onMouseOver }
                onMouseOut={ onMouseOut }>
                <button
                    type="button"
                    style={ thumb }
                    ref={ inputRef }
                    onFocus={ onFocus }
                    onBlur={ onBlur }
                    autoFocus={ autoFocus }
                    disabled={ disabled }>
                    { checked ? <CheckRoundedIcon style={ thumbIcon } /> : <CloseRoundedIcon style={ thumbIcon } /> }
                </button>
            </div>

        );
    }

    handleOnValueChanged = () => {
        const { onValueChanged, checked } = this.props;
        onValueChanged(!checked);
    }

    // handleFocus= () => {
    //     const { focusColor, style } = this.props;
    //     const styles = getStyles(style);
    //     this.setState({
    //         // we should remove/recheck on this style color addition after core component is implemented
    //         container: {
    //             ...styles.container,
    //             borderColor: focusColor || styles.container.borderColor,
    //         },
    //         thumb: {
    //             ...styles.thumb,
    //             backgroundColor: focusColor || styles.thumb.backgroundColor,
    //         },
    //     });
    // }

    // handleHover= () => {
    //     const { hoverColor, style } = this.props;
    //     const styles = getStyles(style);
    //     this.setState({
    //         // we should remove/recheck on this style color addition after core component is implemented
    //         container: {
    //             ...styles.container,
    //             borderColor: hoverColor || styles.container.borderColor,
    //         },
    //         thumb: {
    //             ...styles.thumb,
    //             backgroundColor: hoverColor || styles.thumb.backgroundColor,
    //         },
    //     });
    // }

    // handleBlur= () => {
    //     const { style } = this.props;
    //     this.setState(getStyles(style));
    // }
};

export default withAdditionalStyles(XaltSwitch);
