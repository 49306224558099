import React, { useEffect, useState, useRef } from 'react';
import * as PropTypes from 'prop-types';

import getStyles from './WebCamPrompt.styles';

import { default as Prompt } from '../Prompt/Prompt';
import { default as Button, VARIANT } from '../Button/Button';
import { default as TextLabel } from '../TextLabel/TextLabel';
import { default as Image } from '../Image/Image';

/**
 * A modal that displays content in the middle of the entire viewport and over all other content.  There is a title,
 * content in the form of children and an action area.
 * @see https://material-ui.com/components/dialogs/
 * @see https://material-ui.com/api/dialog/
 */
// eslint-disable-next-line no-unused-vars
const WebCamPrompt = (props) => {
    const {
        cancelPhotoText,
        contextStyles,
        height,
        mimeType,
        onCancel,
        onError,
        onTake,
        preview,
        previewText,
        takePhotoText,
        testID,
        title,
        videoNotAvailableText,
        width,
    } = props;

    const styles = getStyles(contextStyles, width, height);

    const [
        image,
        setImage,
    ] = useState(null);

    const videoRef = useRef(null);
    const canvasRef = useRef(null);

    // Capture a photo by fetching the current contents of the video
    // and drawing it into a canvas, then converting that to a PNG
    // format data URL. By drawing it on an offscreen canvas and then
    // drawing that to the screen, we can change its size and/or apply
    // other changes before drawing it.
    function takePicture() {
        // Get canvas context
        const context = canvasRef.current.getContext('2d');

        // Size canvas
        canvasRef.current.width = width;
        canvasRef.current.height = height;

        // Draw image to canvas
        context.drawImage(videoRef.current, 0, 0, width, height);

        // Get image data from canvas
        const data = canvasRef.current.toDataURL(mimeType, 1);

        // Set preview image if enabled
        if (preview) {
            setImage(data);
        }

        // Handle props callback passing image data
        if (onTake) {
            onTake(data);
        }
    }

    // Component did mount logic
    useEffect(() => {
        // Initialize
        navigator.mediaDevices
            // Get user video capture device
            .getUserMedia({
                video: true,
                audio: false,
            })
            // Set and play video capture stream
            .then((stream) => {
                videoRef.current.srcObject = stream;
                videoRef.current.play();
            })
            // Reset the preview image
            .then(() => {
                if (preview) {
                    setImage(null);
                }
            })
            .catch((err) => {
                if (onError) {
                    onError(err);
                }
            });

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            if (videoRef.current.srcObject) {
                videoRef.current.srcObject.getTracks().forEach((track) => {
                    track.stop();
                });
            }
            else {
                return null;
            }
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // If preview image is enabled
    if (preview) {
        // If an image has been taken
        if (image) {
            // Show the preview image
            styles.preview.display = 'block';

            // Hide the preview text
            styles.previewText.display = 'none';
        }

        // No image taken yet, show the helper text
        else {
            // Hide the preview image
            styles.preview.display = 'none';

            // Show the preview text
            styles.previewText.display = 'block';
        }
    }

    return (
        <Prompt
            actions={ [
                <Button
                    contextStyles={ {
                        primary: styles.takePhotoButton,
                        primaryText: styles.takePhotoButtonText,
                    } }
                    onClick={ (event) => {
                        takePicture();
                        event.preventDefault();
                    } }
                    key="TakePhoto"
                    text={ takePhotoText }
                    variant={ VARIANT.CONTAINED } />,
                <Button
                    contextStyles={ {
                        secondary: styles.cancelButton,
                        secondaryText: styles.cancelButtonText,
                    } }
                    onClick={ onCancel }
                    key="Cancel"
                    text={ cancelPhotoText }
                    variant={ VARIANT.OUTLINED } />,
            ] }
            contextStyles={ {
                actions: styles.actions,
                content: styles.content,
                divider: styles.divider,
                header: styles.header,
                modal: styles.modal,
                overlay: styles.overlay,
                title: styles.title,
            } }
            isModalOpen
            testID={ `${testID}__prompt` }
            title={ title }>
            <div style={ styles.camera }>
                { /* eslint-disable-next-line jsx-a11y/media-has-caption */ }
                <video
                    ref={ videoRef }
                    style={ styles.video }>
                    { videoNotAvailableText }
                </video>
            </div>
            <canvas
                ref={ canvasRef }
                style={ styles.canvas } />
            { preview &&
                <div
                    style={ styles.output }>
                    <TextLabel
                        contextStyles={ {
                            text: styles.previewText,
                        } }>
                        { previewText }
                    </TextLabel>
                    <Image
                        altText={ previewText }
                        imageSrc={ image }
                        contextStyles={ {
                            image: styles.preview,
                        } } />
                </div>
            }
        </Prompt>
    );
};

WebCamPrompt.propTypes = {
    /** Cancel photo button text */
    cancelPhotoText: PropTypes.string,

    /** Extended styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the modal actions container */
        actions: PropTypes.object,

        /** Styles for the container surrounding the video element */
        camera: PropTypes.object,

        /** Styles for the cancel photo button */
        cancelButton: PropTypes.object,

        /** Styles for the cancel photo button text */
        cancelButtonText: PropTypes.object,

        /** Styles for the modal child content container */
        content: PropTypes.object,

        /** Styles for the divider between the modal title and content area */
        divider: PropTypes.object,

        /** Styles for the modal container */
        modal: PropTypes.object,

        /** Styles for the preview output container */
        output: PropTypes.object,

        /** Styles for the modal overlay */
        overlay: PropTypes.object,

        /** Styles for the preview image */
        preview: PropTypes.object,

        /** Styles for the preview image text */
        previewText: PropTypes.object,

        /** Styles for the take photo button */
        takePhotoButton: PropTypes.object,

        /** Styles for the take photo button text */
        takePhotoButtonText: PropTypes.object,

        /** Styles for the modal title and container */
        title: PropTypes.object,

        /** Styles for the video element */
        video: PropTypes.object,
    }),

    /** Height of the video stream and captured image */
    height: PropTypes.number,

    /** Image mime type */
    mimeType: PropTypes.string,

    /** Called on click of the cancel button */
    onCancel: PropTypes.func,

    /** Called when a video device initialization error occurs */
    onError: PropTypes.func,

    /**
     * Called after the take photo button successfully captures an image
     * @param {String} imageDataURL - data:image/png;base64,[imageData]
     */
    onTake: PropTypes.func,

    /** When true, enables a preview display of the captured image */
    preview: PropTypes.bool,

    /** Preview image helper text when preview is enabled */
    previewText: PropTypes.string,

    /** Take photo button text */
    takePhotoText: PropTypes.string,

    /** Id used for testing */
    testID: PropTypes.string,

    /** Title of the prompt modal */
    title: PropTypes.string,

    /** Text displayed when a video stream is not available */
    videoNotAvailableText: PropTypes.string,

    /** Width of the video stream and captured image */
    width: PropTypes.number,
};

WebCamPrompt.defaultProps = {
    cancelPhotoText: 'Cancel',
    contextStyles: {},
    height: 240,
    mimeType: 'image/jpeg',
    preview: true,
    previewText: 'The screen capture will appear in this box.',
    takePhotoText: 'Take Photo',
    testID: 'Webcam',
    title: 'Take a photo',
    videoNotAvailableText: 'Video stream not available.',
    width: 320,
};

export default WebCamPrompt;
