// Core component styles
const coreStyles = {
    adornmentButton: {
        // Reduce default button padding to keep input same size as non-adorned inputs
        padding: '8px',
    },

    adornmentIcon: {
    },

    container: {
        display: 'inline-flex',
        flex: 1,
    },

    input: {
        width: '100%',
    },
};


/**
 * Helper method to get styles for this component
 * @param {Object} contextStyles - Styles for this component
 */
const getStyles = (contextStyles = {}) => {
    const {
        adornmentButton,
        adornmentIcon,
        container,
        input,
        ...restContextStyles
    } = contextStyles;

    return {
        adornmentButton: {
            ...coreStyles.adornmentButton,
            ...adornmentButton,
        },

        adornmentIcon: {
            ...coreStyles.adornmentIcon,
            ...adornmentIcon,
        },

        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        input: {
            ...coreStyles.input,
            ...contextStyles.input,
        },

        ...restContextStyles,
    };
};

export default getStyles;
