import React, { Fragment } from 'react';
import * as PropTypes from 'prop-types';

import { default as TextLabel } from '../TextLabel/TextLabel';
import { default as Icon } from '../Icon/Icon';
import { default as Prompt } from '../Prompt/Prompt';

import getStyles from './ErrorModal.styles';

const ERROR_TYPES = {
    ERROR: 'ERROR',
    WARNING: 'WARNING',
    INFO: 'INFO',
};

/**
 * A modal that displays error content in the middle of the entire viewport and over all other content.
 */
const ErrorModal = (props) => {
    const {
        actions,
        contextStyles,
        errors,
        errorType,
        isClosable,
        isModalExpanded,
        isModalOpen,
        isTransparentOverlay,
        onClose,
        testID,
        title,
    } = props;

    // Override core styles with context styles, separating MUI styles
    const styles = getStyles(contextStyles);

    const ICONS = {
        [ ERROR_TYPES.ERROR ]: {
            iconName: 'error_outlined',
            iconStyle: styles.errorIcon,
        },
        [ ERROR_TYPES.WARNING ]: {
            iconName: 'warning_outlined',
            iconStyle: styles.warningIcon,
        },
        [ ERROR_TYPES.INFO ]: {
            iconName: 'info_outlined',
            iconStyle: styles.infoIcon,
        },
    };

    const {
        iconName,
        iconStyle,
    } = ICONS[errorType];

    const extractRecursiveError = (childError, errMessages) => {
        if (Array.isArray(childError)) {
            childError.forEach((item) => {
                if (item.children) {
                    extractRecursiveError(item.children, errMessages);
                }
                else {
                    errMessages.push(item.message);
                };
            });
        }
    };

    const extractedNestedErrorMessages = (nestedChildren) => {
        const errorList = [];
        extractRecursiveError(nestedChildren, errorList);
        return errorList;
    };

    return (
        <Prompt
            actions={ actions }
            contextStyles={ {
                actions: styles.actions,
                content: styles.content,
                divider: styles.divider,
                header: styles.header,
                modal: styles.modal,
                overlay: styles.overlay,
                title: styles.title,
            } }
            isClosable={ isClosable }
            isModalOpen={ isModalOpen }
            isTransparentOverlay={ isTransparentOverlay }
            onClose={ onClose }
            testID={ `${testID}__error-modal` }
            title={
                <Fragment>
                    <Icon
                        iconName={ iconName }
                        contextStyles={ {
                            icon: iconStyle,
                        } } />
                    { title }
                </Fragment>
            }>
            { errors.map((error) => (
                <Fragment key={ error.title }>
                    { !error.err.children &&
                        <TextLabel
                            key={ error.err.message }
                            contextStyles={ {
                                text: styles.message,
                            } }>
                            { error.err.message }
                        </TextLabel>
                    }
                    { error.err.children && extractedNestedErrorMessages(error.err.children).map((childError) => (
                        <TextLabel
                            key={ childError }
                            contextStyles={ {
                                text: styles.message,
                            } }>
                            { childError }
                        </TextLabel>
                    )) }
                </Fragment>
            )) }
            { isModalExpanded &&
                errors.map((error) => (
                    <pre key={ error.err.message }>
                        <TextLabel
                            key={ error.title }
                            contextStyles={ {
                                text: styles.messageTitle,
                            } }>
                            { error.title }
                        </TextLabel>
                        <TextLabel
                            contextStyles={ {
                                text: styles.stackTrace,
                            } }>
                            { error.err.stackTrace }
                        </TextLabel>
                    </pre>
                ))
            }
        </Prompt>
    );
};

ErrorModal.propTypes = {
    /** Prompt actions */
    actions: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]).isRequired,

    /** Extended styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the modal actions container */
        actions: PropTypes.object,

        /** Styles for the modal child content container */
        content: PropTypes.object,

        /** Styles for the divider between the modal title and content area */
        divider: PropTypes.object,

        /** Styles for the error header icon */
        errorIcon: PropTypes.object,

        /** Styles for the modal title container */
        header: PropTypes.object,

        /** Styles for the header icons */
        headerIcon: PropTypes.object,

        /** Styles for the info header icon */
        infoIcon: PropTypes.object,

        /** Styles for the error messages */
        message: PropTypes.object,

        /** Styles for the error message titles */
        messageTitle: PropTypes.object,

        /** Styles for the modal container */
        modal: PropTypes.object,

        /** Styles for the modal overlay */
        overlay: PropTypes.object,

        /** Styles for the error stacktrace text */
        stackTrace: PropTypes.object,

        /** Styles for the modal title text */
        title: PropTypes.object,

        /** Styles for the warning header icon */
        warningIcon: PropTypes.object,
    }),

    /** Errors to display on the modal */
    errors: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        err: PropTypes.shape({
            message: PropTypes.string,
            stackTrace: PropTypes.string,
        }),
    })),

    /** Type of error. Accepts ERROR, WARNING and INFO*/
    errorType: PropTypes.oneOf([
        ERROR_TYPES.ERROR,
        ERROR_TYPES.INFO,
        ERROR_TYPES.WARNING,
    ]).isRequired,

    /** Disable backdrop click modal closing */
    isClosable: PropTypes.bool,

    /** Defines open or closed state of the stack trace */
    isModalExpanded: PropTypes.bool.isRequired,

    /** Defines open or closed state of modal */
    isModalOpen: PropTypes.bool,

    /** Changes the overlay darkened fill to transparent */
    isTransparentOverlay: PropTypes.bool,

    /** Called when modal is closable and user closes the modal. */
    onClose: PropTypes.func,

    /** Modal title */
    title: PropTypes.string,

    /** Id used for testing */
    testID: PropTypes.string,
};

ErrorModal.defaultProps = {
    contextStyles: {},
    isClosable: true,
    testID: 'ErrorModal',
    title: 'Errors',
};

export {
    ERROR_TYPES,
};

export default ErrorModal;
