import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import { constants } from '../constants';
import pageController from '../controllers/pageController';
import searchController from '../controllers/searchController';
import serviceFactory from '../services/serviceFactory';
import { utilities } from '../utilities';
import uiHelper from '../utilities/uiHelper';
import CompactList from './CompactList';
import DefaultList from './DefaultList';
import engineConstants from './engineConstants';
import GridList from './GridList';
import MaintainableList from './MaintainableList';
import SaltComponent from './SaltComponent';
import SaltContext from './SaltContext';
import SearchSort from './SearchSort';
import SortForm from './SortForm';
import CalculateStatistics from './CalculateStatistics';

const { searchSort } = constants;
const { SORT_DIRECTIONS, DOT_REPLACEMENT } = searchSort;

@observer
export default class List extends SaltComponent {
    static propTypes = {
        children: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.arrayOf(PropTypes.element),
        ]),
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        viewId: PropTypes.string,
        noSearchSort: PropTypes.bool,
        type: PropTypes.string,
        portrait: PropTypes.string,
        landscape: PropTypes.string,
        initPageSize: PropTypes.number,
        // @TODO - these are only honored by the default List on rn right now - do the others!
        showVerticalScrollBar: PropTypes.bool,
        showHorizontalScrollBar: PropTypes.bool,
    };

    static typeName = engineConstants.component.name.list;

    constructor(props, context) {
        super(props, context);
        const { uiStore, dialogStore } = this.contextParams;
        utilities.listHelper.initSelectedRecords(uiStore, dialogStore);
        const { initPageSize } = this.props;
        if (initPageSize) {
            dialogStore.setPageSize(initPageSize);
        }

        this.handleOnSortColumn = this.handleOnSortColumn.bind(this);
        this.handleOnShowSortForm = this.handleOnShowSortForm.bind(this);
        this.restoreSavedSelectedRecords();
    }

    render() {
        const resolvedProps = this.resolveProperties();
        const viewId = this.getViewId(this.props.viewId);
        const { noSearchSort } = resolvedProps;
        const { onError } = this.context;
        const listComponent = this.renderList(resolvedProps);
        const searchProps = { viewId, onError };
        const sortProps = { viewId };

        return (
            <React.Fragment>
                { listComponent }
                { noSearchSort ? null : <SearchSort { ...searchProps } /> }
                { noSearchSort ? null : <SortForm { ...sortProps } /> }
                <CalculateStatistics { ...searchProps } />
            </React.Fragment>
        );
    }

    renderList(resolvedProps) {
        const { dialogStore, uiStore } = this.contextParams;
        const { dialog } = dialogStore;
        const { menu: topMenu, type: viewType, isMaintainableTableStyle } = dialog.view;
        const menu = topMenu ? uiHelper.asGenericMenu(dialog.id, topMenu.findContextMenu()) : null;
        const { orientation, platform } = uiStore.getValueForUIObject(constants.ui.APPLICATION_UI_ID, constants.ui.DEVICE_PROPERTIES);

        const listProps = {
            ...resolvedProps,
            onRequestMore: this.handleRequestMoreData,
            onRefresh: this.handleRefresh,
            onChooseItem: this.handleChooseItem,
            onSelectItem: this.handleSelectItem,
            onChooseItems: this.handleChooseItems,
            onMenuAction: this.handleMenuAction,
            onChooseNone: this.handleChooseNone,
            onSave: this.hadleSave,
            onSelectRange: this.handleSelectRange,
            onSortColumn: this.handleOnSortColumn,
            onShowSortForm: this.handleOnShowSortForm,
            isCustomSortEnabled: !!topMenu?.findAtActionId(engineConstants.action.clientActions.sort)?.visible,
            onSelectMultipleItems: this.handleSelectMultipleItems,
            isRecordSelected: this.handleIsRecordSelected,
            orientation,
            availableMenuItems: menu,
            xMenu: this.getExtendedMenu(viewType, dialogStore, uiStore),
            asyncDataCallback: this.loadAsyncData,
            enableMultiSelect: this.getListSelectionMode() === utilities.listHelper.multi,
            getHeaderProps: this.getHeaderProps,
            recordsSelectionState: dialogStore.recordsSelectionState,
        };

        const { view: formView } = dialogStore.getRootDialogStore().dialog;
        const renderType = this.resolveRenderType(resolvedProps, orientation, platform, formView.isMaintainableQuery || isMaintainableTableStyle);

        if (!renderType || renderType === 'default') {
            return <DefaultList { ...listProps } />;
        }
        if (renderType === 'compact') {
            return <CompactList { ...listProps } />;
        }
        if (renderType === 'grid') {
            return <GridList { ...listProps } />;
        }
        if (renderType === 'maintainable') {
            return <MaintainableList { ...listProps } />;
        }
        return null;
    }

    componentWillUnmount() {
        this.saveSelectedRecords();
    }

    resolveRenderType(resolvedProps, orientation, platform, isMaintainableQuery) {
        const { type, portrait, landscape } = resolvedProps;
        if (platform === constants.devices.PLATFORM_BROWSER_REACT) {
            if (isMaintainableQuery) {
                return 'maintainable';
            }
            return landscape;
        }
        if (landscape) {
            if (orientation === 'LANDSCAPE') {
                return landscape;
            }
        }
        if (portrait) {
            if (orientation === 'PORTRAIT') {
                return portrait;
            }
        }
        return type;
    }

    get contextParams() {
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore, onTransition, onError } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        const { uiStore } = saltStore;
        return { saltStore, onTransition, onError, dialogStore, uiStore };
    }

    fireDefaultAction = (objectId, modifiers, column = {}, isMaintainableQuery) => {
        const { onTransition, onError, dialogStore, uiStore } = this.contextParams;
        const { dialog } = dialogStore;
        const { id, defaultActionId: defaultActionIdOnDialog } = dialog;
        const defaultActionId = column.defaultActionId || column.defaultActionId === '' ? column.defaultActionId : defaultActionIdOnDialog;
        const overrideDefaultActionId = uiStore.getValueForUIObject(id, constants.ui.LIST_OVERRIDE_DEFAULT_ACTION_ID);
        const actionId = overrideDefaultActionId || defaultActionId;
        if (actionId) {
            utilities.listHelper.selectRecord(uiStore, dialogStore, objectId, utilities.listHelper.single); // Always single on an action tap
            pageController.performActionWithConfirmation({
                actionId,
                selectedArray: [ objectId ],
                dialogStore,
                uiStore,
                onTransition,
                onError,
                modifiers,
            });
        } else if (!actionId && !isMaintainableQuery) {
            // If there is no action set, default it to selection and ignore in case of maintainable query.
            utilities.listHelper.selectRecord(uiStore, dialogStore, objectId, utilities.listHelper.single);
        }
    };

    getListSelectionMode = (clientType) => {
        const { dialogStore } = this.contextParams;

        // Always return multiselect for desktop
        if (clientType === 'DESKTOP') {
            return utilities.listHelper.multi;
        }
        return dialogStore.hasMultiSelectActions() ? utilities.listHelper.multi : utilities.listHelper.single;
    }

    handleChooseNone = () => {
        const { dialogStore, uiStore } = this.contextParams;
        utilities.listHelper.clearSelectedRecords(uiStore, dialogStore);
    };

    loadAsyncData = (propName, recordId = undefined) => {
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        return dialogStore.dialog.readLargeProperty(propName, recordId);
    };

    handleIsRecordSelected = (recordId) => {
        const { dialogStore } = this.contextParams;
        return dialogStore.isRecordSelected(recordId);
    };

    restoreSavedSelectedRecords = () => {
        const { dialogStore, uiStore } = this.contextParams;
        const { dialog: { id } } = dialogStore;
        const savedSelections = uiStore.getValueForUIObject(id, constants.ui.LIST_SAVED_SELECTIONS) || [];
        Object.entries(savedSelections).forEach(([ key, value ]) => {
            dialogStore.setRecordSelectionState(key, value);
        });
    };

    saveSelectedRecords = () => {
        const { dialogStore, uiStore } = this.contextParams;
        if (!dialogStore) return;
        const { dialog: { id } } = dialogStore;
        uiStore.setValueForUIObject(id, constants.ui.LIST_SAVED_SELECTIONS, dialogStore.selectedRecords);
    };

    /**
     * Toggle the selected item  or select and fire an action, depending of the 'selection mode'
     */
    handleChooseItem = (objectId, selectionMode = false, clientType = 'MOBILE', modifiers, propName, isMaintainableQuery) => {
        const { dialogStore, uiStore } = this.contextParams;
        // We can either select the item or fire a menu action, based on the 'selection mode'
        if (pageController.getSelectionMode(dialogStore, uiStore) || selectionMode) {
            // If in selection mode, toggle the selected state of the record
            utilities.listHelper.toggleSelectedRecord(uiStore, dialogStore, objectId, this.getListSelectionMode(clientType));
        } else {
            let column;
            if (propName) {
                const { dialog: { view } } = dialogStore;
                column = view.getColumnAt(propName) || {};
            }
            // If not in selection mode, fire the default action.
            this.fireDefaultAction(objectId, modifiers, column, isMaintainableQuery);
        }
    };

    handleRefresh = () => {
        const { dialogStore, onError } = this.contextParams;
        const { lang } = serviceFactory;
        const title = lang.formatString(lang.dialog.errors.errorRefreshingQueryTitle);
        pageController.performRefreshList({ dialogStore, title, onError });
    };

    handleRequestMoreData = () => {
        const { dialogStore, onError } = this.contextParams;
        const { lang } = serviceFactory;
        const title = lang.formatString(lang.dialog.errors.errorRequestingMoreRecordsTitle);
        pageController.performMoreRecords({ dialogStore, title, onError }).then((result) => {
            return result;
        });
    };

    handleMenuAction = (menu) => {
        const { id, modifiers, ...rest } = menu;
        const {
            dialogStore,
            uiStore,
            onTransition,
            onError,
        } = this.contextParams;
        return pageController.performActionWithConfirmation({
            actionId: id,
            selectedArray: utilities.listHelper.getSelectedAsArray(uiStore, dialogStore),
            dialogStore,
            uiStore,
            onTransition,
            onError,
            actionParams: rest,
            modifiers,
        });
    };

    /**
     * Add an item to the selection only (no action)
     */
     handleSelectItem = (objectId, selectionMode = false, clientType = 'MOBILE') => {
         const { dialogStore, uiStore } = this.contextParams;
         // We either want to add to the current selected items or unselect all and select
         // only one depending on the 'selection mode'
         // If not in selection mode, then un-select all before selecting the long press record.
         // We don't have option to switch to selection mode in XHA. So,  getSelectionMode from pageController always returns false.
         // This clears already selected items in XHA. To avoid this, we send selectionMode property.
         if (!pageController.getSelectionMode(dialogStore, uiStore) && !selectionMode) {
             utilities.listHelper.clearSelectedRecords(uiStore, dialogStore);
         }
         utilities.listHelper.selectRecord(uiStore, dialogStore, objectId, this.getListSelectionMode(clientType));
     }

    handleSelectMultipleItems = (newSelectionIndex) => {
        const { dialogStore: listDialogStore } = this.contextParams;
        const { records } = listDialogStore;
        if (listDialogStore.selectedRecordCount > 0) {
            const lastSelectedRecord = listDialogStore.lastRecordSelected;
            const lastSelectedIndex = records.findIndex((record) => record.id === lastSelectedRecord);
            const newSelectedRecordId = records[newSelectionIndex].id;
            const isNewRecordSelected = listDialogStore.isRecordSelected(newSelectedRecordId);
            const batchUpdates = [];
            const start = Math.min(newSelectionIndex, lastSelectedIndex);
            const end = Math.max(newSelectionIndex, lastSelectedIndex);
            // If new selected record is already selected, deselect all the records from last selection
            if (isNewRecordSelected) {
                for (let step = start; step <= end; step += 1) {
                    utilities.listHelper.deselectRecord(listDialogStore, records[step].id);
                }
            } else {
                // Select all the records from last selection
                for (let step = start; step <= end; step += 1) {
                    batchUpdates.push({ objectId: records[step].id, selectionMode: true, clientType: 'DESKTOP' });
                }
            }
            const transactions = batchUpdates.map((selectionObject) => {
                return new Promise((resolve) => { resolve(this.handleSelectItem(selectionObject.objectId, selectionObject.selectionMode, selectionObject.clientType)); });
            });

            return Promise.all(transactions)
                .finally(() => listDialogStore.setLastSelectedRecordId(newSelectedRecordId))
                .catch;
        }
        return this.handleChooseItem(records[newSelectionIndex].id, true, 'DESKTOP');
    }

    getExtendedMenu(viewType, dialogStore, uiStore) {
        return pageController.getAvailableExtendedActions(viewType, dialogStore, uiStore);
    }

    handleOnSortColumn(columnName, sortDirection, multiColumnSort = true) {
        const { onError, dialogStore: listDialogStore, uiStore } = this.contextParams;
        const { searchDialogStore } = listDialogStore;
        const sortTerms = multiColumnSort ? searchDialogStore.sortValues : [];
        const sortTermColumnName = columnName.replace(DOT_REPLACEMENT, '.');
        const foundIndex = sortTerms.findIndex((sortTerm) => sortTerm.name === sortTermColumnName);

        if (sortDirection === SORT_DIRECTIONS.NONE) {
            sortTerms.splice(foundIndex, 1);
        } else if (foundIndex > -1) {
            sortTerms[foundIndex].isAscending = sortDirection === SORT_DIRECTIONS.ASC;
        } else {
            const priority = sortTerms.length === 0 ? 0 : sortTerms.length;
            const term = {
                name: sortTermColumnName,
                isAscending: sortDirection === SORT_DIRECTIONS.ASC,
                priority,
            };
            sortTerms.push(term);
        }

        searchController.submitSortTerms({ listDialogStore, sortTerms, onError, uiStore });
    }

    handleOnShowSortForm() {
        const { dialogStore, onError } = this.contextParams;
        pageController.performSort({ dialogStore, onError });
    }

    getHeaderProps = (colId) => {
        const { dialogStore } = this.contextParams;
        const { searchDialogStore } = dialogStore;
        const sortDirectives = searchDialogStore ? searchDialogStore.sortValues : [];
        const propertyName = colId.replace(DOT_REPLACEMENT, '.');
        const isColumnFiltered = searchController.isPropertyNameFiltered({ listDialogStore: dialogStore, propertyName });
        const sortDirective = sortDirectives.find((sortedColumn) => (sortedColumn.name === propertyName));
        let sortDirection;
        let sortOrder = 0;
        if (sortDirective) {
            const { isAscending, priority } = sortDirective;
            sortDirection = isAscending ? SORT_DIRECTIONS.ASC : SORT_DIRECTIONS.DESC;
            sortOrder = priority + 1;
        }
        return {
            isColumnFiltered,
            sortDirection,
            sortOrder,
        };
    }

    handleChooseItems = (objectIds) => {
        const { dialogStore, uiStore } = this.contextParams;
        utilities.listHelper.selectRecords(uiStore, dialogStore, objectIds);
    };

    hadleSave = () => {
        const {
            dialogStore,
            uiStore,
            onTransition,
            onError,
        } = this.contextParams;
        return pageController.performActionWithConfirmation({
            actionId: engineConstants.action.clientActions.save,
            dialogStore,
            uiStore,
            onTransition,
            onError,
        });
    }

    handleSelectRange = (range) => {
        const { dialogStore, uiStore } = this.contextParams;
        const { dialog: { id, view }, parentDialogStore } = dialogStore;
        const { isMaintainableTableStyle } = view;
        const selectedDialogStore = isMaintainableTableStyle ? dialogStore : parentDialogStore;
        // We can select cells from different sections. So, we have to use parent dialogstore to store these values.
        utilities.listHelper.selectRange(uiStore, selectedDialogStore, { ...range, dialogId: id });
        serviceFactory.clipboard.performClipboardCopy(dialogStore, Object.keys(range.records), range.columns, false);
    };
}

List.contextType = SaltContext;
