/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
const isObject = (item) => {
    return (item && typeof item === 'object' && !Array.isArray(item));
};

/**
 * Function helper to do a deep merge of two objects.
 * @param {Object} targetObject - Object to merge into
 * @param  {...any} mergeObject - Object to merge
 */
const deepMerge = (targetObject, ...mergeObject) => {
    // If the merge object has no length, just return the target
    if (!mergeObject.length) return targetObject;
    const source = mergeObject.shift();

    if (isObject(targetObject) && isObject(source)) {
        Object.entries(source).forEach(([ key ]) => {
            if (isObject(source[key])) {
                if (!targetObject[key]) Object.assign(targetObject, { [key]: {} });
                deepMerge(targetObject[key], source[key]);
            } else {
                Object.assign(targetObject, { [key]: source[key] });
            }
        });
    }

    return deepMerge(targetObject, ...mergeObject);
};

const objectHelper = {
    deepMerge,
    isObject,
};

export default objectHelper;
