import PropTypes from 'prop-types';

export default {
    style: PropTypes.object,
    name: PropTypes.string,
    value: PropTypes.any,
    tipText: PropTypes.string,
    imageSrc: PropTypes.string,
    isReadMode: PropTypes.bool,
    isFocused: PropTypes.bool,
    onValueChanged: PropTypes.func,
    asyncDataCallback: PropTypes.func,
    onLoadOptionValues: PropTypes.func,
    xStyle: PropTypes.object,
    maxLength: PropTypes.number,
    optionValues: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
        }),
    ),
    propertyRef: PropTypes.object,
    placeholder: PropTypes.string,
};
