/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';

import CheckBoxSharp from '@material-ui/icons/CheckBoxSharp';
import CheckBoxOutlineBlankSharpIcon from '@material-ui/icons/CheckBoxOutlineBlankSharp';

// import { trace } from 'mobx';
// import { Log } from 'cv-dialog-sdk';

import Checkbox from '../Checkbox';

import getStyles from './TableSelectionCell.styles';

class TableSelectionCell extends PureComponent {
    static propTypes = {
        style: PropTypes.object,
        selected: PropTypes.bool,
        onClick: PropTypes.func,
        rowId: PropTypes.number,
    }

    static defaultProps = {
        style: {},
        selected: false,
        onClick: () => {},
    }

    render() {
        const {
            style,
            selected } = this.props;

        const styles = getStyles(style, selected);
        return (
            <Checkbox
                contextStyles={ { ...styles } }
                checked={ selected }
                checkedIcon={ <CheckBoxSharp /> }
                icon={ <CheckBoxOutlineBlankSharpIcon /> }
                onClick={ this.handleOnClick } />
        );
    }

    handleOnClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const { onClick, rowId } = this.props;
        onClick(rowId, event);
    }
};

export default TableSelectionCell;
