import React from 'react';
import PropTypes from 'prop-types';
import { default as ChartWrapper } from '../ChartWrapper/ChartWrapper';
import getStyles from './PieChart.styles';

/**
 * The pie chart is mainly used for showing proportion of different categories.
 * Each arc length represents the proportion of data quantity.
 * @see https://echarts.apache.org/examples/en/editor.html?c=pie-simple
 */
const PieChart = (props) => {
    const {
        animation,
        contextStyles,
        grouping,
        horizontal,
        legend,
        onPress,
        onSelectItem,
        renderButtonText,
        seriesData,
        testID,
        title,
        tooltip,
        donut,
    } = props;

    const styles = getStyles(contextStyles);

    /** Build chart options */
    const chartOptions = {
        animation,
        backgroundColor: 'transparent',
        color: [ ...ChartWrapper.defaultColors ],
        textStyle: styles.chartTextStyle,
    };

    // Set chart legend
    if (legend) {
        // Set chart legend layout
        const legendLocation = {
            right: '5%',
            bottom: '5%',
            top: horizontal ? '5%' : 'auto',
            left: horizontal ? 'auto' : '5%',
            orient: horizontal ? 'vertical' : 'horizontal',
            type: 'scroll',
        };

        chartOptions.legend = {
            // Show the legend
            show: true,

            // Set legend series names
            data: seriesData[0].map((item) => (item.label)),

            padding: [
                5,
                10,
            ],
            symbolKeepAspect: true,

            // Set legend layout
            ...legendLocation,

            textStyle: styles.legendTextStyle,
        };
    }

    // Set chart title
    if (title) {
        chartOptions.title = {
            text: title,
            textStyle: styles.titleTextStyle,
            x: 'center',
        };
    }

    // Enable chart tooltip
    if (tooltip) {
        chartOptions.tooltip = {
            confine: true,
            extraCssText: 'z-index: 10',
            padding: [
                2,
                10,
            ],
            textStyle: styles.tooltipTextStyle,
            ...styles.tooltipContainerStyle,
        };
    }

    let radiusValue = Math.round(75 / seriesData.length);
    let startPoint = 0;
    let endPoint = radiusValue;

    if (donut) {
        radiusValue = Math.round(50 / seriesData.length);
        startPoint = 30;
        endPoint = startPoint + radiusValue;
    }

    // Generate chart series data
    chartOptions.series = seriesData.map((eachSerieData) => {
        const serie = {
            // Default to vertical layout with pie centered
            center: [
                '50%',
                '50%',
            ],

            // Show label for each slice
            label: {
                show: true,
                formatter: '{c}',
                ...styles.labelTextStyle,
            },

            // Show lable line for each slice
            labelLine: {
                show: true,
                lineStyle: {
                    ...styles.labelLineStyle,
                },
            },

            // This is a pie chart
            type: 'pie',

            // Size it appropriately
            radius: [
                `${startPoint}%`,
                `${endPoint}%`,
            ],

            bottom: horizontal ? 'auto' : '15%',
        };
        // Set chart legend
        if (legend) {
            // Adjust chart center
            if (horizontal) {
                serie.center[0] = '35%';
            }
        }
        startPoint = endPoint;
        endPoint += radiusValue;

        serie.data = eachSerieData.map((sliceData) => {
            const {
                borderColor,
                borderWidth,
                color,
                displayValue,
                emphasisColor,
                emphasisOpacity,
                label,
                opacity,
                selected,
                value,
                ...sliceRest
            } = sliceData;

            // Begin slice detail
            const pieSlice = {
                // Initialize slice style
                itemStyle: {},

                // Tag the slice with a name
                name: label,

                // Slice selected state
                selected,

                // Slice value
                value,

                avoidLabelOverlap: true,

                // Pass through any extra props
                ...sliceRest,
            };

            // Add slice details
            if (borderColor) {
                pieSlice.itemStyle.borderColor = borderColor;
            }
            if (borderWidth || borderWidth === 0) {
                pieSlice.itemStyle.borderWidth = borderWidth;
            }
            if (color) {
                pieSlice.itemStyle.color = color;
            }
            if (opacity || opacity === 0) {
                pieSlice.itemStyle.opacity = opacity;
            }

            // Adding default opacity to provide more contrast on Item
            const emphasisItemStyle = { opacity: 0.5 };
            if (emphasisOpacity || emphasisOpacity === 0) {
                emphasisItemStyle.opacity = emphasisOpacity;
            }
            if (emphasisColor) {
                emphasisItemStyle.color = emphasisColor;
            }
            pieSlice.emphasis = { itemStyle: emphasisItemStyle };

            if (displayValue) {
                pieSlice.displayValue = displayValue;

                if (tooltip) {
                    pieSlice.tooltip = {
                        formatter: `{b}: ${displayValue}`,
                    };
                }
            }

            return pieSlice;
        });
        return serie;
    });


    // logic to update the serie data points based on grouping
    if (grouping) {
        chartOptions.series = chartOptions.series.map((serie) => {
            let result = [];
            serie.data.forEach((a) => {
                let found = false;
                result = result.map((datapoint) => {
                    const point = { ...datapoint };
                    if (point.name === a.name) {
                        found = true;
                        point.value += a.value;
                    }
                    return point;
                });
                if (!found) {
                    result.push( { name: a.name, value: a.value });
                }
            });
            return { ...serie, data: result };
        });
    }

    // Generate props
    const chartProps = {
        contextStyles: {
            container: styles.container,
        },
        option: chartOptions,
        canvas: true,
    };
    if (onPress) { chartProps.onPress = onPress; }
    if (onSelectItem) { chartProps.onSelectItem = onSelectItem; }
    if (renderButtonText) { chartProps.renderButtonText = renderButtonText; }
    if (testID) { chartProps.testID = testID; }

    return (
        <ChartWrapper { ...chartProps } />
    );
};

PieChart.propTypes = {
    /** Enables/disables chart animations */
    animation: PropTypes.bool,

    /** Styles for the component */
    contextStyles: PropTypes.shape({
        /** Styles for the chart text content */
        chartTextStyle: PropTypes.object,

        /** Styles for the container */
        container: PropTypes.object,

        /** Styles for the slice label line */
        labelLineStyle: PropTypes.object,

        /** Styles for the slice label */
        labelTextStyle: PropTypes.object,

        /** Styles for the legend */
        legendTextStyle: PropTypes.object,

        /** Styles for the chart title */
        titleTextStyle: PropTypes.object,

        /** Styles for the tooltip container */
        tooltipContainerStyle: PropTypes.object,

        /** Styles for the tooltip */
        tooltipTextStyle: PropTypes.object,
    }),

    /** Display donut chart */
    donut: PropTypes.bool,

    /** condition to group the datapoints */
    grouping: PropTypes.bool,

    /** Display horizontal with legend on right */
    horizontal: PropTypes.bool,

    /** Displays the information for the charts */
    legend: PropTypes.bool,

    /**
     * Triggered on press of a pie section
     * @param {Object} itemData - Data for the pie section pressed
     */
    onPress: PropTypes.func,

    /**
     * Triggered on select of a pie section
     * @param {Object} itemData - Data for the pie section selected
     */
    onSelectItem: PropTypes.func,

    /**
     * Used to manage the selected detail button text
     * @param {Object} dataPoint
     */
    renderButtonText: PropTypes.func,

    /** The data for the pie chart */
    seriesData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
        /** Border color of the pie section */
        borderColor: PropTypes.string,

        /** Border width of the pie section */
        borderWidth: PropTypes.number,

        /** Color of the pie section */
        color: PropTypes.string,

        /** Optional formatted value for display */
        displayValue: PropTypes.string,

        /** Highlight color of the pie on press/hover */
        emphasisColor: PropTypes.string,

        /** Name of the sliced data */
        label: PropTypes.string.isRequired,

        /** Opacity of the pie section */
        opacity: PropTypes.number,

        /** Selected state of the slice */
        selected: PropTypes.bool,

        /** The value of the pie section */
        value: PropTypes.number.isRequired,
    }))).isRequired,

    /** Id used for testing */
    testID: PropTypes.string,

    /** Title text for the chart */
    title: PropTypes.string,

    /** Show related data in a tooltip after pressing the pie section */
    tooltip: PropTypes.bool,
};

PieChart.defaultProps = {
    animation: false,
    legend: true,
    contextStyles: {},
    tooltip: true,
};

export default PieChart;
