// Core component styles

const coreStyles = {
    button: {
        primary: {
            display: 'flex',
            minWidth: 0,
            padding: 0,
            backgroundColor: 'rgba(0, 0, 0, 0)',
            '&:hover': {
                display: 'flex',
                minWidth: 0,
                padding: 0,
                backgroundColor: '#d5d5d5',
            },
        },
    },

    container: {
        display: 'flex',
    },

    paperCheckbox: {
        width: 15,
        height: 15,
        checkedColor: 'grey',
        uncheckedColor: 'white',
        showLines: true,
        lineColor: 'black',
        lineWidth: 1,
    },

};

/**
 * Combines coreStyles and contextStyles
 * @param {Object} style
 * @param {Object} contextStyles
 */
const getStyles = (style = {}, contextStyles = {}) => {
    return {
        button: {
            ...coreStyles.button,
            ...contextStyles.button,
        },
        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        paperCheckbox: {
            ...coreStyles.paperCheckbox,
            ...style,
            ...contextStyles.paperCheckbox,
        },
    };
};

export default getStyles;
