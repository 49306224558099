/* @flow */
export default {
    errorsTitle: 'Errors',
    noMessageText: 'No message text provided',
    noMessageTitle: 'No message title provided',
    noStacktrace: 'Stacktrace not available.',
    passwordHasExpired: 'Password has expired',
    passwordNotSetMessage: 'Please create an offline password in settings to be able to switch to offline mode.',
    invalidTenantMessage: 'An Error has occurred.  Please ensure that you are using the correct Tenant ID and/or contact your Administrator for help',
    passwordMisMatch: 'The new password and verification password must match.',
    noHistoryCloseTabTitle: 'Close browser tab',
    noHistoryCloseTabMessage: 'Action performed successfully. Browser will be closed as their is no history to go back to previous diloag',
    scatterBubbleError: 'A bubble or scatter plot must have the x-axis value defined in the graph definition. This graph will be displayed only after this issue is resolved.',
    genericChartError: 'Missing mandatory properties. Please check the configuration.',
    passwordExpiredInfo: 'Your Password is expired. Please contact your administrator.',
    onTransitionRegistryMessage:
        'Only one subscription at a time is allowed for transition registry.\r\n'
        + 'The registry is based on action routes and you can only have one action route registered at a time.\r\n'
        + 'Current class subscriber is:',
    authenticationFailed: 'Authentication Failed',
    adsAccessTokenFailureMessage: 'Failed to get ads access token',
};
