import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import componentFactory from './componentFactory';
import SaltContext from './SaltContext';
import SaltComponent from './SaltComponent';
import engineConstants from './engineConstants';

@observer
export default class Title extends SaltComponent {
    static propTypes = {
        // The label value associated with the propertyName that should be retrieved
        propertyName: PropTypes.string,
        // overrides the propertyName value if supplied (i.e. literal)
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool,
            PropTypes.number,
        ]),
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        viewId: PropTypes.string,
    };

    static typeName = engineConstants.component.name.title;

    render() {
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        const { style, xStyle } = this.props;
        const titleProps = { style, xStyle };
        if (dialogStore) {
            const { dialog } = dialogStore;
            return dialog.paneTitle ? React.createElement(componentFactory.getPlatformComponent('text'), titleProps, dialog.paneTitle) : null;
        }
        return null;
    }
}

Title.contextType = SaltContext;
