import { TypeNames } from 'cv-dialog-sdk';
import { constants, engineConstants } from 'cv-react-core';
import * as PropTypes from 'prop-types';
import React from 'react';

import RWSaltComponent from './RWSaltComponent';
import lang from '../nls/i18n';
import ActionMenu from '../components/base/ActionMenu/ActionMenu';

class RWMenu extends RWSaltComponent {
    static propTypes = {
        // Allows styling of the 'exterior' scroll pane itself
        // Some values don't make sense here and are not allowed (i.e. align-items)
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),

        onMenuAction: PropTypes.func,

        // One of view type names found in { TypeNames } from cv-dialog-sdk
        viewType: PropTypes.string,

        // Standard Menu - Array of normalized actions to shown on menu
        menu: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            icon: PropTypes.string,
            menuText: PropTypes.string,
        })),

        // Extended Menu - Additional available actions that may be OPTIONALLY shown elsewhere (i.e. toolbar)
        xMenu: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            icon: PropTypes.string,
            menuText: PropTypes.string,
        })),
    };

    render() {
        const {
            menu,
            xMenu,
            viewType,
        } = this.props;
        const labels = [];
        const values = [];
        if (menu) {
            menu.forEach((m) => {
                labels.push(m.menuText);
                values.push(m.id);
            });
        }
        // If viewType is Graph we are not adding copy to clipboard option
        if (menu && menu.length && viewType !== TypeNames.GraphTypeName) {
            const extendedMenu = xMenu.find((f) => (f.id === engineConstants.action.clientActions.copyToClipboard));

            if (extendedMenu) {
                labels.push(lang.list.copyToClipboard);
                values.push(extendedMenu.id);
            }
        }
        const showMenu = menu && (labels.length || values.length);
        const menuProps = {
            labels,
            values,
            onMenuAction: this.handleMenuAction,
            key: 'menuKey',
        };

        if (showMenu) {
            return (
                <ActionMenu { ...menuProps } />
            );
        }
        return null;
    }

    handleMenuAction = (groupEvent, nativeEvent) => {
        const { onMenuAction } = this.props;
        const modifiers = (nativeEvent && nativeEvent.altKey) ? { [constants.transitionModifiers.OPEN_IN_TAB]: true } : undefined;
        const event = {
            ...groupEvent,
            modifiers,
        };
        onMenuAction(event);
    }
}

export default RWMenu;
