/**
 * If a style does not fit into a 'text' category then it is assumed to be a 'container' style
 * @type {String[]}
 */
 const WEB_TEXT_STYLES = [
    'color',
    'cursor',
    'direction',
    'font',
    'font-family',
    'fontFamily',
    'font-size',
    'fontSize',
    'font-stretch',
    'fontStretch',
    'font-style',
    'fontStyle',
    'font-variant',
    'fontVariant',
    'font-weight',
    'fontWeight',
    'letter-spacing',
    'letterSpacing',
    'line-height',
    'lineHeight',
    'text-align',
    'textAlign',
    'text-align-last',
    'textAlignLast',
    'text-decoration',
    'textDecoration',
    'text-indent',
    'textIndent',
    'text-justify',
    'textJustify',
    'text-shadow',
    'textShadow',
    'text-transform',
    'textTransform',
    'text-overflow',
    'textOverflow',
    'unicode-bidi',
    'unicodeBidi',
    'unicode-range',
    'unicodeRange',
    'vertical-align',
    'verticalAlign',
    'word-break',
    'wordBreak',
    'white-space',
    'whiteSpace',
    'word-spacing',
    'wordSpacing',
    'word-wrap',
    'wordWrap',
    'writing-mode',
    'writingMode',
    'position',
    'left',
    'right',
    'top',
    'bottom',
];

/**
 * If a style does not fit into a 'text' category then it is assumed to be a 'container' style
 * @type {String[]}
 */
// const NATIVE_TEXT_STYLES = [
//     'textShadowOffset',
//     'color',
//     'fontSize',
//     'fontStyle',
//     'fontWeight',
//     'lineHeight',
//     'textAlign',
//     'textDecorationLine',
//     'textShadowColor',
//     'fontFamily',
//     'textShadowRadius',
//     'includeFontPadding',
//     'textAlignVertical',
//     'fontVariant',
//     'letterSpacing',
//     'textDecorationColor',
//     'textDecorationStyle',
//     'textTransform',
//     'writingDirection',
// ];

const buildStyleObject = (styles = {}, category) => {
    const filteredStyles = {};
    if (category) {
        Object.keys(styles).forEach((style) => {
            if (category.find((categoryStyle) => (style === categoryStyle))) {
                filteredStyles[style] = styles[style];
            }
        });
        return filteredStyles;
    }

    Object.keys(styles).forEach((style) => {
        if (!WEB_TEXT_STYLES.find((categoryStyle) => (style === categoryStyle))) {
            filteredStyles[style] = styles[style];
        }
    });
    return filteredStyles;
};

const parseTextStyles = (styles) => buildStyleObject(styles, WEB_TEXT_STYLES);

const parseContainerStyles = (styles) => buildStyleObject(styles);

const styleParser = {
    parseTextStyles,
    parseContainerStyles,
};

export default styleParser;
