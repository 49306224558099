import React from 'react';
import * as PropTypes from 'prop-types';
import { default as TextLabel } from '../TextLabel/TextLabel';
import { default as Image } from '../Image/Image';

// Styles
import getStyles from './TextLabelWithImage.styles';

/**
 * A component for displaying text.
 */
const TextLabelWithImage = (props) => {
    const { // eslint-disable-line
        contextStyles,
        disabled,
        imageHeight,
        imageSrc,
        imageStretch,
        imageWidth,
        numberOfLines,
        onClick,
        reverse,
        showLoadingIndicator,
        stacked,
        testID,
        text,
        toolTip,
    } = props;

    const styles = getStyles(contextStyles, reverse, stacked);

    // Generate container props
    const containerProps = {
        className: 'text-label-with-image__container',
        style: styles.container,
    };
    if (!disabled && onClick) {
        containerProps.onClick = (event) => {
            onClick(event, props);
        };
    }
    if (testID) { containerProps['data-test-id'] = `${testID}__text-label-with-image__container`; }

    // Generate text props
    const textProps = {
        contextStyles: {
            text: styles.text,
        },
        title: toolTip,
    };
    if (numberOfLines) { textProps.numberOfLines = numberOfLines; }
    if (testID) { textProps.testID = `${testID}__text-label-with-image__text`; }

    // Generate image props
    const imageProps = {
        contextStyles: {
            image: {
                ...styles.image,
                height: `${imageHeight}px`,
                width: imageStretch ? '100%' : `${imageWidth}px`,
            },
            loadingIndicator: styles.loadingIndicator,
        },
        imageSrc,
        toolTip,
    };
    if (showLoadingIndicator) { imageProps.showLoadingIndicator = showLoadingIndicator; }
    if (testID) { imageProps.testID = `${testID}__text-label-with-image__image`; }

    return (
        <div { ...containerProps }>
            <TextLabel { ...textProps }>
                { text }
            </TextLabel>
            { imageSrc && <Image { ...imageProps } /> }
        </div>
    );
};

TextLabelWithImage.propTypes = {
    /** Styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the container surrounding the text and image */
        container: PropTypes.object,

        /** Styles for the image */
        image: PropTypes.object,

        /** Styles for the image loading indicator */
        loadingIndicator: PropTypes.object,

        /** Styles for the text */
        text: PropTypes.object,
    }),

    /** Disables image and text touch events */
    disabled: PropTypes.bool,

    /** Height of the image */
    imageHeight: PropTypes.number,

    /** The image source (either a remote URL or a local file resource) */
    imageSrc: PropTypes.string,

    /**  Scale width and height independently, this may change the aspect ratio of the src */
    imageStretch: PropTypes.bool,

    /** Width of the image */
    imageWidth: PropTypes.number,

    /**
     * Used to truncate the text with an ellipsis after
     * computing the text layout, including line wrapping,
     * such that the total number of lines does not exceed this number
     */
    numberOfLines: PropTypes.number,

    /**
     * Called when the text or image was pressed
     * @param {Object} event - The native change event
     * @param {Object} props - The component instance props
     */
    onClick: PropTypes.func,

    /** Reverse display of text and image */
    reverse: PropTypes.bool,

    /** Controls the image loading indicator visibility */
    showLoadingIndicator: PropTypes.bool,

    /** Display text and image in a column */
    stacked: PropTypes.bool,

    /** Id used for testing */
    testID: PropTypes.string,

    /** The text to display */
    text: PropTypes.string.isRequired,

    /** toolTip string */
    toolTip: PropTypes.string,
};

TextLabelWithImage.defaultProps = {
    contextStyles: {},
    imageHeight: 25,
    imageWidth: 25,
};

export default TextLabelWithImage;
