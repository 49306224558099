/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import componentFactory from '../componentFactory';
import componentPropTypes from './abstractComponentPropTypes';

const propDefinitions = {
    style: componentPropTypes.style,
    value: componentPropTypes.value,
    tipText: componentPropTypes.tipText,
    isReadMode: componentPropTypes.isReadMode,
    isFocused: componentPropTypes.isFocused,
    onValueChanged: componentPropTypes.onValueChanged,
    xStyle: componentPropTypes.xStyle,
    maxLength: componentPropTypes.maxLength,
    propertyRef: componentPropTypes.propertyRef,
};

export default class HtmlTextField extends Component {
    static propTypes = propDefinitions;

    render() {
        const {
            style,
            value,
            tipText,
            isReadMode,
            isFocused,
            onValueChanged,
            xStyle,
            maxLength,
            propertyRef,
        } = this.props;
        const textFieldProps = {
            style,
            value: value || '',
            tipText,
            isReadMode,
            isFocused,
            onValueChanged,
            xStyle,
            maxLength,
            propertyRef,
        };
        return React.createElement(componentFactory.getPlatformPropertyComponent('htmlTextField'), { ...textFieldProps });
    }
}
