import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { TypeNames } from 'cv-dialog-sdk';

import ParentNode from './ParentNode';
import RefUtil from './ref/RefUtil';
import SaltContext from './SaltContext';
import Layout from './xStyle/Layout';
import BaseStyle from './style/BaseStyle';

export default class SaltComponent extends Component {
    static propTypes = {
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xStyle: PropTypes.oneOfType([
            Layout.layoutPropType,
            PropTypes.arrayOf(Layout.layoutPropType),
        ]),
        assert: PropTypes.shape({
            refName: PropTypes.string,
            expr: PropTypes.string,
        }),
        forceResize: PropTypes.bool,
        viewId: PropTypes.string,
    };

    // | ============================== x-style | buffoonery ===================================== |
    // V ====================================== V ================================================ V

    mergeXStyles(saltXStyle = {}, baseXStyles = {}) {
        const supportedXStyles = baseXStyles.supportedXStyles || [];
        const mergedXStyles = {};
        supportedXStyles.forEach((style) => {
            mergedXStyles[style] = {
                ...baseXStyles[style],
                ...saltXStyle[style],
            };
        });

        const saltKeys = Object.keys(saltXStyle);
        saltKeys.forEach((key) => {
            if (!mergedXStyles[key]) {
                mergedXStyles[key] = saltXStyle[key];
            }
        });

        return mergedXStyles;
    }

    /**
     * Process any xStyle directions that apply to this component
     * The xStyle directives will be returned as additional style objects to be merged into the style param
     * xStyle resolved style is applies AFTER any directly supplied style values
     * @param {*} props
     * @param {*} xStyle
     * @param {*} componentName
     */
    applyXStyle(props, xStyle, componentName) {
        const { parentNode } = this.context;
        const { viewId } = this.props;
        const combinedStyle = Layout.combineStyles(props.style, this.processXStyle(xStyle, parentNode));
        const { saltStore } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(this.getViewId(viewId));
        const { dialog } = dialogStore;
        const { view } = dialog;
        const { validMarkup: isNonSALT, paperMarkup } = view;
        // Apply default base styles for non SALT views (that is GML and paper forms)
        const defaultNonSALTStyles = isNonSALT ? BaseStyle.getStyles(componentName, paperMarkup) : {};
        const defaultNonSALTXStyles = isNonSALT ? BaseStyle.getXStyles(componentName, paperMarkup) : {};

        if (view.type === TypeNames.ListTypeName) {
            return {
                ...props,
                style: combinedStyle,
            };
        }
        return {
            ...props,
            xStyle: this.mergeXStyles(xStyle, defaultNonSALTXStyles),
            style: Layout.combineStyles(defaultNonSALTStyles, combinedStyle),
        };
    }

    /**
     * Iterate through xStyle (if array), create and return a conventional style object
     * @param {*} xStyle
     * @param {*} parentNode
     */
    processXStyle(xStyle, parentNode) {
        if (xStyle) {
            if (Array.isArray(xStyle)) {
                return xStyle.map(item => Layout.getStyleForLayout(item.layout, parentNode));
            }
            return Layout.getStyleForLayout(xStyle.layout, parentNode);
        }
        return {};
    }

    // | =============================== context | tomfoolery ========================================= |
    // V ======================================= V ================================================= V

    wrapWithContext(name, resolvedProps, childElem) {
        const { parentNode: currentParentNode } = this.context;
        const parentNode = new ParentNode(name, resolvedProps, currentParentNode);
        const newContext = {
            ...this.context,
            parentNode,
        };
        return (
            <SaltContext.Provider
                value={ newContext }>
                {childElem}
            </SaltContext.Provider>
        );
    }

    // | ================================== prop | antics ========================================== |
    // V ======================================= V ================================================= V

    getPropsWithSubstitution() {
        return this.getPropsWithSubstitutionFor([ 'style', 'xStyle' ]);
    }

    getPropsWithSubstitutionFor(propsNames) {
        const props = {};
        // eslint-disable-next-line react/destructuring-assignment
        propsNames.forEach(propName => { props[propName] = this.props[propName]; });
        const { saltStore, scopeManager } = this.context;
        const { viewId } = this.props;
        const dialogStore = saltStore.getDialogStoreForViewId(this.getViewId(viewId));
        return { ...this.props, ...RefUtil.substituteValues({ ...props }, scopeManager, dialogStore, saltStore) };
    }

    resolveProperties(componentName) {
        let props = this.getPropsWithSubstitution();
        // merge a style array into a single object
        props = { ...props, style: this.flattenStyle(props.style) };
        // generate style from any special xStyle values, if an array, merge into single object
        return { ...this.applyXStyle(props, props.xStyle, componentName) };
    }

    flattenStyle(style) {
        if (Array.isArray(style)) {
            return [ ...style ].reduce(Layout.mergeLeft);
        }
        return style;
    }

    determineVisibility(assert, dialogStore, scopeManager, saltStore) {
        return RefUtil.evalAssertion(assert, dialogStore, scopeManager, saltStore);
    }

    // This may need to become a comprehensive deep-merge, but keeping it simple for now
    // For now we cherry-pick the props that need a deep-merge
    mergeProps(props, otherProps) {
        const newStyle = Layout.combineStyles(props.style, otherProps.style);
        return { ...props, ...otherProps, style: newStyle };
    }

    getViewId(viewId) {
        if (viewId) {
            return viewId;
        }
        const { context } = this;
        return context.viewId;
    }
}

SaltComponent.contextType = SaltContext;
