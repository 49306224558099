const coreStyles = {
    fixedHeader: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        padding: '16px',
        paddingTop: '10px',
        position: 'sticky',
        top: 0,
        zIndex: 1,
    },
    image: {
        boxSizing: 'border-box',
        filter: 'grayscale(1)',
        height: '36px',
        objectFit: 'contain',
        width: '36px',
    },
    listItemContainer: {
        display: 'flex',
        flexGrow: 1,
        cursor: 'pointer',
        alignItems: 'center',
        paddingLeft: 10,
        paddingRight: 10,
        flexShrink: 1,
        overflow: 'hidden',
    },
    launcherContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'rgba(245, 245, 245, .75)',
        height: '72px',
        paddingLeft: 0,
        paddingRight: 0,
    },
    listHover: {
        backgroundColor: '#FFFFFF',
    },
    listItemSelected: {
        borderLeft: 'solid 4px #006E96',
        paddingLeft: 6,
        backgroundColor: 'rgba(255, 255, 255, .8)',
        backdropFilter: 'blur(75px)',
    },
    launcherText: {
        fontSize: '16px',
        fontFamily: 'Roboto',
        lineHeight: '24px',
        fontWeight: 400,
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    imageSelected: {
        boxSizing: 'border-box',
        height: '36px',
        objectFit: 'contain',
        width: '36px',
    },
    imageContainer: {
        cursor: 'pointer',
        justifyContent: 'center',
        padding: '18px 20px 18px 0px',
    },
    wrapper: {
        alignItems: 'center',
        backgroundColor: '#ffffff',
        borderRight: '1px solid rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    keyline: {
        width: 'calc(100% - 20px)',
        height: '1px',
        display: 'block',
        backgroundColor: '#E0E0E0',
        position: 'absolute',
        top: 0,
    },
    expandButton: {
        height: '57px',
        display: 'flex',
        alignItems: 'center',
        minHeight: '57px',
        zIndex: 1000,
        position: 'absolute',
        left: 0,
        bottom: 20,
        backgroundColor: 'rgba(245, 245, 245, .8)',
        paddingRight: 10,
        paddingLeft: 10,
        width: 35,
        justifyContent: 'center',
        cursor: 'pointer',
        backdropFilter: 'blur(75px)',
    },
    expandedButton: {
        width: 265,
        justifyContent: 'flex-end',
    },
    drawer: {
        top: '102px',
        height: 'calc(100% - 179px)',
        overflow: 'overlay',
        overflowX: 'hidden',
        backgroundColor: 'rgba(245, 245, 245, .75)',
        borderRight: 'none',
        width: 55,
        backdropFilter: 'blur(75px)',
        zIndex: 999,
    },
    expanded: {
        width: 285,
    },
    listContainer: {
        paddingTop: 0,
        paddingBottom: 0,
    },
};

const getStyles = (contextStyles = {}) => {
    return {
        fixedHeader: {
            ...coreStyles.fixedHeader,
            ...contextStyles.fixedHeader,
        },
        image: {
            ...coreStyles.image,
            ...contextStyles.image,
        },
        launcherSelected: {
            ...coreStyles.launcherSelected,
            ...contextStyles.launcherSelected,
        },
        paperContainer: {
            ...coreStyles.paperContainer,
            ...contextStyles.paperContainer,
        },
        listHover: {
            ...coreStyles.listHover,
            ...contextStyles.listHover,
        },
        listItemContainer: {
            ...coreStyles.listItemContainer,
            ...contextStyles.listItemContainer,
        },
        launcherContainer: {
            ...coreStyles.launcherContainer,
            ...contextStyles.launcherContainer,
        },
        listItemSelected: {
            ...coreStyles.listItemSelected,
            ...contextStyles.listItemSelected,
        },
        launcherText: {
            ...coreStyles.launcherText,
            ...contextStyles.launcherText,
        },
        imageSelected: {
            ...coreStyles.imageSelected,
            ...contextStyles.imageSelected,
        },
        imageContainer: {
            ...coreStyles.imageContainer,
            ...contextStyles.imageContainer,
        },
        wrapper: {
            ...coreStyles.wrapper,
            ...contextStyles.wrapper,
        },
        keyline: {
            ...coreStyles.keyline,
            ...contextStyles.keyline,
        },
        expandButton: {
            ...coreStyles.expandButton,
            ...contextStyles.expandButton,
        },
        expandedButton: {
            ...coreStyles.expandedButton,
            ...contextStyles.expandedButton,
        },
        drawer: {
            ...coreStyles.drawer,
            ...contextStyles.drawer,
        },
        expanded: {
            ...coreStyles.expanded,
            ...contextStyles.expanded,
        },
        listContainer: {
            ...coreStyles.listContainer,
            ...contextStyles.listContainer,
        },
    };
};

export default getStyles;
