import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { engineConstants } from 'cv-react-core';
import { XaltToolTip, XaltFieldActionButton } from 'xalt-react-atoms';

// eslint-disable-next-line no-unused-vars
const HANDLED_ACTION_IDS = [
'barcodeScan:',
'formatXML:',
];

/**
 * A platform component for building a client action
 */
class RWFieldAction extends PureComponent {
    static propTypes = {
        style: PropTypes.object,
        xStyle: PropTypes.object,
        action: PropTypes.object,
        onFieldAction: PropTypes.func,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool,
            PropTypes.number,
            PropTypes.array,
        ]),
        semanticType: PropTypes.string,
        showAction: PropTypes.bool,
        type: PropTypes.string,
        toolTip: PropTypes.string,
    };

    static defaultProps = {
        type: 'image',
        onFieldAction: () => {},
        toolTip: '',
    };

    constructor(props) {
        super(props);
        this.handleOnActionClick = this.handleOnActionClick.bind(this);
    }
    render() {
        const {
            style,
            xStyle,
            type,
            action,
            showAction,
        } = this.props;

        const toolTip = action.label || '';

        const {
            multiSelectAvailable,
            iconUrl } = action;

        const {
            focus,
            hover,
            icon } = xStyle || {};

        const standardActionProps = {
            style: { objectFit: 'contain', visibility: showAction ? 'visible' : 'hidden', ...style, ...icon },
            focusStyle: { outline: 'none', ...focus },
            hoverStyle: { outline: 'none', ...hover },
            multiple: multiSelectAvailable || false,
            type,
            onClick: this.handleOnActionClick,
            iconUrl,
        };
        return (
            <XaltToolTip
                tipText={ toolTip }>
                <XaltFieldActionButton { ...standardActionProps } />
            </XaltToolTip>
        );
    }

    handleOnActionClick(event) {
        const {
            value,
            onFieldAction,
            semanticType,
            action } = this.props;

        switch (semanticType) {
            case engineConstants.fieldActionSemanticTypes.URL:
                {
                    let url = value;
                    const pattern = /^((http|https|ftp):\/\/)/;
                    // Need to append protocal if missing to open url properly otherwise it treats as relative url.
                    if (url && !pattern.test(url)) {
                        url = 'http://'.concat(url);
                    }
                    if (url) {
                        window.open(url, '_blank');
                    }
                }
                break;
            case engineConstants.fieldActionSemanticTypes.EMAIL:
                window.location.assign(`mailto:${value}`);
                break;
            case engineConstants.fieldActionSemanticTypes.TELEPHONE:
                window.location.assign(`tel:${value}`);
                break;
            case engineConstants.fieldActionSemanticTypes.LARGE_PROPERTY:
                onFieldAction(event); // DO NOTHING, this is managed inside RWFieldActionFilePicker
                break;
            case engineConstants.fieldActionSemanticTypes.COLOR:
                onFieldAction(event); // DO NOTHING, this is managed inside RWFieldActionColorPicker
                break;
            default:
                onFieldAction(action);
        };
    }
}

export default RWFieldAction;
