import { constants } from '../constants';

const calendarHelper = {
    resetCalendar: (dialogStore, uiStore) => {
        const { dialog } = dialogStore;
        uiStore.setValueForUIObject(dialog.id, constants.ui.CALENDAR_UPDATE_MODAL, false);
    },

    refreshCalendar: (dialogStore) => {
        dialogStore.refresh();
    },

    setDate: (dialogStore, uiStore, date) => {
        const { dialog } = dialogStore;
        uiStore.setValueForUIObject(dialog.id, constants.ui.CALENDAR_SELECTED_DATE, date);
    },

    getDate: (dialogStore, uiStore) => {
        const { dialog } = dialogStore;
        return uiStore.getValueForUIObject(dialog.id, constants.ui.CALENDAR_SELECTED_DATE);
    },

    setPickedDate: (dialogStore, uiStore, date) => {
        const { dialog } = dialogStore;
        uiStore.setValueForUIObject(dialog.id, constants.ui.CALENDAR_PICKED_DATE, date);
    },

    getPickedDate: (dialogStore, uiStore) => {
        const { dialog } = dialogStore;
        return uiStore.getValueForUIObject(dialog.id, constants.ui.CALENDAR_PICKED_DATE);
    },

    setDateRange: (dialogStore, dateRange) => {
        dialogStore.setDateRange(dateRange);
    },

    setView: (dialogStore, uiStore, view) => {
        const { dialog } = dialogStore;
        uiStore.setValueForUIObject(dialog.id, constants.ui.CALENDAR_SELECTED_VIEW, view);
    },

    getView: (dialogStore, uiStore) => {
        const { dialog } = dialogStore;
        // Going forward, initial view type(Day/Month/Week) will be given by the API in the view defination which has been configured in the calendar query.
        // Later that can be changed only and can be pesisted until we have the same dailog ID.
        const { initialStyle = 'month' } = dialog.view;
        const defaultCalendarView = initialStyle[0].toUpperCase() + initialStyle.slice(1);
        return uiStore.getValueForUIObject(dialog.id, constants.ui.CALENDAR_SELECTED_VIEW) || defaultCalendarView;
    },

    getFirstDayofWeek: (rootStore) => {
        const weekDays = [ 'SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY' ];
        const { sessionStore: { session: { tenantProperties: { initialWeekDay } } } } = rootStore;
        return weekDays.indexOf(initialWeekDay || 'SUNDAY');
    },

    setConfirmation: (dialogStore, uiStore, show) => {
        const { dialog } = dialogStore;
        uiStore.setValueForUIObject(dialog.id, constants.ui.CALENDAR_UPDATE_MODAL, show);
    },

    getConfirmation: (dialogStore, uiStore) => {
        const { dialog } = dialogStore;
        return uiStore.getValueForUIObject(dialog.id, constants.ui.CALENDAR_UPDATE_MODAL);
    },

    setUpdatedRecord: (dialogStore, record) => {
        dialogStore.setUpdatedRecord(record);
    },

    getUpdatedRecord: (dialogStore) => {
        return dialogStore.getUpdatedRecord();
    },

    setDialogPropValue: (dialogStore, prop, value) => {
        dialogStore.setPropertyValue(prop, value);
    },

    setOrientation: (dialogStore, uiStore, orientation) => {
        const { dialog } = dialogStore;
        uiStore.setValueForUIObject(dialog.id, constants.ui.CALENDAR_ORIENTATION, orientation);
    },

    getOrientation: (dialogStore, uiStore) => {
        const { dialog } = dialogStore;
        return uiStore.getValueForUIObject(dialog.id, constants.ui.CALENDAR_ORIENTATION);
    },

    daysBetween: (startDate, endDate) => {
        const msPerDay = 24 * 60 * 60 * 1000;
        const startDateUTC = new Date(startDate);
        const endDateUTC = new Date(endDate);
        startDateUTC.setMinutes(startDateUTC.getMinutes() - startDateUTC.getTimezoneOffset());
        endDateUTC.setMinutes(endDateUTC.getMinutes() - endDateUTC.getTimezoneOffset());
        return (endDateUTC - startDateUTC) / msPerDay;
    },
};

export default calendarHelper;
