const XALT_DATE_VIEWS = {
    DATE: 'date',
    HOURS: 'hours',
    MINUTES: 'minutes',
    YEAR: 'year',
};
const XALT_PICKER_VARIANTS = {
    DIALOG: 'dialog',
    INLINE: 'inline',
    STATIC: 'static',
};

export const date = {
    XALT_DATE_VIEWS,
    XALT_PICKER_VARIANTS,
};