import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import { utilities } from '../utilities';
import componentFactory from './componentFactory';
import SaltComponent from './SaltComponent';
import SaltContext from './SaltContext';
import ScopeManager from './ScopeManager';


@observer
export default class DefaultList extends SaltComponent {
    static propTypes = {
        children: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.arrayOf(PropTypes.element),
        ]),
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        viewId: PropTypes.string,
        onChooseItem: PropTypes.func,
        onSelectItem: PropTypes.func,
        onMenuAction: PropTypes.func,
        onChooseNone: PropTypes.func,
        onRefresh: PropTypes.func,
        onRequestMore: PropTypes.func,
        availableMenuItems: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            icon: PropTypes.string,
            menuText: PropTypes.string,
        })),
        asyncDataCallback: PropTypes.func,
        showVerticalScrollBar: PropTypes.bool,
        showHorizontalScrollBar: PropTypes.bool,
        refreshInProgress: PropTypes.bool,
        isRefreshTriggeredByTimer: PropTypes.bool,
        isRecordSelected: PropTypes.func,
    };

    render() {
        const {
            onChooseItem, onSelectItem,
            onMenuAction, onRefresh,
            onRequestMore, onChooseNone,
            style, xStyle, availableMenuItems,
            asyncDataCallback,
            showVerticalScrollBar, showHorizontalScrollBar,
            xMenu,
            ...rest
        } = this.props;
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        const { refreshInProgress, isRefreshTriggeredByTimer, queryInProgress } = dialogStore;
        const { uiStore, sessionStore } = saltStore;
        const { records } = dialogStore;
        const { session: { tenantProperties } } = sessionStore;
        const { doubleClickForDefaultAction } = tenantProperties;

        const listProps = {
            style,
            xStyle,
            hasMoreRecords: dialogStore.hasMoreRecords(),
            records: toJS(records),
            keyExtractor: utilities.listHelper.getKeyExtractor,
            onRequestMore,
            isRefreshTriggeredByTimer,
            // TODO: Need to consolidate this to one prop with one meaning for isRefreshing and refreshInProgress.
            // It means the same thing but is used differently across platforms.
            isRefreshing: isRefreshTriggeredByTimer ? false : refreshInProgress,
            refreshInProgress: isRefreshTriggeredByTimer ? false : refreshInProgress,
            queryInProgress,
            onRefresh,
            onChooseItem,
            onSelectItem,
            onMenuAction,
            onChooseNone,
            isRecordSelected: this.handleIsRecordSelected,
            availableMenuItems,
            selectedRecords: utilities.listHelper.getSelectedRecords(uiStore, dialogStore),
            onRenderItem: this.handleOnRenderItem,
            asyncDataCallback,
            showVerticalScrollBar,
            showHorizontalScrollBar,
            xMenu,
            doubleClickForDefaultAction: doubleClickForDefaultAction === 'true',
            ...rest,
        };

        return React.createElement(componentFactory.getPlatformComponent('defaultList'), listProps);
    }

    // eslint-disable-next-line react/prop-types
    handleOnRenderItem = ({ item: record }) => {
        if (record) {
            const { children } = this.props;
            const { scopeManager } = this.context;
            const newChildren = React.Children.map(children, childElement => React.cloneElement(childElement));
            const newContext = {
                ...this.context,
                scopeManager: scopeManager.newScopeWithLocal(ScopeManager.SCOPED_RECORD_REF_NAME, record),
            };
            return (
                <SaltContext.Provider
                    key={ record.id }
                    value={ newContext }>
                    {newChildren}
                </SaltContext.Provider>
            );
        }
        return null;
    };

    handleIsRecordSelected = (record) => {
        const { isRecordSelected } = this.props;
        return isRecordSelected(record.id);
    }
}

DefaultList.contextType = SaltContext;
