import React from 'react';
import PropTypes from 'prop-types';
import Prompt from '../base/Prompt';
import TextLabel from '../base/TextLabel';
import Button, { BUTTON_VARIANT } from '../base/Button';
import lang from '../../nls/i18n';


const SimpleModal = (({ show, onCancel, onConfirm, title, message, yesText, noText, contextStyles, confirmButtonVariant, cancelButtonVariant }) => {
    const actionsList = [];

    if (onCancel) {
        actionsList.push(
            <Button
                onClick={ onCancel }
                key="btnCancel"
                variant={ cancelButtonVariant }
                text={ noText } />
        );
    }
    if (onConfirm) {
        actionsList.push(
            <Button
                onClick={ onConfirm }
                variant={ confirmButtonVariant }
                key="btnLogout"
                text={ yesText } />
        );
    }

    return (
        <Prompt
            contextStyles={ contextStyles }
            isModalOpen={ show }
            isTransparentOverlay
            onClose={ onCancel }
            onSubmit={ onConfirm }
            title={ title }
            actions={ actionsList }>
            <TextLabel
                contextStyles={ contextStyles }>
                { message }
            </TextLabel>
        </Prompt>
    );
});

SimpleModal.defaultProps = {
    cancelButtonVariant: BUTTON_VARIANT.CONTAINED,
    confirmButtonVariant: BUTTON_VARIANT.OUTLINED,
    contextStyles: {},
    message: null,
    noText: lang.generic.cancel,
    yesText: lang.generic.continue,
};

SimpleModal.propTypes = {
    cancelButtonVariant: PropTypes.string,
    confirmButtonVariant: PropTypes.string,
    show: PropTypes.bool,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    title: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.string,
    ]),
    message: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.string,
    ]),
    yesText: PropTypes.string,
    noText: PropTypes.string,
    contextStyles: PropTypes.object,
};

export default SimpleModal;
