/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { XaltRadioGroup, XaltRadio, XaltBox, XaltToolTip, XaltTextLabel } from 'xalt-react-atoms';

/**
 * A platform component for building a Checkbox component
 */
export default class RWRadio extends Component {
    static propTypes = {
        isFocused: PropTypes.bool,
        isReadMode: PropTypes.bool,
        onValueChanged: PropTypes.func,
        style: PropTypes.object,
        xStyle: PropTypes.object,
        tipText: PropTypes.string,
        propertyRef: PropTypes.object,
        value: PropTypes.bool,
        items: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.bool,
            }),
        ),
    };

    static defaultProps = {
        style: {},
    };

    render() {
        const {
            isFocused,
            isReadMode,
            onValueChanged,
            propertyRef,
            style,
            xStyle,
            tipText,
            value,
            items,
        } = this.props;
        const { focus, hover } = xStyle || {};
        const radioStyles = {
            color: style.color,
            padding: '0px',
        };
        const labelStyle = {
            textOverflow: 'ellipsis',
            ...style,
            borderWidth: '0px',
        };
        return (
            <XaltToolTip
                style={ style }
                tipText={ tipText }>
                <XaltRadioGroup
                    value={ value }
                    onChange={ onValueChanged }>
                    { items.map((item) => (
                        <XaltBox
                            style={ { ...style, flexDirection: 'row', borderWidth: '0px' } }
                            key={ item.label }>
                            <XaltRadio
                                autoFocus={ isFocused }
                                disabled={ isReadMode }
                                checked={ item.value === value }
                                value={ item.value }
                                inputRef={ propertyRef }
                                style={ radioStyles }
                                focusStyle={ focus }
                                hoverStyle={ hover } />
                            <XaltTextLabel style={ labelStyle }>
                                { item.label }
                            </XaltTextLabel>
                        </XaltBox>
                    )) }
                </XaltRadioGroup>
            </XaltToolTip>
        );
    }
}
