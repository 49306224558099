/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import componentFactory from '../componentFactory';
import componentPropTypes from './abstractComponentPropTypes';
import serviceFactory from '../../services/serviceFactory';
import engineConstants from '../engineConstants';

const propDefinitions = {
    isFocused: componentPropTypes.isFocused,
    isReadMode: componentPropTypes.isReadMode,
    onValueChanged: componentPropTypes.onValueChanged,
    propertyRef: componentPropTypes.propertyRef,
    style: componentPropTypes.style,
    tipText: componentPropTypes.tipText,
    value: componentPropTypes.value,
    xStyle: componentPropTypes.xStyle,
};

const entryTypes = {
    plainToggle: 'ENTRY_METHOD_TOGGLE',
    toggleWithLabels: 'ENTRY_METHOD_TOGGLE_WITH_LABELS',
    toggleWithLeftAlignLabel: 'ENTRY_METHOD_TOGGLE_ACTIVE_LABEL_LEFT',
    toggleWithRightAlignLabel: 'ENTRY_METHOD_TOGGLE_ACTIVE_LABEL_RIGHT',
};

export default class Switch extends Component {
    static propTypes = propDefinitions;
    static typeName = engineConstants.component.name.switch;

    render() {
        const {
            isFocused,
            isReadMode,
            onValueChanged,
            propertyRef,
            style,
            tipText,
            value,
            xStyle,
        } = this.props;
        const { lang: { generic: { yes } } } = serviceFactory;
        const { booleanFalseAlias, booleanTrueAlias, entryMethod } = xStyle;
        const boolValue = isReadMode ? value === (booleanTrueAlias || yes) : value === 'true';

        let leftLabel;
        let rightLabel;
        if (entryMethod === entryTypes.toggleWithLabels) {
            leftLabel = booleanFalseAlias;
            rightLabel = booleanTrueAlias;
        }
        if (entryMethod === entryTypes.toggleWithLeftAlignLabel) {
            leftLabel = boolValue ? booleanTrueAlias : booleanFalseAlias;
        }
        if (entryMethod === entryTypes.toggleWithRightAlignLabel) {
            rightLabel = boolValue ? booleanTrueAlias : booleanFalseAlias;
        }
        const switchProps = {
            isFocused,
            isReadMode,
            leftLabel,
            onValueChanged,
            propertyRef,
            rightLabel,
            style,
            tipText,
            value: boolValue,
            xStyle,
        };
        return React.createElement(componentFactory.getPlatformPropertyComponent('switch'), { ...switchProps });
    }
}
