/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { utilities } from 'cv-react-core';
import { XaltSwitch, XaltToolTip, XaltTextLabel } from 'xalt-react-atoms';


const {
    styleHelper,
} = utilities;
/**
 * A platform component for building a Switch component
 */
export default class RWSwitch extends Component {
    static propTypes = {
        rightLabel: PropTypes.string,
        leftLabel: PropTypes.string,
        isFocused: PropTypes.bool,
        isReadMode: PropTypes.bool,
        onValueChanged: PropTypes.func,
        style: PropTypes.object,
        xStyle: PropTypes.object,
        tipText: PropTypes.string,
        propertyRef: PropTypes.object,
        value: PropTypes.bool,
    };

    static defaultProps = {
        style: {},
    };

    render() {
        const {
            leftLabel,
            rightLabel,
            isFocused,
            isReadMode,
            onValueChanged,
            propertyRef,
            style,
            xStyle,
            tipText,
            value,
        } = this.props;
        const { focus, hover } = xStyle || {};
        const checkedBackgroundColor = value && hover?.backgroundColor ? { backgroundColor: styleHelper.getRGBAColor(hover.backgroundColor, 0.2) } : {};
        const switchStyles = {
            color: style.color,
            ...checkedBackgroundColor,
        };
        const labelStyle = {
            textOverflow: 'ellipsis',
            ...style,
            flexGrow: '0',
            borderWidth: '0px',
            right: 'auto',
            left: 'auto',
            top: 'auto',
            bottom: 'auto',
        };

        return (
            <XaltToolTip
                style={ style }
                tipText={ tipText }>
                { leftLabel &&
                    <XaltTextLabel style={ { paddingRight: '8px', ...labelStyle } }>
                        { leftLabel }
                    </XaltTextLabel>
                }
                <XaltSwitch
                    autoFocus={ isFocused }
                    checked={ value }
                    disabled={ isReadMode }
                    inputRef={ propertyRef }
                    onValueChanged={ onValueChanged }
                    style={ switchStyles }
                    focusStyle={ focus }
                    hoverStyle={ hover } />
                { rightLabel &&
                    <XaltTextLabel style={ { paddingLeft: '8px', ...labelStyle } }>
                        { rightLabel }
                    </XaltTextLabel>
                }
            </XaltToolTip>
        );
    }
}
