/*       */

export default class Version {
    versionString ;
    constructor(versionString) {
        this.versionString = versionString;
    }

    isValid = () => {
        const version = this.getVersionArray();
        return version.length === 2
            && (this.getMajorVersion() === '1' || this.getMajorVersion() === '2')
            && this.getMinorVersion() === '0';
    }

    isV1 = () => {
        return this.getMajorVersion() === '1';
    }

    getMajorVersion = () => {
        return this.getVersionArray()[0];
    }

    getMinorVersion = () => {
        return this.getVersionArray()[1];
    }

    getVersionArray = () => {
        return this.versionString.split('.');
    }
}
