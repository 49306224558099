/* eslint-disable no-plusplus */
import { SaltDocument } from '../SaltDocument';

const graphTemplate = require('./graphs_simple');

class GraphTranspiler {
    generateSalt(dialog) {
        const document = new SaltDocument(graphTemplate);
        const viewEl = document.getByExpr('**.view');
        document.singleSelectNode(viewEl);
        // @TODO alias, viewAlias - we may want to allow salt to also reference user defined aliases
        const { view } = dialog;
        const viewId = SaltDocument.getViewId(view);
        document.setAttribute('id', viewId);
        return document.rootNode;
    }
}

const graphTranspiler = new GraphTranspiler();
export default graphTranspiler;
