import { RecordSetVisitor } from "../proxy";
import { RecordVisitor } from "../proxy/RecordVisitor";
import { DownloadManagerVisitor } from "./DownloadManagerVisitor";
import { OfflineItemsRecordSetVisitor } from "./OfflineItemsRecordSetVisitor";
import { QueuedItemsRecordSetVisitor } from "./QueuedItemsRecordSetVisitor";
/**
 *
 */
export class GenericDialogDelegateStateVisitor {
    constructor(state) {
        if (typeof state === 'string') {
            this._enclosedJsonObject = JSON.parse(state);
        }
        else {
            this._enclosedJsonObject = state;
        }
    }
    // --- State Management Helpers --- //
    // --- State Import/Export --- //
    enclosedJsonObject() {
        return this._enclosedJsonObject;
    }
    copyAsJsonObject() {
        return JSON.parse(this.copyAsJsonString());
    }
    copyAsJsonString() {
        return JSON.stringify(this.enclosedJsonObject());
    }
    // --- State Management --- //
    addSelectedItemId(id) {
        const index = this.enclosedJsonObject().selectedItemIds.indexOf(id);
        if (index === -1) {
            this.enclosedJsonObject().selectedItemIds.push(id);
        }
    }
    removeSelectedItemId(id) {
        const index = this.enclosedJsonObject().selectedItemIds.indexOf(id);
        if (index > -1) {
            this.enclosedJsonObject().selectedItemIds.splice(index, 1);
        }
    }
    visitBaseUrl() {
        return this.enclosedJsonObject().baseUrl;
    }
    visitAndSetBaseUrl(baseUrl) {
        this.enclosedJsonObject().baseUrl = baseUrl;
    }
    visitBriefcase() {
        return new DownloadManagerVisitor(this.enclosedJsonObject().briefcase);
    }
    visitLoginHash() {
        return this.enclosedJsonObject().loginHash;
    }
    visitAndSetLoginHash(loginHash) {
        this.enclosedJsonObject().loginHash = loginHash;
    }
    visitSelectedItemIds() {
        return this.enclosedJsonObject().selectedItemIds;
    }
    visitSelectedItemsDownloadStatus() {
        return this.enclosedJsonObject().selectedItemsDownloadStatus;
    }
    visitAndSetSelectedItemsDownloadStatus(downloadStatus) {
        this.enclosedJsonObject().selectedItemsDownloadStatus = downloadStatus;
    }
    visitAndClearSelectedItemIds() {
        return this.enclosedJsonObject().selectedItemIds = [];
    }
    visitSessionId() {
        return this.enclosedJsonObject().sessionId;
    }
    visitAndSetSessionId(sessionId) {
        this.enclosedJsonObject().sessionId = sessionId;
    }
    visitTenantId() {
        return this.enclosedJsonObject().tenantId;
    }
    visitAndSetTenantId(tenantId) {
        this.enclosedJsonObject().tenantId = tenantId;
    }
    visitUserId() {
        return this.enclosedJsonObject().userId;
    }
    visitAndSetUserId(userId) {
        this.enclosedJsonObject().userId = userId;
    }
    visitMobileCommentsRecordSet() {
        return new QueuedItemsRecordSetVisitor(this.enclosedJsonObject().mobileComments);
    }
    visitEditedRecordSet() {
        return new QueuedItemsRecordSetVisitor(this.enclosedJsonObject().editedRecords);
    }
    visitSelectedItemsRecordSet() {
        return new OfflineItemsRecordSetVisitor(this.enclosedJsonObject().selectedItems);
    }
    addEditedRecords(breadCrumb, persistentId, request, req, propertyTimestamp) {
        const editedRecordObject = {
            breadCrumb,
            persistentId,
            request,
            record: new RecordVisitor(req.body()),
            id: JSON.stringify(new RecordVisitor(req.body())),
            propertyTimestamp // to maintain the key of an respective properties of an record
        };
        this.enclosedJsonObject().editedRecords.records.push(editedRecordObject);
    }
    visitAndClearEditedRecords() {
        this.enclosedJsonObject().editedRecords.records = [];
    }
    visitAndClearSelectedRecords() {
        this.enclosedJsonObject().selectedItems = RecordSetVisitor.emptyRecordSetVisitor().enclosedJsonObject();
    }
    visitAndClearQueueItems() {
        this.enclosedJsonObject().mobileComments = RecordSetVisitor.emptyRecordSetVisitor().enclosedJsonObject();
    }
    /**
     *
     * @param pendingRecords contains the list of failed sync items
     * Logic to check and update accordingly the record list of EditedRecords with failied sync items
     */
    visitAndUpdateEditedRecords(pendingRecords) {
        const records = [];
        for (const pendingRecord of pendingRecords) {
            const record = this.enclosedJsonObject().editedRecords.records[pendingRecord.index];
            const index = record.record._enclosedJsonObject.properties.findIndex((item) => item.name === 'Error');
            index !== -1 ? record.record._enclosedJsonObject.properties[index].value = pendingRecord.message : '';
            records.push(record);
        }
        this.enclosedJsonObject().editedRecords.records = records;
    }
    removeSelectedEditRecord(index) {
        if (index > -1) {
            this.enclosedJsonObject().editedRecords.records.splice(index, 1);
        }
    }
    updateEditedRecord(index, request, record) {
        if (index > -1) {
            this.enclosedJsonObject().editedRecords.records[index] = Object.assign(Object.assign({}, this.enclosedJsonObject().editedRecords.records[index]), { request, record, id: JSON.stringify(record) });
        }
    }
    visitAndSetRecordsWithOfflineInfo(recordsArray) {
        this.enclosedJsonObject().selectedRecordsWithOfflineInfo = recordsArray;
    }
    visitRecordsWithOfflineInfo() {
        return this.enclosedJsonObject().selectedRecordsWithOfflineInfo;
    }
    visitAndSetOfflineCacheStatus(status) {
        this.enclosedJsonObject().hasOfflineCache = status;
    }
    visitOfflineCacheStatus() {
        return this.enclosedJsonObject().hasOfflineCache ? this.enclosedJsonObject().hasOfflineCache : false;
    }
    /**
     *
     * @param dialogID
     * @param propertyName
     * @param request
     * @param propertyTimestamp
     *
     * Logic to maintain the edited properties like image/attachemnt in an record to sync back
     */
    addContentProperties(dialogID, propertyName, request, propertyTimestamp) {
        const indexFound = this.enclosedJsonObject().offlineContentProperties.findIndex(item => (item.dialogID === dialogID && item.propertyName === propertyName));
        const propertyObject = {
            request,
            dialogID,
            propertyName,
            propertyTimestamp
        };
        if (indexFound === -1) {
            this.enclosedJsonObject().offlineContentProperties.push(propertyObject);
        }
    }
    getContentProperties(dialogID) {
        const indexFound = this.enclosedJsonObject().offlineContentProperties.findIndex(item => item.dialogID === dialogID);
        return indexFound !== -1 ? this.enclosedJsonObject().offlineContentProperties[indexFound] : null;
    }
    visitAndClearContentProperties() {
        this.enclosedJsonObject().offlineContentProperties = [];
    }
}
