/**
 * BuildGlobals defines global status for the build.
 */
export default class BuildGlobals {
    static getBuildType() {
        return BuildGlobals.buildType;
    }
    static setBuildType(type) {
        BuildGlobals.buildType = type;
    }

    static isXHA() {
        return BuildGlobals.getBuildType() === 'xha';
    }

    static getOfflineCapable() {
        return BuildGlobals.offlineCapable;
    }
    static setOfflineCapable(type) {
        BuildGlobals.offlineCapable = type;
    }

    static isOfflineCapable() {
        return BuildGlobals.getOfflineCapable();
    }

    static setLogLevel(logLevel) {
        BuildGlobals.logLevel = logLevel;
    }

    static getLogLevel() {
        return BuildGlobals.logLevel;
    }

    static setTestComponent(testComponent) {
        BuildGlobals.testComponent = testComponent;
    }

    static getTestComponent() {
        return BuildGlobals.testComponent;
    }

    static setClientReleaseVersion(versionNumber) {
        BuildGlobals.clientReleaseVersion = versionNumber;
    }

    static getClientReleaseVersion() {
        return BuildGlobals.clientReleaseVersion;
    }

    static setClientSemanticVersion(versionNumber) {
        BuildGlobals.clientSemanticVersion = versionNumber;
    }

    static getClientSemanticVersion() {
        return BuildGlobals.clientSemanticVersion;
    }
}
