import { RecordSetVisitor } from "../proxy/RecordSetVisitor";
import { OfflineItemsVisitor } from "./OfflineItemsVisitor";
/**
 *
 */
export class OfflineItemsRecordSetVisitor extends RecordSetVisitor {
    constructor(value) {
        super(value);
    }
    // --- State Management Helpers --- //
    static emptyRecordSetVisitor() {
        return new OfflineItemsRecordSetVisitor(super.emptyRecordSetVisitor().enclosedJsonObject());
    }
    // --- State Management --- //
    updateBriefcaseColumnUsingSelections(selectedWorkPackageIds, breadCrumb) {
        const workPackages = this.enclosedJsonObject().records;
        if (workPackages && this.enclosedJsonObject().offlineMode === 'EXPLICIT') {
            for (const r of workPackages) {
                let inBriefcase = false;
                for (const selectedId of selectedWorkPackageIds) {
                    const targets = breadCrumb.filter(item => item !== undefined);
                    targets.push(r.id);
                    const target = targets.join('@');
                    if (target === selectedId || r.id === selectedId) {
                        inBriefcase = true;
                        break;
                    }
                }
                const briefcaseProperty = {
                    "name": "briefcase",
                    "annotations": breadCrumb,
                    "type": "hxgn.api.dialog.Property",
                    "value": inBriefcase
                };
                r.properties.push(briefcaseProperty);
            }
        }
    }
    trimRecordsetToSelections(selectedWorkPackageIds, breadCrumb) {
        const workPackages = this.enclosedJsonObject().records;
        const selectedItems = [];
        if (workPackages && this.enclosedJsonObject().offlineMode === 'EXPLICIT') {
            for (const r of workPackages) {
                let isSelectedItem = false;
                for (const selectedId of selectedWorkPackageIds) {
                    const targets = breadCrumb.filter(item => item !== undefined);
                    targets.push(r.id);
                    const target = targets.join('@');
                    if (target === selectedId || r.id === selectedId || selectedId.endsWith(target)) {
                        isSelectedItem = true;
                        break;
                    }
                }
                if (isSelectedItem) {
                    selectedItems.push(r);
                }
            }
            this.enclosedJsonObject().records = selectedItems;
        }
    }
    visitRecordAtId(id) {
        return super.visitRecordAtId(id);
    }
    *visitRecords() {
        let index = 0;
        while (index < this.enclosedJsonObject().records.length) {
            yield new OfflineItemsVisitor(this.enclosedJsonObject().records[index++]);
        }
    }
}
