import { View } from './View';
/**
 * A composition of View objects that, together, comprise a UI form.
 */
export class Form extends View {
    get isCompositeForm() {
        return this.formStyle === 'COMPOSITE_FORM';
    }
    get isFlowingLayout() {
        return this.formLayout && this.formLayout === 'FLOWING';
    }
    get isFlowingTopDownLayout() {
        return this.formLayout && this.formLayout === 'FLOWING_TOP_DOWN';
    }
    get isFourBoxSquareLayout() {
        return ((this.formLayout && this.formLayout === 'FOUR_BOX_SQUARE') ||
            (this.formLayout && this.formLayout === 'H(2,2)'));
    }
    get isHorizontalLayout() {
        return this.formLayout && this.formLayout === 'H';
    }
    get isMaintainableQuery() {
        return this.formStyle === 'MAINTAINABLE_QUERY';
    }
    get isOptionsFormLayout() {
        return this.formLayout && this.formLayout === 'OPTIONS_FORM';
    }
    get isTabsLayout() {
        return this.formLayout && this.formLayout === 'TABS';
    }
    get isThreeBoxOneLeftLayout() {
        return this.formLayout && this.formLayout === 'THREE_ONE_LEFT';
    }
    get isThreeBoxOneOverLayout() {
        return this.formLayout && this.formLayout === 'THREE_ONE_OVER';
    }
    get isThreeBoxOneRightLayout() {
        return this.formLayout && this.formLayout === 'THREE_ONE_RIGHT';
    }
    get isThreeBoxOneUnderLayout() {
        return this.formLayout && this.formLayout === 'THREE_ONE_UNDER';
    }
    get isTopDownLayout() {
        return this.formLayout && this.formLayout === 'TOP_DOWN';
    }
    get isTwoVerticalLayout() {
        return this.formLayout && this.formLayout.indexOf('H(2,V') > -1;
    }
}
