import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { pageController, serviceFactory, constants } from 'cv-react-core';
import { Log } from 'cv-dialog-sdk';

import Prompt from '../base/Prompt';
import Button, { BUTTON_VARIANT } from '../base/Button';
import Icon from '../base/Icon';
import IconButton from '../base/IconButton';
import TextLabel from '../base/TextLabel';
import getStyles from './styles/LogoutModal.styles';

const { lang } = serviceFactory;
const { ui: { LOGOUT_MODAL_ID, LOGOUT_MODAL_IS_OPEN }} = constants;

@observer
class LogoutModal extends PureComponent {
    static propTypes = {
        uiStore: PropTypes.object,
    };

    render() {
        const { uiStore } = this.props;

        // Get styles
        const {
            closeIcon,
            closeIconButton,
            infoIcon,
            logout,
            titleContainer,
        } = getStyles();
        const isOpen = uiStore.getValueForUIObject(LOGOUT_MODAL_ID, LOGOUT_MODAL_IS_OPEN) || false;
        const title = (
            <div style={ titleContainer }>
                <IconButton
                    contextStyles={ {
                        button: closeIconButton,
                        icon: closeIcon,
                    } }
                    iconName="cancel_circle"
                    iconSize={ 32 }
                    onClick={ this.handleLogoutCancel }
                    title={ lang.generic.cancel } />
            </div>
        );

        return (
            <Prompt
                contextStyles={ logout }
                isModalOpen={ isOpen }
                isTransparentOverlay={ false }
                title={ title }
                actions={ [
                    <Button
                        onClick={ this.handleLogoutCancel }
                        key="btnCancel"
                        variant={ BUTTON_VARIANT.TEXT }
                        text={ lang.mainMenu.logoutConfirmation.cancelBtn } />,
                    <Button
                        onClick={ this.handleLogout }
                        key="btnLogout"
                        text={ lang.mainMenu.logoutConfirmation.confirmBtn } />,
                ] }>
                <Icon
                    contextStyles={ {
                        icon: infoIcon,
                    } }
                    iconName="error_circle"
                    iconSize={ 42 } />
                <TextLabel contextStyles={ logout }>
                    { lang.mainMenu.logoutConfirmation.msg }
                </TextLabel>
            </Prompt>
        );
    }

    handleLogout = () => {
        const { uiStore } = this.props;
        uiStore.removeValueForUIObject(LOGOUT_MODAL_ID, LOGOUT_MODAL_IS_OPEN);
        pageController.logout()
            .catch((error) => {
                Log.warn(error.message);
            });
    };

    handleLogoutCancel = () => {
        const { uiStore } = this.props;
        uiStore.removeValueForUIObject(LOGOUT_MODAL_ID, LOGOUT_MODAL_IS_OPEN);
    };
}

export default withRouter(LogoutModal);
