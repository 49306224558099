import React, { Component } from 'react';
import * as PropTypes from 'prop-types';

// Styles
import getStyles from './Table.styles';

class Table extends Component {
    static propTypes = {
        /** Styles applied to every cell */
        cellStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.arrayOf(PropTypes.object),
        ]),

        /** Information for each column cell */
        columnModel: PropTypes.arrayOf(PropTypes.shape({
            /** Enable the column to wrap its contents */
            wrap: PropTypes.bool,
        })),

        /** Styles for this component */
        contextStyles: PropTypes.shape({
            /** Styles for the main container */
            container: PropTypes.object,

            /** Styles for each row container */
            row: PropTypes.object,

            /** Styles for each column container */
            column: PropTypes.object,
        }),

        /**
         * Multidimensional array of cells
         *  [  // rows
         *      [  // row
         *          e1,  // column
         *          e2,  // column
         *          e3,  // column
         *      ],
         *      [ e4, e5 ],
         *      [ e6, e7, e8 ],
         *  ]
         */
        rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.element)),

        /** Styles applied to every row */
        rowStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.arrayOf(PropTypes.object),
        ]),
        keyExtractor: PropTypes.func,
    };

    static defaultProps = {
        contextStyles: {},
        keyExtractor: () => {},
    };

    render() {
        const {
            contextStyles,
            columnModel,
        } = this.props;

        // Generate component styles
        this.styles = getStyles(contextStyles);

        if (columnModel.length === 1) {
            return this.renderSingleColomnTable();
        }
        // Reset total cells counter as it gets incremented on every row column cell render
        // This is kept track of to determine when all cell layout events have been fired
        this.totalCells = 0;

        // Generate container props
        const containerProps = {
            className: 'c-table__container',
            style: this.styles.container,
        };

        // Render layout container and contents
        return (
            <table { ...containerProps }>
                <tbody
                    className="c-table__body"
                    style={ this.styles.body }>
                    { this.renderRows() }
                </tbody>
            </table>
        );
    }

    renderSingleColomnTable = () => {
        const { rows, keyExtractor } = this.props;
        const rowProps = {
            className: 'c-single-row',
            style: {
                display: 'flex',
                flexDirection: 'row',
                padding: '.1em',
            },
        };
        const columnProps = {
            className: 'c-single-column',
            style: {
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
            },
        };
        return (rows || []).map((columns) => (
            <div
                { ...rowProps }
                key={ `row_${keyExtractor()}` }>
                { columns.map((cell) => (
                    <div
                        { ...columnProps }
                        key={ `column_${keyExtractor()}` }>
                        { cell }
                    </div>
                ))
                }
            </div>
        ));
    }

    /**
     * Renders a 'row' container for every item in the supplied array
     * @return {Array<React.ElementType>}
     */
    renderRows = () => {
        const {
            rows,
            rowStyle,
        } = this.props;
        const {
            row: rowStyles,
        } = this.styles;

        // Combine style arrays
        let combinedStyles = {};
        if (Array.isArray(rowStyle)) {
            rowStyle.forEach((style) => {
                combinedStyles = {
                    ...combinedStyles,
                    ...style,
                };
            });
        }
        else {
            combinedStyles = rowStyle;
        }

        // Generate row props
        const rowProps = {
            style: {
                ...combinedStyles,
                ...rowStyles,
            },
        };

        // Logic to extract the maxColumnsLength from complete rows
        this.maxColumnsLength = Math.max(...(rows || []).map((columns) => columns.length));

        // Render row with its columns and cell contents
        return (rows || []).map((columns, rowIndex) => (
            /* eslint-disable react/no-array-index-key */
            <tr
                className="c-table__row"
                { ...rowProps }
                key={ `row_${rowIndex}` }>
                { this.renderColumns(columns, rowIndex) }
            </tr>
            /* eslint-disable react/no-array-index-key */
        ));
    };

    /**
     * Renders a 'column cell' container for every item in the supplied array.
     *  column cells get a layout callback to collect their natural dimensions
     *  collected column dimensions are used, if available, to size column cells in a table-like format
     * @param {Array} columns
     * @param {Number} rowIndex
     * @return {Array<React.ElementType>}
     */
    renderColumns = (columns = [], rowIndex) => { // eslint-disable-line
        const {
            cellStyle,
            columnModel,
        } = this.props;

        this.columnsCount = columns.length;
        return columns.map((cell, columnIndex) => {
            // Begin column props
            const columnProps = {
                style: {
                    textOverflow: 'ellipsis',
                    ...cellStyle,
                },
            };

            if (
                // If last column
                columnIndex === this.columnsCount - 1 ||

                // Or if set to wrap
                (
                    columnModel &&
                    columnModel[columnIndex] &&
                    columnModel[columnIndex].wrap
                )
            ) {
                // Flex to take up rest of space
                // columnProps.style.width = '100%';
            }
            else {
                // TODO: Hack until we can drive this from salt
                columnProps.style.whiteSpace = 'nowrap';
            }

            // set the colspan length based on the number of maxColumnslength extracted.
            if ((columnIndex + 1) === this.columnsCount && this.columnsCount < this.maxColumnsLength) {
                columnProps.colSpan = this.maxColumnsLength;
            }

            // Render column with its cell contents
            return (
                <td
                    className="c-table__cell"
                    { ...columnProps }
                    key={ `column_${columnIndex}_${this.totalCells}` }>
                    { cell }
                </td>
            );
        });
    };
}

export default Table;
