import React, { Component } from 'react';
import componentFactory from '../componentFactory';
import componentPropTypes from './abstractComponentPropTypes';

// Note: these should be a subset of abstractComponentPropTypes
const propDefinitions = {
    style: componentPropTypes.style,
    name: componentPropTypes.name,
    value: componentPropTypes.value,
    tipText: componentPropTypes.tipText,
    isReadMode: componentPropTypes.isReadMode,
    isFocused: componentPropTypes.isFocused,
    onValueChanged: componentPropTypes.onValueChanged,
    onLoadOptionValues: componentPropTypes.onLoadOptionValues,
    xStyle: componentPropTypes.xStyle,
    optionValues: componentPropTypes.optionValues,
};
export default class DropDownTextwithImageField extends Component {
    static propTypes = propDefinitions;

    render() {
        const {
            style,
            tipText,
            isReadMode,
            isFocused,
            onValueChanged,
            xStyle,
        } = this.props;

        const dropDownFieldProps = {
            style,
            value: this.getValue(),
            tipText,
            isReadMode,
            isFocused,
            onValueChanged,
            xStyle,
            optionValues: this.resolveOptionValues(),
        };
        return React.createElement(componentFactory.getPlatformPropertyComponent('dropDownField'), { ...dropDownFieldProps });
    }

    componentDidMount() {
        const { onLoadOptionValues } = this.props;
        if (onLoadOptionValues) onLoadOptionValues();
    }

    getValue = () => {
        // Value should always be either code -> CodeRef/objectId -> ObjectRef/plain string. It will not be a description.
        const { value } = this.props;
        const optionValues = this.resolveOptionValues();
        return optionValues.find((option) => option.value === value);
    }

    resolveOptionValues = () => {
        const { optionValues } = this.props;
        if (this.options) return this.options;
        if (optionValues.length) {
            this.options = optionValues.map((optionValue) => {
                // image source is contained in the label value. Need to split the value out
                // and create a new object with an image source.
                const imageData = optionValue.label.split(/:(.*)/);
                return {
                    imageSrc: imageData[1],
                    label: imageData[0],
                    value: optionValue.value,
                };
            });
            return this.options;
        }
        return [];
    }
}
