import React, { forwardRef } from 'react';
import * as PropTypes from 'prop-types';
import MUIDrawer from '@material-ui/core/Drawer';
import makeStyles from '@material-ui/core/styles/makeStyles';

import getStyles from './AppDrawer.styles';

/**
 * Enum of drawer close reasons
 * @typedef {Enumerator} CloseReasons
 * @enum {String}
 */
const CLOSE_REASONS = {
    ESCAPE: 'escapeKeyDown',
    BACKDROP: 'backdropClick',
    SELECTION: 'itemSelection',
    TAB: 'tabKeyDown',
};

const VARIANTS = {
    PERMANENT: 'permanent',
    PERSISTENT: 'persistent',
    TEMPORARY: 'temporary',
};

/**
 * A Drawer displays a list of choices on a temporary surface. It appears when the user interacts with a button, or other control.
 * @see https://material-ui.com/components/drawers/
 */
const AppDrawer = forwardRef((props, ref) => {
    const {
        children,
        contextStyles,
        open,
        onClose,
        testID,
        variant,
    } = props;

    // Override core styles with context styles, separating MUI styles
    const {
        ...muiStyles
    } = getStyles(contextStyles);

    // Create dynamic class names and injected DOM styles for MUI component
    // * makeStyles returns a function
    const styles = makeStyles(muiStyles)();

    return (
        <MUIDrawer
            classes={ { paper: styles.container } }
            className="c-app-drawer__container"
            data-test-id={ `${testID}__app-drawer__container` }
            onClose={ onClose }
            open={ open }
            ref={ ref }
            variant={ variant }>
            { children }
        </MUIDrawer>
    );
});

AppDrawer.propTypes = {
    /** Content to be displayed inside the AppDrawer */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,

    /** Extended styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the container surrounding drawer content */
        container: PropTypes.object,
    }),

    /**
     * Called after the drawer is closed
     * @param {Object} event
     * @param {String} reason
     */
    onClose: PropTypes.func,

    /** Defines type of drawer */
    variant: PropTypes.PropTypes.oneOf([
        'permanent',
        'persistent',
        'temporary',
    ]),

    /** Sets drawer visibility */
    open: PropTypes.bool.isRequired,

    /** Id used for testing */
    testID: PropTypes.string,
};

AppDrawer.defaultProps = {
    contextStyles: {},
    testID: 'AppDrawer',
};

AppDrawer.displayName = 'AppDrawer';

export {
    CLOSE_REASONS,
    VARIANTS,
};
export default AppDrawer;
