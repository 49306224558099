import { action, observable } from 'mobx';

/*
    This is created ONCE per FormDialog
    i.e. The dialog store can reliably be assumed to be the top-level Form Dialog
*/
export default class SaltStore {
    @observable references = new Map();
    @observable documentCache = new Map();

    constructor(dialogStore, uiStore, globals) {
        this.dialogStore = dialogStore;
        this.uiStore = uiStore;
        this.globals = globals;
    }

    getRef = (name) => {
        return this.references.get(name);
    };

    @action setRef = (name, value) => {
        this.references.set(name, value);
    };

    getDialogStoreForViewId = (viewId) => {
        if (viewId) {
            return this.dialogStore.getStoreForViewPersistentId(viewId);
        }
        return this.dialogStore;
    };

    get sessionStore() {
        return this.dialogStore.sessionStore;
    }

    getSaltById = (saltId) => {
        return this.documentCache.get(saltId);
    }

    // @TODO retrieve a stored salt snippet - this just
    // needs a server side implemention (currently we cannot store salt outside of attaching it to a view)
    @action loadSaltById = async (saltId) => {
        // This is a placeholder - needs impl
        // NOTE: it would be nice if we could simply retrieve this by URL containing the id
        const document = await new Promise({ saltId });
        this.documentCache.set(saltId, document);

    }
}
