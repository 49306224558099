import React from 'react';
import * as PropTypes from 'prop-types';
import { PatternFormat } from 'react-number-format';

const XaltFormattedInput = (props) => {
        const {
            format,
            inputRef,
            placeholder,
            mask,
            style,
            className,
            value,
            onChange,
        } = props;

        const xaltFormattedInputProps = {
            format,
            getInputRef: inputRef,
            onValueChange: (values) => {
                onChange({
                    target: {
                        value: values.formattedValue,
                    },
                });
            },
            placeholder,
            mask,
            style,
            className,
            value,
        };

        return React.createElement(PatternFormat, { ...xaltFormattedInputProps } );
};

XaltFormattedInput.propTypes = {
    /** Reference to the input element */
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),

    onChange: PropTypes.func,

    /** Placeholder text for input field */
    placeholder: PropTypes.string,

    mask: PropTypes.arrayOf(PropTypes.string),

    format: PropTypes.string,

    value: PropTypes.string,

    className: PropTypes.string,

    style: PropTypes.object,
};

export default XaltFormattedInput;
