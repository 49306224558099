import React from 'react';
import * as PropTypes from 'prop-types';
import MUILinearProgress from '@material-ui/core/LinearProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';

import getStyles from './LinearProgress.styles';

const LinearProgress = (props) => {
    const {
        contextStyles,
        testID,
    } = props;

    const {
        root,
        ...muiStyles
    } = getStyles(contextStyles);
    const styles = makeStyles(muiStyles)();

    const barProps = {
        classes: {
            bar: styles.bar,
        },
        style: root,
        className: 'c-linear-progress',
    };
    if (testID) { barProps['data-test-id'] = `${testID}__linear_progress_bar`; }

    return <MUILinearProgress { ...barProps } />;
};

LinearProgress.propTypes = {
    contextStyles: PropTypes.shape({
        bar: PropTypes.object,
    }),
    testID: PropTypes.string,
};

LinearProgress.defaultProps = {
    contextStyles: {},
};

export default LinearProgress;
