import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import {
    XaltBox,
    XaltTextLabel,
    XaltExpandMore,
    XaltArrowUpward,
    XaltArrowDownward,
    XaltSort,
    XaltLock,
    XaltLockOpen,
    XaltFilter,
} from 'xalt-react-atoms';

import Menu from '../Menu';
import MenuItem from '../MenuItem';
import lang from '../../../nls/i18n';

import getStyles from './TableHeaderCellContentMenu.styles';

export const SORT_DIRECTIONS = {
    ASC: 'asc',
    DESC: 'desc',
    NONE: null,
};

const sortDescriptor = (isSortActive) => isSortActive ? lang.searchSort.customSortActive : lang.searchSort.customSort;
const lockDescriptor = (isColumnLocked) => isColumnLocked ? lang.searchSort.unlockColumn : lang.searchSort.lockColumn;

const lockIcon = (isColumnLocked) => isColumnLocked ? XaltLockOpen : XaltLock;

const menuItemBuilder = ({ isSortActive, isCustomSortEnabled, isColumnLocked, onHandleColumnFrozen, onHandleSorting, onHandleDirectionalSorting }) => {
    const menuItems = [
        { key: 'asc', description: lang.searchSort.sortAscending, icon: XaltArrowUpward, action: (event) => onHandleDirectionalSorting(event, SORT_DIRECTIONS.ASC) },
        { key: 'des', description: lang.searchSort.sortDescending, icon: XaltArrowDownward, action: (event) => onHandleDirectionalSorting(event, SORT_DIRECTIONS.DESC) },
        { key: 'sort', description: sortDescriptor(isSortActive), icon: XaltSort, action: onHandleSorting },
        { key: 'lock', description: lockDescriptor(isColumnLocked), icon: lockIcon(isColumnLocked), action: onHandleColumnFrozen },
    ];
    // filter out the sort menu item only if the custom sort feature isn't available in the global menu
    return menuItems.filter((menuItem) => (!(menuItem.key === 'sort' && !isCustomSortEnabled)));
};

const renderActionButton = (priority, isAscending, isFiltered) => {
    const {
        actionButtonIcon,
        actionButtonWrapper,
        actionButtonLabel,
        actionButtonExpand,
    } = getStyles();

    if (priority === undefined && !isFiltered) {
        return <XaltExpandMore style={ actionButtonExpand } />;
    }

    const ArrowIcon = isAscending ? XaltArrowUpward : XaltArrowDownward;

    return (
        <XaltBox style={ { ...actionButtonWrapper } }>
            {
                isFiltered &&
                    <XaltFilter style={ actionButtonIcon } />
            }
            {
                priority !== undefined &&
                <>
                    <ArrowIcon style={ actionButtonIcon } />
                    <XaltTextLabel style={ actionButtonLabel }>
                        { priority + 1 }
                    </XaltTextLabel>
                </>
            }
        </XaltBox>
    );
};

const TableHeaderCellContentMenu = ({ isSortActive, isCustomSortEnabled, isColumnLocked, isAscending, priority, onHandleColumnFrozen, onHandleSorting, onHandleDirectionalSorting, isFiltered }) => {
    const {
        menuItem: menuItemStyle,
        rowWrapper,
        icon,
        title,
        menu: menuStyle,
        menuRow,
        menuRowHover,
    } = getStyles();

    const menuItems = useMemo(() => menuItemBuilder({ isSortActive, isCustomSortEnabled, isColumnLocked, onHandleColumnFrozen, onHandleSorting, onHandleDirectionalSorting }), [
        isSortActive,
        isCustomSortEnabled,
        isColumnLocked,
        onHandleColumnFrozen,
        onHandleSorting,
        onHandleDirectionalSorting,
    ]);

    return (
        <Menu
            contextStyles={ { container: menuStyle } }
            button={ renderActionButton(priority, isAscending, isFiltered) }>
            {
                menuItems.map(({ key, description, icon: MenuIcon, action: onHandleAction }) =>
                    (
                        <MenuItem
                            contextStyles={ { container: menuRow, focusContainer: menuRowHover } }
                            key={ `menu_item_${key}` }
                            style={ menuItemStyle }
                            text={
                                <XaltBox style={ rowWrapper }>
                                    <MenuIcon style={ icon } />
                                    <XaltTextLabel style={ title }>
                                        { description }
                                    </XaltTextLabel>
                                </XaltBox>
                            }
                            onClick={ onHandleAction } />
                    )
                )
            }
        </Menu>
    );
};

TableHeaderCellContentMenu.propTypes = {
    isSortActive: PropTypes.bool,
    isCustomSortEnabled: PropTypes.bool,
    isColumnLocked: PropTypes.bool,
    priority: PropTypes.number,
    isAscending: PropTypes.bool,
    onHandleColumnFrozen: PropTypes.func,
    onHandleSorting: PropTypes.func,
    onHandleDirectionalSorting: PropTypes.func,
    isFiltered: PropTypes.bool,
};

export default TableHeaderCellContentMenu;
