/**
 */
export class Download_Manager_EnterOfflineMode_REDIRECTION {
    static copyOfResponse() {
        return JSON.parse(JSON.stringify(this.RESPONSE));
    }
}
Download_Manager_EnterOfflineMode_REDIRECTION.PATH = 'tenants/${tenantId}/sessions/${sessionId}/dialogs/10/actions/alias_EnterOfflineMode';
Download_Manager_EnterOfflineMode_REDIRECTION.BODY = {
    "pendingWrites": {
        "id": "1",
        "properties": [],
        "type": "hxgn.api.dialog.Record"
    },
    "type": "hxgn.api.dialog.ActionParameters"
};
Download_Manager_EnterOfflineMode_REDIRECTION.RESPONSE = {
    "dialogMode": "CREATE",
    "referringObject": {
        "dialogType": "hxgn.api.dialog.EditorDialog",
        "dialogMode": "READ",
        "dialogAlias": "Download_Manager_Details",
        "dialogProperties": {},
        "actionId": "alias_EnterOfflineMode",
        "rootDialogName": "Download_Manager_FORM",
        "type": "hxgn.api.dialog.ReferringDialog",
        "dialogId": "10",
        "dialogName": "Download_Manager_Details",
        "rootDialogId": "8"
    },
    "sessionId": "${sessionId}",
    "type": "hxgn.api.dialog.DialogRedirection",
    "dialogId": "13",
    "viewMode": "READ",
    "dialogClassName": "com.catavolt.app.extender.dialog.ZZRunCatavoltSatelliteActionEditorModel",
    "domainClassName": "com.catavolt.app.extender.domain.ZZRunCatavoltSatelliteAction",
    "dialogType": "hxgn.api.dialog.EditorDialog",
    "recordId": "null",
    "dialogAlias": "Download_Manager_EnterOfflineMode_FORM",
    "tenantId": "${tenantId}",
    "refreshNeeded": false,
    "id": "13",
    "dialogName": "Download_Manager_EnterOfflineMode_FORM",
    "selectedViewId": "AAABACcaAAAAAGBT1241836571:477:139058878:13",
    "dialogDescription": "New Run Action"
};
