/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { XaltDropDown, XaltDropDownMultiSelect, XaltBox } from 'xalt-react-atoms';

import getStyles from './styles/Component.styles';

class FilterSelector extends Component {
    static defaultProps = {
        // styles: {},
        value: '',
        items: [],
        onChange: () => {},
        onLoadAvailableValues: () => {},
    }

    static propTypes = {
        // styles: PropTypes.object,
        value: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        isMultiselect: PropTypes.bool,
        items: PropTypes.array,
        onChange: PropTypes.func,
        onLoadAvailableValues: PropTypes.func,
    }

    constructor(props) {
        super(props);
        this.dropDownRef = React.createRef();
    }

    render() {
        const {
            isMultiselect,
            value,
            onChange,
            items,
        } = this.props;
        const {
            dropdownBox,
            dropDownMultiSelectField,
            dropdown,
            dropdownMultiSelectInput,
            option,
            expandIcon,
            optionSelected,
            optionHover } = getStyles();
        const DropdownComponent = isMultiselect ? XaltDropDownMultiSelect : XaltDropDown;

        return (
            <XaltBox style={ isMultiselect ? dropDownMultiSelectField : dropdownBox }>
                <DropdownComponent
                    ref={ this.dropDownRef }
                    style={ isMultiselect ? dropdownMultiSelectInput : dropdown }
                    focusStyle={ isMultiselect ? { flexDirection: 'column', flexWrap: 'wrap', alignItems: 'flex-start' } : {} }
                    disableClearable
                    onValueChanged={ onChange }
                    onOpen={ this.handleOpen }
                    value={ value }
                    items={ items }
                    optionStyles={ option }
                    expandIconStyles={ expandIcon }
                    selectedStyles={ optionSelected }
                    hoverStyles={ optionHover } />
            </XaltBox>
        );
    }

    handleOpen = () => {
        const { onLoadAvailableValues } = this.props;
        onLoadAvailableValues();
    }
}

export default FilterSelector;