import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Panel as PanelBase } from 'cv-library-react-web';

import PanelHeader from '../PanelHeader/PanelHeader';

/**
 * @typedef MenuItemBase
 * @type {object}
 * @property {string} id        - Use as identifier on events
 * @property {string} label     - Button text
*/

/**
 * @typedef MenuItem
 * @extends MenuItemBase
 * @type {object}
 * @property {string} icon      - A valid url for <Image source={icon} /> to be displayed to left of menu label
*/

/**
 * @typedef MenuActionCallback
 * @type {function}
 * @param {MenuItem} item           - An abbreviated menu item object like { label, id }
*/

/**
 * @typedef ContextStyles
 * @property {object} container         - Additional styles for the header outermost <View> container
 * @property {object} iconAndTitleContainer - Additional styles for the <View> container around the title icon and title
 * @property {object} iconTextContainer - Additional styles for the title icon <Text> wrapper
 * @property {object} icon              - Additional styles for the title <Icon>
 * @property {object} titleText         - Additional styles for the title <Text>
*/

/**
 * @typedef Props
 * @property {string} title     - Displayed panel title
 * @property {string} iconCls   - rendered title icon <Icon name={iconCls} /> from react-native-vector-icons/MaterialIcons:
 *                                  RE: https://fonts.google.com/icons?selected=Material+Icons
 * @property {array} group      - 2d array of menu objects that will be rendered across the top of the panel
 * @property {array} menu       - Array of menu objects the will be rendered in the upper-right hamburger
 * @property {MenuActionCallback} onMenuAction - Returns "id" property of pressed menu object
 * @property {object} onGroupAction
 * @property {React.ReactNode|React.ReactNodeArray} quickSearch - Quick search React node to render
 * @property {ContextStyles} contextStyles - Additional styles
 *
 * @static
 * @memberOf Panel
*/

export const defaultProps = {
    title: '',
    groups: [],
    menu: [],
    markupTitle: false,
    contextStyles: {},
};

export const propTypes = {
    children: PropTypes.node,
    contextStyles: PropTypes.object,
    groups: PropTypes.array,
    iconCls: PropTypes.string,
    markupTitle: PropTypes.bool,
    menu: PropTypes.array,
    onGroupAction: PropTypes.func,
    onMenuAction: PropTypes.func,
    popContextMenu: PropTypes.bool,
    quickSearch: PropTypes.node,
    title: PropTypes.string,
};

/**
 * A Panel stacks a CvPanelHeader over a content area.
 *
 * Sample data:
 *   const m1 = { id: 'action1', icon: 'https://vignette.wikia.nocookie.net/animalcrossing/images/2/2e/Star.png' };
 *   const m2 = { id: 'action2', label: 'Menu 2', icon: 'https://vignette.wikia.nocookie.net/animalcrossing/images/2/2e/Star.png' };
 *   const m3 = { id: 'action3', label: 'Menu 3' };
 *   const m4 = { id: 'action4', label: 'Menu 4' };
 *
 *   Resulting properties:
 *     const groups = [ [ m1, m2 ], [ m3, m4 ] ];
 *     const menu = [ m1, m2, m3 ];
 *     const onMenuAction = (id) => { this.handleOnMenuAction(id); };
 *
 *                     <Panel
 *                         title="My Title"
 *                         menu={ menu }
 *                         groups={ groups }
 *                         onMenuAction={ onMenuAction } />
*/
class Panel extends Component {
    /**
     * @static
     * @memberOf Panel
     * @type {Props}
     */
    static defaultProps = defaultProps;

    static propTypes = propTypes;

    render() {
        const {
            contextStyles,
            children,
        } = this.props;

        return (
            <PanelBase
                contextStyles={ contextStyles }
                header={ this.renderHeader() }>
                { children }
            </PanelBase>
        );
    }

    renderHeader = () => {
        const {
            groups,
            iconCls,
            markupTitle,
            menu,
            popContextMenu,
            quickSearch,
            title,
        } = this.props;

        if (markupTitle) {
            return null;
        }

        return (
            <PanelHeader
                groups={ groups }
                iconCls={ iconCls }
                menu={ menu }
                onGroupAction={ this.handleGroupAction }
                onMenuAction={ this.handleMenuAction }
                popContextMenu={ popContextMenu }
                quickSearch={ quickSearch }
                title={ title } />
        );
    };

    handleGroupAction = (groupEvent, nativeEvent) => {
        const {
            onGroupAction,
        } = this.props;

        if (onGroupAction) {
            onGroupAction(groupEvent, nativeEvent);
        }
    };

    handleMenuAction = (menu, nativeEvent) => {
        const {
            onMenuAction,
        } = this.props;

        if (onMenuAction) {
            onMenuAction(menu, nativeEvent);
        }
    };
}

export default Panel;
