/* eslint-disable class-methods-use-this */
import serviceFactory from './serviceFactory';
/**
 * Class: RedirectRegistry
 * Description: This singleton will be used for application routing. The purpose for this class is
 * too provide components the opportunity to capture a route in a callback and determine if they want to handle the 
 * routing or allow the behavior of the route manage the callback. This is managed by the component either
 * returning the redirection to the route as an indicator it doesn't want to do anything or by returning null letting
 * the route know it managed the routing.
 */
class OnTransitionRegistry {
    subscriber = {
        classIdentifier: '',
        callback: null,
    };

    subscribe(classIdentifier, callback) {
        if (this.subscriber.classIdentifier !== classIdentifier && this.subscriber.callback !== null) {
            throw new Error(`${serviceFactory.lang.errors.onTransitionRegistryMessage} ${this.subscriber.classIdentifier}`);
        }
        this.subscriber.classIdentifier = classIdentifier;
        this.subscriber.callback = callback;
    }

    unsubscribe() {
        this.subscriber.callback = null;
    }

    notify = (redirection) => {
        if (this.subscriber && this.subscriber.callback) return this.subscriber.callback(redirection);
        return redirection;
    }
}

const onTransitionRegistry = new OnTransitionRegistry();
export default onTransitionRegistry;
