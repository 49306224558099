import Layout from './Layout';

const borderRadius = (layout) => {
    if (layout.borderRadius) {
        const style = {};
        const multi = Layout.parseMultiPartValue(layout.borderRadius);
        switch (multi.length) {
            case 2:
                style.borderTopLeftRadius = Layout.parseValue(multi[0]);
                style.borderBottomRightRadius = Layout.parseValue(multi[0]);
                style.borderBottomLeftRadius = Layout.parseValue(multi[1]);
                style.borderTopRightRadius = Layout.parseValue(multi[1]);
                break;
            case 3:
                style.borderTopLeftRadius = Layout.parseValue(multi[0]);
                style.borderTopRightRadius = Layout.parseValue(multi[1]);
                style.borderBottomLeftRadius = Layout.parseValue(multi[1]);
                style.borderBottomRightRadius = Layout.parseValue(multi[2]);
                break;
            case 4:
                style.borderTopLeftRadius = Layout.parseValue(multi[0]);
                style.borderTopRightRadius = Layout.parseValue(multi[1]);
                style.borderBottomRightRadius = Layout.parseValue(multi[2]);
                style.borderBottomLeftRadius = Layout.parseValue(multi[3]);
                break;
            default: // Just work with the first value
                style.borderRadius = Layout.parseValue(multi[0]);
        }
        return style;
    }
    return {};
};

export default borderRadius;
