import { View } from './View';
/**
 * A view describing how to display a collection of data as a line graph, pie chart, bar chart, etc.
 */
export class Graph extends View {
}
Graph.GRAPH_TYPE_CARTESIAN = 'GRAPH_TYPE_BAR';
Graph.GRAPH_TYPE_PIE = 'GRAPH_TYPE_PIE';
Graph.PLOT_TYPE_BAR = 'BAR';
Graph.PLOT_TYPE_BUBBLE = 'BUBBLE';
Graph.PLOT_TYPE_LINE = 'LINE';
Graph.PLOT_TYPE_SCATTER = 'SCATTER';
Graph.PLOT_TYPE_STACKED = 'STACKED';
Graph.PLOT_TYPE_SWIMLANE = 'SWIMLANE';
