// Core component styles
const coreStyles = {
    appBar: {
    },

    container: {
    },

    indicator: {
    },

    selectedTab: {
        // For a 'selected' tab
        // '&.Mui-selected': {
        //     color: '#0f0',
        // },
    },

    tab: {
    },

    tabs: {
    },
};

/**
 * Helper method to get styles for the DropDown
 * @param {Object} contextStyles - Custom styles for the DropDown component
 */
const getStyles = (contextStyles = {}) => {
    return {
        appBar: {
            ...coreStyles.appBar,
            ...contextStyles.appBar,
        },

        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        indicator: {
            ...coreStyles.indicator,
            ...contextStyles.indicator,
        },

        selectedTab: {
            ...coreStyles.tab,
            ...contextStyles.tab,
            ...coreStyles.selectedTab,
            ...contextStyles.selectedTab,
        },

        tab: {
            ...coreStyles.tab,
            ...contextStyles.tab,
        },

        tabs: {
            ...coreStyles.tabs,
            ...contextStyles.tabs,
        },
    };
};

export default getStyles;
