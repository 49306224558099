import React from 'react';
import * as PropTypes from 'prop-types';

import { default as TextLabel } from '../TextLabel/TextLabel';
import { default as Button } from '../Button/Button';

import getStyles from './OAuthForm.styles';

const OAuthForm = (props) => {
    const {
        contextStyles,
        errors,
        isLoggingIn,
        oAuthBtnText,
        onOAuthPress,
    } = props;

    const styles = getStyles(contextStyles);

    return (
        <div style={ styles.container }>
            <h2 style={ styles.heading }>Login</h2>
            { errors && errors.main &&
                <TextLabel
                    error
                    contextStyles={ {
                        container: styles.error,
                    } }>
                    { errors.main }
                </TextLabel>
            }
            <Button
                contextStyles={ {
                    container: styles.button,
                    primaryHover: styles.buttonHover,
                    text: styles.buttonText,
                } }
                onClick={ onOAuthPress }
                disabled={ isLoggingIn }
                text={ oAuthBtnText } />
        </div>
    );
};

OAuthForm.propTypes = {
    contextStyles: PropTypes.shape({
        button: PropTypes.object,
        buttonHover: PropTypes.object,
        buttonText: PropTypes.object,
        container: PropTypes.object,
        error: PropTypes.object,
        heading: PropTypes.object,
    }),
    errors: PropTypes.shape({
        main: PropTypes.string,
    }),
    isLoggingIn: PropTypes.bool,
    onOAuthPress: PropTypes.func,
    oAuthBtnText: PropTypes.string,
};

OAuthForm.defaultProps = {
    contextStyles: {},
    isLoggingIn: false,
    onOAuthPress: Promise.resolve(),
    oAuthBtnText: '',
};

export default OAuthForm;
