const coreStyles = {
    modal: {
        padding: '0px',
        minWidth: '25vw',
    },
    closeIconButton: {
        padding: '0px',
    },
    closeButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    closeIcon: {
        color: '#000000',
        fontSize: '32px',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        boderRadius: 60,
        backgroundColor: '#ffffff',
        padding: '20px 16px 34px 16px',
        width: '490px',
    },
    aboutTitle: {
        fontFamily: 'Roboto',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontSize: '18px',
        lineHeight: '26px',
        letterSpacing: '0.02em',
        color: '#4A4949',
        marginBottom: '18px',
    },
    menuImage: {
        height: '48px',
        width: '48px',
        objectFit: 'contain',
        marginBottom: '12px',
    },
    versionContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: '10px',
    },
    versionValue: {
        fontFamily: 'Roboto',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        letterSpacing: '0.02em',
        color: '#4A4949',
        whiteSpace: 'pre',
        textAlign: 'center',
    },
};

const getStyles = (contextStyles = {}) => {
    return {
        modal: {
            ...coreStyles.modal,
            ...contextStyles.modal,
        },
        closeButtonContainer: {
            ...coreStyles.closeButtonContainer,
            ...contextStyles.closeButtonContainer,
        },
        closeIcon: {
            ...coreStyles.closeIcon,
            ...contextStyles.closeIcon,
        },
        closeIconButton: {
            ...coreStyles.closeIconButton,
            ...contextStyles.closeIconButton,
        },
        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },
        menuImage: {
            ...coreStyles.menuImage,
            ...contextStyles.menuImage,
        },
        aboutTitle: {
            ...coreStyles.aboutTitle,
            ...contextStyles.aboutTitle,
        },
        versionContainer: {
            ...coreStyles.versionContainer,
            ...contextStyles.versionContainer,
        },
        versionValue: {
            ...coreStyles.versionValue,
            ...contextStyles.versionValue,
        },
    };
};

export default getStyles;