import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { default as TextLabel } from '../TextLabel/TextLabel';
/**
 * An MenuSeparator is a visual separator between menu items.
 */
export default class MenuSeparator extends PureComponent {
    static propTypes = {
        /** Context Styles for styling the separator */
        contextStyles: PropTypes.shape({
            separatorNoText: PropTypes.object,
            separatorLineWithText: PropTypes.object,
            separatorNoLineWithText: PropTypes.object,
            text: PropTypes.object,
        }),

        /** Text used as a divider label */
        label: PropTypes.string,
        containsLineSeparator: PropTypes.bool,
    };

    static defaultProps = {
        contextStyles: {
            separatorNoText: {},
            separatorLineWithText: {},
            separatorNoLineWithText: {},
            text: {},
        },
    };

    render() {
        const {
            label,
            contextStyles,
            containsLineSeparator,
        } = this.props;
        const { separatorNoText } = contextStyles;
        if (label) {
            const containerStyle = containsLineSeparator ? contextStyles.separatorLineWithText : contextStyles.separatorNoLineWithText;
            const { text: textStyle } = contextStyles;
            return (
                <TextLabel
                    contextStyles={ {
                        container: containerStyle,
                        text: textStyle,
                    } }
                    numberOfLines={ 1 }>
                    { label }
                </TextLabel>
            );
        }
        return (
            <div
                className="c-menu-separator__container"
                style={ separatorNoText } />
        );
    }
};
