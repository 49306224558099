import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import componentFactory from './componentFactory';
import SaltComponent from './SaltComponent';
import serviceFactory from '../services/serviceFactory';
import searchController from '../controllers/searchController';

@observer
export default class QuickSearch extends SaltComponent {
    static propTypes = {
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        onError: PropTypes.func,
        viewId: PropTypes.string,
    };

    render() {
        const {
            onError,
        } = this.props;

        const { searchDialogStore, listDialogStore } = this.scopedContext;
        if (!searchDialogStore) {
            searchController.openQuickSearch({ listDialogStore, onError });
        }

        const resolvedProps = this.resolveProperties();
        const {
            style,
            xStyle,
        } = resolvedProps;
        let isDisabled = true;
        let searchInProgress = false;
        if (searchDialogStore) {
            searchInProgress = searchDialogStore.getSubmitInProgress();
            isDisabled = !searchDialogStore.isQuickSearchAllowed();
        }

        const { lang } = serviceFactory;
        const placeholderText = lang.list.quickSearchPlaceHolder;
        const searchTerm = searchDialogStore ? searchDialogStore.getKeywordSearchValueProperty() : undefined;
        const props = {
            onClear: this.handleClear,
            onSearchTermChange: this.handleSearchTermChange,
            onSubmit: this.handleSubmit,
            disabled: isDisabled,
            searchInProgress,
            searchTerm,
            placeholderText,
            style,
            xStyle,
        };

        return React.createElement(componentFactory.getPlatformComponent('quickSearch'), props);
    }

    get scopedContext() {
        const { saltStore, viewId, onError, scopeManager } = this.context;
        const listDialogStore = saltStore.getDialogStoreForViewId(viewId);
        const { uiStore } = saltStore;
        const { searchDialogStore } = listDialogStore;
        return { searchDialogStore, listDialogStore, viewId, onError, uiStore, scopeManager };
    }

    handleSubmit = () => {
        const { listDialogStore, onError, uiStore } = this.scopedContext;
        const controllerObject = { listDialogStore, onError, uiStore };
        searchController.submitChanges(controllerObject);
    };

    handleClear = () => {
        const { listDialogStore, onError } = this.scopedContext;
        const controllerObject = { listDialogStore, keyword: '', onError };
        searchController.setSearchKeyword(controllerObject);
        const { searchDialogStore } = listDialogStore;
        const searchTerm = !!searchDialogStore.getKeywordSearchValueProperty(false)?.value;
        if (searchTerm) {
            this.handleSubmit();
        }
    };

    handleSearchTermChange = (term) => {
        this.setSearchKeywordValue(term);
    };

    setSearchKeywordValue = (keyword) => {
        const { listDialogStore, onError } = this.scopedContext;
        const controllerObject = { listDialogStore, keyword, onError };

        return searchController.setSearchKeyword(controllerObject);
    }
}
