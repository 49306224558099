import { rootStore } from 'cv-react-core';

const getStyles = (style) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();
    return {
        container: {
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            ...style,
        },
        titleContainer: {
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
        },
        titleTextColumn: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        titleComponent: {
            container: {
                display: 'flex',
                flexDirection: 'row',
                flexGrow: 1,
                padding: '5px',
            },
            text: {
                ...theme.fonts.panelTitle,
                fontFamily: 'Roboto',
                fontSize: '20px',
                lineHeight: '23px',
                color: 'rgba(0, 0, 0, 0.9)',
            },
        },
        titleButtonColumn: {
            display: 'flex',
            flexDirection: 'column',
        },
        tabContainer: {
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
            overflow: 'hidden',
        },
        buttonContainer: {
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'flex-end',
            flexDirection: 'row',
            padding: '10px',
        },
        buttons: {
            container: {
                paddingLeft: '10px',
            },
            icon: {
                fontSize: '24px',
            },
            primary: {
                width: '78px',
                height: '40px',
                padding: '12px 20px',
            },
            secondary: {
                width: '78px',
                height: '40px',
                padding: '12px 20px',
            },
            tertiary: {
                width: '78px',
                height: '40px',
                padding: '12px 20px',
            },
        },
    };
};

export default getStyles;
