/*       */
import AbstractControl from './AbstractControl';

export default class TextField extends AbstractControl {
    newReadOnlyPropertyStyleSet() {
        const result = super.newReadOnlyPropertyStyleSet();
        result.xStyle.autoGrow = false;
        result.xStyle.input = {};
        result.xStyle.input.borderBottomWidth = 0;
        result.xStyle.input.flexGrow = 1;
        result.xStyle.input.paddingVertical = 0;
        result.xStyle.input.variant = 'standard';
        return result;
    }
}
