// Core component styles
const coreStyles = {
    container: {
        display: 'flex',
    },

    text: {
        overflowWrap: 'break-word', // Will wrap on word if it can, will break word if it has to
        lineHeight: 'normal',
        flexGrow: 1,
    },
};

/**
 * Helper method to get the styles needed to wrap the text for given number of lines
 * @param {number} numberOfLines - Number of lines text should display
 */
const getNumberOfLinesStyles = (numberOfLines) => {
    return {
        'display': '-webkit-box',
        'WebkitBoxOrient': 'vertical',
        'WebkitLineClamp': numberOfLines.toString(),
        'overflow': 'hidden',
        'whiteSpace': 'pre-wrap',
    };
};

/**
* Helper method to get the styles of textlabel element
* @param {object} contextStyles - Context styles for the text label
* @param {number} numberOfLines - Number of lines text should display
*/
const getStyles = (contextStyles = {}, numberOfLines = 0) => {
    return {
        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        text: {
            ...coreStyles.text,
            ...(numberOfLines ? getNumberOfLinesStyles(numberOfLines) : {}),
            ...contextStyles.text,
        },
    };
};

export default getStyles;
