import React from 'react';
import * as PropTypes from 'prop-types';

/**
 * Image Component Class, that will render the supplied image url.
 * While Image is loading it will show a loader.
 */
const XaltImage = (props) => {
    const {
        altText,
        style,
        imageSrc,
        toolTip,
    } = props;

    return (
        <img
            alt={ altText || imageSrc }
            className="xalt-image__image"
            title={ toolTip }
            src={ imageSrc }
            style={ style } />
    );
};

XaltImage.propTypes = {
    /** Image description */
    altText: PropTypes.string,

    /** Styles for this component */
    style: PropTypes.object,

    /** The image source (either a remote URL or a local file resource) */
    imageSrc: PropTypes.string,

    toolTip: PropTypes.string,
};

export default XaltImage;
