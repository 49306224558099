import lang from '../nls/i18n';

const PROPS_ERROR = {
    barColor: 'deeppink', // We use this color to explicitly set when there's an error
    valueColor: 'rgba(0, 0, 0, 0)', // Transparent color to hide value used to fill up chart
    animate: false,
    maxValue: 99,
    value: 99,
    valueFormat: '${0}',
};

const ERROR_MESSAGES = {
    BINDING_ERROR: lang.salt.errors.bindingError,
};

const errorHelper = {
    PROPS_ERROR,
    ERROR_MESSAGES,
};

export default errorHelper;
