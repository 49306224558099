import React from 'react';
import PropTypes from 'prop-types';
import Box from './Box';
import Text from './Text';

const SaltError = ({ message, error }) => {
    const textStyle = { margin: 10 };
    return (
        <Box style={ { flexDirection: 'column', margin: 20 } }>
            <Text style={ textStyle }>{ message }</Text>
            <Text style={ { ...textStyle, color: 'red' } }>{ error.message }</Text>
        </Box>
    );
};

SaltError.propTypes = {
    message: PropTypes.string,
    error: PropTypes.object,
};

export default SaltError;
