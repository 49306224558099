/* eslint-disable no-param-reassign */

import jsonata from 'jsonata';
import { Log } from 'cv-dialog-sdk';

import uiHelper from '../utilities/uiHelper';

export class SaltDocument {
    static DEFAULT_VERSION = '1.0.0';

    constructor(json) {
        this.root = this.deepClone(json || { salt: { version: SaltDocument.DEFAULT_VERSION } });
        this.selectedNodeSet = new Set([]);
    }

    get rootNode() {
        return this.root;
    }


    /* ********** Selection ****************************************************************** */

    deselectAllNodes() {
        this.selectedNodeSet.clear();
    }

    deselectNode(node) {
        this.selectedNodeSet.delete(node);
    }

    deselectNodes(nodes) {
        nodes.forEach(this.deselectNode.bind(this));
    }

    get selectedNodes() {
        return [ ...this.selectedNodeSet ];
    }

    get hasSelection() {
        return this.selectedNodeSet.size > 0;
    }

    get isSingleSelection() {
        return this.selectedNodeSet.size === 1;
    }

    selectNode(node) {
        this.selectedNodeSet.add(node);
    }

    selectNodes(nodes) {
        nodes.forEach(this.selectNode.bind(this));
    }

    singleSelectNode(node) {
        this.deselectAllNodes();
        this.selectedNodeSet.add(node);
    }

    /* ********** Operations on current selection ****************************************************************** */

    setAttribute(name, value) {
        if (this.isSingleSelection) {
            this.setAttributeForNode(name, value, this.selectedNodes[0]);
        }
        this.selectedNodes.forEach(this.setAttributeForNode.bind(this, name, this.deepClone(value)));
    }

    mergeAttibute(name, value) {
        if (this.isSingleSelection) {
            this.mergeAttributeForNode(name, value, this.selectedNodes[0]);
        }
        this.selectedNodes.forEach(this.mergeAttributeForNode.bind(this, name, this.deepClone(value)));
    }

    addChild(childNode) {
        if (this.isSingleSelection) {
            this.addChildToNode(childNode, this.selectedNodes[0]);
        }
        this.selectedNodes.forEach(this.addChildToNode.bind(this, this.deepClone(childNode)));
    }

    /* ********** Operations on provided node ****************************************************************** */

    setAttributeForNode(name, value, node) {
        node[name] = value;
    }

    mergeAttributeForNode(name, value, node) {
        if (node[name] && typeof (node[name]) === 'object') {
            try {
                if (Array.isArray(node[name])) {
                    if (Array.isArray(value)) {
                        node[name] = [ ...node[name], ...value ];
                    } else {
                        node[name] = [ ...node[name], value ];
                    }
                } else if (Array.isArray(value)) {
                    node[name] = [ node[name], ...value ];
                } else {
                    node[name] = { ...node[name], ...value };
                }
            } catch (e) {
                throw new Error(`Cannot merge value: ${e.message}`);
            }
        } else {
            throw new Error(`Cannot merge type: ${typeof (node[name])}`);
        }
    }

    addChildToNode(childNode, parentNode) {
        if (!parentNode.children) {
            parentNode.children = [];
        }
        parentNode.children.push(childNode);
    }

    deepClone(node) {
        if (typeof (node) === 'object') {
            return JSON.parse(JSON.stringify(node));
        }
        return node;
    }

    getByExpr(expression) {
        if (!this.hasSelection) {
            return SaltDocument.evalExpression(expression, this.root);
        }
        if (this.isSingleSelection) {
            return SaltDocument.evalExpression(expression, this.selectedNodes[0]);
        }
        return SaltDocument.evalExpression(expression, this.selectedNodes);
    }

    static evalExpression(expression, rootObj) {
        let expr = null;
        try {
            expr = jsonata(expression);
            return expr.evaluate(rootObj);
        } catch (e) {
            const msg = `Invalid expr: '${expression}' :: ${e.message}`;
            Log.warn(msg);
            throw new Error(`Invalid expr: '${expression}' :: ${e.message}`);
        }
    }

    static getViewId(view) {
        return uiHelper.getViewId(view, true);
    }
}
