import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { PagingState, PagingPanel } from '@devexpress/dx-react-grid';
import {
    DragDropProvider,
    Grid,
    TableColumnReordering,
    TableColumnResizing,
    TableColumnVisibility,
    TableFixedColumns,
    TableHeaderRow,
    Table,
} from '@devexpress/dx-react-grid-material-ui';

import { StickyTable } from './StickyTable';

/**
 * Create component to override the DXG root element style
 * @param props
 * @return {*}
 * @constructor
 */
const GridRoot = (props) => (
    <Grid.Root
        { ...props }
        style={ {
            height: '100%',
            overflow: 'hidden',
        } } />
);

/**
 * An extensive table grid control
 * @see https://devexpress.github.io/devextreme-reactive/react/grid/
 */
class DeTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            updatedColumnWidthInfo: undefined,
        };
    }

    render() {
        const {
            style,
            columns, // [ { name: String, title?: String } ]
            columnWidths,
            columnOrder, // [ String ]
            fixedLeftColumns, // [ String ]
            hiddenColumns,
            rows, // [ Object ]
            testID,
            overrideMessages,
            headerCell: TableHeaderCell,
            cellComponent: TableCell,
            rowComponent: TableRow,
            fixedColumnCell: FixedColumnCell,
            tableContainer: ScrollContainer,
            tablePagingContainer: PagingContainer,
            headerCellStubComponent: HeaderStubCell,
            headerCellContentComponent: TableHeaderCellContent,
            currentPageSize,
            currentPageNumber,
            isPagingEnabled } = this.props;

        const { updatedColumnWidthInfo } = this.state;
        return (
            <div
                id={ testID }
                style={ { display: 'flex', flexDirection: 'column', overflow: 'hidden', flex: '1 0 300px', ...style } }>
                <Grid
                    columns={ columns }
                    rows={ rows }
                    rootComponent={ GridRoot }>
                    <DragDropProvider />
                    <Table
                        height="auto"
                        messages={ { noData: overrideMessages.noData } }
                        containerComponent={ ScrollContainer }
                        tableComponent={ StickyTable }
                        rowComponent={ TableRow }
                        stubHeaderCellComponent={ HeaderStubCell }
                        cellComponent={ TableCell } />
                    <TableColumnReordering
                        order={ columnOrder }
                        onOrderChange={ this.handleOrderChange } />
                    <TableColumnResizing
                        columnWidths={ updatedColumnWidthInfo || columnWidths }
                        onColumnWidthsChange={ this.handleColumnWidthUpdateInfo } />
                    <TableHeaderRow
                        cellComponent={ TableHeaderCell }
                        contentComponent={ TableHeaderCellContent } />
                    { isPagingEnabled &&
                        <PagingState
                            currentPage={ currentPageNumber }
                            pageSize={ currentPageSize } />
                    }
                    <TableFixedColumns
                        cellComponent={ FixedColumnCell }
                        leftColumns={ [ ...fixedLeftColumns ] } />
                    { isPagingEnabled &&
                        <PagingPanel
                            containerComponent={ PagingContainer } />
                    }
                    <TableColumnVisibility
                        hiddenColumnNames={ hiddenColumns } />
                </Grid>
            </div>
        );
    };

    handleColumnWidthUpdateInfo = (updatedColumnWidthInfo) => {
        this.setState({
            updatedColumnWidthInfo,
        });
    }

    /**
     * Called on column reorder
     * @param {Array.<String>} columnsNamesInOrder
     * @returns {void}
     */
    handleOrderChange = (columnsNamesInOrder) => {
        const {
            fixedLeftColumns,
            onColumnsReordered,
        } = this.props;
        const leftColumns = columnsNamesInOrder.slice(0, fixedLeftColumns.length);

        if (onColumnsReordered && leftColumns.every((column) => fixedLeftColumns.includes(column))) {
            onColumnsReordered(columnsNamesInOrder);
        }
    };
}

/**
 * @typedef {Function} LocalizeMessage
 * @param {String} value
 * @returns {String}
 */

DeTable.propTypes = {

    style: PropTypes.object,
    /** Display order of columns by column name */
    columnOrder: PropTypes.arrayOf(PropTypes.string),

    /** An array containing column data */
    columns: PropTypes.arrayOf(PropTypes.shape({
        /** The name identifier of the column */
        name: PropTypes.string,

        /** The column header display text */
        title: PropTypes.string,

        /** Overrides the default table column width in pixels or CSS-accepted units (auto, px, %, em, rem, vm, vh, vmin, vmax) */
        width: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
    })).isRequired,

    /** Column Widths */
    columnWidths: PropTypes.array,

    /**
     * Names of columns to freeze and affix to the left of the table
     * NOTE: non-contiguous column names do NOT reorder columns and dock together as a group.
     * If fixed grouped non-contiguous columns are desired then the columnOrder prop must be reordered with
     * fixed columns at the beginning of the columns array in the desired fixed order.
     */
    fixedLeftColumns: PropTypes.arrayOf(PropTypes.string),

    /** Array of hidden columns */
    hiddenColumns: PropTypes.arrayOf(PropTypes.string),

    /**
     * Called on column reorder
     * @param {Array.<String>} columnsNamesInOrder
     * @returns {void}
     */
    onColumnsReordered: PropTypes.func,

    /** ID string used for testing */
    testID: PropTypes.string,

    overrideMessages: PropTypes.shape({
        noData: PropTypes.string,
    }),

    tableContainer: PropTypes.func,

    tablePagingContainer: PropTypes.func,

    headerCell: PropTypes.func.isRequired,

    headerCellContentComponent: PropTypes.func.isRequired,

    headerCellStubComponent: PropTypes.func.isRequired,

    cellComponent: PropTypes.func.isRequired,

    /** Component used to generate the table rows */
    rowComponent: PropTypes.func.isRequired,

    /** An array containing custom data */
    rows: PropTypes.array.isRequired,

    currentPageSize: PropTypes.number,

    currentPageNumber: PropTypes.number,

    isPagingEnabled: PropTypes.bool,

    fixedColumnCell: PropTypes.func,
};

DeTable.defaultProps = {
    fixedLeftColumns: [],
    testID: 'DeTable',
    overrideMessages: {
        noData: 'No records found...',
    },
};

export default DeTable;
