export const dateTypes = {
    DATE: 'date',
    DATETIME: 'datetime',
    TIME: 'time',
};

export const dateViews = {
    DATE: 'date',
    HOURS: 'hours',
    MINUTES: 'minutes',
    YEAR: 'year',
};

export const pickerVariants = {
    DIALOG: 'dialog',
    INLINE: 'inline',
    STATIC: 'static',
};