import SimpleFloatAttribute from '../SimpleFloatAttribute';
import SimpleStringAttribute from '../SimpleStringAttribute';

export default class Width extends SimpleFloatAttribute {
    shrinkIt ;
    constructor(
        name,
        value,
        exportClass,
        isCascading = false,
        overrideName,
        shrinkIt = false,
    ) {
        super(name, value, exportClass, isCascading, overrideName);
        this.shrinkIt = shrinkIt;
    }

    exportTo(context, styleSet) {
        const { style } = styleSet;
        const { xStyle } = styleSet;
        if (SimpleStringAttribute.hasConstantRef(this.value)) {
            // output as an xstyle.layout so *min/*meta can be handled.
            const { layout } = styleSet.xStyle;
            SimpleStringAttribute.exportConstantAsRef(layout, this.overrideName, this.value);
        } else {
            if (this.isPercentBased()) { // eslint-disable-line no-lonely-if
                if (context.parentContext && !context.parentContext.isRowBound()) {
                    context.warnings.addUnpredictableUnboundWidth();
                }
                style[this.overrideName] = this.value;
                if (this.shrinkIt) { styleSet.flexPolicy.setStandardShrink(); }
            } else if (this.isMin()) {
                // No special behavior.  *min is default behavior
            } else if (this.isMeta()) {
                xStyle.layout.sizeToMetaLength = true;
            } else {
                style[this.overrideName] = parseFloat(this.value);
                if (this.shrinkIt) { styleSet.flexPolicy.setStandardShrink(); }
            }
        }
    }

    isPercentBased = () => {
        return this.value.indexOf('%') > -1;
    }
    isMeta = () => {
        return this.value === '*meta';
    }
    isMin = () => {
        return this.value === '*min';
    }
}
