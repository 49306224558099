import React, { Fragment, useState, useEffect, useRef } from 'react';
import * as PropTypes from 'prop-types';
import MUIMenu from '@material-ui/core/Menu';
/* eslint-disable no-restricted-imports */
import makeStyles from '@material-ui/core/styles/makeStyles';

import getStyles from './Menu.styles';

/**
 * Enum of menu close reasons
 * @typedef {Enumerator} CloseReasons
 * @enum {String}
 */
const CLOSE_REASONS = {
    ESCAPE: 'escapeKeyDown',
    BACKDROP: 'backdropClick',
    SELECTION: 'itemSelection',
    TAB: 'tabKeyDown',
};

/**
 * A Menu displays a list of choices on a temporary surface. It appears when the user interacts with a button, or other control.
 * @see https://material-ui.com/components/menus/
 */
const Menu = (props) => {
    const {
        anchorPosition,
        anchorOrigin,
        button,
        children,
        closeOnSelection,
        contextStyles,
        onClose,
        open,
        testID,
    } = props;

    const [
        state,
        setState,
    ] = useState({
        anchorElement: null,
        isOpen: !!open,
    });
    const isMounted = useRef(true);
    useEffect(() => () => {
        isMounted.current = false;
    }, []);

    // Did update check
    useEffect(() => {
        if (state.isOpen !== open && isMounted.current) {
            setState({
                ...state,
                isOpen: !!open,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ open ]);

    // Override core styles with context styles, separating MUI styles
    const {
        ...muiStyles
    } = getStyles(contextStyles);

    // Create dynamic class names and injected DOM styles for MUI component
    // * makeStyles returns a function
    const styles = makeStyles(muiStyles)();

    // Begin a new button element that will open the menu
    let trigger = null;

    // Inject the ability to anchor the menu to the button
    if (button) {
        // Access the button props
        const {
            props: buttonProps,
        } = button;

        const {
            // Get button children to pass on to clone
            children: buttonChildren,

            // Get button click handler to override to open menu
            onClick: buttonClick,
        } = buttonProps;

        // Create new props for trigger
        const triggerProps = {
            // Persist existing button props
            ...buttonProps,

            // Inject new button click handler
            onClick: (event, ...args) => {
                const {
                    target,
                } = event;

                // Persist any original button click event
                if (buttonClick) {
                    buttonClick(event, ...args);
                }

                // Update state with new anchor element which will position menu relative to the element
                if (isMounted.current) {
                    setState({
                        anchorElement: target,
                        isOpen: true,
                    });
                }
            },
        };

        // Create button clone used to open menu
        trigger = React.cloneElement(
            button, // Clone button
            triggerProps, // Inject updated props
            buttonChildren, // Pass on existing button children
        );
    }

    // Inject the ability to close the menu on item selection
    const menuItems = React.Children.map(children, (child) => {
        // Access the child props
        const {
            props: childProps,
        } = child;

        const {
            // Get child children to pass on to clone
            children: itemChildren,

            // Get child click handler to override to close menu on selection
            onClick: itemClick,
        } = childProps;

        // Create new props for MenuItem child
        const itemProps = {
            // Persist existing child props
            ...childProps,

            // Inject new MenuItem click handler
            onClick: (event, ...args) => {
                if (itemClick) {
                    itemClick(event, ...args);
                }
                if (closeOnSelection) {
                    if (onClose) {
                        onClose(event, CLOSE_REASONS.SELECTION);
                    }
                    if (isMounted.current) {
                        setState({
                            anchorElement: null,
                            isOpen: false,
                        });
                    }
                }
            },
        };

        // Create MenuItem clone used to close menu on selection
        return React.cloneElement(
            child,
            itemProps,
            itemChildren,
        );
    });

    const menu = (
        <MUIMenu
            anchorEl={ state.anchorElement }
            classes={ { paper: styles.container } }
            className="c-menu__container"
            data-test-id={ `${testID}__menu__container` }
            getContentAnchorEl={ null }
            keepMounted
            onClose={ (event, reason) => {
                if (onClose) {
                    onClose(event, reason);
                }
                if (isMounted.current) {
                    setState({
                        anchorElement: null,
                        anchorPosition: null,
                        isOpen: false,
                    });
                }
            } }
            open={ state.isOpen }
            anchorReference={ (state.anchorElement && 'anchorEl') || (anchorPosition && 'anchorPosition') || 'none' }
            anchorPosition={
                anchorPosition && anchorPosition.top !== null && anchorPosition.left !== null
                    ? anchorPosition
                    : undefined
            }
            anchorOrigin={ anchorOrigin }>
            { menuItems }
        </MUIMenu>
    );

    if (trigger) {
        return (
            <Fragment>
                { trigger }
                { menu }
            </Fragment>
        );
    }

    return menu;
};

Menu.propTypes = {
    /** The position used to set the position of the menu */
    anchorPosition: PropTypes.shape({
        top: PropTypes.number,
        left: PropTypes.number,
    }),

    /** Anchor origin */
    anchorOrigin: PropTypes.shape({
        vertical: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        horizontal: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
    }),

    /** Component element used as the menu trigger */
    button: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.node,
    ]),

    /** Menu Items that needs to be displayed inside the Menu */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,

    /** Closes the menu after a menu item selection when enabled */
    closeOnSelection: PropTypes.bool,

    /** Styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the container surrounding menu items */
        container: PropTypes.object,
    }),

    /**
     * Called after the menu is closed
     * @param {Object} event
     * @param {String} reason
     */
    onClose: PropTypes.func,

    /** Sets menu visibility */
    open: PropTypes.bool,

    /** Id used for testing */
    testID: PropTypes.string,
};

Menu.defaultProps = {
    closeOnSelection: true,
    contextStyles: {},
    open: false,
    testID: 'Menu',
};

export {
    CLOSE_REASONS,
};
export default Menu;
