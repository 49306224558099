/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import componentFactory from '../componentFactory';
import componentPropTypes from './abstractComponentPropTypes';

export default class TelephoneField extends Component {
    static propTypes = componentPropTypes;

    render() {
        const { maxLength } = this.props;
        const regxValidationPattern = maxLength ? `(^$)|^[+0-9][0-9-]{0,${ maxLength }}$` : undefined;

        const telephoneProps = {
            ...this.props,
            type: 'text',
            regxValidationPattern,
        };
        return React.createElement(componentFactory.getPlatformPropertyComponent('textField'), { ...telephoneProps });
    }
}
