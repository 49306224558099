import { rootStore } from 'cv-react-core';

const coreStyles = {
    container: {
        overflow: 'auto',
        flexGrow: 1,
    },

    launcher: {
    },

    launcherTitleContainer: {
        marginLeft: '20px',
    },

    launcherTitle: {
        color: '#000000',
        fontFamily: 'Roboto',
        fontSize: '14px',
        lineHeight: '16px',
    },

    launcherIcon: {
    },

    launcherList: {
    },

    launcherText: {
        lineHeight: 'normal',
    },

    titleContainer: {
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '12px 0px 12px 17px',
        boxSizing: 'border-box',
        position: 'sticky',
        top: 0,
        zIndex: 1,
    },

    menuContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '12px',
    },

    searchContainer: {
        display: 'flex',
        alignItems: 'center',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#E0E0E0',
        borderRadius: '4px',
    },
};

const getStyles = (contextStyles) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    return {
        container: {
            ...coreStyles.container,
            backgroundImage: `url(${theme.pageBackgroundImage})`,
            ...contextStyles.container,
        },

        launcher: {
            ...coreStyles.launcher,
            ...contextStyles.launcher,
        },

        launcherTitleContainer: {
            ...coreStyles.launcherTitleContainer,
            ...contextStyles.launcherTitleContainer,
        },

        launcherTitle: {
            ...coreStyles.launcherTitle,
            ...contextStyles.launcherTitle,
        },

        launcherIcon: {
            ...coreStyles.launcherIcon,
            ...contextStyles.launcherIcon,
        },

        launcherList: {
            ...coreStyles.launcherList,
            ...contextStyles.launcherList,
        },

        launcherText: {
            ...coreStyles.launcherText,
            ...contextStyles.launcherText,
        },

        titleContainer: {
            ...coreStyles.titleContainer,
            backgroundColor: theme.workbenchLauncherBackgroundColor,
            ...contextStyles.titleContainer,
        },
        menuContainer: {
            ...coreStyles.menuContainer,
            ...contextStyles.menuContainer,
        },
        searchContainer: {
            ...coreStyles.searchContainer,
            ...contextStyles.searchContainer,
        },
    };
};

export default getStyles;
