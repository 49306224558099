import {
    Table,
    TableSelection,
    TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';

export { default as  DeTable } from './DeTable';
export { default as DeTableLoading } from './DeTableLoading';
export const DeTableRow = Table.Row;
export const DeTableCell = Table.Cell;
export const DeTableSelectionRow = TableSelection.Row;
export const DeTableHeaderRowCell = TableHeaderRow.Cell;