import SimpleStringAttribute from './SimpleStringAttribute';

export default class SimpleXStyleAttribute extends SimpleStringAttribute {
    exportTo(context, styleSet) {
        const { xStyle } = styleSet;
        if (SimpleStringAttribute.hasConstantRef(this.value)) {
            SimpleStringAttribute.exportConstantAsRef(xStyle, this.overrideName, this.value);
        } else {
            xStyle[this.overrideName] = this.value;
        }
    }
}
