/*       */

/**
 * A Finalizer is used to add SALT to the resulting SALT object, but have an opportunity after all is done
 * to finalize it's value.
 * Example: The *remaining function for plist needs to know all of the fields referenced in the document.
 * It needs to process the *remaining directive before all the GML is parsed.
 */
export default class Finalizer {
    assigner ;
    resolver ;
    constructor(assigner, resolver) {
        this.assigner = assigner;
        this.resolver = resolver;
    }
    resolve() { this.assigner(this.resolver()); }
}
