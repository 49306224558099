/*       */

import Document from './Document';
import FlexDirection from './layoutAttribute/general/FlexDirection';
import { FLEX_DIRECTION } from './gmlConstants';
import Base from './model/Base';

/**
 * TraverseLevel holds data that is specific to the traverse of the GML document.  i.e. not global.
 */
class TraverseLevel {
    gml ;
    cascading ;
    flexDirection ;
    columnBound ; // Is there a finite size limit, or is this in an unbounded scroller
    rowBound ; // Is there a finite size limit, or is this in an unbounded scroller
    isWithinHeaderOrFooter ;
    isWithinList ;

    constructor(
        gml,
        cascading,
        flexDirection,
        columnBound,
        rowBound,
        isWithinHeaderOrFooter,
        isWithinList,
    ) {
        this.gml = gml;
        this.cascading = cascading;
        this.flexDirection = flexDirection;
        this.columnBound = columnBound;
        this.rowBound = rowBound;
        this.isWithinHeaderOrFooter = isWithinHeaderOrFooter;
        this.isWithinList = isWithinList;
    }
}


/**
 * A Context holds state information for the level of GML that is being traversed.
 */
export default class Context {
    parentContext ;
    namedStyles ;
    version ;
    warnings ;
    document ;
    traverseLevel ;
    knownConstants ;
    constructor(
        parentContext,
        traverseLevel,
        namedStyles,
        version,
        document,
        warnings,
    ) {
        this.parentContext = parentContext;
        this.traverseLevel = traverseLevel;
        this.namedStyles = namedStyles;
        this.version = version;
        this.document = document;
        this.warnings = warnings;
        this.knownConstants = {};
    }

    static starterContext(
        gml,
        version,
        warnings,
        cascading = {},
        namedStyles = {},
    ) {
        const traverseLevel = new TraverseLevel(
            gml,
            cascading,
            FlexDirection.column,
            true,
            true,
            false,
            false,
        );
        return new Context(
            null,
            traverseLevel,
            namedStyles,
            version,
            new Document(),
            warnings,
        );
    }

    get cascading() {
        return this.traverseLevel.cascading;
    }
    get flexDirection() {
        return this.traverseLevel.flexDirection;
    }
    get gml() {
        return this.traverseLevel.gml;
    }
    get isWithinHeaderOrFooter() {
        return this.traverseLevel.isWithinHeaderOrFooter;
    }
    get isWithinList() {
        return this.traverseLevel.isWithinList;
    }

    set columnBound(value) {
        this.traverseLevel.columnBound = value;
    }
    isColumnBound() {
        return this.traverseLevel.columnBound;
    }

    set rowBound(value) {
        this.traverseLevel.rowBound = value;
    }
    isRowBound() {
        return this.traverseLevel.rowBound;
    }

    getDebugColorAttribute() {
        const attribute = this.cascading.debugColor;
        return ((attribute));
    }

    isEquallySized() {
        const es = this.gml.getEquallySizedAttribute(this);
        return !!(es && es.value === 'true');
    }

    isHorizontalScroll() {
        if (!this.flexDirection.isRow()) {
            return false;
        }
        const sa = this.gml.getScrollAttribute(this);
        return !!(sa && sa.value === 'horizontal');
    }

    setIsWithinList() {
        this.traverseLevel.isWithinList = true;
    }

    setKnownConstants(knownConstants) {
        this.knownConstants = knownConstants;
    }

    newContext(gml) {
        // Update the context with information from this level of json.
        // A column is bound if this box or a parent has a fixed height and is not a scroller.
        const newCascading = gml.getCascadingAttributes(this, this.cascading);
        const scrollA = gml.getScrollAttribute(this);
        const columnBound = !!((this.isColumnBound() || gml.getHeightAttribute(this)) && (!scrollA || scrollA.isHorizontal()));
        const rowBound = !!((this.isRowBound() || gml.getWidthAttribute(this)) && (!scrollA || scrollA.isVertical()));
        const flexDirection = (newCascading[FLEX_DIRECTION]);
        const isHeader = Base.isHeader(gml.json, this.version);
        const isFooter = Base.isFooter(gml.json, this.version);

        const traverseLevel = new TraverseLevel(
            gml,
            newCascading,
            flexDirection,
            columnBound,
            rowBound,
            (this.isWithinHeaderOrFooter || isHeader || isFooter),
            this.isWithinList,
        );

        const result = new Context(
            this,
            traverseLevel,
            this.namedStyles,
            this.version,
            this.document,
            this.warnings,
        );
        result.setKnownConstants(this.knownConstants);
        return result;
    }
}
