/* @flow */
export default {
    userNameLbl: 'User',
    passwordLbl: 'Password',
    cta: 'Login',
    copyright: 'All Rights Reserved.',
    errors: {
        requiredField: 'This field is required',
    },
    currentPassword: 'Current password',
    newPassword: 'New password',
    confirmNewPassword: 'Confirm new password',
    savePasswordLbl: 'Save password',
    showPasswordLbl: 'Show password',
    oAuthBtnText: 'Login via oAuth',
    appName: 'Nexus',
    xaltMobility: 'Nexus',
    changePasswordTitle: 'Change Password',
    passwordExpiredHelperText: 'Your password has expired. To continue you must change your existing password and set your new password.',
    passwordExpiredPromptTitle: 'Password Expired',
    passwordExpiryPromptTitle: 'Password Expiring',
    defaultBrowserTitle: 'Nexus By Hexagon',
    invalidTenantUrlPath: 'Tenant id provided in the url not valid:',
    lastRecognizedTenant: 'The last recognized tenant is shown below.',
    bookmarkLoginPage: 'Please bookmark the login page to avoid future messages.',
    passwordRequirementText: 'Your new password must have at least 6 characters,1 letter (A-Z or a-z) and 1digit (0-9).Special characters are optional.',
};
