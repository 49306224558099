import RefUtil from '../RefUtil';
/*
    All plugins should have an exported function with this signature that returns a bindings object.
    Bindings is an object with names/values and/or names/functions to bind
 */
/* eslint-disable no-unused-vars */
export default (rootObject, expression) => {
    // Provide easier access to locally scoped 'special' references

    // ****************** plugin functions ****************** /
    const setProp = (name, value) => {
        // this is only valid on an EditorDialog
        rootObject.modelStore.setPropertyValue(name, value);
    };

    const setRef = (name, value) => {
        RefUtil.setGlobalRef(name, value, rootObject.scopeManager);
    };

    // ****************** end plugin functions ****************** /

    return {
        setProp,
        setRef,
    };
};
