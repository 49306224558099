import { rootStore } from 'cv-react-core';

const coreStyles = {
    avatar: {
        cursor: 'pointer',
        height: '35px',
        width: '35px',
    },

    avatarIcon: {
        color: '#fff',
        fontSize: '35px',
    },

    container: {
    },

    header: {
    },

    menuImage: {
        height: '32px',
        objectFit: 'contain',
    },

    content: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },

    contentWrapper: {
        display: 'flex',
        flexGrow: 1,
        flexShrink: 1,
        overflow: 'hidden',
    },

    footer: {
    },

    linksContainer: {
        alignItems: 'center',
    },

    mainContent: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        flexShrink: 1,
        overflow: 'hidden',
    },

    notificationButton: {
        marginRight: '45px',
        padding: '0px',
        paddingTop: '10px',
    },

    notificationIcon: {
        color: '#fff',
        fontSize: '24px',
    },

    sideDrawer: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 1,
        minWidth: '55px',
        overflow: 'hidden',
    },

    toolbar: {
        backgroundColor: '#000000',
        backgroundImage: 'linear-gradient(90deg, #3BB0C9 0.06%, #028CCD 12.5%, #028CCD 71.53%, #A5CF67 100%)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 5px',
        paddingTop: '4px',
        paddingRight: '12px',
        paddingBottom: '0px',
        paddingLeft: '6px',
        boxSizing: 'border-box',
        height: '52px',
        minHeight: '52px',
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: '#E0E0E0',
    },
};

const getStyles = (contextStyles = {}) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();
    return {
        avatar: {
            ...coreStyles.avatar,
            ...contextStyles.avatar,
        },

        avatarIcon: {
            ...coreStyles.avatarIcon,
            ...contextStyles.avatarIcon,
        },

        content: {
            backgroundColor: theme.workbenchLauncherBackgroundColor,
            ...coreStyles.content,
            ...contextStyles.content,
        },

        contentWrapper: {
            ...coreStyles.contentWrapper,
            ...contextStyles.contentWrapper,
        },

        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        footer: {
            ...coreStyles.footer,
            ...contextStyles.footer,
        },

        header: {
            ...coreStyles.header,
            ...contextStyles.header,
        },

        linksContainer: {
            ...coreStyles.linksContainer,
            ...contextStyles.linksContainer,
        },

        mainContent: {
            ...coreStyles.mainContent,
            ...contextStyles.mainContent,
        },

        notificationButton: {
            ...coreStyles.notificationButton,
            ...contextStyles.notificationButton,
        },

        notificationIcon: {
            ...coreStyles.notificationIcon,
            ...contextStyles.notificationIcon,
        },

        menuImage: {
            ...coreStyles.menuImage,
            ...contextStyles.menuImage,
        },

        sideDrawer: {
            ...coreStyles.sideDrawer,
            ...contextStyles.sideDrawer,
        },

        toolbar: {
            ...coreStyles.toolbar,
            ...contextStyles.toolbar,
        },
    };
};

export default getStyles;
