// Core component styles
const coreStyles = {
    anchorScrollColumn: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 0 0',
        overflowY: 'auto',
    },

    mainContentRowSizingContainer: {
        display: 'flex',
        flexDirection: 'row',
        flex: '1 0 auto',
        overflowY: 'hidden',
        overflowX: 'auto',
        maxHeight: '90vh',
    },

    column: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        minWidth: '500px',
        overflow: 'hidden',
    },
};

/**
 * Helper method to get the styles for Multiple Form component
 * @param {Object} contextStyles - Context styles for Multiple Form Component
 */
const getStyles = (contextStyles = {}) => {
    return {
        column: {
            ...coreStyles.column,
            ...contextStyles.column,
        },

        anchorScrollColumn: {
            ...coreStyles.anchorScrollColumn,
            ...contextStyles.anchorScrollColumn,
        },

        mainContentRowSizingContainer: {
            ...coreStyles.mainContentRowSizingContainer,
            ...contextStyles.mainContentRowSizingContainer,
        },
    };
};

export default getStyles;
