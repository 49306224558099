import React from 'react';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { utilities } from '../utilities';
import SaltContext from './SaltContext';
import componentFactory from './componentFactory';
import { constants } from '../constants';
import SaltComponent from './SaltComponent';
import ScopeManager from './ScopeManager';
import serviceFactory from '../services/serviceFactory';

const { error: { errorTypes, MODAL_STATE } } = constants;
const DROP_DOWN_TYPE = 'DROP_DOWN';

@observer
export default class MaintainableList extends SaltComponent {
    static propTypes = {
        availableMenuItems: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            icon: PropTypes.string,
            menuText: PropTypes.string,
        })),
        onChooseItem: PropTypes.func,
        onChooseNone: PropTypes.func,
        onMenuAction: PropTypes.func,
        onRefresh: PropTypes.func,
        onRequestMore: PropTypes.func,
        onSortColumn: PropTypes.func,
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        viewId: PropTypes.string,
        xMenu: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
        })),
        xStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
    };

    render() {
        const { dialogStore, uiStore, sessionStore } = this.contextParams;
        const { dialog, errorRecordIds, lastRecordSelected, records, selectedRecordCount, selectedRecords } = dialogStore;
        const { view } = dialog;
        const { columns, suppressHeading, spacerLines, isDataStyle, isMaintainableTableStyle } = view;
        const isMaintainable = isDataStyle || isMaintainableTableStyle;
        const selectedDialogStore = this.getDialogStore();
        const selectedRange = isMaintainable && utilities.listHelper.getSelectedRange(uiStore, selectedDialogStore);
        const { id: colIdPrefix } = selectedDialogStore.dialog;
        const { firstVisibleColumnIndex, hiddenColumnIndices, pinnedColumnIndices } = this.getColumnDisplayOptions(selectedDialogStore, isMaintainableTableStyle);
        const { session: { tenantProperties } } = sessionStore;
        const { doubleClickForDefaultAction } = tenantProperties;

        const listProps = {
            ...this.props,
            alignedGrids: this.getAlignedGrids(isMaintainableTableStyle),
            colIdPrefix,
            columns,
            doubleClickForDefaultAction: doubleClickForDefaultAction === 'true',
            errorRecordIds,
            firstVisibleColumnIndex,
            getSelectedRecord: (...args) => this.getSelectedRecord(selectedRange, ...args),
            getPasteValue: (...args) => this.getPasteValue(selectedRange, ...args),
            hasMoreRecords: dialogStore.hasMoreRecords(),
            hiddenColumnIndices,
            isDataViewStyle: isMaintainable,
            isEditable: isMaintainable,
            isLoading: this.isLoading,
            lastRecordSelected,
            lastSection: isMaintainableTableStyle ? true : dialogStore.parentDialogStore.lastChildStore.dialog.id === dialog.id,
            loadedZeroRecords: dialogStore.loadedZeroRecords,
            onClearErrors: this.handleClearErrors,
            onPaste: (...args) => this.handlePaste(toJS(records), ...args),
            onPasteError: this.handlePasteError,
            onReFocus: this.handleRefocus,
            onRenderItem: this.handleRenderItem,
            pinnedColumnIndices,
            records: toJS(records),
            selectedRange,
            selectedRecordCount,
            selectedRecords,
            spacerLines,
            suppressHeading,
        };

        return React.createElement(componentFactory.getPlatformComponent('maintainableList'), listProps);
    }

    get contextParams() {
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        const { uiStore, sessionStore } = saltStore;
        return { dialogStore, uiStore, sessionStore };
    }

    // Logic which defines the Link between the all Maintainable Child Grid.
    // And the Link is established and maintained in FormDialogStore level.
    getAlignedGrids = (isMaintainableTableStyle) => {
        if (isMaintainableTableStyle) return {};

        const { dialogStore } = this.contextParams;
        const { id } = dialogStore.dialog;
        const alignedGridsOptions = dialogStore.parentDialogStore.getAlignedGrids();
        if (alignedGridsOptions && Object.keys(alignedGridsOptions).length) {
            return alignedGridsOptions[id];
        }
        const gridOptions = {};
        const { childDialogStores } = dialogStore.parentDialogStore;
        childDialogStores.forEach((childStore) => {
            gridOptions[childStore.dialog.id] = {
                alignedGrids: [],
            };
        });
        Object.keys(gridOptions).forEach((key) => {
            Object.keys(gridOptions).forEach((childKey) => {
                if (childKey !== key) {
                    gridOptions[key].alignedGrids.push(gridOptions[childKey]);
                }
            });
        });
        dialogStore.parentDialogStore.setAlignedGrids(gridOptions);
        return gridOptions[id];
    }

    getColumnDisplayOptions = (parentDialogStore, isMaintainableTableStyle) => {
        // Initial Display Option values (PINNED, HIDDEN, FIRST Visible columns) will be pulled from the Data section.
        // So, extract these values from Data section and send to all the sections.
        const { dialogStore } = this.contextParams;
        const dataDialogStore = isMaintainableTableStyle ? dialogStore : parentDialogStore.childDialogStores.find((childStore) => childStore.dialog.view.isDataStyle);
        let firstVisibleColumnIndex;
        const hiddenColumnIndices = [];
        const pinnedColumnIndices = [];

        if (dataDialogStore) {
            const { dialog } = dataDialogStore;
            const { view } = dialog;
            const { columns } = view;

            if (dataDialogStore) {
                columns.forEach((col, index) => {
                    if (col.initialDisplay === 'FIRST') {
                        firstVisibleColumnIndex = index;
                    } else if (col.initialDisplay === 'HIDDEN') {
                        hiddenColumnIndices.push(index);
                    } else if (col.initialDisplay === 'PINNED') {
                        pinnedColumnIndices.push(index);
                    }
                });
            }
        }
        return {
            firstVisibleColumnIndex,
            hiddenColumnIndices,
            pinnedColumnIndices,
        };
    }

    getDialogStore = (dialogId) => {
        const { dialogStore } = this.contextParams;
        const { view } = dialogStore.dialog;
        const { isMaintainableTableStyle } = view;
        if (isMaintainableTableStyle) return dialogStore;
        const { parentDialogStore } = dialogStore;
        if (!dialogId) return parentDialogStore;
        return parentDialogStore.getChildDialogStore(dialogId);
    }

    getPropDef = (selectedRange, propName) => {
        const { dialogId } = selectedRange;
        const selectedStore = this.getDialogStore(dialogId);
        const { dialog } = selectedStore;
        const { recordDef } = dialog;
        return recordDef.propDefAtName(propName) || {};
    }

    getProperty = (selectedRange, record, propName) => {
        const { dialogId } = selectedRange;
        const selectedStore = this.getDialogStore(dialogId);
        return selectedStore.getProperty(record, propName);
    }

    getSelectedRecord = (selectedRange, selectedRecordId) => {
        const { dialogId } = selectedRange;
        const selectedStore = this.getDialogStore(dialogId);
        let record;
        if (selectedStore) {
            const { records } = selectedStore;
            record = records.find((rec) => rec.id === selectedRecordId);
        }
        return record;
    }

    handleClearErrors = () => {
        const { dialogStore } = this.contextParams;
        dialogStore.clearErrorRecords();
    }

    handlePaste = async (records, modifiedRecords = [], isCutAction, errors = []) => {
        const { lang } = serviceFactory;
        const localErrors = [ ...errors ];
        if (!modifiedRecords.length && !errors.length) {
            localErrors.push(lang.list.noDataAvailableToPaste);
        }
        const { onSave } = this.props;
        const { dialogStore, uiStore } = this.contextParams;
        const dropdowns = [];
        modifiedRecords.forEach((modifiedRecord) => {
            const { id, properties } = modifiedRecord;
            const record = records.find((rec) => rec.id === id);
            properties.forEach((property) => {
                const { propName, value, type, params } = property;
                if (type === DROP_DOWN_TYPE) {
                    dropdowns.push({ propName, value, record, params });
                } else {
                    dialogStore.setPropertyValue(propName, value, record);
                }
            });
        });
        // Check for the validity of the pasted dropdown value
        await Promise.all(dropdowns.map(async (dropdown) => {
            const { propName, value, record, params } = dropdown;
            const {
                destinationPropDef,
                sourcePropDef,
            } = params;
            const isValid = await this.isValidDropdownValue(propName, record.id, destinationPropDef, this.getDropdownValue(value, sourcePropDef));

            // Paste only if the provided value matches one of the available values in the dropdown list.
            if (isValid) {
                dialogStore.setPropertyValue(propName, value, record);
            } else {
                localErrors.push(lang.formatString(lang.list.invalidDropdownPaste, this.getDropdownValue(value, sourcePropDef, true), propName));
            }
            return isValid;
        }));
        if (isCutAction) {
            utilities.listHelper.clearRange(uiStore, this.getDialogStore());
        }

        if (onSave && modifiedRecords.length) {
            onSave();
        }

        if (localErrors.length) {
            this.handlePasteError(localErrors);
        }
    }

    handleRefocus = () => {
        const { dialogStore: { lastFocusedProperty } } = this.contextParams;
        if (lastFocusedProperty && lastFocusedProperty.current) {
            lastFocusedProperty.current.focus();
        }
    }

    handleRenderItem = (record, childProps) => {
        if (record) {
            const { scopeManager } = this.context;
            const newContext = {
                ...this.context,
                scopeManager: scopeManager.newScopeWithLocal(ScopeManager.SCOPED_RECORD_REF_NAME, record),
            };
            const { xStyle } = this.props;
            const { name, isReadMode } = childProps;
            return (
                <SaltContext.Provider
                    key={ record.id }
                    value={ newContext }>
                    { React.createElement(componentFactory.getAbstractComponent('property'), { ...childProps, xStyle }) }
                    { React.createElement(componentFactory.getAbstractComponent('fieldAction'), { name, isReadMode, xStyle }) }
                </SaltContext.Provider>
            );
        }
        return null;
    };

    isLoading = () => {
        const { dialogStore } = this.contextParams;
        const { dialog: { view }, queryInProgress, refreshInProgress, isRefreshTriggeredByTimer, parentDialogStore } = dialogStore;
        const { isMaintainableTableStyle } = view;
        if (isMaintainableTableStyle) {
            return (queryInProgress || refreshInProgress) && !isRefreshTriggeredByTimer;
        }
        return parentDialogStore.isMaintainableQueryInProgress;
    }

    getPasteValue = (selectedRange, source, destination, selectedRecord) => {
        const sourcePropDef = this.getPropDef(selectedRange, source);
        const destinationPropDef = this.getPropDef(selectedRange, destination);
        const sourceProperty = this.getProperty(selectedRange, selectedRecord, source);
        const { dialogStore } = this.contextParams;
        const { dialog: { view } } = dialogStore;
        const sourceColum = view.getColumnAt(source);
        const destinationColum = view.getColumnAt(destination);

        if (!sourceProperty || !sourcePropDef || !destinationPropDef || !sourceColum || !destinationColum) return {};
        if (source === destination) {
            return { value: sourceProperty.value };
        }
        if (sourcePropDef.isBooleanType) {
            if (destinationPropDef.isBooleanType) {
                return { value: sourceProperty.value };
            }
            if (destinationPropDef.isStringType && !destinationPropDef.format) {
                return { value: utilities.uiHelper.formatPropertyForRead(sourceProperty, sourcePropDef) };
            }
            if (destinationPropDef.isDecimalType || destinationPropDef.isWholeNumberType) {
                return { value: Number(sourceProperty.value) };
            }
        } else if (sourcePropDef.isDateType || sourcePropDef.isTimeType) {
            if (destinationPropDef.isDateType || destinationPropDef.isTimeType || destinationPropDef.isDateTimeType) {
                return { value: sourceProperty.value };
            }
            if (destinationPropDef.isWholeNumberType) {
                if (sourcePropDef.isTimeType) {
                    const date = utilities.uiHelper.formatPropertyForAction(sourceProperty, sourcePropDef);
                    const hours = `${date.getHours()}`.padStart(2, '0');
                    const minutes = `${date.getMinutes()}`.padStart(2, '0');
                    return { value: Number(`${hours}${minutes}`) };
                }
                return { value: Number(sourceProperty.value) };
            }
            if (destinationPropDef.isStringType && !destinationPropDef.format) {
                return { value: utilities.uiHelper.formatPropertyForRead(sourceProperty, sourcePropDef) };
            }
        } else if (sourcePropDef.isDateTimeType) {
            if (destinationPropDef.isDateType || destinationPropDef.isTimeType || destinationPropDef.isDateTimeType) {
                return { value: sourceProperty.value };
            }
        } else if (sourcePropDef.isPercentType) {
            if (destinationPropDef.isPercentType
                || (sourcePropDef.isWholeNumberType && destinationPropDef.isWholeNumberType)
                || destinationPropDef.isDecimalType) {
                return { value: sourceProperty.value };
            }
        } else if (sourcePropDef.isMoneyType) {
            if (destinationPropDef.isMoneyType
                || (sourcePropDef.isWholeNumberType && destinationPropDef.isWholeNumberType)
                || destinationPropDef.isDecimalType) {
                return { value: sourceProperty.value };
            }
        } else if (sourcePropDef.isDecimalType) {
            if (destinationPropDef.isDecimalType) {
                return { value: sourceProperty.value };
            }
            if (destinationPropDef.isStringType && !destinationPropDef.format) {
                return { value: String(sourceProperty.value) };
            }
        } else if (sourcePropDef.isWholeNumberType) {
            if (destinationPropDef.isWholeNumberType || destinationPropDef.isDecimalType) {
                return { value: sourceProperty.value };
            }
            if (destinationPropDef.isStringType && !destinationPropDef.format) {
                return { value: String(sourceProperty.value) };
            }
        } else if (sourcePropDef.isTextBlock || sourcePropDef.isMultiLineText) {
            if (destinationPropDef.isTextBlock
                || destinationPropDef.isMultiLineText
                || (destinationPropDef.isStringType && !destinationPropDef.format)) {
                return { value: sourceProperty.value };
            }
        } else if (sourceColum.isDropDownEntryMethod) {
            if (destinationColum.isDropDownEntryMethod) {
                return {
                    value: sourceProperty.value,
                    type: DROP_DOWN_TYPE,
                    params: {
                        sourcePropDef,
                        destinationPropDef,
                    },
                };
            }
            if (destinationPropDef.isStringType && !destinationPropDef.semanticType && !destinationPropDef.format) {
                return { value: String(sourceProperty.value) };
            }
        } else if (sourcePropDef.isStringType && !sourcePropDef.isPasswordType) {
            if (destinationPropDef.isStringType) {
                return { value: sourceProperty.value };
            }
        }
        const { lang } = serviceFactory;
        throw new Error(lang.formatString(lang.list.cannotPaste, source, destination));
    }

    isValidDropdownValue = (propName, recordId, propDef, value) => {
        const { dialogStore } = this.contextParams;
        const { availableValuesStore } = dialogStore;
        return new Promise((resolve) => {
            dialogStore.updateAvailableValues(propName, recordId).then(() => {
                const availableValues = (availableValuesStore && availableValuesStore.getAvailableValues(propName)) || [];
                const optionValues = this.buildOptionValues(availableValues, propDef);
                const validOption = optionValues.find((option) => option.value === value);
                resolve(!!validOption);
            });
        });
    };

    buildOptionValues(availableValues, propertyDef) {
        if (propertyDef.isCodeRefType) {
            return availableValues.map(value => { return { label: value.description, value: value.code }; });
        }
        if (propertyDef.isObjRefType) {
            return availableValues.map(value => { return { label: value.description, value: value.objectId }; });
        }
        return availableValues.map(value => { return { label: value, value }; });
    }

    getDropdownValue = (value, propertyDef, description) => {
        if (propertyDef.isCodeRefType) {
            if (description) return value.description;
            return value.code;
        }
        if (propertyDef.isObjRefType) {
            if (description) return value.description;
            return value.objectId;
        }
        return value;
    }

    handlePasteError = (errorMessages = []) => {
        const { dialogStore, uiStore } = this.contextParams;
        const { parentDialogStore } = dialogStore;
        const { id: dialogId } = parentDialogStore.dialog;
        const errors = errorMessages.map((message) => ({
            err: { message },
            type: errorTypes.soft,
        }));
        uiStore.setValueForUIObject(dialogId, MODAL_STATE, {
            isModalOpen: true,
            errors,
        });
    }
}

MaintainableList.contextType = SaltContext;
