import { CONST_PREFIX_GML, REF_PREFIX } from '../gmlConstants';


/**
 * A LayoutAttribute is a style attribute that can be exported to a style object.
 */
export default class SimpleStringAttribute {
    name ;
    value ;
    exportClass ;
    isCascading ;
    overrideName ;
    constructor(
        name,
        value,
        exportClass,
        isCascading = false,
        overrideName,
    ) {
        this.name = name;
        this.value = value;
        this.exportClass = exportClass;
        this.isCascading = isCascading;
        this.overrideName = overrideName || name;
    }

    static asRefName = (name) => {
        let result = name;
        if (name.startsWith(CONST_PREFIX_GML)) {
            // replace the @const/ prefix with REF_PREFIX.
            result = `${REF_PREFIX}${name.substring(CONST_PREFIX_GML.length)}`;
        }
        return result;
    }

    static constantAsRef(value) {
        return `$ref('${SimpleStringAttribute.asRefName(value)}')`;
    }

    static hasConstantRef(value) {
        return value.startsWith(CONST_PREFIX_GML);
    }

    static exportConstantAsRef(existingStyle, name, value) {
        existingStyle[name] = SimpleStringAttribute.constantAsRef(value); // eslint-disable-line no-param-reassign
    }

    static reportExportIssue(prop, value) {
        console.warn(`GML property ${prop} does not support referencing a constant: ${value}`);
    }

    exportTo(context, styleSet) {
        const { style } = styleSet;
        if (SimpleStringAttribute.hasConstantRef(this.value)) {
            SimpleStringAttribute.exportConstantAsRef(style, this.overrideName, this.value);
        } else {
            style[this.overrideName] = this.value;
        }
    }
}
