/* eslint-disable */
import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { TypeNames, Log, LargeProperty } from 'cv-dialog-sdk';
import format from 'xml-formatter';

import serviceFactory from '../../services/serviceFactory';
import pageController from '../../controllers/pageController';
import componentFactory from '../componentFactory';
import SaltContext from '../SaltContext';
import ScopeManager from '../ScopeManager';
import SaltComponent from '../SaltComponent';
import engineConstants from '../engineConstants';
import { utilities } from '../../utilities';
import rootStore from '../../stores/rootStore';

const {
    calendarHelper,
} = utilities;

@observer
export default class FieldAction extends SaltComponent {
    static propTypes = {
        // The label value associated with the propertyName that should be retrieved
        propertyName: PropTypes.string,
        // overrides the propertyName value if supplied (i.e. literal)
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool,
            PropTypes.number,
            PropTypes.array,
        ]),
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        viewId: PropTypes.string,
    };

    static typeName = engineConstants.component.name.fieldAction;

    render() {
        // must process substitution BEFORE combining styles.  Otherwise precedence doesn't get honored...
        const resolvedProps = this.resolveProperties(FieldAction.typeName);
        const { style, xStyle, toolTip, isReadMode: isReadModeForList = true } = resolvedProps;
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore, scopeManager } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        if (dialogStore && dialogStore.dataChange) {
            const { dialog } = dialogStore;
            const { view } = dialog;
            const scopedProperty = scopeManager.getRef(ScopeManager.SCOPED_PROPERTY_REF_NAME);
            const propName = scopedProperty ? scopedProperty.name : this.props.name;
            if (!propName) return null;
            let viewDef;
            let property;
            if (view.type === TypeNames.ListTypeName) {
                const record = scopeManager.getRef(ScopeManager.SCOPED_RECORD_REF_NAME);
                property = dialogStore.getProperty(record, propName);
                viewDef = view.getColumnAt(propName);
            }
            else {
                property = dialogStore.getProperty(propName);
                viewDef = view.getAttributeCellValue(propName);
            }
            const { actions = [] } = viewDef || {};
            const propertyDef = dialog.propDefAtName(propName) || {};
            this.addManualActions(propertyDef, actions);
            if (actions.length === 0) return null;
            let actualPropertyName = '';
            if (actions) {
                const fieldActionName = actions[0].actionId.split(':');
                if (fieldActionName[1]) {
                    actualPropertyName = fieldActionName[1];
                } else {
                    // This 'imageName' is only for safety. We can remove it once we got confirmation from scott
                    actualPropertyName = 'imageName';
                }
            }
            const { semanticType } = propertyDef;
            const isReadMode = view.type === TypeNames.ListTypeName ? isReadModeForList : dialog.isReadModeFor(propName);
            const value = isReadMode ? utilities.uiHelper.formatPropertyForRead(property, propertyDef) : utilities.uiHelper.formatPropertyForAction(property, propertyDef);

            const actionElements = [];
            actions.map((actionObject) => {
                const action = { ...actionObject };
                const { id, modes } = action;
                const visibleInReadMode = modes.find((type) => type === 'READ');
                const visibleInWriteMode = modes.find((type) => type === 'WRITE');
                const showAction = !!((isReadMode && visibleInReadMode) || (!isReadMode && visibleInWriteMode));
                const actionProps = {
                    style,
                    xStyle,
                    toolTip,
                    onFieldAction: this.handleFieldAction,
                    isReadMode,
                    action,
                    key: id,
                    semanticType,
                    showAction,
                    value,
                };
                
                // Always render field actions in List view so that width of columns can be calculated properly
                // Inside RWFieldAction, determine the visibility of these actions based on showAction prop.
                if (showAction || view.type === TypeNames.ListTypeName) {
                    const firstDayOfWeek = calendarHelper.getFirstDayofWeek(rootStore);
                    const isColorPickerAction = semanticType === engineConstants.fieldActionSemanticTypes.COLOR;
                    const isFilePickerAction = semanticType === engineConstants.fieldActionSemanticTypes.LARGE_PROPERTY;
                    const isDatePickerAction = semanticType === engineConstants.fieldActionSemanticTypes.DATE;
                    const isTimePickerAction = semanticType === engineConstants.fieldActionSemanticTypes.TIME;
                    const isDateTimePickerAction = semanticType === engineConstants.fieldActionSemanticTypes.DATETIME;
                    if (isDatePickerAction) {
                        action.clientFunction = (changedValue) => this.handleValueChanged(propName, changedValue);
                        actionProps.firstDayOfWeek = firstDayOfWeek;
                        return actionElements.push(React.createElement(componentFactory.getPlatformPropertyComponent('fieldActionDatePicker'), actionProps));
                    }
                    if (isTimePickerAction) {
                        actionProps.is24Hour = utilities.uiHelper.getIs24HourFormat();
                        actionProps.action.clientFunction = (changedValue) => this.handleValueChanged(propName, changedValue);
                        return actionElements.push(React.createElement(componentFactory.getPlatformPropertyComponent('fieldActionTimePicker'), actionProps));
                    }
                    if (isDateTimePickerAction) {
                        actionProps.is24Hour = utilities.uiHelper.getIs24HourFormat();
                        actionProps.firstDayOfWeek = firstDayOfWeek;
                        actionProps.action.clientFunction = (changedValue) => this.handleValueChanged(propName, changedValue);
                        return actionElements.push(React.createElement(componentFactory.getPlatformPropertyComponent('fieldActionDateTimePicker'), actionProps));
                    }
                    if (isColorPickerAction) {
                        actionProps.action.clientFunction = (changedValue) => this.handleValueChanged(propName, changedValue);
                        return actionElements.push(React.createElement(componentFactory.getPlatformPropertyComponent('fieldActionColorPicker'), actionProps));
                    }
                    if (isFilePickerAction) {
                        actionProps.action.clientFunction = (changedValue) => this.handleFileUpload(propName, changedValue, actualPropertyName);
                        return actionElements.push(React.createElement(componentFactory.getPlatformPropertyComponent('fieldActionFilePicker'), actionProps));
                    }
                    return actionElements.push(React.createElement(componentFactory.getPlatformPropertyComponent('fieldAction'), actionProps));
                }
            });
            return actionElements;
        }
        return null;
    }

    addManualActions(propertyDefinition, actions) {
        // TODO: Need to have this added to server side as client action.
        if (propertyDefinition.isTelephoneType) {
            const actionFound = actions.find(action => action.id === 'dialPhone:phone_field');
            if (!actionFound) {
                const telephoneActions = {
                    children: [],
                    type: 'hxgn.api.dialog.Menu',
                    actionId: 'dialPhone:phone_field',
                    id: 'dialPhone:phone_field',
                    iconUrl: 'https://s3.amazonaws.com/res.catavolt.net/xaltres/images/action/phone.png',
                    label: 'Phone',
                    modes: [
                        'READ',
                        'WRITE',
                    ],
                    multiSelectAvailable: false,
                    selectionRequired: false,
                    visible: true,
                    clientFunction: 'TELEPHONE',
                };
                actions.push(telephoneActions);
            }
        }
        if (propertyDefinition.isNumericType) {
            const actionFound = actions.find(action => action.id === 'increment:number_field');
            if (!actionFound) {
                const numberIncrementAction = {
                    children: [],
                    type: 'hxgn.api.dialog.Menu',
                    actionId: 'increment:number_field',
                    id: 'increment:number_field',
                    iconUrl: 'https://s3.amazonaws.com/res.catavolt.net/xaltres/images/action/hide.png',
                    label: 'INCREMENT',
                    modes: [
                        'WRITE',
                    ],
                    multiSelectAvailable: false,
                    selectionRequired: false,
                    visible: true,
                    clientFunction: 'INCREMENT_INTEGER',
                };
                const numberDecrementAction = {
                    children: [],
                    type: 'hxgn.api.dialog.Menu',
                    actionId: 'decrement:number_field',
                    id: 'decrement:number_field',
                    iconUrl: 'https://s3.amazonaws.com/res.catavolt.net/xaltres/images/action/show.png',
                    label: 'DECREMENT',
                    modes: [
                        'WRITE',
                    ],
                    multiSelectAvailable: false,
                    selectionRequired: false,
                    visible: true,
                    clientFunction: 'DECREMENT_INTEGER',
                };
                actions.push(numberIncrementAction);
                actions.push(numberDecrementAction);
            }
        }
        if (propertyDefinition.isDateType) {
            propertyDefinition.semanticType = engineConstants.fieldActionSemanticTypes.DATE;
            const actionFound = actions.find(action => action.id === 'openDate:date_field');
            if (!actionFound) {
                const dateAction = {
                    children: [],
                    type: 'hxgn.api.dialog.Menu',
                    actionId: 'openDate:date_field',
                    id: 'openDate:date_field',
                    iconUrl: 'https://s3.amazonaws.com/res.catavolt.net/catavolt-qa/images/calendaricon.png',
                    label: 'Date',
                    modes: [
                        'WRITE',
                    ],
                    multiSelectAvailable: false,
                    selectionRequired: false,
                    visible: true,
                    clientFunction: 'DATE',
                };
                actions.push(dateAction);
            }
        }
        if (propertyDefinition.isTimeType) {
            propertyDefinition.semanticType = engineConstants.fieldActionSemanticTypes.TIME;
            const actionFound = actions.find(action => action.id === 'openTime:time_field');
            if (!actionFound) {
                const timeAction = {
                    children: [],
                    type: 'hxgn.api.dialog.Menu',
                    actionId: 'openTime:time_field',
                    id: 'openTime:time_field',
                    iconUrl: 'https://s3.amazonaws.com/res.catavolt.net/catavolt-qa/images/calendaricon.png',
                    label: 'Time',
                    modes: [
                        'WRITE',
                    ],
                    multiSelectAvailable: false,
                    selectionRequired: false,
                    visible: true,
                    clientFunction: 'TIME',
                };
                actions.push(timeAction);
            }
        }
        if (propertyDefinition.isDateTimeType) {
            propertyDefinition.semanticType = engineConstants.fieldActionSemanticTypes.DATETIME;
            const actionFound = actions.find(action => action.id === 'openDateTime:datetime_field');
            if (!actionFound) {
                const dateTimeAction = {
                    children: [],
                    type: 'hxgn.api.dialog.Menu',
                    actionId: 'openDateTime:datetime_field',
                    id: 'openDateTime:datetime_field',
                    iconUrl: 'https://s3.amazonaws.com/res.catavolt.net/catavolt-qa/images/calendaricon.png',
                    label: 'DateTime',
                    modes: [
                        'WRITE',
                    ],
                    multiSelectAvailable: false,
                    selectionRequired: false,
                    visible: true,
                    clientFunction: 'DATETIME',
                };
                actions.push(dateTimeAction);
            }
        }
        return actions;
    }

    handleFieldAction = (action) => {
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore, onTransition, onError } = this.context;
        const { uiStore } = saltStore;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        const { actionId } = action;
        const { clientFunction } = action;

        if (engineConstants.isClientAction(clientFunction)) {
            this.performClientAction(dialogStore, clientFunction);
        } else {
            pageController.performActionWithConfirmation({ actionId, selectedArray: null, dialogStore, uiStore, onTransition, onError });
        }
    };

    performClientAction = (dialogStore, clientFunction) => {
        const propertyName = this.getPropName();
        const { scopeManager } = this.context;
        const { dialog } = dialogStore;
        switch (clientFunction) {
            case engineConstants.action.clientActions.selectAll:
                {
                    const { availableValuesStore } = dialogStore;
                    const availableValues = availableValuesStore.getAvailableValues(propertyName);
                    this.handleValueChanged(propertyName, this.buildOptionValues(availableValues));
                }
                break;
            case engineConstants.action.clientActions.selectNone:
                this.handleValueChanged(propertyName, []);
                break;
            case engineConstants.action.clientActions.clearValue:
                this.handleValueChanged(propertyName, null);
                break;
            case engineConstants.action.clientActions.formatXML:
                {
                    const propert = dialogStore.propBuffer.get(propertyName);
                    if (propert) {
                        const updatedValue = propert.value;
                        this.handleXMLValidation(propertyName, updatedValue);
                    }
                }
                break;
            case engineConstants.action.clientActions.incrementInteger:
                {
                    let propert;
                    if (dialog.view.type === TypeNames.ListTypeName) {
                        const record = scopeManager.getRef(ScopeManager.SCOPED_RECORD_REF_NAME);
                        propert = dialogStore.getProperty(record, propertyName);
                    } else {
                        propert = dialogStore.getProperty(propertyName);
                    }
                    if (propert) {
                        const updatedValue = Number(propert.value || 0);
                        this.handleValueChanged(propertyName, updatedValue + 1);
                    }
                }
                break;
            case engineConstants.action.clientActions.decrementInteger:
                {
                    let propert;
                    if (dialog.view.type === TypeNames.ListTypeName) {
                        const record = scopeManager.getRef(ScopeManager.SCOPED_RECORD_REF_NAME);
                        propert = dialogStore.getProperty(record, propertyName);
                    } else {
                        propert = dialogStore.getProperty(propertyName);
                    }
                    if (propert) {
                        const updatedValue = Number(propert.value || 0);
                        this.handleValueChanged(propertyName, updatedValue - 1);
                    }
                }
                break;
            default:
                break;
        }
    }

    buildOptionValues(availableValues) {
        return availableValues.map(value => {
            if (value.type === TypeNames.CodeRefTypeName) {
                return { label: value.description, value: value.code };
            }
            if (value.type === TypeNames.ObjectRefTypeName) {
                return { label: value.description, value: value.objectId };
            }
            return { label: value, value };
        });
    }

    handleValueChanged = (propertyName, changedValue, mimeType = undefined) => {
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore, scopeManager, onError } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        const { dialog, availableValuesStore } = dialogStore;
        const propDef = dialog.propDefAtName(propertyName);
        const { canCauseSideEffects } = propDef;
        let record;
        if (dialog.view.type === TypeNames.ListTypeName) {
            record = scopeManager.getRef(ScopeManager.SCOPED_RECORD_REF_NAME);
        }
        let resolvedValue = changedValue;

        if (changedValue) {
            if (Array.isArray(changedValue)) {
                if (propDef.isCodeRefType) {
                    const availableValues = availableValuesStore.getAvailableValues(propertyName);
                    resolvedValue = changedValue.map((change) => availableValues.find(item => item.code === change.value));
                }
                if (propDef.isObjRefType) {
                    const availableValues = availableValuesStore.getAvailableValues(propertyName);
                    resolvedValue = changedValue.map((change) => availableValues.find(item => item.objectId === change.value));
                }
            } else {
                if (propDef.isCodeRefType) {
                    const availableValues = availableValuesStore.getAvailableValues(propertyName);
                    resolvedValue = availableValues.find(item => item.code === changedValue.value);
                }
                if (propDef.isObjRefType) {
                    const availableValues = availableValuesStore.getAvailableValues(propertyName);
                    resolvedValue = availableValues.find(item => item.objectId === changedValue.value);
                }
            }
        }

        if (mimeType) {
            if (propDef.isFileMimeType) {
                dialogStore.setLargePropertyWithEncodedData(propertyName, resolvedValue, mimeType.type);
                dialogStore.setPropertyValue('fileName', mimeType.name);
            } else {
                dialogStore.setLargePropertyWithEncodedData(propertyName, resolvedValue, mimeType.type);
            }
        } else {
            dialogStore.setPropertyValue(propertyName, resolvedValue, record);
        }

        // @TODO - need a better solution here - is this still needed?
        if (canCauseSideEffects) {
            // Debounce the sideeffects so that requests will not go on every key stroke.
            this.debounce(() => dialogStore.processSideEffects(propertyName, resolvedValue).catch((err) => {
                const title = serviceFactory.lang.dialog.errors.errorProcessingPropertyChange;
                onError({
                    title,
                    err,
                });
            }), 500);
        }
    };

    debounce = (func, delay) => {
        clearTimeout(this.timerId);
        this.timerId = setTimeout(func, delay);
    }
    // createClientActions() {

    // }

    getPropName() {
        const { name } = this.props;
        const { scopeManager } = this.context;
        if (!name) {
            const prop = scopeManager.getRef(ScopeManager.SCOPED_PROPERTY_REF_NAME);
            return prop ? prop.name : null;
        }
        return name;
    }

    handleFileUpload = (propName, changedValue, actualPropetyName) => {
        const file = changedValue.target.files[0];

        const readFileDataAsBase64 = () => new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (fileEvent) => {
                resolve(fileEvent.target.result);
            };

            reader.onerror = (err) => {
                reject(err);
            };

            reader.readAsDataURL(file);
        });

        const promise = readFileDataAsBase64(file);

        promise.then((dataURI) => {
            const data = dataURI.replace(`data:${file.type || LargeProperty.DEFAULT_MIME_TYPE};base64,`, '');
            // Actually we are calling the same function twice, because this is a special case for file upload control.
            // For file upload control, the name will be stored in diff varaible and actaul data will be saved in different property. I don't want to change 'handleValueChanged' method as we will be using it for all types.
            this.handleValueChanged(actualPropetyName || 'imageName', file.name);
            this.handleValueChanged(propName, data, file);
        });
    }

    handleXMLValidation = (propertName, value) => {
        if (value) {
            const xmlFormatHandler = '^@-@^';
            const parseXmlString = serviceFactory.xmlParser;
            const options = { explicitChildren: true, preserveChildrenOrder: true, trim: true };
            parseXmlString(value, options, (error, gmlJson) => {
                if (error) {
                    Log.error(
                        `Error parsing XML:\n: ${utilities.prettyXmlParseError(value, error)}\nThe offending line may be before or after this line.`,
                        'getTenantThemeData', 'SessionStore',
                    );
                }
                if (!error && gmlJson) {
                    // valid xml so go ahead
                    const LOCAL_KEY = 'cX3b7VHj';
                    let xmlFormatedText = value.replace('<![', `${xmlFormatHandler}<![`);
                    xmlFormatedText = xmlFormatedText.replace(']]>', `]]>${xmlFormatHandler}`);
                    let formattedXml = format(xmlFormatedText.replace(/([\w:-]+)\s*=\s*'[^']*'\s*/g, (str) => {
                        const matchedString = str.match(/([\w:-]+)\s*=\s*("[^"]*"|'[^']*'|\w+)\s*/);
                        return matchedString[1] && matchedString[2]
                            ? `${matchedString[1]}='${LOCAL_KEY}${matchedString[2].trim().substring(1, matchedString[2].length - 1)}${LOCAL_KEY}'` : str;
                    }), {
                        collapseContent: true,
                        lineSeparator: '\n',
                    });
                    formattedXml = formattedXml.replace(`${xmlFormatHandler}<![`, '<![');
                    formattedXml = formattedXml.replace(`]]>${xmlFormatHandler}`, ']]>');
                    formattedXml = formattedXml.replace(new RegExp(`(("|')${LOCAL_KEY}|${LOCAL_KEY}("|'))`, 'g'), '\'');
                    if (formattedXml) {
                        this.handleValueChanged(propertName, formattedXml);
                    }
                }
            });
        }
    }
}

FieldAction.contextType = SaltContext;
