
import engineDefault from './engine';
import engineConstantsDefault from './engineConstants';
import ScopeManagerDefault from './ScopeManager';
import GlobalsDefault from './ref/Globals';
import engineUtilsDefault from './utils';
import componentFactoryDefault from './componentFactory';
import layoutComponentFactoryDefault from './form/layoutComponentFactory';
import abstractComponentProviderDefault from './abstractComponentProvider';
import abstractPropertyComponentProviderDefault from './properties/abstractPropertyComponentProvider';

export const engine = engineDefault;
export const engineConstants = engineConstantsDefault;
export const ScopeManager = ScopeManagerDefault;
export const Globals = GlobalsDefault;
export const engineUtils = engineUtilsDefault;
export const engineComponentFactory = componentFactoryDefault;
export const layoutComponentFactory = layoutComponentFactoryDefault;
export const engineAbstractComponentProvider = abstractComponentProviderDefault;
export const abstractPropertyComponentProvider = abstractPropertyComponentProviderDefault;
