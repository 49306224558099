// Core component styles
const coreStyles = {
    container: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#fff',
        position: 'absolute',
        width: '30%',
        top: 10,
        left: '35%',
        borderRadius: '10px',
        paddingBottom: '5px',
        paddingLeft: '24px',
        paddingRight: '0px',
        paddingTop: '5px',
    },
    input: {
        color: '#959595',
        width: '100%',
        minHeight: '30px',
        fontFamily: 'Roboto',
        fontSize: '14px',
        lineHeight: '16px',
    },
    icon: {
        paddingRight: '0px',
        color: '#000',
    },
};

/**
 * Combine component context styles with core styles
 * @param {Object} contextStyles
 * @return {Object}
 */
const getStyles = (contextStyles = {}) => {
    return {
        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },
        icon: {
            ...coreStyles.icon,
            ...contextStyles.icon,
        },
        input: {
            ...coreStyles.input,
            ...contextStyles.input,
        },
    };
};

export default getStyles;