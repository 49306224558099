// Core component styles
const coreStyles = {
    container: {
        // display: 'flex',
        // flexGrow: 'inherit',
        // flexShrink: 'inherit',
    },

    checkboxContainer: {
        // marginRight: '-25px',
    },

    checkbox: {
    },

    checkboxSelected: {
    },

    // TODO: This is a temp fix until we can manage style better for these components
    // This helps the dropdown size to it's container. Was for PPM bug
    dropDown: {
        // display: 'flex',
        // flexGrow: 'inherit',
        // flexShrink: 'inherit',
        width: '100%',
    },

    dropDownFocused: {
    },

    icon: {
    },

    inputContainer: {
        width: '100%',
        padding: 'unset',
    },

    input: {
        // display: 'flex',
        // @TODO The existing version of Autocomplete always overrides these
        // Remove !important when we update to latest version
        width: 'auto !important',
        padding: '2px 0px 3px 0px !important',
    },

    loader: {
        marginRight: '0.5rem',
    },

    menu: {
        // Fix for scrollbar on Windows overlapping content with 'overflowY: auto'
        // overflowY: 'scroll',
    },

    menuImageLoadingIndicator: {
    },

    menuItem: {
        display: 'flex',
        justifyContent: 'space-between',
        // Autocomplete generates the popper width equal to the parent input
        // Wrap menu items in case of an overflow
        whiteSpace: 'pre-wrap',
        width: '100%',
        wordBreak: 'break-word',
    },

    menuItemImage: {
    },

    noOptions: {
        // display: 'flex',
    },

    optionContainer: {
        display: 'none',
    },

    optionItem: {
        padding: '0px',
    },

    popper: {
        width: 'fit-content',
    },

    selectedItem: {
    },
};

/**
 * Helper method to get styles for the DropDown
 * @param {Object} contextStyles - Custom styles for the DropDown component
 */
const getStyles = (contextStyles = {}) => {
    return {
        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        checkboxContainer: {
            ...coreStyles.checkboxContainer,
            ...contextStyles.checkboxContainer,
        },

        checkbox: {
            ...coreStyles.checkbox,
            ...contextStyles.checkbox,
        },

        checkboxSelected: {
            ...coreStyles.checkboxSelected,
            ...contextStyles.checkboxSelected,
        },

        dropDown: {
            ...coreStyles.dropDown,
            ...contextStyles.dropDown,
            '&:focus': {
                ...coreStyles.dropDownFocused,
                ...contextStyles.dropDownFocused,
            },
        },

        icon: {
            ...coreStyles.icon,
            ...contextStyles.icon,
        },

        inputContainer: {
            ...coreStyles.textFieldContainer,
            ...contextStyles.textFieldContainer,
        },

        input: {
            ...coreStyles.input,
            ...contextStyles.input,
        },

        loader: {
            ...coreStyles.loader,
            ...contextStyles.loader,
        },

        menu: {
            ...coreStyles.menu,
            ...contextStyles.menu,
        },

        menuImageLoadingIndicator: {
            ...coreStyles.menuImageLoadingIndicator,
            ...contextStyles.menuImageLoadingIndicator,
        },

        menuItem: {
            ...coreStyles.menuItem,
            ...contextStyles.menuItem,
        },

        menuItemImage: {
            ...coreStyles.menuItemImage,
            ...contextStyles.menuItemImage,
        },

        noOptions: {
            ...coreStyles.noOptions,
            ...contextStyles.noOptions,
        },

        optionContainer: {
            ...coreStyles.optionContainer,
            ...contextStyles.optionContainer,
        },

        optionItem: {
            ...coreStyles.optionItem,
            ...contextStyles.optionItem,
        },

        popper: {
            ...coreStyles.popper,
            ...contextStyles.popper,
        },

        selectedItem: {
            ...coreStyles.selectedItem,
            ...contextStyles.selectedItem,
        },
    };
};

export default getStyles;
