/* @flow */
const propertyExceptions = {
    cameraLocks: 'cameraLocks',
};

const redirectionPropSetter = {
    GraphicIdProp: 'GRAPHIC_ID_PROP',
};

const uiStorage = {
    IsViewerLoaded: 'IsViewerLoaded',
    ViewSizeHW: 'ViewSizeHW',
    IsLibraryLoaded: 'IsLibraryLoaded',
    SelectedGraphicIds: 'SelectedGraphicIds',
    PropertyObject: 'PropertyObject',
};

const authorizationType = {
    SAM: 'SAM',
    OAUTH20: 'OAUTH20',
};

export const viz = {
    propertyExceptions,
    uiStorage,
    redirectionPropSetter,
    authorizationType,
};
