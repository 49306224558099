import React from 'react';
import PropTypes from 'prop-types';
import ChartWrapper from '../ChartWrapper/ChartWrapper';
import getStyles from './BarChart.styles';

/**
 * The bar chart shows different data through the height of a bar
 * which is used in rectangular coordinate with at least 1 category axis.
 */
const BarChart = (props) => {
    const {
        animation,
        contextStyles,
        grouping,
        gridLines,
        gridPosition,
        horizontal,
        horizontalGridLines,
        legend,
        maxX,
        maxY,
        minX,
        minY,
        onPress,
        onSelectItem,
        renderButtonText,
        reverse,
        seriesData,
        stacked,
        testID,
        title,
        tooltip,
        valueLabelFormat,
        verticalGridLines,
        xAxis,
        xAxisTitle,
        yAxis,
        yAxisTitle,
        zoom,
    } = props;

    const styles = getStyles(contextStyles);
    /** Build axis options */
    const axisOptions = {
        xAxis: {
            // Axis tick settings
            axisTick: {
                // Move the tick from between the bars to the center of each bar
                alignWithLabel: true,
            },

            // Axis tick labels
            data: [],

            // Show/hide axis lines
            show: xAxis,

            // Background grid lines
            splitLine: {
                // Show vertical grid lines
                show: gridLines && verticalGridLines,
            },

            // Show categories along axis ticks
            type: 'category',
        },
        yAxis: {
            // Show/hide axis lines
            show: yAxis,

            // Background grid lines
            splitLine: {
                // Show horizontal grid lines
                show: gridLines && horizontalGridLines,
            },

            // Show values along axis ticks
            type: 'value',
        },
    };

    // Show the bars from the top
    if (reverse && !horizontal) {
        axisOptions.xAxis.position = 'top';
        axisOptions.yAxis.inverse = true;
    }

    // Show the bars from the right
    if (reverse && horizontal) {
        axisOptions.xAxis.position = 'right';
        axisOptions.xAxis.inverse = true;
        axisOptions.yAxis.inverse = true;
    }

    // Show the bars from the left
    if (!reverse && horizontal) {
        axisOptions.xAxis.inverse = true;
    }

    /** Build chart options */
    const chartOptions = {
        animation,
        backgroundColor: 'transparent',
        color: [ ...ChartWrapper.defaultColors ],
        grid: {
            containLabel: xAxis || yAxis,
            show: true,
            borderWidth: 0,
            ...gridPosition,
        },
        textStyle: styles.chartTextStyle,
        xAxis: axisOptions.xAxis,
        yAxis: axisOptions.yAxis,
    };

    /** Modify axis options */
    if (horizontal) {
        chartOptions.xAxis = { ...axisOptions.yAxis };
        chartOptions.yAxis = { ...axisOptions.xAxis };
    }

    // Set axis titles after potential reversal
    if (xAxisTitle) {
        chartOptions.xAxis.name = xAxisTitle;
        chartOptions.xAxis.nameLocation = 'center';
        chartOptions.xAxis.nameGap = 25;
        chartOptions.yAxis.textStyle = styles.xAxisTextStyle;
    }
    if (yAxisTitle) {
        chartOptions.yAxis.name = yAxisTitle;
        chartOptions.yAxis.nameLocation = 'center';
        chartOptions.yAxis.nameGap = 55;
        chartOptions.yAxis.textStyle = styles.yAxisTextStyle;
        chartOptions.yAxis.axisLine = {
            show: verticalGridLines,
        };
    }

    // Set chart legend
    if (legend) {
        chartOptions.legend = {
            // Show the legend
            show: true,

            // Initialize legend series names
            data: [],

            padding: [
                5,
                10,
            ],
            symbolKeepAspect: true,
            // apply styles to legend
            textStyle: styles.legendTextStyle,

            // providing the scroll if legends doesnt fit with available area
            type: 'scroll',
            orient: horizontal ? 'vertical' : 'horizontal',
            ...styles.legendContainerStyle,
        };
    }

    // Set chart title
    if (title) {
        chartOptions.title = {
            text: title,
            textStyle: styles.titleTextStyle,
        };
    }

    // Enable chart tooltip
    if (tooltip) {
        chartOptions.tooltip = {
            confine: true,
            extraCssText: 'z-index: 10',
            padding: [
                2,
                10,
            ],
            textStyle: styles.tooltipTextStyle,
            ...styles.tooltipContainerStyle,
        };
    }

    // Enable chart zoom
    if (zoom) {
        chartOptions.dataZoom = {
            type: 'inside',
        };
    }

    // Set range parameters after potential reversal
    if (maxX || maxX === 0) {
        chartOptions.xAxis.max = maxX;
    }
    if (minX || minX === 0) {
        chartOptions.xAxis.min = minX;
    }
    if (maxX || minX) {
        chartOptions.xAxis.scale = true;
    }
    if (maxY || maxY === 0) {
        chartOptions.yAxis.max = maxY;
    }
    if (minY || minY === 0) {
        chartOptions.yAxis.min = minY;
    }
    if (maxY || minY) {
        chartOptions.yAxis.scale = true;
    }

    // Generate chart series data
    chartOptions.series = seriesData.map((barDataInfo, barIdx) => {
        const {
            barBorderColor: seriesBorderColor,
            barBorderWidth: seriesBorderWidth,
            barColor: seriesColor,
            barEmphasisColor: seriesEmphasisColor,
            barOpacity: seriesOpacity,
            barWidth: seriesBarWidth,
            dataPoints,
            legendText,
            valueLabel: seriesValueLabel,
            valueLabelColor: seriesLabelColor,
            valueLabelFormat: seriesLabelFormat,
            valueLabelPosition: seriesLabelPosition,
            valueLabelSize: seriesLabelSize,
            valueLabelWidth: seriesLabelWidth,
            ...barRest
        } = barDataInfo;

        // Generate bar series
        const barSeries = {
            // Remove gap between bar groups
            barGap: 0,

            // Initialize series bar style
            itemStyle: {},

            // This is a bar chart
            type: 'bar',

            // Pass through any extra props
            ...barRest,
        };

        // Add stack if stacked chart
        if (stacked) {
            barSeries.stack = 'BarStack';
        }

        // Add legend information
        if (legend && legendText) {
            // Add the legend item to the legend array
            chartOptions.legend.data.push(legendText);

            // Set the series name to link to the legend item
            barSeries.name = legendText;
        }

        // Add series level bar details
        if (seriesColor) {
            // Override default color spot for the legend and line symbol to reflect color
            chartOptions.color[barIdx] = seriesColor;
        }
        if (seriesBarWidth) {
            barSeries.barWidth = seriesBarWidth;
        }
        if (seriesOpacity || seriesOpacity === 0) {
            barSeries.itemStyle.opacity = seriesOpacity;
        }
        if (seriesBorderWidth || seriesBorderWidth === 0) {
            barSeries.itemStyle.barBorderWidth = seriesBorderWidth;
        }
        if (seriesBorderColor) {
            barSeries.itemStyle.barBorderColor = seriesBorderColor;
        }
        if (seriesEmphasisColor) {
            barSeries.emphasis = { itemStyle: { color: seriesEmphasisColor } };
        }

        // Add series level bar labels
        if (seriesValueLabel) {
            barSeries.label = {
                show: true,
            };
            if (seriesLabelColor) {
                barSeries.label.color = seriesLabelColor;
            }
            if (valueLabelFormat) {
                barSeries.label.formatter = valueLabelFormat.replace('{value}', '{c}');
            }
            if (seriesLabelFormat) {
                barSeries.label.formatter = seriesLabelFormat.replace('{value}', '{c}');
            }
            if (seriesLabelPosition) {
                barSeries.label.position = seriesLabelPosition || 'inside';
            }
            if (seriesLabelSize || seriesLabelSize === 0) {
                barSeries.label.fontSize = seriesLabelSize;
            }
            if (seriesLabelWidth || seriesLabelWidth === 0) {
                barSeries.label.width = seriesLabelWidth;
                barSeries.label.rich = {};
            }
        }

        // Generate bar data
        barSeries.data = dataPoints.map((barDetail) => {
            const {
                axisLabel,
                barBorderColor,
                barBorderWidth,
                barColor,
                barEmphasisColor,
                barEmphasisOpacity,
                barOpacity,
                displayValue,
                filter,
                errorMessage,
                value,
                valueLabel,
                valueLabelColor,
                valueLabelFormat: barLabelFormat,
                valueLabelPosition,
                valueLabelSize,
                valueLabelWidth,
                ...barDetailRest
            } = barDetail;

            // Add label to the x-axis label array
            if (barIdx === 0) {
                if (horizontal) {
                    chartOptions.yAxis.data.push({
                        value: axisLabel,
                        textStyle: styles.yAxisLabelTextStyle,
                    });
                }
                else {
                    chartOptions.xAxis.data.push({
                        value: axisLabel,
                        textStyle: styles.xAxisLabelTextStyle,
                    });
                }
            }

            // Generate minimum bar data
            const barData = {
                // Initialize individual bar style
                itemStyle: {},

                // Bar value
                value,
                axisLabel,

                // Pass through any extra props
                ...barDetailRest,
            };

            // Add individual level bar details
            if (barColor) {
                barData.itemStyle.color = barColor;
            }
            if (barOpacity || barOpacity === 0) {
                barData.itemStyle.opacity = barOpacity;
            }
            if (barBorderWidth || barBorderWidth === 0) {
                barData.itemStyle.barBorderWidth = barBorderWidth;
            }
            if (barBorderColor) {
                barData.itemStyle.barBorderColor = barBorderColor;
            }

            // Adding default opacity to provide more contrast on Item
            const emphasisItemStyle = { opacity: 0.5 };
            if (barEmphasisOpacity || barEmphasisOpacity === 0) {
                emphasisItemStyle.opacity = barEmphasisOpacity;
            }
            if (barEmphasisColor) {
                emphasisItemStyle.color = barEmphasisColor;
            }
            barData.emphasis = { itemStyle: emphasisItemStyle };

            if (displayValue) {
                barData.displayValue = displayValue;

                if (tooltip) {
                    barData.tooltip = {
                        formatter: `{b}: ${displayValue}`,
                    };
                }
            }

            if (errorMessage) {
                barData.tooltip = errorMessage;

                chartOptions.tooltip = {
                    appendToBody: true,
                    backgroundColor: 'rgba(233, 212, 96, 1)',
                    confine: true,
                    extraCssText: 'z-index: 10',
                    textStyle: { ...styles.tooltipTextStyle, ...styles.errorTooltipStyle },
                };
            }

            // Add series level bar labels
            if (valueLabel) {
                barData.label = {
                    show: true,
                };
                if (valueLabelColor) {
                    barData.label.color = valueLabelColor;
                }
                if (valueLabelFormat) {
                    barData.label.formatter = valueLabelFormat.replace('{value}', '{c}');
                }
                if (seriesLabelFormat) {
                    barData.label.formatter = seriesLabelFormat.replace('{value}', '{c}');
                }
                if (barLabelFormat) {
                    barData.label.formatter = barLabelFormat.replace('{value}', '{c}');
                }
                if (valueLabelPosition) {
                    barData.label.position = valueLabelPosition || 'inside';
                }
                if (valueLabelSize || valueLabelSize === 0) {
                    barData.label.fontSize = valueLabelSize;
                }
                if (valueLabelWidth || valueLabelWidth === 0) {
                    barData.label.width = valueLabelWidth;
                    barData.label.rich = {};
                }

                // TODO: add label styles
                // barDetail.label.formatter = [
                //     '{a|Style "a" is applied to this snippet}',
                //     '{b|Style "b" is applied to this snippet}This snippet use default style{x|use style "x"}',
                // ].join('\n');

                // TODO: label dimensions
                // barDetail.label.width = 50;
                // barDetail.label.height = 75;

                // TODO: position by percentage
                // barDetail.label.position = ['50%', '50%'];

                // TODO: remove (for testing dimensions)
                // barDetail.label.borderWidth = 5;
                // barDetail.label.borderColor = '#00f';
                // barDetail.label.backgroundColor = '#123234';
                // barDetail.label.rich = {
                //     a: {
                //         backgroundColor: '#fff',
                //         color: '#fff',
                //     },
                // };
            }

            return barData;
        });

        // logic to update the serie data points based on grouping
        if (grouping) {
            let result = [];
            barSeries.data.forEach((a) => {
                let found = false;
                result = result.map((datapoint) => {
                    const point = { ...datapoint };
                    if (point.name === a.axisLabel) {
                        found = true;
                        point.value += a.value;
                    }
                    return point;
                });
                if (!found) {
                    result.push({
                        name: a.axisLabel,
                        value: a.value,
                    });
                }
            });
            barSeries.data = result;
        }
        return barSeries;
    });

    // Generate chart props
    const chartProps = {
        contextStyles: {
            container: styles.container,
        },
        option: chartOptions,
    };
    if (onPress) {
        chartProps.onPress = onPress;
    }
    if (onSelectItem) {
        chartProps.onSelectItem = onSelectItem;
    }
    if (renderButtonText) {
        chartProps.renderButtonText = renderButtonText;
    }
    if (testID) {
        chartProps.testID = testID;
    }

    return (
        <ChartWrapper { ...chartProps } />
    );
};

BarChart.propTypes = {
    /** Enables/disables chart animations */
    animation: PropTypes.bool,

    /** Styles for the component */
    contextStyles: PropTypes.shape({
        /** Styles for the chart text content */
        chartTextStyle: PropTypes.object,

        /** Styles for the container */
        container: PropTypes.object,

        /** Styles for the error tooltip */
        errorTooltipStyle: PropTypes.object,

        /** Styles for the legend container */
        legendContainerStyle: PropTypes.object,

        /** Styles for the legend */
        legendTextStyle: PropTypes.object,

        /** Styles for the chart title */
        titleTextStyle: PropTypes.object,

        /** Styles for the tooltip container */
        tooltipContainerStyle: PropTypes.object,

        /** Styles for the tooltip */
        tooltipTextStyle: PropTypes.object,

        /** Styles for the xAxisLabels */
        xAxisLabelTextStyle: PropTypes.object,

        /** Styles for the xAxis */
        xAxisTextStyle: PropTypes.object,

        /** Styles for the yAxisLabels */
        yAxisLabelTextStyle: PropTypes.object,

        /** Styles for the yAxis */
        yAxisTextStyle: PropTypes.object,
    }),

    /** Enable display of grid lines */
    gridLines: PropTypes.bool,

    /** Modify grid positioning.  Can be numeric pixel or string relative */
    gridPosition: PropTypes.shape({
        bottom: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        left: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        right: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        top: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
    }),

    /** condition to group the datapoints */
    grouping: PropTypes.bool,

    /** Displays the bars horizontally from left to right */
    horizontal: PropTypes.bool,

    /** Enable display of horizontal grid lines */
    horizontalGridLines: PropTypes.bool,

    /** Enable display of a legend */
    legend: PropTypes.bool,

    /** Maximum X axis value */
    maxX: PropTypes.number,

    /** Maximum Y axis value */
    maxY: PropTypes.number,

    /** Minimum X axis value */
    minX: PropTypes.number,

    /** Minimum Y axis value */
    minY: PropTypes.number,

    /**
     * Triggered on press of a bar
     * @param {Object} itemData - Data for the bar pressed
     */
    onPress: PropTypes.func,

    /**
     * Triggered on select of a bar
     * @param {Object} itemData - Data for the bar selected
     */
    onSelectItem: PropTypes.func,

    /**
     * Used to manage the selected detail button text
     * @param {Object} dataPoint
     */
    renderButtonText: PropTypes.func,

    /** Reverse orientation of the chart */
    reverse: PropTypes.bool,

    /** Series of bar descriptions */
    seriesData: PropTypes.arrayOf(PropTypes.shape({
        /** Border color of the series bars */
        barBorderColor: PropTypes.string,

        /** Border width of the series bars */
        barBorderWidth: PropTypes.number,

        /** Color of the series bars */
        barColor: PropTypes.string,

        /** Color of the series bars on press */
        barEmphasisColor: PropTypes.string,

        /** Opacity of the series bars */
        barOpacity: PropTypes.number,

        /**
         * The width of the bar. Adaptive when not specified.
         * Can be an absolute value like 40 or a percent value like '60%'. The percent is based on the calculated category width.
         */
        barWidth: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),

        /** The data points to display for line chart */
        dataPoints: PropTypes.arrayOf(PropTypes.shape({
            /** Bar axis line text */
            axisLabel: PropTypes.string.isRequired,

            /** Border color of the individual bar */
            barBorderColor: PropTypes.string,

            /** Border width of the individual bar */
            barBorderWidth: PropTypes.number,

            /** Color of the individual bar */
            barColor: PropTypes.string,

            /** Color of the individual bar on press */
            barEmphasisColor: PropTypes.string,

            /** Opacity of the individual bar */
            barOpacity: PropTypes.number,

            /** Optional formatted value for display */
            displayValue: PropTypes.string,

            /** Optional error message - Possible a binding error */
            errorMessage: PropTypes.string,

            /** Value of the bar */
            value: PropTypes.number,

            /** Display label inside the individual bar with the bar value */
            valueLabel: PropTypes.bool,

            /** Color of the individual bar value label */
            valueLabelColor: PropTypes.string,

            /** Format string for the individual bar value label */
            valueLabelFormat: PropTypes.string,

            /** Position of individual bar value label within its bar */
            valueLabelPosition: PropTypes.oneOf([
                'inside',
                'insideLeft',
                'insideRight',
                'insideTop',
                'insideBottom',
                'insideTopLeft',
                'insideBottomLeft',
                'insideTopRight',
                'insideBottomRight',
            ]),

            /** Font size of the individual bar value label */
            valueLabelSize: PropTypes.number,

            /**
             * Width of the series value label containers
             * Can be numeric pixel value or relative string (%, em, etc)
             */
            valueLabelWidth: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string,
            ]),
        })).isRequired,

        /** Displays a legend item for the series */
        legendText: PropTypes.string,

        /** Display label inside the series bars with the bar value */
        valueLabel: PropTypes.bool,

        /** Color of the bar value label */
        valueLabelColor: PropTypes.string,

        /** Format string for the series value labels */
        valueLabelFormat: PropTypes.string,

        /** Position of series value labels within its bar */
        valueLabelPosition: PropTypes.oneOf([
            'inside',
            'insideLeft',
            'insideRight',
            'insideTop',
            'insideBottom',
            'insideTopLeft',
            'insideBottomLeft',
            'insideTopRight',
            'insideBottomRight',
        ]),

        /** Font size of the series value labels */
        valueLabelSize: PropTypes.number,

        /**
         * Width of the series value label containers
         * Can be numeric pixel value or relative string (%, em, etc)
         */
        valueLabelWidth: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
    })).isRequired,

    /** Stack bars */
    stacked: PropTypes.bool,

    /** Id used for testing */
    testID: PropTypes.string,

    /** Title text for the chart */
    title: PropTypes.string,

    /** Show related data in a tooltip after pressing a bar */
    tooltip: PropTypes.bool,

    /**
     * Format string for all value labels
     * @see https://echarts.apache.org/en/option.html#series-scatter.label.formatter for the available formatting options
     */
    valueLabelFormat: PropTypes.string,

    /** Enable display of vertical grid lines */
    verticalGridLines: PropTypes.bool,

    /** Show X axis line, ticks and labels */
    xAxis: PropTypes.bool,

    /** Text to display along the X axis */
    xAxisTitle: PropTypes.string,

    /** Show Y axis line, ticks and labels */
    yAxis: PropTypes.bool,

    /** Text to display along the Y axis */
    yAxisTitle: PropTypes.string,

    /** Enable zooming in/out chart ranges */
    zoom: PropTypes.bool,
};

BarChart.defaultProps = {
    animation: false,
    contextStyles: {},
    gridLines: true,
    gridPosition: {},
    horizontalGridLines: true,
    tooltip: true,
    valueLabelFormat: '{value}',
    verticalGridLines: false,
    xAxis: true,
    xAxisTitle: 'X',
    yAxis: true,
    yAxisTitle: 'Y',
    zoom: true,
};

export default BarChart;
