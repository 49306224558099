import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import { uiHelper } from '../utilities';
import componentFactory from './componentFactory';
import SaltComponent from './SaltComponent';
import serviceFactory from '../services/serviceFactory';

@observer
export default class CalculateStatistics extends SaltComponent {
    static propTypes = {
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        onError: PropTypes.func,
        viewId: PropTypes.string,
    };

    render() {
        const listDialogStore = this.getListDialogStore();
        const { calculateStatisticsFormStore } = listDialogStore;

        // If we don't have any calculateStatisticsFormStore, we just return/close the component
        if (!calculateStatisticsFormStore) return null;

        // Build the statistical data rows and header for the clients to use.
        const { lang } = serviceFactory;
        const { dialog: listDialog } = listDialogStore;
        const { view: listView, recordDef } = listDialog;
        const { columns } = listView;

        const rows = this.extractStatisticsData(toJS(calculateStatisticsFormStore), columns, recordDef);
        const { calculateSattisticsTitle } = lang.searchSort;

        const props = {
            ...this.resolveProperties(),
            columns,
            onCancel: this.handleCancel,
            rows,
            title: calculateSattisticsTitle,
        };
        return React.createElement(componentFactory.getPlatformComponent('calculateStatistics'), props);
    }

    getListDialogStore() {
        const {
            viewId,
        } = this.props;

        const { saltStore } = this.context;
        return saltStore.getDialogStoreForViewId(viewId);
    }

    handleCancel = () => {
        const listDialogStore = this.getListDialogStore();
        listDialogStore.setCalculateStatisticsFormStore(null);
    };

    extractStatisticsData = (calculateStatistics, columns, recordDef) => {
        return calculateStatistics.records.map((record) => {
            const statisticsRow = columns.map((column) => {
                const { propertyName } = column;
                const property = record.propAtName(propertyName);
                const propDef = recordDef.propDefAtName(propertyName);

                // We dont have the specific propDef for Calculate statistics, we are using List propDef to extract the value in Read format.
                // But for passwordField, we are receving numeric value ( for count statistics ), that the reason we are handling this seaparately.
                if (propDef && propDef.isPasswordType && property) {
                    return property.value ? String(property.value).replace(/./g, '*') : '';
                }
                if (record.id.toLowerCase && record.id.toLowerCase() === 'count') {
                    return String(property.value);
                }
                return uiHelper.formatPropertyForRead(property, propDef) || '';
            });

            return {
                id: record.id,
                record: statisticsRow,
            };
        });
    }
}
