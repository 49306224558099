import React from 'react';
import * as PropTypes from 'prop-types';
import MUIRadio from '@material-ui/core/Radio';

import { withAdditionalStyles } from '../hoc/withAdditionalStyles';

/**
 * A control that allows a user to select one or more items from a set.
 * @see https://material-ui.com/components/radio/
 */
const XaltRadio = (props) => {
    const {
        value,
        onChange,
        disabled,
        defaultThemeColor,
        style,
        hoverStyle,
        focusStyle,
        autoFocus,
        checked,
        onFocus,
        onBlur,
        onMouseOver,
        onMouseOut,
    } = props;
    const inputEventProps = {
        onFocus,
        onBlur,
        onMouseOver,
        onMouseOut,
    };

    return (
        <MUIRadio
            autoFocus={ autoFocus }
            color={ defaultThemeColor }
            checked={ checked }
            style={ { ...style, ...hoverStyle, ...focusStyle, backgroundColor: 'transparent' } }
            disabled={ disabled }
            value={ value }
            onChange={ (event) => {
                if (onChange) {
                    onChange(event.target.value);
                }
            } }
            inputProps={ inputEventProps } />
    );
};

XaltRadio.propTypes = {
    /** Focus on the Radio */
    autoFocus: PropTypes.bool,

    /** checked value */
    checked: PropTypes.bool,

    /** The radio selected value */
    value: PropTypes.bool,

    /** Extended styles for this component */
    style: PropTypes.object,

    /**
     * Called when the radio was changed
     * @param {Object} event - The native change event
     * @param {Object} props - The component instance props
     */
    onChange: PropTypes.func,

    disabled: PropTypes.bool,

    defaultThemeColor: PropTypes.string,

    /** Styling applied on hover */
    hoverStyle: PropTypes.object,

    /** Styling applied on focus */
    focusStyle: PropTypes.object,

    /** provided by the HOC */
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
};

XaltRadio.defaultProps = {
    style: {},
    value: '',
    hoverStyle: {
        color: '#1C8EA9',
    },
    focusStyle: {
        color: '#1C8EA9',
    },
    defaultThemeColor: 'default',
};

export default withAdditionalStyles(XaltRadio);
