import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { constants } from '../../constants';
import layoutComponentFactory from './layoutComponentFactory';
import componentFactory from '../componentFactory';
import rootStore from '../../stores/rootStore';

@observer
export default class FormLayout extends Component {
    static propTypes = {
        formDialogStore: PropTypes.object.isRequired,
        elementCallback: PropTypes.func.isRequired,
        // override the formLayout type specified by the server
        type: PropTypes.string,
        /**
         * The child dialog stores that will be included in the form
         */
        childDialogStores: PropTypes.array.isRequired,
    };

    static LAYOUT_QUANTITIES = {
        DEFAULT: 2,
        TOP_DOWN: 2,
        FOUR_BOX_SQUARE: 4,
        THREE_ONE_LEFT: 3,
        THREE_ONE_OVER: 3,
        THREE_ONE_RIGHT: 3,
        THREE_ONE_UNDER: 3,
        'H(V(1,2),1)': 3, // THREE_ONE_LEFT
        'H(2,V(*))': 3, // THREE_ONE_UNDER
        'H(2,2)': 4, // FOUR_BOX_SQUARE
    };
    static FORM_VIEW_MODE = {
        SINGLE_VIEW: 'SINGLE_VIEW',
        TAB_VIEW: 'TAB_VIEW',
        EXPANDED_VIEW: 'EXPANDED_VIEW',
        MULTI_VIEW: 'MULTI_VIEW',
    };
    static TAB_LAYOUT_NAME = 'TABS';

    constructor(props) {
        super(props);
        const { formDialogStore, childDialogStores } = this.props;
        const { isTabsLayout } = formDialogStore.dialog.view;

        if (isTabsLayout && childDialogStores.length) {
            // NOTE: (isTabsLayout) might should be (isTabsLayout && childDialogStores.length > 1)
            // That would be here, and a few lines down also.
            const { uiStore } = rootStore;
            const activeIndex = uiStore.getValueForUIObject(formDialogStore.dialog.id, constants.ui.ACTIVE_TAB_INDEX) || 0;
            this.activeTabId = activeIndex;
            const visibleDialogId = childDialogStores[activeIndex < childDialogStores.length ? activeIndex : (childDialogStores.length - 1)].dialog.id;
            formDialogStore.setTabSingleDialogId(visibleDialogId);
        } else {
            this.activeTabId = 0;
        }
    }

    render() {
        return this.getFormLayoutElement();
    }

    componentWillUnmount() {
        const { formDialogStore } = this.props;
        const { uiStore } = rootStore;
        uiStore.setValueForUIObject(formDialogStore.dialog.id, constants.ui.ACTIVE_TAB_INDEX, this.activeTabId);
    }

    /**
      * @description Returns a JSX Element representing a Form Layout. If the Layout is
      * overflowing with too many components it will attempt to force the layout into a
      * tabbed version of the provided Layout.
      *
      */
    getFormLayoutElement() {
        const { formDialogStore, elementCallback, childDialogStores, type } = this.props;
        if (formDialogStore.isExpandedOrSinglePanelView) {
            const childDialogStore = formDialogStore.expandedOrSingleChildStore;
            const formViewMode = formDialogStore.isExpandedState ? FormLayout.FORM_VIEW_MODE.EXPANDED_VIEW : FormLayout.FORM_VIEW_MODE.SINGLE_VIEW;
            const childElement = elementCallback(childDialogStore, formViewMode);
            return this.getSingleLayoutItem(childElement);
        }

        const { view: form } = formDialogStore.dialog;
        const formLayout = type || form.formLayout;
        const layoutComponent = layoutComponentFactory.getLayoutComponent(formLayout) || layoutComponentFactory.getLayoutComponent('DEFAULT_LAYOUT');

        if (this.isTabsLayout(formLayout) && childDialogStores.length) {
            const elementsWithTitles = this.createTabElementsWithTitles(elementCallback, childDialogStores, FormLayout.FORM_VIEW_MODE.TAB_VIEW);
            return this.getTabLayout(elementsWithTitles);
        }
        // @TODO note - we could also base the overflow policy on the form factor / device size
        const policy = componentFactory.getPlatformFormOverflowPolicy();
        if (policy === 'TABS' && this.hasOverflow(childDialogStores, formLayout)) {
            const layoutMaxQuantity = this.getLayoutMaxQuantity(formLayout);
            const mainComponentFormElements = this.createChildElements(
                elementCallback,
                childDialogStores.slice(0, layoutMaxQuantity), FormLayout.FORM_VIEW_MODE.MULTI_VIEW,
            );
            const mainComponent = this.getLayout(layoutComponent, mainComponentFormElements);

            const overFlowLabeledFormElements = [
                { title: this.getTabTitle(formDialogStore), childElement: mainComponent },
                ...this.createTabElementsWithTitles(elementCallback, childDialogStores.slice(layoutMaxQuantity), FormLayout.FORM_VIEW_MODE.TAB_VIEW),
            ];
            return this.getTabLayout(overFlowLabeledFormElements, {});
        }

        return this.getLayout(layoutComponent, this.createChildElements(
            elementCallback,
            childDialogStores,
            FormLayout.FORM_VIEW_MODE.MULTI_VIEW,
        ), {
            onResizeStop: this.handleResizeStop,
        });
    }

    getTabTitle(dialogStore) {
        return dialogStore.dialog.view.findTitle() || dialogStore.dialog.view.name;
    }

    getTabLayout(elementsWithTitles, props) {
        const TabFormLayout = layoutComponentFactory.getLayoutComponent(FormLayout.TAB_LAYOUT_NAME);
        const { formDialogStore } = this.props;
        const { uiStore } = rootStore;
        const activeIndex = uiStore.getValueForUIObject(formDialogStore.dialog.id, constants.ui.ACTIVE_TAB_INDEX) || 0;
        return React.createElement(TabFormLayout, { labeledFormElements: [ ...elementsWithTitles ], onChangeTab: this.onChangeTab, ...props, activeIndex });
    }

    getLayout(layoutComponent, elements, props = {}) {
        return React.createElement(layoutComponent, { formElements: elements, ...props });
    }

    getSingleLayoutItem(element, props = {}) {
        return React.createElement(layoutComponentFactory.getLayoutComponent('SINGLE'), { formElement: element, ...props });
    }

    getLayoutMaxQuantity(formLayout) {
        return FormLayout.LAYOUT_QUANTITIES[formLayout] || layoutComponentFactory.getLayoutComponent('DEFAULT_LAYOUT');
    }

    hasOverflow(childDialogStores, formLayout) {
        return this.isTabsLayout(formLayout) ? false : this.getLayoutMaxQuantity(formLayout) < childDialogStores.length;
    }

    isTabsLayout(layoutName) {
        return layoutName === FormLayout.TAB_LAYOUT_NAME;
    }

    createChildElements(elementCallback, childDialogStores, formViewMode) {
        return childDialogStores.map((childDialogStore) => {
            return elementCallback(childDialogStore, formViewMode);
        });
    }

    createTabElementsWithTitles(elementCallback, childDialogStores, formViewMode) {
        return childDialogStores.map((childDialogStore) => {
            return {
                title: this.getTabTitle(childDialogStore),
                childElement: elementCallback(childDialogStore, formViewMode),
            };
        });
    }

    handleResizeStop = (viewId, width, height) => {
        const { uiStore } = rootStore;
        uiStore.setValueForUIObject(viewId, constants.ui.DIALOG_WIDTH, width);
        uiStore.setValueForUIObject(viewId, constants.ui.DIALOG_HEIGHT, height);
    };

    onChangeTab = (event, persistTab = true) => {
        const index = event.i;
        const { formDialogStore, childDialogStores, type } = this.props;
        const { view: form } = formDialogStore.dialog;
        const formLayout = type || form.formLayout;
        if (this.hasOverflow(childDialogStores, formLayout) && index === 0) {
            formDialogStore.setTabSingleDialogId(null);
        } else {
            // Since the view could contain multi views we need to increase the index by the
            // number of screens that could be layed out on the overflow tab. Example is the first tab could have
            // x number of views and the second tab dialog would be the first tabs quantity + the index.
            const quantityIncrement = this.getLayoutMaxQuantity(formLayout);
            const dialogIndexId = quantityIncrement > 0 ? index + (quantityIncrement - 1) : index;
            const visibleDialogId = childDialogStores[dialogIndexId].dialog.id;
            formDialogStore.setTabSingleDialogId(visibleDialogId);
            this.activeTabId = index;
            // This condition has been implemented to resolve the tab's issue on XNA. onchange event was triggering multiple times when we set the uistore.
            if (persistTab) {
                const { uiStore } = rootStore;
                uiStore.setValueForUIObject(formDialogStore.dialog.id, constants.ui.ACTIVE_TAB_INDEX, index);
            }
        }
    };
}
