import React, { Component, Fragment } from 'react';
import * as PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { serviceFactory } from 'cv-react-core';

import SimpleModal from '../../modal/SimpleModal';
import Icon from '../../base/Icon';
import IconButton from '../../base/IconButton';
import TextLabel from '../../base/TextLabel';
import { BUTTON_VARIANT } from '../../base/Button';
import lang from '../../../nls/i18n';

import getStyles from './PasswordExpiryPrompt.styles';


// TODO: this components logic can be lifted to the LoginRoute component and most likely converted to a library functional component for improved performance
@observer
class PasswordExpiryPrompt extends Component {
    static defaultProps = {
        contextStyles: {},
    };

    static propTypes = {
        contextStyles: PropTypes.object,
        sessionStore: PropTypes.object,
        passwordExpiryInXDays: PropTypes.bool,
        uiStore: PropTypes.object,
    };

    render() {
        const {
            sessionStore,
            contextStyles,
            passwordExpiryInXDays,
        } = this.props;
        const styles = getStyles(contextStyles);

        const {
            passwordExpired,
            changePasswordPrompt,
        } = sessionStore;

        if ((passwordExpired || passwordExpiryInXDays) && !changePasswordPrompt) {
            const title = (
                <div style={ styles.titleContainer }>
                    <IconButton
                        contextStyles={ {
                            button: styles.closeIconButton,
                            icon: styles.closeIcon,
                        } }
                        iconName="cancel_circle"
                        iconSize={ 32 }
                        onClick={ this.handleCancelAction }
                        title={ lang.generic.cancel } />
                </div>
            );
            const message = (
                <Fragment>
                    <Icon
                        contextStyles={ {
                            icon: styles.infoIcon,
                        } }
                        iconName="error_circle"
                        iconSize={ 48 } />
                    <TextLabel contextStyles={ {
                        text: styles.text,
                    } }>
                        { passwordExpiryInXDays ? sessionStore.getPasswordExpriyXDaysFromSession() : lang.login.passwordExpiredHelperText }
                    </TextLabel>
                </Fragment>
            );
            return (
                <SimpleModal
                    show
                    onConfirm={ this.handleProceedAction }
                    title={ title }
                    message={ message }
                    contextStyles={ styles }
                    confirmButtonVariant={ BUTTON_VARIANT.CONTAINED } />
            );
        }

        return null;
    };

    handleProceedAction = () => {
        const { sessionStore } = this.props;
        sessionStore.setChangePasswordPrompt(true);
    }

    handleCancelAction = () => {
        this.getDeviceProps();
        const {
            sessionStore,
        } = this.props;

        sessionStore.changeCredentials.clear();

        if (sessionStore.passwordExpired){
            sessionStore.setPasswordExpired(false);
        }
        else if (sessionStore.passwordExpiryInXDays){
            sessionStore.setPasswordExpiryInXDays(false);
        }

        sessionStore.setChangePasswordPrompt(false);
    }

    getDeviceProps = () => {
        const { uiStore } = this.props;
        return serviceFactory.device.getDeviceProps(uiStore, serviceFactory.device.deviceSize);
    };
}

export default PasswordExpiryPrompt;
