import { Menu, TypeNames } from 'cv-dialog-sdk';
import PropTypes from 'prop-types';
import React from 'react';
import { observer } from 'mobx-react';

import pageController from '../controllers/pageController';
import { utilities } from '../utilities';
import componentFactory from './componentFactory';
import engineConstants from './engineConstants';
import RefUtil from './ref/RefUtil';
import SaltComponent from './SaltComponent';
import SaltContext from './SaltContext';
import ScopeManager from './ScopeManager';

@observer
export default class Action extends SaltComponent {
    static propTypes = {
        id: PropTypes.string,
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        assert: PropTypes.shape({
            refName: PropTypes.string,
            expr: PropTypes.string,
        }),
        expr: PropTypes.string,
        type: PropTypes.oneOf([ engineConstants.action.type.button, engineConstants.action.type.text, engineConstants.action.type.custom ]),
        children: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
            PropTypes.arrayOf(PropTypes.element),
        ]),
        viewId: PropTypes.string,
    };

    static typeName = engineConstants.component.name.action;

    static isValidActionMode(menuItem, dialogStore) {
        if (menuItem) {
            if (dialogStore.dialog.isReadViewMode) {
                if ((menuItem.actionId === engineConstants.action.clientActions.edit) && !dialogStore.dialog.view.editable) {
                    return false;
                }
                return Menu.isRead(menuItem);
            }
            return Menu.isWrite(menuItem);
        }
        return true;
    }


    render() {
        const { assert, type, xStyle } = this.props;
        const id = this.resolveId();
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore, scopeManager } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        if (dialogStore && dialogStore.dataChange) {
            if (this.determineVisibility(assert, dialogStore, scopeManager, saltStore)) {
                const menuItem = this.getMenuItem();
                if (Action.isValidActionMode(menuItem, dialogStore)) {
                    const componentName = type === engineConstants.action.type.button ?
                        (xStyle?.buttonType === 'secondary' ? engineConstants.action.type.buttonSecondary : engineConstants.action.type.buttonPrimary) :
                        Action.typeName; 
                    const resolvedProps = this.resolveProperties(componentName);
                    const onFireAction = engineConstants.isClientAction(id) ? this.handleFireClientAction : this.handleFireAction;
                    const newProps = {
                        id: resolvedProps.id,
                        menuItem,
                        onFireAction,
                    };
                    return this.wrapWithContext(
                        'Action',
                        resolvedProps,
                        React.createElement(componentFactory.getPlatformComponent('action'), { ...resolvedProps, ...newProps }),
                    );
                }
            }
        }
        return null;
    }

    handleFireAction = () => {
        const actionId = this.resolveId();
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore, scopeManager, onTransition, onError } = this.context;
        const targetDialogStore = saltStore.getDialogStoreForViewId(viewId);
        const { uiStore } = saltStore;
        let selectedArray = null;
        if (targetDialogStore.dialog.view.type === TypeNames.ListTypeName) {
            const record = scopeManager.getRef(ScopeManager.SCOPED_RECORD_REF_NAME);
            selectedArray = record ? [ record.id ] : utilities.listHelper.getSelectedAsArray(uiStore, targetDialogStore);
        }
        let dialogStore = targetDialogStore;
        if (!engineConstants.isClientAction(actionId)) {
            // check that this dialog actually has this server action, otherwise try the top-level (form) dialog
            const menu = actionId && targetDialogStore.dialog.findMenuAt(actionId);
            if (!menu) {
                dialogStore = targetDialogStore.getRootDialogStore();
            }
        }
        pageController.performActionWithConfirmation({
            actionId,
            selectedArray,
            dialogStore,
            uiStore,
            onTransition,
            onError,
        });
    };

    handleFireClientAction = () => {
        const actionId = this.resolveId();
        if (actionId === engineConstants.action.clientActions.expr) {
            this.evalutateExpression();
        } else {
            this.handleFireAction();
        }
    };

    evalutateExpression() {
        const { expr } = this.props;
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore, scopeManager } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        RefUtil.evaluateExpression(expr, dialogStore, scopeManager, saltStore);
    }

    getMenuItem() {
        const actionId = this.resolveId();
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        if (engineConstants.isClientAction(actionId)) {
            if (actionId === engineConstants.action.clientActions.save) {
                const label = dialogStore.dialog.view.commitButtonText;
                return this.createMenuItem(actionId, actionId, label, [ 'WRITE' ]);
            } if (actionId === engineConstants.action.clientActions.cancel) {
                const label = dialogStore.dialog.view.cancelButtonText;
                return this.createMenuItem(actionId, actionId, label, [ 'WRITE' ]);
            } if (actionId === engineConstants.action.clientActions.edit) {
                const label = 'Edit'; // @TODO this should come from somewhere locale aware
                return this.createMenuItem(actionId, actionId, label, [ 'READ' ]);
            } if (actionId === engineConstants.action.clientActions.refresh) {
                const label = 'Refresh'; // @TODO this should come from somewhere locale aware
                return this.createMenuItem(actionId, actionId, label, [ 'READ', 'WRITE' ]);
            } if (actionId === engineConstants.action.clientActions.close) {
                const label = 'Close'; // @TODO this should come from somewhere locale aware
                return this.createMenuItem(actionId, actionId, label, [ 'READ', 'WRITE' ]);
            } if (actionId === engineConstants.action.clientActions.home) {
                const label = 'Main Menu'; // @TODO this should come from somewhere locale aware
                return this.createMenuItem(actionId, actionId, label, [ 'READ', 'WRITE' ]);
            } if (actionId === engineConstants.action.clientActions.search) {
                const label = 'Search'; // @TODO this should come from somewhere locale aware
                return this.createMenuItem(actionId, actionId, label, [ 'READ', 'WRITE' ]);
            }
            return this.createMenuItem(actionId, actionId, actionId, [ 'READ', 'WRITE' ]);
        }
        const { menu } = dialogStore.dialog.view;
        return menu ? menu.findAtActionId(actionId) : null;
    }

    createMenuItem(id, actionId, label, modes) {
        return { id, actionId, label, modes };
    }

    resolveId() {
        const { id, expr } = this.props;
        // This action has an expr rather than an explicit actionId
        if (expr) {
            return engineConstants.action.clientActions.expr;
        }
        // resolve any substitution on the actionId
        const { scopeManager, saltStore } = this.context;
        const viewId = this.getViewId(this.props.viewId);
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        return RefUtil.substituteValues({ id }, scopeManager, dialogStore, saltStore).id;
    }

    getPropsWithSubstitution() {
        const { id } = this.props;
        const { saltStore, scopeManager } = this.context;
        const viewId = this.getViewId(this.props.viewId);
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        return {
            ...super.getPropsWithSubstitution(),
            ...RefUtil.substituteValues({ id }, scopeManager, dialogStore, saltStore),
        };
    }
}

Action.contextType = SaltContext;
