import ActionIconComponentBuilder from './ActionIconComponentBuilder';
import EmailActionIcon from '../../components/base/EmailActionIcon';

/**
 * @deprecated This component is being deprecated after  component refactoring.
 */
class EmailActionComponentBuilder extends ActionIconComponentBuilder {
    constructor() {
        super();
        this.setActionIconComponent(EmailActionIcon);
    }

    processAction() {
        // Populate custom action data
        this.processActionData('SEND_EMAIL');

        // Finish action processing after custom action data populated
        super.processAction();
    }
}

export default EmailActionComponentBuilder;
