import { rootStore } from 'cv-react-core';

const getStyles = (contextStyles) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();
    const textStyles = {
        ...theme.fonts.default,
        color: '#000000',
        fontFamily: 'Roboto',
        fontSize: '14px',
        lineHeight: '20px',
    };

    // Populate the component parts with theme styles
    return {
        // TODO: AppBar needs to support raw elements and control the layout
        // Need to provide a container element due to the TODO in this file.
        container: {
            display: 'flex',
            justifyContent: 'flex-start',
            paddingTop: '0px',
            paddingBottom: '0px',
            paddingLeft: '24px',
            paddingRight: '24px',
            boxSizing: 'border-box',
            alignItems: 'center',
            overflow: 'hidden',
            minHeight: '20px',
            marginTop: '12px',
            ...contextStyles.container,
        },

        link: {
            ...textStyles,
            direction: 'ltr',
            unicodeBidi: 'bidi-override',
            ...contextStyles.link,
            '$.hover': {
                cursor: 'pointer',
            },
        },

        // This will control the text overlap and provide an ellipsis
        overlap: {
            ...textStyles,
            direction: 'rtl',
            overflow: 'hidden',
            textAlign: 'left',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            ...contextStyles.overlap,
        },

        spacer: {
            ...textStyles,
            direction: 'ltr',
            unicodeBidi: 'bidi-override',
            padding: '0px 9px',
            ...contextStyles.link,
            ...contextStyles.spacer,
        },
    };
};

export default getStyles;
