import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { TypeNames } from 'cv-dialog-sdk';
import rootStore from '../stores/rootStore';
import componentFactory from './componentFactory';
import RefUtil from './ref/RefUtil';
import SaltContext from './SaltContext';
import ScopeManager from './ScopeManager';
import SaltComponent from './SaltComponent';
import engineConstants from './engineConstants';
import Layout from './xStyle/Layout';

@observer
export default class Label extends SaltComponent {
    static propTypes = {
        // The label value associated with the propertyName that should be retrieved
        propertyName: PropTypes.string,
        // overrides the propertyName value if supplied (i.e. literal)
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool,
            PropTypes.number,
            PropTypes.object,
        ]),
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        viewId: PropTypes.string,
    };

    static typeName = engineConstants.component.name.label;

    render() {
        // must process substitution BEFORE combining styles.  Otherwise precedence doesn't get honored...
        const resolvedProps = this.resolveProperties(Label.typeName);
        const { propertyName, value, style, xStyle, ...rest } = resolvedProps;
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore, scopeManager } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        if (dialogStore && dialogStore.dataChange) {
            let labelValue = null;
            let constantStyles = {};
            if (value) {
                labelValue = value;
            } else {
                let propName = propertyName;
                if (!propName) {
                    const prop = scopeManager.getRef(ScopeManager.SCOPED_PROPERTY_REF_NAME);
                    propName = prop ? prop.name : null;
                }
                if (dialogStore.dialog.view.type === TypeNames.DetailsTypeName) {
                    constantStyles = this.getConstantStyle(propName, xStyle, dialogStore);
                    labelValue = this.getValueForDetails(dialogStore, propName);
                } else if (dialogStore.dialog.view.type === TypeNames.ListTypeName) {
                    labelValue = this.getValueForList(dialogStore, propName);
                }
            }
            const themeStyle = this.getDetailsThemeStyle();
            const textProps = { style: Layout.combineStyles(themeStyle, { ...style, ...constantStyles }), xStyle, ...rest };
            return React.createElement(componentFactory.getAbstractComponent('text'), textProps, labelValue);
        }
        return null;
    }

    getValueForDetails(dialogStore, propName) {
        const labelCellValue = dialogStore.dialog.view.getLabelForProperty(propName);
        return labelCellValue ? labelCellValue.value : '';
    }

    getValueForList(dialogStore, propName) {
        const column = dialogStore.dialog.view.getColumnAt(propName);
        return column ? column.heading : '';
    }

    getDetailsThemeStyle() {
        const { themeStore } = rootStore;
        const theme = themeStore.getSanitizedTheme();
        return theme.fonts.detailsLabel;
    }

    getListThemeStyle() {
        // A label in a list is usually used as a column header
        // Use the theme value from compact list header
        const { themeStore } = rootStore;
        const theme = themeStore.getSanitizedTheme();
        return theme.fonts.compactListColNLabel;
    }

    getConstantStyle(name, xStyle, dialogStore) {
        const constant = dialogStore.dialog.view.getLabelForProperty(name);
        if (!constant) return {};
        if (constant.isHeading1Style) {
            return xStyle.heading1;
        }
        if (constant.isHeading2Style) {
            return xStyle.heading2;
        }
        if (constant.isHeading3Style) {
            return xStyle.heading3;
        }
        if (constant.isHeading4Style) {
            return xStyle.heading4;
        }
        if (constant.isSectionHeadingStyle) {
            return xStyle.sectionHeading;
        }
        return {};
    }

    // override the base to retrive substituted value that may contain translation / session value.
    getPropsWithSubstitution() {
        const { value } = this.props;
        const { saltStore, scopeManager } = this.context;
        const viewId = this.getViewId(this.props.viewId);
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        return {
            ...super.getPropsWithSubstitution(),
            ...RefUtil.substituteValues({ value }, scopeManager, dialogStore, saltStore),
        };
    }
}

Label.contextType = SaltContext;
