import { Catavolt, CatavoltAuth, Log, storage } from 'cv-dialog-sdk';

/* eslint-disable class-methods-use-this */
class OAuth {
    static CREATE_SESSION_TOKEN_PARAM_NAME = 'oauthToken';
    static PROOF_KEY = 'proofKey';

    /**
     * @returns {Promise<{ permissionToken, proofKey }>}
     */
    authenticate(proofKey, initUrl) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async(resolve, reject) => {
            try {
                // Save the 'proofKey' in localStorage so that it will be used while making loginWithToken call
                storage.setItem(OAuth.PROOF_KEY, proofKey.toString('hex'));
                this.openInCustomTab(initUrl);
                resolve();
            }
            catch (e) {
                Log.error(e);
                reject(e);
            }
        });
    }

    async fetchOAuthUrl(tenantId, proofKey, oAuthAuthorizationUrl = '', oAuthExpectModelAuthorizationResult = false) {
        if (oAuthExpectModelAuthorizationResult) {
            const { oAuthLoginURL } = await Catavolt.getOAuthAuthorization(proofKey, oAuthAuthorizationUrl, 'DESKTOP', window.location.href);
            return oAuthLoginURL;
        }
        const oAuthUrl = await CatavoltAuth.getOAuthUrl(tenantId, proofKey, oAuthAuthorizationUrl, 'DESKTOP', window.location.href);
        return oAuthUrl;
    }

    openInCustomTab(url, additionalUrls) {
        if (Array.isArray(additionalUrls)) {
            additionalUrls.forEach((childUrl) => {
                Promise.resolve(window.open(childUrl, '_blank'));
            });
        }
        return Promise.resolve(window.open(url, '_self'))
            .then((launched) => {
                Log.debug(`Launched custom tabs: ${launched}`);
            })
            .catch((err) => {
                Log.error(err);
            });
    }

    extractPermissionToken() {
        const params = new URLSearchParams(window.location.search);
        const oAuthtoken = params.get(OAuth.CREATE_SESSION_TOKEN_PARAM_NAME);
        return oAuthtoken;
    }
}

const oAuth = new OAuth();
export default oAuth;
export {
 OAuth,
};
