/**
 * A representation of the busy SDK.
 */
export default class SdkBusyStatus {
    constructor(dialogId: Number = -1, { loadingText, detailText, menu }, existingStatus: Object, lang: Object) {
        this.dialogId = dialogId;
        if (loadingText) {
            this.loadingText = loadingText;
        } else if (menu) {
            this.loadingText = lang.formatString(lang.loadingStatus.performingAction, menu.label);
        } else if (existingStatus) {
            this.loadingText = existingStatus.getLoadingText();
        } else {
            this.loadingText = lang.loadingStatus.loading;
        }
        this.detailText = detailText || lang.loadingStatus.pleaseWait;
    }

    getDialogId() {
        return this.dialogId;
    }

    getLoadingText() {
        return this.loadingText;
    }

    getDetailText() {
        return this.detailText;
    }

    differsFrom = (otherStatus) => {
        return !otherStatus || this.getLoadingText() !== otherStatus.getLoadingText()
            || this.getDetailText() !== otherStatus.getDetailText();
    }
}
