// Core component styles
const coreStyles = {
    container: {
        display: 'flex',
        flexFlow: 'row wrap',
        padding: '0px 12px 0px',
    },

    launcher: {
    },

    launcherActionAreaContainer: {
    },

    launcherContentContainer: {
    },

    launcherIcon: {
    },

    launcherIconContainer: {
    },

    launcherIconLoadingIndicator: {
    },

    launcherText: {
    },

    launcherTextContainer: {
    },
};

/**
 * Helper method to get the styles for the component
 * @param {Object} contextStyles - Context styles for the Component
 */
const getStyles = (contextStyles = {}) => {
    return {
        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        launcher: {
            ...coreStyles.launcher,
            ...contextStyles.launcher,
        },

        launcherActionAreaContainer: {
            ...coreStyles.launcherActionAreaContainer,
            ...contextStyles.launcherActionAreaContainer,
        },

        launcherContentContainer: {
            ...coreStyles.launcherContentContainer,
            ...contextStyles.launcherContentContainer,
        },

        launcherIcon: {
            ...coreStyles.launcherIcon,
            ...contextStyles.launcherIcon,
        },

        launcherIconContainer: {
            ...coreStyles.launcherIconContainer,
            ...contextStyles.launcherIconContainer,
        },

        launcherIconLoadingIndicator: {
            ...coreStyles.launcherIconLoadingIndicator,
            ...contextStyles.launcherIconLoadingIndicator,
        },

        launcherText: {
            ...coreStyles.launcherText,
            ...contextStyles.launcherText,
        },

        launcherTextContainer: {
            ...coreStyles.launcherTextContainer,
            ...contextStyles.launcherTextContainer,
        },


    };
};

export default getStyles;
