import { TypeNames } from './types';
export class MapLocationProperty {
    constructor(latitude, longitude, type = TypeNames.MapLocationPropertyTypeName) {
        this.latitude = latitude;
        this.longitude = longitude;
        this.type = type;
    }
    toString() {
        return this.latitude + ':' + this.longitude;
    }
    static fromString(geoLocation) {
        if (!geoLocation) {
            return null;
        }
        const [latitude, longitude] = geoLocation.split(':');
        return new MapLocationProperty(Number(latitude), Number(longitude));
    }
}
