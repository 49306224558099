import React from 'react';
import { createRoot } from 'react-dom/client';
import { LicenseManager } from '@ag-grid-enterprise/core';

import './initSdk';
import './initApp';
import getBrowserSupport from './utilities/getBrowserSupport';

import App from './App';
import BrowserSupportPage from './components/pages/BrowserSupportPage/BrowserSupportPage';
import unregister from './serviceWorker';

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY);
const { supported } = getBrowserSupport();

const container = document.getElementById('root');
const root = createRoot(container);

if (root) {
    if (supported) {
        root.render(<App />);
    }
    else {
        root.render(<BrowserSupportPage />);
    }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
unregister();
