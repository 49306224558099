import { TypeNames } from 'cv-dialog-sdk';
import { utilities } from '../../utilities';

const { annotationHelper, uiHelper, styleHelper } = utilities;

/**
 * A base property factor for building graph component props
 */
export default class BasePropsFactory {
    static annotationHelper = annotationHelper;
    static styleHelper = styleHelper;
    static typeNames = TypeNames;
    static uiHelper = uiHelper;

    /**
     * Leading zeroes from color picker are ignored in Extender making them as invalid colors causing the charts to render in black.
     * For example: #06fa08 is received as #6fa08
     * This is a temporary workaround to add missing leading zeroes to the color.
     */
    static formatColor(color, size = 6) {
        if (color) {
            let colorCode = color.split('#')[1];
            while (colorCode.length < size) colorCode = `0${colorCode}`;
            return `#${colorCode}`;
        }
        return undefined;
    }

    static processAnnotations(annotations = [], notationColorProp) {
        const notation = {};
        annotations.forEach((annotation) => {
            const {
                name: propAnnoName,
                value: propAnnoValue,
            } = annotation;
            // Set the bar color
            if (propAnnoName === 'FGND_COLOR') { notation[notationColorProp] = this.formatColor(propAnnoValue); }
        });
        return notation;
    }

    static processValue(propDef, value) {
        if (propDef.isPercentType && value) {
            return value * 100;
        }
        return value;
    }
}
