import React from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import getStyles from './Carousel.styles';

const THUMBNAIL_POSITIONS = {
    bottom: 'bottom',
    left: 'left',
    top: 'top',
    right: 'right',
};

/**
 * Carousel component
 * Check out @https://github.com/xiaolin/react-image-gallery for more information on props.
 */
const Carousel = (props) => {
    const {
        contextStyles,
        imageRef,
        infinteLoop,
        items,
        lazyLoad,
        onClickItem,
        onSlide,
        renderLeftNav,
        renderRightNav,
        showBullets,
        showFullscreenButton,
        showIndex,
        showNavigation,
        showPlayButton,
        showThumbnails,
        thumbnailPosition,
    } = props;

    // Override core styles with context styles
    const styles = getStyles(contextStyles);

    const carouselProps = {
        infinite: infinteLoop,
        items,
        lazyLoad,
        onClick: (event) => {
            if (onClickItem) {
                onClickItem(event);
            }
        },
        onSlide: (currentIndex) => {
            if (onSlide) {
                onSlide(currentIndex);
            }
        },
        showBullets,
        showFullscreenButton,
        showIndex,
        showNav: showNavigation,
        showPlayButton,
        showThumbnails,
    };

    if (imageRef) {
        carouselProps.ref = imageRef;
    }

    if (renderLeftNav) {
        carouselProps.renderLeftNav = renderLeftNav;
    }

    if (renderRightNav) {
        carouselProps.renderRightNav = renderRightNav;
    }

    if (thumbnailPosition && Object.values(THUMBNAIL_POSITIONS).includes(thumbnailPosition)) {
        carouselProps.thumbnailPosition = thumbnailPosition;
    }

    return (
        <div style={ styles.container }>
            <ImageGallery { ...carouselProps } />
        </div>
    );
};

Carousel.propTypes = {
    /** Styles for this component */
    contextStyles: PropTypes.shape({
        /** Styling for the container surrounding carousel */
        container: PropTypes.object,
    }),

    /**
     * Reference for the carousel
     */
    imageRef: PropTypes.func,

    /** Defines whether infinite scrolling is enabled */
    infinteLoop: PropTypes.bool,

    /** Array of items that needs to be shown in carousel. See @https://github.com/xiaolin/react-image-gallery#props for more info */
    items: PropTypes.arrayOf(PropTypes.object),

    /** Defines  whether lazyloading is enabled */
    lazyLoad: PropTypes.bool,

    /**
     * Called when an item is clicked
     * @param {Object} event - The native click event
     */
    onClickItem: PropTypes.func,

    /**
     * Called on changing positions
     * @param {Number} currentIndex - The current selected index
     */
    onSlide: PropTypes.func,

    /**
     * Called to render the custom left navigation
     * @param {onClick} onClick - The onClick event
     * @param {disabled} disabled - Indicates whether it is disabled
     */
    renderLeftNav: PropTypes.func,

    /**
     * Called to render the custom right navigation
     * @param {onClick} onClick - The onClick event
     * @param {disabled} disabled - Indicates whether it is disabled
     */
    renderRightNav: PropTypes.func,

    /** Defines whether to show bullets on the selected image */
    showBullets: PropTypes.bool,

    /** Defines whether to show full screen button */
    showFullscreenButton: PropTypes.bool,

    /** Defines whether to show the index on the image */
    showIndex: PropTypes.bool,

    /** Defines whether to show navigation button */
    showNavigation: PropTypes.bool,

    /** Defines whether to show play button */
    showPlayButton: PropTypes.bool,

    /** Defines whether to show thumbnails */
    showThumbnails: PropTypes.bool,

    /** Defines the thumbnail position */
    thumbnailPosition: PropTypes.oneOf(Object.keys(THUMBNAIL_POSITIONS)),
};

Carousel.defaultProps = {
    contextStyles: {},
    infinteLoop: false,
    lazyLoad: true,
    showFullscreenButton: false,
    showPlayButton: false,
};

export default Carousel;
export {
    THUMBNAIL_POSITIONS,
};
