import React, { createRef, PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';

import getStyles from './Grid.styles';

class Grid extends PureComponent {
    constructor(props) {
        super(props);
        // This gives information about columns whose widths are calculated.
        this.columnWidthsCalculated = {};
        this.modules = [
            ClientSideRowModelModule,
            ClipboardModule,
            ColumnsToolPanelModule,
            MenuModule,
            RangeSelectionModule,
        ];
        this.containerRef = createRef();
    }

    render() {
        const {
            alignedGrids,
            columns,
            contextStyles,
            domLayout,
            getRowStyle,
            gridActivityIndicator,
            headerHeight,
            isEditable,
            isRangeSelectionEnabled,
            isResizable,
            isRowSelection,
            localeText,
            menuTabs,
            onRowDataUpdated,
            pinnedBottomRowData,
            pinnedTopRowData,
            rowBuffer,
            rows,
            scrollbarWidth,
            singleClickEdit,
            suppressColumnVirtualisation,
            suppressHorizontalScroll,
        } = this.props;
        const defaultColDef = {
            columnsMenuParams: {
                contractColumnSelection: true,
                suppressColumnExpandAll: true,
                suppressColumnFilter: true,
                suppressColumnSelectAll: true,
                suppressSyncLayoutWithGrid: true,
            },
            editable: isEditable,
            menuTabs,
            resizable: isResizable,
            suppressKeyboardEvent: this.handleSuppressKeyboardEvent,
            wrapText: true,
        };
        const {
            container: containerStyles,
            row: rowStyles,
            rowError: rowErrorStyles,
            rowSelected: rowSelectedStyles,
        } = getStyles(contextStyles);
        const gridProps = {
            columnDefs: columns,
            defaultColDef,
            domLayout,
            enableRangeHandle: isRangeSelectionEnabled,
            enableRangeSelection: isRangeSelectionEnabled,
            enterMovesDownAfterEdit: true,
            getRowHeight: this.getRowHeight,
            getRowNodeId: (r) => r.id,
            getRowStyle: (params) => getRowStyle(params, { rowStyles, rowErrorStyles, rowSelectedStyles }),
            gridOptions: alignedGrids,
            headerHeight,
            immutableData: true,
            localeText,
            modules: this.modules,
            onCellClicked: this.handleCellClick,
            onCellContextMenu: this.handleCellContextMenu,
            onCellDoubleClicked: this.handleCellDoubleClick,
            onCellEditingStarted: this.handleCellEditingStart,
            onCellEditingStopped: this.handleCellEditingStop,
            onCellKeyDown: this.hadleCellKeyDown,
            onColumnVisible: this.handleColumnVisible,
            onFirstDataRendered: this.handleFirstDataRendered,
            onGridReady: this.handleGridReady,
            onRowDataUpdated,
            onRowSelected: this.handleRowSelected,
            onVirtualColumnsChanged: this.handleVirtualColumnsChanged,
            pinnedBottomRowData,
            pinnedTopRowData,
            popupParent: document.querySelector('body'),
            preventDefaultOnContextMenu: true,
            rowBuffer,
            rowData: rows,
            scrollbarWidth,
            singleClickEdit,
            suppressClipboardApi: true,
            suppressClipboardPaste: true,
            suppressColumnVirtualisation,
            suppressContextMenu: true,
            suppressDragLeaveHidesColumns: true,
            suppressFieldDotNotation: true,
            suppressHorizontalScroll,
            suppressMultiRangeSelection: true,
        };
        // Below are applicable only when row selection is enabled.
        if (isRowSelection) {
            gridProps.rowSelection = 'multiple';
            gridProps.onRowClicked = this.hadleRowClick;
            gridProps.onRowDoubleClicked = this.handleRowDoubleClick;
        }

        return (
            <div
                className="ag-theme-alpine"
                onScroll={ this.handleScroll }
                ref={ this.containerRef }
                style={ containerStyles }>
                <AgGridReact { ...gridProps } />
                { gridActivityIndicator }
            </div>
        );
    }

    /** React life cycles */
    componentDidMount() {
        document.addEventListener('mouseup', this.clearRanges);
    }

    componentWillUnmount() {
        document.removeEventListener('mouseup', this.clearRanges);
    }
    /** End React life cycles */

    /** AG Grid Lifecycles */
    handleGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    handleFirstDataRendered = (params) => {
        const {
            firstVisibleColumn,
            selectedRecords,
            isRangeSelectionEnabled,
            firstVisibleRow,
            onFirstDataRendered,
        } = this.props;
        const { api } = params;
        // Apply selected records to the grid
        if (selectedRecords && !isRangeSelectionEnabled) {
            api.forEachNode((node) => {
                if (selectedRecords[node.id]) {
                    node.setSelected(true);
                }
            });
        }
        // Scroll to the last known row index
        if (firstVisibleRow) {
            api.ensureIndexVisible(firstVisibleRow, 'middle');
        }

        if (onFirstDataRendered) {
            onFirstDataRendered(this.containerRef.current);
        }
        // Scroll to the first visible column
        if (firstVisibleColumn) {
            const allDisplayedColumns = this.getAllDisplayedColumns();
            api.ensureColumnVisible(allDisplayedColumns[allDisplayedColumns.length - 1].colId);
            api.ensureColumnVisible(firstVisibleColumn);
            this.firstVisibleColumnTrigger = true;
        }
        else {
            this.handleVirtualColumnsChanged();
        }
        // temporary fix for horizontal scrolling in case of pinned columns
        const agGridLeftPinnedList = document.getElementsByClassName('ag-horizontal-left-spacer');
        if (agGridLeftPinnedList.length > 0) {
            agGridLeftPinnedList[agGridLeftPinnedList.length - 1].style.display = 'block';
            agGridLeftPinnedList[agGridLeftPinnedList.length - 1].style.border = 'none';
        }
    }

    /** ************************** End AG Grid Lifecycles ************************** */

    /** Event handlers */
    handleCellClick = (nativeEvent) => {
        const { onCellClick } = this.props;
        if (onCellClick) {
            onCellClick(nativeEvent);
        }
    }

    handleCellContextMenu = (event) => {
        const { onContextMenu } = this.props;
        if (onContextMenu) {
            // Select current row
            event.node.setSelected(true);
            onContextMenu(event);
        }
    }

    handleCellDoubleClick = (nativeEvent) => {
        const { onCellDoubleClick } = this.props;
        if (onCellDoubleClick) {
            onCellDoubleClick(nativeEvent);
        }
    }

    handleCellEditingStart = (nativeEvent) => {
        const { onCellEditingStart } = this.props;
        if (onCellEditingStart) {
            onCellEditingStart(nativeEvent);
        }
    }

    handleCellEditingStop = (nativeEvent) => {
        const { onCellEditingStop } = this.props;
        if (onCellEditingStop) {
            onCellEditingStop(nativeEvent);
        }
    }

    hadleCellKeyDown = (nativeEvent) => {
        const { onEnter } = this.props;
        const { event } = nativeEvent;
        // Handle Enter
        if (event.ctrlKey && event.key === 'Enter' && onEnter) {
            onEnter(nativeEvent);
        }
    }

    /**
     * Autosize columns when unhidden
     * @param {Object} params
     */
    handleColumnVisible = (params) => {
        const currentDisplayedColumns = this.gridColumnApi.getAllDisplayedColumns();
        if (!currentDisplayedColumns.length) {
            this.gridColumnApi.setColumnVisible(params.columns[0].colId, true);
        }
        this.handleVirtualColumnsChanged();
    }


    hadleRowClick = (event) => {
        const { onRowClick } = this.props;
        if (onRowClick) {
            const selectedRows = this.gridApi.getSelectedRows();
            onRowClick(event, selectedRows);
        }
    }

    handleRowDoubleClick = (event) => {
        const { onRowDoubleClick } = this.props;
        if (onRowDoubleClick) {
            const selectedRows = this.gridApi.getSelectedRows();
            onRowDoubleClick(event, selectedRows);
        }
    }

    handleScroll = (event) => {
        const { target } = event;
        const {
            scrollHeight,
            scrollTop,
            clientHeight,
        } = target;
        const { onRequestMore, rows } = this.props;

        // TODO: See if we have any other alternative to determine the end of  list
        if (scrollHeight > 50 && scrollTop > 50 && ((clientHeight + scrollTop) >= scrollHeight) && (this.gridApi.getLastDisplayedRow() + 1 === rows.length) && onRequestMore) {
            onRequestMore();
        }
    }

    handleSuppressKeyboardEvent = (params) => {
        const { onDelete } = this.props;
        const { editing, event } = params;
        if (!editing) {
            if (event.ctrlKey || event.metaKey) {
                const events = {
                    c: () => this.handleCopy(), // Handle COPY
                    v: () => this.handlePaste(), // Handle PASTE
                    x: () => this.handleCopy(true), // Handle CUT
                };
                if (events[event.key]) {
                    events[event.key]();
                    return true;
                }
            }

            if (onDelete) {
                const isBackspaceKey = event.keyCode === 8;
                const isDeleteKey = event.keyCode === 46;

                if (isBackspaceKey || isDeleteKey) {
                    const ranges = this.gridApi.getCellRanges();
                    onDelete(ranges);
                    return true;
                }
            }
        }
        else if (editing && event.key === 'Enter' && !event.ctrlKey) {
            return true;
        }
        else if (event.code === 'Tab' || event.key === 'Tab') {
            // By default Tab shifts focus to the next cell. We have to override this behavior in certain cases.
            // If a cell contains multiple elements, then tab should focus on those elements as well (Example DatePickers).
            // Get all focusable elements within a given cell.
            const focusableElements = event.srcElement.closest('.ag-cell').querySelectorAll('input, button');

            // Filter out all the hidden elements which are not visible on the screen.
            const visibleElements = Array.from(focusableElements).filter((ele) => ele.style.visibility !== 'hidden' && ele.tabIndex !== -1);
            // Allow ag grid to tab to the next cell only when the current focused element is the last focusable element of this cell.
            // On Shift tab, allow ag grid to tab on to the previous cell only when the current focused element is the first element in the cell.
            if (visibleElements.length &&
                ((!event.shiftKey && event.srcElement !== visibleElements[visibleElements.length - 1])
                || (event.shiftKey && event.srcElement !== visibleElements[0])))
                return true;
        }
        return false;
    }

    handleRowSelected = (nativeEvent) => {
        const { api, node } = nativeEvent;
        // Refresh selected row's cells so that we can highlight selected rows.
        api.refreshCells({ rowNodes: [ node ], force: true });
    }

    /** Autosize the newly loaded virtual columns. */
    handleVirtualColumnsChanged = () => {
        const { autoSizeColumns, firstVisibleColumn } = this.props;
        if (autoSizeColumns && this.gridColumnApi) {
            const columnsToGetWidth = this.gridColumnApi.getAllDisplayedVirtualColumns().filter((column) => {
                if (column.colDef.field && !this.columnWidthsCalculated[column.colId]) {
                    this.columnWidthsCalculated[column.colId] = true;
                    return true;
                }
                return false;
            });
            if (columnsToGetWidth.length) {
                setTimeout(() => {
                    this.gridColumnApi.autoSizeColumns(columnsToGetWidth);
                    if (this.firstVisibleColumnTrigger) {
                        this.gridApi.ensureColumnVisible(firstVisibleColumn);
                        this.firstVisibleColumnTrigger = false;
                    }
                }, 50);
            }
        }
    }

    /** ************************** End Event handlers ************************** */

    /** Clipboard handlers */
    handleCopy = (isCutAction = false) => {
        this.gridApi.copySelectedRangeToClipboard();
        const { onCopy } = this.props;

        if (onCopy) {
            const ranges = this.gridApi.getCellRanges();
            onCopy(ranges, isCutAction);
        }
    }

    handlePaste = () => {
        const { onPaste } = this.props;
        const range = this.gridApi.getCellRanges();
        if (onPaste) {
            onPaste(range);
        }
    }

    /** ************************** End Clipboard handlers ************************** */

    /** Miscellaneous */
    addCellRange = (rowStartIndex, rowEndIndex, columnStart, columnEnd) => {
        this.gridApi.addCellRange({
            rowStartIndex,
            rowEndIndex,
            columnStart,
            columnEnd,
        });
    }

    /**
     * At a time only one section can have range selection.
     * So, clear ranges from this section when we click oustide of it.
     * @param {*} event
     */
    clearRanges = (event) => {
        if (!this.containerRef.current.contains(event.target)) {
            this.clearRangeSelection();
        }
    }

    clearRangeSelection = () => {
        const ranges = this.gridApi.getCellRanges();
        if (ranges && ranges.length) {
            this.gridApi.clearRangeSelection();
        }
    }

    clearSelections = () => {
        if (this.gridApi) {
            this.gridApi.deselectAll();
        }
    }

    /**
     * Logic to Flash cells when cut/copy paste is performed
     * @param {*} rows - specifies the row id's array to highlight
     * @param {*} columns - column Id's array to highlight
     */
    flashCells = (rows, columns) => {
        if (this.gridApi) {
            const rowNodes = rows.map((row) => this.gridApi.getDisplayedRowAtIndex(row));
            this.gridApi.flashCells({
                rowNodes,
                columns,
            });
        }
    }

    getAllDisplayedColumns = () => {
        if (this.gridColumnApi) {
            return this.gridColumnApi.getAllDisplayedColumns();
        }
        return [];
    }

    /**
     * Returns the visible column to the right of the provided column.
     * @param {String} colId - ColId of the current column
     */
    getDisplayedColAfter = (colId) => {
        const currentCol = this.gridColumnApi.getColumn(colId);
        return this.gridColumnApi.getDisplayedColAfter(currentCol);
    }

    getLastFocusedCell = () => {
        if (this.gridApi) {
            return this.gridApi.getFocusedCell();
        }
        return undefined;
    }

    getRowHeight = (params) => {
        const { getRowHeight } = this.props;
        return getRowHeight(params);
    };

    /** ************************** End Miscellaneous ************************** */
};

Grid.propTypes = {
    /** Defines the Aligned linking between the Grids */
    alignedGrids: PropTypes.object,

    /** Defines if columns needs to be autosized */
    autoSizeColumns: PropTypes.bool,

    /**
     * An array containing column data
     * @see https://www.ag-grid.com/documentation/react/column-properties/ for all the available column properties
     */
    columns: PropTypes.array,

    /** Extended styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the container surrounding grid */
        container: PropTypes.object,
        /** Styles for each table row */
        row: PropTypes.object,
        /** Styles for each selected table row */
        rowSelected: PropTypes.object,
    }),

    /** defines the layout mode to use by AG Grid */
    domLayout: PropTypes.string,

    /** ColId of first visible column */
    firstVisibleColumn: PropTypes.string,

    /** The index of the row that needs to be shown on first grid load */
    firstVisibleRow: PropTypes.number,

    /** Method used to get row height  */
    getRowHeight: PropTypes.func,

    /** Method used to get row style  */
    getRowStyle: PropTypes.func,

    /** Activity Indicator for Grid */
    gridActivityIndicator: PropTypes.element,

    /** Height of the header */
    headerHeight: PropTypes.number,

    /** Indicates whether Grid is editable */
    isEditable: PropTypes.bool,

    /** Indicates whether range selection is enabled */
    isRangeSelectionEnabled: PropTypes.bool,

    /** Indicates whether row selection is enabled */
    isRowSelection: PropTypes.bool,

    /** Indicates whether Grid columns are resizable */
    isResizable: PropTypes.bool,

    /** Localisation text */
    localeText: PropTypes.object,

    /** Array of menu tabs */
    menuTabs: PropTypes.arrayOf(PropTypes.string),

    /** Called on cell click */
    onCellClick: PropTypes.func,

    /** Called on cell double click */
    onCellDoubleClick: PropTypes.func,

    /** Called when editing starts on cell */
    onCellEditingStart: PropTypes.func,

    /** Called when editing stops on cell */
    onCellEditingStop: PropTypes.func,

    /** Called on cell context menu */
    onContextMenu: PropTypes.func,

    /** Called when copy event (using cmd + c)/ cut event (using cmd + x) is performed  */
    onCopy: PropTypes.func,

    /** Called on delete/backspace key press */
    onDelete: PropTypes.func,

    /** Called on enter key press */
    onEnter: PropTypes.func,

    /** Called on first data render */
    onFirstDataRendered: PropTypes.func,

    /** Called when paste event (using cmd + v) is performed  */
    onPaste: PropTypes.func,

    /** Called to refocus on the textfield */
    onReFocus: PropTypes.func,

    /** Called to get more data into the grid */
    onRequestMore: PropTypes.func,

    /** Called on row click */
    onRowClick: PropTypes.func,

    /** Called on rows update */
    onRowDataUpdated: PropTypes.func,

    /** Called on row double click */
    onRowDoubleClick: PropTypes.func,

    /** Bottom pinned rows data */
    pinnedBottomRowData: PropTypes.array,

    /** Top pinned rows data */
    pinnedTopRowData: PropTypes.array,

    /** The number of rows rendered outside the scrollable viewable area the grid renders */
    rowBuffer: PropTypes.number,

    /** An array containing custom data */
    rows: PropTypes.array.isRequired,

    /** Indicates the width of the scrollbar */
    scrollbarWidth: PropTypes.number,

    /** Information about selected records */
    selectedRecords: PropTypes.object,

    /** If true edits will be performed on single click */
    singleClickEdit: PropTypes.bool,

    /** Spress column virtualisation */
    suppressColumnVirtualisation: PropTypes.bool,

    /** Suppress Horizontal scroll */
    suppressHorizontalScroll: PropTypes.bool,
};

Grid.defaultProps = {
    getRowHeight: () => {},
    getRowStyle: () => {},
    headerHeight: 30,
    isEditable: true,
    isResizable: true,
    isRangeSelectionEnabled: true,
    isRowSelection: false,
    menuTabs: [ 'columnsMenuTab' ],
    onRowDataUpdated: () => {},
    rowBuffer: 20,
    scrollbarWidth: 30,
    selectedRecords: {},
    singleClickEdit: false,
    suppressHorizontalScroll: true,
};

export default Grid;
