// Core component styles
const coreStyles = {
    container: {
        display: 'grid',
        gridTemplateColumns: '100%',
        gridTemplateRows: '100%',
        flexGrow: 1,
        flexDirection: 'column',
        overflow: 'hidden',

        // border: '5px solid purple',
    },
};

/**
 * Helper method to get the styles for Activity Indicator component
 * @param {Object} contextStyles - Context styles for Activity Indicator Component
 */
const getStyles = (contextStyles = {}) => {
    return {
        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },
    };
};

export default getStyles;
