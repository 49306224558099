/*       */

import FlexDirection from './layoutAttribute/general/FlexDirection';

/**
 * FlexPolicy defines the FlexBox flex policy for this view.
 */
const FLEX_DIRECTION = 'flex-direction';
const FLEX_GROW = 'flex-grow';
const FLEX_SHRINK = 'flex-shrink';
const FLEX_BASIS = 'flex-basis';
const ALIGN_ITEMS = 'align-items';
const ALIGN_SELF = 'align-self';
const JUSTIFY_CONTENT = 'justify-content';

const FLEX_START = 'flex-start';
const FLEX_END = 'flex-end';
const CENTER = 'center';
const STRETCH = 'stretch';

const ROW = 'row';

export default class FlexPolicy {
    flexDirection ;
    flexGrow ;
    flexShrink ;
    flexBasis ;
    alignItems ;
    alignSelf ;
    justifyContent ;

    constructor(
        flexDirection,
        flexGrow,
        flexShrink,
        flexBasis,
        alignItems,
        alignSelf,
        justifyContent,
    ) {
        this.flexDirection = flexDirection || FlexDirection.column;
        this.flexGrow = flexGrow;
        this.flexShrink = flexShrink;
        this.flexBasis = flexBasis;
        this.alignItems = alignItems;
        this.alignSelf = alignSelf;
        this.justifyContent = justifyContent;
    }

    static fromStyle(style) {
        const result = new FlexPolicy(style[FLEX_DIRECTION] === ROW ? FlexDirection.row : FlexDirection.column);
        if (style[FLEX_GROW] !== undefined) { result.flexGrow = style[FLEX_GROW]; }
        if (style[FLEX_SHRINK] !== undefined) { result.flexShrink = style[FLEX_SHRINK]; }
        if (style[FLEX_BASIS] !== undefined) { result.flexBasis = style[FLEX_BASIS]; }
        if (style[ALIGN_ITEMS] !== undefined) { result.alignItems = style[ALIGN_ITEMS]; }
        if (style[ALIGN_SELF] !== undefined) { result.alignSelf = style[ALIGN_SELF]; }
        if (style[JUSTIFY_CONTENT] !== undefined) { result.justifyContent = style[JUSTIFY_CONTENT]; }
        return result;
    }

    clone() {
        return new FlexPolicy(this.flexDirection, this.flexGrow, this.flexShrink, this.flexBasis, this.alignItems, this.alignSelf, this.justifyContent);
    }

    isEqual(other) {
        const keysThis = Object.keys(this);
        const keysThat = Object.keys(other);
        if (keysThis.length !== keysThat.length) {
            return false;
        }
        for (let i = 0; i < keysThis.length; i += 1) {
            if (this[keysThis[i]] !== other[keysThis[i]]) {
                return false;
            }
        }
        return true;
    }

    get isDefaultFlexDirection() {
        return this.flexDirection === undefined;
    }
    get isDefaultAlignItems() {
        return this.alignItems === undefined;
    }
    get isDefaultAlignSelf() {
        return this.alignSelf === undefined;
    }
    get isDefaultGrow() {
        return this.flexGrow === undefined;
    }
    get isDefaultJustifyContent() {
        return this.justifyContent === undefined;
    }
    get isDefaultShrink() {
        return this.flexShrink === undefined;
    }
    get isDefaultBasis() {
        return this.flexBasis === undefined;
    }

    setAlignItemsToDefault() {
        this.alignItems = undefined;
    }
    setJustifyContentToDefault() {
        this.justifyContent = undefined;
    }

    assignAlignItems(value) {
        this.alignItems = value;
    }
    assignAlignSelf(value) {
        this.alignSelf = value;
    }
    setAlignItemsCenter() {
        this.alignItems = CENTER;
    }
    setAlignItemsCenterSafe() {
        if (this.isDefaultAlignItems) {
            this.setAlignItemsCenter();
        }
    }
    setAlignItemsEnd() {
        this.alignItems = FLEX_END;
    }
    setAlignItemsStart() {
        this.alignItems = FLEX_START;
    }
    setAlignItemsStartSafe() {
        if (this.isDefaultAlignItems) {
            this.setAlignItemsStart();
        }
    }
    setAlignItemsStretch() {
        this.alignItems = STRETCH;
    }
    setAlignItemsStretchSafe() {
        if (this.isDefaultAlignItems) {
            this.setAlignItemsStretch();
        }
    }

    setAlignSelfStart() {
        this.alignSelf = FLEX_START;
    }
    setAlignSelfStretch() {
        this.alignSelf = STRETCH;
    }

    setFlexColumn() {
        this.flexDirection = FlexDirection.column;
    }
    setFlexRow() {
        this.flexDirection = FlexDirection.row;
    }

    setJustifyContentCenter() {
        this.justifyContent = CENTER;
    }
    setJustifyContentStart() {
        this.justifyContent = FLEX_START;
    }

    setStandardGrow() {
        this.flexGrow = 1;
    }
    setStandardShrink() {
        this.flexShrink = 1;
    }
    setNoShrink() {
        this.flexShrink = 0;
    }
    setStandardShrinkSafe() {
        if (this.isDefaultShrink) {
            this.setStandardShrink();
        }
    }
    setStandardBasis() {
        this.flexBasis = 1; // Standard basis is used to force equal shrinkage/growth among children
    }

    combinedFlexPolicy(other) {
        const exportFP = {};
        this.safeExport(exportFP);
        other.safeExport(exportFP);
        return FlexPolicy.fromStyle(exportFP);
    }

    // Do not overlay existing flex values and do not export default values.
    safeExport(style) {
        const result = style;
        if (!result[FLEX_DIRECTION] && !this.isDefaultFlexDirection) {
            result[FLEX_DIRECTION] = this.flexDirection && this.flexDirection.value; // flow insists
        }
        if (!result[FLEX_GROW] && !this.isDefaultGrow) {
            result[FLEX_GROW] = this.flexGrow;
        }
        if (!result[FLEX_SHRINK] && !this.isDefaultShrink) {
            result[FLEX_SHRINK] = this.flexShrink;
        }
        if (!result[FLEX_BASIS] && !this.isDefaultBasis) {
            result[FLEX_BASIS] = this.flexBasis;
        }
        if (!result[ALIGN_ITEMS] && !this.isDefaultAlignItems) {
            result[ALIGN_ITEMS] = this.alignItems;
        }
        if (!result[ALIGN_SELF] && !this.isDefaultAlignSelf) {
            result[ALIGN_SELF] = this.alignSelf;
        }
        if (!result[JUSTIFY_CONTENT] && !this.isDefaultJustifyContent) {
            result[JUSTIFY_CONTENT] = this.justifyContent;
        }
    }
}
