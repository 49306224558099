import React, { PureComponent } from 'react';
// import { rootStore, pageController } from 'cv-react-core';
// import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import recursiveRouteManager from '../../../routing/RecursiveRouteManager';
import ReactRouterBreadcrumbs from './ReactRouterBreadcrumbs';
// import routeNames from '../../../routing/routeNames';

// const { DIALOG } = routeNames;

// This is a component that hooks into our recursive router history to build and return breadcrumbs and linkage.
// It hooks into the router mechanism and tries to build the breadcrumb objects required for a valid breadcrumb.
// @observer
class DialogBreadcrumbs extends PureComponent {
    static propTypes = {
        dialogStore: PropTypes.object,
    };
    constructor(props) {
        super(props);
        const { dialogStore } = props;
        recursiveRouteManager.addNewDialogRoute(dialogStore);
    }
    render() {
        const routes = [
            recursiveRouteManager.workbenchRouteModel,
            ...recursiveRouteManager.dialogRouteModels,
        ];
        const dialogBreadcrumbsProps = {
            // onClick: this.handleOnClick,
            routes,
        };
        return React.createElement(ReactRouterBreadcrumbs, dialogBreadcrumbsProps);
    }

    // TODO: We need a consistent pattern for showing busy status for routing. This also needs to be done
    // for the browser back button and other navigation patterns outside the page. Once that is determined
    // we can come back and possible handle that pattern inside this handler.
    // handleOnClick = (event, route) => {
    //     const { uiStore } = rootStore;
    //     if (route && route.type === DIALOG) {
    //         const { id } = route;
    //         pageController.setGloballyBusy(id, uiStore);
    //     }
    // }
}
export default DialogBreadcrumbs;
