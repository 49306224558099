/* @flow */
export default {
    title: 'Search/Sort',
    instructions: 'Enter a term to be searched for and/or sorted on throughout the list data.',
    filterTitle: 'Filter criteria (optional)',
    sortTitle: 'Sort criteria (optional)',
    sortNotSelected: '(not selected)',
    clearButtonText: 'Clear',
    submitButtonText: 'Search/Sort',
    searchButtonText: 'Apply',
    clearFiltersToolTip: 'This button will clear search properties only.',
    filterValuePlaceholderText: 'Filter...',
    calculateSattisticsTitle: 'Calculate Column Statistics',
    sortCriteriaTitle: 'Add, remove or change sort direction on columns.',
    sortCriteriaAddColumnButtonText: 'Add a column',
    quickSearchDisabled: 'Quick Search has been disabled by your administrator.',
    quickSearchHelpText: '',
    sortInProgress: 'Sorting...',
    submittingUpdate: 'Submitting update...',
    sortAscending: 'Sort ascending',
    sortDescending: 'Sort descending',
    placeholderDropDown: {
        label: 'Choose column',
        value: 'Choose column',
    },
    customSort: 'Custom sort',
    customSortActive: 'Custom sort (active)',
    lockColumn: 'Lock column',
    unlockColumn: 'Unlock column',
};
