// Core component styles
const coreStyles = {
    minHeight: '250px',
};

/**
* Helper method to get the styles for map component
* @param {object} contextStyles - Context styles for the map component
*/
const getStyles = (contextStyles = {}) => {
    return {
        ...coreStyles,
        ...contextStyles,
    };
};

export default getStyles;
