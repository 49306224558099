import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { XaltBox } from 'xalt-react-atoms';

import SortComponent from './SortComponent';
import lang from '../../nls/i18n';
import getStyles from './styles/SortControl.styles';

class SortControl extends PureComponent {
    static propTypes = {
        sortTerms: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
            isAscending: PropTypes.bool.isRequired,
        })),
        sortableValues: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            label: PropTypes.string,
            isAscending: PropTypes.bool,
        })).isRequired,
        onSortTermsChange: PropTypes.func,
    }

    constructor(props) {
        super(props);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleOnSortChange = this.handleOnSortChange.bind(this);
        this.handleOnSortTermChange = this.handleOnSortTermChange.bind(this);
    }

    render() {
        const { sortTerms } = this.props;
        const {
            section,
            scrollContent,
        } = getStyles();

        const remainingSortableValues = this.formatItems(this.remainingSortableValues);

        return (
            <XaltBox style={ section }>
                <XaltBox style={ scrollContent }>
                    { sortTerms && sortTerms.length > 0 &&
                    sortTerms.map((sortTerm, index) => {
                        const { name: propertyName, label: propertyLabel } = sortTerm;

                            const sortComponentProps = {
                                ...sortTerm,
                                key: `sort_control__${propertyName}_${index}`,
                                propertyName,
                                index,
                                value: {
                                    value: propertyName,
                                    label: propertyLabel,
                                },
                                remainingSortableValues,
                                onSortTermChange: this.handleOnSortTermChange,
                                onChangeSortDirection: this.handleOnSortChange,
                                onRemove: this.handleRemove,
                            };

                            return React.createElement(SortComponent, { ...sortComponentProps });
                        })
                    }
                    { this.remainingSortableValues.length > 0 &&
                        <SortComponent
                            key="sort_control__placeholder"
                            hasOrderOptions={ false }
                            value={ { ...lang.searchSort.placeholderDropDown } }
                            isPlaceholder
                            remainingSortableValues={ remainingSortableValues }
                            onSortTermChange={ this.handlePlaceHolderSortTermChange } /> }
                    <div ref={ (el) => { this.el = el; } } />
                </XaltBox>
            </XaltBox>
        );
    };

    componentDidUpdate = (prevProps) => {
        const { sortTerms: currentSortTerms } = this.props;
        const { sortTerms: prevSortTerms } = prevProps;
        if (prevSortTerms.length < currentSortTerms.length) {
            this.scrollToBottom();
        }
    };

    componentDidMount = () => {
        this.scrollToBottom();
    };

    scrollToBottom() {
        this.el.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }

    get remainingSortableValues() {
        const { sortableValues, sortTerms } = this.props;
        return sortableValues.filter((sortValue) => sortTerms.findIndex((sortTerm) => sortTerm.name === sortValue.name) < 0);
    }

    formatItems = (items) => items.map((item) => {
        return {
            label: item.label,
            value: item.name,
        };
    });

    handleOnSortTermChange(property, index, isAscending) {
        const {
            onSortTermsChange,
            sortableValues,
            sortTerms } = this.props;
        const sortItem = sortTerms[index];

        if (!sortItem || (sortItem && sortItem.name === property.value)) {
            return; // Do nothing
        }

        const newSortItem = sortableValues.find((sort) => sort.name === property.value);
        sortItem.name = newSortItem.name;
        sortItem.label = newSortItem.label;
        sortItem.isAscending = isAscending;

        onSortTermsChange(sortTerms);
    }

    handlePlaceHolderSortTermChange = (property) => {
        const { onSortTermsChange, sortTerms } = this.props;

        const matchingRecord = this.remainingSortableValues.find((record) => property.label === record.label && property.value === record.name);

        if (matchingRecord !== undefined) {
            onSortTermsChange([
                ...sortTerms,
                matchingRecord,
            ]);
        }
    }

   handleOnSortChange(index, isAscending) {
        const { onSortTermsChange, sortTerms } = this.props;

        const terms = sortTerms.map((sortTerm, i) => {
            if (i === index) {
                return {
                    ...sortTerm,
                    isAscending: !isAscending,
                };
            }
            return sortTerm;
        });

        onSortTermsChange(terms);
    }

    handleRemove(index) {
        const { onSortTermsChange, sortTerms } = this.props;
        const terms = sortTerms.filter((_, i) => i !== index);
        onSortTermsChange(terms);
    }
}

export default SortControl;