import React from 'react';
import MuiBadge from '@material-ui/core/Badge';
import * as PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import getStyles from './Badge.styles';

/**
 * Icon component allows a user to view a non-image visual representation of an object or action.
 * @see https://material-ui.com/style/icons/
 */
const Badge = (props) => {
    const {
        children,
        contextStyles,
        overlapCircle,
        testID,
        text,
    } = props;

    // Override core styles with context styles, separating MUI styles
    const {
        ...muiStyles
    } = getStyles(contextStyles);

    // Create dynamic class names and injected DOM styles for MUI component
    // * makeStyles returns a function
    const styles = makeStyles(muiStyles)();

    // Generate badge props
    const badgeProps = {
        classes: {
            root: styles.container, // MUI styles
            badge: styles.text, // MUI styles
        },
        className: 'c-badge__badge',
        invisible: !text,
        showZero: true,
        badgeContent: text,
    };
    if (overlapCircle) { badgeProps.overlap = 'circular'; }
    if (testID) { badgeProps['data-test-id'] = `${testID}__badge__badge`; }

    return (
        <MuiBadge { ...badgeProps }>
            { children }
        </MuiBadge>
    );
};

Badge.propTypes = {
    /** The element to add a badge to */
    children: PropTypes.node,

    /** Styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the container surrounding the icon */
        container: PropTypes.object,

        /** Styles for a icon */
        text: PropTypes.object,
    }),

    /** If true, the badge will wrap a circular shape */
    overlapCircle: PropTypes.bool,

    /** Id used for testing */
    testID: PropTypes.string,

    /** Badge text */
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

Badge.defaultProps = {
    contextStyles: {},
    overlapCircle: true,
};

export default Badge;
