import { DefaultModelUtil, DialogMessageCode, } from '../models';
export class DialogService {
    constructor(client, serverUrl, apiVersion, onLogout) {
        this.client = client;
        this.apiVersion = apiVersion;
        this.onLogout = onLogout;
        this.baseUrl = `${serverUrl}/${apiVersion}`;
    }
    // @TODO
    addAttachment(tenantId, sessionId, dialogId, attachment) {
        return Promise.resolve(null);
    }
    addClientListener(clientListener, locale) {
        this.client.addClientListener(clientListener, locale);
    }
    removeClientListener(clientListener) {
        this.client.removeClientListener(clientListener);
    }
    getTenantCapabilities(tenantId, clientType) {
        return this.get(`tenants/${tenantId}/clientType/${clientType}`).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValue());
    }
    oAuthAuthorization(proofKey, oAuthUrl, clientType = 'MOBILE', webRedirectURL) {
        const postPayLoad = clientType === 'MOBILE' ? { clientState: proofKey } : { clientState: proofKey, xhaRedirectURL: webRedirectURL };
        return this.client.postJson(oAuthUrl, '', postPayLoad).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValue());
    }
    oAuthWorkBenchRedirection(tenantId, sessionId, oAuthUrl) {
        return this.post(`tenants/${tenantId}/sessions/${sessionId}/callbackRequest/${oAuthUrl}`).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValueOrRedirect());
    }
    createSession(tenantId, login) {
        return this.post(`tenants/${tenantId}/sessions`, login).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValueOrRedirect());
    }
    changePassword(tenantId, sessionId, passwordInfo) {
        return this.post(`tenants/${tenantId}/sessions/${sessionId}/changePassword`, passwordInfo).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValue());
    }
    getSession(tenantId, sessionId) {
        return this.get(`tenants/${tenantId}/sessions/${sessionId}`).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValue());
    }
    deleteSession(tenantId, sessionId) {
        return this.d3lete(`tenants/${tenantId}/sessions/${sessionId}`).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValue());
    }
    getContent(tenantId, sessionId, contentId, readLargePropertyParams) {
        return this.post(`tenants/${tenantId}/sessions/${sessionId}/content/${contentId}`, readLargePropertyParams).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValue());
    }
    getWorkbenches(tenantId, sessionId) {
        return this.get(`tenants/${tenantId}/sessions/${sessionId}/workbenches`).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValue());
    }
    getWorkbench(tenantId, sessionId, workbenchId) {
        return this.get(`tenants/{$tenantId}/sessions/{$sessionId}/workbenches/{$workbenchId}`).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValue());
    }
    getRedirection(tenantId, sessionId, redirectionId) {
        return this.get(`tenants/${tenantId}/sessions/${sessionId}/redirections/${redirectionId}`).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValue());
    }
    getDialog(tenantId, sessionId, dialogId) {
        return this.get(`tenants/${tenantId}/sessions/${sessionId}/dialogs/${dialogId}`).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValue());
    }
    deleteDialog(tenantId, sessionId, dialogId) {
        return this.d3lete(`tenants/${tenantId}/sessions/${sessionId}/dialogs/${dialogId}`).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValue());
    }
    getActions(tenantId, sessionId, dialogId) {
        return this.get(`tenants/${tenantId}/sessions/${sessionId}/dialogs/${dialogId}/actions`).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValue());
    }
    performAction(tenantId, sessionId, dialogId, actionId, actionParameters) {
        const encodedActionId = encodeURIComponent(actionId);
        return this.post(`tenants/${tenantId}/sessions/${sessionId}/dialogs/${dialogId}/actions/${encodedActionId}`, actionParameters).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValue());
    }
    performCalculateStatisticsAction(tenantId, sessionId, dialogId, actionId) {
        const encodedActionId = encodeURIComponent(actionId);
        return this.get(`tenants/${tenantId}/sessions/${sessionId}/dialogs/${dialogId}/${encodedActionId}`).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValue());
    }
    performDeepLink(tenantId, sessionId, deeplinkingId) {
        return this.get(`tenants/${tenantId}/sessions/${sessionId}/designLink/${deeplinkingId}`).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValue());
    }
    getWorkbenchActions(tenantId, sessionId, workbenchId) {
        return this.get(`tenants/${tenantId}/sessions/${sessionId}/workbenches/${workbenchId}/actions`).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValue());
    }
    performWorkbenchAction(tenantId, sessionId, workbenchId, actionId) {
        return this.post(`tenants/${tenantId}/sessions/${sessionId}/workbenches/${workbenchId}/actions/${actionId}`, {}).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValue());
    }
    getRecord(tenantId, sessionId, dialogId) {
        return this.get(`tenants/${tenantId}/sessions/${sessionId}/dialogs/${dialogId}/record`).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValue());
    }
    putRecord(tenantId, sessionId, dialogId, record) {
        return this.put(`tenants/${tenantId}/sessions/${sessionId}/dialogs/${dialogId}/record`, record).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValueOrRedirect());
    }
    putRecords(tenantId, sessionId, dialogId, queryActionParameters) {
        return this.put(`tenants/${tenantId}/sessions/${sessionId}/dialogs/${dialogId}/records`, queryActionParameters).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValueOrRedirect());
    }
    getRecords(tenantId, sessionId, dialogId, queryParams) {
        return this.post(`tenants/${tenantId}/sessions/${sessionId}/dialogs/${dialogId}/records`, queryParams).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValue());
    }
    getSampleData(tenantId, sessionId, dialogId, queryParams, formatResponse) {
        return this.post(`tenants/${tenantId}/sessions/${sessionId}/sampleData/${dialogId}`, queryParams).then(jsonClientResponse => {
            return new DialogServiceResponse(formatResponse(jsonClientResponse.value), this.onLogout).responseValue();
        });
    }
    getEditorProperty(tenantId, sessionId, dialogId, propertyName, readLargePropertyParams) {
        return this.post(`tenants/${tenantId}/sessions/${sessionId}/dialogs/${dialogId}/record/${propertyName}`, readLargePropertyParams).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValue());
    }
    getQueryProperty(tenantId, sessionId, dialogId, propertyName, readLargePropertyParams) {
        return this.post(`tenants/${tenantId}/sessions/${sessionId}/dialogs/${dialogId}/records/${propertyName}`, readLargePropertyParams).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValue());
    }
    writeProperty(tenantId, sessionId, dialogId, propertyName, writeLargePropertyParams) {
        return this.put(`tenants/${tenantId}/sessions/${sessionId}/dialogs/${dialogId}/record/${propertyName}`, writeLargePropertyParams).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValue());
    }
    propertyChange(tenantId, sessionId, dialogId, propertyName, sideEffectsParams) {
        return this.post(`tenants/${tenantId}/sessions/${sessionId}/dialogs/${dialogId}/record/${propertyName}/sideEffects`, sideEffectsParams).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValue());
    }
    getAvailableValues(tenantId, sessionId, dialogId, propertyName, availableValuesParams) {
        return this.post(`tenants/${tenantId}/sessions/${sessionId}/dialogs/${dialogId}/record/${propertyName}/availableValues`, availableValuesParams).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValue());
    }
    openContentStream(tenantId, sessionId, contentId) {
        return this.get(`tenants/${tenantId}/sessions/${sessionId}/content/${contentId}`).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValue());
    }
    getMode(tenantId, sessionId, dialogId) {
        return this.get(`tenants/${tenantId}/sessions/${sessionId}/dialogs/${dialogId}/viewMode`).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValue());
    }
    changeMode(tenantId, sessionId, dialogId, mode) {
        return this.put(`tenants/${tenantId}/sessions/${sessionId}/dialogs/${dialogId}/viewMode/${mode}`).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValueOrRedirect());
    }
    getView(tenantId, sessionId, dialogId) {
        return this.get(`tenants/${tenantId}/sessions/${sessionId}/dialogs/${dialogId}/view`).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValue());
    }
    changeView(tenantId, sessionId, dialogId, viewId) {
        return this.put(`tenants/${tenantId}/sessions/${sessionId}/dialogs/${dialogId}/selectedView/${viewId}`, {}).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValue());
    }
    getViews(tenantId, sessionId, dialogId) {
        return this.get(`tenants/${tenantId}/sessions/${sessionId}/dialogs/${dialogId}/availableViews`).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValue());
    }
    streamUrl(tentantId, sessionId, url) {
        return this.stream(url);
    }
    getSaltDocumentNames(tenantId, sessionId, viewPersistentId, saltParameters) {
        return this.post(`tenants/${tenantId}/sessions/${sessionId}/documents/${viewPersistentId}/getSaltNames`, saltParameters).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValue());
    }
    getSaltImageNames(tenantId, sessionId, saltParameters) {
        return this.post(`tenants/${tenantId}/sessions/${sessionId}/getSaltImageNames`, saltParameters).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValue());
    }
    createSalt(tenantId, sessionId, viewPersistentId, saltParameters) {
        return this.post(`tenants/${tenantId}/sessions/${sessionId}/documents/${viewPersistentId}/createSalt`, saltParameters).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValue());
    }
    deleteSalt(tenantId, sessionId, viewPersistentId, saltParameters) {
        return this.post(`tenants/${tenantId}/sessions/${sessionId}/documents/${viewPersistentId}/deleteSalt`, saltParameters).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValue());
    }
    getSalt(tenantId, sessionId, viewPersistentId, saltParameters) {
        return this.post(`tenants/${tenantId}/sessions/${sessionId}/documents/${viewPersistentId}/getSalt`, saltParameters).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValue());
    }
    updateSalt(tenantId, sessionId, viewPersistentId, saltParameters) {
        return this.put(`tenants/${tenantId}/sessions/${sessionId}/documents/${viewPersistentId}/updateSalt`, saltParameters).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValue());
    }
    getSessionCount(tenantId, sessionId) {
        return this.get(`tenants/${tenantId}/sessions/${sessionId}/sessionCount`).then(jsonClientResponse => new DialogServiceResponse(jsonClientResponse, this.onLogout).responseValue());
    }
    isAnyUserInBriefcaseMode(tenantId) {
        return this.client.isAnyUserInBriefcaseMode(tenantId);
    }
    isUserInBriefcaseMode(userInfo) {
        return this.client.isUserInBriefcaseMode(userInfo);
    }
    get lastServiceActivity() {
        return this.client.lastActivity;
    }
    /* Private methods */
    get(path, queryParams) {
        return this.client.getJson(`${this.baseUrl}`, path, queryParams);
    }
    post(path, body) {
        return this.client.postJson(`${this.baseUrl}`, path, body);
    }
    d3lete(path) {
        return this.client.deleteJson(`${this.baseUrl}`, path);
    }
    put(path, body) {
        return this.client.putJson(`${this.baseUrl}`, path, body);
    }
    stream(url) {
        return this.client.openStream(url);
    }
}
class DialogServiceResponse {
    constructor(clientResponse, onLogout) {
        this.clientResponse = clientResponse;
        this.onLogout = onLogout;
    }
    responseValue() {
        return new Promise((resolve, reject) => {
            if (this.hasError) {
                this.handleError(resolve, reject);
            }
            else {
                this.fullfillJsonToModel(this.clientResponse, resolve, reject);
            }
        });
    }
    responseValueOrRedirect() {
        return new Promise((resolve, reject) => {
            if (this.hasError) {
                this.handleError(resolve, reject);
            }
            else if (this.hasValue) {
                this.fullfillJsonToModel(this.clientResponse, resolve, reject);
            }
            else {
                this.fullfillJsonToModel(this.clientResponse, resolve, reject);
            }
        });
    }
    assertNoError() {
        return new Promise((resolve, reject) => {
            if (this.hasError) {
                this.handleError(resolve, reject);
            }
            else {
                resolve(undefined);
            }
        });
    }
    get hasValue() {
        return this.clientResponse.statusCode >= 200 && this.clientResponse.statusCode < 300;
    }
    get hasRedirection() {
        return this.clientResponse.statusCode >= 300 && this.clientResponse.statusCode < 400;
    }
    get hasError() {
        return this.clientResponse.statusCode >= 400;
    }
    handleError(resolve, reject) {
        const dialogMessage = this.clientResponse.value;
        if (!this.validateSessionExists(dialogMessage)) {
            this.onLogout && this.onLogout();
        }
        reject(dialogMessage);
    }
    validateSessionExists(dialogMessage) {
        return dialogMessage.code !== DialogMessageCode.NO_SESSION;
    }
    fullfillJsonToModel(clientResponse, resolve, reject) {
        const start = Date.now();
        // Log.trace(`perf::jsonToModel starting at ${start}`);
        try {
            const result = DefaultModelUtil.jsonToModel(this.clientResponse.value);
            // Log.trace(`perf::jsonToModel finished in ${Date.now() - start} ms ${result.id}`);
            resolve(result);
        }
        catch (e) {
            reject(e);
        }
    }
}
