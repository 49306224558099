import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { rootStore, constants } from 'cv-react-core';

import routeNames from './routeNames';
import NavRoute from './NavRoute';
import AuthRoute from './AuthRoute';

const {
    LOGIN,
    SETTINGS,
    WORKBENCH,
} = routeNames;

const renderNotLoggedIn = (tenantId, route) => (
    <Router>
        <Switch>
            <Route
                component={ AuthRoute }
                path="/:tenantId/:route" />
            <Route path="/">
                <Redirect to={ { pathname: `/${tenantId || '#'}/${route}` } } />
            </Route>
        </Switch>
    </Router>
);

const renderLoggedIn = () => {
    const { sessionStore: { workbenches, initialAction, session: { tenantId } } } = rootStore;
    let defaultWorkbench = workbenches[0];
    let workBenchId;

    // We are setting SelectedWorkbench store only during Workbench OAuth flow manually ( only if it satisfies both checks )
    const params = new URLSearchParams(window.location.search);
    const oAuthToken = params.get(constants.oauth.OAUTH_CALLBACK_TOKEN);
    if (oAuthToken) {
        const { sessionStore, uiStore } = rootStore;
        workBenchId = uiStore.getValueForUIObject(constants.ui.WORKBENCH_UI_OBJECT_ID, constants.ui.WORKBENCH_ACTIVE_ID);
        sessionStore.setOAuthToken(oAuthToken);
        if (workBenchId) {
            sessionStore.setSelectedWorkbenchById(workBenchId);
        }
    }

    workBenchId = workBenchId || initialAction?.workbenchId;
    if (workBenchId) {
        defaultWorkbench = workbenches.find((workbench) => workbench.id === workBenchId) || defaultWorkbench;
    }

    return (
        <Router>
            <Switch>
                <Route
                    path="/:tenantId/:route/:workbenchId/:dialogId?"
                    component={ NavRoute } />
                <Route path="/">
                    <Redirect to={ { pathname: `/${tenantId}/${WORKBENCH}/${defaultWorkbench.id}` } } />
                </Route>
            </Switch>
        </Router>
    );
};

const renderHome = () => {
    const {
        sessionStore,
        settingsStore,
    } = rootStore;

    const { session } = sessionStore;
    const { workbenches } = sessionStore;
    const hasWorkbenches = !!(workbenches && workbenches.length > 0);
    const tenantId = settingsStore && settingsStore.getValue(constants.settings.TENANT_ID) || '';
    if (session && sessionStore.isLoggedIn() && hasWorkbenches) {
        return renderLoggedIn();
    }

    // Always route the user to the settings page if we do not have a tenant
    const route = !tenantId || tenantId.length === 0 ? SETTINGS : LOGIN;
    return renderNotLoggedIn(tenantId, route);
};


const route = () => (
    <Router>
        <Switch>
            <Route
                path="/">
                { renderHome() }
            </Route>
        </Switch>
    </Router>
);

export default route;
