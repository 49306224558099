import recursiveRouteManager from './RecursiveRouteManager';

export default class Navigator {
    constructor(props) {
        const {
            history,
            location,
            match,
        } = props;
        this.history = history;
        this.location = location;
        this.match = match;
        recursiveRouteManager.addRoute(match, location);
    }
}