import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { rootStore } from 'cv-react-core';
import Navigator from './Navigator';
import routeNames from './routeNames';
import SettingsPage from '../components/pages/SettingsPage/SettingsPage';

@observer
class SettingsRoute extends Component {
    static propTypes = {
        navigator: PropTypes.instanceOf(Navigator),
    };

    render() {
        const {
                sessionStore,
                settingsStore,
                themeStore,
                uiStore,
                } = rootStore;
        const params = {
            getSettings: settingsStore.getSanitizedSettings,
            setSettings: settingsStore.fromSanitizedSettings,
            onLogin: this.handleOnLoginPress,
            uiStore,
            sessionStore,
            settingsStore,
            themeStore,
        };

        return (
            <SettingsPage { ...params } />
        );
    }

    handleOnLoginPress = () => {
        const { navigator } = this.props;
        navigator.history.replace(`/${routeNames.LOGIN}`);
    }
}

export default SettingsRoute;
