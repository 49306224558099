import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Paper from '@material-ui/core/Paper';

import styleParser from '../utilities/styleHelpers/styleParser';
import XaltBox from '../XaltBox/XaltBox';
import XaltInputBase from '../XaltInputBase/XaltInputBase';
import XaltTextLabel from '../XaltTextLabel/XaltTextLabel';
import XaltCheckBox from '../XaltCheckBox/XaltCheckBox';
import XaltDropDownList from './XaltDropDownList';
import { XaltClear, XaltExpandMore } from '../XaltIconButton/XaltIconButtonTypes';
import XaltToolTip from '../XaltToolTip/XaltToolTip';

// import XaltVirtualizedList from '../XaltVirtualizedList/XaltVirtualizedList';

import { withAdditionalStyles } from '../hoc/withAdditionalStyles';

const propDefinition = {
    autoFocus: PropTypes.bool,

    /** Styles for this component */
    style: PropTypes.object,

    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
        }),
    ),

    /** Styling applied on focus */
    focusStyle: PropTypes.object,

    adornmentStyle: PropTypes.object,

    /** provided by the HOC */
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,

    /** Prevents the text field from accepting input */
    disabled: PropTypes.bool,

    disableClearable: PropTypes.bool,

    expandIconStyles: PropTypes.object,

    onOpen: PropTypes.func,

    /**
     * Called when the TextField's input changes
     * @param {Object} event - The native change event
     * @param {Object} props - The component instance props
     */
     onValueChanged: PropTypes.func,

    /** The text to populate in the input */
    value: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
        })),
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
        }),
    ]),

    tipText: PropTypes.string,

    loading: PropTypes.bool,

    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),
};

/**
 * ******************************** SPECIAL NOTE WHEN CREATING NEW COMPONENTS **************************
 * @todo This componet should deliver base functionality and not reference any other imported component.
 *      We will need to extract this out and create an atom componet for reuse in other apps. Do not
 *      use this as an example of how to create molecule components.
 * ******************************** SPECIAL NOTE WHEN CREATING NEW COMPONENTS **************************
 *
 * A platform component for building a Drop Down
 */
class XaltDropDownMultiSelect extends Component {
    static propTypes = propDefinition;

    static defaultProps = {
        style: {},
        focusStyle: {},
        value: [],
        items: [],
        disabled: false,
        expandIconStyles: {},
        disableClearable: true,
        loading: false,
        onValueChanged: () => {},
        onOpen: () => {},
    };

    render() {
        const {
            style,
            items,
            disabled,
            value,
            expandIconStyles,
            disableClearable } = this.props;

        const autoCompleteStyles = styleParser.parseContainerStyles(style);

        return (
            <Autocomplete
                debug
                multiple
                limitTags={ 1 }
                autoHighlight
                value={ value }
                disableClearable={ disableClearable }
                disableCloseOnSelect
                style={ { minWidth: autoCompleteStyles.width, maxWidth: autoCompleteStyles.width, flexGrow: autoCompleteStyles.flexGrow } }
                className="x-drop-down__menu"
                disabled={ disabled }
                options={ items }
                PaperComponent={ this.renderPaperComponent }
                getOptionLabel={ this.handleGetOptionLabel } // (option) => option ? option.label : '' }
                getOptionSelected={ this.handleGetOptionSelected }
                onChange={ this.handleOnChange }
                renderOption={ this.handleOnRenderOption }
                getLimitTagsText={ this.getLimitTagsText }
                renderInput={ this.handleOnRenderInput }
                renderTags={ this.handleRenderTags }
                ListboxComponent={ XaltDropDownList }
                popupIcon={ <XaltExpandMore style={ expandIconStyles } /> }
                ListboxProps={ style } />
                // ListboxComponent={ XaltVirtualizedList }
                // ListboxProps={{ style, value, items }} />
        );
    };

    renderPaperComponent = (params) => (
        <Paper
            { ...params }
            style={ { minWidth: '250px', margin: '0px' } }
            elevation={ 10 } />
        )

    renderEndAdornment = (endAdornment) => {
        const { adornmentStyle } = this.props;
        const styles = {
            display: 'flex',
            flexBasis: '20%',
            ...adornmentStyle,
            paddingRight: '5px',
        };
        return (
            <div style={ styles }>
                { endAdornment }
            </div>
        );
    }

    handleGetOptionLabel = (option) => {
        // This is in place to handle freesolo entry. All other scenario's present an object.
        // You can catch the input value change, but it's not optimal
        // as the value gets destorted between object and value. This
        // was a simpe routine that essentially is overriden by the change handler.
        if (option && typeof option === 'string') {
            return option;
        }
        return (option && option.label) ? option.label : '';
    }

    handleGetOptionSelected = (option, valueTest) => option.value === valueTest.value;

    handleOnRenderInput = (params) => {
        const {
            autoFocus,
            style,
            tipText,
            inputRef,
            focusStyle,
            onFocus,
            onBlur,
        } = this.props;
        const { InputProps, ...restParams } = params;
        const {
            ref,
            endAdornment,
            startAdornment,
        } = InputProps;

        return (
            <XaltInputBase
                { ...restParams }
                anchorRef={ ref }
                autoFocus={ autoFocus }
                inputRef={ inputRef }
                endAdornment={ this.renderEndAdornment(endAdornment) }
                startAdornment={ startAdornment }
                disableUnderline
                tipText={ tipText }
                style={ { ...style, flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'center', ...focusStyle } }
                onFocus={ onFocus }
                onBlur={ onBlur }
                fullWidth />
        );
    }

    handleOnRenderOption = (item, { selected }) => {
        const { style } = this.props;
        const textStyles = styleParser.parseTextStyles(style);
        const selectedStyles = selected ? { backgroundColor: 'rgba(28, 142, 169, 0.2)' } : {};
        return (
            <XaltBox
                style={ {
                    display: 'flex',
                    flexDirection: 'row',
                    borderWidth: 0,
                    paddingTop: style.padding || style.paddingBottom,
                    ...style,
                    ...selectedStyles,
                } }
                hoverStyle={ { backgroundColor: '#F5F5F5' } }>
                <XaltCheckBox
                    style={ {
                        width: 18,
                        height: 18,
                        paddingLeft: '4px',
                        paddingRight: '15px',
                        paddingTop: '9px',
                        paddingBottom: '9px',
                        color: '#121212',
                    } }
                    checked={ selected } />
                <XaltTextLabel
                    style={ {
                        cursor: 'pointer',
                        paddingRight: '4px',
                        ...textStyles,
                    } }>
                    { item.label }
                </XaltTextLabel>
            </XaltBox>
        );
    }

    getLimitTagsText = () => {
        const { style, value } = this.props;
        const textStyles = styleParser.parseTextStyles(style);
        const title = value.slice(1).map((v) => v.label)
                        .join('\n');
        // Note: Styles are hardcoded here for now. Some of these styles might driven from themes in future.
        return (
            <XaltToolTip
                tipText={
                    <XaltBox style={ { whiteSpace: 'pre-line' } }>
                        { title }
                    </XaltBox>
                }>
                <XaltBox
                    style={ {
                        ...textStyles,
                        backgroundColor: '#F5F5F5',
                        borderRadius: 4,
                        paddingTop: 2,
                        paddingRight: 8,
                        paddingBottom: 2,
                        paddingLeft: 8,
                        cursor: 'default',
                        marginLeft: '-30px',
                    } }>
                    ...
                </XaltBox>
            </XaltToolTip>
        );
    }

    handleRenderTags = (value) => {
        const { onValueChanged } = this.props;
        const { style } = this.props;
        const textStyles = styleParser.parseTextStyles(style);
        // Note: Some styles are hardcoded here for now. Some of these styles might driven from themes in future.
        return value.map((option) => (
            <XaltBox
                key={ option.value }
                style={ {
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#F5F5F5',
                    borderRadius: 4,
                    paddingTop: 2,
                    paddingRight: 2,
                    paddingBottom: 2,
                    paddingLeft: 2,
                    marginRight: '30px',
                    marginBottom: '4px',
                    flexShrink: 1,
                } }>
                <XaltTextLabel
                    style={ textStyles }>
                    { option.label }
                </XaltTextLabel>
                <XaltClear
                    onClick={ () => onValueChanged(value.filter((v) => v.value !== option.value)) }
                    style={ {
                        ...textStyles,
                        marginLeft: '7px',
                        cursor: 'pointer',
                    } } />
            </XaltBox>
            ));
    }

    handleOnChange = (_, newValues) => {
        const { onValueChanged } = this.props;
        onValueChanged(newValues);
    };
}

export default withAdditionalStyles(XaltDropDownMultiSelect);