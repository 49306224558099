/* eslint-disable no-plusplus */
import { SaltDocument } from '../SaltDocument';

const formTemplate = require('./form_simple');
const maintainableFormTemplate = require('./form_maintainable');

class FormTranspiler {
    generateSalt(dialog) {
    // generateSalt(dialog) {
        // @TODO alias, viewAlias - we may want to allow salt to also reference user defined aliases
        const { view } = dialog;
        const { isMaintainableQuery } = view;
        const viewId = SaltDocument.getViewId(view);
        const document = new SaltDocument(isMaintainableQuery ? maintainableFormTemplate : formTemplate);
        const viewEl = document.getByExpr('**.view');
        document.singleSelectNode(viewEl);
        document.setAttribute('id', viewId);
        if (isMaintainableQuery) {
            this.setPanelViewId(document, dialog, viewId);
        }
        return document.rootNode;
    }

    setPanelViewId(document, dialog, viewId) {
        // Always show toolbar and heading from DATA section.
        const dataDialog = dialog.children.find((childDialog) => childDialog.view.isDataStyle);
        const panelEL = document.getByExpr('**.panel');
        document.singleSelectNode(panelEL);
        if (dataDialog) {
            document.setAttribute('view-id', SaltDocument.getViewId(dataDialog.view));
        }
        const layoutEL = document.getByExpr('**.layout');
        document.singleSelectNode(layoutEL);
        document.setAttribute('view-id', viewId);
    }
}


const formTranspiler = new FormTranspiler();
export default formTranspiler;
