import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { rootStore, constants } from 'cv-react-core';
import { HolyGrailLayout } from 'cv-library-react-web';
import AppHeader from '../../base/AppHeader/AppHeader';

import { getRouteComponent } from '../../../routing/routeComponents';
import getStyles from './AuthNavigation.styles';
import Navigator from '../../../routing/Navigator';
import LoadingIndicator from '../../modal/LoadingIndicator';

/* eslint-disable react/prefer-stateless-function */

class AuthNavigation extends Component {
    static propTypes = {
        targets: PropTypes.object,
        defaultRouteName: PropTypes.string,
        navigator: PropTypes.instanceOf(Navigator),
        /** Id used for testing */
        testID: PropTypes.string,
    };

    render() {
        const {
            testID,
            defaultRouteName,
            navigator } = this.props;

        const {
            sessionStore,
            settingsStore,
            uiStore,
            themeStore } = rootStore;

        const styles = getStyles();

        // pull the route from the URL
        const currentRouteName = navigator.match.params.route || defaultRouteName;
        const CurrentRoute = getRouteComponent(currentRouteName);

        // Construct layout props
        const layoutProps = {
            contextStyles: {
                container: styles.container,
                header: styles.header,
                content: styles.content,
                footer: styles.footer,
            },
        };
        if (testID) { layoutProps['data-test-id'] = `${testID}__layout`; }

        // Render authentication layout
        return (
            <>
                <LoadingIndicator
                    objectId={ constants.ui.LOGIN_IN_PROGRESS }
                    uiStore={ uiStore } />
                <HolyGrailLayout
                    { ...layoutProps }
                    header={ <AppHeader contextStyles={ { container: styles.ribbon } } /> }
                    content={ <CurrentRoute
                        sessionStore={ sessionStore }
                        settingsStore={ settingsStore }
                        uiStore={ uiStore }
                        themeStore={ themeStore }
                        navigator={ navigator } /> } />
            </>
        );
    }
}

export default AuthNavigation;
