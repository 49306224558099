import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import locale from 'date-fns/locale/en-US';
import styleParser from '../utilities/styleHelpers/styleParser';
import { date } from '../constants/dateConstants';

const { XALT_DATE_VIEWS, XALT_PICKER_VARIANTS } = date;

const propDefinition = {
    /** Auto Focuses on the input */
    autoFocus: PropTypes.bool,

    /** Set date and close picker on selection of last date part */
    autoOk: PropTypes.bool,

    /** Styles for this component */
    style: PropTypes.object,

    /**
     * Called for each day on the calendar view
     * @param {Date} the calendar day
     * @returns {Boolean} disables the calendar day
     */
    shouldDisableDate: PropTypes.func,

    /** Prevents the control from changing input */
    disabled: PropTypes.bool,

    /** Hide helper text when error */
    hideHelperText: PropTypes.bool,

    /** Hide picker Date and Time toolbar */
    hideToolbar: PropTypes.bool,

    /** Hide Date and Time picker selection tabs */
    hideTabs: PropTypes.bool,

    /** Reference to the input element */
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),

    /** Display the date picker in a 24 hour format */
    is24Hour: PropTypes.bool,

    /**
     * Called when the user or picker changes the input value
     * @param {Date} date - The selected date
     * @param {String} text - The input text
     * @param {Object} props - The component instance props
     * @returns {void}
     */
    onValueChanged: PropTypes.func,

    /** Selected date value */
    value: PropTypes.instanceOf(Date),

    /** Id used for testing */
    testID: PropTypes.string,

    /** tipText string */
    tipText: PropTypes.string,

    /** Changes how the date/time modal is displayed */
    variant: PropTypes.oneOf([
        /** Uses a viewport centered modal with a darkened background */
        'dialog',

        /** Uses an input anchored modal */
        'inline',

        /** Renders inline rather than in a modal */
        'static',
    ]),

    /** Visible picker views */
    views: PropTypes.arrayOf(PropTypes.string),

    /** Maximum date value */
    maxDate: PropTypes.instanceOf(Date),

    firstDayOfWeek: PropTypes.number,
};

/**
 * DateTimePicker component for picking date and time from user
 * @see https://material-ui.com/demos/pickers/#material-ui-pickers
 * @see https://material-ui-pickers.dev/getting-started/usage
 */
export default class XaltDateTimeField extends PureComponent {
    static propTypes = propDefinition;

    static defaultProps = {
        autoFocus: false,
        autoOk: true,
        hideTabs: true,
        value: null,
        testID: 'DateTimePicker',
        variant: XALT_PICKER_VARIANTS.INLINE,
        views: [
            XALT_DATE_VIEWS.DATE,
            XALT_DATE_VIEWS.HOURS,
            XALT_DATE_VIEWS.MINUTES,
        ],
        maxDate: new Date('12/31/9999'),
        onValueChanged: () => {},
    };

    render() {
        const {
            autoFocus,
            autoOk,
            disabled,
            hideHelperText,
            shouldDisableDate,
            hideToolbar,
            hideTabs,
            inputRef,
            is24Hour,
            onValueChanged,
            style,
            testID,
            tipText,
            variant,
            views,
            value,
            maxDate,
            firstDayOfWeek,
        } = this.props;

        locale.options.weekStartsOn = firstDayOfWeek;

        // extract styles
        const containerStyles = styleParser.parseContainerStyles(style);
        const textStyles = styleParser.parseTextStyles(style);

        // Generate picker props
        const pickerProps = {
            ampm: !is24Hour,
            autoFocus,
            autoOk,
            className: 'c-date-time-picker__picker',
            disableToolbar: hideToolbar,
            hideTabs,
            inputProps: {
                style: { visibility: 'hidden', ...textStyles },
            },
            KeyboardButtonProps: {
                style: { visibility: 'hidden' },
                ref: inputRef,
            },
            onChange: onValueChanged,
            title: tipText,
            value,
            inputValue: '',
            invalidDateMessage: '',
            variant,
            views,
            maxDate,
        };

        // Dialog only props
        if (variant === XALT_PICKER_VARIANTS.DIALOG) {
            pickerProps.clearable = true;
            pickerProps.okLabel = 'Set';
        }
        if (hideHelperText) { pickerProps.helperText = null; }
        if (shouldDisableDate) { pickerProps.shouldDisableDate = shouldDisableDate; }
        if (disabled) { pickerProps.disabled = disabled; }
        if (testID) { pickerProps['data-test-id'] = `${testID}__date-picker__picker`; }

        return (
            <div style={ containerStyles }>
                <MuiPickersUtilsProvider
                    utils={ DateFnsUtils }
                    locale={ locale }>
                    <KeyboardDateTimePicker { ...pickerProps } />
                </MuiPickersUtilsProvider>
            </div>
        );
    }
};
