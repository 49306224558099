import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import MUICheckbox from '@material-ui/core/Checkbox';

import { withAdditionalStyles } from '../hoc/withAdditionalStyles';

const propDefinition = {
    /** Focus on the checkbox */
    autoFocus: PropTypes.bool,

    /** The checked value */
    checked: PropTypes.bool,

    /** Extended styles for this component */
    style: PropTypes.object,

    /** Disables the control */
    disabled: PropTypes.bool,

    /**
     * Called when the checkbox was changed
     * @param {Object} event - The native change event
     * @param {Object} props - The component instance props
     */
    onValueChanged: PropTypes.func,

    /** Reference to the input element */
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),

    /** Styling applied on hover */
    hoverStyle: PropTypes.object,

    /** Styling applied on focus */
    focusStyle: PropTypes.object,

    /** provided by the HOC */
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,

    defaultThemeColor: PropTypes.string,
};

/**
 * A control that allows a user to select one or more items from a set.
 * @see https://material-ui.com/components/checkboxes/
 */
class XaltCheckbox extends Component {
    static propTypes = propDefinition;

    static defaultProps = {
        style: {},
        onValueChanged: () => {},
        hoverStyle: {
            color: '#1C8EA9',
        },
        focusStyle: {
            color: '#1C8EA9',
        },
        defaultThemeColor: 'default',
    };

    render() {
        const {
            autoFocus,
            checked,
            disabled,
            inputRef,
            style,
            hoverStyle,
            focusStyle,
            defaultThemeColor,
            onFocus,
            onBlur,
            onMouseOver,
            onMouseOut,
        } = this.props;
        const inputEventProps = {
            onFocus,
            onBlur,
            onMouseOver,
            onMouseOut,
        };

        return (
            <MUICheckbox
                autoFocus={ autoFocus }
                className="xalt-checkbox__container"
                color={ defaultThemeColor }
                style={ { ...style, ...hoverStyle, ...focusStyle, backgroundColor: 'transparent' } }
                checked={ checked }
                inputRef={ inputRef }
                disabled={ disabled }
                onChange={ this.handleChange }
                disableRipple
                inputProps={ inputEventProps } />
        );
    }

    handleChange = (event) => {
        const { onValueChanged } = this.props;
        const { checked } = event.target;
        onValueChanged(checked);
    }
};

export default withAdditionalStyles(XaltCheckbox);
