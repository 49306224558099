import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import { PhotoshopPicker } from 'react-color';
import Popover from '@material-ui/core/Popover';

const propDefinition = {
    /** The color to populate in the color picker */
    value: PropTypes.string,

    /**
     * Called when the Color pickers value changes
     * @param {String} color - Color hex code
     */
     onChange: PropTypes.func,
};

/**
 * A control that allows a user to select colors.
 */
export default class XaltColorPickerPrompt extends PureComponent {
    static propTypes = propDefinition;

    static defaultProps = {
        value: '',
        onChange: () => {},
    }

    constructor(props) {
        super(props);
        const { value } = this.props;
        this.state = {
            anchorEl: undefined,
            newValue: value,
        };
    }

    render() {
        const { anchorEl, newValue } = this.state;
        const open = Boolean(anchorEl);

        return (
            <Popover
                style={ { margin: '10px' } }
                anchorEl={ anchorEl }
                open={ open }
                onClose={ () => this.setState({ anchorEl: undefined }) }
                anchorOrigin={ {
                    horizontal: 'left',
                    vertical: 'bottom',
                } }
                transformOrigin={ {
                    horizontal: 'left',
                    vertical: 'top',
                } }>
                <PhotoshopPicker
                    color={ newValue ?? '' }
                    onAccept={ this.handleOnAccept }
                    onCancel={ this.handleOnCancel }
                    onChange={ (color) => this.setState({ newValue: color.hex }) } />
            </Popover>
        );
    }

    handleOnAccept = () => {
        const { onChange } = this.props;
        const { newValue } = this.state;
        onChange(newValue);
        this.setState({ anchorEl: undefined });
    }

    handleOnCancel = () => {
        this.setState({ anchorEl: undefined });
    }

    click = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    }
}
