import { TypeNames } from './types';
/**
 * An empty or uninitialized {@link Record}.
 * Represents a 'Record' or set of {@link Property} (names and values).
 * An Record may also have {@link Annotation}s (style annotations) that apply to the whole 'record'
 */
export class NullRecord {
    get annotations() {
        return [];
    }
    annotationsAtName(propName) {
        return [];
    }
    afterEffects(after) {
        return after;
    }
    get backgroundColor() {
        return null;
    }
    backgroundColorFor(propName) {
        return null;
    }
    get foregroundColor() {
        return null;
    }
    foregroundColorFor(propName) {
        return null;
    }
    get id() {
        return null;
    }
    get imageName() {
        return null;
    }
    imageNameFor(propName) {
        return null;
    }
    get imagePlacement() {
        return null;
    }
    imagePlacementFor(propName) {
        return null;
    }
    get isBoldText() {
        return false;
    }
    isBoldTextFor(propName) {
        return false;
    }
    get isItalicText() {
        return false;
    }
    isItalicTextFor(propName) {
        return false;
    }
    get isPlacementCenter() {
        return false;
    }
    isPlacementCenterFor(propName) {
        return false;
    }
    get isPlacementLeft() {
        return false;
    }
    isPlacementLeftFor(propName) {
        return false;
    }
    get isPlacementRight() {
        return false;
    }
    isPlacementRightFor(propName) {
        return false;
    }
    get isPlacementStretchUnder() {
        return false;
    }
    isPlacementStretchUnderFor(propName) {
        return false;
    }
    get isPlacementUnder() {
        return false;
    }
    isPlacementUnderFor(propName) {
        return false;
    }
    get isUnderline() {
        return false;
    }
    isUnderlineFor(propName) {
        return false;
    }
    get overrideText() {
        return null;
    }
    overrideTextFor(propName) {
        return null;
    }
    propAtIndex(index) {
        return null;
    }
    propAtName(propName) {
        return null;
    }
    get propCount() {
        return 0;
    }
    get propNames() {
        return [];
    }
    get properties() {
        return [];
    }
    get sessionValues() {
        return {};
    }
    get propValues() {
        return [];
    }
    get tipText() {
        return null;
    }
    get type() {
        return null;
    }
    tipTextFor(propName) {
        return null;
    }
    toRecord() {
        return this;
    }
    toJSON() {
        return {
            id: this.id,
            properties: this.properties,
            type: TypeNames.RecordTypeName
        };
    }
    valueAtName(propName) {
        return null;
    }
}
NullRecord.singleton = new NullRecord();
