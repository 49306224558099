/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';

const propDefinition = {
    style: PropTypes.object,

    /** Tooltip for hoovering over image */
    tipText: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.element,
        PropTypes.string,
    ]),

    placement: PropTypes.oneOf([
        'bottom-end',
        'bottom-start',
        'bottom',
        'left-end',
        'left-start',
        'left',
        'right-end',
        'right-start',
        'right',
        'top-end',
        'top-start',
        'top',
    ]),

    /** Wrapped child elements the tooltip pertains too*/
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.element,
    ]),

    enterDelay: PropTypes.number,
};
export default class XaltToolTip extends PureComponent {
    static propTypes = propDefinition;
    static defaultProps = {
        style: {},
        tipText: '',
        enterDelay: 1000,
        placement: 'bottom-start',
    }
    render() {
        const {
            style,
            tipText,
            children,
            ...restProps } = this.props;

        const { display } = style;

        const toolTipProps = {
            ...restProps,
            style: {
                display: display || 'inherit',
                ...style,
             },
            className: 'c-tooltip__tooltip',
            title: tipText || '',
        };

        // Tooltips require a ref handler to show the tooltip. Since the
        // We don't know if the component will be a class or a MUI component we have to wrap with a div
        // so the tooltip can show the message based on the ref.
        return (
            <Tooltip { ...toolTipProps }>
                <div className="tooltip-ref-handler">
                    { children }
                </div>
            </Tooltip>
        );
    };
};
