import { TypeNames } from 'cv-dialog-sdk';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import componentFactory from './componentFactory';
import engineConstants from './engineConstants';
import RefUtil from './ref/RefUtil';
import SaltComponent from './SaltComponent';
import SaltContext from './SaltContext';
import ScopeManager from './ScopeManager';

@observer
export default class Table extends SaltComponent {
    static propTypes = {
        layout: PropTypes.shape({
            column: PropTypes.arrayOf(PropTypes.shape({ wrap: PropTypes.bool })),
        }),
        // explict 'array of arrays' of elements for the 'rows' OR
        // a one-dimensional array of elements to use as the template for the supplied iterator
        rows: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.element)),
            PropTypes.arrayOf(PropTypes.element),
        ]),
        // an iteration context for the rows
        // this works like the Iterator component for a property
        rowIterator: PropTypes.shape({
            match: PropTypes.arrayOf(PropTypes.string),
            type: PropTypes.oneOf([ 'property', 'all-properties' ]),
            order: PropTypes.oneOf([ 'match', 'default' ]),
        }),
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        rowStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        cellStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        assert: PropTypes.shape({
            refName: PropTypes.string,
            expr: PropTypes.string,
        }),
        viewId: PropTypes.string,
    };

    static typeName = engineConstants.component.name.table;

    render() {
        const { assert } = this.props;
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore, scopeManager } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);

        if (this.determineVisibility(assert, dialogStore, scopeManager, saltStore)) {
            let resolvedProps = this.resolveProperties();
            const { rowIterator, rows, ...rest } = resolvedProps;
            if (rowIterator) {
                const newRows = this.renderPropertyIterator({ ...rowIterator, rows, ...rest });
                resolvedProps = { ...resolvedProps, rows: newRows };
            }
            return React.createElement(componentFactory.getPlatformComponent('table'), resolvedProps);
        }
        return null;
    }


    /*
        @TODO This routine is almost the same as Iterator.renderProperty
        It would be a good idea to identify and factor out the property iteration behavior so they could share!

        For each propName matched, clone the child elements and set the propName
        in local scope, to be accessed by the child elements
        Note: we don't have to do a 'deep' cloneElement here because at this stage,
        the component instances have not actually been created. (that is, on first render)
        They are 'wrapper' React element classes that 'know' how to instantiate the component on first render
        Deep clone is necessary only when a component has been rendered once already
     */
    renderPropertyIterator(iteratorProps) {
        const { match, order, type, rows } = iteratorProps;
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore, scopeManager } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        const record = dialogStore.record || scopeManager.getRef(ScopeManager.SCOPED_RECORD_REF_NAME);
        if (record) {
            // initialize to all properties in the record
            let { propNames } = record;
            // if no type or type = property, use the properties defined on the view (details or columns)
            if (!type || type === 'property') {
                const { dialog: { view } } = dialogStore;
                if (view.type === TypeNames.DetailsTypeName) {
                    propNames = view.propertyNames;
                } else if (view.type === TypeNames.ListTypeName) {
                    propNames = view.columns.map(column => column.propertyName);
                }
            }
            const matches = (order && order === 'match') ? RefUtil.getMatchesByPattern(match || [ '/.*/' ], propNames)
                : RefUtil.getMatchesByTarget(match || [ '/.*/' ], propNames);
            if (matches && matches.length > 0) {
                return matches.map((propName, index) => {
                    const newChildren = rows;
                    const newContext = {
                        ...this.context,
                        scopeManager:
                        scopeManager.newScopeWithLocal(ScopeManager.SCOPED_PROPERTY_REF_NAME, record.propAtName(propName))
                            .newScopeWithLocal(ScopeManager.SCOPED_INDEX_REF_NAME, index),
                    };
                    if (React.Children.count(newChildren) === 1) {
                        return (
                            <SaltContext.Provider
                                key={ propName }
                                value={ newContext }>
                                {newChildren}
                            </SaltContext.Provider>
                        );
                    }
                    return React.Children.map(newChildren, (childElement) => {
                        return (
                            <SaltContext.Provider
                                key={ propName }
                                value={ newContext }>
                                {childElement}
                            </SaltContext.Provider>
                        );
                    });
                });
            }
            return null;
        }
        return null;
    }
}

Table.contextType = SaltContext;
