import Layout from './Layout';
// NOTE: This code is duplicated in gml/GmlUtil.js
const margin = (layout) => {
    if (layout.margin) {
        // Handle the multi-part values.
        const style = {};
        const multi = Layout.parseMultiPartValue(layout.margin);
        switch (multi.length) {
            case 2:
                style.marginTop = Layout.parseValue(multi[0]);
                style.marginBottom = Layout.parseValue(multi[0]);
                style.marginLeft = Layout.parseValue(multi[1]);
                style.marginRight = Layout.parseValue(multi[1]);
                break;
            case 3:
                style.marginTop = Layout.parseValue(multi[0]);
                style.marginLeft = Layout.parseValue(multi[1]);
                style.marginRight = Layout.parseValue(multi[1]);
                style.marginBottom = Layout.parseValue(multi[2]);
                break;
            case 4:
                style.marginTop = Layout.parseValue(multi[0]);
                style.marginRight = Layout.parseValue(multi[1]);
                style.marginBottom = Layout.parseValue(multi[2]);
                style.marginLeft = Layout.parseValue(multi[3]);
                break;
            default: // Just work with the first value
                style.margin = Layout.parseValue(multi[0]);
        }
        return style;
    }
    return {};
};

export default margin;
