import React, { Fragment, useState, useEffect, useRef } from 'react';
import * as PropTypes from 'prop-types';
import 'date-fns';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/de';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';

import Icon, { ICON_SIZE } from '../Icon/Icon';

import getStyles from './DateTimePicker.styles';

const DATE_TYPES = {
    DATE: 'date',
    DATETIME: 'datetime',
    TIME: 'time',
};
const DATE_VIEWS = {
    DATE: 'date',
    HOURS: 'hours',
    MINUTES: 'minutes',
    YEAR: 'year',
};
const PICKER_VARIANTS = {
    DIALOG: 'dialog',
    INLINE: 'inline',
    STATIC: 'static',
};

/**
 * DateTimePicker component for picking date and time from user
 * @see https://material-ui.com/demos/pickers/#material-ui-pickers
 * @see https://material-ui-pickers.dev/getting-started/usage
 */
const DateTimePicker = (props) => {
    const {
        autoFocus,
        autoOk,
        contextStyles,
        disabled,
        emptyLabel,
        format,
        hideHelperText,
        hideTabs,
        hideToolbar,
        mode,
        onAccept,
        onChange,
        onClose,
        onOpen,
        placeholder,
        selectedDate,
        shouldDisableDate,
        testID,
        toolTip,
        is24Hour,
        variant,
        views,
        maxDate,
        propertyRef,
        firstDayofWeek,
    } = props;

    moment.locale('en-US', { week: { dow: firstDayofWeek || 0 } });

    const {
        container: containerStyles,
        icon: iconStyles,
        ...muiStyles
    } = getStyles(contextStyles);
    const styles = makeStyles(muiStyles)();
    const pickerRef = useRef(autoFocus);
    const [
        dateValue,
        setDateValue,
    ] = useState(selectedDate);
    const [
        dateText,
        setDateText,
    ] = useState(selectedDate);
    const [
        pickerOpen,
        setPickerOpen,
    ] = useState(false);

    // Update when state tracked props are updated
    useEffect(() => {
        setDateValue(selectedDate);
    }, [ selectedDate ]);

    // Trigger 'didUpdate' after closing to pass updated value
    useEffect(() => {
        if (pickerRef.current && !pickerOpen && onClose) {
            pickerRef.current = false;
            onClose(dateValue, dateText);
        }
    }, [
        pickerOpen,
        dateText,
        dateValue,
        onClose,
    ]);

    // Generate container props
    const containerProps = {
        className: 'c-date-time-picker__container',
        style: containerStyles,
    };
    if (testID) { containerProps['data-test-id'] = `${testID}__date-time-picker__container`; }
    // Generate picker props
    const pickerProps = {
        ampm: !is24Hour,
        autoFocus,
        autoOk,
        classes: {
            root: styles.inputContainer,
        },
        className: 'c-date-time-picker__picker',
        disableToolbar: hideToolbar,
        invalidDateMessage: '',
        emptyLabel,
        hideTabs,
        inputProps: {
            ref: propertyRef,
        },
        InputProps: {
            classes: {
                input: styles.inputText,
                root: styles.inputContainer,
                underline: styles.inputUnderline,
            },
            disableUnderline: true,
            onFocus: (event) => {
                event.target.select();
            },
        },
        KeyboardButtonProps: {
            classes: {
                root: styles.iconContainer,
            },
        },
        keyboardIcon: (
            <Icon
                contextStyles={ {
                    icon: iconStyles,
                } }
                iconName="event"
                iconSize={ parseInt(iconStyles.fontSize, 10) || ICON_SIZE.DEFAULT }
                title={ toolTip } />
        ),
        onAccept: (date) => {
            if (onAccept) {
                onAccept(date, props);
            }
        },
        onChange: (date, inputText) => {
            setDateValue(date);
            setDateText(inputText);
            if (onChange) {
                onChange(date ? date.toDate() : null, inputText, props);
            }
        },
        onClose: () => {
            setPickerOpen(false);
        },
        onOpen: () => {
            pickerRef.current = true;
            setPickerOpen(true);
            if (onOpen) {
                onOpen(props);
            }
        },
        open: pickerOpen,
        placeholder,
        title: toolTip,
        value: dateValue,
        variant,
        views,
        maxDate,
    };

    // Dialog only props
    if (variant === PICKER_VARIANTS.DIALOG) {
        pickerProps.clearable = true;
        pickerProps.okLabel = 'Set';
        pickerProps.DialogProps = {
            classes: {
                dialogRoot: styles.dialogContainer,
            },
        };
    }

    // Inline popup props
    if (variant === PICKER_VARIANTS.INLINE) {
        pickerProps.PopoverProps = {
            classes: {
                paper: styles.dialogContainer,
            },
        };
    }

    if (format) { pickerProps.format = format; }
    if (shouldDisableDate) { pickerProps.shouldDisableDate = shouldDisableDate; }
    if (hideHelperText) { pickerProps.helperText = null; }
    if (disabled) { pickerProps.disabled = disabled; }
    if (testID) { pickerProps['data-test-id'] = `${testID}__date-time-picker__picker`; }

    return (
        <div { ...containerProps }>
            <MuiPickersUtilsProvider utils={ MomentUtils }>
                <Fragment>
                    { mode === DATE_TYPES.DATE &&
                        <KeyboardDatePicker { ...pickerProps } />
                    }
                    { mode === DATE_TYPES.TIME &&
                        <KeyboardTimePicker { ...pickerProps } />
                    }
                    { mode === DATE_TYPES.DATETIME &&
                        <KeyboardDateTimePicker { ...pickerProps } />
                    }
                </Fragment>
            </MuiPickersUtilsProvider>
        </div>
    );
};

DateTimePicker.propTypes = {
    /** Auto Focuses on the input */
    autoFocus: PropTypes.bool,

    /** Set date and close picker on selection of last date part */
    autoOk: PropTypes.bool,

    /** Styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the wrapping container surrounding the input container */
        container: PropTypes.object,

        /** Styles for the container surrounding the input */
        inputContainer: PropTypes.object,

        /** Styles for the text input */
        inputText: PropTypes.object,

        /** Styles for the text input underline */
        inputUnderline: PropTypes.object,

        /** Styles for the text input underline in a focused state */
        inputUnderlineFocus: PropTypes.object,

        /** Styles for the text input underline in a hover state */
        inputUnderlineHover: PropTypes.object,

        /** Styles for the text input icon */
        icon: PropTypes.object,

        /** Styles for the container surrounding the text input icon */
        iconContainer: PropTypes.object,

        /** Picker modal */
        dialogContainer: PropTypes.object,

        /**
         * Central picker modal content area containing:
         * Year/Month/Day/Hour/Time/AmPm selection buttons
         * Date/Time tabs
         * Prev/Next month buttons
         * Month/Year label
         * Day of week labels
         * Month view of days
         */
        dialogContentContainer: PropTypes.object,

        /**
         * Topmost picker modal date selection container containing:
         * Year button
         * Month/day button
         * Hour/minute/ampm button
         */
        pickerToolbar: PropTypes.object,

        /** Topmost picker modal year/month/day/time selection buttons */
        pickerToolbarButton: PropTypes.object,

        /** Topmost picker modal year/month/day/time selection button labels */
        pickerToolbarButtonLabels: PropTypes.object,

        /** Topmost picker modal hour/minute separator (:) */
        pickerDateTimeToolbarSeparator: PropTypes.object,

        /**
         * Date/Time selection tabs between Picker Toolbar (year/month/day/time controls)
         * and main calendar control month view content and buttons
         */
        pickerTabs: PropTypes.object,

        /** Tab button */
        pickerTabButtons: PropTypes.object,

        /** Tab button selected indicator */
        pickerTabIndicator: PropTypes.object,

        /**
         * Central picker modal content area containing:
         * Prev/Next month buttons
         * Month/Year label
         * Day of week labels
         * Month view of days
         */
        pickerView: PropTypes.object,

        /** Calendar prev/next buttons and Month/Year label container */
        pickerCalendarSwitchHeader: PropTypes.object,

        /** Calendar prev/next buttons */
        pickerCalendarButton: PropTypes.object,

        /** Calendar prev/next button icon */
        pickerCalendarButtonIcon: PropTypes.object,

        /** Calendar Month/Year label container */
        pickerCalendarHeaderLabel: PropTypes.object,

        /** Calendar days of week container */
        pickerCalendarDaysHeader: PropTypes.object,

        /** Calendar days of week labels */
        pickerCalendarDayLabel: PropTypes.object,

        /** Calendar month view container below days of week container */
        pickerCalendarContainer: PropTypes.object,

        /** Calendar week row */
        pickerCalendarWeek: PropTypes.object,

        /** Calendar day */
        pickerDay: PropTypes.object,

        /** Calendar day current day state */
        pickerDayCurrent: PropTypes.object,

        /** Calendar day selected state */
        pickerDaySelected: PropTypes.object,

        /** Calendar day disabled state */
        pickerDayDisabled: PropTypes.object,

        /** Calendar hidden days */
        pickerDayHidden: PropTypes.object,

        /** Calendar year selection container */
        pickerYearContainer: PropTypes.object,

        /** Calendar year item container */
        pickerYear: PropTypes.object,

        /** Calendar year item container selected state */
        pickerYearSelected: PropTypes.object,

        /** Calendar year item container disabled state */
        pickerYearDisabled: PropTypes.object,

        /** Main container surrounding the clock */
        pickerClockContainer: PropTypes.object,

        /** Clock container */
        pickerClock: PropTypes.object,

        /** Clock central pin */
        pickerClockPin: PropTypes.object,

        /** Clock number */
        pickerClockNumber: PropTypes.object,

        /** Clock number selected state */
        pickerClockNumberSelected: PropTypes.object,

        /** Clock hand from center point to number */
        pickerClockPointer: PropTypes.object,

        /** Clock indicator center not on a number */
        pickerClockPointerThumb: PropTypes.object,

        /** Clock indicator center on a number */
        pickerClockPointerNoPoint: PropTypes.object,

        /**
         * Bottom picker modal action area below content area containing:
         * Cancel/Set buttons
         */
        dialogActionsContainer: PropTypes.object,

        /** Action button containers */
        dialogActionsButtons: PropTypes.object,

        /** Action button labels */
        dialogActionsLabels: PropTypes.object,
    }),

    /** Prevents the control from changing input */
    disabled: PropTypes.bool,

    /** Message displaying in text field, if null passed */
    emptyLabel: PropTypes.string,

    /** Custom display format (see NPM package date-fns) */
    format: PropTypes.string,

    /** Hide helper text when error */
    hideHelperText: PropTypes.bool,

    /** Hide Date and Time picker selection tabs */
    hideTabs: PropTypes.bool,

    /** Hide picker Date and Time toolbar */
    hideToolbar: PropTypes.bool,

    /** Display the date picker in a 24 hour format */
    is24Hour: PropTypes.bool,

    /** Date type mode: date, time, datetime */
    mode: PropTypes.oneOf([
        'date',
        'time',
        'datetime',
    ]),

    /**
     * Called when the date has changed using the picker
     * @param {Date} date - The selected date
     * @param {Object} props - The component instance props
     * @returns {void}
     */
    onAccept: PropTypes.func,

    /**
     * Called when the user or picker changes the input value
     * @param {Date} date - The selected date
     * @param {String} text - The input text
     * @param {Object} props - The component instance props
     * @returns {void}
     */
    onChange: PropTypes.func,

    /**
     * Called when the picker has been closed
     * @param {Date} date - The selected date
     * @param {String} text - The input text
     * @returns {void}
     */
    onClose: PropTypes.func,

    /**
     * Called when the picker has been opened
     * @returns {void}
     */
    onOpen: PropTypes.func,

    /** Placeholder text for date time picker input field */
    placeholder: PropTypes.string,

    /** Selected date value */
    selectedDate: PropTypes.instanceOf(Date),

    /**
     * Called for each day on the calendar view
     * @param {Date} the calendar day
     * @returns {Boolean} disables the calendar day
     */
    shouldDisableDate: PropTypes.func,

    /** Id used for testing */
    testID: PropTypes.string,

    /** toolTip string */
    toolTip: PropTypes.string,

    /** Changes how the date/time modal is displayed */
    variant: PropTypes.oneOf([
        /** Uses a viewport centered modal with a darkened background */
        'dialog',

        /** Uses an input anchored modal */
        'inline',

        /** Renders inline rather than in a modal */
        'static',
    ]),

    /** Visible picker views */
    views: PropTypes.arrayOf(PropTypes.string),

    /** Maximum date value */
    maxDate: PropTypes.instanceOf(Date),

    propertyRef: PropTypes.object,

    /** specifies first day of the week */
    firstDayofWeek: PropTypes.number,
};

DateTimePicker.defaultProps = {
    autoFocus: false,
    contextStyles: {},
    format: 'M/dd/yy, h:mm a',
    mode: DATE_TYPES.DATETIME,
    selectedDate: null,
    testID: 'DateTimePicker',
    variant: PICKER_VARIANTS.DIALOG,
    views: [
        DATE_VIEWS.DATE,
        DATE_VIEWS.HOURS,
        DATE_VIEWS.MINUTES,
        DATE_VIEWS.YEAR,
    ],
    maxDate: new Date('12/31/9999'),
};

export default DateTimePicker;
export {
    DATE_TYPES,
    DATE_VIEWS,
    PICKER_VARIANTS,
};
