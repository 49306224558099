import React from 'react';
import { XaltTextLabel } from 'xalt-react-atoms';
import RWSaltComponent from './RWSaltComponent';
import LinearProgress from '../components/base/LinearProgress';

class RWLoading extends RWSaltComponent {
    render() {
        const { loadingText } = this.props;
        return (
            <div style={ { display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'center', alignItems: 'center', padding: '40px' } }>
                <LinearProgress />
                { loadingText &&
                    <div style={ { marginTop: '10px' } }>
                        <XaltTextLabel>
                            { loadingText }
                        </XaltTextLabel>
                    </div>
                }
            </div>
        );
    }
}

export default RWLoading;
