import React, { Fragment, forwardRef, useState, useEffect, useRef } from 'react';
import * as PropTypes from 'prop-types';
import Popper from '@material-ui/core/Popper';
import { Autocomplete } from '@material-ui/lab';
import { List } from 'react-virtualized';

import makeStyles from '@material-ui/core/styles/makeStyles';
import getStyles from './DropDown.styles';

import { default as MenuItem } from '../MenuItem/MenuItem';
import { default as Checkbox } from '../Checkbox/Checkbox';
import { default as TextField } from '../TextField/TextField';
import { default as ActivityIndicator } from '../ActivityIndicator/ActivityIndicator';

const CLOSE_REASONS = {
    BLUR: 'blur',
    SELECT_OPTION: 'select-option',
};

const ITEM_SIZE = 45;
const FIXED_LIST_WIDTH = 400; // px

const OuterElementContext = React.createContext({});
const OuterElementType = React.forwardRef((props, ref) => {
const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps}/>;
});

/**
 * Dropdown component that allows a user to select an option from a pre-defined menu of options.
 * @see https://material-ui.com/demos/selects/
 */
const DropDown = (props) => {
    const {
        contextStyles,
        items: initialItems,
        itemsResolver: getData,
        isComboBox,
        disabled,
        testID,
        toolTip,
        value,
        multiple,
        onChange,
        renderAdornment,
        renderInput,
        renderItem,
        renderValue,
        autoFocus,
        propertyRef,
    } = props;

    const [
        state,
        setState,
    ] = useState({ open: false, items: initialItems, loading: false, reFocus: false, value: []});
    const {
        items,
        loading,
        open,
        reFocus,
    } = state;
    let scrollPosition = 0 || state.scrollPosition;
    const ListboxComponent = React.forwardRef(function ListboxComponent(
        prop,
        ref
    ) {
        const {
            children,
            role,
            ...other
        } = prop;
        const itemData = [];
        children.forEach((item) => {
            itemData.push(item);
            itemData.push(...(item.children || []));
        });

        const itemCount = Array.isArray(children) ? children.length : 0;
        const calculatedListHeight = items.length * ITEM_SIZE > 400 ? 400 : (items.length * ITEM_SIZE) + 2;
        const selectedIndex = scrollPosition ? Math.round((scrollPosition) / ITEM_SIZE) : 0;

        return (
            <div ref={ref}>
                <OuterElementContext.Provider value={other}>
                    <List
                        className='List'
                        style={{ 
                            boxSizing: 'border-box',
                            padding: 0,
                            '& ul': {
                                padding: 0,
                                margin: 0,
                            } }}
                        itemData={itemData}
                        height={ calculatedListHeight }
                        width={ FIXED_LIST_WIDTH }
                        scrollToItem={ selectedIndex }
                        outerElementType={OuterElementType}
                        innerElementType="div"
                        itemSize={ () => ITEM_SIZE }
                        overscanCount={10}
                        itemCount={itemCount}>
                        {({ index, style }) => React.cloneElement(children[index], { style }) }
                    </List>
                </OuterElementContext.Provider>
            </div>
        );
    });
    ListboxComponent.displayName = 'ListboxComponent';

    const updateState = (updates = {}) => {
        setState({
            ...state,
            ...updates,
        });
    };

    useEffect(() => {
        updateState({
            items: initialItems,
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ initialItems ]);
    const inputRef = propertyRef || useRef();

    const loadData = () => {
        if (getData) {
            updateState({
                loading: true,
            });
            Promise.resolve(getData())
                .then((data) => {
                    updateState({
                        items: data,
                        loading: false,
                        open: true,
                        reFocus: false,
                    });
                });
        }
    };

    useEffect(() => {
        if (reFocus) {
            inputRef.current.focus();
        }
    }, [ reFocus ]);

    // Override core styles with context styles, separating MUI styles
    const {
        menuImageLoadingIndicator: imageLoadingIndicatorStyles,
        loader: loaderStyles,
        menuItem: menuItemStyles,
        menuItemImage: menuItemImageStyles,
        selectedItem: selectedMenuItemStyles,
        checkboxContainer: checkboxContainerStyles,
        checkbox: checkboxStyles,
        checkboxSelected: checkboxSelectedStyles,
        popper: popperStyles,
        ...muiStyles
    } = getStyles(contextStyles);

    // Create dynamic class names and injected DOM styles for MUI component
    // * makeStyles returns a function
    const styles = makeStyles(muiStyles)();

    // Generate select props
    const selectProps = {
        disableUnderline: true,
        displayEmpty: true,
        classes: {
            root: styles.container,
            select: styles.dropDown,
        },
        className: styles.container,
        disabled,
        multiple,
        MenuProps: {
            classes: {
                list: styles.menu,
            },
        },
        onChange: (event, child) => {
            if (onChange) {
                onChange(event, child.props);
            }
        },
        renderValue,
        value,
        open,
        onClose: () => {
            updateState({
                open: false,
                reFocus: true,
            });
        },
        onOpen: () => {
            // Handle dynamic items generation
            if (getData) {
                loadData();
            }
            else {
                updateState({
                    open: true,
                    reFocus: false,
                });
            }
        },
        inputRef,
        autoFocus,
        title: toolTip,
    };

    // Generate indicator props
    const indicatorProps = {
        contextStyles: {
            container: loaderStyles,
        },
        size: 15,
    };

    // Handle dynamic loading state
    if (loading) {
        // Disable control to prevent interaction while loading
        selectProps.disabled = true;

        // Inject an activity indicator before the value display
        selectProps.renderValue = (...args) => ( // eslint-disable-line react/display-name
            <div
                style={ {
                    alignItems: 'center',
                    display: 'flex',
                } }>
                <ActivityIndicator { ...indicatorProps } />
                { renderValue && renderValue(...args) }
            </div>
        );
    }
    if (testID) { selectProps['data-test-id'] = `${testID}__drop-down__menu`; }

    // Generate menu item props
    const itemProps = {
        className: 'c-drop-down__menu-item',
        contextStyles: {
            container: menuItemStyles,
            image: menuItemImageStyles,
            imageLoadingIndicator: imageLoadingIndicatorStyles,
            selected: selectedMenuItemStyles,
        },
        // Autocomplete warns without an onClick listener
        onClick: () => {},
    };
    if (testID) { itemProps.testID = `${testID}__drop-down__menu-item`; }

    // Create menu item with base props used to create this menu instance
    // eslint-disable-next-line react/display-name
    const CustomMenuItem = forwardRef((cProps, ref) => {
        const propKeys = [];
        if (Array.isArray(value)) {
            value.map((item) => propKeys.push(item.description || item));
        }
        const isSelected = propKeys.includes(cProps.text) || cProps.text === value;
        // Autocomplete renders options inside an <li> element.
        // Set component to div to get rid of <li> nesting warning
        return (
            <MenuItem
                component="div"
                ref={ ref }
                { ...itemProps }
                { ...cProps }
                text={
                    <>
                        <span
                            title={ cProps.text }
                            style={ {
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            } }>
                            { cProps.text }
                        </span>
                        { multiple && <Checkbox
                            checked={ isSelected }
                            contextStyles={ {
                                container: checkboxContainerStyles,
                                check: checkboxStyles,
                                checked: checkboxSelectedStyles,
                            } } />
                        }
                    </>
                }
                selected={ isSelected } />
        );
    });

    const autocompleteChange = (event, newVal) => {
        // this.debounce(() => {
            updateState({scrollPosition});
            if (onChange) {
                onChange(event, {
                    item: newVal,
                    value: newVal,
                });
            }
        // }, 300);
    };

    const renderInputContainer = (inputProps) => (
        <TextField
            { ...inputProps }
            propertyRef={ propertyRef }
            onChange={ () => {} }
            InputProps={ {
                ...inputProps.InputProps,
                disableUnderline: true,
                startAdornment: (
                    <Fragment>
                        { loading ? <ActivityIndicator { ...indicatorProps } /> : null }
                        { inputProps.InputProps.startAdornment }
                        { renderAdornment(value) }
                    </Fragment>
                ),
            } } />
    );

    const autocompleteProps = {
        classes: {
            root: styles.dropDown,
            input: styles.input,
            inputRoot: styles.inputContainer,
            noOptions: styles.noOptions,
            option: styles.optionItem,
            popper: loading ? styles.optionContainer : '',
        },
        className: 'c-drop-down__menu',
        id: 'virtualize-list',
        disableClearable: true,
        disableListWrap: true,
        disableCloseOnSelect: multiple,
        getOptionLabel: (item) => (renderValue(item)),
        options: items,
        onChange: autocompleteChange,
        onInputChange: (event, newVal) => {
            if (onChange && newVal === '') {
                onChange(event, { item: newVal });
            }
        },
        onClose: (event, reason) => {
            const { target: { value: newVal } } = event;
            updateState({
                scrollPosition: 0,
            });
            if (isComboBox && onChange && reason === CLOSE_REASONS.BLUR) {
                onChange(event, {
                    item: newVal,
                    value: newVal,
                });
            }
        },
        onOpen: selectProps.onOpen,
        // eslint-disable-next-line react/display-name
        PopperComponent: (popperProps) => (
            <Popper
                { ...popperProps }
                style={ { ...popperStyles } }
                placement="bottom-start" />
        ),
        renderOption: (item) => ( renderItem(CustomMenuItem, item) ),
        renderInput: renderInputContainer,
        title: toolTip,
        value: renderInput(value),
    };

    return (
        <Autocomplete
            // debug={true}
            { ...autocompleteProps }
            noOptionsText="(empty)"
            ListboxComponent={ ListboxComponent }/>
    );
};

DropDown.propTypes = {
    /** Auto focus the dropdown menu */
    autoFocus: PropTypes.bool,

    /** Styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the checkbox when there are multiple items */
        checkbox: PropTypes.object,

        /** Styles for the checkbox container when there are multiple items */
        checkboxContainer: PropTypes.object,

        /** Styles for the checkbox selected when there are multiple items */
        checkboxSelected: PropTypes.object,

        /** Styles for the container surrounding the input */
        container: PropTypes.object,

        /** Styles for the input */
        dropDown: PropTypes.object,

        /** Styles for the input icon */
        icon: PropTypes.object,

        /** Styles for the input field */
        input: PropTypes.object,

        /** Styles for the menu */
        menu: PropTypes.object,

        /** Styles for loading indicator of image in menu item */
        menuImageLoadingIndicator: PropTypes.object,

        /** Styles for each menu option */
        menuItem: PropTypes.object,

        /** Styles for each menu item image */
        menuItemImage: PropTypes.object,

        /** Styles for the Selected Item in the menu */
        selectedItem: PropTypes.object,
    }),

    /** Disable the dropdown */
    disabled: PropTypes.bool,

    /** Shows an manual input entry field if set to true */
    isComboBox: PropTypes.bool,

    /** An array of drop down menu items */
    items: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,

        /**
         * Use 'value' property to access selected value at event.target.value
         */
        PropTypes.object,
    ])),

    /**
     * Return a method that resolves to 'items' prop
     * @return {Array<{data: *, icon: String, text: String}>}
     */
    itemsResolver: PropTypes.func,

    /**
     * Enable/Disable selecting multiple menu options
     */
    multiple: PropTypes.bool,

    /**
     * Called when change event occurs on the dropdown
     * @param {Object} event - The native change event
     * @param {Object} props - The component instance props
     */
    onChange: PropTypes.func.isRequired,

    /**
     * Function that is used to render input adornment
     * @param {any} adornment - Dropdown input value.
     */

    renderAdornment: PropTypes.func,

    /**
     * Called to render the menu items
     * Return MenuItem with 'value' property to access selected value at event.target.value
     * @param {Component} MenuItem - The default menu item component
     * @param {any} Item - Item that needs to be rendered
     */
    renderItem: PropTypes.func.isRequired,

    renderInput: PropTypes.func,

    /**
     * Function that is used to render a custom selected option
     * @param {any} value - The current selected item.
     */
    renderValue: PropTypes.func,

    /** Id used for testing */
    testID: PropTypes.string,

    /** toolTip string */
    toolTip: PropTypes.string,

    /** Selected Item value */
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
    ]),
};

DropDown.defaultProps = {
    contextStyles: {},
    value: '',
    items: [],
    renderAdornment: () => '',
    renderInput: (value) => `${value}`,
    renderValue: (value) => `${value}`,
};

export default DropDown;
