/*       */

import StyleSet from '../StyleSet';
import { fontColor, fontFamily } from '../paperConstants';
import AbstractModel from './AbstractModel';
import FormUtil from '../FormUtil';

export default class AbstractControl extends AbstractModel {
    constructor(json, cell) {
        super(json);
        this.parentWidth = cell.width;
        this.parentHeight = cell.height;
        this.init(cell);
    }
    get designHorizontalJustification() { return this.valueAt('Design.HorizontalJustification'); }

    get useSimpleSalt() { return true; }
    get backgroundColorOverride() { return undefined; }
    get isBold() { return !!this.valueAt('Bold'); }
    get isItalic() { return !!this.valueAt('Italic'); }
    get layoutSizeWidth() { return this.floatAt('Layout.Size.Width', this.parentWidth); }
    get layoutSizeWidths() { return this.floatArrayAt('Layout.Size.Width', this.parentWidth); }
    get layoutSizeHeight() { return this.floatAt('Layout.Size.Height', this.parentHeight); }
    get layoutSizeHeights() { return this.floatArrayAt('Layout.Size.Height', this.parentHeight); }
    get numberOfLines() { return this.floatAt('NumberOfLines', 1); }
    get propertyName() { return this.valueAt('Binding'); }
    get text() { return this.valueAt('Text'); }
    get textAlignment() {
        let result = this.valueAt('TextAlignment');
        if (!result) {
            // If there is no text alignment, check for Design.HorizontalJustification
            const dhj = this.designHorizontalJustification;
            if (dhj && dhj.toLowerCase() !== 'none') {
                result = dhj.toLowerCase();
            }
        }
        return result;
    }
    get textColor() { return this.color('TextColor'); }

    asSalt(parentContext) {
        this.fontSizes = parentContext.form.fontSizes;
        return this.useSimpleSalt ? this.newReadOnlySalt() : this.newComplexSalt();
    }

    get fontId() {
        return this.valueAt('Design.Font.Id');
    }

    getFontSize() {
        const fontSize = this.fontSizes[this.fontId];
        return FormUtil.adjustFontSize(fontSize, fontFamily);
    }

    init(cell) {
        this.top = (this.isFillParentHeight ? 0 : this.layoutOriginY) + this.paddingTop;
        this.left = (this.isFillParentWidth ? 0 : this.layoutOriginX) + this.paddingLeft;
        this.width = this.isFillParentWidth ? cell.width - (this.paddingLeft + this.paddingRight)
            : this.layoutSizeWidth;
        this.height = this.isFillParentHeight ? cell.height - (this.paddingTop + this.paddingBottom)
            : this.layoutSizeHeight;
    }

    newMaintenanceBoxStyleSet() {
        return this.newReadOnlyBoxStyleSet();
    }
    newMaintenancePropertyStyleSet() {
        return this.newReadOnlyPropertyStyleSet();
    }
    newReadOnlyBoxStyleSet() {
        const result = new StyleSet({
            position: 'absolute',
            top: this.top,
            left: this.left,
            width: this.width,
            height: this.height,
            overflow: 'hidden',
            flexDirection: 'row',
        });
        if (this.numberOfLines === 1) {
            result.style.alignItems = 'center';
        }
        return result;
    }
    newReadOnlyPropertyStyleSet() {
        const result = new StyleSet({
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'row',
            color: fontColor(this.textColor),
            fontSize: this.getFontSize(),
            fontFamily,
            whiteSpace: this.numberOfLines > 1 ? 'pre-wrap' : 'nowrap',
        });
        if (this.backgroundColorOverride) { result.style.backgroundColor = this.backgroundColorOverride; }
        if (this.isBold) { result.style.fontWeight = 'bold'; }
        if (this.isItalic) { result.style.fontStyle = 'italic'; }
        result.xStyle.container = {
            paperId: 'property',
        };
        if (this.textAlignment) {
            const alignment = this.textAlignment.toLowerCase();
            result.style.textAlign = alignment;
            result.style.justifyContent = FormUtil.getAlignment(alignment);
            result.xStyle.container.justifyContent = FormUtil.getAlignment(alignment);
        }
        if (this.numberOfLines) {
            result.style.numberOfLines = this.numberOfLines;
        }
        return result;
    }
    newComplexSaltAssertExpression() {
        return { expr: `$dialog.isReadMode or $propDef("${this.propertyName}").isReadOnly` };
    }
    newComplexSalt() {
        // Read mode vs. edit mode sometimes require a tweak.
        return {
            when: {
                assert: this.newComplexSaltAssertExpression(),
                children: [ this.newReadOnlySalt() ],
                'else-children': [ this.newMaintenanceSalt() ],
            },
        };
    }
    newMaintenanceSalt() {
        const boxStyleSet = this.newMaintenanceBoxStyleSet();
        const propertyStyleSet = this.newMaintenancePropertyStyleSet();
        return this.newBoxSalt(boxStyleSet, propertyStyleSet);
    }
    newReadOnlySalt() {
        const boxStyleSet = this.newReadOnlyBoxStyleSet();
        const propertyStyleSet = this.newReadOnlyPropertyStyleSet();
        return this.newBoxSalt(boxStyleSet, propertyStyleSet);
    }
    newBoxSalt(boxStyleSet, propertyStyleSet) {
        return {
            box: {
                ...boxStyleSet.asStyleAttribute(),
                ...boxStyleSet.asXStyleAttribute(),
                children: [ this.newControlSalt(propertyStyleSet), this.newFieldActionSalt() ],
            },
        };
    }
    newControlSalt(propertyStyleSet) {
        return {
            property: {
                name: this.propertyName,
                ...propertyStyleSet.asStyleAttribute(),
                ...propertyStyleSet.asXStyleAttribute(),
            },
        };
    }

    newFieldActionSalt() {
        return {
            fieldAction: {
                name: this.propertyName,
                style: {
                    height: this.height,
                    resizeMode: 'contain', // native
                    objectFit: 'contain', // web
                },
            },
        };
    }
}
