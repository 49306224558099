import React from 'react';
import * as PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import makeStyles from '@material-ui/core/styles/makeStyles';

import Image from '../Image/Image';
import Button from '../Button/Button';
import Divider from '../Divider/Divider';
import ChromeLogo from './chrome-logo.svg';
import EdgeLogo from './edge-logo.svg';
import FirefoxLogo from './firefox-logo.svg';
import SafariLogo from './safari-logo.svg';

import getStyles from './BrowserSupportList.styles';

/**
 * Used to indicate an unsupported browser agent or version and suggests supported ones.
 */
const BrowserSupportList = (props) => {
    const {
        contextStyles,
        i18n,
        imageSrc,
    } = props;

    // Override core styles with context styles, separating MUI styles
    const {
        button: buttonStyles,
        buttonText: buttonTextStyles,
        ...muiStyles
    } = getStyles(contextStyles);

    // Create dynamic class names and injected DOM styles for MUI component
    // * makeStyles returns a function
    const styles = makeStyles(muiStyles)();

    return (
        <Card
            classes={ { root: styles.container } }
            raised>
            { imageSrc &&
                <CardMedia
                    classes={ {
                        root: styles.media,
                    } }
                    image={ imageSrc } />
            }
            <CardHeader
                classes={ { title: styles.title } }
                title={ i18n.title } />
            <CardContent>
                <Typography
                    classes={ { root: styles.body } }
                    variant="body1">
                    { i18n.body }
                </Typography>
                <Divider contextStyles={ {
                    divider: { margin: '16px 0' },
                } } />
                <Typography
                    classes={ { root: styles.subtitle } }
                    variant="h6">
                    { i18n.subtitle }
                </Typography>
                <List>
                    <ListItem divider>
                        <ListItemAvatar>
                            <Image
                                contextStyles={ {
                                    image: {
                                        width: '50px',
                                        height: '50px',
                                    },
                                } }
                                imageSrc={ ChromeLogo }
                                altText={ i18n.chrome } />
                        </ListItemAvatar>
                        <ListItemText
                            classes={ { primary: styles.itemText } }
                            primary={ i18n.chrome } />
                        <ListItemSecondaryAction>
                            <a
                                href="https://www.google.com/chrome"
                                target="_blank"
                                rel="noopener noreferrer">
                                <Button
                                    contextStyles={ {
                                        primary: buttonStyles,
                                        primaryText: buttonTextStyles,
                                    } }
                                    onClick={ () => {} }
                                    text={ i18n.download } />
                            </a>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem divider>
                        <ListItemAvatar>
                            <Image
                                contextStyles={ {
                                    image: {
                                        width: '50px',
                                        height: '50px',
                                    },
                                } }
                                imageSrc={ FirefoxLogo }
                                altText={ i18n.firefox } />
                        </ListItemAvatar>
                        <ListItemText
                            classes={ { primary: styles.itemText } }
                            primary={ i18n.firefox } />
                        <ListItemSecondaryAction>
                            <a
                                href="https://www.mozilla.org/firefox"
                                target="_blank"
                                rel="noopener noreferrer">
                                <Button
                                    contextStyles={ {
                                        primary: buttonStyles,
                                        primaryText: buttonTextStyles,
                                    } }
                                    onClick={ () => {} }
                                    text={ i18n.download } />
                            </a>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem divider>
                        <ListItemAvatar>
                            <Image
                                contextStyles={ {
                                    image: {
                                        width: '50px',
                                        height: '50px',
                                    },
                                } }
                                imageSrc={ SafariLogo }
                                altText={ i18n.safari } />
                        </ListItemAvatar>
                        <ListItemText
                            classes={ { primary: styles.itemText } }
                            primary={ i18n.safari } />
                        <ListItemSecondaryAction>
                            <a
                                href="https://support.apple.com/downloads/safari"
                                target="_blank"
                                rel="noopener noreferrer">
                                <Button
                                    contextStyles={ {
                                        primary: buttonStyles,
                                        primaryText: buttonTextStyles,
                                    } }
                                    onClick={ () => {} }
                                    text={ i18n.download } />
                            </a>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Image
                                contextStyles={ {
                                    image: {
                                        width: '50px',
                                        height: '50px',
                                    },
                                } }
                                imageSrc={ EdgeLogo }
                                altText={ i18n.edge } />
                        </ListItemAvatar>
                        <ListItemText
                            classes={ { primary: styles.itemText } }
                            primary={ i18n.edge } />
                        <ListItemSecondaryAction>
                            <a
                                href="https://www.microsoft.com/edge"
                                target="_blank"
                                rel="noopener noreferrer">
                                { /* TODO: Disable this until the we diagnose the issue for windows.
                                    We should only hit this scenario for windows. No download fix is available.
                                    <Button
                                    contextStyles={ {
                                        primary: buttonStyles,
                                        primaryText: buttonTextStyles,
                                    } }
                                    onClick={ () => {} }
                                    text={ i18n.download } /> */ }
                            </a>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </CardContent>
        </Card>
    );
};

BrowserSupportList.propTypes = {
    /** Extended styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the body text */
        body: PropTypes.object,

        /** Styles for all of the buttons */
        button: PropTypes.object,

        /** Styles for all of the button text */
        buttonText: PropTypes.object,

        /** Styles for the wrapping container */
        container: PropTypes.object,

        /** Styles for all of the list text */
        itemText: PropTypes.object,

        /** Styles for any image container */
        media: PropTypes.object,

        /** Styles for the list title */
        subtitle: PropTypes.object,

        /** Styles for the main title */
        title: PropTypes.object,
    }),

    /** Internal text strings for internationalization */
    i18n: PropTypes.shape({
        /** Descriptive body text */
        body: PropTypes.string,

        /** Name of Google Chrome browser */
        chrome: PropTypes.string,

        /** Text for all download buttons */
        download: PropTypes.string,

        /** Name of Microsoft Edge browser */
        edge: PropTypes.string,

        /** Name of Mozilla Firefox browser */
        firefox: PropTypes.string,

        /** Name of Apple Safari browser */
        safari: PropTypes.string,

        /** Title text for the supported browsers list */
        subtitle: PropTypes.string,

        /** Title text for the descriptive body text */
        title: PropTypes.string,
    }),

    /** Image URL to display above the main title */
    imageSrc: PropTypes.string,
};

BrowserSupportList.defaultProps = {
    contextStyles: {},
    i18n: {
        body: 'You are visiting this page because we detected an unsupported browser. Your browser does not support features that we require. We highly recommend that you update your browser.',
        chrome: 'Google Chrome',
        download: 'Download Latest',
        edge: 'Microsoft Edge (Not supported for Windows at this time)',
        firefox: 'Mozilla Firefox',
        safari: 'Apple Safari',
        subtitle: 'These are the ones we recommend:',
        title: 'Oops! We do not support this version of your browser.',
    },
};

export default BrowserSupportList;
