/* eslint-disable react/no-array-index-key */
import React from 'react';
import * as PropTypes from 'prop-types';
import getStyles from './MultipleForm.styles';
import Resizable from '../Resizable/Resizable';

const chunkedArray = (arr, size) => Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
);

const MultipleForm = (props) => {
    const {
        contextStyles,
        formElements,
        onResizeStop,
        scrollIdentifier,
    } = props;
    const {
        anchorScrollColumn,
        column,
        mainContentRowSizingContainer,
    } = getStyles(contextStyles);
    const chunkedFormElements = chunkedArray(formElements, 3);

    return (
        <div
            className="anchor-column-scollar-container"
            data-scroll-identifier={ scrollIdentifier }
            style={ anchorScrollColumn }>
            {
                chunkedFormElements.map((chunk, index) => {
                    const marginTopStyle = index < 1 ? undefined : { marginTop: '16px' };
                    return (
                        <div
                            className="multiple-form-row"
                            key={ index }
                            style={ { ...marginTopStyle, ...mainContentRowSizingContainer } }>
                            {
                                chunk.map((formElement, childIndex) => {
                                    const {
                                        props: {
                                            dimensions,
                                            viewId,
                                            isDetailsView,
                                        },
                                    } = formElement;
                                    let defaultWidth;
                                    const minWidth = isDetailsView ? { minWidth: 'max-content' } : undefined;
                                    if (dimensions.width) {
                                        defaultWidth = dimensions.width;
                                    }
                                    else {
                                        defaultWidth = isDetailsView ? 'max-content' : '600px';
                                    }
                                    const marginLeftStyle = childIndex < 1 ? undefined : { marginLeft: '16px' };
                                    return (
                                        <Resizable
                                            className="multiple-form-column-content"
                                            defaultSize={ {
                                                width: defaultWidth,
                                                height: dimensions.height,
                                            } }
                                            direction="both"
                                            key={ `multiple-form-column-content-${viewId}` }
                                            onResizeStop={ onResizeStop }
                                            style={ { ...marginLeftStyle, ...column, ...minWidth } }
                                            viewid={ viewId }>
                                            { formElement }
                                        </Resizable>
                                    );
                                })
                            }
                        </div>
                    );
                })
            }
        </div>
    );
};

MultipleForm.propTypes = {
    contextStyles: PropTypes.shape({
        anchorScrollColumn: PropTypes.object,
        mainContentRowSizingContainer: PropTypes.object,
        column: PropTypes.object,
    }),
    formElements: PropTypes.arrayOf(PropTypes.element),
    scrollIdentifier: PropTypes.string,
    onResizeStop: PropTypes.func,
};

MultipleForm.defaultProps = {
    contextStyles: {},
    formElements: [],
};

export default MultipleForm;
