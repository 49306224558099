import React, { Component } from 'react';
import componentFactory from '../componentFactory';
import componentPropTypes from './abstractComponentPropTypes';

// Note: these should be a subset of abstractComponentPropTypes
const propDefinitions = {
    style: componentPropTypes.style,
    name: componentPropTypes.name,
    value: componentPropTypes.value,
    tipText: componentPropTypes.tipText,
    isReadMode: componentPropTypes.isReadMode,
    isFocused: componentPropTypes.isFocused,
    onValueChanged: componentPropTypes.onValueChanged,
    onLoadOptionValues: componentPropTypes.onLoadOptionValues,
    xStyle: componentPropTypes.xStyle,
    optionValues: componentPropTypes.optionValues,
};
export default class ComboBoxField extends Component {
    static propTypes = propDefinitions;
    static defaultProps = {
        onLoadOptionValues: () => {},
    };

    constructor(props) {
        super(props);
        this.handleOnValueChanged = this.handleOnValueChanged.bind(this);
    }

    render() {
        const {
            style,
            value,
            tipText,
            isReadMode,
            isFocused,
            xStyle,
            optionValues,
        } = this.props;

        const foundIndex = optionValues.findIndex((option) => option.value === value);
        if (foundIndex === -1 && value) optionValues.push({ label: value, value });
        const convertedObjectValue = value ? { label: value, value } : value;

        const dropDownFieldProps = {
            style,
            value: convertedObjectValue,
            tipText,
            isReadMode,
            isFocused,
            onValueChanged: this.handleOnValueChanged,
            xStyle,
            optionValues,
            allowFreeformEntry: true,
        };
        return React.createElement(componentFactory.getPlatformPropertyComponent('dropDownField'), { ...dropDownFieldProps });
    }

    componentDidMount() {
        const { onLoadOptionValues } = this.props;
        onLoadOptionValues();
    }

    handleOnValueChanged(newValue) {
        const { onValueChanged } = this.props;
        // values are propsed and passed as Object even for string inputs.
        onValueChanged(newValue.value);
    }
}
