import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { constants, serviceFactory } from 'cv-react-core';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import SimpleModal from './SimpleModal';
import { BUTTON_VARIANT } from '../base/Button';
import Icon from '../base/Icon';
import IconButton from '../base/IconButton';
import TextLabel from '../base/TextLabel';
import getStyles from './styles/ConfirmModal.styles';

const { lang } = serviceFactory;

const ConfirmModal = observer(({ uiStore, dialogId }) => {
    const modalOpenProps = toJS(uiStore.getValueForUIObject(dialogId, constants.ui.MODAL_OPEN_PROPS));
    const {
        closeIcon,
        closeIconButton,
        infoIcon,
        titleContainer,
        ...contextStyles
    } = getStyles();
    if (modalOpenProps) {
        const {
            message,
            onCancel,
            ...restModalOpenProps
        } = modalOpenProps;
        const messageElement = (
            <Fragment>
                <Icon
                    contextStyles={ {
                        icon: infoIcon,
                    } }
                    iconName="error_circle"
                    iconSize={ 42 } />
                <TextLabel contextStyles={ contextStyles }>
                    { message }
                </TextLabel>
            </Fragment>
        );
        const title = onCancel ? (
            <div style={ titleContainer }>
                <IconButton
                    contextStyles={ {
                        button: closeIconButton,
                        icon: closeIcon,
                    } }
                    iconName="cancel_circle"
                    iconSize={ 32 }
                    onClick={ onCancel }
                    title={ lang.generic.cancel } />
            </div>
        ) : null;
        const props = {
             ...restModalOpenProps,
             cancelButtonVariant: BUTTON_VARIANT.TEXT,
             confirmButtonVariant: BUTTON_VARIANT.CONTAINED,
             contextStyles,
             message: messageElement,
             onCancel,
             show: true,
             title,
        };
        return <SimpleModal { ...props } />;
    }
    return null;
});

ConfirmModal.propTypes = {
    uiStore: PropTypes.object,
    dialogId: PropTypes.string,
};

export default ConfirmModal;