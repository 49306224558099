import CryptoJS from 'crypto-js';
import { Log } from './Log';
export class CryptoUtil {
    encrypt(message, secretKey) {
        if (!message)
            return null;
        return CryptoJS.AES.encrypt(message, secretKey).toString();
    }
    decrypt(utf8CipherText, secretKey) {
        try {
            if (!utf8CipherText)
                return null;
            const bytes = CryptoJS.AES.decrypt(utf8CipherText, secretKey);
            return bytes.toString(CryptoJS.enc.Utf8);
        }
        catch (error) {
            Log.error(`Decryption failed with ${error.message}`);
            Log.error(error);
            return null;
        }
    }
}
export const cryptoUtil = new CryptoUtil();
