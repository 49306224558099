import BaseTheme from './BaseTheme';
import colors from './colors';
import themeComponent from './themedComponentFactory';
import themeFactory from './ThemeFactory';
import typography from './typography';
import { brandMappingConstant } from './brandMappingConstants';

export const themes = {
    BaseTheme,
    colors,
    themeComponent,
    themeFactory,
    typography,
    brandMappingConstant,
};
