/* @flow */
export default {
    progress: {
        title: 'Go Online',
        upperText: 'Synchronizing changes from offline.',
        lowerText: 'This may take some time.  A strong wifi connection is recommended.  Be aware this is a data intensive process.',
    },
    prompt: {
        title: 'Go Online',
        message: 'How would you would like to enter online mode?',
        confirm: 'Sync',
        discardChanges: 'Discard',
    },
    refreshSessionPrompt: {
        title: 'Session Expired',
        message: 'Your session has expired, please login again and try to synchronize changes.',
    },
};
