// Core component styles
const coreStyles = {
    container: {
        display: 'inline-flex',
    },

    badge: {
    },

    button: {
    },

    disabled: {
        opacity: 0.4,
    },

    icon: {
    },
};

/**
* Helper method to get the styles for Icon Button component
* @param {Object} contextStyles - Context styles for Icon Button Component
*/
const getStyles = (contextStyles = {}) => {
    return {
        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        badge: {
            ...coreStyles.badge,
            ...contextStyles.badge,
        },

        button: {
            ...coreStyles.button,
            ...contextStyles.button,
        },

        disabled: {
            ...coreStyles.disabled,
            ...contextStyles.disabled,
        },

        icon: {
            ...coreStyles.icon,
            ...contextStyles.icon,
        },
    };
};

export default getStyles;
