// Core component styles
const coreStyles = {
    container: {
    },

    disabled: {
    },

    icon: {
    },

    iconContainer: {
    },

    primary: {
        boxShadow: 'none',
        padding: '15px',
    },

    primaryHover: {
        boxShadow: 'none',
        opacity: 0.5,
    },

    primaryIcon: {
        color: '#fff',
    },

    primaryText: {
        color: '#fff',
        lineHeight: '16px',
    },

    secondary: {
        boxShadow: 'none',
        padding: '15px',
    },

    secondaryHover: {
        opacity: 0.5,
    },

    secondaryIcon: {
        color: '#006e96',
    },

    secondaryText: {
        color: '#006e96',
        lineHeight: '16px',
    },

    tertiary: {
        padding: '15px',
    },

    tertiaryHover: {
        backgroundColor: 'unset',
    },

    tertiaryIcon: {
    },

    tertiaryText: {
        lineHeight: '19px',
    },

    text: {
        cursor: 'pointer',
        textTransform: 'none',
        whiteSpace: 'nowrap',
    },

    textContainer: {
    },
};

/**
* Helper method to get the styles for Button component
* @param {Object} contextStyles - Context styles for the Button Component
*/
const getStyles = (contextStyles = {}) => {
    return {
        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        disabled: {
            ...coreStyles.disabled,
            ...contextStyles.disabled,
        },

        icon: {
            ...coreStyles.icon,
            ...contextStyles.icon,
        },

        iconContainer: {
            ...coreStyles.iconContainer,
            ...contextStyles.iconContainer,
        },

        primary: {
            ...coreStyles.primary,
            ...contextStyles.primary,

            '&:hover': {
                ...coreStyles.primary,
                ...contextStyles.primary,
                ...coreStyles.primaryHover,
                ...contextStyles.primaryHover,
            },
        },

        primaryIcon: {
            ...coreStyles.primaryIcon,
            ...contextStyles.primaryIcon,
        },

        primaryText: {
            ...coreStyles.primaryText,
            ...contextStyles.primaryText,
        },

        secondary: {
            ...coreStyles.secondary,
            ...contextStyles.secondary,

            '&:hover': {
                ...coreStyles.secondary,
                ...contextStyles.secondary,
                ...coreStyles.secondaryHover,
                ...contextStyles.secondaryHover,
            },
        },

        secondaryIcon: {
            ...coreStyles.secondaryIcon,
            ...contextStyles.secondaryIcon,
        },

        secondaryText: {
            ...coreStyles.secondaryText,
            ...contextStyles.secondaryText,
        },

        tertiary: {
            ...coreStyles.tertiary,
            ...contextStyles.tertiary,

            '&:hover': {
                ...coreStyles.tertiary,
                ...contextStyles.tertiary,
                ...coreStyles.tertiaryHover,
                ...contextStyles.tertiaryHover,
            },
        },

        tertiaryIcon: {
            ...coreStyles.tertiaryIcon,
            ...contextStyles.tertiaryIcon,
        },

        tertiaryText: {
            ...coreStyles.tertiaryText,
            ...contextStyles.tertiaryText,
        },

        text: {
            ...coreStyles.text,
            ...contextStyles.text,
        },
        textContainer: {
            ...coreStyles.textContainer,
            ...contextStyles.textContainer,
        },
    };
};

export default getStyles;
