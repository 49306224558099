import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import { engineUtils } from '../engine';
import engine from '../engine/engine';
import SaltEditor from '../engine/SaltEditor';

@observer
export default class SaltPage extends Component {
    static propTypes = {
        uiStore: PropTypes.object,
        dialogStore: PropTypes.object,
        onTransition: PropTypes.func,
        onError: PropTypes.func,
    };

    render() {
        const { dialogStore, uiStore, onTransition, onError } = this.props;
        const { saltDocument } = dialogStore;
        const params = {
            dialogStore,
            uiStore,
            onTransition,
            onError,
        };
        const salt = engineUtils.camelCase(saltDocument);
        return (
            <Fragment>
                <SaltEditor
                    rootDialogStore={ dialogStore }
                    uiStore={ uiStore }
                    onError={ onError } />
                { engine.render(salt, params) }
            </Fragment>
        );
    }
}
