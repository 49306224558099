/*       */
import FormUtil from '../FormUtil';
import AbstractControl from './AbstractControl';

export default class DateTimePicker extends AbstractControl {
    get useSimpleSalt() { return false; }
    newMaintenanceBoxStyleSet() {
        // In read mode, the property displays correctly.  In edit mode, the property sits too low
        // and needs to be raised up a bit.
        const result = super.newMaintenanceBoxStyleSet();

        // @TODO remove this
        // we can no longer access this because the server is doing the transpile.  however,
        // this shouldn't matter - we shouldn't need to know this as salt MUST be cross-platform
        // The client Salt implementations should make these adjustments, if there's a platform difference
        // isXNA() WILL CURRENTLY ALWAYS RETURN TRUE!
        if (FormUtil.isXNA()) return result;

        return this.newMaintenancePropertyStyleSet();
    }
    newMaintenancePropertyStyleSet() {
        // Remove the outline
        const result = super.newMaintenancePropertyStyleSet();
        result.xStyle.container = {
            borderWidth: 0,
            paperId: 'property',
        };
        result.xStyle.inputText = {
            borderWidth: 0,
            borderRadius: 0,
        };
        return result;
    }
}
