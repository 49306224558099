const loginBranding = {
    backgroundColor: 'loginBackgroundColor', // loginBackgroundColor pageBackgroundColor
    backgroundURL: 'loginBackgroundImage', // bannerLogo
    bottomLogoURL: 'appLogo',
    // fontColor: 'colors.fontDefault',
    // topLogoMaxUpScale: 400,
    // topLogoSize: 25,
    topLogoURL: 'appTopLogo', // use as applogo
    // bottomLogoMaxUpScale: 400,
    // bottomLogoPlacement: 'center',
};

const loggedInBranding = {
    backgroundColor: 'workbenchLauncherBackgroundColor', // loginBackgroundColor pageBackgroundColor
    backgroundURL: 'pageBackgroundImage', // bannerLogo
    bottomLogoURL: 'appLogo',
    // fontColor: 'colors.fontDefault',
    // topLogoMaxUpScale: 400,
    // topLogoSize: 25,
    topLogoURL: 'appTopLogo', // use as applogo
    // bottomLogoMaxUpScale: 400,
    // bottomLogoPlacement: 'center',
};

export const brandMappingConstant = {
    loginBranding,
    loggedInBranding,
}