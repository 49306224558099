import React from 'react';
import PropTypes from 'prop-types';
import SaltContext from './SaltContext';
import SaltComponent from './SaltComponent';
import dialogComponentFactory from '../provider/dialogComponentFactory';


export default class ViewRef extends SaltComponent {
    static propTypes = {
        id: PropTypes.string,
    };

    // load the View's salt document and render
    render() {
        const { id } = this.props;
        const { saltStore } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(id);
        return dialogStore ? this.buildViewComponent(dialogStore) : null;
    }

    buildViewComponent = (dialogStore) => {
        const { saltStore, onTransition, onError } = this.context;
        const { uiStore } = saltStore;
        const { dialog } = dialogStore;
        const PageComponent = dialogComponentFactory.getDialogPageComponent(dialog);
        const params = { dialogStore, uiStore, onTransition, onError };
        return React.createElement(PageComponent, params);
    }
}

ViewRef.contextType = SaltContext;
