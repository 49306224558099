export default {
    black: '#000000',
    white: '#ffffff',
    transparent: 'transparent',
    hexagonPallet7BlueDarkest: '#004E72',
    hexagonPallet7BlueDark: '#025D88',
    hexagonPallet7BlueLight: '#0370A3',
    hexagonPallet7BlueLightest: '#028CCD',
    hexagonPallet27GreyDarkest: '#6D6E71',
    hexagonPallet27GreyDark: '#808184',
    hexagonPallet27GreyLight: '#929497',
    hexagonPallet27GreyLightest: '#D0D2D3',
    hexagonPallet17GreenDarkest: '#4C8B2B',
    hexagonPallet17GreenDark: '#4E9D2D',
    hexagonPallet17GreenLight: '#77BC1F',
    hexagonPallet17GreenLightest: '#95D600',
    hexagonPallet25RedDarkest: '#AB2C29',
    hexagonPallet25RedDark: '#CC3524',
    hexagonPallet25RedLight: '#E24301',
    hexagonPallet25RedLightest: '#FF5B35',
    hexagonPallet22OrangeDarkest: '#D75F00',
    hexagonPallet22OrangeDark: '#F37521',
    hexagonPallet22OrangeLight: '#F7931E',
    hexagonPallet22OrangeLightest: '#FFA400',
    hexagonPallet19YellowDarkest: '#A8D300',
    hexagonPallet19YellowDark: '#C7DE00',
    hexagonPallet19YellowLight: '#E1EA00',
    hexagonPallet19YellowLightest: '#F2F338',
    hexagonPallet5BlueLightes: '#94C0E9',
};
