import React, { useRef, useState } from 'react';
import * as PropTypes from 'prop-types';

import { default as SignatureCanvas } from 'react-signature-canvas';
import { default as Prompt } from '../Prompt/Prompt';
import getStyles from './SignaturePrompt.styles';
import { default as Button, VARIANT } from '../Button/Button';

const SignaturePrompt = (props) => {
    const {
        acceptButtonText,
        canvasBackgroundColor,
        cancelButtonText,
        canvasHeight,
        canvasPenColor,
        canvasWidth,
        clearButtonText,
        contextStyles,
        onCancel,
        onSave,
    } = props;

    let canvasRef = useRef(null);
    const [
        disabled,
        setDisabled,
    ] = useState(true);

    const styles = getStyles(contextStyles);

    function handleSave() {
        if (onSave) {
            const dataUri = canvasRef.toDataURL();
            onSave(dataUri);
        }
    }

    function handleUndo() {
        canvasRef.clear();
        setDisabled(true);
    }

    return (
        <Prompt
            actions={ [
                <Button
                    contextStyles={ {
                        secondary: styles.cancelButton,
                        secondaryText: styles.cancelButtonText,
                    } }
                    key={ cancelButtonText }
                    onClick={ onCancel }
                    text={ cancelButtonText }
                    variant={ VARIANT.OUTLINED } />,
                <Button
                    contextStyles={ {
                        secondary: styles.undoButton,
                        secondaryText: styles.undoButtonText,
                    } }
                    key={ clearButtonText }
                    onClick={ handleUndo }
                    text={ clearButtonText }
                    variant={ VARIANT.OUTLINED } />,
                <Button
                    contextStyles={ {
                        primary: styles.actionButton,
                        primaryText: styles.actionButtonText,
                    } }
                    disabled={ disabled }
                    key={ acceptButtonText }
                    onClick={ handleSave }
                    text={ acceptButtonText }
                    variant={ VARIANT.CONTAINED } />,
            ] }
            contextStyles={ {
                actions: contextStyles.actions,
                content: contextStyles.content,
                divider: contextStyles.divider,
                header: contextStyles.header,
                modal: contextStyles.modal,
                overlay: contextStyles.overlay,
                title: contextStyles.title,
            } }>
            <SignatureCanvas
                backgroundColor={ canvasBackgroundColor }
                canvasProps={ {
                    className: 'sigCanvas',
                    height: canvasHeight,
                    width: canvasWidth,
                } }
                onBegin={ () => setDisabled(false) }
                penColor={ canvasPenColor }
                ref={ (ac) => {
                    canvasRef = ac;
                } } />
        </Prompt>
    );
};

SignaturePrompt.propTypes = {
    /** accept button helper button Text */
    acceptButtonText: PropTypes.string,

    /** Extended styles for this component */
    contextStyles: PropTypes.shape({

        /** Styles for Save Button */
        actionButton: PropTypes.object,

        /** Styles for Save Button Text */
        actionButtonText: PropTypes.object,

        /** Styles for prompt Actions */
        actions: PropTypes.object,

        /** Styles for cancel button */
        cancelButton: PropTypes.object,

        /** Styles for cancel button text */
        cancelButtonText: PropTypes.object,

        /** Styles for prompt content text */
        content: PropTypes.object,

        /** Styles for prompt devider text */
        divider: PropTypes.object,

        /** Styles for prompt header text */
        header: PropTypes.object,

        /** Styles for prompt container text */
        modal: PropTypes.object,

        /** Styles for the prompt overlay */
        overlay: PropTypes.object,

        /** Styles for the Undo button */
        undoButton: PropTypes.object,

        /** Styles for the undo button Text */
        undoButtonText: PropTypes.object,
    }),

    /** Styles for the prompt background color */
    canvasBackgroundColor: PropTypes.string,

    /** Cancel button Text */
    cancelButtonText: PropTypes.string,

    /** Signature pad height */
    canvasHeight: PropTypes.number,

    /** Signature pen color */
    canvasPenColor: PropTypes.number,

    /** Signature pad width */
    canvasWidth: PropTypes.number,

    /** Called on click of the cancel button */
    onCancel: PropTypes.func,

    /** Called on save of the save button */
    onSave: PropTypes.func,

    /** undo button prompt helper text */
    clearButtonText: PropTypes.string,
};

SignaturePrompt.defaultProps = {
    canvasBackgroundColor: '#fff',
    canvasHeight: 400,
    canvasPenColor: '#000',
    canvasWidth: 600,
};

export default SignaturePrompt;
