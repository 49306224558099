import { rootStore } from 'cv-react-core';

const getStyles = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();
    const textStyles = {
        lineHeight: '20px',
        ...theme.fonts.error,
    };
    return {
        closeIcon: {
            color: theme.buttonPrimaryBackgroundColor,
        },
        closeIconButton: {
            padding: '0px',
        },
        errorMessage: {
            ...textStyles,
        },
        errorStack: {
            ...textStyles,
        },
        errorTitle: {
            ...textStyles,
            fontSize: '18px',
            lineHeight: '26px',
            marginBottom: '20px',
        },
        infoIcon: {
            color: '#E96382',
            transform: 'rotate(45deg)',
        },
        error: {
            actions: {
                padding: '0px',
            },
            content: {
                textAlign: 'center',
                padding: '0px',
                paddingBottom: '20px',
            },
            divider: {
                display: 'none',
            },
            header: {
                padding: '0px',
            },
            modal: {
                borderRadius: '10px',
                boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.45)',
                boxSizing: 'border-box',
                padding: '20px',
                width: '500px',
            },
            title: {
            },
            text: {
                ...textStyles,
            },
        },
        titleContainer: {
            display: 'flex',
            flexDirection: 'row-reverse',
        },
    };
};

export default getStyles;
