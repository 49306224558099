import { SaltDocument } from '../SaltDocument';

const nfcTemplate = require('./nfc_simple');

class NFCTranspiler {
    generateSalt(dialog) {
        const document = new SaltDocument(nfcTemplate);
        const viewEl = document.getByExpr('**.view');
        document.singleSelectNode(viewEl);
        const { view } = dialog;
        const viewId = SaltDocument.getViewId(view);
        document.setAttribute('id', viewId);
        return document.rootNode;
    }
}

const nfcTranspiler = new NFCTranspiler();
export default nfcTranspiler;
