/**
 * A SaltBoxModel represents the parts needed to output a Salt Box.  They are bundled so they can
 * be passed around and tweaked before they are turned into json.
 */
export default class SaltBoxModel {
    children ;
    styleSet ;
    miscProps ;
    actionId ;
    constructor(styleSet, children, miscProps = {}) {
        this.styleSet = styleSet;
        this.children = children;
        this.miscProps = miscProps;
    }

    asSalt() {
        return {
            box: {
                ...this.miscProps, // May include scroll=horizontal/vertical
                ...this.scrubbedStyle(),
                ...this.styleSet.asXStyleAttribute(),
                ...this.styleSet.asContentContainerStyle(),
                children: this.resolveChildren(),
            },
        };
    }

    setActionId(actionId) {
        this.actionId = actionId;
    }

    resolveChildren() {
        const resolveChildrenPrivate = (inChildren, inResult) => {
            const result = inResult || [];
            inChildren.forEach(e => {
                if (e instanceof SaltBoxModel) {
                    result.push((e).asSalt());
                } else if (e instanceof Array) {
                    resolveChildrenPrivate(e, result);
                } else {
                    result.push(e);
                }
            });
            // If there is an action, add a representative child.
            if (this.actionId) {
                result.push(
                    {
                        action: {
                            id: this.actionId,
                            type: 'custom',
                            style: {
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0,
                            },
                        },
                    },
                );
            }
            return result;
        };
        return resolveChildrenPrivate(this.children);
    }

    scrubbedStyle() {
        let result = { ...this.styleSet.asStyleAttribute() };
        result = this.scrubForBorder(result.style);
        result.position = 'relative';
        return { style: result };
    }
    scrubForBorder(inStyle) {
        const result = inStyle;
        if (result.borderWidth) {
            // There is a border width, add a border style of solid if no border style exists.
            if (!result.borderStyle) {
                result.borderStyle = 'solid';
            }
        } else {
            // There is no border width, remove border color if present.
            if (result.borderColor) { // eslint-disable-line no-lonely-if
                delete result.borderColor;
            }
        }
        return result;
    }

    setScroller(myContext, scrollA) {
        this.miscProps.scroll = scrollA.value;
        this.styleSet.prepForScroll(myContext, scrollA);
    }
}
