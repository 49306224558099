import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { constants, loginController, serviceFactory, BuildGlobals } from 'cv-react-core';
import { Log, storage } from 'cv-dialog-sdk';
import Layout from '../../layouts/BasePage/BasePage';
import LoginForm from '../../base/LoginForm';
import oAuth, { OAuth } from '../../../services/oAuth';

import ChangePassword from './ChangePassword';
import PasswordExpiryPrompt from './PasswordExpiryPrompt';

import getStyles from './LoginPage.styles';
import TextLabel from '../../base/TextLabel';
import Image from '../../base/Image';

const {
    clientType,
    session,
    ui,
    settings,
} = constants;

const {
    PASSWORD,
    SAVE_PASSWORD,
    SHOW_PASSWORD,
    TENANT_ID,
    USER_ID,
} = session;
const {
    TENANT_CAPABILITIES,
} = settings;
const {
    APPLICATION_UI_ID,
    LOGIN_IN_PROGRESS,
} = ui;

// TODO: this components logic can be lifted to the LoginRoute component and most likely converted to a library functional component for improved performance
@observer
class LoginPage extends Component {
    static defaultProps = {
        contextStyles: {},
        sessionStore: null,
        onPostLogin: () => {},
    };

    static propTypes = {
        contextStyles: PropTypes.shape({
            container: PropTypes.object,
        }),
        sessionStore: PropTypes.object,
        settingsStore: PropTypes.object,
        themeStore: PropTypes.object,
        uiStore: PropTypes.object,
        onPostLogin: PropTypes.func,
        onSettings: PropTypes.func,
    };

    render() {
        const {
            contextStyles,
            sessionStore,
            uiStore,
            themeStore,
            settingsStore,
        } = this.props;
        const {
            changePasswordPrompt,
            credentials,
            passwordExpiryInXDays,
        } = sessionStore;
        const tenantCapabilities = settingsStore.getValue(TENANT_CAPABILITIES);
        const showSavePassword = tenantCapabilities ? tenantCapabilities.savePasswordAllowed : true;
        const oAuthEnabled = tenantCapabilities ? tenantCapabilities.oauthAuthorizationAvailable : false;
        // Since the LoginForm requires userid and password we have to set an empty string
        const userName = credentials.get(USER_ID) ? credentials.get(USER_ID) : '';
        const password = credentials.get(PASSWORD) ? credentials.get(PASSWORD) : '';
        const savePassword = credentials.get(SAVE_PASSWORD) ? credentials.get(SAVE_PASSWORD) : false;
        const tenantId = credentials.get(TENANT_ID) ? credentials.get(TENANT_ID) : '';

        const {
            getErrorsForUIObject,
            getValueForUIObject,
        } = uiStore;

        const styles = getStyles(contextStyles);
        const isLoggingIn = getValueForUIObject(APPLICATION_UI_ID, LOGIN_IN_PROGRESS);

        // Generate error object
        let err = null;
        if (getErrorsForUIObject(APPLICATION_UI_ID).length) {
            err = getErrorsForUIObject(APPLICATION_UI_ID)[0].msg;
        }
        const { lang } = serviceFactory;
        const { applicationInfo } = lang;
        const { appLogo } = themeStore;

        return (
            <Layout
                className="p-login-page__layout"
                contextStyles={ {
                    container: styles.anchorContainer,
                } }>
                <div
                    style={ {
                        ...styles.container,
                        visibility: isLoggingIn ? 'hidden' : 'visible',
                    } }>
                    <div style={ styles.loginFormContainer }>
                        { appLogo &&
                            <Image
                                contextStyles={ {
                                    container: styles.logoContainer,
                                    image: styles.logo,
                                    loadingIndicator: styles.imageLoadingIndicator,
                                } }
                                showLoadingIndicator
                                imageSrc={ appLogo } />
                        }
                        { changePasswordPrompt ?
                            <ChangePassword
                                sessionStore={ sessionStore }
                                passwordExpiryInXDays={ passwordExpiryInXDays }
                                uiStore={ uiStore }
                                settingsStore={ settingsStore } /> :
                            <LoginForm
                                contextStyles={ {
                                    textLabel: styles.textLabel,
                                    buttonSecondary: styles.buttonSecondary,
                                    buttonSecondaryText: styles.buttonSecondaryText,
                                    buttonText: styles.buttonText,
                                    buttonPrimary: styles.buttonPrimary,
                                    buttonPrimaryText: styles.buttonPrimaryText,
                                } }
                                errors={ err }
                                isLoggingIn={ isLoggingIn }
                                loginBtnText={ lang.login.cta }
                                onLoginPress={ this.handleLoginPress }
                                oAuthBtnText={ lang.login.oAuthBtnText }
                                oAuthEnabled={ oAuthEnabled }
                                onOAuthPress={ this.handleOnOAuthLogin }
                                onPasswordChange={ this.handlePasswordChange }
                                onSavePasswordChange={ this.handleSavePasswordChange }
                                onSettingsPress={ this.handleSettingsPress }
                                onShowPasswordChange={ this.handleShowPasswordChange }
                                onUserNameChange={ this.handleUserNameChange }
                                password={ password }
                                passwordLbl={ lang.login.passwordLbl }
                                savePassword={ !!savePassword }
                                savePasswordLbl={ lang.login.savePasswordLbl }
                                settingsBtnText={ lang.settings.title }
                                showPassword={ !!credentials.get(SHOW_PASSWORD) }
                                userName={ userName }
                                userNameLbl={ lang.login.userNameLbl }
                                showSavePassword={ showSavePassword } /> }
                    </div>
                    <div style={ styles.separator } />
                    <TextLabel contextStyles={ { container: { maxWidth: '342px' }, text: { ...styles.footerText, fontSize: '14px', whiteSpace: 'normal' } } }>
                        <a
                            style={ styles.footerNavigationLink }
                            href="https://xha.hexagonxalt.net/"
                            target="_blank"
                            rel="noopener noreferrer">
                            Previous Version
                        </a>
                    </TextLabel>
                    <TextLabel contextStyles={ { text: { ...styles.footerText, paddingBottom: '12px' } } }>
                        { `${applicationInfo.ClientReleaseVersion}: ${BuildGlobals.getClientReleaseVersion()}\n${applicationInfo.TenantId}: ${tenantId}` }
                    </TextLabel>
                </div>
                <PasswordExpiryPrompt
                    sessionStore={ sessionStore }
                    passwordExpiryInXDays={ passwordExpiryInXDays }
                    uiStore={ uiStore }
                    settingsStore={ settingsStore } />
            </Layout>
        );
    }

    componentDidMount = () => {
        const {
            uiStore,
        } = this.props;
        loginController.clearGloballyBusy(uiStore);

        // Check for the oAuth login
        const oauthToken = oAuth.extractPermissionToken();
        if (oauthToken) this.loginWithToken(oauthToken);
    };

    handleLoginPress = () => {
        const {
            sessionStore,
            settingsStore,
            uiStore,
            onPostLogin,
        } = this.props;
        loginController.handleLoginPress(sessionStore, settingsStore, uiStore, this.getDeviceProps(), onPostLogin, 'DESKTOP')
            .catch((err) => {
                Log.error('Failed to log in.');
                Log.error(err.message);
                Log.error(err.stack);
            });
    };

    /**
     * This method will handle the redirection to the oAuth site for login.
     * After oAuth login, it will redirect here with the oAuthToken.
     */
    handleOnOAuthLogin = async() => {
        const {
            sessionStore,
            settingsStore,
            uiStore,
        } = this.props;
        await loginController.handleOAuthPress(sessionStore, settingsStore, uiStore, this.getDeviceProps(), clientType.DESKTOP);
    };

    handlePasswordChange = (event) => {
        const { sessionStore, uiStore } = this.props;
        uiStore.clearErrorsForUIObject(constants.ui.APPLICATION_UI_ID);
        const { currentTarget } = event;
        const { value } = currentTarget;
        loginController.handlePasswordTextChange(sessionStore, value);
    };

    handleSavePasswordChange = (value) => {
        const { sessionStore, uiStore } = this.props;
        uiStore.clearErrorsForUIObject(constants.ui.APPLICATION_UI_ID);
        loginController.handleSavePasswordToggle(sessionStore, value);
    };

    handleSettingsPress = () => {
        const { onSettings, uiStore } = this.props;
        uiStore.clearErrorsForUIObject(constants.ui.APPLICATION_UI_ID);
        onSettings();
    }

    handleShowPasswordChange = (showPassword) => {
        const { sessionStore, uiStore } = this.props;
        uiStore.clearErrorsForUIObject(constants.ui.APPLICATION_UI_ID);
        loginController.handleShowPasswordToggle(sessionStore, showPassword);
    };

    handleUserNameChange = (event) => {
        const { sessionStore, uiStore } = this.props;
        uiStore.clearErrorsForUIObject(constants.ui.APPLICATION_UI_ID);
        const { currentTarget } = event;
        const { value } = currentTarget;
        loginController.handleUserNameTextChange(sessionStore, value);
    };

    getDeviceProps = () => {
        const { uiStore } = this.props;
        return serviceFactory.device.getDeviceProps(uiStore, serviceFactory.device.deviceSize);
    };

    /**
     * Attempt to login using the provided token using the login controller.
     * @param {string} permissionToken
     */
     loginWithToken = async(permissionToken) => {
        const {
            settingsStore,
            sessionStore,
            uiStore,
        } = this.props;
        const proofKey = await storage.getItem(OAuth.PROOF_KEY);
        loginController.handleLoginWithSessionToken(sessionStore, settingsStore, uiStore, this.getDeviceProps(), permissionToken, proofKey, clientType.DESKTOP)
            .then(() => storage.removeItem(OAuth.PROOF_KEY));
    };
}

export default LoginPage;
