/* @flow */
export const map = {
    DEFAULT_ROUTE_COLOR: '#F56262',
    DEFAULT_ROUTE_COLORS: [ '#F56262', '#7A33C1', '#00C9CC' ],
    DEFAULT_NUMBER_OF_ROUTES: 3,
    DEFAULT_LATITUDE_DELTA: 0.09220021650587285,
    DEFAULT_LONGITUDE_DELTA: 0.08525703102350235,
    MAX_REGION_VIEW: {
        latitude: 0,
        longitude: 0,
        latitudeDelta: 90,
        longitudeDelta: 180,
    },
};
