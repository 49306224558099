import React from 'react';
import PropTypes from 'prop-types';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import serviceFactory from '../services/serviceFactory';
import engineComponentFactory from './componentFactory';
import pageController from '../controllers/pageController';
import SaltContext from './SaltContext';
import Layout from './xStyle/Layout';
import SaltComponent from './SaltComponent';
import RefreshTimer from '../utilities/RefreshTimer';

@observer
export default class DetailsView extends SaltComponent {
    static propTypes = {
        id: PropTypes.string,
        children: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.arrayOf(PropTypes.element),
        ]),
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xStyle: PropTypes.oneOfType([
            Layout.layoutPropType,
            PropTypes.arrayOf(Layout.layoutPropType),
        ]),
        scroll: PropTypes.string,
        assert: PropTypes.shape({
            refName: PropTypes.string,
            expr: PropTypes.string,
        }),
    };

    render() {
        const { id } = this.props;
        const { saltStore } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(id);
        return dialogStore.record ? this.renderDetails() : this.renderLoading();
    }

    componentDidUpdate() {
        const { id } = this.props;
        const { saltStore, onError } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(id);

        if (dialogStore !== this.dialogStore) {
            // retain the reference for an identity comparison later
            this.dialogStore = dialogStore;
            this.createRefreshTimer(dialogStore, onError);
            this.registerAutorun(dialogStore, onError);
        }
    }

    componentDidMount() {
        const { id } = this.props;
        const { saltStore, onError } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(id);
        if (dialogStore !== this.dialogStore) {
            // retain the reference for an identity comparison later
            this.dialogStore = dialogStore;
            this.createRefreshTimer(dialogStore, onError);
            this.registerAutorun(dialogStore, onError);
        }
    }

    componentWillUnmount() {
        if (this.disposer) {
            this.disposer();
        }
        delete this.dialogStore;
        if (this.refreshTimer) this.refreshTimer.destroy();
    }

    renderDetails() {
        const { id, style, scroll, assert, xStyle, children, ...rest } = this.props;
        const component = engineComponentFactory.getAbstractComponent('box');
        const boxProps = { style, scroll, assert, xStyle, ...rest };
        return React.createElement(component, boxProps, children);
    }

    renderLoading() {
        const component = engineComponentFactory.getPlatformComponent('loading');
        const props = { loadingText: serviceFactory.lang.loadingStatus.pleaseWait };
        return React.createElement(component, props);
    }

    registerAutorun(dialogStore, onError) {
        if (this.disposer) {
            this.disposer();
        }
        this.disposer = autorun(() => {
            if (dialogStore.isRefreshNeeded) {
                const title = serviceFactory.lang.dialog.errors.errorReadingRecordTitle;
                pageController.performReadRecord({ dialogStore, title, onError });
            }

            // The dialogStore comparison is not enough to check when switching to write mode.
            // If we are updating to write mode we need to destroy the timer so we do not refresh
            // in the middle of someone updating content.
            const { dialog } = dialogStore;
            const { isWriteMode } = dialog;
            if (isWriteMode) {
                this.refreshTimer.destroy();
            } else {
                this.createRefreshTimer(dialogStore, onError);
            }
        });
    }

    createRefreshTimer(dialogStore, onError) {
        if (this.refreshTimer) this.refreshTimer.destroy();

        // Timer refresh in milliseconds is only available on the parent form dialog
        const { parentDialogStore } = dialogStore;
        this.refreshTimer = new RefreshTimer(parentDialogStore);
        const title = serviceFactory.lang.dialog.errors.errorReadingRecordTitle;
        this.refreshTimer.onRefresh = pageController.performReadRecord.bind(this, { dialogStore, title, onError });
        this.refreshTimer.start(dialogStore);
    }
}

DetailsView.contextType = SaltContext;
