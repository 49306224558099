
import { Catavolt } from 'cv-dialog-sdk';
import { action, computed, observable, toJS } from 'mobx';

export default class WorkbenchStore {
    constructor(sessionStore) {
        this.sessionStore = sessionStore;
    }

    /**
     * @type {Workbench}
     */
    @observable.ref workbench = null;

    /**
     * Used in conjunction with filtered actions to fiter down actions based on this string.
     * @type {String}
     */
    @observable filterBy = '';

    @action setWorkbench = (workbench, cacheData = true) => {
        this.workbench = workbench;

        if (cacheData) {
            this.sessionStore.cacheStore.setValueForCacheType(this.sessionStore.cacheStore.cacheStores.WORKBENCH, workbench);
        }
    };

    /**
     * @returns {ReadonlyArray<WorkbenchAction>}
     */
    @computed get actions() {
        return this.workbench.actions;
    }

    /**
     * @param workbenchId
     * @param actionId
     * @returns {Promise<Redirection>}
     */
    @action performWorkbenchAction = (workbenchId, actionId) => {
        return Catavolt.performWorkbenchActionForId(workbenchId, actionId);
    };

   /**
    * Provides a read-only array of filtered down workbenches.
    * observable filterBy is the string used for the filter.
    * @returns {ReadonlyArray<WorkbenchAction>}
    */
   @computed get filteredActions() {
        // If no filter just return the actions.
        if (this.filterBy.length === 0) return this.actions;

        // Return array of actions filtered by observable filterBy
        return this.workbench.actions.filter((workbench) => {
            return workbench.name.toLowerCase().indexOf(this.filterBy.toLowerCase()) > -1;
        });
    }

    /**
     * Set the filterBy value update the filteredActions array.
     * @param value
     */
    @action setFilter = (value = '') => {
        this.filterBy = value;
    }

    clearAll = () => {
        // Clean up observables
        this.setWorkbench(undefined, false);
        // Clean up local storage;
        this.sessionStore.cacheStore.clearCacheType(this.sessionStore.cacheStore.cacheStores.WORKBENCH);
    }

    getSanitizedWorkbench = () => {
        return toJS(this.workbench);
    }
}
