import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { XaltTextLabel } from 'xalt-react-atoms';

const propDefinitions = {
    style: PropTypes.object,
    value: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.string,
    ]),
    numberOfLines: PropTypes.number,
};

/**
 * A platform component for building a text label. Any platform specific implemenation for
 * this type of property should go here. If it can be shared cross platform, you need to evaluate
 * if it should go into the abstract implementation of TextLabel or determine if it is a property
 * attribute that all components could consume.
 */
class RWTextLabel extends PureComponent {
    static propTypes = propDefinitions;

    static defaultProps = {
        style: {},
    };

    render() {
        const {
            numberOfLines,
            value,
            style } = this.props;

        return (
            <XaltTextLabel
                numberOfLines={ numberOfLines }
                style={ style }>
                { value }
            </XaltTextLabel>
        );
    }
}

export default RWTextLabel;
