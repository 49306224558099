/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { XaltInputBase, XaltInputAdornment, XaltIconButton, XaltClear } from 'xalt-react-atoms';

/**
 * A platform component for building a DateTime field
 */
export default class RWDateTimeField extends Component {
    static propTypes = {
        style: PropTypes.object,
        value: PropTypes.string,
        isReadMode: PropTypes.bool,
        isFocused: PropTypes.bool,
        onValueChanged: PropTypes.func,
        xStyle: PropTypes.object,
        propertyRef: PropTypes.object,
        placeholder: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.textRef = props.propertyRef || React.createRef();
    }

    render() {
        const {
            style,
            value,
            isReadMode,
            isFocused,
            onValueChanged,
            placeholder,
            xStyle,
        } = this.props;
        const { input, clearIcon = {} } = xStyle || { input: {} };
        return (
            <XaltInputBase
                style={ { textOverflow: 'ellipsis', ...style, ...input } }
                endAdornment={ this.renderEndAdornment(isReadMode, clearIcon, value) }
                disabled={ isReadMode }
                placeholder={ placeholder }
                onValueChanged={ onValueChanged }
                value={ value }
                autoFocus={ isFocused }
                inputRef={ this.textRef } />
        );
    }

    renderEndAdornment(isReadOnly, iconStyle, value) {
        const { fontSize } = iconStyle;
        return (
            <XaltInputAdornment style={ { visibility: (isReadOnly || !value) ? 'hidden' : 'visible' } }>
                <XaltIconButton
                    onClick={ this.handleOnClearClick }
                    style={ iconStyle }>
                    <XaltClear
                        style={ { fontSize } } />
                </XaltIconButton>
            </XaltInputAdornment>
        );
    }

    handleOnClearClick = () => {
        const { onValueChanged } = this.props;
        this.textRef.current.focus();
        onValueChanged(null);
    }
}