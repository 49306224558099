import { TypeNames } from 'cv-dialog-sdk';
import { componentBuilderProvider, componentBuilderTypes } from './componentBuilderProvider';

// These action id text partials are already handled by the application in a custom manner
const HANDLED_ACTION_IDS = [ 'sendEmail:', 'openURL:', 'dialPhone:', 'barcodeScan:', 'uploadFile:', 'colorChooser:', 'selectAll:', 'selectNone:', 'formatXML:' ];

/**
 * @typedef {Object} DialogComponentBuilderFactoryParameters
 * @property {Object} viewDef
 * @property {Object} propDef
 * @property {Boolean} isReadMode
 * @property {Object} record
 * @property {Object} property
 */

/**
 * This factory determines the correct component builder to return
 * based on the passed parameters.
 * @param {DialogComponentBuilderFactoryParameters} dialogProps
 * @param {Object} contextStyles
 * @return {Object}
 */
const componentBuilderFactory = (dialogProps, contextStyles) => {
    // Get builders to be used
    const BarCodeIconComponentBuilder = componentBuilderProvider.getBuilder(componentBuilderTypes.BarCodeIcon);
    const BarChartPropComponentBuilder = componentBuilderProvider.getBuilder(componentBuilderTypes.BarChartProp);
    const BooleanComponentBuilder = componentBuilderProvider.getBuilder(componentBuilderTypes.Boolean);
    const ClearActionComponentBuilder = componentBuilderProvider.getBuilder(componentBuilderTypes.ClearAction);
    const ColorPickerComponentBuilder = componentBuilderProvider.getBuilder(componentBuilderTypes.ColorPicker);
    const CompositeComponentBuilder = componentBuilderProvider.getBuilder(componentBuilderTypes.Composite);
    const DateTimeComponentBuilder = componentBuilderProvider.getBuilder(componentBuilderTypes.DateTime);
    const DropDownComponentBuilder = componentBuilderProvider.getBuilder(componentBuilderTypes.DropDown);
    const EmailIconComponentBuilder = componentBuilderProvider.getBuilder(componentBuilderTypes.EmailIcon);
    const GaugeChartPropComponentBuilder = componentBuilderProvider.getBuilder(componentBuilderTypes.GaugeChartProp);
    const ImageComponentBuilder = componentBuilderProvider.getBuilder(componentBuilderTypes.Image);
    const ImageActionComponentBuilder = componentBuilderProvider.getBuilder(componentBuilderTypes.ImageAction);
    const PaperCheckboxComponentBuilder = componentBuilderProvider.getBuilder(componentBuilderTypes.PaperCheckbox);
    const PhoneIconComponentBuilder = componentBuilderProvider.getBuilder(componentBuilderTypes.PhoneIcon);
    const SelectActionComponentBuilder = componentBuilderProvider.getBuilder(componentBuilderTypes.Select);
    const TextFieldComponentBuilder = componentBuilderProvider.getBuilder(componentBuilderTypes.TextField);
    const TextLabelComponentBuilder = componentBuilderProvider.getBuilder(componentBuilderTypes.TextLabel);
    const TextWithImageComponentBuilder = componentBuilderProvider.getBuilder(componentBuilderTypes.TextWithImage);
    const WebIconComponentBuilder = componentBuilderProvider.getBuilder(componentBuilderTypes.WebIcon);
    const FilePickerInputComponentBuilder = componentBuilderProvider.getBuilder(componentBuilderTypes.FilePickerInput);

    // We do not have any way to identify these actions from property definitions. So, we defined below map.
    // If we can determine action from property definitions, we can define them in HANDLED_ACTION_IDS above.
    // Once the dialog server sends the new property (clientFunction that is coming from cloud server) to client, we can refactor the below code.
    const CLIENT_ACTION_IDS = {
        'setToEmpty:': ClearActionComponentBuilder,
    };

    // Spread params
    const {
        viewDef,
        viewType,
        propDef,
        property,
        record,
        isReadMode = true,
    } = dialogProps;
    const viewDefinition = viewDef || {};
    const propertyDefinition = propDef || {};
    const isTextWithImage = !!((property && property.imageName) || (record && record.imageName));
    const isImageWithURL = viewDefinition.displayMediaInline && propertyDefinition.isURLType;
    const isLargeImage = propertyDefinition.isLargePropertyType || isImageWithURL;
    const usePaperCheckbox = !!(contextStyles && contextStyles.paperCheckbox);
    const useBarChartProp = !!(contextStyles && contextStyles.barChartProp);
    const useGaugeChartProp = !!(contextStyles && contextStyles.gaugeChartProp);
    let componentBuilder = null;

    /**
     * EDIT MODE
     * The property is allowed to be updated (ie: writeAllowed || writeEnabled).
     */
    if (!isReadMode) {
        // Paper checkbox
        if (usePaperCheckbox) {
            componentBuilder = new PaperCheckboxComponentBuilder();
        }

        // Generate Drop Down component builder
        else if (viewDefinition.isDropDownEntryMethod || viewDefinition.isComboBoxEntryMethod || viewDefinition.isIconEntryMethod || (viewDefinition.isTextFieldEntryMethod && viewDefinition.autoFillCapable)) {
            componentBuilder = new CompositeComponentBuilder(new DropDownComponentBuilder(), new SelectActionComponentBuilder());
        }

        // Paper barChart prop (can only be specified via forms or gml)
        else if (useBarChartProp) {
            componentBuilder = new BarChartPropComponentBuilder();
        }

        // Paper gaugeChart prop (can only be specified via forms or gml)
        else if (useGaugeChartProp) {
            componentBuilder = new GaugeChartPropComponentBuilder();
        }

        // Generate Switch / Checkbox component builder
        else if (propertyDefinition.isBooleanType) {
            componentBuilder = new BooleanComponentBuilder();
        }

        // Generate Date / Time / DateTime component builder
        else if (propertyDefinition.isDateTimeType || propertyDefinition.isDateType || propertyDefinition.isTimeType) {
            componentBuilder = new DateTimeComponentBuilder();
        }

        // Generate Text Input with Bar Code Scan launcher component builder
        else if (propertyDefinition.isBarcodeType) {
            componentBuilder = new CompositeComponentBuilder(new TextFieldComponentBuilder(), new BarCodeIconComponentBuilder());
        }

        // Generate Text Input with Email launcher component builder
        else if (propertyDefinition.isEmailType) {
            componentBuilder = new CompositeComponentBuilder(new TextFieldComponentBuilder(), new EmailIconComponentBuilder());
        }

        // Generate Text Input with Phone launcher component builder
        else if (propertyDefinition.isTelephoneType) {
            componentBuilder = new CompositeComponentBuilder(new TextFieldComponentBuilder(), new PhoneIconComponentBuilder());
        }

        // Generate Text Input with URL launcher component builder
        else if (propertyDefinition.isURLType) {
            componentBuilder = new CompositeComponentBuilder(new TextFieldComponentBuilder(), new WebIconComponentBuilder());
        }

        // Generate Text Input with color picker component builder
        else if (propertyDefinition.isColorPickerType) {
            componentBuilder = new CompositeComponentBuilder(new TextFieldComponentBuilder(), new ColorPickerComponentBuilder());
        }

        // Generate Image builder
        else if (propertyDefinition.isLargePropertyType) {
            if (propertyDefinition.isFileMimeType) {
                componentBuilder = new FilePickerInputComponentBuilder();
            } else {
                componentBuilder = new ImageComponentBuilder();
            }
        }

        // Generate ObjectRef builder
        else if (propertyDefinition.isObjRefType) {
            componentBuilder = new TextLabelComponentBuilder();
        }

        // Default to Text Input component builder
        else {
            // Generate Text Input component builder
            componentBuilder = new TextFieldComponentBuilder();
        }
    }

    /**
     * READ-ONLY MODE
     * For display only as the property is not allowed to be updated.
     */
    else {
        // Default to text component
        componentBuilder = new TextLabelComponentBuilder();

        // Print markup shows the checkbox regardless of read/maintenance mode of view.
        if (usePaperCheckbox) { // eslint-disable-line no-lonely-if
            componentBuilder = new PaperCheckboxComponentBuilder();
        }

        // Paper barChart prop (can only be specified via forms or gml)
        else if (useBarChartProp) {
            componentBuilder = new BarChartPropComponentBuilder();
        }

        // Paper gaugeChart prop (can only be specified via forms or gml)
        else if (useGaugeChartProp) {
            componentBuilder = new GaugeChartPropComponentBuilder();
        }

        // For NON-LIST views
        if (viewType !== TypeNames.ListTypeName) {
            // Generate email text with email launcher icon component builder
            if (propertyDefinition.isEmailType) { // eslint-disable-line no-lonely-if
                componentBuilder = new CompositeComponentBuilder(new TextLabelComponentBuilder(), new EmailIconComponentBuilder());
            }

            // Generate phone number text with phone launcher icon component builder
            else if (propertyDefinition.isTelephoneType) {
                componentBuilder = new CompositeComponentBuilder(new TextLabelComponentBuilder(), new PhoneIconComponentBuilder());
            } else if (propertyDefinition.isURLType) {
                // Generate plain text URL with launcher icon
                componentBuilder = new CompositeComponentBuilder(new TextLabelComponentBuilder(), new WebIconComponentBuilder());
            }

            // Generate Text label with color picker component builder
            else if (propertyDefinition.isColorPickerType) {
                componentBuilder = new CompositeComponentBuilder(new TextLabelComponentBuilder(), new ColorPickerComponentBuilder());
            }
        }

        // For ALL views
        // Check for an image associated with any text
        // then generate a read-only text with image component builder
        if (isTextWithImage) {
            componentBuilder = new TextWithImageComponentBuilder();
        }

        // If the view is telling us to display this as inline media and it is the type of object that
        // can be displayed as inline media, use an image component builder to show the media as an image.
        // Down the road there may be other types of inline media (video?), but for now it's just image.
        else if (isLargeImage) {
            if (propertyDefinition.isURLType) {
                // Generate plain text URL with launcher icon
                componentBuilder = new CompositeComponentBuilder(new ImageComponentBuilder(), new WebIconComponentBuilder());
            } else {
                componentBuilder = new ImageComponentBuilder();
            }
        }
    }

    // If we have view actions available
    if (Array.isArray(viewDefinition.actions) && viewDefinition.actions.length) {
        // Create a builder for each action
        viewDefinition.actions.forEach((action) => {
            // Get action id to check against handled action ids
            const actionId = action.id.substring(0, action.id.indexOf(':') + 1);

            // Determine if the action is enabled in the current read/write mode
            // TODO: use action.modes.isRead/isWrite getters when SDK is updated
            const actionModes = action.modes;
            const isActionReadEnabled = actionModes.indexOf('READ') > -1 && isReadMode;
            const isActionWriteEnabled = actionModes.indexOf('WRITE') > -1 && !isReadMode;
            const isActionEnabled = isActionReadEnabled || isActionWriteEnabled || (isActionReadEnabled && isActionWriteEnabled);

            // If the action is enabled
            if (isActionEnabled) {
                // If action is identified as a client side action based on actionID instead of property definitions
                if (CLIENT_ACTION_IDS[actionId]) {
                    componentBuilder = new CompositeComponentBuilder(componentBuilder, new CLIENT_ACTION_IDS[actionId]());
                }

                // if action id is NOT already handled by the application
                else if (!HANDLED_ACTION_IDS.includes(actionId)) {
                    // Create builder for action
                    const imageActionComponentBuilder = new ImageActionComponentBuilder().setActionData(action);
                    componentBuilder = new CompositeComponentBuilder(componentBuilder, imageActionComponentBuilder);
                }
            }
        });
    }

    // Return component builder
    return componentBuilder;
};

export default componentBuilderFactory;
