/* eslint-disable class-methods-use-this */
/* eslint-disable putout/multiple-properties-destructuring */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { serviceFactory } from 'cv-react-core';
import { observer } from 'mobx-react';

import FilterSelector from './FilterSelector';

@observer
class FilterOperationComponent extends Component {
    static defaultProps = {
        // styles: {},
        onSetFilterOperation: () => {},
    }

    static propTypes = {
        propertyName: PropTypes.string.isRequired,
        onSetFilterOperation: PropTypes.func,
        searchDialogStore: PropTypes.object,
    }

    constructor(props) {
        super(props);
        this.handleOnOperationChange = this.handleOnOperationChange.bind(this);
        this.getAvailableValues = this.getAvailableValues.bind(this);
        this.handleOnLoadAvailableValues = this.handleOnLoadAvailableValues.bind(this);
    }

    render() {
        const dropDownProps = {
            ...this.props,
            ...this.getPropData(),
            onChange: this.handleOnOperationChange,
        };

        return React.createElement(FilterSelector, dropDownProps);
    }

    getPropData = () => {
        const { searchDialogStore, propertyName } = this.props;
        const filterOperationProperty = searchDialogStore.getSearchOpPropertyForName(propertyName);
        const { value: propertyOperationValue, name: propertyOperationName } = filterOperationProperty;
        const { lang } = serviceFactory;
        const availableValues = this.getAvailableValues();
        const items = this.formatItems(availableValues);
        return {
            key: `${propertyOperationName}_${propertyName}`,
            value: this.createItem(propertyOperationValue || undefined),
            items,
            onLoadAvailableValues: this.handleOnLoadAvailableValues,
            allSelectedText: lang.details.dropdown.allSelected,
            multipleSelectionsText: lang.details.dropdown.multipleSelections,
        };
    }

    formatItems(items) {
        if (items.length === 0) return [];
        return items.map((item) => this.createItem(item));
    }

    createItem(item) {
        if (!item) return {
            label: '',
            value: '',
        };
        return {
            label: item.description,
            value: item.code,
        };
    }

    getAvailableValues() {
        const { searchDialogStore, propertyName } = this.props;
        const filterOperationProperty = searchDialogStore.getSearchOpPropertyForName(propertyName);
        const { availableValuesStore } = searchDialogStore;
        const { name } = filterOperationProperty;
        return availableValuesStore.getAvailableValues(name);
    }

    handleOnLoadAvailableValues() {
        const { searchDialogStore, propertyName } = this.props;
        const filterOperationProperty = searchDialogStore.getSearchOpPropertyForName(propertyName);
        const { name } = filterOperationProperty;
        const { availableValuesStore } = searchDialogStore;
        const availableValues = availableValuesStore.getAvailableValues(name);
        if (availableValues.length > 0) return;
        searchDialogStore.updateAvailableValues(name);
    }

    /**
     * @todo This needs to be refactored and fixed in core so we can pass up simple props.
     */
    handleOnOperationChange(newValue) {
        // const { value } = propData;
        const { searchDialogStore, propertyName, onSetFilterOperation } = this.props;
        const filterOperationProperty = searchDialogStore.getSearchOpPropertyForName(propertyName);
        const { name } = filterOperationProperty;
        const { availableValuesStore } = searchDialogStore;
        const availableValues = availableValuesStore.getAvailableValues(name);
        const selection = availableValues.find((a) => a.code === newValue.value);
        if (onSetFilterOperation) onSetFilterOperation(propertyName, selection);
        // const { onSetFilterOperation, propertyName } = this.props;
        // if (onSetFilterOperation) onSetFilterOperation(propertyName, newValue.value);
    }
}

export default FilterOperationComponent;