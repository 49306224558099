
import { action, observable } from 'mobx';
import ListDialogStore from './ListDialogStore';

export default class GraphDialogStore extends ListDialogStore {
    /**
     * To maintian the charts filter selection
     */
    @observable chartFilteredItem = [];

    @action setChartFilteredItem(filteredItems) {
        this.chartFilteredItem = filteredItems;
    }

    getChartFilteredItem = () => {
        return this.chartFilteredItem;
    };
}
