import { rootStore } from 'cv-react-core';

const getTheme = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    // Populate the component parts with theme styles
    return {
        appointment: {
            fontFamily: theme.calendarFontFamily,
            backgroundColor: theme.appointmentBackgroundColor,
            color: theme.appointmentBackgroundColor,
        },
        appointmentContent: {
            fontFamily: theme.calendarFontFamily,
            color: theme.fonts.default.color,
        },
        container: {
            ...theme.fonts.default,
            fontFamily: theme.calendarFontFamily,
            backgroundColor: theme.calendarBackgroundColor,
            color: theme.calendarTextColor,
        },
        dayScaleCell: {
            ...theme.fonts.default,
            borderBottomColor: theme.calendarSeparatorColor,
            color: theme.calendarTextColor,
            fontFamily: theme.calendarFontFamily,
            fontSize: theme.calendarPickerFontSize,
            textAlign: 'right',
        },
        secondDatePickerCell: {
            color: theme.calendarPickerCellColor,
            fontFamily: theme.calendarFontFamily,
            fontSize: theme.calendarPickerFontSize,
            fontWeight: theme.calendarPickerFontWeight,
        },
        selectedDay: {
            fontFamily: theme.calendarFontFamily,
            backgroundColor: theme.calendarSelectedDayBackgroundColor,
            color: theme.calendarSelectedDayColor,
        },
        switcher: {
            ...theme.fonts.default,
            color: theme.calendarTextColor,
            fontFamily: theme.calendarFontFamily,
        },
        todayButton: {
            color: theme.calendarTextColor,
            fontFamily: theme.calendarFontFamily,
        },
        todayCell: {
            color: theme.calendarSelectedDayColor,
            backgroundColor: theme.calendarSelectedDayBackgroundColor,
        },
        timeTableCell: {
            color: theme.calendarTextColor,
            fontSize: '16px',
            textAlign: 'right',
        },
        weekDayCell: {
            borderBottomColor: theme.calendarSeparatorColor,
        },
    };
};

export default getTheme;
