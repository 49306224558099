import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { constants } from 'cv-react-core';

// [MVP-TODO] -> We have to drive this from Property so that, we can remove these unnecessary components.
import FilterValueTextComponent from './FilterValueTextComponent';
import FilterValueDateTimeComponent from './FilterValueDateTimeComponent';
import FilterValueToggleComponent from './FilterValueToggleComponent';
import FilterValueSelectorComponent from './FilterValueSelectorComponent';

const { searchSort } = constants;
const { FILTER_TYPES } = searchSort;

class FilterValueComponent extends Component {
    static defaultProps = {
        // styles: {},
        onSetFilterValue: () => {},
    }

    static propTypes = {
        propertyName: PropTypes.string.isRequired,
        onSetFilterValue: PropTypes.func,
        searchDialogStore: PropTypes.object,
    }

    render() {
        const {
            propertyName,
            searchDialogStore,
            onSetFilterValue } = this.props;
        const propertyType = this.getPropertyType(propertyName, searchDialogStore);

        const componentProps = {
            propertyName,
            searchDialogStore,
            onSetFilterValue,
            propertyType,
        };
        if (propertyType === FILTER_TYPES.BOOLEAN) {
                return React.createElement(FilterValueToggleComponent, { ...componentProps });
        }
        if (propertyType === FILTER_TYPES.DATE) {
            return React.createElement(FilterValueDateTimeComponent, { ...componentProps });
        }
        if (propertyType === FILTER_TYPES.DROPDOWN) {
            return React.createElement(FilterValueSelectorComponent, { ...componentProps });
        }

        return React.createElement(FilterValueTextComponent, { ...componentProps });
    };

    getPropertyType = (propertyName, searchDialogStore) => {
        const filterValueProperty = searchDialogStore.getSearchValuePropertyForName(propertyName);
        const { name } = filterValueProperty;
        const propertyValueDefinition = searchDialogStore.getSearchValuePropertyDefForName(propertyName);
        const { dialog } = searchDialogStore;
        const { view } = dialog;
        const { attributeCellsByPropName } = view;
        const propertyAttribute = attributeCellsByPropName[name];
        let filterType = FILTER_TYPES.STRING;

        if (propertyAttribute.isTextFieldEntryMethod) {
            if (propertyValueDefinition.isStringType) { filterType = FILTER_TYPES.STRING; }
            if (propertyValueDefinition.isTextBlockType) { filterType = FILTER_TYPES.STRING; }
            if (propertyValueDefinition.isBooleanType) { filterType = FILTER_TYPES.BOOLEAN; }
            if (propertyValueDefinition.isDateType) { filterType = FILTER_TYPES.DATE; }
            if (propertyValueDefinition.isTimeType) { filterType = FILTER_TYPES.DATE; }
            if (propertyValueDefinition.isDateTimeType) { filterType = FILTER_TYPES.DATE; }
            if (propertyValueDefinition.isNumericType) { filterType = FILTER_TYPES.INTEGER; }
            if (propertyValueDefinition.isIntType) { filterType = FILTER_TYPES.INTEGER; }
            if (propertyValueDefinition.isDecimalType) { filterType = FILTER_TYPES.DECIMAL; }
            if (propertyValueDefinition.isMoneyType) { filterType = FILTER_TYPES.DECIMAL; }
            if (propertyValueDefinition.isPercentType) { filterType = FILTER_TYPES.DECIMAL; }
        }
        if (propertyAttribute.isDropDownEntryMethod) filterType = FILTER_TYPES.DROPDOWN;

        return filterType;
    };
}

export default FilterValueComponent;