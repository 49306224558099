const getStyles = (contextStyles = {}) => {
    return {
        container: {
            display: 'flex',
            flexGrow: 1,
            height: '100%',
            width: '100%',
            boxSizing: 'border-box',
            borderBottom: '1px solid #D2D2D2',
            ...contextStyles.container,
        },
    };
};

export default getStyles;
