/*       */
import { constants } from 'cv-react-core';
import AbstractControl from './AbstractControl';

/*
A Signature is either based on a URL or a property.
 */
export default class Signature extends AbstractControl {
    get URL() { return this.valueAt('URL'); }

    get backgroundColorOverride() {
        return this.color('BackgroundColor') ? this.color('BackgroundColor') : super.backgroundColorOverride;
    }

    get lineColor() { return this.color('LineColor'); }

    get resizingMode() {
        // return this.valueAt('ResizingMode');
        return 'contain';
    }

    calcIconFontSize(bounding) {
        // Could increase this by a factor for large bounding values, but an icon that does
        // not fit snug in the box is OK also.
        return bounding;
    }

    newReadOnlyBoxStyleSet() {
        const result = super.newReadOnlyBoxStyleSet();
        result.style.justifyContent = 'center';
        return result;
    }

    newReadOnlyPropertyStyleSet() {
        const result = super.newReadOnlyPropertyStyleSet();
        result.xStyle.container = result.xStyle.container || { };
        result.xStyle.container.display = 'flex';
        result.xStyle.container.flexGrow = 1;
        result.xStyle.container.flexDirection = 'row';
        result.xStyle.container.justifyContent = 'center';
        result.xStyle.icon = result.xStyle.icon || {};
        result.xStyle.icon.fontSize = this.calcIconFontSize(Math.min(this.width, this.height));
        result.xStyle.icon.opacity = 0.2;

        result.xStyle.image = {
            'flex-direction': 'column',
            'flex-shrink': 1,
            overflow: 'hidden',
            resizeMode: this.resizingMode, // native
            objectFit: this.resizingMode, // web
            width: this.width,
            height: this.height,
        };

        result.xStyle.signatureStyle = {
            'flex-direction': 'column',
            'flex-shrink': 1,
            backgroundColor: this.backgroundColorOverride,
            lineColor: this.lineColor,
            overflow: 'hidden',
            resizeMode: this.resizingMode, // native
            objectFit: this.resizingMode, // web
            width: this.width,
            height: this.height,
        };

        result.xStyle.actionOverrides = {};
        // This option defaults to false and must be explicitly allowed.
        result.xStyle.actionOverrides[constants.imagePickerMenuIds.display] = 'true';
        result.xStyle.actionOverrides[constants.imagePickerMenuIds.signature] = 'true';
        return result;
    }

    newControlSalt(propertyStyleSet) {
        if (this.propertyName) {
            return super.newControlSalt(propertyStyleSet);
        }
        return this.newImageSalt(propertyStyleSet);
    }

    newImageSalt(propertyStyleSet) {
        // If there is no URL, display a one pixel transparent gif.
        // http://png-pixel.com/
        // Without this, the Button, which is a subclass of this, will not fire actions correctly.
        const onePixelImage = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';
        return {
            image: {
                source: this.URL || onePixelImage,
                ...propertyStyleSet.asStyleAttribute(),
                ...propertyStyleSet.asXStyleAttribute(),
            },
        };
    }
}
