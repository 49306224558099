/*       */


import { VIEW_DETAIL, VIEW_LIST } from '../../exportClassConstants';
import SimpleXStyleLayoutAttribute from '../SimpleXStyleLayoutAttribute';
import AbstractLayoutFactory from './AbstractLayoutFactory';

class ViewLayoutFactory extends AbstractLayoutFactory {
    constructor() {
        super();
        const attributesAndAliases = [
            'detailPadding',
            'listPadding',
        ];
        this.init(attributesAndAliases);
    }

    initFactory() {
        super.initFactory();
        const { factory } = this;
        factory.detailPadding = (json, key) => {
            return new SimpleXStyleLayoutAttribute('padding', this.getAsString(json, key), [ VIEW_DETAIL ]);
        };
        factory.listPadding = (json, key) => {
            return new SimpleXStyleLayoutAttribute('padding', this.getAsString(json, key), [ VIEW_LIST ]);
        };
    }
}

const viewLayoutFactory = new ViewLayoutFactory();
export default viewLayoutFactory;
