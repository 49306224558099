import { BaseComponentBuilder } from 'cv-react-core';

/**

* @deprecated This component is being deprecated after  component refactoring.

*/
class WebActionComponentBuilder extends BaseComponentBuilder {
    build = () => null;
}

export default WebActionComponentBuilder;