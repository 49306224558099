import React from 'react';
import * as PropTypes from 'prop-types';
import { uiHelper } from 'cv-react-core';

import Menu from '../Menu';
import MenuItem from '../MenuItem';
import IconButton from '../IconButton';

import getStyles from './ActionMenu.styles';

/**
 * An ActionMenu displays menu actions as a tool on a panel header.  Pressing the tool shows a menu, and selecting
 * a menu fires the onMenuAction callback.
 * Menu options are passed in as matching lists of labels and values.  If a label is not provided for a value, the
 * value will be used as the label.  If a value is not passed in for a label, it will not be presented.
 */
const ActionMenu = (props) => {
    // // Log.debug('ACTION MENU RENDER: ', props); // eslint-disable-line
    const {
        contextStyles,
        labels,
        onMenuAction,
        popContextMenu,
        testID,
        values,
    } = props;
    const menuItems = values.map((val, i) => {
        const text = (labels.length > i && labels[i]) ? labels[i] : val.toString();
        return {
            text,
            value: val,
        };
    });
    const styles = getStyles(contextStyles);

    uiHelper.assignMenuKeys(menuItems);

    return (
        <Menu
            button={
                <IconButton
                    contextStyles={ {
                        button: styles.button,
                        container: styles.container,
                        icon: styles.icon,
                        text: styles.text,
                    } }
                    iconName="more_vert"
                    title="More options"
                    iconSize={ 20 }
                    onClick={ () => null }
                    testID={ `${testID}__icon-button` } />
            }
            open={ popContextMenu }
            testID={ `${testID}__menu` }>
            { menuItems.map((item) => (
                <MenuItem
                    // eslint-disable-next-line no-unused-vars
                    onClick={ (nativeEvent) => {
                        if (onMenuAction) {
                            onMenuAction({
                                label: item.text,
                                id: item.value,
                            }, nativeEvent);
                        }
                    } }
                    key={ item.key }
                    value={ item }
                    testID={ `${testID}__menu-item` }
                    text={ item.text } />
            )) }
        </Menu>
    );
};

ActionMenu.propTypes = {
    contextStyles: PropTypes.object,
    labels: PropTypes.array,
    onMenuAction: PropTypes.func,
    popContextMenu: PropTypes.bool,

    /** Id used for testing */
    testID: PropTypes.string,
    values: PropTypes.array,
};

ActionMenu.defaultProps = {
    contextStyles: {},
    labels: [],
    popContextMenu: false,
    testID: 'ActionMenu',
    values: [],
};

export default ActionMenu;
