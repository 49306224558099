import SimpleStringAttribute from '../SimpleStringAttribute';

const VALUES = {
    BOLD: 'bold',
    ITALIC: 'italic',
    UNDERLINE: 'underline',
    ALIGN_LEFT: 'alignLeft',
    ALIGN_RIGHT: 'alignRight',
    ALIGN_CENTER: 'alignCenter',
    ALIGN_JUSTIFY: 'alignJustify',
    ALIGN_AUTO: 'alignAuto',
};

export default class PropStyle extends SimpleStringAttribute {
    constructor(
        name,
        value,
        exportClass,
        isCascading = false,
        overrideName,
    ) {
        super(name, value, exportClass, isCascading, overrideName);
        this.initStyles();
    }

    static newAttribute = (
        warnings,
        name,
        value,
        exportClass,
        isCascading = false,
        overrideName,
    ) => {
        value.split(',').map(m => m.trim()).forEach(e => {
            if (!Object.values(VALUES).includes(e)) {
                warnings.addInvalidValue(name, e);
            }
        });
        return new PropStyle(name, value, exportClass, isCascading, overrideName);
    }

    exportTo(context, styleSet) {
        const { style } = styleSet;
        if (SimpleStringAttribute.hasConstantRef(this.value)) {
            SimpleStringAttribute.reportExportIssue(this.name, this.value);
        }
        this.value.split(',').map(m => m.trim()).forEach(e => {
            const w = this.styles[e];
            if (w) {
                style[w.key] = w.value;
            }
        });
    }

    initStyles() {
        this.styles = {};
        const { styles } = this;
        const make = (key, value) => { return { key, value }; };
        styles[VALUES.BOLD] = make('font-weight', 'bold');
        styles[VALUES.ITALIC] = make('font-style', 'italic');
        styles[VALUES.UNDERLINE] = make('text-decoration-line', 'underline');
        styles[VALUES.ALIGN_LEFT] = make('text-align', 'left');
        styles[VALUES.ALIGN_RIGHT] = make('text-align', 'right');
        styles[VALUES.ALIGN_CENTER] = make('text-align', 'center');
        styles[VALUES.ALIGN_JUSTIFY] = make('text-align', 'justify');
        styles[VALUES.ALIGN_AUTO] = make('text-align', 'auto');
    }
}
