// Core component styles
const coreStyles = {
    container: {
        display: 'inline-flex',
        zIndex: 999999,
    },

    centered: {
        display: 'inline-flex',
        position: 'absolute',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        // zIndex: 999999,
    },

    indicator: {
    },
};

/**
* Helper method to get the styles for Activity Indicator component
* @param {Object} contextStyles - Context styles for Activity Indicator Component
*/
const getStyles = (contextStyles = {}) => {
    return {
        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        centered: {
            ...coreStyles.centered,
            ...contextStyles.centered,
        },

        indicator: {
            ...coreStyles.indicator,
            ...contextStyles.indicator,
        },
    };
};

export default getStyles;
