import { ActionParametersVisitor } from "./ActionParametersVisitor";
import { DialogProxyTools } from "./DialogProxyTools";
export class DialogRequest {
    constructor(baseUrl, resourcePath, body, queryParams, formData, fetchClientParams) {
        this._baseUrl = baseUrl;
        this._resourcePath = resourcePath;
        this._resourcePathElems = resourcePath.split('/');
        this._body = body;
        this._queryParams = queryParams;
        this._fetchClientParams = fetchClientParams;
    }
    static createFromDeleteRequest(baseUrl, resourcePath) {
        return new DialogRequest(baseUrl, resourcePath, null, null, null, [baseUrl, resourcePath]);
    }
    static createFromGetRequest(baseUrl, resourcePath, queryParams) {
        return new DialogRequest(baseUrl, resourcePath, null, queryParams, null, [baseUrl, resourcePath, queryParams]);
    }
    static createFromPostRequest(baseUrl, resourcePath, body) {
        return new DialogRequest(baseUrl, resourcePath, body, null, null, [baseUrl, resourcePath, body]);
    }
    static createFromPutRequest(baseUrl, resourcePath, body) {
        return new DialogRequest(baseUrl, resourcePath, body, null, null, [baseUrl, resourcePath, body]);
    }
    static createFromPostMultipartRequest(baseUrl, resourcePath, formData) {
        return new DialogRequest(baseUrl, resourcePath, null, null, formData, [baseUrl, resourcePath, formData]);
    }
    actionId() {
        return this._resourcePathElems[7];
    }
    baseUrl() {
        return this._baseUrl;
    }
    body() {
        return this._body;
    }
    isTenantCapabilitiesPath() {
        return (this._resourcePathElems.length === 4 &&
            this._resourcePathElems[0] === DialogRequest.TENANTS &&
            this._resourcePathElems[2] === DialogRequest.ClientType);
    }
    deconstructDeleteDialogPath() {
        return {
            tenantId: this._resourcePathElems[1],
            sessionId: this._resourcePathElems[3],
            dialogId: this._resourcePathElems[5]
        };
    }
    deconstructDeleteSessionPath() {
        return {
            tenantId: this._resourcePathElems[1],
            sessionId: this._resourcePathElems[3]
        };
    }
    deconstructGetDialogPath() {
        return {
            tenantId: this._resourcePathElems[1],
            sessionId: this._resourcePathElems[3],
            dialogId: this._resourcePathElems[5]
        };
    }
    deconstructGetRecordPath() {
        return {
            tenantId: this._resourcePathElems[1],
            sessionId: this._resourcePathElems[3],
            dialogId: this._resourcePathElems[5]
        };
    }
    deconstructGetRedirectionPath() {
        return {
            tenantId: this._resourcePathElems[1],
            sessionId: this._resourcePathElems[3],
            redirectionId: this._resourcePathElems[5]
        };
    }
    deconstructPostMenuActionPath() {
        return {
            tenantId: this._resourcePathElems[1],
            sessionId: this._resourcePathElems[3],
            dialogId: this._resourcePathElems[5],
            actionId: this._resourcePathElems[7]
        };
    }
    deconstructPostRecordsPath() {
        return {
            tenantId: this._resourcePathElems[1],
            sessionId: this._resourcePathElems[3],
            dialogId: this._resourcePathElems[5]
        };
    }
    deconstructPostSessionContentPath() {
        return {
            tenantId: this._resourcePathElems[1],
            sessionId: this._resourcePathElems[3],
            contentId: this._resourcePathElems[5]
        };
    }
    deconstructPostSessionsPath() {
        return {
            tenantId: this._resourcePathElems[1]
        };
    }
    deconstructPostWorkbenchActionPath() {
        return {
            tenantId: this._resourcePathElems[1],
            sessionId: this._resourcePathElems[3],
            workbenchId: this._resourcePathElems[5],
            actionId: this._resourcePathElems[7]
        };
    }
    deconstructPutViewModePath() {
        return {
            tenantId: this._resourcePathElems[1],
            sessionId: this._resourcePathElems[3],
            dialogId: this._resourcePathElems[5],
            viewMode: this._resourcePathElems[7]
        };
    }
    deconstructPutPropertyPath() {
        return {
            tenantId: this._resourcePathElems[1],
            sessionId: this._resourcePathElems[3],
            dialogId: this._resourcePathElems[5],
            propertyName: this._resourcePathElems[7]
        };
    }
    deconstructPutRecordPath() {
        return {
            tenantId: this._resourcePathElems[1],
            sessionId: this._resourcePathElems[3],
            dialogId: this._resourcePathElems[5]
        };
    }
    deconstructPostAvailableValuesPath() {
        return {
            tenantId: this._resourcePathElems[1],
            sessionId: this._resourcePathElems[3],
            dialogId: this._resourcePathElems[5],
            property: this._resourcePathElems[7]
        };
    }
    deconstructPostRecordsFlagPath() {
        return {
            tenantId: this._resourcePathElems[1],
            sessionId: this._resourcePathElems[3],
            dialogId: this._resourcePathElems[5],
            property: this._resourcePathElems[6]
        };
    }
    dialogId() {
        return this._resourcePathElems[5];
    }
    fetchClientParams() {
        return this._fetchClientParams;
    }
    isCreateSessionPath() {
        return this._resourcePathElems.length === 3 &&
            this._resourcePathElems[0] === DialogRequest.TENANTS &&
            this._resourcePathElems[2] === DialogRequest.SESSIONS;
    }
    isDeleteDialogPath() {
        return (this._resourcePathElems.length === 6 &&
            this._resourcePathElems[0] === DialogRequest.TENANTS &&
            this._resourcePathElems[2] === DialogRequest.SESSIONS &&
            this._resourcePathElems[4] === DialogRequest.DIALOGS);
    }
    isDeleteSessionPath() {
        return this._resourcePathElems.length === 4 &&
            this._resourcePathElems[0] === DialogRequest.TENANTS &&
            this._resourcePathElems[2] === DialogRequest.SESSIONS;
    }
    isGetAvailableViewsPath() {
        return (this._resourcePathElems.length === 7 &&
            this._resourcePathElems[0] === DialogRequest.TENANTS &&
            this._resourcePathElems[2] === DialogRequest.SESSIONS &&
            this._resourcePathElems[4] === DialogRequest.DIALOGS &&
            this._resourcePathElems[6] === DialogRequest.AVAILABLE_VIEWS);
    }
    isGetDialogPath() {
        return (this._resourcePathElems.length === 6 &&
            this._resourcePathElems[0] === DialogRequest.TENANTS &&
            this._resourcePathElems[2] === DialogRequest.SESSIONS &&
            this._resourcePathElems[4] === DialogRequest.DIALOGS);
    }
    isGetSampleDataPath() {
        return (this._resourcePathElems.length === 6 &&
            this._resourcePathElems[0] === DialogRequest.TENANTS &&
            this._resourcePathElems[2] === DialogRequest.SESSIONS &&
            this._resourcePathElems[4] === DialogRequest.SAMPLEDATA);
    }
    isGetDialogPathWithDialogId(dialogId) {
        if (!this.isGetDialogPath()) {
            return false;
        }
        const pathFields = this.deconstructGetDialogPath();
        return pathFields.dialogId === dialogId;
    }
    isGetRecordPath() {
        return (this._resourcePathElems.length === 7 &&
            this._resourcePathElems[0] === DialogRequest.TENANTS &&
            this._resourcePathElems[2] === DialogRequest.SESSIONS &&
            this._resourcePathElems[4] === DialogRequest.DIALOGS &&
            this._resourcePathElems[6] === DialogRequest.RECORD);
    }
    isGetRedirectionPath() {
        return (this._resourcePathElems.length === 6 &&
            this._resourcePathElems[0] === DialogRequest.TENANTS &&
            this._resourcePathElems[2] === DialogRequest.SESSIONS &&
            this._resourcePathElems[4] === DialogRequest.REDIRECTIONS);
    }
    isGetSelectedViewPath() {
        return (this._resourcePathElems.length === 7 &&
            this._resourcePathElems[0] === DialogRequest.TENANTS &&
            this._resourcePathElems[2] === DialogRequest.SESSIONS &&
            this._resourcePathElems[4] === DialogRequest.DIALOGS &&
            this._resourcePathElems[6] === DialogRequest.SELECTED_VIEW);
    }
    isGetSessionPath() {
        return this._resourcePathElems.length === 4 && this._resourcePathElems[0] === DialogRequest.TENANTS && this._resourcePathElems[2] === DialogRequest.SESSIONS;
    }
    isGetTenantPath() {
        return this._resourcePathElems.length === 2 && this._resourcePathElems[0] === DialogRequest.TENANTS;
    }
    isGetTenantsPath() {
        return this._resourcePathElems.length === 1 && this._resourcePathElems[0] === DialogRequest.TENANTS;
    }
    isGetViewModePath() {
        return (this._resourcePathElems.length === 7 &&
            this._resourcePathElems[0] === DialogRequest.TENANTS &&
            this._resourcePathElems[2] === DialogRequest.SESSIONS &&
            this._resourcePathElems[4] === DialogRequest.DIALOGS &&
            this._resourcePathElems[6] === DialogRequest.VIEW_MODE);
    }
    isGetWorkbenchesPath() {
        return (this._resourcePathElems.length === 5 &&
            this._resourcePathElems[0] === DialogRequest.TENANTS &&
            this._resourcePathElems[2] === DialogRequest.SESSIONS &&
            this._resourcePathElems[4] === DialogRequest.WORKBENCHES);
    }
    isPostAvailableValuesPath() {
        return (this._resourcePathElems.length === 9 &&
            this._resourcePathElems[0] === DialogRequest.TENANTS &&
            this._resourcePathElems[2] === DialogRequest.SESSIONS &&
            this._resourcePathElems[4] === DialogRequest.DIALOGS &&
            this._resourcePathElems[6] === DialogRequest.RECORD &&
            this._resourcePathElems[8] === DialogRequest.AVAILABLE_VALUES);
    }
    isPostRecordsFlagPath() {
        return (this._resourcePathElems.length === 8 &&
            this._resourcePathElems[0] === DialogRequest.TENANTS &&
            this._resourcePathElems[2] === DialogRequest.SESSIONS &&
            this._resourcePathElems[4] === DialogRequest.DIALOGS &&
            this._resourcePathElems[6] === DialogRequest.RECORDS &&
            this._resourcePathElems[7] === DialogRequest.LARGE_PROERTY_FLAG_VALUES);
    }
    isPostRecordFlagPath() {
        return (this._resourcePathElems.length === 8 &&
            this._resourcePathElems[0] === DialogRequest.TENANTS &&
            this._resourcePathElems[2] === DialogRequest.SESSIONS &&
            this._resourcePathElems[4] === DialogRequest.DIALOGS &&
            this._resourcePathElems[6] === DialogRequest.RECORD &&
            this._resourcePathElems[7] === DialogRequest.LARGE_PROERTY_FLAG_VALUES);
    }
    isPostRecordLargeProperty() {
        return (this._resourcePathElems.length === 8 &&
            this._resourcePathElems[0] === DialogRequest.TENANTS &&
            this._resourcePathElems[2] === DialogRequest.SESSIONS &&
            this._resourcePathElems[4] === DialogRequest.DIALOGS &&
            this._resourcePathElems[6] === DialogRequest.RECORD);
    }
    isPostMenuActionPath() {
        return (this._resourcePathElems.length === 8 &&
            this._resourcePathElems[0] === DialogRequest.TENANTS &&
            this._resourcePathElems[2] === DialogRequest.SESSIONS &&
            this._resourcePathElems[4] === DialogRequest.DIALOGS &&
            this._resourcePathElems[6] === DialogRequest.ACTIONS);
    }
    isPostMenuActionPathWithActionId(actionId) {
        if (!this.isPostMenuActionPath()) {
            return false;
        }
        const pathFields = this.deconstructPostMenuActionPath();
        return pathFields.actionId === actionId;
    }
    isPostMenuActionPathWithDialogIdAndActionId(dialogId, actionId) {
        if (!this.isPostMenuActionPath()) {
            return false;
        }
        const pathFields = this.deconstructPostMenuActionPath();
        return pathFields.dialogId === dialogId &&
            pathFields.actionId === actionId;
    }
    isPostMenuActionPathWithDialogId(dialogId) {
        if (!this.isPostMenuActionPath()) {
            return false;
        }
        const pathFields = this.deconstructPostMenuActionPath();
        return pathFields.dialogId === dialogId;
    }
    isPostRecordsPath() {
        return (this._resourcePathElems.length === 7 &&
            this._resourcePathElems[0] === DialogRequest.TENANTS &&
            this._resourcePathElems[2] === DialogRequest.SESSIONS &&
            this._resourcePathElems[4] === DialogRequest.DIALOGS &&
            this._resourcePathElems[6] === DialogRequest.RECORDS);
    }
    isPostRecordsPathWithDialogId(dialogId) {
        if (!this.isPostRecordsPath()) {
            return false;
        }
        const pathFields = this.deconstructPostRecordsPath();
        return pathFields.dialogId === dialogId;
    }
    isPostSessionPath() {
        return this._resourcePathElems.length === 3 &&
            this._resourcePathElems[0] === DialogRequest.TENANTS &&
            this._resourcePathElems[2] === DialogRequest.SESSIONS;
    }
    isPostSessionContentPath() {
        return (this._resourcePathElems.length === 6 &&
            this._resourcePathElems[0] === DialogRequest.TENANTS &&
            this._resourcePathElems[2] === DialogRequest.SESSIONS &&
            this._resourcePathElems[4] === DialogRequest.CONTENT);
    }
    isPostWorkbenchActionPath() {
        return (this._resourcePathElems.length === 8 &&
            this._resourcePathElems[0] === DialogRequest.TENANTS &&
            this._resourcePathElems[2] === DialogRequest.SESSIONS &&
            this._resourcePathElems[4] === DialogRequest.WORKBENCHES &&
            this._resourcePathElems[6] === DialogRequest.ACTIONS);
    }
    isPostWorkbenchActionPathWithActionId(actionId) {
        if (!this.isPostWorkbenchActionPath()) {
            return false;
        }
        const pathFields = this.deconstructPostWorkbenchActionPath();
        return pathFields.actionId === actionId;
    }
    isPutPropertyPath() {
        return (this._resourcePathElems.length === 8 &&
            this._resourcePathElems[0] === DialogRequest.TENANTS &&
            this._resourcePathElems[2] === DialogRequest.SESSIONS &&
            this._resourcePathElems[4] === DialogRequest.DIALOGS &&
            this._resourcePathElems[6] === DialogRequest.RECORD);
    }
    isPutRecordPath() {
        return (this._resourcePathElems.length === 7 &&
            this._resourcePathElems[0] === DialogRequest.TENANTS &&
            this._resourcePathElems[2] === DialogRequest.SESSIONS &&
            this._resourcePathElems[4] === DialogRequest.DIALOGS &&
            this._resourcePathElems[6] === DialogRequest.RECORD);
    }
    isPutSelectedViewPath() {
        return (this._resourcePathElems.length === 8 &&
            this._resourcePathElems[0] === DialogRequest.TENANTS &&
            this._resourcePathElems[2] === DialogRequest.SESSIONS &&
            this._resourcePathElems[4] === DialogRequest.DIALOGS &&
            this._resourcePathElems[6] === DialogRequest.SELECTED_VIEW);
    }
    isPutViewModePath() {
        return (this._resourcePathElems.length === 8 &&
            this._resourcePathElems[0] === DialogRequest.TENANTS &&
            this._resourcePathElems[2] === DialogRequest.SESSIONS &&
            this._resourcePathElems[4] === DialogRequest.DIALOGS &&
            this._resourcePathElems[6] === DialogRequest.VIEW_MODE);
    }
    resourcePath() {
        return this._resourcePath;
    }
    resourcePathElems() {
        return this._resourcePathElems;
    }
    sessionId() {
        return this._resourcePathElems[3];
    }
    targetId() {
        const targetIds = this.targetIds();
        if (targetIds) {
            return targetIds[0];
        }
        return null;
    }
    targetIds() {
        if (DialogProxyTools.isActionParametersModel(this.body())) {
            return ActionParametersVisitor.visitTargetsValue(this.body());
        }
        return null;
    }
    tenantId() {
        return this._resourcePathElems[1];
    }
}
DialogRequest.ACTIONS = 'actions';
DialogRequest.AVAILABLE_VALUES = 'availableValues';
DialogRequest.LARGE_PROERTY_FLAG_VALUES = 'flag';
DialogRequest.AVAILABLE_VIEWS = 'availableViews';
DialogRequest.CONTENT = 'content';
DialogRequest.DIALOGS = 'dialogs';
DialogRequest.SAMPLEDATA = 'sampleData';
DialogRequest.RECORD = 'record';
DialogRequest.RECORDS = 'records';
DialogRequest.REDIRECTIONS = 'redirections';
DialogRequest.SELECTED_VIEW = 'selectedView';
DialogRequest.SESSIONS = 'sessions';
DialogRequest.TENANTS = 'tenants';
DialogRequest.VIEW_MODE = 'viewMode';
DialogRequest.WORKBENCHES = 'workbenches';
DialogRequest.ClientType = 'clientType';
