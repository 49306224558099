/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import componentFactory from '../componentFactory';
import componentPropTypes from './abstractComponentPropTypes';
import serviceFactory from '../../services/serviceFactory';
import engineConstants from '../engineConstants';

const propDefinitions = {
    isFocused: componentPropTypes.isFocused,
    isReadMode: componentPropTypes.isReadMode,
    onValueChanged: componentPropTypes.onValueChanged,
    style: componentPropTypes.style,
    tipText: componentPropTypes.tipText,
    value: componentPropTypes.value,
    xStyle: componentPropTypes.xStyle,
    propertyRef: componentPropTypes.propertyRef,
};

export default class Checkbox extends Component {
    static propTypes = propDefinitions;
    static typeName = engineConstants.component.name.checkbox;

    render() {
        const {
            isFocused,
            isReadMode,
            onValueChanged,
            propertyRef,
            style,
            tipText,
            value,
            xStyle,
        } = this.props;
        const { lang: { generic: { yes } } } = serviceFactory;
        const { booleanTrueAlias } = xStyle;
        const checkboxProps = {
            isFocused,
            isReadMode,
            onValueChanged,
            propertyRef,
            style,
            tipText,
            value: isReadMode ? value === (booleanTrueAlias || yes) : value === 'true',
            xStyle,
        };
        return React.createElement(componentFactory.getPlatformPropertyComponent('checkbox'), { ...checkboxProps });
    }
}
