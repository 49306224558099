import { ArrayUtil } from '../util';
import { NullRecord } from './NullRecord';
import { RecordBuffer } from './RecordBuffer';
import { RecordImpl } from './RecordImpl';
import { TypeNames } from './types';
/**
 * Utility for working with Records
 */
export class RecordUtil {
    static newRecord(id, properties, annotations = [], sessionValues = {}) {
        return new RecordImpl(id, ArrayUtil.copy(properties), ArrayUtil.copy(annotations), TypeNames.RecordTypeName, sessionValues);
    }
    static isRecord(o) {
        return o instanceof RecordImpl || o instanceof RecordBuffer || o instanceof NullRecord;
    }
    static unionRight(l1, l2) {
        const result = ArrayUtil.copy(l1);
        l2.forEach((p2) => {
            if (!l1.some((p1, i) => {
                if (p1.name === p2.name) {
                    result[i] = p2;
                    return true;
                }
                return false;
            })) {
                result.push(p2);
            }
        });
        return result;
    }
}
