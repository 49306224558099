/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import componentFactory from '../componentFactory';
import componentPropTypes from './abstractComponentPropTypes';

export default class NumberField extends Component {
    static propTypes = componentPropTypes;

    render() {
        const { maxLength, value } = this.props;
        let regxPattern;
        if (maxLength && value) {
            // If we have any special character (+, - or .), increment maxLength by 1 so that actual digits will get the provided length.
            let incrementValue = maxLength;
            if (value.match(/[+-.]/)) {
                incrementValue += 1;
            }
            regxPattern = `^[0-9+-.]{0,${ incrementValue }}$`;
        }
        return React.createElement(componentFactory.getPlatformPropertyComponent('textField'), { ...this.props, type: 'number', regxValidationPattern: regxPattern });
    }
}
