/**
 * A Document is a global object that holds information about the GML.
 */
export default class Document {
    fillerSearchAt = {};
    plistFieldNames = {};
    finalizers = [];
    isDetailWithHeaderOrFooter = false;
    viewId ;
    addFinalizer(finalizer) {
        this.finalizers.push(finalizer);
    }
    resolveAll() {
        this.finalizers.forEach(e => (e.resolve()));
        this.finalizers = [];
    }
    setIsDetailWithHeaderOrFooter(v) { this.isDetailWithHeaderOrFooter = v; }
    setViewId(v) { this.viewId = v; }
}
