import React, { PureComponent, Fragment } from 'react';
import { observer } from 'mobx-react';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { constants, serviceFactory } from 'cv-react-core';

import Icon from '../base/Icon';
import IconButton from '../base/IconButton';
import Prompt from '../base/Prompt';
import TextLabel from '../base/TextLabel';
import routeNames from '../../routing/routeNames';
import getStyles from './styles/ErrorRouteModal.styles';

const { error: { errorTypes, MODAL_STATE }, ui } = constants;
const { MODAL_DIALOG_ID, MODAL_DIALOG_STORE_INFO } = ui;

const { hard, soft } = errorTypes;
const { lang } = serviceFactory;
const { WORKBENCH } = routeNames;

@observer
class ErrorRouteModal extends PureComponent {
    static propTypes = {
        objectId: PropTypes.string,
        uiStore: PropTypes.object,
        history: PropTypes.object,
        match: PropTypes.object,
    };

    render() {
        const { objectId, uiStore } = this.props;

        const {
            closeIcon,
            closeIconButton,
            infoIcon,
            error: errorStyles,
            errorMessage,
            errorTitle,
            titleContainer,
        } = getStyles();
        const modalState = uiStore.getValueForUIObject(objectId, MODAL_STATE);
        const {
            isModalOpen,
            errors,
            isCloseWindow,
        } = modalState || { isModalOpen: false, errors: [] };
        const errorList = this.getErrors(errors);
        const errorMessages = errorList && errorList.hard.length > 0 ? errorList.hard : errorList.soft;
        const title = (
            <div style={ titleContainer }>
                <IconButton
                    contextStyles={ {
                        button: closeIconButton,
                        icon: closeIcon,
                    } }
                    iconName="cancel_circle"
                    iconSize={ 32 }
                    onClick={ isCloseWindow ? this.handleCloseWindow : this.handleModalClose }
                    title={ isCloseWindow ? lang.generic.closeBrowserTab : lang.generic.close } />
            </div>
        );

        return (
            <Prompt
                contextStyles={ errorStyles }
                isModalOpen={ isModalOpen }
                isTransparentOverlay={ false }
                title={ title }>
                <Icon
                    contextStyles={ {
                        icon: infoIcon,
                    } }
                    iconName="do_not_disturb_on"
                    iconSize={ 42 }
                    title="Error" />
                { errorMessages.map((error, errorIndex) => (
                    /* eslint-disable react/no-array-index-key */
                    <Fragment key={ errorIndex }>
                        <TextLabel
                            contextStyles={ {
                                text: errorTitle,
                            } }>
                            { error.title }
                        </TextLabel>
                        { !error.err.children &&
                            <TextLabel
                                contextStyles={ {
                                    text: errorMessage,
                                } }>
                                { error.err.message }
                            </TextLabel>
                        }
                        { error.err.children && this.extractedNestedErrorMessages(error.err.children).map((childError, childIndex) => (
                            <TextLabel
                                key={ childIndex }
                                contextStyles={ {
                                    text: errorMessage,
                                } }>
                                { childError }
                            </TextLabel>
                        )) }
                    </Fragment>
                )) }
            </Prompt>
        );
    }

    extractRecursiveError = (childError, errMessages) => {
        if (Array.isArray(childError)) {
            childError.forEach((item) => {
                if (item.children) {
                    this.extractRecursiveError(item.children, errMessages);
                }
                else {
                    errMessages.push(item.message);
                };
            });
        }
    };

    extractedNestedErrorMessages = (nestedChildren) => {
        const errorList = [];
        this.extractRecursiveError(nestedChildren, errorList);
        return errorList;
    };

    isEmpty = (str) => str && !str.trim();

    getErrors = (errors) => {
        const errorList = {
            hard: [],
            soft: [],
        };

        if (!errors) return errorList;

        // Gather errors by type for display
        errors.forEach((error) => { /* eslint-disable no-param-reassign */
            const {
                type,
            } = error;
            if (type === soft) {
                if (this.isEmpty(error.title)) {
                    error.title = lang.errors.noMessageTitle;
                }
                if (error.err && this.isEmpty(error.err.message)) {
                    error.err.message = lang.errors.noMessageText;
                }
                if (error.err && this.isEmpty(error.err.stackTrace)) {
                    error.err.stackTrace = lang.errors.noStacktrace;
                }
                errorList.soft.push(error);
            }
            else if (type === hard) {
                errorList.hard.push(error);
            }
        });

        return errorList;
    };

    handleCloseWindow = () => {
        // Closing the browser tab since we dont have any history entry to goBack to prior dialog,
        // And this is most possibly expected when dialog is opened in new tab
        const { objectId, uiStore } = this.props;
        uiStore.clearErrorsForUIObject(objectId);
        if (window.close) window.close();
    }

    handleModalClose = () => {
        const {
            objectId,
            uiStore,
            match } = this.props;
        const modalState = uiStore.getValueForUIObject(objectId, MODAL_STATE);
        if (!modalState) return;
        const { errors } = modalState;
        const errorList = this.getErrors(errors);
        uiStore.clearErrorsForUIObject(objectId);
        uiStore.removeValueForUIObject(objectId, MODAL_STATE);

        const { params } = match;
        const { route } = params;
        if (errorList && errorList.hard.length > 0 && route !== WORKBENCH) {
            // hard errors need to 'go back' to page prior to error
            const { history } = this.props;
            uiStore.removeValueForUIObject(MODAL_DIALOG_ID, MODAL_DIALOG_STORE_INFO);
            if (history) history.goBack();
        }
    }
}

export default withRouter(ErrorRouteModal);
