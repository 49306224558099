import SimpleStringAttribute from '../SimpleStringAttribute';

const VALUES = {
    BOTH: 'both',
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical',
};

export default class Expand extends SimpleStringAttribute {
    static newAttribute = (
        warnings,
        name,
        value,
        exportClass,
        isCascading = false,
        overrideName,
    ) => {
        if (!Object.values(VALUES).find(f => f === value)) {
            warnings.addInvalidValue(name, value);
            return null;
        }
        return new Expand(name, value, exportClass, isCascading, overrideName);
    }

    exportTo(context, styleSet) {
        if (SimpleStringAttribute.hasConstantRef(this.value)) {
            SimpleStringAttribute.reportExportIssue('expand', this.value);
        }
        const { flexPolicy } = styleSet;
        const { parentContext } = context;
        if (parentContext) {
            if (parentContext.flexDirection.isRow()) {
                // The box flexDirection=horizontal
                if (this.isHorizontal() || this.isBoth()) {
                    flexPolicy.setStandardGrow(); // expand on main axis
                }
                if (this.isVertical() || this.isBoth()) {
                    flexPolicy.setAlignSelfStretch(); // expand on cross axis
                }
            } else {
                // The box flexDirection=vertical
                if (this.isVertical() || this.isBoth()) {
                    flexPolicy.setStandardGrow(); // expand on main axis
                }
                if (this.isHorizontal() || this.isBoth()) {
                    flexPolicy.setAlignSelfStretch(); // expand on cross axis
                }
            }
        }
    }

    isBoth = () => {
        return this.value.startsWith(VALUES.BOTH);
    }
    isHorizontal = () => {
        return this.value.startsWith(VALUES.HORIZONTAL);
    }
    isVertical = () => {
        return this.value.startsWith(VALUES.VERTICAL);
    }
}
