/* eslint-disable no-unused-vars */
import { rootStore } from 'cv-react-core';
/**
* Helper method to get the styles for Icon component
* @param {Object} contextStyles - Context styles for Icon Component
*/
const getStyles = (contextStyles = {}) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    return {
        pageWrapper: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            height: '50px',
            paddingTop: '12px',
            paddingBottom: '12px',
            paddingLeft: '10px',
            paddingRight: '24px',
        },
        pageSizingContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        pageNumberingContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        textLabel: {
            ...theme.fonts.default,
            fontSize: '14px',
            textAlign: 'center',
        },
        pagingContainer: {
            marginLeft: '12px',
        },
        primary: {
            backgroundColor: '#006E96',
            color: '#ffffff',
            padding: '0px',
            width: '26px',
            height: '26px',
            marginLeft: '5px',
            borderRadius: '4px',
        },
        primaryText: {
            color: '#ffffff',
        },
        secondary: {
            backgroundColor: '#F5F5F5',
            color: '#000000',
            padding: '0px',
            width: '26px',
            height: '26px',
            marginLeft: '5px',
            borderRadius: '4px',
        },
        pagerButton: {
            color: '#000000',
            padding: '0px',
            size: 'small',
            marginLeft: '5px',
            width: '20px',
            height: '20px',
            borderRadius: '4px',
        },
        ellipsis: {
            backgroundColor: '#ffffff',
            color: '#9E9E9E',
            padding: '0px',
            width: '26px',
            height: '26px',
            marginLeft: '5px',
            display: 'flex',
            justifyContent: 'center',
        },
        pagingText: {
            fontSize: '14px',
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
            textAlign: 'center',
            color: '#000000',
            lineHeight: '20px',
            marginRight: '4px',
        },
        icon: {
            backgroundColor: 'none',
            padding: '0px',
            width: '15px',
            height: '16px',
            fontSize: '16px',
            color: '#000000',
        },
        disabledIcon: {
            color: '#D3D3D3',
        },
    };
};

export default getStyles;
