
import loginControllerDefault from './loginController';
import pageControllerDefault from './pageController';
import workbenchControllerDefault from './workbenchController';
import searchControllerDefault from './searchController';

export const loginController = loginControllerDefault;
export const pageController = pageControllerDefault;
export const workbenchController = workbenchControllerDefault;
export const searchController = searchControllerDefault;
