import { DialogModeEnum, TypeNames } from './types';
export class ReferringObject {
    isDialogReferrer() {
        return this.type === TypeNames.ReferringDialogTypeName;
    }
    isWorkbenchReferrer() {
        return this.type === TypeNames.ReferringWorkbenchTypeName;
    }
    get sourceDestroyed() {
        if (this.isDialogReferrer()) {
            return this['dialogMode'] === DialogModeEnum.DESTROYED;
        }
        else {
            return false;
        }
    }
}
