// Core component styles
const coreStyles = {
    container: {
    },

    bar: {
        backgroundColor: '#006e96',
    },

    title: {
        color: '#fff',
        fontFamily: 'Roboto',
        fontSize: '18px',
    },

    titleContainer: {
    },

    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: '0px',
        paddingBottom: '0px',
        paddingLeft: '24px',
        paddingRight: '24px',
        boxSizing: 'border-box',
    },

    image: {
        widht: '40px',
        height: '40px',
    },

    imageLoadingIndicator: {},

    menuItem: {
    },

    menuButton: {
    },

    menuItemFocused: {
    },

    menuItemSelected: {
    },
};

/**
* Helper method to get the styles for Button component
* @param {Object} contextStyles - Context styles for the Button Component
*/
const getStyles = (contextStyles = {}) => {
    return {
        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        bar: {
            ...coreStyles.bar,
            ...contextStyles.bar,
        },

        title: {
            ...coreStyles.title,
            ...contextStyles.title,
        },

        titleContainer: {
            ...coreStyles.titleContainer,
            ...contextStyles.titleContainer,
        },

        toolbar: {
            ...coreStyles.toolbar,
            ...contextStyles.toolbar,
        },

        image: {
            ...coreStyles.image,
            ...contextStyles.image,
        },

        imageLoadingIndicator: {
            ...coreStyles.imageLoadingIndicator,
            ...contextStyles.imageLoadingIndicator,
        },

        menuButton: {
            ...coreStyles.menuButton,
            ...contextStyles.menuButton,
        },

        menuItem: {
            ...coreStyles.menuItem,
            ...contextStyles.menuItem,
        },

        menuIcon: {
            ...contextStyles.menuIcon,
        },

        menuItemFocused: {
            ...coreStyles.menuItemFocused,
            ...contextStyles.menuItemFocused,
        },

        menuItemSelected: {
            ...coreStyles.menuItemSelected,
            ...contextStyles.menuItemSelected,
        },
    };
};

export default getStyles;
