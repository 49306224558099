/* eslint-disable putout/multiple-properties-destructuring */
import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import getStyles from './CvScrollView.styles';

export const propTypes = {
    contextStyles: PropTypes.shape({
        container: PropTypes.object,
        scrollContentWrapper: PropTypes.object,
    }),
    loadingDataIndicator: PropTypes.element,
    isLoading: PropTypes.bool,
    /** Function fired when you reach the bottom of the scroll view. */
    onRequestMore: PropTypes.func,
    /** child items that needs to be displayed inside the CvScrollView */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element,
    ]),
    showVerticalScrollBar: PropTypes.bool,
    showHorizontalScrollBar: PropTypes.bool,
    onScrollPositionSet: PropTypes.func,
    scrollToPosition: PropTypes.bool,
    scrollPosition: PropTypes.object,
};

const MathFloorLimit = 100000;
const GetRandomKey = (floorLimit) => Math.floor(Math.random() * Math.floor(floorLimit));
/**
 * This class will provide scrollable content that tells you when you reach the bottom of content.
 */
class CvScrollView extends Component {
    static propTypes = propTypes;

    static defaultProps = {
        onRequestMore: () => {},
        onScrollPositionSet: () => {},
        isLoading: false,
        scrollToPosition: false,
    };

    constructor(props) {
        super(props);
        this.handleOnReachBottom = this.handleOnReachBottom.bind(this);
        this.handleOnScroll = this.handleOnScroll.bind(this);
        this.scrollContainerToPosition = this.scrollContainerToPosition.bind(this);
        this.innerRef = React.createRef();
        this.scrollKey = GetRandomKey(MathFloorLimit);
        this.initialFocusChecked = false;
        this.scrollPosition = {
            top: 0,
            left: 0,
        };
    }

    render() {
        const {
            children, isLoading, loadingDataIndicator, contextStyles,
            showHorizontalScrollBar, showVerticalScrollBar } = this.props;

        const styles = getStyles(contextStyles, showHorizontalScrollBar, showVerticalScrollBar);

        return (
            <div
                className="cv-scroll-container"
                style={ {...styles.container} }>
                <div
                    className="cv-scroll-row"
                    style={ {...styles.scrollRow} }>
                    <div
                        key={ this.scrollKey }
                        className="cv-scroll-column"
                        ref={ this.innerRef }
                        onScroll={ this.handleOnScroll }
                        style={ {...styles.scrollColumn} }>
                        { children }
                    </div>
                </div>
                <div
                    className="cv-activity-row"
                    style={ {...styles.activityRow} }>
                    <div
                        className="cv-activity-column"
                        style={ {...styles.activityColumn} }>
                        { isLoading && loadingDataIndicator }
                    </div>
                </div>
            </div>
        );
    }

    componentDidUpdate(prevProps) {
        const { scrollToPosition, scrollPosition } = this.props;
        const { scrollPosition: prevScrollPosition, scrollToPosition: prevScrollToPosition } = prevProps;

        if ((scrollToPosition !== prevScrollToPosition || scrollPosition !== prevScrollPosition) && scrollToPosition && scrollPosition) {
            this.scrollContainerToPosition(scrollPosition);
        }
        if ((this.scrollColumn.clientHeight === this.scrollColumn.scrollHeight) && this.scrollColumn.clientHeight > 0 && !this.initialFocusChecked) {
            this.initialFocusChecked = true;
            this.handleOnReachBottom();
        }
    }

    componentDidMount() {
        const { scrollToPosition, scrollPosition } = this.props;
        if (scrollToPosition && scrollPosition) {
            this.scrollContainerToPosition(scrollPosition);
        }

        // For now, want to check and see if we have enough content to make the scrolls visible.
        // If we do not, we will fire the handler. If more checks are needed we can move this into
        // the update routine as well.
        if (this.scrollColumn.clientHeight === this.scrollColumn.scrollHeight) {
            this.handleOnReachBottom();
        }
    }

    /** Event Handlers */

    /**
     * Handler for reaching the bottom of the scroll view.
     * @param {Object} options ScrollView options
     */
    handleOnReachBottom() {
        const { onRequestMore } = this.props;
        onRequestMore();
    }

    /**
     * Handler for scroll actions.
     * @todo We need to watch this method as it can be very chatty. Need to monitor and make
     * sure we don't need to throtle the updates for smooth scroll transition.
     * @param {object} event Event data.
     */
    handleOnScroll(event) {
        const { target } = event;
        const { scrollHeight, scrollTop, clientHeight, scrollLeft } = target;

        const scrolledPercentage = ((clientHeight + scrollTop) * 100) / scrollHeight;
        // Add a small padding just for very small sizes
        if (scrollHeight > 50 && scrolledPercentage >= 85) {
            this.handleOnReachBottom();
        }

        this.scrollPosition.left = scrollLeft;
        this.scrollPosition.top = scrollTop;
    }

    /** ************************** END Event Handlers************************************* */

    /** Properties */
    get scrollTop() {
        return this.scrollPosition && this.scrollPosition.top || 0;
    }

    get scrollLeft() {
        return this.scrollPosition && this.scrollPosition.left || 0;
    }

    get scrollColumn() {
        if (this.innerRef && this.innerRef.current) {
            return this.innerRef.current;
        }
        return null;
    }
    /** ************************** END Properties ************************************* */

    /** Methods */
    scrollContainerToPosition({top = 0, left = 0}) {
        if (this.scrollColumn) {
            this.scrollColumn.scrollTop = top;
            this.scrollColumn.scrollLeft = left;
            const { onScrollPositionSet } = this.props;
            onScrollPositionSet();
        }
    }
    /** ************************** END Methods************************************* */
}

export default CvScrollView;
