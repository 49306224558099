import { rootStore } from 'cv-react-core';

const coreStyles = {
    anchorContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
    },

    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.75)',
        backdropFilter: 'blur(80px)',
        borderRadius: '10px',
        marginBottom: '20px',
        marginTop: '20px',
        overflow: 'hidden',
    },

    footerText: {
        color: '#000000',
        fontFamily: 'Roboto',
        fontSize: '12px',
        lineHeight: '15px',
        padding: '12px',
        textAlign: 'center',
        whiteSpace: 'pre',
        paddingBottom: '0px',
    },

    footerNavigationLink: {
        color: '#006E96',
        fontWeight: 800,
        paddingLeft: '4px',
        fontSize: '15px',
        fontStyle: 'italic',
    },

    imageLoadingIndicator: {},

    loginFormContainer: {
        padding: '48px',
        paddingBottom: '20px',
        maxWidth: '342px',
    },

    logo: {
        width: '100%',
        height: '100%',
        maxHeight: '110px',
    },

    logoContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: '0px 40px',
        marginBottom: '40px',
    },

    separator: {
        backgroundColor: '#E0E0E0',
        height: '1px',
        width: '100%',
    },

    buttonSecondary: {
        borderColor: '#006e96',
    },

    buttonSecondaryText: {
        color: '#006e96',
        fontFamily: 'roboto',
        marginLeft: '5px',
    },

    buttonText: {
        fontSize: '14px',
        letterSpacing: '0.5px',
    },

    buttonPrimary: {
        backgroundColor: '#006e96',
    },

    buttonPrimaryText: {
        fontFamily: 'Roboto',
    },

};

const getStyles = (contextStyles) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();
    const backgroundImageStyles = theme.loginBackgroundImage ? {
        backgroundImage: `url(${theme.loginBackgroundImage})`,
    } : {
        backgroundImage: 'radial-gradient(50% 50% at 50% 50%, rgba(99, 35, 255, 0.2) 0%, rgba(99, 35, 255, 0) 100%), radial-gradient(50% 50% at 50% 50%, rgba(28, 142, 169, 0.2) 0%, rgba(28, 142, 169, 0) 100%)',
        backgroundPosition: '60% -117px, 50% 543px',
        backgroundSize: '597px 597px, 597px 597px',
    };

    return {
        anchorContainer: {
            ...coreStyles.anchorContainer,
            backgroundColor: theme.loginBackgroundColor,
            ...backgroundImageStyles,
            ...contextStyles.anchorContainer,
        },

        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        footerText: {
            ...coreStyles.footerText,
            ...contextStyles.footerText,
        },

        footerNavigationLink: {
            ...coreStyles.footerNavigationLink,
            ...contextStyles.footerNavigationLink,
        },

        imageLoadingIndicator: {
            ...coreStyles.imageLoadingIndicator,
            ...contextStyles.imageLoadingIndicator,
        },

        loginFormContainer: {
            ...coreStyles.loginFormContainer,
            ...contextStyles.loginFormContainer,
        },

        logo: {
            ...coreStyles.logo,
            ...contextStyles.logo,
        },

        logoContainer: {
            ...coreStyles.logoContainer,
            ...contextStyles.logoContainer,
        },

        separator: {
            ...coreStyles.separator,
            ...contextStyles.separator,
        },

        textLabel: {
            paddingLeft: '10px',
        },

        buttonSecondary: {
            ...coreStyles.buttonSecondary,
        },

        buttonSecondaryText: {
            ...coreStyles.buttonSecondaryText,
        },

        buttonText: {
            ...coreStyles.buttonText,
        },

        buttonPrimaryText: {
            ...coreStyles.buttonPrimaryText,
        },

        buttonPrimary: {
            ...coreStyles.buttonPrimary,
        },
    };
};

export default getStyles;
