import React from 'react';
import * as PropTypes from 'prop-types';
import ButtonBase, { VARIANT } from '../ButtonBase/ButtonBase';
import Icon, { ICON_SIZE } from '../Icon/Icon';
import TextLabel from '../TextLabel/TextLabel';

import getStyles from './Button.styles';

const {
    CONTAINED,
    OUTLINED,
    TEXT,
} = VARIANT;
const {
    DEFAULT,
    INHERIT,
    LARGE,
    SMALL,
} = ICON_SIZE;

/**
 * Button component: A control is that shows a user a button with a text or icon representation of an action to be performed and allows the user to select the button to run the action.
 * The control is capable of displaying an activity indicator to indicate that the performed action is in progress.
 * @see https://material-ui.com/demos/buttons/
 * @see https://material-ui.com/api/button/
 * @see https://material-ui.com/api/button-base/
 */
const Button = (props) => {
    const {
        contextStyles,
        disabled,
        iconName,
        iconSize,
        onClick,
        reverse,
        testID,
        text,
        type,
        variant,
    } = props;

    // Override core styles with context styles
    const styles = getStyles(contextStyles);

    /** Renders the Text */
    const renderText = () => {
        const textProps = {
            className: 'c-button__text',
            contextStyles: {
                container: styles.textContainer,
                text: {
                    ...styles.text,
                    ...(variant === CONTAINED && styles.primaryText),
                    ...(variant === OUTLINED && styles.secondaryText),
                    ...(variant === TEXT && styles.tertiaryText),
                },
            },
        };
        if (testID) { textProps.testID = `${testID}__button__text`; }

        return (
            <TextLabel { ...textProps }>
                { text }
            </TextLabel>
        );
    };

    /** Renders the Icon */
    const renderIcon = () => {
        const iconProps = {
            className: 'c-button__icon',
            contextStyles: {
                container: styles.iconContainer,
                icon: {
                    ...styles.icon,
                    ...(variant === CONTAINED && styles.primaryIcon),
                    ...(variant === OUTLINED && styles.secondaryIcon),
                    ...(variant === TEXT && styles.tertiaryIcon),
                },
            },
            iconName,
            iconSize,
        };
        if (testID) { iconProps.testID = `${testID}__button__icon`; }

        return (
            <Icon { ...iconProps } />
        );
    };

    // Generate button props
    const buttonProps = {
        className: 'c-button__container',
        contextStyles: {
            container: styles.container,
            disabled: styles.disabled,
            primary: {
                ...(variant === CONTAINED && styles.primary),
            },
            secondary: {
                ...(variant === OUTLINED && styles.secondary),
            },
            tertiary: {
                ...(variant === TEXT && styles.tertiary),
            },
        },
        onClick: (event) => {
            onClick(event, props);
        },
        type,
        variant,
    };
    if (disabled) { buttonProps.disabled = true; }
    if (testID) { buttonProps.testID = `${testID}__button__container`; }

    /** If text or icon are not provided, the container is also not rendered. */
    if (text || iconName) {
        return (
            <ButtonBase { ...buttonProps }>
                { (!!iconName && !reverse) && renderIcon() }
                { !!text && renderText() }
                { (!!iconName && reverse) && renderIcon() }
            </ButtonBase>
        );
    }

    return null;
};

Button.propTypes = {
    /** Styles for this component */
    contextStyles: PropTypes.shape({
        /** Styling for the main button container */
        container: PropTypes.object,

        /** Styles for icon */
        icon: PropTypes.object,

        /** Styles for the container around the icon */
        iconContainer: PropTypes.object,

        /** Styles for a primary 'contained' button variant */
        primary: PropTypes.object,

        /** Styles for a primary 'contained' button variant icon */
        primaryIcon: PropTypes.object,

        /** Styles for a primary 'contained' button variant text */
        primaryText: PropTypes.object,

        /** Styles for a secondary 'outlined' button variant */
        secondary: PropTypes.object,

        /** Styles for a secondary 'outlined' button variant icon */
        secondaryIcon: PropTypes.object,

        /** Styles for a secondary 'outlined' button variant text */
        secondaryText: PropTypes.object,

        /** Styles for a tertiary 'text' button variant */
        tertiary: PropTypes.object,

        /** Styles for a tertiary 'text' button variant icon */
        tertiaryIcon: PropTypes.object,

        /** Styles for a tertiary 'text' button variant text */
        tertiaryText: PropTypes.object,

        /** Styles for the button text */
        text: PropTypes.object,

        /** Styles for the container around the button text */
        textContainer: PropTypes.object,
    }),

    /** Disable the button */
    disabled: PropTypes.bool,

    /** The icon to be displayed */
    iconName: PropTypes.string,

    /**
     * Size of the icon to be rendered.
     */
    iconSize: PropTypes.oneOf([
        DEFAULT,
        INHERIT,
        LARGE,
        SMALL,
    ]),

    /**
     * Called when the button is clicked.
     * @param {Object} event - The native click event
     * @param {Object} props - The component instance props
     */
    onClick: PropTypes.func.isRequired,

    /** Icon location. Defaults to the left (false). */
    reverse: PropTypes.bool,

    /** Id used for testing */
    testID: PropTypes.string,

    /** Text displayed inside the button */
    text: PropTypes.string,

    /** Type of the button */
    type: PropTypes.string,

    /** Button Variant. Defaults to the 'contained' variant. Valid Types: 'outlined', 'contained' */
    variant: PropTypes.oneOf([
        CONTAINED,
        OUTLINED,
        TEXT,
    ]),
};

Button.defaultProps = {
    contextStyles: {},
    iconSize: SMALL,
    variant: CONTAINED,
};

export default Button;
export {
    VARIANT,
    ICON_SIZE,
};
