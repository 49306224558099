// Core component styles
const coreStyles = {
    container: {},
    axisLine: {},
    primaryAxisTick: {},
    secondaryAxisTick: {},
    pointer: {},
};

/**
 * Helper method to get styles for the Gauge Chart
 * @param {Object} contextStyles - custom styles for the gauge chart
 */
const getStyles = (contextStyles) => {
    return {
        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },
        axisLine: {
            ...coreStyles.axisLine,
            ...contextStyles.axisLine,
        },
        primaryAxisTick: {
            ...coreStyles.primaryAxisTick,
            ...contextStyles.primaryAxisTick,
        },
        secondaryAxisTick: {
            ...coreStyles.secondaryAxisTick,
            ...contextStyles.secondaryAxisTick,
        },
        pointer: {
            ...coreStyles.pointer,
            ...contextStyles.pointer,
        },
    };
};

export default getStyles;
