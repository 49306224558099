import React from 'react';
import * as PropTypes from 'prop-types';
import MuiLabel from '@material-ui/core/FormLabel';

import makeStyles from '@material-ui/core/styles/makeStyles';
import getStyles from './TextLabel.styles';

/**
 * Text Label component to display text or label
 * @see https://material-ui.com/style/typography/
 * @see https://material-ui.com/api/typography/
 * @see https://material-ui.com/api/form-label/
 */
const TextLabel = (props) => {
    const {
        children,
        contextStyles,
        error,
        numberOfLines,
        onClick,
        testID,
        title,
        toolTip,
    } = props;

    // Override core styles with context styles, separating MUI styles
    const {
        container: containerStyles,
        ...muiStyles
    } = getStyles(contextStyles, numberOfLines);

    // Create dynamic class names and injected DOM styles for MUI component
    // * makeStyles returns a function
    const styles = makeStyles(muiStyles)();

    // Generate container props
    const containerProps = {
        className: 'c-text-label__container',
        style: containerStyles, // non-MUI styles
    };
    if (testID) { containerProps['data-test-id'] = `${testID}__text-label__container`; }

    // Generate label props
    const labelProps = {
        className: 'c-text-label__text',
        classes: {
            root: styles.text, // MUI styles
        },
        error,
    };
    if (onClick) {
        labelProps.onClick = (event) => {
            onClick(event, props);
        };
    }
    if (testID) { labelProps['data-test-id'] = `${testID}__text-label__text`; }
    if (title || toolTip) { labelProps.title = title || toolTip; }

    return (
        <div { ...containerProps }>
            <MuiLabel { ...labelProps }>
                { children }
            </MuiLabel>
        </div>
    );
};

TextLabel.propTypes = {
    /** The text to display */
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),

    /** Styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the container surrounding the text */
        container: PropTypes.object,

        /** Styles for the text */
        text: PropTypes.object,
    }),

    /** Show the label with error styles */
    error: PropTypes.bool,

    /**
     * Used to truncate the text with an ellipsis after
     * computing the text layout, including line wrapping,
     * such that the total number of lines does not exceed this number
     */
    numberOfLines: PropTypes.number,

    /**
     * Called when the text is pressed
     * @param {Object} event - The native change event
     * @param {Object} props - The component instance props
     */
    onClick: PropTypes.func,

    /** Id used for testing */
    testID: PropTypes.string,

    /** Title used for tooltip */
    title: PropTypes.string,

    /** toolTip string */
    toolTip: PropTypes.string,
};

TextLabel.defaultProps = {
    contextStyles: {},
    children: '',
};

export default TextLabel;
