/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';

import { DeTableHeaderRowCell } from 'cv-library-react-web';
import LibraryAddCheckOutlinedIcon from '@material-ui/icons/LibraryAddCheckOutlined';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
// import { constants, searchController } from 'cv-react-core';
import Checkbox from '../Checkbox';
import getStyles from './TableHeaderCell.styles';

class TableHeaderCell extends PureComponent {
    static propTypes = {
        hearderBaseProps: PropTypes.object,
        isSearchFormOpen: PropTypes.bool,
        pageSelectAll: PropTypes.bool,
    };

    static defaultProps = {
        isSearchFormOpen: false,
        pageSelectAll: false,
    };

    render() {
        const {
            hearderBaseProps,
            isSearchFormOpen,
            pageSelectAll } = this.props;
        const {
            style,
            column,
            position } = hearderBaseProps;

        const {
            isFiltered,
            fixedColumn,
            name,
            spacer } = column;

        if (!isSearchFormOpen) this.isFiltered = isFiltered;
        const componentStyle = getStyles(style, isSearchFormOpen ? this.isFiltered : isFiltered, fixedColumn, name.localeCompare('selection_column') === 0, spacer);

        const tableHeaderRowCellProps = {
            ...hearderBaseProps,
            style: { ...componentStyle.container, left: fixedColumn ? position : 'unset'},
            draggingEnabled: !fixedColumn,
        };

        if (name.localeCompare('selection_column') === 0) {
            tableHeaderRowCellProps.children = React.createElement(Checkbox, { checkedIcon: <LibraryAddCheckIcon />, icon: <LibraryAddCheckOutlinedIcon />, checked: pageSelectAll, onClick: this.handleClick, contextStyles: { container: componentStyle.checkBoxContainer, check: componentStyle.check } });
        }
        return React.createElement(DeTableHeaderRowCell, { ...tableHeaderRowCellProps });
        // const childrenWithProps = React.Children.map(children, (child) => {
        //     // checking isValidElement is the safe way and avoids a typescript error too
        //     if (React.isValidElement(child)) {
        //       return React.cloneElement(child, {
        //         name,
        //         title,
        //         isAscending,
        //         fixedColumn,
        //         priority,
        //         isSearchFormOpen,
        //         onSortColumn,
        //         onColumnFrozen });
        //     }
        //     return child;
        //   });
        // return React.createElement(DeTableHeaderRowCell, { ...tableHeaderRowCellProps }, childrenWithProps);
    }

    handleClick = (event) => {
        event.stopPropagation();
        const { target: { checked } } = event;
        const { onSelectAll } = this.props;
        onSelectAll(checked, 'DESKTOP');
    }

    // Use this to preserve existing sort information since we have to look at the searchDialogStore directly for these values.
    // Otherwise you will see the grids update as users update information in advanced search.
    isFiltered;
    sortValue;
}

export default TableHeaderCell;
