import React from 'react';
import * as PropTypes from 'prop-types';

import getStyles from './SpacerCell.styles';

const SpacerCell = (props) => {
    const {
        contextStyles,
        node: { selected },
    } = props;
    const { spacer } = getStyles(contextStyles, selected);

    return (
        <span style={ spacer } />
    );
};

SpacerCell.propTypes = {
    contextStyles: PropTypes.shape({
        spacer: PropTypes.object,
    }),
    node: PropTypes.object,
};

SpacerCell.defaultProps = {
    contextStyles: {},
    node: {},
};

export default SpacerCell;
