import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import componentFactory from './componentFactory';
import SaltComponent from './SaltComponent';
import SaltContext from './SaltContext';
import pageController from '../controllers/pageController';

@observer
export default class AvailableViews extends SaltComponent {
    static propTypes = {
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        viewId: PropTypes.string,
    };

    render() {
        const { dialogStore } = this.scopedContext;
        if (dialogStore) {
            const { style, xStyle, isReadMode } = this.props;
            const { dialog } = dialogStore;
            const { availableViews, selectedViewId } = dialog;
            const selectedViewDescriptor = availableViews ? availableViews.find((f) => (f.id === selectedViewId)) : {};
            if (availableViews && availableViews.length > 1){
                const optionValues = this.formatItems(availableViews);
                const value = selectedViewDescriptor ? optionValues.find((item) => item.value === selectedViewDescriptor.id) : undefined;

                const dropDownFieldProps = {
                    style,
                    value: typeof value === 'string' ? { label: value, value } : value,
                    isReadMode,
                    onValueChanged: this.handleOnOpenView,
                    xStyle,
                    optionValues,
                    disableClearable: true,
                };
                return React.createElement(componentFactory.getPlatformPropertyComponent('dropDownField'), dropDownFieldProps);
            }
        }
        return null;
    }

    get scopedContext() {
        const { saltStore, viewId, onError, onTransition } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        const { uiStore } = saltStore;
        const { searchDialogStore } = dialogStore;
        return { searchDialogStore, dialogStore, viewId, onError, uiStore, onTransition };
    }

    formatItems(items) {
        return items.map((item) => {
            return {
                label: item.title,
                value: item.id,
            };
        });
    }

    handleOnOpenView = (viewDescriptor) => {
        const { uiStore, dialogStore, onTransition, onError } = this.scopedContext;
        const { dialog } = dialogStore;
        const { availableViews } = dialog;
        const foundItem = availableViews.find((i) => i.id === viewDescriptor.value);
        const params = { viewDescriptor: foundItem, dialogStore, uiStore, onTransition, onError };
        return pageController.openView(params);
    }
}

AvailableViews.contextType = SaltContext;
