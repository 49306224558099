import React from 'react';
import * as PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';
import styleHelper from 'cv-react-core/src/utilities/styleHelper';
import BaseTable from '../components/base/Table';
import RWSaltComponent from './RWSaltComponent';

class RWTable extends RWSaltComponent {
    static propTypes = {
        /** Styles applied to every cell */
        cellStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.arrayOf(PropTypes.object),
        ]),

        /** Table layout information */
        layout: PropTypes.shape({
            /** Information for each column cell */
            column: PropTypes.arrayOf(PropTypes.shape({ wrap: PropTypes.bool })),
        }),

        /**
         * Multidimensional array of cells
         *  [  // rows
         *      [  // row
         *          e1,  // column
         *          e2,  // column
         *          e3,  // column
         *      ],
         *      [ e4, e5 ],
         *      [ e6, e7, e8 ],
         *  ]
         */
        rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.element)),

        /** Styles applied to every row */
        rowStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.arrayOf(PropTypes.object),
        ]),

        /** Styles for this component container */
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.arrayOf(PropTypes.object),
        ]),

        /** Styles for this component container */
        xStyle: PropTypes.oneOfType([
            // PropTypes.arrayOf(PropTypes.object),
            PropTypes.object,
        ]),
    };

    render() {
        const {
            layout,
            style,
            xStyle,
            ...rest
        } = this.props;

        const {
            container: containerStyles,
        } = styleHelper.categorizeWebStyles(RWSaltComponent.extractCombinedStyles(style));

        const tableProps = {
            columnModel: layout.column,
            contextStyles: {
                container: {
                    // Temporary until table size is managed using flex and surrounding size
                    overflow: 'auto',
                    ...containerStyles,
                },
                row: {},
                column: {},
            },
            keyExtractor: uuidv4,
            ...rest,
        };

        if (xStyle) {
            if (xStyle.container) {
                tableProps.contextStyles.container = {
                    ...tableProps.contextStyles.container,
                    ...xStyle.container,
                };
            }
            if (xStyle.row) {
                tableProps.contextStyles.row = {
                    ...tableProps.contextStyles.row,
                    ...xStyle.row,
                };
            }
            if (xStyle.column) {
                tableProps.contextStyles.column = {
                    ...tableProps.contextStyles.column,
                    ...xStyle.column,
                };
            }
        }

        return React.createElement(BaseTable, tableProps);
    }
}

export default RWTable;
