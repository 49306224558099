/*       */

/**
 * A WarningNotifier is a global object that holds warnings to be displayed as a notification.
 */
export default class WarningNotifier {
    miscWarnings = {};
    errorCallback;

    constructor(errorCallback) {
        this.errorCallback = errorCallback;
    }

    // addUnpredictableUnboundWidth = () => {
    //     const msg = 'Unpredictable results: Percent width used in scroll direction';
    //     this.miscWarnings[msg] = null;
    // }

    shouldReport = () => { return true; }

    report = () => {
        if (this.shouldReport()) {
            const miscKeys = Object.keys(this.miscWarnings);
            const needToReport = miscKeys.length;
            let message;
            if (needToReport) {
                message = 'GML Issues\n\n';

                // Misc issues
                if (miscKeys.length) {
                    message += 'General\n';
                    message += this.reportConversions(this.miscWarnings);
                }
                if (this.errorCallback) this.errorCallback(message);
            }
        }
    }

    reportConversions = (messagesAndCounts) => {
        let message = '';
        Object.keys(messagesAndCounts).forEach(e => {
            message += `  ${e}\n`;
        });
        return message;
    }
}
