/* eslint-disable no-unused-vars */
import { rootStore } from 'cv-react-core';

const TableHeaderLockedZIndex = 500;
const TableHeaderZIndex = 400;
/**
* Helper method to get the styles for Icon component
* @param {Object} contextStyles - Context styles for Icon Component
*/
const getStyles = (contextStyles = {}, isFiltered, isFixedColumn, isSelectAllColumn, spacer) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();
    const spacerStyles = spacer ? {
        padding: 0,
        borderBottom: 0,
    } : {};
    const paddingStyles = isSelectAllColumn ? {
        paddingTop: 0,
        paddingRight: 8,
        paddingBottom: 0,
        paddingLeft: 8,
    } : theme.space;
    return {
        container: {
            ...paddingStyles,
            backgroundColor: 'white',
            marginBottom: '5px',
            borderBottom: '1px solid #D2D2D2',
            borderTop: '1px solid #D2D2D2',
            zIndex: isFixedColumn ? TableHeaderLockedZIndex : TableHeaderZIndex,
            ...contextStyles,
            ...spacerStyles,
            borderRight: isSelectAllColumn ? '1px solid #D2D2D2' : '0px',
        },
        checkBoxContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        },
        check: {
            padding: '0px',
            width: '18px',
            height: '18px',
            color: '#121212',
        },
    };
};

export default getStyles;
