import { observable, action, configure, toJS } from 'mobx';
import { Log } from 'cv-dialog-sdk';
import { constants } from '../constants';
import { themes } from '../theme';
import { utilities } from '../utilities';
import serviceFactory from '../services/serviceFactory';

// eslint-disable-next-line no-irregular-whitespace
configure({ enforceActions: 'observed' });

export default class ThemeStore {
    @observable appLogo = '';
    @observable bannerLogo = '';
    @observable appName = '';
    @observable spaceMode = '';
    @observable currentTheme = {};
    @observable themeData = new Map();
    // tenantIdChanged = false;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @action setTheme = (theme) => {
        // Set currentTheme style properties for application
        this.currentTheme = theme;
        this.appLogo = this.currentTheme.appLogo;
        this.appName = this.currentTheme.appName;
        this.bannerLogo = this.currentTheme.bannerLogo;
        this.appTopLogo = this.currentTheme.appTopLogo;
        this.spaceMode = this.currentTheme.spaceMode;
    }

    @action setValue = (key, value) => {
        // Check and see if we are getting a new tenant value. If so we need to restore cached instance.
        // if (key === constants.session.TENANT_ID && value !== this.settings.get(constants.session.TENANT_ID)) {
        //     this.tenantIdChanged = true;
        // }
        // Set the new value
        this.themeData.set(key, value);
        this.saveThemeStoreCache();
    };

    getValue = (key) => {
        return this.themeData.get(key);
    };

    getSanitizedTheme = () => {
        return toJS(this.currentTheme);
    }

    getSanitizedThemeData = () => {
        const result = toJS(this.themeData);
        return result;
    };

    /**
     * Processing the tenantCapabilities information to update the default Branding theming
     */
    processTenantBrandingThemeData(tenantCapabilities, tenantId) {
        // Update the saved tenant values with new values from the Tenant Capabilities info.
        const valuesByTenant = this.getValue(constants.storage.THEME) || {};
        if (tenantCapabilities && tenantCapabilities.defaultBrandingLoginJSON) {
            const { loginBranding } = themes.brandMappingConstant;
            valuesByTenant[tenantId] = this.getTenantBrandingThemeData(JSON.parse(tenantCapabilities.defaultBrandingLoginJSON), loginBranding);
            this.setValue(constants.storage.THEME, valuesByTenant);
        }

        // Set the current theme now that tenant branding login json values are available to the factory.
        this.setTheme(themes.themeFactory(this.rootStore).getTheme(tenantId));
    }

    /**
     * Processing the session infromation to update the login theming ( GML Defaults)
     */
    processTenantLoginThemeData(session) {
        // Update the saved tenant values with new values from this session.
        const valuesByTenant = this.getValue(constants.storage.THEME) || {};
        if (valuesByTenant[session.tenantId]) {
            const { tenantProperties: { brandingLauncherJSON, listDensity } } = session;
            const { loggedInBranding } = themes.brandMappingConstant;
            // Added minimal valid json string after || operator, so that JSON.parse does not throw error when brandingLauncherJSON not provided, however will still throw error if provided brandingLauncherJSON is not a valid Json
            const brandingLauncherStyles = this.getTenantBrandingThemeData(JSON.parse(brandingLauncherJSON || '{}'), loggedInBranding);
            valuesByTenant[session.tenantId] = { ...brandingLauncherStyles, ...this.getTenantThemeData(session), spaceMode: (listDensity && listDensity === 'COMPACT') ? 'compact' : 'padded' };
        } else {
            valuesByTenant[session.tenantId] = this.getTenantThemeData(session);
        }
        this.setValue(constants.storage.THEME, valuesByTenant);

        // Set the current theme now that tenant values are available to the factory.
        this.setTheme(themes.themeFactory(this.rootStore).getTheme(session.tenantId));
    }

    processListToggler(session) {
        const valuesByTenant = this.getValue(constants.storage.THEME) || {};
        if (valuesByTenant[session.tenantId]) {
            valuesByTenant[session.tenantId] = { ...valuesByTenant[session.tenantId], spaceMode: (valuesByTenant[session.tenantId].spaceMode === 'padded') ? 'compact' : 'padded' };
        } else {
            valuesByTenant[session.tenantId] = { spaceMode: 'compact' };
        }
        this.setValue(constants.storage.THEME, valuesByTenant);
        this.setTheme(themes.themeFactory(this.rootStore).getTheme(session.tenantId));
    }

    /**
     * Fetch branding login information from the tenant.
     * @returns { object } - Returns style information from the tenant in a flat object format.
     */
    getTenantBrandingThemeData = (defaultBrandingLoginJSON, brandingConstants) => {
        let themeData = {};

        // extracting and mapping the properties from brandingloginjson to theme format
        Object.keys(defaultBrandingLoginJSON).forEach((label) => {
            if (brandingConstants[label]) {
                const result = brandingConstants[label].split('.').reduceRight((val, key) => ({ [key]: val }), defaultBrandingLoginJSON[label]);
                themeData = { ...themeData, ...result };
            }
        });

        return themeData;
    }

    /**
     * Fetch custom GLM theme information from the tenant.
     * @returns { object } - Returns style information from the tenant in a flat object format.
     */
    getTenantThemeData = (session) => {
        const themeData = {};

        // Fetch tenant level GML & bail if none exists.
        const tenantGMLXML = session.tenantProperties.GMLDefaults || '';
        if (tenantGMLXML === '') return undefined;

        // Parse GML into a json object.
        const options = { explicitChildren: true, preserveChildrenOrder: true, trim: true };
        const parseXmlString = serviceFactory.xmlParser;
        parseXmlString(tenantGMLXML, options, (error, gmlJson) => {
            if (error) {
                Log.error(
                    `Error parsing mobileTheme from the tenant level GML:\n: ${utilities.prettyXmlParseError(tenantGMLXML, error)}\nThe offending line may be before or after this line.`,
                    'getTenantThemeData', 'SessionStore',
                );
            }
            if (!error && gmlJson) {
                // Fetch object data needed to set theme
                const mobileTheme = gmlJson.gml.$$.find(f => f['#name'] === 'mobileTheme');
                this.getStyleObject(mobileTheme, themeData);
            }
        });

        return themeData;
    }

    /**
     * Extracts data object from GML based on property name.
     * @param { object } tenantGML - tenant level GML
     * @param { object } styleDataIn - object that is populated with found style content.
     */
    getStyleObject = (tenantGML, styleDataIn) => {
        const styleData = styleDataIn;
        if (!tenantGML || !tenantGML.$$) return;

        // Add each attribute to styleData.
        tenantGML.$$.forEach((attribute) => {
            const stringValue = attribute._;
            if (attribute.$$ === undefined) {
                // Process this attribute directly.
                const { name } = attribute.$;
                styleData[name] = Number.isNaN(Number(stringValue)) ? stringValue : Number(stringValue);
            } else {
                // This attribute is really structure for child attributes.
                const name = attribute['#name'];
                const childStyle = {};
                styleData[name] = childStyle;
                this.getStyleObject(attribute, childStyle);
            }
        });
    }


    /**
     * Updating the cacheStore with theme infromation
     */
    saveThemeStoreCache = () => {
        const { cacheStore } = this.rootStore;
        cacheStore.setCacheType(cacheStore.cacheStores.THEME, this.getSanitizedThemeData());
    }

    /**
     * restore the theming infromation from cacheStore and updating the themStore
     */
    restoreFromCache = (cacheData) => {
        const { theme } = cacheData;
        if (theme) {
            this.themeData.clear();
            Object.keys(theme).forEach(key => {
                this.setValue(key, theme[key]);
            });
        }
        // Load theme for the current tenant
        this.setTheme(themes.themeFactory(this.rootStore).getTheme(this.rootStore.settingsStore.settings.get('tenantId')));
    };
}
