import React from 'react';
import PropTypes from 'prop-types';
import { default as ChartWrapper } from '../ChartWrapper/ChartWrapper';
import getStyles from './ScatterBubbleChart.styles';

/**
 * A scatter bubble chart is a type of plot or mathematical diagram using Cartesian coordinates to display values for typically two variables for a set of data.
 * @see https://echarts.apache.org/examples/en/editor.html?c=bubble-gradient
 */
const ScatterBubbleChart = (props) => {
    const {
        animation,
        contextStyles,
        gridLines,
        horizontal,
        horizontalGridLines,
        legend,
        maxSpan,
        maxX,
        maxY,
        minSpan,
        minX,
        minY,
        onPress,
        onSelectItem,
        renderButtonText,
        seriesData,
        testID,
        title,
        tooltip,
        valueLabelFormat,
        verticalGridLines,
        xAxis,
        xAxisTitle,
        yAxis,
        yAxisTitle,
        zoom,
    } = props;

    const styles = getStyles(contextStyles);

    /** Build axis options */
    const axisOptions = {
        xAxis: {
            // Show/hide axis lines
            show: xAxis,

            // Background grid lines
            splitLine: {
                // Show vertical grid lines
                show: gridLines && verticalGridLines,
            },
        },
        yAxis: {
            // Show/hide axis lines
            show: yAxis,

            // Background grid lines
            splitLine: {
                // Show horizontal grid lines
                show: gridLines && horizontalGridLines,
            },
        },
    };

    // Set axis titles
    if (xAxisTitle) {
        axisOptions.xAxis.name = xAxisTitle;
        axisOptions.xAxis.nameLocation = 'center';
        axisOptions.xAxis.nameGap = 25;
        axisOptions.xAxis.textStyle = styles.xAxisTextStyle;
    }
    if (yAxisTitle) {
        axisOptions.yAxis.name = yAxisTitle;
        axisOptions.yAxis.nameLocation = 'center';
        axisOptions.yAxis.nameGap = 55;
        axisOptions.yAxis.textStyle = styles.yAxisTextStyle;
        axisOptions.yAxis.axisLine = {
            show: verticalGridLines,
        };
    }

    /** Build chart options */
    const chartOptions = {
        animation,
        backgroundColor: 'transparent',
        color: [ ...ChartWrapper.defaultColors ],
        grid: {
            borderWidth: 0,
            containLabel: true,
            show: true,
        },
        textStyle: styles.chartTextStyle,
        xAxis: axisOptions.xAxis,
        yAxis: axisOptions.yAxis,
    };

    // Set chart legend
    if (legend) {
        chartOptions.legend = {
            // Show the legend
            show: true,

            // Initialize legend series names
            data: [],

            padding: [
                5,
                10,
            ],
            symbolKeepAspect: true,

            // apply styles to legend
            textStyle: styles.legendTextStyle,

            // providing the scroll if legends doesnt fit with available area
            type: 'scroll',
            orient: horizontal ? 'vertical' : 'horizontal',
            ...styles.legendContainerStyle,
        };
    }

    // Set chart title
    if (title) {
        chartOptions.title = {
            text: title,
            textStyle: styles.titleTextStyle,
        };
    }

    // Enable chart tooltip
    if (tooltip) {
        chartOptions.tooltip = {
            confine: true,
            extraCssText: 'z-index: 10',
            padding: [
                2,
                10,
            ],
            textStyle: styles.tooltipTextStyle,
            formatter: (params) => {
                let tooltipText;
                if (params.value.length > 1) {
                    tooltipText = `${params.name}<br/>${axisOptions.yAxis.name || 'Y'}: ${params.value[1]}<br/>${axisOptions.xAxis.name || 'X'}: ${params.value[0]}`;
                    if (params.data.bubbleRadiusName && params.data.symbolSize) {
                        tooltipText += `<br/>${params.data.bubbleRadiusName}: ${params.data.symbolSize}`;
                    }
                }
                return tooltipText;
            },
            ...styles.tooltipContainerStyle,
        };
    }

    // Enable chart zoom
    if (zoom) {
        chartOptions.dataZoom = {
            type: 'inside',
            minSpan,
            maxSpan,
        };
    }

    // Set chart ranges
    if (maxX || maxX === 0) { chartOptions.xAxis.max = maxX; }
    if (minX || minX === 0) { chartOptions.xAxis.min = minX; }
    if (maxX || minX) { chartOptions.xAxis.scale = true; }
    if (maxY || maxY === 0) { chartOptions.yAxis.max = maxY; }
    if (minY || minY === 0) { chartOptions.yAxis.min = minY; }
    if (maxY || minY) { chartOptions.yAxis.scale = true; }

    // Generate chart series data
    chartOptions.series = seriesData.map((bubbleDataInfo, bubbleIdx) => {
        const {
            dataPoints,
            legendText,
            symbolBorderColor: seriesBorderColor,
            symbolBorderWidth: seriesBorderWidth,
            symbolColor: seriesColor,
            symbolEmphasisColor: seriesEmphasisColor,
            symbolOpacity: seriesOpacity,
            symbolSize: seriesSize,
            valueLabel: seriesValueLabel,
            valueLabelColor: seriesLabelColor,
            valueLabelFormat: seriesLabelFormat,
            valueLabelHeight: seriesLabelHeight,
            valueLabelSize: seriesLabelSize,
            valueLabelWidth: seriesLabelWidth,
            ...bubbleRest
        } = bubbleDataInfo;

        // Generate bubble series
        const bubbleSeries = {
            // Initialize series bubble style
            itemStyle: {},

            // This is a scatter (bubble) chart
            type: 'scatter',

            // Pass through any extra props
            ...bubbleRest,
        };

        // Add legend information
        if (legend && legendText) {
            // Add the legend item to the legend array
            chartOptions.legend.data.push(legendText);

            // Set the series name to link to the legend item
            bubbleSeries.name = legendText;
        }

        // Add series level bubble details
        if (seriesBorderColor) {
            bubbleSeries.itemStyle.borderColor = seriesBorderColor;
        }
        if (seriesBorderWidth || seriesBorderWidth === 0) {
            bubbleSeries.itemStyle.borderWidth = seriesBorderWidth;
        }
        if (seriesColor) {
            // Override default color spot for the legend and line symbol to reflect color
            chartOptions.color[bubbleIdx] = seriesColor;
        }
        if (seriesEmphasisColor) {
            bubbleSeries.emphasis = { itemStyle: { color: seriesEmphasisColor } };
        }
        if (seriesOpacity || seriesOpacity === 0) {
            bubbleSeries.itemStyle.opacity = seriesOpacity;
        }
        if (seriesSize || seriesSize === 0) {
            bubbleSeries.symbolSize = seriesSize;
        }

        // Add series level bubble labels
        if (seriesValueLabel) {
            bubbleSeries.label = {
                show: true,
            };
            if (seriesLabelColor) {
                bubbleSeries.label.color = seriesLabelColor;
            }
            if (valueLabelFormat) {
                bubbleSeries.label.formatter = valueLabelFormat.replace('{value}', '{c}');
            }
            if (seriesLabelFormat) {
                bubbleSeries.label.formatter = seriesLabelFormat.replace('{value}', '{c}');
            }
            if (seriesLabelHeight || seriesLabelHeight === 0) {
                bubbleSeries.label.height = seriesLabelHeight;
            }
            if (seriesLabelSize || seriesLabelSize === 0) {
                bubbleSeries.label.fontSize = seriesLabelSize;
            }
            if (seriesLabelWidth || seriesLabelWidth === 0) {
                bubbleSeries.label.width = seriesLabelWidth;
            }
        }

        // Generate bubble data
        bubbleSeries.data = dataPoints.map((bubbleDetail) => {
            const {
                symbolBorderColor,
                symbolBorderWidth,
                symbolColor,
                symbolEmphasisColor,
                symbolEmphasisOpacity,
                symbolOpacity,
                symbolSize,
                value,
                valueLabel,
                valueLabelColor,
                valueLabelFormat: bubbleLabelFormat,
                valueLabelHeight,
                valueLabelSize,
                valueLabelWidth,
                ...bubbleDetailRest
            } = bubbleDetail;

            // Generate minimum bubble data
            const bubbleData = {
                // Initialize individual bubble style
                itemStyle: {},

                // Bubble value
                value,

                // Pass through any extra props
                ...bubbleDetailRest,
            };

            // Add individual level bubble details
            if (symbolBorderColor) {
                bubbleData.itemStyle.borderColor = symbolBorderColor;
            }
            if (symbolBorderWidth || symbolBorderWidth === 0) {
                bubbleData.itemStyle.borderWidth = symbolBorderWidth;
            }
            if (symbolColor) {
                // Override default color spot for the legend and line symbol to reflect color
                bubbleData.itemStyle.color = symbolColor;
            }

            // Adding default opacity to provide more contrast on Item
            const emphasisItemStyle = { opacity: 0.5 };
            if (symbolEmphasisOpacity || symbolEmphasisOpacity === 0) {
                emphasisItemStyle.opacity = symbolEmphasisOpacity;
            }
            if (symbolEmphasisColor) {
                emphasisItemStyle.color = symbolEmphasisColor;
            }
            bubbleData.emphasis = { itemStyle: emphasisItemStyle };

            if (symbolOpacity || symbolOpacity === 0) {
                bubbleData.itemStyle.opacity = symbolOpacity;
            }
            if (symbolSize || symbolSize === 0) {
                bubbleData.symbolSize = symbolSize;
            }

            // Add individual level bubble labels
            if (valueLabel) {
                bubbleData.label = {
                    show: true,
                };
                if (valueLabelColor) {
                    bubbleData.label.color = valueLabelColor;
                }
                if (valueLabelFormat) {
                    bubbleData.label.formatter = valueLabelFormat.replace('{value}', '{c}');
                }
                if (seriesLabelFormat) {
                    bubbleData.label.formatter = seriesLabelFormat.replace('{value}', '{c}');
                }
                if (bubbleLabelFormat) {
                    bubbleData.label.formatter = bubbleLabelFormat.replace('{value}', '{c}');
                }
                if (valueLabelHeight || valueLabelHeight === 0) {
                    bubbleData.label.height = valueLabelHeight;
                }
                if (valueLabelSize || valueLabelSize === 0) {
                    bubbleData.label.fontSize = valueLabelSize;
                }
                if (valueLabelWidth || valueLabelWidth === 0) {
                    bubbleData.label.width = valueLabelWidth;
                }
            }

            return bubbleData;
        });

        return bubbleSeries;
    });

    // Generate chart props
    const chartProps = {
        contextStyles: {
            container: styles.container,
        },
        option: chartOptions,
    };
    if (onPress) { chartProps.onPress = onPress; }
    if (onSelectItem) { chartProps.onSelectItem = onSelectItem; }
    if (renderButtonText) { chartProps.renderButtonText = renderButtonText; }
    if (testID) { chartProps.testID = testID; }

    return (
        <ChartWrapper { ...chartProps } />
    );
};

ScatterBubbleChart.propTypes = {
    /** Enables/disables chart animations */
    animation: PropTypes.bool,

    /** Styles for the component */
    contextStyles: PropTypes.shape({
        /** Styles for the chart text content */
        chartTextStyle: PropTypes.object,

        /** Styles for the container */
        container: PropTypes.object,

        /** Styles for the legend container */
        legendContainerStyle: PropTypes.object,

        /** Styles for the legend */
        legendTextStyle: PropTypes.object,

        /** Styles for the chart title */
        titleTextStyle: PropTypes.object,

        /** Styles for the tooltip container */
        tooltipContainerStyle: PropTypes.object,

        /** Styles for the tooltip */
        tooltipTextStyle: PropTypes.object,

        /** Styles for the xAxis */
        xAxisTextStyle: PropTypes.object,

        /** Styles for the yAxis */
        yAxisTextStyle: PropTypes.object,
    }),

    /** Enable display of grid lines */
    gridLines: PropTypes.bool,

    /** Enables the orientation of Legend*/
    horizontal: PropTypes.bool,

    /** Enable display of horizontal grid lines */
    horizontalGridLines: PropTypes.bool,

    /** Enable display of a legend */
    legend: PropTypes.bool,

    /** Used to restrict minimal window size, in percent, which value is in the range of [0, 100]. */
    minSpan: PropTypes.string,

    /** Used to restrict maximal window size, in percent, which value is in the range of [0, 100]. */
    maxSpan: PropTypes.string,

    /** Maximum X axis value */
    maxX: PropTypes.number,

    /** Maximum Y axis value */
    maxY: PropTypes.number,

    /** Minimum X axis value */
    minX: PropTypes.number,

    /** Minimum Y axis value */
    minY: PropTypes.number,

    /**
     * Triggered on press of a bar
     * @param {Object} itemData - Data for the bar pressed
     */
    onPress: PropTypes.func,

    /**
     * Triggered on select of a bar
     * @param {Object} itemData - Data for the bar selected
     */
    onSelectItem: PropTypes.func,

    /**
     * Used to manage the selected detail button text
     * @param {Object} dataPoint
     */
    renderButtonText: PropTypes.func,

    /** Series of bubble descriptions */
    seriesData: PropTypes.arrayOf(PropTypes.shape({
        /** The data points to display for bubble chart */
        dataPoints: PropTypes.arrayOf(PropTypes.shape({
            /** Name of field from which bubble radius is extracted */
            bubbleRadiusName: PropTypes.string,

            /** Display name to identify the bubble */
            name: PropTypes.string.isRequired,

            /** Border color of the individual symbol */
            symbolBorderColor: PropTypes.string,

            /** Border width of the individual symbol */
            symbolBorderWidth: PropTypes.number,

            /** Color of the individual symbol */
            symbolColor: PropTypes.string,

            /** Emphasis color of the individual symbol on press/hover */
            symbolEmphasisColor: PropTypes.string,

            /** Opacity of the individual symbol */
            symbolOpacity: PropTypes.number,

            /** Size of the individual symbol */
            symbolSize: PropTypes.oneOfType([

                /** Size of the symbol in number */
                PropTypes.number,

                /** Array to represent width and height - ex: [ 10, 20 ] where width is 10 and height is 20 */
                PropTypes.array,
            ]),

            /** The value of the individual symbol that represents the X and Y coordinates */
            value: PropTypes.arrayOf(PropTypes.number).isRequired,

            /** Display label with the individual symbol value */
            valueLabel: PropTypes.bool,

            /** Color of the individual symbol value label */
            valueLabelColor: PropTypes.string,

            /** Format string for the individual symbol value label */
            valueLabelFormat: PropTypes.string,

            /** Height of the individual symbol value label */
            valueLabelHeight: PropTypes.oneOfType([
                /** Height of the content block */
                PropTypes.number,

                /** Percentage of the content height */
                PropTypes.string,
            ]),

            /** Font size of the individual symbol value label */
            valueLabelSize: PropTypes.number,

            /** Width of the individual symbol value label */
            valueLabelWidth: PropTypes.oneOfType([
                /** Width of the content block */
                PropTypes.number,

                /** Percentage of the content width */
                PropTypes.string,
            ]),
        })).isRequired,

        /** Displays a legend item for the series */
        legendText: PropTypes.string,

        /** Border color of the series symbols */
        symbolBorderColor: PropTypes.string,

        /** Border width of the series symbols */
        symbolBorderWidth: PropTypes.number,

        /** Color of the series symbols */
        symbolColor: PropTypes.string,

        /** Emphasis color of the series symbols on press/hover */
        symbolEmphasisColor: PropTypes.string,

        /** Opacity of the series symbols */
        symbolOpacity: PropTypes.number,

        /** Size of the series symbols */
        symbolSize: PropTypes.oneOfType([

            /** Size of the symbol in number */
            PropTypes.number,

            /** Array to represent width and height - ex: [ 10, 20 ] where width is 10 and height is 20 */
            PropTypes.array,
        ]),

        /** Display label with the series symbols value */
        valueLabel: PropTypes.bool,

        /** Color of the series symbols value label */
        valueLabelColor: PropTypes.string,

        /** Format string for the series symbols value label */
        valueLabelFormat: PropTypes.string,

        /** Height of the series symbols value label */
        valueLabelHeight: PropTypes.oneOfType([
            /** Height of the content block */
            PropTypes.number,

            /** Percentage of the content height */
            PropTypes.string,
        ]),

        /** Font size of the series symbols value label */
        valueLabelSize: PropTypes.number,

        /** Width of the series symbols label */
        valueLabelWidth: PropTypes.oneOfType([
            /** Width of the content block */
            PropTypes.number,

            /** Percentage of the content width */
            PropTypes.string,
        ]),
    })).isRequired,

    /** Id used for testing */
    testID: PropTypes.string,

    /** Title text for the chart */
    title: PropTypes.string,

    /** Show related data in a tooltip after pressing a bubble */
    tooltip: PropTypes.bool,

    /**
     * Format string for the value label
     * @see https://echarts.apache.org/en/option.html#series-scatter.label.formatter for the available formatting options
     */
    valueLabelFormat: PropTypes.string,

    /** Enable display of vertical grid lines */
    verticalGridLines: PropTypes.bool,

    /** Show X axis line, ticks and labels */
    xAxis: PropTypes.bool,

    /** Name of xAxis */
    xAxisTitle: PropTypes.string,

    /** Show Y axis line, ticks and labels */
    yAxis: PropTypes.bool,

    /** Name of yAxis */
    yAxisTitle: PropTypes.string,

    /** Enable zooming in/out chart ranges */
    zoom: PropTypes.bool,
};

ScatterBubbleChart.defaultProps = {
    animation: false,
    contextStyles: {},
    gridLines: true,
    horizontalGridLines: true,
    tooltip: true,
    valueLabelFormat: '{value}',
    verticalGridLines: false,
    xAxis: true,
    xAxisTitle: 'X',
    yAxis: true,
    yAxisTitle: 'Y',
    zoom: true,
};

export default ScatterBubbleChart;
