export var DialogModeEnum;
(function (DialogModeEnum) {
    DialogModeEnum["COPY"] = "COPY";
    DialogModeEnum["CREATE"] = "CREATE";
    DialogModeEnum["READ"] = "READ";
    DialogModeEnum["UPDATE"] = "UPDATE";
    DialogModeEnum["DESTROYED"] = "DESTROYED";
    DialogModeEnum["DELETE"] = "DELETE";
    DialogModeEnum["LIST"] = "LIST";
})(DialogModeEnum || (DialogModeEnum = {}));
export var FilterOperatorEnum;
(function (FilterOperatorEnum) {
    FilterOperatorEnum["EQUAL"] = "Equal";
    FilterOperatorEnum["NOT_EQUAL"] = "Not Equal";
    FilterOperatorEnum["GREATER_THAN"] = "Greater Than";
    FilterOperatorEnum["LESS_THAN"] = "Less Than";
    FilterOperatorEnum["CONTAINS"] = "Contains";
    FilterOperatorEnum["STARTS_WITH"] = "Starts With";
    FilterOperatorEnum["ENDS_WITH"] = "Ends With";
})(FilterOperatorEnum || (FilterOperatorEnum = {}));
export var DialogMessageCode;
(function (DialogMessageCode) {
    DialogMessageCode["DEFAULT"] = "DEFAULT";
    DialogMessageCode["CHANGEPASSWORD"] = "CHANGEPASSWORD";
    DialogMessageCode["NO_SESSION"] = "NO_SESSION";
})(DialogMessageCode || (DialogMessageCode = {}));
export var ActionIdsEnum;
(function (ActionIdsEnum) {
    ActionIdsEnum["SEARCH_ACTION_ID"] = "#search";
    ActionIdsEnum["CALCULATE_STATISTICS_ACTION_ID"] = "columnStatistics";
})(ActionIdsEnum || (ActionIdsEnum = {}));
export var QueryDirectionEnum;
(function (QueryDirectionEnum) {
    QueryDirectionEnum["FORWARD"] = "FORWARD";
    QueryDirectionEnum["BACKWARD"] = "BACKWARD";
})(QueryDirectionEnum || (QueryDirectionEnum = {}));
export var SortDirectionEnum;
(function (SortDirectionEnum) {
    SortDirectionEnum["ASC"] = "ASC";
    SortDirectionEnum["DESC"] = "DESC";
})(SortDirectionEnum || (SortDirectionEnum = {}));
export var ViewModeEnum;
(function (ViewModeEnum) {
    ViewModeEnum["READ"] = "READ";
    ViewModeEnum["WRITE"] = "WRITE";
})(ViewModeEnum || (ViewModeEnum = {}));
export var TypeNames;
(function (TypeNames) {
    TypeNames["ActionParametersTypeName"] = "hxgn.api.dialog.ActionParameters";
    TypeNames["AppWindowTypeName"] = "hxgn.api.dialog.AppWindow";
    TypeNames["ArrayResultTypeName"] = "hxgn.api.dialog.ArrayResult";
    TypeNames["AttributeCellValue"] = "hxgn.api.dialog.AttributeCellValue";
    TypeNames["AvailableValuesParametersTypeName"] = "hxgn.api.dialog.AvailableValuesParameters";
    TypeNames["BarcodeScanTypeName"] = "hxgn.api.dialog.BarcodeScan";
    TypeNames["CalendarTypeName"] = "hxgn.api.dialog.Calendar";
    TypeNames["CodeRefTypeName"] = "hxgn.api.dialog.CodeRef";
    TypeNames["ColumnTypeName"] = "hxgn.api.dialog.Column";
    TypeNames["ContentRedirectionTypeName"] = "hxgn.api.dialog.ContentRedirection";
    TypeNames["DetailsTypeName"] = "hxgn.api.dialog.Details";
    TypeNames["DialogTypeName"] = "hxgn.api.dialog.Dialog";
    TypeNames["DialogMessageTypeName"] = "hxgn.api.dialog.DialogMessage";
    TypeNames["DialogRedirectionTypeName"] = "hxgn.api.dialog.DialogRedirection";
    TypeNames["EditorDialogTypeName"] = "hxgn.api.dialog.EditorDialog";
    TypeNames["ForcedLineCellValue"] = "hxgn.api.dialog.ForcedLineCellValue";
    TypeNames["FormTypeName"] = "hxgn.api.dialog.Form";
    TypeNames["GpsReadingTypeName"] = "hxgn.api.dialog.GpsReading";
    TypeNames["GpsReadingPropertyTypeName"] = "hxgn.api.dialog.GpsReadingProperty";
    TypeNames["GraphTypeName"] = "hxgn.api.dialog.Graph";
    TypeNames["ImagePickerTypeName"] = "hxgn.api.dialog.ImagePicker";
    TypeNames["LabelCellValue"] = "hxgn.api.dialog.LabelCellValue";
    TypeNames["LargePropertyTypeName"] = "hxgn.api.dialog.LargeProperty";
    TypeNames["ListTypeName"] = "hxgn.api.dialog.List";
    TypeNames["LoginTypeName"] = "hxgn.api.dialog.Login";
    TypeNames["MapTypeName"] = "hxgn.api.dialog.Map";
    TypeNames["MapLocationTypeName"] = "hxgn.api.dialog.MapLocation";
    TypeNames["MapLocationPropertyTypeName"] = "hxgn.api.dialog.MapLocationProperty";
    TypeNames["NFCScanTypeName"] = "hxgn.api.dialog.NFCScan";
    TypeNames["NullRedirectionTypeName"] = "hxgn.api.dialog.NullRedirection";
    TypeNames["ObjectRefTypeName"] = "hxgn.api.dialog.ObjectRef";
    TypeNames["PropertyTypeName"] = "hxgn.api.dialog.Property";
    TypeNames["PowerBITypeName"] = "hxgn.api.dialog.PowerBI";
    TypeNames["PrintMarkupTypeName"] = "hxgn.api.dialog.PrintMarkup";
    TypeNames["QueryActionParametersTypeName"] = "hxgn.api.dialog.QueryActionParameters";
    TypeNames["QueryDialogTypeName"] = "hxgn.api.dialog.QueryDialog";
    TypeNames["QueryParametersTypeName"] = "hxgn.api.dialog.QueryParameters";
    TypeNames["ReadLargePropertyParameters"] = "hxgn.api.dialog.ReadLargePropertyParameters";
    TypeNames["RecordTypeName"] = "hxgn.api.dialog.Record";
    TypeNames["ReferringDialogTypeName"] = "hxgn.api.dialog.ReferringDialog";
    TypeNames["ReferringWorkbenchTypeName"] = "hxgn.api.dialog.ReferringWorkbench";
    TypeNames["SaltParameters"] = "hxgn.api.dialog.SaltParameters";
    TypeNames["SaltDocument"] = "hxgn.api.dialog.SaltDocument";
    TypeNames["SaltDocumentName"] = "hxgn.api.dialog.SaltDocumentName";
    TypeNames["SaltImageName"] = "hxgn.api.dialog.SaltImageName";
    TypeNames["SideEffectsParameters"] = "hxgn.api.dialog.SideEffectsParameters";
    TypeNames["SideEffectsResponse"] = "hxgn.api.dialog.SideEffectsResponse";
    TypeNames["SessionTypeName"] = "hxgn.api.dialog.Session";
    TypeNames["StreamTypeName"] = "hxgn.api.dialog.Stream";
    TypeNames["SubstitutionCellValue"] = "hxgn.api.dialog.SubstitutionCellValue";
    TypeNames["WebRedirectionTypeName"] = "hxgn.api.dialog.WebRedirection";
    TypeNames["WorkbenchTypeName"] = "hxgn.api.dialog.Workbench";
    TypeNames["WorkbenchRedirectionTypeName"] = "hxgn.api.dialog.WorkbenchRedirection";
    TypeNames["WriteLargePropertyParametersTypeName"] = "hxgn.api.dialog.WriteLargePropertyParameters";
    TypeNames["TenantCapabilitiesTypeName"] = "hxgn.api.dialog.TenantCapabilities";
    TypeNames["VizTypeName"] = "hxgn.api.dialog.Viz";
    TypeNames["VizGraphicIdTypeName"] = "hxgn.api.dialog.VizGraphicId";
    TypeNames["OAuthLoginRedirectionTypeName"] = "hxgn.api.dialog.OAuthLoginRedirection";
})(TypeNames || (TypeNames = {}));
