import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import format from 'xml-formatter';

import lang from '../../nls/i18n';
import ActionButtonImage from '../base/ActionButtonImage/ActionButtonImage';
import TextLabel from '../base/TextLabel';
import TextField from '../base/TextField';
import getStyles from './styles/XMLEditor.styles';

const xmlFormatHandler = '^@-@^';
class XMLEditor extends Component {
    state = {
        isValidXml: true,
    };

    render() {
        const {
            contextStyles,
            formatXmlAction,
            ...rest
        } = this.props;
        const {
            isValidXml,
        } = this.state;
        const {
            buttonContainer,
            editorContainer,
            errorMessage,
        } = getStyles(contextStyles);
        return (
            <div style={ editorContainer }>
                <TextField
                    { ...rest }
                    contextStyles={ contextStyles } />
                { !isValidXml &&
                    <TextLabel
                        contextStyles={ {
                            text: errorMessage,
                        } }
                        error>
                        { lang.generic.xmlParseError }
                    </TextLabel> }
                <div style={ buttonContainer }>
                    <ActionButtonImage
                        imageSource={ formatXmlAction.iconUrl }
                        tooltip={ formatXmlAction.label }
                        onAction={ this.handleValidation } />
                </div>
            </div>
        );
    }

    /**
     * Helper method to validate XML
     */
    handleValidation = () => {
        const {
            onChange,
            value,
        } = this.props;

        if (value) {
            const parser = new DOMParser();
            const dom = parser.parseFromString(`${value}`, 'application/xml');
            const isValidXml = dom.getElementsByTagName('parsererror').length === 0;

            this.setState({
                isValidXml,
            }, () => {
                if (isValidXml && onChange) {
                    // XML parser replaces all the single quoted attributes with double quotes.
                    // Below is a simple hack where we add some local key to all the single quote attributes.
                    // When we get response from xml formatter, we replace all these localkeys with single quotes again. This way we preserve single quotes for attributes.
                    const LOCAL_KEY = 'cX3b7VHj';

                    let xmlFormatedText = value.replace(new RegExp('<!\\[', 'g'), `${xmlFormatHandler}<![`);
                    xmlFormatedText = xmlFormatedText.replace(new RegExp(']]>', 'g'), `]]>${xmlFormatHandler}`);

                    let formattedXml = format(xmlFormatedText.replace(/([\w:-]+)\s*=\s*'[^']*'\s*/g, (str) => {
                        const matchedString = str.match(/([\w:-]+)\s*=\s*("[^"]*"|'[^']*'|\w+)\s*/);
                        return matchedString[1] && matchedString[2] ?
                            `${matchedString[1]}='${LOCAL_KEY}${matchedString[2].trim().substring(1, matchedString[2].length - 1)}${LOCAL_KEY}'` : str;
                    }), {
                        collapseContent: true,
                        lineSeparator: '\n',
                    });

                    // Looking for opening and closing of the formatHandler - '^@-@^'
                    formattedXml = formattedXml.replace(new RegExp('\\^\\@\\-\\@\\^\\<!\\[', 'g'), '<![');
                    formattedXml = formattedXml.replace(new RegExp('\\]\\]\\>\\^\\@\\-\\@\\^', 'g'), ']]>');
                    onChange({
                        target: {
                            value: formattedXml.replace(new RegExp(`(("|')${LOCAL_KEY}|${LOCAL_KEY}("|'))`, 'g' ), '\''),
                        },
                    });
                }
            });
        }
    }
}

XMLEditor.propTypes = {
    /** Styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the button container */
        buttonContainer: PropTypes.object,

        /** Styles for the editor container */
        editorContainer: PropTypes.object,

        /** Styles for the error message text */
        errorMessage: PropTypes.object,
    }),

    /** Action for the format xml */
    formatXmlAction: PropTypes.object,

    /**
     * Called when the input changes
     * @param {Object} event - The native change event
     */
    onChange: PropTypes.func,

    /** The text to populate in the XMLEditor */
    value: PropTypes.string,
};

XMLEditor.defaultProps = {
    contextStyles: {},
    formatXmlAction: {},
};

export default XMLEditor;
