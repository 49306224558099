import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { XaltBox, XaltInputBase, XaltTextLabel, XaltToolTip } from 'xalt-react-atoms';

/**
 * A platform component for building a text area
 */
class RWTextBlock extends PureComponent {
    static propTypes = {
        style: PropTypes.object,
        value: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
            PropTypes.bool,
        ]),
        tipText: PropTypes.string,
        isReadMode: PropTypes.bool,
        isFocused: PropTypes.bool,
        onValueChanged: PropTypes.func,
        xStyle: PropTypes.object,
        maxLength: PropTypes.number,
        propertyRef: PropTypes.object,
        minRows: PropTypes.number,
        leftOverMessage: PropTypes.string,
        leftOverCharacters: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
    };

    static defaultProps = {
        style: {},
    };

    constructor(props) {
        super(props);
        this.textBlockRef = props.propertyRef || React.createRef();
    }

    render() {
        const {
            style,
            value,
            tipText,
            isReadMode,
            isFocused,
            onValueChanged,
            xStyle,
            maxLength,
            leftOverMessage,
            leftOverCharacters,
            minRows } = this.props;

        const {
            input,
            multilineInList,
            nonSaltPropertyBGColor = {} } = xStyle || { input: {} };
        const nonSaltPropBGColor = isReadMode ? {} : nonSaltPropertyBGColor;
        const componentStyle = { lineHeight: 'normal', textOverflow: 'ellipsis', whiteSpace: 'pre-wrap', ...nonSaltPropBGColor, ...style, ...input };

        const {
            display,
            flexGrow,
            flexShrink,
            width,
            height } = style;
        const tipStyle = {
            display: display || 'flex',
            flexGrow,
            flexShrink,
            width,
            height,
            flexDirection: 'column',
        };
        return (
            <XaltBox style={ { flexShrink: 1, flexGrow: 1, ...multilineInList } }>
                <XaltToolTip
                    style={ { ...tipStyle } }
                    tipText={ tipText }>
                    <XaltInputBase
                        style={ componentStyle }
                        disabled={ isReadMode }
                        maxLength={ maxLength }
                        multiline
                        minRows={ minRows }
                        fullWidth
                        onValueChanged={ onValueChanged }
                        readOnly={ isReadMode }
                        tipText={ tipText }
                        value={ value }
                        inputRef={ this.textBlockRef }
                        multilineInList={ multilineInList }
                        autoFocus={ isFocused }>
                        { value }
                    </XaltInputBase>
                    {
                        !multilineInList && leftOverMessage &&
                        <XaltBox style={ { ...componentStyle, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', borderWidth: 0 } }>
                            <XaltTextLabel style={ { ...componentStyle, fontSize: 12, color: leftOverCharacters ? '' : '#CD171A', flexGrow: 0 } }>
                                { leftOverMessage }
                            </XaltTextLabel>
                        </XaltBox>
                    }
                </XaltToolTip>
            </XaltBox>
        );
    }

    componentDidUpdate = () => {
        this.updateTextBlockHeight();
    }

    componentDidMount = () => {
        this.updateTextBlockHeight();
    };

    updateTextBlockHeight = () => {
        const { xStyle } = this.props;
        const { multilineInList } = xStyle || { input: {} };
        if (!multilineInList) {
            this.textBlockRef.current.style.height = 'auto';
            this.textBlockRef.current.style.height = `${this.textBlockRef.current.scrollHeight}px`;
        }
    }
}

export default RWTextBlock;
