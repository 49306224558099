/* @flow */
export default {
    loading: 'Loading...',
    performingAction: 'Performing: {0}',
    pleaseWait: 'Please wait ...',
    pleaseWaitOpening: 'Please wait, opening',
    processingRequest: 'Processing request...',
    saveTenantData: {
        loadingText: 'Saving settings information...',
        detailText: 'Set tenant settings...',
    },
    performingLogin: {
        loadingText: 'Login in process',
        detailText: 'Please wait ...',
    },
    performingOAuthLogin: {
        loadingText: 'Page is being constructed',
        detailText: 'Please wait ...',
    },
    slowConnection: 'The connection to the server is slow.\nPlease wait...',
};
