/* @flow */
export default {
    errors: {
        errorChangingToReadTitle: 'Error changing to read mode',
        errorChangingToWriteTitle: 'Error changing to write mode',
        errorLaunchingTitle: 'Error launching: {0}',
        errorOpenDialogTitle: 'Open Failed',
        errorChangingViewTitle: 'Error changing view',
        errorNoProgramForUrlTitle: 'Cannot open URL',
        errorPerformingActionTitle: 'Error performing action: {0}',
        errorReadingRecordTitle: 'Error reading record',
        errorProcessingPropertyChange: 'Error processing property change',
        errorSavingRecordTitle: 'Error saving record',
        errorMessageNetworkingIssue: 'This is likely a networking issue.',
        errorMessageCheckNetworkConnection: 'Please check your network connection.',
        errorRefreshingQueryTitle: 'A failure occured trying to refresh data on this page. This is likely a networking issue. Please check your network connection.',
        errorRequestingMoreRecordsTitle: 'Error requesting more records',
        noProgramForUrlMsg: 'No program is installed to open url: {0}',
        clientActionNotFound: 'Client action not found: {0}',
        failedToOpenSearchDialog: 'Failed to open search dialog for query dialog: {0}',
        failedToOpenSortModal: 'Failed to open sort modal: {0}',
        failedToFetchCalculateStatistics: 'Failed to fetch calculate statistics for query list',
    },
};
