import React from 'react';
import * as PropTypes from 'prop-types';

import getStyles from './HolyGrailLayout.styles';

const HolyGrailLayout = (props) => {
    const {
        content,
        contextStyles,
        footer,
        header,
        testID,
    } = props;
    const styles = getStyles(contextStyles);
    const mainProps = {
        className: 'l-root-container',
        style: styles.container,
    };
    if (testID) { mainProps['data-test-id'] = `${testID}__hg-layout__container`; }

    const headerProps = {
        className: 'l-header-container',
        style: styles.header,
    };
    if (testID) { headerProps['data-test-id'] = `${testID}__hg-layout__header`; }

    const contentProps = {
        className: 'l-content-container',
        style: styles.content,
    };
    if (testID) { contentProps['data-test-id'] = `${testID}__hg-layout__content`; }

    const footerProps = {
        className: 'l-footer-container',
        style: styles.footer,
    };
    if (testID) { footerProps['data-test-id'] = `${testID}__hg-layout__footer`; }

    return (
        <div { ...mainProps }>
            { header &&
                <header { ...headerProps }>
                    { header }
                </header>
            }
            <main { ...contentProps }>
                { content }
            </main>
            { footer &&
                <footer { ...footerProps }>
                    { footer }
                </footer>
            }
        </div>
    );
};

HolyGrailLayout.defaultProps = {
    contextStyles: {},
};

HolyGrailLayout.propTypes = {
    /** Main content component */
    content: PropTypes.node.isRequired,

    /** contextStyles for branding this page */
    contextStyles: PropTypes.shape({
        /** Main content container styles */
        content: PropTypes.object,

        /** Use to style the content that wraps all sub components. */
        container: PropTypes.object,

        /** Footer container styles */
        footer: PropTypes.object,

        /** Header container styles */
        header: PropTypes.object,
    }),

    /** Footer component */
    footer: PropTypes.node,

    /** Header component */
    header: PropTypes.node,

    /** Id used for testing */
    testID: PropTypes.string,
};

export default HolyGrailLayout;
