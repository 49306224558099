/**
 * *****************************************************
 */
export class TimeValue {
    static fromString(timeString) {
        /* expecting hh:mm:ss.lll */
        const [hours = '0', minutes = '0', secondsPart = '0.0'] = timeString.split(':');
        const [seconds = '0', millis = '0'] = secondsPart.split('.');
        return new TimeValue(Number(hours), Number(minutes), Number(seconds), Number(millis));
    }
    static fromDateValue(dateValue) {
        return new TimeValue(dateValue.getHours(), dateValue.getMinutes(), dateValue.getSeconds(), dateValue.getMilliseconds());
    }
    constructor(hours, minutes, seconds, millis) {
        this.hours = hours;
        this.minutes = minutes;
        this.seconds = seconds;
        this.millis = millis;
    }
    toString() {
        return `${this.pad(this.hours.toString())}:${this.pad(this.minutes.toString())}:${this.pad(this.seconds.toString())}.${this.pad(this.millis.toString(), '000')}`;
    }
    toDateValue() {
        const d = new Date();
        d.setHours(this.hours, this.minutes, this.seconds, this.millis);
        return d;
    }
    pad(s, pad = '00') {
        return (pad + s).substring(s.length);
    }
}
