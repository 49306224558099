import { AnnotationVisitor } from "./AnnotationVisitor";
import { DialogProxyTools } from "./DialogProxyTools";
/**
 *
 */
export class PropertyVisitor {
    constructor(value) {
        if (!value) {
            throw new Error('PropertyVisitor -- null value exception');
        }
        if (typeof value === 'string') {
            this._enclosedJsonObject = JSON.parse(value);
        }
        else {
            this._enclosedJsonObject = value;
        }
        if (!DialogProxyTools.isPropertyModel(this._enclosedJsonObject)) {
            throw new Error("Object passed to PropertyVisitor is not a Property");
        }
    }
    // --- State Management Helpers --- //
    // --- State Import/Export --- //
    copyAsJsonObject() {
        return JSON.parse(this.copyAsJsonString());
    }
    copyAsJsonString() {
        return JSON.stringify(this.enclosedJsonObject());
    }
    enclosedJsonObject() {
        return this._enclosedJsonObject;
    }
    // --- State Management --- //
    *visitAnnotations() {
        let index = 0;
        while (index < this.enclosedJsonObject().annotations.length) {
            yield new AnnotationVisitor(this.enclosedJsonObject().annotations[index++]);
        }
    }
    format() {
        return this.enclosedJsonObject().format;
    }
    name() {
        return this.enclosedJsonObject().name;
    }
    type() {
        return this.enclosedJsonObject().type;
    }
    value() {
        return this.enclosedJsonObject().value;
    }
}
