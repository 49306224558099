// Core component styles
const coreStyles = {
    anchorRow: {
        display: 'flex',
        flexDirection: 'row',
        flex: '1 0 1',
        overflowY: 'auto',
        overflowX: 'auto',
    },

    anchorScrollColumn: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 0 0',
        overflowY: 'auto',
    },

    mainContentRowSizingContainer: {
        display: 'flex',
        flexDirection: 'row',
        flex: '1 0 auto',
        overflowY: 'hidden',
        overflowX: 'auto',
    },

    column: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 0 0',
        overflow: 'hidden',
        maxHeight: '90vh',
    },
};

/**
 * Helper method to get the styles and merge with context styles
 * @param {Object} contextStyles
 */
const getStyles = (contextStyles = {}) => {
    return {
        anchorRow: {
            ...coreStyles.anchorRow,
            ...contextStyles.anchorRow,
        },

        anchorScrollColumn: {
            ...coreStyles.anchorScrollColumn,
            ...contextStyles.anchorScrollColumn,
        },

        mainContentRowSizingContainer: {
            ...coreStyles.mainContentRowSizingContainer,
            ...contextStyles.mainContentRowSizingContainer,
        },

        column: {
            ...coreStyles.column,
            ...contextStyles.column,
        },
    };
};

export default getStyles;
