import { action, observable } from 'mobx';

export default class ScopeManager {
    @observable globalScope = new Map();
    @observable localScope = new Map();

    constructor(globalScope, localScope) {
        if (globalScope) this.globalScope = globalScope;
        if (localScope) this.localScope = localScope;
    }

    getRef(name) {
        const ref = this.localScope.get(name);
        const resolved = ref || this.globalScope.get(name);
        return resolved || undefined;
    }

    @action setGlobalRef = (name, value) => {
        this.globalScope.set(name, value);
    };

    @action newScopeWithLocal = (name, value) => {
        const localScope = observable(new Map([ ...this.localScope, [ name, value ] ]));
        return new ScopeManager(this.globalScope, localScope);
    };

    // @TODO consider upgrading mobx so we don't have to do this
    // newer versions of mobx support observing key additions to plain objects
    get allRefs() {
        const globals = {};
        [ ...this.globalScope ].forEach((pair) => {
            const [ key, val ] = pair;
            globals[key] = val;
        });
        const locals = {};
        [ ...this.localScope ].forEach((pair) => {
            const [ key, val ] = pair;
            locals[key] = val;
        });
        return { ...globals, ...locals };
    }

    // special scope values
    static SCOPED_PROPERTY_REF_NAME = '$scope_property';
    static SCOPED_RECORD_REF_NAME = '$scope_record';
    static SCOPED_INDEX_REF_NAME = '$scope_index';
}
