import PropTypes from 'prop-types';
import borderRadius from './borderRadius';
import lineWidth from './lineWidth';
import margin from './margin';
import padding from './padding';
import expand from './expand';
import width from './width';
import size from './size';

export default class Layout {
    static layoutPropType = PropTypes.shape({
        layout: PropTypes.shape({
            alignItems: PropTypes.oneOf([ 'center', 'flex-start', 'flex-end', 'left', 'right', 'top', 'bottom' ]),
            borderRadius: PropTypes.string,
            expand: PropTypes.oneOf([ 'horizontal', 'vertical', 'both' ]),
            height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
            justifyContent: PropTypes.oneOf([ 'center', 'flex-start', 'flex-end', 'left', 'right', 'full', 'even' ]),
            margin: PropTypes.string,
            padding: PropTypes.string,
            width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
        }),
    });

    static combineStyles(styles1, styles2) {
        if (!styles1) return styles2;
        if (!styles2) return styles1;
        if (Array.isArray(styles1)) {
            if (Array.isArray(styles2)) {
                return [ ...styles1, ...styles2 ].reduce(Layout.mergeLeft);
            }
            return [ ...styles1, styles2 ].reduce(Layout.mergeLeft);
        } if (Array.isArray(styles2)) {
            return [ styles1, ...styles2 ].reduce(Layout.mergeLeft);
        }
        return { ...styles1, ...styles2 };
    }

    static getStyleForLayout(layout, parentNode) {
        if (layout) {
            return {
                ...borderRadius(layout),
                ...lineWidth(layout, parentNode),
                ...margin(layout),
                ...expand(layout, parentNode),
                ...padding(layout),
                ...width(layout),
                ...size(layout),
            };
        }
        return {};
    }

    static parseMultiPartValue(input) {
        let multi = [];
        if (typeof input === 'number') {
            multi = [ input ];
        } else if (typeof input === 'string') {
            // Handle the multi-part values.
            multi = input.split(',').map(m => m.trim());
        }
        return multi;
    }

    static parseValue(input) {
        if (typeof input === 'string') {
            if (input.indexOf('%') > -1) {
                return input;
            }
        }
        return parseFloat(input);
    }

    static mergeLeft = (current, next) => {
        return { ...current, ...next };
    };
}
