/*       */
import StyleSet from '../StyleSet';
import AbstractModel from './AbstractModel';
import BarChart from './BarChart';
import Border from './Border';
import Grid from './Grid';
import FormUtil from '../FormUtil';
import Label from './Label';
import Image from './Image';
import { GRIDS } from '../ModelCache';
import UnknownComponent from './UnknownComponent';
import TextField from './TextField';
import DateTimePicker from './DateTimePicker';
import ValuePicker from './ValuePicker';
import TextArea from './TextArea';
import Button from './Button';
import CheckBox from './CheckBox';
import GaugeChart from './GaugeChart';
import Signature from './Signature';

export default class Cell extends AbstractModel {
    constructor(json, grid) {
        super(json);
        this.init(grid);
    }
    get borderWidthTop() { return this.floatAt('BorderWidths.Top'); }
    get borderWidthLeft() { return this.floatAt('BorderWidths.Left'); }
    get borderWidthBottom() { return this.floatAt('BorderWidths.Bottom'); }
    get borderWidthRight() { return this.floatAt('BorderWidths.Right'); }
    get grids() {
        return this.cache.get(GRIDS,
            () => { return this.childrenOfType('Grid').map(m => new Grid(m, this.width, this.height)); });
    }
    get layoutColumn() { return this.floatAt('Layout.Column'); }
    get layoutRow() { return this.floatAt('Layout.Row'); }
    asSalt = (parentContext) => {
        const myContext = this.updateContext(parentContext);
        const borders = this.buildBorders();
        const styleSet = new StyleSet({
            paperId: this.id,
            position: 'absolute',
            top: this.top,
            left: this.left,
            width: this.width,
            height: this.height,
            backgroundColor: this.color('BackgroundColor'),
        });
        let children = this.allChildren().map(m => this.asModel(m));
        children = [ ...children, ...borders ];
        // If there are no children and no background color, the cell does not need to be drawn.
        if (!children.length && !styleSet.style.backgroundColor) {
            return null;
        }
        return {
            box: {
                ...styleSet.asStyleAttribute(),
                ...styleSet.asXStyleAttribute(),
                children: children.map(m => m.asSalt(myContext)),
            },
        };
    }

    asModel(json) {
        const name = FormUtil.nameOf(json);
        let model;
        switch (name) {
            case 'BarChart':
                model = new BarChart(json, this);
                break;
            case 'Button':
                model = new Button(json, this);
                break;
            case 'CheckBox':
                model = new CheckBox(json, this);
                break;
            case 'DatePicker':
                model = new DateTimePicker(json, this);
                break;
            case 'GaugeChart':
                model = new GaugeChart(json, this);
                break;
            case 'Grid':
                model = new Grid(json, this.width, this.height);
                break;
            case 'Image':
                model = new Image(json, this);
                break;
            case 'Label':
                model = new Label(json, this);
                break;
            case 'SignatureCapture':
                model = new Signature(json, this);
                break;
            case 'TextArea':
                model = new TextArea(json, this);
                break;
            case 'TextField':
                model = new TextField(json, this);
                break;
            case 'TimePicker':
                model = new DateTimePicker(json, this);
                break;
            case 'ValuePicker':
                model = new ValuePicker(json, this);
                break;
            default:
                model = new UnknownComponent(json, this);
                break;
        }
        return model;
    }

    buildBorders() {
        const { width, height } = this;
        const borderColor = this.color('BorderColor') || 'black';
        const borders = [];
        let bw = this.borderWidthTop;
        let bw2 = bw / 2;
        if (bw) {
            borders.push(new Border(borderColor, 0 - bw2, 0 - bw2, width + bw, bw));
        }
        bw = this.borderWidthLeft;
        bw2 = bw / 2;
        if (bw) {
            borders.push(new Border(borderColor, 0 - bw2, 0 - bw2, bw, height + bw));
        }
        bw = this.borderWidthBottom;
        bw2 = bw / 2;
        if (bw) {
            borders.push(new Border(borderColor, 0 - bw2, height - bw2, width + bw, bw));
        }
        bw = this.borderWidthRight;
        bw2 = bw / 2;
        if (bw) {
            borders.push(new Border(borderColor, width - bw2, 0 - bw2, bw, height + bw));
        }
        return borders;
    }

    init(grid) {
        // Identify the width and height of this cell.
        this.width = grid.layoutSizeWidths[this.layoutColumn];
        this.height = grid.layoutSizeHeights[this.layoutRow];

        // Identify the top/left corner of this cell in the parent grid.
        const col = this.layoutColumn;
        const cols = grid.layoutSizeWidths;
        const row = this.layoutRow;
        const rows = grid.layoutSizeHeights;
        this.top = 0;
        this.left = 0;
        for (let i = 0; i < row; i += 1) { this.top += rows[i]; }
        for (let i = 0; i < col; i += 1) { this.left += cols[i]; }
    }
}
