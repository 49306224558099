import SimpleStringAttribute from './SimpleStringAttribute';

/**
 * A LayoutAttribute is a style attribute that can be exported to a style object.
 */
export default class SimpleFloatAttribute {
    name ;
    value ;
    exportClass ;
    isCascading ;
    overrideName ;
    constructor(
        name,
        value,
        exportClass,
        isCascading = false,
        overrideName,
    ) {
        this.name = name;
        this.value = value;
        this.exportClass = exportClass;
        this.isCascading = isCascading;
        this.overrideName = overrideName || name;
    }

    exportTo(context, styleSet) {
        const { style } = styleSet;
        if (SimpleStringAttribute.hasConstantRef(this.value)) {
            SimpleStringAttribute.exportConstantAsRef(style, this.overrideName, this.value);
        } else {
            style[this.overrideName] = parseFloat(this.value);
        }
    }
}
