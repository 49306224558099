import { rootStore } from 'cv-react-core';

// Core component styles
const coreStyles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100px',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100px',
        backgroundColor: '#F2F2F2',
    },
    root: {
        backgroundColor: '#FFFFFF',
    },
    bar: {
    },
    labelContainer: {
        paddingLeft: '5px',
    },
    labelText: {
        fontSize: '12px',
        marginTop: '6px',
    },
};

const getStyles = (contextStyles = {}) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();
    return {
        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },
        root: {
            ...coreStyles.root,
            ...contextStyles.root,
        },
        bar: {
            ...coreStyles.bar,
            ...contextStyles.bar,
            backgroundColor: theme.statusLoadingIndicatorColor,
        },
        labelContainer: {
            ...coreStyles.labelContainer,
            ...contextStyles.labelContainer,
        },
        labelText: {
            ...coreStyles.labelText,
            ...contextStyles.labelText,
        },
    };
};

export default getStyles;