/**
 * NOTE: Had to write this class because we are back-level and cannot make use of modern iterators.
 */
export class ValueIterator {
    constructor(array) {
        this._nextIndex = 0;
        this._array = array;
    }
    done() {
        return this._nextIndex >= this._array.length;
    }
    next() {
        return this._array[this._nextIndex++];
    }
}
