import { BlobClientResponse } from '../client/BlobClientResponse';
import { JsonClientResponse } from '../client/JsonClientResponse';
import { ReadableStreamClientResponse } from '../client/StreamingClientResponse';
import { TextClientResponse } from '../client/TextClientResponse';
import { VoidClientResponse } from '../client/VoidClientResponse';
import { Log } from '../util';
import { LogLabels } from '../constants';
const logColor = Log.deepskyblue;
export class FetchClient {
    constructor() {
        this._lastActivity = new Date();
    }
    isAnyUserInBriefcaseMode(tenantId) {
        return Promise.resolve(false);
    }
    isUserInBriefcaseMode(userInfo) {
        return Promise.resolve(false);
    }
    addClientListener(clientListener, locale) {
        this._clientListener = clientListener;
        this._locale = locale;
    }
    removeClientListener(clientListener) {
        this._clientListener = null;
    }
    getBlob(baseUrl, resourcePath) {
        const url = resourcePath ? `${baseUrl}/${resourcePath}` : baseUrl;
        return this.processRequest(url, 'GET').then((response) => {
            return response.blob().then(blob => new BlobClientResponse(blob, response.status));
        });
    }
    getText(baseUrl, resourcePath) {
        const url = resourcePath ? `${baseUrl}/${resourcePath}` : baseUrl;
        return this.processRequest(url, 'GET').then((response) => {
            return response.text().then(text => new TextClientResponse(text, response.status));
        });
    }
    openStream(baseUrl, resourcePath) {
        const url = resourcePath ? `${baseUrl}/${resourcePath}` : baseUrl;
        return this.processRequest(url, 'GET').then((response) => {
            return Promise.resolve(new ReadableStreamClientResponse(response.body, response.status));
        });
    }
    postMultipart(baseUrl, resourcePath, formData) {
        const url = resourcePath ? `${baseUrl}/${resourcePath}` : baseUrl;
        return this.processRequest(url, 'POST', formData).then((response) => {
            return new VoidClientResponse(response.status);
        });
    }
    getJson(baseUrl, resourcePath, queryParams) {
        const headers = { Accept: 'application/json' };
        const queryString = this.encodeQueryParams(queryParams);
        const url = resourcePath ? `${baseUrl}/${resourcePath}${queryString}` : `${baseUrl}${queryString}`;
        return this.processRequest(url, 'GET', null, headers).then((response) => {
            return this.assertJsonContentType(response.headers.get('content-type')).then(() => {
                return response.json().then(json => {
                    Log.debug(LogLabels.SDK_CLIENT, `getJson response: [status]:${response.status} [body]:`, json, logColor);
                    return new JsonClientResponse(json, response.status);
                });
            });
        });
    }
    get lastActivity() {
        return this._lastActivity;
    }
    postJson(baseUrl, resourcePath, jsonBody) {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
        };
        const body = jsonBody && JSON.stringify(jsonBody);
        const url = resourcePath ? `${baseUrl}/${resourcePath}` : baseUrl;
        return this.processRequest(url, 'POST', body, headers).then((response) => {
            return this.assertJsonContentType(response.headers.get('content-type')).then(() => {
                return response.json().then(json => {
                    Log.debug(LogLabels.SDK_CLIENT, `postJson response: [status]:${response.status} [body]:`, json, logColor);
                    return new JsonClientResponse(json, response.status);
                });
            });
        });
    }
    putJson(baseUrl, resourcePath, jsonBody) {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
        };
        const body = jsonBody && JSON.stringify(jsonBody);
        const url = resourcePath ? `${baseUrl}/${resourcePath}` : baseUrl;
        return this.processRequest(url, 'PUT', body, headers).then((response) => {
            return this.assertJsonContentType(response.headers.get('content-type')).then(() => {
                return response.json().then(json => {
                    Log.debug(LogLabels.SDK_CLIENT, `putJson response: [status]:${response.status} [body]:`, json, logColor);
                    return new JsonClientResponse(json, response.status);
                });
            });
        });
    }
    deleteJson(baseUrl, resourcePath) {
        const headers = { Accept: 'application/json' };
        const url = resourcePath ? `${baseUrl}/${resourcePath}` : baseUrl;
        return this.processRequest(url, 'DELETE', null, headers).then((response) => {
            return this.assertJsonContentType(response.headers.get('content-type')).then(() => {
                return response.json().then(json => {
                    Log.debug(LogLabels.SDK_CLIENT, `deleteJson response: [status]:${response.status} [body]:`, json, logColor);
                    return new JsonClientResponse(json, response.status);
                });
            });
        });
    }
    fetch(url, method, body, headers, fetchOpts) {
        return this.processRequest(url, method, body, headers, fetchOpts);
    }
    assertJsonContentType(contentType) {
        return new Promise((resolve, reject) => {
            if (this.isJsonContentType(contentType)) {
                resolve();
            }
            else {
                reject(new Error(`Expected 'application/json', got ${contentType}`));
            }
        });
    }
    encodeQueryParams(queryParams) {
        let result = '';
        if (queryParams) {
            for (const name in queryParams) {
                if (queryParams.hasOwnProperty(name)) {
                    result += `${encodeURIComponent(name)}=${encodeURIComponent(queryParams[name])}&`;
                }
            }
        }
        return result.length > 0 ? `?${result.slice(0, -1)}` : result;
    }
    isJsonContentType(contentType) {
        return contentType && contentType.includes('application/json');
    }
    processRequest(url, method, body, headers, fetchOpts) {
        return new Promise((resolve, reject) => {
            const requestHeaders = new Headers(headers);
            const init = { method, mode: 'cors' };
            if (body) {
                init.body = body;
            }
            if (headers) {
                init.headers = new Headers(headers);
            }
            if (fetchOpts) {
                Object.assign(init, fetchOpts);
            }
            if (!['GET', 'POST', 'PUT', 'DELETE'].some(v => method === v)) {
                reject(new Error(`FetchClient::processRequest: Unsupported method: ${method}`));
            }
            else {
                const start = Date.now();
                Log.debug(LogLabels.SDK_CLIENT, `Fetch request: ${method} ${url} [body]:${body ? body : 'none'}`, logColor);
                fetch(url, init)
                    .then(response => {
                    this._lastActivity = new Date();
                    // Log.debug(`perf::Fetch request took: ${ Date.now() - start } ms`, logColor);
                    // Log.debug('FetchClient response: ', response, logColor);
                    resolve(response);
                })
                    .catch(error => {
                    this._lastActivity = new Date();
                    reject(error);
                });
            }
        });
    }
}
