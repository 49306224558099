import { action, computed, observable } from 'mobx';

export default class AvailableValuesStore {
    @observable availableValuesByPropName = {};

    getAvailableValues(propName) {
        if (!this.availableValuesByPropName[propName]) {
            // this is necessary to initialize and make the new array observable
            // this.setAvailableValues(propName, []);
            // TODO: Need to check if we can short circute side effects update from calling this store with every property,
            // even properties without available values. It causes issues with the dialog server if we call for available values
            // on a property that doesn't allow it. Should be able to look at the props and prevent the lookup.
            // Returning an empty array. The assumption here is if we do not have the property
            // set, we are still loading. The setAvailableValues will load ther property and set the observable.
            // The issue of setting the property and an empty array caused issues when sideeffetcs ran becaues we 
            // reinitialized the record which pushed each property into this stack. Then each property tried to fetch
            // available values causing a null exception.
            return [];
        }
        return this.availableValuesByPropName[propName];
    }

    @action setAvailableValues(propName, availableValues) {
        const currentValues = this.availableValuesByPropName[propName];
        if (!currentValues) {
            this.availableValuesByPropName[propName] = availableValues;
        } else {
            // Note! this has to be done 'in-place' because the array is being observed
            // i.e. this must be a mutation.  don't replace this array
            currentValues.splice(0, currentValues.length, ...availableValues);
        }
    }

    @computed get propNames() {
        return Object.keys(this.availableValuesByPropName);
    }
}
