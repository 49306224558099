import { TypeNames } from 'cv-dialog-sdk';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import engineConstants from './engineConstants';
import RefUtil from './ref/RefUtil';
import SaltComponent from './SaltComponent';
import SaltContext from './SaltContext';
import ScopeManager from './ScopeManager';
import DetailsIterator from './DetailsIterator';

@observer
export default class Iterator extends SaltComponent {
    static propTypes = {
        match: PropTypes.arrayOf(PropTypes.string),
        type: PropTypes.oneOf([ 'property', 'all-properties', 'rows-columns' ]),
        order: PropTypes.oneOf([ 'match', 'default' ]),
        children: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.arrayOf(PropTypes.element),
        ]),
        viewId: PropTypes.string,
    };

    static typeName = engineConstants.component.name.iterator;

    render() {
        const { type } = this.props;
        const { saltStore } = this.context;
        const viewId = this.getViewId(this.props.viewId);
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        if (dialogStore && dialogStore.dataChange) {
            const { dialog: { view } } = dialogStore;
            if (type === 'property' || type === 'all-properties') {
                return this.renderProperty();
            }
            if (type === 'rows-columns' && view.type === TypeNames.DetailsTypeName) {
                return <DetailsIterator { ...this.props } />;
            }
        }
        return null;
    }

    /*
        For each propName matched, clone the child elements and set the propName
        in local scope, to be accessed by the child elements
        Note: we don't have to do a 'deep' cloneElement here because at this stage,
        the component instances have not actually been created. (that is, on first render)
        They are 'wrapper' React element classes that 'know' how to instantiate the component on first render
        Deep clone is necessary only when a component has been rendered once already
     */
    renderProperty() {
        const { match, order, type, children } = this.props;
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore, scopeManager } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        const record = dialogStore.record || scopeManager.getRef(ScopeManager.SCOPED_RECORD_REF_NAME);
        if (record) {
            let { propNames } = record;
            // if no type or type = property, use the properties defined on the view (details or columns)
            if (!type || type === 'property') {
                const { dialog: { view } } = dialogStore;
                // view.getAttributeCellValue(propName);
                if (view.type === TypeNames.DetailsTypeName) {
                    propNames = view.propertyNames;
                } else if (view.type === TypeNames.ListTypeName) {
                    propNames = view.columns.map(column => column.propertyName);
                }
            }
            const matches = (order && order === 'match') ? RefUtil.getMatchesByPattern(match || [ '/.*/' ], propNames)
                : RefUtil.getMatchesByTarget(match || [ '/.*/' ], propNames);
            if (matches && matches.length > 0) {
                return matches.map((propName, index) => {
                    const newContext = {
                        ...this.context,
                        scopeManager:
                            scopeManager.newScopeWithLocal(ScopeManager.SCOPED_PROPERTY_REF_NAME, record.propAtName(propName))
                                .newScopeWithLocal(ScopeManager.SCOPED_INDEX_REF_NAME, index),
                    };
                    return (
                        <SaltContext.Provider
                            // eslint-disable-next-line react/no-array-index-key
                            key={ propName }
                            value={ newContext }>
                            {children}
                        </SaltContext.Provider>
                    );
                });
            }
            return null;
        }
        return null;
    }
}

Iterator.contextType = SaltContext;
