import { RecordVisitor } from "../proxy/RecordVisitor";
/**
 *
 */
export class OfflineItemsVisitor extends RecordVisitor {
    constructor(value) {
        super(value);
    }
    // --- State Management Helpers --- //
    // --- State Management --- //
    visitBriefcase() {
        return this.visitPropertyValueAt(OfflineItemsVisitor.BRIEFCASE_PROPERTY_NAME);
    }
    visitClassification() {
        return this.visitPropertyValueAt(OfflineItemsVisitor.CLASSIFICATION_PROPERTY_NAME);
    }
    visitConfig() {
        return this.visitPropertyValueAt(OfflineItemsVisitor.CONFIG_PROPERTY_NAME);
    }
    visitContract() {
        return this.visitPropertyValueAt(OfflineItemsVisitor.CONTRACT_PROPERTY_NAME);
    }
    visitCreationDate() {
        return this.visitPropertyValueAt(OfflineItemsVisitor.CREATION_DATE_PROPERTY_NAME);
    }
    visitCreationUser() {
        return this.visitPropertyValueAt(OfflineItemsVisitor.CREATION_USER_PROPERTY_NAME);
    }
    visitDescription() {
        return this.visitPropertyValueAt(OfflineItemsVisitor.DESCRIPTION_PROPERTY_NAME);
    }
    visitDisciplines() {
        return this.visitPropertyValueAt(OfflineItemsVisitor.DISCIPLINES_PROPERTY_NAME);
    }
    visitId() {
        return this.visitPropertyValueAt(OfflineItemsVisitor.ID_PROPERTY_NAME);
    }
    visitLastUpdateDate() {
        return this.visitPropertyValueAt(OfflineItemsVisitor.LAST_UPDATE_DATE_PROPERTY_NAME);
    }
    visitName() {
        return this.visitPropertyValueAt(OfflineItemsVisitor.NAME_PROPERTY_NAME);
    }
    visitOrganizationsGroup() {
        return this.visitPropertyValueAt(OfflineItemsVisitor.ORGANIZATIONS_GROUP_PROPERTY_NAME);
    }
    visitOwningGroup() {
        return this.visitPropertyValueAt(OfflineItemsVisitor.OWNING_GROUP_PROPERTY_NAME);
    }
    visitUid() {
        return this.visitPropertyValueAt(OfflineItemsVisitor.UID_PROPERTY_NAME);
    }
}
OfflineItemsVisitor.BRIEFCASE_PROPERTY_NAME = "briefcase";
OfflineItemsVisitor.CLASSIFICATION_PROPERTY_NAME = "Classification";
OfflineItemsVisitor.CONFIG_PROPERTY_NAME = "Config";
OfflineItemsVisitor.CONTRACT_PROPERTY_NAME = "Contract";
OfflineItemsVisitor.CREATION_DATE_PROPERTY_NAME = "Creation_Date";
OfflineItemsVisitor.CREATION_USER_PROPERTY_NAME = "Creation_User";
OfflineItemsVisitor.DESCRIPTION_PROPERTY_NAME = "Description";
OfflineItemsVisitor.DISCIPLINES_PROPERTY_NAME = "Disciplines";
OfflineItemsVisitor.ID_PROPERTY_NAME = "Id";
OfflineItemsVisitor.LAST_UPDATE_DATE_PROPERTY_NAME = "Last_Update_Date";
OfflineItemsVisitor.NAME_PROPERTY_NAME = "Name";
OfflineItemsVisitor.ORGANIZATIONS_GROUP_PROPERTY_NAME = "Organizations";
OfflineItemsVisitor.OWNING_GROUP_PROPERTY_NAME = "Owning_Group";
OfflineItemsVisitor.UID_PROPERTY_NAME = "UID";
