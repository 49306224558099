/*       */

import StyleSet from '../StyleSet';
import { pageSeparatorExtraWidth } from '../paperConstants';

export default class PageSeparator {
    constructor(currentPage, pageIndex, totalPages, height) {
        this.currentPageIndex = pageIndex;
        this.currentPage = currentPage;
        this.totalPages = totalPages;
        this.height = height;
    }
    asSalt(parentContext) {
        const width = parentContext.form.layoutSizeWidth + pageSeparatorExtraWidth;
        const top = this.currentPage.top + this.currentPage.height;
        const styleSet = new StyleSet({
            position: 'absolute',
            flexDirection: 'row',
            left: 0, // -(pageSeparatorExtraWidth / 2),
            top,
            width,
            height: this.height,
            backgroundColor: 'lightGrey',
            alignItems: 'center',
            justifyContent: 'center',
        });

        const children = this.buildPlaceholders();

        return {
            box: {
                ...styleSet.asStyleAttribute(),
                ...styleSet.asXStyleAttribute(),
                children,
            },
        };
    }

    buildPlaceholders() {
        // If there are more than 10 pages, just return 2 generic page placeholders.
        if (this.totalPages > 10) {
            return [ this.otherPagePlaceholder(), this.otherPagePlaceholder() ];
        }

        // If there are 10 or fewer total pages, create a placeholder for each page, with a special
        // placeholder representing the current page: (i.e. page 3 of 4   o o ^ o )
        const result = [];
        for (let i = 0; i < this.totalPages; i += 1) {
            if (i === this.currentPageIndex) {
                result.push(this.currentPagePlaceHolder());
            } else {
                result.push(this.otherPagePlaceholder());
            }
        }
        return result;
    }

    currentPagePlaceHolder() {
        // const iconName = 'icon:flare';
        // const iconName = 'icon:keyboard_arrow_up';
        const iconName = 'icon:arrow_drop_up';
        return {
            image: {
                'resize-mode': 'contain',
                source: iconName,
                style: { color: 'grey', marginLeft: 0, marginRight: 0 },
                iconProps: { iconSize: 30 },
            },
        };
    }
    otherPagePlaceholder() {
        // const iconName = 'icon:radio_button_unchecked'; // hollow circle
        const iconName = 'icon:lens'; // Solid circle
        return {
            image: {
                'resize-mode': 'contain',
                source: iconName,
                style: { color: 'grey', marginLeft: 4, marginRight: 4 },
                iconProps: { iconSize: 10 },
            },
        };
    }
}
