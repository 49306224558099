/**
 */
export class MobileComment_CommentNotAvailable_RECORD {
    static copyOfResponse() {
        return JSON.parse(JSON.stringify(this.RESPONSE));
    }
}
MobileComment_CommentNotAvailable_RECORD.PATH = 'tenants/${tenantId}/sessions/${sessionId}/dialogs/16/record';
MobileComment_CommentNotAvailable_RECORD.RESPONSE = {
    "dialogAlias": "MobileComment_CommentNotAvailable",
    "annotations": [],
    "id": null,
    "type": "hxgn.api.dialog.Record",
    "dialogName": "MobileComment_CommentNotAvailable",
    "properties": []
};
