import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { rootStore, constants } from 'cv-react-core';

import {
    XaltBox,
    XaltInputBase,
    XaltSearch,
    XaltIconButton,
    XaltClear,
} from 'xalt-react-atoms';
import LauncherList from '../../base/LauncherList';
import TextLabel from '../../base/TextLabel';
import WorkbenchMenu from './WorkbenchMenu';
import Navigator from '../../../routing/Navigator';

import getStyles from './WorkbenchPage.styles';

const { ui } = constants;
const {
    WORKBENCH_UI_OBJECT_ID,
    WORKBENCH_PAGE_INITIAL_ACTION_TAKEN,
} = ui;

const IN_PROGRESS = 1;
const IS_INITIAL_LAUNCH_ACTION = true;

/**
 * WorkbenchPage contains launchers defined by the Dashboard configuration tools
 */
@observer
class WorkbenchPage extends Component {
    static defaultProps = {
        contextStyles: {},
    };

    static propTypes = {
        /** ContextStyles for branding this page */
        contextStyles: PropTypes.shape({
            /** Styles for the main wrapping container */
            container: PropTypes.object,

            /** Styles for the workbench launcher content container */
            content: PropTypes.object,

            /** Styles for the launcher list item container */
            launcher: PropTypes.object,

            /** Styles for the launcher list title container*/
            launcherTitleContainer: PropTypes.object,

            /** Styles for the launcher list title*/
            launcherTitle: PropTypes.object,

            /** Styles for the launcher list item icon */
            launcherIcon: PropTypes.object,

            /** Styles for the launcher list container */
            launcherList: PropTypes.object,

            /** Styles for the launcher list item text */
            launcherText: PropTypes.object,

            /** Styles for the workbench launcher navigation container */
            navigation: PropTypes.object,
        }),

        navigator: PropTypes.instanceOf(Navigator),

        /** Calls the handle launcher open event on container components */
        onOpenLauncher: PropTypes.func,

        /** workbenchStore that contains data to populate launchers */
        workbenchStore: PropTypes.object,
    };

    static StateIsGrid = 'WorkbenchPageIsGrid';

    static StateActiveLauncherId = 'WorkbenchPageActiveLauncherId';

    constructor(props) {
        super(props);
        const {
            sessionStore,
            uiStore,
        } = rootStore;
        const {
            initialAction,
            workbenches,
        } = sessionStore;

        // Default to launcher list grid view
        uiStore.setValueForUIObject(WORKBENCH_UI_OBJECT_ID, WorkbenchPage.StateIsGrid, true);

        // Clear workbench filter/search. This is to handle changing between workbenches.
        this.handleClearFilter();

        // If session contains an initial action we will perform that on each login. This should
        // only be done once per login based on the current requirements. If no initial action is defined
        // we don't do anything.
        if (initialAction !== undefined
            && (!uiStore.getValueForUIObject(WORKBENCH_UI_OBJECT_ID, WORKBENCH_PAGE_INITIAL_ACTION_TAKEN))) {
            const launcher = { ...initialAction };

            // @TODO: Fix this in the SDK. Currently returns empty workbenchId when looking at initialAction.
            // Therefore we have to loop through each workbench and it's action to find the correct workbenchId
            for (let i = 0; i < workbenches.length; i += 1) {
                const workbench = workbenches[i];
                const {
                    actions,
                } = workbench;
                const foundAction = actions.find((action) => {
                    const {
                        actionId,
                    } = action;
                    return actionId === initialAction.actionId;
                });
                if (foundAction) {
                    const {
                        workbenchId,
                    } = foundAction;
                    if (workbenchId !== '') {
                        launcher.workbenchId = workbenchId;
                        break;
                    }
                }
            }
            this.performWorkbenchAction(launcher, IS_INITIAL_LAUNCH_ACTION);
            uiStore.setValueForUIObject(WORKBENCH_UI_OBJECT_ID, WORKBENCH_PAGE_INITIAL_ACTION_TAKEN, IN_PROGRESS);
        }
    }

    render() {
        return this.renderWorkbenches();
    }

    renderWorkbenches() {
        const {
            contextStyles,
            navigator,
        } = this.props;
        const {
            uiStore,
        } = rootStore;
        const {
            workbenchStore,
        } = this.props;

        const isGrid = uiStore.getValueForUIObject(WORKBENCH_UI_OBJECT_ID, WorkbenchPage.StateIsGrid);
        const activeLauncherId = uiStore.getValueForUIObject(WORKBENCH_UI_OBJECT_ID, WorkbenchPage.StateActiveLauncherId);
        const styles = getStyles(contextStyles);
        const { name: workbenchName } = workbenchStore.getSanitizedWorkbench();

        // Generate container props
        const containerProps = {
            className: 'p-workbench-page',
            style: styles.container,
        };

        // Generate launcher list props
        const launcherListProps = {
            contextStyles: {
                container: styles.launcherList,
                launcher: styles.launcher,
                launcherIcon: styles.launcherIcon,
                launcherText: styles.launcherText,
            },
            gridView: isGrid,
            launchers: workbenchStore.filteredActions,
            testID: containerProps.className,
        };

        // If there is no activeLauncherId, then add the click event
        // ignoring the click handler if one exists
        if (!activeLauncherId) {
            launcherListProps.onLauncherClick = this.handleLauncherClick;
        }

        // TODO: replace div with BasePage layout
        return (
            <div { ...containerProps }>
                <div style={ styles.titleContainer }>
                    <div style={ styles.menuContainer }>
                        <WorkbenchMenu navigator={ navigator } />
                        <TextLabel
                            contextStyles={ {
                                container: styles.launcherTitleContainer,
                                text: styles.launcherTitle,
                            } }>
                            { workbenchName }
                        </TextLabel>
                    </div>
                    { this.renderSearchComponent(workbenchStore) }
                </div>
                <LauncherList
                    filteredValue={ workbenchStore.filterBy }
                    { ...launcherListProps } />
            </div>
        );
    }

    renderSearchComponent = (workbenchStore) => {
        const { contextStyles } = this.props;
        const styles = getStyles(contextStyles);
        return (
            <XaltBox
                style={ styles.searchContainer }
                focusStyle={ { borderColor: '#1C8EA9' } }
                hoverStyle={ { borderColor: '#1C8EA9' } }>
                <XaltInputBase
                    type="text"
                    style={ { width: '300px' } }
                    placeholder="Search"
                    startAdornment={ this.renderSearchIcon() }
                    endAdornment={ this.renderClearIcon(workbenchStore) }
                    value={ workbenchStore.filterBy }
                    onValueChanged={ this.handleSearchTextChange } />
            </XaltBox>
        );
    };

    renderSearchIcon = () => (
        <XaltIconButton
            style={ { padding: '8px' } }
            disabled>
            <XaltSearch />
        </XaltIconButton>
    );

    renderClearIcon = (workbenchStore) => (
        workbenchStore.filterBy.length ?
            <XaltIconButton
                style={ { marginRight: '12px', padding: '8px' } }
                onClick={ this.handleClearFilter }>
                <XaltClear />
            </XaltIconButton> : null
    );

    handleSearchTextChange = (text) => {
        const { workbenchStore } = this.props;
        workbenchStore.setFilter(text);
    }

    handleClearFilter = () => {
        const { workbenchStore } = this.props;
        workbenchStore.setFilter('');
    };

    handleLauncherClick = (launcher, nativeEvent) => {
        const modifiers = nativeEvent.altKey ? { [constants.transitionModifiers.OPEN_IN_TAB]: true } : undefined;
        this.performWorkbenchAction(launcher, false, modifiers);
    };

    /**
     * Performs the necessary workbench action based on launcher content. This method
     * will perform the described launcher workbench action based on workbench id and
     * transition to that workbench. If failure occurs we raise the onError page.
     * @param {object} launcher launcher data
     * @param {boolean} isInitialLaunchAction true if this is the one time execution of initial launch action
     */
    performWorkbenchAction(launcher, isInitialLaunchAction = false, modifiers) {
        const {
            workbenchStore,
            onOpenLauncher,
        } = this.props;

        if (onOpenLauncher) onOpenLauncher(workbenchStore, launcher, isInitialLaunchAction, modifiers);
    }
}

export default WorkbenchPage;
