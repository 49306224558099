import React from 'react';
import * as PropTypes from 'prop-types';

import { withAdditionalStyles } from '../hoc/withAdditionalStyles';

const propDefinition = {
    /** Styles for this component */
    style: PropTypes.object,

    /** Styling applied on hover */
    hoverStyle: PropTypes.object,

    /** Styling applied on focus */
    focusStyle: PropTypes.object,

    disabled: PropTypes.bool,

    label: PropTypes.string,

    onClick: PropTypes.func,

    onChange: PropTypes.func,

    iconUrl: PropTypes.string,

    type: PropTypes.string,

    multiple: PropTypes.bool,

    accept: PropTypes.array,

    /** provided by the HOC */
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,

    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),
};

/**
 * A platform component for building a Drop Down
 */
const XaltFieldActionButton = (props) => {
    const {
        label,
        iconUrl,
        style,
        focusStyle,
        hoverStyle,
        inputRef,
        ...restProps
    } = props;

    const inputProps = {
        ...restProps,
        ref: inputRef,
        style: { ...style, ...hoverStyle, ...focusStyle },
        alt: label,
        src: iconUrl,
    };

    return (
        <input { ...inputProps } />
    );
};

XaltFieldActionButton.propTypes = propDefinition;
XaltFieldActionButton.defaultProps = {
    style: { width: 16, height: 16 },
    focusStyle: {},
    hoverStyle: {},
    disabled: false,
    multiple: false,
    accept: undefined,
    onClick: () => {},
    onChange: () => {},
    type: 'image',
};

export default withAdditionalStyles(XaltFieldActionButton);