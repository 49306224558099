import React from 'react';
import * as PropTypes from 'prop-types';
import MuiButton from '@material-ui/core/Button';

import makeStyles from '@material-ui/core/styles/makeStyles';
import getStyles from './ButtonBase.styles';

const VARIANT = {
    CONTAINED: 'contained',
    OUTLINED: 'outlined',
    TEXT: 'text',
};

/**
 * Button component: A control is that shows a user a button with a text or icon representation of an action to be performed and allows the user to select the button to run the action.
 * The control is capable of displaying an activity indicator to indicate that the performed action is in progress.
 * @see https://material-ui.com/demos/buttons/
 * @see https://material-ui.com/api/button/
 * @see https://material-ui.com/api/button-base/
 */
const ButtonBase = (props) => {
    const {
        children,
        contextStyles,
        disabled,
        onClick,
        testID,
        type,
        variant,
    } = props;

    // Override core styles with context styles, separating MUI styles
    const {
        container: containerStyles,
        ...muiStyles
    } = getStyles(contextStyles);

    // Create dynamic class names and injected DOM styles for MUI component
    // * makeStyles returns a function
    const styles = makeStyles(muiStyles)();

    // Generate container props
    const containerProps = {
        className: 'c-button-base__container',
        style: containerStyles, // non-MUI styles
    };
    if (testID) { containerProps['data-test-id'] = `${testID}__button-base__container`; }

    // Generate button props
    const buttonProps = {
        classes: {
            // root: {}, // MUI styles
        },
        className: 'c-button-base__button',
        variant,
        color: 'primary',
        onClick: (event) => {
            onClick(event, props);
        },
        type,
    };

    // Add contained (primary) styles
    if (variant === VARIANT.CONTAINED) {
        buttonProps.classes.root = styles.primary;
        buttonProps.classes.containedPrimary = styles.primary;
    }

    // Set outlined (secondary) styles
    if (variant === VARIANT.OUTLINED) {
        buttonProps.classes.root = styles.secondary;
    }

    // Set Text (tertiary) styles
    if (variant === VARIANT.TEXT) {
        buttonProps.classes.root = styles.tertiary;
    }

    if (disabled) {
        buttonProps.disabled = true;
        buttonProps.classes.disabled = styles.disabled;
    }
    if (testID) { buttonProps['data-test-id'] = `${testID}__button-base__button`; }

    return (
        <div { ...containerProps }>
            <MuiButton { ...buttonProps }>
                { children }
            </MuiButton>
        </div>
    );
};

ButtonBase.propTypes = {
    /** Contents For Button */
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]).isRequired,

    /** Styles for this component */
    contextStyles: PropTypes.shape({
        /** Styling for the main button container */
        container: PropTypes.object,

        /** Styles for a primary 'contained' button variant */
        primary: PropTypes.object,

        /** Styles for a primary 'contained' button variant hover state */
        primaryHover: PropTypes.object,

        /** Styles for a secondary 'outlined' button variant */
        secondary: PropTypes.object,

        /** Styles for a secondary 'outlined' button variant hover state */
        secondaryHover: PropTypes.object,

        /** Styles for a tertiary 'text' button variant */
        tertiary: PropTypes.object,

        /** Styles for a tertiary 'text' button variant hover state */
        tertiaryHover: PropTypes.object,
    }),

    /** Disable the button */
    disabled: PropTypes.bool,

    /**
     * Called when the button is clicked.
     * @param {Object} event - The native click event
     * @param {Object} props - The component instance props
     */
    onClick: PropTypes.func.isRequired,

    /** Id used for testing */
    testID: PropTypes.string,

    /** Type of the button */
    type: PropTypes.string,

    /** Button Variant. Defaults to the 'contained' variant. Valid Types: 'outlined', 'contained' */
    variant: PropTypes.oneOf([
        VARIANT.CONTAINED,
        VARIANT.OUTLINED,
        VARIANT.TEXT,
    ]),
};

ButtonBase.defaultProps = {
    contextStyles: {},
    variant: VARIANT.CONTAINED,
};

export default ButtonBase;
export {
    VARIANT,
};
