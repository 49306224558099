import React from 'react';
import * as PropTypes from 'prop-types';
import { Marker } from '../MapsWrapper/index';

/**
 * Marker component allows a user to view markers on Google map.
 * @see https://developers.google.com/maps/documentation/javascript/reference/marker
 * @see https://github.com/fullstackreact/google-maps-react#marker
 */
const MapMarker = (props) => {
    const {
        marker,
        children,
        testID,
        imageHeight,
        imageWidth,
        position,
        markerType,
        onClick,
        ...remainingProps
    } = props;

    const markerProps = {
        id: marker.id,
        position,
        pinColor: marker.pinColor,
        description: marker.description,
        title: marker.name,
        onClick,
        testID,
        ...remainingProps,
    };

    if (markerType === 'image') {
        markerProps.icon = {
            url: marker.imageName,
            // eslint-disable-next-line no-undef
            scaledSize: new google.maps.Size(imageWidth, imageHeight),
        };
    }
    else if (marker.pinColor) {
        const icon = {
            path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
            fillColor: marker.pinColor,
            fillOpacity: 1,
            strokeColor: '#fff',
            strokeWeight: 2,
            scale: 1.3,
        };
        markerProps.icon = icon;
    }
    return (
        <Marker
            { ...markerProps }>
            { children }
        </Marker>
    );
};

MapMarker.propTypes = {
    /** Marker details object */
    marker: PropTypes.shape({
        /** Id of the marker */
        id: PropTypes.string,

        /** Name of the marker */
        name: PropTypes.string,

        /** Custom image link to be used as marker*/
        imageName: PropTypes.string,
    }),

    position: PropTypes.shape({
        /** Latitude of the point */
        lat: PropTypes.number,

        /** Longitude of the point */
        lng: PropTypes.number,
    }),

    /**
    * Called when the marker is pressed.
    * @param {Object} coordinate - Marker coordinate
    * @param {String} id - Marker id
    */
    onClick: PropTypes.func,

    /** Marker content */
    children: PropTypes.node,

    /** Marker types */
    markerType: PropTypes.oneOf([
        'pin',
        'image',
    ]),

    /** Custom image height of marker. Applicable only when markerType is image.*/
    imageHeight: PropTypes.number,

    /** Custom image width of marker. Applicable only when markerType is image. */
    imageWidth: PropTypes.number,

    /** Id used for testing */
    testID: PropTypes.string,
};

MapMarker.defaultProps = {
    marker: {},
    imageHeight: 40,
    imageWidth: 40,
};

MapMarker.MarkerType = {
    Image: 'image',
    Pin: 'pin',
};

export default MapMarker;
