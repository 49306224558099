import componentFactory from '../componentFactory';
import BuildGlobals from '../../provider/BuildGlobals';


/**
 * The purpose of this factory is to select the right abstract component type with the given parameters for consideration
 * Ideally, this would only require the 'PropertyDef', but is dependent on other data in a few cases
 */
class PropertyComponentFactory {
    getComponent(props) {
        const {
            cellDef,
            propertyDef,
            property,
            record,
            isReadMode,
            xStyle,
        } = props;

        const cellDefinition = cellDef || {};
        const propertyDefinition = propertyDef || {};
        const isTextWithImage = !!((property && property.imageName) || (record && record.imageName));
        const isImageWithURL = cellDefinition.displayMediaInline && propertyDefinition.isURLType;
        const isLargeImage = propertyDefinition.isLargePropertyType || isImageWithURL;
        const usePaperCheckbox = !!(xStyle && xStyle.paperCheckbox);
        const useBarChartProp = !!(xStyle && xStyle.barChartProp);
        const useGaugeChartProp = !!(xStyle && xStyle.gaugeChartProp);

        /**
         * EDIT MODE for cases where we generate a new type of component that is not controllable with readonly/disabling features.
         * The property is allowed to be updated (ie: writeAllowed || writeEnabled).
         */

        // @TODO - try to determine most commonly used types first (i.e. text, date, etc.) to avoid these conditional checks
        // How can we determine a text type first (rather than last as a default)?
        if (!isReadMode) {
            // Paper checkbox
            if (usePaperCheckbox) return null;

            // Generate Switch / Checkbox / Radio component builder
            if (propertyDefinition.isBooleanType) {
                if (cellDefinition.isCheckBoxEntryMethod) {
                    return componentFactory.getAbstractPropertyComponent('checkbox');
                }
                if (cellDefinition.isRadioEntryMethod) {
                    return componentFactory.getAbstractPropertyComponent('radio');
                }
                return componentFactory.getAbstractPropertyComponent('switch');
            }

            // we should remove this condition check once all component are handled in XNA as well
            // or bring out the components developed ( from condition check ) in both Platform XNA/XHA like Switch
            if (BuildGlobals.isXHA()) {
                // Generate Drop Down component builder
                // TODO: We need to utilize the readOnly props and stop building special cases for read mode. We can simply disable
                // the dropdown once we adjust the formatter from creating a string for value and use the codeRef.
                if (propertyDefinition.isCodeRefType && propertyDefinition.cardinality === '*') return componentFactory.getAbstractPropertyComponent('dropDownMultiSelectField');
                if (cellDefinition.isIconEntryMethod) return componentFactory.getAbstractPropertyComponent('dropDownTextwithImageField');
                if (cellDefinition.isDropDownEntryMethod) return componentFactory.getAbstractPropertyComponent('dropDownField');
                if (cellDefinition.isComboBoxEntryMethod || (cellDefinition.isTextFieldEntryMethod && cellDefinition.autoFillCapable)) return componentFactory.getAbstractPropertyComponent('comboBoxField');

                // Paper barChart prop (can only be specified via forms or gml)
                if (useBarChartProp) return null;
                // Paper gaugeChart prop (can only be specified via forms or gml)
                if (useGaugeChartProp) return null;

                // Generate Date / Time / DateTime component builder
                if (propertyDefinition.isDateType) {
                    return componentFactory.getAbstractPropertyComponent('dateField');
                }
                if (propertyDefinition.isDateTimeType) {
                    return componentFactory.getAbstractPropertyComponent('dateTimeField');
                }
                if (propertyDefinition.isTimeType) {
                    return componentFactory.getAbstractPropertyComponent('timeField');
                }
                // Generate Image builder
                if (propertyDefinition.isLargePropertyType) {
                    if (propertyDefinition.isFileMimeType) {
                        return componentFactory.getAbstractPropertyComponent('filePickerInput');
                    }
                    return null; // Should build an image like ImageComponentBuilder();
                }

                if (propertyDefinition.isNumericType) return componentFactory.getAbstractPropertyComponent('numberField');

                if (propertyDefinition.isEmailType) return componentFactory.getAbstractPropertyComponent('emailField');

                if (propertyDefinition.isDescriptionType) return componentFactory.getAbstractPropertyComponent('textField');

                if (propertyDefinition.isNameType) return componentFactory.getAbstractPropertyComponent('textField');

                if (propertyDefinition.isNFCType) return componentFactory.getAbstractPropertyComponent('textField');

                if (propertyDefinition.isBarcodeType) return componentFactory.getAbstractPropertyComponent('textField');
            }
        }
        // we should remove this condition check once all component are handled in XNA as well
        // or bring out the components developed ( from condition check ) in both Platform XNA/XHA like Switch
        if (BuildGlobals.isXHA()) {
            // Print markup shows the checkbox regardless of read/maintenance mode of view.
            if (usePaperCheckbox) return null;

            // Paper barChart prop (can only be specified via forms or gml)
            if (useBarChartProp) return null;

            // Paper gaugeChart prop (can only be specified via forms or gml)
            if (useGaugeChartProp) return null;

            // Check for an image associated with any text
            if (isTextWithImage) return componentFactory.getAbstractPropertyComponent('textWithImage');

            // If the view is telling us to display this as inline media and it is the type of object that
            // can be displayed as inline media, use an image component builder to show the media as an image.
            // Down the road there may be other types of inline media (video?), but for now it's just image.
            if (isLargeImage) {
                if (propertyDefinition.isURLType) {
                    // Generate plain text URL with launcher icon
                    return null;
                }
                return null;
            }

            if (propertyDefinition.isHTMLType) return componentFactory.getAbstractPropertyComponent('htmlTextField');

            if (propertyDefinition.isPasswordType) return componentFactory.getAbstractPropertyComponent('passwordField');

            // Generate Text Input with Phone launcher component builder
            if (propertyDefinition.isTelephoneType) return componentFactory.getAbstractPropertyComponent('telephoneField');

            // Generate Text Input with URL launcher component builder
            if (propertyDefinition.isURLType) return componentFactory.getAbstractPropertyComponent('textField');

            if (propertyDefinition.isTextBlock || propertyDefinition.isMultiLineText) return componentFactory.getAbstractPropertyComponent('textBlock');

            // Color picker component
            if (propertyDefinition.isColorPickerType) return componentFactory.getAbstractPropertyComponent('textFieldWithColorPalette');
        }

        // Generate Switch / Checkbox / Radio component builder
        if (propertyDefinition.isBooleanType && cellDefinition.isEntryTypeDisplayMethod) {
            if (cellDefinition.isCheckBoxEntryMethod) {
                return componentFactory.getAbstractPropertyComponent('checkbox');
            }
            if (cellDefinition.isRadioEntryMethod) {
                return componentFactory.getAbstractPropertyComponent('radio');
            }
            return componentFactory.getAbstractPropertyComponent('switch');
        }

        // default to textLabel in read mode
        // we should remove this condition check once all component are handled in XNA as well
        // or bring out the components developed ( from condition check ) in both Platform XNA/XHA like Switch
        if (BuildGlobals.isXHA()) {
            return componentFactory.getAbstractPropertyComponent('textLabel');
        }
        return null;
    }
}

const propertyComponentFactory = new PropertyComponentFactory();
export default propertyComponentFactory;
