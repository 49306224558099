import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { XaltBox, XaltIconButton, XaltArrowUpward, XaltArrowDownward, XaltDelete, XaltDropDown, XaltTextLabel } from 'xalt-react-atoms';

import lang from '../../nls/i18n';
import getStyles from './styles/SortComponent.styles';

export default class SortComponent extends PureComponent {
    static propTypes = {
        value: PropTypes.object,
        isPlaceholder: PropTypes.bool,
        index: PropTypes.number,
        isAscending: PropTypes.bool,
        remainingSortableValues: PropTypes.array,
        onSortTermChange: PropTypes.func,
        onRemove: PropTypes.func,
        onChangeSortDirection: PropTypes.func,
    }

    constructor(props) {
        super(props);
        this.handleOnSortTermChange = this.handleOnSortTermChange.bind(this);
        this.handleOnChangeSortDirection = this.handleOnChangeSortDirection.bind(this);
        this.handleOnRemove = this.handleOnRemove.bind(this);
    }

    render() {
        const {
            value,
            remainingSortableValues,
            index,
            isAscending,
            isPlaceholder,
        } = this.props;

        const {
            sortingRow,
            directionLabel: directionStyle,
            orderContainer,
            dropDown,
            dropDownTitleWrapper,
            title,
            iconStyle,
            option,
            expandIcon,
            optionSelected,
            optionHover,
        } = getStyles();

        const directionLabel = isAscending ? lang.searchSort.sortAscending : lang.searchSort.sortDescending;

        return (
            <XaltBox
                key={ `sort-selector-drop_down_${index}` }
                style={ sortingRow }>
                <XaltBox style={ dropDownTitleWrapper }>
                    <XaltTextLabel style={ title }>Column</XaltTextLabel>
                    <XaltDropDown
                        style={ dropDown }
                        onValueChanged={ this.handleOnSortTermChange }
                        disableClearable
                        allowFreeformEntry={ false }
                        value={ value }
                        items={ remainingSortableValues }
                        optionStyles={ option }
                        expandIconStyles={ expandIcon }
                        selectedStyles={ optionSelected }
                        hoverStyles={ optionHover } />
                </XaltBox>
                <XaltBox style={ orderContainer }>
                    { !isPlaceholder &&
                        <>
                            <XaltBox>
                                <XaltIconButton onClick={ this.handleOnChangeSortDirection }>
                                    { isAscending ? <XaltArrowUpward style={ iconStyle } /> : <XaltArrowDownward style={ iconStyle } /> }
                                </XaltIconButton>
                                <XaltTextLabel style={ directionStyle }>
                                    { directionLabel }
                                </XaltTextLabel>
                            </XaltBox>
                            <XaltIconButton onClick={ this.handleOnRemove }>
                                <XaltDelete style={ iconStyle } />
                            </XaltIconButton>
                        </> }
                </XaltBox>
            </XaltBox>
        );
    };

    handleOnChangeSortDirection() {
        const {
            onChangeSortDirection,
            index,
            isAscending,
        } = this.props;
        onChangeSortDirection(index, isAscending);
    }

    handleOnRemove() {
        const {
            onRemove,
            index,
        } = this.props;
        onRemove(index);
    }

    handleOnSortTermChange(itemSelected) {
        const {
            onSortTermChange,
            index,
            isAscending,
        } = this.props;

        onSortTermChange(itemSelected, index, isAscending);
    }
}
