/**
 */
export class Download_Manager_SelectedItems_RECORDS {
    static copyOfResponse() {
        return JSON.parse(JSON.stringify(this.RESPONSE));
    }
}
Download_Manager_SelectedItems_RECORDS.PATH = 'tenants/${tenantId}/sessions/${sessionId}/dialogs/11/records';
Download_Manager_SelectedItems_RECORDS.BODY = {
    "fetchDirection": "FORWARD",
    "fetchMaxRecords": 50,
    "fromRecordId": "6GW7000A",
    "type": "hxgn.api.dialog.QueryParameters"
};
Download_Manager_SelectedItems_RECORDS.RESPONSE = {
    "defaultActionId": null,
    "records": [{
            "annotations": [],
            "id": "6GW7000A",
            "type": "hxgn.api.dialog.Record",
            "properties": [{
                    "name": "name",
                    "format": null,
                    "annotations": [],
                    "type": "hxgn.api.dialog.Property",
                    "value": "SDA Mobile Test Package"
                }, {
                    "name": "workpackageid",
                    "format": null,
                    "annotations": [],
                    "type": "hxgn.api.dialog.Property",
                    "value": "6GW7000A"
                }, {
                    "name": "description",
                    "format": null,
                    "annotations": [],
                    "type": "hxgn.api.dialog.Property",
                    "value": "SDA Mobile Test Package"
                }, {
                    "name": "creation_date",
                    "format": "date",
                    "annotations": [],
                    "type": "hxgn.api.dialog.Property",
                    "value": "2017-10-11"
                }, {
                    "name": "disciplines",
                    "format": null,
                    "annotations": [],
                    "type": "hxgn.api.dialog.Property",
                    "value": null
                }, {
                    "name": "last_update_date",
                    "format": "date",
                    "annotations": [],
                    "type": "hxgn.api.dialog.Property",
                    "value": "2017-10-11"
                }]
        }],
    "dialogAlias": "Download_Manager_SelectedItems",
    "hasMore": false,
    "type": "hxgn.api.dialog.RecordSet",
    "dialogName": "Download_Manager_SelectedItems"
};
