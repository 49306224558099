import { toJS } from 'mobx';
import { constants } from '..';
import BaseTheme from './BaseTheme';

/**
 * Provides theme information for the application.
 * @param {Object} rootStore
 */
const themeFactory = (rootStore) => {
    // Setup the new theme information based on tenant if provided and stored locally
    const newTheme = (tenantId) => {
        let overrideThemeValues = null;
        if (tenantId) {
            // Retrieve the theme values for this tenant from the settings store.
            const { themeStore } = rootStore;
            const valuesByTenant = (themeStore && themeStore.getValue(constants.storage.THEME)) || {};
            overrideThemeValues = valuesByTenant && toJS(valuesByTenant[tenantId]);
        }
        // Return the new theme with override values
        return new BaseTheme(overrideThemeValues);
    };

    return {

        /**
         * Gets the theme information by the system or tenant
         * @returns {Object} theme - Theme styles for the application
         */
        getTheme: (tenantId: String = '') => {
            return newTheme(tenantId);
        },
    };
};

export default themeFactory;
