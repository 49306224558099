/* eslint-disable no-plusplus */
import { SaltDocument } from '../SaltDocument';

const calendarTemplate = require('./calendar_simple.json');

class CalendarTranspiler {
    generateSalt(dialog) {
        const document = new SaltDocument(calendarTemplate);
        const viewEl = document.getByExpr('**.view');
        document.singleSelectNode(viewEl);
        // @TODO alias, viewAlias - we may want to allow salt to also reference user defined aliases
        const { view } = dialog;
        const viewId = SaltDocument.getViewId(view);
        document.setAttribute('id', viewId);
        return document.rootNode;
    }
}

const calendarTranspiler = new CalendarTranspiler();
export default calendarTranspiler;
