import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { rootStore, constants, serviceFactory } from 'cv-react-core';
import routeNames from '../../../routing/routeNames';
import Navigator from '../../../routing/Navigator';
import Menu from '../../base/Menu';
import MenuItem from '../../base/MenuItem';
import IconButton from '../../base/IconButton';
import getStyles from './WorkbenchMenu.styles';

const {
    ABOUT,
    WORKBENCH,
} = routeNames;

const { ui } = constants;
const {
    WORKBENCH_UI_OBJECT_ID,
    WORKBENCH_MENU_OPEN,
} = ui;
const { lang } = serviceFactory;

@observer
class WorkbenchMenu extends PureComponent {
    constructor(props) {
        super(props);
        this.handleMenuStatus(false);
    }

    render() {
        const { navigator } = this.props;
        const { uiStore } = rootStore;
        const isOpen = uiStore.getValueForUIObject(WORKBENCH_UI_OBJECT_ID, WORKBENCH_MENU_OPEN);
        const styles = getStyles();
        const workbenches = this.getWorkbenches();
        return (
            <Menu
                contextStyles={ {
                    container: styles.container,
                } }
                button={
                    <IconButton
                        contextStyles={ {
                            button: styles.homeIconButton,
                            icon: styles.homeIcon,
                        } }
                        svgPath="M12 5.69l5 4.5V18h-2v-6H9v6H7v-7.81l5-4.5M12 3L2 12h3v8h6v-6h2v6h6v-8h3L12 3z"
                        onClick={ () => this.handleMenuStatus(true) }
                        title="Workbenches" />
                }
                closeOnSelection={ false }
                anchorOrigin={ { vertical: 'bottom', horizontal: 'left' } }
                onClose={ () => this.handleMenuStatus(false) }
                open={ isOpen }>
                <li style={ styles.menuHeader }>
                    <span style={ styles.menuHeaderText }>Menu</span>
                    <IconButton
                        contextStyles={ {
                            button: styles.closeIconButton,
                            icon: styles.closeIcon,
                        } }
                        iconName="cancel_circle"
                        onClick={ () => this.handleMenuStatus(false) }
                        title={ lang.generic.close } />
                </li>
                <li style={ styles.scrollableContainer }>
                    <ul>
                        { workbenches.map((workbench) => (
                            <MenuItem
                                contextStyles={ {
                                    container: styles.menuItem,
                                    focusContainer: styles.menuItemFocused,
                                    selected: styles.menuItemSelected,
                                } }
                                key={ workbench.id }
                                onClick={ (nativeEvent) => this.handleWorkbenchClick(workbench, nativeEvent) }
                                selected={ workbench.id === navigator.match.params.workbenchId }
                                text={ workbench.name } />
                            )
                        ) }
                    </ul>
                </li>
            </Menu>
        );
    }

    getWorkbenches = () => {
        const { sessionStore } = rootStore;
        const { workbenches } = sessionStore;
        return workbenches;
    }

    handleMenuStatus = (status) => {
        const { uiStore } = rootStore;
        uiStore.setValueForUIObject(WORKBENCH_UI_OBJECT_ID, WORKBENCH_MENU_OPEN, status);
    }

    handleWorkbenchClick = (workbench, nativeEvent) => {
        const { navigator } = this.props;
        const { id: routeId } = workbench;
        const workbenches = this.getWorkbenches();
        const target = workbenches.find((wb) => wb.id === routeId);
        const currentWorkbenchId = navigator.match.params.workbenchId;
        const workbenchId = target ? target.id : currentWorkbenchId;
        const { sessionStore } = rootStore;
        sessionStore.setSelectedWorkbenchById(workbenchId);
        const { tenantId } = sessionStore.session || {};
        this.handleMenuStatus(false);
        let url;
        switch (routeId) {
            case 'about':
                url = `/${tenantId}/${ABOUT}/${currentWorkbenchId || ''}`;
                break;
            default:
                url = `/${tenantId}/${WORKBENCH}/${workbenchId || ''}`;
        }
        if (nativeEvent && nativeEvent.altKey) window.open(url, '_blank');
        else navigator.history.push(url);
    }
};

WorkbenchMenu.propTypes = {
    navigator: PropTypes.instanceOf(Navigator),
};

export default WorkbenchMenu;
