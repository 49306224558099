import { Dialog } from 'cv-dialog-sdk';
import { constants } from '../constants';
import serviceFactory from '../services/serviceFactory';

const onCatchError = ({ listDialogStore, error, onError }) => {
    listDialogStore.setSearchFormOpen(false);
    const title = serviceFactory.lang.formatString(serviceFactory.lang.dialog.errors.failedToOpenSearchDialog, listDialogStore.dialog.id);
    onError({
        title,
        err: error,
        type: constants.error.errorTypes.soft,
    });
};

class SearchController {
    openQuickSearch = ({ listDialogStore, onError }) => {
        const catchError = (error) => { onCatchError({ listDialogStore, error, onError }); };
        if (listDialogStore.getIsLoadingSearchStore()) return null;
        return listDialogStore.getOrOpenSearchDialogFormStore()
            .then((searchFormDialogStore) => {
                const searchDialogStore = searchFormDialogStore.firstChildStore;
                return searchDialogStore.readRecord()
                    .then(() => {
                        return searchDialogStore;
                    })
                    .catch(catchError);
            })
            .catch(catchError);
    }

    openForm = ({ listDialogStore, openAction, onError }) => {
        const catchError = (error) => { onCatchError({ listDialogStore, error, onError }); };
        if (listDialogStore.getIsLoadingSearchStore()) return Promise.resolve();

        // We don't need to make a second search api call if the data is already in the store.
        if (listDialogStore.searchDialogStore) {
            openAction(true);
            return Promise.resolve(listDialogStore.searchDialogStore);
        }

        listDialogStore.setQueryInProgress(true);
        return listDialogStore.getOrOpenSearchDialogFormStore()
            .then((searchFormDialogStore) => {
                const searchDialogStore = searchFormDialogStore.firstChildStore;
                return searchDialogStore.readRecord()
                    .then(() => {
                        openAction(true);
                        return searchDialogStore;
                    })
                    .catch(catchError)
                    .finally(() => {
                        listDialogStore.setQueryInProgress(false);
                    });
            })
            .catch((e) => {
                catchError(e);
                listDialogStore.setQueryInProgress(false);
            });
    }

    openSearchForm = ({ listDialogStore, onError }) => {
        const openAction = (value) => listDialogStore.setSearchFormOpen(value);

        return this.openForm({ listDialogStore, openAction, onError });
    }

    openSortModal = ({ listDialogStore, onError }) => {
        const openAction = (value) => listDialogStore.setSortModalOpen(value);

        return this.openForm({ listDialogStore, openAction, onError });
    }

    getSearchDialogStore = (listDialogStore) => {
        return listDialogStore.searchDialogStore;
    }

    getSearchFormDialogStore = (listDialogStore) => {
        return listDialogStore.searchDialogFormStore;
    }

    /**
     * Provide quick lookup for determining if property contains a filter value
     * @param {bool} propertyName
     */
    isPropertyNameFiltered = ({ listDialogStore, propertyName }) => {
        const { searchDialogStore } = listDialogStore;
        if (!searchDialogStore) return false;

        const searchValue = searchDialogStore.getSearchValuePropertyForName(propertyName);
        return !!(searchValue && searchValue.value);
    }

    clearSearchValues = ({ listDialogStore }) => {
        const { searchDialogStore } = listDialogStore;
        searchDialogStore.clearSearchValues();
    };

    clearSortValues = ({ listDialogStore }) => {
        const { searchDialogStore } = listDialogStore;
        searchDialogStore.clearSortValues();
    };

    setSearchKeyword = ({ listDialogStore, keyword }) => {
        const { searchDialogStore } = listDialogStore;
        searchDialogStore.setKeywordSearchValue(keyword);
    };

    setSortTerms = ({ listDialogStore, sortTerms }) => {
        const { searchDialogStore } = listDialogStore;
        searchDialogStore.clearSortValues();
        sortTerms.forEach((e, i) => {
            if (e.isAscending) {
                searchDialogStore.setAscending(e.name, i);
            } else {
                searchDialogStore.setDescending(e.name, i);
            }
        });
    }

    getSortTerms = ({ listDialogStore }) => {
        const { searchDialogStore, dialog } = listDialogStore;
        if (!searchDialogStore) return [];

        const values = searchDialogStore.sortValues;
        const builtValues = [];
        const { view } = dialog;
        const { columns } = view;
        if (values) {
            values.forEach(e => {
                const column = columns.find(c => c.propertyName === e.name);
                const { heading } = column;
                builtValues.push({
                    name: e.name,
                    label: heading || e.name,
                    isAscending: e.isAscending,
                    priority: e.priority,
                });
            });
        }
        return builtValues;
    }

    submitSortTerms = ({ listDialogStore, sortTerms, onError, uiStore }) => {
        this.setSortTerms({ listDialogStore, sortTerms, onError });
        return this.submitChanges({ listDialogStore, onError, uiStore });
    }

    submitChanges = ({ listDialogStore, onError, uiStore }) => {
        const catchError = (error) => { onCatchError({ listDialogStore, error, onError }); };
        listDialogStore.setQueryInProgress(true);
        const { searchDialogStore } = listDialogStore;
        if (searchDialogStore.submitInProgress) return searchDialogStore;
        searchDialogStore.setSubmitInProgress(true);
        listDialogStore.setSearchFormOpen(false);
        listDialogStore.setSortModalOpen(false);
        return searchDialogStore.submitSearch()
            .then(() => {
                // After we submit the search we need to reopen for editing.
                if (listDialogStore.restorePaginationDefaults) {
                    listDialogStore.restorePaginationDefaults();
                    const { id, view: { pagingMethod } } = listDialogStore.dialog;
                    if (pagingMethod === Dialog.PAGING_DEFINED_DIALOG_CLASS && listDialogStore.pageSize) {
                        uiStore.setValueForUIObject(id, constants.ui.LIST_PAGINATION_DATA, {
                            pageSize: listDialogStore.pageSize,
                        }, true);
                    }
                }
                return searchDialogStore.reopenSearch()
                    .then(() => searchDialogStore.readRecord()
                        .then(() => {
                            listDialogStore.resetList()
                                .then(() => {
                                    searchDialogStore.setSubmitInProgress(false);
                                })
                                .catch((error) => {
                                    // We need to clear the search results to prevent a hard refresh
                                    // from creating the same issue this is addressing. Even though we
                                    // throw a hard error, if you refresh with the dialog up the same issue occurs.
                                    searchDialogStore.clearAllValues();
                                    this.submitChanges({ listDialogStore, onError });
                                    onCatchError({ listDialogStore, error, onError });
                                });
                            return searchDialogStore;
                        })
                        .catch(catchError)).catch(catchError);
            })
            .catch(catchError);
    }
}

const searchController = new SearchController();
export default searchController;
