import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import RadioGroup from '@material-ui/core/RadioGroup';

const propDefinition = {
    /** The radio selected value */
    value: PropTypes.bool,

    /**
     * Called when the radio was changed
     * @param {Object} event - The native change event
     * @param {Object} props - The component instance props
     */
    onChange: PropTypes.func,

    /** The text to display */
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),
};

/**
 * A control that allows a user to select one or more items from a set.
 * @see https://material-ui.com/es/api/radio-group/
 */
class XaltRadioGroup extends PureComponent {
    static propTypes = propDefinition;

    static defaultProps = {
        onChange: () => {},
    };

    render() {
        const {
            value,
            children,
        } = this.props;

        return (
            <RadioGroup
                className="xalt-radiogroup__container"
                value={ value }
                onChange={ this.handleChange }
                row>
                { children }
            </RadioGroup>
        );
    }

    handleChange = (event) => {
        const { onChange } = this.props;
        const { value } = event.target;
        onChange(value);
    }
}

export default XaltRadioGroup;