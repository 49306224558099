import { AttributeCellValue } from './AttributeCellValue';
import { View } from './View';
export class PrintMarkup extends View {
    constructor() {
        super(...arguments);
        this._orderedCellValue = null;
    }
    get orderedCellValues() {
        if (!this._orderedCellValue) {
            this._orderedCellValue = {};
            this.rows.forEach((cellRow) => {
                cellRow.forEach((cell) => {
                    cell.values.forEach((cellValue) => {
                        if (cellValue instanceof AttributeCellValue) {
                            const attributeCellValue = cellValue;
                            this._orderedCellValue[attributeCellValue.propertyName] = attributeCellValue;
                        }
                    });
                });
            });
        }
        return this._orderedCellValue;
    }
}
