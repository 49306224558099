// Core component styles
const coreStyles = {
    actionButton: {
    },

    actionButtonText: {
    },

    actions: {
    },

    cancelButton: {
    },

    cancelButtonText: {
    },

    content: {
    },

    divider: {
    },

    header: {
    },

    modal: {
    },

    overlay: {
    },

    undoButton: {
    },

    undoButtonText: {
    },
};

/**
 * Combines coreStyles and contextStyles
 * @param {Object} contextStyles
 */
const getStyles = (contextStyles = {}) => {
    return {

        actionButton: {
            ...coreStyles.actionButton,
            ...contextStyles.actionButton,
        },

        actionButtonText: {
            ...coreStyles.actionButtonText,
            ...contextStyles.actionButtonText,
        },

        actions: {
            ...coreStyles.actions,
            ...contextStyles.actions,
        },

        cancelButton: {
            ...coreStyles.cancelButton,
            ...contextStyles.cancelButton,
        },

        cancelButtonText: {
            ...coreStyles.cancelButtonText,
            ...contextStyles.cancelButtonText,
        },

        content: {
            ...coreStyles.content,
            ...contextStyles.content,
        },

        divider: {
            ...coreStyles.divider,
            ...contextStyles.divider,
        },

        header: {
            ...coreStyles.header,
            ...contextStyles.header,
        },

        modal: {
            ...coreStyles.modal,
            ...contextStyles.modal,
        },

        overlay: {
            ...coreStyles.overlay,
            ...contextStyles.overlay,
        },

        undoButton: {
            ...coreStyles.undoButton,
            ...contextStyles.undoButton,
        },

        undoButtonText: {
            ...coreStyles.undoButtonText,
            ...contextStyles.undoButtonText,
        },
    };
};

export default getStyles;
