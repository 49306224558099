// Core component styles
const coreStyles = {
    activityIndicator: {
    },

    cell: {
        userSelect: 'none',
        display: 'table-cell',
        height: '100%',
    },
    cellImage: {

    },
    cellSelected: {
        backgroundColor: '#dedede',
    },

    headerCell: {
        borderRight: '0px',
    },

    headerCellButton: {
    },

    headerCellButtonBadge: {
    },

    headerCellButtonIcon: {
    },

    headerCellFilterButton: {
    },

    headerCellFilterButtonIcon: {
    },

    headerCellFiltered: {
    },

    headerCellLockButton: {
    },

    headerCellLockButtonIcon: {
    },

    headerCellLockButtonSection: {
        backgroundColor: '#fff',
        position: 'absolute',
        right: 0,
    },

    headerCellSortButtonSection: {
        backgroundColor: '#fff',
        position: 'absolute',
        left: 0,
    },

    headerCellText: {
        cursor: 'pointer',
    },

    headerRow: {
    },

    row: {
    },

    rowSelected: {
        backgroundColor: '#dedede',
    },

    rowSelectionCell: {
        backgroundColor: '#fff',
        left: 0,
        padding: 0,
        position: 'sticky',
        textAlign: 'center',
    },

    rowSelectionCellCheck: {
        padding: '0px',
    },

    rowSelectionCellChecked: {
        padding: '0px',
    },

    rowSelectionCellSelected: {
    },

    rowSelectionTray: {
        // position: 'absolute',
        // zIndex: 500, // show above fixed column cells
        // left: 0,
        // bottom: 0,
        backgroundColor: '#006e96',
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
    },

    rowSelectionTrayCloseButton: {
        marginLeft: '8px',
        padding: '6px',
    },

    rowSelectionTrayCloseButtonIcon: {
        color: '#fff',
    },

    rowSelectionTrayText: {
        color: '#fff',
        whiteSpace: 'nowrap',
    },

    spacerCell: {
        backgroundColor: '#ffffff',
        borderBottom: 0,
        width: 20,
    },

    verticalDivider: {
        height: '100%',
        width: '1px',
        backgroundColor: '#D2D2D2',
        right: '0px',
        top: '-5px',
        position: 'absolute',
    }
};

/**
* Helper method to get the styles for Icon component
* @param {Object} contextStyles - Context styles for Icon Component
*/
const getStyles = (contextStyles = {}) => {
    return {
        activityIndicator: {
            ...coreStyles.activityIndicator,
            ...contextStyles.activityIndicator,
        },

        cell: {
            ...coreStyles.cell,
            ...contextStyles.cell,
        },
        cellImage: {
            ...coreStyles.cellImage,
            ...contextStyles.cellImage,
        },
        cellSelected: {
            ...coreStyles.cellSelected,
            ...contextStyles.cellSelected,
        },

        headerCell: {
            ...coreStyles.headerCell,
            ...contextStyles.headerCell,
        },

        headerCellButton: {
            ...coreStyles.headerCellButton,
            ...contextStyles.headerCellButton,
        },

        headerCellButtonBadge: {
            ...coreStyles.headerCellButtonBadge,
            ...contextStyles.headerCellButtonBadge,
        },

        headerCellButtonIcon: {
            ...coreStyles.headerCellButtonIcon,
            ...contextStyles.headerCellButtonIcon,
        },

        headerCellFiltered: {
            ...coreStyles.headerCellFiltered,
            ...contextStyles.headerCellFiltered,
        },

        headerCellFilterButton: {
            ...coreStyles.headerCellFilterButton,
            ...contextStyles.headerCellFilterButton,
        },

        headerCellFilterButtonIcon: {
            ...coreStyles.headerCellFilterButtonIcon,
            ...contextStyles.headerCellFilterButtonIcon,
        },

        headerCellLockButton: {
            ...coreStyles.headerCellLockButton,
            ...contextStyles.headerCellLockButton,
        },

        headerCellLockButtonIcon: {
            ...coreStyles.headerCellLockButtonIcon,
            ...contextStyles.headerCellLockButtonIcon,
        },

        headerCellLockButtonSection: {
            ...coreStyles.headerCellLockButtonSection,
            ...contextStyles.headerCellLockButtonSection,
        },

        headerCellSortButtonSection: {
            ...coreStyles.headerCellSortButtonSection,
            ...contextStyles.headerCellSortButtonSection,
        },

        headerCellText: {
            ...coreStyles.headerCellText,
            ...contextStyles.headerCellText,
        },

        headerRow: {
            ...coreStyles.headerRow,
            ...contextStyles.headerRow,
        },

        row: {
            ...coreStyles.row,
            ...contextStyles.row,
        },

        rowSelected: {
            ...coreStyles.rowSelected,
            ...contextStyles.rowSelected,
        },

        rowSelectionCell: {
            ...coreStyles.rowSelectionCell,
            ...contextStyles.rowSelectionCell,
        },
        rowSelectionCellCheck: {
            ...coreStyles.rowSelectionCellCheck,
            ...contextStyles.rowSelectionCellCheck,
        },

        rowSelectionCellChecked: {
            ...coreStyles.rowSelectionCellChecked,
            ...contextStyles.rowSelectionCellChecked,
        },

        rowSelectionCellSelected: {
            ...coreStyles.rowSelectionCellSelected,
            ...contextStyles.rowSelectionCellSelected,
        },

        rowSelectionTray: {
            ...coreStyles.rowSelectionTray,
            ...contextStyles.rowSelectionTray,
        },

        rowSelectionTrayCloseButton: {
            ...coreStyles.rowSelectionTrayCloseButton,
            ...contextStyles.rowSelectionTrayCloseButton,
        },

        rowSelectionTrayCloseButtonIcon: {
            ...coreStyles.rowSelectionTrayCloseButtonIcon,
            ...contextStyles.rowSelectionTrayCloseButtonIcon,
        },

        rowSelectionTrayText: {
            ...coreStyles.rowSelectionTrayText,
            ...contextStyles.rowSelectionTrayText,
        },

        spacerCell: {
            ...coreStyles.spacerCell,
            ...contextStyles.spacerCell,
        },

        verticalDivider: {
            ...coreStyles.verticalDivider,
            ...contextStyles.verticalDivider,
        }
    };
};

export default getStyles;
