const action = {
    type:
    {
        button: 'button',
        text: 'text',
        custom: 'custom',
        buttonSecondary: 'ButtonSecondary',
        buttonPrimary: 'ButtonPrimary',
    },
    clientActions: {
        close: '#close',
        save: '#save',
        edit: '#edit',
        cancel: '#cancel',
        refresh: '#refresh',
        search: '#search',
        sort: '#sort',
        notificationsAction: '#notificationsAction',
        home: '#home',
        toggleExpand: '#toggleExpand',
        toggleSelectMode: '#toggleSelectMode',
        changeDefaultAction: '#changeDefaultAction',
        select: '#select',
        expr: '#expr',
        copyToClipboard: '#copyToClipboard',
        openImage: '#openImage',
        calculateStatistics: '#calculateStatistics',
        editSalt: '#editSalt',
        selectAll: 'SELECT_ALL',
        selectNone: 'SELECT_NONE',
        colorChooser: 'COLOR_CHOOSER',
        clearValue: 'CLEAR_VALUE',
        listDensity: 'LIST_DENSITY',
        formatXML: 'FORMAT_XML',
        incrementInteger: 'INCREMENT_INTEGER',
        decrementInteger: 'DECREMENT_INTEGER',
    },
};
const event = {
    type: {
        layout: 'layout',
    },
};
const component = {
    name: {
        action: 'Action',
        box: 'Box',
        calendar: 'Calendar',
        text: 'Text',
        image: 'Image',
        iterator: 'Iterator',
        label: 'Label',
        list: 'List',
        menu: 'Menu',
        property: 'Property',
        ref: 'Ref',
        salt: 'Salt',
        table: 'Table',
        view: 'View',
        when: 'When',
        viz: 'Viz',
        graph: 'Graph',
        barcode: 'Barcode',
        map: 'Map',
        powerbi: 'PowerBI',
        imagePicker: 'ImagePicker',
        fieldAction: 'FieldAction',
        textBlock: 'TextBlock',
        textWithImage: 'TextWithImage',
        switch: 'Switch',
        radio: 'Radio',
        checkbox: 'Checkbox',
        textLabel: 'TextLabel',
    },
};

const fieldActionSemanticTypes = {
    BARCODE: 'BARCODE',
    EMAIL: 'EMAIL',
    FILE_UPLOAD: 'FILE_UPLOAD',
    PASSWORD: 'PASSWORD',
    TELEPHONE: 'TELEPHONE',
    URL: 'URL',
    LARGE_PROPERTY: 'LARGE_PROPERTY',
    COLOR: 'COLOR',
    DATE: 'DATE',
    TIME: 'TIME',
    DATETIME: 'DATETIME',
    NUMBER: 'NUMBER',
};


const isClientAction = (actionId) => {
    return Object.values(action.clientActions).indexOf(actionId) > -1;
};

const engineConstants = { action, event, component, isClientAction, fieldActionSemanticTypes };
export default engineConstants;
