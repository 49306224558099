import { rootStore } from 'cv-react-core';

const coreStyles = {
    closeIcon: {
        color: '#424242',
        fontSize: '24px',
    },
    closeIconButton: {
        padding: '0px',
    },
    container: {
        boxSizing: 'border-box',
        padding: '0px',
        paddingTop: '0px',
        width: '308px',
        borderRadius: 8,
        overflow: 'hidden',
        marginTop: 3,
        maxHeight: 'calc(100% - 106px)',
    },
    homeIcon: {
        color: '#000000',
        fontSize: '24px',
    },
    homeIconButton: {
        padding: '0px',
    },
    menuHeader: {
        alignItems: 'center',
        backgroundColor: '#ffffff',
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: '0px',
        outline: '0px',
        padding: '9px 16px',
        position: 'sticky',
        top: 0,
        zIndex: 1,
        borderBottom: 'solid 1px #BDBDBD',
        marginBottom: '12px',
    },
    menuHeaderText: {
        fontSize: '20px',
        lineHeight: '26px',
    },
    menuItem: {
        backgroundColor: 'transparent',
        padding: '0px 8px 0px 8px',
        margin: '0px 0px 8px 8px',
        minHeight: '44px',
    },
    menuItemFocused: {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        borderRadius: '5px',
    },
    menuItemSelected: {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        borderRadius: '5px',
    },
    scrollableContainer: {
        overflow: 'auto',
        maxHeight: 'calc(100vh - 170px)',
        height: '100%',
        marginRight: '14px',
    },
};

const getStyles = (contextStyles = {}) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();
    const textStyles = {
        ...theme.fonts.default,
        color: '#000000',
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 400,
        letterSpacing: '0.02em',
        lineHeight: '20px',
    };
    return {
        closeIcon: {
            ...coreStyles.closeIcon,
            ...contextStyles.closeIcon,
        },
        closeIconButton: {
            ...coreStyles.closeIconButton,
            ...contextStyles.closeIconButton,
        },
        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },
        homeIcon: {
            ...coreStyles.homeIcon,
            ...contextStyles.homeIcon,
        },
        homeIconButton: {
            ...coreStyles.homeIconButton,
            ...contextStyles.homeIconButton,
        },
        menuHeader: {
            ...coreStyles.menuHeader,
            ...contextStyles.menuHeader,
        },
        menuHeaderText: {
            ...textStyles,
            ...coreStyles.menuHeaderText,
            ...contextStyles.menuHeaderText,
        },
        menuItem: {
            ...textStyles,
            ...coreStyles.menuItem,
            ...contextStyles.menuItem,
        },
        menuItemFocused: {
            ...textStyles,
            ...coreStyles.menuItemFocused,
            ...contextStyles.menuItemFocused,
        },
        menuItemSelected: {
            ...textStyles,
            ...coreStyles.menuItemSelected,
            ...contextStyles.menuItemSelected,
        },
        menuText: {
            ...textStyles,
            ...coreStyles.menuText,
            ...contextStyles.menuText,
        },
        scrollableContainer: {
            ...coreStyles.scrollableContainer,
            ...contextStyles.scrollableContainer,
        },
    };
};

export default getStyles;
