import React from 'react';
import componentFactory from './componentFactory';

class Generator {
    constructor() {
        this.init();
    }

    init() {
        this.tokens = {};
        this.tokens.view = this.view;
        this.tokens.viewRef = this.viewRef;
        this.tokens.box = this.box;
        this.tokens.calendar = this.calendar;
        this.tokens.text = this.text;
        this.tokens.image = this.image;
        this.tokens.property = this.property;
        this.tokens.menu = this.menu;
        this.tokens.action = this.action;
        this.tokens.label = this.label;
        this.tokens.ref = this.ref;
        this.tokens.iterator = this.iterator;
        this.tokens.when = this.when;
        this.tokens.list = this.list;
        this.tokens.layout = this.layout;
        this.tokens.panel = this.panel;
        this.tokens.searchSort = this.searchSort;
        this.tokens.salt = this.salt;
        this.tokens.table = this.table;
        this.tokens.viz = this.viz;
        this.tokens.gps = this.gps;
        this.tokens.powerbi = this.powerbi;
        this.tokens.graph = this.graph;
        this.tokens.barcode = this.barcode;
        this.tokens.map = this.map;
        this.tokens.imagePicker = this.imagePicker;
        this.tokens.calculateStatistics = this.calculateStatistics;
        this.tokens.nfc = this.nfc;
        this.tokens.fieldAction = this.fieldAction;
        this.tokens.quickSearch = this.quickSearch;
        this.tokens.availableViews = this.availableViews;
        this.tokens.title = this.title;
        this.tokens.quickActions = this.quickActions;
    }

    /* eslint-disable no-restricted-syntax */
    /**
     * @param node the json formatted salt tree
     * @param index optional for key generation
     * @returns {*}
     */
    generate(node, index) {
        const keys = Object.keys(node);
        if (keys.length === 1) {
            const key = keys[0];
            const fn = this.tokens[key];
            if (!fn) {
                throw new Error(`Unknown salt token encountered: ${key}`);
            }
            return fn(node[key], index);
        }
        throw new Error(`Expected a single key in node definition. Got ${keys}`);
    }

    createChildren(children) {
        if (Array.isArray(children)) {
            return children.map((childNode, index) => {
                return this.generate(childNode, index);
            });
        } if (typeof children === 'object') {
            return this.generate(children);
        }
        return children;
    }

    // | ============================== components | ===================================== |
    // V ========================================= V ===================================== V

    box = (box, index) => {
        const { children, ...rest } = box;
        const props = index !== undefined ? { ...rest, key: index } : { ...rest };
        const component = componentFactory.getAbstractComponent('box');
        return React.createElement(component, props, this.createChildren(children));
    };

    gps = (gps, index) => {
        const { children, ...rest } = gps;
        const props = index !== undefined ? { ...rest, key: index } : { ...rest };
        const component = componentFactory.getAbstractComponent('gps');
        return React.createElement(component, props);
    };

    calendar = (calendar, index) => {
        const { children, ...rest } = calendar;
        const props = { ...rest, key: index };
        const component = componentFactory.getAbstractComponent('calendar');
        return React.createElement(component, props, this.createChildren(children));
    }

    text = (text, index) => {
        const { children, ...rest } = text;
        const props = index !== undefined ? { ...rest, key: index } : { ...rest };
        const component = componentFactory.getAbstractComponent('text');
        return React.createElement(component, props, this.createChildren(children));
    };

    image = (image, index) => {
        const { children, ...rest } = image;
        const props = index !== undefined ? { ...rest, key: index } : { ...rest };
        const component = componentFactory.getAbstractComponent('image');
        return React.createElement(component, props, this.createChildren(children));
    };

    view = (view, index) => {
        const { children, id, ...rest } = view;
        const props = { ...rest, id, key: id || index };
        const component = componentFactory.getAbstractComponent('view');
        return React.createElement(component, props, this.createChildren(children));
    };

    viewRef = (viewRef, index) => {
        const { id, ...rest } = viewRef;
        const props = { ...rest, id, key: id || index };
        const component = componentFactory.getAbstractComponent('viewRef');
        return React.createElement(component, props);
    };

    property = (property, index) => {
        const { name, key, ...rest } = property;
        const props = { ...rest, ...{ name }, key: key || name || index };
        const component = componentFactory.getAbstractComponent('property');
        return React.createElement(component, props);
    };

    ref = (ref, index) => {
        const { children, ...rest } = ref;
        const props = { ...rest, key: index };
        const component = componentFactory.getAbstractComponent('ref');
        return React.createElement(component, props, this.createChildren(children));
    };

    action = (action, index) => {
        const { id, key, children, ...rest } = action;
        const props = { ...rest, ...{ id }, key: key || id || index };
        const component = componentFactory.getAbstractComponent('action');
        return React.createElement(component, props, this.createChildren(children));
    };

    menu = (menu, index) => {
        const { id, ...rest } = menu;
        const props = { ...rest, ...{ id }, key: id || index };
        const component = componentFactory.getAbstractComponent('menu');
        return React.createElement(component, props);
    };

    label = (label, index) => {
        const { propertyName, ...rest } = label;
        const props = {
            ...rest,
            ...{ propertyName },
            key: propertyName ? `${propertyName}_label` : index,
        };
        const component = componentFactory.getAbstractComponent('label');
        return React.createElement(component, props);
    };

    iterator = (iterator, index) => {
        const { children, elseChildren, ...rest } = iterator;
        const genChildren = this.createChildren(children);
        const genElseChildren = this.createChildren(elseChildren);
        const props = { ...rest, key: index, children: genChildren, elseChildren: genElseChildren };
        const component = componentFactory.getAbstractComponent('iterator');
        return React.createElement(component, props);
    };

    when = (when, index) => {
        const { children, elseChildren, ...rest } = when;
        const genChildren = this.createChildren(children);
        const genElseChildren = this.createChildren(elseChildren);
        const props = { ...rest, children: genChildren, elseChildren: genElseChildren, key: index };
        const component = componentFactory.getAbstractComponent('when');
        return React.createElement(component, props);
    };

    list = (list, index) => {
        const { children, ...rest } = list;
        const props = { ...rest, key: index };
        const component = componentFactory.getAbstractComponent('list');
        return React.createElement(component, props, this.createChildren(children));
    };

    layout = (layout, index) => {
        const { children, ...rest } = layout;
        // note: children don't get rendered here, but passed a value that may be rendered
        // more judiciously by the Layout component
        const props = { ...rest, saltChildren: children, key: index };
        const component = componentFactory.getAbstractComponent('layout');
        return React.createElement(component, props);
    };

    panel = (panel, index) => {
        const { children, ...rest } = panel;
        const props = { ...rest, key: index };
        const component = componentFactory.getAbstractComponent('panel');
        return React.createElement(component, props, this.createChildren(children));
    };

    searchSort = (searchSort, index) => {
        const props = { ...searchSort, key: index };
        const component = componentFactory.getAbstractComponent('searchSort');
        return React.createElement(component, props);
    }

    calculateStatistics = (calculateStatistics, index) => {
        const props = { ...calculateStatistics, key: index };
        const component = componentFactory.getAbstractComponent('calculateStatistics');
        return React.createElement(component, props);
    }

    table = (table, index) => {
        const { rows, ...rest } = table;
        const renderedRows = rows.map(row => this.createChildren(row));
        const props = index !== undefined ? { ...rest, rows: renderedRows, key: index } : { ...rest, rows: renderedRows };
        const component = componentFactory.getAbstractComponent('table');
        return React.createElement(component, props);
    };

    salt = (salt, index) => {
        const { children, ...rest } = salt;
        const props = index !== undefined ? { ...rest, key: index } : { ...rest };
        const component = componentFactory.getAbstractComponent('salt');
        return React.createElement(component, props, this.createChildren(children));
    };

    viz = (viz, index) => {
        const { children, ...rest } = viz;
        const props = index !== undefined ? { ...rest, key: index } : { ...rest, key: 'viz' };
        const component = componentFactory.getAbstractComponent('viz');
        return React.createElement(component, props, this.createChildren(children));
    };

    powerbi = (powerbi, index) => {
        const { children, ...rest } = powerbi;
        const props = index !== undefined ? { ...rest, key: index } : { ...rest, key: 'powerbi' };
        const component = componentFactory.getAbstractComponent('powerbi');
        return React.createElement(component, props, this.createChildren(children));
    };

    graph = (graph, index) => {
        const { children, ...rest } = graph;
        const props = index !== undefined ? { ...rest, key: index } : { ...rest, key: 'graph' };
        const component = componentFactory.getAbstractComponent('graph');
        return React.createElement(component, props, this.createChildren(children));
    }

    barcode = (barcode, index) => {
        const { children, ...rest } = barcode;
        const props = index !== undefined ? { ...rest, key: index } : { ...rest, key: 'barcode' };
        const component = componentFactory.getAbstractComponent('barcode');
        return React.createElement(component, props, this.createChildren(children));
    }

    map = (map, index) => {
        const { children, ...rest } = map;
        const props = index !== undefined ? { ...rest, key: index } : { ...rest, key: 'map' };
        const component = componentFactory.getAbstractComponent('map');
        return React.createElement(component, props, this.createChildren(children));
    }

    imagePicker = (imagePicker, index) => {
        const { children, ...rest } = imagePicker;
        const props = index !== undefined ? { ...rest, key: index } : { ...rest, key: 'imageViewer' };
        const component = componentFactory.getAbstractComponent('imagePicker');
        return React.createElement(component, props, this.createChildren(children));
    }

    nfc = (nfc, index) => {
        const { children, ...rest } = nfc;
        const props = index !== undefined ? { ...rest, key: index } : { ...rest, key: 'nfc' };
        const component = componentFactory.getAbstractComponent('nfc');
        return React.createElement(component, props, this.createChildren(children));
    }

    fieldAction = (property, index) => {
        const { children, ...rest } = property;
        const props = index !== undefined ? { ...rest, key: index } : { ...rest };
        const component = componentFactory.getAbstractComponent('fieldAction');
        return React.createElement(component, props, this.createChildren(children));
    };

    quickSearch = (quickSearch, index) => {
        const props = { ...quickSearch, key: index };
        const component = componentFactory.getAbstractComponent('quickSearch');
        return React.createElement(component, props);
    }

    availableViews = (availableViews, index) => {
        const props = { ...availableViews, key: index };
        const component = componentFactory.getAbstractComponent('availableViews');
        return React.createElement(component, props);
    }

    title = (title, index) => {
        const props = { ...title, key: index };
        const component = componentFactory.getAbstractComponent('title');
        return React.createElement(component, props);
    }

    quickActions = (quickActions, index) => {
        const props = { ...quickActions, key: index };
        const component = componentFactory.getAbstractComponent('quickActions');
        return React.createElement(component, props);
    }
}

const generate = new Generator();
export default generate;
