import { DataUrl } from '../util';
import { TypeNames } from './types';
export class LargeProperty {
    constructor(encodedData, contentType, hasMore = false, type = TypeNames.LargePropertyTypeName, url) {
        this.encodedData = encodedData;
        this.contentType = contentType;
        this.hasMore = hasMore;
        this.type = type;
        this.url = url;
    }
    get isLoaded() {
        return !!this.url || !this.hasMore;
    }
    get mimeType() {
        return this.contentType;
    }
    toUrl() {
        if (this.isLoaded) {
            if (this.url) {
                return this.url;
            }
            else {
                return DataUrl.createDataUrl(this.mimeType, this.encodedData);
            }
        }
        else {
            throw new Error('LargeProperty::toUrl: encodedData is not yet loaded');
        }
    }
    asNewLargeProperty(encodedData, hasMore = false) {
        return new LargeProperty(encodedData, this.contentType, hasMore, this.type, this.url);
    }
}
LargeProperty.DEFAULT_MIME_TYPE = 'application/octet-stream';
