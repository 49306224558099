import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import { XaltInputBase, constants } from 'xalt-react-atoms';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';

const { input: { XALT_INPUT_TYPES } } = constants;

/**
 * A platform component for building a text label
 */
export default class RWPasswordField extends Component {
    static propTypes = {
        style: PropTypes.object,
        value: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
            PropTypes.bool,
        ]),
        isReadMode: PropTypes.bool,
        isFocused: PropTypes.bool,
        onValueChanged: PropTypes.func,
        xStyle: PropTypes.object,
        maxLength: PropTypes.number,
        type: PropTypes.oneOf([
            XALT_INPUT_TYPES.EMAIL,
            XALT_INPUT_TYPES.NUMBER,
            XALT_INPUT_TYPES.PASSWORD,
            XALT_INPUT_TYPES.TEXT,
        ]),
        propertyRef: PropTypes.object,
        showAdornment: PropTypes.bool,
    };

    static defaultProps = {
        style: {},
        type: XALT_INPUT_TYPES.TEXT,
    };

    constructor(props) {
        super(props);
        this.state = {
            showPassword: false,
            focus: false,
        };
        this.textRef = props.propertyRef || React.createRef();
    }

    render() {
        // Get children prop for text
        const {
            style,
            value,
            isReadMode,
            isFocused,
            onValueChanged,
            xStyle,
            type,
            maxLength } = this.props;

        const { input, clearIcon = {} } = xStyle || { input: {} };
        const componentStyle = { ...style, ...input };
        const { showPassword, focus } = this.state;
        let newValue = value ?? '';

        // Regardless of password length, always show 16 dots in Read Only, Edit and Hover state. Exception: Focus state
        if (!showPassword && (isReadMode || !focus)) {
            newValue = '••••••••••••••••';
        }

        return (
            <XaltInputBase
                style={ { display: 'flex', ...componentStyle } }
                endAdornment={ this.renderEndAdornment(isReadMode, clearIcon, value) }
                disabled={ isReadMode }
                maxLength={ maxLength }
                onValueChanged={ onValueChanged }
                readOnly={ isReadMode }
                value={ newValue }
                type={ showPassword ? XALT_INPUT_TYPES.TEXT : type }
                autoFocus={ isFocused }
                onFocus={ this.handleFocus }
                onBlur={ () => this.handleFocus(false) }
                inputRef={ this.textRef } />
        );
    }

    renderEndAdornment(isReadOnly, iconStyle, value) {
        const { showPassword } = this.state;
        const { showAdornment } = this.props;
        if (isReadOnly || !value || !showAdornment) return null;
        const { fontSize } = iconStyle;
        return (
            <InputAdornment position="end">
                <IconButton
                    style={ iconStyle }
                    onClick={ this.handleShowPassword }>
                    { showPassword ? <VisibilityOff style={ { fontSize } } /> : <Visibility style={ { fontSize } } /> }
                </IconButton>
            </InputAdornment>
        );
    }

    handleShowPassword = () => {
        this.setState((prevState) => {
            return {
                showPassword: !prevState.showPassword,
            };
        });
    };

    handleFocus = (focus = true) => {
        this.setState({
            focus,
        });
    }
}
