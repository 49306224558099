import React from 'react';
import * as PropTypes from 'prop-types';
import getStyles from './VerticalForm.styles';
import Resizable from '../Resizable/Resizable';

const VerticalForm = (props) => {
    const {
        contextStyles,
        formElements,
        onResizeStop,
        scrollIdentifier,
    } = props;

    const {
        anchorRow,
        anchorScrollColumn,
        column,
        mainContentRowSizingContainer,
    } = getStyles(contextStyles);

    return (
        <div
            className="achor-row-container"
            style={ anchorRow }>
            <div
                className="anchor-column-scollar-container"
                data-scroll-identifier={ scrollIdentifier }
                style={ anchorScrollColumn }>
                {
                    formElements.map((formElement, index) => {
                        const {
                            props: {
                                dimensions,
                                viewId,
                            },
                        } = formElement;
                        const marginTopStyle = index < 1 ? undefined : { marginTop: '16px' };
                        return (
                            <Resizable
                                defaultSize={ {
                                    width: dimensions.width,
                                    height: dimensions.height,
                                } }
                                direction="vertical"
                                key={ `vertical-content-row-${viewId}` }
                                onResizeStop={ onResizeStop }
                                style={ { ...marginTopStyle, ...mainContentRowSizingContainer } }
                                viewid={ viewId }>
                                <div
                                    className="vertical-column-content"
                                    key={ `vertical-column-content-${viewId}` }
                                    style={ { ...column } }>
                                    { formElement }
                                </div>
                            </Resizable>
                        );
                    })
                }
            </div>
        </div>
    );
};

VerticalForm.propTypes = {
    contextStyles: PropTypes.shape({
        anchorScrollColumn: PropTypes.object,
        column: PropTypes.object,
        mainContentRowSizingContainer: PropTypes.object,
    }),
    formElements: PropTypes.arrayOf(PropTypes.element),
    scrollIdentifier: PropTypes.string,
    onResizeStop: PropTypes.func,
};

VerticalForm.defaultProps = {
    contextStyles: {},
    formElements: [],
};

export default VerticalForm;
