/*       */
import AbstractControl from './AbstractControl';

export default class Label extends AbstractControl {
    get backgroundColorOverride() { return undefined; }

    newControlSalt(propertyStyleSet) {
        return {
            text: {
                ...propertyStyleSet.asStyleAttribute(),
                ...propertyStyleSet.asXStyleAttribute(),
                children: this.text,
            },
        };
    }
    newReadOnlyBoxStyleSet() {
        const result = super.newReadOnlyBoxStyleSet();
        result.style.pointerEvents = 'none';
        return result;
    }
}
