/**
 *
 */
export class RedirectionVisitor {
    constructor(value) {
        if (!value) {
            throw new Error('DialogRedirectionVisitor -- null value exception');
        }
        if (typeof value === 'string') {
            this._enclosedJsonObject = JSON.parse(value);
        }
        else {
            this._enclosedJsonObject = value;
        }
    }
    // --- State Management Helpers --- //
    // --- State Import/Export --- //
    copyAsJsonObject() {
        return JSON.parse(this.copyAsJsonString());
    }
    copyAsJsonString() {
        return JSON.stringify(this.enclosedJsonObject());
    }
    enclosedJsonObject() {
        return this._enclosedJsonObject;
    }
    // --- State Management --- //
    visitId() {
        return this.enclosedJsonObject().id;
    }
    visitAndSetId(id) {
        this.enclosedJsonObject().id = id;
    }
    visitReferringDialogId() {
        return this.visitReferringObject()['dialogId'];
    }
    visitReferringDialogMode() {
        return this.visitReferringObject()['dialogMode'];
    }
    visitAndSetReferringDialogAlias(dialogAlias) {
        this.visitReferringObject()['dialogAlias'] = dialogAlias;
    }
    visitAndSetReferringDialogId(dialogId) {
        this.visitReferringObject()['dialogId'] = dialogId;
    }
    visitAndSetReferringDialogMode(dialogMode) {
        this.visitReferringObject()['dialogMode'] = dialogMode;
    }
    visitAndSetReferringDialogName(dialogName) {
        this.visitReferringObject()['dialogName'] = dialogName;
    }
    visitReferringObject() {
        return this.enclosedJsonObject().referringObject;
    }
}
