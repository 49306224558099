import { rootStore } from 'cv-react-core';

const coreStyles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.75)',
        backdropFilter: 'blur(80px)',
        borderRadius: '10px',
        position: 'relative',
        margin: '20px 0px',
        overflow: 'hidden',
    },

    debugButton: {
        display: 'flex',
        flexGrow: 1,
        width: '100%',
        position: 'absolute',
        bottom: '3px',
        padding: '0px 48px',
        height: '69px',
        boxSizing: 'border-box',
    },

    footerText: {
        color: '#000000',
        fontFamily: 'Roboto',
        fontSize: '12px',
        lineHeight: '15px',
        padding: '12px',
        textAlign: 'center',
        whiteSpace: 'pre',
    },

    imageLoadingIndicator: {},

    layout: {
        display: 'flex',
        backgroundColor: 'transparent',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundImage: 'radial-gradient(50% 50% at 50% 50%, rgba(99, 35, 255, 0.2) 0%, rgba(99, 35, 255, 0) 100%), radial-gradient(50% 50% at 50% 50%, rgba(28, 142, 169, 0.2) 0%, rgba(28, 142, 169, 0) 100%)',
        backgroundPosition: '60% -117px, 50% 543px',
        backgroundSize: '597px 597px, 597px 597px',
        backgroundRepeat: 'no-repeat',
    },

    logo: {
        width: '100%',
        height: '100%',
    },

    logoContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: '0px 40px',
        marginBottom: '40px',
    },

    separator: {
        backgroundColor: '#E0E0E0',
        height: '1px',
        width: '100%',
    },

    settingsFormContainer: {
        padding: '48px',
        paddingBottom: '14px',
        maxWidth: '342px',
    },
};

const getStyles = (contextStyles) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    return {
        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },
        debugButton: {
            ...coreStyles.debugButton,
            ...contextStyles.debugButton,
        },
        footerText: {
            ...coreStyles.footerText,
            ...contextStyles.footerText,
        },
        imageLoadingIndicator: {
            ...coreStyles.imageLoadingIndicator,
            ...contextStyles.imageLoadingIndicator,
        },
        layout: {
            ...coreStyles.layout,
            backgroundColor: theme.pageBackgroundColor,
            ...contextStyles.layout,
        },
        logo: {
            ...coreStyles.logo,
            ...contextStyles.logo,
        },
        logoContainer: {
            ...coreStyles.logoContainer,
            ...contextStyles.logoContainer,
        },
        settingsFormComponent: {
            textField: {
            },
        },
        separator: {
            ...coreStyles.separator,
            ...contextStyles.separator,
        },
        settingsFormContainer: {
            ...coreStyles.settingsFormContainer,
            ...contextStyles.settingsFormContainer,
        },
    };
};

export default getStyles;
