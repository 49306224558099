import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { default as Button, VARIANT } from '../Button/Button';
import { default as FilePickerButton } from '../FilePickerButton/FilePickerButton';
import { default as Prompt } from '../Prompt/Prompt';

import { default as MenuItem } from '../MenuItem/MenuItem';
import { default as Menu } from '../Menu/Menu';
import { default as SignaturePrompt } from '../SignaturePrompt/SignaturePrompt';
import { default as WebCamPrompt } from '../WebcamPrompt/WebCamPrompt';

import { default as getStyles } from './ImagePickerMenu.styles';

const IMAGE_ACTIONS = {
    annotate: {
        id: 'annotate',
        text: 'Annotate Image',
    },
    delete: {
        id: 'delete',
        text: 'Delete Image',
    },
    display: {
        id: 'display',
        text: 'Display Image',
    },
    import: {
        id: 'import',
        text: 'Import Image',
    },
    signature: {
        id: 'signature',
        text: 'Make Signature',
    },
    takePhoto: {
        id: 'takePhoto',
        text: 'Take Photo',
    },
};

class ImagePickerMenu extends Component {
    static propTypes = {
        /** accept button prompt helper text */
        acceptButtonText: PropTypes.string,

        /** Actions to display in the image picker menu */
        actions: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            text: PropTypes.string,
        })),

        /** Annotate image prompt helper text */
        annotateHelperText: PropTypes.string,

        /** Available actions to include in the image picker menu */
        availableActions: PropTypes.shape({
            annotate: PropTypes.shape({
                id: PropTypes.string,
                text: PropTypes.string,
            }),
            delete: PropTypes.shape({
                id: PropTypes.string,
                text: PropTypes.string,
            }),
            display: PropTypes.shape({
                id: PropTypes.string,
                text: PropTypes.string,
            }),
            import: PropTypes.shape({
                id: PropTypes.string,
                text: PropTypes.string,
            }),
            signature: PropTypes.shape({
                id: PropTypes.string,
                text: PropTypes.string,
            }),
            takePhoto: PropTypes.shape({
                id: PropTypes.string,
                text: PropTypes.string,
            }),
        }),

        /** Prompt cancel button text */
        cancelButtonText: PropTypes.string,

        /** The image to display */
        children: PropTypes.node.isRequired,

        /** undo button helper text */
        clearButtonText: PropTypes.string,

        /** Extended styles for this component */
        contextStyles: PropTypes.shape({
            /** Styles for the prompt actions container */
            actions: PropTypes.object,

            /** Styles for the prompt action button */
            actionButton: PropTypes.object,

            /** Styles for the prompt action button text */
            actionButtonText: PropTypes.object,

            /** Styles for the prompt cancel button */
            cancelButton: PropTypes.object,

            /** Styles for the prompt cancel button text */
            cancelButtonText: PropTypes.object,

            /** Styles for the prompt child content container */
            content: PropTypes.object,

            /** Styles for the divider between the prompt title and content area */
            divider: PropTypes.object,

            /** Styles for the prompt title container */
            header: PropTypes.object,

            /** Styles for the menu container */
            menu: PropTypes.object,

            /** Styles for the menu item container and text */
            menuItem: PropTypes.object,

            /** Styles for the focused/hovered menu item container and text */
            menuItemFocused: PropTypes.object,

            /** Styles for the prompt container */
            modal: PropTypes.object,

            /** Styles for the prompt overlay */
            overlay: PropTypes.object,

            /** Styles for the signature prompt */
            signatureStyle: PropTypes.object,

            /** Styles for the prompt title text */
            title: PropTypes.object,
        }),

        /** Delete image helper text */
        deleteHelperText: PropTypes.string,

        /** Import file prompt helper text */
        importHelperText: PropTypes.string,

        /** Image mime type */
        mimeType: PropTypes.string,

        /**
         * Called on change of the image source
         * @typedef {Object} ImageData
         * @property {String} uri
         * @property {String} data
         * @property {String} mimeType
         *
         * @param {ImageData} imageData
         * @returns {void}
         */
        onChangeImage: PropTypes.func,

        /** The Raw image to display and it is currenlty used in only Display Image Prompt*/
        RawImage: PropTypes.node,

        /** Props for signature prompt */
        signatureProps: PropTypes.object,
    };

    static defaultProps = {
        acceptButtonText: 'Add a signature:',
        actions: [],
        annotateHelperText: '',
        availableActions: IMAGE_ACTIONS,
        cancelButtonText: 'Cancel',
        clearButtonText: 'Undo',
        contextStyles: {},
        deleteHelperText: 'Would you like to delete this image?',
        mimeType: 'image/jpeg',
        importHelperText: 'Select an image to import:',
        // captureMaxWidth: 500,
        // captureMaxHeight: 500,
    };

    constructor(props) {
        super(props);
        const {
            availableActions,
            signatureProps = {},
        } = props;
        this.state = {
            [availableActions.annotate.id]: false,
            [availableActions.delete.id]: false,
            [availableActions.display.id]: false,
            [availableActions.import.id]: false,
            [availableActions.signature.id]: signatureProps.autoFocus,
            [availableActions.takePhoto.id]: false,
        };
    }

    render = () => {
        const {
            availableActions,
            contextStyles,
        } = this.props;
        const {
            [availableActions.annotate.id]: annotateImage,
            [availableActions.delete.id]: deleteImage,
            [availableActions.display.id]: displayImage,
            [availableActions.import.id]: importImage,
            [availableActions.signature.id]: makeSignature,
            [availableActions.takePhoto.id]: takePhoto,
        } = this.state;

        this.styles = getStyles(contextStyles);

        return (
            <React.Fragment>
                { this.renderMenu() }
                { annotateImage && this.renderAnnotateImagePrompt() }
                { deleteImage && this.renderDeletePrompt() }
                { displayImage && this.renderDisplayPrompt() }
                { importImage && this.renderImportPrompt() }
                { makeSignature && this.renderSignaturePrompt() }
                { takePhoto && this.renderTakePhotoPrompt() }
            </React.Fragment>
        );
    };

    renderMenu = () => {
        const {
            actions,
            children,
        } = this.props;

        return (
            <Menu
                contextStyles={ {
                    container: this.styles.menu,
                } }
                button={ (
                    <div
                        className="c-image-picker-menu__container"
                        style={ this.styles.buttonContainer }>
                        { children }
                    </div>
                ) }>
                { actions.map((action) => (
                    <MenuItem
                        contextStyles={ {
                            container: this.styles.menuItem,
                            focusContainer: this.styles.menuItemFocused,
                        } }
                        key={ action.id }
                        onClick={ () => {
                            this.setState({
                                [action.id]: true,
                            });
                        } }
                        text={ action.text } />
                )) }
            </Menu>
        );
    };

    renderAnnotateImagePrompt = () => {
        const {
            annotateHelperText,
            availableActions,
            cancelButtonText,
        } = this.props;

        return (
            <Prompt
                actions={ [
                    <Button
                        contextStyles={ {
                            primary: this.styles.actionButton,
                            primaryText: this.styles.actionButtonText,
                        } }
                        key={ availableActions.annotate.id }
                        onClick={ this.handleAnnotateImage }
                        text={ availableActions.annotate.text }
                        variant={ VARIANT.CONTAINED } />,
                    <Button
                        contextStyles={ {
                            secondary: this.styles.cancelButton,
                            secondaryText: this.styles.cancelButtonText,
                        } }
                        key="cancel"
                        onClick={ () => {
                            this.setState({
                                [availableActions.annotate.id]: false,
                            });
                        } }
                        text={ cancelButtonText }
                        variant={ VARIANT.OUTLINED } />,
                ] }
                contextStyles={ {
                    actions: this.styles.actions,
                    content: this.styles.content,
                    divider: this.styles.divider,
                    header: this.styles.header,
                    modal: this.styles.modal,
                    overlay: this.styles.overlay,
                    title: this.styles.title,
                } }
                title={ availableActions.annotate.text }>
                <p>
                    { annotateHelperText }
                </p>
            </Prompt>
        );
    };

    renderDeletePrompt() {
        const {
            availableActions,
            cancelButtonText,
            deleteHelperText,
        } = this.props;

        return (
            <Prompt
                actions={ [
                    <Button
                        contextStyles={ {
                            primary: this.styles.actionButton,
                            primaryText: this.styles.actionButtonText,
                        } }
                        key={ availableActions.delete.id }
                        onClick={ this.handleDeleteImage }
                        text={ availableActions.delete.text }
                        variant={ VARIANT.CONTAINED } />,
                    <Button
                        contextStyles={ {
                            secondary: this.styles.cancelButton,
                            secondaryText: this.styles.cancelButtonText,
                        } }
                        key="cancel"
                        onClick={ () => {
                            this.setState({
                                [availableActions.delete.id]: false,
                            });
                        } }
                        text={ cancelButtonText }
                        variant={ VARIANT.OUTLINED } />,
                ] }
                contextStyles={ {
                    actions: this.styles.actions,
                    content: this.styles.content,
                    divider: this.styles.divider,
                    header: this.styles.header,
                    modal: this.styles.modal,
                    overlay: this.styles.overlay,
                    title: this.styles.title,
                } }
                title={ availableActions.delete.text }>
                <p>
                    { deleteHelperText }
                </p>
            </Prompt>
        );
    };

    renderDisplayPrompt = () => {
        const {
            availableActions,
            cancelButtonText,
            RawImage,
        } = this.props;


        return (
            <Prompt
                actions={ [
                    <Button
                        contextStyles={ {
                            secondary: this.styles.cancelButton,
                            secondaryText: this.styles.cancelButtonText,
                        } }
                        key="cancel"
                        onClick={ () => {
                            this.setState({
                                [availableActions.display.id]: false,
                            });
                        } }
                        text={ cancelButtonText }
                        variant={ VARIANT.OUTLINED } />,
                ] }
                contextStyles={ {
                    actions: this.styles.actions,
                    content: this.styles.content,
                    divider: this.styles.divider,
                    header: this.styles.header,
                    modal: {
                        ...this.styles.modal,
                        maxHeight: '80%',
                        maxWidth: '80%',
                    },
                    overlay: this.styles.overlay,
                    title: this.styles.title,
                } }
                title={ availableActions.display.text }>
                { RawImage }
            </Prompt>
        );
    };

    renderImportPrompt() {
        const {
            availableActions,
            cancelButtonText,
            importHelperText,
        } = this.props;

        return (
            <Prompt
                actions={ [
                    <FilePickerButton
                        fileTypes={ [ 'images' ] }
                        contextStyles={ {
                            button: this.styles.actionButton,
                            buttontext: this.styles.actionButtonText,
                        } }
                        key={ availableActions.import.id }
                        buttonText={ availableActions.import.text }
                        buttonVariant={ VARIANT.CONTAINED }
                        onChange={ this.handleImportImage } />,
                    <Button
                        contextStyles={ {
                            secondary: this.styles.cancelButton,
                            secondaryText: this.styles.cancelButtonText,
                        } }
                        key="cancel"
                        onClick={ () => {
                            this.setState({
                                [availableActions.import.id]: false,
                            });
                        } }
                        text={ cancelButtonText }
                        variant={ VARIANT.OUTLINED } />,
                ] }
                contextStyles={ {
                    actions: this.styles.actions,
                    content: this.styles.content,
                    divider: this.styles.divider,
                    header: this.styles.header,
                    modal: this.styles.modal,
                    overlay: this.styles.overlay,
                    title: this.styles.title,
                } }
                title={ availableActions.import.text }>
                <p>
                    { importHelperText }
                </p>
            </Prompt>
        );
    };

    renderSignaturePrompt = () => {
        const {
            acceptButtonText,
            availableActions,
            cancelButtonText,
            clearButtonText,
        } = this.props;
        const {
            backgroundColor,
            height,
            lineColor,
            width,
        } = this.styles.signatureStyle;

        return (
            <SignaturePrompt
                acceptButtonText={ acceptButtonText }
                cancelButtonText={ cancelButtonText }
                canvasBackgroundColor={ backgroundColor }
                canvasHeight={ height }
                canvasPenColor={ lineColor }
                canvasWidth={ width }
                clearButtonText={ clearButtonText }
                contextStyles={ {
                    actionButton: this.styles.actionButton,
                    actionButtonText: this.styles.actionButtonText,
                    actions: this.styles.actions,
                    cancelButton: this.styles.cancelButton,
                    cancelButtonText: this.styles.cancelButtonText,
                    content: this.styles.content,
                    divider: this.styles.divider,
                    header: this.styles.header,
                    modal: this.styles.modal,
                    overlay: this.styles.overlay,
                    undoButton: this.styles.undoButton,
                    undoButtonText: this.styles.undoButtonText,
                } }
                onCancel={ () => {
                    this.setState({
                        [availableActions.signature.id]: false,
                    });
                } }
                onSave={ this.handleSignImage } />
        );
    };

    renderTakePhotoPrompt = () => {
        const {
            availableActions,
            cancelButtonText,
            mimeType,
        } = this.props;

        return (
            <WebCamPrompt
                cancelPhotoText={ cancelButtonText }
                contextStyles={ {
                    actions: this.styles.actions,
                    cancelButton: this.styles.cancelButton,
                    cancelButtonText: this.styles.cancelButtonText,
                    content: this.styles.content,
                    divider: this.styles.divider,
                    header: this.styles.header,
                    modal: this.styles.modal,
                    overlay: this.styles.overlay,
                    takePhotoButton: this.styles.actionButton,
                    takePhotoButtonText: this.styles.actionButtonText,
                    title: this.styles.title,
                } }
                mimeType={ mimeType }
                onCancel={ () => {
                    this.setState({
                        [availableActions.takePhoto.id]: false,
                    });
                } }
                onTake={ this.handleTakePhoto }
                preview={ false }
                takePhotoText={ availableActions.takePhoto.text } />
        );
    };

    handleAnnotateImage = (/* data */) => {
        const {
            availableActions,
            onChangeImage,
        } = this.props;

        // console.log('ANNOTATED IMAGE: ', data); // eslint-disable-line

        // Close prompt
        this.setState({
            [availableActions.annotate.id]: false,
        }, () => {
            // Check for callback
            if (onChangeImage) {
                // Pass data to callback
                // onChangeImage(this.bundleValues(null, data, mimeType));
                this.notImplemented();
            }
        });
    };

    handleDeleteImage = (/* data */) => {
        const {
            availableActions,
            mimeType,
            onChangeImage,
        } = this.props;

        // console.log('DELETED IMAGE: ', data); // eslint-disable-line

        // Close prompt
        this.setState({
            [availableActions.delete.id]: false,
        }, () => {
            // Check for callback
            if (onChangeImage) {
                // Pass data to callback
                onChangeImage(this.bundleValues(null, null, mimeType));
            }
        });
    };

    handleImportImage = (event) => {
        const file = event.target.files[0];

        const readFileDataAsBase64 = () => new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (fileEvent) => {
                resolve(fileEvent.target.result);
            };

            reader.onerror = (err) => {
                reject(err);
            };

            reader.readAsDataURL(file);
        });

        const handleChangeImage = (dataURI, type) => {
            const {
                availableActions,
                onChangeImage,
            } = this.props;

            const data = dataURI.replace(`data:${type};base64,`, '');

            // Close prompt
            this.setState({
                [availableActions.import.id]: false,
            }, () => {
                // Check for callback
                if (onChangeImage) {
                    // Pass data to callback
                    onChangeImage(this.bundleValues(dataURI, data, type));
                }
            });
        };

        const promise = readFileDataAsBase64(file);

        promise.then((data) => {
            handleChangeImage(data, file.type);
        });
    };

    handleSignImage = (dataUri) => {
        const {
            availableActions,
            onChangeImage,
        } = this.props;

        // console.log('SIGNED IMAGE: ', data); // eslint-disable-line

        // Close prompt
        this.setState({
            [availableActions.signature.id]: false,
        }, () => {
            // Check for callback
            if (onChangeImage) {
                // add save image to record
                const modifiedURI = dataUri.replace('data:', '');
                const splitArray = modifiedURI.split(';base64,');
                if (splitArray.length > 0) {
                    const data = splitArray[1] ? splitArray[1] : null;
                    const mimeType = splitArray[0] ? splitArray[0] : '';
                    onChangeImage({
                        data,
                        mimeType,
                    });
                }
            }
        });
    };

    /**
     * Called when a photo is taken
     * @param {String} dataURI
     */
    handleTakePhoto = (dataURI) => {
        const {
            availableActions,
            mimeType,
            onChangeImage,
        } = this.props;

        const data = dataURI.replace(`data:${mimeType};base64,`, '');

        // Close prompt
        this.setState({
            [availableActions.takePhoto.id]: false,
        }, () => {
            // Check for callback
            if (onChangeImage) {
                // Pass data to callback
                onChangeImage(this.bundleValues(dataURI, data, mimeType));
            }
        });
    };

    bundleValues = (uri = null, data = null, mimeType = undefined) => {
        return {
            uri,
            data,
            mimeType,
        };
    };

    // eslint-disable-next-line class-methods-use-this
    notImplemented(msg = null) {
        const message = msg || 'This feature has not been implemented yet';
        // eslint-disable-next-line no-alert
        window.alert(
            `Not Implemented\n${message}`
        );
    }
}

export default ImagePickerMenu;

export {
    IMAGE_ACTIONS,
};
