import React from 'react';
import { BaseComponentBuilder } from 'cv-react-core';
import { TypeNames } from 'cv-dialog-sdk';
import lang from '../../nls/i18n';
import DropDown from '../../components/base/DropDown';
import Image from '../../components/base/Image';

/**
 * @deprecated You should use XaltDropDown or XaltDropDownMultiSelect
 * A builder for creating drop down menu components
 */
class DropDownComponentBuilder extends BaseComponentBuilder {
    /**
     * COMPONENT BASED PROPERTIES
     */

    /** Begin Context Styles */

    setInputStyles(inputStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            input: inputStyles,
        });
    }

    getInputStyles() {
        const { input } = this.getContextStyles();
        return input || {};
    }

    setMenuStyles(menuStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            menu: menuStyles,
        });
    }
    getMenuStyles() {
        const { menu } = this.getContextStyles();
        return menu || {};
    }

    setMenuItemImageStyles(menuItemStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            menuItemImage: menuItemStyles,
        });
    }
    getMenuItemImageStyles() {
        const { menuItemImage } = this.getContextStyles();
        return menuItemImage || {};
    }

    setMenuItemStyles(menuItemStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            menuItem: menuItemStyles,
        });
    }
    getMenuItemStyles() {
        const { menuItem } = this.getContextStyles();
        return menuItem || {};
    }

    setDropDownStyles(dropDownStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            dropDown: dropDownStyles,
        });
    }

    setMultiple(multiple = false) {
        this.props.multiple = multiple;
    }

    getMultiple() {
        return this.props.multiple;
    }

    setIsComboBox(isComboBox = false) {
        this.props.isComboBox = isComboBox;
    }

    getIsComboBox() {
        return this.props.isComboBox;
    }

    setSelectedItemStyles(selectedItemStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            selectedItem: selectedItemStyles,
        });
    }
    getSelectedItemStyles() {
        const { selectedItem } = this.getContextStyles();
        return selectedItem || {};
    }
    /** End Context Styles */


    /** Begin Props */
    setAutoFocus(autoFocus = true) {
        this.props.autoFocus = autoFocus;
        return this;
    }
    getAutoFocus() {
        return this.props.autoFocus;
    }

    setDisabled(disabled = true) {
        this.props.disabled = disabled;
        return this;
    }
    getDisabled() {
        return this.props.disabled;
    }

    setItems(items = []) {
        if (Array.isArray(items)) {
            this.props.items = items;
        }
        return this;
    }
    getItems() {
        return this.props.items || [];
    }

    setItemsResolver(itemsResolver) {
        this.props.itemsResolver = itemsResolver;
        return this;
    }
    getItemsResolver() {
        return this.props.itemsResolver;
    }

    setOnChange(onChange) {
        if (typeof onChange === 'function') {
            this.props.onChange = onChange;
        }
        return this;
    }
    getOnChange() {
        return this.props.onChange;
    }

    setRenderAdornment(renderAdornment) {
        if (typeof renderAdornment === 'function') {
            this.props.renderAdornment = renderAdornment;
        }
        return this;
    }
    getRenderAdornment() {
        return this.props.renderAdornment;
    }

    setRenderInput(renderInput) {
        if (typeof renderInput === 'function') {
            this.props.renderInput = renderInput;
        }
        return this;
    }

    getRenderInput() {
        return this.props.renderInput;
    }

    setRenderItem(renderItem) {
        if (typeof renderItem === 'function') {
            this.props.renderItem = renderItem;
        }
        return this;
    }
    getRenderItem() {
        return this.props.renderItem;
    }

    setRenderValue(renderValue) {
        if (typeof renderValue === 'function') {
            this.props.renderValue = renderValue;
        }
        return this;
    }
    getRenderValue() {
        return this.props.renderValue;
    }

    setValue(value = '') {
        this.props.value = typeof value === 'object' && value.description ? value.description : value;
        return this;
    }
    getValue() {
        return this.props.value;
    }
    /** End Props */


    /**
     * PROCESS AND UPDATE COMPONENT PROPERTIES
     */

    processDisplay() {
        const property = this.getProperty();
        const propDef = this.getPropDef();
        const stagedValue = this.getStagedValue();
        const viewDef = this.getViewDef();
        const { cardinality } = propDef;
        const isTextWithImage = viewDef.isIconEntryMethod;

        // Default the height and width of menu item iamge to 40
        const imageHeight = 40;
        const imageWidth = 40;

        // Initialize component value
        let selectedItem = null;

        // Set supplied value if valid
        if (stagedValue && typeof stagedValue.value !== 'undefined') {
            selectedItem = stagedValue.value;
        }

        // Set value from record
        else {
            selectedItem = property.value ? property.value : lang.details.dropdown.noSelectedItem;
        }

        if ((selectedItem.type === BaseComponentBuilder.typeNames.CodeRefTypeName) || (selectedItem.type === BaseComponentBuilder.typeNames.ObjectRefTypeName)) {
            this.setValue(selectedItem.description);
        }
        else {
            this.setValue(selectedItem);
        }

        if (propDef.isCodeRefType && cardinality === '*') {
            this.setMultiple(true);
        }

        if (viewDef.isComboBoxEntryMethod || viewDef.autoFillCapable) {
            this.setIsComboBox(true);
        }

        this.setRenderAdornment((value) => {
            const [
                imageName,
                imageSrc,
            ] = this.getImageSrc(value);
            if (isTextWithImage && imageSrc) {
                return (
                    <Image
                        altText={ imageName }
                        imageSrc={ imageSrc }
                        contextStyles={ {
                            image: {
                                width: imageWidth,
                                height: imageHeight,
                            },
                    } } />
                );
            }
            return null;
        });

        this.setRenderInput((value) => {
            const [ imageName ] = this.getImageSrc(value);
            if (isTextWithImage && imageName) {
                return imageName;
            }
            return value;
        });

        this.setRenderItem((MenuItem, item) => {
            const isCodeRef = (item.type === BaseComponentBuilder.typeNames.CodeRefTypeName || item.type === BaseComponentBuilder.typeNames.ObjectRefTypeName);
            const menuItemProps = {
                value: isCodeRef ? item.code : item,
                text: isCodeRef ? item.description : item,
                key: isCodeRef ? (item.code || item.description) : item,
                imageHeight,
                imageWidth,
                isTextWithImage,
                item,
            };

            if (isTextWithImage) {
                const [
                    imageName,
                    imageSrc,
                ] = this.getImageSrc(item.description);
                menuItemProps.imageName = imageName;
                menuItemProps.imageSrc = imageSrc;
            }
            else {
                menuItemProps.imageName = menuItemProps.text;
            }
            return (
                <MenuItem { ...menuItemProps } />
            );
        });

        this.setRenderValue((value) => { /* eslint-disable no-param-reassign */
            // It looks like nothing, but this is a unicode space character that will render with height.
            // Without it, if the result is only an empty string or spaces, the control does not size to the text height.
            // see http://emptycharacter.com/
            const specialSpace = '\u00A0'; // hexcode for non-breaking space
            const availableValues = this.getAvailableValues();
            const allItems = availableValues();
            if (Array.isArray(value)) {
                if (value.length === 1) {
                    value = value[0].description;
                }
                else if (value.length === 0) {
                    value = lang.details.dropdown.noSelection;
                }
                else if (allItems && value.length === allItems.length) {
                    value = lang.details.dropdown.allSelected;
                }
                else {
                    value = lang.details.dropdown.multipleSelections;
                }
            }
            let v = '';
            if (typeof value === 'object') {
                v = value.description ? `${value.description}` : '';
            }
            else {
                v = `${value}`;
            }

            return (v && v.length > 0 ? v : specialSpace);
        });
    }

    processStyle() {
        const categorizedStyles = BaseComponentBuilder.styleHelper.categorizeWebStyles(this.getStyle());
        const viewType = this.getViewType();

        // This is a temporary change here to fit dropdowns into Maintainable lists.
        // We have permanent solution for this in new UX.
        if (viewType === TypeNames.ListTypeName) {
            this.setDropDownStyles({
                borderWidth: 0,
            });
        }

        this.setContainerStyles({
            ...categorizedStyles.container,
            ...this.getContainerStyles(),
        });
        this.setMenuStyles({
            ...categorizedStyles.text,
            ...this.getMenuStyles(),
        });
        this.setMenuItemImageStyles({
            objectFit: 'contain',
            ...this.getMenuItemImageStyles(),
        });
        this.setMenuItemStyles({
            ...categorizedStyles.text,
            ...this.getMenuItemStyles(),
        });
        this.setSelectedItemStyles({
            ...categorizedStyles.text,
            ...this.getSelectedItemStyles(),
        });
        this.setInputStyles({
            ...categorizedStyles.text,
            ...this.getInputStyles(),
        });
    }

    processChangeHandler() {
        const onValueChangeHandler = this.getOnValueChangeHandler();
        const isReadMode = this.getReadMode();
        const property = this.getProperty();
        const isMultiple = this.getMultiple();
        const value = this.getValue();
        const { name: propertyName = '' } = property;

        if (!isReadMode && !!propertyName && !!onValueChangeHandler) {
            this.setOnChange((event, childProps) => {
                let propertyList = [];
                if (isMultiple && Array.isArray(value)) {
                    propertyList = propertyList.concat(value);
                    const index = propertyList.findIndex((item) => item.description === childProps.value.description);
                    if (index > -1) {
                        propertyList.splice(index, 1);
                    }
                    else {
                        propertyList.push(childProps.value);
                    }
                }
                else {
                    propertyList = (childProps.value === lang.details.dropdown.noSelectedItem ? null : childProps.value);
                }
                onValueChangeHandler(propertyName, propertyList);
            });
        }
    }

    processMiscProps() {
        const isFocused = this.getFocusedState();
        const itemsResolver = this.getAvailableValuesLoader();

        if (isFocused) { this.setAutoFocus(); }
        if (itemsResolver) {
            this.setItemsResolver(() => (
                Promise.resolve(itemsResolver())
            ));
        }
    }

    buildProps() {
        // Update the display text
        this.processDisplay();

        // Update the drop down style
        this.processStyle();

        // Update change handler to support property / value data
        this.processChangeHandler();

        // Set miscellaneous properties based on dialog data
        this.processMiscProps();
    }


    /**
     * BUILD AND RETURN COMPONENT AS REACT ELEMENT
     */

    build() {
        // Process and construct final props
        this.buildProps();

        return <DropDown { ...this.getProps() } />;
    }
}

export default DropDownComponentBuilder;
