/*       */


import chartPropLayoutFactory from './ChartPropLayoutFactory';
import generalLayoutFactory from './GeneralLayoutFactory';
import imagePropLayoutFactory from './ImagePropLayoutFactory';
import signatureLayoutFactory from './SignaturePropLayoutFactory';
import lineLayoutFactory from './LineLayoutFactory';
import plistLayoutFactory from './PlistLayoutFactory';
import listLayoutFactory from './ListLayoutFactory';
import viewLayoutFactory from './ViewLayoutFactory';
import alistLayoutFactory from './AlistLayoutFactory';

class GmlAttributeFactory {
    attributeFor = (json, key, context) => {
        return generalLayoutFactory.attributeFor(json, key, context)
            || plistLayoutFactory.attributeFor(json, key, context)
            || lineLayoutFactory.attributeFor(json, key, context)
            || signatureLayoutFactory.attributeFor(json, key, context)
            || imagePropLayoutFactory.attributeFor(json, key, context)
            || chartPropLayoutFactory.attributeFor(json, key, context)
            || listLayoutFactory.attributeFor(json, key, context)
            || viewLayoutFactory.attributeFor(json, key, context)
            || alistLayoutFactory.attributeFor(json, key, context);
    }
}

const gmlAttributeFactory = new GmlAttributeFactory();
export default gmlAttributeFactory;
