/* eslint-disable no-plusplus */
import { SaltDocument } from '../SaltDocument';

const pbiTemplate = require('./pbi_simple.json');

class PbiTranspiler {
    generateSalt(dialog) {
        const document = new SaltDocument(pbiTemplate);
        const viewEl = document.getByExpr('**.view');
        document.singleSelectNode(viewEl);
        // @TODO alias, viewAlias - we may want to allow salt to also reference user defined aliases
        const { view } = dialog;
        const viewId = SaltDocument.getViewId(view);
        document.setAttribute('id', viewId);
        return document.rootNode;
    }
}

const pbiTranspiler = new PbiTranspiler();
export default pbiTranspiler;
