/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import * as pbi from 'powerbi-client';

// Styles
import getStyles from './PowerBI.styles';

export default class PowerBI extends PureComponent {
	static defaultProps = {
	    background: 1,
	    contextStyles: {},
	    filterPaneEnabled: false,
	    layoutType: 2,
	    navContentPaneEnabled: false,
	    tokenType: 1,
	    type: 'report',
	    useConfiguration: false,
	};

  	static propTypes = {
  	    /** Access token for thr report */
  	    accessToken: PropTypes.string.isRequired,

  	    /** 0 is for Default background from powerBi, 1 is for transparent background */
  	    background: PropTypes.oneOf([
  	        0,
  	        1,
  	    ]),

  	    /** Styles for this component */
  	    contextStyles: PropTypes.shape({
  	        /** Styling for the power BI container */
  	        container: PropTypes.object,
  	    }),

  	    /** Whole configuration object */
  	    embedConfiguration: PropTypes.shape({
  	        /** Access token for thr report */
  	        accessToken: PropTypes.string.isRequired,

  	        /** Embed URL of the report */
  	        embedUrl: PropTypes.string.isRequired,

  	        /** Report Id */
  	        id: PropTypes.string.isRequired,

  	        /** Settings for thr report */
  	        settings: PropTypes.shape({
  	            /** 0 is for Default background from powerBi, 1 is for transparent background */
  	            background: PropTypes.oneOf([
  	                0,
  	                1,
  	            ]),

  	            /** Property to show/hide filter pane */
  	            filterPaneEnabled: PropTypes.bool,

  	            /** Layout of the embed powerbi report */
  	            layoutType: PropTypes.oneOf([
  	                0,
  	                1,
  	                2,
  	                3,
  	            ]),

  	            /** Locale settings for the report */
  	            localeSettings: PropTypes.shape({
  	                /** Defines the language that powerBI uses for localization */
  	                language: PropTypes.string,

  	                /** Defines the text formatting that powerBI uses for dates, currency etc */
  	                formatLocale: PropTypes.string,
  	            }),

  	            /** Property to show/hide page navigation */
  	            navContentPaneEnabled: PropTypes.bool,
  	        }),

  	        /** Type of Access token. 0 is for AAD token and 1 is for Embed Token */
  	        tokenType: PropTypes.oneOf([
  	            0,
  	            1,
  	        ]).isRequired,

  	        /** The type of embed */
  	        type: PropTypes.string.isRequired,
  	    }),

  	    /** Embed URL of the report */
  	    embedUrl: PropTypes.string.isRequired,

  	    /** Property to show/hide filter pane */
  	    filterPaneEnabled: PropTypes.bool,

  	    /** Defines the text formatting that powerBI uses for dates, currency etc */
  	    formatLocale: PropTypes.string,

  	    /** Report Id */
  	    id: PropTypes.string.isRequired,

  	    /** Defines the language that powerBI uses for localization */
  	    language: PropTypes.string,

  	    /** Layout of the embed powerbi report */
  	    layoutType: PropTypes.oneOf([
  	        0,
  	        1,
  	        2,
  	        3,
  	    ]),

  	    /** Property to show/hide page navigation */
  	    navContentPaneEnabled: PropTypes.bool,

  	    /** Type of Access token. 0 is for AAD token and 1 is for Embed Token */
  	    tokenType: PropTypes.oneOf([
  	        0,
  	        1,
		  ]),

  	    /** The type of embed */
  	    type: PropTypes.string,

  	    /** Defines whether to use config object or individual properties */
  	    useConfiguration: PropTypes.bool,
  	};

  	constructor(props) {
  	    super(props);
  	    this.pbiRef = createRef();
  	}

  	render() {
  	    const { contextStyles } = this.props;
  	    const { container: containerStyles } = getStyles(contextStyles);
  	    return (
  	        <div
  	            ref={ this.pbiRef }
  	            style={ containerStyles } />
  	    );
  	}

  	componentDidMount() {
  	    const powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
  	    powerbi.embed(this.pbiRef.current, this.getConfiguration());
  	}

	/** Helper method to get the configuration for Power Bi report */
	getConfiguration = () => {
	    const {
	        accessToken,
	        background,
	        embedConfiguration,
	        embedUrl,
	        filterPaneEnabled,
	        formatLocale,
	        id,
	        language,
	        layoutType,
	        navContentPaneEnabled,
	        tokenType,
	        type,
	        useConfiguration,
	    } = this.props;

	    /** Use Embed Configuration if it is passed */
	    if (useConfiguration && embedConfiguration) {
	        return embedConfiguration;
	    }

	    /** When no config object is available build configuration object */
	    const configuration = {
	        accessToken,
	        embedUrl,
	        id,
	        settings: {
	            background,
	            filterPaneEnabled,
	            layoutType,
	            navContentPaneEnabled,
	        },
	        tokenType,
	        type,
	    };

	    /** Apply locale settings for the report */
	    if (language) {
	        configuration.settings.localeSettings = {
	            formatLocale,
	            language,
	        };
	    }

	    return configuration;
	};
}
