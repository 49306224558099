/**
 * Core component style object that contains style objects
 * for all the styles for inner elements.
 */
const coreStyles = {
    button: {
        width: 'unset',
        marginBottom: '12px',
    },

    buttonPrimary: {
        width: '100%',
        padding: '13px',
        boxShadow: 'none',
    },

    buttonPrimaryHover: {
    },

    buttonPrimaryText: {
    },

    buttonText: {
    },

    buttonSecondary: {
        width: '100%',
        padding: '13px',
    },

    buttonSecondaryHover: {
    },

    buttonSecondaryText: {
    },

    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '342px',
    },

    dividerContainer: {
        alignItems: 'center',
        display: 'flex',
        marginBottom: '10px',
    },

    dividerLine: {
        background: '#000000',
        height: '1px',
        flexGrow: 1,
    },

    dividerText: {
        color: '#000000',
        fontFamily: 'Roboto',
        fontSize: '12px',
        lineHeight: '14px',
        padding: '0 10px',
    },

    error: {
        background: '#ffffff',
        borderRadius: '6px',
        padding: '15px',
        boxSizing: 'border-box',
        marginBottom: '20px',
    },

    errorText: {
        lineHeight: '20px',
    },

    input: {
        lineHeight: '26px',
        padding: '0px 8px',
    },

    savePasswordContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '20px',
    },

    textField: {
        marginBottom: '12px',
        height: '50px',
        flexGrow: 0,
    },

    textLabel: {
        lineHeight: '20px',
    },
};

/**
 * Combine all styles, including context styles,
 * into a single style object for
 * @param {Object} contextStyles
 */
const getStyles = (contextStyles = {}, error) => {
    const { textField } = contextStyles;
    const {
        errorTextColor,
        activeBorderColor,
        borderColor,
        ...restTextFieldStyles
    } = textField;

    // This is a temporary solution here to ahieve hover, focused and error styles for textfields.
    const textFieldStyles = {
        '& label': {
            color: error ? errorTextColor : '#000000',
        },
        '& label.Mui-focused': {
            color: error ? errorTextColor : activeBorderColor,
        },
        '&:hover label': {
            color: error ? errorTextColor : activeBorderColor,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: `${error ? errorTextColor : borderColor}`,
            },
            '&:hover fieldset': {
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: `${error ? errorTextColor : activeBorderColor}`
            },
            '&.Mui-focused fieldset': {
                borderWidth: 2,
                borderStyle: 'solid',
                borderColor: `${error ? errorTextColor : activeBorderColor}`
            },
        },
    };
    return {
        button: {
            ...coreStyles.button,
            ...contextStyles.button,
        },

        buttonPrimary: {
            ...coreStyles.buttonPrimary,
            ...contextStyles.buttonPrimary,
        },

        buttonPrimaryHover: {
            ...coreStyles.buttonPrimaryHover,
            ...contextStyles.buttonPrimaryHover,
        },

        buttonPrimaryText: {
            ...coreStyles.buttonPrimaryText,
            ...contextStyles.buttonPrimaryText,
        },

        buttonText: {
            ...coreStyles.buttonText,
            ...contextStyles.buttonText,
        },

        buttonSecondary: {
            ...coreStyles.buttonSecondary,
            ...contextStyles.buttonSecondary,
        },

        buttonSecondaryText: {
            ...coreStyles.buttonSecondaryText,
            ...contextStyles.buttonSecondaryText,
        },

        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        dividerContainer: {
            ...coreStyles.dividerContainer,
            ...contextStyles.dividerContainer,
        },

        dividerLine: {
            ...coreStyles.dividerLine,
            ...contextStyles.dividerLine,
        },

        dividerText: {
            ...coreStyles.dividerText,
            ...contextStyles.dividerText,
        },

        error: {
            ...coreStyles.error,
            ...contextStyles.error,
        },

        errorText: {
            ...coreStyles.errorText,
            ...contextStyles.errorText,
        },

        input: {
            ...coreStyles.input,
            ...contextStyles.input,
        },

        savePasswordContainer: {
            ...coreStyles.savePasswordContainer,
            ...contextStyles.savePasswordContainer,
        },

        textField: {
            ...coreStyles.textField,
            ...textFieldStyles,
            ...restTextFieldStyles,
        },

        textLabel: {
            ...coreStyles.textLabel,
            ...contextStyles.textLabel,
        },
    };
};

export default getStyles;
