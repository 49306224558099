/*       */

import AbstractGml from './AbstractGml';
import { ACTION, GENERAL } from '../exportClassConstants';
import StyleSet from '../StyleSet';
import GmlUtil from '../GmlUtil';
import { INNER, PRIMARY_BUTTON_PREFIX } from '../gmlConstants';
import SaltBoxModel from '../SaltBoxModel';

export default class Alist extends AbstractGml {
    asSalt(parentContext, assertExpr = '') {
        const myContext = this.updateContext(parentContext);
        const styleSet = new StyleSet();
        this.exportStyleTo(styleSet, myContext, [ GENERAL ]);
        this.applyDefaultFlex(myContext, styleSet);
        this.applyEquallySized(myContext, styleSet);
        this.applyFlexForFiller(myContext, styleSet);
        const scrollA = this.getScrollAttribute(myContext);
        const rawChildren = this.children(myContext);
        const children = scrollA ? rawChildren : GmlUtil.asRowChildren(myContext, styleSet, rawChildren).children;
        this.addBackgroundImage(myContext, children);

        const sbm = new SaltBoxModel(styleSet, children, this.asAssertAttribute(this.asQualifierExprString(assertExpr)));

        // Wrap in a containing box or action if there are special needs.
        return this.wrapForSpecialCases(myContext, parentContext, sbm);
    }

    children(myContext) {
        const children = [];
        // The content will be a comma separated list of action ids.
        const content = GmlUtil.getValueForExpr(this.json, [ INNER ]) || '';
        if (content) {
            const parts = content.split(',').map(m => m.trim());
            parts.forEach((e) => {
                let actionId = e;
                let buttonType = 'secondary';
                if (actionId.startsWith(PRIMARY_BUTTON_PREFIX)) {
                    actionId = e.substring(PRIMARY_BUTTON_PREFIX.length);
                    buttonType = 'primary';
                }
                const styleSet = new StyleSet();
                styleSet.flexPolicy.setStandardShrink();
                this.exportStyleTo(styleSet, myContext, [ ACTION ]);
                children.push({
                    box: {
                        ...styleSet.asStyleAttribute(),
                        ...styleSet.asXStyleAttribute(),
                        children: [ {
                            action: {
                                id: actionId,
                                type: 'button',
                                'x-style': {
                                    buttonType,
                                    button: { ...GmlUtil.resolveMargin(styleSet.xStyle.layout) },
                                },
                            },
                        } ],
                    },
                });
            });
        }
        return children;
    }
}
