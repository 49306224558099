import React, { Component } from 'react';
import componentFactory from '../componentFactory';
import componentPropTypes from './abstractComponentPropTypes';

// Note: these should be a subset of abstractComponentPropTypes
const propDefinitions = {
    style: componentPropTypes.style,
    name: componentPropTypes.name,
    value: componentPropTypes.value,
    tipText: componentPropTypes.tipText,
    isReadMode: componentPropTypes.isReadMode,
    isFocused: componentPropTypes.isFocused,
    onValueChanged: componentPropTypes.onValueChanged,
    onLoadOptionValues: componentPropTypes.onLoadOptionValues,
    xStyle: componentPropTypes.xStyle,
    optionValues: componentPropTypes.optionValues,
};
export default class DropDownMultiSelectField extends Component {
    static propTypes = propDefinitions;

    render() {
        const {
            style,
            tipText,
            isReadMode,
            isFocused,
            onValueChanged,
            xStyle,
            optionValues,
        } = this.props;

        const dropDownFieldProps = {
            style,
            value: this.getValue(),
            tipText,
            isReadMode,
            isFocused,
            onValueChanged,
            xStyle,
            optionValues,
        };
        return React.createElement(componentFactory.getPlatformPropertyComponent('dropDownMultiSelectField'), { ...dropDownFieldProps });
    }

    componentDidMount() {
        const { onLoadOptionValues } = this.props;
        if (onLoadOptionValues) onLoadOptionValues();
    }

    getValue = () => {
        // Value should always be either code -> CodeRef/objectId -> ObjectRef/plain string. It will not be a description.
        const { value, optionValues } = this.props;
        return value.reduce((accumulator, currentValue) => {
            const option = optionValues.find((item) => item.value === currentValue);
            if (option) {
                return [ ...accumulator, option ];
            }
            return accumulator;
        }, []);
    }
}
