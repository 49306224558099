/* eslint-disable no-underscore-dangle */
import { storage } from 'cv-dialog-sdk';
import { action, observable, toJS } from 'mobx';
import { constants } from '../constants';
import { utilities } from '../utilities';

const getModifiedDate = () => {
    return new Date().toISOString();
};

const SettingsSavedDate = 'savedDate';
const KeyValuePair = 'keyValuePairs';
const SettingsHistory = 'settingsHistory';

class CacheData {
    modifiedDate = getModifiedDate();
    session = null;
    workbench = {};
    ui = {};
    settings = {};
    settingsHistory = [];
    keyValuePairs = {};
    theme = {};

    constructor(cacheData = null) {
        if (!cacheData) return;

        // Hydrate the object from object passed in
        const stores = constants.storage.CACHE_STORES;
        if (Object.prototype.hasOwnProperty.call(cacheData, stores.SESSION)) this.setSession(cacheData[stores.SESSION]);
        if (Object.prototype.hasOwnProperty.call(cacheData, stores.WORKBENCH)) this.setWorkbench(cacheData[stores.WORKBENCH]);
        if (Object.prototype.hasOwnProperty.call(cacheData, stores.UI)) this.setUi(cacheData[stores.UI]);
        if (Object.prototype.hasOwnProperty.call(cacheData, stores.SETTINGS)) this.setSettings(cacheData[stores.SETTINGS]);
        if (Object.prototype.hasOwnProperty.call(cacheData, stores.THEME)) this.setTheme(cacheData[stores.THEME]);
        if (Object.prototype.hasOwnProperty.call(cacheData, SettingsHistory)) this.settingsHistory = cacheData[SettingsHistory];

        // Set all random key value pairings
        Object.keys(cacheData[KeyValuePair]).forEach((key) => {
            this.setKeyValue(key, cacheData[KeyValuePair][key]);
        });
    }

    getSession() {
        return this.session;
    }

    setSession = (sessionData) => {
        this.session = sessionData;
        this.updateModifiedDate();
    }

    getWorkbench() {
        return this.workbench;
    }

    setWorkbench = (workbenchData) => {
        this.workbench = workbenchData;
        this.updateModifiedDate();
    }

    getUi() {
        return this.ui;
    }

    setUi = (uiData) => {
        this.ui = uiData;
        this.updateModifiedDate();
    }

    getTheme() {
        return this.theme;
    }

    setTheme = (themeData) => {
        this.theme = themeData;
        this.updateModifiedDate();
    }

    getSettings() {
        return this.settings;
    }

    setSettings = (settingsData) => {
        this.settings = this.addSettingHistory(settingsData);
    }

    clearSettingsHistory = () => {
        this.settingsHistory = [];
    }

    getModifiedDate() {
        return this.modifiedDate;
    }

    setModifiedDate = (timeStamp) => {
        this.modifiedDate = timeStamp;
    }

    getKeyValue(key) {
        return this.keyValuePairs[key];
    }

    setKeyValue = (key, value) => {
        this.keyValuePairs[key] = value;
        this.updateModifiedDate();
    }

    clearKeyValues = () => {
        this.keyValuePairs = {};
        this.updateModifiedDate();
    }

    updateModifiedDate = () => {
        this.setModifiedDate(getModifiedDate());
    }

    addSettingHistory = (settings) => {
        // Cleanup history
        this.cleanupOldSettings();

        // Create a copy to be returned if needs to be reset
        const saveSettings = {};
        utilities.objectHelpers.deepMerge(saveSettings, settings);

        // If we do not have any keys for settings, we are loading from cache so do not clear credentials
        // Check and see if we are getting a new tenant value. If so we need to clear current settings user creds
        if (Object.keys(this.settings).length > 0 && settings[constants.session.TENANT_ID] !== this.settings[constants.session.TENANT_ID]) {
            this.settings = {};
            // reset theme and user creds
            saveSettings[constants.storage.USER_CREDS] = {};
            // saveSettings[constants.storage.THEME] = {};
        }

        // Stamp settings saved settings with a time so we can remove old storage.
        saveSettings[SettingsSavedDate] = getModifiedDate();

        // Check if we have already stored the setting.
        const foundSetting = this.settingsHistory.findIndex(setting => {
            const tenantId = setting[constants.settings.TENANT_ID];
            return tenantId
                && tenantId.localeCompare(settings[constants.settings.TENANT_ID]) === 0;
        });

        // If we have the setting just update it.
        if (foundSetting >= 0) {
            // Copy saved settings
            const mergedSettings = {};
            utilities.objectHelpers.deepMerge(mergedSettings, this.settingsHistory[foundSetting]);

            // Create a merged copy of history and new settings
            utilities.objectHelpers.deepMerge(mergedSettings, saveSettings);

            // Update setting history
            utilities.objectHelpers.deepMerge(this.settingsHistory[foundSetting], mergedSettings);
            // Do not save password in history
            if (this.settingsHistory[foundSetting][constants.storage.USER_CREDS]) {
                this.settingsHistory[foundSetting][constants.storage.USER_CREDS][constants.session.PASSWORD] = '';
                this.settingsHistory[foundSetting][constants.storage.USER_CREDS][constants.session.SAVE_PASSWORD] = false;
            }
            return mergedSettings;
        }

        // If we don't find the setting already saved, push one onto the stack.
        this.settingsHistory.push(saveSettings);
        return saveSettings;
    }

    cleanupOldSettings = () => {
        const newHistory = [];
        const purgeDate = new Date().getTime() - (30 * 24 * 60 * 60 * 1000);
        this.settingsHistory.forEach(setting => {
            const savedDate = new Date(setting[SettingsSavedDate]).getTime();
            if (savedDate > purgeDate) {
                newHistory.push(setting);
            }
        });
        this.settingsHistory = newHistory;
    }
}

export default class CacheStore {
    // these object will be persisted to local storage
    @observable cacheData = new CacheData();

    constructor() {
        this.cacheRestoredPromise = new Promise((resolve, reject) => {
            this.restoreApp().then(resolve).catch(reject);
        });
    }

    get cacheStores() {
        return constants.storage.CACHE_STORES;
    }

    getValueForCacheType(cacheType, key) {
        switch (cacheType) {
            case constants.storage.CACHE_STORES.SESSION:
                return this.cacheData.session[key];
            case constants.storage.CACHE_STORES.WORKBENCH:
                return this.cacheData.workbench[key];
            case constants.storage.CACHE_STORES.UI:
                return this.cacheData.ui[key];
            case constants.storage.CACHE_STORES.SETTINGS:
                return this.cacheData.settings[key];
            default:
                return this.cacheData.getKeyValue(key);
        }
    }

    @action setValueForCacheType(cacheType, key, value) {
        const { session, workbench, ui, settings } = this.cacheData;
        switch (cacheType) {
            case constants.storage.CACHE_STORES.SESSION:
                session[key] = value;
                this.cacheData.setSession(session);
                break;
            case constants.storage.CACHE_STORES.WORKBENCH:
                workbench[key] = value;
                this.cacheData.setWorkbench(workbench);
                break;
            case constants.storage.CACHE_STORES.UI:
                ui[key] = value;
                this.cacheData.setUi(ui);
                break;
            case constants.storage.CACHE_STORES.SETTINGS:
                settings[key] = value;
                this.cacheData.setSettings(settings);
                break;
            default:
                this.cacheData.setKeyValue(key, value);
        }
        this.save();
    }

    @action clearValueForCacheType(cacheType, key) {
        const { session, workbench, ui, settings } = this.cacheData;
        const value = '';
        switch (cacheType) {
            case constants.storage.CACHE_STORES.SESSION:
                session[key] = value;
                this.cacheData.setSession(session);
                break;
            case constants.storage.CACHE_STORES.WORKBENCH:
                workbench[key] = value;
                this.cacheData.setWorkbench(workbench);
                break;
            case constants.storage.CACHE_STORES.UI:
                ui[key] = value;
                this.cacheData.setUi(ui);
                break;
            case constants.storage.CACHE_STORES.SETTINGS:
                settings[key] = value;
                this.cacheData.setSettings(settings);
                break;
            default:
                return;
        }
        this.save();
    }

    @action clearCacheType(cacheType) {
        switch (cacheType) {
            case constants.storage.CACHE_STORES.SESSION:
                this.cacheData.setSession({});
                break;
            case constants.storage.CACHE_STORES.WORKBENCH:
                this.cacheData.setWorkbench({});
                break;
            case constants.storage.CACHE_STORES.UI:
                this.cacheData.setUi({});
                break;
            case constants.storage.CACHE_STORES.SETTINGS:
                this.cacheData.setSettings({});
                this.cacheData.clearSettingsHistory();
                break;
            default:
                return;
        }
        this.save();
    }

    getCacheType(cacheType) {
        switch (cacheType) {
            case constants.storage.CACHE_STORES.SESSION:
                return this.cacheData.getSession();
            case constants.storage.CACHE_STORES.WORKBENCH:
                return this.cacheData.getWorkbench();
            case constants.storage.CACHE_STORES.UI:
                return this.cacheData.getUi();
            case constants.storage.CACHE_STORES.SETTINGS:
                return this.cacheData.getSettings();
            default:
                return null;
        }
    }

    @action setCacheType(cacheType, cacheData) {
        switch (cacheType) {
            case constants.storage.CACHE_STORES.SESSION:
                this.cacheData.setSession(cacheData);
                break;
            case constants.storage.CACHE_STORES.WORKBENCH:
                this.cacheData.setWorkbench(cacheData);
                break;
            case constants.storage.CACHE_STORES.UI:
                this.cacheData.setUi(cacheData);
                break;
            case constants.storage.CACHE_STORES.SETTINGS:
                this.cacheData.setSettings(cacheData);
                break;
            case constants.storage.CACHE_STORES.THEME:
                this.cacheData.setTheme(cacheData);
                break;
            default:
                return;
        }
        this.save();
    }

    @action clearCache() {
        this.cacheData = new CacheData();
        this.save();
    }

    save() {
        utilities.defaultPromiseHandler(storage.setJson(constants.storage.CACHE, toJS(this.cacheData)));
    }

    restoreApp() {
        return utilities.defaultPromiseHandler(storage.getJson(constants.storage.CACHE)).then(result => {
            this.cacheData = new CacheData(result);
            return this.cacheData;
        });
    }
}
