var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ClientEventType } from "../client/ClientEventType";
import { JsonClientResponse } from "../client/JsonClientResponse";
import { DialogModeEnum } from '../models/types';
import { ActionParametersVisitor } from "../proxy/ActionParametersVisitor";
import { DialogProxyTools } from "../proxy/DialogProxyTools";
import { DialogRedirectionVisitor } from "../proxy/DialogRedirectionVisitor";
import { DialogRequest } from "../proxy/DialogRequest";
import { DialogVisitor } from "../proxy/DialogVisitor";
import { LoginVisitor } from "../proxy/LoginVisitor";
import { RecordSetVisitor } from "../proxy/RecordSetVisitor";
import { RecordVisitor } from "../proxy/RecordVisitor";
import { RedirectionVisitor } from "../proxy/RedirectionVisitor";
import { SessionVisitor } from "../proxy/SessionVisitor";
import { WriteLargePropertyParametersVisitor } from "../proxy/WriteLargePropertyParametersVisitor";
import { storage as clientStorage } from "../storage";
import { Base64 } from "../util/Base64";
import { Log } from '../util/Log';
import { GenericDialogDelegateTools } from "./GenericDialogDelegateTools";
import { OfflineItemsRecordSetVisitor } from "./OfflineItemsRecordSetVisitor";
import { Download_Manager_EnterOfflineMode_FORM } from "./samples/Download_Manager_EnterOfflineMode_FORM";
import { Download_Manager_EnterOfflineMode_RECORD } from "./samples/Download_Manager_EnterOfflineMode_RECORD";
import { Download_Manager_EnterOfflineMode_REDIRECTION } from "./samples/Download_Manager_EnterOfflineMode_REDIRECTION";
import { Download_Manager_FORM } from "./samples/Download_Manager_FORM";
import { Download_Manager_FORM_REDIRECTION } from "./samples/Download_Manager_FORM_REDIRECTION";
import { MobileComment_CommentNotAvailable_RECORD } from "./samples/MobileComment_CommentNotAvailable_RECORD";
import { MobileComment_ImageNotAvailable_RECORD } from "./samples/MobileComment_ImageNotAvailable_RECORD";
import { SelectedOfflineItemsVisitor } from "./SelectedOfflineItemsVisitor";
// @FIX_THIS
// remove this:
const storage = clientStorage;
// uncomment this
// const storage = clientStorage.getSecureInstance();
// Set the secret key like this:
// storage.secretKey = 'secret_key';
export class GenericDialogDelegate {
    constructor() {
        this.recordsWithOfflineInfo = [];
        this.explicitListItems = {
            records: []
        };
        this._dialogDelegateStateVisitor = null;
    }
    initialize(dialogProxy) {
        // Log.trace("GenericDialogDelegate::initialize -- setting dialog proxy");
        this._dialogProxy = dialogProxy;
        return Promise.resolve();
    }
    isAnyUserInBriefcaseMode(tenantId) {
        return __awaiter(this, void 0, void 0, function* () {
            const dialogDelegateStateKeys = yield GenericDialogDelegateTools.readDialogDelegateStateKeys(tenantId);
            for (const k of dialogDelegateStateKeys) {
                const fields = k.split(`.${tenantId}`);
                const userId = fields[0];
                if (yield this.isUserInBriefcaseMode({ userId, tenantId })) {
                    return true;
                }
            }
        });
    }
    isUserInBriefcaseMode(userInfo) {
        return __awaiter(this, void 0, void 0, function* () {
            // Log.trace(`GenericDialogDelegate::isUserInBriefcaseMode userInfo`, userInfo);
            const userId = userInfo['userId'];
            const tenantId = userInfo['tenantId'];
            const dialogDelegateStateVisitor = yield GenericDialogDelegateTools.readDialogDelegateStateVisitor(tenantId, userId);
            // If session has expired and user affirmed to relogin, return false, so user can login using online credentials.
            const reloginUserAffirmation = yield storage.getJson('ReloginUserAffirmation');
            return dialogDelegateStateVisitor &&
                dialogDelegateStateVisitor.visitBriefcase() &&
                !(reloginUserAffirmation && dialogDelegateStateVisitor.visitBriefcase().visitSessionExpiry()) &&
                (!dialogDelegateStateVisitor.visitBriefcase().visitOnline() || dialogDelegateStateVisitor.visitBriefcase().visitDownloads());
        });
    }
    // --- Request Handlers --- //
    getBlob(request) {
        // Log.trace("GenericDialogDelegate::getBlob -- path: " + request.resourcePath());
        if (!this.delegateOnline()) {
            throw new Error(`Blob request is not valid during offline mode: ${request.resourcePath()}`);
        }
        return null;
    }
    getText(request) {
        // Log.trace("GenericDialogDelegate::getText -- path: " + request.resourcePath());
        if (!this.delegateOnline()) {
            throw new Error(`Text request is not valid during offline mode: ${request.resourcePath()}`);
        }
        return null;
    }
    openStream(request) {
        // Log.trace("GenericDialogDelegate::openStream -- path: " + request.resourcePath());
        if (!this.delegateOnline()) {
            throw new Error(`Stream request is not valid during offline mode: ${request.resourcePath()}`);
        }
        return null;
    }
    postMultipart(request) {
        // Log.trace("GenericDialogDelegate::postMultipart -- path: " + request);
        if (!this.delegateOnline()) {
            throw new Error(`Multipart request is not valid during offline mode: ${request.resourcePath()}`);
        }
        return null;
    }
    getJson(request) {
        const thisMethod = 'GenericDialogDelegate::getJson';
        // Log.trace(`${thisMethod} -- path: ${request.resourcePath()}`);
        let pathFields;
        if (request.isGetDialogPath()) {
            pathFields = request.deconstructGetDialogPath();
            if (GenericDialogDelegateTools.isBriefcaseEnterOfflineDialogId(pathFields.dialogId)) {
                const response = Download_Manager_EnterOfflineMode_FORM.copyOfResponse();
                const enterOfflineVisitor = new DialogVisitor(response);
                enterOfflineVisitor.propagateTenantIdAndSessionId(pathFields.tenantId, pathFields.sessionId);
                enterOfflineVisitor.visitAndSetId(GenericDialogDelegateTools.DOWNLOAD_MANAGER_ENTER_OFFLINE_ROOT_DIALOG_ID);
                enterOfflineVisitor.visitChildAtNameAndSetId(GenericDialogDelegateTools.BRIEFCASE_ENTER_OFFLINE_DETAILS_DIALOG_NAME, GenericDialogDelegateTools.DOWNLOAD_MANAGER_ENTER_OFFLINE_DETAILS_DIALOG_ID);
                return Promise.resolve(new JsonClientResponse(response, 200));
            }
            if (GenericDialogDelegateTools.isOfflineBriefcaseDialogId(pathFields.dialogId)) {
                const response = Download_Manager_FORM.copyOfResponse();
                const downloadManagerVisitor = new DialogVisitor(response);
                downloadManagerVisitor.propagateTenantIdAndSessionId(pathFields.tenantId, pathFields.sessionId);
                downloadManagerVisitor.visitAndSetId(GenericDialogDelegateTools.DOWNLOAD_MANAGER_ROOT_DIALOG_ID);
                downloadManagerVisitor.visitChildAtNameAndSetId(GenericDialogDelegateTools.DOWNLOAD_MANAGER_DETAILS_DIALOG_NAME, GenericDialogDelegateTools.BRIEFCASE_DETAILS_DIALOG_ID);
                downloadManagerVisitor.visitChildAtNameAndSetId(GenericDialogDelegateTools.DOWNLOAD_MANAGER_SELECTED_ITEMS_DIALOG_NAME, GenericDialogDelegateTools.BRIEFCASE_WORK_PACKAGES_DIALOG_ID);
                downloadManagerVisitor.visitChildAtNameAndSetId(GenericDialogDelegateTools.DOWNLOAD_MANAGER_QUEUED_ITEMS_DIALOG_NAME, GenericDialogDelegateTools.BRIEFCASE_COMMENTS_DIALOG_ID);
                return Promise.resolve(new JsonClientResponse(response, 200));
            }
        }
        else if (request.isGetRecordPath()) {
            pathFields = request.deconstructGetRecordPath();
            if (GenericDialogDelegateTools.isBriefcaseEnterOfflineDetailsDialogId(pathFields.dialogId)) {
                const response = Download_Manager_EnterOfflineMode_RECORD.copyOfResponse();
                const recordVisitor = new RecordVisitor(response);
                const offlinePassword = this._dialogDelegateStateVisitor.visitBriefcase().visitPassword();
                recordVisitor.visitAndSetPropertyValueAt('P_USERID', this.delegateUserId());
                recordVisitor.visitAndSetPropertyValueAt('P_PASSWORD', offlinePassword);
                recordVisitor.visitAndSetPropertyValueAt('P_PASSWORD_CONFIRM', offlinePassword);
                return Promise.resolve(new JsonClientResponse(recordVisitor.enclosedJsonObject(), 200));
            }
            if (GenericDialogDelegateTools.isOfflineBriefcaseDetailsDialogId(pathFields.dialogId)) {
                const response = this.delegateDownloadManagerVisitor().enclosedJsonObject();
                return Promise.resolve(new JsonClientResponse(response, 200));
            }
            if (pathFields.dialogId === GenericDialogDelegateTools.MOBILE_COMMENT_IMAGE_NOT_AVAILABLE_DETAILS_DIALOG_ID) {
                const recordJson = MobileComment_ImageNotAvailable_RECORD.copyOfResponse();
                return Promise.resolve(new JsonClientResponse(recordJson, 200));
            }
            if (pathFields.dialogId === GenericDialogDelegateTools.MOBILE_COMMENT_COMMENT_NOT_AVAILABLE_DETAILS_DIALOG_ID) {
                const recordJson = MobileComment_CommentNotAvailable_RECORD.copyOfResponse();
                return Promise.resolve(new JsonClientResponse(recordJson, 200));
            }
        }
        else if (request.isTenantCapabilitiesPath()) {
            return null;
        }
        pathFields = request.deconstructGetDialogPath();
        if (pathFields.dialogId === 'cancelOfflineCrawling') {
            return this.cancelOfflineCrawlingProcess(pathFields.tenantId, pathFields.sessionId);
        }
        if (!this.delegateOnline()) {
            if (request.isGetDialogPath()) {
                return DialogProxyTools.readDialogAsOfflineResponse(this.delegateUserId(), request);
            }
            if (request.isGetRecordPath()) {
                if (GenericDialogDelegate.getJsonCallCount && GenericDialogDelegate.briefCaseEditAction.isBriefCaseAction) {
                    return DialogProxyTools.readRecordAsVisitorFromRequest(this.delegateUserId(), request).then(recordVisitor => {
                        if (recordVisitor) {
                            const requestObject = JSON.parse(JSON.stringify(GenericDialogDelegate.briefCaseEditAction.request));
                            const properties = requestObject._enclosedJsonObject.properties;
                            for (let i = 0, len = properties.length; i < len; i++) {
                                if (properties[i].name && properties[i].value) {
                                    recordVisitor.visitAndSetPropertyValueAt(properties[i].name, properties[i].value);
                                }
                            }
                            if (GenericDialogDelegate.getJsonCallCount === 1) {
                                this.resetBriefCaseEditAction();
                            }
                            return new JsonClientResponse(recordVisitor.enclosedJsonObject(), 200);
                        }
                        else {
                            this.resetBriefCaseEditAction();
                            GenericDialogDelegate.getJsonCallCount = 0;
                            return DialogProxyTools.constructRequestNotValidDuringOfflineMode('readRecordAsOfflineResponse', request.resourcePath());
                        }
                    });
                }
                GenericDialogDelegate.getJsonCallCount = 0;
                this.resetBriefCaseEditAction();
                return DialogProxyTools.readRecordAsOfflineResponse(this.delegateUserId(), request);
            }
            return Promise.resolve(DialogProxyTools.constructRequestNotValidDuringOfflineMode('getJson', request.resourcePath()));
        }
        return null;
    }
    postJson(request) {
        const thisMethod = 'GenericDialogDelegate::postJson';
        // Log.trace(`${thisMethod} -- path: ${request.resourcePath()}`);
        // Log.trace(`${thisMethod} -- body: ${JSON.stringify(request.body())}`);
        if (GenericDialogDelegateTools.isSelectedItemsAddToBriefcaseMenuActionRequest(request)) {
            return this.performSelectedItemsAddItemToBriefcase(request);
        }
        else if (GenericDialogDelegateTools.isSelectedItemsRemoveFromBriefcaseMenuActionRequest(request)) {
            return this.performSelectedItemsRemoveItemFromBriefcase(request);
        }
        else if (request.isCreateSessionPath()) {
            return this.performCreateSessionRequest(request);
        }
        else if (GenericDialogDelegateTools.isBriefcaseWorkbenchActionRequest(request)) {
            return this.performBriefcaseWorkbenchActionRequest(request);
        }
        else if (GenericDialogDelegateTools.isOfflineBriefcaseSelectedItemsRequest(request)) {
            return this.performOfflineBriefcaseSelectedItemsRequest(request);
        }
        else if (GenericDialogDelegateTools.isOfflineBriefcaseCommentsRecordSetRequest(request)) {
            return this.performOfflineBriefcaseCommentsRequest(request);
        }
        else if (GenericDialogDelegateTools.isDownloadSelectedItemsRequest(request)) {
            const pathFields = request.deconstructPostMenuActionPath();
            return this.performDownloadSelectedItemsRequest(request.baseUrl(), pathFields.tenantId, pathFields.sessionId);
        }
        else if (GenericDialogDelegateTools.isSettingsRequest(request)) {
            return this.performSettingsRequest(request);
        }
        else if (GenericDialogDelegateTools.isEnterOfflineModeMenuActionRequest(request)) {
            const pathFields = request.deconstructPostMenuActionPath();
            return this.genericPerformEnterOfflineModeContinueRequest(request.baseUrl(), pathFields.tenantId, pathFields.sessionId);
        }
        else if (GenericDialogDelegateTools.isClearOfflineCacheRequest(request)) {
            return this.performClearOfflineCacheRequest(request);
        }
        else if (GenericDialogDelegateTools.isExitOfflineModeMenuActionRequest(request)) {
            return this.genericPerformExitOfflineModeMenuActionRequest(request);
        }
        else if (GenericDialogDelegateTools.isExitOfflineModeAndDiscardChangesMenuActionRequest(request)) {
            return this.genericPerformExitOfflineModeAndDiscardChangesMenuActionRequest(request);
        }
        else if (GenericDialogDelegateTools.isRemoveEditedRecordModeMenuActionRequest(request)) {
            return this.genericPerformRemoveEditedItemMenuActionRequest(request);
        }
        else if (request.isPostMenuActionPathWithDialogId(GenericDialogDelegateTools.BRIEFCASE_WORK_PACKAGES_DIALOG_ID)) {
            const pathFields = request.deconstructPostMenuActionPath();
            return this.delegateOnline() ? Promise.resolve(new JsonClientResponse(GenericDialogDelegateTools.constructGenericListActionNullRedirection(pathFields.tenantId, pathFields.sessionId, pathFields.dialogId, pathFields.actionId), 303)) : this.returnParentListDialog(request);
        }
        if (!this.delegateOnline()) {
            if (request.isPostMenuActionPathWithDialogId(GenericDialogDelegateTools.BRIEFCASE_COMMENTS_DIALOG_ID)) {
                return this.readAndDelagateParentRedirectionWithAction(request);
            }
            if (request.isPostWorkbenchActionPath()) {
                return DialogProxyTools.readWorkbenchActionRedirectionAsOfflineResponse(this.delegateUserId(), request);
            }
            else if (request.isPostMenuActionPath()) {
                return DialogProxyTools.readMenuActionRedirectionAsOfflineResponse(this.delegateUserId(), request);
            }
            else if (request.isPostRecordsPath()) {
                return DialogProxyTools.readRecordSetAsOfflineResponse(this.delegateUserId(), request);
            }
            else if (request.isPostSessionContentPath()) {
                return DialogProxyTools.readSessionContentAsOfflineResponse(this.delegateUserId(), request);
            }
            else if (request.isPostAvailableValuesPath()) {
                return DialogProxyTools.readPropertyAvailableValuesAsOfflineResponse(this.delegateUserId(), request);
            }
            else if (request.isPostRecordsFlagPath() || request.isPostRecordFlagPath() || request.isPostRecordLargeProperty()) {
                return DialogProxyTools.readContentFlagAsOfflineResponse(this.delegateUserId(), request);
            }
            return Promise.resolve(DialogProxyTools.constructRequestNotValidDuringOfflineMode('postJson', request.resourcePath()));
        }
        return null;
    }
    putJson(request) {
        // Log.trace("GenericDialogDelegate::putJson -- path: " + request.resourcePath());
        // Log.trace("GenericDialogDelegate::putJson -- body: " + JSON.stringify(request.body()));
        if (request.isPutViewModePath()) {
            const pathFields = request.deconstructPutViewModePath();
            if (GenericDialogDelegateTools.isBriefcaseEnterOfflineDetailsDialogId(pathFields.dialogId) && pathFields.viewMode === 'READ') {
                return Promise.resolve(new JsonClientResponse(GenericDialogDelegateTools.constructBriefcaseEnterOfflineDetailsNullRedirection(pathFields.tenantId, pathFields.sessionId, false), 303));
            }
        }
        if (request.isPutRecordPath()) {
            const pathFields = request.deconstructPostRecordsPath();
            if (GenericDialogDelegateTools.isBriefcaseEnterOfflineDetailsDialogId(pathFields.dialogId)) {
                const recordVisitor = new RecordVisitor(request.body());
                const loginHash = GenericDialogDelegateTools.createOfflineLoginHash(pathFields.tenantId, pathFields.sessionId, this.delegateUserId(), recordVisitor.visitPropertyValueAt('P_PASSWORD'));
                // Log.trace("GenericDialogDelegate::putJson -- loginHash: " + loginHash);
                this._dialogDelegateStateVisitor.visitAndSetLoginHash(loginHash);
                this._dialogDelegateStateVisitor.visitBriefcase().visitAndSetPassword(recordVisitor.visitPropertyValueAt('P_PASSWORD'));
                GenericDialogDelegateTools.writeDialogDelegateState(pathFields.tenantId, this._dialogDelegateStateVisitor);
                return Promise.resolve(new JsonClientResponse(GenericDialogDelegateTools.constructBriefcaseEnterOfflineDetailsNullRedirection(pathFields.tenantId, pathFields.sessionId, true), 303));
            }
        }
        if (!this.delegateOnline()) {
            if (request.isPutViewModePath()) {
                const pathFields = request.deconstructPutViewModePath();
                this.updateBreadCrumbs(request);
                if (pathFields.viewMode === 'WRITE') {
                    // As viewMode has changed to edit, update actionId of last breadcrumb to 'edit'
                    const lastBreadCrumb = GenericDialogDelegate.breadCrumb.pop();
                    lastBreadCrumb.breadcrumb.actionId = 'edit';
                    GenericDialogDelegate.breadCrumb.push(lastBreadCrumb);
                    return this.createWriteViewModeDialogResponse(request);
                }
                if (pathFields.viewMode === 'READ') {
                    return this.createReadViewModeDialogResponse(request);
                }
            }
            if (request.isPutRecordPath()) {
                const pathFields = request.deconstructPutRecordPath();
                let recordVisitor = new RecordVisitor(request.body());
                /**
                 * Logic to check whether the record edit/delete/create is from Pending Transactions and Also verify whether any property edited to skip save
                 * And Add error property manually, which is used for error catch in Pending Transaction record level.
                 */
                if (recordVisitor && (recordVisitor.enclosedJsonObject().properties.length > 0 || !GenericDialogDelegate.briefCaseEditAction.isBriefCaseAction)) {
                    recordVisitor.visitAndSetPropertyValueAt('Error', '');
                    if (GenericDialogDelegate.briefCaseEditAction.isBriefCaseAction) {
                        // const recordDup = this._dialogDelegateStateVisitor.enclosedJsonObject().editedRecords.records[GenericDialogDelegate.briefCaseEditAction.index];
                        const requestObj = JSON.parse(JSON.stringify(GenericDialogDelegate.briefCaseEditAction.request));
                        const requestObject = new RecordVisitor(requestObj._enclosedJsonObject);
                        const properties = recordVisitor.enclosedJsonObject().properties;
                        for (let i = 0, len = properties.length; i < len; i++) {
                            if (properties[i].name && properties[i].value) {
                                requestObject.visitAndSetPropertyValueAt(properties[i].name, properties[i].value);
                            }
                        }
                        recordVisitor = requestObject;
                    }
                    return DialogProxyTools.writeRecordEdit(this.delegateUserId(), pathFields.tenantId, pathFields.dialogId, recordVisitor).then(nullValue => {
                        return DialogProxyTools.readBreadCrumbForTheDialog(this.delegateUserId(), pathFields.tenantId, nullValue.enclosedJsonObject().rootDialogId).then((breadCrumb) => __awaiter(this, void 0, void 0, function* () {
                            const breadcrumb = JSON.parse(JSON.stringify(GenericDialogDelegate.breadCrumb));
                            if (GenericDialogDelegate.briefCaseEditAction.index === -1) {
                                this._dialogDelegateStateVisitor.addEditedRecords(breadcrumb, nullValue.enclosedJsonObject().persistentId, JSON.parse(JSON.stringify(request)), request, GenericDialogDelegate.propertyTimestamp);
                            }
                            else {
                                const requestObj = JSON.parse(JSON.stringify(request));
                                requestObj._body = recordVisitor.enclosedJsonObject();
                                this._dialogDelegateStateVisitor.updateEditedRecord(GenericDialogDelegate.briefCaseEditAction.index, JSON.parse(JSON.stringify(requestObj)), recordVisitor);
                            }
                            this.resetBriefCaseEditAction();
                            GenericDialogDelegate.propertyTimestamp = '';
                            DialogProxyTools.writeOutBoundQueue(this.delegateUserId(), pathFields.tenantId, this._dialogDelegateStateVisitor.enclosedJsonObject().editedRecords);
                            return GenericDialogDelegateTools.writeDialogDelegateState(pathFields.tenantId, this._dialogDelegateStateVisitor).then(nullValue2 => {
                                return this.constructCreateCommentNullRedirection(pathFields.tenantId, pathFields.sessionId, pathFields.dialogId, 'DESTROYED');
                            });
                        }));
                    });
                }
                else {
                    this.resetBriefCaseEditAction();
                    return Promise.resolve(this.constructCreateCommentNullRedirection(pathFields.tenantId, pathFields.sessionId, pathFields.dialogId, 'DESTROYED'));
                }
            }
            if (request.isPutPropertyPath()) {
                const pathFields = request.deconstructPutPropertyPath();
                const writeLargePropertyParameters = new WriteLargePropertyParametersVisitor(request.body());
                /**
                 * Logic to save the porperties like image/attachemnt in Asynstorage in same key path
                 */
                if (GenericDialogDelegate.propertyTimestamp === '') {
                    GenericDialogDelegate.propertyTimestamp = Date.now().toString();
                }
                return DialogProxyTools.writePropertyCommit(this.delegateUserId(), pathFields.tenantId, pathFields.dialogId, pathFields.propertyName, writeLargePropertyParameters, GenericDialogDelegate.propertyTimestamp).then(nullValue => {
                    this._dialogDelegateStateVisitor.addContentProperties(pathFields.dialogId, pathFields.propertyName, request, GenericDialogDelegate.propertyTimestamp);
                    return this.constructCreateCommentNullRedirection(pathFields.tenantId, pathFields.sessionId, pathFields.dialogId, '');
                });
            }
            return Promise.resolve(DialogProxyTools.constructRequestNotValidDuringOfflineMode('putJson', request.resourcePath()));
        }
        return null;
    }
    deleteJson(request) {
        // Log.trace("GenericDialogDelegate::deleteJson -- path: " + request.resourcePath());
        if (request.isDeleteSessionPath()) {
            return this.performDeleteSessionRequest(request);
        }
        // Whether online or offline, we need to short-circuit the deletion of briefcase dialogs. The briefcase dialogs
        // are 100% client-side only and are synthesized for each use.
        if (request.isDeleteDialogPath() && GenericDialogDelegateTools.startsWithBriefcaseRootDialogId(request.dialogId())) {
            const response = {
                "dialogId": request.dialogId(),
                "type": "hxgn.api.dialog.DialogId"
            };
            return Promise.resolve(new JsonClientResponse(response, 200));
        }
        if (!this.delegateOnline()) {
            const pathFields = request.deconstructDeleteDialogPath();
            if (request.isDeleteDialogPath()) {
                const response = {
                    "dialogId": pathFields.dialogId,
                    "type": "hxgn.api.dialog.DialogId"
                };
                return Promise.resolve(new JsonClientResponse(response, 200));
            }
            return Promise.resolve(DialogProxyTools.constructRequestNotValidDuringOfflineMode('deleteJson', request.resourcePath()));
        }
        return null;
    }
    // --- Response Handlers --- //
    handleDeleteJsonResponse(request, response) {
        // Log.trace("GenericDialogDelegate::handleDeleteJsonResponse -- path: " + request.resourcePath());
        response.then(jcr => {
            // Log.trace("GenericDialogDelegate::handleDeleteJsonResponse -- json response: " + JSON.stringify(jcr.value));
            // To capture/update the persistentId, which is needed for explicit record selection and while crawling
            // this.explicitUpdateBreadCrumb(request, jcr);
        });
        const pathFields = request.deconstructDeleteDialogPath();
        return DialogProxyTools.readBackActionPerformed(pathFields.tenantId, pathFields.sessionId, pathFields.dialogId).then((isExplicitDialogDestroy) => {
            this.explicitUpdateBreadCrumb(request);
            if (!this.delegateOnline()) {
                this.updateBreadCrumbs(request, isExplicitDialogDestroy);
            }
            return response;
        });
    }
    handleGetBlobResponse(request, response) {
        // Log.trace("GenericDialogDelegate::handleGetBlobResponse -- path: " + request.resourcePath());
        response.then(bcr => Log.trace("GenericDialogDelegate::handleGetBlobResponse -- blob response: " + JSON.stringify(bcr.value)));
        return response;
    }
    handleGetJsonResponse(request, response) {
        // Log.trace("GenericDialogDelegate::handleGetJsonResponse -- path: " + request.resourcePath());
        /**
         * Below logic of tracking the Getcall must be refined. Basically the logic is to fetch and display the record,
         * when user perform open action on Offline Pending transaction. And Getcall count is tracked because we observed
         * that record's dailog which has children action performs getcall multiple times ( assume the no of children actions times).
         * So track and clear the editedrecord history variables we are using this logic.
         */
        (!request.isTenantCapabilitiesPath() && !this.delegateOnline()) ? GenericDialogDelegate.getJsonCallCount++ : GenericDialogDelegate.getJsonCallCount = 0;
        // response.then(jcr => Log.trace("GenericDialogDelegate::handleGetJsonResponse -- json response: " + JSON.stringify(jcr.value)));
        return response.then(jcr => {
            if (jcr.statusCode === 200) {
                const jsonObject = jcr.value;
                if (GenericDialogDelegateTools.isRootDialog(jsonObject)) {
                    const SelectedItemsDialog = GenericDialogDelegateTools.insertBriefcaseMetaDataIntoExplicitQueryDialogs(jsonObject);
                    return new JsonClientResponse(SelectedItemsDialog, 200);
                }
            }
            return jcr;
        });
    }
    handleGetTextResponse(request, response) {
        // Log.trace("GenericDialogDelegate::handleGetTextResponse -- path: " + request.resourcePath());
        response.then(tcr => Log.trace("GenericDialogDelegate::handleGetTextResponse -- text response: " + JSON.stringify(tcr.value)));
        return response;
    }
    handleOpenStreamResponse(request, response) {
        // Log.trace("GenericDialogDelegate::handleOpenStreamResponse -- path: " + request.resourcePath());
        response.then(sp => Log.trace("GenericDialogDelegate::handleOpenStreamResponse -- stream producer response: " + sp));
        return response;
    }
    handlePostJsonResponse(request, response) {
        const thisMethod = 'GenericDialogDelegate::handlePostJsonResponse';
        // Log.trace(`${thisMethod} -- path: ${request.resourcePath()}`);
        return response.then((jsonClientResponse) => __awaiter(this, void 0, void 0, function* () {
            // To capture the persistentId, which is needed for explicit record selection and while crawling
            this.explicitAddBreadCrumb(request, jsonClientResponse);
            if (jsonClientResponse.statusCode === 200) {
                const jsonObject = jsonClientResponse.value;
                if (DialogProxyTools.isSessionModel(jsonObject)) {
                    // Storing sessions response.
                    const sessionVisitor = new SessionVisitor(jsonClientResponse.value);
                    const key = `${sessionVisitor.visitTenantId()}.${sessionVisitor.visitUserId()}.sessions`;
                    storage.setJson(key, JSON.parse(JSON.stringify(jsonClientResponse)));
                    return this.initializeAfterCreateSession(request, new SessionVisitor(jsonObject)).then(nullValue => jsonClientResponse);
                }
                else if (this._dialogDelegateStateVisitor && GenericDialogDelegateTools.isSelectedItemsListRecordSet(request, jsonObject) && !(GenericDialogDelegateTools.isOfflineBriefcaseCommentsRecordSetRequest(request))) {
                    const pathFields = request.deconstructPostRecordsPath();
                    const selectedItemsRecordSetVisitor = new OfflineItemsRecordSetVisitor(jsonObject);
                    if (this.delegateOnline()) {
                        selectedItemsRecordSetVisitor.updateBriefcaseColumnUsingSelections(this.delegateSelectedItemIds(), GenericDialogDelegate.explicitBreadCrumb);
                    }
                    else {
                        // Filter from the list of selected items, only the items which are already downloaded.
                        const downloadedItems = this.delegateSelectedItemIds().filter(item => {
                            if (this._dialogDelegateStateVisitor.visitSelectedItemsDownloadStatus()[item]) {
                                return true;
                            }
                        });
                        selectedItemsRecordSetVisitor.trimRecordsetToSelections(downloadedItems, GenericDialogDelegate.explicitBreadCrumb);
                    }
                    this.delegateSelectedItemsRecordSetVisitor().addOrUpdateAllRecords(selectedItemsRecordSetVisitor);
                    return GenericDialogDelegateTools.writeDialogDelegateState(pathFields.tenantId, this._dialogDelegateStateVisitor).then(nullValue => {
                        return jsonClientResponse;
                    });
                }
            }
            if (jsonClientResponse.statusCode !== 400 && !this.delegateOnline()) {
                this.addBreadCrumbs(request, jsonClientResponse);
            }
            return jsonClientResponse;
        }));
    }
    handlePostMultipartResponse(request, response) {
        // Log.trace("GenericDialogDelegate::handlePostMultipartResponse -- path: " + request.resourcePath());
        response.then(vcr => Log.trace("GenericDialogDelegate::handlePostMultipartResponse -- void response: " + JSON.stringify(vcr.value)));
        return response;
    }
    handlePutJsonResponse(request, response) {
        const thisMethod = 'GenericDialogDelegate::handlePutJsonResponse';
        // Log.trace("GenericDialogDelegate::handlePutJsonResponse -- path: " + request.resourcePath());
        response.then(jcr => {
            // Log.trace(`${thisMethod} -- json response:  ${JSON.stringify(jcr.statusCode)} ${JSON.stringify(jcr.value)}`);
            this.handleNullRedirectionResponseOffline(request, jcr.value);
        });
        return response;
    }
    handleNullRedirectionResponseOffline(request, response) {
        if (response.type && response.type === 'hxgn.api.dialog.NullRedirection'
            && response.referringObject && response.referringObject.dialogMode
            && response.referringObject.dialogMode === DialogModeEnum.DESTROYED
            && GenericDialogDelegate.explicitBreadCrumbAdded) {
            GenericDialogDelegate.explicitBreadCrumb.pop();
            GenericDialogDelegate.explicitBreadCrumbDialogIds.pop();
            GenericDialogDelegate.explicitBreadCrumbAdded = false;
            if (GenericDialogDelegate.breadCrumb.length > 0) {
                GenericDialogDelegate.breadCrumb.pop();
            }
        }
    }
    // --- Others --- //
    /**
     * Handle change of viewMode from READ to Write for an editor dialog associated with a record.
     * @param {DialogRequest} request Request object for viewMode Change Request
     */
    createWriteViewModeDialogResponse(request) {
        return __awaiter(this, void 0, void 0, function* () {
            const pathFields = request.deconstructPutViewModePath();
            const resourcePathForGetDialogRequest = `tenantId/${pathFields.tenantId}/sessionId/${pathFields.sessionId}/dialogs/${pathFields.dialogId}/record`;
            const request1 = DialogRequest.createFromGetRequest(request.baseUrl(), resourcePathForGetDialogRequest, null);
            const readModeDialog = yield DialogProxyTools.readRecordAsOfflineResponse(this.delegateUserId(), request1);
            const readModeDialogVisitor = new RecordVisitor(readModeDialog.value);
            const rootParentDialogId = readModeDialogVisitor.enclosedJsonObject().rootParentDialogId;
            /**
             * Get dialogRedirectionResponse of edit on the record at parent dialog and then get redirected to dialog
             */
            let actionIdAtTargetId = 'edit';
            const targetId = readModeDialogVisitor.visitRecordId();
            if (targetId) {
                const targetIdEncoded = Base64.encodeUrlSafeString(targetId);
                actionIdAtTargetId = `${actionIdAtTargetId}@${targetIdEncoded}`;
            }
            const editMenuActionRedirection = yield DialogProxyTools.readDialogRedirectionAsVisitor(this.delegateUserId(), pathFields.tenantId, rootParentDialogId, actionIdAtTargetId);
            // Getting Redirected to dialog
            const dialogForEditId = editMenuActionRedirection instanceof DialogRedirectionVisitor ? editMenuActionRedirection.enclosedJsonObject().id : '';
            const dialogForEditIdRes = editMenuActionRedirection instanceof DialogRedirectionVisitor ? editMenuActionRedirection.enclosedJsonObject() : '';
            return new JsonClientResponse(dialogForEditIdRes, 200);
            /**
             * Commented the below code for refernce until the above issue fix works fine.
             * Once it works fine, we can remove it in next commit
             */
            // const resourcePathForGetDialogForEditRequest = `tenantId/${pathFields.tenantId}/sessionId/${pathFields.sessionId}/dialogs/${dialogForEditId}`;
            // const request5 = DialogRequest.createFromGetRequest(request.baseUrl(), resourcePathForGetDialogForEditRequest, null);
            // const dialogForEdit = await DialogProxyTools.readDialogAsOfflineResponse(this.delegateUserId(), request5);
            // if(dialogForEdit.statusCode === 400){
            //     const response = JSON.parse(JSON.stringify(dialogForEdit.value));
            //     return new JsonClientResponse(response, dialogForEdit.statusCode);
            // }
            // const dialogForEditVisitor = new DialogVisitor(dialogForEdit.value);
            // const dialogObject = dialogForEditVisitor.enclosedJsonObject();
            // // Find among the children, the child dialog with dialogMode = UPDATE
            // if (dialogObject.children) {
            //     for (const childDialog of dialogObject.children) {
            //         if (childDialog.dialogMode === 'UPDATE') {
            //             return new JsonClientResponse(childDialog, 200);
            //         }
            //     }
            // }
            // else {
            //     return null;
            // }
        });
    }
    /**
     * Handle change of viewMode from WRITE to READ for an editor dialog associated with a record.
     * @param {DialogRequest} request Request object for viewMode Change Request
     */
    createReadViewModeDialogResponse(request) {
        return __awaiter(this, void 0, void 0, function* () {
            const pathFields = request.deconstructPutViewModePath();
            const resourcePathForGetRecordRequest = `tenantId/${pathFields.tenantId}/sessionId/${pathFields.sessionId}/dialogs/${pathFields.dialogId}/record`;
            const request1 = DialogRequest.createFromGetRequest(request.baseUrl(), resourcePathForGetRecordRequest, null);
            const readModeDialog = yield DialogProxyTools.readRecordAsOfflineResponse(this.delegateUserId(), request1);
            const readModeDialogVisitor = new RecordVisitor(readModeDialog.value);
            const rootParentDialogId = readModeDialogVisitor.enclosedJsonObject().rootParentDialogId;
            const rootDialogId = readModeDialogVisitor.enclosedJsonObject().rootDialogId;
            return new JsonClientResponse(GenericDialogDelegateTools.contructCancelClickNullRedirection(pathFields.tenantId, pathFields.sessionId, rootDialogId), 303);
            /**
             * Commented the below code for refernce until the above issue fix works fine.
             * Once it works fine, we can remove it in next commit
             */
            /**
             * Get dialogRedirectionResponse of open on the record at parent dialog and then get redirected to dialog
             */
            // let actionIdAtTargetId = 'open';
            // const targetId = readModeDialogVisitor.visitRecordId();
            // if (targetId) {
            //     const targetIdEncoded = Base64.encodeUrlSafeString(targetId);
            //     actionIdAtTargetId = `${actionIdAtTargetId}@${targetIdEncoded}`;
            //     const openMenuActionRedirection = await DialogProxyTools.readDialogRedirectionAsVisitor(this.delegateUserId(), pathFields.tenantId, rootParentDialogId, actionIdAtTargetId);
            //     // Getting Redirected to dialog
            //     const dialogForOpenId = openMenuActionRedirection instanceof DialogRedirectionVisitor ? openMenuActionRedirection.enclosedJsonObject().id: null;
            //     const dialogForOpenIdRes = openMenuActionRedirection instanceof DialogRedirectionVisitor ? openMenuActionRedirection.enclosedJsonObject(): null;
            //     return new JsonClientResponse(dialogForOpenIdRes, 200);
            //     // if (!dialogForOpenId) {
            //     //     const resourcePathForGetDialogRequest = `tenantId/${pathFields.tenantId}/sessionId/${pathFields.sessionId}/dialogs/${rootDialogId}`;
            //     //     const request2 = DialogRequest.createFromGetRequest(request.baseUrl(), resourcePathForGetDialogRequest, null);        
            //     //     const editModeDialog = await DialogProxyTools.readDialogAsOfflineResponse(this.delegateUserId(), request2);
            //     //     const editModeDialogVisitor = new DialogVisitor(editModeDialog.value);
            //     //     // Find the dialog among children whose viewMode change to READ was requested
            //     //     const dialogObj = editModeDialogVisitor.enclosedJsonObject().children.filter(child => child.id === pathFields.dialogId)[0];
            //     //     // Set DialogMode to READ
            //     //     dialogObj.dialogMode = 'READ';
            //     //     dialogObj.viewMode = 'READ';
            //     //     return new JsonClientResponse(dialogObj, 200);
            //     // }
            //     // const resourcePathForGetDialogForOpenRequest = `tenantId/${pathFields.tenantId}/sessionId/${pathFields.sessionId}/dialogs/${dialogForOpenId}`;
            //     // const request5 = DialogRequest.createFromGetRequest(request.baseUrl(), resourcePathForGetDialogForOpenRequest, null);
            //     // const dialogForOpen = await DialogProxyTools.readDialogAsOfflineResponse(this.delegateUserId(), request5);
            //     // const dialogForOpenVisitor = new DialogVisitor(dialogForOpen.value);
            //     // const dialogObject = dialogForOpenVisitor.enclosedJsonObject();
            //     // // Find among the children, the child dialog with dialogMode = UPDATE
            //     // if (dialogObject.children) {
            //     //     for (const childDialog of dialogObject.children) {
            //     //         if (childDialog.dialogMode === 'READ') {
            //     //             return new JsonClientResponse(childDialog, 200);
            //     //         }
            //     //     }
            //     // }
            // }
            // else {
            //     return new JsonClientResponse(GenericDialogDelegateTools.contructCancelClickNullRedirection(pathFields.tenantId, pathFields.sessionId, pathFields.dialogId), 303);
            // }
        });
    }
    /**
     * Logic to handle the User's open action on the Edited records item
     * @param {DialogRequest} request Request object for opening the Edited record on user open action
     */
    readAndDelagateParentRedirectionWithAction(request) {
        return __awaiter(this, void 0, void 0, function* () {
            let recordIndex = -1;
            let breadcrumb = this._dialogDelegateStateVisitor.enclosedJsonObject().editedRecords.records.filter((record, index) => {
                const recordDup = JSON.parse(JSON.stringify(record.record));
                const propIndex = recordDup._enclosedJsonObject.properties.findIndex((item) => item.name === 'Error');
                propIndex !== -1 ? recordDup._enclosedJsonObject.properties[propIndex].value = '' : '';
                if (JSON.stringify(recordDup) === request.body().targets[0]) {
                    recordIndex = index;
                    return true;
                }
                return false;
                // return JSON.stringify(recordDup)  === request.body().targets[0];
            });
            if (breadcrumb && breadcrumb.length > 0) {
                /**
                 * Assigning the breadCrumb paths of the record user is opening from Pending transaction list to GenericDialogDelegate.breadCrumb for replaying
                 */
                GenericDialogDelegate.breadCrumb = JSON.parse(JSON.stringify(breadcrumb[0].breadCrumb));
                GenericDialogDelegate.briefCaseEditAction.index = recordIndex;
                GenericDialogDelegate.briefCaseEditAction.isBriefCaseAction = true;
                GenericDialogDelegate.briefCaseEditAction.request = JSON.parse(breadcrumb[0].id);
                breadcrumb = breadcrumb[0].breadCrumb[breadcrumb[0].breadCrumb.length - 1];
                const pathFields = request.deconstructPostMenuActionPath();
                const resourcePathForGetDialogRequest = `tenantId/${pathFields.tenantId}/sessionId/${pathFields.sessionId}/dialogs/${breadcrumb.dialogId}/record`;
                const requestObj = DialogRequest.createFromGetRequest(request.baseUrl(), resourcePathForGetDialogRequest, null);
                const dialogResponse = yield DialogProxyTools.readDialogAsOfflineResponse(this.delegateUserId(), requestObj);
                let actionIdAtTargetId = breadcrumb.breadcrumb.actionId;
                const targetId = breadcrumb.breadcrumb.recordId[0];
                let editMenuActionRedirection = null;
                const dailogID = dialogResponse.value['referringObject'].dialogId ? dialogResponse.value['referringObject'].dialogId : dialogResponse.value['referringObject'].workbenchId;
                if (targetId) {
                    const targetIdEncoded = Base64.encodeUrlSafeString(targetId);
                    actionIdAtTargetId = `${actionIdAtTargetId}@${targetIdEncoded}`;
                    editMenuActionRedirection = yield DialogProxyTools.readDialogRedirectionAsVisitor(this.delegateUserId(), pathFields.tenantId, dailogID, actionIdAtTargetId);
                }
                else {
                    editMenuActionRedirection = yield DialogProxyTools.readDialogRedirectionAsVisitorWithoutTargetId(this.delegateUserId(), pathFields.tenantId, dailogID, actionIdAtTargetId);
                }
                return new JsonClientResponse(editMenuActionRedirection.enclosedJsonObject(), 303);
            }
            return new JsonClientResponse(DialogProxyTools.constructRequestNotValidDuringOfflineMode('readDialogAsOfflineResponse', request.resourcePath()), 303);
        });
    }
    cacheForOffline(baseUrl, userId, tenantId, sessionId, selectedWorkItems) {
        return __awaiter(this, void 0, void 0, function* () {
            const key = `${tenantId}.${userId}.sessions`;
            const response = yield storage.getJson(key);
            const sessionVisitor = new SessionVisitor(response.value);
            this.userId = userId;
            this.tenantId = tenantId;
            this.baseUrl = baseUrl;
            this.sessionId = sessionId;
            const workbenches = yield sessionVisitor.enclosedJsonObject().appWindow.workbenches;
            this.selectedWorkItems = selectedWorkItems;
            // Generate user selected records list mapped to persistentIds
            if (selectedWorkItems && selectedWorkItems.length) {
                selectedWorkItems.forEach((item) => {
                    const aliases = item.split('@');
                    const length = aliases.length;
                    if (aliases.length > 1) {
                        if (this.explicitListItems[aliases[length - 2]]) {
                            if (!this.explicitListItems[aliases[length - 2]].includes(aliases[length - 1])) {
                                this.explicitListItems[aliases[length - 2]].push(aliases[length - 1]);
                            }
                        }
                        else {
                            this.explicitListItems[aliases[length - 2]] = [aliases[length - 1]];
                        }
                    }
                    else if (aliases.length === 1) {
                        this.explicitListItems.records.push(aliases[length - 1]);
                    }
                });
            }
            // Loop for every workbench, if offline capable, then start caching.
            for (const workbench of workbenches) {
                if (workbench.offlineCapable) {
                    const actions = workbench.actions;
                    if (GenericDialogDelegate.crawlCancel === true) {
                        GenericDialogDelegate.crawlingRedirectionStatus = false;
                        return null;
                    }
                    // Notification message can be modified to more verbose
                    this.notifyClientListener({ message: `Capturing the app with user selected records for offline.`, eventType: ClientEventType.MESSAGEWITHCANCELOPTION });
                    // Loop for every action, if offline capable, then start caching.
                    for (const action of actions) {
                        if (action.offlineMode) {
                            // Add wbAction breadCrumb
                            const crawlingBreadCrumbs = [];
                            const bCrumbObj = JSON.parse(JSON.stringify(GenericDialogDelegate.breadCrumbObject));
                            bCrumbObj.breadcrumb.type = 'wbAction';
                            bCrumbObj.breadcrumb.wbId = workbench.id;
                            bCrumbObj.breadcrumb.actionId = action.actionId;
                            crawlingBreadCrumbs.push(bCrumbObj);
                            const beforeAndAfterValues = yield DialogProxyTools.genericCaptureWorkbenchActionRedirectionAndDialog(this.userId, this.baseUrl, sessionVisitor.visitTenantId(), sessionVisitor.visitId(), workbench.id, action.actionId, GenericDialogDelegate.newCrawlCopy);
                            if (beforeAndAfterValues && !beforeAndAfterValues['dialogError']) {
                                const dialogVisitor = new DialogVisitor(beforeAndAfterValues['beforeDialog']);
                                GenericDialogDelegate.promisesList.push(this.captureDialogChildren(dialogVisitor, crawlingBreadCrumbs));
                            }
                        }
                    }
                    ;
                }
            }
            ;
            return GenericDialogDelegate.promisesList;
        });
    }
    /**
     * Cache child dialogs for a dialog
     * @param dialogVisitor dialog whose children are to be cached
     */
    captureDialogChildren(dialogVisitor, crawlingBreadCrumbs = [], traversedDialogs = [], traversedDialogsWithPersistentId = []) {
        return __awaiter(this, void 0, void 0, function* () {
            const dialog = dialogVisitor.enclosedJsonObject();
            const traversedDialogsCopy = JSON.parse(JSON.stringify(traversedDialogs));
            const traversedDialogsWithPersistentIdCopy = JSON.parse(JSON.stringify(traversedDialogsWithPersistentId));
            if (GenericDialogDelegate.crawlCancel === true) {
                GenericDialogDelegate.crawlingRedirectionStatus = false;
                return null;
            }
            if (dialog.children && !traversedDialogsCopy.includes(dialog.persistentId)) {
                traversedDialogsCopy.push(dialog.persistentId);
                traversedDialogsWithPersistentIdCopy.push({ [dialog.persistentId]: dialog.id });
                for (const childDialog of dialog.children) {
                    if (GenericDialogDelegate.crawlCancel) {
                        GenericDialogDelegate.crawlingRedirectionStatus = false;
                        return null;
                    }
                    const childDialogMenuActions = this.getActionsList(childDialog);
                    const childDialogLevelMenuActions = this.getActionsList(childDialog, true);
                    // Capture QueryDialog
                    if (childDialog.type === 'hxgn.api.dialog.QueryDialog') {
                        try {
                            const genericRecordSetVisitor = yield DialogProxyTools.genericCaptureRecordSet(this.userId, this.baseUrl, this.tenantId, this.sessionId, childDialog.id, GenericDialogDelegate.newCrawlCopy);
                            // Run each offline menuActions for each records
                            if (childDialogMenuActions && childDialogMenuActions.length) {
                                if (childDialog.offlineMode === 'EXPLICIT') {
                                    if (this.explicitListItems[dialog.persistentId] && this.explicitListItems[dialog.persistentId].length) {
                                        for (const r of this.explicitListItems[dialog.persistentId]) {
                                            GenericDialogDelegate.promisesList.push(this.cacheActionsOnRecord(r, childDialogMenuActions, dialog, childDialog, traversedDialogsCopy, traversedDialogsWithPersistentIdCopy, crawlingBreadCrumbs));
                                            // await this.cacheActionsOnRecord (r, childDialogMenuActions, dialog, childDialog, traversedDialogsCopy, crawlingBreadCrumbs)
                                            /**
                                             * Logic to extract the Dropdown and Flag properties list and accordingly fetch and store the response for offline
                                             */
                                            //  const propertyList = this.getPropertiesListForCrawl(childDialog);
                                            //  GenericDialogDelegate.promisesList.push(this.cachePropertiesOfRecord(childDialog, propertyList, r, 'Query'));
                                        }
                                    }
                                    else {
                                        // For handling explicit records with no associated persistentId
                                        for (const r of genericRecordSetVisitor.visitRecords()) {
                                            if (this.explicitListItems.records.includes(r.visitRecordId())) {
                                                GenericDialogDelegate.promisesList.push(this.cacheActionsOnRecord(r.visitRecordId(), childDialogMenuActions, dialog, childDialog, traversedDialogsCopy, traversedDialogsWithPersistentIdCopy, crawlingBreadCrumbs));
                                                // await this.cacheActionsOnRecord (r.visitRecordId(), childDialogMenuActions, dialog, childDialog, traversedDialogsCopy, crawlingBreadCrumbs)
                                                /**
                                                 * Logic to extract the Dropdown and Flag  properties list and accordingly fetch and store the response for offline
                                                 */
                                                // const propertyList = this.getPropertiesListForCrawl(childDialog);
                                                // GenericDialogDelegate.promisesList.push(this.cachePropertiesOfRecord(childDialog, propertyList, r, 'Query'));
                                            }
                                        }
                                    }
                                }
                                else if (childDialog.offlineMode === 'IMPLICIT') {
                                    if (genericRecordSetVisitor.recordCount() > 0) {
                                        for (const r of genericRecordSetVisitor.visitRecords()) {
                                            GenericDialogDelegate.promisesList.push(this.cacheActionsOnRecord(r.visitRecordId(), childDialogMenuActions, dialog, childDialog, traversedDialogsCopy, traversedDialogsWithPersistentIdCopy, crawlingBreadCrumbs));
                                            // await this.cacheActionsOnRecord (r.visitRecordId(), childDialogMenuActions, dialog, childDialog, traversedDialogsCopy, crawlingBreadCrumbs);
                                            /**
                                             * Logic to extract the Dropdown and Flag  properties list and accordingly fetch and store the response for offline
                                             */
                                            //  const propertyList = this.getPropertiesListForCrawl(childDialog);
                                            //  GenericDialogDelegate.promisesList.push(this.cachePropertiesOfRecord(childDialog, propertyList, r, 'Query'));
                                        }
                                    }
                                    else {
                                        GenericDialogDelegate.promisesList.push(this.cacheActionsOnRecord(null, childDialogMenuActions, dialog, childDialog, traversedDialogsCopy, traversedDialogsWithPersistentIdCopy, crawlingBreadCrumbs));
                                        // await this.cacheActionsOnRecord (null, childDialogMenuActions, dialog, childDialog, traversedDialogsCopy, crawlingBreadCrumbs);
                                    }
                                }
                            }
                        }
                        catch (error) {
                            GenericDialogDelegate.crawlCancel = true;
                            GenericDialogDelegate.crawlingRedirectionStatus = false;
                            GenericDialogDelegate.crawlingRedirection = error;
                            throw new Error(error);
                        }
                    }
                    // Capture EditorDialog
                    else if (childDialog.type === 'hxgn.api.dialog.EditorDialog') {
                        try {
                            const genericRecordVisitor = yield DialogProxyTools.genericCaptureRecord(this.userId, this.baseUrl, this.tenantId, this.sessionId, childDialog.id, dialog.id, dialog.referringObject.dialogId, GenericDialogDelegate.newCrawlCopy);
                            /**
                             * Logic to handle the actions available on Editor dailog record level
                             */
                            if (childDialogLevelMenuActions && childDialogLevelMenuActions.length > 0) {
                                GenericDialogDelegate.promisesList.push(this.cacheActionsOnRecord(genericRecordVisitor.visitRecordId(), childDialogLevelMenuActions, dialog, childDialog, traversedDialogsCopy, traversedDialogsWithPersistentIdCopy, crawlingBreadCrumbs));
                                // await this.cacheActionsOnRecord (genericRecordVisitor.visitRecordId(), childDialogLevelMenuActions, dialog, childDialog, traversedDialogsCopy, crawlingBreadCrumbs);
                            }
                            /**
                             * Logic to extract the Dropdown and Flag properties list and accordingly fetch and store the response for offline
                             */
                            const propertyList = this.getPropertiesListForCrawl(childDialog);
                            // GenericDialogDelegate.promisesList.push(this.cachePropertiesOfRecord(childDialog, propertyList, genericRecordVisitor, 'Edit'));
                            yield this.cachePropertiesOfRecord(childDialog, propertyList, genericRecordVisitor, 'Edit');
                        }
                        catch (error) {
                            GenericDialogDelegate.crawlCancel = true;
                            GenericDialogDelegate.crawlingRedirectionStatus = false;
                            GenericDialogDelegate.crawlingRedirection = error;
                            throw new Error(error);
                        }
                    }
                    else {
                        return null;
                    }
                }
                ;
            }
            else if (traversedDialogsCopy.includes(dialog.persistentId)) {
                const index = traversedDialogsWithPersistentIdCopy.findIndex(tDWPID => tDWPID[dialog.persistentId]);
                if (index !== -1) {
                    const id = traversedDialogsWithPersistentIdCopy[index][dialog.persistentId];
                    const key = `copy.${this.userId}.${this.tenantId}.${id}.dialog`;
                    // extracting the existing dialog response to look for Redircetion Dialog ID to extract from storage
                    const response = yield storage.getJson(key);
                    if (dialog && response) {
                        // extracting the Redirection response to update the present redirection response to stop circular crawl
                        const { actionId: exitingActionId, dialogId: exitingDialogId } = response.referringObject;
                        const existingRedirectionResponse = JSON.parse(JSON.stringify(yield DialogProxyTools.readDialogRedirectionAsVisitorWithoutTargetId(`copy.${this.userId}`, this.tenantId, exitingDialogId, exitingActionId)));
                        // extracting the present redirection response using the present dialog response
                        const { actionId, dialogId } = dialog.referringObject;
                        const redirectionResponse = JSON.parse(JSON.stringify(yield DialogProxyTools.readDialogRedirectionAsVisitorWithoutTargetId(`copy.${this.userId}`, this.tenantId, dialogId, actionId)));
                        if (existingRedirectionResponse && redirectionResponse) {
                            redirectionResponse._enclosedJsonObject.id = existingRedirectionResponse._enclosedJsonObject.id;
                            redirectionResponse._enclosedJsonObject.dialogId = existingRedirectionResponse._enclosedJsonObject.dialogId;
                            // updating the present redirection response to match the existing redirection response ID and pushing into storage.
                            yield DialogProxyTools.readAndUpdateExistingDialogRedirection(`copy.${this.userId}`, this.tenantId, dialogId, actionId, redirectionResponse);
                        }
                    }
                }
            }
            return null;
        });
    }
    ;
    /**
     * Crawl actions for a specified record
     */
    cacheActionsOnRecord(recordId, childDialogMenuActions, dialog, childDialog, traversedDialogs, traversedDialogsWithPersistentIdCopy, crawlingBreadCrumbs) {
        return __awaiter(this, void 0, void 0, function* () {
            let resultingDialogVisitor;
            if (GenericDialogDelegate.crawlCancel === true) {
                GenericDialogDelegate.crawlingRedirectionStatus = false;
                return null;
            }
            for (const menuAction of childDialogMenuActions) {
                if (GenericDialogDelegate.crawlCancel) {
                    GenericDialogDelegate.crawlingRedirectionStatus = false;
                    return null;
                }
                const crawlingBreadCrumbsCopy = JSON.parse(JSON.stringify(crawlingBreadCrumbs));
                // Push menuaction breadcrumb
                const bCrumbObj = JSON.parse(JSON.stringify(GenericDialogDelegate.breadCrumbObject));
                bCrumbObj.breadcrumb.type = 'menuAction';
                bCrumbObj.breadcrumb.actionId = menuAction.actionId;
                bCrumbObj.breadcrumb.persistentId = dialog.persistentId;
                bCrumbObj.breadcrumb.recordId = recordId;
                crawlingBreadCrumbsCopy.push(bCrumbObj);
                this.notifyClientListener({ message: `Caching object ${recordId} ...`, eventType: ClientEventType.MESSAGEWITHCANCELOPTION });
                // Log.trace(`capturing ${menuAction.actionId} on ${childDialog.id} on the record ${recordId}`);
                const beforeAndAfterValuesMenuAction = yield DialogProxyTools.genericCaptureMenuActionRedirectionAndDialog(this.userId, this.baseUrl, this.tenantId, this.sessionId, childDialog.id, childDialog.id, menuAction.actionId, recordId, GenericDialogDelegate.newCrawlCopy);
                // Recursively continue caching dialogs generated as result of menu action on dialog until there are no actions left which can be cached.
                if (beforeAndAfterValuesMenuAction && beforeAndAfterValuesMenuAction['beforeDialog'] && !beforeAndAfterValuesMenuAction['dialogError']) {
                    resultingDialogVisitor = new DialogVisitor(beforeAndAfterValuesMenuAction['beforeDialog']);
                    const dialogRedirectionVisitor = new DialogRedirectionVisitor(beforeAndAfterValuesMenuAction['afterDialogRedirection']);
                    if (childDialog.offlineMode === 'EXPLICIT') {
                        this.associateOfflineInfoToRecords(dialog.id, childDialog.id, childDialog.persistentId, recordId, menuAction.actionId, resultingDialogVisitor, dialogRedirectionVisitor, crawlingBreadCrumbs);
                    }
                    GenericDialogDelegate.promisesList.push(this.captureDialogChildren(resultingDialogVisitor, crawlingBreadCrumbsCopy, traversedDialogs, traversedDialogsWithPersistentIdCopy));
                    // await this.captureDialogChildren(resultingDialogVisitor, crawlingBreadCrumbsCopy, traversedDialogs);
                }
            }
            ;
        });
    }
    /**
     * Get list of menu actions for the child dialog
     */
    getActionsList(childDialog, specificTopMenu = false) {
        const childDialogMenuActions = [];
        if (childDialog && childDialog.view && childDialog.view.menu && childDialog.view.menu.children) {
            childDialog.view.menu.children.forEach(menuAction => {
                if (!menuAction.children.length && (menuAction.offlineMode === 'PERFORM' || menuAction.offlineMode === 'RETRIEVE')) {
                    childDialogMenuActions.push(menuAction);
                }
                else {
                    if (!specificTopMenu) {
                        menuAction.children.forEach(childAction => {
                            let childActionFound = false;
                            childDialogMenuActions.forEach(action => {
                                if (action.actionId === childAction.actionId) {
                                    childActionFound = true;
                                }
                            });
                            if ((childAction.offlineMode === 'PERFORM' || childAction.offlineMode === 'RETRIEVE') && !childActionFound) {
                                childDialogMenuActions.push(childAction);
                            }
                        });
                    }
                }
            });
        }
        return childDialogMenuActions;
    }
    /**
     * Get list of dropdown properties for the child dialog
     */
    getPropertiesListForCrawl(childDialog) {
        const propertiesList = {
            "dropDownPropertiesList": [],
            "largePropertiesList": []
        };
        if (childDialog && childDialog.recordDef && childDialog.recordDef.propertyDefs && childDialog.recordDef.propertyDefs.length > 0) {
            childDialog.recordDef.propertyDefs.forEach(property => {
                if (property.format && (property.format === 'code-ref' && !property.canCauseSideEffects)) {
                    propertiesList.dropDownPropertiesList.push(property);
                }
                else if (property.semanticType && ((property.semanticType === 'LARGE_PROPERTY' || property.semanticType === 'USER_SIGNATURE') && property.contentType !== 'application/octet-stream' && !property.canCauseSideEffects)) {
                    propertiesList.largePropertiesList.push(property);
                }
            });
        }
        return propertiesList;
    }
    /**
     * Logic to fetch and  store the Dropdown and Flag properties for offline
     */
    cachePropertiesOfRecord(childDialog, recordProperties, record, queryType) {
        return __awaiter(this, void 0, void 0, function* () {
            for (const property of recordProperties.dropDownPropertiesList) {
                // GenericDialogDelegate.promisesList.push(DialogProxyTools.genericCaptureDropDownProperties(this.userId, this.baseUrl, this.tenantId, this.sessionId, childDialog.id, record.enclosedJsonObject().id, property.propertyName, GenericDialogDelegate.newCrawlCopy));
                yield DialogProxyTools.genericCaptureDropDownProperties(this.userId, this.baseUrl, this.tenantId, this.sessionId, childDialog.id, record.enclosedJsonObject().id, property.propertyName, GenericDialogDelegate.newCrawlCopy);
            }
            ;
            for (const property of recordProperties.largePropertiesList) {
                // GenericDialogDelegate.promisesList.push(DialogProxyTools.genericCaptureLargeProperties(this.userId, this.baseUrl, this.tenantId, this.sessionId, childDialog.id, record.enclosedJsonObject().id, property.propertyName, queryType, GenericDialogDelegate.newCrawlCopy));
                yield DialogProxyTools.genericCaptureLargeProperties(this.userId, this.baseUrl, this.tenantId, this.sessionId, childDialog.id, record.enclosedJsonObject().id, property, queryType, GenericDialogDelegate.newCrawlCopy);
            }
            ;
        });
    }
    /**
     * Process outbound queue
     */
    processOutboundQueue(queue, tenantId, sessionId, userId, baseUrl) {
        return __awaiter(this, void 0, void 0, function* () {
            // Log.trace(`OutBoundQueue for processing : ${JSON.stringify(queue)}`);
            const thisMethod = 'OfflineTools::processOutboundQueue';
            this.userId = userId;
            this.tenantId = tenantId;
            this.baseUrl = baseUrl;
            this.sessionId = sessionId;
            const unSuccessfulItem = [];
            const errorMessage = [];
            let queueIndex = 0;
            if (queue && queue.records.length) {
                const offlineSessionJcr = yield this.captureOfflineSession(baseUrl, tenantId, sessionId);
                for (const queueItem of queue.records) {
                    unSuccessfulItem.push(queueItem);
                    this.notifyClientListener({ message: `Synchronizing perform action(s) on ${queueItem.request._body.id}.`, eventType: ClientEventType.MESSAGE });
                    // Replay using breadcrumbs to get dialogId in server context for parent(container) dialog of dialog in which maintenance action happened.
                    let actionContextDialogId = yield DialogProxyTools.replayContext(this.userId, this.baseUrl, this.tenantId, this.sessionId, queueItem.breadCrumb, null);
                    // Get dialog and find among child dialogs, dialog with persistentId as queueItem.persistentId
                    if (actionContextDialogId && !actionContextDialogId['message']) {
                        const resourcePath = `tenants/${this.tenantId}/sessions/${this.sessionId}/dialogs/${actionContextDialogId}`;
                        // Log.trace(`${thisMethod} -- getting online dialog: ${actionContextDialogId}`);
                        const dialogJcr = yield DialogProxyTools.commonFetchClient().getJson(this.baseUrl, resourcePath);
                        if (dialogJcr.statusCode !== 200) {
                            throw new Error(`Unexpected result when getting dialog ${actionContextDialogId}: ${dialogJcr.statusCode}`);
                        }
                        const dialogVisitor = new DialogVisitor(dialogJcr.value);
                        const dialogObject = dialogVisitor.enclosedJsonObject();
                        // Find among children dialog with persistentId same as in queueItem.
                        if (dialogObject.children) {
                            dialogObject.children.forEach(childDialog => {
                                if (childDialog.persistentId === queueItem.persistentId) {
                                    actionContextDialogId = childDialog.id;
                                }
                            });
                        }
                        const index = queueItem.request._body.properties && queueItem.request._body.properties.findIndex((item) => item.name === 'Error');
                        index !== -1 ? queueItem.request._body.properties.splice(index, 1) : '';
                        const dialogRequest = new DialogRequest(queueItem.request._baseUrl, queueItem.request._resourcePath, queueItem.request._body, null, null, queueItem.request._fetchClientParams);
                        if (actionContextDialogId && dialogRequest.isPutRecordPath()) {
                            const resourcePathElems = dialogRequest.resourcePathElems();
                            const propertyDialogID = resourcePathElems[5];
                            // Update dialogId and sessionId.
                            resourcePathElems[5] = actionContextDialogId;
                            resourcePathElems[3] = this.sessionId;
                            const resourcePath = resourcePathElems.join('/');
                            /**
                             * Logic to handle the indentify and sync the record level specifc properties like image/attahcemnts etc..,
                             */
                            const propertyTimestamp = queueItem.propertyTimestamp;
                            const putProperty = this._dialogDelegateStateVisitor.getContentProperties(propertyDialogID);
                            if (putProperty) {
                                const propertyCommitJsonObject = yield DialogProxyTools.readPropertyCommit(this.userId, this.tenantId, propertyDialogID, putProperty.propertyName, propertyTimestamp);
                                if (propertyCommitJsonObject) {
                                    this.notifyClientListener({
                                        message: `Writing image for document comment`,
                                        eventType: ClientEventType.MESSAGE
                                    });
                                    const putPropertyRequest = new DialogRequest(putProperty.request._baseUrl, putProperty.request._resourcePath, putProperty.request._body, null, null, putProperty.request._fetchClientParams);
                                    const propertyResourcePathElems = putPropertyRequest.resourcePathElems();
                                    // Update dialogId and sessionId.
                                    propertyResourcePathElems[5] = actionContextDialogId;
                                    propertyResourcePathElems[3] = this.sessionId;
                                    const propertyResourcePath = propertyResourcePathElems.join('/');
                                    for (const writeLargeProperty of propertyCommitJsonObject) {
                                        const commitCreateCommentPropertyJcr = yield DialogProxyTools.commonFetchClient().putJson(this.delegateBaseUrl(), propertyResourcePath, writeLargeProperty);
                                        if (commitCreateCommentPropertyJcr.statusCode !== 200) {
                                            Log.error(`Unexpected result when committing a large property: ${commitCreateCommentPropertyJcr.statusCode} ${JSON.stringify(commitCreateCommentPropertyJcr.value)}`);
                                            break;
                                        }
                                    }
                                }
                            }
                            // Make the network call using fetchClient.
                            const dialogRedirectionJcr = yield DialogProxyTools.commonFetchClient().putJson(dialogRequest.baseUrl(), resourcePath, dialogRequest.body());
                            // Log.trace(`dialogRedirectionJcr for putJson: ${dialogRedirectionJcr}`);
                            if (dialogRedirectionJcr.statusCode === 303 || dialogRedirectionJcr.statusCode === 200) {
                                unSuccessfulItem.pop();
                            }
                            else if (dialogRedirectionJcr.statusCode === 400) {
                                const res = JSON.parse(JSON.stringify(dialogRedirectionJcr));
                                if (res.value && res.value.messageType && res.value.messageType === 'ERROR') {
                                    let errorText = res.value.message + ' ';
                                    if (res.value.children) {
                                        for (const child of res.value.children) {
                                            errorText += child.messageType && child.messageType === 'ERROR' ? child.message + ' ' : '';
                                        }
                                    }
                                    errorMessage.push({
                                        'index': queueIndex,
                                        'message': errorText,
                                        'record': queueItem.record
                                    });
                                }
                                // Log.trace(`${thisMethod} Error while submitting updated record info. Error Code: ${dialogRedirectionJcr.statusCode}`);
                                // Log.trace(`Processing of outbound queue item ${JSON.stringify(queueItem)} failed.`)
                            }
                            else {
                                // Log.trace(`${thisMethod} Error while submitting updated record info. Error Code: ${dialogRedirectionJcr.statusCode}`);
                                // Log.trace(`Processing of outbound queue item ${JSON.stringify(queueItem)} failed.`)
                            }
                        }
                        else if (dialogRequest.isPutViewModePath()) {
                            // Handle accordingly.
                        }
                    }
                    else {
                        errorMessage.push({
                            'index': queueIndex,
                            'message': actionContextDialogId['message'],
                            'record': queueItem.record
                        });
                        // Log.trace(`${thisMethod} Error while submitting updated record info. Error Info: ${actionContextDialogId}`);
                        // Log.trace(`Processing of outbound queue item ${JSON.stringify(queueItem)} failed.`);
                    }
                    queueIndex++;
                }
            }
            return unSuccessfulItem.length > 0 ? errorMessage : [true];
        });
    }
    associateOfflineInfoToRecords(parentDialogId, containingDialogId, persistentId, id, actionId, actionDialog, dialogRedirectionVisitor, crawlingBreadcrumbs) {
        let recordFound = false;
        const parentDialogPersistentId = dialogRedirectionVisitor.enclosedJsonObject().referringObject.rootPersistentId;
        this.recordsWithOfflineInfo.forEach((record) => {
            if (record && ((record.id === id && record.persistentId === persistentId) || (record.id === id && !record.persistentId))) {
                record.parentDialogOfflineId = parentDialogId;
                record[actionId] = actionDialog.visitId();
                record.parentDialogPersistentId = parentDialogPersistentId;
                record.breadcrumbs = crawlingBreadcrumbs;
                recordFound = true;
            }
        });
        if (!recordFound) {
            const recordInfo = {
                id,
                parentDialogOfflineId: parentDialogId,
                persistentId,
                containingDialogId,
                parentDialogPersistentId,
                breadcrumbs: crawlingBreadcrumbs,
            };
            recordInfo[actionId] = actionDialog.visitId();
            this.recordsWithOfflineInfo.push(recordInfo);
        }
    }
    ;
    returnParentListDialog(request) {
        return __awaiter(this, void 0, void 0, function* () {
            const pathFields = request.deconstructPostMenuActionPath();
            const recordsWithOfflineInfo = this._dialogDelegateStateVisitor.visitRecordsWithOfflineInfo();
            const recordIdFromRequest = request.body().targets[0];
            let parentListDialogId;
            let containingDialogId;
            let parentDialogPersistentId;
            for (const record of recordsWithOfflineInfo) {
                if (record['id'] === recordIdFromRequest) {
                    parentListDialogId = record['parentDialogOfflineId'];
                    containingDialogId = record['containingDialogId'];
                    parentDialogPersistentId = record['parentDialogPersistentId'];
                }
            }
            return parentListDialogId ? Promise.resolve(new JsonClientResponse(GenericDialogDelegateTools.constructParentListDialogRedirection(request, recordIdFromRequest, parentListDialogId, parentDialogPersistentId), 303)) : Promise.resolve(new JsonClientResponse(GenericDialogDelegateTools.constructGenericListActionNullRedirection(pathFields.tenantId, pathFields.sessionId, pathFields.dialogId, pathFields.actionId), 303));
        });
    }
    captureOfflineSession(baseUrl, tenantId, sessionId) {
        return __awaiter(this, void 0, void 0, function* () {
            const thisMethod = 'GenericDialogDelegate::captureOfflineSession';
            // this.notifyClientListener({message: 'Capturing session for offline', eventType: ClientEventType.MESSAGE});
            const resourcePath = `tenants/${tenantId}/sessions/${sessionId}`;
            const sessionJcr = yield DialogProxyTools.commonFetchClient().getJson(baseUrl, resourcePath);
            if (sessionJcr.statusCode === 400 && sessionJcr.value['message'].includes('Session')) {
                return Promise.reject({ type: 'InvalidSession', message: 'Invalid session' });
            }
            // Log.trace(`${thisMethod} -- session value: ${JSON.stringify(sessionJcr.value)}`);
            const sessionVisitor = new SessionVisitor(sessionJcr.value);
            yield GenericDialogDelegateTools.writeOfflineSession(tenantId, this.delegateUserId(), sessionVisitor);
            return sessionJcr;
        });
    }
    constructCreateCommentNullRedirection(tenantId, sessionId, dialogId, destroyed) {
        const nullRedirection = DialogProxyTools.constructNullRedirection(tenantId, sessionId);
        const nullRedirectionVisitor = new RedirectionVisitor(nullRedirection);
        nullRedirectionVisitor.visitAndSetReferringDialogAlias('Documents_CreateComment');
        nullRedirectionVisitor.visitAndSetReferringDialogName('Documents_CreateComment');
        nullRedirectionVisitor.visitAndSetReferringDialogId(dialogId);
        nullRedirectionVisitor.visitAndSetReferringDialogMode(destroyed);
        return new JsonClientResponse(nullRedirectionVisitor.enclosedJsonObject(), 303);
    }
    delegateBaseUrl() {
        return this._dialogDelegateStateVisitor.visitBaseUrl();
    }
    delegateDownloadManagerVisitor() {
        return this._dialogDelegateStateVisitor.visitBriefcase();
    }
    delegateOnline() {
        if (this._dialogDelegateStateVisitor) {
            return this._dialogDelegateStateVisitor.visitBriefcase().visitOnline();
        }
        return true;
    }
    delegateSelectedItemIds() {
        return this._dialogDelegateStateVisitor.visitSelectedItemIds();
    }
    delegateUserId() {
        return this._dialogDelegateStateVisitor.visitUserId();
    }
    delegateSelectedItemsRecordSetVisitor() {
        return this._dialogDelegateStateVisitor.visitSelectedItemsRecordSet();
    }
    initializeAfterCreateSession(request, sessionVisitor) {
        return __awaiter(this, void 0, void 0, function* () {
            const thisMethod = 'GenericDialogDelegate::initializeAfterCreateSession';
            const pathFields = request.deconstructPostSessionsPath();
            // await DialogProxyTools.showStoredDialogNavigation();
            // await DialogProxyTools.showAllStorageKeys();
            // await DialogProxyTools.showAllStorageKeysAndValues();
            const dialogDelegateStateVisitor = yield GenericDialogDelegateTools.readDialogDelegateStateVisitor(pathFields.tenantId, sessionVisitor.visitUserId());
            // Log.trace(`${thisMethod} -- dialog delegate state before initializing: ${dialogDelegateStateVisitor.copyAsJsonString()}`);
            // Log.trace(`${thisMethod} -- selected work packages count: ${dialogDelegateStateVisitor.visitSelectedItemIds().length}`);
            const loginVisitor = new LoginVisitor(request.body());
            dialogDelegateStateVisitor.visitAndSetBaseUrl(request.baseUrl());
            dialogDelegateStateVisitor.visitAndSetTenantId(pathFields.tenantId);
            dialogDelegateStateVisitor.visitAndSetSessionId(sessionVisitor.visitId());
            dialogDelegateStateVisitor.visitAndSetUserId(sessionVisitor.visitUserId());
            // Set state to offline/online as per user click
            const offlinepressed = request.body().deviceProperties.offlineMode;
            dialogDelegateStateVisitor.visitBriefcase().visitAndSetOnline(!offlinepressed);
            // Reset session expiry and user affirmation to relogin to be false
            storage.setJson('ReloginUserAffirmation', false);
            dialogDelegateStateVisitor.visitBriefcase().visitAndSetSessionExpiry(false);
            // Use online credentials to generate offline LoginHash
            if (!dialogDelegateStateVisitor.visitLoginHash() && loginVisitor.visitUserId() && loginVisitor.visitPassword()) {
                const offlineLoginHash = GenericDialogDelegateTools.createOfflineLoginHash(pathFields.tenantId, sessionVisitor.visitId(), loginVisitor.visitUserId(), loginVisitor.visitPassword());
                dialogDelegateStateVisitor.visitAndSetLoginHash(offlineLoginHash);
                dialogDelegateStateVisitor.visitBriefcase().visitAndSetPassword(loginVisitor.visitPassword());
            }
            else if (dialogDelegateStateVisitor.visitLoginHash() && loginVisitor.visitUserId() && loginVisitor.visitPassword()) {
                const offlinePassword = dialogDelegateStateVisitor.visitBriefcase().visitPassword();
                const newofflineLoginHash = GenericDialogDelegateTools.createOfflineLoginHash(pathFields.tenantId, sessionVisitor.visitId(), loginVisitor.visitUserId(), offlinePassword);
                dialogDelegateStateVisitor.visitAndSetLoginHash(newofflineLoginHash);
            }
            // dialogDelegateStateVisitor.visitWorkPackagesRecordSet().visitAndClearRecords();
            yield GenericDialogDelegateTools.writeDialogDelegateState(pathFields.tenantId, dialogDelegateStateVisitor);
            this._dialogDelegateStateVisitor = dialogDelegateStateVisitor;
            const jsonObject = sessionVisitor.enclosedJsonObject();
            const { workbenches } = jsonObject.appWindow;
            const briefcaseWorkbench = {
                id: 'BriefcaseManager',
                name: 'Download Manager',
                offlineCapable: false,
                type: 'hxgn.api.dialog.Workbench',
                actions: [
                    {
                        actionId: 'Briefcase',
                        alias: 'Briefcase',
                        iconBase: 'https://s3-eu-west-1.amazonaws.com/res-euw.catavolt.net/hxgnsdx/images/briefcase-icon.png',
                        id: 'Briefcase',
                        name: 'Briefcase',
                        type: 'hxgn.api.dialog.WorkbenchAction',
                        workbenchId: 'BriefcaseManager',
                    },
                ],
            };
            let isAppOfflineCapable = false;
            const onlineStatus = this.delegateOnline();
            // Check if app is offline capable and add online status flag to launchers/actions
            workbenches.forEach(workbench => {
                if (workbench.offlineCapable) {
                    isAppOfflineCapable = true;
                }
                if (workbench.actions) {
                    workbench.actions.forEach(action => {
                        action.isOffline = !onlineStatus;
                    });
                }
            });
            // Add Briefcase Manager workbench
            if (isAppOfflineCapable) {
                workbenches.push(briefcaseWorkbench);
            }
            const freshDialogDelegateStateVisitor = yield GenericDialogDelegateTools.readDialogDelegateStateVisitor(pathFields.tenantId, sessionVisitor.visitUserId());
            // Log.trace(`${thisMethod} -- dialog delegate state after initializing: ${freshDialogDelegateStateVisitor.copyAsJsonString()}`);
            // Log.trace(`${thisMethod} -- done initializing`);
        });
    }
    notifyClientListener(clientEvent) {
        const clientListener = this._dialogProxy.clientListener();
        if (clientListener) {
            clientListener(clientEvent);
        }
    }
    performBriefcaseWorkbenchActionRequest(request) {
        const pathFields = request.deconstructPostWorkbenchActionPath();
        const dialogRedirection = Download_Manager_FORM_REDIRECTION.copyOfResponse();
        DialogRedirectionVisitor.propagateDialogId(dialogRedirection, GenericDialogDelegateTools.DOWNLOAD_MANAGER_ROOT_DIALOG_ID);
        DialogRedirectionVisitor.propagateTenantIdAndSessionId(dialogRedirection, pathFields.tenantId, pathFields.sessionId);
        return Promise.resolve(new JsonClientResponse(dialogRedirection, 303));
    }
    performCreateSessionRequest(request) {
        return __awaiter(this, void 0, void 0, function* () {
            const thisMethod = 'GenericDialogDelegate::performCreateSessionRequest';
            const pathFields = request.deconstructPostSessionsPath();
            const loginVisitor = new LoginVisitor(request.body());
            const delegateState = yield GenericDialogDelegateTools.readDialogDelegateStateVisitor(pathFields.tenantId, loginVisitor.visitUserId());
            // If session is expired and user affirmed to relogin, return null to enable online login
            const reloginUserAffirmation = yield storage.getJson('ReloginUserAffirmation');
            const offlinepressed = request.body().deviceProperties.offlineMode;
            if (!offlinepressed || (!delegateState || (delegateState.visitBriefcase().visitSessionExpiry() && reloginUserAffirmation))) {
                return null;
            }
            const offlineSessionId = delegateState.visitSessionId();
            const offlineLoginHash = delegateState.visitLoginHash();
            const candidateLoginHash = GenericDialogDelegateTools.createOfflineLoginHash(pathFields.tenantId, offlineSessionId, loginVisitor.visitUserId(), loginVisitor.visitPassword());
            if (candidateLoginHash !== offlineLoginHash) {
                const dialogMessage = DialogProxyTools.constructDialogMessageModel("User Id or Password is not valid");
                return Promise.resolve(new JsonClientResponse(dialogMessage, 400));
            }
            const offlineSessionVisitor = yield GenericDialogDelegateTools.readOfflineSession(pathFields.tenantId, delegateState.visitUserId());
            // Log.trace(`${thisMethod} -- returning offline session: ${offlineSessionVisitor.copyAsJsonString()}`);
            return new JsonClientResponse(offlineSessionVisitor.enclosedJsonObject(), 200);
        });
    }
    performDeleteSessionRequest(request) {
        const thisMethod = 'GenericDialogDelegate::performDeleteSessionRequest';
        if (!this.delegateOnline()) {
            const pathFields = request.deconstructDeleteSessionPath();
            return Promise.resolve(new JsonClientResponse(GenericDialogDelegateTools.constructOfflineLogoutResponse(pathFields.sessionId), 200));
        }
        return null;
    }
    recursivePromises(promisesList, tenantId, sessionId) {
        return __awaiter(this, void 0, void 0, function* () {
            // const t1 = performance.now();
            return Promise.all(promisesList).then((success) => __awaiter(this, void 0, void 0, function* () {
                if (success.length === promisesList.length) {
                    this._dialogDelegateStateVisitor.visitAndSetOfflineCacheStatus(true);
                    this.delegateDownloadManagerVisitor().visitAndSetDownloadDate(new Date());
                    this.delegateDownloadManagerVisitor().visitAndSetDownloads(true);
                    this.setDownloadStatusForSelectedItems();
                    this._dialogDelegateStateVisitor.visitAndSetRecordsWithOfflineInfo(this.recordsWithOfflineInfo);
                    yield GenericDialogDelegateTools.writeDialogDelegateState(tenantId, this._dialogDelegateStateVisitor);
                    GenericDialogDelegate.crawlingRedirection = GenericDialogDelegateTools.constructExitOfflineModeNullRedirection(tenantId, sessionId);
                    GenericDialogDelegate.crawlingRedirectionStatus = true;
                }
                else {
                    yield this.recursivePromises(promisesList, tenantId, sessionId);
                }
            })).catch((error) => {
                // const t3 = performance.now();
                // Log.trace(`Caching failed in ${(t3-t1)/1000} seconds with error: ${JSON.stringify(error)}`);
                GenericDialogDelegate.crawlingRedirectionStatus = false;
                GenericDialogDelegate.crawlingRedirection = error;
            });
        });
    }
    setDownloadStatusForSelectedItems() {
        const statusObj = {};
        this.delegateSelectedItemIds().forEach(element => {
            statusObj[element] = true;
        });
        this._dialogDelegateStateVisitor.visitAndSetSelectedItemsDownloadStatus(statusObj);
    }
    /**
     * Performs crawling and downloads/caches dialog responses for selected objects and implicit objects
     * @param baseUrl
     * @param tenantId
     * @param sessionId
     */
    performDownloadSelectedItemsRequest(baseUrl, tenantId, sessionId) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this._dialogDelegateStateVisitor.enclosedJsonObject().loginHash) {
                const dialogMessage = DialogProxyTools.constructDialogMessageModel("Please create an offline password in settings to be able to switch to offline mode.");
                return Promise.resolve(new JsonClientResponse(dialogMessage, 400));
            }
            const offlineSessionJcr = yield this.captureOfflineSession(baseUrl, tenantId, sessionId);
            // const t1 = performance.now();
            const selectedWorkItems = this._dialogDelegateStateVisitor.enclosedJsonObject().selectedItemIds;
            /**
             * initilaizing the GoOffline process by triggering the server to start the Offline process so that it doesnt close/delete dialogs
             */
            const goOfflineProcess = yield DialogProxyTools.intializeGoOfflineProcess(this.delegateUserId(), baseUrl, tenantId, sessionId, 'goOffline');
            if (goOfflineProcess.statusCode !== 200) {
                return Promise.reject(goOfflineProcess);
            }
            else {
                // goOfflineProcess.value.offlineStatus.clientShouldCrawl
                // Need to check on different failur scenrios while crawling, and validate correct the then and catch blocks are executed
                const currentOfflineKeys = yield DialogProxyTools.retrieveFilteredKeys(this.delegateUserId(), tenantId);
                GenericDialogDelegate.newCrawlCopy = 'copy';
                // GenericDialogDelegate.newCrawlCopy = '';
                const outboundQueue = yield DialogProxyTools.readOutBoundQueue(this.delegateUserId(), tenantId);
                const freshDialogDelegateStateVisitor = yield GenericDialogDelegateTools.readDialogDelegateStateVisitor(tenantId, this.delegateUserId());
                GenericDialogDelegate.promisesList = [];
                GenericDialogDelegate.crawlCancel = false;
                GenericDialogDelegate.crawlingRedirectionStatus = true;
                this.explicitListItems = {
                    records: []
                };
                const promiseList = yield this.cacheForOffline(baseUrl, this.delegateUserId(), tenantId, sessionId, selectedWorkItems);
                yield this.recursivePromises(GenericDialogDelegate.promisesList, tenantId, sessionId);
                // const t2 = performance.now();
                // Log.trace(`Total time taken for capturing: ${(t2-t1)/1000} seconds`);
                if (GenericDialogDelegate.crawlingRedirectionStatus) {
                    const response = yield DialogProxyTools.intializeGoOfflineProcess(this.delegateUserId(), baseUrl, tenantId, sessionId, 'goOnline');
                    yield DialogProxyTools.clearAllStoragekeys(currentOfflineKeys);
                    yield DialogProxyTools.updateAllFilteredKeys(this.delegateUserId(), tenantId, GenericDialogDelegate.newCrawlCopy);
                    yield DialogProxyTools.clearAllStorageAt(`${GenericDialogDelegate.newCrawlCopy}.${this.delegateUserId()}`, tenantId);
                    yield this.captureOfflineSession(baseUrl, tenantId, sessionId);
                    DialogProxyTools.writeOutBoundQueue(this.delegateUserId(), tenantId, outboundQueue);
                    yield GenericDialogDelegateTools.writeDialogDelegateState(tenantId, freshDialogDelegateStateVisitor);
                    GenericDialogDelegate.newCrawlCopy = '';
                    return Promise.resolve(new JsonClientResponse(GenericDialogDelegate.crawlingRedirection, 303));
                }
                else {
                    const response = yield DialogProxyTools.intializeGoOfflineProcess(this.delegateUserId(), baseUrl, tenantId, sessionId, 'goOnline');
                    yield DialogProxyTools.clearAllStorageAt(`${GenericDialogDelegate.newCrawlCopy}.${this.delegateUserId()}`, tenantId);
                    GenericDialogDelegate.newCrawlCopy = '';
                    return Promise.reject(GenericDialogDelegate.crawlingRedirection);
                }
            }
        });
    }
    /**
     * Handling the crawl cancel action.
     * Inject the Reject promise inside the Promises list of crawling and also use cancelCrawl bvariable to stop recursive addition of Promises to list in CrawlProcess
     */
    cancelOfflineCrawlingProcess(tenantId, sessionId) {
        return __awaiter(this, void 0, void 0, function* () {
            GenericDialogDelegate.crawlCancel = true;
            this.notifyClientListener({
                message: 'Cancelling the Offline Crawling',
                eventType: ClientEventType.MESSAGE
            });
            GenericDialogDelegate.promisesList.unshift(Promise.reject());
            yield DialogProxyTools.clearAllStorageAt(`${GenericDialogDelegate.newCrawlCopy}.${this.delegateUserId()}`, tenantId);
            GenericDialogDelegate.crawlingRedirection = GenericDialogDelegateTools.constructExitOfflineModeNullRedirection(tenantId, sessionId);
            this.notifyClientListener({
                message: 'Completed Cancelling the Offline Crawling',
                eventType: ClientEventType.MESSAGE
            });
            return Promise.resolve(new JsonClientResponse(GenericDialogDelegate.crawlingRedirection, 303));
        });
    }
    genericPerformEnterOfflineModeContinueRequest(baseUrl, tenantId, sessionId) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.delegateOnline()) {
                const dialogMessage = DialogProxyTools.constructDialogMessageModel("Already offline");
                return Promise.resolve(new JsonClientResponse(dialogMessage, 400));
            }
            else if (!this._dialogDelegateStateVisitor.enclosedJsonObject().loginHash) {
                const dialogMessage = DialogProxyTools.constructDialogMessageModel("Please create an offline password in settings to be able to switch to offline mode.");
                return Promise.resolve(new JsonClientResponse(dialogMessage, 400));
            }
            if (!this._dialogDelegateStateVisitor.visitOfflineCacheStatus()) {
                yield this.performDownloadSelectedItemsRequest(baseUrl, tenantId, sessionId);
            }
            this.delegateDownloadManagerVisitor().visitAndSetOnline(false);
            this.notifyClientListener({
                message: 'Enter offline mode completed',
                eventType: ClientEventType.OFFLINE
            });
            yield GenericDialogDelegateTools.writeDialogDelegateState(tenantId, this._dialogDelegateStateVisitor);
            return Promise.resolve(new JsonClientResponse(GenericDialogDelegateTools.constructExitOfflineModeNullRedirection(tenantId, sessionId), 303));
        });
    }
    performSettingsRequest(request) {
        return __awaiter(this, void 0, void 0, function* () {
            const pathFields = request.deconstructPostMenuActionPath();
            const dialogRedirection = Download_Manager_EnterOfflineMode_REDIRECTION.copyOfResponse();
            DialogRedirectionVisitor.propagateDialogId(dialogRedirection, GenericDialogDelegateTools.DOWNLOAD_MANAGER_ENTER_OFFLINE_ROOT_DIALOG_ID);
            DialogRedirectionVisitor.propagateTenantIdAndSessionId(dialogRedirection, pathFields.tenantId, pathFields.sessionId);
            return Promise.resolve(new JsonClientResponse(dialogRedirection, 303));
        });
    }
    /**
     * Clear all selections for offline along with crawled offline cache from storage.
     * @param request
     */
    performClearOfflineCacheRequest(request) {
        return __awaiter(this, void 0, void 0, function* () {
            const pathFields = request.deconstructPostMenuActionPath();
            this._dialogDelegateStateVisitor.visitAndClearSelectedItemIds();
            this._dialogDelegateStateVisitor.visitAndClearSelectedRecords();
            this.delegateSelectedItemsRecordSetVisitor().visitAndClearRecords();
            this._dialogDelegateStateVisitor.visitAndClearEditedRecords();
            this._dialogDelegateStateVisitor.visitAndClearQueueItems();
            // Clear outbound queue
            DialogProxyTools.writeOutBoundQueue(this.delegateUserId(), pathFields.tenantId, { records: [] });
            this._dialogDelegateStateVisitor.visitAndSetOfflineCacheStatus(false);
            this._dialogDelegateStateVisitor.visitAndSetSelectedItemsDownloadStatus({});
            this.delegateDownloadManagerVisitor().visitAndSetDownloadDate(null);
            this.delegateDownloadManagerVisitor().visitAndSetDownloads(false);
            this.notifyClientListener({ message: 'Clearing Offline Cache', eventType: ClientEventType.MESSAGE });
            let offlineSessionVisitor;
            if (!this.delegateOnline()) {
                offlineSessionVisitor = yield GenericDialogDelegateTools.readOfflineSession(pathFields.tenantId, this.delegateUserId());
            }
            yield DialogProxyTools.clearAllStorageAt(this.delegateUserId(), pathFields.tenantId);
            if (!this.delegateOnline()) {
                yield GenericDialogDelegateTools.writeOfflineSession(pathFields.tenantId, this.delegateUserId(), offlineSessionVisitor);
            }
            yield GenericDialogDelegateTools.writeDialogDelegateState(pathFields.tenantId, this._dialogDelegateStateVisitor);
            return Promise.resolve(new JsonClientResponse(GenericDialogDelegateTools.constructGenericListActionNullRedirection(pathFields.tenantId, pathFields.sessionId, pathFields.dialogId, pathFields.actionId, true), 303));
        });
    }
    genericPerformExitOfflineModeMenuActionRequest(request) {
        return __awaiter(this, void 0, void 0, function* () {
            const thisMethod = 'GenericDialogDelegate::performExitOfflineModeMenuActionRequest';
            if (this.delegateOnline()) {
                const dialogMessage = DialogProxyTools.constructDialogMessageModel("Already online");
                return Promise.resolve(new JsonClientResponse(dialogMessage, 400));
            }
            const baseurl = request.baseUrl();
            const pathFields = request.deconstructPostMenuActionPath();
            const tenantId = pathFields.tenantId;
            const sessionId = pathFields.sessionId;
            const userId = this.delegateUserId();
            // Do outbound queue processing
            const outboundQueue = yield DialogProxyTools.readOutBoundQueue(userId, tenantId);
            let success;
            try {
                success = yield this.processOutboundQueue(outboundQueue, tenantId, sessionId, userId, baseurl);
            }
            catch (error) {
                if (error.type === 'InvalidSession') {
                    this.delegateDownloadManagerVisitor().visitAndSetSessionExpiry(true);
                    yield GenericDialogDelegateTools.writeDialogDelegateState(pathFields.tenantId, this._dialogDelegateStateVisitor);
                    return Promise.reject(error);
                }
            }
            if (success[0] === true) {
                this._dialogDelegateStateVisitor.visitAndClearEditedRecords();
                this._dialogDelegateStateVisitor.visitAndClearQueueItems();
                this.notifyClientListener({ message: 'Exit offline mode completed', eventType: ClientEventType.ONLINE });
                this.delegateDownloadManagerVisitor().visitAndSetOnline(true);
                // Clear outbound queue
                DialogProxyTools.writeOutBoundQueue(this.delegateUserId(), tenantId, { records: [] });
                yield GenericDialogDelegateTools.writeDialogDelegateState(pathFields.tenantId, this._dialogDelegateStateVisitor);
                const nullRedirection = GenericDialogDelegateTools.constructExitOfflineModeNullRedirection(pathFields.tenantId, pathFields.sessionId);
                return new JsonClientResponse(nullRedirection, 303);
            }
            else {
                Log.warn(`Outbound queue processing failed with error: ${JSON.stringify(success)}`);
                let dialogMessage = '';
                if (success.length > 0) {
                    this._dialogDelegateStateVisitor.visitAndUpdateEditedRecords(success);
                    DialogProxyTools.writeOutBoundQueue(this.delegateUserId(), tenantId, this._dialogDelegateStateVisitor.enclosedJsonObject().editedRecords);
                    yield GenericDialogDelegateTools.writeDialogDelegateState(pathFields.tenantId, this._dialogDelegateStateVisitor);
                    dialogMessage = DialogProxyTools.constructDialogMessageModel("Synchronization has failed. Please check Offline Transactions");
                }
                else {
                    dialogMessage = DialogProxyTools.constructDialogMessageModel("Synchronization has failed. Please check Offline Transactions");
                }
                this.delegateDownloadManagerVisitor().visitAndSetOnline(false);
                return Promise.resolve(new JsonClientResponse(dialogMessage, 400));
            }
        });
    }
    /**
     * Discard pending changes and toggle to online mode.
     * @param request
     */
    genericPerformExitOfflineModeAndDiscardChangesMenuActionRequest(request) {
        return __awaiter(this, void 0, void 0, function* () {
            const pathFields = request.deconstructPostMenuActionPath();
            this._dialogDelegateStateVisitor.visitAndClearEditedRecords();
            this._dialogDelegateStateVisitor.visitAndClearQueueItems();
            this.notifyClientListener({ message: 'Exit offline mode completed', eventType: ClientEventType.ONLINE });
            this.delegateDownloadManagerVisitor().visitAndSetOnline(true);
            // Clear outbound queue
            DialogProxyTools.writeOutBoundQueue(this.delegateUserId(), pathFields.tenantId, { records: [] });
            yield GenericDialogDelegateTools.writeDialogDelegateState(pathFields.tenantId, this._dialogDelegateStateVisitor);
            const nullRedirection = GenericDialogDelegateTools.constructExitOfflineModeNullRedirection(pathFields.tenantId, pathFields.sessionId);
            return new JsonClientResponse(nullRedirection, 303);
        });
    }
    genericPerformRemoveEditedItemMenuActionRequest(request) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.delegateOnline()) {
                const alreadyOnlineDialogMessage = DialogProxyTools.constructDialogMessageModel("Already online");
                return Promise.resolve(new JsonClientResponse(alreadyOnlineDialogMessage, 400));
            }
            const baseurl = request.baseUrl();
            const pathFields = request.deconstructPostMenuActionPath();
            const tenantId = pathFields.tenantId;
            const sessionId = pathFields.sessionId;
            const userId = this.delegateUserId();
            this._dialogDelegateStateVisitor.removeSelectedEditRecord(request.body().targets[0]);
            DialogProxyTools.writeOutBoundQueue(this.delegateUserId(), tenantId, this._dialogDelegateStateVisitor.enclosedJsonObject().editedRecords);
            this.notifyClientListener({ message: 'Item Deleted', eventType: ClientEventType.MESSAGE });
            const dialogMessage = DialogProxyTools.constructDialogMessageModel("Already online");
            return GenericDialogDelegateTools.writeDialogDelegateState(pathFields.tenantId, this._dialogDelegateStateVisitor).then(nullValue2 => {
                return Promise.resolve(new JsonClientResponse(dialogMessage, 303));
            });
        });
    }
    performOfflineBriefcaseCommentsRequest(request) {
        const response = this._dialogDelegateStateVisitor.visitEditedRecordSet().enclosedJsonObject();
        return Promise.resolve(new JsonClientResponse(response, 200));
    }
    performOfflineBriefcaseSelectedItemsRequest(request) {
        const response = RecordSetVisitor.emptyRecordSetVisitor().enclosedJsonObject();
        const downloadStatusObj = this._dialogDelegateStateVisitor.visitSelectedItemsDownloadStatus();
        const selectedItems = this.delegateSelectedItemsRecordSetVisitor().enclosedJsonObject().records;
        this.delegateSelectedItemIds().forEach(element => {
            const elementStatusObj = downloadStatusObj && downloadStatusObj[element] ? true : false;
            const extractedData = element.split('@');
            const id = extractedData[extractedData.length - 1];
            selectedItems.forEach(record => {
                if (record.id === id) {
                    const recordVisitor = new RecordVisitor(record);
                    const elementName = recordVisitor.visitPropertyValueAt('Name');
                    RecordSetVisitor.addOrUpdateRecord(response, SelectedOfflineItemsVisitor.createFromOfflineItemsVisitor(element, elementStatusObj, elementName));
                }
            });
        });
        return Promise.resolve(new JsonClientResponse(response, 200));
    }
    performSelectedItemsAddItemToBriefcase(request) {
        // REQUIRED: Before we can add a Work Package to the briefcase, we must be online
        if (!this.delegateOnline()) {
            const dialogMessage = DialogProxyTools.constructDialogMessageModel("Cannot add a Work Package to the briefcase while offline");
            return Promise.resolve(new JsonClientResponse(dialogMessage, 400));
        }
        const targets = ActionParametersVisitor.visitTargetsValue(request.body());
        if (targets && targets.length > 0) {
            if (targets[0]['alias'] && targets[0]['alias'].length) {
                targets[0] = targets[0]['alias'].filter(item => item !== undefined);
                targets[0] = targets[0].join('@');
                const target = targets.join('@');
                this._dialogDelegateStateVisitor.addSelectedItemId(target);
            }
            else {
                this._dialogDelegateStateVisitor.addSelectedItemId(targets[0]);
            }
        }
        const pathFields = request.deconstructPostMenuActionPath();
        this._dialogDelegateStateVisitor.visitAndSetOfflineCacheStatus(false);
        return GenericDialogDelegateTools.writeDialogDelegateState(pathFields.tenantId, this._dialogDelegateStateVisitor).then(nullValue => {
            return new JsonClientResponse(GenericDialogDelegateTools.constructAddToBriefcaseNullRedirection(pathFields.tenantId, pathFields.sessionId, pathFields.dialogId), 303);
        });
    }
    performSelectedItemsRemoveItemFromBriefcase(request) {
        // REQUIRED: Before we can remove a Work Package from the briefcase, we must be online
        if (!this.delegateOnline()) {
            const dialogMessage = DialogProxyTools.constructDialogMessageModel("Cannot remove a Work Package from the briefcase while offline");
            return Promise.resolve(new JsonClientResponse(dialogMessage, 400));
        }
        const targets = ActionParametersVisitor.visitTargetsValue(request.body());
        if (targets && targets.length > 0) {
            if (targets[0]['alias'] && targets[0]['alias'].length) {
                targets[0] = targets[0]['alias'].filter(item => item !== undefined);
                targets[0] = targets[0].join('@');
                const target = targets.join('@');
                this._dialogDelegateStateVisitor.removeSelectedItemId(target);
            }
            else {
                this._dialogDelegateStateVisitor.removeSelectedItemId(targets[0]);
            }
        }
        const pathFields = request.deconstructPostMenuActionPath();
        this._dialogDelegateStateVisitor.visitAndSetOfflineCacheStatus(false);
        return GenericDialogDelegateTools.writeDialogDelegateState(pathFields.tenantId, this._dialogDelegateStateVisitor).then(nullValue => {
            return new JsonClientResponse(GenericDialogDelegateTools.constructRemoveFromBriefcaseNullRedirection(pathFields.tenantId, pathFields.sessionId, pathFields.dialogId), 303);
        });
    }
    addBreadCrumbs(request, response) {
        return __awaiter(this, void 0, void 0, function* () {
            const bCrumbObj = JSON.parse(JSON.stringify(GenericDialogDelegate.breadCrumbObject));
            const match = /^Briefcase/i;
            if (response.value.type !== 'hxgn.api.dialog.NullRedirection' && !match.test(response.value.id)) {
                if (response.value.type === 'hxgn.api.dialog.DialogRedirection' && response.value.referringObject.workbenchId === 'Briefcase Manager') {
                    const recordsWithOfflineInfo = this._dialogDelegateStateVisitor.visitRecordsWithOfflineInfo();
                    const recordIdFromRequest = request.body().targets[0];
                    let breadcrumbs = [];
                    for (const record of recordsWithOfflineInfo) {
                        if (record['id'] === recordIdFromRequest && record['breadcrumbs'] && record['breadcrumbs'].length) {
                            breadcrumbs = record['breadcrumbs'];
                        }
                    }
                    GenericDialogDelegate.breadCrumb = [...breadcrumbs];
                }
                else if (request.isPostWorkbenchActionPath()) {
                    /**
                     * Clearing the breadcrumbs first if it is workBench call, expecting the Action paths needed for Replay starts from Workbench action
                     */
                    GenericDialogDelegate.breadCrumb = [];
                    bCrumbObj.dialogId = response.value.id;
                    const pathFields = request.deconstructPostWorkbenchActionPath();
                    bCrumbObj.breadcrumb.type = 'wbAction';
                    bCrumbObj.breadcrumb.wbId = pathFields.workbenchId;
                    bCrumbObj.breadcrumb.actionId = pathFields.actionId;
                    GenericDialogDelegate.breadCrumb.push(bCrumbObj);
                }
                else if (request.isPostMenuActionPath()) {
                    bCrumbObj.dialogId = response.value.id;
                    const pathFields = request.deconstructPostMenuActionPath();
                    if (pathFields.dialogId !== 'briefcase_comments') {
                        bCrumbObj.breadcrumb.type = 'menuAction';
                        bCrumbObj.breadcrumb.actionId = pathFields.actionId;
                        const recordRes = yield DialogProxyTools.readRecordSetAsVisitor(this.delegateUserId(), request);
                        bCrumbObj.breadcrumb.persistentId = response.value.persistentId;
                        if (recordRes) {
                            bCrumbObj.breadcrumb.persistentId = recordRes.enclosedJsonObject().persistentId;
                        }
                        const rBody = request.body();
                        if (rBody.targets) {
                            if (rBody.targets.length > 0) {
                                bCrumbObj.breadcrumb.recordId = rBody.targets;
                            }
                            else {
                                /**
                                 * when action is performed from menu bar and it doesnt have traget id.
                                 * we are fetching the first record in the list downloaded as target record.
                                 */
                                const targets = yield DialogProxyTools.readMenuActionRedirectionAsOfflineResponse(this.delegateUserId(), request);
                                bCrumbObj.breadcrumb.recordId = [targets.value['recordId']];
                            }
                        }
                        if (bCrumbObj.breadcrumb.persistentId) {
                            GenericDialogDelegate.breadCrumb.push(bCrumbObj);
                        }
                    }
                }
            }
        });
    }
    updateBreadCrumbs(request, isExplicitDialogDestroy = false, response = null) {
        if (request.isDeleteDialogPath() && isExplicitDialogDestroy) {
            const pathFields = request.deconstructDeleteDialogPath();
            const index = GenericDialogDelegate.breadCrumb.findIndex(breadcrumb => breadcrumb.dialogId === pathFields.dialogId);
            GenericDialogDelegate.breadCrumb = index !== -1 ? GenericDialogDelegate.breadCrumb.slice(0, index) : GenericDialogDelegate.breadCrumb;
        }
    }
    explicitAddBreadCrumb(request, response) {
        if ((request.isPostWorkbenchActionPath() || request.isPostMenuActionPath()) && response.value.persistentId) {
            if (response.value.dialogAlias) {
                if (!response.value.dialogAlias.startsWith("Briefcase")) {
                    GenericDialogDelegate.explicitBreadCrumb.push(response.value.persistentId);
                    GenericDialogDelegate.explicitBreadCrumbDialogIds.push(response.value.dialogId);
                    GenericDialogDelegate.explicitBreadCrumbAdded = true;
                }
            }
            else {
                GenericDialogDelegate.explicitBreadCrumb.push(response.value.persistentId);
                GenericDialogDelegate.explicitBreadCrumbDialogIds.push(response.value.dialogId);
                GenericDialogDelegate.explicitBreadCrumbAdded = true;
            }
        }
    }
    explicitUpdateBreadCrumb(request, response = {}) {
        const deleteDialogId = request.resourcePathElems().length >= 6 ? request.resourcePathElems()[5] : '';
        const index = GenericDialogDelegate.explicitBreadCrumbDialogIds.findIndex(dialogId => dialogId === deleteDialogId);
        if (index !== -1) {
            if (GenericDialogDelegate.explicitBreadCrumbDialogIds.length === GenericDialogDelegate.explicitBreadCrumb.length) {
                GenericDialogDelegate.explicitBreadCrumbDialogIds = GenericDialogDelegate.explicitBreadCrumbDialogIds.slice(0, index);
                GenericDialogDelegate.explicitBreadCrumb.pop();
            }
            else if (GenericDialogDelegate.explicitBreadCrumbDialogIds.length > GenericDialogDelegate.explicitBreadCrumb.length) {
                GenericDialogDelegate.explicitBreadCrumbDialogIds = GenericDialogDelegate.explicitBreadCrumbDialogIds.slice(0, index);
            }
        }
    }
    resetBriefCaseEditAction() {
        GenericDialogDelegate.briefCaseEditAction = {
            isBriefCaseAction: false,
            request: [],
            index: -1
        };
    }
}
GenericDialogDelegate.propertyTimestamp = '';
GenericDialogDelegate.getJsonCallCount = 0;
GenericDialogDelegate.briefCaseEditAction = {
    isBriefCaseAction: false,
    request: [],
    index: -1
};
GenericDialogDelegate.crawlCancel = false;
GenericDialogDelegate.promisesList = [];
GenericDialogDelegate.crawlingRedirection = null;
GenericDialogDelegate.crawlingRedirectionStatus = null;
GenericDialogDelegate.newCrawlCopy = 'copy';
GenericDialogDelegate.breadCrumb = [];
GenericDialogDelegate.explicitBreadCrumb = [];
GenericDialogDelegate.explicitBreadCrumbDialogIds = [];
GenericDialogDelegate.explicitBreadCrumbAdded = false;
GenericDialogDelegate.breadCrumbObject = {
    dialogId: '',
    breadcrumb: {
        type: '',
        wbId: '',
        actionId: '',
        persistentId: '',
        recordId: [],
    }
};
