import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'clsx';
import TableMUI from '@material-ui/core/Table';
import withStyles from '@material-ui/styles/withStyles';
import { RefType } from '@devexpress/dx-react-core';

const styles = theme => ( {
    table: {
        tableLayout: 'fixed',
        borderCollapse: 'separate',
        width: '-webkit-fill-available',
        '& thead th': {
            position: 'sticky',
            fallbacks: {
                position: '-webkit-sticky'
            },
        },
        '& thead th:hover div:nth-child(2) > div:first-child': {
            opacity: 0,
        },
    },
    stickyTable: {
        position: 'sticky',
        overflow: 'visible',
    },
    headTable: {
        top: 0
    },
    footTable: {
        bottom: 0
    }
});

// Reference article: https://github.com/DevExpress/devextreme-reactive/issues/896
class TableBase extends PureComponent {
    static propTypes = {
        use: PropTypes.oneOf(['head', 'foot']),
        children: PropTypes.node.isRequired,
        classes: PropTypes.object.isRequired,
        className: PropTypes.string,
        tableRef: RefType.isRequired
    };
    
    static defaultProps = {
        use: undefined,
        className: undefined
    };

    render() {
        const {
            children,
            classes,
            className,
            use,
            tableRef,
            ...restProps } = this.props;

        return (
            <TableMUI
                ref={ tableRef }
                stickyHeader
                aria-label="sticky table"
                className={classNames(
                    {
                        [classes.table]: true,
                        [classes.stickyTable]: !!use,
                        [classes.headTable]: use === 'head',
                        [classes.footTable]: use === 'foot'
                    },
                    className
                )}
                {...restProps}
            >
                {children}
            </TableMUI>
        )
    }
};

export const StickyTable = withStyles(styles, { name: 'Table' })(TableBase);
