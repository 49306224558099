import { rootStore } from 'cv-react-core';

const getTheme = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    // Populate the component parts with theme styles
    return {
        container: {
        },

        launcher: {
            backgroundColor: theme.workbenchLauncherTileBackgroundColor,
            borderRadius: theme.workbenchLauncherTileBorderRadius,
        },

        launcherActionAreaContainer: {
            backgroundColor: theme.workbenchLauncherIconBackgroundColor,
        },

        launcherContentContainer: {
            backgroundColor: theme.workbenchLauncherTextBackgroundColor,
        },

        launcherIcon: {
        },

        launcherIconContainer: {
        },

        launcherIconLoadingIndicator: {
            color: theme.statusLoadingIndicatorColor,
        },

        launcherText: {
            ...theme.fonts.workbenchLauncher,
            fontFamily: 'Roboto',
        },

        launcherTextContainer: {
        },
    };
};

export default getTheme;
