import { TypeNames } from 'cv-dialog-sdk';
import { utilities } from '../utilities';

const { annotationHelper, uiHelper, styleHelper } = utilities;

/**
 * @typedef PropDef
 * @property {Boolean} isLargePropertyType
 * @property {Boolean} isSignatureType
 * @property {Boolean} isURLType
 */

/**
 * A base builder to convert dialog data to component properties
 */
class BaseComponentBuilder {
    static annotationHelper = annotationHelper;
    static styleHelper = styleHelper;
    static typeNames = TypeNames;
    static uiHelper = uiHelper;

    constructor() {
        this.props = {};
        this.data = {};
    }


    /**
     * DIALOG BASED PROPERTIES
     */

    setReadMode(isReadMode = true) {
        this.data.isReadMode = !!isReadMode;
        return this;
    }
    getReadMode() {
        return this.data.isReadMode;
    }

    setProperty(property) {
        this.data.property = property;
        return this;
    }
    getProperty() {
        return this.data.property || {};
    }


    /**
     * @param {PropDef} propDef
     * @returns {BaseComponentBuilder}
     */
    setPropDef(propDef) {
        this.data.propDef = propDef;
        return this;
    }

    /**
     * @returns {Object|PropDef}
     */
    getPropDef() {
        return this.data.propDef || {};
    }

    setRecord(record) {
        this.data.record = record;
        return this;
    }
    getRecord() {
        return this.data.record || {};
    }

    setRecords(records) {
        this.data.records = records;
        return this;
    }
    getRecords() {
        return this.data.records || [];
    }

    setViewDef(viewDef) {
        this.data.viewDef = viewDef;
        return this;
    }
    getViewDef() {
        return this.data.viewDef || {};
    }

    setViewType(viewType) {
        if (typeof viewType === 'string') {
            this.data.viewType = viewType;
        }
        return this;
    }
    getViewType() {
        return this.data.viewType;
    }

    setOrientation(orientation) {
        this.data.orientation = orientation;
        return this;
    }
    getOrientation() {
        return this.data.orientation;
    }

    setCompactState(isCompactView = true) {
        this.data.isCompactView = !!isCompactView;
        return this;
    }
    getCompactState() {
        return this.data.isCompactView;
    }

    setEnabledAnnotations(mode) {
        if (typeof mode === 'string') {
            this.data.enabledAnnotations = mode;
        }
        return this;
    }
    getEnabledAnnotations() {
        return this.data.enabledAnnotations;
    }

    setFocusedState(isFocused = true) {
        this.data.isFocused = !!isFocused;
        return this;
    }
    getFocusedState() {
        return this.data.isFocused;
    }

    getAsyncDataCallback() {
        return this.data.asyncDataCallback;
    }

    setOnValueChangeHandler(onValueChangeHandler) {
        if (typeof onValueChangeHandler === 'function') {
            this.data.onValueChangeHandler = onValueChangeHandler;
        }
        return this;
    }
    getOnValueChangeHandler() {
        return this.data.onValueChangeHandler;
    }

    setAvailableValuesLoader(valuesGetter) {
        if (typeof valuesGetter === 'function') {
            this.data.availableValuesLoader = valuesGetter;
        }
        return this;
    }
    getAvailableValuesLoader() {
        return this.data.availableValuesLoader;
    }

    setAvailableValues(values) {
        this.data.availableValues = values;
        return this;
    }

    getAvailableValues() {
        return this.data.availableValues;
    }

    setActionOnPressHandler(onPressHandler) {
        if (typeof onPressHandler === 'function') {
            this.data.actionOnPressHandler = onPressHandler;
        }
        return this;
    }
    getActionOnPressHandler() {
        return this.data.actionOnPressHandler;
    }

    setActionData(actionData) {
        this.data.actionData = actionData;
        return this;
    }
    getActionData() {
        return this.data.actionData || {};
    }

    setStagedValue(stagedValue) {
        this.data.stagedValue = stagedValue;
        return this;
    }
    getStagedValue() {
        return this.data.stagedValue;
    }

    setAutoGrow(autoGrow = true) {
        this.data.autoGrow = !!autoGrow;
        return this;
    }
    getAutoGrow() {
        return this.data.autoGrow;
    }

    setTabSequenceArray(componentArray) {
        if (Array.isArray(componentArray)) {
            this.data.tabSequenceArray = componentArray;
        }
        return this;
    }
    getTabSequenceArray() {
        return this.data.tabSequenceArray;
    }

    setTabSequenceHandler(sequenceHandler) {
        if (typeof sequenceHandler === 'function') {
            this.data.tabSequenceHandler = sequenceHandler;
        }
        return this;
    }
    getTabSequenceHandler() {
        return this.data.tabSequenceHandler;
    }

    setRefHandler(refHandler) {
        if (typeof refHandler === 'function') {
            this.data.refHandler = refHandler;
        }
        return this;
    }
    getRefHandler() {
        return this.data.refHandler;
    }

    setData(data) {
        if (data && typeof data === 'object') {
            this.data = data;
        }
        return this;
    }
    getData() {
        return this.data;
    }

    setDialogProps(dialogProps) {
        if (typeof dialogProps === 'object') {
            this.dialogProps = { ...dialogProps };
            this.setData({ ...this.getData(), ...dialogProps });
        }
        return this;
    }
    getDialogProps() {
        return this.dialogProps;
    }


    /**
     * COMPONENT BASED PROPERTIES
     */

    setKey(key) {
        if (key) {
            this.props.key = key;
        }
        return this;
    }
    getKey() {
        return this.props.key;
    }

    setStyle(style) {
        if (style && typeof style === 'object') {
            this.props.style = style;
        }
        return this;
    }
    getStyle() {
        return this.props.style || {};
    }

    setContextStyles(contextStyles) {
        if (contextStyles && typeof contextStyles === 'object') {
            this.props.contextStyles = contextStyles;
        }
        return this;
    }
    getContextStyles() {
        return this.props.contextStyles || {};
    }

    setContainerStyles(containerStyles) {
        this.setContextStyles({ ...this.getContextStyles(), container: containerStyles });
    }
    getContainerStyles() {
        const { container } = this.getContextStyles();
        return container || {};
    }

    setToolTip(toolTip) {
        if (toolTip) {
            this.props.toolTip = toolTip;
        }
        return this;
    }

    setProps(props) {
        if (props && typeof props === 'object') {
            this.props = { ...props };
        }
        return this;
    }
    getProps() {
        return this.props;
    }

    /**
     * Leading zeroes from color picker are ignored in Extender making them as invalid colors causing the charts to render in black.
     * For example: #06fa08 is received as #6fa08
     * This is a temporary workaround to add missing leading zeroes to the color.
     */
    getColor(color, size = 6) {
        if (color) {
            let colorCode = color.split('#')[1];
            while (colorCode.length < size) colorCode = `0${colorCode}`;
            return `#${colorCode}`;
        }
        return undefined;
    }

    /**
     * Helper method to get text value for text field.
     * Moving this over here so that both XHA and XNA can use it.
     * Once we have props factory in core, we can move this to the respective props factory.
     * @param {Object} stagedValue
     * @param {Object} property
     * @param {Object} propDef
     * @returns {string}
     */
    resolveTextValue(stagedValue, property, propDef) {
        // Initialize component value
        let textValue = '';

        // Set supplied value if valid
        if (stagedValue && typeof stagedValue.value === 'string') {
            textValue = stagedValue.value;
        } else {
            // Set value from record
            textValue = BaseComponentBuilder.uiHelper.formatPropertyForWrite(property, propDef);
        }

        // Return text
        return textValue;
    }

    /**
     * Helper method to get text value for boolean field.
     * Moving this over here so that both XHA and XNA can use it.
     * Once we have props factory in core, we can move this to the respective props factory.
     * @param {Object} stagedValue
     * @param {Object} property
     * @param {Object} propDef
     * @returns {boolean}
     */
    resolveBooleanValue(stagedValue, property, propDef) {
        let booleanValue = false;

        // Staging values are only populated when the user interacts with a write-enabled control
        // If user has changed value (not null/undefined)
        if (stagedValue && typeof stagedValue.value === 'boolean') {
            // Persist the user changed value on render/refresh
            booleanValue = stagedValue.value;
        } else {
            // Value has not been changed, use value from data on render/refresh
            booleanValue = BaseComponentBuilder.uiHelper.parse(property, propDef);
        }

        // Return boolean
        return booleanValue;
    }

    /**
     * Helper method to extract image url from coderef description.
     * We have to split it on the first occurence of : in order to get url and text.
     * This is a temporary workaround untill we have IconChooserRef property.
     * @param {String} value
     * @returns {Array}
     */
    getImageSrc(value = '') {
        if (typeof value === 'string') {
            const [
                imageName,
                imageSrc,
            ] = value.split(/:(.*)/);
    
            return [ imageName, imageSrc ];
        }
        return value;
    }

    /**
     * OVERRIDES BY INDIVIDUAL BUILDERS
     */

    buildProps() {}
    build() {}
}

export default BaseComponentBuilder;
