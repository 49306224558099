/* eslint-disable no-param-reassign */

import { toJS } from 'mobx';

/**
 * Logic to verify whether the datapoint satisfy's the filter option
 * @param {object} filter
 * @param {Array} chartFilteredItem
 * @param {} fromDateIndex
 * @param {*} endDateIndex
 */
const isDateTypeFilterDPAvailable = (filter, chartFilteredItem, fromDateIndex, endDateIndex) => {
    if (fromDateIndex !== -1 && endDateIndex !== -1) {
        if (new Date(filter.code) < new Date(chartFilteredItem[fromDateIndex].code) || new Date(chartFilteredItem[endDateIndex].code) < new Date(filter.code)) {
            return false;
        }
    } else if (fromDateIndex !== -1 && new Date(filter.code) < new Date(chartFilteredItem[fromDateIndex].code)) {
        return false;
    } else if (endDateIndex !== -1 && new Date(chartFilteredItem[endDateIndex].code) < new Date(filter.code)) {
        return false;
    }
    return true;
};

/**
 * Logic to verify whether the datapoint satisfy's the filter option
 * @param {object} filter
 * @param {Array} chartFilteredItem
 * @param {*} rangeIndex
 */
const isRangeDPAvailable = (filter, chartFilteredItem, rangeIndex) => {
    if (rangeIndex !== -1) {
        const value = toJS(chartFilteredItem[rangeIndex].code);
        if (filter.code < value[0] || value[1] < filter.code) {
            return false;
        }
    }
    return true;
};

/**
 * Handles the logic of filter the dataPoints and update filterItems Array
 * @param {*} filterProp
 * @param {*} chartFilteredItem
 * @param {*} propDefType
 * @param {*} filterArray
 */
const getCodeRefFilterDP = (filterProp, chartFilteredItem, propDefType, filterArray) => {
    const filter = {
        code: filterProp.value.code,
        description: filterProp.value.description,
        checked: true,
        type: propDefType.format,
    };
    const chartItem = chartFilteredItem.find(item => item.code === filter.code); // check

    if (chartItem && !chartItem.checked) {
        filter.checked = chartItem.checked;
        filterArray.push(filter);
        return {
            isUndefined: true,
            filterArray,
        };
    }
    filterArray.push(filter);
    return {
        isUndefined: false,
        filterArray,
    };
};

/**
 * Handles the logic of updating the min and max values
 * @param {*} min
 * @param {*} max
 * @param {*} value
 * @param {*} minValue
 * @param {*} maxValue
 */
const updateMinMaxValues = (min, max, value, minValue = min, maxValue = max) => {
    if (min === '') {
        min = value;
    } else if (minValue < value) {
        if (max === '' || (value > maxValue)) {
            max = value;
        }
    } else {
        if (max === '') {
            max = min;
        }
        min = value;
    }
    return { min, max };
};

const retrievedMatchedIndices = (chartFilteredItem = []) => {
    const matchedIndices = { fromDateIndex: -1, endDateIndex: -1, rangeIndex: -1 };
    chartFilteredItem.forEach((item, index) => {
        if (item.description === 'fromDate') {
            matchedIndices.fromDateIndex = index;
        } else if (item.description === 'endDate') {
            matchedIndices.endDateIndex = index;
        } else if (item.description === 'range') {
            matchedIndices.rangeIndex = index;
        }
    });
    return matchedIndices;
};

// Search the record properties array for the filter property
const getFilteredItems = (filterProp, filterProperty, properties, propDefs, filterItems, chartFilteredItem, fromDateIndex, endDateIndex, rangeIndex) => {
    let isFilterUndefined = false;
    if (filterProperty && filterProperty[0] && filterProperty[0].propertyName) {
        filterProp = properties.find((property) => {
            const { name } = property;

            // Get the property with the data point property name
            return name === filterProperty[0].propertyName;
        });

        if (filterProp) {
            const propDefType = propDefs.find((def) => def.propertyName === filterProperty[0].propertyName);
            filterItems.type = propDefType.format;
            if (propDefType.isCodeRefType) {
                const { isUndefined, filterArray } = getCodeRefFilterDP(filterProp, chartFilteredItem, propDefType, filterItems.filterArray);
                filterItems.filterArray = filterArray;
                isFilterUndefined = isUndefined;
            } else if (propDefType.isDecimalType || propDefType.isIntType) {
                const filter = {
                    code: filterProp.value,
                    description: filterProp.value,
                    checked: true,
                    type: propDefType.format,
                };
                const { min, max } = updateMinMaxValues(filterItems.minRange, filterItems.maxRange, filterProp.value);
                filterItems.minRange = min;
                filterItems.maxRange = max;

                if (!isRangeDPAvailable(filter, chartFilteredItem, rangeIndex)) {
                    isFilterUndefined = true;
                }
            } else if (propDefType.isDateType) {
                const filter = {
                    code: filterProp.value,
                    description: filterProp.value,
                    checked: true,
                    type: propDefType.format,
                };
                const { min, max } = updateMinMaxValues(filterItems.minDate, filterItems.maxDate, new Date(filterProp.value), new Date(filterItems.minDate), new Date(filterItems.maxDate));
                filterItems.minDate = min;
                filterItems.maxDate = max;

                if (!isDateTypeFilterDPAvailable(filter, chartFilteredItem, fromDateIndex, endDateIndex)) {
                    isFilterUndefined = true;
                } else {
                    filterItems.filterArray.push(filter);
                }
            }
        }
    }

    return {
        isUndefined: isFilterUndefined,
        filteredItems: filterItems,
        filterPropItem: filterProp,
    };
};

const updateChartFilterProps = (chartProps, filterProperty, filterItems, rangeValue = [], chartFilteredItem) => {
    let filterArray;
    if (filterProperty && filterProperty[0] && filterProperty[0].propertyName) {
        if (filterItems.type === 'code-ref') {
            filterArray = filterItems.filterArray.filter((v, i, a) => a.findIndex(t => (t.code === v.code)) === i);
        } else if (filterItems.type === 'date') {
            const filterIndex = retrievedMatchedIndices(chartFilteredItem);
            filterArray = [ {
                code: (filterIndex && filterIndex.fromDateIndex > -1) ? chartFilteredItem[filterIndex.fromDateIndex].code : filterItems.minDate,
                description: 'fromDate',
                checked: true,
                type: 'date',
            }, {
                code: (filterIndex && filterIndex.endDateIndex > -1) ? chartFilteredItem[filterIndex.endDateIndex].code : filterItems.maxDate,
                description: 'endDate',
                checked: true,
                type: 'date',
            } ];
        } else {
            const resultValue = toJS(rangeValue);
            filterArray = [ {
                code: [ Number(resultValue[0]), Number(resultValue[1]) ],
                description: 'range',
                checked: true,
                type: 'decimal',
                minRange: Number(filterItems.minRange),
                maxRange: Number(filterItems.maxRange),
            } ];
        }
        chartProps.filterable = true;
        chartProps.filterArray = filterArray;
    }

    return chartProps;
};

const chartHelper = {
    getCodeRefFilterDP,
    getFilteredItems,
    isDateTypeFilterDPAvailable,
    retrievedMatchedIndices,
    updateChartFilterProps,
    updateMinMaxValues,
};

export default chartHelper;
