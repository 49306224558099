import React, { Component } from 'react';
import * as PropTypes from 'prop-types';

import TextLabel from '../TextLabel/TextLabel';
import TextField from '../TextField/TextField';
import Button from '../Button/Button';
import XaltCheckbox from '../../xaltComponents/XaltCheckBox/XaltCheckBox';
import getStyles from './SettingsForm.styles';

class SettingsForm extends Component {
    render() {
        const {
            cancelBtnText,
            contextStyles,
            errors,
            isSaving,
            onCancelPress,
            onSavePress,
            onTenantChange,
            saveBtnText,
            server,
            settingsLabel,
            showToggleServer,
            tenant,
            tenantIdLabel,
            useServer,
        } = this.props;
        const styles = getStyles(contextStyles, !!errors);
        const disableButtons = isSaving || !tenant || !tenant.trim() || (useServer && !server);

        return (
            <div style={ styles.container }>
                <TextLabel
                    contextStyles={ {
                        text: styles.heading,
                    } }>
                    { settingsLabel }
                </TextLabel>
                {
                    errors && (
                        <TextLabel
                            contextStyles={ {
                                container: styles.error,
                                text: styles.errorText,
                            } }>
                            { errors }
                        </TextLabel>
                    )
                }
                <TextField
                    autoFocus
                    contextStyles={ {
                        container: styles.tenantTextField,
                        focused: styles.input,
                        hoover: styles.input,
                        input: styles.input,
                    } }
                    label={ tenantIdLabel }
                    onChange={ onTenantChange }
                    onSubmit={ onSavePress }
                    value={ tenant }
                    variant="outlined" />
                { showToggleServer && this.renderAdvancedSettings(styles) }
                <div style={ styles.footer }>
                    <Button
                        contextStyles={ {
                            container: styles.button,
                            primary: styles.primaryButton,
                            primaryHover: styles.primaryButtonHover,
                            primaryText: styles.primaryButtonText,
                            text: styles.buttonText,
                        } }
                        disabled={ disableButtons }
                        onClick={ onSavePress }
                        text={ saveBtnText }
                        type="submit" />
                    <Button
                        contextStyles={ {
                            container: styles.button,
                            secondary: { width: '100%', ...styles.secondaryButton },
                            secondaryHover: styles.secondaryButtonHover,
                            secondaryText: styles.secondaryButtonText,
                            text: styles.buttonText,
                        } }
                        disabled={ disableButtons }
                        onClick={ onCancelPress }
                        text={ cancelBtnText }
                        variant="outlined" />
                </div>
            </div>
        );
    }

    renderAdvancedSettings = (styles) => {
        const {
            advancedSettingsLabel,
            clearCacheBtnText,
            clearCacheText,
            onClearCache,
            onServerChange,
            onUseServerChange,
            server,
            serverURLLabelText,
            useServer,
            useDebugServerLabel,
        } = this.props;
        return (
            <>
                <TextLabel
                    contextStyles={ {
                        text: styles.advancedSettingsTitle,
                    } }>
                    { advancedSettingsLabel }
                </TextLabel>
                <div style={ styles.useServerContainer }>
                    <XaltCheckbox
                        checked={ useServer }
                        onValueChanged={ onUseServerChange }
                        style={ { padding: '0px' } } />
                    <TextLabel
                        contextStyles={ {
                            text: styles.textLabel,
                        } }>
                        { useDebugServerLabel }
                    </TextLabel>
                </div>
                <TextField
                    contextStyles={ {
                        container: styles.textField,
                        focused: styles.input,
                        hoover: styles.input,
                        input: styles.input,
                    } }
                    disabled={ !useServer }
                    label={ serverURLLabelText }
                    onChange={ onServerChange }
                    value={ server }
                    variant="outlined" />
                <TextLabel
                    contextStyles={ {
                        text: styles.clearCacheText,
                    } }>
                    { clearCacheText }
                </TextLabel>
                <Button
                    contextStyles={ {
                        container: styles.clearCacheButton,
                        secondary: styles.secondaryButton,
                        secondaryHover: styles.secondaryButtonHover,
                        secondaryText: styles.secondaryButtonText,
                        text: styles.buttonText,
                    } }
                    onClick={ onClearCache }
                    text={ clearCacheBtnText }
                    variant="outlined" />
            </>
        );
    }
};

SettingsForm.propTypes = {
    advancedSettingsLabel: PropTypes.string,
    cancelBtnText: PropTypes.string,
    clearCacheBtnText: PropTypes.string,
    clearCacheText: PropTypes.string,
    contextStyles: PropTypes.shape({
        button: PropTypes.object,
        buttonText: PropTypes.object,
        container: PropTypes.object,
        error: PropTypes.object,
        heading: PropTypes.object,
        primaryButton: PropTypes.object,
        primaryButtonHover: PropTypes.object,
        primaryButtonText: PropTypes.object,
        secondaryButton: PropTypes.object,
        secondaryButtonHover: PropTypes.object,
        secondaryButtonText: PropTypes.object,
        textField: PropTypes.object,
        textLabel: PropTypes.object,
        useServerContainer: PropTypes.object,
    }),
    errors: PropTypes.string,
    isSaving: PropTypes.bool,
    showToggleServer: PropTypes.bool,
    onCancelPress: PropTypes.func.isRequired,
    onSavePress: PropTypes.func.isRequired,
    onServerChange: PropTypes.func.isRequired,
    onTenantChange: PropTypes.func.isRequired,
    onUseServerChange: PropTypes.func.isRequired,
    saveBtnText: PropTypes.string,
    settingsLabel: PropTypes.string,
    server: PropTypes.string.isRequired,
    serverURLLabelText: PropTypes.string,
    tenant: PropTypes.string.isRequired,
    tenantIdLabel: PropTypes.string,
    useServer: PropTypes.bool.isRequired,
    onClearCache: PropTypes.func,
    useDebugServerLabel: PropTypes.string,
};

SettingsForm.defaultProps = {
    advancedSettingsLabel: 'Advanced Settings',
    cancelBtnText: 'Cancel',
    clearCacheBtnText: 'Clear Cache',
    clearCacheText: 'Delete cached Xalt Moblity data from your browser.',
    contextStyles: {},
    saveBtnText: 'Save',
    settingsLabel: 'Settings',
    serverURLLabelText: 'Server URL',
    tenantIdLabel: 'Tenant ID',
    useDebugServerLabel: 'Use Server',
};

export default SettingsForm;
