// import { rootStore } from 'cv-react-core';

const getStyles = ( /* index */ ) => {
    // const { themeStore } = rootStore;
    // const theme = themeStore.getSanitizedTheme();
    // const colors = theme.colors.listAlternatingColors.split(',').map((m) => m.trim());
    // let backgroundColor = theme.listUnderlayColor;
    // if (colors.length) {
    //     const colorIndex = index % colors.length;
    //     backgroundColor = colors[colorIndex];
    // }
   return {
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: '5px',
            paddingBottom: '5px',
            // backgroundColor,
        },
        anchor: {
            display: 'flex',
            flex: 1,
            position: 'relative',
            padding: '10px',
        },
        buttons: {
            container: {
                paddingLeft: '5px',
            },
            icon: {
                fontSize: '18px',
                color: 'rgba(0, 0, 0, 0.1)',
            },
        },
        component: {
            display: 'flex',
            width: '210px',
            height: '40px',
            minHeight: '40px',
            borderWidth: 0.998314,
            borderStyle: 'solid',
            borderColor: 'rgba(0, 0, 0, 0.1)',
            boxSizing: 'border-box',
            borderRadius: '4px',
            background: '#ffffff',
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '20px',
            alignItems: 'center',
            letterSpacing: '0.02em',
            color: '#646464',
            position: 'relative',
            textIndent: '10px',
        },
        dropdown: {
            paddingLeft: 8,
            paddingRight: 8,
            paddingBottom: 8,
            paddingTop: 8,
            textOverflow: 'ellipsis',
            flexGrow: 1,
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: 16,
            alignItems: 'center',
            letterSpacing: 0.02,
            color: '#121212',
            lineHeight: 'normal',
        },
        dropdownBox: {
            display: 'flex',
            alignItems: 'center',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: '#E0E0E0',
            boxSizing: 'border-box',
            borderRadius: '4px',
            marginRight: '12px',
            height: '44px',
            width: '210px',
        },
       dropDownMultiSelectField: {
           display: 'flex',
           alignItems: 'center',
           borderWidth: 1,
           borderStyle: 'solid',
           borderColor: '#E0E0E0',
           boxSizing: 'border-box',
           borderRadius: '4px',
           marginRight: '12px',
           minHeight: '44px',
           width: '210px',
       },
        headingLabel: {
            color: 'rgba(0, 0, 0, 0.6)',
            position: 'relative',
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '12px',
            lineHeight: '14px',
            textAlign: 'center',
            letterSpacing: '0.01em',
        },
        option: {
            flexGrow: 1,
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 16,
            alignItems: 'center',
            letterSpacing: 0.02,
            color: '#121212',
            paddingLeft: 8,
            paddingRight: 8,
            borderStyle: 'solid',
            borderColor: 'rgb(224, 224, 224)',
            width: 180,
            borderWidth: 0,
            borderRadius: 0,
            marginRight: 0,
            display: 'flex',
            flexDirection: 'row',
            paddingTop: 13,
            paddingBottom: 13,
        },
        expandIcon: {
            color: '#000000',
        },
        optionSelected: {
            backgroundColor: 'rgba(28, 142, 169, 0.2)',
        },
        optionHover: {
            backgroundColor: '#F5F5F5',
        },
        dropdownMultiSelectInput: {
            paddingLeft: 8,
            paddingRight: 16,
            paddingBottom: 8,
            paddingTop: 8,
            textOverflow: 'ellipsis',
            flexGrow: 1,
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: 16,
            alignItems: 'center',
            letterSpacing: 0.02,
            color: '#121212',
            lineHeight: 'normal',
        },
    };
};

export default getStyles;
