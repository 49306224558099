/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import componentFactory from '../componentFactory';
import componentPropTypes from './abstractComponentPropTypes';

// Note: these should be a subset of abstractComponentPropTypes
const propDefinitions = {
    style: componentPropTypes.style,
    value: componentPropTypes.value,
    tipText: componentPropTypes.tipText,
    isReadMode: componentPropTypes.isReadMode,
    isFocused: componentPropTypes.isFocused,
    onValueChanged: componentPropTypes.onValueChanged,
    xStyle: componentPropTypes.xStyle,
    asyncDataCallback: componentPropTypes.asyncDataCallback,
};

// this class should handle paper forms editable images
export default class PaperImage extends Component {
    static propTypes = propDefinitions;

    render() {
        const {
            style,
            tipText,
            isReadMode,
            isFocused,
            onValueChanged,
            xStyle,
        } = this.props;

        const imageProps = {
            style,
            tipText,
            isReadMode,
            isFocused,
            onValueChanged,
            xStyle,
        };
        return React.createElement(componentFactory.getPlatformPropertyComponent('imagePicker'), { ...imageProps });
    }

}
