var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { DialogProxyTools } from "../proxy/DialogProxyTools";
import { RecordSetVisitor } from "../proxy/RecordSetVisitor";
import { SessionVisitor } from "../proxy/SessionVisitor";
import { storage as clientStorage } from "../storage";
import { DownloadManagerVisitor } from "./DownloadManagerVisitor";
import { GenericDialogDelegateStateVisitor } from "./GenericDialogDelegateStateVisitor";
import { Download_Manager_Details_RECORD } from "./samples/Download_Manager_Details_RECORD";
// @FIX_THIS
// remove this:
const storage = clientStorage;
// uncomment this
// const storage = clientStorage.getSecureInstance();
// Set the secret key like this:
// storage.secretKey = 'secret_key';
export class GenericDialogDelegateTools {
    static constructAddToBriefcaseNullRedirection(tenantId, sessionId, referringDialogId) {
        const nullRedirectionId = DialogProxyTools.constructNullRedirectionId();
        return {
            "tenantId": tenantId,
            "referringObject": {
                "dialogMode": "LIST",
                "dialogAlias": "Workpackage_AddToBriefcase_FORM",
                "actionId": "alias_AddToBriefcase",
                "type": "hxgn.api.dialog.ReferringDialog",
                "dialogId": referringDialogId
            },
            "refreshNeeded": true,
            "sessionId": sessionId,
            "id": nullRedirectionId,
            "type": "hxgn.api.dialog.NullRedirection"
        };
    }
    static constructGenericListActionNullRedirection(tenantId, sessionId, referringDialogId, actionId, refreshNeeded = false) {
        const nullRedirectionId = DialogProxyTools.constructNullRedirectionId();
        return {
            "tenantId": tenantId,
            "referringObject": {
                "dialogMode": "LIST",
                "actionId": actionId,
                "type": "hxgn.api.dialog.ReferringDialog",
                "dialogId": referringDialogId
            },
            "refreshNeeded": refreshNeeded,
            "sessionId": sessionId,
            "id": nullRedirectionId,
            "type": "hxgn.api.dialog.NullRedirection"
        };
    }
    static constructParentListDialogRedirection(request, recordId, dialogId, persistentId) {
        return {
            "type": "hxgn.api.dialog.DialogRedirection",
            "dialogId": dialogId,
            "id": dialogId,
            "persistentId": persistentId,
            "referringObject": {
                "type": "hxgn.api.dialog.ReferringWorkbench",
                "workbenchId": "Briefcase Manager",
                "actionId": "open",
                "dialogProperties": {}
            },
            "refreshNeeded": false,
            "dialogMode": "READ",
            "dialogType": "hxgn.api.dialog.EditorDialog",
            "recordId": recordId,
            "sessionId": request.sessionId(),
            "tenantId": request.tenantId(),
            "viewMode": "READ"
        };
    }
    static constructBriefcaseEnterOfflineDetailsNullRedirection(tenantId, sessionId, refreshNeeded) {
        const nullRedirectionId = DialogProxyTools.constructNullRedirectionId();
        // TODO: What should a ReferringDialog contain as an action id when the action is a 'PUT viewMode'?
        return {
            "tenantId": tenantId,
            "referringObject": {
                "dialogMode": "DESTROYED",
                "dialogName": this.BRIEFCASE_ENTER_OFFLINE_DETAILS_DIALOG_NAME,
                "dialogAlias": this.BRIEFCASE_ENTER_OFFLINE_DETAILS_DIALOG_NAME,
                "type": "hxgn.api.dialog.ReferringDialog",
                "dialogId": this.DOWNLOAD_MANAGER_ENTER_OFFLINE_DETAILS_DIALOG_ID
            },
            "refreshNeeded": refreshNeeded,
            "sessionId": sessionId,
            "id": nullRedirectionId,
            "type": "hxgn.api.dialog.NullRedirection"
        };
    }
    static constructExitOfflineModeNullRedirection(tenantId, sessionId) {
        const nullRedirectionId = DialogProxyTools.constructNullRedirectionId();
        return {
            "tenantId": tenantId,
            "referringObject": {
                "dialogMode": "READ",
                "dialogAlias": "Download_Manager_Details",
                "actionId": this.EXIT_OFFLINE_MODE_MENU_ACTION_ID,
                "type": "hxgn.api.dialog.ReferringDialog",
                "dialogId": this.BRIEFCASE_DETAILS_DIALOG_ID
            },
            "refreshNeeded": true,
            "sessionId": sessionId,
            "id": nullRedirectionId,
            "type": "hxgn.api.dialog.NullRedirection"
        };
    }
    static constructOfflineLogoutResponse(sessionId) {
        return {
            "sessionId": sessionId,
            "type": this.SESSION_ID_MODEL_TYPE
        };
    }
    static constructRemoveFromBriefcaseNullRedirection(tenantId, sessionId, referringDialogId) {
        const nullRedirectionId = DialogProxyTools.constructNullRedirectionId();
        return {
            "tenantId": tenantId,
            "referringObject": {
                "dialogMode": "LIST",
                "dialogAlias": "Workpackage_RemoveFromBriefcase_FORM",
                "actionId": "alias_RemoveFromBriefcase",
                "type": "hxgn.api.dialog.ReferringDialog",
                "dialogId": referringDialogId
            },
            "refreshNeeded": true,
            "sessionId": sessionId,
            "id": nullRedirectionId,
            "type": "hxgn.api.dialog.NullRedirection"
        };
    }
    static contructCancelClickNullRedirection(tenantId, sessionId, referringDialogId) {
        return {
            "tenantId": tenantId,
            "referringObject": {
                "type": "hxgn.api.dialog.ReferringDialog",
                "dialogAlias": "",
                "dialogId": referringDialogId,
                "dialogMode": "DESTROYED",
                "dialogName": "",
                "rootDialogId": "",
                "dialogProperties": {}
            },
            "refreshNeeded": false,
            "sessionId": sessionId,
            "id": "null_redirection",
            "type": "hxgn.api.dialog.NullRedirection"
        };
    }
    static createOfflineLoginHash(tenantId, sessionId, userId, password) {
        // const source =`OFFLINE_LOGIN:${tenantId}/${sessionId}/${userId}/${password}`;
        const source = `OFFLINE_LOGIN:${tenantId}/${userId}/${password}`;
        // TODO: insert crypto hashing here
        return source;
    }
    static isBriefcaseEnterOfflineDetailsDialogId(dialogId) {
        return dialogId === this.DOWNLOAD_MANAGER_ENTER_OFFLINE_DETAILS_DIALOG_ID;
    }
    static isBriefcaseEnterOfflineDialogId(dialogId) {
        return dialogId === this.DOWNLOAD_MANAGER_ENTER_OFFLINE_ROOT_DIALOG_ID;
    }
    static isBriefcaseWorkbenchActionRequest(dialogRequest) {
        return dialogRequest.isPostWorkbenchActionPathWithActionId(GenericDialogDelegateTools.BRIEFCASE_WORKBENCH_ACTION_ID);
    }
    static isEnterOfflineModeMenuActionRequest(dialogRequest) {
        return dialogRequest.isPostMenuActionPathWithActionId(GenericDialogDelegateTools.ENTER_OFFLINE_MODE_MENU_ACTION_ID);
    }
    static isSettingsRequest(dialogRequest) {
        return dialogRequest.isPostMenuActionPathWithActionId(GenericDialogDelegateTools.SETTINGS_ACTION_ID);
    }
    static isDownloadSelectedItemsRequest(dialogRequest) {
        return dialogRequest.isPostMenuActionPathWithActionId(GenericDialogDelegateTools.DOWNLOAD_SELECTED_ITEMS_ACTION_ID);
    }
    static isClearOfflineCacheRequest(dialogRequest) {
        return dialogRequest.isPostMenuActionPathWithActionId(GenericDialogDelegateTools.CLEAR_OFFLINE_CACHE_ACTION_ID);
    }
    static isExitOfflineModeMenuActionRequest(dialogRequest) {
        return dialogRequest.isPostMenuActionPathWithActionId(GenericDialogDelegateTools.EXIT_OFFLINE_MODE_MENU_ACTION_ID);
    }
    static isExitOfflineModeAndDiscardChangesMenuActionRequest(dialogRequest) {
        return dialogRequest.isPostMenuActionPathWithActionId(GenericDialogDelegateTools.EXIT_OFFLINE_MODE_AND_DISCARD_CHANGES_MENU_ACTION_ID);
    }
    static isOfflineBriefcaseCommentsRecordSetRequest(dialogRequest) {
        return dialogRequest.isPostRecordsPathWithDialogId(this.BRIEFCASE_COMMENTS_DIALOG_ID);
    }
    static isOfflineBriefcaseDialogId(dialogId) {
        return dialogId === this.DOWNLOAD_MANAGER_ROOT_DIALOG_ID;
    }
    static isOfflineBriefcaseDetailsDialogId(dialogId) {
        return dialogId === this.BRIEFCASE_DETAILS_DIALOG_ID;
    }
    static isOfflineBriefcaseSelectedItemsDialogId(dialogId) {
        return dialogId === this.BRIEFCASE_WORK_PACKAGES_DIALOG_ID;
    }
    static isOfflineBriefcaseSelectedItemsRequest(dialogRequest) {
        if (!dialogRequest.isPostRecordsPath()) {
            return false;
        }
        const pathFields = dialogRequest.deconstructPostRecordsPath();
        return GenericDialogDelegateTools.isOfflineBriefcaseSelectedItemsDialogId(pathFields.dialogId);
    }
    static isSelectedItemsAddToBriefcaseMenuActionRequest(dialogRequest) {
        return dialogRequest.isPostMenuActionPathWithActionId(GenericDialogDelegateTools.ADD_TO_BRIEFCASE_MENU_ACTION_ID);
    }
    static isBriefcaseRecordsMenuActionRequest(dialogRequest) {
        return dialogRequest.isPostMenuActionPathWithDialogId(GenericDialogDelegateTools.DOWNLOAD_MANAGER_ROOT_DIALOG_ID);
    }
    static isSelectedItemsListRecordSet(dialogRequest, jsonObject) {
        if (!dialogRequest.isPostRecordsPath()) {
            return false;
        }
        // if (!jsonObject || !jsonObject.type || !jsonObject.dialogName) {
        if (!jsonObject || !jsonObject.type) {
            return false;
        }
        return jsonObject.type === this.RECORD_SET_MODEL_TYPE;
    }
    static isSelectedItemsRemoveFromBriefcaseMenuActionRequest(dialogRequest) {
        if (!dialogRequest.isPostMenuActionPath()) {
            return false;
        }
        const pathFields = dialogRequest.deconstructPostMenuActionPath();
        return pathFields.actionId === GenericDialogDelegateTools.REMOVE_FROM_BRIEFCASE_MENU_ACTION_ID;
    }
    static isSelectedItemsRootDialog(jsonObject) {
        if (!jsonObject || !jsonObject.type || !jsonObject.dialogName) {
            return false;
        }
        return jsonObject.type === this.EDITOR_DIALOG_MODEL_TYPE &&
            jsonObject.dialogName === this.WORK_PACKAGES_ROOT_DIALOG_NAME;
    }
    static isRootDialog(jsonObject) {
        // if (!jsonObject || !jsonObject.type || !jsonObject.dialogName) {
        if (!jsonObject || !jsonObject.type) {
            return false;
        }
        return jsonObject.type === this.EDITOR_DIALOG_MODEL_TYPE;
    }
    static insertBriefcaseMetaDataIntoSelectedItemsDialog(originalDialog) {
        const selectedItemsQueryDialog = originalDialog.children[0];
        const propertyDefs = selectedItemsQueryDialog.recordDef.propertyDefs;
        const columns = selectedItemsQueryDialog.view.columns;
        if (propertyDefs && columns) {
            propertyDefs.push({
                "writeAllowed": false,
                "propertyName": "briefcase",
                "canCauseSideEffects": false,
                "upperCaseOnly": false,
                "propertyType": "boolean",
                "type": "hxgn.api.dialog.PropertyDef",
                "writeEnabled": false
            });
            columns.push({
                "propertyName": "briefcase",
                "heading": "Briefcase",
                "type": "hxgn.api.dialog.Column"
            });
        }
        // Return original dialog WITH patches
        return originalDialog;
    }
    static insertBriefcaseMetaDataIntoExplicitQueryDialogs(originalDialog) {
        originalDialog.children.forEach(childDialog => {
            if (childDialog.type === 'hxgn.api.dialog.QueryDialog' && childDialog.offlineMode === 'EXPLICIT') {
                const selectedItemsQueryDialog = childDialog;
                const propertyDefs = selectedItemsQueryDialog.recordDef.propertyDefs;
                const columns = selectedItemsQueryDialog.view.columns;
                if (propertyDefs && columns) {
                    propertyDefs.push({
                        "writeAllowed": false,
                        "propertyName": "briefcase",
                        "canCauseSideEffects": false,
                        "upperCaseOnly": false,
                        "propertyType": "boolean",
                        "type": "hxgn.api.dialog.PropertyDef",
                        "writeEnabled": false
                    });
                    columns.push({
                        "propertyName": "briefcase",
                        "heading": "Briefcase",
                        "type": "hxgn.api.dialog.Column"
                    });
                }
            }
        });
        // Return original dialog WITH patches
        return originalDialog;
    }
    static readDialogDelegateStateKeys(tenantId) {
        return __awaiter(this, void 0, void 0, function* () {
            const keys = yield storage.getAllKeys();
            const dialogDelegateStateKeysForTenant = [];
            for (const k of keys) {
                if (k.endsWith(this.DIALOG_DELEGATE_STATE_KEY_SUFFIX) && k.includes(`.${tenantId}.`)) {
                    dialogDelegateStateKeysForTenant.push(k);
                }
            }
            return dialogDelegateStateKeysForTenant;
        });
    }
    static readDialogDelegateStateVisitor(tenantId, userId) {
        const key = this.createStorageKey(tenantId, userId, this.DIALOG_DELEGATE_STATE_KEY);
        return storage.getJson(key).then(jsonObject => {
            let jsonObjectRes = {};
            if (!jsonObject) {
                const briefcase = Download_Manager_Details_RECORD.copyOfResponse();
                DownloadManagerVisitor.visitAndSetOnline(briefcase, true);
                jsonObject = {
                    briefcase: Download_Manager_Details_RECORD.copyOfResponse(),
                    selectedItemIds: [],
                    editedRecords: RecordSetVisitor.emptyRecordSetVisitor().enclosedJsonObject(),
                    offlineContentProperties: [],
                    userId: null,
                    selectedItems: RecordSetVisitor.emptyRecordSetVisitor().enclosedJsonObject(),
                    mobileComments: RecordSetVisitor.emptyRecordSetVisitor().enclosedJsonObject()
                };
                jsonObjectRes = jsonObject;
                // Log.trace('GenericDialogDelegateTools::readDelegateState -- returning defaults: ' + JSON.stringify(jsonObject));
            }
            else {
                const jsonObjectNew = {
                    briefcase: Download_Manager_Details_RECORD.copyOfResponse(),
                    selectedItemIds: [],
                    editedRecords: RecordSetVisitor.emptyRecordSetVisitor().enclosedJsonObject(),
                    offlineContentProperties: [],
                    userId: null,
                    selectedItems: RecordSetVisitor.emptyRecordSetVisitor().enclosedJsonObject(),
                    mobileComments: RecordSetVisitor.emptyRecordSetVisitor().enclosedJsonObject()
                };
                jsonObjectRes = Object.assign(Object.assign({}, jsonObjectNew), jsonObject);
            }
            return new GenericDialogDelegateStateVisitor(jsonObjectRes);
        });
    }
    static readOfflineSession(tenantId, userId) {
        const key = this.createStorageKey(tenantId, userId, this.OFFLINE_SESSION_KEY);
        return storage.getJson(key).then(jsonObject => new SessionVisitor(jsonObject));
    }
    static startsWithBriefcaseRootDialogId(dialogId) {
        return dialogId.startsWith(this.DOWNLOAD_MANAGER_ROOT_DIALOG_ID);
    }
    static writeDialogDelegateState(tenantId, stateVisitor) {
        const userId = stateVisitor.visitUserId();
        const key = this.createStorageKey(tenantId, userId, this.DIALOG_DELEGATE_STATE_KEY);
        return storage.setJson(key, stateVisitor.enclosedJsonObject());
    }
    static writeOfflineSession(tenantId, userId, offlineSessionVisitor) {
        const key = this.createStorageKey(tenantId, userId, this.OFFLINE_SESSION_KEY);
        return storage.setJson(key, offlineSessionVisitor.enclosedJsonObject());
    }
    static createStorageKey(tenantId, userId, keyTemplate) {
        const key = keyTemplate.replace('${tenantId}', tenantId);
        return key.replace('${userId}', userId);
    }
    static isRemoveEditedRecordModeMenuActionRequest(dialogRequest) {
        return dialogRequest.isPostMenuActionPathWithActionId(GenericDialogDelegateTools.REMOVE_EDITED_RECORD_MENU_ACTION_ID);
    }
}
// Briefcase Dialog Ids
GenericDialogDelegateTools.DOWNLOAD_MANAGER_ROOT_DIALOG_ID = 'briefcase';
GenericDialogDelegateTools.BRIEFCASE_COMMENTS_DIALOG_ID = GenericDialogDelegateTools.DOWNLOAD_MANAGER_ROOT_DIALOG_ID + '_comments';
GenericDialogDelegateTools.BRIEFCASE_DETAILS_DIALOG_ID = GenericDialogDelegateTools.DOWNLOAD_MANAGER_ROOT_DIALOG_ID + '_details';
GenericDialogDelegateTools.BRIEFCASE_WORK_PACKAGES_DIALOG_ID = GenericDialogDelegateTools.DOWNLOAD_MANAGER_ROOT_DIALOG_ID + '_workPackages';
GenericDialogDelegateTools.DOWNLOAD_MANAGER_ENTER_OFFLINE_DETAILS_DIALOG_ID = GenericDialogDelegateTools.DOWNLOAD_MANAGER_ROOT_DIALOG_ID + '_enter_offline_details';
GenericDialogDelegateTools.DOWNLOAD_MANAGER_ENTER_OFFLINE_ROOT_DIALOG_ID = GenericDialogDelegateTools.DOWNLOAD_MANAGER_ROOT_DIALOG_ID + '_enter_offline';
// Dialog Names
GenericDialogDelegateTools.DOWNLOAD_MANAGER_DETAILS_DIALOG_NAME = 'Download_Manager_Details';
GenericDialogDelegateTools.DOWNLOAD_MANAGER_QUEUED_ITEMS_DIALOG_NAME = 'Download_Manager_QueuedItems';
GenericDialogDelegateTools.DOWNLOAD_MANAGER_ROOT_DIALOG_NAME = 'Download_Manager_FORM';
GenericDialogDelegateTools.DOWNLOAD_MANAGER_SELECTED_ITEMS_DIALOG_NAME = 'Download_Manager_SelectedItems';
GenericDialogDelegateTools.BRIEFCASE_ENTER_OFFLINE_DETAILS_DIALOG_NAME = 'Briefcase_EnterOfflineMode';
GenericDialogDelegateTools.MOBILE_COMMENT_IMAGE_NOT_AVAILABLE_DETAILS_DIALOG_ID = 'image_not_available_details';
GenericDialogDelegateTools.MOBILE_COMMENT_IMAGE_NOT_AVAILABLE_DETAILS_DIALOG_NAME = 'MobileComment_ImageNotAvailable';
GenericDialogDelegateTools.MOBILE_COMMENT_IMAGE_NOT_AVAILABLE_ROOT_DIALOG_ID = 'image_not_available';
GenericDialogDelegateTools.MOBILE_COMMENT_IMAGE_NOT_AVAILABLE_ROOT_DIALOG_NAME = 'MobileComment_ImageNotAvailable_FORM';
GenericDialogDelegateTools.MOBILE_COMMENT_COMMENT_NOT_AVAILABLE_DETAILS_DIALOG_ID = 'comment_not_available_details';
GenericDialogDelegateTools.MOBILE_COMMENT_COMMENT_NOT_AVAILABLE_DETAILS_DIALOG_NAME = 'MobileComment_CommentNotAvailable';
GenericDialogDelegateTools.MOBILE_COMMENT_COMMENT_NOT_AVAILABLE_ROOT_DIALOG_ID = 'comment_not_available';
GenericDialogDelegateTools.MOBILE_COMMENT_COMMENT_NOT_AVAILABLE_ROOT_DIALOG_NAME = 'MobileComment_CommentNotAvailable_FORM';
GenericDialogDelegateTools.WORK_PACKAGES_LIST_DIALOG_NAME = 'Workpackage_General';
GenericDialogDelegateTools.WORK_PACKAGES_ROOT_DIALOG_NAME = 'Workpackage_General_FORM';
// Action Ids
GenericDialogDelegateTools.ADD_TO_BRIEFCASE_MENU_ACTION_ID = 'alias_AddToBriefcase';
GenericDialogDelegateTools.BRIEFCASE_WORKBENCH_ACTION_ID = 'Briefcase';
GenericDialogDelegateTools.SETTINGS_ACTION_ID = 'settings';
GenericDialogDelegateTools.DOWNLOAD_SELECTED_ITEMS_ACTION_ID = 'downloadSelectedItems';
GenericDialogDelegateTools.CLEAR_OFFLINE_CACHE_ACTION_ID = 'clearOfflineCache';
GenericDialogDelegateTools.ENTER_OFFLINE_MODE_MENU_ACTION_ID = 'alias_EnterOfflineMode';
GenericDialogDelegateTools.EXIT_OFFLINE_MODE_MENU_ACTION_ID = 'alias_ExitOfflineMode';
GenericDialogDelegateTools.EXIT_OFFLINE_MODE_AND_DISCARD_CHANGES_MENU_ACTION_ID = 'alias_ExitOfflineModeAndDiscardChanges';
GenericDialogDelegateTools.REMOVE_FROM_BRIEFCASE_MENU_ACTION_ID = 'alias_RemoveFromBriefcase';
GenericDialogDelegateTools.REMOVE_EDITED_RECORD_MENU_ACTION_ID = 'alias_RemoveEditedRecord';
// Model Types
GenericDialogDelegateTools.EDITOR_DIALOG_MODEL_TYPE = 'hxgn.api.dialog.EditorDialog';
GenericDialogDelegateTools.RECORD_SET_MODEL_TYPE = 'hxgn.api.dialog.RecordSet';
GenericDialogDelegateTools.SESSION_ID_MODEL_TYPE = 'hxgn.api.dialog.SessionId';
// Storage Keys
GenericDialogDelegateTools.DIALOG_DELEGATE_STATE_KEY_SUFFIX = 'SdaDialogDelegate.delegatestate';
GenericDialogDelegateTools.DIALOG_DELEGATE_STATE_KEY = '${userId}.${tenantId}.' + GenericDialogDelegateTools.DIALOG_DELEGATE_STATE_KEY_SUFFIX;
GenericDialogDelegateTools.OFFLINE_SESSION_KEY = '${userId}.${tenantId}.offlinesession';
