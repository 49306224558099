export class CvLocale {
    constructor(language, country) {
        this.language = language;
        this.country = country;
    }
    get langCountryString() {
        if (this.country) {
            return `${this.language.toLowerCase()}-${this.country.toLowerCase()}`;
        }
        else {
            return this.language.toLowerCase();
        }
    }
}
