import React from 'react';
import * as PropTypes from 'prop-types';
import getStyles from './ThreeOneLeftForm.styles';
import Resizable from '../Resizable/Resizable';

const ThreeOneLeftForm = (props) => {
    const {
        contextStyles,
        formElements,
        onResizeStop,
        scrollIdentifier,
    } = props;

    const {
        anchorScrollColumn,
        mainContentRowSizingContainer,
        column,
        overflowColumn,
        rowSizingContainer,
    } = getStyles(contextStyles);

    const defaultProps = {
        props: {
            dimensions: {},
            isDetailsView: false,
        },
    };

    const {
        props: {
            viewId: viewId0,
            dimensions: dimensions0,
            isDetailsView: isDetailsView0,
        },
    } = formElements[0] || defaultProps;
    const {
        props: {
            viewId: viewId1,
            dimensions: dimensions1,
            isDetailsView: isDetailsView1,
        },
    } = formElements[1] || defaultProps;
    const {
        props: {
            viewId: viewId2,
            dimensions: dimensions2,
            isDetailsView: isDetailsView2,
        },
    } = formElements[2] || defaultProps;

    let defaultWidth0; let defaultWidth1; let
        defaultWidth2;
    if (dimensions0.width) {
        defaultWidth0 = dimensions0.width;
    }
    else {
        defaultWidth0 = isDetailsView0 ? 'max-content' : '600px';
    }

    if (dimensions1.width) {
        defaultWidth1 = dimensions1.width;
    }
    else {
        defaultWidth1 = isDetailsView1 ? 'max-content' : undefined;
    }

    if (dimensions2.width) {
        defaultWidth2 = dimensions2.width;
    }
    else {
        defaultWidth2 = isDetailsView2 ? 'max-content' : undefined;
    }

    const maxHeightForDViewFormEle1 = isDetailsView1 ? rowSizingContainer : {};
    const maxHeightForDViewFormEle2 = isDetailsView2 ? rowSizingContainer : {};

    return (
        <div
            className="anchor-column-scollar-container"
            data-scroll-identifier={ scrollIdentifier }
            style={ anchorScrollColumn }>
            <div
                className="top-row-container"
                key="content-row-container-top"
                style={ { ...mainContentRowSizingContainer } }>
                <Resizable
                    className="left-column-content"
                    defaultSize={ {
                        width: defaultWidth0,
                        height: dimensions0.height,
                    } }
                    direction="both"
                    key="left-column-content"
                    onResizeStop={ onResizeStop }
                    style={ { ...column } }
                    viewid={ viewId0 }>
                    { formElements[0] }
                </Resizable>
                { formElements.length > 1 &&
                    <div
                        className="right-column-content"
                        key="right-column-content"
                        style={ { marginLeft: '16px', ...column } }>
                        <div
                            style={ maxHeightForDViewFormEle1 }>
                            <Resizable
                                className="right-column-content-top"
                                defaultSize={ {
                                    width: defaultWidth1,
                                    height: dimensions1.height,
                                } }
                                direction="both"
                                key="right-column-content-top"
                                onResizeStop={ onResizeStop }
                                style={ { ...mainContentRowSizingContainer } }
                                viewid={ viewId1 }>
                                { formElements[1] }
                            </Resizable>
                        </div>
                        { formElements[2] &&
                            <div
                                style={ maxHeightForDViewFormEle2 }>
                                <Resizable
                                    className="right-column-content-bottom"
                                    defaultSize={ {
                                        width: defaultWidth2,
                                        height: dimensions2.height,
                                    } }
                                    direction="both"
                                    key="right-column-content-bottom"
                                    onResizeStop={ onResizeStop }
                                    style={ { marginTop: '16px', ...mainContentRowSizingContainer } }
                                    viewid={ viewId2 }>
                                    { formElements[2] }
                                </Resizable>
                            </div>
                        }
                    </div>
                }
            </div>
            { formElements.length > 3 &&
                formElements.slice(3).map((formElement) => {
                    const {
                        props: {
                            dimensions,
                            viewId,
                        },
                    } = formElement;
                    return (
                        <div
                            key={ `overflow-content-row-${viewId}` }
                            style={ { marginTop: '16px', ...rowSizingContainer, ...mainContentRowSizingContainer } }>
                            <Resizable
                                className="overflow-column-content"
                                defaultSize={ {
                                    width: dimensions.width,
                                    height: dimensions.height,
                                } }
                                direction="both"
                                key={ `left-column-content-bottom-${viewId}` }
                                onResizeStop={ onResizeStop }
                                style={ { ...overflowColumn } }
                                viewid={ viewId }>
                                { formElement }
                            </Resizable>
                        </div>
                    );
                })
            }
        </div>
    );
};

ThreeOneLeftForm.propTypes = {
    contextStyles: PropTypes.shape({
        anchorScrollColumn: PropTypes.object,
        mainContentRowSizingContainer: PropTypes.object,
        column: PropTypes.object,
        overflowColumn: PropTypes.object,
    }),
    formElements: PropTypes.arrayOf(PropTypes.element),
    scrollIdentifier: PropTypes.string,
    onResizeStop: PropTypes.func,
};

ThreeOneLeftForm.defaultProps = {
    contextStyles: {},
    formElements: [],
};

export default ThreeOneLeftForm;
