// Core component styles
const coreStyles = {
    container: {
        display: 'flex',
    },

    disabled: {
    },

    rail: {
        height: 3,
    },

    thumb: {
    },

    track: {
        height: 3,
    },

    trackContainer: {
        height: 3,
    },
};

/**
 * Combines coreStyles and contextStyles
 * @param {Object} contextStyles
 */
const getStyles = (contextStyles = {}) => {
    return {
        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        disabled: {
            ...coreStyles.disabled,
            ...contextStyles.disabled,
        },

        rail: {
            ...coreStyles.rail,
            ...contextStyles.rail,
        },

        thumb: {
            ...coreStyles.thumb,
            ...contextStyles.thumb,
        },

        track: {
            ...coreStyles.track,
            ...contextStyles.track,
        },

        trackContainer: {
            ...coreStyles.trackContainer,
            ...contextStyles.trackContainer,
        },
    };
};

export default getStyles;