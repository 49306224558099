import { Log, LogLevel } from "./Log";
const logColor = Log.orange;
export function DebugProperty(opts) {
    return function (target, key) {
        let val = target[key];
        const getter = () => {
            return createDebugProxy(Object.assign({ target: val }, opts));
        };
        const setter = (next) => {
            val = next;
        };
        Object.defineProperty(target, key, {
            get: getter,
            set: setter,
            enumerable: true,
            configurable: true,
        });
    };
}
export function DebugMethod(opts) {
    return function (target, key, descriptor) {
        descriptor.value = createDebugProxy(Object.assign({ target: descriptor.value }, opts));
        return descriptor;
    };
}
export function createDebugProxy(opts) {
    const target = opts.target;
    const logLevel = (opts.logLevel === undefined || opts.logConfig === null) ? LogLevel.DEBUG : opts.logLevel;
    const logConfig = opts.logConfig || logColor;
    const loggerArgs = opts.loggerArgs || [];
    if (Log.isEnabled(logLevel)) {
        const logger = Log.loggerForLevel(logLevel);
        if (typeof target === "object") {
            return new Proxy(target, {
                get(target, propKey) {
                    const origProp = target[propKey];
                    try {
                        if (typeof origProp === 'function') {
                            const className = (target.constructor && target.constructor.name) || 'obj';
                            return function (...args) {
                                logger(...loggerArgs, `Calling ${className}.${String(propKey)}()`, logConfig);
                                return origProp.apply(this, args);
                            };
                        }
                        return origProp;
                    }
                    catch (e) {
                        logger(...loggerArgs, `Failed Proxy call for ${origProp.name}`, logConfig);
                    }
                }
            });
        }
        else if (typeof target === "function") {
            return new Proxy(target, {
                apply(target, thisArg, argumentsList) {
                    try {
                        logger(...loggerArgs, `Calling function ${target.name || 'anonymous'}()`, logConfig);
                        return target.apply(thisArg, argumentsList);
                    }
                    catch (e) {
                        logger(...loggerArgs, `Failed Proxy call for ${target.name || 'anonymous'}()`, logConfig);
                    }
                }
            });
        }
        else {
            return target;
        }
    }
    return target;
}
