import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';

import Image from '../Image/Image';
import TextLabel from '../TextLabel/TextLabel';


import getStyles from './LauncherItem.styles';

/**
 * A listing of workbench action launchers
 */
const LauncherItem = (props) => {
    const {
        icon,
        text,
        onClick,
        contextStyles,
        testID,
        filteredValue,
    } = props;

    const styles = getStyles(contextStyles);

    const [
        focusState,
        setFocusState,
    ] = useState(false);

    // Generate container props
    const containerHoverStyle = focusState && styles.containerHoverStyle;
    const containerProps = {
        className: 'c-launcher-item__container',
        style: { ...styles.container, ...containerHoverStyle },
    };
    if (onClick) {
        containerProps.onClick = (event) => {
            onClick(event, props);
        };
    }
    if (testID) { containerProps['data-test-id'] = `${testID}__launcher-item__container`; }

    // Generate icon props
    const iconProps = {
        className: 'c-launcher-item__image',
        contextStyles: {
            container: styles.iconContainer,
            image: styles.icon,
            loadingIndicator: styles.iconLoadingIndicator,
        },
        imageSrc: icon,
    };
    if (testID) { iconProps.testID = `${testID}__launcher-item__image`; }

    // Generate text props
    const textContainerHoverStyle = focusState && styles.textContainerHoverStyle;
    const textProps = {
        className: 'c-launcher-item__text',
        contextStyles: {
            container: { ...styles.textContainer, ...textContainerHoverStyle },
            text: styles.text,
        },
        numberOfLines: 5,
        title: text,
    };
    if (testID) { textProps.testID = `${testID}__launcher-item__text`; }

    const renderHightLightText = () => {
        if (filteredValue.length) {
            const parts = text.split(new RegExp(`(${filteredValue})`, 'i'));
            let highlighted = false;
            const textParts = parts.map((part, index) => {
                const value = {
                    text: part,
                    id: index,
                    highlight: false,
                };
                if (part.toLowerCase() === filteredValue.toLowerCase() && !highlighted) {
                    highlighted = true;
                    value.highlight = true;
                }
                return value;
            });
            return (
                <span>
                    { textParts.map((part) => (
                        <span
                            key={ part.id }
                            style={ part.highlight ? {
                                backgroundColor: '#006e96',
                                color: 'white',
                            } : {} }>
                            { part.text }
                        </span>
                    ))
                    }
                </span>
            );
        }
        return text;
    };

    return (
        <Card { ...containerProps }>
            <CardActionArea
                style={ styles.actionAreaContainer }
                onFocus={ () => setFocusState(true) }
                onBlur={ () => setFocusState(false) }
                onMouseOver={ () => setFocusState(true) }
                onMouseLeave={ () => setFocusState(false) }>
                <Image { ...iconProps } />
                <TextLabel { ...textProps }>
                    { renderHightLightText() }
                </TextLabel>
            </CardActionArea>
        </Card>
    );
};

LauncherItem.propTypes = {
    /** Styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the CardActionArea */
        actionAreaContainer: PropTypes.object,

        /** Styling for the main container */
        container: PropTypes.object,

        /** Styles for the container hover effect for card */
        containerHoverStyle: PropTypes.object,

        /** Styling for the icon */
        icon: PropTypes.object,

        /** Styling for the icon container */
        iconContainer: PropTypes.object,

        /** Styling for the text */
        text: PropTypes.object,

        /** Styling for text container */
        textContainer: PropTypes.object,

        /** Styles for the text container hover effect */
        textContainerrHoverStyle: PropTypes.object,
    }),

    /** Launcher icon/image */
    icon: PropTypes.string.isRequired,

    /**
     * Called on launcher click
     * @param {Object} event - The native change event
     * @param {Object} props - The component instance props
     */
    onClick: PropTypes.func,

    /** Id used for testing */
    testID: PropTypes.string,

    /** Launcher text */
    text: PropTypes.string.isRequired,

    filteredValue: PropTypes.string,
};

LauncherItem.defaultProps = {
    contextStyles: {},
};

export default LauncherItem;
