import React from 'react';
import * as PropTypes from 'prop-types';
import getStyles from './SingleForm.styles';

const SingleForm = (props) => {
    const {
        contextStyles,
        formElement,
        scrollIdentifier,
        ...rest
    } = props;
    const { container } = getStyles(contextStyles);
    return (
        <div
            className="l-single-form__container"
            data-scroll-identifier={ scrollIdentifier }
            style={ container }
            { ...rest }>
            { formElement }
        </div>
    );
};

SingleForm.propTypes = {
    contextStyles: PropTypes.shape({
        container: PropTypes.object,
    }),
    formElement: PropTypes.element.isRequired,
    scrollIdentifier: PropTypes.string,
};

SingleForm.defaultProps = {
    contextStyles: {},
};

export default SingleForm;
