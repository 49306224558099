import RWFieldAction from './RWFieldAction';
import RWFieldActionColorPicker from './RWFieldActionColorPicker';
import RWFieldActionDatePicker from './RWFieldActionDatePicker';
import RWFieldActionTimePicker from './RWFieldActionTimePicker';
import RWFieldActionDateTimePicker from './RWFieldActionDateTimePicker';
import RWFieldActionFilePicker from './RWFieldActionFilePicker';
import RWTextLabel from './RWTextLabel';
import RWTextField from './RWTextField';
import RWTextBlock from './RWTextBlock';
import RWTextWithImage from './RWTextWithImage';
import RWDropDownField from './RWDropDownField';
import RWDropDownMultiSelectField from './RWDropDownMultiSelectField';
import RWSwitch from './RWSwitch';
import RWDateField from './RWDateField';
import RWDateTimeField from './RWDateTimeField';
import RWTimeField from './RWTimeField';
import RWTextFieldWithColorPalette from './RWTextFieldWithColorPalette';
import RWHtmlTextField from './RWHtmlTextField';
import RWCheckbox from './RWCheckbox';
import RWRadio from './RWRadio';
import RWPasswordField from './RWPasswordField';

/**
 *       ___ _       _    __                          ___                           _             ___                                             _
 *      / _ \ | __ _| |_ / _| ___  _ __ _ __ ___     / _ \_ __ ___  _ __   ___ _ __| |_ _   _    / __\___  _ __ ___  _ __   ___  _ __   ___ _ __ | |_ ___
 *     / /_)/ |/ _` | __| |_ / _ \| '__| '_ ` _ \   / /_)/ '__/ _ \| '_ \ / _ \ '__| __| | | |  / /  / _ \| '_ ` _ \| '_ \ / _ \| '_ \ / _ \ '_ \| __/ __|
 *    / ___/| | (_| | |_|  _| (_) | |  | | | | | | / ___/| | | (_) | |_) |  __/ |  | |_| |_| | / /__| (_) | | | | | | |_) | (_) | | | |  __/ | | | |_\__ \
 *    \/    |_|\__,_|\__|_|  \___/|_|  |_| |_| |_| \/    |_|  \___/| .__/ \___|_|   \__|\__, | \____/\___/|_| |_| |_| .__/ \___/|_| |_|\___|_| |_|\__|___/
 *                                                                 |_|                  |___/                       |_|
 *      Platform Property Components are registered here
 *      All other component types (non-property) are registered in the top-level engine/platformComponentProvider
 */
const platformPropertyComponentProvider = {
    textLabel: RWTextLabel,
    textField: RWTextField,
    textBlock: RWTextBlock,
    textImage: RWTextWithImage,
    fieldAction: RWFieldAction,
    fieldActionFilePicker: RWFieldActionFilePicker,
    fieldActionColorPicker: RWFieldActionColorPicker,
    fieldActionDatePicker: RWFieldActionDatePicker,
    fieldActionTimePicker: RWFieldActionTimePicker,
    fieldActionDateTimePicker: RWFieldActionDateTimePicker,
    dropDownField: RWDropDownField,
    dropDownMultiSelectField: RWDropDownMultiSelectField,
    dropDownTextwithImageField: RWDropDownField,
    switch: RWSwitch,
    dateField: RWDateField,
    dateTimeField: RWDateTimeField,
    timeField: RWTimeField,
    textFieldWithColorPalette: RWTextFieldWithColorPalette,
    htmlTextField: RWHtmlTextField,
    checkbox: RWCheckbox,
    radio: RWRadio,
    passwordField: RWPasswordField,

    /*
    html: Html,
    image: Image,
    icon: Icon
    urlImage: UrlImage
    */

};

export default platformPropertyComponentProvider;