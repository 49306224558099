// Core component styles
const coreStyles = {
    // We reduce padding for each container to address the issue with MUI
    // adding too much padding by default. This can be overriden.
    container: {
        display: 'flex',
        flexGrow: 1,
        flexShrink: 1,
        padding: 'unset',
    },
    input: {
        display: 'flex',
        flexGrow: 1,
        padding: 'unset',
        whiteSpace: 'break-spaces',
    },

    focused: {
        padding: 'unset',
    },

    hoover: {
        padding: 'unset',
    },

    disabled: {
    },

    notchedOutline: {
        border: 'none',
    },

    underline: {
    },

    underlineFocus: {
    },

    underlineHover: {
    },
};

/**
 * Helper method to get font styles for autoGrow input. Calculate all the available font properties to get the font style.
 * @param {Object} style - Styles for the component
 */
const getFontStyles = (style) => {
    const fontStyle = style.fontStyle ? `${style.fontStyle} ` : '';
    const fontVariant = style.fontVariant ? `${style.fontVariant} ` : '';
    const fontWeight = style.fontWeight ? `${style.fontWeight} ` : '';
    const fontSize = style.fontSize ? `${style.fontSize}px ` : '16px ';
    const fontFamily = style.fontFamily || 'Roboto';
    return style.font || `${fontStyle}${fontVariant}${fontWeight}${fontSize}${fontFamily}`;
};

/**
 * Helper method to calculate width of the input element.
 * It uses HTML5 canvas.measureText to compute the width of the given text of given font.
 * @param {String} text - Text inside the text input
 * @param {String} font - Font style for autoGrow element
 */
const getInputWidth = (text, font, iconOffsetWidth) => {
    const canvas = getInputWidth.canvas || (getInputWidth.canvas = document.createElement('canvas'));
    const context = canvas.getContext('2d');
    context.font = font;
    const actualMetrics = context.measureText(text);
    const minMetrics = context.measureText('WWWW');
    return Math.max(actualMetrics.width + iconOffsetWidth, minMetrics.width);
};

/**
 * Helper method to get the width of autoGrow element.
 * @param {String} containerWidth - Width of the container
 * @param {Number} inputWidth - width of the input element
 */
const getAutoGrowWidth = (containerWidth, inputWidth) => {
    if (!containerWidth) {
        return `${inputWidth}px`;
    }
    const modifiedContainerWidth = containerWidth.toString().replace('px', '');
    return `${inputWidth > modifiedContainerWidth ? modifiedContainerWidth : inputWidth}px`;
};

/**
 * Helper method to get styles for this component
 * @param {Object} contextStyles - Styles for this component
 * @param {Boolean} autoGrow - Grows/shrinks the input based on the size of its text value
 * @param {String} value - The text to populate in the input
 */
const getStyles = (contextStyles = {}, autoGrow = false, value, iconOffsetWidth = 0) => {
    const containerWidth = contextStyles.container && contextStyles.container.width;

    // Get font styles for autoGrow element
    const font = autoGrow && getFontStyles({
        ...coreStyles.input,
        ...contextStyles.input,
    });

    // AutoGrow elements width and font
    const autoGrowStyles = autoGrow && {
        width: getAutoGrowWidth(containerWidth, getInputWidth(value, font, iconOffsetWidth)),
        font,
    };

    return {
        root: {
            ...coreStyles.container,
            ...contextStyles.container,
        },
        input: {
            ...coreStyles.input,
            ...contextStyles.input,
            ...autoGrowStyles,
            '&:hover': {
                ...coreStyles.hoover,
                ...contextStyles.hoover,
            },
        },

        notchedOutline: {
            '&$notchedOutline': {
                ...coreStyles.notchedOutline,
                ...contextStyles.notchedOutline,
            },
        },

        focused: {
            '&$focused': {
                ...coreStyles.focused,
                ...contextStyles.input,
            },
        },

        // disabled: {
        //     '&$disabled': {
        //         ...coreStyles.disabled,
        //         ...contextStyles.disabled,
        //     },
        // },

        underline: {
            '&&&:before': {
                ...coreStyles.underline,
                ...contextStyles.underline,
            },
            '&&:after': {
                ...coreStyles.underlineFocus,
                ...contextStyles.underlineFocus,
            },
            '&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before': {
                ...coreStyles.underlineHover,
                ...contextStyles.underlineHover,
            },
        },
    };
};

export default getStyles;
