// Core component styles
const coreStyles = {
    actions: {
    },

    actionButton: {
    },

    actionButtonText: {
    },

    buttonContainer: {
        cursor: 'pointer',
    },

    cancelButton: {
    },

    cancelButtonText: {
    },

    content: {
    },

    divider: {
    },

    header: {
    },

    menu: {
    },

    menuItem: {
    },

    menuItemFocused: {
    },

    modal: {
    },

    overlay: {
    },

    signatureStyle: {
    },

    title: {
    },

    undoButton: {
    },

    undoButtonText: {
    },
};

/**
 * Helper method to get styles for the Menu Component
 * @param {Object} contextStyles - Custom styles for the Menu component
 */
const getStyles = (contextStyles = {}) => {
    return {
        actions: {
            ...coreStyles.actions,
            ...contextStyles.actions,
        },

        actionButton: {
            ...coreStyles.actionButton,
            ...contextStyles.actionButton,
        },

        actionButtonText: {
            ...coreStyles.actionButtonText,
            ...contextStyles.actionButtonText,
        },

        buttonContainer: {
            ...coreStyles.buttonContainer,
            ...contextStyles.buttonContainer,
        },

        cancelButton: {
            ...coreStyles.cancelButton,
            ...contextStyles.cancelButton,
        },

        cancelButtonText: {
            ...coreStyles.cancelButtonText,
            ...contextStyles.cancelButtonText,
        },

        content: {
            ...coreStyles.content,
            ...contextStyles.content,
        },

        divider: {
            ...coreStyles.divider,
            ...contextStyles.divider,
        },

        header: {
            ...coreStyles.header,
            ...contextStyles.header,
        },

        menu: {
            ...coreStyles.menu,
            ...contextStyles.menu,
        },

        menuItem: {
            ...coreStyles.menuItem,
            ...contextStyles.menuItem,
        },

        menuItemFocused: {
            ...coreStyles.menuItemFocused,
            ...contextStyles.menuItemFocused,
        },

        modal: {
            ...coreStyles.modal,
            ...contextStyles.modal,
        },

        overlay: {
            ...coreStyles.overlay,
            ...contextStyles.overlay,
        },

        signatureStyle: {
            ...coreStyles.signatureStyle,
            ...contextStyles.signatureStyle,
        },

        title: {
            ...coreStyles.title,
            ...contextStyles.title,
        },

        undoButton: {
            ...coreStyles.undoButton,
            ...contextStyles.undoButton,
        },

        undoButtonText: {
            ...coreStyles.undoButtonText,
            ...contextStyles.undoButtonText,
        },
    };
};

export default getStyles;
