import { rootStore } from 'cv-react-core';

const getTheme = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    // Populate the component parts with theme styles
    return {
        chartTextStyle: {
            ...theme.fonts.default,
            color: '#646464',
            fontFamily: 'Roboto',
        },

        container: {
        },

        legendTextStyle: {
            ...theme.fonts.default,
            color: '#646464',
            fontFamily: 'Roboto',
            fontSize: 12,
        },

        titleTextStyle: {
            ...theme.fonts.default,
            fontFamily: 'Roboto',
        },

        tooltipTextStyle: {
            ...theme.fonts.default,
            color: '#fff',
            fontFamily: 'Roboto',
            fontSize: 12,
        },

        xAxisTextStyle: {
            ...theme.fonts.default,
            fontFamily: 'Roboto',
        },

        yAxisTextStyle: {
            ...theme.fonts.default,
            fontFamily: 'Roboto',
        },
    };
};

export default getTheme;
