import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { serviceFactory } from 'cv-react-core';
import { observer } from 'mobx-react';
import { XaltInputBase, XaltBox } from 'xalt-react-atoms';

// import TextField from '../base/TextField';
import getStyles from './styles/Component.styles';

@observer
class FilterValueTextComponent extends Component {
    static defaultProps = {
        // styles: {},
        onSetFilterValue: () => {},
    }

    static propTypes = {
        propertyName: PropTypes.string.isRequired,
        onSetFilterValue: PropTypes.func,
        searchDialogStore: PropTypes.object,
        propertyType: PropTypes.string,
    }

    constructor(props) {
        super(props);
        this.handleTextFieldOnChange = this.handleTextFieldOnChange.bind(this);
    }

    render() {
        const {
            propertyName,
            searchDialogStore,
            propertyType } = this.props;
        const filterValueProperty = searchDialogStore.getSearchValuePropertyForName(propertyName);
        const { value } = filterValueProperty;

        const { lang } = serviceFactory;
        const styles = getStyles();

        return (
            <XaltBox style={ { ...styles.component } }>
                <XaltInputBase
                    disableUnderline
                    style={ { ...styles.component } }
                    onValueChanged={ this.handleTextFieldOnChange }
                    value={ (value || value === 0) ? value : '' }
                    type={ propertyType }
                    placeholder={ lang.searchSort.filterValuePlaceholderText } />
            </XaltBox>
            // <TextField
            //     onChange={ this.handleTextFieldOnChange }
            //     placeholder={ lang.searchSort.filterValuePlaceholderText }
            //     type={ propertyType }
            //     value={ (value || value === 0) ? value : '' } />
        );
    };

    handleTextFieldOnChange(newValue) {
        const { onSetFilterValue, propertyName } = this.props;
        onSetFilterValue(propertyName, newValue);
    }
}

export default FilterValueTextComponent;