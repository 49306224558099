/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import componentFactory from '../componentFactory';
import componentPropTypes from './abstractComponentPropTypes';

export default class PasswordField extends Component {
    static propTypes = componentPropTypes;
    constructor(props) {
        super(props);
        const { value } = props;
        this.initialValue = value;
    }

    render() {
        return React.createElement(componentFactory.getPlatformPropertyComponent('passwordField'), {
            ...this.props,
            type: 'password',
            onValueChanged: this.handleValueChanged,
            // If password is already existing, the password visibility button does not show.
            showAdornment: !this.initialValue,
        });
    }

    componentDidUpdate(prevProps) {
        const { isReadMode: prevIsReadMode } = prevProps;
        const { isReadMode, value } = this.props;

        // As we are using same component in both read and write modes,
        // we have to reset the initial value to achieve erasing password functionality.
        if (prevIsReadMode !== isReadMode) {
            this.initialValue = value;
        }
    }

    handleValueChanged = (value) => {
        const { onValueChanged } = this.props;
        let newValue = value;
        // If users start typing on an existing password, it erases the old password.
        if (this.initialValue) {
            newValue = newValue.replace(this.initialValue, '');
            this.initialValue = '';
        }
        onValueChanged(newValue);
    }
}
