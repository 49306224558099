/* @flow */
export default {
    backDisabled: 'Back is disabled for this function',
    cancel: 'Cancel',
    cancelTitle: 'Cancel Process',
    cancelConfirmation: 'Are you sure you would like to cancel this process?',
    close: 'Close',
    continue: 'Continue',
    error: 'Error',
    no: 'No',
    save: 'Save',
    unknown: 'unknown',
    yes: 'Yes',
    ok: 'Ok',
    search: 'Search',
    delete: 'Delete',
    deleteTitle: 'Delete Process',
    deleteConfirmation: 'Are you sure you want to delete this value?',
    applicationError: 'Application Error',
    saveConfirmation: 'You have unsaved changes. Continue anyway?',
    unsavedPrompt: 'You must save your edit activity before continuing with this action',
    formatXML: 'Format XML',
    xmlParseError: 'This XML contains errors and cannot be formatted.',
    displayImageTitle: 'Display Image',
    closeBrowserTab: 'Close Browser Tab',
    leftOverCharacters: '{0} characters left',
    giveYourFeedback: 'Give Your Feedback',
};
