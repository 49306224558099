/**
 * A Document is a global object that holds information about the Form.
 */
export default class Document {
    finalizers = [];
    addFinalizer = (finalizer) => {
        this.finalizers.push(finalizer);
    }
    resolveAll = () => {
        this.finalizers.forEach(e => (e.resolve()));
        this.finalizers = [];
    }
}
