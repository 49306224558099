import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { constants, loginController, serviceFactory } from 'cv-react-core';
import { Log } from 'cv-dialog-sdk';

import Button, { BUTTON_VARIANT } from '../../base/Button';
import TextLabel from '../../base/TextLabel';
import PasswordField from '../../base/PasswordField';

import getStyles from './ChangePassword.styles';

const {
    session,
    ui,
} = constants;

const {
    CURRENT_PASSWORD,
    NEW_PASSWORD,
    CONFIRM_PASSWORD,
} = session;

const {
    APPLICATION_UI_ID,
    LOGIN_IN_PROGRESS,
} = ui;

// TODO: this components logic can be lifted to the LoginRoute component and most likely converted to a library functional component for improved performance
@observer
class ChangePassword extends Component {
    static defaultProps = {
        contextStyles: {},
    };

    static propTypes = {
        contextStyles: PropTypes.object,
        sessionStore: PropTypes.object,
        settingsStore: PropTypes.object,
        uiStore: PropTypes.object,
    };

    render() {
        const {
            sessionStore,
            contextStyles,
            uiStore,
        } = this.props;
        // Generate errors
        let err = null;
        const errors = uiStore.getErrorsForUIObject(APPLICATION_UI_ID);
        if (errors.length) {
            err = errors[0].msg;
        }
        const {
            changeCredentials,
        } = sessionStore;
        const styles = getStyles(contextStyles, !!err);

        // Since the ChangePassword prompt requires currentPassword, newPassword and newConfirmPassword we have to set an empty string
        const currentPassword = changeCredentials.get(CURRENT_PASSWORD) || '';
        const newPassword = changeCredentials.get(NEW_PASSWORD) || '';
        const newConfirmPassword = changeCredentials.get(CONFIRM_PASSWORD) || '';
        const passwordFieldProps = {
            contextStyles: {
                container: styles.password,
                focused: styles.input,
                hoover: styles.input,
                input: styles.input,
            },
            variant: 'outlined',
        };
        const { lang } = serviceFactory;

        return (
            <div style={ styles.container }>
                {
                    err && (
                        <TextLabel
                            contextStyles={ {
                                container: styles.error,
                                text: styles.errorText,
                            } }>
                            { err }
                        </TextLabel>
                    )
                }
                <PasswordField
                    { ...passwordFieldProps }
                    autoComplete="current-password"
                    label={ lang.login.currentPassword }
                    onChange={ (event) => this.handleChangePasswordFieldChange(event, CURRENT_PASSWORD) }
                    value={ currentPassword } />
                <PasswordField
                    { ...passwordFieldProps }
                    autoComplete="new-password"
                    label={ lang.login.newPassword }
                    onChange={ (event) => this.handleChangePasswordFieldChange(event, NEW_PASSWORD) }
                    value={ newPassword } />
                <PasswordField
                    { ...passwordFieldProps }
                    autoComplete="confirm-new-password"
                    label={ lang.login.confirmNewPassword }
                    onChange={ (event) => this.handleChangePasswordFieldChange(event, CONFIRM_PASSWORD) }
                    value={ newConfirmPassword } />
                <Button
                    contextStyles={ {
                        container: styles.button,
                        primary: styles.buttonPrimary,
                        primaryHover: styles.buttonPrimaryHover,
                        primaryText: styles.buttonPrimaryText,
                        text: styles.buttonText,
                    } }
                    disabled={ !currentPassword.trim() || !newPassword.trim() || !newConfirmPassword.trim() }
                    onClick={ this.handleChangePasswordPress }
                    text={ lang.generic.continue } />
                <Button
                    contextStyles={ {
                        container: styles.button,
                        secondary: styles.buttonSecondary,
                        secondaryText: styles.buttonSecondaryText,
                        text: styles.buttonText,
                    } }
                    text={ lang.generic.cancel }
                    onClick={ this.handleCancelPress }
                    variant={ BUTTON_VARIANT.OUTLINED } />
            </div>
        );
    };

    handleChangePasswordPress = () => {
        const {
            sessionStore,
            settingsStore,
            uiStore,
        } = this.props;

        const newPassword = sessionStore.changeCredentials.get(NEW_PASSWORD);
        const newConfirmPassword = sessionStore.changeCredentials.get(CONFIRM_PASSWORD);

        if (newPassword && newConfirmPassword && newPassword.localeCompare(newConfirmPassword) === 0){
            if (sessionStore.passwordExpired){
                sessionStore.setPasswordExpired(false);
                loginController.handleChangePasswordAndCreateSessionPress(sessionStore, settingsStore, uiStore, this.getDeviceProps(), this.handlePostLogin, 'DESKTOP')
                .catch((err) => {
                    Log.error('Failed to log in.');
                    Log.error(err.message);
                    Log.error(err.stack);
                });
            }
            else {
                loginController.handleChangePasswordPress(sessionStore, uiStore, this.handlePostLogin)
                .catch((err) => {
                    Log.error('Failed to log in.');
                    Log.error(err.message);
                    Log.error(err.stack);
                });
            }
        }
        else {
            const { lang } = serviceFactory;
            uiStore.setValueForUIObject(APPLICATION_UI_ID, LOGIN_IN_PROGRESS, false, false);
            uiStore.addErrorForUIObject(APPLICATION_UI_ID, {
                type: 'generic',
                msg: lang.errors.passwordMisMatch,
            });
        }
    }

    handleCancelPress = () => {
        this.getDeviceProps();
        const {
            sessionStore,
            uiStore,
        } = this.props;

        sessionStore.changeCredentials.clear();

        if (sessionStore.passwordExpired){
            sessionStore.setPasswordExpired(false);
        }
        else if (sessionStore.passwordExpiryInXDays){
            sessionStore.setPasswordExpiryInXDays(false);
        }

        uiStore.clearErrorsForUIObject(APPLICATION_UI_ID);
        sessionStore.setChangePasswordPrompt(false);
    }

    handleChangePasswordFieldChange = (event, key) => {
        const { sessionStore, uiStore } = this.props;
        uiStore.clearErrorsForUIObject(APPLICATION_UI_ID);
        const { currentTarget } = event;
        const { value } = currentTarget;
        loginController.handleChangeCredentialTextChanges(sessionStore, value, key);
    }

    getDeviceProps = () => {
        const { uiStore } = this.props;
        return serviceFactory.device.getDeviceProps(uiStore, serviceFactory.device.deviceSize);
    };
}

export default ChangePassword;
