import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import engine from './engine';
import engineConstants from './engineConstants';
import RefUtil from './ref/RefUtil';
import SaltComponent from './SaltComponent';
import SaltContext from './SaltContext';

/**
 * Begins and encloses a salt document.
 * May also be used anywhere in the document to load salt dynamically,
 * either from an existing ref (refName), from an inline expression (expr),
 * or remotely given a 'saltId'
 * NOTE: we need a server-side impl for the retrieval of salt by 'saltId'
 */
@observer
export default class Salt extends SaltComponent {
    static propTypes = {
        children: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.arrayOf(PropTypes.element),
        ]),
        // For loading dynamic salt, expect 1 of refName, expr, or saltId
        refName: PropTypes.string,
        expr: PropTypes.string,
        viewId: PropTypes.string,
        version: PropTypes.string,
        saltId: PropTypes.string,
    };

    static typeName = engineConstants.component.name.salt;

    componentDidMount() {
        const { saltId, translation } = this.props;
        if (saltId) {
            const { saltStore } = this.context;
            saltStore.loadSaltById(saltId);
        }

        if (translation) {
            const viewId = this.getViewId(this.props.viewId);
            const { saltStore, scopeManager } = this.context;
            const dialogStore = saltStore.getDialogStoreForViewId(viewId);
            RefUtil.setGlobalRef(`translations::${dialogStore.dialog.id}`, translation, scopeManager);
        }
    }

    render() {
        const { children, refName, expr, saltId } = this.props;
        const { scopeManager } = this.context;
        if (refName) {
            const salt = RefUtil.getRefByName(refName, scopeManager);
            return engine.render(toJS(salt));
        }
        if (expr) {
            const viewId = this.getViewId(this.props.viewId);
            const { saltStore } = this.context;
            const dialogStore = saltStore.getDialogStoreForViewId(viewId);
            const salt = RefUtil.evaluateExpression(expr, dialogStore, scopeManager, saltStore);
            return engine.render(toJS(salt));
        }
        if (saltId) {
            const { saltStore } = this.context;
            const salt = saltStore.getSaltById(saltId);
            return salt ? engine.render(toJS(salt)) : null;
        }
        return children;
    }
}

Salt.contextType = SaltContext;
