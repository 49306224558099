/*       */

/**
 * A StyleSet is a Style object and an xStyle object.
 */
export const BOTH = 'both';
export const GROW = 'grow';
export const SHRINK = 'shrink';
export const EQUAL = 'equal';
export const NONE = 'none';

export default class StyleSet {
    style ;
    xStyle ;
    constructor(style = {}, xStyle = {}) {
        this.style = style;
        this.style.overflow = style.overflow || 'visible'; // Grids need to overflow to draw their borders correctly.
        this.xStyle = xStyle;
        if (!this.xStyle.layout) {
            this.xStyle.layout = {};
        }
    }

    asStyleAttribute = (mergeProps = {}) => {
        return { style: { ...this.style, ...mergeProps } };
    }

    asXStyleAttribute = (mergeProps = {}) => {
        if (Object.keys(this.xStyle).length > 1 || Object.keys(this.xStyle.layout).length || Object.keys(mergeProps).length) {
            return { xStyle: { ...this.xStyle, ...mergeProps } };
        }
        return {};
    }
}
