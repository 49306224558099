const invalidNativeStyles = [ 'transform' ];
const animationEffects = {
    fadeIn: {
        0: { opacity: 0 },
        1: { opacity: 1 }
    },
    fadeOut: {
        0: { opacity: 1 },
        0.4: { opacity: 0.5 },
        0.8: { opacity: 0 },
        1: { opacity: 1 }
    },
    zoomOut: {
        0: {
            opacity: 1,
            scale: 1,
            transform: 'scale(1)',
        },
        0.4: {
            opacity: 1,
            scale: 0.3,
            transform: 'scale(0.3)',
        },
        0.8: {
            opacity: 0,
            scale: 0,
            transform: 'scale(0)',
        },
        1: {
            opacity: 1,
            scale: 1,
            transform: 'scale(1)',
        },
    },
    zoomIn: {
        0: {
            opacity: 1,
            scale: 0,
            transform: 'scale(0)',
        },
        0.4: {
            opacity: 1,
            scale: 0.3,
            transform: 'scale(0.3)',
        },
        0.8: {
            opacity: 1,
            scale: 0.8,
            transform: 'scale(0.8)',
        },
        1: {
            opacity: 1,
            scale: 1,
            transform: 'scale(1)',
        },
    }
};

const recursiveRemoveKey = (object, deleteKey) => {
    delete object[deleteKey];
    
    Object.values(object).forEach((val) => { 
      if (typeof val !== 'object') return;
      recursiveRemoveKey(val, deleteKey);
    });
}

const extractWebAnimationEffect = (effect) => {
    return animationEffects[effect] ? Object.values(animationEffects[effect]) : [];
}

const extractNativeAnimationEffect = (effect) => {
    if ( animationEffects[effect] ) {
        let animation = animationEffects[effect];
        invalidNativeStyles.map((invalidStyle) => {
            recursiveRemoveKey(animation, invalidStyle);
        });
        return animation;
    }
    return {};
}
const animationHelper = {
    extractWebAnimationEffect,
    extractNativeAnimationEffect
}
export default animationHelper;