/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import componentFactory from '../componentFactory';
import componentPropTypes from './abstractComponentPropTypes';

export default class EmailField extends Component {
    static propTypes = componentPropTypes;

    render() {
        return React.createElement(componentFactory.getPlatformPropertyComponent('textField'), { ...this.props, type: 'email' });
    }
}
