/*       */
import Attribute from './Attribute';

export const THIS = '`$`';
export const CHILDREN = '`$$`';
export const INNER = '`_`';

export const CONST_PREFIX_GML = '@const/';
export const SESSION_PREFIX_GML = 'session.';
export const LABEL_PREFIX_GML = '@label/';
export const REF_PREFIX = 'hxgnRefPrefix_';
export const CONST_PREFIX_EXTENDER = '*const[';
export const ICON_PREFIX = 'icon:';
export const RES_PREFIX = 'res:';
export const PRIMARY_BUTTON_PREFIX = 'primary:';

export const FLEX_DIRECTION = 'flex-direction';

export const ELEMENTS = {
    gml: 'gml',
    const: 'const',
    style: 'style',
    list: 'list',
    detail: 'detail',
    div: 'div',
    col: 'col', // alias for div
    row: 'row', // alias for div
    plist: 'plist',
    colProps: 'colProps', // alias for plist
    rowProps: 'rowProps', // alias for plist
    alist: 'alist',
    colActions: 'colActions', // alias for alist
    rowActions: 'rowActions', // alias for alist
    line: 'line',
    conditional: 'conditional',
    and: 'and', // alias for conditional
    or: 'or', // alias for conditional
    else: 'else', // in conjunction with Condition
    android: 'android',
};

export const QUALIFIERS = {
    portrait: 'portrait',
    landscape: 'landscape',
    small: 'small',
    smallPortrait: 'small-portrait',
    smallLandscape: 'small-landscape',
    medium: 'medium',
    mediumPortrait: 'medium-portrait',
    mediumLandscape: 'medium-landscape',
    large: 'large',
    largePortrait: 'large-portrait',
    largeLandscape: 'large-landscape',
    ignore: 'ignore',
    header: 'header',
    footer: 'footer',
    nodata: 'nodata',
    readOnly: 'readOnly',
    maintenance: 'maintenance',
};

export const MISC_A = {
    actionId: new Attribute('actionID'), // 'actionId'?
    alignItems: new Attribute('alignItems', 'align'),
    backgroundImage: new Attribute('backgroundImage'),
    backgroundNineSliceInsets: new Attribute('backgroundNineSliceInsets'),
    equallySized: new Attribute('equallySized'),
    expand: new Attribute('expand', 'expandable'),
    height: new Attribute('height'),
    qualifiers: new Attribute('qualifiers'),
    scroll: new Attribute('scroll'),
    width: new Attribute('width'),
};

export const CONDITIONAL_A = {
    property: new Attribute('property'),
    operator: new Attribute('operator'),
    operandProperty: new Attribute('operandProperty'),
    operandConstant: new Attribute('operandConstant'),
};

export const DETAIL_A = {
    markupEdit: new Attribute('markupEdit'),
    markupSaveCancel: new Attribute('markupSaveCancel'),
};

export const GML_A = {
    version: new Attribute('version'),
};
