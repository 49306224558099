import { decode, encode } from 'base-64';
import { BuildGlobals,
    componentBuilderProvider,
    dialogComponentFactory,
    engineAbstractComponentProvider,
    abstractPropertyComponentProvider,
    engineComponentFactory,
    layoutComponentFactory,
    rootStore,
    saltProvider,
    serviceFactory,
    themes,
        } from 'cv-react-core';
import { parseString } from 'xml2js';

import { version, releaseVersion } from '../package.json';
import enginePlatformComponentProvider from './engine/platformComponentProvider';
import debugProvider from './provider/debugProvider';
import lang from './nls/i18n';
import builderProvider from './properties/componentBuilderProvider';
import { clipboard, crypto, device, linking, notify, oAuth } from './services';
import platformPropertyComponentProvider from './engine/properties/platformPropertyComponentProvider';

// eslint-disable-next-line prefer-destructuring
const env = process.env;

// initialization helper functions
const getProductType = () => ('xha');
const isSda = getProductType() === 'sda';

// setup service
serviceFactory.setClipboardService(clipboard);
serviceFactory.setDeviceService(device);
serviceFactory.setCryptoService(crypto);
serviceFactory.setNotifyService(notify);
serviceFactory.setLangService(lang);

serviceFactory.setLinkingService(linking);
serviceFactory.setOAuthService(oAuth);
serviceFactory.setXmlParserService(parseString);

// setup base64 methods
global.atob = decode;
global.btoa = encode;

// setup Top-level Dialog component factory
// delegate chain (order matters!)

const providerList = [ saltProvider ];
if (env.REACT_APP_SALT_DEBUG === 'true') {
    providerList.unshift(debugProvider);
}
dialogComponentFactory.setProviderList(providerList);

// setup engine components
engineComponentFactory.setPlatformComponentProvider(enginePlatformComponentProvider);
layoutComponentFactory.setLayoutComponentProvider(enginePlatformComponentProvider);
engineComponentFactory.setAbstractComponentProvider(engineAbstractComponentProvider);
// V2 Components
engineComponentFactory.setAbstractPropertyComponentProvider(abstractPropertyComponentProvider);
engineComponentFactory.setPlatformPropertyComponentProvider(platformPropertyComponentProvider);

// V1 Components setup component builders
componentBuilderProvider.setBuilderProvider(builderProvider);

// setup theme
themes.BaseTheme.appLogo = isSda ? require('./assets/images/logos/login_logo.png')
    : require('./assets/images/logos/Nexus_By_Hexagon.png');

themes.BaseTheme.bannerLogo = require('./assets/images/logos/hxgn_login_bar.png');

themes.BaseTheme.appName = serviceFactory.lang.formatString(lang.login.appName);

BuildGlobals.setBuildType(getProductType());
BuildGlobals.setLogLevel(env.REACT_APP_LOG_LEVEL);
BuildGlobals.setTestComponent(env.REACT_APP_TEST_COMPONENT);
BuildGlobals.setClientReleaseVersion(releaseVersion);
BuildGlobals.setClientSemanticVersion(version);

const { themeStore } = rootStore;
themeStore.setTheme(themes.themeFactory(rootStore).getTheme());

// DEBUG ***************************************************

// Direct login URL
// Catavolt.initDialogApi('http://localhost:8085');

// turn on mobx activity
/*
spy((event) => {
    if (event.type === 'action') {
        Log.debug(`Mobx Spy: ${event.name}(${event.arguments})`);
    } else if (event.type === 'reaction') {
        let id = '?';
        if (event.object && event.object.reactComponent &&
            event.object.reactComponent.props.navigation &&
            event.object.reactComponent.props.navigation.state &&
            event.object.reactComponent.props.navigation.state.params &&
            event.object.reactComponent.props.navigation.state.params.dialogId) {
            id = event.object.reactComponent.props.navigation.state.params.dialogId;
        }
        Log.debug(`Mobx Spy: ${event.type} : ${event.object.name} DialogId: ${id}`);
    }
});
*/

// END DEBUG ******************************************************
