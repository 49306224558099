import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { XaltCheckbox, XaltTextLabel } from 'xalt-react-atoms';

import Menu from '../Menu';
import MenuItem from '../MenuItem';
import getStyles from './ColumnChooser.styles';

/**
 * This component helps in show/hide columns.
 */
class ColumnChooser extends Component {
    static propTypes = {
        /** Array of columns */
        columns: PropTypes.array,

        /** Method used to update the hidden columns */
        onHiddenColumnsChange: PropTypes.func,
    };

    state = {
        anchorPosition: null,
    };

    render() {
        const { columns } = this.props;
        const { anchorPosition } = this.state;
        const hasPinnedCols = columns.some((col) => col.isPinned);
        const styles = getStyles();
        const menuProps = {
            anchorPosition,
            closeOnSelection: false,
            contextStyles: {
                container: styles.container,
            },
            onClose: () => this.show(null),
            open: !!anchorPosition,
        };
        return (
            <Menu { ...menuProps }>
                {
                    columns.map((col, index) => {
                        const {
                            hidden,
                            isPinned,
                            name,
                            spacer,
                            title,
                        } = col;
                        const disabled = spacer || (hasPinnedCols ? isPinned : index === 0);
                        return (
                            <MenuItem
                                disabled={ disabled }
                                key={ name }
                                onClick={ () => this.handleChange(name, !hidden) }
                                text={ (
                                    <>
                                        <XaltCheckbox checked={ !hidden } />
                                        <XaltTextLabel style={ styles.text }>
                                            { title }
                                        </XaltTextLabel>
                                    </>
                                ) } />
                        );
                    })
                }
            </Menu>
        );
    }

    handleChange = (name, hidden) => {
        const { onHiddenColumnsChange } = this.props;
        onHiddenColumnsChange(name, hidden);
    }

    show = (anchorPosition) => {
        this.setState({
            anchorPosition,
        });
    }
}

export default ColumnChooser;
