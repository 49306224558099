import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import MuiIcon from '@material-ui/core/Icon';
import * as PropTypes from 'prop-types';
import { BaseTooltip } from 'cv-library-react-web';
import makeStyles from '@material-ui/core/styles/makeStyles';
import getStyles from './Icon.styles';

import Badge from '../Badge/Badge';

/** Default icon size */
const ICON_SIZE_DEFAULT = 'medium';

const ICON_SIZE = {
    DEFAULT: 'medium',
    INHERIT: 'inherit',
    LARGE: 'large',
    SMALL: 'small',
};

/**
 * Icon component allows a user to view a non-image visual representation of an object or action.
 * @see https://material-ui.com/style/icons/
 */
const Icon = (props) => {
    const {
        badgeText,
        contextStyles,
        iconName,
        iconSize,
        svgPath,
        testID,
        title,
    } = props;

    // Override core styles with context styles, separating MUI styles
    const {
        container: containerStyles,
        badge: badgeStyles,
        iconStyles,
        ...muiStyles
    } = getStyles(contextStyles);

    // Create dynamic class names and injected DOM styles for MUI component
    // * makeStyles returns a function
    const styles = makeStyles(muiStyles)();

    // Generate container props
    const containerProps = {
        className: 'c-icon__container',
        style: containerStyles, // non-MUI styles
    };
    if (testID) { containerProps['data-test-id'] = `${testID}__icon__container`; }

    // Generate icon props
    const iconProps = {
        classes: {
            root: styles.icon, // MUI styles
        },
        className: 'c-icon__icon',

        // To accept Numbers for iconSize
        style: {},
    };

    // To avoid proptype warnings, assign fontSize property only when it is valid, else assign it in the style property.
    if (Object.values(ICON_SIZE).includes(iconSize)) {
        iconProps.fontSize = iconSize;
    }
    else {
        iconProps.style.fontSize = iconSize;
    }

    if (testID) { iconProps['data-test-id'] = `${testID}__icon__icon`; }

    const iconComponent = svgPath ?
        (   
            <BaseTooltip tooltip={ title } contextStyles={{ tooltipRef : iconStyles }}>
                <SvgIcon { ...iconProps }>
                    <path d={ svgPath } />
                </SvgIcon>
            </BaseTooltip>
        ) :
        (
            <BaseTooltip tooltip={ title } contextStyles={{ tooltipRef : iconStyles }}>
                <MuiIcon { ...iconProps }>
                    { iconName }
                </MuiIcon>
            </BaseTooltip>
        );

    // Generate badge props
    if (badgeText) {
        const badgeProps = {
            contextStyles: {
                text: badgeStyles,
            },
            text: badgeText,
        };
        if (testID) { badgeProps.testID = `${testID}__icon__badge`; }

        return (
            <span { ...containerProps }>
                <Badge { ...badgeProps }>
                    { iconComponent }
                </Badge>
            </span>
        );
    }

    return (
        <span { ...containerProps }>
            { iconComponent }
        </span>
    );
};

Icon.propTypes = {
    /** Badge content */
    badgeText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),

    /** Styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the container surrounding the icon */
        container: PropTypes.object,

        /** Styles for a badge */
        badge: PropTypes.object,

        /** Styles for an icon */
        icon: PropTypes.object,
    }),

    /** Name of a UI icon, or an icon element */
    iconName: PropTypes.string,

    /** Size of the icon to be rendered */
    iconSize: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf([
            ICON_SIZE.DEFAULT,
            ICON_SIZE.INHERIT,
            ICON_SIZE.LARGE,
            ICON_SIZE.SMALL,
        ]),
    ]),

    /** Path for SVG Icon */
    svgPath: PropTypes.string,

    /** Id used for testing */
    testID: PropTypes.string,

    /**
     * Description string.
     * Browsers usually render this as a tooltip on hover, whereas screen
     * readers will use it for aural feedback.
     * By default, this is set to the icon's name for accessibility.
     */
    title: PropTypes.string,
};

Icon.defaultProps = {
    contextStyles: {},
    iconSize: ICON_SIZE_DEFAULT,
};

export default Icon;
export {
    ICON_SIZE,
};
