/*       */

import { ACTION } from '../../exportClassConstants';

import SimpleXStyleLayoutAttribute from '../SimpleXStyleLayoutAttribute';
import AbstractLayoutFactory from './AbstractLayoutFactory';

class PlistLayoutFactory extends AbstractLayoutFactory {
    constructor() {
        super();
        const attributesAndAliases = [
            'actionMargin',
        ];
        this.init(attributesAndAliases);
    }

    initFactory() {
        super.initFactory();
        const { factory } = this;
        factory.actionMargin = (json, key) => {
            return new SimpleXStyleLayoutAttribute('actionMargin', this.getAsString(json, key), [ ACTION ], true, 'margin');
        };
    }
}
const plistLayoutFactory = new PlistLayoutFactory();
export default plistLayoutFactory;
