export const prettyXmlParseError = (xml, errorObject) => {
    let result = errorObject ? errorObject.toString() : 'Unknown error';
    const { message } = errorObject;
    if (message) {
        // See if the error is indexing into the XML we are parsing.
        // searching for '...\nColumn: 1382\n....'
        result = message;
        const s = '\nColumn: ';
        const i1 = message.indexOf(s) + s.length;
        const i2 = message.indexOf('\n', i1 + 1);
        if (i2 > i1) {
            const errorLocation = parseInt(message.substr(i1, i2 - i1), 10);
            // Find the preceding lf
            let start = xml.lastIndexOf('\n', errorLocation);
            let end = xml.indexOf('\n', errorLocation);
            if (start === -1 && end === -1) {
                start = xml.lastIndexOf('\r', errorLocation);
                end = xml.indexOf('\r', errorLocation);
            }
            start = start === -1 ? 0 : start;
            end = end === -1 ? xml.length : end;
            result += `\n${xml.substr(start, end - start)}`;
        }
    }
    return result;
};

export const defaultPromiseHandler = (aPromise) => {
    return aPromise.catch((err) => {
        if (err) {
            console.error('defaultPromiseHandler::Caught unhandled error:');
            console.error(err.message);
            console.error(err.stack);
        }
    });
};

export const LogLabels = {
    SALT: 'salt:engine',
};
