import React from 'react';
import * as PropTypes from 'prop-types';
import MuiTextField from '@material-ui/core/TextField';

import {makeStyles} from '@material-ui/core/styles';
import getStyles from './TextField.styles';

const INPUT_TYPES = {
    TEXT: 'text',
    PASSWORD: 'password',
    EMAIL: 'email',
    NUMBER: 'number',
};

/**
 * TextField is a component that allows users to add or modify the input.
 * @see https://material-ui.com/demos/text-fields/
 */
const TextField = (props) => {
    const {
        autoFocus,
        autoGrow,
        contextStyles,
        disabled,
        disableUnderline,
        endAdornment,
        iconOffsetWidth,
        maxLength,
        multiline,
        onChange,
        rowsMax,
        startAdornment,
        testID,
        toolTip,
        type,
        value,
        onSubmit,
        autoComplete,
        placeholder,
        propertyRef,
        readOnly,
        variant,
        ...rest
    } = props;

    // Override core styles with context styles, separating MUI styles
    const {
        ...muiStyles
    } = getStyles(contextStyles, autoGrow, value, iconOffsetWidth);

    // Create dynamic class names and injected DOM styles for MUI component
    // * makeStyles returns a function
    const styles = makeStyles(muiStyles)();

    const oultineVariant = variant === 'outlined' ? { notchedOutline: styles.notchedOutline } : {};

    // Generate button props
    const inputProps = {
        autoFocus,
        classes: {
            root: styles.root, // MUI styles
        },
        className: styles.root,
        disabled,
        InputProps: {
            classes: {
                root: styles.input, // MUI styles
                // disabled: styles.disabled,
                focused: styles.focused,
                ...oultineVariant,
            },
            endAdornment,
            inputProps: {
                className: styles.input,
                maxLength,
            },
            startAdornment,
        },
        inputRef: propertyRef,
        multiline,
        onChange: (event) => {
            onChange(event, props);
        },
        onKeyPress: (event) => {
            if (event.key === 'Enter' && onSubmit) {
                onSubmit(event, props);
            }
        },
        onFocus: (event) => {
            // On focus, select the text in input when it is not a multiline field.
            if (!multiline) {
                event.target.select();
            }
        },
        rowsMax,
        title: toolTip,
        type,
        value,
        autoComplete,
        placeholder,
        readOnly,
        variant,
        ...rest,
    };
    if (testID) {
        inputProps['data-test-id'] = `${testID}__text-field__input`;
    }

    return (
        <MuiTextField { ...inputProps } />
    );
};

TextField.propTypes = {
    /** String that helps autofilling form control by browser */
    autoComplete: PropTypes.string,

    /** Focuses the input */
    autoFocus: PropTypes.bool,

    /** Grows/shrinks the input based on the size of its text value */
    autoGrow: PropTypes.bool,

    /** Styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the container surrounding the text field */
        container: PropTypes.object,

        /** Styles for the text field */
        input: PropTypes.object,

        /** Styles for the text field underline */
        underline: PropTypes.object,

        /** Styles for the text field underline in a focused state */
        underlineFocus: PropTypes.object,

        /** Styles for the text field underline in a hover state */
        underlineHover: PropTypes.object,
    }),

    /** Prevents the text field from accepting input */
    disabled: PropTypes.bool,

    /** Diables the underline styling for input field */
    disableUnderline: PropTypes.bool,

    /** Elements that needs to be placed towards the end of the TextField */
    endAdornment: PropTypes.node,

    /** Extra width to offset textfield adornment space set in px*/
    iconOffsetWidth: PropTypes.number,

    /** Limits the maximum number of characters that can be entered */
    maxLength: PropTypes.number,

    /** Enables the text input to be multiple lines */
    multiline: PropTypes.bool,

    /**
     * Called when the TextField's input changes
     * @param {Object} event - The native change event
     * @param {Object} props - The component instance props
     */
    onChange: PropTypes.func,

    /**
     * Called when enter is pressed on TextField
     * @param {Object} event - The native key press event
     * @param {Object} props - The component instance props
     */
    onSubmit: PropTypes.func,

    /** Placeholder text for input field */
    placeholder: PropTypes.string,

    /** Field holding the reference to Property field */
    propertyRef: PropTypes.object,

    /** Prevents the text field from editing input */
    readOnly: PropTypes.bool,

    /** Maximum number of rows in case of multiline */
    rowsMax: PropTypes.number,

    /** Elements that needs to be placed at the beginning of the TextField */
    startAdornment: PropTypes.node,

    /** Id used for testing */
    testID: PropTypes.string,

    /** toolTip string */
    toolTip: PropTypes.string,

    /** Determines the type of input */
    type: PropTypes.oneOf([
        INPUT_TYPES.TEXT,
        INPUT_TYPES.PASSWORD,
        INPUT_TYPES.EMAIL,
        INPUT_TYPES.NUMBER,
    ]),

    /** The text to populate in the input */
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),

    /** Number of rows to display when multiline option is set to true. */
    rows: PropTypes.number,

    /** If true, the input will take up the full width of its container. */
    fullWidth: PropTypes.bool,

    /** The variant to use. */
    variant: PropTypes.string,
};

TextField.defaultProps = {
    contextStyles: {},
    type: INPUT_TYPES.TEXT,
    // Need to default to this to prevent chrome autofilling values
    autoComplete: 'new-password',
    fullWidth: false,
};

export default TextField;
export {
    INPUT_TYPES,
};
