/*       */

import {
    PROPERTY,
    PROPERTY_DATA,
    PROPERTY_EDIT,
    PROPERTY_LABEL,
    PROPERTY_MARGIN,
    PROPERTY_TEXT,
    PROPERTY_WIDTH,
} from '../../exportClassConstants';

import PropStyle from '../plist/PropStyle';
import SimpleFloatAttribute from '../SimpleFloatAttribute';
import SimpleStringAttribute from '../SimpleStringAttribute';
import MutedLayoutAttribute from '../MutedLayoutAttribute';
import Width from '../general/Width';
import SimpleXStyleLayoutAttribute from '../SimpleXStyleLayoutAttribute';
import AbstractLayoutFactory from './AbstractLayoutFactory';

class PlistLayoutFactory extends AbstractLayoutFactory {
    constructor() {
        super();
        const attributesAndAliases = [
            // 'constWidth',
            'dataColor',
            'dataFontSize:dataSize',
            'dataMargin',
            'dataStyle',
            'dataWidth:valueWidth',
            'editBackgroundColor',
            'editColor:editTextColor',
            'editDataWidth:editValueWidth:editTextWidth',
            'editStyle',
            'labelFontSize:labelSize',
            'labelColor',
            'labelMargin',
            'labelPlacement',
            'labelStyle',
            'labelValueSeparator',
            'labelWidth',
            'propSeparator',
            'textFontSize:textSize',
            'textStyle',
            'textColor',
            'textMargin',
        ];
        this.init(attributesAndAliases);
    }

    initFactory() {
        super.initFactory();
        const { factory } = this;
        factory.dataFontSize = (json, key) => {
            return new SimpleFloatAttribute('dataFontSize', this.getAsString(json, key), [ PROPERTY, PROPERTY_DATA ], true, 'font-size');
        };
        factory.dataStyle = (json, key, context) => {
            const { warnings } = context;
            return PropStyle.newAttribute(warnings, 'dataStyle', this.getAsString(json, key), [ PROPERTY, PROPERTY_DATA ], true);
        };
        factory.dataColor = (json, key) => {
            return new SimpleStringAttribute('dataColor', this.getAsString(json, key), [ PROPERTY, PROPERTY_DATA ], true, 'color');
        };
        factory.dataMargin = (json, key) => {
            return new SimpleXStyleLayoutAttribute('dataMargin', this.getAsString(json, key), [ PROPERTY, PROPERTY_DATA, PROPERTY_MARGIN ], true, 'margin');
        };
        factory.dataWidth = (json, key) => {
            return new Width('dataWidth', this.getAsString(json, key), [ PROPERTY, PROPERTY_DATA, PROPERTY_WIDTH ], true, 'width');
        };
        factory.editBackgroundColor = (json, key) => {
            return new SimpleStringAttribute('editBackgroundColor', this.getAsString(json, key), [ PROPERTY, PROPERTY_EDIT ], true, 'backgroundColor');
        };
        factory.editColor = (json, key) => {
            return new SimpleStringAttribute('editColor', this.getAsString(json, key), [ PROPERTY, PROPERTY_EDIT ], true, 'color');
        };
        factory.editDataWidth = (json, key) => {
            return new Width('editDataWidth', this.getAsString(json, key), [ PROPERTY, PROPERTY_EDIT, PROPERTY_WIDTH ], true, 'width');
        };
        factory.editStyle = (json, key, context) => {
            const { warnings } = context;
            return PropStyle.newAttribute(warnings, 'editStyle', this.getAsString(json, key), [ PROPERTY, PROPERTY_EDIT ], true);
        };
        factory.labelFontSize = (json, key) => {
            return new SimpleFloatAttribute('labelFontSize', this.getAsString(json, key), [ PROPERTY, PROPERTY_LABEL ], true, 'font-size');
        };
        factory.labelColor = (json, key) => {
            return new SimpleStringAttribute('labelColor', this.getAsString(json, key), [ PROPERTY, PROPERTY_LABEL ], true, 'color');
        };
        factory.labelMargin = (json, key) => {
            return new SimpleXStyleLayoutAttribute('labelMargin', this.getAsString(json, key), [ PROPERTY, PROPERTY_LABEL, PROPERTY_MARGIN ], true, 'margin');
        };
        factory.labelPlacement = (json, key, context) => {
            const { warnings } = context;
            const value = this.getAsString(json, key);
            if (![ 'left', 'topLeft', 'topCenter', 'topRight', 'none' ].includes(value)) {
                warnings.addInvalidValue('labelPlacement', value);
            }
            return new MutedLayoutAttribute('labelPlacement', value, [ PROPERTY ], true);
        };
        factory.labelStyle = (json, key, context) => {
            const { warnings } = context;
            return PropStyle.newAttribute(warnings, 'labelStyle', this.getAsString(json, key), [ PROPERTY, PROPERTY_LABEL ], true);
        };
        factory.labelValueSeparator = (json, key) => {
            return new MutedLayoutAttribute('labelValueSeparator', this.getAsString(json, key), [ PROPERTY ], true);
        };
        factory.labelWidth = (json, key) => {
            return new Width('labelWidth', this.getAsString(json, key), [ PROPERTY, PROPERTY_LABEL, PROPERTY_WIDTH ], true, 'width');
        };
        factory.propSeparator = (json, key) => {
            return new MutedLayoutAttribute('propSeparator', this.getAsString(json, key), [ PROPERTY ], true);
        };
        factory.textFontSize = (json, key) => {
            return new SimpleFloatAttribute('textFontSize', this.getAsString(json, key), [ PROPERTY, PROPERTY_TEXT ], true, 'font-size');
        };
        factory.textColor = (json, key) => {
            return new SimpleStringAttribute('textColor', this.getAsString(json, key), [ PROPERTY, PROPERTY_TEXT ], true, 'color');
        };
        factory.textMargin = (json, key) => {
            return new SimpleXStyleLayoutAttribute('textMargin', this.getAsString(json, key), [ PROPERTY, PROPERTY_TEXT, PROPERTY_MARGIN ], true, 'margin');
        };
        factory.textStyle = (json, key, context) => {
            const { warnings } = context;
            return PropStyle.newAttribute(warnings, 'textStyle', this.getAsString(json, key), [ PROPERTY, PROPERTY_TEXT ], true);
        };
    }
}
const plistLayoutFactory = new PlistLayoutFactory();
export default plistLayoutFactory;
