import moment from 'moment';
import numeral from 'numeral';
import locales from 'numeral/locales';
import { CodeRef } from './CodeRef';
import { GpsReadingProperty } from './GpsReadingProperty';
import { MapLocationProperty } from './MapLocationProperty';
import { ObjectRef } from './ObjectRef';
import { TypeNames } from './types';
// Chose the locales to load based on this list:
// https://stackoverflow.com/questions/9711066/most-common-locales-for-worldwide-compatibility
// Best effort for now.  Need to dynamically load these from Globalize???
import 'moment/locale/de';
import 'moment/locale/en-ca';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/ja';
import 'moment/locale/pt';
import 'moment/locale/pt-br';
import 'moment/locale/ru';
import 'moment/locale/sv';
import 'moment/locale/zh-cn';
import 'moment/locale/fi';
import 'moment/locale/da';
import 'moment/locale/nb';
import 'moment/locale/nn';
// we must use these to prevent giving moment non-existent locales that cause it hurl in RN
// @see https://github.com/moment/moment/issues/3624#issuecomment-288713419
const supportedLocales = ['en', 'de', 'en-ca', 'en-gb', 'es', 'fr', 'it', 'ja', 'pt', 'pt-br', 'ru', 'sv', 'zh-cn', 'fi', 'da', 'nb', 'nn'];
import { DateTimeValue } from '../util/DateTimeValue';
import { DateValue } from '../util/DateValue';
import { TimeValue } from '../util/TimeValue';
const localesInit = locales;
const rightHandCurrencySymbolLocales = ['fr', 'sv', 'ru'];
/**
 * Helper for transforming values to and from formats suitable for reading and writing to the server
 * (i.e. object to string and string to object)
 */
class PrivatePropFormats {
}
PrivatePropFormats.decimalFormat = [
    '0,0',
    '0,0.0',
    '0,0.00',
    '0,0.000',
    '0,0.0000',
    '0,0.00000',
    '0,0.000000',
    '0,0.0000000',
    '0,0.00000000',
    '0,0.000000000',
    '0,0.0000000000'
];
PrivatePropFormats.decimalFormatGeneric = '0,0.[0000000000000000000000000]';
PrivatePropFormats.moneyFormat = [
    '$0,0',
    '$0,0.0',
    '$0,0.00',
    '$0,0.000',
    '$0,0.0000',
    '$0,0.00000',
    '$0,0.000000',
    '$0,0.0000000',
    '$0,0.00000000',
    '$0,0.000000000',
    '$0,0.0000000000'
];
PrivatePropFormats.moneyFormatGeneric = '$0,0.00[0000000000000000000000000]';
PrivatePropFormats.moneyFormatRH = [
    '0,0 $',
    '0,0.0 $',
    '0,0.00 $',
    '0,0.000 $',
    '0,0.0000 $',
    '0,0.00000 $',
    '0,0.000000 $',
    '0,0.0000000 $',
    '0,0.00000000 $',
    '0,0.000000000 $',
    '0,0.0000000000 $'
];
PrivatePropFormats.moneyFormatGenericRH = '0,0.00[0000000000000000000000000] $';
PrivatePropFormats.percentFormat = [
    '0,0%',
    '0,0%',
    '0,0%',
    '0,0.0%',
    '0,0.00%',
    '0,0.000%',
    '0,0.0000%',
    '0,0.00000%',
    '0,0.000000%',
    '0,0.0000000%',
    '0,0.00000000%'
];
PrivatePropFormats.percentFormatGeneric = '0,0.[0000000000000000000000000]%';
PrivatePropFormats.wholeFormat = '0,0';
export class PropertyFormatter {
    static singleton(catavoltApi) {
        if (!PropertyFormatter._singleton) {
            PropertyFormatter._singleton = new PropertyFormatter(catavoltApi);
        }
        return PropertyFormatter._singleton;
    }
    constructor(_catavoltApi) {
        this._catavoltApi = _catavoltApi;
        if (PropertyFormatter._singleton) {
            throw new Error('Singleton instance already created');
        }
        this.resetFormats();
        PropertyFormatter._singleton = this;
    }
    /**
     * Get a string representation of this property suitable for showing in read mode
     * @param prop
     * @param propDef
     * @returns {string}
     */
    formatForRead(prop, propDef) {
        if (prop === null || prop === undefined) {
            return '';
        }
        else {
            return this.formatValueForRead(prop.value, propDef);
        }
    }
    formatValueForRead(value, propDef) {
        const locales = [this.getResolvedLocale(this._catavoltApi.locale.langCountryString)];
        if (value === null || value === undefined) {
            return '';
        }
        else if ((propDef && propDef.isListType) || Array.isArray(value)) {
            return value.reduce((prev, current) => {
                return (prev ? prev + ', ' : '') + this.formatValueForRead(current, propDef);
            }, '');
        }
        else if ((propDef && propDef.isCodeRefType) || value instanceof CodeRef) {
            return value.description;
        }
        else if ((propDef && propDef.isObjRefType) || value instanceof ObjectRef) {
            return value.description;
        }
        else if ((propDef && propDef.isDateTimeType) || value instanceof DateTimeValue) {
            if (typeof value === 'string')
                return value;
            const dateValue = (value instanceof DateTimeValue) ? value.dateObj : value;
            if (propDef.isShortDate)
                return `${moment(dateValue).locale(locales).format('l')} ${moment(dateValue).locale(locales).format('LT')}`;
            return moment(dateValue)
                .locale(locales)
                .format('lll');
        }
        else if ((propDef && propDef.isTimeType) || value instanceof TimeValue) {
            if (typeof value === 'string')
                return value;
            return moment(value)
                .locale(locales)
                .format('LT');
        }
        else if ((propDef && propDef.isDateType) || value instanceof Date || value instanceof DateValue) {
            if (typeof value === 'string')
                return value;
            const dateValue = (value instanceof DateValue) ? value.dateObj : value;
            // If short date is configured by the administrator, change the output format
            if (propDef.isShortDate)
                return moment(dateValue).locale(locales).format('l');
            return moment(dateValue)
                .locale(locales)
                .format('ll');
        }
        else if (propDef && propDef.isPasswordType) {
            return value.replace(/./g, '*');
        }
        // else if ((propDef && propDef.isListType) || Array.isArray(value)) {
        //     return value.reduce((prev, current) => {
        //         return (prev ? prev + ', ' : '') + this.formatValueForRead(current, null);
        //     }, '');
        // } 
        else {
            return this.toString(value, propDef);
        }
    }
    /**
     * Get a string representation of this property suitable for showing in write mode
     * @param prop
     * @param propDef
     * @returns {string | string[]}
     */
    formatForWrite(prop, propDef) {
        if (prop === null || prop === undefined || prop.value === null || prop.value === undefined) {
            return null;
        }
        else if (Array.isArray(prop.value)) {
            return prop.value.map((item) => {
                if (item.type === TypeNames.CodeRefTypeName) {
                    return item.code;
                }
                if (item.type === TypeNames.ObjectRefTypeName) {
                    return item.objectId;
                }
                if (!item.type) {
                    return item;
                }
            });
        }
        else if ((propDef && propDef.isCodeRefType) || (prop.value instanceof CodeRef)) {
            return prop.value.code;
        }
        else if ((propDef && propDef.isObjRefType) || (prop.value instanceof ObjectRef)) {
            return prop.value.objectId;
        }
        else {
            return this.toStringWrite(prop.value, propDef);
        }
    }
    /**
     * Get formatted value for field action
     * @param prop
     * @param propDef
     * @returns {}
     */
    formatForAction(prop, propDef) {
        if (prop === null || prop === undefined || prop.value === null || prop.value === undefined) {
            return null;
        }
        else if (propDef && propDef.isTimeType) {
            if (prop.value instanceof TimeValue || prop.value instanceof Date) {
                return (prop.value instanceof TimeValue) ? prop.value.toDateValue() : prop.value;
            }
            return null;
        }
        else if (propDef && (propDef.isDateType || propDef.isDateTimeType)) {
            if (prop.value instanceof Date)
                return prop.value;
            return null;
        }
        return this.formatForWrite(prop, propDef);
    }
    /**
     * Attempt to construct (or preserve) the appropriate data type given primitive (or already constructed) value.
     * Note this must be done here and not at 'write' time because it requires the knowledge of the PropDef
     * @param value
     * @param propDef
     * @returns {}
     */
    parse(value, propDef) {
        const locale = this.getResolvedLocale(this._catavoltApi.locale.langCountryString);
        let propValue = value;
        if (propDef.isDecimalType) {
            let newVal = value;
            if (typeof value === 'string') {
                newVal = value.replace(',', '');
                newVal = newVal.length === 1 ? newVal.replace('.', '') : newVal; // Guard '.' = NaN
            }
            // Though we get empty value ('') here, number casting will return 0.
            // In case of sideeffects, this disables the user from clearing the textfield as it will always have 0.
            // So, cast it to a number only when we have valid value.
            propValue = (propValue || propValue === 0) ? Number(newVal) : null;
        }
        else if (propDef.isLongType) {
            const newVal = typeof value === 'string' ? value.replace(',', '') : value;
            propValue = Number(newVal);
        }
        else if (propDef.isIntType) {
            const newVal = typeof value === 'string' ? value.replace(',', '') : value;
            propValue = propValue ? Number(newVal) : 0;
        }
        else if (propDef.isBooleanType) {
            if (typeof value === 'string') {
                propValue = value.toLowerCase() !== 'false' && value.toLowerCase() !== 'no' && value !== '0';
            }
            else {
                propValue = !!value;
            }
        }
        else if (propDef.isDateType) {
            // this could be a DateValue, a Date, or a string
            if (value === '') {
                propValue = null;
            }
            else if (value instanceof DateValue) {
                propValue = value;
            }
            else if (typeof value === 'object') {
                propValue = new DateValue(value);
            }
            else {
                // parse as local time
                propValue = new DateValue(moment(value, propDef.getDateFormatString(locale)).toDate());
            }
        }
        else if (propDef.isDateTimeType) {
            // this could be a DateTimeValue, a Date, or a string
            if (value === '') {
                propValue = null;
            }
            else if (value instanceof DateTimeValue) {
                propValue = value;
            }
            else if (typeof value === 'object') {
                propValue = new DateTimeValue(value);
            }
            else {
                // parse as local time
                propValue = new DateTimeValue(moment(value, propDef.getDateFormatString(locale)).toDate());
            }
        }
        else if (propDef.isTimeType) {
            if (value === '') {
                propValue = null;
            }
            else if (value instanceof TimeValue) {
                propValue = value;
            }
            else if (typeof value === 'object') {
                propValue = TimeValue.fromDateValue(value);
            }
            else {
                propValue = TimeValue.fromDateValue(moment(value, propDef.getDateFormatString(locale)).toDate());
            }
        }
        else if (propDef.isCodeRefType) {
            if (typeof value === 'string') {
                propValue = CodeRef.fromString(value);
            }
        }
        else if (propDef.isMapLocationType) {
            if (typeof value === 'string') {
                propValue = MapLocationProperty.fromString(value);
            }
        }
        else if (propDef.isObjRefType) {
            if (typeof value === 'string') {
                propValue = ObjectRef.fromString(value);
            }
        }
        else if (propDef.isGpsReadingType) {
            propValue = GpsReadingProperty.fromObject(value);
        }
        return propValue;
    }
    resetFormats() {
        this.decimalFormat = PrivatePropFormats.decimalFormat.slice(0);
        this.decimalFormatGeneric = PrivatePropFormats.decimalFormatGeneric;
        this.moneyFormat = PrivatePropFormats.moneyFormat.slice(0);
        this.moneyFormatRH = PrivatePropFormats.moneyFormatRH.slice(0);
        this.moneyFormatGeneric = PrivatePropFormats.moneyFormatGeneric;
        this.moneyFormatGenericRH = PrivatePropFormats.moneyFormatGenericRH;
        this.percentFormat = PrivatePropFormats.percentFormat.slice(0);
        this.percentFormatGeneric = PrivatePropFormats.percentFormatGeneric;
        this.wholeFormat = PrivatePropFormats.wholeFormat;
        this.registerAdditionalLocales();
    }
    toString(o, propDef) {
        return this.toStringRead(o, propDef);
    }
    /**
     * Render this value as a string
     *
     * @param o
     * @param {PropertyDef} propDef
     * @returns {string}
     */
    toStringRead(o, propDef) {
        const locales = [this.getResolvedLocale(this._catavoltApi.locale.langCountryString)];
        if (typeof o === 'number') {
            if (propDef && !propDef.isUnformattedNumericType) {
                const locale = this.getResolvedLocale(this._catavoltApi.locale.langCountryString);
                numeral.locale(locale);
                if (propDef.isMoneyType) {
                    let f = null;
                    if (rightHandCurrencySymbolLocales.includes(locale)) {
                        f = propDef.scale < this.moneyFormatRH.length
                            ? this.moneyFormatRH[propDef.scale] || this.moneyFormatGenericRH
                            : this.moneyFormatGenericRH;
                    }
                    else {
                        f = propDef.scale < this.moneyFormat.length
                            ? this.moneyFormat[propDef.scale] || this.moneyFormatGeneric
                            : this.moneyFormatGeneric;
                    }
                    // If there is a currency symbol, remove it noting it's position pre/post
                    // Necessary because numeral will replace $ with the symbol based on the locale of the browser.
                    // This may be desired down the road, but for now, the server provides the symbol to use.
                    const atStart = f.length > 0 && f[0] === '$';
                    const atEnd = f.length > 0 && f[f.length - 1] === '$';
                    if (this._catavoltApi.currencySymbol) {
                        f = f.replace('$', ''); // Format this as a number, and slam in Extender currency symbol
                        let formatted = numeral(o).format(f);
                        if (atStart) {
                            formatted = this._catavoltApi.currencySymbol + formatted;
                        }
                        if (atEnd) {
                            formatted = formatted + this._catavoltApi.currencySymbol;
                        }
                        return formatted;
                    }
                    else {
                        return numeral(o).format(f); // Should substitute browsers locale currency symbol
                    }
                }
                else if (propDef.isPercentType) {
                    const f = propDef.scale < this.percentFormat.length
                        ? this.percentFormat[propDef.scale] || this.percentFormatGeneric
                        : this.percentFormatGeneric;
                    return numeral(o).format(f); // numeral accomplishs * 100, relevant if we use some other symbol
                }
                else if (propDef.isIntType || propDef.isLongType) {
                    return numeral(o).format(this.wholeFormat);
                }
                else if (propDef.isDecimalType || propDef.isDoubleType) {
                    const f = propDef.scale < this.decimalFormat.length
                        ? this.decimalFormat[propDef.scale] || this.decimalFormatGeneric
                        : this.decimalFormatGeneric;
                    return numeral(o).format(f);
                }
                else {
                    return String(o);
                }
            }
            else {
                return String(o);
            }
        }
        else if (typeof o === 'object') {
            if ((propDef && propDef.isTimeType) || o instanceof TimeValue) {
                return moment(o)
                    .locale(locales)
                    .format('LT');
            }
            else if (o instanceof Date) {
                return o.toISOString();
            }
            else if (o instanceof DateValue) {
                return o.dateObj.toISOString();
            }
            else if (o instanceof DateTimeValue) {
                return o.dateObj.toISOString();
            }
            else if (o instanceof CodeRef) {
                return o.toString();
            }
            else if (o instanceof ObjectRef) {
                return o.toString();
            }
            else if (o instanceof GpsReadingProperty) {
                return o.toString();
            }
            else if (o instanceof MapLocationProperty) {
                return o.toString();
            }
            else {
                return String(o);
            }
        }
        else {
            return String(o);
        }
    }
    toStringWrite(o, propDef) {
        const locale = this.getResolvedLocale(this._catavoltApi.locale.langCountryString);
        if (typeof o === 'number' && propDef) {
            if (propDef.isMoneyType) {
                return o.toFixed(2);
            }
            else if (propDef.isIntType || propDef.isLongType || !propDef.scale) {
                return o.toFixed(0);
            }
            else if (propDef.isDecimalType || propDef.isDoubleType) {
                // Show at least one decimal place so user knows decimal entry is allowed.  Don't show trailing 0s.
                // In case of sideeffects, if we show one default decimal place, it cannot be backspaced. 
                // For example: 0.0| --> you backspace to 0.| --> the client immediately goes back to 0.0| if we have 1 as the fixed default decimal number.
                // So, use 0 in case of sideeffects.
                return o.toFixed(Math.max(propDef.canCauseSideEffects ? 0 : 1, (o.toString().split('.')[1] || []).length));
            }
        }
        else if (propDef && propDef.isTimeType) {
            if (o instanceof TimeValue || o instanceof Date) {
                const dateValue = (o instanceof TimeValue) ? o.toDateValue() : o;
                return moment(dateValue)
                    .locale(locale)
                    .format('LT');
            }
            return o;
        }
        else if (propDef && propDef.isDateType) {
            if (o instanceof Date || o instanceof DateValue || (o && typeof o === 'string')) {
                let dateOValue;
                if (typeof o === 'string') {
                    dateOValue = new Date(o);
                }
                else {
                    dateOValue = (o instanceof DateValue) ? o.dateObj : o;
                }
                const dateValue = moment(dateOValue)
                    .locale(locale)
                    .format('L');
                return dateValue;
            }
            return o;
        }
        else if (propDef && propDef.isDateTimeType) {
            if (o instanceof Date || o instanceof DateTimeValue) {
                const dateTimeOValue = (o instanceof DateTimeValue) ? o.dateObj : o;
                const dateTimeValue = moment(dateTimeOValue)
                    .locale(locale)
                    .format(propDef.getDateFormatString(locale));
                return dateTimeValue;
            }
            return o;
        }
        else {
            return this.toStringRead(o, propDef);
        }
    }
    getResolvedLocale(locale) {
        if (!this._resolvedLocale) {
            if (supportedLocales.includes(locale)) {
                this._resolvedLocale = locale;
            }
            else {
                const sepIndex = locale.indexOf('-');
                if (sepIndex > -1) {
                    const lang = locale.substring(0, sepIndex);
                    if (supportedLocales.includes(lang)) {
                        this._resolvedLocale = lang;
                    }
                }
                this._resolvedLocale = this._catavoltApi.DEFAULT_LOCALE.language;
            }
        }
        return this._resolvedLocale;
    }
    registerAdditionalLocales() {
        numeral.register("locale", "sv", {
            delimiters: {
                thousands: ' ',
                decimal: ','
            },
            abbreviations: {
                thousand: 'k',
                million: 'mn',
                billion: 'md',
                trillion: 'bn'
            },
            ordinal: function (number) {
                var a = Math.abs(number) % 10, b = Math.abs(number) % 100;
                if ((a === 1 || a === 2) && (b !== 11 && b !== 12)) {
                    return ':a';
                }
                return ':e';
            },
            currency: {
                symbol: 'kr'
            }
        });
        numeral.register("locale", "en-ca", {
            delimiters: {
                thousands: ',',
                decimal: '.'
            },
            abbreviations: {
                thousand: 'k',
                million: 'm',
                billion: 'b',
                trillion: 't'
            },
            ordinal: function (number) {
                var b = number % 10;
                return (~~(number % 100 / 10) === 1) ? 'th' :
                    (b === 1) ? 'st' :
                        (b === 2) ? 'nd' :
                            (b === 3) ? 'rd' : 'th';
            },
            currency: {
                symbol: '$'
            }
        });
        numeral.register("locale", "zh-cn", {
            delimiters: {
                thousands: ',',
                decimal: '.'
            },
            abbreviations: {
                thousand: 'k',
                million: 'm',
                billion: 'b',
                trillion: 't'
            },
            ordinal: function (number) {
                var b = number % 10;
                return (~~(number % 100 / 10) === 1) ? 'th' :
                    (b === 1) ? 'st' :
                        (b === 2) ? 'nd' :
                            (b === 3) ? 'rd' : 'th';
            },
            currency: {
                symbol: '$'
            }
        });
        numeral.register('locale', 'pt', {
            delimiters: {
                thousands: '.',
                decimal: ','
            },
            abbreviations: {
                thousand: 'mil',
                million: 'milhões',
                billion: 'b',
                trillion: 't'
            },
            ordinal: function (number) {
                return 'º';
            },
            currency: {
                symbol: 'R$'
            }
        });
        numeral.register('locale', 'da', {
            delimiters: {
                thousands: '.',
                decimal: ','
            },
            abbreviations: {
                thousand: 'k',
                million: 'mio',
                billion: 'mia',
                trillion: 'b'
            },
            ordinal: function (number) {
                return '.';
            },
            currency: {
                symbol: 'DKK'
            }
        });
        numeral.register('locale', 'nb', {
            delimiters: {
                thousands: ' ',
                decimal: ','
            },
            abbreviations: {
                thousand: 'k',
                million: 'm',
                billion: 'b',
                trillion: 't'
            },
            ordinal: function (number) {
                return '.';
            },
            currency: {
                symbol: 'kr'
            }
        });
        numeral.register("locale", "nn", {
            delimiters: {
                thousands: ',',
                decimal: '.'
            },
            abbreviations: {
                thousand: 'k',
                million: 'm',
                billion: 'b',
                trillion: 't'
            },
            ordinal: function (number) {
                var b = number % 10;
                return (~~(number % 100 / 10) === 1) ? 'th' :
                    (b === 1) ? 'st' :
                        (b === 2) ? 'nd' :
                            (b === 3) ? 'rd' : 'th';
            },
            currency: {
                symbol: '$'
            }
        });
    }
}
