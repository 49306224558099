/**
 */
export class Download_Manager_QueuedItems_RECORDS {
    static copyOfResponse() {
        return JSON.parse(JSON.stringify(this.RESPONSE));
    }
}
Download_Manager_QueuedItems_RECORDS.PATH = 'tenants/${tenantId}/sessions/${sessionId}/dialogs/12/records';
Download_Manager_QueuedItems_RECORDS.BODY = {
    "fetchDirection": "FORWARD",
    "fetchMaxRecords": 50,
    "fromRecordId": "6H9B000A",
    "type": "hxgn.api.dialog.QueryParameters"
};
Download_Manager_QueuedItems_RECORDS.RESPONSE = {
    "defaultActionId": null,
    "records": [{
            "annotations": [],
            "id": "6H9B000A",
            "type": "hxgn.api.dialog.Record",
            "properties": [{
                    "name": "workpackageid",
                    "format": null,
                    "annotations": [],
                    "type": "hxgn.api.dialog.Property",
                    "value": "6GW7000A"
                }, {
                    "name": "tagid",
                    "format": null,
                    "annotations": [],
                    "type": "hxgn.api.dialog.Property",
                    "value": "6FQA03BA"
                }, {
                    "name": "name",
                    "format": null,
                    "annotations": [],
                    "type": "hxgn.api.dialog.Property",
                    "value": "teacup"
                }, {
                    "name": "description",
                    "format": null,
                    "annotations": [],
                    "type": "hxgn.api.dialog.Property",
                    "value": "teacup is empty"
                }, {
                    "name": "documentid",
                    "format": null,
                    "annotations": [],
                    "type": "hxgn.api.dialog.Property",
                    "value": null
                }, {
                    "name": "mobilecommentid",
                    "format": null,
                    "annotations": [],
                    "type": "hxgn.api.dialog.Property",
                    "value": "6H9B000A"
                }, {
                    "name": "picture",
                    "format": null,
                    "annotations": [],
                    "type": "hxgn.api.dialog.Property",
                    "value": null
                }, { "name": "status", "format": null, "annotations": [], "type": "hxgn.api.dialog.Property", "value": null }]
        }],
    "dialogAlias": "Download_Manager_QueuedItems",
    "hasMore": false,
    "type": "hxgn.api.dialog.RecordSet",
    "dialogName": "Download_Manager_QueuedItems"
};
