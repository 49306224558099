import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import { constants } from '../constants';
import componentFactory from './componentFactory';
import engineConstants from './engineConstants';
import SaltComponent from './SaltComponent';
import SaltContext from './SaltContext';
import Layout from './xStyle/Layout';
import uiHelper from '../utilities/uiHelper';

/* eslint-disable react/prefer-stateless-function */
@observer
export default class Box extends SaltComponent {
    static propTypes = {
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xStyle: PropTypes.oneOfType([
            Layout.layoutPropType,
            PropTypes.arrayOf(Layout.layoutPropType),
        ]),
        assert: PropTypes.shape({
            refName: PropTypes.string,
            expr: PropTypes.string,
        }),
        // allows styling of the 'interior' content wrapper in a scrollpane
        scrollContentStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        animate: PropTypes.shape({
            effect: PropTypes.string.isRequired,
            duration: PropTypes.number,
            easing: PropTypes.string,
            iterations: PropTypes.number,
            delay: PropTypes.number,
        }),
        viewId: PropTypes.string,
        resizable: PropTypes.bool,
        direction: PropTypes.string,
        identifier: PropTypes.string,
    };

    static typeName = engineConstants.component.name.box;

    render() {
        const { assert, resizable, direction } = this.props;
        const viewId = this.getViewId(this.props.viewId);
        const { dialogStore, saltStore, scopeManager } = this.contextParams;

        if (dialogStore && dialogStore.dataChange) {
            if (this.determineVisibility(assert, dialogStore, scopeManager, saltStore)) {
                const resolvedProps = this.resolveProperties(Box.typeName);
                return this.wrapWithContext(
                    Box.typeName,
                    resolvedProps,
                    React.createElement(
                        componentFactory.getPlatformComponent('box'),
                        { ...resolvedProps, onResizeStop: this.handleResizeStop, resizable, direction, viewId, ...this.extractDimensions() },
                    ),
                );
            }
        }
        return null;
    }

    get contextParams() {
        const { identifier, viewId } = this.props;
        const viewID = this.getViewId(viewId);
        const { saltStore, scopeManager } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewID);
        const { view } = dialogStore.dialog;
        const viewSectionId = uiHelper.getViewId(view, true);
        const { uiStore } = saltStore;
        return { viewSectionId, uiStore, identifier, dialogStore, saltStore, scopeManager };
    }

    handleResizeStop = (_e, _dir, ref) => {
        const {
            style: {
                width,
                height,
            },
        } = ref;
        const { viewSectionId, uiStore, identifier } = this.contextParams;
        uiStore.setValueForUIObject(`${viewSectionId}_${identifier}`, constants.ui.DIALOG_WIDTH, width);
        uiStore.setValueForUIObject(`${viewSectionId}_${identifier}`, constants.ui.DIALOG_HEIGHT, height);
    };

    extractDimensions = () => {
        const { viewSectionId, uiStore, identifier } = this.contextParams;
        return {
            initialWidth: uiStore.getValueForUIObject(`${viewSectionId}_${identifier}`, constants.ui.DIALOG_WIDTH),
            initialHeight: uiStore.getValueForUIObject(`${viewSectionId}_${identifier}`, constants.ui.DIALOG_HEIGHT),
        };
    }
}

Box.contextType = SaltContext;
