import { rootStore } from 'cv-react-core';

// Core component styles
const coreStyles = {
    container: {
        backgroundColor: '#F2F2F2',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0px',
        paddingBottom: '5px',
        paddingTop: '3px',
    },

    text: {
    },

    textContainer: {
        paddingLeft: '15px',
        paddingRight: '27px',
    },

    versionContainer: {
        display: 'flex',
    },
};

/**
* Helper method to get the styles for the component
* @param {Object} contextStyles - Context styles for the Component
*/
const getStyles = (contextStyles = {}) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();
    const textStyles = {
        ...theme.fonts.default,
        color: 'rgba(0, 0, 0, 0.6)',
        fontFamily: 'Roboto',
        fontSize: '10px',
        lineHeight: '12px',
    };

    return {
        container: {
            ...coreStyles.container,
            // backgroundColor: theme.colors.alternate1,
            ...contextStyles.container,
        },

        text: {
            ...coreStyles.text,
            ...textStyles,
            ...contextStyles.text,
        },

        textContainer: {
            ...coreStyles.textContainer,
            ...contextStyles.textContainer,
        },

        versionContainer: {
            ...coreStyles.versionContainer,
            ...contextStyles.versionContainer,
        },
    };
};

export default getStyles;
