import { rootStore } from 'cv-react-core';

const getTheme = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    // Populate the component parts with theme styles
    return {
        bar: {
            backgroundColor: theme.statusLoadingIndicatorColor,
        },

        modal: {
            backgroundColor: theme.statusLoadingBackgroundColor,
            shadowColor: theme.modalShadowColor,
        },

        overlay: {
        },

        text: {
            ...theme.fonts.loadingStatusMinor,
        },

        textContainer: {
        },
    };
};

export default getTheme;
