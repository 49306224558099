import Layout from './Layout';

const width = (layout) => {
    const { width } = layout;
    if (width) {
        // Handle the multi-part values.
        const style = {};
        if (width === '*meta' || width === '*min') {
            // Do nothing.  These values are not currently supported.
        } else {
            style.width = Layout.parseValue(width);
        }
        return style;
    }
    return {};
};

export default width;
