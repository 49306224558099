import engineConstants from '../engineConstants';

const styles = {
    [engineConstants.component.name.fieldAction]: {
        base: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 4,
            paddingRight: 4,
            marginLeft: 3,
            cursor: 'pointer',
            borderRadius: 4,
        },
        xStyles: {
            supportedXStyles: [ 'icon', 'hover', 'focus' ],
            icon: {
                width: 20,
                height: 20,
            },
            hover: {
                borderColor: '#006E96',
            },
            focus: {
                borderColor: '#006E96',
                borderWidth: 2,
                backgroundColor: '#E4F2F3',
            },
        },
    },
    [engineConstants.action.type.buttonPrimary]: {
        base: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: 12,
            paddingRight: 20,
            paddingBottom: 12,
            paddingLeft: 20,
            backgroundColor: '#006E96',
            borderRadius: 4,
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: 14,
            textAlign: 'center',
            letterSpacing: 0.5,
            color: '#ffffff',
            height: 40,
        },
        xStyles: {
            supportedXStyles: [ 'hover', 'focus', 'disabled' ],
            hover: {
                backgroundColor: '#004A65',
            },
            focus: {
                backgroundColor: '#004A65',
            },
            disabled: {
                backgroundColor: '#004A65',
                opacity: 0.5,
            },
        },
    },
    [engineConstants.action.type.buttonSecondary]: {
        base: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: 12,
            paddingRight: 20,
            paddingBottom: 12,
            paddingLeft: 20,
            backgroundColor: 'none',
            borderRadius: 4,
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: 14,
            textAlign: 'center',
            letterSpacing: 0.5,
            color: '#006E96',
            height: 40,
            borderColor: '#006E96',
            borderWidth: 1,
            borderStyle: 'solid',
        },
        xStyles: {
            supportedXStyles: [ 'hover', 'focus', 'disabled' ],
            hover: {
                borderColor: '#006E96',
                borderWidth: 2,
            },
            focus: {
                borderColor: '#006E96',
                borderWidth: 2,
            },
            disabled: {
                color: '#004A65',
                borderColor: '#004A65',
                opacity: 0.5,
            },
        },
    },
    [engineConstants.component.name.property]: {
        base: {
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: 16,
            alignItems: 'center',
            letterSpacing: 0.02,
            color: '#121212',
            paddingBottom: 4,
            paddingTop: 4,
        },
        xStyles: {
            supportedXStyles: [ 'clearIcon', 'colorPalette', 'ellipsizeMode', 'option', 'expandIcon', 'optionSelected', 'optionHover', 'dropdown', 'hideLeftOverCharacters', 'nonSaltPropertyBGColor' ],
            clearIcon: {
                fontSize: 12,
                backgroundColor: 'transparent',
                position: 'relative',
            },
            colorPalette: {
                borderRadius: 2,
                marginLeft: 10,
                width: 26,
                height: 26,
            },
            dropdown: {
                paddingLeft: 8,
                paddingRight: 8,
                paddingBottom: 8,
                paddingTop: 8,
            },
            ellipsizeMode: 'tail',
            option: {
                flexGrow: 1,
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 16,
                alignItems: 'center',
                letterSpacing: 0.02,
                color: '#121212',
                paddingLeft: 8,
                paddingRight: 8,
                borderStyle: 'solid',
                borderColor: 'rgb(224, 224, 224)',
                width: 180,
                borderWidth: 0,
                borderRadius: 0,
                marginRight: 0,
                display: 'flex',
                flexDirection: 'row',
                paddingTop: 13,
                paddingBottom: 13,
            },
            expandIcon: {
                color: '#000000',
            },
            optionSelected: {
                backgroundColor: 'rgba(28, 142, 169, 0.2)',
            },
            optionHover: {
                backgroundColor: '#F5F5F5',
            },
            hideLeftOverCharacters: true,
            nonSaltPropertyBGColor: {
                backgroundColor: 'rgba(0, 0, 0, 0.09)',
            },
        },
    },
    [engineConstants.component.name.box]: {
        base: {
            flexShrink: 0,
        },
    },
};

// Paper form Style object to override or provide specific styles
const paperMarkupStyles = JSON.parse(JSON.stringify(styles));
paperMarkupStyles[engineConstants.component.name.fieldAction].base = {
    ...styles[engineConstants.component.name.fieldAction].base,
    paddingTop: 4,
    paddingBottom: 4,
};
paperMarkupStyles[engineConstants.component.name.property].xStyles.nonSaltPropertyBGColor = {};
paperMarkupStyles[engineConstants.component.name.box].base = {};

export default class BaseStyles {
    static getStyles(componentName, paperMarkup) {
        const baseStyles = paperMarkup ? paperMarkupStyles : styles;
        return baseStyles[componentName]?.base || {};
    }

    static getXStyles(componentName, paperMarkup) {
        const baseStyles = paperMarkup ? paperMarkupStyles : styles;
        return baseStyles[componentName]?.xStyles || {};
    }
}
