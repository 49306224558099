/**
 * Currently only Boxes, Actions, Images are added to the parentNode chain (See Box.render SaltProvider wrapper)
 */
export default class ParentNode {
    constructor(name, props, parentNode) {
        this.name = name;
        this.props = props;
        this.parentNode = parentNode;
    }

    static getNearestFlexNode(node) {
        if (node.name === 'Box'
            || node.name === 'Action'
            || node.name === 'Image') {
            return node;
        }
        if (node.parentNode) {
            return ParentNode.getNearestFlexNode(node.parentNode);
        }
        return null;
    }

    getNearestFlexNode() {
        return ParentNode.getNearestFlexNode(this);
    }
}
