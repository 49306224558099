import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Map as NativeMap, GoogleApiWrapper } from '../MapsWrapper';

import getStyles from './Map.styles';

/**
 * Map component allows a user to view Google map.
 * @see https://developers.google.com/maps/documentation/javascript/reference/map
 * @see https://github.com/fullstackreact/google-maps-react
 */
class Map extends Component {
    render() {
        const {
            contextStyles,
            children,
            testID,
            fitToPins,
            showsUserLocation,
            maxNumberOfRoutes,
            showsMyLocationButton,
            onMapReady,
            followsUserLocation,
            onBoundsChanged,
            onMarkerClick,
            enableMapToggle,
            bounds,
            ...remainingProps
        } = this.props;

        // Override core styles with context styles
        const mapStyles = getStyles(contextStyles);

        const mapProps = {
            style: mapStyles,
            fitToPins,
            centerAroundCurrentLocation: showsUserLocation,
            maxNumberOfRoutes,
            showsMyLocationButton,
            bounds,
            onReady: onMapReady,
            followsUserLocation,
            onMarkerClick,
            onClick: this.handleClick,
            testID,
            mapTypeControl: enableMapToggle,
            mapTypeControlOptions: {
                // eslint-disable-next-line no-undef
                position: google.maps.ControlPosition.LEFT_BOTTOM,
                mapTypeIds: [
                    'roadmap',
                    'satellite',
                ],
            },
            onBounds_changed: onBoundsChanged,
            ...remainingProps,
        };

        return (
            <NativeMap
                ref={ (map) => { this.map = map; } }
                { ...mapProps }>
                { children }
            </NativeMap>
        );
    }

    /**
     * Sets the center of the map
     */
    setCenter = (position) => {
        if (position){
            this.map.setCenter({
                lat: position.latitude,
                lng: position.longitude,
            });
        }
    };

    /**
     * Sets the zoom of the map
     */
    setZoom = (zoom) => {
        if (zoom){
            this.map.setZoom(zoom);
        }
    };

    /**
     * Handle when map was right clicked
     */
    handleClick = (props, map, event) => {
        const { latLng } = event;
        if (latLng){
            const coordinate = {
                latitude: latLng.lat(),
                longitude: latLng.lng(),
            };
            const { onMapClick } = this.props;
            if (onMapClick) { onMapClick(coordinate); }
        }
    };

    /**
     * Helper method to convert points array to google bounds and fit the map
     */
    fitToMarkers = (points) => {
        // eslint-disable-next-line no-undef
        const bounds = new google.maps.LatLngBounds();
        for (let i = 0; i < points.length; i += 1) {
            bounds.extend(points[i]);
        }
        this.map.fitBounds(bounds);
    }

    /**
     * Helper method to get directions from origin to destination
     */
    getDirections = (origin, destination, callback, errback) => {
        // eslint-disable-next-line no-undef
        const directionsService = new google.maps.DirectionsService();

        directionsService.route({
            origin: `${origin.latitude },${ origin.longitude}`,
            destination: `${destination.latitude },${ destination.longitude}`,
            // eslint-disable-next-line no-undef
            unitSystem: google.maps.UnitSystem.IMPERIAL,
            travelMode: 'DRIVING',
            provideRouteAlternatives: true,
        }, function success(response, status) {
            if (status === 'OK' && callback) {
                callback(response);
            }
            else if ( errback){
                errback(status);
            }
        });
    }
};

Map.propTypes = {
    /** Styles for this component */
    contextStyles: PropTypes.object,

    /** Map Content */
    children: PropTypes.node,

    /** Fits the map to the pins when loaded */
    fitToPins: PropTypes.bool,

    /** Maximum number of routes to be displayed on map */
    maxNumberOfRoutes: PropTypes.number,

    /** Show user location */
    showsUserLocation: PropTypes.bool,

    /** Display my location button */
    showsMyLocationButton: PropTypes.bool,

    /** Follows the user location */
    followsUserLocation: PropTypes.bool,

    /** Called when marker was pressed */
    onMarkerClick: PropTypes.func,

    /** Called when map was clicked */
    onMapClick: PropTypes.func,

    /** Callback that is called once the map is fully loaded */
    onMapReady: PropTypes.func,

    /** Bounds of the map */
    bounds: PropTypes.object,

    /**
     * Called when the bounds are changed
     * @param {Object} region - The selected region
     */
    onBoundsChanged: PropTypes.func,

    /** Enable map toggle button */
    enableMapToggle: PropTypes.bool,

    /** Id used for testing */
    testID: PropTypes.string,
};

Map.defaultProps = {
    contextStyles: {},
    maxNumberOfRoutes: 3,
    fitToPins: false,
    showsUserLocation: false,
    showsMyLocationButton: true,
    followsUserLocation: true,
    enableMapToggle: true,
};


export default GoogleApiWrapper({
    apiKey: 'AIzaSyC5WpEECHQ9kJqI9KTZh4tTIYAbEqJpRUE',
})(Map);
