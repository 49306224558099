/*       */
import AbstractControl from './AbstractControl';

export default class GaugeChart extends AbstractControl {
    get animate() { return this.valueAt('Animate') === 'true'; }
    get gaugeImageMode() { return this.valueAt('GaugeImageMode'); }
    get gaugeImageURL() { return this.valueAt('GaugeImageURL'); }
    get pointerColor() { return this.color('PointerColor'); }
    get pointerHorizontalLength() { return this.floatAt('PointerHorizontalLength'); }
    get pointerVerticalLength() { return this.floatAt('PointerVerticalLength'); }
    get pointerPivotX() { return this.floatAt('PointerPivotX'); }
    get pointerPivotY() { return this.floatAt('PointerPivotY'); }
    get pointerThickness() { return this.floatAt('PointerThickness'); }
    get pointerStyle() { return this.floatAt('PointerStyle'); }
    get maxDegrees() { return this.floatAt('MaxDegrees'); }
    get minDegrees() { return this.floatAt('MinDegrees'); }
    get maxValue() { return this.floatAt('MaxValue'); }
    get minValue() { return this.floatAt('MinValue'); }
    get reverse() { return this.valueAt('Reverse') === 'true'; }
    get valueCenterX() { return this.floatAt('ValueCenterX'); }
    get valueCenterY() { return this.floatAt('ValueCenterY'); }
    get valueColor() { return this.color('ValueColor'); }
    get valuePlacement() { return this.valueAt('ValuePlacement'); }
    get valueFormat() { return this.valueAt('ValueFormat'); }
    get valueTextSize() { return this.floatAt('ValueTextSize'); }
    get valueWidth() { return this.floatAt('ValueWidth'); }

    newReadOnlyPropertyStyleSet() {
        const result = super.newReadOnlyPropertyStyleSet();
        const gaugeChartProp = { };
        gaugeChartProp.gaugeImageMode = this.gaugeImageMode;
        gaugeChartProp.gaugeImageURL = this.gaugeImageURL;
        gaugeChartProp.pointerColor = this.pointerColor;
        gaugeChartProp.pointerHorizontalLength = this.pointerHorizontalLength;
        gaugeChartProp.pointerVerticalLength = this.pointerVerticalLength;
        gaugeChartProp.pointerPivotX = this.pointerPivotX;
        gaugeChartProp.pointerPivotY = this.pointerPivotY;
        gaugeChartProp.pointerThickness = this.pointerThickness;
        gaugeChartProp.pointerStyle = this.pointerStyle;
        gaugeChartProp.maxDegrees = this.maxDegrees;
        gaugeChartProp.minDegrees = this.minDegrees;
        gaugeChartProp.animate = this.animate;
        gaugeChartProp.reverse = this.reverse;
        if ((this.minValue || this.maxValue) && (this.minValue !== this.maxValue)) {
            gaugeChartProp.minValue = this.minValue;
            gaugeChartProp.maxValue = this.maxValue;
        }
        if (this.valueFormat) {
            gaugeChartProp.valueCenterX = this.valueCenterX;
            gaugeChartProp.valueCenterY = this.valueCenterY;
            gaugeChartProp.valueColor = this.valueColor;
            gaugeChartProp.valuePlacement = this.valuePlacement;
            gaugeChartProp.valueFormat = this.valueFormat;
            gaugeChartProp.valueTextSize = this.valueTextSize;
            if (this.valueWidth) {
                gaugeChartProp.valueWidth = this.valueWidth;
            }
        }
        result.xStyle.gaugeChartProp = gaugeChartProp;
        return result;
    }
}
