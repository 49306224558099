import React from 'react';
import * as PropTypes from 'prop-types';
import getStyles from './ThreeOneRightForm.styles';
import Resizable from '../Resizable/Resizable';

const ThreeOneRightForm = (props) => {
    const {
        contextStyles,
        formElements,
        onResizeStop,
        scrollIdentifier,
    } = props;

    const {
        anchorScrollColumn,
        mainContentRowSizingContainer,
        column,
        overflowColumn,
        rowSizingContainer,
    } = getStyles(contextStyles);

    const minWidthFitContent = { minWidth: 'max-content' };
    const defaultProps = {
        props: {
            dimensions: {},
            isDetailsView: false,
        },
    };
    const { props: { isDetailsView: f1 } } = formElements[0] || defaultProps;
    const { props: { isDetailsView: f2 } } = formElements[1] || defaultProps;
    const { props: { isDetailsView: f3 } } = formElements[2] || defaultProps;
    const minWidthTopLeft = f1 ? minWidthFitContent : null;
    const minWidthBottomLeft = f2 ? minWidthFitContent : null;
    const minWidthRight = f3 ? minWidthFitContent : null;
    const maxHeightForDViewFormEle1 = f1 ? rowSizingContainer : {};
    const maxHeightForDViewFormEle2 = f2 ? rowSizingContainer : {};

    const {
        props: {
            viewId: viewId0,
            dimensions: dimensions0,
        },
    } = formElements[0] || defaultProps;
    const {
        props: {
            viewId: viewId1,
            dimensions: dimensions1,
        },
    } = formElements[1] || defaultProps;
    const {
        props: {
            viewId: viewId2,
            dimensions: dimensions2,
        },
    } = formElements[2] || defaultProps;

    let defaultWidth0; let defaultWidth1; let
        defaultWidth2;
    if (dimensions0.width) {
        defaultWidth0 = dimensions0.width;
    }
    else {
        defaultWidth0 = f1 ? 'max-content' : undefined;
    }

    if (dimensions1.width) {
        defaultWidth1 = dimensions1.width;
    }
    else {
        defaultWidth1 = f2 ? 'max-content' : undefined;
    }

    if (dimensions2.width) {
        defaultWidth2 = dimensions2.width;
    }
    else {
        defaultWidth2 = f3 ? 'max-content' : '600px';
    }

    return (
        <div
            className="anchor-column-scollar-container"
            data-scroll-identifier={ scrollIdentifier }
            style={ anchorScrollColumn }>
            <div
                className="top-row-container"
                key="content-row-container-top"
                style={ { ...mainContentRowSizingContainer } }>
                <div
                    className="left-column-content"
                    key="left-column-content"
                    style={ { ...column } }>
                    <div
                        style={ maxHeightForDViewFormEle1 }>
                        <Resizable
                            className="left-column-content-top"
                            defaultSize={ {
                                width: defaultWidth0,
                                height: dimensions0.height,
                            } }
                            direction="vertical"
                            key="left-column-content-top"
                            onResizeStop={ onResizeStop }
                            style={ { ...mainContentRowSizingContainer, ...minWidthTopLeft } }
                            viewid={ viewId0 }>
                            { formElements[0] }
                        </Resizable>
                    </div>
                    { formElements[1] &&
                        <div
                            style={ maxHeightForDViewFormEle2 }>
                            <Resizable
                                className="left-column-content-bottom"
                                defaultSize={ {
                                    width: defaultWidth1,
                                    height: dimensions1.height,
                                } }
                                direction="vertical"
                                key="left-column-content-bottom"
                                onResizeStop={ onResizeStop }
                                style={ { marginTop: '16px', ...mainContentRowSizingContainer, ...minWidthBottomLeft } }
                                viewid={ viewId1 }>
                                { formElements[1] }
                            </Resizable>
                        </div>
                    }
                </div>
                { formElements[2] &&
                    <Resizable
                        className="right-column-content"
                        defaultSize={ {
                            width: defaultWidth2,
                            height: dimensions2.height,
                        } }
                        direction="bothAlt"
                        key="right-column-content"
                        onResizeStop={ onResizeStop }
                        style={ { marginLeft: '16px', ...column, ...minWidthRight } }
                        viewid={ viewId2 }>
                        { formElements[2] }
                    </Resizable>
                }
            </div>
            { formElements.length > 3 &&
                formElements.slice(3).map((formElement) => {
                    const {
                        props: {
                            dimensions,
                            viewId,
                        },
                    } = formElement;
                    return (
                        <div
                            key={ `overflow-content-row-${viewId}` }
                            style={ { marginTop: '16px', ...rowSizingContainer, ...mainContentRowSizingContainer } }>
                            <Resizable
                                className="overflow-column-content"
                                defaultSize={ {
                                    width: dimensions.width,
                                    height: dimensions.height,
                                } }
                                direction="both"
                                key={ `left-column-content-bottom-${viewId}` }
                                onResizeStop={ onResizeStop }
                                style={ { ...overflowColumn } }
                                viewid={ viewId }>
                                { formElement }
                            </Resizable>
                        </div>
                    );
                })
            }
        </div>
    );
};

ThreeOneRightForm.propTypes = {
    contextStyles: PropTypes.shape({
        anchorScrollColumn: PropTypes.object,
        mainContentRowSizingContainer: PropTypes.object,
        column: PropTypes.object,
        overflowColumn: PropTypes.object,
    }),
    formElements: PropTypes.arrayOf(PropTypes.element),
    scrollIdentifier: PropTypes.string,
    onResizeStop: PropTypes.func,
};

ThreeOneRightForm.defaultProps = {
    contextStyles: {},
    formElements: [],
};

export default ThreeOneRightForm;
