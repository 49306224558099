import { TypeNames } from './types';
export class GpsReadingProperty {
    constructor(accuracy, latitude, longitude, source = 'GPS', type = TypeNames.GpsReadingPropertyTypeName) {
        this.accuracy = accuracy;
        this.latitude = latitude;
        this.longitude = longitude;
        this.source = source;
        this.type = type;
    }
    static fromObject(geoLocation) {
        if (!geoLocation) {
            return null;
        }
        const { accuracy, latitude, longitude } = geoLocation;
        return new GpsReadingProperty(Number(accuracy), Number(latitude), Number(longitude));
    }
}
