/*       */

import SimpleFloatAttribute from '../SimpleFloatAttribute';
import SimpleStringAttribute from '../SimpleStringAttribute';

import { SIGNATURE } from '../../exportClassConstants';
import AbstractLayoutFactory from './AbstractLayoutFactory';

class SignaturePropLayoutFactory extends AbstractLayoutFactory {
    constructor() {
        super();
        const attributesAndAliases = [
            'signatureLineColor',
            'signatureLineWidth',
            'signatureBackgroundColor',
            'signatureWidth',
            'signatureHeight',
        ];
        this.init(attributesAndAliases);
    }

    initFactory() {
        super.initFactory();
        const { factory } = this;
        factory.signatureLineColor = (json, key) => {
            return new SimpleStringAttribute('lineColor', this.getAsString(json, key), [ SIGNATURE ], true);
        };
        factory.signatureLineWidth = (json, key) => {
            return new SimpleFloatAttribute('lineThickness', this.getAsString(json, key), [ SIGNATURE ], true);
        };
        factory.signatureBackgroundColor = (json, key) => {
            return new SimpleStringAttribute('backgroundColor', this.getAsString(json, key), [ SIGNATURE ], true);
        };
        factory.signatureWidth = (json, key) => {
            return new SimpleFloatAttribute('width', this.getAsString(json, key), [ SIGNATURE ], true);
        };
        factory.signatureHeight = (json, key) => {
            return new SimpleFloatAttribute('height', this.getAsString(json, key), [ SIGNATURE ], true);
        };
    }
}

const signaturePropLayoutFactory = new SignaturePropLayoutFactory();
export default signaturePropLayoutFactory;
