/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import componentFactory from '../componentFactory';
import componentPropTypes from './abstractComponentPropTypes';

const propDefinitions = {
    style: componentPropTypes.style,
    value: componentPropTypes.value,
    tipText: componentPropTypes.tipText,
    isReadMode: componentPropTypes.isReadMode,
    isFocused: componentPropTypes.isFocused,
    onValueChanged: componentPropTypes.onValueChanged,
    xStyle: componentPropTypes.xStyle,
    maxLength: componentPropTypes.maxLength,
};

export default class TextFieldWithColorPalette extends Component {
    static propTypes = propDefinitions;

    render() {
        const regxValidationPattern = '^(|#[0-9a-f]{0,6})$';

        const textFieldProps = {
            ...this.props,
            regxValidationPattern,
        };
        return React.createElement(componentFactory.getPlatformPropertyComponent('textFieldWithColorPalette'), { ...textFieldProps });
    }
}
