// Core component styles
const coreStyles = {
    container: {
        display: 'flex',
        flexGrow: 1,
        flexShrink: 1,
        flexDirection: 'column',
        borderRadius: '6px',
        boxShadow: 'none',

        overflow: 'hidden',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#E0E0E0',
    },

    content: {
        display: 'flex',
        flexGrow: 1,
        flexShrink: 1,
        flexDirection: 'column',
        overflow: 'hidden',
    },
};

/**
 * Helper method to get the styles for Activity Indicator component
 * @param {Object} contextStyles - Context styles for Activity Indicator Component
 */
const getStyles = (contextStyles = {}) => {
    return {
        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        content: {
            ...coreStyles.content,
            ...contextStyles.content,
        },
    };
};

export default getStyles;
