// Core component styles
const coreStyles = {
    chartTextStyle: {
    },

    container: {
    },

    legendContainerStyle: {
        bottom: 10,
        left: 'center',
        lineHeight: 14,
    },

    legendTextStyle: {
    },

    titleTextStyle: {
    },

    tooltipContainerStyle: {
        backgroundColor: '#000',
        borderRadius: 2,
    },

    tooltipTextStyle: {
        lineHeight: 13,
    },

    xAxisTextStyle: {
    },

    yAxisTextStyle: {
    },
};

/**
 * Helper method to get styles for the Scatter Bubble Chart
 * @param {Object} contextStyles - custom styles for the Scatter Bubble chart
 */
const getStyles = (contextStyles) => {
    return {
        chartTextStyle: {
            ...coreStyles.chartTextStyle,
            ...contextStyles.chartTextStyle,
        },

        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        legendContainerStyle: {
            ...coreStyles.legendContainerStyle,
            ...contextStyles.legendContainerStyle,
        },

        legendTextStyle: {
            ...coreStyles.legendTextStyle,
            ...contextStyles.legendTextStyle,
        },

        titleTextStyle: {
            ...coreStyles.titleTextStyle,
            ...contextStyles.titleTextStyle,
        },

        tooltipContainerStyle: {
            ...coreStyles.tooltipContainerStyle,
            ...contextStyles.tooltipContainerStyle,
        },

        tooltipTextStyle: {
            ...coreStyles.tooltipTextStyle,
            ...contextStyles.tooltipTextStyle,
        },

        xAxisTextStyle: {
            ...coreStyles.xAxisTextStyle,
            ...contextStyles.xAxisTextStyle,
        },

        yAxisTextStyle: {
            ...coreStyles.yAxisTextStyle,
            ...contextStyles.yAxisTextStyle,
        },
    };
};

export default getStyles;
