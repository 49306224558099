import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';

import { XaltButton, XaltTextLabel, XaltIconButton, XaltForwardButton, XaltBackButton } from 'xalt-react-atoms';
import getStyles from './TablePagingContainer.styles';

class TablePagingContainer extends PureComponent {
    static propTypes = {
        pageSizes: PropTypes.array,
        onGoToPage: PropTypes.func,
        onCurrentPageSizeChange: PropTypes.func,
        onGoToNextPage: PropTypes.func,
        onGoToPreviousPage: PropTypes.func,
        pagingItemTitle: PropTypes.string,
        pageNumber: PropTypes.number,
        pagesRetrieved: PropTypes.number,
        pageSize: PropTypes.number,
        canPageForward: PropTypes.bool,
        canPageBack: PropTypes.bool,
    };

    render() {
        const {
            pageSizes,
            onGoToNextPage,
            onGoToPreviousPage,
            pagingItemTitle,
            canPageForward,
            canPageBack } = this.props;
        const styles = getStyles();

        return (
            <div style={ styles.pageWrapper }>
                <div style={ styles.pageSizingContainer }>
                    <XaltTextLabel style={ {...styles.pagingText } }>
                        { pagingItemTitle || '' }
                    </XaltTextLabel>
                    <div style={ { ...styles.pageSizingContainer, ...styles.pagingContainer } }>
                        { pageSizes.map((size) => this.generatepaginationButton(size, styles, 'pageSize')) }
                    </div>
                </div>
                <div style={ styles.pageNumberingContainer }>
                    <XaltIconButton
                        style={ styles.pagerButton }
                        disabled={ !canPageBack }
                        onClick={ onGoToPreviousPage }>
                        <XaltBackButton style={ canPageBack ? styles.icon : { ...styles.icon, ...styles.disabledIcon } } />
                    </XaltIconButton>
                    { this.renderPagination() }
                    <XaltIconButton
                        style={ styles.pagerButton }
                        disabled={ !canPageForward }
                        onClick={ onGoToNextPage }>
                        <XaltForwardButton style={ canPageForward ? styles.icon : { ...styles.icon, ...styles.disabledIcon } } />
                    </XaltIconButton>
                </div>
            </div>
        );
    }

    renderPagination = () => {
        const {
            pageNumber,
            pagesRetrieved,
            canPageForward,
        } = this.props;
        const styles = getStyles();
        const pagingOptions = [];
        // Page numbers to be shown
        const pages = [
            1,
            pageNumber - 1,
            pageNumber,
            pageNumber + 1,
            pagesRetrieved,
        ];
        const ellipsisPages = [
            2,
            pagesRetrieved - 1,
        ];
        /* eslint-disable no-plusplus */
        for (let page = 1; page <= pagesRetrieved; page++) {
            let PageButton;
            if (pages.includes(page)) {
                PageButton = this.generatepaginationButton(page, styles);
            }
            else if (ellipsisPages.includes(page)) {
                PageButton = (
                    <div
                        key={ `ellipsis_${page}` }
                        style={ styles.ellipsis }>
                        ...
                    </div>
                );
            }
            pagingOptions.push(PageButton);
        }
        if (canPageForward && pageNumber === pagesRetrieved) {
            const PageButton = this.generatepaginationButton(pagesRetrieved + 1, styles);
            pagingOptions.push(PageButton);
        }
        return pagingOptions;
    }

    generatepaginationButton = (buttonText, styles, buttonType = 'pageNumber') => {
        const {
            onGoToPage,
            onCurrentPageSizeChange,
            pageSize,
            pageNumber,
        } = this.props;
        const buttonProps = {
            style: pageSize === buttonText ? { ...styles.primary, ...styles.pagingText, ...styles.primaryText } : { ...styles.secondary, ...styles.pagingText },
            onClick: () => onCurrentPageSizeChange(buttonText),
            disabled: pageSize === buttonText,
        };

        if (buttonType === 'pageNumber') {
            const textStyles = pageNumber === buttonText ? { ...styles.pagingText, ...styles.primaryText } : styles.pagingText;
            buttonProps.style = pageNumber === buttonText ? { ...styles.primary, ...textStyles} : { ...styles.secondary, ...textStyles };
            buttonProps.onClick = () => onGoToPage(buttonText);
            buttonProps.disabled = pageNumber === buttonText;
        }
        return (
            <XaltButton
                { ...buttonProps }
                key={ buttonText }>
                { buttonText }
            </XaltButton>
        );
    }
}

export default TablePagingContainer;