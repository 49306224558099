/*       */

import MutedLayoutAttribute from '../MutedLayoutAttribute';
import SimpleFloatAttribute from '../SimpleFloatAttribute';
import SimpleStringAttribute from '../SimpleStringAttribute';

import FlexDirection from '../general/FlexDirection';
import Scroll from '../general/Scroll';
import AlignItems from '../general/AlignItems';
import JustifyContent from '../general/JustifyContent';
import Width from '../general/Width';
import Height from '../general/Height';
import { GENERAL, GENERAL_FLEX, GENERAL_MARGIN } from '../../exportClassConstants';
import Expand from '../general/Expand';
import SimpleXStyleLayoutAttribute from '../SimpleXStyleLayoutAttribute';
import AbstractLayoutFactory from './AbstractLayoutFactory';
import Base from '../../model/Base';
import StrictMode from '../general/StrictMode';
import StrictModeExceptions from '../general/StrictModeExceptions';
import DebugColor from '../general/DebugColor';
import AllowShrink from '../general/AllowShrink';
import AlignSelf from '../general/AlignSelf';

class GeneralLayoutFactory extends AbstractLayoutFactory {
    constructor() {
        super();
        const attributesAndAliases = [
            'actionId:actionID',
            'alignItems:align',
            'alignSelf',
            'allowConstShrink',
            'allowDataShrink',
            'allowLabelShrink',
            'allowShrink',
            'backgroundColor',
            'backgroundImage',
            'backgroundNineSliceInsets',
            'borderColor',
            'borderWidth:borderThickness',
            'borderRadius:cornerRadius',
            'debugColor',
            'equallySized',
            'expand:expandable',
            'flexDirection:orientation',
            'height',
            'justifyContent:distribute',
            'margin',
            'marginBottom',
            'marginLeft',
            'marginRight',
            'marginTop',
            'minHeight',
            'minWidth',
            'overflow',
            'padding',
            'paddingBottom',
            'paddingLeft',
            'paddingRight',
            'paddingTop',
            'gmlId',
            'scroll',
            'strictMode',
            'strictModeExceptions',
            'width',
        ];
        this.init(attributesAndAliases);
    }

    initFactory() {
        super.initFactory();
        const { factory } = this;
        factory.actionId = (json, key) => {
            return new MutedLayoutAttribute('actionId', this.getAsString(json, key), [ GENERAL ]);
        };
        factory.alignItems = (json, key, context) => {
            const { warnings } = context;
            const modern = this.getAsString(json, 'alignItems');
            const legacy = this.getAsString(json, 'align');
            return AlignItems.newAttribute(warnings, modern, legacy);
        };
        factory.alignSelf = (json, key, context) => {
            const { warnings } = context;
            return AlignSelf.newAttribute(warnings, this.getAsString(json, key));
        };
        factory.allowConstShrink = (json, key) => {
            return new MutedLayoutAttribute('allowConstShrink', this.getAsString(json, key), [], true);
        };
        factory.allowDataShrink = (json, key) => {
            return new MutedLayoutAttribute('allowDataShrink', this.getAsString(json, key), [], true);
        };
        factory.allowLabelShrink = (json, key) => {
            return new MutedLayoutAttribute('allowLabelShrink', this.getAsString(json, key), [], true);
        };
        factory.allowShrink = (json, key) => {
            return new AllowShrink('allowShrink', this.getAsString(json, key), [ GENERAL ], false);
        };
        factory.backgroundColor = (json, key) => {
            return new SimpleStringAttribute('backgroundColor', this.getAsString(json, key), [ GENERAL ]);
        };
        factory.backgroundImage = (json, key) => {
            return new MutedLayoutAttribute('backgroundImage', this.getAsString(json, key), [ GENERAL ]);
        };
        factory.backgroundNineSliceInsets = (json, key) => {
            return new MutedLayoutAttribute('backgroundNineSliceInsets', this.getAsString(json, key), [ GENERAL ]);
        };
        factory.borderColor = (json, key) => {
            return new SimpleStringAttribute('borderColor', this.getAsString(json, key), [ GENERAL ], true);
        };
        factory.borderWidth = (json, key) => {
            return new SimpleFloatAttribute('borderWidth', this.getAsString(json, key), [ GENERAL ]);
        };
        factory.borderRadius = (json, key) => {
            return new SimpleXStyleLayoutAttribute('borderRadius', this.getAsString(json, key), [ GENERAL ]);
        };
        factory.debugColor = (json, key) => {
            return new DebugColor('debugColor', this.getAsString(json, key), [ GENERAL ], true);
        };
        factory.equallySized = (json, key) => {
            return new MutedLayoutAttribute('equallySized', this.getAsString(json, key), [ GENERAL, GENERAL_FLEX ]);
        };
        factory.expand = (json, key, context) => {
            const { warnings } = context;
            const modern = this.getAsString(json, 'expand');
            const legacy = this.getAsString(json, 'expandable');
            return Expand.newAttribute(warnings, 'expand', (modern || legacy), [ GENERAL, GENERAL_FLEX ], false);
        };
        factory.flexDirection = (json, key, context) => {
            const { warnings } = context;
            const modern = this.getAsString(json, 'flexDirection');
            if (modern) {
                if (Object.values(FlexDirection.VALUES).includes(modern)) {
                    return modern === FlexDirection.VALUES.ROW ? FlexDirection.row : FlexDirection.column;
                }
                warnings.addInvalidValue('flexDirection', modern);
            } else {
                // Convert the legacy horizontal/vertical to the flex-direction row/column.
                const legacy = this.getAsString(json, key);
                if ([ 'horizontal', 'vertical' ].includes(legacy)) {
                    return legacy === 'horizontal' ? FlexDirection.row : FlexDirection.column;
                }
                warnings.addInvalidValue('orientation', legacy);
            }
            return null;
        };
        factory.height = (json, key) => {
            return new Height('height', this.getAsString(json, key), [ GENERAL ]);
        };
        factory.justifyContent = (json, key, context) => {
            const { warnings } = context;
            const modern = this.getAsString(json, 'justifyContent');
            const legacy = this.getAsString(json, 'distribute');
            return JustifyContent.newAttribute(warnings, modern, legacy);
        };
        factory.margin = (json, key) => {
            return new SimpleXStyleLayoutAttribute('margin', this.getAsString(json, key), [ GENERAL, GENERAL_MARGIN ]);
        };
        factory.marginBottom = (json, key) => {
            return new SimpleFloatAttribute('marginBottom', this.getAsString(json, key), [ GENERAL, GENERAL_MARGIN ]);
        };
        factory.marginLeft = (json, key) => {
            return new SimpleFloatAttribute('marginLeft', this.getAsString(json, key), [ GENERAL, GENERAL_MARGIN ]);
        };
        factory.marginRight = (json, key) => {
            return new SimpleFloatAttribute('marginRight', this.getAsString(json, key), [ GENERAL, GENERAL_MARGIN ]);
        };
        factory.marginTop = (json, key) => {
            return new SimpleFloatAttribute('marginTop', this.getAsString(json, key), [ GENERAL, GENERAL_MARGIN ]);
        };
        factory.minHeight = (json, key) => {
            return new Height('minHeight', this.getAsString(json, key), [ GENERAL ], false, 'minHeight');
        };
        factory.minWidth = (json, key) => {
            return new Width('minWidth', this.getAsString(json, key), [ GENERAL ], false, 'minWidth');
        };
        factory.overflow = (json, key) => {
            return new SimpleFloatAttribute('overflow', this.getAsString(json, key), [ GENERAL ]);
        };
        factory.padding = (json, key) => {
            return new SimpleXStyleLayoutAttribute('padding', this.getAsString(json, key), [ GENERAL ]);
        };
        factory.paddingBottom = (json, key) => {
            return new SimpleFloatAttribute('paddingBottom', this.getAsString(json, key), [ GENERAL ]);
        };
        factory.paddingLeft = (json, key) => {
            return new SimpleFloatAttribute('paddingLeft', this.getAsString(json, key), [ GENERAL ]);
        };
        factory.paddingRight = (json, key) => {
            return new SimpleFloatAttribute('paddingRight', this.getAsString(json, key), [ GENERAL ]);
        };
        factory.paddingTop = (json, key) => {
            return new SimpleFloatAttribute('paddingTop', this.getAsString(json, key), [ GENERAL ]);
        };
        factory.gmlId = (json, key) => {
            return new SimpleStringAttribute('gmlId', this.getAsString(json, key), [ GENERAL ]);
        };
        factory.scroll = (json, key, context) => {
            if (context) {
                let result = null;
                const { warnings } = context;
                // rawValue will be true/false.  Construct Scroll with a direction of horizontal/vertical.
                const rawValue = this.getAsString(json, key);
                if (rawValue) {
                    if (rawValue === 'true') {
                        const dir = Base.isRow(json) ? 'horizontal' : 'vertical';
                        result = new Scroll(dir);
                    } else if (rawValue !== 'false') {
                        warnings.addInvalidValue('scroll', rawValue);
                    }
                }
                // Scroll is not allowed in V1 or in V2 if this is a details with header/footer
                // FYI - the V2 syntax of row/rowProps is being used to discern direction.
                return this.reportScrollWarning(context, result, rawValue);
            }
            return null;
        };
        factory.strictMode = (json, key) => {
            return new StrictMode(this.getAsString(json, key));
        };
        factory.strictModeExceptions = (json, key) => {
            return new StrictModeExceptions(this.getAsString(json, key));
        };
        factory.width = (json, key) => {
            return new Width('width', this.getAsString(json, key), [ GENERAL ]);
        };
    }

    reportScrollWarning = (context, scrollA, value) => {
        const { version, document, warnings } = context;
        if ((scrollA || value) && (document.isDetailWithHeaderOrFooter || version.isV1())) {
            if (version.isV1()) {
                warnings.addV1ScrollWarning();
            } else if (context.document.isDetailWithHeaderOrFooter) {
                // If this is a detail with a header or footer, then scroll cannot be used
                warnings.addV2ScrollWarning();
            }
            return null;
        }
        if (value !== 'true' && value !== 'false') {
            warnings.addScrollValueWarning(value);
            return null;
        }
        return scrollA;
    };
}

const generalLayoutFactory = new GeneralLayoutFactory();
export default generalLayoutFactory;
