/* @flow */
export const devices = {
    PHONE: 'small',
    TABLET_MEDIUM: 'medium',
    TABLET_LARGE: 'large',
    TABLET_EXTRA_LARGE: 'large', // extra large but server not ready

    PLATFORM_IOS_REACT: 'ios-react',
    PLATFORM_ANDROID_REACT: 'android-react',
    PLATFORM_BROWSER_REACT: 'browser-react',

    ORIENTATION_LANDSCAPE: 'LANDSCAPE',
    ORIENTATION_PORTRAIT: 'PORTRAIT',
};
