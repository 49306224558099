import SimpleStringAttribute from './SimpleStringAttribute';

const VALUES = {
    TRUE: 'true',
    FALSE: 'false',
};

export default class ImageActionAttribute extends SimpleStringAttribute {
    static newAttribute = (
        warnings,
        name,
        value,
        exportClass,
        isCascading = false,
        overrideName,
    ) => {
        if (Object.values(VALUES).includes(value)) {
            return new ImageActionAttribute(name, value, exportClass, isCascading, overrideName);
        }
        warnings.addInvalidValue(name, value);
        return null;
    }

    exportTo(context, styleSet) {
        const { xStyle } = styleSet;
        if (!xStyle.actionOverrides) {
            xStyle.actionOverrides = {};
        }
        const style = xStyle.actionOverrides;
        if (SimpleStringAttribute.hasConstantRef(this.value)) {
            SimpleStringAttribute.exportConstantAsRef(style, this.overrideName, this.value);
        } else {
            style[this.overrideName] = this.value;
        }
    }
}
