import React, { Fragment } from 'react';
import * as PropTypes from 'prop-types';

import getStyles from './Prompt.styles';

import { default as Divider } from '../Divider/Divider';
import { default as Modal } from '../Modal/Modal';
import { default as ModalTitle } from '../ModalTitle/ModalTitle';
import { default as ModalContent } from '../ModalContent/ModalContent';
import { default as ModalActions } from '../ModalActions/ModalActions';

/**
 * A modal that requires an action to be performed.  There is a title,
 * content in the form of children and an action area.
 * @see https://material-ui.com/components/dialogs/
 * @see https://material-ui.com/api/dialog/
 */
const Prompt = (props) => {
    const {
        actions,
        children,
        contextStyles,
        isClosable,
        isModalOpen,
        isTransparentOverlay,
        onClose,
        onSubmit,
        testID,
        title,
    } = props;

    const styles = getStyles(contextStyles);

    return (
        <Modal
            contextStyles={ {
                modal: styles.modal,
                overlay: styles.overlay,
            } }
            isClosable={ isClosable }
            isModalOpen={ isModalOpen }
            isTransparentOverlay={ isTransparentOverlay }
            onClose={ onClose }
            onSubmit={ onSubmit }
            testID={ `${testID}__modal` }>
            { title &&
                <Fragment>
                    <ModalTitle
                        contextStyles={ {
                            container: styles.header,
                            title: styles.title,
                        } }>
                        { title }
                    </ModalTitle>
                    <Divider contextStyles={ { divider: styles.divider } } />
                </Fragment>
            }
            <ModalContent contextStyles={ { content: styles.content } }>
                { children }
            </ModalContent>
            { actions &&
                <ModalActions contextStyles={ { container: styles.actions } }>
                    { actions }
                </ModalActions>
            }
        </Modal>
    );
};

Prompt.propTypes = {
    /** Prompt actions */
    actions: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),

    /** Optional message */
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]).isRequired,

    /** Extended styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the modal actions container */
        actions: PropTypes.object,

        /** Styles for the modal child content container */
        content: PropTypes.object,

        /** Styles for the divider between the modal title and content area */
        divider: PropTypes.object,

        /** Styles for the modal title container */
        header: PropTypes.object,

        /** Styles for the modal container */
        modal: PropTypes.object,

        /** Styles for the modal overlay */
        overlay: PropTypes.object,

        /** Styles for the modal title text */
        title: PropTypes.object,
    }),

    /** Disable backdrop click modal closing */
    isClosable: PropTypes.bool,

    /** Defines open or closed state of modal */
    isModalOpen: PropTypes.bool,

    /** Changes the overlay darkened fill to transparent */
    isTransparentOverlay: PropTypes.bool,

    /** Called when modal is closable and user closes the modal. */
    onClose: PropTypes.func,

    /** Called when enter key is pressed on Modal component */
    onSubmit: PropTypes.func,

    /** Title of dialog */
    title: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.string,
    ]),

    /** Id used for testing */
    testID: PropTypes.string,
};

Prompt.defaultProps = {
    contextStyles: {},
    isModalOpen: true,
    isClosable: true,
    testID: 'Prompt',
};

export default Prompt;
