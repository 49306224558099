/*       */

import StyleSet from '../StyleSet';
import AbstractModel from './AbstractModel';
import FormUtil from '../FormUtil';
import Grid from './Grid';
import { GRIDS } from '../ModelCache';
import { pageSeparatorExtraWidth } from '../paperConstants';

export default class Page extends AbstractModel {
    constructor(json, form, spaceBetweenPages, pageIndex = 0, prevPage = null) {
        super(json);
        this.init(form, spaceBetweenPages, pageIndex, prevPage);
    }
    static createWithChildren(children, form) {
        const fabricatedJson = FormUtil.newWithName('Page');
        fabricatedJson.Children = [ { $$: children } ];
        return new Page(fabricatedJson, form, 0);
    }
    get grids() {
        return this.getPageGrids();
    }
    getPageGrids(form = null) {
        const height = form ? form.layoutSizeHeight : this.height;
        return this.cache.get(GRIDS,
            () => { return this.childrenOfType('Grid').map(m => new Grid(m, this.width, height)); });
    }

    asSalt = (parentContext) => {
        const myContext = this.updateContext(parentContext);
        const styleSet = new StyleSet({
            width: this.width,
            height: this.height,
            position: 'absolute',
            top: this.top,
            left: pageSeparatorExtraWidth / 2,
        });

        return {
            box: {
                ...styleSet.asStyleAttribute(),
                ...styleSet.asXStyleAttribute(),
                children: this.grids.map(m => m.asSalt(myContext)),
            },
        };
    }
    init(form, spaceBetweenPages, pageIndex, prevPage = null) {
        this.width = form.layoutSizeWidth;
        this.height = this.getPageGrids(form).reduce((accumulator, grid) => Math.max(accumulator, grid.height + grid.layoutOriginY), 0);
        this.height = Math.max(this.height, form.layoutSizeHeight);
        this.top = prevPage ? prevPage.top + prevPage.height + spaceBetweenPages : 0;
    }
}
