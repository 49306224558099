import React from 'react';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { Dialog } from 'cv-dialog-sdk';

import { utilities } from '../utilities';
import SaltContext from './SaltContext';
import componentFactory from './componentFactory';
import SaltComponent from './SaltComponent';
import rootStore from '../stores/rootStore';
import BuildGlobals from '../provider/BuildGlobals';
import { constants } from '../constants';
import searchController from '../controllers/searchController';
import { serviceFactory } from '../services';

@observer
export default class GridList extends SaltComponent {
    static propTypes = {
        viewId: PropTypes.string,
    };

    constructor(props) {
        super(props);
        const { themeStore, sessionStore } = rootStore;
        const theme = themeStore.getSanitizedTheme();
        const { fonts: { dataTableHeading } } = theme;
        this.dataTableHeadingFonts = toJS(dataTableHeading);
        const { session: { tenantProperties } } = sessionStore;
        const { doubleClickForDefaultAction } = tenantProperties;
        this.doubleClickForDefaultAction = doubleClickForDefaultAction;

        const alternatingBackgroundColors = theme.dataTableListAlternatingColors;
        const selectionBackgroundColor = theme.listSelectedUnderlayColor;
        this.rowStyle = {
            verticalAlign: 'top',
            alternatingBackgroundColors,
            selectionBackgroundColor,
        };
        this.columnWidths = [];
    }

    render() {
        const { dialogStore, uiStore, onError } = this.contextParams;
        const { searchDialogStore } = dialogStore;
        const { sortValues, isSearchFormOpen, submitInProgress } = searchDialogStore || { isSearchFormOpen: false, sortValues: [] };
        const { dialog, records, isListInitialized } = dialogStore;

        const { view, recordDef } = dialog;
        const { columnData: columns, pageSize1, pageSize2, pageSize3 } = view;
        // TODO: The isXHA is a temporary checker. Need to extend the column size helper for native.
        // Checking if refreshInProgress to reduce the number of calls to this routing. This really needs
        // to be removed and managed in the grid.
        if (BuildGlobals.isXHA() && !dialogStore.refreshInProgress) {
            this.columnWidths = utilities.listHelper.getColumnSizes(this.isPagingEnabled ? dialogStore.pageRecords
                : dialogStore.records, columns, this.dataTableHeadingFonts, 700, recordDef, dialogStore, sortValues);
        }

        const { lang } = serviceFactory;

        const listProps = {
            ...this.props,
            columnWidths: this.columnWidths,
            columns,
            viewType: view.type,
            fixedColumnCount: view.fixedColumnCount,
            records: this.isPagingEnabled ? dialogStore.pageRecords : dialogStore.records,
            recordDef,
            keyExtractor: utilities.listHelper.getKeyExtractor,
            onError,
            dialogStore,
            uiStore,
            isSearchFormOpen,
            sortValues,
            doubleClickForDefaultAction: this.doubleClickForDefaultAction === 'true',
            isListInitialized,
            rowStyle: this.rowStyle,
            pageSelectAll: dialogStore.selectedRecordCount === (this.isPagingEnabled ? dialogStore.pageRecords.length : records.length),
            /** Paging */
            isPagingEnabled: this.isPagingEnabled,
            currentPageSize: dialogStore.pageSize,
            currentPageNumber: dialogStore.pageNumber,
            pagesRetrieved: dialogStore.pagesRetrieved,
            pageSizes: this.isPagingEnabled ? [ pageSize1, pageSize2, pageSize3 ] : undefined,
            recordsCount: dialogStore.fetchedRecordCount || records.length,
            canPageForward: dialogStore.hasMoreRecords() && !dialogStore.queryInProgress,
            canPageBack: dialogStore.pageNumber > 1,
            pagingItemTitle: `${lang.list.pagingItemTitle}`,
            refreshInProgress: dialogStore.refreshInProgress || submitInProgress,
            /** End Paging */
            isColumnFiltered: this.handleIsColumnFiltered,
            onSelectAll: this.handleOnSelectAll,
            onPageSizeChange: this.handlePageSizeChange,
            onGoToPage: this.handleOnGoToPage,
            onGoToNextPage: this.handleGoToNextPage,
            onGoToPreviousPage: this.handleGoToPreviousPage,
        };

        return React.createElement(componentFactory.getPlatformComponent('gridTable'), listProps);
    }

    get contextParams() {
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore, onError } = this.context;
        const { uiStore } = saltStore;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        return { uiStore, dialogStore, onError };
    }

    get isPagingEnabled() {
        const { dialogStore } = this.contextParams;
        const { dialog } = dialogStore;
        const { view: { pagingMethod } } = dialog;
        return pagingMethod === Dialog.PAGING_DEFINED_DIALOG_CLASS;
    }

    handleIsColumnFiltered = (propertyName) => {
        const { dialogStore } = this.contextParams;
        return searchController.isPropertyNameFiltered({ listDialogStore: dialogStore, propertyName });
    }

    handleOnSelectAll = (selectionMode, clientType) => {
        // Until the is properly introduced in the List class we are disabling for Mobile
        if (clientType !== 'DESKTOP') return;

        const { onChooseNone } = this.props;
        if (!selectionMode) {
            onChooseNone();
        } else {
            // It was observed that a range selector is not following the standard protocol for selecting a range of records
            // So we are putting this in place until the impact is observed for changing the list class handleSelectRange uses the
            // stores correctly for managing selection state. We were in the process of removing uiStore usage and moving into an
            // observable state to reduce performance hits on renders.
            const { uiStore, dialogStore } = this.contextParams;
            const { records, pageRecords } = dialogStore;

            const transactions = this.isPagingEnabled ? pageRecords.map((selectionObject) => {
                return new Promise((resolve) => { resolve(utilities.listHelper.selectRecord(uiStore, dialogStore, selectionObject.id, utilities.listHelper.multi)); });
            })
                : records.map((selectionObject) => {
                    return new Promise((resolve) => { resolve(utilities.listHelper.selectRecord(uiStore, dialogStore, selectionObject.id, utilities.listHelper.multi)); });
                });
            Promise.all(transactions)
                .catch();
        }
    }

    /** *************************************** PAGING EVENTS ***************************************** */
    handlePageSizeChange = (size) => {
        if (!this.isPagingEnabled) return;

        const { uiStore, dialogStore } = this.contextParams;
        const {
            onChooseNone,
        } = this.props;
        const { view: { pagingPersistentId } } = dialogStore.dialog;
        onChooseNone();

        uiStore.setValueForUIObject(pagingPersistentId, constants.ui.VIEW_PAGINATIONSETTINGS, size);
        dialogStore.restorePaginationDefaults();
        dialogStore.setPageSize(size);
        dialogStore.resetList();
    }

    persistPagination = () => {
        if (!this.isPagingEnabled) return;

        const { uiStore, dialogStore } = this.contextParams;
        const { pageNumber, recordIds, fetchedRecordCount, dialog, pageSize } = dialogStore;
        uiStore.setValueForUIObject(dialog.id, constants.ui.LIST_PAGINATION_DATA, {
            pageNumber,
            recordIds,
            fetchedRecordCount,
            pageSize,
        }, true);
    }

    handleGoToNextPage = () => {
        if (!this.isPagingEnabled) return;

        const { dialogStore } = this.contextParams;
        const { onChooseNone } = this.props;
        onChooseNone();
        dialogStore.pageForward().then(this.persistPagination);
    }

    handleGoToPreviousPage = () => {
        if (!this.isPagingEnabled) return;

        const { dialogStore } = this.contextParams;
        const { onChooseNone } = this.props;
        onChooseNone();
        dialogStore.pageBackward().then(this.persistPagination);
    }

    handleOnGoToPage = (pageNumber) => {
        if (!this.isPagingEnabled) return;

        const { dialogStore } = this.contextParams;
        const { onChooseNone } = this.props;
        onChooseNone();
        dialogStore.gotToPage(pageNumber).then(this.persistPagination);
    }
    /** *************************************** END PAGING EVENTS ***************************************** */
}

GridList.contextType = SaltContext;
