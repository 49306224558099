import { Property, propertyFormatter } from 'cv-dialog-sdk';
/*
    All plugins should have an exported function with this signature that returns a bindings object.
    Bindings is an object with names/values and/or names/functions to bind
 */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
export default (rootObject, expression) => {
    const dialog = rootObject._;
    const applyOp = (propOrValue1, propOrValue2, operation) => {
        if (propOrValue1 instanceof Property || propOrValue2 instanceof Property) {
            if (propOrValue1 instanceof Property) {
                if (propOrValue2 instanceof Property) {
                    return operation(propOrValue1.comparable, propOrValue2.comparable);
                }
                const property1Def = dialog.propDefAtName(propOrValue1.name);
                return operation(propOrValue1.comparable, Property.valueForCompare(propertyFormatter.parse(propOrValue2, property1Def)));
            }
            const property2Def = dialog.propDefAtName(propOrValue2.name);
            return operation(Property.valueForCompare(propertyFormatter.parse(propOrValue1, property2Def)), propOrValue2.comparable);
        }
        return operation(propOrValue1, propOrValue2);
    };

    // adds an additional check to treat empty strings as equal to null/undefined
    const equalish = (v1, v2) => {
        if (v1 === null || v1 === undefined) {
            return v2 === '' || v1 == v2;
        } if (v2 === null || v2 === undefined) {
            return v1 === '' || v2 == v1;
        }
        return v1 == v2;
    };

    const eq = (propOrValue1, propOrValue2) => applyOp(propOrValue1, propOrValue2, (v1, v2) => equalish(v1, v2));
    const ne = (propOrValue1, propOrValue2) => applyOp(propOrValue1, propOrValue2, (v1, v2) => !equalish(v1, v2));
    const gt = (propOrValue1, propOrValue2) => applyOp(propOrValue1, propOrValue2, (v1, v2) => v1 > v2);
    const lt = (propOrValue1, propOrValue2) => applyOp(propOrValue1, propOrValue2, (v1, v2) => v1 < v2);
    const gte = (propOrValue1, propOrValue2) => applyOp(propOrValue1, propOrValue2, (v1, v2) => v1 >= v2);
    const lte = (propOrValue1, propOrValue2) => applyOp(propOrValue1, propOrValue2, (v1, v2) => v1 <= v2);
    const contains = (propOrValue1, propOrValue2) => applyOp(propOrValue1, propOrValue2, (v1, v2) => v1.toLowerCase().indexOf(v2.toLowerCase()) > -1);

    return { eq, ne, gt, lt, gte, lte, contains };
};
