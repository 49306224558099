import { DialogProxyTools } from "./DialogProxyTools";
import { RecordVisitor } from "./RecordVisitor";
/**
 *
 */
export class RecordSetVisitor {
    constructor(value) {
        if (!value) {
            throw new Error('RecordSetVisitor -- null value exception');
        }
        if (typeof value === 'string') {
            this._enclosedJsonObject = JSON.parse(value);
        }
        else {
            this._enclosedJsonObject = value;
        }
        if (!DialogProxyTools.isRecordSetModel(this._enclosedJsonObject)) {
            throw new Error("Object passed to RecordSetVisitor is not a RecordSet");
        }
        if (!this._enclosedJsonObject.records) {
            throw new Error('Invalid record set -- missing records field');
        }
        if (!Array.isArray(this._enclosedJsonObject.records)) {
            throw new Error('Invalid record set -- records field is not an array');
        }
    }
    // --- State Management Helpers --- //
    static addOrUpdateRecord(jsonObject, recordState) {
        (new RecordSetVisitor(jsonObject)).addOrUpdateRecord(recordState);
    }
    static emptyRecordSetVisitor() {
        return new RecordSetVisitor({
            defaultActionId: null,
            records: [],
            hasMore: false,
            type: "hxgn.api.dialog.RecordSet"
        });
    }
    // --- State Import/Export --- //
    copyAsJsonObject() {
        return JSON.parse(this.copyAsJsonString());
    }
    copyAsJsonString() {
        return JSON.stringify(this.enclosedJsonObject());
    }
    enclosedJsonObject() {
        return this._enclosedJsonObject;
    }
    // --- State Management --- //
    addOrUpdateAllRecords(recordSetState) {
        for (const r of recordSetState.enclosedJsonObject().records) {
            this.addOrUpdateRecord(new RecordVisitor(r));
        }
    }
    addOrUpdateRecord(recordState) {
        let found = false;
        const recordCopy = JSON.parse(JSON.stringify(recordState.enclosedJsonObject()));
        for (const r of this.enclosedJsonObject().records) {
            if (r.id === recordCopy.id) {
                for (const k of Object.keys(r)) {
                    r[k] = recordCopy[k];
                }
                found = true;
                break;
            }
        }
        if (!found) {
            this.enclosedJsonObject().records.push(recordCopy);
        }
    }
    fromRecordId(recordId) {
        if (!recordId) {
            return;
        }
        const records = [];
        let found = false;
        for (const r of this.visitRecords()) {
            if (found) {
                records.push(r);
            }
            else {
                if (r.visitRecordId() === recordId) {
                    found = true;
                }
            }
        }
        return this.enclosedJsonObject().records = records;
    }
    recordCount() {
        return this.enclosedJsonObject().records.length;
    }
    visitAndClearRecords() {
        this.enclosedJsonObject().records = [];
    }
    visitHasMore() {
        return this.enclosedJsonObject().hasMore;
    }
    visitAndSetHasMore(hasMore) {
        this.enclosedJsonObject().hasMore = hasMore;
    }
    visitRecordAtId(id) {
        for (const r of this.visitRecords()) {
            if (r.visitRecordId() === id) {
                return r;
            }
        }
        return null;
    }
    *visitRecords() {
        let index = 0;
        while (index < this.enclosedJsonObject().records.length) {
            yield new RecordVisitor(this.enclosedJsonObject().records[index++]);
        }
    }
    addOrUpdateRecordProperties(recordState) {
        const recordCopy = JSON.parse(JSON.stringify(recordState.enclosedJsonObject()));
        for (const r of this.enclosedJsonObject().records) {
            if (r.id === recordCopy.id) {
                const recordProperties = recordCopy.properties;
                for (let i = 0, len = recordProperties.length; i < len; i++) {
                    if (recordProperties[i].name && recordProperties[i].value) {
                        for (const p of r.properties) {
                            if (p.name === recordProperties[i].name) {
                                p.value = recordProperties[i].value;
                            }
                        }
                    }
                }
                break;
            }
        }
    }
}
