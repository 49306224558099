import React, { Component } from 'react';
import * as PropTypes from 'prop-types';

/**
 * This is very specific to Dropdowns. This doesn't fit for universal usage. So, had to place this within XaltDropDown folder.
 * We are only overriding the default padding of ul and li items to 0 in this component.
 */

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
class XaltDropDownList extends Component {
    static propTypes = {
        children: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node,
            PropTypes.arrayOf(PropTypes.node),
        ]),

        innerRef: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.object,
        ]),

        onMouseDown: PropTypes.func,

        style: PropTypes.object,
    };

    static defaultProps = {
        style: {},
    };

    render() {
        const {
            innerRef,
            children,
            style,
            onMouseDown,
        } = this.props;
        return (
            <ul
                ref={ innerRef }
                onMouseDown={ onMouseDown }
                role="listbox"
                style={ {
                    maxHeight: '80vh',
                    overflow: 'auto',
                    ...style,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                } }>
                {
                    React.Children.map(children,
                        (childElement) => React.cloneElement(childElement, { style: { paddingTop: 0, paddingBottom: 0, paddingLeft: 0, paddingRight: 0 } }))
                }
            </ul>
        );
    }
}

const DropddownList = React.forwardRef((props, ref) => (
    <XaltDropDownList
        innerRef={ ref }
        { ...props } />
));

DropddownList.displayName = 'XaltDropDownList';

export default DropddownList;
