import moment from 'moment';
import { CellValue } from './CellValue';
import { TypeNames } from './types';
/**
 * A property definition describes a particular value of a business entity. Business entities are transacted as records,
 * therefore properties and lists of properties are referred to as fields and records. Moreover, a list of property definitions
 * is referred to as a record definition and is the metadata describing the read/write capabilities of a specific dialog model
 * in use by a specific user in a specific workflow.
 * Contains information that 'defines' a property {@link Property} (name/value)
 * An instance of the {@link Property} contains the actual data value.
 */
export class PropertyDef {
    constructor(canCauseSideEffects, contentType, displayLength, format, length, propertyName, propertyType, scale, semanticType, upperCaseOnly, writeAllowed, writeEnabled, booleanTrueAlias, booleanFalseAlias, datePresentationFormat) {
        this.canCauseSideEffects = canCauseSideEffects;
        this.contentType = contentType;
        this.displayLength = displayLength;
        this.format = format;
        this.length = length;
        this.propertyName = propertyName;
        this.propertyType = propertyType;
        this.scale = scale;
        this.semanticType = semanticType;
        this.upperCaseOnly = upperCaseOnly;
        this.writeAllowed = writeAllowed;
        this.writeEnabled = writeEnabled;
        this.booleanTrueAlias = booleanTrueAlias;
        this.booleanFalseAlias = booleanFalseAlias;
        this.datePresentationFormat = datePresentationFormat;
    }
    get isBarcodeType() {
        return this.semanticType === 'BARCODE';
    }
    get isByteFormat() {
        return this.format === 'byte';
    }
    get isBooleanType() {
        return this.propertyType === 'boolean';
    }
    get isCodeRefType() {
        return this.format === 'code-ref';
    }
    get isDateType() {
        return this.format === 'date';
    }
    get isDateTimeType() {
        return this.format === 'date-time';
    }
    /** used to determine how to format a date field. */
    get isShortDate() {
        return this.datePresentationFormat && this.datePresentationFormat.toUpperCase() === 'SHORT';
    }
    get isDecimalType() {
        return this.format === 'decimal';
    }
    get isDoubleType() {
        return this.format === 'double';
    }
    get isEmailType() {
        return this.semanticType === 'EMAIL';
    }
    get isFileAttachment() {
        return this.semanticType === 'FILE_UPLOAD';
    }
    get isFloatType() {
        return this.format === 'float';
    }
    get isGpsReadingType() {
        return this.propertyType === TypeNames.GpsReadingPropertyTypeName || this.format === 'geo-fix';
    }
    get isMapLocationType() {
        return (this.propertyType === TypeNames.MapLocationPropertyTypeName || this.format === 'geo-location');
    }
    get isHTMLType() {
        return this.semanticType === 'HTML';
    }
    // @TODO
    get isInlineMediaStyle() {
        return (this.semanticType === CellValue.STYLE_INLINE_MEDIA || this.semanticType === CellValue.STYLE_INLINE_MEDIA2);
    }
    get isListType() {
        return this.propertyType === 'array';
    }
    get isIntType() {
        return ['integer', 'int32', 'int64'].some(v => this.propertyType === v);
    }
    get isLongType() {
        return this.format === 'int64';
    }
    get isMoneyType() {
        return this.semanticType === 'MONEY';
    }
    get isNameType() {
        return this.semanticType === 'NAME';
    }
    get isNumericType() {
        return this.isDecimalType || this.isIntType || this.isDoubleType || this.isLongType || this.isFloatType;
    }
    get isLargePropertyType() {
        return this.semanticType === 'LARGE_PROPERTY' || this.isSignatureType;
    }
    get isObjRefType() {
        return this.format === 'object-ref';
    }
    get isPasswordType() {
        return this.format === 'password' || this.semanticType === 'PASSWORD';
    }
    get isPercentType() {
        return this.semanticType === 'PERCENTAGE';
    }
    get isPowerBI() {
        return this.propertyType === TypeNames.PowerBITypeName;
    }
    get isReadOnly() {
        return !this.writeAllowed || !this.writeEnabled;
    }
    get isWritable() {
        return this.writeAllowed || this.writeEnabled;
    }
    get isSignatureType() {
        return this.semanticType === 'USER_SIGNATURE';
    }
    get isStringType() {
        return this.propertyType === 'string';
    }
    get isTelephoneType() {
        return this.semanticType === 'TELEPHONE';
    }
    get isTextBlock() {
        return this.semanticType === 'TEXT_BLOCK';
    }
    get isTimeType() {
        return this.format === 'time';
    }
    get isUnformattedNumericType() {
        return this.semanticType === 'UNFORMATTED';
    }
    get isURLType() {
        return this.semanticType === 'URL';
    }
    get isWholeNumberType() {
        return this.semanticType === 'WHOLE_QUANTITY';
    }
    get isFileMimeType() {
        return this.contentType === 'application/octet-stream';
    }
    get isColorPickerType() {
        return this.semanticType === 'COLOR';
    }
    get isMultiLineText() {
        return this.semanticType === 'MULTI_LINE_TEXT';
    }
    get isNFCType() {
        return this.semanticType === 'NFC';
    }
    get isDescriptionType() {
        return this.semanticType === 'DESCRIPTION';
    }
    getDateFormatString(locale) {
        if (!locale || !['date', 'time', 'date-time'].includes(this.format))
            return;
        const dateFormat = moment
            .localeData(locale)
            .longDateFormat('L');
        const timeFormat = moment
            .localeData(locale)
            .longDateFormat('LT')
            .replace('hh', 'h')
            .replace('h', 'hh')
            .replace('HH', 'H')
            .replace('H', 'HH');
        if (this.format === 'date') {
            return dateFormat;
        }
        else if (this.format === 'time') {
            return timeFormat;
        }
        return `${dateFormat}, ${timeFormat}`;
    }
}
