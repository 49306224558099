import React from 'react';
import PropTypes from 'prop-types';
import { pageController } from 'cv-react-core';
import componentFactory from './componentFactory';
import SaltComponent from './SaltComponent';
import SaltContext from './SaltContext';

const BARCODESCAN_PROP_NAME = 'BARCODESCAN_PROP';

export default class Barcode extends SaltComponent {
    static propTypes = {
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        viewId: PropTypes.string,
    };

    render() {
        let resolvedProps = this.resolveProperties();
        resolvedProps = {
            ...resolvedProps,
            onBarcodeScanned: this.handleOnBarcodeScanned,
        };
        return React.createElement(componentFactory.getPlatformComponent('barcode'), resolvedProps);
    }


    handleOnBarcodeScanned = (barcodeData) => {
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore, onTransition, onError } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        const {
            uiStore,
        } = saltStore;

        // Get barcode information
        const { data } = barcodeData;
        dialogStore.setPropertyValue(BARCODESCAN_PROP_NAME, data);

        // Write updated values to the server and transition
        pageController.performWrite({ dialogStore, uiStore, onTransition, onError });
    };
}

Barcode.contextType = SaltContext;
