const coreStyles = {
    divider: {
    },
};

const getStyles = (contextStyles = {}) => {
    return {
        divider: {
            ...coreStyles.divider,
            ...contextStyles.divider,
        },
    };
};

export default getStyles;
