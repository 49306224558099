import { action, observable, toJS } from 'mobx';

const ERRORS = 'ERRORS';

export default class UIStore {
    // these items will be persisted to local storage
    @observable ui = new Map();
    // these items will not be persisted to local storage (so they should be 'derivable' at runtime)
    @observable transientUi = new Map();

    @observable notificationReceived = false;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    compositeKey = (objectId, key) => {
        return `${objectId}_${key}`;
    };

    @action addErrorForUIObject = (objectId, error) => {
        const compositeKey = this.compositeKey(objectId, ERRORS);
        if (!this.transientUi.has(compositeKey)) {
            this.transientUi.set(compositeKey, []);
        }
        this.transientUi.get(compositeKey).push(error);
    };

    @action asTransaction(transaction) {
        return transaction();
    }

    @action clearErrorsForUIObject = (objectId) => {
        const compositeKey = this.compositeKey(objectId, ERRORS);
        this.transientUi.delete(compositeKey);
    };

    getErrorsForUIObject = (objectId) => {
        const compositeKey = this.compositeKey(objectId, ERRORS);
        if (this.transientUi.has(compositeKey)) {
            return this.transientUi.get(compositeKey);
        }
        return [];
    };

    getValueForUIObject = (objectId, key) => {
        const compositeKey = this.compositeKey(objectId, key);
        // Compare with undefined so falsy values like 0, false and '' get returned
        if (this.ui.get(compositeKey) !== undefined) {
            return this.ui.get(compositeKey);
        }
        return this.transientUi.get(compositeKey);
    };

    @action setValueForUIObject = (objectId, key, value, cacheData = true) => {
        const compositeKey = this.compositeKey(objectId, key);
        if (cacheData) {
            this.ui.set(compositeKey, value);
            const { cacheStore } = this.rootStore;
            cacheStore.setCacheType(cacheStore.cacheStores.UI, this.getSanitizedUI());
        } else {
            this.transientUi.set(compositeKey, value);
        }
    };

    @action removeValueForUIObject = (objectId, key) => {
        const compositeKey = this.compositeKey(objectId, key);
        if (this.ui.has(compositeKey)) {
            this.ui.delete(compositeKey);
            const { cacheStore } = this.rootStore;
            cacheStore.setCacheType(cacheStore.cacheStores.UI, this.getSanitizedUI());
        }
        if (this.transientUi.has(compositeKey)) {
            this.transientUi.delete(compositeKey);
        }
    };

    @action clearValuesForUIObject = (objectId, cacheData = true) => {
        this.ui.forEach((value, key) => {
            if (key.startsWith(`${objectId}_`)) {
                this.ui.delete(key);
            }
        });
        this.transientUi.forEach((value, key) => {
            if (key.startsWith(`${objectId}_`)) {
                this.transientUi.delete(key);
            }
        });
        if (cacheData) {
            const { cacheStore } = this.rootStore;
            cacheStore.setCacheType(cacheStore.cacheStores.UI, this.getSanitizedUI());
        }
    };

    @action setNotificationReceived = (value) => {
        this.notificationReceived = value;
    }

    get notificationReceived() {
        return this.notificationReceived;
    }

    @action clearAll = () => {
        // Clean up observables
        this.ui.clear();
        this.transientUi.clear();
        // Clean up local storage;
        const { cacheStore } = this.rootStore;
        cacheStore.clearCacheType(cacheStore.cacheStores.UI);
    };

    getSanitizedUI = () => {
        return toJS(this.ui);
    };

    // private ************************************************

    restoreFromCache = (cacheData) => {
        const { ui } = cacheData;
        if (ui) {
            this.ui.clear();
            Object.keys(ui).forEach(key => {
                this.ui.set(key, ui[key]);
            });
        }
        return ui;
    };
}
