import React from 'react';
import * as PropTypes from 'prop-types';
import { default as IconButton, ICON_SIZE } from '../IconButton/IconButton';
import { default as TextField } from '../TextField/TextField';

// Styles
import getStyles from './FilePickerInput.styles';

/**
 * FilePicker component allows user to pick files to upload.
 * @see https://material-ui.com/demos/buttons/
 */
class FilePickerInput extends React.Component {
    state = {
        fileName: '',
    };

    render() {
        const { fileName } = this.state;
        const {
            fileTypes,
            contextStyles,
            disabled,
            placeholderText,
            testID,
            toolTip,
            autoGrow,
        } = this.props;

        let accept = '';

        const fileMap = {
            pdf: 'application/pdf,',
            plainText: 'text/plain,',
            images: 'image/*,',
            audio: 'audio/*,',
            video: 'video/*,',
        };

        fileTypes.forEach( (fileType) => { accept += ( fileMap[fileType] || '*,' ); });

        // Override core styles with context styles
        const {
            adornmentButton,
            adornmentIcon,
            ...restContextStyles
        } = getStyles(contextStyles);

        // Generate icon props
        const iconProps = {
            contextStyles: {
                button: adornmentButton,
                icon: adornmentIcon,
            },
            iconName: 'cloud_upload',
            onClick: this.handleClick,
            title: toolTip,
        };
        if (disabled) { iconProps.disabled = disabled; }
        if (testID) { iconProps.testID = testID; }

        return (
            <>
                <input
                    accept={ accept }
                    ref={ (input) => { this.inputElement = input; } }
                    style={ { display: 'none' } }
                    type="file"
                    onChange={ this.handleChange } />
                <TextField
                    readOnly
                    contextStyles={ restContextStyles }
                    disabled={ disabled }
                    testID={ testID }
                    toolTip={ toolTip }
                    placeholder={ placeholderText }
                    value={ fileName }
                    autoGrow={ autoGrow && fileName }
                    endAdornment={
                        <IconButton { ...iconProps } />
                    } />
            </>
        );
    }

    /**
     * Called when the file input changes
     * @param {{target: Object}} e - The new file
     */
    handleChange = (e) => {
        const { onChange } = this.props;
        const { value } = e.target;
        if (value) {
            const file = value.replace(/^.*[\\/]/, '');
            this.setState({ fileName: file });
            if (onChange) {
                onChange(e, this.props);
            }
        }
        // else {
        //     this.setState({ fileName: '' });
        // }
    };

     /** Called when the picker Icon is clicked to open the picker */
     handleClick = () => {
         this.inputElement.click();
     }
}

FilePickerInput.propTypes = {

    /** Grows/shrinks the input based on the size of its text value */
    autoGrow: PropTypes.bool,

    /** Styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the container surrounding the text field */
        container: PropTypes.object,

        /** Styles for the text field */
        input: PropTypes.object,

        /** Styles for the adornment button */
        adornmentButton: PropTypes.object,

        /** Styles for the adornment icon */
        adornmentIcon: PropTypes.object,
    }),

    /** Prevents the text field from accepting input */
    disabled: PropTypes.bool,

    /** File input types*/
    fileTypes: PropTypes.arrayOf(PropTypes.oneOf([
        'allFiles',
        'images',
        'plainText',
        'audio',
        'video',
        'pdf',
    ])),

    /** Size of the icon to be rendered. */
    iconSize: PropTypes.oneOfType([
        /** Font size of the SVG icon */
        PropTypes.number,

        /** Icon size presets */
        PropTypes.oneOf([
            ICON_SIZE.DEFAULT,
            ICON_SIZE.INHERIT,
            ICON_SIZE.LARGE,
            ICON_SIZE.SMALL,
        ]),
    ]),

    /**
     * Called when the document selection has changed
     * @param {Object} event - The native change event
     * @param {Object} props - The component instance props
     */
    onChange: PropTypes.func.isRequired,

    /** Placeholder text for input field */
    placeholderText: PropTypes.string,

    /** Id used for testing */
    testID: PropTypes.string,

    /** toolTip string */
    toolTip: PropTypes.string,
};

FilePickerInput.defaultProps = {
    fileTypes: [ 'allFiles' ],
    iconSize: 16,
    disabled: false,
    placeholderText: 'Choose a file',
    testID: 'FilePickerInputField',
    autoGrow: true,
};

export default FilePickerInput;
