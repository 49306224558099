import { ELEMENTS } from './gmlConstants';
import { prettyXmlParseError } from './logUtils';
import Base from './model/Base';
import Gml from './model/Gml';
import WarningNotifier from './WarningNotifier';

export default class GmlV1ToSalt {
    static xmlParser;
    errorString;
    salt;

    perform(gmlXml, viewId, tenantGMLXML = '', errorCallback) {
        const options = { explicitChildren: true, preserveChildrenOrder: true };
        // const totalTime = new Date();

        // Construct a Gml object from the tenant level GML if tenant level GML exists.
        // Then pass that tenant level GML into the Gml constructor so tenant level constants and props can be processed.
        let tenantGML = null;
        let parseTime1 = new Date();
        const parseXmlString = GmlV1ToSalt.xmlParser;
        parseXmlString(tenantGMLXML, options, (error, gmlJson) => {
            parseTime1 = Math.abs(new Date() - parseTime1);
            if (error) {
                this.errorString = `Error parsing Tenant Level GML.\n${prettyXmlParseError(tenantGMLXML, error)}\nThe offending line may be before or after this line.`;
                console.error(this.errorString);
            }
            if (gmlJson) {
                tenantGML = new Gml(gmlJson[ELEMENTS.gml]);
            }
        });

        // Construct the GML that will be used to build the view.
        let parseTime2 = new Date();
        parseXmlString(gmlXml, options, (error, gmlJson) => {
            parseTime2 = Math.abs(new Date() - parseTime2);
            if (error) {
                this.errorString = `Error parsing XML.\n${prettyXmlParseError(gmlXml, error)}\nThe offending line may be before or after this line.`;
                console.error(this.errorString);
            }
            if (gmlJson) {
                const gml = new Gml(gmlJson[ELEMENTS.gml], tenantGML);
                if (Base.isIgnore(gml.json)) {
                    // Ignore this... no resulting salt.
                } else if (!gml.getVersion().isValid()) {
                    this.errorString = `Unrecognized version number in root of GML: ${gml.getVersionString()}`;
                    console.error(this.errorString);
                } else {
                    try {
                        // @remove
                        // const warnings = new WarningNotifier(serviceFactory.notify.showError);
                        const warnings = new WarningNotifier(errorCallback);
                        this.salt = gml.asSalt(warnings, viewId);
                        warnings.report();
                        // const saltingTime = Math.abs(new Date() - totalTime - parseTime1 - parseTime2);
                        // const parsingTime = parseTime1 + parseTime2;
                        // const performanceMsg = `GML transpile, total: ${saltingTime + parsingTime}  parsing: ${parsingTime}  salting: ${saltingTime}`;
                        // console.log(performanceMsg);
                        // Log.debug(JSON.stringify(this.salt, null, 4));
                        // Log.debug('GML/SALT:', this.salt);
                    } catch (err) {
                        if (err.message) {
                            this.errorString = `Error converting GML to SALT: ${err.message}`;
                            if (err.stack) {
                                this.errorString += err.stack;
                            }
                        } else {
                            this.errorString = `Error converting GML to SALT: ${err}`;
                        }
                        console.error(this.errorString);
                    }
                }
            }
        });
    }

    getName(gml) {
        const rootChildren = Base.rootChildren(gml.json);
        if (rootChildren.length > 0) {
            return rootChildren[0].json['#name'];
        }
        return '';
    }
}
