import React from 'react';
import * as PropTypes from 'prop-types';
import MUISwitch from '@material-ui/core/Switch';

import makeStyles from '@material-ui/core/styles/makeStyles';
import getStyles from './Toggle.styles';

/**
 * A control that allows a user to toggle between binary values (on/off, yes/no, true/false)
 * @see https://material-ui.com/components/switches/
 */
const Toggle = (props) => {
    const {
        checked,
        contextStyles,
        disabled,
        onChange,
        testID,
        toolTip,
    } = props;

    // Override core styles with context styles, separating MUI styles
    const {
        container: containerStyles,
        ...muiStyles
    } = getStyles(contextStyles);

    // Create dynamic class names and injected DOM styles for MUI component
    // * makeStyles returns a function
    const styles = makeStyles(muiStyles)();

    // Generate container props
    const containerProps = {
        className: 'c-toggle__container',
        style: containerStyles, // non-MUI styles
    };
    if (testID) { containerProps['data-test-id'] = `${testID}__toggle__container`; }

    // Generate switch props
    const switchProps = {
        checked,
        classes: {
            root: styles.trackContainer,
            switchBase: styles.thumbContainer,
            thumb: styles.thumb,
            track: styles.track,
        },
        className: 'c-toggle__switch',
        disabled,
        onChange: (event) => {
            onChange(event, props);
        },
        title: toolTip,
    };
    if (testID) { switchProps['data-test-id'] = `${testID}__toggle__switch`; }

    return (
        <div { ...containerProps }>
            <MUISwitch { ...switchProps } />
        </div>
    );
};

Toggle.propTypes = {
    /** The switch value */
    checked: PropTypes.bool,

    /** Styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the container surrounding the control */
        container: PropTypes.object,

        /** Styles for the toggle thumb button */
        thumb: PropTypes.object,

        /** Styles for container surrounding the thumb button */
        thumbContainer: PropTypes.object,

        /** Styles for container surrounding the thumb button in a true state */
        thumbContainerSelected: PropTypes.object,

        /** Styles for the toggle thumb button in a true state */
        thumbSelected: PropTypes.object,

        /** Styles for the toggle track */
        track: PropTypes.object,

        /** Styles for container surrounding the track */
        trackContainer: PropTypes.object,

        /** Styles for the toggle track in a true state */
        trackSelected: PropTypes.object,
    }),

    /** Disables the control */
    disabled: PropTypes.bool,

    /**
     * Called when the switch was changed
     * @param {Object} event - The native change event
     * @param {Object} props - The component instance props
     */
    onChange: PropTypes.func,

    /** Id used for testing */
    testID: PropTypes.string,

    /** toolTip string */
    toolTip: PropTypes.string,
};

Toggle.defaultProps = {
    contextStyles: {},
};

export default Toggle;
