import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import componentFactory from './componentFactory';
import SaltContext from './SaltContext';
import engineConstants from './engineConstants';
import SaltComponent from './SaltComponent';
import { utilities } from '../utilities';
import { constants } from '../constants';
import pageController from '../controllers/pageController';
import serviceFactory from '../services/serviceFactory';

@observer
export default class ImagePicker extends SaltComponent {
    static propTypes = {
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        viewId: PropTypes.string,
    };

    static typeName = engineConstants.component.name.imagePicker;

    render() {
        const { dialogStore, uiStore } = this.contextParams;
        const { dialog, records, refreshInProgress } = dialogStore;
        const { view: { name, type } } = dialog;
        const dialogId = dialogStore.getRootDialogStore().dialog.id;
        const openImage = uiStore.getValueForUIObject(dialogId, constants.ui.OPEN_IMAGE);
        const selectedRecord = utilities.listHelper.getSelectedAsArray(uiStore, dialogStore)[0];
        const resolvedProps = {
            ...this.resolveProperties(),
            asyncDataCallback: this.loadAsyncData,
            items: this.processRecords(records),
            onChooseItem: this.handleChooseItem,
            onClickItem: this.handleClickItem,
            onCloseItem: this.handleCloseItem,
            openImage,
            refreshInProgress,
            selectedRecord,
            viewName: name,
            viewType: type,
        };
        const component = React.createElement(componentFactory.getPlatformComponent('imagePicker'), resolvedProps);
        return component;
    }

    get contextParams() {
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore, onTransition, onError } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        const { uiStore } = saltStore;
        return { saltStore, onTransition, onError, dialogStore, uiStore };
    }

    processRecords = () => {
        const { dialogStore } = this.contextParams;
        const { records } = dialogStore;
        return records.map((record) => this.getRecord(record));
    }

    getRecord = (record) => {
        const { dialogStore } = this.contextParams;
        const { dialog } = dialogStore;
        const { recordDef } = dialog;
        const {
            id,
            properties,
        } = record;
        const row = { id };

        // Extract image and label from properties
        properties.forEach((property) => {
            const {
                name,
                value,
            } = property;
            const prop = (record && record.propAtName(name)) || {};
            const propDef = recordDef.propDefAtName(name) || {};
            const isImage = !!((prop && prop.imageName) || (record && record.imageName));
            const isLargeImage = propDef.isLargePropertyType;
            const isURL = propDef.isURLType;
            const isName = propDef.isNameType;
            if (isImage || isURL) {
                row.image = value;
            } else if (isLargeImage) {
                row.largeImage = value;
                row.largeImagePropertyName = name;
            } else if (isName) {
            // TODO: Need to confirm this.
                row.label = value;
            }
        });
        return row;
    }

    handleCloseItem = () => {
        const { uiStore, dialogStore } = this.contextParams;
        const dialogId = dialogStore.getRootDialogStore().dialog.id;
        uiStore.removeValueForUIObject(dialogId, constants.ui.OPEN_IMAGE);
    }

    loadAsyncData = (propName, recordId) => {
        const { dialogStore } = this.contextParams;
        return dialogStore.dialog.readLargeProperty(propName, recordId);
    }

    handleChooseItem = (targetId, currentIndex) => {
        const { dialogStore, uiStore } = this.contextParams;
        const { records } = dialogStore;
        utilities.listHelper.selectRecord(uiStore, dialogStore, targetId, utilities.listHelper.single);

        // Load more records when we are towards the end of the list
        if ((currentIndex + 5) > records.length) {
            this.handleRequestMoreData();
        }
    }

    handleClickItem = () => {
        const { onTransition, onError, dialogStore, uiStore } = this.contextParams;
        const { dialog } = dialogStore;
        const { id, defaultActionId } = dialog;
        const overrideDefaultActionId = uiStore.getValueForUIObject(id, constants.ui.LIST_OVERRIDE_DEFAULT_ACTION_ID);
        const actionId = overrideDefaultActionId || defaultActionId;
        if (actionId) {
            pageController.performActionWithConfirmation({
                actionId,
                dialogStore,
                uiStore,
                onTransition,
                onError,
            });
        }
    };

    handleRequestMoreData = () => {
        const { dialogStore, onError } = this.contextParams;
        const { lang } = serviceFactory;
        const title = lang.formatString(lang.dialog.errors.errorRequestingMoreRecordsTitle);
        pageController.performMoreRecords({ dialogStore, title, onError }).then((result) => {
            return result;
        });
    };
}

ImagePicker.contextType = SaltContext;
