import React, { Fragment } from 'react';
import * as PropTypes from 'prop-types';

import TextLabel from '../TextLabel/TextLabel';
import TextField from '../TextField/TextField';
import PasswordField from '../PasswordField/PasswordField';
import XaltCheckbox from '../../xaltComponents/XaltCheckBox/XaltCheckBox';
import Button, { VARIANT } from '../Button/Button';

import getStyles from './LoginForm.styles';

const LoginForm = (props) => {
    const {
        contextStyles,
        errors,
        isLoggingIn,
        loginBtnText,
        oAuthBtnText,
        oAuthEnabled,
        onLoginPress,
        onOAuthPress,
        onPasswordChange,
        onSavePasswordChange,
        onSettingsPress,
        onShowPasswordChange,
        onUserNameChange,
        password,
        passwordLbl,
        savePassword,
        savePasswordLbl,
        settingsBtnText,
        showPassword,
        showSavePassword,
        userName,
        userNameLbl,
    } = props;

    const styles = getStyles(contextStyles, !!errors);

    const handleSubmit = (event) => {
        event.preventDefault();
    };

    return (
        <form
            onSubmit={ handleSubmit }
            style={ styles.container }>
            {
                errors && (
                    <TextLabel
                        contextStyles={ {
                            container: styles.error,
                            text: styles.errorText,
                        } }>
                        { errors }
                    </TextLabel>
                )
            }
            <TextField
                autoComplete="username"
                autoFocus
                contextStyles={ {
                    container: styles.textField,
                    focused: styles.input,
                    hoover: styles.input,
                    input: styles.input,
                } }
                label={ userNameLbl }
                onChange={ onUserNameChange }
                value={ userName }
                variant="outlined" />
            <PasswordField
                contextStyles={ {
                    container: styles.textField,
                    focused: styles.input,
                    hoover: styles.input,
                    input: styles.input,
                } }
                label={ passwordLbl }
                onChange={ onPasswordChange }
                onShowPasswordChange={ onShowPasswordChange }
                showPassword={ showPassword }
                value={ password }
                variant="outlined" />
            { showSavePassword &&
                <div style={ styles.savePasswordContainer }>
                    <XaltCheckbox
                        checked={ savePassword }
                        onValueChanged={ onSavePasswordChange }
                        style={ { padding: '0px' } } />
                    <TextLabel
                        contextStyles={ {
                            text: styles.textLabel,
                        } }>
                        { savePasswordLbl }
                    </TextLabel>
                </div>
            }
            <Button
                contextStyles={ {
                    container: styles.button,
                    primary: styles.buttonPrimary,
                    primaryHover: styles.buttonPrimaryHover,
                    primaryText: styles.buttonPrimaryText,
                    text: styles.buttonText,
                    disabled: styles.buttonPrimaryHover,
                } }
                disabled={ isLoggingIn || !userName.trim() || !password.trim() }
                onClick={ onLoginPress }
                text={ loginBtnText }
                type="submit" />
            {
                oAuthEnabled && (
                    <Fragment>
                        <Button
                            contextStyles={ {
                                container: styles.button,
                                secondary: styles.buttonSecondary,
                                secondaryText: styles.buttonSecondaryText,
                                text: styles.buttonText,
                            } }
                            disabled={ isLoggingIn }
                            onClick={ onOAuthPress }
                            text={ oAuthBtnText }
                            variant={ VARIANT.OUTLINED } />
                    </Fragment>
                )
            }
            <Button
                contextStyles={ {
                    container: styles.button,
                    secondary: styles.buttonSecondary,
                    secondaryText: styles.buttonSecondaryText,
                    text: styles.buttonText,
                } }
                text={ settingsBtnText }
                onClick={ onSettingsPress }
                iconName="settings_outline"
                variant={ VARIANT.OUTLINED } />
        </form>
    );
};

LoginForm.propTypes = {
    contextStyles: PropTypes.shape({
        /** Styles for the container surrounding the button */
        button: PropTypes.object,

        /** Styles for the primary button */
        buttonPrimary: PropTypes.object,

        /** Styles for the primary button hover state */
        buttonPrimaryHover: PropTypes.object,

        /** Styles for the button primary text */
        buttonPrimaryText: PropTypes.object,

        /** Styles for the secondary button */
        buttonTertiary: PropTypes.object,

        /** Styles for the secondary button hover state */
        buttonTertiaryHover: PropTypes.object,

        /** Styles for the button secondary text */
        buttonTertiaryText: PropTypes.object,

        /** Styles for the button text */
        buttonText: PropTypes.object,

        /** Styles for the container surrounding the form */
        container: PropTypes.object,

        /** Styles for the container surrounding the password */
        password: PropTypes.object,

        /** Styles for the container surrounding the save password */
        savePasswordContainer: PropTypes.object,

        /** Styles for the text field */
        textField: PropTypes.object,

        /** Styles for the text */
        textLabel: PropTypes.object,
    }),
    errors: PropTypes.string,
    isLoggingIn: PropTypes.bool,
    loginBtnText: PropTypes.string,
    oAuthBtnText: PropTypes.string,
    oAuthEnabled: PropTypes.bool,
    onOAuthPress: PropTypes.func,
    onLoginPress: PropTypes.func.isRequired,
    onPasswordChange: PropTypes.func.isRequired,
    onSavePasswordChange: PropTypes.func.isRequired,
    onSettingsPress: PropTypes.func,
    onShowPasswordChange: PropTypes.func.isRequired,
    onUserNameChange: PropTypes.func.isRequired,
    password: PropTypes.string.isRequired,
    passwordLbl: PropTypes.string,
    savePassword: PropTypes.bool.isRequired,
    savePasswordLbl: PropTypes.string,
    settingsBtnText: PropTypes.string,
    showSavePassword: PropTypes.bool,
    showPassword: PropTypes.bool.isRequired,
    userName: PropTypes.string.isRequired,
    userNameLbl: PropTypes.string,
};

LoginForm.defaultProps = {
    contextStyles: {},
    loginBtnText: 'Login',
    passwordLbl: 'Password',
    savePasswordLbl: 'Save password',
    settingsBtnText: 'Settings',
    userNameLbl: 'User Id',
};

export default LoginForm;
