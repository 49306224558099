/* eslint-disable no-underscore-dangle */
import { Catavolt, TypeNames } from 'cv-dialog-sdk';
import { action, observable } from 'mobx';
import uiHelper from '../utilities/uiHelper';

/**
export interface SaltParameters {
    readonly documentName?: string;
    readonly documentDescription?: string;
    readonly saltDocument?: {};
    readonly discriminators?: {};
    readonly additionalProperties?: {};
}
 */
export default class SaltEditorStore {
    @observable editorError = null;
    @observable saltDocumentNames = [];
    @observable _activeDocumentString = undefined;
    @observable _activeDocumentName = undefined;

    constructor(dialogStore) {
        this.dialogStore = dialogStore;
        this.tenantId = dialogStore.sessionStore.session.tenantId;
        this.sessionId = dialogStore.sessionStore.session.id;
        this.viewPersistentId = uiHelper.getViewId(dialogStore.dialog.view);
    }

    refreshStore() {
        return this.getSaltDocumentNames();
    }

    saveOrUpdateDocument(saltDocument, name) {
        return this.getSaltDocumentNames().then(names => {
            this.clearEditorError();
            if (names.some(documentName => {
                return documentName && documentName.name === name;
            })) {
                return this.updateSalt({
                    documentName: name,
                    saltDocument,
                    discriminators: {},
                });
            }
            return this.storeSalt({
                documentName: name,
                saltDocument,
                discriminators: {},
            });
        });
    }

    loadSaltDocument(documentName) {
        return this.getSalt({ documentName }).then((result) => {
            this.clearEditorError();
            const { saltDocumentName, saltDocument } = result;
            this.setActiveDocumentName(saltDocumentName);
            const saltString = JSON.stringify(saltDocument, null, 2);
            this.setActiveDocumentString(saltString);
            return saltDocument;
        });
    }

    deleteDocument(documentName) {
        this.clearEditorError();
        return this.deleteSalt({ documentName });
    }

    resetStore() {
        this.clearEditorError();
        this.setActiveDocumentName(undefined);
        this.setActiveDocumentString(undefined);
    }

    getSaltDocumentNames() {
        return Catavolt.dialogApi.getSaltDocumentNames(this.tenantId, this.sessionId, this.viewPersistentId, { type: TypeNames.SaltParameters })
            .then(result => {
                const documentNames = result.arrayResult;
                this.setSaltDocumentNames(documentNames);
                return documentNames;
            });
    }

    get activeDocumentString() {
        if (this._activeDocumentString === undefined || this._activeDocumentString === null) {
            return JSON.stringify(this.dialogStore.saltDocument, null, 2);
        }
        return this._activeDocumentString;
    }

    get activeDocumentName() {
        if (this._activeDocumentName === undefined || this._activeDocumentName === null) {
            return this.dialogStore.getSaltDocumentName();
        }
        return this._activeDocumentName;
    }

    @action setSaltDocumentNames(documentNames) {
        this.saltDocumentNames = documentNames;
    }

    @action setActiveDocumentName(documentName) {
        if (this.editorError) this.clearEditorError();
        this._activeDocumentName = documentName;
    }

    @action setActiveDocumentString(saltDocumentString) {
        if (this.editorError) this.clearEditorError();
        this._activeDocumentString = saltDocumentString;
    }

    @action setEditorError(editorError) {
        this.editorError = editorError;
    }

    @action clearEditorError() {
        this.editorError = undefined;
    }

    @action asTransaction(transaction) {
        return transaction();
    }

    /**
     * ******************************************************************************
     * SDK interaction
     */

    getSaltImageNames() {
        return Catavolt.dialogApi.getSaltImageNames(this.tenantId, this.sessionId, { type: TypeNames.SaltParameters }).then(result => {
            return result.arrayResult;
        });
    }

    storeSalt(saltParameters) {
        return Catavolt.dialogApi.createSalt(this.tenantId, this.sessionId, this.viewPersistentId,
            {
                ...saltParameters,
                type: TypeNames.SaltParameters,
            });
    }

    getSalt(saltParameters) {
        return Catavolt.dialogApi.getSalt(this.tenantId, this.sessionId, this.viewPersistentId,
            {
                ...saltParameters,
                type: TypeNames.SaltParameters,
            });
    }

    updateSalt(saltParameters) {
        return Catavolt.dialogApi.updateSalt(this.tenantId, this.sessionId, this.viewPersistentId,
            {
                ...saltParameters,
                type: TypeNames.SaltParameters,
            });
    }

    deleteSalt(saltParameters) {
        return Catavolt.dialogApi.deleteSalt(this.tenantId, this.sessionId, this.viewPersistentId,
            {
                ...saltParameters,
                type: TypeNames.SaltParameters,
            });
    }
    /**
     * End SDK interaction
     * ******************************************************************************
     */
}
