import { DropDown } from 'cv-library-react-web';
import { themes } from 'cv-react-core';

// Get theme method
import getTheme from './DropDown.theme';

// Get theme component method
const { themeComponent } = themes;

// Generate and export a themed component
/**
 * @deprecated you should use XaltDropDown or XaltDropDownMultiSelect
 */
export default themeComponent(DropDown, getTheme);
