// Core component styles
const coreStyles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
};

/**
* Helper method to get the styles for Carousel component
* @param {Object} contextStyles - Context styles for the Carousel Component
*/
const getStyles = (contextStyles = {}) => {
    return {
        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },
    };
};

export default getStyles;
