import { prettyXmlParseError } from './logUtils';
import WarningNotifier from './WarningNotifier';
import Form from './paperModel/Form';
import Context from './Context';
import FormUtil from './FormUtil';

export default class PaperXmlToSalt {
    static xmlParser;
    errorString ;
    salt ;

    perform = (paperXml, viewId, errorCallback) => {
        const options = { explicitChildren: true, preserveChildrenOrder: true };

        const parseXmlString = PaperXmlToSalt.xmlParser;
        // Parse the XML into json.
        parseXmlString(paperXml, options, (error, paperJson) => {
            if (error) {
                this.errorString = `Error parsing Paper Forms XML.\n${prettyXmlParseError(paperXml, error)}\nThe offending line may be before or after this line.`;
                console.error(this.errorString);
            }
            if (paperJson) {
                const formJson = FormUtil.valueAt(paperJson, 'Form');
                const form = new Form(formJson);
                try {
                    const warnings = new WarningNotifier(errorCallback);
                    this.salt = form.asSalt(Context.starterContext(form, warnings), viewId);
                    warnings.report();
                    // console.log(`PaperForms to SALT transpile: ${(new Date()).getMilliseconds() - start.getMilliseconds()}`);
                    // Log.debug(JSON.stringify(this.salt, null, 4));
                    // Log.debug('GML/SALT:', this.salt);
                } catch (err) {
                    this.errorString = `Error converting Paper Forms XML to SALT: ${err.message || err}`;
                    if (err.stack) {
                        this.errorString += `\n\n${err.stack}`;
                    }
                    console.error(this.errorString);
                }
            }
        });
    }

    static setClientIsXHA(isXHA) {
        FormUtil.isXHA = isXHA;
    }
}
