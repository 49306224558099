import React from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import * as PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import getStyles from './ModalTitle.styles';

/**
 * A styled title that displays well in a modal.
 * @see https://material-ui.com/components/dialogs/
 * @see https://material-ui.com/api/dialog-title/
 */
const ModalTitle = (props) => {
    const {
        children,
        contextStyles,
        disableTypography,
        testID,
    } = props;

    // Override core styles with context styles, separating MUI styles
    const muiStyles = getStyles(contextStyles);

    // Create dynamic class names and injected DOM styles for MUI component
    // * makeStyles returns a function
    const styles = makeStyles(muiStyles)();

    return (
        <MuiDialogTitle
            className="c-modal-title"
            classes={ {
                root: styles.container, // MUI styles
            } }
            disableTypography={ disableTypography }
            data-test-id={ `${testID}__modal-title` }>
            { children }
        </MuiDialogTitle>
    );
};

ModalTitle.propTypes = {
    /** Content displayed in a styled modal title container */
    children: PropTypes.node,

    /** Extended styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the title container */
        container: PropTypes.object,

        /** Styles for the title */
        title: PropTypes.object,
    }),

    /** If true, the children won't be wrapped by a typography component */
    disableTypography: PropTypes.bool,

    /** Id used for testing */
    testID: PropTypes.string,
};

ModalTitle.defaultProps = {
    contextStyles: {},
    testID: 'ModalTitle',
};

export default ModalTitle;
