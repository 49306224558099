// Core component styles
const coreStyles = {
    chartTextStyle: {
    },

    container: {
    },

    errorTooltipStyle: {
        color: 'red',
        fontSize: 12,
    },

    legendContainerStyle: {
        bottom: 10,
        left: 'center',
        lineHeight: 14,
    },

    legendTextStyle: {
    },

    titleTextStyle: {
    },

    tooltipContainerStyle: {
        backgroundColor: '#000',
        borderRadius: 2,
    },

    tooltipTextStyle: {
        lineHeight: 13,
    },

    xAxisTextStyle: {
    },

    xAxisLabelTextStyle: {
    },

    yAxisTextStyle: {
    },

    yAxisLabelTextStyle: {
    },
};

const getStyles = (contextStyles = {}) => {
    return {
        chartTextStyle: {
            ...coreStyles.chartTextStyle,
            ...contextStyles.chartTextStyle,
        },

        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        errorTooltipStyle: {
            ...coreStyles.errorTooltipStyle,
            ...contextStyles.errorTooltipStyle,
        },

        legendContainerStyle: {
            ...coreStyles.legendContainerStyle,
            ...contextStyles.legendContainerStyle,
        },

        legendTextStyle: {
            ...coreStyles.legendTextStyle,
            ...contextStyles.legendTextStyle,
        },

        titleTextStyle: {
            ...coreStyles.titleTextStyle,
            ...contextStyles.titleTextStyle,
        },

        tooltipContainerStyle: {
            ...coreStyles.tooltipContainerStyle,
            ...contextStyles.tooltipContainerStyle,
        },

        tooltipTextStyle: {
            ...coreStyles.tooltipTextStyle,
            ...contextStyles.tooltipTextStyle,
        },

        xAxisTextStyle: {
            ...coreStyles.xAxisTextStyle,
            ...contextStyles.xAxisTextStyle,
        },

        xAxisLabelTextStyle: {
            ...coreStyles.xAxisLabelTextStyle,
            ...contextStyles.xAxisLabelTextStyle,
        },

        yAxisTextStyle: {
            ...coreStyles.yAxisTextStyle,
            ...contextStyles.yAxisTextStyle,
        },

        yAxisLabelTextStyle: {
            ...coreStyles.xAxisLabelTextStyle,
            ...contextStyles.xAxisLabelTextStyle,
        },
    };
};

export default getStyles;
