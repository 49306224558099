import React, { useState, useRef, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import ActivityIndicator from '../ActivityIndicator/ActivityIndicator';

import getStyles from './Image.styles';

/**
 * Image Component Class, that will render the supplied image url.
 * While Image is loading it will show a loader.
 */
const Image = (props) => {
    const {
        altText,
        contextStyles,
        imageSrc,
        isDisabled,
        onClick,
        showLoadingIndicator,
        testID,
        toolTip,
    } = props;
    const [
        isImageLoaded,
        setImageLoaded,
    ] = useState(false);
    const isMounted = useRef(true);
    useEffect(() => () => {
        isMounted.current = false;
    }, []);
    const showIndicator = showLoadingIndicator && !isImageLoaded;
    const styles = getStyles(contextStyles, isDisabled);

    return (
        <div
            className="c-image__container"
            data-test-id={ `${testID}__image-container` }
            style={ styles.container }>
            { showIndicator &&
                <ActivityIndicator
                    centered
                    contextStyles={ {
                        indicator: styles.loadingIndicator,
                    } }
                    testID={ `${testID}__image-indicator` } />
            }
            { /* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events */ }
            <img
                alt={ altText || imageSrc }
                className="c-image__image"
                data-test-id={ `${testID}__image-root` }
                onClick={ (event) => {
                    if (onClick && !isDisabled) {
                        onClick(event, props);
                    }
                } }
                onLoad={ () => {
                    if (isMounted.current) {
                        setImageLoaded(true);
                    }
                } }
                title={ toolTip }
                src={ imageSrc }
                style={ styles.image } />
        </div>
    );
};

Image.propTypes = {
    /** Image description */
    altText: PropTypes.string,

    /** Styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the container surrounding the image */
        container: PropTypes.object,

        /** Styles for the image */
        image: PropTypes.object,

        /** Styles for the loading indicator */
        loadingIndicator: PropTypes.object,
    }),

    /** The image source (either a remote URL or a local file resource) */
    imageSrc: PropTypes.string,

    /** Disables image touch */
    isDisabled: PropTypes.bool,

    /**
     * Called when the image was pressed
     * @param {Object} event - The native click event
     * @param {Object} props - The component instance props
     */
    onClick: PropTypes.func,

    /** Controls the loading indicator visibility */
    showLoadingIndicator: PropTypes.bool,

    /** Id used for testing */
    testID: PropTypes.string,

    /** toolTip string */
    toolTip: PropTypes.string,
};

Image.defaultProps = {
    contextStyles: {},
    showLoadingIndicator: true,
};

export default Image;
