import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';

import { XaltButton, XaltIconButton, XaltClear, XaltBox, XaltTextLabel } from 'xalt-react-atoms';
import CvModal from '../base/SimpleModal';
import SortControl from '../forms/SortControl';

import getStyles from './styles/SortModal.styles';

class SortModal extends PureComponent {
    static propTypes = {
        isModalOpen: PropTypes.bool,
        onCancel: PropTypes.func,
        sortTerms: PropTypes.array,
        sortableValues: PropTypes.array,
        onSubmit: PropTypes.func,
    }

    constructor(props) {
        super(props);
        const { sortTerms } = props;

        this.state = { localSortTerms: [ ...sortTerms ] };
    }

    render() {
        const {
            isModalOpen,
            onCancel,
            sortableValues,
        } = this.props;

        const { localSortTerms } = this.state;

        const {
            modal,
            modalColumnContainer,
            modalContent,
            headingContainer,
            closeIcon,
            headingText,
            bodyContainer,
            footerContainer,
            applyButton,
            applyButtonHover,
            resetButton,
            resetButtonHover,
        } = getStyles();

        return (
            <CvModal
                isModalOpen={ isModalOpen }
                isTransparentOverlay
                contextStyles={ { modal, modalColumnContainer } }>
                <XaltBox style={ modalContent }>
                    <XaltBox style={ headingContainer }>
                        <XaltTextLabel style={ headingText }>Custom Sort</XaltTextLabel>
                        <XaltIconButton
                            onClick={ onCancel }>
                            <XaltClear
                                style={ closeIcon } />
                        </XaltIconButton>
                    </XaltBox>
                    <XaltBox style={ bodyContainer }>
                        <SortControl
                            sortTerms={ localSortTerms }
                            sortableValues={ sortableValues }
                            onSortTermsChange={ this.handleOnSortTermsChange } />
                    </XaltBox>
                    <XaltBox style={ footerContainer }>
                        <XaltButton
                            style={ resetButton }
                            hoverStyle={ resetButtonHover }
                            onClick={ this.handleOnSortClear }>
                                Reset
                        </XaltButton>
                        <XaltButton
                            style={ applyButton }
                            hoverStyle={ applyButtonHover }
                            onClick={ this.handleOnSubmit }>
                                Apply
                        </XaltButton>
                    </XaltBox>
                </XaltBox>
            </CvModal>
        );
    };

    handleOnSortTermsChange = (sortTerms) => {
        this.setState({ localSortTerms: [ ...sortTerms ] });
    }

    handleOnSubmit = () => {
        const { onSubmit } = this.props;
        const { localSortTerms } = this.state;

        onSubmit(localSortTerms);
    }

    handleOnSortClear = () => {
        this.setState({ localSortTerms: [] });
    }
}

export default SortModal;