import { action, observable, computed } from 'mobx';
import DialogStore from './DialogStore';

export default class FormDialogStore extends DialogStore {
    // panels can be expanded to claim the full view
    @observable expandedDialogId = null;
    // when there's a tab with only 1 child panel, we remove the panel menu
    @observable tabSingleDialogId = null;

    // in case of maintainable query, all the child Grids are linked to each other with their reference holding in below property
    alignedGrids = null;

    /**
     * @param actionId
     * @returns {Promise<Redirection>}
     */
    @action performMenuAction = ({ actionId }) => {
        return this.dialog.performMenuActionWithId(actionId)
            .then((result) => {
                // Update any dialog observables (i.e. isDestroyed, etc.)
                this.updateDialogObservables();
                return result;
            });
    };

    @action setExpandedDialogId(expandedDialogId) {
        this.expandedDialogId = expandedDialogId;
    }

    @action setTabSingleDialogId(tabSingleDialogId) {
        this.tabSingleDialogId = tabSingleDialogId;
    }

    get expandedChildStore() {
        return this.expandedDialogId ? this.getChildDialogStore(this.expandedDialogId) : null;
    }

    get tabSingleDialogStore() {
        return this.tabSingleDialogId ? this.getChildDialogStore(this.tabSingleDialogId) : null;
    }

    get currentSingleViewChildStore() {
        return this.expandedOrSingleChildStore || this.tabSingleDialogStore;
    }

    get expandedOrSingleChildStore() {
        if (this.isExpandedState) {
            return this.expandedChildStore;
        }
        return this.isSingleChildStore ? this.firstChildStore : null;
    }

    @computed get isCurrentSingleView() {
        return this.isSingleChildStore || this.isExpandedState || this.isSinglePanelTab;
    }

    get isExpandedOrSinglePanelView() {
        return this.isSingleChildStore || this.isExpandedState;
    }

    get isSingleChildStore() {
        return this.childDialogStores.length === 1;
    }

    get firstChildStore() {
        return this.childDialogStores[0];
    }

    get lastChildStore() {
        return this.childDialogStores[this.childDialogStores.length - 1];
    }

    get isExpandedState() {
        return !!this.expandedDialogId;
    }

    get isSinglePanelTab() {
        return !!this.tabSingleDialogId;
    }

    setAlignedGrids = (value) => {
        this.alignedGrids = value;
    };

    getAlignedGrids = () => {
        return this.alignedGrids;
    };

    get isMaintainableQueryInProgress() {
        return this.childDialogStores.some((childStore) => ((childStore.queryInProgress || childStore.refreshInProgress) && !childStore.isRefreshTriggeredByTimer));
    }
}
