import React from 'react';

/**
 * Deep merge styles.
 * Accepts multiple style object arguments.
 * Each successive style object argument overwrites
 * the previously merged style objects where necessary.
 * @param {Object} args
 */
const combineStyles = (...args) => {
    const combine = (...styles) => {
        const allStyles = {};
        styles.forEach((style) => {
            if (typeof style === 'object') {
                Object.keys(style).forEach((key) => {
                    if (style[key] && typeof style[key] === 'object') {
                        allStyles[key] = combine(allStyles[key], style[key]);
                    } else {
                        allStyles[key] = style[key];
                    }
                });
            }
        });
        return allStyles;
    };
    return combine(...args);
};

/**
 * Injects theme styles into component context styles
 * @param {Function} ComponentToTheme
 * @param {Function|Object} theme
 * @return {Function}
 */
const themeComponent = (ComponentToTheme, theme) => {
    const ThemedComponent = React.forwardRef((props, ref) => {
        // Handle the ability to run a function to get a theme.
        // This helps support a data store that may not ready
        // when imports get resolved.
        const themeStyles = typeof theme === 'function' ? theme() : theme;

        // Pull out the context styles to combine with theme styles
        const { contextStyles, ...restProps } = props;

        // Create new props with themed context styles
        // overridden by any passed context styles
        const themedProps = {
            ...restProps,
            ref,
            contextStyles: combineStyles(themeStyles, contextStyles),
        };

        // Return themed component
        return (
            <ComponentToTheme { ...themedProps } />
        );
    });
    // Persist prop types and defaults as component to theme
    ThemedComponent.propTypes = { ...ComponentToTheme.propTypes };
    ThemedComponent.defaultProps = { ...ComponentToTheme.defaultProps };
    return ThemedComponent;
};

export { combineStyles };
export default themeComponent;
