import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { XaltColorPicker } from 'xalt-react-atoms';
import RWFieldAction from './RWFieldAction';

/**
 * A platform component for building a client action
 */
class RWFieldActionColorPicker extends PureComponent {
    static propTypes = {
        style: PropTypes.object,
        action: PropTypes.object,
        onFieldAction: PropTypes.func,
        value: PropTypes.string,
        semanticType: PropTypes.string,
        type: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.handleOnActionClick = this.handleOnActionClick.bind(this);
        this.colorPickerRef = React.createRef();
    }

    render() {
        const {
            value,
            ...restProps } = this.props;

        const standardActionPrps = {
            ...restProps,
            value,
            onFieldAction: this.handleOnActionClick, // override standard action click
        };

        return (
            <>
                <RWFieldAction { ...standardActionPrps } />
                <XaltColorPicker
                    ref={ this.colorPickerRef }
                    value={ value }
                    onChange={ this.handleOnChange } />
            </>
        );
    }

    handleOnChange = (color) => {
        const { action } = this.props;
        const { clientFunction } = action;
        if (clientFunction) {
            clientFunction(color);
        }
    };

    handleOnActionClick(event) {
        if (this.colorPickerRef && this.colorPickerRef.current) {
            this.colorPickerRef.current.click(event);
        }
    }
}

export default RWFieldActionColorPicker;
