import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import {
    IntegratedSelection,
    SelectionState,
    VirtualTableState,
} from '@devexpress/dx-react-grid';
import {
    Plugin,
    Template,
    TemplateConnector,
} from '@devexpress/dx-react-core';
import {
    DragDropProvider,
    Grid,
    TableColumnReordering,
    TableColumnResizing,
    TableColumnVisibility,
    TableFixedColumns,
    TableHeaderRow,
    TableSelection,
    VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import TableMUI from '@material-ui/core/Table';

import Checkbox from '../Checkbox/Checkbox';
import DataTableHeaderCell, { SORT_DIRECTIONS } from './DataTableHeaderCell';
import DataTableCell from './DataTableCell';
import DataTableRow from './DataTableRow';
import DataTableSelectionTray from './DataTableSelectionTray';

import getStyles from './DataTable.styles';

/**
 * Create component to override the DXG root element style
 * @param props
 * @return {*}
 * @constructor
 */
const GridRoot = (props) => (
    <Grid.Root
        { ...props }
        style={ {
            height: '100%',
            overflow: 'auto',
        } } />
);

/**
 * An extensive table grid control
 * @see https://devexpress.github.io/devextreme-reactive/react/grid/
 */
class DataTable extends PureComponent {
    render() {
        const {
            columns, // [ { name: String, title?: String } ]
            columnWidths,
            columnOrder, // [ String ]
            contextStyles, // Object
            fixedLeftColumns, // [ String ]
            hasMoreRecords, // Boolean
            hiddenColumns,
            loading, // Boolean
            multiSelect, // Boolean
            pageSize, // Number
            rows, // [ Object ]
            listActivityIndicator,
            selectedRowIndexes, // [ Number ]
            showSelectionTray, // Boolean
            overrideMessages,
            doubleClickForDefaultAction,
        } = this.props;

        this.styles = getStyles(contextStyles);

        // Begin plugins collection
        // @see https://www.devexpress.com/Support/Center/Question/Details/T758233/react-grid-the-encountered-two-children-with-the-same-key-symbol-reordering-error-occurs
        const plugins = [];

        /* Include Lazy Loading (different from infiniteScrolling) Functionality and Handling */
        plugins.push(
            <VirtualTableState
                infiniteScrolling={ false }
                loading={ loading || ( hasMoreRecords && !rows.length ) }
                pageSize={ pageSize }
                skip={ 0 }
                getRows={ () => Promise.resolve() } />
        );

        /* Include Row Selection Handling */
        plugins.push(
            <SelectionState
                selection={ selectedRowIndexes } />
        );

        /* Include Drag and Drop Functionality and Handling */
        plugins.push(
            <DragDropProvider />
        );

        /* Include Table */
        plugins.push(
            <VirtualTable
                cellComponent={ this.getTableCellComponent }
                height="auto"
                messages={ { noData: overrideMessages.noData } }
                rowComponent={ this.getTableRowComponent }
                containerComponent={ this.renderTableComponent }
                stubHeaderCellComponent={ this.getStubHeaderCellComponent } />
        );

        /* Include Column Reordering Functionality and Handling */
        plugins.push(
            <TableColumnReordering
                order={ columnOrder }
                onOrderChange={ this.handleOrderChange } />
        );

        /* Include Column Resizing Functionality and Handling */
        /* Controlled With Internal Grid State */
        if (columnWidths.length > 0) {
            plugins.push(
                <TableColumnResizing
                    defaultColumnWidths={ columnWidths }
                    minColumnWidth={ 25 } />
            );
        }

        /* Include Column Header Functionality and Handling */
        plugins.push(
            <TableHeaderRow
                cellComponent={ this.getHeaderCellComponent }
                contentComponent={ this.getHeaderCellContentComponent }
                rowComponent={ this.getHeaderRowComponent } />
        );

        /* Include the Multiple Row Selection Functionality and Handling  */
        plugins.push(
            <IntegratedSelection />
        );

        /* Include Row Selection Functionality */
        if (!doubleClickForDefaultAction) {
            plugins.push(
                <TableSelection
                    cellComponent={ this.getTableSelectionCellComponent }
                    selectByRowClick
                    highlightRow
                    showSelectionColumn={ multiSelect } />
            );
        }

        /* Include Fixed/Frozen/Pinned columns */
        plugins.push(
            <TableFixedColumns
                leftColumns={ [
                    TableSelection.COLUMN_TYPE,
                    ...fixedLeftColumns,
                ] } />
        );

        // Add table row override
        // @see https://github.com/DevExpress/devextreme-reactive/issues/437
        plugins.push(
            <Template
                name="tableRow"
                predicate={ this.getTableRowPredicate }>
                { this.getTableRowConnector }
            </Template>
        );

        plugins.push(
            <TableColumnVisibility
                hiddenColumnNames={ hiddenColumns } />
        );

        return (
            <div style={ { display: 'flex', flexDirection: 'column', overflow: 'hidden', flex: '1 0 300px' } }>
                <Grid
                    columns={ columns }
                    key={ plugins.length }
                    rows={ rows }
                    rootComponent={ GridRoot }>
                    { plugins.map((plugin, index) => (
                        <Plugin
                            // eslint-disable-next-line react/no-array-index-key
                            key={ index }>
                            { plugin }
                        </Plugin>
                    )) }
                </Grid>
                { listActivityIndicator }
                { showSelectionTray &&
                    <div>
                        <DataTableSelectionTray
                            contextStyles={ {
                                closeButton: this.styles.rowSelectionTrayCloseButton,
                                closeButtonIcon: this.styles.rowSelectionTrayCloseButtonIcon,
                                container: this.styles.rowSelectionTray,
                                text: this.styles.rowSelectionTrayText,
                            } }
                            count={ selectedRowIndexes.length }
                            onClear={ this.handleRowSelectionClear } />
                    </div>
                }
            </div>
        );
    };

    // its wraps the table with an enclosing container which can be styled
    renderTableComponent = ( args ) => (
        <TableMUI
            ref={ args.tableRef }
            style={ { ...args.style, width: '-webkit-fill-available' } }>
            { args.children }
        </TableMUI>
    )

    getHeaderRowComponent = (baseProps) => {
        const {
            children,
            style,
            ...rest
        } = baseProps;

        return (
            <VirtualTable.Row
                { ...rest }
                style={ {
                    ...style,
                    ...this.styles.headerRow,
                } }>
                { children }
            </VirtualTable.Row>
        );
    };

    /**
     * Called to render a table header cell (<th/>)
     * @param {CellProps} headerCellProps
     * @return {React.ReactElement}
     */
    getHeaderCellComponent = (headerCellProps) => {
        // console.log('HEADER CELL PROPS: ', headerCellProps); // eslint-disable-line
        const {
            fixedLeftColumns,
        } = this.props;
        const {
            children,
            style,
            column,
            ...rest
        } = headerCellProps;
        const {
            isFiltered,
            spacer,
        } = column;

        const spacerCellStyles = spacer ? this.styles.spacerCell : {};
        const headerCellStyles = isFiltered ? {
            ...style,
            ...this.styles.headerCell,
            ...this.styles.headerCellFiltered,
        } : {
            ...style,
            ...this.styles.headerCell,
            ...spacerCellStyles,
        };

        return (
            <TableHeaderRow.Cell
                { ...rest }
                column={ column }
                draggingEnabled={ !fixedLeftColumns.includes(column.name) }
                style={ headerCellStyles }>
                { children }
            </TableHeaderRow.Cell>
        );
    };

    /**
     * @typedef {Object} HeaderCellContent
     * @property {'left'|'right'|'center'} align - Specifies the table’s column alignment.
     * @property {React.ReactNode|React.ReactNodeArray} children - React node(s) used to render the cell content.
     * @property {Column} column - Specifies the associated user column.
     *
     * Called to render content inside a table header cell (<th/>)
     * @param {HeaderCellContent} baseProps
     * @return {React.ReactElement}
     */
    getHeaderCellContentComponent = (baseProps) => {
        const {
            fixedLeftColumns, // [ String ]
            onColumnFrozen, // Function { String } columnName
            onColumnVisibility,
            onSortColumn,
            onShowSortForm,
            isCustomSortEnabled,
            sortDirectives, // [ { columnName: String, direction: 'asc'|'desc'|null } ]
            menuComponent,
            rows,
        } = this.props;
        const {
            // Available props
            // align: PropTypes.bool,
            // children: PropTypes.oneOfType([
            //     PropTypes.array,
            //     PropTypes.node,
            //     PropTypes.element,
            // ]),
            column,
        } = baseProps;
        const {
            name,
            title,
            isFiltered,
            spacer,
        } = column;
        if (spacer) return <div style={ this.styles.verticalDivider } />;
        const sortDirectiveIndex = sortDirectives.findIndex((sortedColumn) => (sortedColumn.columnName === name));
        let sortDirective = {};
        let sortOrder;
        if (sortDirectiveIndex !== -1) {
            sortDirective = sortDirectives[sortDirectiveIndex];
            sortOrder = sortDirectiveIndex;
        }
        const {
            direction,
        } = sortDirective;

        const headerCellLockButton = isFiltered ? {
            ...this.styles.headerCellLockButtonSection,
            ...this.styles.headerCellFiltered,
        } : this.styles.headerCellLockButtonSection;

        // TODO: rename DataTableHeaderCell to DataTableHeaderCellContent
        return (
            <DataTableHeaderCell
                columnName={ name }
                columnTitle={ title }
                columnLocked={ fixedLeftColumns.includes(name) }
                contextStyles={ {
                    filterButton: {
                        ...this.styles.headerCellButton,
                        ...this.styles.headerCellFilterButton,
                    },
                    filterButtonBadge: this.styles.headerCellButtonBadge,
                    filterButtonIcon: {
                        ...this.styles.headerCellButtonIcon,
                        ...this.styles.headerCellFilterButtonIcon,
                    },
                    lockButton: {
                        ...this.styles.headerCellButton,
                        ...this.styles.headerCellLockButton,
                    },
                    lockButtonBadge: this.styles.headerCellButtonBadge,
                    lockButtonIcon: {
                        ...this.styles.headerCellButtonIcon,
                        ...this.styles.headerCellLockButtonIcon,
                    },
                    lockButtonSection: headerCellLockButton,
                    sortButtonSection: this.styles.headerCellSortButtonSection,
                    verticalDivider: this.styles.verticalDivider,
                    text: this.styles.headerCellText,
                } }
                onColumnFrozen={ onColumnFrozen }
                onColumnVisibility={ onColumnVisibility }
                onSortColumn={ onSortColumn }
                onShowSortForm={ onShowSortForm }
                isCustomSortEnabled={ isCustomSortEnabled }
                sortDirection={ direction }
                sortOrder={ sortOrder }
                menuComponent={ menuComponent }
                showMenuComponent={ rows.length > 0 } />
        );
    };

    /**
     * Specifies whether the row template should be rendered
     * @typedef {Object} Row
     * @property {Array.<React.Element>} children
     * @property {Object} style
     * @property {TableRow} tableRow
     *
     * @param {Row} params
     * @returns {Boolean}
     */
    getTableRowPredicate = (params) => {
        const { tableRow } = params;
        return tableRow.type === VirtualTable.ROW_TYPE;
    };

    /**
     * Called to override DXG table row component
     * @param {Row} connectorParams
     * @returns {React.ReactElement}
     */
    getTableRowConnector = (connectorParams) => {
        const {
            tableRow,
        } = connectorParams;
        const {
            rowId,
        } = tableRow;
        return (
            <TemplateConnector>
                { (getters) => {
                    const {
                        selection,
                    } = getters;
                    return this.getTableRowComponent({
                        ...connectorParams,
                        selected: selection.findIndex((i) => (i === rowId)) > -1,
                    });
                } }
            </TemplateConnector>
        );
    };

    /**
     * Called to render a table row (<tr/>)
     * @param {Object} baseProps
     * @property {Array} baseProps.children
     * @property {Function} baseProps.onToggle
     * @property {Boolean} baseProps.selected
     * @property {Object} baseProps.style
     * @property {TableRow} baseProps.tableRow
     * @return {React.ReactElement}
     */
    getTableRowComponent = (baseProps) => {
        // Available props
        const { // eslint-disable-line
            tableRow,
            children,
            selected,
            style,
        } = baseProps;

        const {
            rowComponent: TableRow, // Function
        } = this.props;
        const { alternatingColors } = this.styles.row;

        const colors = alternatingColors.split(',').map((m) => m.trim()) || '';
        let backgroundColor = '';
        if (colors.length > 0) {
            const colorIndex = tableRow.rowId % colors.length;
            backgroundColor = colors[colorIndex];
        }

        let styles = {
            ...style,
            backgroundColor,
            ...this.styles.row,
        };

        if (selected) {
            tableRow.selected = selected;
            styles = {
                ...styles,
                ...this.styles.rowSelected,
            };
        }

        return (
            <TableRow
                onContextMenu={ this.handleRowContextMenu }
                onVisible={ this.handleMoreRows }
                style={ styles }
                tableRow={ tableRow }>
                { children }
            </TableRow>
        );
    };

    /**
     * Called to render a table stub header cell (<th/>)
     * @param {CellProps} baseProps
     * @returns {React.ReactElement}
     */
    getStubHeaderCellComponent = (baseProps) => {
        // Available props
        const {
            style,
        } = baseProps;
        const styles = {
            ...style,
            ...this.styles.headerCell,
        };

        return (
            <VirtualTable.StubHeaderCell
                { ...baseProps }
                style={ styles } />
        );
    }

    /**
     * Called to render a table cell (<td/>)
     * @param {CellProps} baseProps
     * @returns {React.ReactElement}
     */
    getTableCellComponent = (baseProps) => {
        const {
            cellComponent: TableCell, // Function
            selectedRowIndexes,
        } = this.props;

        // Available props
        const {
            column,
            tableColumn,
            tableRow,
            style,
        } = baseProps;
        const {
            rowId,
        } = tableRow;
        const {
            fixed,
        } = tableColumn;

        let styles = {
            ...style,
            ...this.styles.cell,
            cellImage: { ...this.styles.cellImage },
        };

        if (fixed) {
            const { fixedAlternatingColors } = this.styles.row;
            const colors = fixedAlternatingColors.split(',').map((m) => m.trim()) || '';
            let backgroundColor = '';

            if (colors.length > 0) {
                const colorIndex = rowId % colors.length;
                backgroundColor = colors[colorIndex];
            }
            styles.backgroundColor = backgroundColor;
        }

        if (selectedRowIndexes.includes(rowId)) {
            styles = {
                ...styles,
                ...this.styles.cellSelected,
            };
        }

        return (
            <TableCell
                { ...baseProps }
                onClick={ (event) => this.handleSelectionChange(rowId, selectedRowIndexes, event, column.name) }
                onDoubleClick={ (event) => this.handleSelectionChange(rowId, selectedRowIndexes, event, column.name) }
                style={ styles } />
        );
    };

    /**
     * Called to render a custom table selection cell (<td/>)
     * @param {CellProps} baseProps
     * @property {Object} row - the user defined row
     * @property {Boolean} selected - indicates whether a row is selected
     * @property {Function} onToggle - DXG internal row selection toggle function
     * @return {React.ReactElement}
     */
    getTableSelectionCellComponent = (baseProps) => {
        const {
            selectedRowIndexes,
        } = this.props;
        // Available props
        const {
            selected,
            tableRow,
        } = baseProps;
        const {
            rowId,
        } = tableRow;
        let styles = {
            ...this.styles.cell,
            ...this.styles.rowSelectionCell,
        };

        if (selected) {
            styles = {
                ...styles,
                ...this.styles.cellSelected,
                ...this.styles.rowSelectionCellSelected,
            };
        }

        return (
            <DataTableCell
                { ...baseProps }
                onClick={ (event) => this.handleSelectionChange(rowId, selectedRowIndexes, event) }
                style={ styles }>
                <Checkbox
                    checked={ selected }
                    contextStyles={ {
                        check: this.styles.rowSelectionCellCheck,
                        checked: this.styles.rowSelectionCellChecked,
                    } } />
            </DataTableCell>
        );
    };

    /**
     * Called on column reorder
     * @param {Array.<String>} columnsNamesInOrder
     * @returns {void}
     */
    handleOrderChange = (columnsNamesInOrder) => {
        const {
            fixedLeftColumns,
            onColumnsReordered,
        } = this.props;
        const leftColumns = columnsNamesInOrder.slice(0, fixedLeftColumns.length);

        if (onColumnsReordered && leftColumns.every((column) => fixedLeftColumns.includes(column))) {
            this.resetWhataHackNumber();
            onColumnsReordered(columnsNamesInOrder);
        }
    };

    /**
     * Called on row right click
     * @param {SyntheticEvent} event
     * @param {TableRow} tableRow
     * @returns {void}
     */
    handleRowContextMenu = (event, tableRow) => {
        const {
            onRowContextMenu,
            selectedRowIndexes,
        } = this.props;
        if (onRowContextMenu) {
            onRowContextMenu(event, tableRow, selectedRowIndexes);
        }
    };

    /**
     * Called on selection tray clear selections button click
     * @returns {void}
     */
    handleRowSelectionClear = () => {
        const {
            onRowSelectionClear,
        } = this.props;
        if (onRowSelectionClear) {
            onRowSelectionClear();
        }
    };

    /**
     * Called on row selection
     * @param {Number} newSelectionIndex
     * @param {Array.<Number>} previousSelectionIndexes
     * @param {SyntheticEvent} event
     * @returns {void}
     */
    handleSelectionChange = (newSelectionIndex, previousSelectionIndexes, event, columnName) => {
        const {
            onRowSelectionChange,
        } = this.props;
        if (onRowSelectionChange) {
            onRowSelectionChange(previousSelectionIndexes, newSelectionIndex, event, columnName);
        }
    };

    whatAHackNumber = 50;

    resetWhataHackNumber() {
        this.whatAHackNumber = 50;
    }

    /**
     * Called when scrolling
     * @param {Object} row - Current row
     * @returns {void}
     */
    handleMoreRows = (row) => {
        const {
            onLoadMoreData,
            rows,
            queryInProgress,
        } = this.props;

        // TODO: PLEASE READ
        // We are keeping 50 records ahead of the rows loaded. This variable is used
        // to make sure we do not force unecessary calls to the load process. Since this
        // is fired on every row, the side effect is we hammer the render method with a bunch
        // of useless renders. We check this against the loacal hack variable for how many we
        // will load. This is hacked into place because this is a horrible place to call the on
        // loadMoreData process. We have to do this until we setup a proper table to manage reaching
        // the end of a view.
        const hackRowNumber = rows.indexOf(row) * 2;

        // whatAHackNumber is the place holder for how many rows we have loaded or will load. Since this method is called
        // for all rows, it was hammering the app with onLoadMoreData calls for each row. This whatAHackNumber will keep up
        // with how many rows we are loading or how many we will load. We could use queryInProgress, but this method fires so
        // often that we still have a lot more than necessary renders.
        if (onLoadMoreData && (hackRowNumber >= this.whatAHackNumber)) {
            // This is just one more check to not overload the calls to fetch more records
            if (!queryInProgress) {
                this.whatAHackNumber = rows.length + 50;
                onLoadMoreData();
            }
        }
    };
}

/**
 * @typedef {Function} LocalizeMessage
 * @param {String} value
 * @returns {String}
 */

DataTable.propTypes = {

    /** Component used to generate the row table cells and its contents */
    cellComponent: PropTypes.oneOfType([
        /**
         * @typedef {Object} TableColumn - DXG Table Column Object
         * @property {'left'|'right'|'center'} align - Specifies the table’s column alignment.
         * @property {Column} column - Specifies the associated user column.
         * @property {'left'|'right'} fixed - Specifies the fixed table’s column alignment.
         * @property {String} key - A unique table column identifier.
         * @property {Symbol} type - Specifies the table column type. The specified value defines which cell template is used to render the column.
         * @property {Number} width - Specifies the table column width.
         */
        /**
         * @typedef {Object} TableRow - DXG Table Row Object
         * @property {Number|String} height - Specifies the table row height.
         * @property {String} key - A unique table row identifier.
         * @property {Object} row - Specifies the associated user row.
         * @property {Number|String} rowId - Specifies the associated row’s ID.
         * @property {Symbol} type - Specifies the table row type. The specified value defines which cell template is used to render the row.
         */
        /**
         * @typedef {Object} Column - Specifies the user column.
         * @property {String} name - column reference name
         * @property {String} title - column display text
         */
        /**
         * @typedef {Object} CellProps
         * @property {React.ReactNode|React.ReactNodeArray} children - React node(s) used to render the cell content.
         * @property {String} className - Classes added to the cell <td> container.
         * @property {Number} colSpan - The count of columns that the root cell element spans.
         * @property {Column} column - Specifies the cells associated user column.
         * @property {Object} row - Specifies the cell's row.
         * @property {Number} rowSpan - The count of rows that the root cell element spans.
         * @property {Object} style - Styles added to the cell <td> container.
         * @property {TableColumn} tableColumn - Specifies a DXG table column.
         * @property {TableRow} tableRow - Specifies a DXG table row.
         * @property {*} value - Specifies a value to be rendered within the cell.
         */
        /**
         * @param {CellProps} cellProps
         * @returns {React.ReactNode}
         */
        PropTypes.func,

        /** React.ReactNode */
        PropTypes.object,
    ]),

    /** Display order of columns by column name */
    columnOrder: PropTypes.arrayOf(PropTypes.string),

    /** An array containing column data */
    columns: PropTypes.arrayOf(PropTypes.shape({
        /** The name identifier of the column */
        name: PropTypes.string,

        /** The column header display text */
        title: PropTypes.string,

        /** Overrides the default table column width in pixels or CSS-accepted units (auto, px, %, em, rem, vm, vh, vmin, vmax) */
        width: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
    })).isRequired,

    /** Column Widths */
    columnWidths: PropTypes.array,

    /** Extended styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for each table cell */
        cell: PropTypes.object,

        /** Styles for each table cell selected state */
        cellSelected: PropTypes.object,

        /** Styles for the container surrounding the table */
        container: PropTypes.object,

        /** Styles for all column header cells */
        headerCell: PropTypes.object,

        /** Styles for all column header cell buttons */
        headerCellButton: PropTypes.object,

        /** Styles for all header cell button badges */
        headerCellButtonBadge: PropTypes.object,

        /** Styles for all column header cell button icons */
        headerCellButtonIcon: PropTypes.object,

        /** Styles for all column header cell filter buttons */
        headerCellFilterButton: PropTypes.object,

        /** Styles for all column header cell filter button icons */
        headerCellFilterButtonIcon: PropTypes.object,

        /** Styles for all column header cell column lock buttons */
        headerCellLockButton: PropTypes.object,

        /** Styles for all column header cell column lock button icons */
        headerCellLockButtonIcon: PropTypes.object,

        /** Styles for all column header cell column names */
        headerCellText: PropTypes.object,

        /** Styles for the header row */
        headerRow: PropTypes.object,

        /**
         * Styles for each table row
         * Notes:
         * Row styles are only visible when cell background is transparent
         * Transparent cell backgrounds and frozen columns are not compatible.  Use cell related styles instead.
         */
        row: PropTypes.object,

        /**
         * Styles for each selected table row
         * Row styles are only visible when cell background is transparent
         * Transparent cell backgrounds and frozen columns are not compatible.  Use cell related styles instead.
         */
        rowSelected: PropTypes.object,

        /** Styles for the row selection (checkbox) cell */
        rowSelectionCell: PropTypes.object,

        /** Styles for the row selection (checkbox) cell checkbox */
        rowSelectionCellCheck: PropTypes.object,

        /** Styles for the row selection (checkbox) cell checkbox in a true state */
        rowSelectionCellChecked: PropTypes.object,

        /** Styles for the row selection (checkbox) cell row selected state */
        rowSelectionCellSelected: PropTypes.object,

        /** Styles for the row selection tray */
        rowSelectionTray: PropTypes.object,

        /** Styles for the row selection tray close button */
        rowSelectionTrayCloseButton: PropTypes.object,

        /** Styles for the row selection tray close button icon */
        rowSelectionTrayCloseButtonIcon: PropTypes.object,

        /** Styles for the row selection tray text */
        rowSelectionTrayText: PropTypes.object,

        /** Styles for the search cancel button */
        searchcancelButton: PropTypes.object,

        /** Styles for the search cancel button icon */
        searchcancelButtonIcon: PropTypes.object,

        /** Styles for the search field container */
        searchFieldContainer: PropTypes.object,

        /** Styles for the search field input */
        searchFieldInput: PropTypes.object,

        /** Styles for the search field underline */
        searchFieldUnderline: PropTypes.object,

        /** Styles for the search field underline in a focused state */
        searchFieldunderlineFocus: PropTypes.object,

        /** Styles for the text field underline in a hover state */
        searchFieldunderlineHover: PropTypes.object,

        /** Styles for the search button */
        searchSubmitButton: PropTypes.object,

        /** Styles for the search button icon */
        searchSubmitButtonIcon: PropTypes.object,
    }),

    /**
     * Names of columns to freeze and affix to the left of the table
     * NOTE: non-contiguous column names do NOT reorder columns and dock together as a group.
     * If fixed grouped non-contiguous columns are desired then the columnOrder prop must be reordered with
     * fixed columns at the beginning of the columns array in the desired fixed order.
     */
    fixedLeftColumns: PropTypes.arrayOf(PropTypes.string),

    /** Indicates if we have more records */
    hasMoreRecords: PropTypes.bool,

    /** Shows the table loading state when enabled */
    loading: PropTypes.bool,

    /** Enables row selection utilizing a selection column */
    multiSelect: PropTypes.bool,

    /**
     * Called on column freeze toggle component click
     * @param {String} columnName - the name of the column with the freeze toggle
     * @returns {void}
     */
    onColumnFrozen: PropTypes.func,

    /**
     * Called on column reorder
     * @param {Array.<String>} columnsNamesInOrder
     * @returns {void}
     */
    onColumnsReordered: PropTypes.func,

    /**
     * Called on column resize
     * @param {Array.<{ columnName: String, width: Number }>} columnsWithWidths
     * @returns {void}
     */
    onColumnsResized: PropTypes.func,

    /**
     * Called when the viewport row threshold reaches a certain point when scrolling
     * @returns {void}
     */
    onLoadMoreData: PropTypes.func,

    /**
     * Called on row right click
     * @param {SyntheticEvent} event
     * @param {TableRow} tableRow - Specifies a DXG table row.
     * @returns {void}
     */
    onRowContextMenu: PropTypes.func,

    /**
     * Called on row click
     * @param {Array.<Number>} previousSelectionIndexes
     * @param {Number} newSelectionIndex
     * @param {SyntheticEvent} event
     * @returns {void}
     */
    onRowSelectionChange: PropTypes.func,

    /**
     * Called on selection tray clear selections button click
     * @returns {void}
     */
    onRowSelectionClear: PropTypes.func,

    /**
     * Called on column sort ascending toggle component click
     * @param {String} columnName - the name of the column to be sorted
     * @returns {void}
     */
    onSortAscending: PropTypes.func,

    /**
     * Called on column sort clear toggle component click
     * @param {String} columnName - the name of the column to clear sorting
     * @returns {void}
     */
    onSortColumn: PropTypes.func,

    /**
     * Called from menu to show custom sort modal
     * @returns {void}
     */
    onShowSortForm: PropTypes.func,

    /**
     * Whether the custom sort functionality is available
     * @return {bool}
     */
    isCustomSortEnabled: PropTypes.bool,

    /**
     * Called on column sort descending toggle component click
     * @param {String} columnName - the name of the column to be sorted
     * @returns {void}
     */
    onSortDescending: PropTypes.func,

    /** Specifies the count of rows in the current virtual chunk */
    pageSize: PropTypes.number,

    /** Component used to generate the table rows */
    rowComponent: PropTypes.oneOfType([
        /**
         * @typedef {Object} RowProps
         * @property {React.ReactNodeArray} children - React nodes used to render the row cells.
         * @property {Function} onClick - The row click handler.
         * @property {Function} onDoubleClick - The row double click handler.
         * @property {Object} style - Styles added to the cell <td> container.
         * @property {TableRow} tableRow - Specifies a DXG table row.
         */
        /**
         * @param {RowProps} rowProps
         * @returns {React.ReactNode}
         */
        PropTypes.func,

        /** React.ReactNode */
        PropTypes.object,
    ]),

    /** An array containing custom data */
    rows: PropTypes.array.isRequired,

    /** Indexes of rows that show as selected in the UI */
    selectedRowIndexes: PropTypes.arrayOf(PropTypes.number),

    /** Shows the table record selection tray when enabled */
    showSelectionTray: PropTypes.bool,

    /** User supplied column sorting directives */
    sortDirectives: PropTypes.arrayOf(PropTypes.shape({
        /** The name identifier of the column */
        columnName: PropTypes.string,

        /** The sorting direction reflected in the UI */
        direction: PropTypes.oneOf([
            'asc',
            'desc',
            null,
        ]),
    })),

    /** ID string used for testing */
    testID: PropTypes.string,

    overrideMessages: PropTypes.shape({
        noData: PropTypes.string,
    }),

    // Indicator for knowing if we are pulling more records.
    queryInProgress: PropTypes.bool,

    // Toggle for knowing if double click is enabled
    doubleClickForDefaultAction: PropTypes.bool,


    // Component class for the menu
    menuComponent: PropTypes.elementType,

    hiddenColumns: PropTypes.array,

    listActivityIndicator: PropTypes.object,

    onColumnVisibility: PropTypes.func,
};

DataTable.defaultProps = {
    cellComponent: DataTableCell,
    contextStyles: {},
    fixedLeftColumns: [],
    rowComponent: DataTableRow,
    selectedRowIndexes: [],
    sortDirectives: [],
    testID: 'DataTable',
    overrideMessages: {
        noData: 'No records found...',
    },
    doubleClickForDefaultAction: false,
};

export {
    DataTableCell,
    DataTableRow,
    SORT_DIRECTIONS,
};

export default DataTable;
