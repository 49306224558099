import React from 'react';
import PropTypes from 'prop-types';
import ChartWrapper from '../ChartWrapper/ChartWrapper';
import getStyles from './LineChart.styles';

/**
 * A line chart displays information as a series of data points called 'markers' connected by straight line segments.
 * @see https://echarts.apache.org/examples/en/editor.html?c=line-simple
 */
const LineChart = (props) => {
    const {
        animation,
        contextStyles,
        grouping,
        gridLines,
        horizontal,
        horizontalGridLines,
        legend,
        maxX,
        maxY,
        minX,
        minY,
        onPress,
        onSelectItem,
        renderButtonText,
        seriesData,
        stacked,
        testID,
        title,
        tooltip,
        verticalGridLines,
        xAxis,
        xAxisTitle,
        yAxis,
        yAxisTitle,
        zoom,
    } = props;

    const styles = getStyles(contextStyles);

    /** Build axis options */
    const axisOptions = {
        xAxis: {
            // Axis tick settings
            axisTick: {
                // Move the tick from between the points to the center of each point
                alignWithLabel: true,
            },

            // Axis tick labels
            data: [],

            // Show/hide axis lines
            show: xAxis,

            // Background grid lines
            splitLine: {
                // Show vertical grid lines
                show: gridLines && verticalGridLines,
            },

            // Show categories along axis ticks
            type: 'category',
        },
        yAxis: {
            // Show/hide axis lines
            show: yAxis,

            // Background grid lines
            splitLine: {
                // Show horizontal grid lines
                show: gridLines && horizontalGridLines,
            },

            // Show values along axis ticks
            type: 'value',
        },
    };

    // Set axis titles
    if (xAxisTitle) {
        axisOptions.xAxis.name = xAxisTitle;
        axisOptions.xAxis.nameLocation = 'center';
        axisOptions.xAxis.nameGap = 25;
        axisOptions.xAxis.textStyle = styles.xAxisTextStyle;
    }
    if (yAxisTitle) {
        axisOptions.yAxis.name = yAxisTitle;
        axisOptions.yAxis.nameLocation = 'center';
        axisOptions.yAxis.nameGap = 55;
        axisOptions.yAxis.textStyle = styles.yAxisTextStyle;
        axisOptions.yAxis.axisLine = {
            show: verticalGridLines,
        };
    }

    /** Build chart options */
    const chartOptions = {
        animation,
        backgroundColor: 'transparent',
        color: [ ...ChartWrapper.defaultColors ],
        grid: {
            borderWidth: 0,
            containLabel: true,
            show: true,
        },
        textStyle: styles.chartTextStyle,
        xAxis: axisOptions.xAxis,
        yAxis: axisOptions.yAxis,
    };

    // Set chart legend
    if (legend) {
        chartOptions.legend = {
            // Show the legend
            show: true,

            // Initialize legend series names
            data: [],
            padding: [
                5,
                10,
            ],
            symbolKeepAspect: true,

            // apply styles to legend
            textStyle: styles.legendTextStyle,

            // providing the scroll if legends doesnt fit with available width
            type: 'scroll',
            orient: horizontal ? 'vertical' : 'horizontal',
            ...styles.legendContainerStyle,
        };
    }

    // Set chart title
    if (title) {
        chartOptions.title = {
            text: title,
            textStyle: styles.titleTextStyle,
        };
    }

    // Enable chart tooltip
    if (tooltip) {
        chartOptions.tooltip = {
            axisPointer: {
                type: 'none',
            },
            confine: true,
            extraCssText: 'z-index: 10',
            padding: [ 10 ],
            textStyle: styles.tooltipTextStyle,
            trigger: 'axis',
            formatter: (param) => param.reduce(
                (accumulator, currentElement) => `${accumulator} ${currentElement.marker} ${currentElement.seriesName}: ${currentElement.data.displayValue} <br/>`,
                `${param[0].name} <br/>`
            ),
            ...styles.tooltipContainerStyle,
        };
    }

    // Enable chart zoom
    if (zoom) {
        chartOptions.dataZoom = {
            type: 'inside',
        };
    }

    // Set chart ranges
    if (maxX || maxX === 0) { chartOptions.xAxis.max = maxX; }
    if (minX || minX === 0) { chartOptions.xAxis.min = minX; }
    if (maxX || minX) { chartOptions.xAxis.scale = true; }
    if (maxY || maxY === 0) { chartOptions.yAxis.max = maxY; }
    if (minY || minY === 0) { chartOptions.yAxis.min = minY; }
    if (maxY || minY) { chartOptions.yAxis.scale = true; }

    // Generate chart series data
    chartOptions.series = seriesData.map((lineData, lineIdx) => {
        const {
            legendText,
            lineColor,
            lineOpacity,
            dataPoints,
            lineWidth,
            ...lineRest
        } = lineData;

        // Generate line series
        const lineSeries = {
            // Initialize line style
            lineStyle: {},

            // Show all symbols irrespective of available space
            showAllSymbol: true,

            // This is a line chart
            type: 'line',

            // Pass through any extra props
            ...lineRest,
        };

        // Add stack if stacked chart
        if (stacked) {
            lineSeries.stack = 'LineStack';
        }

        // Add legend information
        if (legend && legendText) {
            // Add the legend item to the legend array
            chartOptions.legend.data.push(legendText);

            // Set the series name to link to the legend item
            lineSeries.name = legendText;
        }

        // Add line details
        if (lineColor) {
            // Override default color spot for the legend and line symbol to reflect color
            chartOptions.color[lineIdx] = lineColor;
        }
        if (lineOpacity || lineOpacity === 0) {
            lineSeries.lineStyle.opacity = lineOpacity;
        }
        if (lineWidth || lineWidth === 0) {
            lineSeries.lineStyle.width = lineWidth;
        }

        // Generate line points data
        lineSeries.data = dataPoints.map((linePoint) => {
            const {
                axisLabel,
                displayValue,
                filter,
                symbolColor,
                symbolEmphasisColor,
                symbolEmphasisOpacity,
                symbolSize,
                value,
                ...linePointRest
            } = linePoint;

            // Add label to the x-axis label array
            if (lineIdx === 0) {
                chartOptions.xAxis.data.push({
                    value: axisLabel,
                    textStyle: styles.xAxisLabelTextStyle,
                });
            }

            // Generate minimum point data
            const pointData = {
                // Initialize point style
                itemStyle: {},

                // Point value
                value,

                axisLabel,

                // Pass through any extra props
                ...linePointRest,
            };

            // Add styles for point
            if (symbolColor) {
                pointData.itemStyle.borderColor = symbolColor;
            }

            // Adding default opacity to provide more contrast on Item
            const emphasisItemStyle = { opacity: 0.5 };
            if (symbolEmphasisOpacity || symbolEmphasisOpacity === 0) {
                emphasisItemStyle.opacity = symbolEmphasisOpacity;
            }
            if (symbolEmphasisColor) {
                emphasisItemStyle.color = symbolEmphasisColor;
            }
            pointData.itemStyle.emphasis = emphasisItemStyle;

            if (symbolSize || symbolSize === 0) {
                pointData.symbolSize = symbolSize;
                pointData.itemStyle.borderWidth = symbolSize;
            }
            else {
                // Default to a symbol size of 4
                pointData.symbolSize = 4;
                pointData.itemStyle.borderWidth = 4;
            }
            if (displayValue) {
                pointData.displayValue = displayValue;
            }
            return pointData;
        });

        // logic to update the serie data points based on grouping
        if (grouping) {
            let result = [];
            lineSeries.data.forEach((a) => {
                let found = false;
                result = result.map((datapoint) => {
                    const point = { ...datapoint };
                    if (point.name === a.axisLabel) {
                        found = true;
                        point.value += a.value;
                    }
                    return point;
                });
                if (!found) {
                    result.push( { name: a.axisLabel, value: a.value });
                }
            });
            lineSeries.data = result;
        }
        return lineSeries;
    });

    // Generate chart props
    const chartProps = {
        contextStyles: {
            container: styles.container,
        },
        option: chartOptions,
    };
    if (onPress) { chartProps.onPress = onPress; }
    if (onSelectItem) { chartProps.onSelectItem = onSelectItem; }
    if (renderButtonText) { chartProps.renderButtonText = renderButtonText; }
    if (testID) { chartProps.testID = testID; }

    return (
        <ChartWrapper { ...chartProps } />
    );
};

LineChart.propTypes = {
    /** Enables/disables chart animations */
    animation: PropTypes.bool,

    /** Styles for the component */
    contextStyles: PropTypes.shape({
        /** Styles for the chart text content */
        chartTextStyle: PropTypes.object,

        /** Styles for the container */
        container: PropTypes.object,

        /** Styles for the legend container */
        legendContainerStyle: PropTypes.object,

        /** Styles for the legend */
        legendTextStyle: PropTypes.object,

        /** Styles for the chart title */
        titleTextStyle: PropTypes.object,

        /** Styles for the tooltip container */
        tooltipContainerStyle: PropTypes.object,

        /** Styles for the tooltip */
        tooltipTextStyle: PropTypes.object,

        /** Styles for the xAxisLabels */
        xAxisLabelTextStyle: PropTypes.object,

        /** Styles for the xAxis */
        xAxisTextStyle: PropTypes.object,

        /** Styles for the yAxis */
        yAxisTextStyle: PropTypes.object,
    }),

    /** condition to group the datapoints */
    grouping: PropTypes.bool,

    /** Enable display of grid lines */
    gridLines: PropTypes.bool,

    /** Enables the orientation of Legend*/
    horizontal: PropTypes.bool,

    /** Enable display of horizontal grid lines */
    horizontalGridLines: PropTypes.bool,

    /** Enable display of a legend */
    legend: PropTypes.bool,

    /** Maximum X axis value */
    maxX: PropTypes.number,

    /** Maximum Y axis value */
    maxY: PropTypes.number,

    /** Minimum X axis value */
    minX: PropTypes.number,

    /** Minimum Y axis value */
    minY: PropTypes.number,

    /**
     * Triggered on press of a symbol
     * @param {Object} itemData - Data for the symbol pressed
     */
    onPress: PropTypes.func,

    /**
     * Triggered on select of a symbol
     * @param {Object} itemData - Data for the symbol selected
     */
    onSelectItem: PropTypes.func,

    /**
     * Used to manage the selected detail button text
     * @param {Object} dataPoint
     */
    renderButtonText: PropTypes.func,

    /** Series of line descriptions */
    seriesData: PropTypes.arrayOf(PropTypes.shape({
        /** Displays a legend item for the series */
        legendText: PropTypes.string,

        /** Color of the series line */
        lineColor: PropTypes.string,

        /** Opacity of the series line */
        lineOpacity: PropTypes.number,

        /** The data points to display for line chart */
        dataPoints: PropTypes.arrayOf(PropTypes.shape({
            /** The axis line text */
            axisLabel: PropTypes.string.isRequired,

            /** Optional formatted value for display */
            displayValue: PropTypes.string,

            /** Color of the symbol */
            symbolColor: PropTypes.string,

            /** Emphasis color of the symbol press/hover */
            symbolEmphasisColor: PropTypes.string,

            /** Size of the circular point */
            symbolSize: PropTypes.oneOfType([

                /** Size of the symbol in number */
                PropTypes.number,

                /** Array to represent width and height - ex: [ 10, 20 ] where width is 10 and height is 20 */
                PropTypes.arrayOf(PropTypes.number),
            ]),

            /** Value of the line point */
            value: PropTypes.number.isRequired,
        })).isRequired,

        /** Width of the series line */
        lineWidth: PropTypes.number,
    })).isRequired,

    /** Stack lines */
    stacked: PropTypes.bool,

    /** Id used for testing */
    testID: PropTypes.string,

    /** Title text for the chart */
    title: PropTypes.string,

    /** Show related data in a tooltip after pressing a bar */
    tooltip: PropTypes.bool,

    /** Enable display of vertical grid lines */
    verticalGridLines: PropTypes.bool,

    /** Show X axis line, ticks and labels */
    xAxis: PropTypes.bool,

    /** Name of xAxis */
    xAxisTitle: PropTypes.string,

    /** Show Y axis line, ticks and labels */
    yAxis: PropTypes.bool,

    /** Name of yAxis */
    yAxisTitle: PropTypes.string,

    /** Enable zooming in/out chart ranges */
    zoom: PropTypes.bool,
};

LineChart.defaultProps = {
    animation: false,
    contextStyles: {},
    gridLines: true,
    horizontalGridLines: true,
    tooltip: true,
    verticalGridLines: false,
    xAxis: true,
    xAxisTitle: 'X',
    yAxis: true,
    yAxisTitle: 'Y',
    zoom: true,
};

export default LineChart;
