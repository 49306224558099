import { ClientResponse } from './ClientResponse';
export class ReadableStreamClientResponse extends ClientResponse {
    constructor(value, statusCode) {
        super(value, statusCode);
        this.reader = value.getReader();
    }
    read() {
        return this.reader.read();
    }
}
