import { AttributeCellValue } from "./AttributeCellValue";
import { LabelCellValue } from "./LabelCellValue";
import { View } from './View';
const CONSTANT_PREFIX = '::CONSTANT::';
/**
 * A abstract definition for small visual area populated with labels and values. Labels are typically presented as simple text phrases.
 * Values are usually presented inside a UI component, such as a TextField or ComboBox.
 */
export class Details extends View {
    findCellValue(matcher) {
        return this.findCellValueForRow((row) => {
            let cellValue = null;
            row.some((cell) => {
                return cell.values.some((value) => {
                    cellValue = matcher(value);
                    return !!cellValue;
                });
            });
            return cellValue;
        });
    }
    /**
     * @param matcher - a function that given a row of cells, returns a matching CellValue (or null)
     */
    findCellValueForRow(matcher) {
        let cellValue = null;
        this.rows.some(row => {
            cellValue = matcher(row);
            return !!cellValue;
        });
        return cellValue;
    }
    getAttributeCellValue(propertyName) {
        return this.attributeCellsByPropName[propertyName] || {};
    }
    /**
     * 1-based index
     * @param number
     */
    getConstantByIndex(index) {
        return this.constants.length >= index ? this.constants[index - 1] : null;
    }
    getLabelForProperty(propertyName) {
        if (propertyName && propertyName.indexOf(CONSTANT_PREFIX) > -1) {
            return this.constantByName[propertyName];
        }
        return this.labelsByPropName[propertyName];
    }
    get constants() {
        if (!this.constantsArray) {
            this.initIndexes();
        }
        return this.constantsArray;
    }
    get propertyNames() {
        if (!this.propertyNamesArray) {
            this.initIndexes();
        }
        return this.propertyNamesArray;
    }
    get attributeCells() {
        return Object.keys(this.attributeCellsByPropName).map(key => this.attributeCellsByPropName[key]);
    }
    get attributeCellsByPropName() {
        if (!this.attributeCellMap) {
            this.initIndexes();
        }
        return this.attributeCellMap;
    }
    get labelsByPropName() {
        if (!this.propertyLabelMap) {
            this.initIndexes();
        }
        return this.propertyLabelMap;
    }
    get constantByName() {
        if (!this.constantsMap) {
            this.initIndexes();
        }
        return this.constantsMap;
    }
    get rowsData() {
        if (!this.rowsArray) {
            this.initIndexes();
        }
        return this.rowsArray;
    }
    getValidCells(cellValues = []) {
        return cellValues.filter((cellValue) => (cellValue instanceof LabelCellValue || cellValue instanceof AttributeCellValue));
    }
    initIndexes() {
        this.attributeCellMap = {};
        this.propertyLabelMap = {};
        this.constantsArray = [];
        this.propertyNamesArray = [];
        this.constantsMap = {};
        this.rowsArray = [];
        this.rows.forEach((row) => {
            if (row.length > 0) {
                const rowData = [];
                const labelCell = {};
                let i = 0;
                do {
                    const firstCell = row[i];
                    const secondCell = i + 1 < row.length ? row[i + 1] : undefined;
                    this.handleCellPair(firstCell, secondCell);
                    this.buildRowData(firstCell, secondCell, rowData, labelCell);
                    i = i + 2;
                } while (i < row.length);
                this.rowsArray.push(rowData);
            }
            row.forEach((cell) => {
                cell.values.forEach((cellValue) => {
                    if (cellValue instanceof AttributeCellValue) {
                        this.attributeCellMap[cellValue.propertyName] = cellValue;
                    }
                });
            });
        });
    }
    buildRowData(firstCell, secondCell, row, labelCell) {
        if (firstCell && firstCell.values && firstCell.values.length) {
            const [labelCellValue] = this.getValidCells(firstCell.values);
            if (labelCellValue instanceof LabelCellValue) {
                labelCell.label = labelCellValue;
                if (secondCell && secondCell.values && secondCell.values.length) {
                    const cellValues = secondCell.values;
                    cellValues.forEach((cell) => {
                        if (cell instanceof AttributeCellValue) {
                            row.push(cell.propertyName);
                            this.propertyLabelMap[cell.propertyName] = labelCellValue;
                        }
                        else if (cell instanceof LabelCellValue) {
                            if (!cell.value) {
                                row.push(`${CONSTANT_PREFIX}${labelCellValue.value}`);
                                this.constantsMap[`${CONSTANT_PREFIX}${labelCellValue.value}`] = labelCellValue;
                            }
                            else {
                                if (labelCellValue) {
                                    row.push(`${CONSTANT_PREFIX}${labelCellValue.value}`);
                                    this.constantsMap[`${CONSTANT_PREFIX}${labelCellValue.value}`] = labelCellValue;
                                }
                                row.push(`${CONSTANT_PREFIX}${cell.value}`);
                                this.constantsMap[`${CONSTANT_PREFIX}${cell.value}`] = cell;
                            }
                        }
                    });
                }
                else {
                    row.push(`${CONSTANT_PREFIX}${labelCellValue.value}`);
                    this.constantsMap[`${CONSTANT_PREFIX}${labelCellValue.value}`] = labelCellValue;
                }
            }
            else if (labelCellValue instanceof AttributeCellValue) {
                row.push(labelCellValue.propertyName);
                this.propertyLabelMap[labelCellValue.propertyName] = labelCell.label;
                // if the first cell has an attribute cell value, we need to check if the second cell has an attribute cell
                if (secondCell && secondCell.values && secondCell.values.length) {
                    const cellValues = secondCell.values;
                    cellValues.forEach((cell) => {
                        if (cell instanceof AttributeCellValue) {
                            row.push(cell.propertyName);
                            this.propertyLabelMap[cell.propertyName] = labelCell.label;
                        }
                        else {
                            row.push(`${CONSTANT_PREFIX}${cell.value}`);
                            this.constantsMap[`${CONSTANT_PREFIX}${cell.value}`] = cell;
                        }
                    });
                }
            }
        }
        else if (secondCell && secondCell.values && secondCell.values.length) {
            const cellValues = secondCell.values;
            cellValues.forEach((cell) => {
                if (cell instanceof AttributeCellValue) {
                    row.push(cell.propertyName);
                }
                else {
                    row.push(`${CONSTANT_PREFIX}${cell.value}`);
                    this.constantsMap[`${CONSTANT_PREFIX}${cell.value}`] = cell;
                }
            });
        }
    }
    //Detect Label/AttributeValue Pairs and constants (single labels)
    handleCellPair(firstCell, secondCell) {
        if (firstCell && firstCell.values) {
            const [labelCellValue] = firstCell.values;
            if (labelCellValue instanceof LabelCellValue) {
                if (secondCell && secondCell.values) {
                    const [cellValue] = secondCell.values;
                    if (cellValue instanceof AttributeCellValue) {
                        this.propertyNamesArray.push(cellValue.propertyName);
                        this.propertyLabelMap[cellValue.propertyName] = labelCellValue;
                    }
                    else if (cellValue instanceof LabelCellValue) {
                        if (!cellValue.value) {
                            this.constantsArray.push(labelCellValue.value);
                        }
                    }
                }
                else {
                    this.constantsArray.push(labelCellValue.value);
                }
            }
            else if (labelCellValue instanceof AttributeCellValue) {
                this.propertyNamesArray.push(labelCellValue.propertyName);
            }
        }
    }
}
