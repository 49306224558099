/*       */

import AbstractGml from './AbstractGml';
import Base from './Base';
import { GENERAL } from '../exportClassConstants';
import StyleSet from '../StyleSet';
import SaltBoxModel from '../SaltBoxModel';
import GmlUtil from '../GmlUtil';

export default class Div extends AbstractGml {
    asSalt(parentContext, assertExpr = '') {
        const myContext = this.updateContext(parentContext);
        const styleSet = new StyleSet();
        this.exportStyleTo(styleSet, myContext, [ GENERAL ]);
        this.applyDefaultFlex(myContext, styleSet);
        this.applyEquallySized(myContext, styleSet);
        this.applyFlexForFiller(myContext, styleSet);
        const scrollA = this.getScrollAttribute(myContext);
        const rawChildren = Base.children(myContext).map(c => c.asSalt(myContext));
        const children = scrollA ? rawChildren : GmlUtil.asRowChildren(myContext, styleSet, rawChildren).children;
        this.addBackgroundImage(myContext, children);

        const sbm = new SaltBoxModel(styleSet, children, this.asAssertAttribute(this.asQualifierExprString(assertExpr)));

        // Wrap in a containing box or action if there are special needs.
        return this.wrapForSpecialCases(myContext, parentContext, sbm);
    }
}
