import { DialogProxyTools } from "./DialogProxyTools";
import { PropertyVisitor } from "./PropertyVisitor";
/**
 *
 */
export class RecordVisitor {
    constructor(value) {
        if (!value) {
            throw new Error('RecordVisitor -- null value exception');
        }
        if (typeof value === 'string') {
            this._enclosedJsonObject = JSON.parse(value);
        }
        else {
            this._enclosedJsonObject = value;
        }
        if (!DialogProxyTools.isRecordModel(this._enclosedJsonObject)) {
            throw new Error("Object passed to RecordVisitor is not a Record");
        }
        if (!this._enclosedJsonObject.properties) {
            throw new Error('Invalid record -- missing properties field');
        }
        if (!Array.isArray(this._enclosedJsonObject.properties)) {
            throw new Error('Invalid record set -- properties field is not an array');
        }
    }
    // --- State Management Helpers --- //
    static visitPropertyValueAt(record, propertyName) {
        return (new RecordVisitor(record)).visitPropertyValueAt(propertyName);
    }
    static visitAndSetPropertyValueAt(record, propertyName, value) {
        (new RecordVisitor(record)).visitAndSetPropertyValueAt(propertyName, value);
    }
    // --- State Import/Export --- //
    copyAsJsonObject() {
        return JSON.parse(this.copyAsJsonString());
    }
    copyAsJsonString() {
        return JSON.stringify(this.enclosedJsonObject());
    }
    enclosedJsonObject() {
        return this._enclosedJsonObject;
    }
    // --- State Management --- //
    visitPropertyValueAt(propertyName) {
        for (const p of this.enclosedJsonObject().properties) {
            if (p.name === propertyName) {
                return p.value;
            }
        }
        return undefined;
    }
    visitAndSetPropertyValueAt(propertyName, value) {
        let found = false;
        for (const p of this.enclosedJsonObject().properties) {
            if (p.name === propertyName) {
                p.value = value;
                found = true;
                break;
            }
        }
        if (!found) {
            this.enclosedJsonObject().properties.push({
                "name": propertyName,
                "format": null,
                "annotations": [],
                "type": "hxgn.api.dialog.Property",
                "value": value
            });
        }
    }
    *visitProperties() {
        let index = 0;
        while (index < this.enclosedJsonObject().properties.length) {
            yield new PropertyVisitor(this.enclosedJsonObject().properties[index++]);
        }
    }
    visitRecordId() {
        return this.enclosedJsonObject().id;
    }
    // newly added to stored rootdialogid for referring to it parent dailog for updating the record in recordset
    visitAndSetParentDialogId(rootDialogId) {
        this.enclosedJsonObject()['rootDialogId'] = rootDialogId;
    }
    // newly added to stored rootdialogid for referring to it parent dailog for updating the record in recordset
    visitAndSetRootParentDialogId(rootParentDialogId) {
        this.enclosedJsonObject()['rootParentDialogId'] = rootParentDialogId;
    }
}
