export default class MenuSpec {
    constructor(id, dialogId, icon, menuText, multiSelectAvailable, selectionRequired, isSeparator, containsLineSaperator = true) {
        this.id = id;
        this.dialogId = dialogId;
        this.icon = icon;
        this.menuText = menuText;
        this.multiSelectAvailable = multiSelectAvailable;
        this.selectionRequired = selectionRequired;
        this.isSeparator = isSeparator;
        this.containsLineSeparator = containsLineSaperator;
    }
    static isSeparator = menu => {
        return menu instanceof MenuSpec && menu.isSeparator;
    }
    static newSeparator = (separatorText, containsLineSeparator = true) => {
        return new MenuSpec(null, null, null, separatorText, false, false, true, containsLineSeparator);
    }
}
