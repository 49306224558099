/*       */

import { GENERAL } from '../../exportClassConstants';
import MutedLayoutAttribute from '../MutedLayoutAttribute';

const VALUES = {
    TRUE: 'true',
    FALSE: 'false',
};

/**
 * Strict Mode contains a true/false value.  If true, reporting occurs, if false, no reporting occurs.
 * If true, an additional list of elements/attributes may be listed to be ignored.
 * A value of "true, newAttribute" will report everything except newAttribute being reported as an
 * unknown attribute.
 */
export default class StrictMode extends MutedLayoutAttribute {
    constructor(value) {
        super('strictMode', value, [ GENERAL ], true);
    }

    shouldReport = () => {
        return this.value === VALUES.TRUE;
    }
}
