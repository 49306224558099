/*       */

import AbstractGml from './AbstractGml';
import Base from './Base';
import { GENERAL, GENERAL_FLEX, LIST, VIEW_LIST } from '../exportClassConstants';
import StyleSet from '../StyleSet';

export default class List extends AbstractGml {
    asSalt(parentContext) {
        const myContext = this.updateContext(parentContext);
        myContext.setIsWithinList();
        const children = this.buildHeaderBodyFooterChildren(myContext, parentContext);

        // This is the View Salt component.  It's children is the [ header, body, footer ] children array.
        const viewStyleSet = new StyleSet();
        this.exportStyleTo(viewStyleSet, myContext, [ GENERAL, VIEW_LIST ]);
        // Regardless of what was requested, this view must be column based to provide a header body and footer.
        viewStyleSet.flexPolicy.setFlexColumn();
        viewStyleSet.style.overflow = 'hidden';
        const userAlignItems = viewStyleSet.flexPolicy.alignItems;
        // If there is no parent or this is V1, grow big.
        const hasParent = !!parentContext.parentContext;
        if (!hasParent || parentContext.version.isV1()) {
            viewStyleSet.flexPolicy.setStandardGrow();
            viewStyleSet.flexPolicy.setStandardShrink();
            if (!userAlignItems) {
                viewStyleSet.flexPolicy.setAlignItemsStretch();
            }
        }

        return {
            view: {
                id: parentContext.document.viewId,
                ...viewStyleSet.asStyleAttribute(),
                ...viewStyleSet.asXStyleAttribute(),
                children,
            },
        };
    }

    buildHeaderBodyFooterChildren(myContext, parentContext) {
        const { version } = parentContext;
        // Collect the nodata, headers and footers.
        const nodataChildren = Base.children(myContext).filter(f => Base.isNoData(f.json)).map(c => c.asSalt(myContext));
        const headerChildren = Base.children(myContext).filter(f => Base.isHeader(f.json, version)).map(c => c.asSalt(myContext));
        const footerChildren = Base.children(myContext).filter(f => Base.isFooter(f.json, version)).map(c => c.asSalt(myContext));

        // Collect the regular children.  Exclude header and footers, although do not exclude them
        // if this is post V1 as headers and footers are ignored after V1.
        const regChildren = Base.children(myContext).filter(f => {
            return !Base.isNoData(f.json) && !Base.isHeader(f.json, version) && !Base.isFooter(f.json, version);
        }).map(c => c.asSalt(myContext));

        const children = [];
        // **** Header Content ****
        if (headerChildren.length) {
            children.push({
                box: {
                    style: { flexDirection: 'column' },
                    children: Base.flattenChildren(headerChildren),
                },
            });
        }
        // **** Main Content ****
        const regStyleSet = new StyleSet();
        this.exportStyleTo(regStyleSet, myContext, [ LIST, GENERAL_FLEX ]);
        regStyleSet.style.overflow = 'hidden';
        regStyleSet.flexPolicy.setStandardGrow();
        regStyleSet.flexPolicy.setStandardShrink();
        regStyleSet.flexPolicy.setAlignItemsStretch();

        const listChild = {
            list: {
                ...regStyleSet.asStyleAttribute(),
                ...regStyleSet.asXStyleAttribute(),
                children: Base.flattenChildren(regChildren),
            },
        };
        if (nodataChildren.length) {
            // ***** No data conntext *****
            const nodataStyleSet = new StyleSet();
            nodataStyleSet.style.overflow = 'hidden';
            nodataStyleSet.flexPolicy.setStandardGrow();
            nodataStyleSet.flexPolicy.setStandardShrink();
            nodataStyleSet.flexPolicy.setAlignItemsStretch();

            const nodataChild = {
                box: {
                    ...nodataStyleSet.asStyleAttribute(),
                    children: nodataChildren,
                },
            };
            // If there are nodata children, then display either the list or the nodata content.
            children.push({
                when: {
                    assert: { expr: '$dialog.scroller.isCompleteAndEmpty' },
                    children: [ nodataChild ],
                    'else-children': [ listChild ],
                },
            });
        } else {
            children.push(listChild);
        }

        // **** Footer Content ****
        if (footerChildren.length) {
            children.push({
                box: {
                    style: { flexDirection: 'column' },
                    children: Base.flattenChildren(footerChildren),
                },
            });
        }
        return children;
    }

    // eslint-disable-next-line no-unused-vars
    // findFillers = (parentContext:Context): FillerSearch => {
    //     // On a list expand must be explicit.  Is on V1.
    //     return new FillerSearch();
    // }
}
