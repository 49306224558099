import React, { Fragment, Component } from 'react';
import * as PropTypes from 'prop-types';
import { TypeNames } from 'cv-dialog-sdk';
import { engineConstants, rootStore } from 'cv-react-core';


import ActionButton from '../ActionButton/ActionButton';
import ActionButtonImage from '../ActionButtonImage/ActionButtonImage';
import TapActionToolSalt from '../TapActionTool/TapActionToolSalt';

import getStyles from './QuickActions.styles';

const propDefinition = {
    actions: PropTypes.array,
    onMenuAction: PropTypes.func,
    isExpanded: PropTypes.bool,
    menu: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        icon: PropTypes.string,
        menuText: PropTypes.string,
    })),
    xMenu: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        icon: PropTypes.string,
        menuText: PropTypes.string,
    })),
    viewType: PropTypes.string,
    showFormTools: PropTypes.bool,
};

class QuickActions extends Component {
    static propTypes = propDefinition;

    static defaultProps = {
        actions: [],
        onMenuAction: () => Promise.resolve(),
    };

    render() {
        const {
            actions,
            onMenuAction,
        } = this.props;

        return (
            <Fragment>
                { actions.map((action) => {
                    if (typeof action.id === 'undefined') { return null; }
                    return (
                        <ActionButtonImage
                            key={ `toolKey${action.id}` }
                            imageSource={ action.icon }
                            tooltip={ action.menuText }
                            onAction={ (...args) => onMenuAction(...args) }
                            id={ action.id } />
                    );
                }) }
                { this.renderExtenderTools() }
                { this.renderToggleExpand() }
                { this.renderDenseView() }
            </Fragment>
        );
    }

    renderDenseView = () => {
        const {
            viewType,
            xMenu,
            onMenuAction,
        } = this.props;
        const {
            themeStore,
        } = rootStore;
        const theme = themeStore.getSanitizedTheme();
        if (viewType === TypeNames.ListTypeName) {
            // This has to be displayed in all list views
            const tool = xMenu.find((f) => (f.id === engineConstants.action.clientActions.listDensity));
            if (tool) {
                const styles = getStyles();
                return (
                    <ActionButton
                        icon="view_compact"
                        tooltip={ theme.spaceMode === 'compact' ? 'move to relaxed' : 'move to compact' }
                        key={ `toolKey${tool.id}` }
                        onAction={ (...args) => onMenuAction(...args) }
                        id={ tool.id }
                        contextStyles={ {
                            iconContainer: styles.listToggler,
                        } } />
                );
            }
        }
        return null;
    }

    renderExtenderTools = () => {
        const {
            viewType,
            menu,
            xMenu,
        } = this.props;
        const {
            themeStore,
        } = rootStore;
        const theme = themeStore.getSanitizedTheme();
        // Extended Tools specific to view types
        switch (viewType) {
            case TypeNames.ListTypeName: {
                const tool = xMenu.find((f) => (f.id === engineConstants.action.clientActions.changeDefaultAction));

                if (tool) {
                    const tapMenu = menu.filter((m) => (m.selectionRequired));

                    if (tapMenu.length > 1) {
                        const tapActionLabel = tool.currentTapActionLabel
                            || ((tool.currentTapActionId !== engineConstants.action.clientActions.select) && tool.defaultTapActionLabel)
                            || '(select)';
                        return (
                            <TapActionToolSalt
                                { ...tool }
                                menu={ tapMenu }
                                icon="arrow_drop_down"
                                tooltip="Define what tapping a list item does"
                                iconStyles={ {
                                    color: theme.actionIconColor,
                                    fontSize: theme.actionIconSize,
                                } }
                                label={ `Tap: ${tapActionLabel}` } />
                        );
                    }
                }
            }
            break;
            case TypeNames.DetailsTypeName:
            // More stuff here (added to prevent lint error)
            break;
            default:
            // Default here
            break;
        }
        return null;
    }

    renderToggleExpand = () => {
        const {
            showFormTools,
            xMenu,
            isExpanded,
            onMenuAction,
        } = this.props;
        if (showFormTools) {
            const tool = xMenu.find((f) => (f.id === engineConstants.action.clientActions.toggleExpand));
            if (tool) {
                const styles = getStyles();
                return (
                    <ActionButton
                        icon={ isExpanded ? 'unfold_less' : 'aspect_ratio' }
                        tooltip={ isExpanded ? 'Return to multi-pane view' : 'Expand to single-pane view' }
                        key={ `toolKey${tool.id}` }
                        onAction={ (...args) => onMenuAction(...args) }
                        id={ tool.id }
                        contextStyles={ {
                            iconContainer: styles.expandContainer,
                        } } />
                );
            }
        }
        return null;
    };
}

export default QuickActions;
