import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Box from './Box';

import RefUtil from './ref/RefUtil';
import SaltComponent from './SaltComponent';
import SaltContext from './SaltContext';
import ScopeManager from './ScopeManager';

@observer
export default class DetailsIterator extends SaltComponent {
    static propTypes = {
        children: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.arrayOf(PropTypes.element),
        ]),
        viewId: PropTypes.string,
    };

    render() {
        const { scopeManager, dialogStore } = this.contextParams;
        const record = dialogStore.record || scopeManager.getRef(ScopeManager.SCOPED_RECORD_REF_NAME);
        if (record) {
            const { dialog: { view } } = dialogStore;
            const { rowsData: rows } = view;
            if (rows && rows.length) {
                return rows.map((row, index) => {
                    return (
                        <Box
                            // eslint-disable-next-line react/no-array-index-key
                            key={ index }
                            style={ { flexDirection: 'row' } }>
                            { this.renderRow(row, index) }
                        </Box>
                    );
                });
            }
            return null;
        }
        return null;
    }

    get contextParams() {
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore, scopeManager } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        return { saltStore, dialogStore, scopeManager, viewId };
    }

    renderRow(row, index) {
        const { saltStore, scopeManager, dialogStore, viewId } = this.contextParams;
        const record = dialogStore.record || scopeManager.getRef(ScopeManager.SCOPED_RECORD_REF_NAME);
        const { children, expr, elseChildren } = this.props;
        return row.map((column, cIndex) => {
            const newContext = {
                ...this.context,
                scopeManager:
                    scopeManager.newScopeWithLocal(ScopeManager.SCOPED_PROPERTY_REF_NAME, record.propAtName(column) || { name: column })
                        .newScopeWithLocal(ScopeManager.SCOPED_INDEX_REF_NAME, index),
            };
            let content = children;
            if (expr) {
                content = RefUtil.evaluateExpression(expr, dialogStore, newContext.scopeManager, saltStore) ? children : elseChildren;
            }
            return (
                <SaltContext.Provider
                    // eslint-disable-next-line react/no-array-index-key
                    key={ `${column}-${viewId}-${cIndex}` }
                    value={ newContext }>
                    { content }
                </SaltContext.Provider>
            );
        });
    }
}

DetailsIterator.contextType = SaltContext;
