import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Catavolt } from 'cv-dialog-sdk';
import componentFactory from './componentFactory';
import SaltContext from './SaltContext';
import Layout from './xStyle/Layout';
import SaltComponent from './SaltComponent';
import { pageController } from '../controllers';
import { uiHelper, utilities } from '../utilities';
import rootStore from '../stores/rootStore';
import serviceFactory from '../services/serviceFactory';

const {
    annotationHelper,
    calendarHelper,
} = utilities;

@observer
export default class Calendar extends SaltComponent {
    static propTypes = {
        id: PropTypes.string,
        children: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.arrayOf(PropTypes.element),
        ]),
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xStyle: PropTypes.oneOfType([
            Layout.layoutPropType,
            PropTypes.arrayOf(Layout.layoutPropType),
        ]),
    };

    get contextParams() {
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore, onTransition, onError } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        const { uiStore } = saltStore;
        return { saltStore, onTransition, onError, dialogStore, uiStore };
    }

    refreshDialog = () => {
        const { dialogStore, uiStore } = this.contextParams;
        const { sessionStore } = rootStore;
        const { parentDialogStore } = dialogStore;
        const { dialog } = parentDialogStore;
        sessionStore.openOrRefreshDialogStore(dialog.id).then(() => {
            pageController.clearGloballyBusy(dialog.id, uiStore);
        });
    }

    handleEditRedirection = (redirection) => {
        const { dialogStore, uiStore, onError } = this.contextParams;
        const { parentDialogStore } = dialogStore;
        const { dialog: parentDialog } = parentDialogStore;
        const { sessionStore } = rootStore;
        const { dialogId } = redirection;
        const { dialog } = dialogStore;
        const { view } = dialog;
        const updatedRecord = calendarHelper.getUpdatedRecord(dialogStore);
        const viewType = calendarHelper.getView(dialogStore, uiStore);
        const {
            startDatePropertyName,
            startTimePropertyName,
            endDatePropertyName,
            endTimePropertyName,
            occurDatePropertyName,
            occurTimePropertyName,
        } = view;
        return sessionStore.openOrRefreshDialogStore(dialogId)
            .then((store) => {
                const detailsDialogStore = store.childDialogStores[0];
                const title = serviceFactory.lang.dialog.errors.errorReadingRecordTitle;
                pageController.setGloballyBusy(parentDialog.id, uiStore, { loadingText: serviceFactory.lang.loadingStatus.processingRequest });
                pageController.performReadRecord({ dialogStore: detailsDialogStore, title, onError }).then(() => {
                    const record = Object.entries(updatedRecord)[0][1];
                    calendarHelper.setDialogPropValue(detailsDialogStore, startDatePropertyName, record.startDate);
                    calendarHelper.setDialogPropValue(detailsDialogStore, occurDatePropertyName, record.startDate);
                    if (viewType === 'Day') {
                        calendarHelper.setDialogPropValue(detailsDialogStore, endDatePropertyName, record.startDate);
                        calendarHelper.setDialogPropValue(detailsDialogStore, startTimePropertyName, record.startDate);
                        calendarHelper.setDialogPropValue(detailsDialogStore, endTimePropertyName, record.endDate);
                        calendarHelper.setDialogPropValue(detailsDialogStore, occurTimePropertyName, record.startDate);
                    } else if (viewType === 'Week') {
                        calendarHelper.setDialogPropValue(detailsDialogStore, endDatePropertyName, record.startDate);
                    } else if (viewType === 'Month') {
                        calendarHelper.setDialogPropValue(detailsDialogStore, endDatePropertyName, record.endDate - 1);
                    }
                    pageController.performWrite({ dialogStore: detailsDialogStore, uiStore, onTransition: this.refreshDialog, onError });
                });
            });
    }

    handleAppointmentUpdate = ({ changed }) => {
        const { dialogStore, uiStore } = this.contextParams;
        calendarHelper.setUpdatedRecord(dialogStore, changed);
        // Show the prompt to confirm or cancel the drag-drop
        calendarHelper.setConfirmation(dialogStore, uiStore, true);
    }

    handleConfirm = () => {
        const { dialogStore, uiStore, onError } = this.contextParams;
        const record = calendarHelper.getUpdatedRecord(dialogStore);
        const recordId = Object.entries(record)[0][0];
        calendarHelper.setConfirmation(dialogStore, uiStore, false);
        pageController.performActionWithConfirmation({ actionId: 'edit', selectedArray: [ recordId ], dialogStore, uiStore, onTransition: this.handleEditRedirection, onError });
    }

    handleCancel = () => {
        const { dialogStore, uiStore } = this.contextParams;
        calendarHelper.setUpdatedRecord(dialogStore, null);
        calendarHelper.setConfirmation(dialogStore, uiStore, false);
    }

    handleDateChange = (currentDate, offset = false) => {
        const { dialogStore, uiStore } = this.contextParams;
        const prevDate = new Date(calendarHelper.getDate(dialogStore, uiStore) || new Date());
        // Prevent re-renders
        if (!offset) {
            // Strip hours from the date
            currentDate.setHours(0, 0, 0, 0);
            // Saves the current selected date from date navigator
            // This is used to save the location when clicking to/from the appointment
            calendarHelper.setDate(dialogStore, uiStore, currentDate);
            this.setDateRange(dialogStore, currentDate);
            calendarHelper.refreshCalendar(dialogStore);
            return;
        }
        if (currentDate.toISOString() !== prevDate.toISOString()) {
            // Offset timezone
            const offsetDate = new Date(currentDate.getTime() + (currentDate.getTimezoneOffset() * 60000));
            // Strip hours from the date
            offsetDate.setHours(0, 0, 0, 0);
            // Saves the current selected date from date navigator
            // This is used to save the location when clicking to/from the appointment
            calendarHelper.setDate(dialogStore, uiStore, offsetDate);
            this.setDateRange(dialogStore, offsetDate);
            calendarHelper.refreshCalendar(dialogStore);
        }
    }

    handleViewChange = (viewName) => {
        const { dialogStore, uiStore } = this.contextParams;
        // Saves [Month/Week/Day] enum value
        calendarHelper.setView(dialogStore, uiStore, viewName);
    }

    handlePickerChange = (date) => {
        const { dialogStore, uiStore } = this.contextParams;
        // Strip hours from the date
        date.setHours(0, 0, 0, 0);
        calendarHelper.setPickedDate(dialogStore, uiStore, date);
    }

    navigateDate = (direction) => {
        const { dialogStore, uiStore } = this.contextParams;
        const directions = {
            previous: -1,
            next: 1,
        };
        const date = new Date(calendarHelper.getDate(dialogStore, uiStore) || new Date());
        const view = calendarHelper.getView(dialogStore, uiStore);
        const dirOffset = directions[direction];
        if (view === 'Month') {
            date.setMonth(date.getMonth() + dirOffset);
        } else if (view === 'Week') {
            date.setDate(date.getDate() + (dirOffset * 6));
        } else if (view === 'Day') {
            date.setDate(date.getDate() + dirOffset);
        }
        this.setDateRange(dialogStore, date);
        calendarHelper.setDate(dialogStore, uiStore, new Date(date));
        calendarHelper.refreshCalendar(dialogStore);
    }

    handlePrevious = () => {
        this.navigateDate('previous');
    }

    handleNext = () => {
        this.navigateDate('next');
    }

    handleMonthCellClick = (event) => {
        const { dialogStore, uiStore } = this.contextParams;
        const currentDate = event.currentTarget.getAttribute('date');
        const defaultDate = calendarHelper.getDate(dialogStore, uiStore);
        const anchorDate = new Date(defaultDate || (new Date()));
        // First and last days of the month
        const firstDay = new Date(anchorDate.getFullYear(), anchorDate.getMonth(), 1);
        const lastDay = new Date(anchorDate.getFullYear(), anchorDate.getMonth() + 1, 0);
        const activeDate = new Date(currentDate);
        // Click works only for the active month
        if (activeDate >= firstDay && activeDate <= lastDay) {
            calendarHelper.setDate(dialogStore, uiStore, currentDate);
            calendarHelper.setPickedDate(dialogStore, uiStore, currentDate);
        }
    }

    handleMonthCellDoubleClick = (event) => {
        const { dialogStore, uiStore } = this.contextParams;
        const currentDate = event.currentTarget.getAttribute('date');
        const defaultDate = calendarHelper.getDate(dialogStore, uiStore);
        const anchorDate = new Date(defaultDate || (new Date()));
        // First and last days of the month
        const firstDay = new Date(anchorDate.getFullYear(), anchorDate.getMonth(), 1);
        const lastDay = new Date(anchorDate.getFullYear(), anchorDate.getMonth() + 1, 0);
        const activeDate = new Date(currentDate);
        // Double click works only for the active month
        if (activeDate >= firstDay && activeDate <= lastDay) {
            calendarHelper.setDate(dialogStore, uiStore, currentDate);
            calendarHelper.setView(dialogStore, uiStore, 'Day');
        }
    }

    handleEventDoubleClick = (event) => {
        const { uiStore, dialogStore, onTransition, onError } = this.contextParams;
        const { dialog } = dialogStore;
        const { view } = dialog;
        const { defaultActionId: actionId } = view;

        const objectId = event.currentTarget.getAttribute('objectid');

        // If not in selection mode, then un-select all before selecting the long press record.
        if (!pageController.getSelectionMode(dialogStore, uiStore)) {
            utilities.listHelper.clearSelectedRecords(uiStore, dialogStore);
        }

        pageController.performActionWithConfirmation({ actionId, selectedArray: [ objectId ], dialogStore, uiStore, onTransition, onError });
    }

    handleNativeEventClick = (event) => {
        const { uiStore, dialogStore, onTransition, onError } = this.contextParams;
        const { dialog } = dialogStore;
        const { view } = dialog;
        const { defaultActionId: actionId } = view;

        const { objectId } = event.data;

        pageController.performActionWithConfirmation({ actionId, selectedArray: [ objectId ], dialogStore, uiStore, onTransition, onError });
    }

    handleEventClick = (event) => {
        // Setting click and double click events on the same element causes unexpected behaviour
        // Setting a standard 500 ms delay timer resolves this issue
        // TODO: this is a hack so that double click actually triggers and not just two single clicks
        setTimeout(() => {
            const { uiStore, dialogStore } = this.contextParams;
            // If not in selection mode, then un-select all before selecting the long press record.

            if (!pageController.getSelectionMode(dialogStore, uiStore)) {
                utilities.listHelper.clearSelectedRecords(uiStore, dialogStore);
            }
            const {
                data: {
                    objectId,
                },
            } = event;
            utilities.listHelper.selectRecord(uiStore, dialogStore, objectId, utilities.listHelper.single);
        }, 500);
    }

    handleMenuAction = (menu) => {
        const {
            dialogStore,
            uiStore,
            onTransition,
            onError,
        } = this.contextParams;
        return pageController.performActionWithConfirmation({
            actionId: menu.id,
            selectedArray: utilities.listHelper.getSelectedAsArray(uiStore, dialogStore),
            dialogStore,
            uiStore,
            onTransition,
            onError,
        });
    }

    handleOrientationChange = (orientation) => {
        const { uiStore, dialogStore } = this.contextParams;
        calendarHelper.setOrientation(dialogStore, uiStore, orientation);
    }

    getSelectedItems = () => {
        const {
            dialogStore,
            uiStore,
        } = this.contextParams;
        return utilities.listHelper.getSelectedAsArray(uiStore, dialogStore);
    }

    getCalendarEvents = (records, view) => {
        const appointmentCounter = {};
        const allDayCounter = {};
        const appointments = records.map((record) => {
            // Get record properties
            const {
                properties,
                id,
            } = record;

            const {
                descriptionPropertyName,
                startDatePropertyName,
                startTimePropertyName,
                endDatePropertyName,
                endTimePropertyName,
                occurDatePropertyName,
                occurTimePropertyName,
            } = view;

            let baseStyles = {};

            // Initialize event property:value container
            const event = {};

            event.objectId = id;

            // To identify appointment in drag and drop event
            event.id = id;

            baseStyles = annotationHelper.getBackgroundAsStyle(record, null, baseStyles);
            baseStyles = annotationHelper.getAsStyle(record, null, baseStyles);

            event.style = baseStyles;

            let hasOccurDate = false;
            let hasOccurTime = false;

            properties.forEach((property) => {
                if (property.name === descriptionPropertyName) {
                    event.title = property.value;
                } else if (property.name === startDatePropertyName) {
                    event.startDate = property.value;
                } else if (property.name === endDatePropertyName) {
                    event.endDate = property.value;
                } else if (property.name === startTimePropertyName) {
                    event.startTime = property.value;
                } else if (property.name === endTimePropertyName) {
                    event.endTime = property.value;
                } else if (property.name === occurDatePropertyName) {
                    event.startDate = property.value;
                    hasOccurDate = true;
                } else if (property.name === occurTimePropertyName) {
                    event.startTime = property.value;
                    hasOccurTime = true;
                }
            });

            // When enddate doesn't exist, month view doesn't consider it
            // Adds a second to the end date to make it a valid event in all views
            if (event.startDate && !event.endDate) {
                event.endDate = new Date(event.startDate.getTime());
                event.endDate.setSeconds(event.startDate.getSeconds() + 1);
                if (event.startTime && !event.endTime) {
                    event.endTime = event.startTime;
                    event.endTime.seconds = event.startTime.seconds + 1;
                } else if (!event.startTime && event.endTime) {
                    event.startTime = event.endTime;
                    event.startTime.seconds = event.endTime.seconds - 1;
                }
            }

            // If the events spans over a day or more, set allDay to true
            if (calendarHelper.daysBetween(event.startDate, event.endDate) >= 1 || (hasOccurDate && !hasOccurTime && !event.startTime && !event.endTime)) {
                event.allDay = true;
            }

            if (event.startDate) {
                // Adds an increasing counter value to events from the same startDate
                // Clients can use this to show only a few events in month preview
                let loop = new Date(event.startDate.setHours(0, 0, 0, 0));
                while (loop <= event.endDate.setHours(0, 0, 0, 0)) {
                    if (appointmentCounter[loop]) {
                        appointmentCounter[loop] += 1;
                    } else {
                        appointmentCounter[loop] = 1;
                    }
                    if (event.allDay) {
                        if (allDayCounter[loop]) {
                            allDayCounter[loop] += 1;
                        } else {
                            allDayCounter[loop] = 1;
                        }
                    }
                    if (!event.counter) {
                        event.counter = {};
                    }
                    event.counter[loop] = appointmentCounter[loop];
                    const newDate = loop.setDate(loop.getDate() + 1);
                    loop = new Date(newDate);
                }
                if (event.startTime) {
                    const {
                        startTime: {
                            hours,
                            minutes,
                        },
                    } = event;
                    event.startDate.setHours(hours, minutes);
                    const today = new Date();
                    today.setHours(hours, minutes);
                    const timeString = uiHelper.formatPropertyForRead({ value: today }, { isTimeType: true });
                    event.timeString = timeString;
                    event.titleSummary = event.title.slice(0);
                    event.title = `${timeString} ${event.title}`;
                }
            }
            if (event.endDate) {
                if (event.endTime) {
                    const {
                        endTime: {
                            hours,
                            minutes,
                        },
                    } = event;
                    event.endDate.setHours(hours, minutes);
                }
                // When the endDate is set to the start of the day
                // Calendar in Month view does not consider it
                // Adds a second to extend the event to the end day
                if (event.endDate.getHours() === 0 && event.endDate.getMinutes() === 0 && event.endDate.getSeconds() === 0) {
                    event.endDate.setSeconds(1);
                }
            }
            // This means that startdate and enddate are equal
            // Calendar in month view doesn't consider this event
            // Adding a single second to the end time to make sure
            // the event renders in every calendar view
            if (event.startDate - event.endDate === 0) {
                event.endDate.setSeconds(event.startDate.getSeconds() + 1);
            }
            return event;
        });
        return {
            allDayCounter,
            appointmentCounter,
            appointments,
        };
    }

    getExtendedMenu() {
        const { dialogStore, uiStore } = this.contextParams;
        const { dialog } = dialogStore;
        const { type } = dialog.view;
        return pageController.getAvailableExtendedActions(type, dialogStore, uiStore);
    }

    getMenuItems = () => {
        const { dialogStore } = this.contextParams;
        const { dialog } = dialogStore;
        const { menu: topMenu } = dialog.view;
        const availableMenuItems = topMenu ? uiHelper.asGenericMenu(dialog.id, topMenu.findContextMenu()) : null;
        return availableMenuItems;
    }


    setDateRange(dialogStore, defaultDate) {
        const anchorDate = new Date(defaultDate || (new Date()));
        // First and last days of the month
        const firstDay = new Date(anchorDate.getFullYear(), anchorDate.getMonth(), 1);
        const lastDay = new Date(anchorDate.getFullYear(), anchorDate.getMonth() + 1, 0);
        // We also want a week before and after the current month
        firstDay.setDate(firstDay.getDate() - 7);
        lastDay.setDate(lastDay.getDate() + 7);
        const firstDayFormat = firstDay.toISOString().replace(/T.*/, '');
        const lastDayFormat = lastDay.toISOString().replace(/T.*/, '');
        const dateRange = `CALENDAR:${firstDayFormat}:${lastDayFormat}`;
        calendarHelper.setDateRange(dialogStore, dateRange);
    }

    componentWillUnmount() {
        const { uiStore, dialogStore } = this.contextParams;
        calendarHelper.resetCalendar(dialogStore, uiStore);
    }

    render() {
        const { uiStore, dialogStore } = this.contextParams;
        const { dialog, records, queryInProgress } = dialogStore;
        const { view } = dialog;
        let resolvedProps = this.resolveProperties();
        const availableMenuItems = this.getMenuItems();
        const xMenu = this.getExtendedMenu();
        const { allDayCounter, appointments } = this.getCalendarEvents(records, view);
        const defaultDate = calendarHelper.getDate(dialogStore, uiStore);
        const defaultView = calendarHelper.getView(dialogStore, uiStore);
        const pickedDate = calendarHelper.getPickedDate(dialogStore, uiStore);
        const orientation = calendarHelper.getOrientation(dialogStore, uiStore);
        const showConfirmation = calendarHelper.getConfirmation(dialogStore, uiStore);
        const firstDayOfWeek = calendarHelper.getFirstDayofWeek(rootStore);
        const selectedItems = this.getSelectedItems();
        if (!dialog || !records || !records.length) {
            this.setDateRange(dialogStore, defaultDate);
        }
        resolvedProps = {
            appointments,
            allDayCounter,
            availableMenuItems,
            defaultDate,
            defaultView,
            pickedDate,
            firstDayOfWeek,
            locale: Catavolt.locale.langCountryString,
            orientation,
            isLoading: queryInProgress,
            onAppointmentUpdate: this.handleAppointmentUpdate,
            onConfirm: this.handleConfirm,
            onCancel: this.handleCancel,
            onDateChange: this.handleDateChange,
            onViewChange: this.handleViewChange,
            onPickerChange: this.handlePickerChange,
            onPrevious: this.handlePrevious,
            onNext: this.handleNext,
            onMonthCellClick: this.handleMonthCellClick,
            onMonthCellDoubleClick: this.handleMonthCellDoubleClick,
            onEventClick: this.handleEventClick,
            onNativeEventClick: this.handleNativeEventClick,
            onEventDoubleClick: this.handleEventDoubleClick,
            onMenuAction: this.handleMenuAction,
            onOrientationChange: this.handleOrientationChange,
            selectedItems,
            showConfirmation,
            xMenu,
            ...resolvedProps,
        };
        return React.createElement(componentFactory.getPlatformComponent('calendar'), resolvedProps);
    }
}

Calendar.contextType = SaltContext;
