/*       */
import GmlUtil from '../GmlUtil';
import { FLEX_DIRECTION, GML_A, REF_PREFIX } from '../gmlConstants';
import FlexDirection from '../layoutAttribute/general/FlexDirection';
import Context from '../Context';
import Version from '../Version';
import AbstractGml from './AbstractGml';
import Base from './Base';
import gmlAttributeFactory from '../layoutAttribute/factory/GmlAttributeFactory';
import SimpleStringAttribute from '../layoutAttribute/SimpleStringAttribute';

export default class Gml extends AbstractGml {
    tenantLevelGML ;
    constructor(jsonObject, tenantLevelGML) {
        super(jsonObject);
        this.tenantLevelGML = tenantLevelGML;
        Gml.assignJsonKeys(jsonObject, 'root', 9999);
    }

    asSalt(warnings, viewId) {
        const defaultFlexDirection = FlexDirection.column;
        // Place defaults into a GML-like structure so they can be parsed out and seeded into our cascading
        // values starting point.  If these values exist in any GML document, the defaults here will be ignored.
        const gmlLikeDefaults = {
            $: {
                strictMode: 'true',
                actionMargin: '5,5,5,5', // Top,right,bottom,left
                textFontSize: '18',
                lineWidth: '1',
                lineColor: '#000000',
                labelValueSeparator: ':  ',
                propSeparator: '  ',
                detailPadding: '5',
                listPadding: '5',
                imageSize: '50',
            },
        };
        // Seed the cascading with defaults.
        const starterContext = Context.starterContext(this, this.getVersion(), warnings);
        let defaultCascading = { };
        defaultCascading.strictMode = gmlAttributeFactory.attributeFor(gmlLikeDefaults, 'strictMode', starterContext);
        defaultCascading.actionMargin = gmlAttributeFactory.attributeFor(gmlLikeDefaults, 'actionMargin', starterContext);
        defaultCascading.textSize = gmlAttributeFactory.attributeFor(gmlLikeDefaults, 'textFontSize', starterContext);
        defaultCascading.lineWidth = gmlAttributeFactory.attributeFor(gmlLikeDefaults, 'lineWidth', starterContext);
        defaultCascading.lineColor = gmlAttributeFactory.attributeFor(gmlLikeDefaults, 'lineColor', starterContext);
        defaultCascading.labelValueSeparator = gmlAttributeFactory.attributeFor(gmlLikeDefaults, 'labelValueSeparator', starterContext);
        defaultCascading.propSeparator = gmlAttributeFactory.attributeFor(gmlLikeDefaults, 'propSeparator', starterContext);
        defaultCascading.detailPadding = gmlAttributeFactory.attributeFor(gmlLikeDefaults, 'detailPadding', starterContext);
        defaultCascading.listPadding = gmlAttributeFactory.attributeFor(gmlLikeDefaults, 'listPadding', starterContext);
        defaultCascading.imageSize = gmlAttributeFactory.attributeFor(gmlLikeDefaults, 'imageSize', starterContext);
        defaultCascading = this.tenantLevelGML ? this.tenantLevelGML.getCascadingAttributes(starterContext, defaultCascading) : defaultCascading;
        defaultCascading[FLEX_DIRECTION] = defaultFlexDirection;

        // Rebuild the context now that we have the default cascading attributes
        const cascading = this.getCascadingAttributes(starterContext, defaultCascading);
        const context = Context.starterContext(this, this.getVersion(), warnings, cascading, this.buildStylesByName());
        context.document.setViewId(viewId);

        // Set the reporting mode based on strictMode prop.  There will always be one because it is part
        // of the defaultCascading set.
        warnings.setStrictMode(cascading.strictMode);
        warnings.setStrictModeExceptions(cascading.strictModeExceptions);

        // Build the salt ref children.
        const saltChildren = [];
        this.exportConstants(context, saltChildren);
        this.exportSaltChildren(context, saltChildren);

        const result = {
            salt: {
                version: '1.0.0',
                children: Base.flattenChildren(saltChildren),
            },
        };
        context.document.resolveAll();
        return result;
    }

    static assignJsonKeys(json, parentId, sequence) {
        const myId = `${parentId}-${sequence.toString()}`;
        if (!json.$) json.$ = {}; // eslint-disable-line no-param-reassign
        json.$.cvId = myId; // eslint-disable-line no-param-reassign
        // Traverse and modify the json to have a unique key for each node.
        Base.elements(json).forEach((c, i) => {
            if (typeof c === 'object') {
                Gml.assignJsonKeys(c, myId, i);
            }
        });
    }

    buildConstantRefs(consts) {
        let result = [];
        // If a constant has relevant qualifiers, then nest the constant into a when.
        consts.forEach(c => {
            const constObject = {};
            const constRefs = [];
            // First expand the constant into "constObject".  These props all represent a name value constant pair.
            c.getAttributesAsObject(constObject);
            Object.keys(constObject).forEach(e => {
                if (e !== 'qualifiers' && e !== 'cvId') {
                    // Setup value as a numeric if it contains numeric content.
                    // see https://stackoverflow.com/questions/175739/built-in-way-in-javascript-to-check-if-a-string-is-a-valid-number
                    // eslint-disable-next-line no-self-compare
                    let value = +constObject[e] === +constObject[e] && !Number.isNaN(parseInt(constObject[e], 10)) ? +constObject[e] : constObject[e];
                    // An exception to the documentation has been added. We could handle this but it would require
                    // an enhancement to the engine/xStyle methods to support outputting an xStyle.
                    if (value === '*meta') {
                        value = 20;
                    }
                    constRefs.push({
                        ref: {
                            name: SimpleStringAttribute.asRefName(`${REF_PREFIX}${e}`),
                            value,
                        },
                    });
                }
            });
            // If there is an expression string as a result of looking at the qualifiers, then place
            // the ref definition within a "when".  Otherwise just return the ref.
            const expr = c.asQualifierExprString();
            if (expr) {
                const whenChild = {
                    when: {
                        assert: { expr },
                        children: constRefs,
                    },
                };
                result.push(whenChild);
            } else {
                result = result.concat(constRefs);
            }
        });
        return result;
    }

    buildStylesByName() {
        const result = {};
        // Apply constants from tenant level GML
        if (this.tenantLevelGML) {
            this.buildStylesByNameFor(this.tenantLevelGML.styles(), result);
        }
        // Apply constants from this GML.
        this.buildStylesByNameFor(this.styles(), result);
        return result;
    }

    buildStylesByNameFor(styles, result) {
        const toUpdate = result;
        styles.forEach(namedStyle => {
            const attributes = namedStyle.getAttributesAsObject();
            delete attributes.name;

            // Write the ref with the exported attributes.
            toUpdate[namedStyle.getName()] = attributes;
        });
        return result;
    }

    exportConstants(context, saltChildren) {
        // Apply constants from tenant level GML
        const knownConstants = {};
        const addKnown = (name) => { knownConstants[name.substring(REF_PREFIX.length)] = true; };
        const logKnown = (e) => {
            if (e.ref) {
                addKnown(e.ref.name);
            } else if (e.when && e.when.children) {
                e.when.children.forEach(e2 => {
                    addKnown(e2.ref.name);
                });
            }
        };
        if (this.tenantLevelGML) {
            this.buildConstantRefs(this.tenantLevelGML.constants())
                .forEach(e => {
                    saltChildren.push(e);
                    logKnown(e);
                });
        }
        // Apply constants from this GML.
        this.buildConstantRefs(this.constants()).forEach(e => {
            saltChildren.push(e);
            logKnown(e);
        });
        context.setKnownConstants(knownConstants);
    }

    exportSaltChildren(context, saltChildren) {
        // Process the root children.  Only display one root child.  Accomplish this by listing
        // each root child in a when and each subsequent root child in the else.  If an unconditional
        // root is encountered, it will be displayed and others afteward will not.
        const gmlChildren = Base.rootChildren(this.json, context);
        let prevElseChildren = null;
        gmlChildren.forEach(e => {
            // Either take the child as is, or wrap in a when to handle qualifiers.
            const child = e.asSalt(context);
            const ac = e.asQualifierExprString() || 'true';
            const newElseChildren = [];
            const newChild = {
                when: {
                    assert: { expr: ac },
                    children: [ child ],
                    'else-children': newElseChildren,
                },
            };
            // First time, push the when as the only saltChild.  Every other time, push the when as the
            // else-children of the previous when.
            if (!prevElseChildren) {
                saltChildren.push(newChild);
                prevElseChildren = newElseChildren;
            } else {
                prevElseChildren.push(newChild);
                prevElseChildren = newElseChildren;
            }
        });
    }

    getVersion() {
        const raw = this.getVersionString();
        const rawWithDefault = raw || '1.0'; // If not found, assume version 1.0.
        return new Version(rawWithDefault);
    }

    getVersionString() {
        return GmlUtil.getValueForExpr(this.json, [ GML_A.version ]);
    }

    constants() {
        return Base.constants(this.json);
    }

    styles() {
        return Base.styles(this.json);
    }
}
