const getStyles = (contextStyles = {}, selected) => {
    // Populate the component parts with theme styles
    return {
        spacer: {
            backgroundColor: selected ? 'unset' : '#ffffff',
            display: 'flex',
            flexGrow: 1,
            height: '100%',
            boxSizing: 'border-box',
            ...contextStyles.spacer,
        },
    };
};

export default getStyles;
