import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';

import getStyles from './GridCell.styles';

class GridCell extends PureComponent {
    static propTypes = {
        /** Styles for cell */
        cellStyle: PropTypes.object,

        /** Column properties */
        column: PropTypes.object,

        /** Extended styles for this component */
        contextStyles: PropTypes.shape({
            /** Styles for the cell container */
            container: PropTypes.object,
        }),

        /** Row  node */
        node: PropTypes.object,

        /** Called to render property */
        onRenderItem: PropTypes.func,

        /** Array of records */
        records: PropTypes.array,

        /** Row index of the record */
        rowIndex: PropTypes.number,
    };

    static defaultProps = {
        contextStyles: {},
    };

    render() {
        const {
            cellStyle,
            column: { colDef },
            contextStyles,
            node: { selected },
            onRenderItem,
            records,
            rowIndex,
        } = this.props;
        const { container } = getStyles(contextStyles);
        const record = records[rowIndex];
        const propertyName = colDef.field;
        const { tipText } = record && record.propAtName(propertyName) || {};
        const propertyProps = {
            allowAnnotations: !selected,
            name: propertyName,
            style: cellStyle,
        };
        return (
            <span
                key={ `${rowIndex}_${propertyName}` }
                style={ container }
                title={ tipText }>
                { onRenderItem(record, propertyProps) }
            </span>
        );
    }
}

export default GridCell;
