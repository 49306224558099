import React, { PureComponent } from 'react';

import SortModal from '../components/modal/SortModal';

/**
 * RWSortForm is a thin wrapper around the SortModal component
 */
class RWSortForm extends PureComponent {
    render() {
        return (
            React.createElement(SortModal, { ...this.props })
        );
    }
}

export default RWSortForm;
