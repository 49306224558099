/* @flow */
export default {
    advancedSettings: 'Advanced Settings',
    clearCacheLabel: 'Clear Cache',
    clearCacheText: 'Delete cached Xalt Moblity data from your browser.',
    title: 'Settings',
    tenantIdLabel: 'Tenant ID',
    useOauthLabel: 'Use OAuth For Login',
    serverURLLabel: 'ServerUrl',
    useDebugServerLabel: 'Use Server',
};
