import { Dialog, Form, PropertyFormatter, RedirectionUtil, TypeNames } from '../models';
import { DialogProxy } from '../proxy/DialogProxy';
import { CvLocale } from '../util/CvLocale';
import { Log } from '../util/Log';
import { ObjUtil } from '../util/ObjUtil';
import { SessionTimer } from "../util/SessionTimer";
import { DialogService } from './DialogService';
import { createDebugProxy } from "../util/ProxyUtils";
/**
 * Top-level entry point into the Catavolt API
 */
export class CatavoltApiImpl {
    /* ********************
            Statics
     *********************** */
    /**
     * Get the default session time
     * @returns {number}
     */
    static get defaultTTLInMillis() {
        return CatavoltApiImpl.ONE_HOUR_IN_MILLIS;
    }
    /**
     * Get the singleton instance of the CatavoltApiImpl
     * @returns {CatavoltApiImpl}
     */
    static get singleton() {
        if (!CatavoltApiImpl._singleton) {
            CatavoltApiImpl._singleton = new CatavoltApiImpl(CatavoltApiImpl.SERVER_URL, CatavoltApiImpl.SERVER_VERSION);
        }
        return CatavoltApiImpl._singleton;
    }
    /**
     * Construct an CatavoltApiImpl
     * This should not be called directly, instead use the 'singleton' method
     * @private
     */
    constructor(serverUrl, serverVersion) {
        this.DEFAULT_LOCALE = new CvLocale('en');
        this.dataLastChangedTime = new Date();
        this._locale = null;
        this._sessionTimer = null;
        this._onSessionExpiration = null;
        this._defaultServerUrl = null;
        if (CatavoltApiImpl._singleton) {
            throw new Error('Singleton instance already created');
        }
        this._devicePropsStatic = {};
        this._devicePropsDynamic = {};
        // Note: if the getsessioncount fails with a 'NO_SESSION' return code, logout will happen automatically
        // If the client goes offline - logout will not happen, only an error
        this._sessionTimer = new SessionTimer(() => {
            return this.dialogApi.getSessionCount(this.session.tenantId, this.session.id)
                .then(result => { }).catch(e => Log.error(`getSessionCount failed with ${e.message}`));
        }, CatavoltApiImpl.CHECK_SESSION_INTERVAL_MILLIS);
        this.initDialogApi(serverUrl, serverVersion);
        CatavoltApiImpl._singleton = this;
    }
    /* *****************
        Public Ops
       ******************* */
    /**
     * Add or replace a dynamic device property (func)
     * @param propName
     * @param propFn
     */
    addDynamicDeviceProp(propName, propFn) {
        this._devicePropsDynamic[propName] = propFn;
    }
    /**
     * Add or replace a static device property
     *
     * @param propName
     * @param propValue
     */
    addStaticDeviceProp(propName, propValue) {
        this._devicePropsStatic[propName] = propValue;
    }
    /**
     *
     * @param {ClientListener} clientListener
     */
    addClientListener(clientListener) {
        this._dialogApi.addClientListener(clientListener, this.locale);
    }
    /**
     * Get the preferred locale
     * @returns {CvLocale}
     */
    get locale() {
        if (!this._locale) {
            // the server can set the locale - see if it has...
            const defaultLocale = this.session.tenantProperties.browserLocale;
            if (defaultLocale) {
                try {
                    const localeJson = JSON.parse(defaultLocale);
                    if (localeJson.language) {
                        this._locale = new CvLocale(localeJson.language, localeJson.country);
                    }
                }
                catch (err) {
                    this._locale = this.DEFAULT_LOCALE;
                }
            }
        }
        //otherwise, try the browser setting
        if (!this._locale) {
            if (navigator && navigator.languages && navigator.languages.length) {
                // in the future, we can the list here against our support and choose the first language that is supported
                this._locale = new CvLocale(navigator.languages[0]);
            }
            else {
                this._locale = this.DEFAULT_LOCALE;
            }
        }
        return this._locale;
    }
    set locale(locale) {
        this._locale = locale;
    }
    /**
     * change password and create new session
     *
     * @param tenantId
     * @param clientType
     * @param userId
     * @param oldPassword
     * @param newPassword
     *
     * @returns {Promise<Session | Redirection>}
     */
    changePasswordAndLogin(tenantId, clientType, userId, oldPassword, newPassword) {
        const changePasswordFields = {
            newPassword
        };
        return this.processLogin(tenantId, clientType, userId, oldPassword, changePasswordFields);
    }
    /**
     * change password
     *
     * @param password
     * @param newPassword
     */
    changePassword(password, newPassword) {
        const passwordInfo = {
            password,
            newPassword
        };
        return this.dialogApi.changePassword(this.session.tenantId, this.session.id, passwordInfo).then((result) => {
            return result;
        });
    }
    /**
     * Get the number of millis that the client will remain active between calls
     * to the server.
     * @returns {number}
     */
    get clientTimeoutMillis() {
        // @TEMP @TEST return 20000;
        const mins = this.session.tenantProperties.clientTimeoutMinutes;
        return mins ? Number(mins) * 60 * 1000 : CatavoltApiImpl.defaultTTLInMillis;
    }
    /**
     * Get the currency symbol override if defined from the server.
     * @returns {string}
     */
    get currencySymbol() {
        const currencySymbol = this.session.tenantProperties.currencySymbol;
        return currencySymbol ? currencySymbol : null;
    }
    /**
     * Get the device props
     * @returns {{[p: string]: string}}
     */
    get deviceProps() {
        const newProps = ObjUtil.addAllProps(this._devicePropsStatic, {});
        for (const attr in this._devicePropsDynamic) {
            if (this._devicePropsDynamic.hasOwnProperty(attr)) {
                newProps[attr] = this._devicePropsDynamic[attr]();
            }
        }
        return newProps;
    }
    /**
     * Get the DialogApi instance
     * @returns {DialogApi}
     */
    get dialogApi() {
        return this._dialogApi;
    }
    /**
     * Initialize a dialog service implementation for use by this CatavoltApiImpl
     *
     * @param serverVersion
     * @param serverUrl
     */
    initDialogApi(serverUrl, serverVersion = CatavoltApiImpl.SERVER_VERSION, client = new DialogProxy()) {
        const resolvedServerUrl = serverUrl || this._defaultServerUrl || CatavoltApiImpl.SERVER_URL;
        this._dialogApi = new DialogService(client, resolvedServerUrl, serverVersion, () => {
            this.logout().then(() => {
                this._onSessionExpiration && this._onSessionExpiration();
            });
        });
    }
    /**
     * Initialize a dialog service implementation for use by this CatavoltApiImpl with the specified Client
     */
    initDialogApiWithClient(client) {
        this.initDialogApi(this._defaultServerUrl || CatavoltApiImpl.SERVER_URL, CatavoltApiImpl.SERVER_VERSION, client);
    }
    /**
     * Initialize a dialog service implementation for use by this CatavoltApiImpl with the specified Client and Server
     */
    initDialogApiWithServerAndClient(serverUrl, client) {
        this.initDialogApi(serverUrl, CatavoltApiImpl.SERVER_VERSION, client);
    }
    /**
     * For debug purposes only
     * Turns on method tracing
     */
    initDebugDialogApi() {
        this._dialogApi = createDebugProxy({ loggerArgs: ['DialogAPI>'], target: this._dialogApi, logConfig: Log.hotpink });
    }
    /**
     * Check for the availability of the given featureSet
     * @see FeatureSet
     * @param featureSet
     * @returns {boolean}
     */
    isFeatureSetAvailable(featureSet) {
        try {
            const currentVersion = AppVersion.getAppVersion(this.session.serverVersion);
            const featureMinimumVersion = FeatureVersionMap[featureSet];
            return featureMinimumVersion.isLessThanOrEqualTo(currentVersion);
        }
        catch (error) {
            Log.error('Failed to compare appVersions for feature ' + featureSet);
            Log.error(error);
            return false;
        }
    }
    /**
     * Checked logged in status
     * @returns {boolean}
     */
    get isLoggedIn() {
        return !!this._session;
    }
    /**
     * Check offline status
     *
     * @param tenantId
     */
    isAnyUserInBriefcaseMode(tenantId) {
        return this.dialogApi.isAnyUserInBriefcaseMode(tenantId);
    }
    /**
     * Check offline status
     *
     * @param userInfo
     * @returns {Promise<boolean>}
     */
    isUserInBriefcaseMode(userInfo) {
        return this.dialogApi.isUserInBriefcaseMode(userInfo);
    }
    /**
     * Retrieve the tenant capabilities
     *
     * @param tenantId
     * @param clientType
     *
     * * @returns {Promise<TenantCapabilities>}
     */
    retrieveTenantCapabilities(tenantId, clientType) {
        return this.dialogApi.getTenantCapabilities(tenantId, clientType)
            .then((result) => {
            return result;
        })
            .catch(error => {
            return error;
        });
    }
    /**
     * Get OAuth Login Redirection Model
     *
     * @param proofKey
     * @param clientType
     * @param webRedirectURL
     * @param oAuthUrl
     */
    getOAuthAuthorization(proofKey, oAuthUrl, clientType, webRedirectURL) {
        return this.dialogApi.oAuthAuthorization(proofKey, oAuthUrl, clientType, webRedirectURL)
            .then((result) => {
            return result;
        })
            .catch(error => {
            return error;
        });
    }
    getOAuthWorkBenchRedirection(tenantId, sessionId, oAuthUrl) {
        return this.dialogApi.oAuthWorkBenchRedirection(tenantId, sessionId, oAuthUrl)
            .then((result) => {
            return result;
        })
            .catch(error => {
            return error;
        });
    }
    /**
     * Log in and retrieve the Session
     *
     * @param tenantId
     * @param clientType
     * @param userId
     * @param password
     *
     * @returns {Promise<Session | Redirection>}
     */
    login(tenantId, clientType, userId, password) {
        if (this.isLoggedIn) {
            return this.logout().then(result => this.processLogin(tenantId, clientType, userId, password));
        }
        else {
            return this.processLogin(tenantId, clientType, userId, password);
        }
    }
    loginWithToken(tenantId, clientType, permissionToken, proofKey) {
        if (this.isLoggedIn) {
            return this.logout().then(result => this.processLogin(tenantId, clientType, null, null, null, permissionToken, proofKey));
        }
        else {
            return this.processLogin(tenantId, clientType, null, null, null, permissionToken, proofKey);
        }
    }
    /**
     * Logout and destroy the session
     * @returns {{sessionId:string}}
     */
    logout() {
        if (this.isLoggedIn) {
            const sessionId = this.session.id;
            return this.dialogApi
                .deleteSession(this.session.tenantId, this.session.id)
                .then(result => {
                this._session = null;
                this._sessionTimer.stopSessionTimer();
                return result;
            })
                .catch(error => {
                Log.warn(`Error logging out ${error.message}`);
                Log.debug(`Error logging out ${JSON.stringify(error)}`);
                this._session = null;
                this._sessionTimer.stopSessionTimer();
                return { sessionId };
            });
        }
        else {
            return Promise.resolve({ sessionId: null });
        }
    }
    openDialogWithId(dialogId) {
        return this.dialogApi.getDialog(this.session.tenantId, this.session.id, dialogId).then((dialog) => {
            dialog.initialize(this);
            if (dialog.view instanceof Form) {
                return dialog;
            }
            else {
                throw new Error(`Unexpected top-level dialog view type: ${dialog.view.type}`);
            }
        });
    }
    openDialog(redirection) {
        return this.openDialogWithId(redirection.dialogId);
    }
    /**
     * Not yet implemented
     * @param {string} url
     * @returns {Promise<StreamProducer>}
     */
    openStream(url) {
        // return this.dialogApi.streamUrl(null, null, url);
        throw Error('not yet implemented');
    }
    toDialogOrRedirection(resultPr) {
        return resultPr.then((actionResult) => {
            if (RedirectionUtil.isDialogRedirection(actionResult)) {
                return this.openDialog(actionResult);
            }
            else if (RedirectionUtil.isRedirection(actionResult)) {
                return Promise.resolve(actionResult);
            }
            else {
                // @TODO - this shouldn't be a null redirection - what should it be?
                return Promise.resolve(actionResult);
            }
        });
    }
    getRedirection(redirectionId) {
        return this.dialogApi.getRedirection(this.session.tenantId, this.session.id, redirectionId);
    }
    /**
     * Open a {@link WorkbenchAction}
     * @param workbenchAction
     * @returns {Promise<{actionId:string} | Redirection>}
     */
    performWorkbenchAction(workbenchAction) {
        return this.performWorkbenchActionForId(workbenchAction.workbenchId, workbenchAction.id);
    }
    /**
     * Open a {@link WorkbenchWorkbenchAction}
     * @param workbenchId
     * @param workbenchActionId
     * @returns {Promise<{actionId:string} | Redirection>}
     */
    performWorkbenchActionForId(workbenchId, workbenchActionId) {
        if (!this.isLoggedIn) {
            return Promise.reject(new Error('User is not logged in'));
        }
        return this.dialogApi.performWorkbenchAction(this.session.tenantId, this.session.id, workbenchId, workbenchActionId);
    }
    /**
     * Refresh the CatavoltApiImpl
     *
     * @returns {Promise<Session>}
     */
    refreshSession(tenantId, sessionId) {
        return this.dialogApi.getSession(tenantId, sessionId).then(session => {
            this._session = session;
            this._sessionTimer.resetSessionTimer();
            return session;
        });
    }
    performDeepLink(tenantId, sessionId, deeplinkingId) {
        return this.dialogApi.performDeepLink(tenantId, sessionId, deeplinkingId).then(result => {
            return result;
        });
    }
    /**
     * Time remaining before this session is expired by the server
     * @returns {number}
     */
    get remainingSessionTime() {
        return this.clientTimeoutMillis - (new Date().getTime() - this.dialogApi.lastServiceActivity.getTime());
    }
    /**
     *
     * @param {ClientListener} clientListener
     */
    removeClientListener(clientListener) {
        this._dialogApi.removeClientListener(clientListener);
    }
    setDefaultServerUrl(serverUrl) {
        this._defaultServerUrl = serverUrl;
    }
    getDefaultServerUrl() {
        return this._defaultServerUrl || CatavoltApiImpl.SERVER_URL;
    }
    /**
     * Get the Session
     * @returns {Session}
     */
    get session() {
        return this._session;
    }
    /**
     * Return whether or not the session has expired
     * @returns {boolean}
     */
    get sessionHasExpired() {
        return this.remainingSessionTime < 0;
    }
    /**
     * Function that will be notified when the session expires
     * @param {() => void} onSessionExpiration
     */
    set onSessionExpiration(onSessionExpiration) {
        this._onSessionExpiration = onSessionExpiration;
    }
    /**
     * Trigger Loader Event
     * * @returns {Promise<any>}
     */
    triggerLoaderEvent(tenantId, sessionId, dialogId) {
        return this.dialogApi.getMode(tenantId, sessionId, dialogId).then(session => {
            return null;
        });
    }
    /**
     *
     * @param {string} contentId
     * @param {StreamConsumer} streamConsumer
     * @returns {Promise<LargeProperty>}
     */
    streamContent(contentId, streamConsumer) {
        return Dialog.loadLargeProperty((params) => {
            return this.dialogApi.getContent(this.session.tenantId, this.session.id, contentId, params);
        }, streamConsumer);
    }
    processLogin(tenantId, clientType, userId, password, changePasswordFields, permissionToken, proofKey) {
        const login = {
            userId,
            password,
            permissionToken,
            proofKey,
            clientType,
            deviceProperties: this.deviceProps,
            changePasswordFields,
            type: TypeNames.LoginTypeName
        };
        return this.dialogApi.createSession(tenantId, login).then((result) => {
            if (result.type === TypeNames.SessionTypeName) {
                this._session = result;
                this._sessionTimer.resetSessionTimer();
                return result;
            }
            else {
                return result;
            }
        });
    }
}
CatavoltApiImpl.ONE_HOUR_IN_MILLIS = 60 * 60 * 1000;
CatavoltApiImpl.CHECK_SESSION_INTERVAL_MILLIS = 5 * 60 * 1000;
// defaults (if not set by user)
CatavoltApiImpl.SERVER_URL = 'https://dialog.hxgn-api.net';
CatavoltApiImpl.SERVER_VERSION = 'v0';
class AppVersion {
    static getAppVersion(versionString) {
        const [major, minor, patch] = versionString.split('.');
        return new AppVersion(Number(major || 0), Number(minor || 0), Number(patch || 0));
    }
    constructor(major, minor, patch) {
        this.major = major;
        this.minor = minor;
        this.patch = patch;
    }
    /**
     * Is 'this' version less than or equal to the supplied version?
     * @param anotherVersion - the version to compare to 'this' version
     * @returns {boolean}
     */
    isLessThanOrEqualTo(anotherVersion) {
        if (anotherVersion.major > this.major) {
            return true;
        }
        else if (anotherVersion.major === this.major) {
            if (anotherVersion.minor > this.minor) {
                return true;
            }
            else if (anotherVersion.minor === this.minor) {
                return anotherVersion.patch >= this.patch;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
}
const FeatureVersionMap = {
    View_Support: AppVersion.getAppVersion('1.3.447'),
    Unified_Search: AppVersion.getAppVersion('1.3.463')
};
export const Catavolt = CatavoltApiImpl.singleton;
export const propertyFormatter = PropertyFormatter.singleton(Catavolt);
