/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import componentFactory from '../componentFactory';
import componentPropTypes from './abstractComponentPropTypes';
import serviceFactory from '../../services/serviceFactory';
import engineConstants from '../engineConstants';

const propDefinitions = {
    style: componentPropTypes.style,
    value: componentPropTypes.value,
    tipText: componentPropTypes.tipText,
    isReadMode: componentPropTypes.isReadMode,
    isFocused: componentPropTypes.isFocused,
    onValueChanged: componentPropTypes.onValueChanged,
    xStyle: componentPropTypes.xStyle,
    maxLength: componentPropTypes.maxLength,
    propertyRef: componentPropTypes.propertyRef,
};

export default class TextBlock extends Component {
    static propTypes = propDefinitions;
    static typeName = engineConstants.component.name.textBlock;

    render() {
        const {
            style,
            value,
            tipText,
            isReadMode,
            isFocused,
            onValueChanged,
            xStyle,
            maxLength,
            propertyRef,
        } = this.props;
        const { multilineInList } = xStyle || {};
        const length = value ? maxLength - value.length : maxLength;
        const { lang } = serviceFactory;

        let leftOverMessage = '';
        if (maxLength && maxLength <= 4000 && !xStyle.hideLeftOverCharacters && !isReadMode && length >= 0) {
            leftOverMessage = lang.formatString(lang.generic.leftOverCharacters, length);
        }

        const textBlockProps = {
            style,
            value: value || '',
            tipText: tipText || (multilineInList ? value : ''),
            isReadMode,
            isFocused,
            onValueChanged,
            xStyle,
            maxLength,
            propertyRef,
            leftOverMessage,
            leftOverCharacters: length,
        };
        return React.createElement(componentFactory.getPlatformPropertyComponent('textBlock'), { ...textBlockProps });
    }
}
