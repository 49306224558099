class ComponentBuilderProvider {
    static types = {
        BarCodeIcon: 'BarCodeIcon',
        BarChartProp: 'BarChartProp',
        Boolean: 'Boolean',
        ClearAction: 'ClearAction',
        ColorPicker: 'ColorPicker',
        Composite: 'Composite',
        DateTime: 'DateTime',
        DropDown: 'DropDown',
        EmailIcon: 'EmailIcon',
        GaugeChartProp: 'GaugeChartProp',
        Image: 'Image',
        ImageAction: 'ImageAction',
        PaperCheckbox: 'PaperCheckbox',
        PhoneIcon: 'PhoneIcon',
        TextField: 'TextField',
        TextLabel: 'TextLabel',
        TextWithImage: 'TextWithImage',
        Select: 'Select',
        WebIcon: 'WebIcon',
        FilePickerInput: 'FilePickerInput',
    };

    getBuilder(name) {
        if (!this.builderProvider) {
            throw new Error('No component builder provider set.');
        } else if (!this.builderProvider[name]) {
            throw new Error(`No component builder for component: ${name}`);
        }
        return this.builderProvider[name];
    }

    setBuilderProvider(provider) {
        this.builderProvider = provider;
    }
    getBuilderProvider() {
        return this.builderProvider;
    }
}

export const componentBuilderProvider = new ComponentBuilderProvider();
export const { types: componentBuilderTypes } = ComponentBuilderProvider;
