
import { constants } from '../../constants';

export default class Globals {
    constructor(sessionStore, uiStore) {
        this.sessionStore = sessionStore;
        this.uiStore = uiStore;
    }

    get isLandscape() {
        return this.device.screenWidth > this.device.screenHeight;
    }

    get isPortrait() {
        return this.device.screenWidth < this.device.screenHeight;
    }

    get isAndroid() {
        return this.device.platform === constants.devices.PLATFORM_ANDROID_REACT;
    }

    get isIOS() {
        return this.device.platform === constants.devices.PLATFORM_IOS_REACT;
    }

    get isBrowser() {
        return this.device.platform === constants.devices.PLATFORM_BROWSER_REACT;
    }

    get device() {
        return this.uiStore.getValueForUIObject(constants.ui.APPLICATION_UI_ID, constants.ui.DEVICE_PROPERTIES);
    }

    get session() {
        return this.sessionStore.session;
    }
}
