import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { TypeNames } from 'cv-dialog-sdk';
import DetailsView from './DetailsView';
import ListView from './ListView';
import FormView from './FormView';
import Box from './Box';
import SaltContext from './SaltContext';
import Layout from './xStyle/Layout';
import SaltComponent from './SaltComponent';
import engineConstants from './engineConstants';

const typeComponentMap = {};
typeComponentMap[TypeNames.CalendarTypeName] = ListView;
typeComponentMap[TypeNames.DetailsTypeName] = DetailsView;
typeComponentMap[TypeNames.ListTypeName] = ListView;
typeComponentMap[TypeNames.FormTypeName] = FormView;
// @TODO @rchalla - is this intentional?  FormView for PowerBi Detail?
typeComponentMap[TypeNames.PowerBITypeName] = FormView;
typeComponentMap[TypeNames.GpsReadingTypeName] = DetailsView;
typeComponentMap[TypeNames.GraphTypeName] = ListView;
typeComponentMap[TypeNames.BarcodeScanTypeName] = DetailsView;
typeComponentMap[TypeNames.MapTypeName] = ListView;
typeComponentMap[TypeNames.ImagePickerTypeName] = ListView;
typeComponentMap[TypeNames.VizTypeName] = DetailsView;
typeComponentMap[TypeNames.NFCScanTypeName] = DetailsView;

@observer
export default class View extends SaltComponent {
    static propTypes = {
        id: PropTypes.string,
        children: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.arrayOf(PropTypes.element),
        ]),
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xStyle: PropTypes.oneOfType([
            Layout.layoutPropType,
            PropTypes.arrayOf(Layout.layoutPropType),
        ]),
        assert: PropTypes.shape({
            refName: PropTypes.string,
            expr: PropTypes.string,
        }),
        scroll: PropTypes.string,
    };

    static typeName = engineConstants.component.name.view;

    render() {
        const { id, style, xStyle, assert, scroll, children } = this.props;
        const { saltStore } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(id);
        if (dialogStore) {
            const component = typeComponentMap[dialogStore.dialog.view.type];
            const childElem = React.createElement(component, this.props);
            const newContext = {
                ...this.context,
                viewId: id,
            };
            return (
                <SaltContext.Provider
                    value={ newContext }>
                    {childElem}
                </SaltContext.Provider>
            );
        }
        const boxProps = { style, xStyle, assert, scroll };
        return React.createElement(Box, boxProps, children);
    }
}

View.contextType = SaltContext;
