import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import Toggle from '../base/Switch';


@observer
class FilterValueToggleComponent extends Component {
    static defaultProps = {
        // styles: {},
        onSetFilterValue: () => {},
    }

    static propTypes = {
        propertyName: PropTypes.string.isRequired,
        searchDialogStore: PropTypes.object,
        onSetFilterValue: PropTypes.func,
    }

    constructor(props) {
        super(props);
        this.handleSwitchOnChange = this.handleSwitchOnChange.bind(this);
    }

    render() {
        const { propertyName, searchDialogStore } = this.props;
        const filterValueProperty = searchDialogStore.getSearchValuePropertyForName(propertyName);
        const { value } = filterValueProperty;

        return (
            <Toggle
                checked={ value || false }
                onChange={ this.handleSwitchOnChange } />
        );
    };

    handleSwitchOnChange(event) {
        const { target } = event;
        const { checked: value } = target;
        const { onSetFilterValue, propertyName } = this.props;
        onSetFilterValue(propertyName, value);
    }
}

export default FilterValueToggleComponent;