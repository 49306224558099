import FlexPolicy from './FlexPolicy';
import FlexDirection from './layoutAttribute/general/FlexDirection';

/**
 * A StyleSet is a Style object and an xStyle object.
 */
export const BOTH = 'both';
export const GROW = 'grow';
export const SHRINK = 'shrink';
export const EQUAL = 'equal';
export const NONE = 'none';

export default class StyleSet {
    flexPolicy ;
    scrollContentStyle ;
    style ;
    xStyle ;
    constructor(style = {}, xStyle = {}) {
        this.flexPolicy = new FlexPolicy(FlexDirection.column); // Just pick column, should be set by caller
        this.style = style;
        this.style.overflow = 'hidden';
        this.xStyle = xStyle;
        if (!this.xStyle.layout) {
            this.xStyle.layout = {};
        }
    }

    asContentContainerStyle() {
        return this.scrollContentStyle;
    }

    asStyleAttribute(mergeProps = {}) {
        const flexValues = {};
        this.flexPolicy.safeExport(flexValues);
        return { style: { ...flexValues, ...this.style, ...mergeProps } };
    }

    asXStyleAttribute(mergeProps = {}) {
        if (Object.keys(this.xStyle).length > 1 || Object.keys(this.xStyle.layout).length || Object.keys(mergeProps).length) {
            return { xStyle: { ...this.xStyle, ...mergeProps } };
        }
        return {};
    }

    combinedStyleSet(other) {
        const result = new StyleSet({ ...this.style, ...other.style }, { ...this.xStyle, ...other.xStyle });
        result.scrollContentStyle = { ...this.scrollContentStyle, ...other.scrollContentStyle };
        result.flexPolicy = this.flexPolicy.combinedFlexPolicy(other.flexPolicy);
        return result;
    }

    isEqual(other) {
        if (Object.keys(this.style).length !== Object.keys(other.style).length
            || (Object.keys(this.xStyle).length !== Object.keys(other.xStyle).length)
            || (Object.keys(this.xStyle.layout).length !== Object.keys(other.xStyle.layout).length)) {
            return false;
        }
        return StyleSet.isObjectEqual(this.style, other.style)
            && StyleSet.isObjectEqual(this.xStyle, other.xStyle)
            && this.flexPolicy.isEqual(other.flexPolicy);
    }
    static isObjectEqual(o1, o2) {
        if (Object.keys(o1).length !== Object.keys(o2).length) {
            return false;
        }
        const keys = Object.keys(o1);
        for (let i = 0; i < keys.length; i += 1) {
            const e = keys[i];
            if ((o1[e] instanceof Object)) {
                if (!(o2[e] instanceof Object)) {
                    return false;
                }
                if (!StyleSet.isObjectEqual(o1[e], o2[e])) {
                    return false;
                }
            } else if (o1[e] !== o2[e]) {
                return false;
            }
        }
        return true;
    }

    prepForScroll(myContext, scroll) {
        // SALT requires an alignItems to be directed to the scroll container.
        if (scroll && Object.keys(scroll).length) {
            this.promoteAlignItemsToContentContainer();
            this.promoteJustifyContentToContentContainer();

            // If not constrained by width/height, add a flex.
            if (myContext.flexDirection.isRow() && !this.style.width) {
                this.flexPolicy.setStandardGrow();
            } else if (myContext.flexDirection.isColumn() && !this.style.height) {
                this.flexPolicy.setStandardGrow();
            }
            // Scrolling containers need to shrink or there is nothing to scroll
            this.flexPolicy.setStandardShrink();
        }
    }

    promoteAlignItemsToContentContainer() {
        const ai = this.flexPolicy.alignItems;
        if (ai) {
            this.scrollContentStyle = this.scrollContentStyle || { scrollContentStyle: { } };
            this.scrollContentStyle.scrollContentStyle['align-items'] = ai;
            this.flexPolicy.setAlignItemsToDefault();
        }
    }

    promoteJustifyContentToContentContainer() {
        const jc = this.flexPolicy.justifyContent;
        if (jc) {
            this.scrollContentStyle = this.scrollContentStyle || { scrollContentStyle: { } };
            this.scrollContentStyle.scrollContentStyle['justify-content'] = jc;
            this.flexPolicy.setJustifyContentToDefault();
        }
    }
}
