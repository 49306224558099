import { LogLevel, Catavolt, FetchClient } from 'cv-dialog-sdk';
import { action, observable, toJS } from 'mobx';
import { BuildGlobals } from 'cv-react-core';
import { constants } from '..';

export default class SettingsStore {
    @observable settings = new Map();
    @observable FCMToken = null;

    tenantIdChanged = false;
    loadingFromCache = false;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @action setValue = (key, value, cacheData = true) => {
        // Check and see if we are getting a new tenant value. If so we need to restore cached instance.
        if (key === constants.session.TENANT_ID && value !== this.settings.get(constants.session.TENANT_ID)) {
            this.tenantIdChanged = true;
        }

        // Set the new value
        this.settings.set(key, value);

        if (cacheData) {
            this.saveSettingsCache();
            this.updateSettingsFromCache();
        }
    };

    getValue = (key) => {
        return this.settings.get(key);
    };

    clearAllSettings = () => {
        this.settings.clear();
        const { cacheStore } = this.rootStore;
        cacheStore.clearCacheType(cacheStore.cacheStores.SETTINGS);
    };

    getSanitizedSettings = () => {
        const result = toJS(this.settings);
        // Provide defaults where appropriate.
        if (result[constants.settings.LOGGING_LEVEL] === undefined) {
            result[constants.settings.LOGGING_LEVEL] = LogLevel.WARN;
        }
        if (result[constants.settings.SERVER_URL] === undefined) {
            result[constants.settings.SERVER_URL] = Catavolt.getDefaultServerUrl();
        }
        return result;
    };

    @action fromSanitizedSettings = (settings, cacheData = true) => {
        if (settings && settings[constants.settings.TENANT_ID]) {
            this.settings.clear();
            Object.keys(settings).forEach(key => {
                this.setValue(key, settings[key], false);
            });

            if (cacheData) {
                this.saveSettingsCache();
                this.updateSettingsFromCache();
            }

            // Look at the cv-dialog-sdk for default server if you can't determine
            // what the default server should be. You can also clear your cache and
            // reload the app to get the default server. We are not resetting the development
            // server settings on purpose to reduce the number of locations this value is stored.
            if (settings[constants.settings.USE_DEVELOPMENT_SERVER]
                && settings[constants.settings.SERVER_URL]
                && settings[constants.settings.SERVER_URL].length > 0) {
                const serverUrl = settings[constants.settings.SERVER_URL];
                if (BuildGlobals.isXHA() || !BuildGlobals.isOfflineCapable()) {
                    // xha custom server or no offline
                    Catavolt.initDialogApiWithServerAndClient(serverUrl, new FetchClient());
                } else {
                    // custom server with defaults (offline capable)
                    Catavolt.initDialogApi(serverUrl);
                }
            } else if (BuildGlobals.isXHA()) {
                // default server, xha
                Catavolt.initDialogApiWithClient(new FetchClient());
            } else {
                // defaults (offline capable)
                Catavolt.initDialogApi();
            }

            // Load theme for this tenant
            // const { themeStore } = this.rootStore;
            // themeStore.setTheme(themes.themeFactory(this.rootStore).getTheme(settings.tenantId));
        }
    };

    updateSettingsFromCache = () => {
        if (this.tenantIdChanged) {
            const { cacheStore } = this.rootStore;
            const cachedSettings = cacheStore.getCacheType(cacheStore.cacheStores.SETTINGS);
            // Add cached settings for new tenant
            this.settings.set(constants.storage.USER_CREDS, cachedSettings[constants.storage.USER_CREDS]);
            // this.settings.set(constants.storage.THEME, cachedSettings[constants.storage.THEME]);
        }
    }

    saveSettingsCache = () => {
        const { cacheStore } = this.rootStore;
        cacheStore.setCacheType(cacheStore.cacheStores.SETTINGS, this.getSanitizedSettings());
    }

    restoreFromCache = (cacheData) => {
        const { settings } = cacheData;
        this.fromSanitizedSettings(settings, false);
        this.loadingFromCache = false;
    }

    @action setFCMToken = (value) => {
        this.FCMToken = value;
    }

    FCMToken = () => {
        return this.FCMToken;
    }
}
