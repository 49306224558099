/*       */

import SimpleFloatAttribute from '../SimpleFloatAttribute';
import SimpleStringAttribute from '../SimpleStringAttribute';

import { ICON, IMAGE, PROPERTY, PROPERTY_DATA } from '../../exportClassConstants';
import ImageActionAttribute from '../ImageActionAttribute';
import AbstractLayoutFactory from './AbstractLayoutFactory';

class ImagePropLayoutFactory extends AbstractLayoutFactory {
    constructor() {
        super();
        const attributesAndAliases = [
            'allowImageAnnotate:imageAnnotate',
            'allowImageTakePhoto',
            'allowImageImport',
            'allowImageDelete',
            'allowImagePreviewOnTap:imagePreviewOnTap',
            'iconColor',
            // 'iconReverse',
            // 'iconReverseColor',
            'iconSize',
            // 'iconType',
            'imageBorderColor',
            'imageBorderRadius',
            'imageBorderWidth:imageBorderThickness',
            'imageResizeMode',
            'imageSize',
        ];
        this.init(attributesAndAliases);
    }

    initFactory() {
        super.initFactory();
        const { factory } = this;
        factory.allowImageAnnotate = (json, key, context) => {
            const { warnings } = context;
            return ImageActionAttribute.newAttribute(warnings, 'annotate', this.getAsString(json, key), [ PROPERTY, PROPERTY_DATA ], true);
        };
        factory.allowImageTakePhoto = (json, key, context) => {
            const { warnings } = context;
            return ImageActionAttribute.newAttribute(warnings, 'takePhoto', this.getAsString(json, key), [ PROPERTY, PROPERTY_DATA ], true);
        };
        factory.allowImageImport = (json, key, context) => {
            const { warnings } = context;
            return ImageActionAttribute.newAttribute(warnings, 'importImage', this.getAsString(json, key), [ PROPERTY, PROPERTY_DATA ], true);
        };
        factory.allowImageDelete = (json, key, context) => {
            const { warnings } = context;
            return ImageActionAttribute.newAttribute(warnings, 'deleteImage', this.getAsString(json, key), [ PROPERTY, PROPERTY_DATA ], true);
        };
        factory.allowImagePreviewOnTap = (json, key, context) => {
            const { warnings } = context;
            return ImageActionAttribute.newAttribute(warnings, 'display', this.getAsString(json, key), [ PROPERTY, PROPERTY_DATA ], true);
        };
        factory.iconColor = (json, key) => {
            return new SimpleStringAttribute('color', this.getAsString(json, key), [ ICON ], true);
        };
        // case 'iconReverse':
        //     result = new SimpleBooleanAttribute('reverse', this.getAsString(json, key), [ ICON ], true);
        //     break;
        // case 'iconReverseColor':
        //     result = new SimpleStringAttribute('reverse-color', this.getAsString(json, key), [ ICON ], true);
        //     break;
        factory.iconSize = (json, key) => {
            return new SimpleFloatAttribute('size', this.getAsString(json, key), [ ICON ], true);
        };
        // case 'iconType':
        //     result = new SimpleStringAttribute('type', this.getAsString(json, key), [ ICON ], true);
        //     break;
        factory.imageBorderColor = (json, key) => {
            return new SimpleStringAttribute('borderColor', this.getAsString(json, key), [ IMAGE ], true);
        };
        factory.imageBorderRadius = (json, key) => {
            return new SimpleFloatAttribute('borderRadius', this.getAsString(json, key), [ IMAGE ], true);
        };
        factory.imageBorderWidth = (json, key) => {
            return new SimpleFloatAttribute('borderWidth', this.getAsString(json, key), [ IMAGE ], true);
        };
        factory.imageResizeMode = (json, key) => {
            return new SimpleStringAttribute('resizeMode', this.getAsString(json, key), [ IMAGE ], true);
        };
        factory.imageSize = (json, key) => {
            return new SimpleStringAttribute('imageSize', this.getAsString(json, key), [ IMAGE ], true);
        };
    }
}
const imagePropLayoutFactory = new ImagePropLayoutFactory();
export default imagePropLayoutFactory;
