// Core component styles
const coreStyles = {
    container: {
    },

    icon: {
    },

    preview: {
        width: '20px',
        height: '20px',
    },
};

/**
 * Combine component context styles with core styles
 * @param {Object} contextStyles
 * @return {Object}
 */
const getStyles = (contextStyles) => {
    return {
        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        icon: {
            ...coreStyles.icon,
            ...contextStyles.icon,
        },

        preview: {
            ...coreStyles.preview,
            ...contextStyles.preview,
        },
    };
};

export default getStyles;
