import React from 'react';
import * as PropTypes from 'prop-types';
import MuiIconButton from '@material-ui/core/IconButton';

import makeStyles from '@material-ui/core/styles/makeStyles';
import getStyles from './IconButton.styles';

import { default as Icon, ICON_SIZE } from '../Icon/Icon';

/** Default ripple property. It is enabled by default. */
const ICON_BUTTON_DISABLE_RIPPLE = false;

/** Default state of Icon button. The icon button is enabled out of the box. */
const ICON_BUTTON_IS_DISABLED = false;

/**
 * Icon Button component :allows a user to view a non-image icon and have a click action associated with it.
 * @see https://material-ui.com/demos/buttons/#icon-buttons
 * @see https://material-ui.com/style/icons/
 */
const IconButton = (props) => {
    const {
        badgeText,
        contextStyles,
        disabled,
        disableRipple,
        iconName,
        iconSize,
        onClick,
        svgPath,
        testID,
        title,
    } = props;

    // Override core styles with context styles, separating MUI styles
    const {
        container: containerStyles,
        icon: iconStyles,
        badge: badgeStyles,
        ...muiStyles
    } = getStyles(contextStyles);

    // Create dynamic class names and injected DOM styles for MUI component
    // * makeStyles returns a function
    const styles = makeStyles(muiStyles)();

    // Generate container props
    const containerProps = {
        className: 'c-icon-button__container',
        style: containerStyles, // non-MUI styles
    };
    if (testID) { containerProps['data-test-id'] = `${testID}__icon-button__container`; }

    // Generate button props
    const buttonProps = {
        classes: {
            disabled: styles.disabled,
            root: styles.button, // MUI styles
        },
        className: 'c-icon-button__button',
        disabled,
        disableRipple,
        onClick: (event) => {
            onClick(event, props);
        },
    };
    if (testID) { buttonProps['data-test-id'] = `${testID}__icon-button__button`; }

    // Generate icon props
    const iconProps = {
        badgeText,
        className: 'c-icon-button__icon',
        contextStyles: {
            badge: badgeStyles, // non-MUI styles
            icon: iconStyles, // non-MUI styles
        },
        iconSize,
        iconName,
        svgPath,
        title,
    };
    if (testID) { iconProps.testID = `${testID}__icon-button__icon`; }

    return (
        <div { ...containerProps }>
            <MuiIconButton { ...buttonProps }>
                <Icon { ...iconProps } />
            </MuiIconButton>
        </div>
    );
};

IconButton.propTypes = {
    /** Badge content */
    badgeText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),

    /** Styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the container surrounding the icon button */
        container: PropTypes.object,

        /** Styles for a badge */
        badge: PropTypes.object,

        /** Styles for the button surrounding the icon */
        button: PropTypes.object,

        /** Styles for the icon */
        icon: PropTypes.object,
    }),

    /** For disabling the icon button click event. */
    disabled: PropTypes.bool,

    /** For disabling the ripple effect when the icon is clicked. */
    disableRipple: PropTypes.bool,

    /** Name of an icon to render. */
    iconName: PropTypes.string,

    /** Size of the icon to be rendered. */
    iconSize: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf([
            ICON_SIZE.DEFAULT,
            ICON_SIZE.INHERIT,
            ICON_SIZE.LARGE,
            ICON_SIZE.SMALL,
        ]),
    ]),

    /**
     * Called when the icon is clicked
     * @param {Object} event - The native click event
     * @param {Object} props - The component instance props
     */
    onClick: PropTypes.func.isRequired,

    /** Path for SVG Icon */
    svgPath: PropTypes.string,

    /** Id used for testing. */
    testID: PropTypes.string,

    /**
     * Description string.
     * Browsers usually render this as a tooltip on hover, whereas screen
     * readers will use it for aural feedback.
     * By default, this is set to the icon's name for accessibility.
     */
    title: PropTypes.string,
};

IconButton.defaultProps = {
    contextStyles: {},
    disabled: ICON_BUTTON_IS_DISABLED,
    disableRipple: ICON_BUTTON_DISABLE_RIPPLE,
    iconSize: ICON_SIZE.DEFAULT,
    testID: 'IconButton',
};

export default IconButton;
export {
    ICON_SIZE,
};
