import React from 'react';
import * as PropTypes from 'prop-types';
import MUICircularProgress from '@material-ui/core/CircularProgress';

import makeStyles from '@material-ui/core/styles/makeStyles';
import getStyles from './ActivityIndicator.styles';

/** Default color of Activity Indicator */
export const ACTIVITY_INDICATOR_COLOR = 'inherit';

/** Default thickness of Activity Indicator */
export const ACTIVITY_INDICATOR_THICKNESS = 3.6; // default thickness as specified by Material UI.

/** Default size of Activity Indicator */
export const ACTIVITY_INDICATOR_SIZE = 40; // default size as specified by Material UI.

/**
 * Activity Indicator component :indicates the user that a control level action is currently
 * being performed and that further actions with that control should wait until the initial action is completed.
 * @see https://material-ui.com/demos/progress/#circular-indeterminate
 * @see https://material-ui.com/api/circular-progress/
 */
const ActivityIndicator = (props) => {
    const {
        centered,
        contextStyles,
        size,
        testID,
        thickness,
    } = props;

    // Override core styles with context styles, separating MUI styles
    const {
        container: containerStyles,
        centered: centeredStyles,
        ...muiStyles
    } = getStyles(contextStyles);

    // Create dynamic class names and injected DOM styles for MUI component
    // * makeStyles returns a function
    const styles = makeStyles(muiStyles)();

    // Generate container props
    const containerProps = {
        className: 'c-activity-indicator__container',
        style: centered ? centeredStyles : containerStyles, // non-MUI styles
    };
    if (testID) { containerProps['data-test-id'] = `${testID}__activity-indicator__container`; }

    // Generate indicator props
    const indicatorProps = {
        classes: {
            root: styles.indicator, // MUI styles
        },
        className: 'c-activity-indicator__indicator',
        color: ACTIVITY_INDICATOR_COLOR,
        size,
        thickness,
    };
    if (testID) { indicatorProps['data-test-id'] = `${testID}__activity-indicator__indicator`; }

    return (
        <span { ...containerProps }>
            <MUICircularProgress { ...indicatorProps } />
        </span>
    );
};

ActivityIndicator.propTypes = {
    /** Styles for this component */
    centered: PropTypes.bool,

    /** Styles for this component */
    contextStyles: PropTypes.shape({
        /** Styling for the Activity Indicator container */
        container: PropTypes.object,

        /** Styles for the Activity Indicator */
        indicator: PropTypes.object,
    }),

    /** Width and Height Of the Activity Indicator in px */
    size: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),

    /** Id used for testing */
    testID: PropTypes.string,

    /** Thickness Of the Activity Indicator */
    thickness: PropTypes.number,
};

ActivityIndicator.defaultProps = {
    contextStyles: {},
    size: ACTIVITY_INDICATOR_SIZE,
    thickness: ACTIVITY_INDICATOR_THICKNESS,
};

export default ActivityIndicator;
