/**
 */
export class Download_Manager_FORM_REDIRECTION {
    static copyOfResponse() {
        return JSON.parse(JSON.stringify(this.RESPONSE));
    }
}
Download_Manager_FORM_REDIRECTION.PATH = 'tenants/${tenantId}/sessions/${sessionId}/workbenches/SDAWorkbench/actions/Briefcase';
Download_Manager_FORM_REDIRECTION.BODY = {};
Download_Manager_FORM_REDIRECTION.RESPONSE = {
    "dialogMode": "READ",
    "referringObject": {
        "actionId": "Briefcase",
        "type": "hxgn.api.dialog.ReferringWorkbench",
        "workbenchId": "SDAWorkbench"
    },
    "sessionId": "${sessionId}",
    "type": "hxgn.api.dialog.DialogRedirection",
    "dialogId": "8",
    "viewMode": "READ",
    "dialogClassName": "com.catavolt.app.extender.dialog.CatavoltSatelliteEntityEditorModel",
    "domainClassName": "cx.AAABACcSAAAAAF8B.com.catavolt.sql.hexagonsdaopAAABACcRAAAAAF7t.briefcase",
    "dialogType": "hxgn.api.dialog.EditorDialog",
    "recordId": "1",
    "dialogAlias": "Download_Manager_FORM",
    "tenantId": "${tenantId}",
    "refreshNeeded": false,
    "id": "8",
    "dialogName": "Download_Manager_FORM",
    "selectedViewId": "AAABACcXAAAAAF8e",
    "dialogDescription": "Briefcase: 1"
};
