/*       */

// Export classes are used to select batches of attributes for processing.
export const GENERAL = 'GENERAL';
export const GENERAL_FLEX = 'GENERAL:FLEX';
export const GENERAL_MARGIN = 'GENERAL:MARGIN';

export const VIEW_DETAIL = 'VIEW:DETAIL';
export const VIEW_LIST = 'VIEW:LIST';

export const ACTION = 'ACTION';
export const LIST = 'LIST';
export const LINE = 'LINE';
export const ICON = 'ICON';
export const IMAGE = 'IMAGE';
export const CHART = 'CHART';
export const BARCHART = 'BARCHART';
export const GAUGECHART = 'GAUGECHART';
export const SIGNATURE = 'SIGNATURE';

export const PROPERTY = 'PROPERTY';
export const PROPERTY_TEXT = 'PROPERTY:TEXT'; // Plist classifier
export const PROPERTY_LABEL = 'PROPERTY:LABEL'; // Plist classifier
export const PROPERTY_DATA = 'PROPERTY:DATA'; // Plist classifier
export const PROPERTY_EDIT = 'PROPERTY:EDIT'; // Plist classifier
export const PROPERTY_MARGIN = 'PROPERTY:MARGIN';
export const PROPERTY_WIDTH = 'PROPERTY:WIDTH';
