import React from 'react';
import PropTypes from 'prop-types';
import { pageController } from 'cv-react-core';
import componentFactory from './componentFactory';
import SaltComponent from './SaltComponent';
import SaltContext from './SaltContext';

const NFCSCAN_PROP_NAME = 'NFCSCAN_PROP';

export default class NFC extends SaltComponent {
    static propTypes = {
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        viewId: PropTypes.string,
    };

    render() {
        return React.createElement(componentFactory.getPlatformComponent('nfc'), { onNfcScanned: this.handleNfcScanned });
    }


    handleNfcScanned = (nfcData) => {
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore, onTransition, onError } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        const { uiStore } = saltStore;
        dialogStore.setPropertyValue(NFCSCAN_PROP_NAME, nfcData);
        pageController.performWrite({ dialogStore, uiStore, onTransition, onError });
    };
}

NFC.contextType = SaltContext;
