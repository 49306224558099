import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import getStyles from './ReactRouterBreadcrumbs.styles';

// This is a component for building breadcrumbs.
class ReactRouterBreadcrumbs extends Component {
    static propTypes = {
        /** Styles for this component */
        contextStyles: PropTypes.shape({
            /** Styles for the Container */
            container: PropTypes.object,

            /** Styles for the Breadcrumb link */
            link: PropTypes.object,

            /** Styles for the Breadcrumb wrapper */
            overlap: PropTypes.object,

            /** Styles for the Breadcrumb spacer */
            spacer: PropTypes.object,
        }),
        onClick: PropTypes.func,
        routes: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            description: PropTypes.string,
            url: PropTypes.string,
        })),
        spacer: PropTypes.string,
        history: PropTypes.object,
    };

    static defaultProps = {
        contextStyles: {},
        routes: [],
        spacer: '>',
    };

    render() {
        const {
            contextStyles,
            routes,
            spacer,
        } = this.props;
        const styles = getStyles(contextStyles);
        return (
            <div style={ styles.container }>
                <div style={ styles.overlap }>
                    {
                        routes.map((route, i) => (
                            <Fragment
                                key={ route.url }>
                                { i < routes.length - 1 &&
                                    <>
                                        <Link
                                            onClick={ (nativeEvent) => this.handleBreadCrumbClick(nativeEvent, route) }
                                            style={ styles.link }
                                            to={ route.url }>
                                            { route.description }
                                        </Link>
                                        <span style={ styles.spacer }>
                                            { spacer }
                                        </span>
                                    </>
                                }
                                {
                                    i === routes.length - 1 &&
                                        <span style={ {
                                            ...styles.link,
                                            cursor: 'auto',
                                        } }>
                                            { route.description }
                                        </span>
                                }

                            </Fragment>
                        ))
                    }
                </div>
            </div>
        );
    }

    /* eslint-disable class-methods-use-this */
    handleBreadCrumbClick(nativeEvent, route) {
        if (nativeEvent && nativeEvent.altKey) {
            nativeEvent.preventDefault();
            window.open(`${window.location.origin}${route.url || ''}`, '_blank');
        }
        else {
            const {
                onClick = () => {},
                history,
                routes,
            } = this.props;

            // We need to find the index of the route selected
            const foundRoute = routes.findIndex((routeData) => routeData.id === route.id);
            // Based on the selected route index, we want to pop the index location and add 1
            // to subtract from the number of routes to know where to move the history pointer to
            const goBackCount = (foundRoute + 1) - routes.length;
            history.go(goBackCount);
            onClick(nativeEvent, route);
        }
    }
}
export default withRouter(ReactRouterBreadcrumbs);