/*       */


/**
 * A ModelCache holds models to prevent unnecessary rebuilds of models.
 */
export const PAGES = 'pages';
export const GRIDS = 'grids';
export const CELLS = 'cells';
export default class ModelCache {
    cache = {};
    get(key, initializer) {
        if (!this.cache[key]) {
            this.cache[key] = initializer();
        }
        return this.cache[key];
    }
}
