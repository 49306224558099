/* @flow */
export const settings = {
    TENANT_ID: 'tenantId',
    USE_OAUTH: 'useOAuth',
    SERVER_URL: 'serverURL',
    USE_DEVELOPMENT_SERVER: 'useDevelopmentServer',
    USER_ID: 'userId',
    PASSWORD: 'password',
    SHOW_PASSWORD: 'showPassword',
    SAVE_PASSWORD: 'savePassword',
    PASSWORD_EXPIRATION: 'passwordExpiration',
    TENANT_CAPABILITIES: 'tenantCapabilities',
    NO_SAVE_REQUIRED_ACTIONS: 'noSaveRequiredActions',
};
