import React from 'react';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { AppBar } from 'cv-library-react-web';
import { constants, rootStore } from 'cv-react-core';
import ActionHeader from '../../base/ActionHeader/ActionHeader';
import ActionDropDown from '../../base/ActionDropDown/ActionDropDown';
import ActionButtonImage from '../../base/ActionButtonImage/ActionButtonImage';

const propTypes = {
    dialogId: PropTypes.string.isRequired,
    uiStore: PropTypes.object.isRequired,
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]),
};
// TODO: Need to refactor how we build these form level and panel level controls.
const buildToolBarGroups = (availableViews, applicationBarMenu, backgroundColor, onOpenView) => {
    const groups = [];
    let tool = null;
    let groupIndex = -1;
    let groupingItems = false;

    if (availableViews) {
        if (availableViews.viewDescriptors && availableViews.viewDescriptors.length > 1) {
            tool = {
                id: 'queryItems',
                items: availableViews.viewDescriptors,
                itemSelected: availableViews.selectedViewDescriptor,
                onAction: onOpenView,
                componentClass: ActionDropDown,
            };
            groups.push([ tool ]);
        }
    }

    // Put the second grouping of items, the App bar buttons
    if (applicationBarMenu) {
        applicationBarMenu.forEach((menu) => {
            const toolProps = {
                id: menu.id,
                tooltip: menu.menuText,
                imageSource: menu.icon,
                contextStyles: {
                    backgroundColor,
                },
            };
            tool = {
                ...toolProps,
                componentClass: ActionButtonImage,
            };

            if (groupingItems && groupIndex === groups.length - 1) {
                groups[groupIndex].push(tool);
            }
            else {
                groupIndex = groups.length;
                groupingItems = true;
                groups.push([ tool ]);
            }
        });
    }

    return groups;
};

/**
 * Form controls appear on the navigation bar.  This is currently only a menu.
 * This component listens to the uiStore for it's props, since it sits in the router packages built-in
 * navigation bar, and we have no way to update it's props directly when we need to.
 */
const FormToolBar = observer((props) => {
    const {
        dialogId,
        uiStore,
        title,
        closeIcon,
        onMenuAction,
        onGroupAction,
        onOpenView,
    } = props;
    const formControlsProps = toJS(uiStore.getValueForUIObject(dialogId, constants.ui.FORM_CONTROLS_PROPS));
    if (formControlsProps) {
        const {
            menuItems,
            availableViews,
            applicationBarMenu,
        } = formControlsProps;
        const appBarMenuItems = menuItems.map((menuItem) => {
            return {
                label: menuItem.menuText,
                ...menuItem,
            };
        });
        const { themeStore } = rootStore;
        const theme = themeStore.getSanitizedTheme();
        const { fontDefault: fontColor } = theme.colors;
        const toolGroups = buildToolBarGroups(availableViews, applicationBarMenu, theme.formActionBarBackgroundColor, onOpenView);
        const actionHeaderProps = {
            groups: toolGroups,
            onGroupAction: (menuItem, nativeEvent) => {
                const modifiers = (nativeEvent && nativeEvent.altKey) ? { [constants.transitionModifiers.OPEN_IN_TAB]: true } : undefined;
                const menuEvent = {
                    ...menuItem,
                    modifiers,
                };
                onGroupAction(menuEvent);
            },
        };
        const toolBar = <ActionHeader { ...actionHeaderProps } />;
        return (
            <AppBar
                title={ title }
                alignMenu="right"
                menuItems={ appBarMenuItems }
                onMenuItemClick={ (menuItem, nativeEvent) => {
                    const modifiers = (nativeEvent && nativeEvent.altKey) ? { [constants.transitionModifiers.OPEN_IN_TAB]: true } : undefined;
                    const menuEvent = {
                        ...menuItem,
                        modifiers,
                    };
                    onMenuAction(menuEvent);
                } }
                toolbar={ toolBar }
                closeIcon={ closeIcon }
                contextStyles={ {
                    bar: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    },
                    toolbar: {
                        justifyContent: 'flex-start',
                        minHeight: '32px',
                        maxHeight: '32px',
                        marginTop: '18px',
                    },
                    menuIcon: {
                        color: fontColor,
                    },
                    menuButton: {
                        padding: '5px',
                        flexShrink: 0,
                        borderColor: '#E0E0E0',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        borderRadius: '4px',
                    },
                    menuItem: {
                        backgroundColor: theme.drawerMenuItemBackgroundColor,
                        color: theme.drawerMenuItemTextColor,
                        fontSize: theme.drawerMenuItemTextSize,
                        fontWeight: theme.drawerMenuItemFontWeight,
                        borderColor: theme.drawerMenuItemBorderColor,
                        borderWidth: theme.drawerMenuBorderWidth,
                    },
                    menuItemFocused: {
                        backgroundColor: theme.fonts.menu.color,
                        color: theme.menuBackgroundColor,
                    },
                    menuItemSelected: {
                        color: theme.drawerMenuItemActiveTextColor,
                        backgroundColor: `${theme.drawerMenuItemActiveBackgroundColor} !important`,
                    },
                    titleContainer: {
                        flexGrow: 1,
                    },
                    title: {
                        color: fontColor,
                    },
                }
                } />
        );
    }
    return null;
});


FormToolBar.propTypes = propTypes;

export default FormToolBar;