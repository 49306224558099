import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { TypeNames } from 'cv-dialog-sdk';
import { utilities } from '../utilities';
import uiHelper from '../utilities/uiHelper';
import componentFactory from './componentFactory';
import SaltComponent from './SaltComponent';
import SaltContext from './SaltContext';
import pageController from '../controllers/pageController';
import FormLayout from './form/FormLayout';
import BuildGlobals from '../provider/BuildGlobals';
import QuickSearch from './QuickSearch';

/**
 * Note! Panel must directly wrap a View (i.e. children[0] must be a View)
 */
@observer
export default class Panel extends SaltComponent {
    static propTypes = {
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        showTitle: PropTypes.bool,
        showMenu: PropTypes.bool,
        showTools: PropTypes.bool,
        showAvailableViews: PropTypes.bool,
        viewId: PropTypes.string,
        // this will be undefined unless this panel is using a built-in layout component (wrapped by it)
        // meaning panels used directly won't have expand collapse functionality
        formViewMode: PropTypes.string,
        hideDefaultPanelHeader: PropTypes.bool,
    };

    static defaultProps = {
        showTitle: true,
        showMenu: true,
        showAvailableViews: true,
        showTools: true,
        hideDefaultPanelHeader: false,
    };

    render() {
        // panel may have to retrieve it's view-id from the single child view that it is wrapping
        const viewId = this.resolveViewId();
        const { saltStore, onError } = this.context;
        const { uiStore } = saltStore;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        if (dialogStore) {
            const resolvedProps = this.resolveProperties();
            const { formViewMode } = resolvedProps;
            const { dialog } = dialogStore;
            const { view } = dialog;
            const { type: viewType, menu: topMenu, gmlMarkup, validMarkup } = view;
            const { showTitle, showAvailableViews, hideDefaultPanelHeader } = this.props;
            const menu = topMenu ? uiHelper.asGenericMenu(dialog.id, topMenu.findContextMenu()) : [];
            const applicationBarMenu = topMenu ? uiHelper.asGenericMenu(dialog.id, topMenu.findActionBarMenu()) : [];
            const { availableViews, selectedViewId } = dialog;
            // let the implementing panel know if we are in expanded mode
            const isExpanded = formViewMode && formViewMode === FormLayout.FORM_VIEW_MODE.EXPANDED_VIEW;
            // let the implementing panel know if we're layout managed with multiple views
            const showFormTools = formViewMode && (formViewMode === FormLayout.FORM_VIEW_MODE.MULTI_VIEW || formViewMode === FormLayout.FORM_VIEW_MODE.EXPANDED_VIEW);

            const selectedViewDescriptor = availableViews ? availableViews.find((f) => (f.id === selectedViewId)) : {};
            const searchProps = { viewId, onError };
            const { view: formView } = dialogStore.getRootDialogStore().dialog;

            const quickSearch = BuildGlobals.isXHA() && viewType === TypeNames.ListTypeName && !gmlMarkup && !formView.isMaintainableQuery && <QuickSearch { ...searchProps } />;
            let markupTitle = viewType === TypeNames.MapTypeName || hideDefaultPanelHeader;

            // If there is a valid GML/Paper markup, set markupTitle to false so that it renders default non SALT panel header.
            if (validMarkup) {
                markupTitle = false;
            }
            const props = {
                ...resolvedProps,
                menu,
                applicationBarMenu,
                availableViews: showAvailableViews ? { viewDescriptors: availableViews, selectedViewDescriptor, onOpenView: this.handleOnOpenView } : {},
                // xMenu should be used for client side menu operations that are not modeled in extender
                xMenu: this.getExtendedMenu(viewType, dialogStore, uiStore),
                isExpanded,
                showFormTools,
                title: dialog.paneTitle,
                onMenuAction: this.handleMenuAction,
                panelId: dialog.id,
                viewType,
                quickSearch,
                showTitle,
                markupTitle,
            };
            const newContext = {
                ...this.context,
                viewId,
            };
            return (
                <SaltContext.Provider
                    value={ newContext }>
                    { React.createElement(componentFactory.getPlatformComponent('panel'), props) }
                </SaltContext.Provider>
            );
        }
        return null;
    }

    resolveViewId() {
        const { viewId, children } = this.props;
        if (!viewId && children && children.length > 0) {
            if (children[0].props.id) {
                return children[0].props.id;
            }
        }
        return this.getViewId(this.props.viewId);
    }

    handleMenuAction = (menuEvent) => {
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId); // RB/TJ - There is a dialogId in the event
        const { uiStore } = saltStore;
        const { onTransition, onError } = this.context;
        const { id, modifiers, ...rest } = menuEvent;
        pageController.performActionWithConfirmation({
            actionId: id,
            selectedArray: utilities.listHelper.getSelectedAsArray(uiStore, dialogStore),
            dialogStore,
            uiStore,
            onTransition,
            onError,
            actionParams: rest,
            modifiers,
        });
    };

    handleOnOpenView = (viewDescriptor) => {
        const viewId = this.resolveViewId();
        const { saltStore, onTransition, onError } = this.context;
        const { uiStore } = saltStore;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        const params = { viewDescriptor, dialogStore, uiStore, onTransition, onError };
        return pageController.openView(params);
    }

    getExtendedMenu(viewType, dialogStore, uiStore) {
        // build extended menu objects with the necessary pieces for each
        return pageController.getAvailableExtendedActions(viewType, dialogStore, uiStore);
    }
}

Panel.contextType = SaltContext;
