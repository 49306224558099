import React from 'react';
import { BaseComponentBuilder } from 'cv-react-core';
import { DateValue, TimeValue, Catavolt } from 'cv-dialog-sdk';
import DateTimePicker, { DATE_TYPES, DATE_VIEWS, DATETIME_PICKER_VARIANTS } from '../../components/base/DateTimePicker';


/**
 * @deprecated A builder for creating date/time picker components
 */
class DateTimeComponentBuilder extends BaseComponentBuilder {
    /**
     * COMPONENT BASED PROPERTIES
     */

    /** Begin Context Styles */
    setInputContainerStyles(inputContainerStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            inputContainer: inputContainerStyles,
        });
    }
    getInputContainerStyles() {
        const { inputContainer } = this.getContextStyles();
        return inputContainer || {};
    }

    setInputTextStyles(inputTextStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            inputText: inputTextStyles,
        });
    }
    getInputTextStyles() {
        const { inputText = {}, input = {} } = this.getContextStyles();
        return { ...inputText, ...input };
    }

    setIconStyles(iconStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            icon: iconStyles,
        });
    }
    getIconStyles() {
        const { icon } = this.getContextStyles();
        return icon || {};
    }

    setIconContainerStyles(iconContainerStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            iconContainer: iconContainerStyles,
        });
    }
    getIconContainerStyles() {
        const { iconContainer } = this.getContextStyles();
        return iconContainer || {};
    }

    setDialogContainerStyles(dialogContainerStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            dialogContainer: dialogContainerStyles,
        });
    }
    getDialogContainerStyles() {
        const { dialogContainer } = this.getContextStyles();
        return dialogContainer || {};
    }

    setDialogContentContainerStyles(dialogContentContainerStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            dialogContentContainer: dialogContentContainerStyles,
        });
    }
    getDialogContentContainerStyles() {
        const { dialogContentContainer } = this.getContextStyles();
        return dialogContentContainer || {};
    }

    setPickerToolbarStyles(pickerToolbarStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            pickerToolbar: pickerToolbarStyles,
        });
    }
    getPickerToolbarStyles() {
        const { pickerToolbar } = this.getContextStyles();
        return pickerToolbar || {};
    }

    setPickerToolbarButtonStyles(pickerToolbarButtonStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            pickerToolbarButton: pickerToolbarButtonStyles,
        });
    }
    getPickerToolbarButtonStyles() {
        const { pickerToolbarButton } = this.getContextStyles();
        return pickerToolbarButton || {};
    }

    setPickerToolbarButtonLabelsStyles(pickerToolbarButtonLabelsStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            pickerToolbarButtonLabels: pickerToolbarButtonLabelsStyles,
        });
    }
    getPickerToolbarButtonLabelsStyles() {
        const { pickerToolbarButtonLabels } = this.getContextStyles();
        return pickerToolbarButtonLabels || {};
    }

    setPickerDateTimeToolbarSeparatorStyles(pickerDateTimeToolbarSeparatorStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            pickerDateTimeToolbarSeparator: pickerDateTimeToolbarSeparatorStyles,
        });
    }
    getPickerDateTimeToolbarSeparatorStyles() {
        const { pickerDateTimeToolbarSeparator } = this.getContextStyles();
        return pickerDateTimeToolbarSeparator || {};
    }

    setPickerTabsStyles(pickerTabsStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            pickerTabs: pickerTabsStyles,
        });
    }
    getPickerTabsStyles() {
        const { pickerTabs } = this.getContextStyles();
        return pickerTabs || {};
    }

    setPickerTabButtonsStyles(pickerTabButtonsStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            pickerTabButtons: pickerTabButtonsStyles,
        });
    }
    getPickerTabButtonsStyles() {
        const { pickerTabButtons } = this.getContextStyles();
        return pickerTabButtons || {};
    }

    setPickerTabIndicatorStyles(pickerTabIndicatorStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            pickerTabIndicator: pickerTabIndicatorStyles,
        });
    }
    getPickerTabIndicatorStyles() {
        const { pickerTabIndicator } = this.getContextStyles();
        return pickerTabIndicator || {};
    }

    setPickerViewStyles(pickerViewStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            pickerView: pickerViewStyles,
        });
    }
    getPickerViewStyles() {
        const { pickerView } = this.getContextStyles();
        return pickerView || {};
    }

    setPickerCalendarSwitchHeaderStyles(pickerCalendarSwitchHeaderStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            pickerCalendarSwitchHeader: pickerCalendarSwitchHeaderStyles,
        });
    }
    getPickerCalendarSwitchHeaderStyles() {
        const { pickerCalendarSwitchHeader } = this.getContextStyles();
        return pickerCalendarSwitchHeader || {};
    }

    setPickerCalendarButtonStyles(pickerCalendarButtonStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            pickerCalendarButton: pickerCalendarButtonStyles,
        });
    }
    getPickerCalendarButtonStyles() {
        const { pickerCalendarButton } = this.getContextStyles();
        return pickerCalendarButton || {};
    }

    setPickerCalendarButtonIconStyles(pickerCalendarButtonIconStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            pickerCalendarButtonIcon: pickerCalendarButtonIconStyles,
        });
    }
    getPickerCalendarButtonIconStyles() {
        const { pickerCalendarButtonIcon } = this.getContextStyles();
        return pickerCalendarButtonIcon || {};
    }

    setPickerCalendarHeaderLabelStyles(pickerCalendarHeaderLabelStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            pickerCalendarHeaderLabel: pickerCalendarHeaderLabelStyles,
        });
    }
    getPickerCalendarHeaderLabelStyles() {
        const { pickerCalendarHeaderLabel } = this.getContextStyles();
        return pickerCalendarHeaderLabel || {};
    }

    setPickerCalendarDaysHeaderStyles(pickerCalendarDaysHeaderStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            pickerCalendarDaysHeader: pickerCalendarDaysHeaderStyles,
        });
    }
    getPickerCalendarDaysHeaderStyles() {
        const { pickerCalendarDaysHeader } = this.getContextStyles();
        return pickerCalendarDaysHeader || {};
    }

    setPickerCalendarDayLabelStyles(pickerCalendarDayLabelStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            pickerCalendarDayLabel: pickerCalendarDayLabelStyles,
        });
    }
    getPickerCalendarDayLabelStyles() {
        const { pickerCalendarDayLabel } = this.getContextStyles();
        return pickerCalendarDayLabel || {};
    }

    setPickerCalendarContainerStyles(pickerCalendarContainerStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            pickerCalendarContainer: pickerCalendarContainerStyles,
        });
    }
    getPickerCalendarContainerStyles() {
        const { pickerCalendarContainer } = this.getContextStyles();
        return pickerCalendarContainer || {};
    }

    setPickerCalendarWeekStyles(pickerCalendarWeekStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            pickerCalendarWeek: pickerCalendarWeekStyles,
        });
    }
    getPickerCalendarWeekStyles() {
        const { pickerCalendarWeek } = this.getContextStyles();
        return pickerCalendarWeek || {};
    }

    setPickerDayStyles(pickerDayStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            pickerDay: pickerDayStyles,
        });
    }
    getPickerDayStyles() {
        const { pickerDay } = this.getContextStyles();
        return pickerDay || {};
    }

    setPickerDayCurrentStyles(pickerDayCurrentStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            pickerDayCurrent: pickerDayCurrentStyles,
        });
    }
    getPickerDayCurrentStyles() {
        const { pickerDayCurrent } = this.getContextStyles();
        return pickerDayCurrent || {};
    }

    setPickerDaySelectedStyles(pickerDaySelectedStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            pickerDaySelected: pickerDaySelectedStyles,
        });
    }
    getPickerDaySelectedStyles() {
        const { pickerDaySelected } = this.getContextStyles();
        return pickerDaySelected || {};
    }

    setPickerDayDisabledStyles(pickerDayDisabledStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            pickerDayDisabled: pickerDayDisabledStyles,
        });
    }
    getPickerDayDisabledStyles() {
        const { pickerDayDisabled } = this.getContextStyles();
        return pickerDayDisabled || {};
    }

    setPickerDayHiddenStyles(pickerDayHiddenStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            pickerDayHidden: pickerDayHiddenStyles,
        });
    }
    getPickerDayHiddenStyles() {
        const { pickerDayHidden } = this.getContextStyles();
        return pickerDayHidden || {};
    }

    setPickerYearContainerStyles(pickerYearContainerStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            pickerYearContainer: pickerYearContainerStyles,
        });
    }
    getPickerYearContainerStyles() {
        const { pickerYearContainer } = this.getContextStyles();
        return pickerYearContainer || {};
    }

    setPickerYearStyles(pickerYearStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            pickerYear: pickerYearStyles,
        });
    }
    getPickerYearStyles() {
        const { pickerYear } = this.getContextStyles();
        return pickerYear || {};
    }

    setPickerYearSelectedStyles(pickerYearSelectedStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            pickerYearSelected: pickerYearSelectedStyles,
        });
    }
    getPickerYearSelectedStyles() {
        const { pickerYearSelected } = this.getContextStyles();
        return pickerYearSelected || {};
    }

    setPickerYearDisabledStyles(pickerYearDisabledStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            pickerYearDisabled: pickerYearDisabledStyles,
        });
    }
    getPickerYearDisabledStyles() {
        const { pickerYearDisabled } = this.getContextStyles();
        return pickerYearDisabled || {};
    }

    setPickerClockContainerStyles(pickerClockContainerStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            pickerClockContainer: pickerClockContainerStyles,
        });
    }
    getPickerClockContainerStyles() {
        const { pickerClockContainer } = this.getContextStyles();
        return pickerClockContainer || {};
    }

    setPickerClockStyles(pickerClockStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            pickerClock: pickerClockStyles,
        });
    }
    getPickerClockStyles() {
        const { pickerClock } = this.getContextStyles();
        return pickerClock || {};
    }

    setPickerClockPinStyles(pickerClockPinStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            pickerClockPin: pickerClockPinStyles,
        });
    }
    getPickerClockPinStyles() {
        const { pickerClockPin } = this.getContextStyles();
        return pickerClockPin || {};
    }

    setPickerClockNumberStyles(pickerClockNumberStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            pickerClockNumber: pickerClockNumberStyles,
        });
    }
    getPickerClockNumberStyles() {
        const { pickerClockNumber } = this.getContextStyles();
        return pickerClockNumber || {};
    }

    setPickerClockNumberSelectedStyles(pickerClockNumberSelectedStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            pickerClockNumberSelected: pickerClockNumberSelectedStyles,
        });
    }
    getPickerClockNumberSelectedStyles() {
        const { pickerClockNumberSelected } = this.getContextStyles();
        return pickerClockNumberSelected || {};
    }

    setPickerClockPointerStyles(pickerClockPointerStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            pickerClockPointer: pickerClockPointerStyles,
        });
    }
    getPickerClockPointerStyles() {
        const { pickerClockPointer } = this.getContextStyles();
        return pickerClockPointer || {};
    }

    setPickerClockPointerThumbStyles(pickerClockPointerThumbStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            pickerClockPointerThumb: pickerClockPointerThumbStyles,
        });
    }
    getPickerClockPointerThumbStyles() {
        const { pickerClockPointerThumb } = this.getContextStyles();
        return pickerClockPointerThumb || {};
    }

    setPickerClockPointerNoPointStyles(pickerClockPointerNoPointStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            pickerClockPointerNoPoint: pickerClockPointerNoPointStyles,
        });
    }
    getPickerClockPointerNoPointStyles() {
        const { pickerClockPointerNoPoint } = this.getContextStyles();
        return pickerClockPointerNoPoint || {};
    }

    setDialogActionsContainerStyles(dialogActionsContainerStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            dialogActionsContainer: dialogActionsContainerStyles,
        });
    }
    getDialogActionsContainerStyles() {
        const { dialogActionsContainer } = this.getContextStyles();
        return dialogActionsContainer || {};
    }

    setDialogActionsButtonsStyles(dialogActionsButtonsStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            dialogActionsButtons: dialogActionsButtonsStyles,
        });
    }
    getDialogActionsButtonsStyles() {
        const { dialogActionsButtons } = this.getContextStyles();
        return dialogActionsButtons || {};
    }

    setDialogActionsLabelsStyles(dialogActionsLabelsStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            dialogActionsLabels: dialogActionsLabelsStyles,
        });
    }
    getDialogActionsLabelsStyles() {
        const { dialogActionsLabels } = this.getContextStyles();
        return dialogActionsLabels || {};
    }
    /** End Context Styles */


    /** Begin Props */
    setAutoFocus(autoFocus = true) {
        this.props.autoFocus = autoFocus;
        return this;
    }
    getAutoFocus() {
        return this.props.autoFocus;
    }

    setAutoOk(autoOk = true) {
        this.props.autoOk = autoOk;
        return this;
    }
    getAutoOk() {
        return this.props.autoOk;
    }

    setDisabled(disabled = true) {
        this.props.disabled = disabled;
        return this;
    }
    getDisabled() {
        return this.props.disabled;
    }

    setFormat(format) {
        this.props.format = format;
        return this;
    }
    getFormat() {
        return this.props.format;
    }

    setHideHelperText(hideHelperText = true) {
        this.props.hideHelperText = hideHelperText;
        return this;
    }
    getHideHelperText() {
        return this.props.hideHelperText;
    }

    setHideTabs(hideTabs = true) {
        this.props.hideTabs = hideTabs;
        return this;
    }
    getHideTabs() {
        return this.props.hideTabs;
    }

    setHideToolbar(hideToolbar = true) {
        this.props.hideToolbar = hideToolbar;
        return this;
    }
    getHideToolbar() {
        return this.props.hideToolbar;
    }

    setIs24Hour(is24Hour = true) {
        this.props.is24Hour = is24Hour;
        return this;
    }
    getIs24Hour() {
        return this.props.is24Hour;
    }

    setDateMode() {
        this.props.mode = DATE_TYPES.DATE;
        return this;
    }
    setTimeMode() {
        this.props.mode = DATE_TYPES.TIME;
        return this;
    }
    setDateTimeMode() {
        this.props.mode = DATE_TYPES.DATETIME;
        return this;
    }
    getMode() {
        return this.props.mode;
    }

    setOnChange(onChange) {
        if (typeof onChange === 'function') {
            this.props.onChange = onChange;
        }
        return this;
    }
    getOnChange() {
        return this.props.onChange;
    }

    setOnClose(onClose) {
        if (typeof onClose === 'function') {
            this.props.onClose = onClose;
        }
        return this;
    }
    getOnClose() {
        return this.props.onClose;
    }

    setSelectedDate(selectedDate) {
        if (selectedDate instanceof Date) {
            this.props.selectedDate = selectedDate;
        }
        return this;
    }
    getSelectedDate() {
        return this.props.selectedDate;
    }

    setViews(views) {
        this.props.views = views;
        return this;
    }
    getViews() {
        return this.props.views;
    }

    setVariant(variant) {
        this.props.variant = variant;
        return this;
    }
    getVariant() {
        return this.props.variant;
    }

    /** End Props */


    /**
     * PROCESS AND UPDATE COMPONENT PROPERTIES
     */

    processDisplay() {
        const propDef = this.getPropDef();
        const property = this.getProperty();
        const stagedValue = this.getStagedValue();

        // Initialize component value
        let dateValue = null;

        // Staging values are only populated when the user interacts with a write-enabled control
        // If user has changed value (not null/undefined)
        if (stagedValue && stagedValue.value instanceof Date) {
            // Persist the user changed value on render/refresh
            dateValue = stagedValue.value;
        }

        // Value has not been changed, use value from data on render/refresh
        else {
            // Process date for display
            dateValue = BaseComponentBuilder.uiHelper.formatPropertyForWrite(property, propDef) || '';

            // If date info is an Object of TimeValue
            if (propDef.isTimeType && property && property.value instanceof TimeValue) {
                // Convert to proper date string
                dateValue = property.value.toDateValue();
            }

            // If date info is an Object of DateValue
            if (propDef.isDateType && property && property.value instanceof DateValue) {
                // Convert to proper date string
                dateValue = property.value.dateObj;
            }
        }

        this.setSelectedDate(dateValue ? new Date(dateValue) : null);
    }

    processStyle() {
        const categorizedStyles = BaseComponentBuilder.styleHelper.categorizeWebStyles(this.getStyle());

        this.setContainerStyles({
            ...categorizedStyles.container,
            ...this.getContainerStyles(),
        });
        this.setInputTextStyles({
            ...categorizedStyles.text,
            ...this.getInputTextStyles(),
        });
        this.setIconStyles({
            ...categorizedStyles.text,
            fontSize: '24px',
            ...this.getIconStyles(),
        });
    }

    processChangeHandler() {
        const onValueChangeHandler = this.getOnValueChangeHandler();
        const isReadMode = this.getReadMode();
        const property = this.getProperty();
        const { name: propertyName = '' } = property;

        if (!isReadMode && !!propertyName && !!onValueChangeHandler) {
            this.setOnChange((propertyValue) => {
                // eslint-disable-next-line no-restricted-globals
                if (propertyValue && isNaN(propertyValue.getTime())) {
                    // ignore invalid dates
                }
                else {
                    onValueChangeHandler(propertyName, propertyValue);
                }
            });
        }
    }

    processDateTimeProps() {
        const isFocused = this.getFocusedState();
        const propDef = this.getPropDef();
        const { getDateFormatString } = propDef;
        const dateFormatString = getDateFormatString(Catavolt.locale.langCountryString);
        const is24Hour = BaseComponentBuilder.uiHelper.getIs24HourFormat();
        /**
         * We have default auto validation with the error message provided by the date picker.
         * So when we enter some wrong format it will display an error below the DateTime field.
         * But when coming to paper XML, we don't need to have internal validation.
         * This will disturb the UI, so to resolve the same we need to send one additional property for XML based form. Added below 2 lines for the same
         */
        const containerStyles = this.getContainerStyles();
        if (containerStyles.paperId) { this.setHideHelperText(); }

        if (isFocused) { this.setAutoFocus(true); }
        if (propDef.isDateType) {
            this.setDateMode();
            this.setFormat(dateFormatString); // Needs to be NLS aware
            this.setViews([ DATE_VIEWS.DATE ]);
            this.setVariant(DATETIME_PICKER_VARIANTS.INLINE);
        }
        if (propDef.isTimeType) {
            this.setTimeMode();
            this.setFormat(dateFormatString); // Needs to be NLS aware
            this.setViews([
                DATE_VIEWS.HOURS,
                DATE_VIEWS.MINUTES,
            ]);
            this.setVariant(DATETIME_PICKER_VARIANTS.INLINE);
        }
        if (propDef.isDateTimeType) {
            this.setDateTimeMode();
            this.setFormat(dateFormatString); // Needs to be NLS aware
            this.setViews([
                DATE_VIEWS.DATE,
                DATE_VIEWS.HOURS,
                DATE_VIEWS.MINUTES,
            ]);
            this.setVariant(DATETIME_PICKER_VARIANTS.INLINE);
            this.setHideTabs(true);
        }
        this.setIs24Hour(is24Hour);
        // since we are using picker variant as INLINE, the autoOK should be set as TRUE as INLINE variant doesnt show set/clear/cancel Buttons.
        this.setAutoOk(true);
    }

    buildProps() {
        // Update the display text
        this.processDisplay();

        // Update the date/time style
        this.processStyle();

        // Update change handler to support property / value data
        this.processChangeHandler();

        // Set date/time properties based on dialog data
        this.processDateTimeProps();
    }


    /**
     * BUILD AND RETURN COMPONENT AS REACT ELEMENT
     */

    build() {
        // Process and construct final props
        this.buildProps();

        // Build and return component as React element
        return <DateTimePicker { ...this.getProps() } />;
    }
}

export default DateTimeComponentBuilder;
