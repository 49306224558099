import React from 'react';

const SaltContext = React.createContext({
    viewId: null,
    saltStore: null,
    scopeManager: null,
    onTransition: null,
    onError: null,
    parentNode: null,
});

export default SaltContext;
