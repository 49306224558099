import { rootStore } from 'cv-react-core';

const getTheme = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    // Populate the component parts with theme styles
    return {
        bar: {
            backgroundColor: theme.statusLoadingIndicatorColor,
        },
        root: {
        },
    };
};

export default getTheme;
