import PropTypes from 'prop-types';
import React from 'react';

import componentFactory from './componentFactory';
import engineConstants from './engineConstants';
import RefUtil from './ref/RefUtil';
import SaltComponent from './SaltComponent';
import SaltContext from './SaltContext';
import DetailsDialogStore from '../stores/DetailsDialogStore';

/* eslint-disable react/prefer-stateless-function */
export default class Image extends SaltComponent {
    static propTypes = {
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        assert: PropTypes.shape({
            refName: PropTypes.string,
            expr: PropTypes.string,
        }),
        source: PropTypes.string.isRequired,
        resizeMode: PropTypes.string,
        capInsets: PropTypes.shape({
            top: PropTypes.number,
            right: PropTypes.number,
            bottom: PropTypes.number,
            left: PropTypes.number,
        }),
        iconProps: PropTypes.shape({
            size: PropTypes.number,
            color: PropTypes.string,
        }),
        viewId: PropTypes.string,
    };

    static typeName = engineConstants.component.name.image;

    static replaceResUrl(url, saltStore, dialogStore) {
        const { sessionStore } = saltStore;
        let replaceUrl = url;
        if (url.indexOf('${') > -1 && dialogStore instanceof DetailsDialogStore) {
            // Extract propertyName from URL. The name of the image is available in Property.
            const propertyName = url.split('${')[1].split('}')[0];
            // Use this propertyName to get the actual value for the image
            replaceUrl = `res:${dialogStore.getProperty(propertyName)?.value}`;
        }
        replaceUrl = replaceUrl ? replaceUrl.replace('res:', sessionStore.getGMLAssetsURL().trim()) : '';
        return replaceUrl;
    }

    render() {
        const { assert } = this.props;
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore, scopeManager } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        if (this.determineVisibility(assert, dialogStore, scopeManager, saltStore)) {
            const resolvedProps = this.resolveProperties();
            return this.wrapWithContext(
                Image.typeName,
                resolvedProps,
                React.createElement(componentFactory.getPlatformComponent('image'), resolvedProps),
            );
        }
        return null;
    }

    // override the base to add res url
    getPropsWithSubstitution() {
        const { source, iconProps } = this.props;
        const { saltStore, scopeManager } = this.context;
        const viewId = this.getViewId(this.props.viewId);
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        const newProps = {
            ...super.getPropsWithSubstitution(),
            ...RefUtil.substituteValues({ iconProps, source }, scopeManager, dialogStore, saltStore),
        };
        return { ...newProps, source: Image.replaceResUrl(newProps.source, saltStore, dialogStore) };
    }
}

Image.contextType = SaltContext;
