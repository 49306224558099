import React, { forwardRef } from 'react';
import * as PropTypes from 'prop-types';
import MuiTableCell from '@material-ui/core/TableCell';
import makeStyles from '@material-ui/core/styles/makeStyles';

import getStyles from './CalendarCell.styles';

const CalendarCell = forwardRef((props, ref) => {
    const {
        children,
        contextStyles,
        onCellContextMenu,
        onCellClick,
        onCellDoubleClick,
        testID,
        ...rest
    } = props;

    // Override core styles with context styles, separating MUI styles
    const {
        ...muiStyles
    } = getStyles(contextStyles);

    // Create dynamic class names and injected DOM styles for MUI component
    // * makeStyles returns a function
    const styles = makeStyles(muiStyles)();

    return (
        <MuiTableCell
            classes={ { root: styles.calendarCellStyles } }
            className="c-calendar-cell__container"
            data-test-id={ `${testID}calendar-cell__container` }
            onContextMenu={ onCellContextMenu }
            onClick={ onCellClick }
            onDoubleClick={ onCellDoubleClick }
            ref={ ref }
            { ...rest }>
            { children }
        </MuiTableCell>
    );
});

CalendarCell.propTypes = {
    /** Content to be displayed inside the CalendarCell */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,

    /** Extended styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the container surrounding calendar cell content */
        calendarCellStyles: PropTypes.object,
    }),

    /** Handler method for right click/context menu event */
    onCellContextMenu: PropTypes.func,

    /** Event triggered when a cell is clicked in month view */
    onCellClick: PropTypes.func,

    /** Event triggered when a cell is double clicked in month view */
    onCellDoubleClick: PropTypes.func,

    /** Id used for testing */
    testID: PropTypes.string,
};

CalendarCell.defaultProps = {
    contextStyles: {},
    testID: 'CalendarCell',
};

CalendarCell.displayName = 'CalendarCell';

export default CalendarCell;
