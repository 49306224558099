/*       */
import StrictMode from './layoutAttribute/general/StrictMode';


/**
 * A WarningNotifier is a global object that holds warnings to be displayed as a notification.
 */
export default class WarningNotifier {
    strictMode = new StrictMode('true');
    strictModeExceptions ;
    v2AttributeWarnings = {};
    v2ElementWarnings = {};
    miscWarnings = {};
    errorCallback;

    constructor(errorCallback) {
        this.errorCallback = errorCallback;
    }

    addInvalidConditional = (type) => {
        const msg = `Conditional incomplete.  Requires property, operand and either operandConstant or operandProperty: <${type}>`;
        this.miscWarnings[msg] = null;
    }

    addInvalidConditionalExtras = (type) => {
        const msg = `Conditional may not have additional (non-conditional) attributes: <${type}>`;
        this.miscWarnings[msg] = null;
    }

    addInvalidValue = (attribute, value) => {
        const msg = `Attribute ${attribute} contains an unknown value: ${value}`;
        this.miscWarnings[msg] = null;
    }

    addNoPropsInListHeaderFooter = (attribute) => {
        const msg = `Properties may not exist in headers or footers on a list: ${attribute}`;
        this.miscWarnings[msg] = null;
    }

    addUndefinedConstantRef = (constName) => {
        const msg = `Referenced constant is not defined: ${constName}`;
        this.miscWarnings[msg] = null;
    }

    addUndefinedStyleNameRef = (styleName) => {
        const msg = `Referenced styleName is not defined: ${styleName}`;
        this.miscWarnings[msg] = null;
    }

    addUnknownAttribute = (attributeName) => {
        if (!this.strictModeExceptions || this.strictModeExceptions.shouldReportAttribute(attributeName)) {
            // noReports are attributes that don't get formally built and therefore will show as a false hit.
            const noReports = [
                'cvId',
                'operandConstant',
                'operandProperty',
                'operator',
                'markupEdit',
                'markupSaveCancel',
                'markupTitle',
                'property',
                'qualifiers',
                'styleNames',
                'version',
            ];
            if (!noReports.includes(attributeName)) {
                const msg = `Attribute ${attributeName} is not recognized`;
                this.miscWarnings[msg] = null;
            }
        }
    }

    addUnknownElement = (elementName) => {
        if (!this.strictModeExceptions || this.strictModeExceptions.shouldReportElement(elementName)) {
            const msg = `Element ${elementName} is not recognized`;
            this.miscWarnings[msg] = null;
        }
    }

    addUnpredictableUnboundHeight = () => {
        const msg = 'Unpredictable results: Percent height used in scroll direction';
        this.miscWarnings[msg] = null;
    }

    addUnpredictableUnboundWidth = () => {
        const msg = 'Unpredictable results: Percent width used in scroll direction';
        this.miscWarnings[msg] = null;
    }

    addV2AttributeValueWarning = (attribute, value, preferredValue) => {
        const msg = `Attribute ${attribute} value: ${value} -> ${preferredValue}`;
        this.v2AttributeWarnings[msg] = null;
    }

    addV2AttributeWarning = (version, alias, preferredKey) => {
        if (!version.isV1()) {
            const msg = `${alias} -> ${preferredKey}`;
            this.v2AttributeWarnings[msg] = null;
        }
    }
    addV2ElementWarning = (version, alias, preferredKey) => {
        if (!version.isV1()) {
            const msg = `${alias} -> ${preferredKey}`;
            this.v2ElementWarnings[msg] = null;
        }
        // else {
        //     // 23322 - silencing GML error's for now
        //     this.v2ElementWarnings[preferredKey] = preferredKey;
        // }
    }
    addV1EquallySizedColWarning = () => {
        // 23322 - silencing GML error's for now
        // const msg = 'V1 does not support equallySized on a column.';
        // this.miscWarnings[msg] = null;
    }
    addV1ScrollWarning = () => {
        // 23322 - silencing GML error's for now
        // const msg = 'A V1 detail cannot use the scroll attribute';
        // this.miscWarnings[msg] = null;
    }
    addV2ScrollWarning = () => {
        const msg = 'A detail with a header or footer cannot use the scroll attribute';
        this.miscWarnings[msg] = null;
    }
    addScrollValueWarning = (value = 'undefined') => {
        const msg = `Value supplied to scroll attribute is not valid: ${value}`;
        this.miscWarnings[msg] = null;
    }
    report = () => {
        if (this.strictMode.shouldReport()) {
            const elementKeys = Object.keys(this.v2ElementWarnings);
            const attributeKeys = Object.keys(this.v2AttributeWarnings);
            const miscKeys = Object.keys(this.miscWarnings);
            const needToReport = elementKeys.length || attributeKeys.length || miscKeys.length;
            let message;
            if (needToReport) {
                message = 'GML Issues\n\n';

                // Report elements
                if (elementKeys.length) {
                    message += 'Convert these elements from V1 syntax\n';
                    message += this.reportConversions(this.v2ElementWarnings);
                }
                // Report attributes
                if (attributeKeys.length) {
                    message += elementKeys.length ? '\n\n' : '';
                    message += 'Convert these attributes from V1 syntax\n';
                    message += this.reportConversions(this.v2AttributeWarnings);
                }
                // Misc issues
                if (miscKeys.length) {
                    message += (elementKeys.length || attributeKeys.length) ? '\n\n' : '';
                    message += 'General\n';
                    message += this.reportConversions(this.miscWarnings);
                }
                if (this.errorCallback) this.errorCallback(message);
            }
        }
    }

    reportConversions = (messagesAndCounts) => {
        let message = '';
        Object.keys(messagesAndCounts).forEach(e => {
            message += `  ${e}\n`;
        });
        return message;
    }

    setStrictMode = (strictMode) => {
        this.strictMode = strictMode;
    }

    setStrictModeExceptions = (strictModeExceptions) => {
        this.strictModeExceptions = strictModeExceptions;
    }
}
