/* eslint-disable no-unused-vars */
import { rootStore } from 'cv-react-core';
/**
* Helper method to get the styles for Icon component
* @param {Object} contextStyles - Context styles for Icon Component
*/
const getStyles = (contextStyles = {}) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    return {
        headerCellTextContainer: {
            justifyContent: 'flex-start',
            ...contextStyles.headerCell,
        },
        headerCellButtonRowContainer: {
            display: 'flex',
            flexDirection: 'row',
        },
        headerCellButtonRow: {
            display: 'flex',
            flexDirection: 'row',
        },
        headerCellButtonColumn: {
            display: 'flex',
            flexDirection: 'column',
            ...contextStyles.headerCellButtonSection,
        },
        headerCellButton: {
            padding: '0px',
            margin: '0px',
            marginLeft: '2px',
            marginRight: '2px',
            ...contextStyles.headerCellButton,
        },

        headerCellButtonBadge: {
            ...theme.fonts.dataTableHeading,
            color: 'rgba(0, 0, 0, 0.6)',
            height: '12px',
            minWidth: '8px',
            fontSize: '8px',
            ...contextStyles.headerCellButtonBadge,
        },

        headerCellButtonIcon: {
            // color: colorHeading,
            color: 'rgba(0, 0, 0, 0.6)',
            fontSize: '18px',
            ...contextStyles.headerCellButtonIcon,
        },

        headerCellText: {
            ...theme.fonts.dataTableHeading,
            ...contextStyles.headerCellText,
            textAlign: 'left',
            padding: '2px',
            color: '#121212',
        },

        verticalDivider: {
            height: '100%',
            width: '1px',
            backgroundColor: '#D2D2D2',
            right: '0px',
            position: 'absolute',
        },
    };
};

export default getStyles;
