import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import styleParser from '../utilities/styleHelpers/styleParser';
import { input } from '../constants/inputConstants';

const { XALT_INPUT_TYPES } = input;

const propDefinition = {
    /** Reference to the surrounding container */
    anchorRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),

    /** String that helps autofilling form control by browser */
    autoComplete: PropTypes.string,

    /** Focuses the input */
    autoFocus: PropTypes.bool,

    /** Styles for this component */
    style: PropTypes.object,

    /** Prevents the text field from accepting input */
    disabled: PropTypes.bool,

    /** Elements that needs to be placed towards the end of the TextField */
    endAdornment: PropTypes.node,

    /** If true, the input will take up the full width of its container. */
    fullWidth: PropTypes.bool,

    /** Custom component */
    inputComponent: PropTypes.elementType,

    /** Attributes applied to the input element. */
    inputProps: PropTypes.object,

    /** Reference to the input element */
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),

    maxLength: PropTypes.number,

    /** If true, a textarea element will be rendered. */
    multiline: PropTypes.bool,

    multilineInList: PropTypes.object,

    /**
     * Called when the TextField's input changes
     * @param {Object} event - The native change event
     * @param {Object} props - The component instance props
     */
     onValueChanged: PropTypes.func,

    /** Placeholder text for input field */
    placeholder: PropTypes.string,

    /** Prevents the text field from editing input */
    readOnly: PropTypes.bool,

    /** Elements that needs to be placed at the beginning of the TextField */
    startAdornment: PropTypes.node,

    /** Determines the type of input */
    type: PropTypes.oneOf([
        XALT_INPUT_TYPES.TEXT,
        XALT_INPUT_TYPES.PASSWORD,
        XALT_INPUT_TYPES.EMAIL,
        XALT_INPUT_TYPES.NUMBER,
    ]),

    /** The text to populate in the input */
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool,
    ]),

    /** Pattern for validating value update */
    regxValidationPattern: PropTypes.string,

    onFocus: PropTypes.func,

    onBlur: PropTypes.func,
};
/**
 * A platform component for building a html input
 */
export default class XaltInputBase extends Component {
    static propTypes = propDefinition;

    static defaultProps = {
        style: {},
        type: XALT_INPUT_TYPES.TEXT,
        // Need to default to this to prevent chrome autofilling values
        autoComplete: 'new-password',
        fullWidth: false,
        disabled: false,
        placeholder: '',
        readOnly: false,
        multiline: false,
        onValueChanged: () => {},
        onFocus: () => {},
        onBlur: () => {},
        multilineInList: {},
    };

    render() {
        const {
            anchorRef,
            autoComplete,
            autoFocus,
            disabled,
            inputProps,
            multiline,
            placeholder,
            readOnly,
            style,
            type,
            value,
            endAdornment,
            inputComponent,
            startAdornment,
            inputRef,
            fullWidth,
            maxLength,
            multilineInList,
            onBlur,
        } = this.props;

        const textStyles = { height: 'auto', padding: 0, ...styleParser.parseTextStyles(style), ...multilineInList };
        const containerStyles = styleParser.parseContainerStyles(style);

        const inputBaseProps = {
            className: 'x-input__base',
            fullWidth,
            style: { ...containerStyles },
            inputProps: {
                style: { ...textStyles },
                autoFocus,
                autoComplete,
                disabled,
                placeholder,
                readOnly,
                type,
                value,
                maxLength,
                ...inputProps,
            },
            endAdornment,
            startAdornment,
            inputRef,
            ref: anchorRef,
            multiline,
            disableUnderline: true,
            onFocus: this.handleFocus,
            onChange: this.handleOnChange,
            onBlur,
        };

        if (inputComponent) {
            inputBaseProps.inputComponent = inputComponent;
        }

        return React.createElement(Input, { ...inputBaseProps } );
    }

    // Overrides default selection of input value to set caret position at the end
    handleFocus = (event) => {
        const { target } = event;
        const { type } = target;
        const { onFocus } = this.props;
        event.preventDefault();
        target.setAttribute('type', 'text');
        target.selectionStart = target.value.length;
        target.setAttribute('type', type);
        onFocus();
    };

    handleOnChange = (event) => {
        const { onValueChanged, regxValidationPattern } = this.props;
        const { value } = event.target;
        if (regxValidationPattern) {
            const validationPatternExp = new RegExp(regxValidationPattern);
            const valid = !!validationPatternExp.test(value);
            if (!valid) return;
        }
        onValueChanged(value);
    }
}
