import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getStyles from './styles/ChartFilter.styles';

import DateTimePicker from '../base/DateTimePicker';
import Checkbox from '../base/Checkbox';
import TextLabel from '../base/TextLabel';
import Slider from '../base/Slider';

export default class ChartFilter extends Component {
    static propTypes = {
        /** Styles for the component */
        contextStyles: PropTypes.shape({
            /** Styles for the container */
            checkBoxContainer: PropTypes.object,

            /** Styles for the container */
            container: PropTypes.object,
        }),
        filterArray: PropTypes.array,
        headerText: PropTypes.string,
        onFilterPress: PropTypes.func,
    };
    render() {
        const {
            contextStyles,
            filterArray,
            headerText,
            onFilterPress,
        } = this.props;

        const styles = getStyles(contextStyles);

        // Generate container props
        const containerProps = {
            style: styles.container,
        };

        // Build menu items
        let filteredCheckboxList = [];
        if (Array.isArray(filterArray)) {
            filteredCheckboxList = filterArray.map((filter, index) => (this.renderFilterItem(filter, onFilterPress, styles, index)));
        }

        return (
            <div { ...containerProps }>
                <TextLabel>
                    { headerText }
                </TextLabel>
                { filteredCheckboxList }
            </div>
        );
    }

    renderFilterItem = (filter, onFilterPress, styles, index) => {
        const {
            checked,
            code,
            description,
            maxRange,
            minRange,
            type,
        } = filter;

        const styling = {
            style: {
                ...styles.checkBoxContainer,
            },
        };

        let component = null;

        if (type === 'code-ref'){
            const displayName = `${code}|${description}`;
            component = (
                <div
                    { ...styling }
                    key={ `container_${index}` }>
                    { filter && <Checkbox
                        checked={ checked }
                        filter={ filter }
                        key={ `checkbox_${index}` }
                        name={ code }
                        onChange={ onFilterPress } />
                    }
                    <TextLabel key={ `textLabel_${index}` }>
                        { displayName }
                    </TextLabel>
                </div>
            );
        }
        else if (type === 'date'){
            component = (
                <DateTimePicker
                    contextStyles={ { } }
                    filter={ filter }
                    format="MM/DD/YYYY"
                    key={ `data_${index}` }
                    mode={ type }
                    name={ description }
                    onChange={ this.handleDateChange }
                    selectedDate={ code || null }
                    views={ [ type ] } />
                );
        }
        else if (type === 'decimal'){
            component = (
                <Slider
                    filter={ filter }
                    key={ `Slider${index}` }
                    max={ maxRange }
                    min={ minRange }
                    name={ description }
                    onChange={ this.handleSliderChange }
                    value={ code } />
                );
        }

        return component;
    }

    handleDateChange = (event, dateChange, props) => {
        // eslint-disable-next-line no-param-reassign
        props.filter.code = new Date(dateChange);
        const { onFilterPress } = this.props;
        onFilterPress(event, props);
    }

    handleSliderChange = (event, newValue, props) => {
        // eslint-disable-next-line no-param-reassign
        props.filter.code = newValue;
        const { onFilterPress } = this.props;
        onFilterPress(event, props);
     }
}