import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import { serviceFactory } from 'cv-react-core';
import { XaltToolTip } from 'xalt-react-atoms';

import IconButton from '../components/base/IconButton';
import TextField from '../components/base/TextField';

class RWQuickSearch extends PureComponent {
    static propTypes = {
        /**
         * Called on search term change
         * @param {any} term
         * @returns {void}
         */
        onSearchTermChange: PropTypes.func,

        disabled: PropTypes.bool,

        searchInProgress: PropTypes.bool,

        /**
         * Called on search term reset
         * @returns {void}
         */
        onClear: PropTypes.func,

        /**
         * Called on search term submit
         * @returns {void}
         */
        onSubmit: PropTypes.func,

        /** placeholder text for quick filter */
        placeholderText: PropTypes.string,

        /** Quick filter search term */
        searchTerm: PropTypes.object,

        /** ID string used for testing */
        testID: PropTypes.string,

        style: PropTypes.object,
    };

    static defaultProps = {
        placeholderText: 'Quick Filter...',
        searchTerm: { value: '' },
        onSubmit: () => {},
        onClear: () => {},
    };

    constructor(props) {
        super(props);
        this.handleSearchTermChange = this.handleSearchTermChange.bind(this);
    }

    render() {
        const {
            searchTerm,
            placeholderText,
            testID,
            onSubmit,
            onClear,
            disabled,
            searchInProgress,
            style,
        } = this.props;

        const buttonStyleProps = {
            button: {
                padding: '6px',
            },
            icon: {
                fontSize: 20,
                padding: '0px',
                color: '#121212',
            },
        };
        const inputStyleProps = {
            container: {
                flexGrow: 0,
                height: '32px',
                background: '#FFFFFF',
                borderRadius: '4px',
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: '#E0E0E0',
                boxSizing: 'border-box',
            },
            input: {
                fontSize: '14px',
                color: '#121212',
                paddingTop: 0,
                paddingBottom: 0,
                textOverflow: 'ellipsis',
                height: 'auto',
            },
            hoover: {
                paddingTop: 0,
                paddingBottom: 0,
            },
        };

        const { lang } = serviceFactory;
        let toolTipText = lang.searchSort.quickSearchHelpText;
        if (disabled) {
            toolTipText = lang.searchSort.quickSearchDisabled;
        }

        const disableSearch = !((searchTerm.value && searchTerm.value.trim().length > 0) || searchInProgress);
        const {
            display,
            flexGrow,
            flexShrink,
            width,
            height } = style;
        const tipStyle = {
            display: display || 'flex',
            flexGrow,
            flexShrink,
            width,
            height,
            flexDirection: 'column',
        };
        return (
            <XaltToolTip
                style={ { ...tipStyle } }
                tipText={ toolTipText }>
                <TextField
                    contextStyles={ inputStyleProps }
                    value={ searchTerm.value || '' }
                    variant="outlined"
                    disabled={ disabled }
                    endAdornment={
                        <IconButton
                            contextStyles={ buttonStyleProps }
                            iconName="cancel"
                            title="Clear"
                            disabled={ searchInProgress || !searchTerm.value }
                            onClick={ onClear } />
                    }
                    startAdornment={
                        <IconButton
                            contextStyles={ buttonStyleProps }
                            disabled={ disableSearch }
                            iconName="search"
                            onClick={ onSubmit } />
                    }
                    onChange={ this.handleSearchTermChange }
                    onSubmit={ onSubmit }
                    placeholder={ placeholderText }
                    testID={ `${testID}__seacrchfield-container` } />
            </XaltToolTip>
        );
    }

    /**
     * Called on search term change
     * @param {SyntheticEvent} event
     * @returns {void}
     */
    handleSearchTermChange(event) {
        const {
            onSearchTermChange,
        } = this.props;

        if (onSearchTermChange) {
            onSearchTermChange(event.target.value);
        }
    };
}

export default RWQuickSearch;