import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { PanelHeader as PanelHeaderBase } from 'cv-library-react-web';

import ActionHeader from '../ActionHeader/ActionHeader';
import Icon from '../Icon';
import TextLabel from '../TextLabel';

import getStyles from './PanelHeader.styles';

/**
 * A PanelHeader consists of a Title and an ActionHeader.
 */
class PanelHeader extends Component {
    static defaultProps = {
        title: '',
        groups: [],
        menu: [],
        contextStyles: {},
    };

    static propTypes = {
        title: PropTypes.string,
        iconCls: PropTypes.string,
        popContextMenu: PropTypes.bool,
        groups: PropTypes.array,
        menu: PropTypes.array,
        contextStyles: PropTypes.object,
        onMenuAction: PropTypes.func,
        onGroupAction: PropTypes.func,
        quickSearch: PropTypes.node,
    };

    render() {
        // Log.debug('PANEL HEADER RENDER: ', this.props); // eslint-disable-line

        const {
            title,
            iconCls,
            groups,
            menu,
            popContextMenu,
            contextStyles,
            quickSearch,
        } = this.props;
        this.styles = getStyles(contextStyles);
        const hasIcon = !!iconCls;
        const hasText = !!title;

        // Generate action header props
        const actionHeaderProps = {
            groups,
            menu,
            onGroupAction: this.handleGroupAction,
            onMenuAction: this.handleMenuAction,
            popContextMenu,
        };

        // Turn these hard coded styles off to make the menu reappear
        return (
            <PanelHeaderBase
                contextStyles={ {
                    container: this.styles.container,
                } }>
                { (hasIcon || hasText) &&
                    <>
                        <div
                            className="c-panel-header__content"
                            style={ this.styles.iconAndTitleContainer }>
                            { hasIcon && this.renderTitleIcon() }
                            { hasText && this.renderTitleText() }
                        </div>
                        <div
                            className="c-panel-header__separator"
                            style={ { flex: 1 } } />
                    </>
                }
                { (quickSearch) &&
                    <>
                        <div style={ this.styles.searchContainer }>
                            { quickSearch }
                        </div>
                    </>
                }
                <div
                    className="c-panel-header__separator"
                    style={ { flex: 1 } } />
                <ActionHeader { ...actionHeaderProps } />
            </PanelHeaderBase>
        );
    }

    renderTitleIcon = () => {
        const {
            iconCls,
        } = this.props;

        // Generate text props
        const textProps = {
            contextStyles: {
                text: this.styles.iconTextContainer,
            },
        };

        // Generate icon props
        const iconProps = {
            contextStyles: {
                name: iconCls,
                icon: this.styles.icon,
            },
        };

        return (
            <TextLabel { ...textProps }>
                <Icon { ...iconProps } />
            </TextLabel>
        );
    };

    renderTitleText = () => {
        const {
            title,
        } = this.props;

        // Generate text props
        const textProps = {
            contextStyles: {
                text: this.styles.titleText,
            },
        };

        return (
            <TextLabel { ...textProps }>
                { title }
            </TextLabel>
        );
    };

    handleMenuAction = (menu, nativeEvent) => {
        const { onMenuAction } = this.props;

        if (onMenuAction) {
            onMenuAction(menu, nativeEvent);
        }
    };

    handleGroupAction = (groupEvent, nativeEvent) => {
        const { onGroupAction } = this.props;
        if (onGroupAction) {
            onGroupAction(groupEvent, nativeEvent);
        }
    };
}

export default PanelHeader;
