/* eslint-disable array-element-newline */
/* eslint-disable array-bracket-newline */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { uiHelper, serviceFactory, utilities, rootStore } from 'cv-react-core';
import { TimeValue } from 'cv-dialog-sdk';
import { observer } from 'mobx-react';
import DateTimePicker, { DATE_TYPES, DATE_VIEWS, DATETIME_PICKER_VARIANTS } from '../base/DateTimePicker';

const { calendarHelper } = utilities;
@observer
class FilterValueDateTimeComponent extends Component {
    static defaultProps = {
        // styles: {},
        onSetFilterValue: () => {},
    }

    static propTypes = {
        propertyName: PropTypes.string.isRequired,
        onSetFilterValue: PropTypes.func,
        searchDialogStore: PropTypes.object,
    }

    constructor(props) {
        super(props);
        this.handleDateOnChange = this.handleDateOnChange.bind(this);
    }

    render() {
        // TODO: This needs to be cleaned up.
        const {
            propertyName,
            searchDialogStore } = this.props;
        const filterValueProperty = searchDialogStore.getSearchValuePropertyForName(propertyName);
        const { value } = filterValueProperty;
        const propertyValueDefinition = searchDialogStore.getSearchValuePropertyDefForName(propertyName);
        const is24Hour = uiHelper.getIs24HourFormat();
        const dateFormat = uiHelper.getDateFormatString();
        const { lang } = serviceFactory;
        const firstDayofWeek = calendarHelper.getFirstDayofWeek(rootStore);
        const dateTimeProps = {
            mode: DATE_TYPES.DATE,
            format: dateFormat,
            views: [],
            // since we are using picker variant as INLINE, the autoOK should be set as TRUE as INLINE variant doesnt show set/clear/cancel Buttons.
            autoOk: true,
            placeholder: lang.searchSort.filterValuePlaceholderText,
            onChange: this.handleDateOnChange,
            selectedDate: value || null,
            is24Hour,
            firstDayofWeek,
        };

        if (propertyValueDefinition.isDateType) {
            dateTimeProps.variant = DATETIME_PICKER_VARIANTS.INLINE;
            dateTimeProps.views = [ DATE_VIEWS.DATE ];
        }
        if (propertyValueDefinition.isTimeType) {
            const format = is24Hour ? 'HH:mm' : 'hh:mm a';
            dateTimeProps.mode = DATE_TYPES.TIME;
            dateTimeProps.format = format; // Needs to be NLS aware
            dateTimeProps.variant = DATETIME_PICKER_VARIANTS.INLINE;
            dateTimeProps.views = [ DATE_VIEWS.HOURS, DATE_VIEWS.MINUTES ];
            dateTimeProps.selectedDate = value instanceof TimeValue ? value.toDateValue() : null;
        }
        if (propertyValueDefinition.isDateTimeType) {
            const format = is24Hour ? `${dateFormat}, HH:mm` : `${dateFormat}, hh:mm a`;
            dateTimeProps.mode = DATE_TYPES.DATETIME;
            dateTimeProps.format = format; // Needs to be NLS aware
            dateTimeProps.variant = DATETIME_PICKER_VARIANTS.INLINE;
            dateTimeProps.views = [ DATE_VIEWS.DATE, DATE_VIEWS.HOURS, DATE_VIEWS.MINUTES ];
            dateTimeProps.hideTabs = true;
        }
        return React.createElement(DateTimePicker, { ...dateTimeProps });
    };

    handleDateOnChange(date) {
        // eslint-disable-next-line no-restricted-globals
        if (date && isNaN(date.getTime())) {
            // ignore invalid dates
            return;
        }
        const { onSetFilterValue, propertyName } = this.props;
        onSetFilterValue(propertyName, date);
    }
}

export default FilterValueDateTimeComponent;