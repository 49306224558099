import colors from './colors';
import typography, { calcLineHeight } from './typography';

// These colors are either not used in the product, or will be replaced with SDx GML.
const COLOR_DARK_BLUE = colors.hexagonPallet7BlueDarkest;
const COLOR_GREEN = colors.green;
const COLOR_LIGHT_BLUE = colors.hexagonPallet7BlueLightest;
const COLOR_ORANGE = colors.hexagonPallet22OrangeDarkest;
const COLOR_RED = colors.hexagonPallet25RedDark;

// These colors are not configurable
const COLOR_BLACK = colors.black;
const COLOR_WHITE = colors.white;
const TRANSPARENT = colors.transparent;
const EXTERNAL_LINK = colors.hexagonPallet7BlueLightest;

// Note: Added here temporarily. These are the new colors from UX.
// These colors needs to be moved to colors.js file with appropriate names.
const XALT_BACKGROUND = '#E5E5E5';
const XALT_BLUE = '#1C8EA9';
const XALT_PRIMARY = '#3BB0C9';
const XALT_LIGHT_BLACK = '#4A4949';
const XALT_SECONDARY_LIGHT_GRAY4 = '#646464';
const XALT_SECONDARY_LIGHT_GRAY5 = '#4A4A4A';
const XALT_ERROR = '#B00020';
const XALT_HEADING = '#191919';

export const spaceModes = {
    Padded: 'padded',
    Compact: 'compact',
};

export default class BaseTheme {
    constructor(overrideThemeValues) {
        this.overrideThemeValues = overrideThemeValues;
        this.resolved = {};
    }
    static appLogo = null;
    static appTopLogo = null;
    static bannerLogo = null;
    static appName = '';
    static spaceMode = spaceModes.Padded;

    getKey = (name, limb) => {
        const k = limb || '';
        return `${k}.${name}`;
    }

    getOverrideFontObject = (from) => {
        const myThis = this;
        return {
            get color() { return myThis.getValue('color', undefined, from); },
            get fontSize() { return myThis.getValue('fontSize', undefined, from); },
            get fontStyle() { return myThis.getValue('fontStyle', undefined, from); },
            get fontWeight() { return myThis.getValue('fontWeight', undefined, from); },
            get textAlign() { return myThis.getValue('textAlign', undefined, from); },
            get textDecorationLine() { return myThis.getValue('textDecorationLine', undefined, from); },
            get fontFamily() { return myThis.getValue('fontFamily', undefined, from); },
        };
    }

    getValue = (name, defaultValue, limb) => {
        const key = this.getKey(name, limb);
        const cachedResult = this.resolved[key];
        if (cachedResult !== undefined) {
            return cachedResult;
        }
        let override;
        if (this.overrideThemeValues) {
            // limb can be a dot separated value such as fonts.something.
            if (limb) {
                const f = (obj, currentName) => {
                    return obj && obj[currentName];
                };
                const lastObj = limb.split('.').reduce(f, this.overrideThemeValues);
                override = lastObj && lastObj[name];
            } else {
                override = this.overrideThemeValues[name];
            }
        }
        const answer = (override !== undefined) ? override : defaultValue;
        this.resolved[key] = answer;
        return answer;
    }

    mergeFonts = (fontsArray) => {
        // Return one font that is the combination of the fonts in fontsArray
        const answer = {};
        fontsArray.forEach(font => {
            Object.keys(font).forEach(key => {
                if (font[key]) {
                    answer[key] = font[key];
                }
            });
        });
        return answer;
    }

    get space() {
        const getValue = this.getValue.bind(this);
        const limb = 'space';
        if (this.spaceMode === spaceModes.Compact) {
            return {
                get paddingLeft() { return getValue('paddingLeft', 8, limb); },
                get paddingRight() { return getValue('paddingRight', 8, limb); },
                get paddingTop() { return getValue('paddingTop', 2, limb); },
                get paddingBottom() { return getValue('paddingBottom', 2, limb); },
            };
        }
        return {
            get paddingLeft() { return getValue('paddingLeft', 13, limb); },
            get paddingRight() { return getValue('paddingRight', 12, limb); },
            get paddingTop() { return getValue('paddingTop', 8, limb); },
            get paddingBottom() { return getValue('paddingBottom', 9, limb); },
        };
    }

    get colors() {
        const getValue = this.getValue.bind(this);
        const limb = 'colors';
        return {
            // primary: workbenchSearchBackgroundColor, navigationBarBackgroundColor (navbar), panelTitleColor?,
            //          LoginBackground
            get primary() { return getValue('primary', colors.hexagonPallet7BlueDarkest, limb); },

            get alternate1() { return getValue('alternate1', colors.hexagonPallet27GreyDarkest, limb); },
            get alternate2() { return getValue('alternate2', colors.hexagonPallet27GreyLight, limb); },
            get alternate3() { return getValue('alternate3', colors.hexagonPallet27GreyLightest, limb); },

            // panel: searchSortPopupBackgroundColor, pageBackgroundColor, loginFormPanelColor, dataTablePreLoadBackgroundColor
            get panel() { return getValue('panel', colors.white, limb); },
            // Background color for all the pages
            get background() { return getValue('background', XALT_BACKGROUND, limb); },

            // controls: RadioButtons, DateTime, DropDown
            get controls() { return getValue('controls', XALT_SECONDARY_LIGHT_GRAY5, limb); },
            get activeControls() { return getValue('activeControls', XALT_BLUE, limb); },
            get buttons() { return getValue('buttons', COLOR_BLACK, limb); },

            // loading: listLoadingIndicatorColor, pageLoadingIndicatorColor, statusLoadingIndicatorColor
            get loading() { return getValue('loading', XALT_PRIMARY, limb); },

            get error() { return getValue('error', XALT_ERROR, limb); },
            get info() { return getValue('info', colors.hexagonPallet7BlueDarkest, limb); },
            get warning() { return getValue('warning', colors.hexagonPallet19YellowLightest, limb); },
            get remove() { return getValue('remove', XALT_ERROR, limb); },

            // List Colors
            get listAlternatingColors() {
                const listAlternatingGray = '#FAFAFA';
                return getValue('listAlternatingColors', `${listAlternatingGray}, ${colors.white}`);
            },

            // Until reuse can be identified, these are named very specific.
            get loginSettingsIconColor() { return getValue('loginSettingsIconColor', colors.white, limb); },
            get workbenchLauncherTileColor() { return getValue('workbenchLauncherTileColor', colors.white, limb); },

            // Fonts
            get fontDefault() { return getValue('fontDefault', colors.black, limb); },
            get fontSecondary() { return getValue('fontSecondary', colors.white, limb); },
        };
    }

    get fonts() {
        const getValue = this.getValue.bind(this);
        const limb = 'fonts';
        const myThis = this;
        return {
            // Font Size
            get fontSizeSmall() { return getValue('fontSizeSmall', typography.baseMetaSize, limb); },
            get fontSizeMidSmall() { return getValue('fontSizeMidSmall', 13, limb); },
            get fontSizeMedium() { return getValue('fontSizeMedium', 15, limb); },
            get fontSizeNormal() { return getValue('fontSizeNormal', typography.baseFontSize, limb); },
            get fontSizeMidLarge() { return getValue('fontSizeMidLarge', 20, limb); },
            get fontSizeLarge() { return getValue('fontSizeLarge', typography.baseMegaSize, limb); },
            // Font Family
            get fontFamily() { return getValue('fontFamily', typography.baseFontFamily, limb); },

            get default() {
                const name = 'base';
                const key = myThis.getKey(name, limb);
                const cachedResult = myThis.resolved[key];
                if (cachedResult) {
                    return cachedResult;
                }

                // Only specify the values that are different from the basing font.
                const font = {
                    get color() { return myThis.colors.fontDefault; },
                    get fontSize() { return myThis.fonts.fontSizeNormal; },
                    get fontStyle() { return 'normal'; },
                    get fontWeight() { return 'normal'; },
                    get textAlign() { return 'auto'; },
                    get textDecorationLine() { return 'none'; },
                    get fontFamily() { return myThis.fonts.fontFamily; },
                };
                myThis.resolved[key] = myThis.mergeFonts([ font, myThis.getOverrideFontObject(`${limb}.${name}`) ]);
                return myThis.resolved[key];
            },

            get aboutLicenseHeader() {
                const name = 'aboutLicenseHeader';
                const key = myThis.getKey(name, limb);
                const cachedResult = myThis.resolved[key];
                if (cachedResult) {
                    return cachedResult;
                }
                const myValues = {
                    get fontSize() { return myThis.fonts.fontSizeMidLarge; },
                    get fontWeight() { return 'bold'; },
                };

                myThis.resolved[key] = myThis.mergeFonts([ myThis.fonts.default, myValues, myThis.getOverrideFontObject(`${limb}.${name}`) ]);
                return myThis.resolved[key];
            },

            get aboutLicenseDetail() {
                const name = 'aboutLicenseDetail';
                const key = myThis.getKey(name, limb);
                const cachedResult = myThis.resolved[key];
                if (cachedResult) {
                    return cachedResult;
                }
                const myValues = {
                };

                myThis.resolved[key] = myThis.mergeFonts([ myThis.fonts.default, myValues, myThis.getOverrideFontObject(`${limb}.${name}`) ]);
                return myThis.resolved[key];
            },

            get actionButtonLabel() {
                const name = 'actionButtonLabel';
                const key = myThis.getKey(name, limb);
                const cachedResult = myThis.resolved[key];
                if (cachedResult) {
                    return cachedResult;
                }
                const myValues = {
                };

                myThis.resolved[key] = myThis.mergeFonts([ myThis.fonts.default, myValues, myThis.getOverrideFontObject(`${limb}.${name}`) ]);
                return myThis.resolved[key];
            },

            get buttonPrimary() {
                const name = 'buttonPrimary';
                const key = myThis.getKey(name, limb);
                const cachedResult = myThis.resolved[key];
                if (cachedResult) {
                    return cachedResult;
                }
                const myValues = {
                    get fontSize() { return 14; },
                    get textAlign() { return 'center'; },
                    get letterSpacing() { return 0.5; },
                    get fontWeight() { return '500'; },
                    get color() { return '#F2F2F2'; },
                };

                myThis.resolved[key] = myThis.mergeFonts([ myThis.fonts.default, myValues, myThis.getOverrideFontObject(`${limb}.${name}`) ]);
                return myThis.resolved[key];
            },

            get buttonSecondary() {
                const name = 'buttonSecondary';
                const key = myThis.getKey(name, limb);
                const cachedResult = myThis.resolved[key];
                if (cachedResult) {
                    return cachedResult;
                }
                const myValues = {
                    get fontSize() { return 14; },
                    get textAlign() { return 'center'; },
                    get letterSpacing() { return 0.5; },
                    get fontWeight() { return '500'; },
                    get color() { return COLOR_BLACK; },
                };

                myThis.resolved[key] = myThis.mergeFonts([ myThis.fonts.default, myValues, myThis.getOverrideFontObject(`${limb}.${name}`) ]);
                return myThis.resolved[key];
            },

            get buttonTertiary() {
                const name = 'buttonTertiary';
                const key = myThis.getKey(name, limb);
                const cachedResult = myThis.resolved[key];
                if (cachedResult) {
                    return cachedResult;
                }
                const myValues = {
                    get fontSize() { return 16; },
                    get textAlign() { return 'center'; },
                    get color() { return COLOR_BLACK; },
                };

                myThis.resolved[key] = myThis.mergeFonts([ myThis.fonts.default, myValues, myThis.getOverrideFontObject(`${limb}.${name}`) ]);
                return myThis.resolved[key];
            },

            get loginButtonPrimary() {
                const name = 'loginButtonPrimary';
                const key = myThis.getKey(name, limb);
                const cachedResult = myThis.resolved[key];
                if (cachedResult) {
                    return cachedResult;
                }
                const myValues = {
                    get fontSize() { return 14; },
                    get letterSpacing() { return 0.5; },
                    get textAlign() { return 'center'; },
                    get fontWeight() { return '500'; },
                    get color() { return COLOR_WHITE; },
                };

                myThis.resolved[key] = myThis.mergeFonts([ myThis.fonts.default, myValues, myThis.getOverrideFontObject(`${limb}.${name}`) ]);
                return myThis.resolved[key];
            },

            get compactListCol0() {
                const name = 'compactListCol0';
                const key = myThis.getKey(name, limb);
                const cachedResult = myThis.resolved[key];
                if (cachedResult) {
                    return cachedResult;
                }
                const myValues = {
                    get fontSize() { return myThis.fonts.fontSizeMidLarge; },
                };

                myThis.resolved[key] = myThis.mergeFonts([ myThis.fonts.default, myValues, myThis.getOverrideFontObject(`${limb}.${name}`) ]);
                return myThis.resolved[key];
            },

            get compactListColN() {
                const name = 'compactListColN';
                const key = myThis.getKey(name, limb);
                const cachedResult = myThis.resolved[key];
                if (cachedResult) {
                    return cachedResult;
                }
                const myValues = {
                };

                myThis.resolved[key] = myThis.mergeFonts([ myThis.fonts.default, myValues, myThis.getOverrideFontObject(`${limb}.${name}`) ]);
                return myThis.resolved[key];
            },

            get compactListColNLabel() {
                const name = 'compactListColNLabel';
                const key = myThis.getKey(name, limb);
                const cachedResult = myThis.resolved[key];
                if (cachedResult) {
                    return cachedResult;
                }
                const myValues = {
                    get color() { return myThis.colors.primary; },
                };

                myThis.resolved[key] = myThis.mergeFonts([ myThis.fonts.default, myValues, myThis.getOverrideFontObject(`${limb}.${name}`) ]);
                return myThis.resolved[key];
            },

            get copyrightCompany() {
                const name = 'copyrightCompany';
                const key = myThis.getKey(name, limb);
                const cachedResult = myThis.resolved[key];
                if (cachedResult) {
                    return cachedResult;
                }
                const myValues = {
                    get color() { return myThis.colors.fontSecondary; },
                    get textAlign() { return 'center'; },
                };

                myThis.resolved[key] = myThis.mergeFonts([ myThis.fonts.default, myValues, myThis.getOverrideFontObject(`${limb}.${name}`) ]);
                return myThis.resolved[key];
            },

            get copyrightVersion() {
                const name = 'copyrightVersion';
                const key = myThis.getKey(name, limb);
                const cachedResult = myThis.resolved[key];
                if (cachedResult) {
                    return cachedResult;
                }
                const myValues = {
                    get color() { return myThis.colors.alternate3; },
                    get fontSize() { return myThis.fonts.fontSizeMidSmall; },
                    get textAlign() { return 'center'; },
                };

                myThis.resolved[key] = myThis.mergeFonts([ myThis.fonts.default, myValues, myThis.getOverrideFontObject(`${limb}.${name}`) ]);
                return myThis.resolved[key];
            },

            get dataTableHeading() {
                const name = 'dataTableHeading';
                const key = myThis.getKey(name, limb);
                const cachedResult = myThis.resolved[key];
                if (cachedResult) {
                    return cachedResult;
                }
                const myValues = {
                    get fontWeight() { return '500'; },
                    get fontSize() { return 14; },
                    get color() { return myThis.colors.fontSecondary; },
                    get fontFamily() { return 'Roboto'; },
                    get textTransform() { return 'uppercase'; },
                };

                myThis.resolved[key] = myThis.mergeFonts([ myThis.fonts.default, myValues, myThis.getOverrideFontObject(`${limb}.${name}`) ]);
                return myThis.resolved[key];
            },

            get dataTableRow() {
                const name = 'dataTableRow';
                const key = myThis.getKey(name, limb);
                const cachedResult = myThis.resolved[key];
                if (cachedResult) {
                    return cachedResult;
                }
                const myValues = {
                    get fontSize() { return 12; },
                };

                myThis.resolved[key] = myThis.mergeFonts([ myThis.fonts.default, myValues, myThis.getOverrideFontObject(`${limb}.${name}`) ]);
                return myThis.resolved[key];
            },

            get detailsAttribute() {
                const name = 'detailsAttribute';
                const key = myThis.getKey(name, limb);
                const cachedResult = myThis.resolved[key];
                if (cachedResult) {
                    return cachedResult;
                }
                const myValues = {
                    get fontSize() { return 12; },
                };

                myThis.resolved[key] = myThis.mergeFonts([ myThis.fonts.default, myValues, myThis.getOverrideFontObject(`${limb}.${name}`) ]);
                return myThis.resolved[key];
            },

            get detailsLabel() {
                const name = 'detailsLabel';
                const key = myThis.getKey(name, limb);
                const cachedResult = myThis.resolved[key];
                if (cachedResult) {
                    return cachedResult;
                }
                const myValues = {
                    get color() { return myThis.colors.primary; },
                    get fontSize() { return 12; },
                };

                myThis.resolved[key] = myThis.mergeFonts([ myThis.fonts.default, myValues, myThis.getOverrideFontObject(`${limb}.${name}`) ]);
                return myThis.resolved[key];
            },

            get error() {
                const name = 'error';
                const key = myThis.getKey(name, limb);
                const cachedResult = myThis.resolved[key];
                if (cachedResult) {
                    return cachedResult;
                }
                const myValues = {
                    get fontSize() { return 14; },
                    get textAlign() { return 'center'; },
                    get letterSpacing() { return 0.28; },
                    get color() { return XALT_LIGHT_BLACK; },
                };

                myThis.resolved[key] = myThis.mergeFonts([ myThis.fonts.default, myValues, myThis.getOverrideFontObject(`${limb}.${name}`) ]);
                return myThis.resolved[key];
            },

            get listLoading() {
                const name = 'listLoading';
                const key = myThis.getKey(name, limb);
                const cachedResult = myThis.resolved[key];
                if (cachedResult) {
                    return cachedResult;
                }
                const myValues = {
                    // get color() { return myThis.colors.fontAlternate; },
                };

                myThis.resolved[key] = myThis.mergeFonts([ myThis.fonts.default, myValues, myThis.getOverrideFontObject(`${limb}.${name}`) ]);
                return myThis.resolved[key];
            },

            get loadingStatusMajor() {
                const name = 'loadingStatusMajor';
                const key = myThis.getKey(name, limb);
                const cachedResult = myThis.resolved[key];
                if (cachedResult) {
                    return cachedResult;
                }
                const myValues = {
                    get fontSize() { return 18; },
                    get textAlign() { return 'center'; },
                    get color() { return myThis.colors.fontDefault; },
                };

                myThis.resolved[key] = myThis.mergeFonts([ myThis.fonts.default, myValues, myThis.getOverrideFontObject(`${limb}.${name}`) ]);
                return myThis.resolved[key];
            },

            get loadingStatusMinor() {
                const name = 'loadingStatusMinor';
                const key = myThis.getKey(name, limb);
                const cachedResult = myThis.resolved[key];
                if (cachedResult) {
                    return cachedResult;
                }
                const myValues = {
                    get fontSize() { return 14; },
                    get textAlign() { return 'center'; },
                    get letterSpacing() { return 0.28; },
                    get color() { return XALT_LIGHT_BLACK; },
                };

                myThis.resolved[key] = myThis.mergeFonts([ myThis.fonts.default, myValues, myThis.getOverrideFontObject(`${limb}.${name}`) ]);
                return myThis.resolved[key];
            },

            // Keeping these specific to login and settings page for now. If these styles are same as details page styles, we can remove these.
            get loginSettingsHeading() {
                const name = 'loginSettingsHeading';
                const key = myThis.getKey(name, limb);
                const cachedResult = myThis.resolved[key];
                if (cachedResult) {
                    return cachedResult;
                }
                const myValues = {
                    get fontSize() { return 20; },
                    get color() { return XALT_HEADING; },
                };

                myThis.resolved[key] = myThis.mergeFonts([ myThis.fonts.default, myValues, myThis.getOverrideFontObject(`${limb}.${name}`) ]);
                return myThis.resolved[key];
            },

            get loginSettingsInfo() {
                const name = 'loginSettingsInfo';
                const key = myThis.getKey(name, limb);
                const cachedResult = myThis.resolved[key];
                if (cachedResult) {
                    return cachedResult;
                }
                const myValues = {
                    get fontSize() { return 14; },
                };

                myThis.resolved[key] = myThis.mergeFonts([ myThis.fonts.default, myValues, myThis.getOverrideFontObject(`${limb}.${name}`) ]);
                return myThis.resolved[key];
            },

            get loginSettingsLabel() {
                const name = 'loginSettingsLabel';
                const key = myThis.getKey(name, limb);
                const cachedResult = myThis.resolved[key];
                if (cachedResult) {
                    return cachedResult;
                }
                const myValues = {
                    get fontSize() { return 14; },
                };

                myThis.resolved[key] = myThis.mergeFonts([ myThis.fonts.default, myValues, myThis.getOverrideFontObject(`${limb}.${name}`) ]);
                return myThis.resolved[key];
            },

            get logoutPrompt() {
                const name = 'logoutPrompt';
                const key = myThis.getKey(name, limb);
                const cachedResult = myThis.resolved[key];
                if (cachedResult) {
                    return cachedResult;
                }
                const myValues = {
                    get fontSize() { return 18; },
                    get textAlign() { return 'center'; },
                    get letterSpacing() { return 0.36; },
                    get color() { return XALT_LIGHT_BLACK; },
                };

                myThis.resolved[key] = myThis.mergeFonts([ myThis.fonts.default, myValues, myThis.getOverrideFontObject(`${limb}.${name}`) ]);
                return myThis.resolved[key];
            },

            get menu() {
                const name = 'menu';
                const key = myThis.getKey(name, limb);
                const cachedResult = myThis.resolved[key];
                if (cachedResult) {
                    return cachedResult;
                }
                const myValues = {
                    get color() { return myThis.colors.fontSecondary; },
                };

                myThis.resolved[key] = myThis.mergeFonts([ myThis.fonts.default, myValues, myThis.getOverrideFontObject(`${limb}.${name}`) ]);
                return myThis.resolved[key];
            },

            get menuTextSeparator() {
                const name = 'menuTextSeparator';
                const key = myThis.getKey(name, limb);
                const cachedResult = myThis.resolved[key];
                if (cachedResult) {
                    return cachedResult;
                }
                const myValues = {
                    get color() { return myThis.colors.alternate3; },
                    get fontSize() { return myThis.fonts.fontSizeMidSmall; },
                    get fontWeight() { return '200'; },
                };

                myThis.resolved[key] = myThis.mergeFonts([ myThis.fonts.default, myValues, myThis.getOverrideFontObject(`${limb}.${name}`) ]);
                return myThis.resolved[key];
            },

            get meta() {
                const name = 'meta';
                const key = myThis.getKey(name, limb);
                const cachedResult = myThis.resolved[key];
                if (cachedResult) {
                    return cachedResult;
                }
                const myValues = {
                    get color() { return myThis.colors.alternate2; },
                    get fontWeight() { return 'bold'; },
                };

                myThis.resolved[key] = myThis.mergeFonts([ myThis.fonts.default, myValues, myThis.getOverrideFontObject(`${limb}.${name}`) ]);
                return myThis.resolved[key];
            },

            get navigationBarHeader() {
                const name = 'navigationBarHeader';
                const key = myThis.getKey(name, limb);
                const cachedResult = myThis.resolved[key];
                if (cachedResult) {
                    return cachedResult;
                }
                const myValues = {
                    get color() { return myThis.colors.fontSecondary; },
                    get fontWeight() { return 'bold'; },
                };

                myThis.resolved[key] = myThis.mergeFonts([ myThis.fonts.default, myValues, myThis.getOverrideFontObject(`${limb}.${name}`) ]);
                return myThis.resolved[key];
            },

            get panelTitle() {
                const name = 'panelTitle';
                const key = myThis.getKey(name, limb);
                const cachedResult = myThis.resolved[key];
                if (cachedResult) {
                    return cachedResult;
                }
                const myValues = {
                    get fontSize() { return myThis.fonts.fontSizeLarge; },
                };

                myThis.resolved[key] = myThis.mergeFonts([ myThis.fonts.default, myValues, myThis.getOverrideFontObject(`${limb}.${name}`) ]);
                return myThis.resolved[key];
            },

            get radioButton() {
                const name = 'radioButton';
                const key = myThis.getKey(name, limb);
                const cachedResult = myThis.resolved[key];
                if (cachedResult) {
                    return cachedResult;
                }
                const myValues = {
                    get color() { return myThis.colors.controls; },
                };

                myThis.resolved[key] = myThis.mergeFonts([ myThis.fonts.default, myValues, myThis.getOverrideFontObject(`${limb}.${name}`) ]);
                return myThis.resolved[key];
            },

            get workbenchLauncher() {
                const name = 'workbenchLauncher';
                const key = myThis.getKey(name, limb);
                const cachedResult = myThis.resolved[key];
                if (cachedResult) {
                    return cachedResult;
                }
                const myValues = {
                    get fontSize() { return 18; },
                    get alignItems() { return 'center'; },
                    get color() { return myThis.colors.fontDefault; },
                };

                myThis.resolved[key] = myThis.mergeFonts([ myThis.fonts.default, myValues, myThis.getOverrideFontObject(`${limb}.${name}`) ]);
                return myThis.resolved[key];
            },

            get changeView() {
                const name = 'changeView';
                const key = myThis.getKey(name, limb);
                const cachedResult = myThis.resolved[key];
                if (cachedResult) {
                    return cachedResult;
                }
                const myValues = {
                    get fontSize() { return 14; },
                    get alignItems() { return 'center'; },
                    get letterSpacing() { return 0.28; },
                    get color() { return XALT_SECONDARY_LIGHT_GRAY4; },
                };

                myThis.resolved[key] = myThis.mergeFonts([ myThis.fonts.default, myValues, myThis.getOverrideFontObject(`${limb}.${name}`) ]);
                return myThis.resolved[key];
            },

            get input() {
                const name = 'input';
                const key = myThis.getKey(name, limb);
                const cachedResult = myThis.resolved[key];
                if (cachedResult) {
                    return cachedResult;
                }
                const myValues = {
                    get fontSize() { return 16; },
                    get alignItems() { return 'center'; },
                    get letterSpacing() { return 0.32; },
                    get color() { return myThis.colors.fontDefault; },
                };

                myThis.resolved[key] = myThis.mergeFonts([ myThis.fonts.default, myValues, myThis.getOverrideFontObject(`${limb}.${name}`) ]);
                return myThis.resolved[key];
            },
        };
    }

    // Selectors: Drop downs, Date Time, etc
    get selectorBorderWidth() { return this.getValue('selectorBorderWidth', 1); }
    get selectorBorderStyle() { return this.getValue('selectorBorderStyle', 'solid'); }
    get selectorBorderRadius() { return this.getValue('selectorBorderRadius', 4); }
    // App
    get appLogo() { return this.getValue('appLogo', BaseTheme.appLogo); }
    get appTopLogo() { return this.getValue('appTopLogo', BaseTheme.appTopLogo); }
    get bannerLogo() { return this.getValue('bannerLogo', BaseTheme.bannerLogo); }
    get appName() { return this.getValue('appName', BaseTheme.appName); }
    get spaceMode() { return this.getValue('spaceMode', BaseTheme.spaceMode); }
    // Page
    get pageBackgroundImage() { return this.getValue('pageBackgroundImage', null); }
    get pageBackgroundColor() { return this.getValue('pageBackgroundColor', this.colors.background); }
    // Annotation Page
    get annotationPageButtonBorderColor() { return this.getValue('annotationPageButtonBorderColor', this.colors.controls); }
    get annotationPageButtonBackgroundColor() { return this.getValue('annotationPageButtonBackgroundColor', TRANSPARENT); }
    get annotationPageButtonSelectedBackgroundColor() { return this.getValue('annotationPageButtonSelectedBackgroundColor', this.colors.alternate3); }
    get annotationPageButtonIconColor() { return this.getValue('annotationPageButtonIconColor', this.colors.controls); }

    // Workbench Page
    get workbenchLauncherTitleFontSize() { return this.getValue('workbenchLauncherTitleFontSize', this.fonts.fontSizeLarge); }
    get workbenchLauncherTitleColor() { return this.getValue('workbenchLauncherTitleColor', this.colors.alternate2); }
    get workbenchLauncherTileBackgroundColor() { return this.getValue('workbenchLauncherTileBackgroundColor', this.colors.workbenchLauncherTileColor); }
    get workbenchLauncherTileBorderColor() { return this.getValue('workbenchLauncherTileBorderColor', this.colors.workbenchLauncherTileColor); }
    get workbenchLauncherTileBorderRadius() { return this.getValue('workbenchLauncherTileBorderRadius', 6); }
    get workbenchLauncherIconBackgroundColor() { return this.getValue('workbenchLauncherIconBackgroundColor', TRANSPARENT); }
    get workbenchLauncherTextBackgroundColor() { return this.getValue('workbenchLauncherTextBackgroundColor', TRANSPARENT); }
    get workbenchLauncherBackgroundColor() { return this.getValue('workbenchLauncherBackgroundColor', this.colors.background); }
    get workbenchSearchBackgroundColor() { return this.getValue('workbenchSearchBackgroundColor', this.colors.primary); }
    // Buttons
    get buttonPrimaryBackgroundColor() { return this.getValue('buttonPrimaryBackgroundColor', this.colors.buttons); }
    get buttonPrimaryBorderColor() { return this.getValue('buttonPrimaryBorderColor', this.colors.buttons); }
    get buttonSecondaryBackgroundColor() { return this.getValue('buttonSecondaryBackgroundColor', TRANSPARENT); }
    get buttonSecondaryBorderColor() { return this.getValue('buttonSecondaryBorderColor', this.colors.buttons); }
    get buttonTextAlignment() { return this.getValue('buttonTextAlignment', 'center'); }
    get buttonIconSize() { return this.getValue('buttonIconSize', this.fonts.fontSizeNormal); }
    get buttonIconPrimaryColor() { return this.getValue('buttonIconPrimaryColor', this.fonts.buttonPrimary.color); }
    get buttonIconSecondaryColor() { return this.getValue('buttonIconSecondaryColor', this.fonts.buttonSecondary.color); }
    get buttonUnderlayColor() { return this.getValue('buttonUnderlayColor', this.colors.buttons); }
    // Compact List
    get compactListAlternatingColors() { return this.getValue('compactListAlternatingColors', this.colors.listAlternatingColors); }
    get compactListDiscloseIconSize() { return this.getValue('compactListDiscloseIconSize', 40); }
    get compactListDiscloseAnimationDuration() { return this.getValue('compactListDiscloseAnimationDuration', 250); }
    get compactListDiscloseImage() { return this.getValue('compactListDiscloseImage', 'chevron-right'); }
    get compactListDiscloseIconColor() { return this.getValue('compactListDiscloseIconColor', this.colors.alternate2); }
    // CheckBox
    get checkBoxCheckedBackgroundColor() { return this.getValue('checkBoxCheckedBackgroundColor', this.colors.controls); }
    get checkBoxUnCheckedBackgroundColor() { return this.getValue('checkBoxUnCheckedBackgroundColor', this.colors.alternate3); }
    get checkBoxButtonColor() { return this.getValue('checkBoxButtonColor', this.colors.controls); }
    // App-Header (navigation bar)
    get navigationBarTintColor() { return this.getValue('navigationBarTintColor', this.colors.fontSecondary); }
    get navigationBarBackgroundColor() { return this.getValue('navigationBarBackgroundColor', this.colors.primary); }
    get navigationBarLineHeight() { return this.getValue('navigationBarLineHeight', typography.baseLineHeight); }
    get navigationBarMenuIconColor() { return this.getValue('navigationBarMenuIconColor', this.colors.fontSecondary); }
    get navigationBarMenuIconSize() { return this.getValue('navigationBarMenuIconSize', 45); }
    // Panel Header
    get panelBackgroundColor() { return this.getValue('panelBackgroundColor', this.colors.panel); }
    get panelIconColor() { return this.getValue('panelIconColor', this.colors.alternate1); }
    // Action Button
    get actionButtonBackgroundColor() { return this.getValue('actionButtonBackgroundColor', TRANSPARENT); }
    get actionIconColor() { return this.getValue('actionIconColor', this.colors.alternate2); }
    get actionIconSize() { return this.getValue('actionIconSize', 20); }
    get actionSeparatorColor() { return this.getValue('actionSeparatorColor', this.colors.alternate2); }
    get actionSeparatorTopBorderWidth() { return this.getValue('actionSeparatorTopBorderWidth', 2); }
    get actionSeparatorTextBackgroundColor() { return this.getValue('actionSeparatorTextBackgroundColor', this.colors.transparent); }
    // Drawer
    get drawerBackgroundColor() { return this.getValue('drawerBackgroundColor', this.colors.alternate1); }
    get drawerMenuItemBackgroundColor() { return this.getValue('drawerMenuItemBackgroundColor', this.colors.alternate1); }
    get drawerMenuItemPadding() { return this.getValue('drawerMenuItemPadding', 10); }
    get drawerMenuItemActiveBackgroundColor() { return this.getValue('drawerMenuItemActiveBackgroundColor', this.colors.alternate2); }
    get drawerMenuItemActiveTextColor() { return this.getValue('drawerMenuItemActiveTextColor', this.colors.fontSecondary); }
    get drawerMenuItemTextColor() { return this.getValue('drawerMenuItemTextColor', this.colors.alternate3); }
    get drawerMenuItemBorderColor() { return this.getValue('drawerMenuItemBorderColor', this.colors.alternate3); }
    get drawerMenuItemTextSize() { return this.getValue('drawerMenuItemTextSize', this.fonts.fontSizeMedium); }
    get drawerMenuItemFontFamily() { return this.getValue('drawerMenuItemFontFamily', this.fonts.fontFamily); }
    get drawerMenuItemLineHeight() { return this.getValue('drawerMenuItemLineHeight', 0); }
    get drawerMenuItemFontWeight() { return this.getValue('drawerMenuItemFontWeight', 'normal'); }
    get drawerMenuBorderWidth() { return this.getValue('drawerMenuBorderWidth', 0); }
    get drawerMenuSeparatorLineColor() { return this.getValue('drawerMenuSeparatorLineColor', this.colors.alternate3); }
    get drawerAvatarBackgroundColor() { return this.getValue('drawerAvatarBackgroundColor', COLOR_ORANGE); }
    get drawerAvatarTextColor() { return this.getValue('drawerAvatarTextColor', this.colors.fontSecondary); }
    get drawerAvatarHighlightColor() { return this.getValue('drawerAvatarHighlightColor', this.colors.alternate2); }
    get drawerLogoutButtonBackgroundColor() { return this.getValue('drawerLogoutButtonBackgroundColor', this.colors.alternate1); }
    get drawerLogoutButtonTextColor() { return this.getValue('drawerLogoutButtonTextColor', this.colors.fontSecondary); }
    get drawerExpandButtonFontSize() { return this.getValue('drawerExpandButtonFontSize', this.fonts.fontSizeLarge); }
    get drawerExpandIconFontSize() { return this.getValue('drawerExpandIconFontSize', this.fonts.fontSizeLarge); }
    // Error Panel
    get errorContainerBackgroundColor() { return this.getValue('errorContainerBackgroundColor', TRANSPARENT); }
    get errorTitleContentSeparatorColor() { return this.getValue('errorTitleContentSeparatorColor', this.colors.alternate3); }
    get errorMessageSeparatorColor() { return this.getValue('errorMessageSeparatorColor', this.colors.alternate3); }
    get errorTitleIconColor() { return this.getValue('errorTitleIconColor', this.colors.error); }
    get errorStacktraceColor() { return this.getValue('errorStacktraceColor', this.colors.alternate2); }
    get errorMessageColor() { return this.getValue('errorMessageColor', this.colors.alternate1); }
    get errorIconColor() { return this.getValue('errorIconColor', this.colors.error); }
    get warningIconColor() { return this.getValue('warningIconColor', this.colors.warning); }
    get infoIconColor() { return this.getValue('infoIconColor', this.colors.info); }
    // List
    get listHeaderSeparatorColor() { return this.getValue('listHeaderSeparatorColor', this.colors.alternate1); }
    get listLoadingIndicatorColor() { return this.getValue('listLoadingIndicatorColor', this.colors.loading); }
    get listEmptyLabelColor() { return this.getValue('listEmptyLabelColor', this.colors.alternate1); }
    get listUnderlayColor() { return this.getValue('listUnderlayColor', TRANSPARENT); }
    get listSelectedUnderlayColor() { return this.getValue('listSelectedUnderlayColor', 'rgb(174, 214, 224)'); }
    get listTapUnderlayColor() { return this.getValue('listTapUnderlayColor', colors.hexagonPallet5BlueLightes); }
    // Menu
    get menuBorderColor() { return this.getValue('menuBorderColor', this.colors.alternate1); }
    get menuBackgroundColor() { return this.getValue('menuBackgroundColor', this.colors.alternate1); }
    get menuActiveItemBackgroundColor() { return this.getValue('menuActiveItemBackgroundColor', this.colors.alternate2); }
    get menuIconSize() { return this.getValue('menuIconSize', this.fonts.fontSizeLarge); }
    get menuIconColor() { return this.getValue('menuIconColor', this.fonts.menu.color); }
    // Modal
    get modalBackgroundColor() { return this.getValue('modalBackgroundColor', this.colors.alternate1); }
    get modalShadowColor() { return this.getValue('modalShadowColor', COLOR_BLACK); }
    get modalTransparency() { return this.getValue('modalTransparency', '85'); }
    // Radio Buttons
    get radioButtonSize() { return this.getValue('radioButtonSize', 24); }
    get radioButtonSelectorColorInner() { return this.getValue('radioButtonSelectorColorInner', this.colors.controls); }
    get radioButtonSelectorColorOuter() { return this.getValue('radioButtonSelectorColorOuter', this.colors.controls); }
    get radioButtonSelectorBorderWidth() { return this.getValue('radioButtonSelectorBorderWidth', 2); }
    // Text
    get externalLink() { return this.getValue('externalLink', EXTERNAL_LINK); }
    // TextInput
    get textInputUnderlineColor() { return this.getValue('inputUnderlineColor', this.colors.controls); }
    get textInputActiveBorderColor() { return this.getValue('inputActiveBorderColor', this.colors.activeControls); }
    // Input (Search panel)
    get inputBackgroundColor() { return this.getValue('inputBackgroundColor', this.colors.panel); }
    // DateTime
    get dateTimeBorderColor() { return this.getValue('dateTimeBorderColor', this.colors.controls); }
    get dateTimeBorderWidth() { return this.getValue('dateTimeBorderWidth', this.selectorBorderWidth); }
    get dateTimeBorderStyle() { return this.getValue('dateTimeBorderStyle', this.selectorBorderStyle); }
    get dateTimeBorderRadius() { return this.getValue('dateTimeBorderRadius', this.selectorBorderRadius); }
    // Drop down
    get dropDownBorderColor() { return this.getValue('dropDownBorderColor', this.colors.controls); }
    get dropDownBorderWidth() { return this.getValue('dropDownBorderWidth', this.selectorBorderWidth); }
    get dropDownBorderStyle() { return this.getValue('dropDownBorderStyle', this.selectorBorderStyle); }
    get dropDownBorderRadius() { return this.getValue('dropDownBorderRadius', this.selectorBorderRadius); }
    get dropDownMenuBackgroundColor() { return this.getValue('dropDownMenuBackgroundColor', this.colors.alternate1); }
    get dropDownMenuTextColor() { return this.getValue('dropDownMenuTextColor', this.colors.alternate3); }
    // Image Control
    get imageSizeDetail() { return this.getValue('imageSizeDetail', 175); }
    get imageSizeList() { return this.getValue('imageSizeList', 40); }
    get imagePlaceholderColor() { return this.getValue('imagePlaceholderColor', this.colors.controls); }
    get imagePlaceholderIcon() { return this.getValue('imagePlaceholderIcon', 'insert-drive-file'); }
    get imageBrokenPlaceholderIcon() { return this.getValue('imageBrokenPlaceholderIcon', 'image-broken'); }
    // Login
    get loginBackgroundColor() { return this.getValue('loginBackgroundColor', this.colors.background); }
    get loginBackgroundImage() { return this.getValue('loginBackgroundImage', null); }
    get loginButtonPrimaryBackgroundColor() { return this.getValue('loginButtonPrimaryBackgroundColor', XALT_BLUE); }
    get loginFormPanelColor() { return this.getValue('loginFormPanelColor', this.colors.panel); }
    get loginFormTextColor() { return this.getValue('loginFormTextColor', COLOR_BLACK); }
    get loginSettingsTextColor() { return this.getValue('loginSettingsTextColor', XALT_SECONDARY_LIGHT_GRAY4); }
    get loginSettingsIconColor() { return this.getValue('loginSettingsIconColor', this.colors.loginSettingsIconColor); }
    get loginSettingsIconButtonBackgroundColor() { return this.getValue('loginSettingsIconButtonBackgroundColor', TRANSPARENT); }
    // Briefcase
    get briefcaseOnlineOfflinePanelColor() { return this.getValue('briefcaseOnlineOfflinePanelColor', this.colors.panel); }
    get briefcaseOnlineButtonBackgroundColor() { return this.getValue('briefcaseOnlineButtonBackgroundColor', this.colors.alternate3); }
    get briefcaseDeleteButtonBackgroundColor() { return this.getValue('briefcaseDeleteButtonBackgroundColor', this.colors.remove); }
    get briefcaseOnlineButtonTextColor() { return this.getValue('briefcaseOnlineButtonTextColor', this.colors.alternate1); }
    get briefcaseOfflineButtonTextColor() { return this.getValue('briefcaseOfflineButtonTextColor', this.colors.alternate1); }
    get briefcaseDeleteButtonTextColor() { return this.getValue('briefcaseDeleteButtonTextColor', this.colors.fontSecondary); }
    get briefcaseOnlineButtonBorderColor() { return this.getValue('briefcaseOnlineButtonBorderColor', this.colors.alternate2); }
    get briefcaseOfflineButtonBorderColor() { return this.getValue('briefcaseOfflineButtonBorderColor', this.colors.alternate2); }
    get briefcaseDeleteButtonBorderColor() { return this.getValue('briefcaseDeleteButtonBorderColor', this.colors.remove); }
    get briefcaseHeaderLabelBackgroundColor() { return this.getValue('briefcaseHeaderLabelBackgroundColor', this.colors.primary); }
    get briefcaseOnOffPanelBackgroundColor() { return this.getValue('briefcaseOnOffPanelBackgroundColor', this.colors.panel); }
    // Offline/online
    get transitionToOfflineBackgroundColor() { return this.getValue('transitionToOfflineBackgroundColor', TRANSPARENT); }
    get transitionToOnlineBackgroundColor() { return this.getValue('transitionToOnlineBackgroundColor', TRANSPARENT); }
    get onlineOfflineProgressColor() { return this.getValue('onlineOfflineProgressColor', COLOR_DARK_BLUE); }
    get onlineOfflineProgressUnfilledColor() { return this.getValue('onlineOfflineProgressUnfilledColor', COLOR_LIGHT_BLUE); }
    get goingOnlineImageColor() { return this.getValue('goingOnlineImageColor', COLOR_GREEN); }
    get goingOfflineImageColor() { return this.getValue('goingOfflineImageColor', COLOR_RED); }
    // Page loading
    get pageLoadingIndicatorColor() { return this.getValue('pageLoadingIndicatorColor', this.colors.loading); }
    get pageLoadingIndicatorSize() { return this.getValue('pageLoadingIndicatorSize', 'large'); }
    get statusLoadingIndicatorColor() { return this.getValue('statusLoadingIndicatorColor', this.colors.loading); }
    get statusLoadingBackgroundColor() { return this.getValue('statusLoadingBackgroundColor', this.colors.panel); }
    // Search/Sort dialog
    get searchSortPopupBackgroundColor() { return this.getValue('searchSortPopupBackgroundColor', this.colors.panel); }
    get searchSortRemoveToolFontSize() { return this.getValue('searchSortRemoveToolFontSize', 35); }
    get searchSortRemoveToolColor() { return this.getValue('searchSortRemoveToolColor', this.colors.remove); }
    // Settings
    get settingsBackgroundColor() { return this.getValue('settingsBackgroundColor', this.colors.background); }
    // Data Table
    get dataTableHeadingBackgroundColor() { return this.getValue('dataTableHeadingBackgroundColor', 'white'); }
    get dataTableHeadingBackgroundFilteredColor() { return this.getValue('dataTableHeadingBackgroundFilteredColor', this.colors.alternate3); }
    get dataTableHeadingIconColor() { return this.getValue('dataTableHeadingIconColor', 'rgba(0, 0, 0, 0.6)'); }

    get dataTableListAlternatingColors() { return this.getValue('dataTableListAlternatingColors', this.colors.listAlternatingColors); }
    get dataTableListFixedAlternatingColors() { return this.getValue('dataTableListFixedAlternatingColors', '#EBF7FA, #DFEAED'); }
    get dataTableFrozenColumnSeparatorBackgroundColor() { return this.getValue('dataTableFrozenColumnSeparatorBackgroundColor', this.colors.alternate1); }
    get dataTableGridBorderColor() { return this.getValue('dataTableGridBorderColor', 'rgba(0, 0, 0, 0.1)'); }
    get dataTablePreLoadBackgroundColor() { return this.getValue('dataTablePreLoadBackgroundColor', this.colors.panel); }
    // Calendar
    get calendarFontFamily() { return this.getValue('calendarFontFamily', 'Roboto'); }
    get calendarBackgroundColor() { return this.getValue('calendarBackgroundColor', colors.white); }
    get calendarTextColor() { return this.getValue('calendarTextColor', '#959595'); }
    get appointmentBackgroundColor() { return this.getValue('appointmentBackgroundColor', '#3BB0C9'); }
    get appointmentTextColor() { return this.getValue('appointmentTextColor', this.colors.fontSecondary); }
    get calendarPickerCellColor() { return this.getValue('calendarPickerCellColor', 'rgba(0, 0, 0, 0.6)'); }
    get calendarPickerFontSize() { return this.getValue('calendarPickerFontSize', this.fonts.fontSizeMedium - 1); }
    get calendarPickerFontWeight() { return this.getValue('calendarPickerFontWeight', 'normal'); }
    get calendarSelectedDayBackgroundColor() { return this.getValue('calendarSelectedDayBackgroundColor', COLOR_BLACK); }
    get calendarSelectedDayColor() { return this.getValue('calendarSelectedDayColor', COLOR_WHITE); }
    get calendarSeparatorColor() { return this.getValue('calendarSeparatorColor', COLOR_BLACK); }
    // Copy Right
    get copyRightBackgroundColor() { return this.getValue('copyRightBackgroundColor', this.colors.alternate1); }
    // License
    get licenseBackground() { return this.getValue('licenseBackground', this.colors.panel); }
    // Avatar - NOT USED
    get avatarLineHeight() { return this.getValue('avatarLineHeight', calcLineHeight(typography.baseMetaSize + 26)); }
    get avatarFontFamily() { return this.getValue('avatarFontFamily', this.fonts.fontFamily); }
    get avatarFontSize() { return this.getValue('avatarFontSize', typography.baseMetaSize + 23); }
    get avatarFontAlign() { return this.getValue('avatarFontAlign', 'center'); }
    get avatarFontAlignVertical() { return this.getValue('avatarFontAlignVertical', 'center'); }
    get avatarFontWeight() { return this.getValue('avatarFontWeight', 'normal'); }
    // AppBar
    get appBarButtonMargin() { return this.getValue('appBarButtonMargin', 1); }
    get appBarButtonBackgroundColor() { return this.getValue('appBarButtonBackgroundColor', TRANSPARENT); }
    get appBarButtonPadding() { return this.getValue('appBarButtonPadding', 8); }
    get appBarButtonBorderRadius() { return this.getValue('appBarButtonBorderRadius', 4); }
    get appBarButtonMinWidth() { return this.getValue('appBarButtonMinWidth', 10); }
    get appBarButtonHooverBackgroundColor() { return this.getValue('appBarButtonHooverBackgroundColor', TRANSPARENT); }
    get appBarButtonWidth() { return this.getValue('appBarButtonWidth', 16); }
    // Form Toolbar
    get formActionBarBackgroundColor() { return this.getValue('formActionBarBackgroundColor', COLOR_WHITE); }
    /**
     * NOTE: This is not the ideal situation moving forward to exclusively define these theme values
     * inidividually for HTLM and mobile. This is very temporary until we can refactor themes and pull
     * those concepts into Salt.
     */
    // Details HTML
    get detailRowVerticalAlignment() { return this.getValue('detailRowVerticalAlignment', 'top'); }
    get detailColumnVerticalAlignment() { return this.getValue('detailRowVerticalAlignment', 'top'); }
    get detailSectionBackgroundColor() { return this.getValue('detailSectionBackgroundColor', colors.white); }
    // get detailAttributeBackgroundColor() { return this.getValue('detailAttributeBackgroundColor', '#f7f7f7'); }
    // get detailAttributeHooverBackgroundColor() { return this.getValue('detailAttributeHooverBackgroundColor', '#f7f7f7'); }
    // DropDown HTML
    get htmlDropDownBorderColor() { return this.getValue('htmlDropDownBorderColor', colors.hexagonPallet27GreyLightest); }
    get htmlDropDownBorderWidth() { return this.getValue('htmlDropDownBorderWidth', this.selectorBorderWidth); }
    get htmlDropDownBorderStyle() { return this.getValue('htmlDropDownBorderStyle', this.selectorBorderStyle); }
    get htmlDropDownBorderRadius() { return this.getValue('htmlDropDownBorderRadius', 4); }
    get htmlDropDownMenuBackgroundColor() { return this.getValue('htmlDropDownMenuBackgroundColor', this.colors.alternate1); }
    get htmlDropDownMenuTextColor() { return this.getValue('htmlDropDownMenuTextColor', this.colors.alternate3); }
    // DateTime HTML
    get htmlDateTimeBorderColor() { return this.getValue('htmlDateTimeBorderColor', colors.hexagonPallet27GreyLightest); }
    get htmlDateTimeBorderWidth() { return this.getValue('htmlDateTimeBorderWidth', this.selectorBorderWidth); }
    get htmlDateTimeBorderStyle() { return this.getValue('htmlDateTimeBorderStyle', this.selectorBorderStyle); }
    get htmlDateTimeBorderRadius() { return this.getValue('htmlDateTimeBorderRadius', 0); }
    // Mobile Tab's color and customisation
    get mobileTabBarBGColor() { return this.getValue('mobileTabBarBGColor'); }
    get mobileTabBarActiveTextColor() { return this.getValue('mobileTabBarActiveTextColor'); }
    get mobileTabBarInActiveTextColor() { return this.getValue('mobileTabBarInActiveTextColor'); }
    get mobileTabBarUnderlineColor() { return this.getValue('mobileTabBarUnderlineColor', '#000080'); }
    get mobileTabBarTextFontFamily() { return this.getValue('mobileTabBarTextFontFamily', this.fonts.fontFamily); }
    get mobileTabBarTextFontSize() { return this.getValue('mobileTabBarTextFontSize', this.fonts.fontSizeMedium); }
    get mobileTabBarUnderlineHeight() { return this.getValue('mobileTabBarUnderlineHeight', 3); }
}
