/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { XaltInputBase, XaltToolTip, XaltClear, XaltIconButton, XaltInputAdornment, XaltBox } from 'xalt-react-atoms';

/**
 * A platform component for building a text label
 */
export default class RWTextFieldWithColorPalette extends Component {
    static propTypes = {
        style: PropTypes.object,
        value: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
            PropTypes.bool,
        ]),
        tipText: PropTypes.string,
        onValueChanged: PropTypes.func,
        xStyle: PropTypes.object,
        regxValidationPattern: PropTypes.string,
        isReadMode: PropTypes.bool,
        isFocused: PropTypes.bool,
        maxLength: PropTypes.number,
        type: PropTypes.string,
        propertyRef: PropTypes.object,
    };

    static defaultProps = {
        style: {},
    };

    constructor(props) {
        super(props);
        this.handleOnClearClick = this.handleOnClearClick.bind(this);
        this.textRef = props.propertyRef || React.createRef();
    }

    render() {
        // Get children prop for text
        const {
            style,
            value,
            tipText,
            isReadMode,
            isFocused,
            onValueChanged,
            xStyle,
            type,
            regxValidationPattern,
            maxLength } = this.props;
        const {
            input,
            clearIcon = {},
            colorPalette = {},
            ellipsizeMode } = xStyle || { input: {} };
        let textOverflow = {};
        if (ellipsizeMode) {
            if (ellipsizeMode === 'tail') {
                textOverflow = { textOverflow: 'ellipses' };
            }
            if (ellipsizeMode === 'head') {
                textOverflow = { textOverflow: 'ellipses', direction: 'rtl' };
            }
            if (ellipsizeMode === 'clip') {
                textOverflow = { textOverflow: 'clip' };
            }
        }

        const componentStyle = { ...style, ...input, ...textOverflow };
        const {
            display,
            flexGrow,
            flexShrink,
            width,
            height } = style;
        const tileStyle = {
            ...colorPalette,
            background: value,
        };
        if (!value) {
            tileStyle.display = 'none';
        }

        const tipStyle = {
            display: display || 'flex',
            flexGrow,
            flexShrink,
            width,
            height,
        };
        return (
            <XaltToolTip
                style={ { ...tipStyle } }
                tipText={ tipText }>
                <XaltBox style={ tileStyle } />
                <XaltInputBase
                    style={ { display: 'flex', ...componentStyle } }
                    endAdornment={ this.renderEndAdornment(isReadMode, clearIcon, value) }
                    disabled={ isReadMode }
                    maxLength={ maxLength }
                    multiline={ false }
                    onValueChanged={ onValueChanged }
                    readOnly={ isReadMode }
                    value={ value ?? '' }
                    type={ type }
                    regxValidationPattern={ regxValidationPattern }
                    autoFocus={ isFocused }
                    inputRef={ this.textRef }>
                    { value }
                </XaltInputBase>
            </XaltToolTip>
        );
    }

    renderEndAdornment(isReadOnly, iconStyle, value) {
        const { fontSize } = iconStyle;
        return (
            <XaltInputAdornment style={ { visibility: (isReadOnly || !value) ? 'hidden' : 'visible' } }>
                <XaltIconButton
                    onClick={ this.handleOnClearClick }
                    style={ iconStyle }>
                    <XaltClear
                        style={ { fontSize } } />
                </XaltIconButton>
            </XaltInputAdornment>
        );
    }

    handleOnClearClick() {
        const { onValueChanged } = this.props;
        this.textRef.current.focus();
        onValueChanged('');
    }
}
