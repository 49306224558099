import { GENERAL, GENERAL_FLEX } from '../../exportClassConstants';
import SimpleStringAttribute from '../SimpleStringAttribute';

const VALUES = {
    FLEX_START: 'flexStart',
    FLEX_END: 'flexEnd',
    CENTER: 'center',
    SPACE_BETWEEN: 'spaceBetween',
    SPACE_AROUND: 'spaceAround',
};

export default class JustifyContent extends SimpleStringAttribute {
    isLegacy ;
    constructor(modernValue, legacyValue) {
        const value = ((modernValue || legacyValue));
        super('justify-content', (value), [ GENERAL, GENERAL_FLEX ], false);
        this.isLegacy = !!legacyValue;
    }

    static newAttribute = (warnings, modernValue, legacyValue) => {
        const result = new JustifyContent(modernValue, legacyValue);
        if (result.asResolvedValue() === null) {
            warnings.addInvalidValue(result.isLegacy ? 'distribute' : 'justifyContent', result.value);
            return null;
        }
        // Check if V2 is using a dated V1 value.  left/right/top/bottom are acceptable aliases but
        // full and even need to be converted to the modern value.
        if (modernValue === 'even') {
            warnings.addV2AttributeValueWarning('justifyContent', modernValue, VALUES.SPACE_AROUND);
        } else if (modernValue === 'full') {
            warnings.addV2AttributeValueWarning('justifyContent', modernValue, VALUES.SPACE_BETWEEN);
        }
        return result;
    }

    asResolvedValue = () => {
        // Resolve names and aliases
        if (this.value === 'left' || this.value === 'top' || this.value === VALUES.FLEX_START) {
            return 'flex-start';
        } if (this.value === 'right' || this.value === 'bottom' || this.value === VALUES.FLEX_END) {
            return 'flex-end';
        } if (this.value === VALUES.CENTER) {
            return 'center';
        } if (this.value === 'full' || this.value === VALUES.SPACE_BETWEEN) {
            return 'space-between';
        } if (this.value === 'even' || this.value === VALUES.SPACE_AROUND) {
            return 'space-around';
        }
        return null;
    }

    exportTo(context, styleSet) {
        const { flexPolicy } = styleSet;
        if (SimpleStringAttribute.hasConstantRef(this.value)) {
            // Would not need to report if we converted left/right/top/bottom to start/end.
            SimpleStringAttribute.reportExportIssue('justifyContent', this.value);
        }
        flexPolicy.justifyContent = this.asResolvedValue();
    }
}
