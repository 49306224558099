import { Log } from 'cv-dialog-sdk';
import SessionStore from './SessionStore';
import UIStore from './UIStore';
import ThemeStore from './ThemeStore';
import SettingsStore from './SettingsStore';
import CacheStore from './CacheStore';

class RootStore {
    cacheStore;
    sessionStore;
    uiStore;
    settingsStore;
    themeStore;

    constructor() {
        this.cacheStore = new CacheStore();
        this.sessionStore = new SessionStore(this);
        this.uiStore = new UIStore(this);
        this.settingsStore = new SettingsStore(this);
        this.themeStore = new ThemeStore(this);

        this.appRestoredPromise = new Promise((resolve) => {
            this.restoreApp().then(resolve);
        });
    }

    restoreApp = () => {
        return this.cacheStore.cacheRestoredPromise.then((cacheData) => {
            this.settingsStore.restoreFromCache(cacheData);
            this.uiStore.restoreFromCache(cacheData);
            this.sessionStore.restoreFromCache(cacheData);
            this.themeStore.restoreFromCache(cacheData);
        }).catch(err => {
            if (err) {
                Log.debug(err.stack);
                Log.debug(err);
            }
        });
    }
}

const rootStore = new RootStore();
export default rootStore;
