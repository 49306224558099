// /**
//  * Helper method to get the styles needed to wrap the text for given number of lines
//  * @param {number} numberOfLines - Number of lines text should display
//  */
const getNumberOfLinesStyles = (numberOfLines) => {
    return {
        'display': '-webkit-box',
        'WebkitBoxOrient': 'vertical',
        'WebkitLineClamp': numberOfLines.toString(),
        'overflow': 'hidden',
        'whiteSpace': 'pre-wrap',
        overflowWrap: 'break-word',
    };
};

/**
* Helper method to get the styles of textlabel element
* @param {object} style - styles for the text label
* @param {number} numberOfLines - Number of lines text should display
*/
const getStyles = (style = {}, numberOfLines = 0) => {
    return {
        ...style,
        ...(numberOfLines > 1 ? getNumberOfLinesStyles(numberOfLines) : {}),
    };
};

export default getStyles;
