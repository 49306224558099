/*       */

import { GENERAL } from '../../exportClassConstants';
import MutedLayoutAttribute from '../MutedLayoutAttribute';

/**
 * Strict Mode Exceptions is a list of elements/attributes may be listed to be ignored.
 * A value of "true, newAttribute" will report everything except newAttribute being reported as an
 * unknown attribute.
 */
export default class StrictModeExceptions extends MutedLayoutAttribute {
    splitNamesCache ;
    constructor(value) {
        super('strictModeExceptions', value, [ GENERAL ], true);
    }

    shouldReportAttribute = (attributeName) => {
        return !this.contains(attributeName);
    }

    shouldReportElement = (elementName) => {
        return !this.contains(elementName);
    }

    contains = (name) => {
        return this.splitNames().includes(name);
    }

    splitNames = () => {
        if (!this.splitNamesCache) { this.splitNamesCache = (this.value || '').split(','); }
        return this.splitNamesCache;
    }
}
