import { rootStore } from 'cv-react-core';

// Core component styles
const coreStyles = {
    container: {
        marginRight: 8,
        backgroundColor: 'transparent',
    },

    contentContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: '2px',
        marginVertical: 4,
    },

    highlightedContainer: {
        borderRadius: 3,
        backgroundColor: '#ccc',
    },

    iconContainer: {
    },

    icon: {
        // width: 20,
        height: 20,
    },

    text: {
    },
};

/**
 * Helper method to get the styles for the component
 * @param {Object} contextStyles - Context styles for the Component
 */
const getStyles = (contextStyles = {}) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    return {
        container: {
            ...coreStyles.container,
            backgroundColor: theme.actionButtonBackgroundColor,
            ...contextStyles.container,
        },

        contentContainer: {
            ...coreStyles.contentContainer,
            ...contextStyles.contentContainer,
        },

        highlightedContainer: {
            ...coreStyles.container,
            backgroundColor: theme.actionButtonBackgroundColor,
            ...contextStyles.container,
            ...coreStyles.highlightedContainer,
            ...contextStyles.highlightedContainer,
        },

        iconContainer: {
            ...coreStyles.iconContainer,
            ...contextStyles.iconContainer,
        },

        icon: {
            ...coreStyles.icon,
            ...contextStyles.icon,
        },

        text: {
            ...coreStyles.text,
            ...theme.fonts.actionButtonLabel,
            ...contextStyles.text,
        },
    };
};

export default getStyles;
