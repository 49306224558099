import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import echarts from 'echarts';
import getStyles from './ChartWrapper.styles';

export default class ChartWrapper extends PureComponent {
    /** TODO: Can be moved to core, which can be used by native and html. Check with Alan. */
    static defaultColors = [
        '#F56262',
        '#7B80EC',
        '#F5C162',
        '#F5A862',
        '#7DCA65',
        '#00BA9E',
        '#78B5A7',
        '#7BA6EC',
        '#0097BA',
        '#8DD0DF',
        '#EC7BA6',
    ];

    constructor(props) {
        super(props);

        // the echarts object.
        this.echartsLib = echarts;

        // echarts div element
        this.echartsElement = null;
        this.handleReSizeBrowser = this.handleReSizeBrowser.bind(this);
    }

    render() {
        const {
            contextStyles,
            testID,
        } = this.props;

        const styles = getStyles(contextStyles);

        // Generate container props
        const containerProps = {
            style: styles.container,
        };

        if (testID) {
            containerProps.testID = `${testID}__container`;
        }

        return (
            <div
                ref={ (e) => { this.echartsElement = e; } }
                { ...containerProps } />
        );
    }

    componentDidUpdate(prevProps) {
        const { option } = this.props;
        const { option: prevOption } = prevProps;
        const prevPropString = JSON.stringify(prevOption);
        const optionString = JSON.stringify(option);
        if (prevPropString.localeCompare(optionString) !== 0) {
            const chart = this.getEchartsInstance();
            chart.setOption(option, true, false);
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleReSizeBrowser);
        this.mountChartDom();

        // added this logic to make chart calculate height correctly w.r.t parent Div and set.
        window.dispatchEvent(new Event('resize'));
    }

    componentWillUnmount() {
        this.dispose();
    }

    // return the echart object
    getEchartsInstance = () => {
        const {
            theme,
            opts,
        } = this.props;

        return this.echartsLib.getInstanceByDom(this.echartsElement) ||
            this.echartsLib.init(this.echartsElement, theme, opts);
    }

    // Handle browser resize event
    handleReSizeBrowser = () => {
        if (this.echartObj) {
            this.echartObj.resize();
        }
    }

    // dispose echarts and clear size-sensor
    dispose = () => {
        if (this.echartsLib) {
            window.removeEventListener('resize', this.handleReSizeBrowser);

            // dispose echarts instance
            this.echartsLib.dispose(this.echartsElement);
        }
    };

    // Mount the E chart DOM.
    mountChartDom = () => {
        const {
            notMerge,
            lazyUpdate,
            onPress,
            onSelectItem,
            option,
            initDrag,
        } = this.props;

        // init the echart object
        this.echartObj = this.getEchartsInstance();

        // set the echart option
        this.echartObj.setOption(option, notMerge || false, lazyUpdate || false);

        if (onPress) {
            this.echartObj.on('dblclick', (e) => {
                const {
                    data,
                } = e;
                if (e.dimensionNames && Array.isArray(data)) {
                    const index = e.dimensionNames.findIndex((dim) => dim === 'id');
                    onPress({
                        ...data,
                        'id': data[index],
                    }, e.event);
                }
                else {
                    onPress(data, e.event);
                }
            });
        }

        // on click handler for a pie/bar/(point on a line chart).
        if (onSelectItem) {
            this.echartObj.on('click', (target) => {
                // There's no API to fetch selected graph element, Cannot save DOM element to store
                // Refreshing chart instance or fetching selected element from chart instance is not performant
                // This is the optimal way to deselect previous graph element
                if (this.prevTarget) {
                    this.echartObj.dispatchAction({
                        type: 'downplay',
                        seriesIndex: this.prevTarget.seriesIndex,
                        dataIndex: this.prevTarget.dataIndex,
                    });
                }
                this.echartObj.dispatchAction({
                    type: 'highlight',
                    seriesIndex: target.seriesIndex,
                    dataIndex: target.dataIndex,
                });
                this.prevTarget = target;
                const {
                    data,
                } = target;
                if (target.dimensionNames && Array.isArray(data)) {
                    const index = target.dimensionNames.findIndex((dim) => dim === 'id');
                    onSelectItem({
                        ...data,
                        'id': data[index],
                    }, target.event);
                }
                else {
                    onSelectItem(data, target.event);
                }
            });
        }

        if (initDrag) {
            initDrag(this.echartObj);
        }
    }
}

ChartWrapper.propTypes = {
    /**
     * Configuration item and data.
     * @see https://echarts.apache.org/en/api.html#echartsInstance.setOption
     */
    option: PropTypes.object.isRequired,

    /**
     * On Press handler of a point in a chart
     */
    onPress: PropTypes.func,

    /**
     * On Select handler of a point in a chart
     */
    onSelectItem: PropTypes.func,

    /**
     * Optional; states whether not to merge with previous option; false by defualt, stating merging.
     * @see https://echarts.apache.org/en/api.html#echartsInstance.setOption
     */
    notMerge: PropTypes.bool,

    /**
     * Optional; states whether not to update chart immediately; false by defualt, stating update immediately.
     * @see https://echarts.apache.org/en/api.html#echartsInstance.setOption
     */
    lazyUpdate: PropTypes.bool,

    /**
     * Theme to be applied.
     * @see https://echarts.apache.org/en/api.html#echartsInstance.setOption
     */
    theme: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),

    /**
     * Optional chart configurations
     * @see https://echarts.apache.org/en/api.html#echarts.init
     */
    opts: PropTypes.shape({
        /**
         * Ratio of one physical pixel to the size of one device independent pixels. Browser's window.devicePixelRatio is used by default.
         * @see https://echarts.apache.org/en/api.html#echarts.init
         */
        devicePixelRatio: PropTypes.number,

        /**
         * Canvas or Svg rendering
         * @see https://echarts.apache.org/en/api.html#echarts.init
         */
        renderer: PropTypes.oneOf([
            'canvas',
            'svg',
        ]),

        /**
         * Specify width explicitly, in pixel. If setting to null/undefined/'auto', width of dom (instance container) will be used.
         * @see https://echarts.apache.org/en/api.html#echarts.init
         */
        width: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.oneOf([
                null,
                undefined,
                'auto',
            ]),
        ]),

        /**
         * Specify height explicitly, in pixel. If setting to null/undefined/'auto', height of dom (instance container) will be used.
         * @see https://echarts.apache.org/en/api.html#echarts.init
         */
        height: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.oneOf([
                null,
                undefined,
                'auto',
            ]),
        ]),
    }),

    /** Styles for the component */
    contextStyles: PropTypes.shape({
        /** Styles for the container */
        container: PropTypes.object,
    }),

    /** Id used for testing */
    testID: PropTypes.string,

    /** initDrag is used to add DragDrop funtionality to chart */
    initDrag: PropTypes.func,
};

ChartWrapper.defaultProps = {
    notMerge: false,
    lazyUpdate: false,
    theme: null,
    opts: {
    },
};