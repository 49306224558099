/**
 * If a style does not fit into a 'text' category then it is assumed to be a 'container' style
 * @type {String[]}
 */
const WEB_TEXT_STYLES = [
    'color',
    'direction',
    'font',
    'font-family',
    'fontFamily',
    'font-size',
    'fontSize',
    'font-stretch',
    'fontStretch',
    'font-style',
    'fontStyle',
    'font-variant',
    'fontVariant',
    'font-weight',
    'fontWeight',
    'letter-spacing',
    'letterSpacing',
    'line-height',
    'lineHeight',
    'text-align',
    'textAlign',
    'text-align-last',
    'textAlignLast',
    'text-decoration-line',
    'textDecorationLine',
    'text-decoration',
    'textDecoration',
    'text-indent',
    'textIndent',
    'text-justify',
    'textJustify',
    'text-shadow',
    'textShadow',
    'text-transform',
    'textTransform',
    'text-overflow',
    'textOverflow',
    'unicode-bidi',
    'unicodeBidi',
    'unicode-range',
    'unicodeRange',
    'vertical-align',
    'verticalAlign',
    'word-break',
    'wordBreak',
    'white-space',
    'whiteSpace',
    'word-spacing',
    'wordSpacing',
    'word-wrap',
    'wordWrap',
    'writing-mode',
    'writingMode',
];

/**
 * If a style does not fit into a 'text' category then it is assumed to be a 'container' style
 * @type {String[]}
 */
const NATIVE_TEXT_STYLES = [
    'textShadowOffset',
    'color',
    'fontSize',
    'fontStyle',
    'fontWeight',
    'lineHeight',
    'textAlign',
    'textDecorationLine',
    'textShadowColor',
    'fontFamily',
    'textShadowRadius',
    'includeFontPadding',
    'textAlignVertical',
    'fontVariant',
    'letterSpacing',
    'textDecorationColor',
    'textDecorationStyle',
    'textTransform',
    'writingDirection',
];

/**
 * Categorizes provided styles into 'container' and 'text' styles
 * @param {Object} styles
 * @param {Array} category
 * @return {{ container: Object, text: Object }}
 */
const categorizeStyles = (styles, category) => {
    const textStyles = {};
    const containerStyles = {};

    Object.keys(styles).forEach((style) => {
        if (category.find((categoryStyle) => (style === categoryStyle))) {
            textStyles[style] = styles[style];
        } else {
            containerStyles[style] = styles[style];
        }
    });

    return {
        // TODO: This 'text' style object needs to be replaced with the usage of featuredElement so we
        // do not have to hardcode types and make this more generic.
        text: textStyles,
        container: containerStyles,
        // TODO: Need to refactor 'text' to a more generic type of featuredElement.
        // featured element will be the definition of the core element the style should be applied too.
        // the purpose of the container is simply a wrapper if needed to surround the featured element.
        featuredElement: textStyles,
    };
};

/**
 * Categorizes provided web styles into 'container' and 'text' styles
 * @param {Object} styles
 * @return {{ container: Object, text: Object }}
 */
const categorizeWebStyles = (styles) => {
    return categorizeStyles(styles, WEB_TEXT_STYLES);
};

/**
 * Categorizes provided native styles into 'container' and 'text' styles
 * @param {Object} styles
 * @return {{ container: Object, text: Object }}
 */
const categorizeNativeStyles = (styles) => {
    return categorizeStyles(styles, NATIVE_TEXT_STYLES);
};

const getRGBAColor = (color, opacity = 1) => {
    if (!color.includes('rgb')) {
        const [ r, g, b ] = color.match(/\w\w/g).map(x => parseInt(x, 16));
        return `rgba(${r},${g},${b},${opacity})`;
    }
    if (color.indexOf('rgba') === -1) {
        return color.replace(')', `, ${opacity})`).replace('rgb', 'rgba');
    }
    const RGBA = color.split(',');
    return `${RGBA[0]},${RGBA[1]},${RGBA[2]},${opacity})`;
};

const styleHelper = {
    categorizeWebStyles,
    categorizeNativeStyles,
    getRGBAColor,
};

export default styleHelper;
