import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';

import MUIAppBar from '@material-ui/core/AppBar';
import MUITabs from '@material-ui/core/Tabs';
import MUITab from '@material-ui/core/Tab';

import getStyles from './Tabs.styles';

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => {
    return {
        root: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
        },
    };
});

const ScrollableTabs = (props) => {
    const {
        contextStyles,
        initialTab,
        onChange,
        tabs,
        testID,
    } = props;
    const classes = useStyles();
    const [
        value,
        setValue,
    ] = useState(initialTab || 0);

    // Update initial tab on props change
    useEffect(() => {
        setValue(initialTab);
    }, [ initialTab ]);

    // Override core styles with context styles, separating MUI styles
    const {
        container: containerStyles,
        selectedTab: selectedTabStyles,
        ...muiStyles
    } = getStyles(contextStyles);

    // Create dynamic class names and injected DOM styles for MUI component
    // * makeStyles returns a function
    const styles = makeStyles({
        ...muiStyles,
        root: selectedTabStyles,
    })();

    // console.log('STYLES: ', styles); // eslint-disable-line

    // Generate container props
    const containerProps = {
        className: 'c-tabs__container',
        style: containerStyles, // non-MUI styles
    };
    if (testID) { containerProps['data-test-id'] = `${testID}__tabs__container`; }

    function handleChange(event, tabId) {
        // console.log('TABS CHANGE', event, tabId); // eslint-disable-line
        setValue(tabId);
        onChange(event, tabId);
    }

    // Generate app bar props
    const appBarProps = {
        classes: {
            root: styles.appBar,
        },
        className: 'c-tabs__bar',
        color: 'default',
        position: 'static',
    };
    if (testID) { appBarProps['data-test-id'] = `${testID}__tabs__bar`; }

    // Generate tabs props
    const tabsProps = {
        classes: {
            indicator: styles.indicator,
        },
        className: 'c-tabs__tabs',
        color: 'default',
        position: 'static',
        value,
        onChange: handleChange,
        indicatorColor: 'primary',
        textColor: 'primary',
        variant: 'scrollable',
        scrollButtons: 'auto',
        'aria-label': 'scrollable auto tabs example',
    };
    if (testID) { tabsProps['data-test-id'] = `${testID}__tabs__tabs`; }

    // console.log('TABS PROPS', tabsProps); // eslint-disable-line

    return (
        <div className={ classes.root }>
            <MUIAppBar { ...appBarProps }>
                <MUITabs { ...tabsProps }>
                    { tabs.map((tab, tabIdx) => {
                        const {
                            id,
                            label,
                        } = tab;
                        const tabProps = {
                            classes: {
                                root: styles.root,
                            },
                            className: 'c-tabs__tab',
                            wrapped: true,
                            label,
                            value: id,
                            ...a11yProps(tabIdx),
                        };
                        if (value === id) { tabProps.classes.root = styles.root; }
                        if (testID) { tabProps['data-test-id'] = `${testID}__tabs__tab`; }
                        return (
                            <MUITab
                                { ...tabProps }
                                key={ id } />
                        );
                    }) }
                </MUITabs>
            </MUIAppBar>
        </div>
    );
};

ScrollableTabs.propTypes = {
    /** Styles for this component */
    contextStyles: PropTypes.shape({
        /** Styling for the main container */
        container: PropTypes.object,
    }),

    /** Initial tab ID to display */
    initialTab: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
    ]),

    /**
     * Called when the selected tab changes
     * @param {Object} event
     * @param {Number} id
     */
    onChange: PropTypes.func,

    /** */
    tabs: PropTypes.arrayOf(PropTypes.shape({
        /** Text to display as the tab label */
        label: PropTypes.string,

        /** ID used to specify the tab */
        id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
    })).isRequired,

    /** Id used for testing */
    testID: PropTypes.string,
};

ScrollableTabs.defaultProps = {
    initialTab: false,
    contextStyles: {},
};

export default ScrollableTabs;
