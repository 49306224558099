/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { XaltDropDownMultiSelect } from 'xalt-react-atoms';

/**
 * A platform component for building a text label
 */
export default class RWDropDownMultiSelectField extends Component {
    static propTypes = {
        style: PropTypes.object,
        xStyle: PropTypes.object,
        value: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                ]),
            })),
            PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                ]),
            }),
        ]),
        tipText: PropTypes.string,
        // isReadMode: PropTypes.bool,
        isFocused: PropTypes.bool,
        onValueChanged: PropTypes.func,
        // xStyle: PropTypes.object,
        optionValues: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                ]),
            }),
        ),
    };

    static defaultProps = {
        style: {},
        optionValues: [],
    };

    render() {
        const {
            isFocused,
            style,
            xStyle,
            value,
            tipText,
            onValueChanged,
            optionValues,
        } = this.props;
        const {
            expandIcon,
        } = xStyle;

        return (
            <XaltDropDownMultiSelect
                autoFocus={ isFocused }
                focusStyle={ { flexDirection: 'column', flexWrap: 'wrap', alignItems: 'flex-start' } }
                expandIconStyles={ expandIcon }
                style={ {
                    textOverflow: 'ellipsis',
                    ...style,
                } }
                onValueChanged={ onValueChanged }
                tipText={ tipText }
                value={ value }
                items={ optionValues } />
        );
    }
}
