import React from 'react';
import echarts from 'echarts';
import PropTypes from 'prop-types';
import ChartWrapper from '../ChartWrapper/ChartWrapper';
import getStyles from './SwimlaneChart.styles';

/**
* The swimlane chart shows different data through the width of a swimlane
* which is used in rectangular coordinate with at least 1 category axis.
*/
const SwimlaneChart = (props) => {
    const {
        animation,
        contextStyles,
        grouping,
        dataProps,
        gridPosition,
        hoverDetails,
        horizontal,
        isMaintainable,
        legend,
        onPress,
        onSelectItem,
        formatProp,
        minX,
        maxX,
        renderButtonText,
        seriesData,
        testID,
        title,
        tooltip,
        valueLabelFormat,
        xAxis,
        yAxis,
        zoom,
    } = props;

    const styles = getStyles(contextStyles);

    /** Build chart options */
    const chartOptions = {
        animation,
        backgroundColor: 'transparent',
        color: [ ...ChartWrapper.defaultColors ],
        grid: {
            containLabel: xAxis || yAxis,
            show: true,
            borderWidth: 0,
            ...gridPosition,
        },
        textStyle: styles.chartTextStyle,
    };

    // Set chart legend
    if (legend) {
        chartOptions.legend = {
            // Show the legend
            show: true,

            // Initialize legend series names
            data: [],
            // apply styles to legend
            textStyle: styles.legendTextStyle,

            // providing the scroll if legends doesnt fit with available area
            type: 'scroll',
            orient: horizontal ? 'vertical' : 'horizontal',
        };
    }

    // Set chart title
    if (title) {
        chartOptions.title = {
            text: title,
            textStyle: styles.titleTextStyle,
        };
    }

    // Enable chart tooltip
    if (tooltip) {
        chartOptions.tooltip = {
            confine: true,
            extraCssText: 'z-index: 10',
            textStyle: styles.tooltipTextStyle,
        };
    }

    // Enable chart zoom
    if (zoom) {
        chartOptions.dataZoom = [
            {
                type: 'slider',
                xAxisIndex: 0,
                filterMode: 'weakFilter',
                height: 20,
                bottom: 0,
                start: 0,
                end: 26,
                handleIcon: 'path://M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                handleSize: '80%',
                showDetail: true,
            },
            {
                type: 'inside',
                id: 'insideX',
                xAxisIndex: 0,
                filterMode: 'weakFilter',
                start: 0,
                end: 26,
                zoomOnMouseWheel: false,
                moveOnMouseMove: !isMaintainable,
            },
            {
                type: 'slider',
                yAxisIndex: 0,
                zoomLock: true,
                width: 15,
                right: 10,
                top: 70,
                bottom: 20,
                start: 95,
                end: 100,
                handleIcon: 'path://M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                handleSize: '80%',
                showDetail: true,
            },
            {
                type: 'inside',
                id: 'insideY',
                yAxisIndex: 0,
                start: 95,
                end: 100,
                zoomOnMouseWheel: false,
                moveOnMouseMove: !isMaintainable,
                moveOnMouseWheel: true,
            },
        ];
    }
    chartOptions.xAxis = {
        type: 'time',
        position: 'top',
        splitLine: {
            lineStyle: {
                color: [ '#E9EDFF' ],
            },
        },
        axisLine: {
            show: false,
        },
        axisTick: {
            lineStyle: {
                color: '#929ABA',
            },
        },
        axisLabel: {
            color: '#929ABA',
            inside: false,
            align: 'center',
        },
    };

    if (minX || minX === 0) { chartOptions.xAxis.min = minX; }
    if (maxX || maxX === 0) { chartOptions.xAxis.max = maxX; }

    const yAxisIncrement = -0.01;

    chartOptions.yAxis = {
        axisTick: { show: false },
        splitLine: { show: false },
        axisLine: { show: false },
        axisLabel: { show: false },
        interval: yAxisIncrement,
        min: -1,
    };

    const {
        barValueProp,
        barColorProp,
        categoryIndexProp,
        startValueProp,
        endValueProp,
        xAxisLabelProp,
    } = dataProps;

    let groupedSwimlanes = [];

    // Generate chart series data
    chartOptions.series = seriesData.map((swimlaneDataInfo, swimlaneIdx) => {
        const {
            swimlaneBorderColor: seriesBorderColor,
            swimlaneBorderWidth: seriesBorderWidth,
            swimlaneColor: seriesColor,
            swimlaneEmphasisColor: seriesEmphasisColor,
            swimlaneOpacity: seriesOpacity,
            swimlaneWidth: seriesBarWidth,
            dimensions,
            legendText,
            valueLabel: seriesValueLabel,
            valueLabelColor: seriesLabelColor,
            valueLabelFormat: seriesLabelFormat,
            valueLabelPosition: seriesLabelPosition,
            valueLabelSize: seriesLabelSize,
            valueLabelWidth: seriesLabelWidth,
            swimlaneData,
            ...swimlaneRest
        } = swimlaneDataInfo;

        // Generate swimlane series
        const swimlaneSeries = {
            data: swimlaneData,
            dimensions: dimensions.map((dimension) => {
                if (dimension === xAxisLabelProp) {
                    return {
                        name: xAxisLabelProp,
                        type: 'ordinal',
                    };
                }
                return dimension;
            }),

            encode: {
                x: [
                    startValueProp,
                    endValueProp,
                ],
                y: categoryIndexProp,
            },

            // This is a swimlane chart
            type: 'custom',

            itemStyle: {},

            // Pass through any extra props
            ...swimlaneRest,
        };

        swimlaneSeries.tooltip = {
            formatter: (params) => {
                const {
                    dimensionNames,
                    data,
                } = params;
                if (hoverDetails) {
                    const hover = hoverDetails.reduce((hoverStr, propItem) => {
                        const {
                            label,
                            propertyName,
                        } = propItem;
                        const itemIndex = dimensionNames.findIndex((dimension) => dimension === propertyName);
                        const value = data[itemIndex];
                        let formattedValue = value;
                        if (typeof value === 'object') {
                            formattedValue = formatProp(value);
                        }
                        return `${hoverStr }<tr><td>${label || ''}</td><td>${formattedValue ? `:    ${formattedValue}` : ''}</td><tr>`;
                    }, '');
                    return `<table>${hover}</table>`;
                }
                return '';
            },
        };

        // Add legend information
        if (legend && legendText) {
            // Add the legend item to the legend array
            chartOptions.legend.data.push(legendText);

            // Set the series name to link to the legend item
            swimlaneSeries.name = legendText;
        }

        // Add series level swimlane details
        if (seriesColor) {
            // Override default color spot for the legend and line symbol to reflect color
            chartOptions.color[swimlaneIdx] = seriesColor;
        }
        if (seriesBarWidth) {
            swimlaneSeries.swimlaneWidth = seriesBarWidth;
        }
        if (seriesOpacity || seriesOpacity === 0) {
            swimlaneSeries.itemStyle.opacity = seriesOpacity;
        }
        if (seriesBorderWidth || seriesBorderWidth === 0) {
            swimlaneSeries.itemStyle.swimlaneBorderWidth = seriesBorderWidth;
        }
        if (seriesBorderColor) {
            swimlaneSeries.itemStyle.swimlaneBorderColor = seriesBorderColor;
        }
        if (seriesEmphasisColor) {
            swimlaneSeries.emphasis = { itemStyle: { color: seriesEmphasisColor } };
        }

        // Add series level swimlane labels
        if (seriesValueLabel) {
            swimlaneSeries.label = {
                show: true,
            };
            if (seriesLabelColor) {
                swimlaneSeries.label.color = seriesLabelColor;
            }
            if (valueLabelFormat) {
                swimlaneSeries.label.formatter = valueLabelFormat.replace('{value}', '{c}');
            }
            if (seriesLabelFormat) {
                swimlaneSeries.label.formatter = seriesLabelFormat.replace('{value}', '{c}');
            }
            if (seriesLabelPosition) {
                swimlaneSeries.label.position = seriesLabelPosition || 'inside';
            }
            if (seriesLabelSize || seriesLabelSize === 0) {
                swimlaneSeries.label.fontSize = seriesLabelSize;
            }
            if (seriesLabelWidth || seriesLabelWidth === 0) {
                swimlaneSeries.label.width = seriesLabelWidth;
                swimlaneSeries.label.rich = {};
            }
        }

        swimlaneSeries.renderItem = (params, api) => {
            const categoryIndex = api.value(categoryIndexProp);
            const startTime = api.coord([
                api.value(startValueProp),
                categoryIndex,
            ]);
            const endTime = api.coord([
                api.value(endValueProp),
                categoryIndex,
            ]);

            const swimlaneLength = Math.abs(endTime[0] - startTime[0]);
            const swimlaneHeight = 20;
            const x = startTime[0];
            const y = startTime[1] - swimlaneHeight;

            const barValue = `${api.value(barValueProp) }`;
            const barValueWidth = echarts.format.getTextRect(barValue).width;
            const text = (swimlaneLength > barValueWidth + 40 && x + swimlaneLength >= 180)
                ? barValue : '';

            const clipRectByRect = (args, rect) => echarts.graphic.clipRectByRect(rect, {
                x: args.coordSys.x,
                y: args.coordSys.y,
                width: args.coordSys.width,
                height: args.coordSys.height,
            });

            const rectNormal = clipRectByRect(params, {
                x, y, width: swimlaneLength, height: swimlaneHeight,
            });

            const rectText = clipRectByRect(params, {
                x, y, width: swimlaneLength, height: swimlaneHeight,
            });

            const lineEnd = clipRectByRect(params, {
                x: (x + swimlaneLength), y, width: 1, height: swimlaneHeight,
            });

            const rectNormalStyle = {};
            if (api.value(barColorProp)) {
                rectNormalStyle.fill = api.value(barColorProp);
            }

            return {
                type: 'group',
                children: [
                    {
                        type: 'rect',
                        ignore: !rectNormal,
                        shape: rectNormal,
                        style: api.style(rectNormalStyle),
                    },
                    {
                        type: 'rect',
                        ignore: !rectText,
                        shape: rectText,
                        style: api.style({
                            fill: 'transparent',
                            stroke: 'transparent',
                            text,
                            textFill: '#fff',
                        }),
                    },
                    {
                        type: 'rect',
                        ignore: !lineEnd,
                        shape: lineEnd,
                        style: api.style({
                            fill: 'black',
                            stroke: 'transparent',
                            zlevel: 999999,
                        }),
                    },
                ],
            };
        };

        const {
            groupingProp,
        } = swimlaneSeries;

        const groupingIndex = swimlaneSeries.dimensions.findIndex((dimension) => {
            if (dimension === 'string') {
                return dimension === groupingProp;
            }
            if (dimension && dimension.name) {
                return dimension.name === groupingProp;
            }
            return false;
        });

        const categoryIndex = swimlaneSeries.dimensions.findIndex((dimension) => dimension === categoryIndexProp);

        if (grouping) {
            let result = [];
            const groupingCounter = {};
            swimlaneSeries.data.forEach((a) => {
                const res = [ ...a ];
                result = result.map((point) => {
                    if (point[groupingIndex] === res[groupingIndex]) {
                        res[categoryIndex] = point[categoryIndex];
                    }
                    return point;
                });
                const lastElm = result[result.length - 1];
                if (result.length && res[categoryIndex] > lastElm[categoryIndex]) {
                    groupingCounter[lastElm[groupingIndex]] = lastElm[categoryIndex];
                    if (!Object.prototype.hasOwnProperty.call(groupingCounter, res[groupingIndex])) {
                        res[categoryIndex] = result[result.length - 1][categoryIndex] + yAxisIncrement;
                    }
                }
                result.push(res);
            });
            swimlaneSeries.data = result;
            groupedSwimlanes = result;
        }

        chartOptions.yAxis.max = Math.abs(yAxisIncrement);

        return swimlaneSeries;
    });

    const renderAxisLabelItem = (params, api) => {
        const y = api.coord([
            0,
            api.value(0),
        ])[1];
        if (y < params.coordSys.y + 5) {
            return false;
        }
        return {
            type: 'group',
            position: [
                10,
                y,
            ],
            children: [
                {
                    type: 'path',
                    shape: {
                        d: 'M0,0 L0,-20 L30,-20 C42,-20 38,-1 50,-1 L70,-1 L70,0 Z',
                        x: 0,
                        y: -20,
                        width: 90,
                        height: 20,
                        layout: 'cover',
                    },
                    style: {
                        fill: '#368c6c',
                    },
                },
                {
                    type: 'text',
                    style: {
                        x: 24,
                        y: -3,
                        text: api.value(xAxisLabelProp),
                        textVerticalAlign: 'bottom',
                        textAlign: 'center',
                        textFill: '#fff',
                    },
                },
            ],
        };
    };

    seriesData.map((seriesItem) => chartOptions.series.push({
        type: 'custom',
        tooltip: {
            formatter: () => '',
        },
        renderItem: renderAxisLabelItem,
        dimensions: seriesItem.dimensions.map((dimension) => {
            if (dimension === xAxisLabelProp) {
                return {
                    name: xAxisLabelProp,
                    type: 'ordinal',
                };
            }
            return dimension;
        }),
        encode: {
            x: -1, // Then this series will not controlled by x.
            y: 0,
        },
        data: groupedSwimlanes,
    }));

    // Generate chart props
    const chartProps = {
        contextStyles: {
            container: styles.container,
        },
        option: chartOptions,
    };
    if (onPress) { chartProps.onPress = onPress; }
    if (onSelectItem) { chartProps.onSelectItem = onSelectItem; }
    if (renderButtonText) { chartProps.renderButtonText = renderButtonText; }
    if (testID) { chartProps.testID = testID; }

    if (isMaintainable) {
        const initDrag = (chartInstance) => {
            let draggingElement;
            let draggingCursorOffset = [
                0,
                0,
            ];
            let draggingElementLength;
            let draggingRecord;
            let dropRecord;
            let extractedCategoryIndexProp;
            let extractedStartValueProp;
            let extractedEndValueProp;
            const recordId = 'id';
            let extractedRecordId;

            const extractIndexes = (param) => {
                extractedCategoryIndexProp = param.dimensionNames.findIndex((x) => x === categoryIndexProp);
                extractedStartValueProp = param.dimensionNames.findIndex((x) => x === startValueProp);
                extractedEndValueProp = param.dimensionNames.findIndex((x) => x === endValueProp);
                extractedRecordId = param.dimensionNames.findIndex((x) => x === recordId);
            };

            const addOrUpdateBar = (el, itemData, style, z) => {
                const pointArrival = chartInstance.convertToPixel('grid', [
                    itemData.timeArrival,
                    itemData.categoryIndex,
                ]);
                const pointDeparture = chartInstance.convertToPixel('grid', [
                    itemData.timeDeparture,
                    itemData.categoryIndex,
                ]);
                const barLength = pointDeparture[0] - pointArrival[0];
                const barHeight = 20;
                let updatedElement = el;
                if (!updatedElement) {
                    updatedElement = new echarts.graphic.Rect({
                        shape: { x: 0, y: 0, width: 0, height: 0 },
                        style,
                        z,
                    });
                    chartInstance.getZr().add(updatedElement);
                }
                updatedElement.attr({
                    shape: { x: 0, y: 0, width: barLength, height: barHeight },
                    position: [
                        pointArrival[0],
                        pointArrival[1] - barHeight,
                    ],
                });
                return updatedElement;
            };

            const prepareDrop = () => {
                // Check droppable place.
                const xPixel = draggingElement.shape.x + draggingElement.position[0];
                const yPixel = draggingElement.shape.y + draggingElement.position[1];
                const cursorData = chartInstance.convertFromPixel('grid', [
                    xPixel,
                    yPixel,
                ]);
                if (cursorData) {
                // Make dropRecord
                    dropRecord = {
                        categoryIndex: Math.floor(cursorData[1]),
                        timeArrival: cursorData[0],
                        timeDeparture: cursorData[0] + draggingElementLength,
                    };
                }
            };

            const dragRelease = () => {
                if (draggingElement) {
                    chartInstance.getZr().remove(draggingElement);
                    draggingElement = null;
                }
                dropRecord = null;
                draggingRecord = null;
            };

            chartInstance.on('mousedown', (param) => {
                if ( !param || param.seriesIndex == null) {
                    return;
                }
                extractIndexes(param);
                // Drag start
                draggingRecord = {
                    dataIndex: param.dataIndex,
                    categoryIndex: param.value[extractedCategoryIndexProp],
                    timeArrival: param.value[extractedStartValueProp],
                    timeDeparture: param.value[extractedEndValueProp],
                    id: param.value[extractedRecordId],
                };
                const style = {
                    lineWidth: 2,
                    fill: 'rgba(255,0,0,0.1)',
                    stroke: 'rgba(255,0,0,0.8)',
                    lineDash: [
                        6,
                        3,
                    ],
                };
                draggingElement = addOrUpdateBar(draggingElement, draggingRecord, style, 100);
                draggingCursorOffset = [
                    draggingElement.position[0] - param.event.offsetX,
                    draggingElement.position[1],
                ];
                draggingElementLength = draggingRecord.timeDeparture - draggingRecord.timeArrival;
            });

            chartInstance.getZr().on('mousemove', (event) => {
                if (!draggingElement) {
                    return;
                }
                // Move draggingElement.
                draggingElement.attr('position', [
                    draggingCursorOffset[0] + event.offsetX,
                    draggingCursorOffset[1],
                ]);
                prepareDrop();
            });

            chartInstance.getZr().on('mouseup', () => {
                // Drop
                if (draggingElement && dropRecord) {
                    const {
                        onDragDrop,
                    } = props;
                    if ( onDragDrop ) {
                        const updatedProperties = {
                            [startValueProp]: new Date(dropRecord.timeArrival),
                            [endValueProp]: new Date(dropRecord.timeDeparture),
                            [recordId]: draggingRecord.id,
                        };
                        onDragDrop(updatedProperties, dataProps);
                    }
                }
                dragRelease();
            });

            chartInstance.getZr().on('globalout', dragRelease);
        };

        chartProps.initDrag = initDrag;
    }

    return (
        <ChartWrapper { ...chartProps } />
    );
};

SwimlaneChart.propTypes = {
    /** Enables/disables chart animations */
    animation: PropTypes.bool,

    /** Styles for the component */
    contextStyles: PropTypes.shape({
        /** Styles for the chart text content */
        chartTextStyle: PropTypes.object,

        /** Styles for the container */
        container: PropTypes.object,

        /** Styles for the error tooltip */
        errorTooltipStyle: PropTypes.object,

        /** Styles for the legend */
        legendTextStyle: PropTypes.object,

        /** Styles for the chart title */
        titleTextStyle: PropTypes.object,

        /** Styles for the tooltip */
        tooltipTextStyle: PropTypes.object,

        /** Styles for the xAxis */
        xAxisTextStyle: PropTypes.object,

        /** Styles for the yAxis */
        yAxisTextStyle: PropTypes.object,
    }),

    dataProps: PropTypes.shape({
        /** Prop for display value for rectangular bar across y-axis */
        barValueProp: PropTypes.string,

        /** Prop for display color for bar across y-axis */
        barColorProp: PropTypes.string,

        /** Prop for grouping records based on grouping point */
        categoryIndexProp: PropTypes.string,

        /** Date or DateTime value for the bar */
        startValueProp: PropTypes.string,

        /** Date or DateTime value for the bar */
        endValueProp: PropTypes.string,

        /** Prop for x-axis label */
        xAxisLabelProp: PropTypes.string,
    }),

    /** Enable display of grid lines */
    gridLines: PropTypes.bool,

    /** Modify grid positioning.  Can be numeric pixel or string relative */
    gridPosition: PropTypes.shape({
        bottom: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        left: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        right: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        top: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
    }),

    /** condition to group the datapoints */
    grouping: PropTypes.bool,

    /** List of properties to show on swimlane bar hover */
    hoverDetails: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        propertyName: PropTypes.string,
        type: PropTypes.string,
    })),

    /** Displays the swimlanes horizontally from left to right */
    horizontal: PropTypes.bool,

    /** specifies whether Drag Drop is applicable */
    isMaintainable: PropTypes.bool,

    /** Enable display of a legend */
    legend: PropTypes.bool,

    /** Maximum X axis value */
    maxX: PropTypes.string,

    /** Minimum X axis value */
    minX: PropTypes.string,

    /**
     * Triggered on press of a swimlane
     * @param {Object} itemData - Data for the swimlane pressed
     */
    onPress: PropTypes.func,

    /**
     * Triggered on select of a swimlane
     * @param {Object} itemData - Data for the swimlane selected
     */
    onSelectItem: PropTypes.func,

    /**
     * Formats value based on the type of data
     */
    formatProp: PropTypes.func,

    /**
     * Used to manage the selected detail button text
     * @param {Object} dataPoint
     */
    renderButtonText: PropTypes.func,

    /** Reverse orientation of the chart */
    reverse: PropTypes.bool,

    /** Series of swimlane descriptions */
    seriesData: PropTypes.arrayOf(PropTypes.shape({
        /** Border color of the series swimlanes */
        swimlaneBorderColor: PropTypes.string,

        /** Border width of the series swimlanes */
        swimlaneBorderWidth: PropTypes.number,

        /** Color of the series swimlanes */
        swimlaneColor: PropTypes.string,

        /** Color of the series swimlanes on press */
        swimlaneEmphasisColor: PropTypes.string,

        /** Opacity of the series swimlanes */
        swimlaneOpacity: PropTypes.number,

        /**
         * The width of the swimlane. Adaptive when not specified.
         * Can be an absolute value like 40 or a percent value like '60%'. The percent is based on the calculated category width.
         */
        swimlaneWidth: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),

        /** Displays a legend item for the series */
        legendText: PropTypes.string,

        /** Display label inside the series swimlanes with the swimlane value */
        valueLabel: PropTypes.bool,

        /** Color of the swimlane value label */
        valueLabelColor: PropTypes.string,

        /** Format string for the series value labels */
        valueLabelFormat: PropTypes.string,

        /** Position of series value labels within its swimlane */
        valueLabelPosition: PropTypes.oneOf([
            'inside',
            'insideLeft',
            'insideRight',
            'insideTop',
            'insideBottom',
            'insideTopLeft',
            'insideBottomLeft',
            'insideTopRight',
            'insideBottomRight',
        ]),

        /** Font size of the series value labels */
        valueLabelSize: PropTypes.number,

        /**
         * Width of the series value label containers
         * Can be numeric pixel value or relative string (%, em, etc)
         */
        valueLabelWidth: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
    })).isRequired,

    /** Id used for testing */
    testID: PropTypes.string,

    /** Title text for the chart */
    title: PropTypes.string,

    /** Show related data in a tooltip after pressing a swimlane */
    tooltip: PropTypes.bool,

    /**
     * Format string for all value labels
     * @see https://echarts.apache.org/en/option.html#series-scatter.label.formatter for the available formatting options
     */
    valueLabelFormat: PropTypes.string,

    /** Show X axis line, ticks and labels */
    xAxis: PropTypes.bool,

    /** Text to display along the X axis */
    xAxisTitle: PropTypes.string,

    /** Show Y axis line, ticks and labels */
    yAxis: PropTypes.bool,

    /** Text to display along the Y axis */
    yAxisTitle: PropTypes.string,

    /** Enable zooming in/out chart ranges */
    zoom: PropTypes.bool,
};

SwimlaneChart.defaultProps = {
    animation: false,
    contextStyles: {},
    gridLines: true,
    gridPosition: {},
    isMaintainable: false,
    tooltip: true,
    valueLabelFormat: '{value}',
    xAxis: true,
    xAxisTitle: 'X',
    yAxis: true,
    yAxisTitle: 'Y',
    zoom: true,
};

export default SwimlaneChart;