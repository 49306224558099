import React from 'react';
import * as PropTypes from 'prop-types';
import { default as Button, VARIANT } from '../Button/Button';

// Styles
import getStyles from './FilePickerButton.styles';

const {
    CONTAINED,
    OUTLINED,
    TEXT,
} = VARIANT;

/**
 * FilePicker component allows user to pick files to upload.
 * @see https://material-ui.com/demos/buttons/
 */
class FilePickerButton extends React.Component {
    render() {
        const {
            fileTypes,
            contextStyles,
            testID,
            buttonText,
            buttonVariant,
        } = this.props;

        let accept = '';

        const fileMap = {
            pdf: 'application/pdf,',
            plainText: 'text/plain,',
            images: 'image/*,',
            audio: 'audio/*,',
            video: 'video/*,',
        };

        fileTypes.forEach( (fileType) => { accept += ( fileMap[fileType] || '*,' ); });

        const styles = getStyles(contextStyles);

        return (
            <>
                <input
                    accept={ accept }
                    ref={ (input) => { this.inputElement = input; } }
                    style={ { display: 'none' } }
                    type="file"
                    onChange={ this.handleChange } />
                <Button
                    contextStyles={ {
                        primary: styles.button,
                        primaryText: styles.buttonText,
                    } }
                    onClick={ this.handleClick }
                    text={ buttonText }
                    variant={ buttonVariant }
                    testID={ testID } />
            </>
        );
    }

    /**
     * Called when the file input changes
     * @param {{target: Object}} e - The native change event
     */
    handleChange = (e) => {
        const { onChange } = this.props;
        onChange(e, this.props);
    };

     /** Called when the picker Icon is clicked to open the picker */
     handleClick = () => {
         this.inputElement.click();
     }
}

FilePickerButton.propTypes = {
    /** Text displayed inside the button */
    buttonText: PropTypes.string,

    /** Button Variant. Defaults to the 'contained' variant. Valid Types: 'outlined', 'contained' */
    buttonVariant: PropTypes.oneOf([
        CONTAINED,
        OUTLINED,
        TEXT,
    ]),

    /** Styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the cancel photo button */
        button: PropTypes.object,

        /** Styles for the cancel photo button text */
        buttonText: PropTypes.object,
    }),

    /** File input types*/
    fileTypes: PropTypes.arrayOf(PropTypes.oneOf([
        'allFiles',
        'images',
        'plainText',
        'audio',
        'video',
        'pdf',
    ])),

    /**
     * Called when the document selection has changed
     * @param {Object} event - The native change event
     * @param {Object} props - The component instance props
     */
    onChange: PropTypes.func.isRequired,

    /** Id used for testing */
    testID: PropTypes.string,
};

FilePickerButton.defaultProps = {
    fileTypes: [ 'allFiles' ],
    buttonText: 'Upload',
    buttonVariant: CONTAINED,
};

export default FilePickerButton;
