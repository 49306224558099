/*       */

/*
An Image is either based on a URL or a property.
 */
import Image from './Image';

const ACTION_PREFIX = 'catavolt:action:name:';

export default class Button extends Image {
    get useSimpleSalt() { return this.enabledInReadMode; }
    get enabledInReadMode() { return this.valueAt('EnabledInReadMode') === 'true'; }
    get actionId() {
        const pn = super.propertyName;
        return pn.length > ACTION_PREFIX.length ? pn.substr(ACTION_PREFIX.length) : '';
    }
    get propertyName() { return undefined; }

    newComplexSaltAssertExpression() {
        return { expr: '$dialog.isReadMode' };
    }
    newReadOnlySalt() {
        return this.enabledInReadMode ? this.newMaintenanceSalt() : super.newReadOnlySalt();
    }
    newMaintenanceBoxStyleSet() {
        const result = super.newMaintenanceBoxStyleSet();
        result.style.cursor = 'pointer';
        return result;
    }
    newMaintenanceSalt() {
        return this.newActionSalt();
    }
    newActionSalt() {
        const boxStyleSet = this.newMaintenanceBoxStyleSet();
        const propertyStyleSet = this.newMaintenancePropertyStyleSet();
        return {
            box: {
                ...boxStyleSet.asStyleAttribute(),
                ...boxStyleSet.asXStyleAttribute(),
                children: [ {
                    action: {
                        id: this.actionId,
                        type: 'custom',
                        children: [ this.newControlSalt(propertyStyleSet) ],
                    },
                } ],
            },
        };
    }
}
