// Core component styles
const coreStyles = {
    appointment: {
        border: 'none',
        borderRadius: '1px',
    },
    appointmentContainer: {
        position: 'relative',
        transform: 'none',
        height: '20px',
    },
    appointmentContent: {
    },
    allDayAppointment: {
        position: 'relative',
    },
    container: {
    },
    currentDateHeader: {
        margin: '6% 10%',
        fontSize: '24px',
    },
    dayScaleCell: {
        borderRight: 'none',
        borderBottom: '1px solid',
        padding: '6px',
    },
    weekDayCell: {
        display: 'flex',
        alignItems: 'baseline',
        width: '100%',
        paddingBottom: '6px',
        paddingLeft: '5px',
        borderBottom: '1px solid',
    },
    weekDayCellText: {
        color: '#959595',
        fontSize: '16px',
        fontWeight: '300',
    },
    weekDayCellTextSelected: {
        color: 'black',
    },
    weekDayCellNumber: {
        color: '#646464',
        fontSize: '25px',
        width: '35px',
        height: '35px',
        lineHeight: '35px',
        textAlign: 'center',
        fontWeight: '300',
        marginLeft: '6px',
        marginRight: '2px',
    },
    weekDayCellNumberSelected: {
        color: 'rgb(255, 255, 255)',
        borderRadius: '50%',
        fontWeight: '400',
        backgroundColor: 'rgb(0, 0, 0)',
    },
    event: {
    },
    monthAppointment: {
        height: '20px',
    },
    paperContainer: {
        display: 'flex',
    },
    pickerContainer: {
        height: '100%',
        width: '310px',
    },
    secondDatePickerCell: {
        width: '26px',
        height: '26px',
        padding: '8px',
        margin: '5px',
    },
    selectedDay: {
        borderRadius: '50%',
        padding: '8px',
    },
    switcher: {
    },
    todayButton: {
        border: 'none',
    },
    todayCell: {
        width: '1.72em',
        height: '1.72em',
        marginTop: '-0.1em',
        textAlign: 'center',
        lineHeight: '1.72',
        marginLeft: 'auto',
        borderRadius: '50%',
    },
    toolbar: {
        border: 'none',
    },
    timeTableCell: {
        height: '110px',
        padding: '6px',
        boxSizing: 'border-box',
        userSelect: 'none',
        verticalAlign: 'top',
        borderRight: '1px solid rgba(0, 0, 0, 0.1)',
        borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        '&:hover': {
            backgroundColor: '#F2F2F2',
            transition: '0.3s',
        },
    },
    timeTableCellWeekend: {
        backgroundColor: '#FBFBFB',
    },
    timeTableCellHover: {
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
    },
};

/**
 * Combine component context styles with core styles
 * @param {Object} contextStyles
 * @return {Object}
 */
const getStyles = (contextStyles = {}) => {
    return {
        appointment: {
            ...coreStyles.appointment,
            ...contextStyles.appointment,
        },
        appointmentContainer: {
            ...coreStyles.appointmentContainer,
            ...contextStyles.appointmentContainer,
        },
        appointmentContent: {
            ...coreStyles.appointmentContent,
            ...contextStyles.appointmentContent,
        },
        allDayAppointment: {
            ...coreStyles.allDayAppointment,
            ...contextStyles.allDayAppointment,
        },
        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },
        currentDateHeader: {
            ...coreStyles.currentDateHeader,
            ...contextStyles.currentDateHeader,
        },
        dayScaleCell: {
            ...coreStyles.dayScaleCell,
            ...contextStyles.dayScaleCell,
        },
        weekDayCell: {
            ...coreStyles.weekDayCell,
            ...contextStyles.weekDayCell,
        },
        weekDayCellText: {
            ...coreStyles.weekDayCellText,
            ...contextStyles.weekDayCellText,
        },
        weekDayCellTextSelected: {
            ...coreStyles.weekDayCellTextSelected,
            ...contextStyles.weekDayCellTextSelected,
        },
        weekDayCellNumber: {
            ...coreStyles.weekDayCellNumber,
            ...contextStyles.weekDayCellNumber,
        },
        weekDayCellNumberSelected: {
            ...coreStyles.weekDayCellNumberSelected,
            ...contextStyles.weekDayCellNumberSelected,
        },
        event: {
            ...coreStyles.event,
            ...contextStyles.event,
        },
        monthAppointment: {
            ...coreStyles.monthAppointment,
            ...contextStyles.monthAppointment,
        },
        paperContainer: {
            ...coreStyles.paperContainer,
            ...contextStyles.paperContainer,
        },
        pickerContainer: {
            ...coreStyles.pickerContainer,
            ...contextStyles.pickerContainer,
        },
        secondDatePickerCell: {
            ...coreStyles.secondDatePickerCell,
            ...contextStyles.secondDatePickerCell,
        },
        selectedDay: {
            ...coreStyles.selectedDay,
            ...contextStyles.selectedDay,
        },
        switcher: {
            ...coreStyles.switcher,
            ...contextStyles.switcher,
        },
        todayButton: {
            ...coreStyles.todayButton,
            ...contextStyles.todayButton,
        },
        todayCell: {
            ...coreStyles.todayCell,
            ...contextStyles.todayCell,
        },
        toolbar: {
            ...coreStyles.toolbar,
            ...contextStyles.toolbar,
        },
        timeTableCell: {
            ...coreStyles.timeTableCell,
            ...contextStyles.timeTableCell,
        },
        timeTableCellWeekend: {
            ...coreStyles.timeTableCellWeekend,
            ...contextStyles.timeTableCellWeekend,
        }
    };
};

export default getStyles;
