import Layout from './Layout';

const padding = (layout) => {
    if (layout.padding) {
        // Handle the multi-part values.
        const style = {};
        const multi = Layout.parseMultiPartValue(layout.padding);
        switch (multi.length) {
            case 2:
                style.paddingTop = Layout.parseValue(multi[0]);
                style.paddingBottom = Layout.parseValue(multi[0]);
                style.paddingLeft = Layout.parseValue(multi[1]);
                style.paddingRight = Layout.parseValue(multi[1]);
                break;
            case 3:
                style.paddingTop = Layout.parseValue(multi[0]);
                style.paddingLeft = Layout.parseValue(multi[1]);
                style.paddingRight = Layout.parseValue(multi[1]);
                style.paddingBottom = Layout.parseValue(multi[2]);
                break;
            case 4:
                style.paddingTop = Layout.parseValue(multi[0]);
                style.paddingRight = Layout.parseValue(multi[1]);
                style.paddingBottom = Layout.parseValue(multi[2]);
                style.paddingLeft = Layout.parseValue(multi[3]);
                break;
            default: // Just work with the first value
                style.padding = Layout.parseValue(multi[0]);
        }
        return style;
    }
    return {};
};

export default padding;
