export class SessionTimer {
    constructor(validateSession, checkIntervalMillis) {
        this.validateSession = validateSession;
        this.checkIntervalMillis = checkIntervalMillis;
    }
    ;
    resetSessionTimer() {
        this.stopSessionTimer();
        this.sessionInterval = setInterval(() => {
            this.validateSession();
        }, this.checkIntervalMillis);
    }
    ;
    stopSessionTimer() {
        if (this.sessionInterval) {
            clearInterval(this.sessionInterval);
        }
    }
    ;
}
