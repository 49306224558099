/*       */
import AbstractControl from './AbstractControl';
import FormUtil from '../FormUtil';

export default class TextArea extends AbstractControl {
    newReadOnlyPropertyStyleSet() {
        const result = super.newReadOnlyPropertyStyleSet();
        const buildStyle = this.buildOverflow(result);
        result.style = buildStyle.containerStyle;
        result.xStyle.input = buildStyle.input;
        return result;
    }
    buildOverflow(style) {
        const containerStyle = style.style || {};
        if (this.numberOfLines > 1) {
            // @TODO remove this
            // we can no longer access this because the server is doing the transpile.  however,
            // this shouldn't matter - we shouldn't need to know this as salt MUST be cross-platform
            // The client Salt implementations should make these adjustments, if there's a platform difference
            // isXNA() WILL CURRENTLY ALWAYS RETURN TRUE!

            containerStyle.overflow = FormUtil.isXNA() ? 'scroll' : 'auto'; // auto is not supported in react native. So, set it to scroll.
        }
        const input = {
            alignItems: 'flex-start',
            borderBottomWidth: 0,
            flexGrow: 1,
            flexShrink: 1,
            padding: 0,
            variant: 'standard',
        };
        return { containerStyle, input };
    }
}
