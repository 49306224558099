import React from 'react';
import PropTypes from 'prop-types';
import componentFactory from './componentFactory';
import SaltContext from './SaltContext';
import SaltComponent from './SaltComponent';
import { pageController } from 'cv-react-core';

const GEOFIX_PROP = 'GEOFIX_PROP';

export default class Gps extends SaltComponent {
    static propTypes = {
        viewId: PropTypes.string,
    };

    render() {
        return React.createElement(componentFactory.getPlatformComponent('gps'), { onLocation: this.handleLocation });
    }

    handleLocation = (location) => {
        const viewId = this.getViewId(this.props.viewId);
        const { saltStore, onTransition, onError } = this.context;
        const dialogStore = saltStore.getDialogStoreForViewId(viewId);
        const {
            uiStore,
        } = saltStore;
        dialogStore.setPropertyValue(GEOFIX_PROP, location);
        pageController.performWrite({ dialogStore, uiStore, onTransition, onError });
    };
}

Gps.contextType = SaltContext;
