// Core component styles
const coreStyles = {
    content: {
    },
};

/**
 * Combines coreStyles and contextStyles
 * @param {Object} contextStyles
 */
const getStyles = (contextStyles = {}) => {
    return {
        content: {
            ...coreStyles.content,
            ...contextStyles.content,
        },
    };
};

export default getStyles;
