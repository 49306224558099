import React, { PureComponent } from 'react';
import { dialogComponentFactory, rootStore, constants } from 'cv-react-core';
import { XaltIconButton, XaltClear } from 'xalt-react-atoms';
import * as PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import Modal from '../base/Modal';
import getStyles from './styles/DialogModal.styles';
import FormToolBar from '../panels/FormToolBar/FormToolBar';
import ErrorRouteModal from './ErrorRouteModal';

const { ui } = constants;
const {
    MODAL_DIALOG_ID,
    MODAL_DIALOG_STORE_INFO,
    MODAL_TRANSITION } = ui;

@observer
class DialogModal extends PureComponent {
    static propTypes = {
        uiStore: PropTypes.object,
        history: PropTypes.object,
    };

    render() {
        const {
          uiStore,
        } = this.props;

        const modalDialogInfo = uiStore.getValueForUIObject(MODAL_DIALOG_ID, MODAL_DIALOG_STORE_INFO) || {
            dialogId: null,
            isModalOpen: false,
            onError: () => {},
            onTransition: () => {},
        };

        const {
            dialogId,
            isModalOpen,
            onError,
            onTransition,
        } = modalDialogInfo;

        let transition = onTransition;

        // if source dialog is destroyed, get the modal transition from current route which is set in dilaogRoute component
        if (modalDialogInfo.sourceDestroyed) {
            const { onTransition: routeTransition } = uiStore.getValueForUIObject(MODAL_DIALOG_ID, MODAL_TRANSITION) || {};
            if (routeTransition) {
                transition = routeTransition;
            }
        }

        const { sessionStore } = rootStore;
        const dialogStore = sessionStore.getOpenDialogStore(dialogId);
        const { dialog } = dialogStore || {};
        const component = dialog ? dialogComponentFactory.getDialogPageComponent(dialog) : null;

        const params = {
            dialogStore,
            uiStore,
            onTransition: transition,
            onError,
        };

        const { modal } = getStyles();
        const { description } = dialog || '';
        const formToolBarProps = {
            dialogId,
            uiStore,
            title: description,
            closeIcon: this.closeIcon(),
        };
        const errorModalProps = {
            objectId: dialogId,
            uiStore,
        };
        return (
            <Modal
                // onClose={ this.handleOnCloseModal }
                isTransparentOverlay={ false }
                contextStyles={ { modal } }
                isModalOpen={ (component && isModalOpen) || false }>
                { component && React.createElement(FormToolBar, formToolBarProps ) }
                { component && React.createElement(component, params) }
                { component && React.createElement(ErrorRouteModal, errorModalProps ) }
            </Modal>
        );
    }

    handleOnCloseModal = () => {
        const { uiStore, history } = this.props;
        const modalDialogInfo = uiStore.getValueForUIObject(MODAL_DIALOG_ID, MODAL_DIALOG_STORE_INFO);

        if (!modalDialogInfo) return;

        const { dialogId, goBackOnClose } = modalDialogInfo;

        const { sessionStore } = rootStore;
        sessionStore.destroyDialogStore(dialogId);

        if (goBackOnClose) history.goBack();
        uiStore.removeValueForUIObject(MODAL_DIALOG_ID, MODAL_DIALOG_STORE_INFO);
    }

    closeIcon = () => {
        const { iconContainer, icon } = getStyles();
        return (
            <XaltIconButton
                onClick={ this.handleOnCloseModal }
                style={ iconContainer }>
                <XaltClear style={ icon } />
            </XaltIconButton>
        );
    }
}

export default withRouter(DialogModal);