import React, { PureComponent } from 'react';
import { constants, rootStore, serviceFactory } from 'cv-react-core';
import Avatar from '@material-ui/core/Avatar';
import TextLabel from '../base/TextLabel';
import getStyles from './styles/ProfileMenu.styles';
import Menu from '../base/Menu';
import MenuItem from '../base/MenuItem';
import IconButton from '../base/IconButton';
import Icon from '../base/Icon';

const { ui } = constants;
const {
    APPLICATION_UI_ID,
    ABOUT_MODAL_IS_OPEN,
    LOGOUT_MODAL_ID,
    LOGOUT_MODAL_IS_OPEN,
} = ui;

class ProfileMenu extends PureComponent {
    state = { anchorEl: null };

    render() {
        const styles = getStyles();
        const { sessionStore } = rootStore;
        const { lang } = serviceFactory;
        const { userId } = sessionStore.session || {};
        const { anchorEl } = this.state;
        const menuItems = [
            {
                icon: 'info_outlined',
                key: 'About',
                text: lang.mainMenu.about,
            },
            {
                icon: 'exit_to_app_outlined',
                key: 'Exit',
                text: lang.mainMenu.logoutConfirmation.title,
            },
        ];

        return (
            <Menu
                anchorEl={ anchorEl }
                button={
                    <Avatar
                        onClick={ this.handleAvatarClick }
                        style={ styles.avatar }
                        variant="rounded">
                        <Icon
                            contextStyles={ {
                                icon: styles.avatarIcon,
                            } }
                            title={ userId }
                            iconName="person" />
                    </Avatar>
                }
                closeOnSelection={ false }
                contextStyles={ {
                    container: styles.container,
                } }
                anchorOrigin={ { vertical: 38, horizontal: 'right' } }
                onClose={ this.handleClose }
                open={ !!anchorEl }>
                <li
                    key="header"
                    style={ styles.contentContainer }>
                    <div style={ styles.userContainer }>
                        <TextLabel
                            contextStyles={ {
                                container: styles.labelContainer,
                                text: styles.labelText,
                            } }>
                            { lang.applicationInfo.UserId }
                        </TextLabel>
                        <TextLabel
                            contextStyles={ {
                                container: styles.valueContainer,
                                text: styles.valueText,
                            } }>
                            { userId }
                        </TextLabel>
                    </div>
                    <IconButton
                        contextStyles={ {
                            button: styles.closeIconButton,
                            icon: styles.closeIcon,
                        } }
                        disableRipple
                        iconName="cancel_circle"
                        onClick={ () => this.setState({ anchorEl: null }) }
                        title={ lang.generic.close } />
                </li>
                {
                    menuItems.map((menu) => (
                        <MenuItem
                            contextStyles={ {
                                container: styles.menuItem,
                                focusContainer: styles.menuItemFocused,
                                selected: styles.menuItemSelected,
                            } }
                            key={ menu.key }
                            onClick={ () => this.handleClick(menu.key) }
                            text={
                                <div style={ styles.imageContainer }>
                                    <Icon
                                        contextStyles={ {
                                            icon: styles.imageStyle,
                                        } }
                                        iconName={ menu.icon }
                                        iconSize={ 21 } />
                                    <TextLabel
                                        contextStyles={ {
                                            container: styles.valueContainer,
                                            text: styles.profileText,
                                        } }>
                                        { menu.text }
                                    </TextLabel>
                                </div>
                            } />
                )) }
            </Menu>
        );
    }

    handleClick = (item) => {
        const { uiStore } = rootStore;
        this.setState({ anchorEl: null }, () => {
            if (item === 'Exit') {
                uiStore.setValueForUIObject(LOGOUT_MODAL_ID, LOGOUT_MODAL_IS_OPEN, true);
            }
            else {
                uiStore.setValueForUIObject(APPLICATION_UI_ID, ABOUT_MODAL_IS_OPEN, true);
            }
        });
    }

    handleAvatarClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    }

    handleClose = () => {
        this.setState({ anchorEl: null });
    }
}

export default ProfileMenu;
