/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import componentFactory from '../componentFactory';
import componentPropTypes from './abstractComponentPropTypes';

const propDefinitions = {
    style: componentPropTypes.style,
    value: componentPropTypes.value,
    isReadMode: componentPropTypes.isReadMode,
    isFocused: componentPropTypes.isFocused,
    onValueChanged: componentPropTypes.onValueChanged,
    xStyle: componentPropTypes.xStyle,
    propertyRef: componentPropTypes.propertyRef,
};

export default class DateTimeField extends Component {
    static propTypes = propDefinitions;

    render() {
        const {
            style,
            value,
            isReadMode,
            isFocused,
            onValueChanged,
            xStyle,
            propertyRef,
        } = this.props;
        const { formatString: placeholder } = xStyle;

        const DateTimeInputProps = {
            style,
            value,
            isReadMode,
            isFocused,
            onValueChanged,
            xStyle,
            placeholder,
            propertyRef,
        };
        return React.createElement(componentFactory.getPlatformPropertyComponent('dateTimeField'), { ...DateTimeInputProps });
    }
}
