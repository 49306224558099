// Core component styles
const coreStyles = {
    column: { 
        display: "flex",
        flexDirection: 'column',
        flexGrow: 1,
        flexShrink: 1,
        overflowX: 'auto',
    },

    container: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        flexShrink: 1,
        overflow: 'hidden',
    },
};

/**
 * Helper method to get the styles for Activity Indicator component
 * @param {Object} contextStyles - Context styles for Activity Indicator Component
 */
const getStyles = (contextStyles = {}) => {
    return {
        column: {
            ...coreStyles.column,
            ...contextStyles.column,
        },

        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },
    };
};

export default getStyles;
