import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import getStyles from './XaltTextLabel.styles';

/* Special NOTE on why we use style instead of className/css:
    https://material-ui.com/getting-started/faq/#when-should-i-use-inline-style-vs-css
*/
const propDefinitions = {
    /** Styles for this component */
    style: PropTypes.object,

    /**
     * Used to truncate the text with an ellipsis after
     * computing the text layout, including line wrapping,
     * such that the total number of lines does not exceed this number
     */
    numberOfLines: PropTypes.number,

    // Label name
    name: PropTypes.string,

    /** The text to display */
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),
};
/**
 * A platform component for building a text label
 */
class XaltTextLabel extends PureComponent {
    static propTypes = propDefinitions;

    static defaultProps = {
        children: '',
        numberOfLines: 1,
        name: 'x-label',
    };

    render() {
        const {
            style,
            numberOfLines,
            name,
            children } = this.props;

        const styles = getStyles(style, numberOfLines);

        return (
            <label
                name={ name }
                style={ styles }>
                { children }
            </label>
        );
    }
}

export default XaltTextLabel;
