
import Attribute from './Attribute';
import { THIS } from './gmlConstants';
import AbstractGml from './model/AbstractGml';
import Base from './model/Base';
import SaltBoxModel from './SaltBoxModel';
import StyleSet from './StyleSet';

const jsonata = require('jsonata');

export default class GmlUtil {
    static asRowChildren(myContext, styleSet, children) {
        const { flexPolicy } = styleSet;
        const { parentContext } = myContext;
        // Rows do not need a wrapping row.  If the parent is a column and styleSet has a flexGrow,
        // then prioritize the vertical growth behavior over the horizontal sizing obtained by wrapping
        // in a row.
        if (!parentContext || flexPolicy.flexDirection.isRow()
            || (parentContext.flexDirection.isColumn() && flexPolicy.flexGrow)) {
            return { children };
        }
        const getFlexGrow = (o) => {
            return o.box && o.box.style && o.box.style['flex-grow'];
        };
        const getAlignSelf = (o) => {
            return o.box && o.box.style && o.box.style['align-self'];
        };
        const getFlexBasis = (o) => {
            return o.box && o.box.style && o.box.style['flex-basis'];
        };
        const setFlexShrink = (o) => {
            if (o.box && o.box.style) {
                const { style } = o.box;
                style['flex-shrink'] = 1;
            }
        };
        const setFlexGrow = (o) => {
            if (o.box && o.box.style) {
                const { style } = o.box;
                style['flex-grow'] = 1;
            }
        };
        const doesHavePercentHeight = (o) => {
            const test = o.box && o.box.style && o.box.style.height;
            return (typeof test === 'string') && test.indexOf('%') > -1;
        };
        const isLine = (o) => {
            const { xStyle } = o.box;
            return xStyle && xStyle.layout && xStyle.layout.lineWidth !== undefined;
        };
        // Wrap these children in a row so that they may all have flexShrink:1
        const shrinkAndRotate = (o) => {
            if (!o.box || doesHavePercentHeight(o) || isLine(o)) {
                return o; // Percent heights fail when wrapped
            }
            const oStyle = o.box.style;
            const fb = getFlexBasis(o);
            const fg = getFlexGrow(o);
            const as = getAlignSelf(o);
            const ss = new StyleSet({ gmlId: 'colChildWrapper' });
            ss.flexPolicy.setFlexRow();
            setFlexShrink(o);
            if (fb) {
                ss.flexPolicy.flexBasis = fb;
                delete oStyle['flex-basis'];
            }
            if (fg) {
                ss.flexPolicy.flexGrow = fg;
                delete oStyle['flex-grow'];
                ss.flexPolicy.setAlignItemsStretch();
            }
            if (as === 'stretch') {
                ss.flexPolicy.setStandardGrow();
                setFlexGrow(o);
            }
            return { // wrapper
                box: {
                    ...ss.asStyleAttribute(),
                    children: [ o ],
                },
            };
        };
        return { children: children.map(m => shrinkAndRotate(m)) };
    }

    static doesCrossAxisFlex(myContext, flexDirection, scroll) {
        const hasFlex = (childJson) => {
            if (typeof childJson === 'string') { // Plist children
                return false;
            }
            const fillerSearch = myContext.gml.findFillers(myContext);
            if ((flexDirection.isColumn() && fillerSearch.horizontalCount)
                || (flexDirection.isRow() && fillerSearch.verticalCount)) {
                return true;
            }
            const wa = AbstractGml.getWidthAttributeJson(childJson, myContext);
            const ha = AbstractGml.getHeightAttributeJson(childJson, myContext);
            const ea = AbstractGml.getExpandAttributeJson(childJson, myContext);
            const isPercentWidth = wa && wa.isPercentBased();
            const isPercentHeight = ha && ha.isPercentBased();
            const isExpandableWidth = (ea && (ea.isBoth() || ea.isHorizontal())) || false;
            const isExpandableHeight = (ea && (ea.isBoth() || ea.isVertical())) || false;
            const isScrollWidth = !!(scroll && scroll.isHorizontal());
            const isScrollHeight = !!(scroll && scroll.isVertical());
            return (flexDirection.isColumn() && (isPercentWidth || isExpandableWidth || isScrollWidth))
                || (flexDirection.isRow() && (isPercentHeight || isExpandableHeight || isScrollHeight));
        };
        return hasFlex(myContext.gml.json);
    }

    static doesCrossAxisFlexOfAnyChild(myContext, flexDirection, scroll) {
        return !!Base.children(myContext).find(f => {
            const c = f.updateContext(myContext);
            return GmlUtil.doesCrossAxisFlex(c, flexDirection, scroll);
        });
    }

    static doesMainAxisFlex(myContext, flexDirection, rootScroll) {
        const hasFlex = (childJson) => {
            const fillerSearch = myContext.gml.findFillers(myContext);
            if ((flexDirection.isRow() && fillerSearch.horizontalCount)
                || (flexDirection.isColumn() && fillerSearch.verticalCount)) {
                return true;
            }
            if (typeof childJson === 'string') { // Plist children
                return childJson.indexOf('*filler') > -1; // ------ EARLY EXIT
            }
            const wa = AbstractGml.getWidthAttributeJson(childJson, myContext);
            const ha = AbstractGml.getHeightAttributeJson(childJson, myContext);
            const ea = AbstractGml.getExpandAttributeJson(childJson, myContext);
            const sa = AbstractGml.getScrollAttributeJson(childJson, myContext);
            const esa = AbstractGml.getEquallySizedAttributeJson(childJson, myContext);

            const isPercentWidth = wa && wa.isPercentBased();
            const isPercentHeight = ha && ha.isPercentBased();
            const isExpandableWidth = (ea && (ea.isBoth() || ea.isHorizontal())) || false;
            const isExpandableHeight = (ea && (ea.isBoth() || ea.isVertical())) || false;
            let isScrollWidth = !!(rootScroll && rootScroll.isHorizontal());
            isScrollWidth = isScrollWidth || (sa && sa.isHorizontal() && !wa);
            let isScrollHeight = !!(rootScroll && rootScroll.isVertical());
            isScrollHeight = isScrollHeight || (sa && sa.isVertical() && !ha);
            const childDir = Base.flexDirectionForJson(childJson);
            const isEquallySized = childDir === flexDirection && esa && (esa.value === 'true')
                && (!myContext.version.isV1() || childDir.isRow()); // V1 does not support equal on columns

            if ((flexDirection.isRow() && (isPercentWidth || isExpandableWidth || isScrollWidth || isEquallySized))
                || (flexDirection.isColumn() && (isPercentHeight || isExpandableHeight || isScrollHeight || isEquallySized))) {
                return true; // ------ EARLY EXIT
            }

            // If there is no flex, but there is no explicit size either, check the children to see if
            // they flex, as boxes grow if there is no size.
            if ((flexDirection.isRow() && !wa) || (flexDirection.isColumn() && !ha)) {
                return !!Base.elements(childJson).find(f => hasFlex(f));
            }

            return false;
        };
        if (hasFlex(myContext.gml.json)) {
            return true;
        }
        // If an element has no explicit width/height, need to look at children for expand also.
        return !!Base.elements(myContext.gml.json).find(f => hasFlex(f));
    }

    static getAttributesAsObject(json, mergeInto = {}) {
        const a = this.pvtGetValueForExpr(json, [ THIS ]) || {};
        return Object.assign(mergeInto, a);
    }

    // It is known that this attribute exists.  Return it's value.
    static getValueForAttribute(json, expressionArray) {
        const result = this.pvtGetValueForExpr(json, expressionArray) || '';
        return (result);
    }

    // The attribute may or may not exist.  Search the Attribute and it's alias and provide a default if missing.
    static getValueForExpr(json, expressionArray, defaultValue) {
        let result = this.pvtGetValueForExpr(json, expressionArray);
        if (result === undefined && this.pvtHasAlias(expressionArray)) {
            result = this.pvtGetValueForExpr(json, expressionArray, true);
        }
        return result !== undefined ? result : defaultValue;
    }

    static matchFlexDirection(flexDirection, styleSet) {
        if (flexDirection.isRow()) {
            styleSet.flexPolicy.setFlexRow();
        } else {
            styleSet.flexPolicy.setFlexColumn(); // Default
        }
    }

    static promoteAll(sourceStyleSet, targetStyleSet, exclusions = [], duplicates = []) {
        // With few exceptions, promote attributes to new style set.
        const promote = (sourceIn, targetIn) => {
            const source = sourceIn;
            const target = targetIn;
            Object.keys(source).forEach(e => {
                if (!exclusions.includes(e)) {
                    target[e] = source[e];
                    if (!duplicates.includes(e) && e !== 'flexDirection') {
                        delete source[e];
                    }
                }
            });
        };
        promote(sourceStyleSet.style, targetStyleSet.style);
        promote(sourceStyleSet.xStyle, targetStyleSet.xStyle);
        promote(sourceStyleSet.flexPolicy, targetStyleSet.flexPolicy);
        const xStyleToChange = sourceStyleSet.xStyle;
        xStyleToChange.layout = {}; // Must always have one of these.
    }

    // NOTE: This code is duplicated in engine/xStyle/margin.js
    static resolveMargin(layout) {
        if (layout.margin) {
            // Handle the multi-part values.
            const multi = layout.margin.split(',')
                .map(m => m.trim());
            const style = {};
            switch (multi.length) {
                case 2:
                    style.marginTop = parseFloat(multi[0]);
                    style.marginBottom = parseFloat(multi[0]);
                    style.marginLeft = parseFloat(multi[1]);
                    style.marginRight = parseFloat(multi[1]);
                    break;
                case 3:
                    style.marginTop = parseFloat(multi[0]);
                    style.marginLeft = parseFloat(multi[1]);
                    style.marginRight = parseFloat(multi[1]);
                    style.marginBottom = parseFloat(multi[2]);
                    break;
                case 4:
                    style.marginTop = parseFloat(multi[0]);
                    style.marginRight = parseFloat(multi[1]);
                    style.marginBottom = parseFloat(multi[2]);
                    style.marginLeft = parseFloat(multi[3]);
                    break;
                default: // Just work with the first value
                    style.margin = parseFloat(multi[0]);
            }
            return style;
        }
        return {};
    }

    static wrapForScroller(
        myContext,
        boxModel,
        scrollA,
    ) {
        const newParentSS = new StyleSet();
        GmlUtil.promoteAll(boxModel.styleSet, newParentSS, [ 'alignItems', 'justifyContent' ]);

        // ss.style['json-to-salt-debug'] = 'wrapForScroller';
        newParentSS.flexPolicy.setStandardShrink();
        boxModel.setScroller(myContext, scrollA);
        return new SaltBoxModel(newParentSS, [ boxModel ]);
    }

    // Private ----------------------------------------------------------------
    static pvtBuildExpression(expressionArray, useAlias = false) {
        const fn = (result, element) => {
            if (element instanceof Attribute) {
                // If alias is requested and we have an alias, use it.
                const attributeName = (useAlias && element.alias()) || element.name();
                // THIS context is optional if an Attribute is the only thing in the list.
                return this.pvtDotBetween(result, expressionArray.length === 1 ? `${THIS}.${attributeName}` : attributeName);
            }
            // Just put a dot in between
            return this.pvtDotBetween(result, element);
        };
        return expressionArray.reduce(fn, '');
    }

    static pvtDotBetween(part1, part2) {
        if (part1) {
            return `${part1}.${part2}`;
        }
        return part2;
    }

    static pvtGetValueForExpr(json, expressionArray, useAlias = false) {
        if (json) {
            let jsonataExpr = null;
            const expression = this.pvtBuildExpression(expressionArray, useAlias);
            try {
                jsonataExpr = jsonata(expression);
                return jsonataExpr.evaluate(json);
            } catch (e) {
                const msg = `Invalid gml expr: '${expression}' :: ${e.message}`;
                console.warn(msg);
                throw new Error(`Invalid gml expr: '${expression}' :: ${e.message}`);
            }
        }
        return undefined;
    }

    static pvtHasAlias(expressionArray) {
        if (expressionArray.length > 0) {
            const lastElement = expressionArray[expressionArray.length - 1];
            if (lastElement instanceof Attribute) {
                return lastElement.hasAlias();
            }
        }
        return false;
    }
}
