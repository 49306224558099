// Core component styles
const coreStyles = {
    container: {
        padding: '0 25px',
        minHeight: '48px',
    },

    focusContainer: {},

    image: {
    },

    imageLoadingIndicator: {
    },

    selected: {
    },
};

/**
 * Helper method to get styles for the Menu Component
 * @param {Object} contextStyles - Custom styles for the Menu component
 */
const getStyles = (contextStyles = {}) => {
    return {
        container: {
            ...coreStyles.container,
            ...contextStyles.container,
            '&:hover': {
                ...coreStyles.focusContainer,
                ...contextStyles.focusContainer,
            },
        },

        image: {
            ...coreStyles.image,
            ...contextStyles.image,
        },

        imageLoadingIndicator: {
            ...coreStyles.imageLoadingIndicator,
            ...contextStyles.imageLoadingIndicator,
        },

        selected: {
            ...coreStyles.selected,
            ...contextStyles.selected,
        },
    };
};

export default getStyles;
