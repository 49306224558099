/* eslint-disable class-methods-use-this */
import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';
import * as PropTypes from 'prop-types';
import { constants } from 'cv-react-core';

import Modal from '../base/Modal';
import TextLabel from '../base/TextLabel';
import LinearProgress from '../base/LinearProgress';
import getStyles from './styles/LoadingIndicator.styles';

const {
    ui,
} = constants;
const {
    GLOBAL_SDK_BUSY,
} = ui;

@observer
class LoadingIndicator extends PureComponent {
    static propTypes = {
        objectId: PropTypes.string,
        uiStore: PropTypes.object,
    };

    render() {
        const { objectId, uiStore } = this.props;
        // Since the modal object is a child element of the parent dialog, we need to see if it is the modal in scope and open to use its busy status.
        // If it is not, we can use the object id being sent in to check status.
        const modalDialogId = this.getModalDialogId(uiStore);
        const existingStatusObj = modalDialogId ? uiStore.getValueForUIObject(modalDialogId, GLOBAL_SDK_BUSY) : uiStore.getValueForUIObject(objectId, GLOBAL_SDK_BUSY);

        const { status, isOpen = true } = existingStatusObj || { status: {}, isOpen: false };
        const { loadingText, detailText } = status || { loadingText: '', detailText: '' };
        const styles = getStyles();
        return (
            <Modal
                contextStyles={ { modal: styles.modal, overlay: styles.overlay } }
                isModalOpen={ isOpen }
                isTransparentOverlay={ false }>
                <LinearProgress />
                <TextLabel
                    contextStyles={ { text: { ...styles.majorText } } }>
                    { loadingText }
                </TextLabel>
                <TextLabel
                    contextStyles={ { text: { ...styles.minorText } } }>
                    { detailText }
                </TextLabel>
            </Modal>
        );
    }

    getModalDialogId(uiStore) {
        const modalDialogInfo = uiStore.getValueForUIObject(constants.ui.MODAL_DIALOG_ID, constants.ui.MODAL_DIALOG_STORE_INFO);
        return modalDialogInfo && modalDialogInfo.dialogId && modalDialogInfo.isModalOpen ? modalDialogInfo.dialogId : null;
    }
}

export default LoadingIndicator;
