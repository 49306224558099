/**
 * A FillerSearch reports the number of fillers found in each direction.
 */
export default class FillerSearch {
    verticalCount ;
    horizontalCount ;
    constructor(horizontalCount = 0, verticalCount = 0) {
        this.horizontalCount = horizontalCount;
        this.verticalCount = verticalCount;
    }
    bump(bump, direction) {
        if (direction.isRow()) {
            this.horizontalCount += bump;
        } else {
            this.verticalCount += bump;
        }
    }
    bumpHorizontal(bump) {
        this.horizontalCount += bump;
    }
    bumpVertical(bump) {
        this.verticalCount += bump;
    }

    merge(fillerSearch) {
        return new FillerSearch(
            (this.horizontalCount + fillerSearch.horizontalCount),
            (this.verticalCount + fillerSearch.verticalCount),
        );
    }
}
