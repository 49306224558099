/* @flow */
export const ui = {
    APPLICATION_UI_ID: 'APPLICATION_UI_ID',
    WORKBENCH_UI_OBJECT_ID: 'WORKBENCH_UI_ID',
    WORKBENCH_PAGE_INITIAL_ACTION_TAKEN: 'WORKBENCH_PAGE_INITIAL_ACTION',
    WORKBENCH_MENU_OPEN: 'WORKBENCH_MENU_OPEN',
    WORKBENCH_ACTIVE_ID: 'WORKBENCH_ACTIVE_ID',
    GLOBAL_SDK_BUSY: 'GLOBAL_SDK_BUSY',
    LOGIN_IN_PROGRESS: 'LOGIN_IN_PROGRESS',
    DIALOG_PAGE_TITLE: 'DIALOG_PAGE_TITLE',
    DIALOG_VIEW_VISIBLE: 'DIALOG_VIEW_VISIBLE',
    DIALOG_WIDTH: 'DIALOG_WIDTH',
    DIALOG_HEIGHT: 'DIALOG_HEIGHT',
    FIREBASE_TOKEN_ID: 'FIREBASE_TOKEN_ID',
    FORM_CONTROLS_PROPS: 'FORM_CONTROLS_PROPS',
    MODAL_OPEN_PROPS: 'MODAL_OPEN_PROPS',
    NOTIFICATION_ACTION: 'NOTIFICATION_ACTION',
    SELECTED_RANGE: 'SELECTED_RANGE',
    SELECTED_RECORDS: 'SELECTED_RECORDS',
    SELECTION_MODE: 'SELECTION_MODE',
    DEVICE_PROPERTIES: 'DEVICE_PROPERTIES',
    LIST_OVERRIDE_DEFAULT_ACTION_ID: 'LIST_OVERRIDE_DEFAULT_ACTION_ID',
    MARKER_COORDINATES: 'MARKER_COORDINATES',
    BREAD_CRUMBS: 'BREADCRUMBS',
    ACTIVE_LAUNCHER_ID: 'ACTIVE_LAUNCHER_ID',
    CLEAR_DIALOG_BREAD_CRUMBS: 'CLEAR_DIALOG_BREAD_CRUMBS',
    OPEN_IMAGE: 'OPEN_IMAGE',
    MODAL_DIALOG_ID: 'MODAL_DIALOG_ID',
    MODAL_DIALOG_STORE_INFO: 'MODAL_DIALOG_STORE_INFO',
    MODAL_TRANSITION: 'MODAL_TRANSITION',
    LOGOUT_MODAL_ID: 'LOGOUT_MODAL_ID',
    LOGOUT_MODAL_IS_OPEN: 'LOGOUT_MODAL_IS_OPEN',
    ABOUT_MODAL_IS_OPEN: 'ABOUT_MODAL_IS_OPEN',
    FIRST_FOCUSABLE_PROPERTY: 'FIRST_FOCUSABLE_PROPERTY',
    CALENDAR_SELECTED_DATE: 'CALENDAR_SELECTED_DATE',
    CALENDAR_SELECTED_VIEW: 'CALENDAR_SELECTED_VIEW',
    CALENDAR_PICKED_DATE: 'CALENDAR_PICKED_DATE',
    CALENDAR_UPDATE_MODAL: 'CALENDAR_UPDATE_MODAL',
    CALENDAR_ORIENTATION: 'CALENDAR_ORIENTATION',
    SCROLL_POSITION: 'SCROLL_POSITION',
    SCROLL_CONTAINER: 'SCROLL_CONTAINER',
    ACTIVE_TAB_INDEX: 'ACTIVE_TAB_INDEX',
    /** List */
    LIST_ROWS_LOADING: 'LIST_ROWS_LOADING',
    VIEW_PAGINATIONSETTINGS: 'VIEW_PAGINATIONSETTINGS',
    LIST_PAGINATION_DATA: 'LIST_PAGINATION_DATA',
    /** END LIST */
    SALT_EDITOR_PROPS: 'SALT_EDITOR_PROPS',
    SALT_EDITOR_VISIBLE_DIALOG_ID: 'SALT_EDITOR_VISIBLE_DIALOG_ID',
    IS_RECORD_SELECTED: 'IS_RECORD_SELECTED',
    LIST_SAVED_SELECTIONS: 'LIST_SAVED_SELECTIONS',
    NFC_ANDROID_PROMPT: 'NFC_ANDROID_PROMPT',
};
