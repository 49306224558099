/*       */
import StyleSet from '../StyleSet';
import AbstractModel from './AbstractModel';
import Cell from './Cell';
import { CELLS } from '../ModelCache';

export default class Grid extends AbstractModel {
    constructor(json, parentWidth, parentHeight) {
        super(json);
        this.parentWidth = parentWidth;
        this.parentHeight = parentHeight;
        this.init();
    }
    get cells() {
        // Sort cells left-to-right and top-to-bottom. This helps in tabbing through forms in order.
        return this.cache.get(CELLS,
            () => { return this.childrenOfType('Cell').map(m => new Cell(m, this)).sort((a, b) => a.top - b.top || a.left - b.left); });
    }
    get layoutSizeWidth() { return this.floatAt('Layout.Size.Width', this.parentWidth); }
    get layoutSizeWidths() { return this.floatArrayAt('Layout.Size.Width', this.parentWidth); }
    get layoutSizeHeight() { return this.floatAt('Layout.Size.Height', this.parentHeight); }
    get layoutSizeHeights() { return this.floatArrayAt('Layout.Size.Height', this.parentHeight); }

    asSalt = (parentContext) => {
        const myContext = this.updateContext(parentContext);
        const styleSet = new StyleSet({
            paperId: this.id,
            position: 'absolute',
            top: this.layoutOriginY,
            left: this.layoutOriginX,
            width: this.width,
            height: this.height,
        });

        return {
            box: {
                ...styleSet.asStyleAttribute(),
                ...styleSet.asXStyleAttribute(),
                children: this.cells.map(m => m.asSalt(myContext)).filter(f => f !== null),
            },
        };
    }
    init() {
        this.width = this.layoutSizeWidths.reduce((accumulator, currentValue) => accumulator + currentValue);
        this.height = this.layoutSizeHeights.reduce((accumulator, currentValue) => accumulator + currentValue);
    }
}
