import React from 'react';
import * as PropTypes from 'prop-types';
import getStyles from './PanelHeader.styles';

const PanelHeader = (props) => {
    const {
        children,
        contextStyles,
    } = props;
    const {
        container,
    } = getStyles(contextStyles);

    return (
        <div
            className="c-panel-header__container"
            style={ container }>
            { children }
        </div>
    );
};

PanelHeader.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]),
    contextStyles: PropTypes.shape({
        container: PropTypes.object,
    }),
};

PanelHeader.defaultProps = {
    contextStyles: {},
};

export default PanelHeader;
