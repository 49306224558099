import ActionIconComponentBuilder from './ActionIconComponentBuilder';
import PhoneActionIcon from '../../components/base/PhoneActionIcon';

/**
 * @deprecated This component is being deprecated after  component refactoring.
 */
class PhoneActionComponentBuilder extends ActionIconComponentBuilder {
    constructor() {
        super();
        this.setActionIconComponent(PhoneActionIcon);
    }

    processAction() {
        // Populate custom action data
        this.processActionData('DIAL_PHONE');

        // Finish action processing after custom action data populated
        super.processAction();
    }
}

export default PhoneActionComponentBuilder;
