/* eslint-disable react/destructuring-assignment */
import { Log } from 'cv-dialog-sdk';
import React, { Component } from 'react';
import SaltError from './SaltError';

export default class SaltErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { error: false };
    }

    render() {
        if (this.state.error) {
            return this.renderError(this.state.error);
        }
        return this.props.children;
    }

    renderError(error) {
        const message = 'Something went wrong:';
        Log.error(message, error);
        return (
            <SaltError
                message={ message }
                error={ error } />
        );
    }

    componentDidCatch(error, info) {
        this.setState({ error });
        Log.error(error, info);
    }
}
