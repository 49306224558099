import {
    GENERAL,
    ICON,
    IMAGE,
    PROPERTY,
    PROPERTY_DATA,
    PROPERTY_EDIT,
    PROPERTY_LABEL,
    PROPERTY_TEXT,
    SIGNATURE,
    BARCHART,
    GAUGECHART,
} from '../exportClassConstants';
import FillerSearch from '../FillerSearch';
import Finalizer from '../Finalizer';
import { CONST_PREFIX_EXTENDER, CONST_PREFIX_GML, ICON_PREFIX, INNER, LABEL_PREFIX_GML, RES_PREFIX, SESSION_PREFIX_GML } from '../gmlConstants';
import GmlUtil from '../GmlUtil';
import FlexDirection from '../layoutAttribute/general/FlexDirection';
import SimpleStringAttribute from '../layoutAttribute/SimpleStringAttribute';
import SaltBoxModel from '../SaltBoxModel';
import StyleSet from '../StyleSet';
import AbstractGml from './AbstractGml';


const EXTENDER_REPEAT_OPTION_NAME = 'ZZREPEAT_ACTION_PROPERTY_NAMEZZ';

const ALL = '*all';
const REMAINING = '*remaining';
const FILLER = '*filler';
const REPEAT = '*repeatOption';

const SHRINK = FlexDirection.row;

class Grouping {
    justifyContent ;
    children ;
    includeTrailingSeparator ;
    isShrink ;
    constructor(children, justifyContent) {
        this.children = children;
        this.justifyContent = justifyContent;
    }
}

export default class Plist extends AbstractGml {
    asSalt(parentContext, assertExpr = '') {
        const myContext = this.updateContext(parentContext);
        const styleSet = new StyleSet();
        this.exportStyleTo(styleSet, myContext, [ GENERAL ]);
        this.applyDefaultFlex(myContext, styleSet);
        this.applyEquallySized(myContext, styleSet);
        this.applyFlexForFiller(myContext, styleSet);
        // plist cannot handle equally sized until SALT allows for label, property & separator to be rendered as one
        // child.  Currently space-between or space-around will add space between/around labels and separators.

        let saltChildrenGrouping;
        // The content will be a comma separated list of [ property | @const | const[x] ]
        const content = GmlUtil.getValueForExpr(this.json, [ INNER ]) || '';
        if (content) {
            const parts = this.validate(myContext, content.split(',').map(m => m.trim()));
            // If this is a horizontal alignment, analyze the parts and determine what the alignItems
            // value should be.  If necessary, wrap fields and *fillers.
            const propsGrouping = this.analyzeProps(myContext, parts);
            saltChildrenGrouping = this.children(myContext, propsGrouping);
        } else {
            saltChildrenGrouping = new Grouping([]);
        }

        this.setJustifyContent(myContext, saltChildrenGrouping, styleSet);
        this.addBackgroundImage(myContext, saltChildrenGrouping.children);

        const sbm = new SaltBoxModel(styleSet, saltChildrenGrouping.children, this.asAssertAttribute(this.asQualifierExprString(assertExpr)));

        // Wrap in a containing box or action if there are special needs.
        return this.wrapForSpecialCases(myContext, parentContext, sbm);
    }

    children(myContext, propsBundle) {
        const children = [];
        const propSeparator = this.getAttributeValue('propSeparator', myContext.cascading) || '';
        propsBundle.children.forEach((prop, index) => {
            const betweenEnd = propsBundle.includeTrailingSeparator ? propsBundle.children.length : propsBundle.children.length - 1;
            const between = myContext.flexDirection.isRow() && (index < betweenEnd);
            if (prop instanceof Grouping) {
                // If prop is a Bundle, then a section of props has been selected to be grouped together so
                // they can have their own justifyContent.  This is how *filler is handled in react.
                const grouping = this.children(myContext, prop);
                const styleSet = this.setJustifyContent(myContext, prop);
                myContext.flexDirection.exportTo(myContext, styleSet);
                const subSalt = {
                    box: {
                        ...styleSet.asStyleAttribute(),
                        children: grouping.children,
                    },
                };
                children.push(subSalt);
            } else if (prop.startsWith(CONST_PREFIX_GML)) {
                // @const/name
                this.addGmlConstant(myContext, myContext.flexDirection, children, prop);
                this.addPropSeparator(myContext, between, propSeparator, children);
            } else if (prop.startsWith(SESSION_PREFIX_GML)) {
                this.addGmlSessionProp(myContext, myContext.flexDirection, children, prop);
                this.addPropSeparator(myContext, between, propSeparator, children);
            } else if (prop.startsWith(LABEL_PREFIX_GML)) {
                // @label/propName
                this.addGmlLabel(myContext, myContext.flexDirection, children, prop);
                this.addPropSeparator(myContext, between, propSeparator, children);
            } else if (prop.startsWith(CONST_PREFIX_EXTENDER)) {
                // *const[x]
                this.addExtenderConstant(myContext, myContext.flexDirection, children, prop);
                this.addPropSeparator(myContext, between, propSeparator, children);
            } else if (prop.startsWith(RES_PREFIX)) {
                // res:image.png
                this.addImageResource(myContext, myContext.flexDirection, children, prop);
                this.addPropSeparator(myContext, between, propSeparator, children);
            } else if (prop.startsWith(ICON_PREFIX)) {
                // icon:name
                this.addIconResource(myContext, myContext.flexDirection, children, prop);
                this.addPropSeparator(myContext, between, propSeparator, children);
            } else if (prop === ALL) {
                const matching = [ this.buildAllMatchExpression(myContext) ];
                this.addAllProperties(myContext, myContext.flexDirection, children, matching, between, propSeparator);
            } else if (prop === REMAINING) {
                const matching = [];
                const finalizer = new Finalizer( // Can't be resolved until entire GML document is parsed.
                    (v) => { matching[0] = v; },
                    () => { return this.buildRemainingMatchExpression(myContext); },
                );
                myContext.document.addFinalizer(finalizer);
                matching.push(finalizer);
                this.addAllProperties(myContext, myContext.flexDirection, children, matching, between, propSeparator);
            } else if (prop === FILLER) {
                this.addFiller(myContext, children);
            } else if (prop === REPEAT) {
                this.addProperty(myContext, myContext.flexDirection, children, EXTENDER_REPEAT_OPTION_NAME);
                this.addPropSeparator(myContext, between, propSeparator, children);
            } else {
                // Must be a property
                // Record the reference to this field in plistFieldNames for subsequent *remaining operations
                myContext.document.plistFieldNames[prop] = null; // eslint-disable-line no-param-reassign
                this.addProperty(myContext, myContext.flexDirection, children, prop);
                this.addPropSeparator(myContext, between, propSeparator, children);
            }
        });
        // This Bundle is slightly different than other Bundles in that it's children content are SALT
        // elements and other Bundles have prop names or nested Bundles.
        return new Grouping(children, propsBundle.justifyContent !== null ? propsBundle.justifyContent : undefined);
    }

    addAllProperties(
        myContext,
        parentDirection,
        children,
        matching,
        between,
        propSeparator,
    ) {
        const iterChildren = [];
        this.addProperty(myContext, parentDirection, iterChildren);
        // Ideally, the propSeparator would not be added for the last property.  But for now, we will always
        // add the separator with between set to false.
        this.addPropSeparator(myContext, false, propSeparator, children);
        children.push({
            iterator: {
                type: 'property',
                match: matching,
                children: iterChildren,
            },
        });
    }

    addGmlConstant(myContext, parentDirection, children, name) {
        const { classifiers } = this;
        // Validate this is a constant we have seen before
        const shortName = name.substring(CONST_PREFIX_GML.length);
        if (!myContext.knownConstants[shortName]) {
            const { warnings } = myContext;
            warnings.addUndefinedConstantRef(shortName);
        } else {
            // Build a style in two passes.  First TEXT props and then add on DATA props.
            const styleSet = this.newLocalStyleSet(myContext, SHRINK);
            let excludeList = classifiers.remove(classifiers.allSubtypes(), PROPERTY_TEXT);
            this.exportStyleTo(styleSet, myContext, [ PROPERTY ], ((excludeList)));
            excludeList = classifiers.remove(classifiers.allSubtypes(), PROPERTY_DATA);
            this.exportStyleTo(styleSet, myContext, [ PROPERTY_DATA ], ((excludeList)));
            if (!this.allowConstShrink(myContext)) {
                styleSet.flexPolicy.setNoShrink();
            }

            this.addSaltChild(myContext, parentDirection, children, {
                text: {
                    ...this.asStyleAttributeSansDirection(styleSet),
                    children: [
                        {
                            ref: {
                                name: SimpleStringAttribute.asRefName(name),
                            },
                        },
                    ],
                },
            });
        }
    }

    addGmlSessionProp(myContext, parentDirection, children, name) {
        const { classifiers } = this;
        const sessionPropName = name.substring(SESSION_PREFIX_GML.length);
        const styleSet = this.newLocalStyleSet(myContext, SHRINK);
        let excludeList = classifiers.remove(classifiers.allSubtypes(), PROPERTY_TEXT);
        this.exportStyleTo(styleSet, myContext, [ PROPERTY ], ((excludeList)));
        excludeList = classifiers.remove(classifiers.allSubtypes(), PROPERTY_DATA);
        this.exportStyleTo(styleSet, myContext, [ PROPERTY_DATA ], ((excludeList)));
        if (!this.allowConstShrink(myContext)) {
            styleSet.flexPolicy.setNoShrink();
        }

        this.addSaltChild(myContext, parentDirection, children, {
            text: {
                ...this.asStyleAttributeSansDirection(styleSet),
                children: `$sessionValue('${sessionPropName}')`,
            },
        });
    }

    addGmlLabel(myContext, parentDirection, children, name) {
        // Convert @label/propName to propName.
        const propName = name.substr(LABEL_PREFIX_GML.length);
        const styleSet = this.getStyleSetForLabel(myContext);
        if (!this.allowLabelShrink(myContext)) {
            styleSet.flexPolicy.setNoShrink();
        }
        this.addSaltChild(myContext, parentDirection, children, {
            label: {
                propertyName: propName,
                ...this.asStyleAttributeSansDirection(styleSet),
                ...styleSet.asXStyleAttribute(),
            },
        });
    }

    addExtenderConstant(myContext, parentDirection, children, name) {
        const { classifiers } = this;
        // Find the index of the constant.  e is in a format of *const[xx] and we are extracting xx.
        const start = CONST_PREFIX_EXTENDER.length;
        const end = name.indexOf(']');
        const iString = name.substr(start, end - start);
        const i = parseInt(iString, 10) - 1; // -1 as GML is 1 based indexing

        // Build a style in two passes.  First TEXT props and then add on DATA props.
        let excludeList = classifiers.remove(classifiers.allSubtypes(), PROPERTY_TEXT);
        const styleSet = this.newLocalStyleSet(myContext, SHRINK);
        this.exportStyleTo(styleSet, myContext, [ PROPERTY ], ((excludeList)));
        excludeList = classifiers.remove(classifiers.allSubtypes(), PROPERTY_DATA);
        this.exportStyleTo(styleSet, myContext, [ PROPERTY_DATA ], ((excludeList)));
        if (!this.allowConstShrink(myContext)) {
            styleSet.flexPolicy.setNoShrink();
        }

        this.addSaltChild(myContext, parentDirection, children, {
            text: {
                ...this.asStyleAttributeSansDirection(styleSet),
                children: [
                    {
                        ref: {
                            name,
                            expr: `$dialog.constants[${i}]`,
                            render: true,
                        },
                    },
                ],
            },
        });
    }

    addFiller(myContext, parent) {
        if (myContext.flexDirection.isColumn() && myContext.isColumnBound()) {
            parent.push({
                box: {
                    style: { 'flex-grow': 1, 'flex-shrink': 1 },
                },
            });
        }
        if (myContext.flexDirection.isRow() && myContext.isRowBound()) {
            const style = { width: '100%' };
            parent.push({
                box: {
                    style,
                },
            });
        }
    }

    addIconResource(myContext, parentDirection, parent, name) {
        // Create the image to flex to it's full size, and size a containing box that has a border and possible
        // border radius.
        const styleSet = this.newLocalStyleSet(myContext, SHRINK);
        this.exportStyleTo(styleSet, myContext, [ ICON ]);
        this.addSaltChild(myContext, parentDirection, parent, {
            image: {
                'resize-mode': 'contain',
                source: name,
                iconProps: { ...this.asStyleAttributeSansDirection(styleSet).style },
            },
        });
    }

    addImageResource(myContext, parentDirection, parent, name) {
        // Create the image to flex to it's full size, and size a containing box that has a border and possible
        // border radius.
        const resizeMode = this.getAttributeValue('imageResizeMode', myContext.cascading) || 'contain';
        const image = {
            image: {
                resizeMode,
                source: name,
                style: { 'align-items': 'center', 'justify-content': 'center', flexGrow: 1, alignSelf: 'stretch' },
            },
        };

        // NOTE: Image styles such as border size, border color, width, height, etc are applied here to the containing
        // box.  When an image is in a plist, this code does not "know" that a given property is an image so these
        // styles are applied to the image as xStyles in that case.
        const styleSet = new StyleSet({ overflow: 'hidden' });
        styleSet.flexPolicy.setAlignItemsCenter();
        styleSet.flexPolicy.setJustifyContentCenter();
        styleSet.flexPolicy.setStandardShrink();
        this.exportStyleTo(styleSet, myContext, [ IMAGE ]);
        // If an image size is present, promote it to the xStyle.layout so a ref can be handled.
        if (styleSet.style.imageSize) {
            styleSet.xStyle.layout.size = styleSet.style.imageSize;
            delete styleSet.style.imageSize;
        }
        const bm = new SaltBoxModel(styleSet, [ image ]);
        this.addSaltChild(myContext, parentDirection, parent, bm.asSalt());
    }

    addProperty(myContext, parentDirection, parent, name) {
        const labelPlacement = this.getAttributeValue('labelPlacement', myContext.cascading) || 'left';
        if (labelPlacement.startsWith('top')) {
            // Top
            // Label --------------
            const children = [];
            const styleSet = this.newLocalStyleSet(myContext, parentDirection);
            styleSet.flexPolicy.setFlexColumn();
            if ((labelPlacement === 'topLeft') || (labelPlacement === 'topStart')) {
                styleSet.flexPolicy.setAlignItemsStart();
            } else if (labelPlacement === 'topCenter') {
                styleSet.flexPolicy.setAlignItemsCenter();
            } else if ((labelPlacement === 'topRight') || (labelPlacement === 'topEnd')) {
                styleSet.flexPolicy.setAlignItemsEnd();
            }
            parent.push({
                box: {
                    ...styleSet.asStyleAttribute(),
                    children,
                },
            });
            this.addPropertyLabel(myContext, FlexDirection.column, children, name);
            this.addPropertyValue(myContext, FlexDirection.column, children, name);
        } else if (labelPlacement === 'left') {
            // Left Label --------------
            const children = [];
            const separator = this.getAttributeValue('labelValueSeparator', myContext.cascading);
            this.addPropertyLabel(myContext, FlexDirection.row, children, name, separator);
            this.addPropertyValue(myContext, FlexDirection.row, children, name);
            const styleSet = this.newLocalStyleSet(myContext, parentDirection);
            styleSet.flexPolicy.setFlexRow();
            styleSet.flexPolicy.setAlignItemsCenter();
            styleSet.flexPolicy.setJustifyContentStart();
            parent.push({
                box: {
                    ...styleSet.asStyleAttribute(),
                    children,
                },
            });
        } else {
            this.addPropertyValue(myContext, parentDirection, parent, name);
        }
    }

    addPropertyValue(myContext, parentDirection, parent, name) {
        const { classifiers } = this;
        const propName = name ? `'${name}'` : '$property.name';

        // Build a style in two passes.  First TEXT props and then add on DATA props.
        let excludeList = classifiers.remove(classifiers.allSubtypes(), PROPERTY_TEXT);
        const textStyleRead = this.newLocalStyleSet(myContext, SHRINK);
        // textStyleRead.flexPolicy.setStandardShrink(); // Needed on container, not property
        this.exportStyleTo(textStyleRead, myContext, [ PROPERTY, BARCHART, GAUGECHART ], ((excludeList)));
        excludeList = classifiers.remove(classifiers.allSubtypes(), PROPERTY_DATA);
        this.exportStyleTo(textStyleRead, myContext, [ PROPERTY_DATA, BARCHART, GAUGECHART ], ((excludeList)));
        // Edit style is a copy of read with edit on top.
        excludeList = classifiers.remove(classifiers.allSubtypes(), PROPERTY_EDIT);
        const textStyleEdit = this.newLocalStyleSet(myContext, SHRINK);
        textStyleEdit.xStyle.autoGrow = false;
        // textStyleEdit.flexPolicy.setStandardShrink(); // Needed on container, not property
        this.exportStyleTo(textStyleEdit, myContext, [ PROPERTY_EDIT ], ((excludeList)));
        if (!this.allowDataShrink(myContext)) {
            textStyleRead.flexPolicy.setNoShrink();
            textStyleEdit.flexPolicy.setNoShrink();
        }

        const imageStyleSet = this.newLocalStyleSet(myContext, SHRINK);
        this.exportStyleTo(imageStyleSet, myContext, [ IMAGE ]);
        let imageXStyle = {};
        if (Object.keys(imageStyleSet.style).length) {
            imageXStyle = { image: { ...imageStyleSet.asStyleAttribute().style } };
        }
        const signatureStyleSet = this.newLocalStyleSet(myContext, SHRINK);
        this.exportStyleTo(signatureStyleSet, myContext, [ SIGNATURE ]);
        let signatureXStyle = {};
        if (Object.keys(signatureStyleSet.style).length) {
            signatureXStyle = { signatureStyle: { ...signatureStyleSet.asStyleAttribute().style } };
        }

        // If there are no edit styles, then just output the property with the read styles.
        const textStyleReadAndEdit = textStyleRead.combinedStyleSet(textStyleEdit);
        if (StyleSet.isObjectEqual(textStyleRead.style, textStyleReadAndEdit.style)
            && textStyleRead.flexPolicy.isEqual(textStyleReadAndEdit.flexPolicy)
            && StyleSet.isObjectEqual(textStyleRead.xStyle, textStyleEdit.xStyle)) {
            this.addSaltChild(myContext, parentDirection, parent, {
                property: {
                    name,
                    ...this.asStyleAttributeSansDirection(textStyleRead),
                    ...textStyleRead.asXStyleAttribute({ ...imageXStyle, ...signatureXStyle }),
                },
            });
        } else {
            // If there are edit styles, condition which set of styles is used based on the property being editable.
            this.addSaltChild(myContext, parentDirection, parent, {
                when: {
                    assert: { expr: `$dialog.isReadMode or $propDef(${propName}).isReadOnly` },
                    children: [ {
                        property: {
                            name,
                            ...this.asStyleAttributeSansDirection(textStyleRead),
                            ...textStyleRead.asXStyleAttribute({ ...imageXStyle, ...signatureXStyle }),
                        },
                    }, this.addFieldAction(name) ],
                    'else-children': [ {
                        property: {
                            name,
                            ...this.asStyleAttributeSansDirection(textStyleReadAndEdit),
                            ...textStyleReadAndEdit.asXStyleAttribute({ ...imageXStyle, ...signatureXStyle }),
                        },
                    }, this.addFieldAction(name) ],
                },
            });
        }
    }

    addFieldAction(name) {
        return {
            fieldAction: {
                name,
                style: {
                    resizeMode: 'contain', // native
                    objectFit: 'contain', // web
                    height: 20, // As of now we dont have any posibility to give dynamic height to the fieldaction icon. So fixing it as 20 for now
                },
            },
        };
    }

    addPropertyLabel(myContext, parentDirection, parent, name, separator) {
        const { classifiers } = this;
        const labelAndSeparator = [];

        // The separator will be null for a label "top*" scenario.  For a label "left" scenario it may be an empty
        // string but will not be null.
        let styleSet;
        if (separator !== undefined) {
            // labelPlacement is left.
            labelAndSeparator.push(this.getPropertyLabelChild(myContext, FlexDirection.row, name));
            labelAndSeparator.push(this.getSeparatorChild(myContext, ((separator))));
            styleSet = this.newLocalStyleSet(myContext, SHRINK);
            styleSet.flexPolicy.setFlexRow();
            styleSet.flexPolicy.setJustifyContentStart();
            styleSet.flexPolicy.setAlignItemsCenter();
            if (!this.allowLabelShrink(myContext)) {
                styleSet.flexPolicy.setNoShrink();
            }
        } else {
            labelAndSeparator.push(this.getPropertyLabelChild(myContext, FlexDirection.column, name));
            styleSet = this.newLocalStyleSet(myContext, SHRINK);
            styleSet.flexPolicy.setFlexColumn();
        }

        // Build a style in two passes.  First TEXT props and then add on LABEL props.
        // NOTE: This style only has margin and width.
        let excludeList = classifiers.remove(classifiers.allSubtypes(), PROPERTY_TEXT);
        this.exportStyleTo(styleSet, myContext, [ PROPERTY_TEXT ], ((excludeList)));
        excludeList = classifiers.remove(classifiers.allSubtypes(), PROPERTY_LABEL);
        this.exportStyleTo(styleSet, myContext, [ PROPERTY_LABEL ], ((excludeList)));
        this.addSaltChild(myContext, parentDirection, parent, {
            box: {
                ...styleSet.asStyleAttribute(),
                ...styleSet.asXStyleAttribute(),
                children: labelAndSeparator,
            },
        });
    }

    addSaltChild(myContext, parentDirection, children, salt) {
        // If the plist is of vertical orientation, then wrap this child salt in a horizontal box to allow for
        // shrinking.
        if (parentDirection.isColumn()) {
            const styleSet = new StyleSet();
            styleSet.flexPolicy.setFlexRow();
            styleSet.flexPolicy.setJustifyContentStart();
            styleSet.flexPolicy.setAlignItemsCenter();
            this.applyDebugColor(myContext, styleSet);
            const box = {
                box: {
                    ...styleSet.asStyleAttribute(),
                    children: [ salt ],
                },
            };
            children.push(box);
        } else {
            children.push(salt);
        }
    }

    allowConstShrink(context) {
        const s = this.getAttributeValue('allowConstShrink', context.cascading) || 'true';
        return !!(s && s === 'true');
    }

    allowDataShrink(context) {
        const s = this.getAttributeValue('allowDataShrink', context.cascading) || 'true';
        return !!(s && s === 'true');
    }

    allowLabelShrink(context) {
        const s = this.getAttributeValue('allowLabelShrink', context.cascading) || 'true';
        return !!(s && s === 'true');
    }

    analyzeProps(myContext, propNames) {
        if (myContext.flexDirection.isColumn()) {
            return new Grouping(propNames); // n/a for columns
        }
        if (propNames.indexOf(FILLER) === -1) {
            return new Grouping(propNames); // n/a for no fillers
        }
        const firstProp = () => {
            return propNames[0];
        };
        const lastProp = () => {
            return propNames[propNames.length - 1];
        };
        const isFiller = (prop) => {
            return prop === FILLER;
        };
        const sansFiller = () => {
            return propNames.filter(f => { return !isFiller(f); });
        };
        const sansFillerWithGroups = () => {
            // Remove the fillers, but if there are multiple props between fillers, then wrap them in
            // a group.
            let current = [];
            const result = [];
            const finalizeCurrent = () => {
                if (current.length) {
                    result.push(current);
                    current = [];
                }
            };
            propNames.forEach(e => {
                if (isFiller(e)) {
                    finalizeCurrent();
                } else {
                    current.push(e);
                }
            });
            finalizeCurrent();
            // result now contains an array of arrays.  Each child array is a grouping of props.
            // Finalize result into an array of Groupings.  Even single props need to be in a
            // group so the separator is not treated for space between/around.
            return result.map((m, i) => {
                const g = new Grouping(m);
                g.isShrink = true;
                if (i !== result.length - 1) {
                    g.includeTrailingSeparator = true;
                }
                return g;
            });
        };
        const isAlternating = () => {
            // Must alternate between *filler & prop or prop & *filler.
            let lastIsFiller = !isFiller(propNames[0]);
            let index = 0;
            let isAlter = true;
            while (isAlter && (index < propNames.length)) {
                const prop = propNames[index];
                if (lastIsFiller && isFiller(prop)) isAlter = false;
                // Multiple consecutive properties is just fine (line below)
                // if (!lastIsFiller && !isFiller(prop)) isAlter = false;
                index += 1;
                lastIsFiller = isFiller(prop);
            }
            return isAlter;
        };
        const isLeft = () => {
            // Must be prop,prop...,*filler
            return propNames.indexOf(FILLER) === propNames.length - 1;
        };
        const isRight = () => {
            // Must be *filler,prop,prop...
            return propNames.slice().reverse().indexOf(FILLER) === propNames.length - 1;
        };
        const isCenter = () => {
            // Must be *filler,prop,prop...,*filler
            if (propNames.length < 3 || (firstProp() !== FILLER) || (lastProp() !== FILLER)) return false;
            const test = propNames.slice(1, propNames.length - 1);
            return test.indexOf(FILLER) === -1;
        };
        const isSpaceAround = () => {
            // Must be *filler,prop,(*filler,prop)...,*filler
            if (propNames.length < 3 || (firstProp() !== FILLER) || (lastProp() !== FILLER)) return false;
            return isAlternating();
        };
        const isSpaceBetween = () => {
            // Must be prop,*filler,(prop,*filler)...,prop
            if (propNames.length < 3 || (firstProp() === FILLER) || (lastProp() === FILLER)) return false;
            return isAlternating();
        };
        // If it's one of the common types, set the justify content and return;
        if (isLeft()) return new Grouping(sansFiller(), 'flex-start');
        if (isRight()) return new Grouping(sansFiller(), 'flex-end');
        if (isCenter()) return new Grouping(sansFiller(), 'center');
        if (isSpaceBetween()) return new Grouping(sansFillerWithGroups(), 'space-between');
        if (isSpaceAround()) return new Grouping(sansFillerWithGroups(), 'space-evenly');

        // Given that this is not one of the standard cases, force this into a standard case
        // by grouping fields together.
        // If we are here, then the first and last prop in propNames are one *filler and one prop.
        // If we pull the prop into it's own group, the remaining props will fit a standard case.
        if (isFiller(firstProp())) {
            const grouping1 = this.analyzeProps(myContext, propNames.slice(0, propNames.length - 2));
            const grouping2 = new Grouping([ propNames[propNames.length - 1] ]);
            grouping2.isShrink = true;
            return new Grouping([ grouping1, grouping2 ], 'space-evenly');
        } if (!isFiller(firstProp())) {
            const grouping1 = new Grouping([ propNames[0] ]);
            grouping1.isShrink = true;
            const grouping2 = this.analyzeProps(myContext, propNames.slice(1, propNames.length));
            return new Grouping([ grouping1, grouping2 ], 'space-evenly');
        }

        // Bail out - do it the old way.
        return new Grouping(propNames);
    }

    asStyleAttributeSansDirection(styleSet) {
        const result = styleSet.asStyleAttribute();
        delete result.style['flex-direction'];
        return result;
    }

    buildAllMatchExpression(myContext) {
        const namesToExclude = [];
        namesToExclude.push(EXTENDER_REPEAT_OPTION_NAME);
        return this.buildMatchExpression(myContext, namesToExclude);
    }

    buildRemainingMatchExpression(myContext) {
        const namesToExclude = Object.keys(myContext.document.plistFieldNames);
        namesToExclude.push(EXTENDER_REPEAT_OPTION_NAME);
        return this.buildMatchExpression(myContext, namesToExclude);
    }

    buildMatchExpression(myContext, namesToExclude) {
        if (namesToExclude.length) {
            // The regex that captures "other" fields:  \b(?!^field1$|^field2$)\b\S+
            const PREFIX = '\\b(?!'; // any|ole
            const SUFFIX = ')\\b\\S+';
            let result = PREFIX;
            namesToExclude.forEach((e, i) => {
                result += '^';
                result += e;
                result += '$';
                if (i !== namesToExclude.length - 1) {
                    result += '|'; // For all but the last, add a pipe.
                }
            });
            result += SUFFIX;
            return result;
        }
        return '/.*/';
    }

    getEquallySizedAttribute(context) {
        const r = super.getEquallySizedAttribute(context);
        if (r) {
            console.error('equallySized not supported for plist');
        }
        return null; // equallySized not supported for plist
    }

    getPropertyLabelChild(myContext, parentDirection, name) {
        const styleSet = this.getStyleSetForLabel(myContext);
        return {
            label: {
                propertyName: name,
                ...this.asStyleAttributeSansDirection(styleSet),
                ...styleSet.asXStyleAttribute(),
            },
        };
    }

    addPropSeparator(myContext, between, separator, parent) {
        if (between) {
            const styleSet = this.getStyleSetForLabel(myContext);
            const child = {
                text: {
                    ...this.asStyleAttributeSansDirection(styleSet),
                    children: separator,
                },
            };
            parent.push(child);
        }
    }

    getSeparatorChild(myContext, separator) {
        const styleSet = this.getStyleSetForLabel(myContext);
        return {
            text: {
                ...this.asStyleAttributeSansDirection(styleSet),
                children: separator,
            },
        };
    }

    getStyleSetForLabel(myContext) {
        const { classifiers } = this;
        // PROPERTY_MARGIN is excluded here because it is applied on parent.
        // Get the TEXT props and then lay on top the LABEL props.
        let excludeList = classifiers.remove(classifiers.allSubtypesPlusMarginAndWidth(), PROPERTY_TEXT);
        const styleSet = this.newLocalStyleSet(myContext, SHRINK);
        this.exportStyleTo(styleSet, myContext, [ PROPERTY_TEXT ], ((excludeList)));
        excludeList = classifiers.remove(classifiers.allSubtypesPlusMarginAndWidth(), PROPERTY_LABEL);
        this.exportStyleTo(styleSet, myContext, [ PROPERTY_LABEL ], ((excludeList)));

        return styleSet;
    }

    findFillers(parentContext, fillerSearch = new FillerSearch()) {
        // The content will be a comma separated list of [ property | @const | const[x] ]
        const myContext = this.updateContext(parentContext);
        const content = GmlUtil.getValueForExpr(this.json, [ INNER ]) || '';
        content.split(',')
            .map(m => m.trim())
            .forEach(e => {
                if (e === '*filler') {
                    fillerSearch.bump(1, myContext.flexDirection);
                }
            });
        return fillerSearch;
    }

    newLocalStyleSet(myContext, parentDirection, initial) {
        const styleSet = new StyleSet(initial);
        if (parentDirection.isRow()) {
            styleSet.flexPolicy.setStandardShrink();
        }
        this.applyDebugColor(myContext, styleSet);
        return styleSet;
    }

    setJustifyContent(myContext, grouping, styleSet) {
        const result = styleSet || new StyleSet();
        if (!styleSet) {
            this.applyDebugColor(myContext, result);
            GmlUtil.matchFlexDirection(myContext.flexDirection, result);
            if (myContext.flexDirection.isRow()) {
                result.flexPolicy.setStandardShrink();
            }
            const ai = this.getAlignItemsAttribute(myContext);
            result.flexPolicy.alignItems = ai ? ai.value : 'center';
        }
        if (grouping.justifyContent) {
            result.flexPolicy.justifyContent = grouping.justifyContent;
        }
        return result;
    }

    classifiers = {
        allSubtypes: () => {
            return [ 'PROPERTY:DATA', 'PROPERTY:EDIT', 'PROPERTY:LABEL', 'PROPERTY:TEXT' ].slice();
        },
        allSubtypesPlusMarginAndWidth: () => {
            return [ 'PROPERTY:DATA', 'PROPERTY:EDIT', 'PROPERTY:LABEL', 'PROPERTY:TEXT', 'PROPERTY:MARGIN', 'PROPERTY:WIDTH' ].slice();
        },
        marginAndWidth: () => {
            return [ 'PROPERTY:MARGIN', 'PROPERTY:WIDTH' ].slice();
        },
        add: (list, toAdd) => {
            toAdd.forEach(e => list.push(e));
            return list;
        },
        remove: (list, toRemove) => {
            return list.filter(f => f !== toRemove);
        },
    }

    validate(myContext, propNames) {
        if (!myContext.isWithinHeaderOrFooter || !myContext.isWithinList) {
            return propNames; // No need to validate
        }
        // No props are allowed
        const { warnings } = myContext;
        return propNames.filter(f => {
            const isConst = f.search('@const/') > -1;
            const isRes = f.search('res:') > -1;
            const isIcon = f.search('icon:') > -1;
            const isFiller = f.search(/\*filler/) > -1;
            if (!isConst && !isRes && !isIcon && !isFiller) {
                warnings.addNoPropsInListHeaderFooter(f);
                return false;
            }
            return true;
        });
    }
}
