/**
 *
 */
export class WriteLargePropertyParametersVisitor {
    constructor(value) {
        if (!value) {
            throw new Error('WriteLargePropertyParametersVisitor -- null value exception');
        }
        if (typeof value === 'string') {
            this._enclosedJsonObject = JSON.parse(value);
        }
        else {
            this._enclosedJsonObject = value;
        }
    }
    // --- State Management Helpers --- //
    // --- State Import/Export --- //
    copyAsJsonObject() {
        return JSON.parse(this.copyAsJsonString());
    }
    copyAsJsonString() {
        return JSON.stringify(this.enclosedJsonObject());
    }
    enclosedJsonObject() {
        return this._enclosedJsonObject;
    }
    // --- State Management --- //
    visitAppend() {
        return this.enclosedJsonObject().append;
    }
}
