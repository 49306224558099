/* eslint-disable react/prefer-stateless-function */
import React, { Component, Fragment } from 'react';
import * as PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import DateFnsUtils from '@date-io/date-fns';

import { ViewState, EditingState, IntegratedEditing } from '@devexpress/dx-react-scheduler';

import {
    AllDayPanel,
    Appointments,
    ConfirmationDialog,
    DragDropProvider,
    DayView,
    MonthView,
    Scheduler,
    TodayButton,
    Toolbar,
    ViewSwitcher,
    WeekView,
} from '@devexpress/dx-react-scheduler-material-ui';
import IconButton from '../IconButton/IconButton';
import Appointment from '../Appointment/Appointment';
import CalendarCell from '../CalendarCell/CalendarCell';
import CalendarDatePicker from '../CalendarDatePicker/CalendarDatePicker';

// Styles
import getStyles from './Calendar.styles';

class Calendar extends Component {
    static propTypes = {
        /** Styles for this component */
        contextStyles: PropTypes.shape({
            /** Styles for the calendar container */
            container: PropTypes.object,
            /** Styles for the event container */
            event: PropTypes.object,
            /** Styles for the view switcher container */
            switcher: PropTypes.object,
        }),

        /** Appointment array of objects to show events on calendar */
        appointments: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string,
            startDate: PropTypes.instanceOf(Date),
            endDate: PropTypes.instanceOf(Date),
        })),

        /** Counter with dates and respective number of all day events per day */
        allDayCounter: PropTypes.objectOf(PropTypes.number),

        /** Default current date to show on render */
        defaultDate: PropTypes.oneOfType([
            PropTypes.instanceOf(Date),
            PropTypes.string,
        ]),

        /** Default view to show on render */
        defaultView: PropTypes.oneOf([
            'Day',
            'Week',
            'Month',
        ]),

        /** Date picked on the fixed date picker */
        pickedDate: PropTypes.oneOfType([
            PropTypes.instanceOf(Date),
            PropTypes.string,
        ]),

        /** Handler method when an appointment is dragged and dropped */
        onAppointmentUpdate: PropTypes.func,

        /** Handler method for date picker year/month change */
        onPickerChange: PropTypes.func,

        /** Handler method for clicking left/previous on the calendar */
        onPrevious: PropTypes.func,

        /** Handler method for clicking right/next on the calendar */
        onNext: PropTypes.func,

        /** Handler method for right click/context menu event */
        onEventContextMenu: PropTypes.func,

        /** Handler methods for click event */
        onEventClick: PropTypes.func,

        /** Handler method for double click event */
        onEventDoubleClick: PropTypes.func,

        firstDayOfWeek: PropTypes.number,

        /** Locale to specify the format the timeline should be displayed */
        locale: PropTypes.string,

        /** Event to handle current date change using date navigator */
        onDateChange: PropTypes.func,

        /** Event to handle when view changes between day, week and month */
        onViewChange: PropTypes.func,

        /** Event triggered when a cell is clicked in month view */
        onMonthCellClick: PropTypes.func,

        /** Event triggered when a cell is double clicked in month view */
        onMonthCellDoubleClick: PropTypes.func,
    };

    static defaultProps = {
        contextStyles: {},
        defaultDate: new Date(),
        defaultView: 'Month',
        pickedDate: new Date(),
    };

    // Prevents re-renders, improves performance and doesn't lose scroll position on selection
    // @TODO: Find a better way to stop re-renders and remember scroll position. This works fine but I'm not 100% certain it won't cause side effects in the future
    shouldComponentUpdate(prevProps) {
        if (JSON.stringify(prevProps) === JSON.stringify(this.props)) {
            return false;
        }
        return true;
    }

    render() {
        const {
            appointments,
            allDayCounter,
            contextStyles,
            defaultDate,
            defaultView,
            pickedDate,
            onAppointmentUpdate,
            onDateChange,
            onPrevious,
            onNext,
            onPickerChange,
            onEventContextMenu,
            onViewChange,
            onMonthCellClick,
            onMonthCellDoubleClick,
            onEventClick,
            onEventDoubleClick,
            firstDayOfWeek,
            locale,
        } = this.props;

        const {
            appointment: appointmentStyles,
            appointmentContainer: appointmentContainerStyles,
            appointmentContent: appointmentContentStyles,
            allDayAppointment: allDayAppointmentStyles,
            container: containerStyles,
            currentDateHeader: currentDateHeaderStyles,
            dayScaleCell: dayScaleCellStyles,
            weekDayCell: weekDayCellStyles,
            weekDayCellText: weekDayCellTextStyles,
            weekDayCellTextSelected: weekDayCellTextSelectedStyles,
            weekDayCellNumber: weekDayCellNumberStyles,
            weekDayCellNumberSelected: weekDayCellNumberSelectedStyles,
            monthAppointment: monthAppointmentStyles,
            paperContainer: paperContainerStyles,
            pickerContainer: pickerContainerStyles,
            switcher: switcherStyles,
            todayButton: todayButtonStyles,
            todayCell: todayCellStyles,
            toolbar: toolbarStyles,
            timeTableCell: timeTableCellStyles,
            timeTableCellWeekend: timeTableCellWeekendStyles,
            secondDatePickerCell: secondDatePickerCellStyles,
            selectedDay: selectedDayStyles,
        } = getStyles(contextStyles);

        const datefns = new DateFnsUtils(new Date(defaultDate));

        const RootProps = ({ ...restProps }) => (
            <Scheduler.Root
                style={ containerStyles }
                { ...restProps }>
            </Scheduler.Root>
        );

        const SwitcherProps = ({ ...restProps }) => (
            <ViewSwitcher.Switcher
                style={ switcherStyles }
                { ...restProps }>
            </ViewSwitcher.Switcher>
        );

        const AllDayAppointmentProps = ({ ...restProps }) => {
            const currentDate = new Date(defaultDate); // get current date
            currentDate.setHours(0, 0, 0, 0);
            const first = currentDate.getDate() - currentDate.getDay(); // First day is the day of the month - the day of the week
            const firstDay = new Date(currentDate.setDate(first));
            const last = firstDay.getDate() + 6; // last day is the first day + 6
            const lastDay = new Date(currentDate.setDate(last));
            let loop = new Date(firstDay);
            let highestCounter = 0;
            while (loop <= lastDay) {
                if (allDayCounter[loop] && allDayCounter[loop] > highestCounter) {
                    highestCounter = allDayCounter[loop];
                }
                const newDate = loop.setDate(loop.getDate() + 1);
                loop = new Date(newDate);
            }
            const weekStyles = {
                style: {
                    height: `${(highestCounter + 1) * 20}px`,
                    position: 'relative',
                },
            };
            const dayStyles = {
                style: allDayAppointmentStyles,
            };
            return (
                <AllDayPanel.AppointmentLayer
                    { ...(defaultView === 'Day' && dayStyles) }
                    { ...(defaultView === 'Week' && weekStyles) }
                    { ...restProps } />
            );
        };

        const AppointmentContainerProps = ({ ...restProps }) => {
            const appointmentChild = restProps.children[1];
            const {
                props: {
                    params: {
                        data: {
                            allDay,
                            counter,
                        },
                    },
                },
            } = appointmentChild;
            const minCounter = counter ? Math.max(...Object.values(counter)) : 0;
            const monthStyles = {
                style: {
                    ...restProps.style,
                    ...monthAppointmentStyles,
                },
            };
            const weekStyles = {
                top: `${minCounter * 20}px`,
                position: 'absolute',
            };
            const mergedStyles = {
                style: {
                    ...restProps.style,
                    ...appointmentContainerStyles,
                    ...(defaultView === 'Week' && weekStyles),
                },
            };
            const isValidAllDay = allDay && (defaultView === 'Day' || defaultView === 'Week');
            return (
                <Appointments.Container
                    { ...restProps }
                    { ...(defaultView === 'Month' && monthStyles) }
                    { ...(isValidAllDay && mergedStyles) } />
            );
        };

        const AppointmentProps = ({ ...restProps }) => (
            <Appointment
                { ...restProps }
                contextStyles={ {
                    container: appointmentStyles,
                } }
                onEventContextMenu={ onEventContextMenu }
                onEventDoubleClick={ onEventDoubleClick }
                onEventClick={ onEventClick } />
        );

        const AppointmentContentProps = ({ ...restProps }) => {
            const {
                timeString,
                title,
            } = restProps.data;
            return (
                <Fragment>
                    <span
                        style={ {
                            padding: '0 5px',
                        } }>
                        { timeString }
                    </span>
                    <span
                        style={ appointmentContentStyles }>
                        { title }
                    </span>
                </Fragment>
            );
        };

        const DragDropDraftProps = ({ ...restProps }) => (
            <DragDropProvider.DraftAppointment
                { ...restProps }
                style={ { ...restProps.style, ...restProps.data.style } } />
        );

        const DragDropSourceProps = ({ ...restProps }) => (
            <DragDropProvider.SourceAppointment
                { ...restProps }
                style={ { ...restProps.style, ...restProps.data.style } } />
        );

        const WeekDayScaleCellProps = ({ ...restProps }) => {
            const {
                startDate,
                today,
            } = restProps;
            let dayOfWeek = '';
            const alignContent = {
                justifyContent: 'flex-end',
            };
            if (defaultView === 'Week') {
                dayOfWeek = datefns.format(startDate, 'eee');
            }
            else if (defaultView === 'Day') {
                dayOfWeek = datefns.format(startDate, 'eeee');
                alignContent.justifyContent = 'flex-start';
            }
            return (
                <td style={ {
                    padding: '0',
                } }>
                    <div style={ {
                        ...weekDayCellStyles,
                        ...alignContent,
                    } }>
                        <div
                            style={ {
                                ...weekDayCellTextStyles,
                                ...(today ? weekDayCellTextSelectedStyles : {} ),
                            } }>
                            { dayOfWeek }
                        </div>
                        <div
                            style={ {
                                ...weekDayCellNumberStyles,
                                ...(today ? weekDayCellNumberSelectedStyles : {} ),
                            } }>
                            { startDate.getDate() }
                        </div>
                    </div>
                </td>
            );
        };

        const DayScaleCellProps = ({ ...restProps }) => {
            const {
                startDate,
            } = restProps;
            const weekDay = datefns.format(startDate, 'eee');
            return (
                <td
                    style={ dayScaleCellStyles }>
                    <div>
                        { weekDay }
                    </div>
                </td>
            );
        };

        const ToolbarProps = ({ ...restProps }) => (
            <Toolbar.Root
                { ...restProps }
                style={ toolbarStyles } />
        );

        const TimeTableCellProps = ({ ...restProps }) => {
            const {
                otherMonth,
                startDate,
                today,
            } = restProps;
            let displayDate = '';
            let isWeekend = false;
            if (startDate.getDate() === 1 && !otherMonth) {
                displayDate = `${datefns.format(startDate, 'MMM')}`;
            }
            if ((startDate.getDay() === 0) || (startDate.getDay() === 6)) {
                isWeekend = true;
            }
            return (
                <CalendarCell
                    contextStyles={ {
                        calendarCellStyles: {
                            ...timeTableCellStyles,
                            ...(isWeekend ? timeTableCellWeekendStyles : {}),
                        },
                    } }
                    date={ startDate }
                    onCellContextMenu={ onEventContextMenu }
                    onCellClick={ onMonthCellClick }
                    onCellDoubleClick={ onMonthCellDoubleClick }>
                    <div
                        style={ {...(today && todayCellStyles)} }>
                        { `${displayDate} ${startDate.getDate()}` }
                    </div>
                </CalendarCell>
            );
        };

        const TodayButtonProps = ({ ...restProps }) => (
            <Fragment>
                <IconButton
                    iconName="chevron_left"
                    onClick={ onPrevious } />
                <TodayButton.Button
                    style={ todayButtonStyles }
                    { ...restProps } />
                <IconButton
                    iconName="chevron_right"
                    onClick={ onNext } />
            </Fragment>
        );

        const allowResize = () => (
            !(defaultView === 'Week')
        );

        const handleDateChange = (date) => {
            onPickerChange(date);
            onDateChange(date);
        };

        // Render layout container and contents
        return (
            <Paper
                style={ paperContainerStyles }>
                <div
                    style={ pickerContainerStyles }>
                    <div
                        style={ currentDateHeaderStyles }>
                        { datefns.format(new Date(defaultDate), 'MMMM yyyy') }
                    </div>
                    <CalendarDatePicker
                        contextStyles={ {
                            secondDatePickerCellStyles,
                            selectedDayStyles,
                        } }
                        defaultDate={ defaultDate }
                        defaultView={ defaultView }
                        onDateChange={ onDateChange }
                        onPickerChange={ onPickerChange }
                        firstDayOfWeek={ firstDayOfWeek }
                        pickedDate={ pickedDate } />
                </div>
                <Scheduler
                    rootComponent={ RootProps }
                    data={ appointments }
                    firstDayOfWeek={ firstDayOfWeek }
                    locale={ locale }>
                    <ViewState
                        currentDate={ defaultDate }
                        currentViewName={ defaultView }
                        onCurrentViewNameChange={ onViewChange }
                        onCurrentDateChange={ handleDateChange } />
                    <DayView
                        dayScaleCellComponent={ WeekDayScaleCellProps } />
                    <WeekView
                        dayScaleCellComponent={ WeekDayScaleCellProps } />
                    <MonthView
                        dayScaleCellComponent={ DayScaleCellProps }
                        timeTableCellComponent={ TimeTableCellProps } />
                    <AllDayPanel
                        appointmentLayerComponent={ AllDayAppointmentProps } />
                    <Toolbar
                        rootComponent={ ToolbarProps } />
                    <Appointments
                        appointmentComponent={ AppointmentProps }
                        appointmentContentComponent={ AppointmentContentProps }
                        containerComponent={ AppointmentContainerProps } />
                    <EditingState
                        onCommitChanges={ onAppointmentUpdate } />
                    <IntegratedEditing />
                    <ViewSwitcher
                        switcherComponent={ SwitcherProps } />
                    <TodayButton
                        buttonComponent={ TodayButtonProps } />
                    <ConfirmationDialog />
                    <DragDropProvider
                        allowResize={ allowResize }
                        draftAppointmentComponent={ DragDropDraftProps }
                        sourceAppointmentComponent={ DragDropSourceProps } />
                </Scheduler>
            </Paper>
        );
    }
}

export default Calendar;
