import React, { Component } from 'react';
import componentFactory from '../componentFactory';
import componentPropTypes from './abstractComponentPropTypes';
import serviceFactory from '../../services/serviceFactory';

// Note: these should be a subset of abstractComponentPropTypes
const propDefinitions = {
    style: componentPropTypes.style,
    name: componentPropTypes.name,
    value: componentPropTypes.value,
    tipText: componentPropTypes.tipText,
    isReadMode: componentPropTypes.isReadMode,
    isFocused: componentPropTypes.isFocused,
    onValueChanged: componentPropTypes.onValueChanged,
    onLoadOptionValues: componentPropTypes.onLoadOptionValues,
    xStyle: componentPropTypes.xStyle,
    optionValues: componentPropTypes.optionValues,
    propertyRef: componentPropTypes.propertyRef,
    placeholder: componentPropTypes.placeholder
};
export default class DropDownField extends Component {
    static propTypes = propDefinitions;
    static defaultProps = {
        onLoadOptionValues: () => {},
    };

    render() {
        const {
            style,
            tipText,
            isReadMode,
            isFocused,
            xStyle,
            optionValues,
            propertyRef,
        } = this.props;

        const dropDownFieldProps = {
            style,
            value: this.getValue(),
            tipText,
            isReadMode,
            isFocused,
            onValueChanged: this.handleOnValueChanged,
            xStyle,
            optionValues,
            disableClearable: true,
            propertyRef,
            placeholder: serviceFactory.lang.details.dropdown.noSelectedItem,
        };
        return React.createElement(componentFactory.getPlatformPropertyComponent('dropDownField'), { ...dropDownFieldProps });
    }

    componentDidMount() {
        const { onLoadOptionValues } = this.props;
        onLoadOptionValues();
    }

    getValue = () => {
        // Value should always be either code -> CodeRef/objectId -> ObjectRef/plain string. It will not be a description.
        const { value, optionValues } = this.props;
        return optionValues.find((option) => option.value === value);
    }

    handleOnValueChanged = (newValue) => {
        /** @todo
         *  We need to cleanup all the string passing for consistency. Currently we have to hijack
         *  some dropdown properties because it maybe a string value bing passed and not an object.
         */
        const { onValueChanged } = this.props;
        onValueChanged(newValue);
    }
}
