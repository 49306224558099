const getStyles = () => {
    return {
        section: {
            flexGrow: 1,
            flexDirection: 'column',
            minHeight: 0,
            flexShrink: 'unset',
        },
        scrollContent: {
            flexDirection: 'column',
            overflow: 'auto',
            minHeight: 0,
            flexShrink: 'unset',
        },
    };
};

export default getStyles;
