// Core component styles
const coreStyles = {
    actionAreaContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },

    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#ffffff',
        borderRadius: '6px',
        boxShadow: 'none',
        cursor: 'pointer',
        overflow: 'hidden',
        width: '284px',
        height: '231px',
        margin: '6px',
        border: '1px solid #E0E0E0',
    },

    containerHoverStyle: {
        backgroundColor: 'rgba(0, 151, 186, 0.2)',
        border: '1px solid #0097BA',
    },

    icon: {
        boxSizing: 'border-box',
        width: '90px',
        height: '100px',
        objectFit: 'contain',
    },

    iconContainer: {
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },

    iconLoadingIndicator: {},

    text: {
        cursor: 'pointer',
        wordBreak: 'break-word',
        color: '#000000',
        fontSize: '20px',
        fontWeight: 'normal',
        lineHeight: '20px',
    },

    textContainer: {
        display: 'flex',
        flexShrink: 1,
        borderTop: '1px solid',
        borderColor: '#E0E0E0',
        boxSizing: 'border-box',
        paddingTop: '22px',
        paddingBottom: '22px',
        paddingLeft: '20px',
        paddingRight: '20px',
        width: '100%',
    },

    textContainerHoverStyle: {
        borderColor: '#0097BA',
    },
};

/**
 * Helper method to get the styles for the component
 * @param {Object} contextStyles - Context styles for the Component
 */
const getStyles = (contextStyles = {}) => {
    return {
        actionAreaContainer: {
            ...coreStyles.actionAreaContainer,
            ...contextStyles.actionAreaContainer,
        },

        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        contentContainer: {
            ...coreStyles.contentContainer,
            ...contextStyles.contentContainer,
        },

        containerHoverStyle: {
            ...coreStyles.containerHoverStyle,
            ...contextStyles.containerHoverStyle,
        },

        icon: {
            ...coreStyles.icon,
            ...contextStyles.icon,
        },

        iconContainer: {
            ...coreStyles.iconContainer,
            ...contextStyles.iconContainer,
        },

        iconLoadingIndicator: {
            ...coreStyles.iconLoadingIndicator,
            ...contextStyles.iconLoadingIndicator,
        },

        text: {
            ...coreStyles.text,
            ...contextStyles.text,
        },

        textContainer: {
            ...coreStyles.textContainer,
            ...contextStyles.textContainer,
        },

        textContainerHoverStyle: {
            ...coreStyles.textContainerHoverStyle,
            ...contextStyles.textContainerHoverStyle,
        },
    };
};

export default getStyles;
