import React from 'react';
import { BaseComponentBuilder } from 'cv-react-core';
import IconButton from '../../components/base/IconButton';
import ImageActionComponentBuilder from './ImageActionComponentBuilder';

/**
 * @deprecated This component is being deprecated after  component refactoring.
 */
class ClearActionComponentBuilder extends BaseComponentBuilder {
    /**
     * COMPONENT BASED PROPERTIES
     */

    /** Begin Context Styles */
    setIconStyles(iconStyles) {
        return this.setContextStyles({
            ...this.getContextStyles(),
            icon: iconStyles,
        });
    }
    getIconStyles() {
        const { icon } = this.getContextStyles();
        return icon || {};
    }

    setButtonStyles(buttonStyles) {
        return this.setContextStyles({
            ...this.getContextStyles(),
            button: buttonStyles,
        });
    }
    getButtonStyles() {
        const { button } = this.getContextStyles();
        return button || {};
    }
    /** End Context Styles */

    /** Begin Props */
    setIconName(iconName) {
        this.props.iconName = iconName;
        return this;
    }
    getIconName() {
        return this.props.iconName;
    }

    setIconSize(iconSize) {
        this.props.iconSize = iconSize;
        return this;
    }
    getIconSize() {
        return this.props.iconSize;
    }

    setIconTitle(title) {
        this.props.title = title;
        return this;
    }
    getIconTitle() {
        return this.props.title;
    }

    setOnClickHandler(onClick) {
        if (typeof onClick === 'function') {
            this.props.onClick = onClick;
        }
        return this;
    }
    getOnChangeTextHandler() {
        return this.props.onClick;
    }
    /** End Props */

    processAction() {
        const onValueChangeHandler = this.getOnValueChangeHandler();
        const isReadMode = this.getReadMode();
        const property = this.getProperty();
        const { name: propertyName = '' } = property;

        if (!isReadMode && !!propertyName && !!onValueChangeHandler) {
            this.setOnClickHandler(() => {
                onValueChangeHandler(propertyName, null);
            });
        }
    }

    processIconProps() {
        const viewDef = this.getViewDef();
        const { actions } = viewDef;
        const clearAction = actions.find((action) => {
            const { actionId } = action;
            return actionId.indexOf('setToEmpty:') !== -1;
        });

        this.setIconTitle(clearAction.label);
        this.setIconName('cancel');
        this.setIconSize(ImageActionComponentBuilder.deriveImageSize(this.getStyle()));
    }

    processStyles() {
        const categorizedStyles = BaseComponentBuilder.styleHelper.categorizeWebStyles(this.getStyle());

        this.setContainerStyles({
            ...categorizedStyles.container,
            ...this.getContainerStyles(),
        });
        this.setButtonStyles({
            padding: 0,
            ...this.getButtonStyles(),
        });
        this.setIconStyles({
            ...categorizedStyles.text,
            ...this.getIconStyles(),
        });
    }

    buildProps() {
        // Set icon props
        this.processIconProps();

        // Set action styles
        this.processStyles();

        // Set action handler
        this.processAction();
    }
    /**
     * BUILD AND RETURN COMPONENT AS REACT ELEMENT
     */

    build() {
        // Process and construct final props
        this.buildProps();

        return (
            <IconButton { ...this.getProps() } />
        );
    }
}

export default ClearActionComponentBuilder;
