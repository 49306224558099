/* @flow */
export const session = {
    USER_ID: 'userId',
    PASSWORD: 'password',
    TENANT_ID: 'tenantId',
    ACCESS_TOKEN: 'accessToken',
    SHOW_PASSWORD: 'showPassword',
    SAVE_PASSWORD: 'savePassword',
    PASSWORD_EXPIRATION: 'passwordExpiration',
    IS_DIRTY: 'isDirty',
    FCM_DEVICE_ID: 'DeviceID',
    CURRENT_PASSWORD: 'currentPassword',
    NEW_PASSWORD: 'newPassword',
    CONFIRM_PASSWORD: 'confirmPassword',
    PASSWORD_EXPIRATION_CODE: 'CHANGE_PASSWORD',
    DOUBLE_CLICK_FOR_DEFAULT_ACTION: 'doubleClickForDefaultAction',
};
