// Core component styles
const coreStyles = {
    chartTextStyle: {
    },

    container: {
        minWidth: '800px',
    },

    labelLineStyle: {
        color: '#646464',
    },

    labelTextStyle: {
    },

    legendTextStyle: {
    },

    titleTextStyle: {
    },

    tooltipContainerStyle: {
        backgroundColor: '#000',
        borderRadius: 2,
    },

    tooltipTextStyle: {
        lineHeight: 13,
    },
};

/**
 * Helper method to get styles for the Pie Chart
 * @param {Object} contextStyles - custom styles for the pie chart
 */
const getStyles = (contextStyles) => {
    return {
        chartTextStyle: {
            ...coreStyles.chartTextStyle,
            ...contextStyles.chartTextStyle,
        },

        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        labelLineStyle: {
            ...coreStyles.labelLineStyle,
            ...contextStyles.labelLineStyle,
        },

        labelTextStyle: {
            ...coreStyles.labelTextStyle,
            ...contextStyles.labelTextStyle,
        },

        legendTextStyle: {
            ...coreStyles.legendTextStyle,
            ...contextStyles.legendTextStyle,
        },

        titleTextStyle: {
            ...coreStyles.titleTextStyle,
            ...contextStyles.titleTextStyle,
        },

        tooltipContainerStyle: {
            ...coreStyles.tooltipContainerStyle,
            ...contextStyles.tooltipContainerStyle,
        },

        tooltipTextStyle: {
            ...coreStyles.tooltipTextStyle,
            ...contextStyles.tooltipTextStyle,
        },
    };
};

export default getStyles;
