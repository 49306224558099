import { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Log, TypeNames, DialogMessageCode } from 'cv-dialog-sdk';
import { rootStore, constants, onTransitionRegistry } from 'cv-react-core';
import { LogLabels } from '../utilities/logUtil';

/* eslint-disable */
import recursiveRouteManager from './RecursiveRouteManager';
import lang from '../nls/i18n';
import routeNames from './routeNames';
import Navigator from './Navigator';
import fileManager from '../utilities/fileManager';
import linking from '../services/linking';
import clipboard from '../services/clipboard';
import oAuth from '../services/oAuth';

const {
    DIALOG
} = routeNames;
const {
    error,
    displayHints,
} = constants;
const {
    errorTypes,
    MODAL_STATE,
} = error;
const {
    // hard,
    soft,
} = errorTypes;
const { ui } = constants;
const { MODAL_DIALOG_ID, MODAL_DIALOG_STORE_INFO, DIALOG_VIEW_VISIBLE, WORKBENCH_UI_OBJECT_ID } = ui;
const { HINT_DIALOG } = displayHints;

// Logger Example: Label and static config
const logger = Log.debug.bind(Log, LogLabels.ROUTENAV);
const logColor = Log.gold;
class Route extends Component {
    static propTypes = {
        dialogId: PropTypes.string,
        navigator: PropTypes.instanceOf(Navigator),
    };


    /**
     * @param {Object} redirection
     */
    handleTransition = (redirection, modifiers={}) => {
        if (redirection) {
            if (onTransitionRegistry.notify(redirection) === null) {
                logger('Redirection handled by component', logColor)
                return Promise.resolve();
            }
            logger(`Moving to a new route for ${redirection.type ? redirection.type : redirection.dialogId}`, logColor);
            if (redirection.type === TypeNames.WebRedirectionTypeName) {
                return this.handleWebUrl(redirection);
            }
            if (redirection.type === TypeNames.OAuthLoginRedirectionTypeName) {
                return this.handleOAuthRedirection(redirection);
            }
            if (redirection.type === TypeNames.ContentRedirectionTypeName) {
                return this.handleContentRedirection(redirection);
            }
            if (redirection.type === TypeNames.NullRedirectionTypeName) {
                return this.handleNullRedirection(redirection);
            }
            if (redirection.type === TypeNames.DialogRedirectionTypeName) {
                return this.handleRouteToDialogPage(redirection, modifiers);
            }
            if (redirection.dialogId === '#home') {
                // @TODO - figure out how to navigate to the workbench
                return Promise.resolve();
            }
            if (redirection.dialogId === '#close') {
                return this.handleCloseDialog();
            }
        }
        // dialogs rather than redirections will be ignored
        logger('No redirection', logColor)
        return Promise.resolve();
    };

    handleCloseDialog = () => ( Promise.resolve() );

    handleContentRedirection = (redirection) => {
        return this.handleSourceDestroyed(redirection)
            .then(() => {
                const {
                    sessionStore,
                } = rootStore;
                const {
                    fileName: redirectionFileName,
                    id,
                    url,
                } = redirection;

                if (url) return window.open(url, '_blank');

                const fileName = `${redirectionFileName || id}`;
                return sessionStore.streamContent(redirection.id, fileManager.getStreamConsumer(fileName))
                    .then((content) => {
                        fileManager.getFileURL(content.contentType, content.encodedData)
                            .then((url) => {
                                // Response is object URL. You can either view or download the file
                                // View only pdf/image types and download rest all types
                                const viewFile = (content.contentType === 'application/pdf') ||
                                                /image\/(gif|jpg|jpeg|tiff|png)$/i.test(content.contentType);
                                linking.downloadFile(url, fileManager.getDownloadFileName(fileName), { viewFile });
                            })
                            .catch((err) => {
                                const error = {
                                    title: lang.dialog.errors.errorOpenDialogTitle,
                                    err,
                                    type: constants.error.errorTypes.hard,
                                };
                                this.handleError(error);
                            });
                    })
                    .catch((err) => {
                        const error = {
                            title: lang.dialog.errors.errorOpenDialogTitle,
                            err,
                            type: constants.error.errorTypes.hard,
                        };
                        this.handleError(error);
                    });
            })
            .catch((err) => {
                Log.debug(err, 'handleContentRedirection', 'Route');
            });
    };

    handleError = (error) => {
        // if the session is not there, don't pop an error, just log out
        // TODO: Determine if we should update all areas using onError to provide correct error object.
        const { err } = error;
        const { code } = err || { code: '' };
        const { uiStore } = rootStore;
        if(code !== DialogMessageCode.NO_SESSION) {
            const {
                dialogId,
            } = this.props;
            const {
                id,
                title,
                err,
                type = soft,
            } = error;
            if(err) Log.error(err.stack ? err.stack : err);
            const key = id || dialogId || WORKBENCH_UI_OBJECT_ID;
            uiStore.addErrorForUIObject(key, {
                title,
                err,
                type,
            });
            const errors = uiStore.getErrorsForUIObject(key);
            uiStore.setValueForUIObject(key, MODAL_STATE, {
                isModalOpen: true,
                errors,
            });
            return;
        }
        // If we error, we always want to remove the modal because it is destroyed
        uiStore.removeValueForUIObject(MODAL_DIALOG_ID, MODAL_DIALOG_STORE_INFO);
    };


    // Note: A page should observe the 'isRefreshNeeded' value on DialogStore to know when to 'refresh'
    handleNullRedirection = (redirection) => {
        return (
            this.handleSourceDestroyed(redirection)
        );
    };

    handleRouteToDialogPage = (redirection, modifiers={}) => {
        const { sessionStore, uiStore } = rootStore;
        const { dialogId: nextDialogId, displayHint } = redirection;
        const { navigator, dialogId: currentDialogId } = this.props;
        // We are looking for WorkbenchId in referring object (as second check) as we dont have WorkbenchId in navigator params during Workbench OAuth flow
        const { sourceDestroyed, rootDialogId, workbenchId: workbenchIdFromRefObj } = redirection.referringObject || {};
        const workbenchId = navigator.match.params.workbenchId || workbenchIdFromRefObj;
        const { session: { tenantId } } = sessionStore;
        return sessionStore.openOrRefreshDialogStore(nextDialogId)
            .then(() => {
                const loc = `/${tenantId}/${DIALOG}/${workbenchId}/${nextDialogId}`;

                const isModalRedirection = displayHint && displayHint === HINT_DIALOG;

                // As we can now navigate from one launcher to another launcher directly using Side Drawer,
                // we have to clear all the existing dialog route modals from breadcrumbs when we move to a different launcher.
                if (modifiers[constants.ui.CLEAR_DIALOG_BREAD_CRUMBS]) {
                    recursiveRouteManager.clearDialogRouteModels();
                }

                // Modifier was enabled for loading this view. Currently that is Command/Alt depending on OS
                // Note, you cannot pop a modal out to a new window
                if(modifiers[constants.transitionModifiers.OPEN_IN_TAB] && !isModalRedirection) {
                    window.open(loc, '_blank');
                    return null;
                }

                // hide the current modal if any exist. This is managed by a single object
                uiStore.removeValueForUIObject(MODAL_DIALOG_ID, MODAL_DIALOG_STORE_INFO);

                // Are we going to or coming from a modal route
                if (this.isModalRouting(redirection)) {
                    this.handleModalDialog(uiStore, rootDialogId, currentDialogId, nextDialogId, sourceDestroyed, redirection, loc);
                    return null;
                }

                 if (sourceDestroyed) {
                    // Replace the local on the stack and transition
                    recursiveRouteManager.pop(currentDialogId);
                    return navigator.history.replace(loc);
                }

                // Push the local on the stack and transition
                return navigator.history.push(loc);
            })
            .catch((err) => {
                const error = {
                    title: lang.dialog.errors.errorOpenDialogTitle,
                    err,
                    type: constants.error.errorTypes.hard,
                };
                this.handleError(error);
            });
    };

    handleModalDialog = (uiStore, rootDialogId, currentDialogId, nextDialogId, sourceDestroyed, redirection, loc) => {
        const { displayHint } = redirection;
        const isModalRoute = displayHint && displayHint === HINT_DIALOG || false;
        const { navigator } = this.props;

        // A modal can initiate a set of actions to pull data. If we are looping through
        // this series of actions, we want to replace the existing location and pop the breadcrumb
        // because you cannot go back to the previous location.
        const isFormDialogRoute = rootDialogId === currentDialogId;

        if (isModalRoute) {
            // If the source content is destroyed we need to pop the breadcrumb and indicate to the modal that you can not go back
            // to the page that is visible behind the modal.
            if (sourceDestroyed && isFormDialogRoute) {
                recursiveRouteManager.pop(currentDialogId);
                this.handleSourceDestroyed(redirection);
            }

            // Due to the developer mode dialog, we may lose scope of the visible dialog. So we need to also check
            // and make sure the visible dialog is also active or destroyed.
            let isVsibleDialogDestroyed = this.isDialogDestroyed && this.isDialogDestroyed(currentDialogId);

            // Setup the observable so the modal knows how to load
            uiStore.setValueForUIObject(MODAL_DIALOG_ID, MODAL_DIALOG_STORE_INFO, {
                dialogId: nextDialogId,
                isModalOpen: true,
                goBackOnClose: sourceDestroyed && (isFormDialogRoute || isVsibleDialogDestroyed),
                sourceDestroyed,
                onTransition: this.handleTransition,
                onError: this.handleError,
            });
            return uiStore.setValueForUIObject(rootDialogId, DIALOG_VIEW_VISIBLE, false);
        }
        else if (sourceDestroyed && isFormDialogRoute) {
            recursiveRouteManager.pop(currentDialogId);
            return navigator.history.replace(loc);
        }
        else if (currentDialogId && this.isDialogDestroyed(currentDialogId)) {
            recursiveRouteManager.pop(currentDialogId);
            return navigator.history.push(loc);
        }

        return navigator.history.push(loc);

    }

    handleSourceDestroyed = (redirection) => {
        const { navigator, dialogId: currentDialogId } = this.props;
        const { sourceDestroyed, rootDialogId } = redirection.referringObject;
        const { uiStore, sessionStore } = rootStore;

        if(sourceDestroyed) { // If this is a modal exists, destroy it
            if(uiStore.getValueForUIObject(MODAL_DIALOG_ID, MODAL_DIALOG_STORE_INFO)) {
                uiStore.removeValueForUIObject(MODAL_DIALOG_ID, MODAL_DIALOG_STORE_INFO);
                const dialogStore = sessionStore.getOpenDialogStore(currentDialogId);
                if(dialogStore) {
                    const { dialog: { isDestroyed }} = dialogStore;
                    isDestroyed ? navigator.history.goBack() : dialogStore.setAllIsRefreshNeeded(true);
                }
            // if this is the current form, go back
            } else if (rootDialogId === currentDialogId) {
                navigator.history.goBack();
            } else if (!navigator.history || !navigator.history.goBack || navigator.history.length <= 1) {
                // Manually inserting a error info for user that Tab will be closed as their is history to goBack to prior dialog,
                // And this is most possibly expected when dialog is opened in new tab
                uiStore.setValueForUIObject(currentDialogId, MODAL_STATE, {
                    isModalOpen: true,
                    errors: [
                        {
                            title: lang.errors.noHistoryCloseTabTitle,
                            err: {
                                message: lang.errors.noHistoryCloseTabMessage,
                                stackTrace: lang.errors.noHistoryCloseTabMessage,
                            },
                            type: constants.error.errorTypes.hard,
                        },
                    ],
                    isCloseWindow: true,
                });
            } else {
                if (this.isDialogDestroyed && this.isDialogDestroyed(currentDialogId)) {
                    navigator.history.goBack();
                }
                else if (this.handleReFocus) this.handleReFocus(currentDialogId);
            }
        }

        return Promise.resolve();
    };

    handleWebUrl = async (redirection) => {
        this.handleSourceDestroyed(redirection).catch((err) => {
            Log.debug(err, 'handleWebUrl', 'Route');
        });
        const {
            url,
        } = redirection;

        if (url) {
            if (url.indexOf('clipboard=true') > -1) {
                try {
                    const data = await fetch(url);
                    const text = await data.text();
                    clipboard.cpoyContentToClipboard(text);
                }
                catch {
                    clipboard.cpoyContentToClipboard('');
                }
            }
            else {
                window.open(url);
            }
        }
        else {
            this.handleError({
                title: lang.dialog.errors.errorOpenDialogTitle,
                err: {
                    title: lang.dialog.errors.errorNoProgramForUrlTitle,
                    msg: lang.formatString(lang.dialog.errors.noProgramForUrlMsg, url),
                },
            });
        }
        return Promise.resolve();
    };

    handleOAuthRedirection = async (redirection) => {
        const {
            oAuthLoginURL,
        } = redirection;

        if (oAuthLoginURL) {
            oAuth.openInCustomTab(oAuthLoginURL);
        }
        else {
            this.handleError({
                title: lang.dialog.errors.errorOpenDialogTitle,
                err: {
                    title: lang.dialog.errors.errorNoProgramForUrlTitle,
                    msg: lang.formatString(lang.dialog.errors.noProgramForUrlMsg, url),
                },
            });
        }
        return Promise.resolve();
    };

    isModalRouting = (redirection) => {
        const { displayHint, referringObject } = redirection;
        // No reference object means this is deeplinking URL
        if (!referringObject) {
             return false;
        }
        const { dialogProperties: { displayHint: propertyDisplayHint } } = referringObject;
        if (displayHint && displayHint === HINT_DIALOG) {
            return true;
        }
        else if (propertyDisplayHint && propertyDisplayHint === HINT_DIALOG) {
            return true;
        }
        return false;
    }

    handleOnLogin = () => {
        // TODO: Need to add routing to login
        // Log.info('Post login callback...');
        // const { history, navigator } = this.props;
        // const { params: { tenantId } } = navigator;
        // const loc = `/${tenantId}/${routeNames.WORKBENCH}`;
        // history.push(loc);
    }
}

export default Route;
