// Core component styles
const coreStyles = {
    container: {
    },

    text: {
    },
};

/**
* Helper method to get the styles for Icon component
* @param {Object} contextStyles - Context styles for Icon Component
*/
const getStyles = (contextStyles = {}) => {
    return {
        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        text: {
            ...coreStyles.text,
            ...contextStyles.text,
        },
    };
};

export default getStyles;
