import jsonata from 'jsonata';
import { Log } from 'cv-dialog-sdk';
import accessors from './extensions/accessors';
import comparable from './extensions/comparable';
import comparators from './extensions/comparators';
import modifiers from './extensions/modifiers';


export default class Expr {
    static getValueForExpr(rootObj, expression, bindings) {
        if (expression && rootObj) {
            let expr = null;
            try {
                expr = jsonata(expression);
                return expr.evaluate(rootObj, Expr.mergeBindings(bindings, rootObj, expression));
            } catch (e) {
                const msg = `Invalid salt expr: '${expression}' :: ${e.message}`;
                Log.warn(msg);
                throw new Error(`Invalid salt expr: '${expression}' :: ${e.message}`);
            }
        }
        return null;
    }

    static mergeBindings(bindings, rootObj, expression) {
        return {
            ...accessors(rootObj, expression),
            ...comparable(rootObj, expression),
            ...comparators(rootObj, expression),
            ...modifiers(rootObj, expression),
            ...bindings,
        };
    }
}
