import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import { ButtonBase } from 'cv-library-react-web';

import Icon from '../Icon';
import Image from '../Image';
import TextLabel from '../TextLabel';

import getStyles from './ActionButton.styles';
import IconButton from '../IconButton';

/**
 * An ActionButton is a clickable action represented by an icon and a label.  Either may be omitted.  Upon pressing
 * the tool the onAction callback is fired.
 */
export const LABEL_PLACEMENT_PRE = 'pre';
export const LABEL_PLACEMENT_POST = 'post';
export const LABEL_PLACEMENT_NONE = 'none';

export const defaultProps = {
    id: '',
    label: '',
    labelPlacement: LABEL_PLACEMENT_NONE,
    iconStyles: {},
    highlightAction: false,
    tooltip: 'default tooltip',
};

export const propTypes = {
    contextStyles: PropTypes.object,
    id: PropTypes.string,
    imageURL: PropTypes.string,
    icon: PropTypes.string,
    toggleIcon: PropTypes.string,
    iconStyles: PropTypes.object,
    label: PropTypes.string,
    labelPlacement: PropTypes.string,
    highlightAction: PropTypes.bool,
    tooltip: PropTypes.string,
    onAction: PropTypes.func,
};

class ActionButton extends PureComponent {
    static defaultProps = defaultProps;

    static propTypes = propTypes;

    constructor(props) {
        super(props);
        this.state = {
            showAltIcon: false,
        };
    }

    render() {
        // Layout each component in sequence, if present.
        const {
            contextStyles,
            highlightAction,
            icon,
            iconStyles,
            imageURL,
            label,
            labelPlacement,
            tooltip,
        } = this.props;

        const styles = getStyles({
            icon: iconStyles,
            ...contextStyles,
        });

        // Generate base button props
        const buttonProps = {
            contextStyles: {
                container: highlightAction ? styles.highlightedContainer : styles.container,
                contentContainer: styles.contentContainer,
            },
            onClick: this.handlePress,
            variant: 'outlined',
            tooltip,
        };
        const isIconOnly = icon && !label;

        const Button = isIconOnly ?
            (
                <IconButton
                    contextStyles={ {
                        button: { padding: '6px' },
                        container: styles.iconContainer,
                    } }
                    iconName={ icon }
                    iconSize={ 20 }
                    onClick={ this.handlePress }
                    title={ tooltip } />
            ) :
            (
                <ButtonBase { ...buttonProps }>
                    { label && labelPlacement === LABEL_PLACEMENT_PRE && this.renderLabel(styles.text) }
                    { icon && this.renderIcon(styles.icon) }
                    { imageURL && !icon && this.renderImage(styles.icon, imageURL) }
                    { label && labelPlacement === LABEL_PLACEMENT_POST && this.renderLabel(styles.text) }
                </ButtonBase>
            );
        return this.renderModal(Button) || Button;
    }

    renderImage = (styles, imageSrc) => {
        // Generate image props
        const imageProps = {
            contextStyles: {
                container: this.applyMarginForLabel(styles),
            },
            imageSrc,
        };

        return (
            <Image { ...imageProps } />
        );
    };

    renderIcon = (styles) => {
        const {
            showAltIcon,
        } = this.state;
        const {
            icon,
            toggleIcon,
        } = this.props;

        // This icon is really an image URL.
        if (icon.substring(0, 4) === 'http') {
            return this.renderImage(styles, icon);
        }

        // Generate icon props
        const iconProps = {
            contextStyles: {
                container: this.applyMarginForLabel(styles),
            },
            iconName: showAltIcon ? toggleIcon : icon,
        };

        return (
            <Icon { ...iconProps } />
        );
    };

    renderLabel = (styles) => {
        const {
            label,
        } = this.props;

        // Generate text props
        const textProps = {
            contextStyles: {
                text: styles,
            },
        };

        return (
            <TextLabel { ...textProps }>
                { label }
            </TextLabel>
        );
    };

    /**
     * To be overridden
     * @param {React.ReactElement} [Button] - Component used to trigger menu
     * @returns {React.ReactElement}
     */
    renderModal = (Button) => null; // eslint-disable-line

    handlePress = (nativeEvent) => {
        const {
            showAltIcon,
        } = this.state;
        const {
            id,
            icon,
            toggleIcon,
            label,
            onAction,
        } = this.props;
        const menu = {
            id,
            icon,
            label,
        };

        if (onAction) {
            onAction(menu, nativeEvent);
        }

        if (icon && toggleIcon) {
            this.setState({
                showAltIcon: !showAltIcon,
            });
        }
    };

    applyMarginForLabel = (styles) => {
        const {
            labelPlacement,
        } = this.props;
        const margin = labelPlacement === LABEL_PLACEMENT_NONE ? 0 : 8;
        const newStyles = { ...styles }; // clone

        if (labelPlacement === LABEL_PLACEMENT_PRE) {
            newStyles.marginLeft = margin;
        }
        else if (labelPlacement === LABEL_PLACEMENT_POST) {
            newStyles.marginRight = margin;
        }

        return newStyles;
    };
}

export default ActionButton;
