/* eslint-disable putout/multiple-properties-destructuring */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'cv-library-react-web';

import Button, { BUTTON_VARIANT } from '../base/Button';
import TextLabel from '../base/TextLabel';
import IconButton from '../base/IconButton';
import getStyles from './styles/SearchSortForm.style';
import FilterControl from './FilterControl';

// Lang
import lang from '../../nls/i18n';

export default class SearchSortForm extends Component {
    static defaultProps = {
        style: {},
    }

    static propTypes = {
        title: PropTypes.string,
        filterTerms: PropTypes.array,
        searchDialogStore: PropTypes.object,
        onSubmit: PropTypes.func.isRequired,
        onClear: PropTypes.func.isRequired,
        onSortClear: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        onSetFilterValue: PropTypes.func,
        onSetFilterOperation: PropTypes.func,
        style: PropTypes.object,
    }

    constructor(props) {
        super(props);

        // TODO: This is a temporary solution until we can provide a better design
        // of the search search form. It was a quick implementation and little time
        // was spent on usablility. For now we will manage enabling/disabling cancel with state.
        this.state = {
            disableClearButton: false,
        };
    }

    render() {
        const { style, onSubmit, onClear, onSortClear } = this.props;
        // TODO: This is a temporary solution until we can provide a better design
        // of the search search form. It was a quick implementation and little time
        // was spent on usablility. For now we will manage enabling/disabling cancel with state.
        const { disableClearButton } = this.state;
        const styles = getStyles(style);
        return (
            <Panel
                contextStyles={ { container: { margin: '0px' } } }
                header={ this.renderTitleComponent(styles) }>
                <div
                    style={ {
                        display: 'flex',
                        flexDirection: 'row',
                        flexShrink: 1,
                        flexGrow: 1,
                        overflow: 'hidden',
                    } }>
                    <div
                        style={ styles.tabContainer }>
                        { this.renderFilterElement(styles) }
                    </div>
                </div>
                <div>
                    <div style={ styles.buttonContainer }>
                        <Button
                            contextStyles={ styles.buttons }
                            variant={ BUTTON_VARIANT.OUTLINED }
                            text={ lang.searchSort.clearButtonText }
                            onClick={ disableClearButton ? onSortClear : onClear } />
                        <Button
                            contextStyles={ styles.buttons }
                            variant={ BUTTON_VARIANT.CONTAINED }
                            text={ lang.searchSort.searchButtonText }
                            onClick={ onSubmit } />
                    </div>
                </div>
            </Panel>
        );
    }

    renderTitleComponent(styles) {
        const { title, onCancel } = this.props;
        const { titleComponent, titleContainer, titleTextColumn, titleButtonColumn, buttons } = styles;
        return (
            <div>
                <div style={ titleContainer }>
                    <div
                        style={ titleTextColumn }>
                        <TextLabel
                            contextStyles={ titleComponent }>
                            { title }
                        </TextLabel>
                    </div>
                    <div
                        style={ titleButtonColumn }>
                        <IconButton
                            contextStyles={ buttons }
                            iconName="cancel"
                            onClick={ onCancel } />
                    </div>
                </div>
            </div>
        );
    }

    renderFilterElement = (styles) => {
        const { filterTerms, onSetFilterOperation, onSetFilterValue, searchDialogStore } = this.props;
        const filterProps = {
            filterTerms,
            onSetFilterOperation,
            onSetFilterValue,
            styles,
            searchDialogStore,
        };
        return React.createElement(FilterControl, { ...filterProps });
    }
}
