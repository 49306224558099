/*       */

import StyleSet from '../StyleSet';

export default class Border {
    constructor(borderColor, left, top, width, height) {
        this.borderColor = borderColor;
        this.left = left;
        this.top = top;
        this.width = width;
        this.height = height;
    }
    asSalt = () => {
        const styleSet = new StyleSet({
            position: 'absolute',
            left: this.left,
            top: this.top,
            width: this.width,
            height: this.height,
            backgroundColor: this.borderColor,
        });

        return {
            box: {
                ...styleSet.asStyleAttribute(),
                ...styleSet.asXStyleAttribute(),
                children: [],
            },
        };
    }
}
