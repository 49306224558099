/* @flow */
export default {
    deleteButtonText: 'Delete',
    annotateButtonText: 'Annotate Image',
    getImageError: {
        title: 'Error getting Image',
        message: 'App failed to get image, please try again.',
        buttonText: 'OK',
    },
    deleteImageError: {
        title: 'Image Annotation',
        message: 'There is no image to delete.',
        buttonText: 'OK',
    },
    annotateImageError: {
        title: 'Image Annotation',
        message: 'Please upload or take an image to annotate.',
        buttonText: 'OK',
    },
};
