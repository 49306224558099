/* @flow */
export default {
    disabled: 'NFC disabled',
    enableMessage: 'Enable NFC to continue.',
    error: 'NFC Error.',
    goToNfcSetting: 'Go to NFC setting',
    instruction: 'Hold your device near the tag to get information.',
    invalidMessage: 'Invalid/unsupported message in the NFC tag.',
    notSupported: 'NFC not supported in this device.',
    ready: 'Ready to Scan',
    sucess: 'Success',
    timeout: 'NFC Session Timeout.',
};
