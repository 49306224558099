
class ServiceFactory {
    get clipboard() {
        if (!this.clipboardService) {
            throw new Error('No clipboardService has been set in ServiceFactory');
        }
        return this.clipboardService;
    }

    get crypto() {
        if (!this.cryptoService) {
            throw new Error('No cryptoService has been set in ServiceFactory');
        }
        return this.cryptoService;
    }

    get device() {
        if (!this.deviceService) {
            throw new Error('No deviceService has been set in ServiceFactory');
        }
        return this.deviceService;
    }

    get lang() {
        if (!this.langService) {
            throw new Error('No langService has been set in ServiceFactory');
        }
        return this.langService;
    }

    get linking() {
        if (!this.linkingService) {
            throw new Error('No linkingService has been set in ServiceFactory');
        }
        return this.linkingService;
    }

    get notify() {
        if (!this.notifyService) {
            throw new Error('No notifyService has been set in ServiceFactory');
        }
        return this.notifyService;
    }

    get oAuth() {
        if (!this.oAuthService) {
            throw new Error('No oAuthService has been set in ServiceFactory');
        }
        return this.oAuthService;
    }

    get xmlParser() {
        if (!this.xmlParserService) {
            throw new Error('No xmlParserService has been set in ServiceFactory');
        }
        return this.xmlParserService;
    }

    setClipboardService(clipboardService) {
        this.clipboardService = clipboardService;
    }

    setCryptoService(cryptoService) {
        this.cryptoService = cryptoService;
    }

    setDeviceService(deviceService) {
        this.deviceService = deviceService;
    }

    setLangService(langService) {
        this.langService = langService;
    }

    setLinkingService(linkingService) {
        this.linkingService = linkingService;
    }

    setNotifyService(notifyService) {
        this.notifyService = notifyService;
    }

    setOAuthService(oAuthService) {
        this.oAuthService = oAuthService;
    }

    setXmlParserService(xmlParserService) {
        this.xmlParserService = xmlParserService;
    }
}

const serviceFactory = new ServiceFactory();
export default serviceFactory;
