// Core component styles
const coreStyles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        flexShrink: 1,
        overflow: 'hidden',
    },

    row: {
        display: 'flex',
        flexDirection: 'row',
    },

    column: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
};

/**
 * Helper method to get styles for the ComboBox
 * @param {Object} contextStyles - Custom styles for the ComboBox component
 */
const getStyles = (contextStyles = {}, showVerticalScrollBar, showHorizontalScrollBar ) => {
    const overflowY = showVerticalScrollBar ? 'scroll' : 'unset';
    const overflowX = showHorizontalScrollBar ? 'scroll' : 'unset';
    const overflow = !showHorizontalScrollBar && !showVerticalScrollBar ? 'auto' : { overflowX, overflowY } ;
    return {
        container: {
            overflow,
            ...coreStyles.container,
            ...contextStyles.container,
        },

        scrollRow: {
            ...coreStyles.row,
            overflow: 'hidden',
            flexGrow: 1,
            ...contextStyles.row,
        },

        scrollColumn: {
            overflowY,
            overflowX,
            overflow,
            ...coreStyles.column,
            ...contextStyles.column,
        },

        activityRow: {
            ...coreStyles.row,
            flexShrink: 1,
            overflow: 'visible',
            ...contextStyles.row,
        },

        activityColumn: {
            ...coreStyles.column,
            ...contextStyles.column,
        },
    };
};

export default getStyles;
