// TODO: This needs to go away.
const FILTER_TYPES = {
    BOOLEAN: 'BOOLEAN',
    DATE: 'DATE',
    DECIMAL: 'number',
    INTEGER: 'number',
    STRING: 'text',
    DROPDOWN: 'dropdown',
};

const DOT_REPLACEMENT = '__$__';

const SORT_DIRECTIONS = {
    ASC: 'asc',
    DESC: 'desc',
    NONE: null,
};

export const searchSort = {
    FILTER_TYPES,
    SORT_DIRECTIONS,
    DOT_REPLACEMENT,
}