/* eslint-disable react/prefer-stateless-function */
import React, { Component, Fragment } from 'react';
import * as PropTypes from 'prop-types';


import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker as DatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import MUIButton from '@material-ui/core/IconButton';
import locale from 'date-fns/locale/en-US';

// Styles
import getStyles from './CalendarDatePicker.styles';

class CalendarDatePicker extends Component {
    static propTypes = {
        /** Default current date to show on render */
        defaultDate: PropTypes.oneOfType([
            PropTypes.instanceOf(Date),
            PropTypes.string,
        ]),

        /** Default view to show on render */
        defaultView: PropTypes.oneOf([
            'Day',
            'Week',
            'Month',
        ]),

        /** Event to handle current date change using date navigator */
        onDateChange: PropTypes.func,

        /** Handler method for date picker year/month change */
        onPickerChange: PropTypes.func,

        firstDayOfWeek: PropTypes.number,

        /** Date picked on the fixed date picker */
        pickedDate: PropTypes.oneOfType([
            PropTypes.instanceOf(Date),
            PropTypes.string,
        ]),

        /** Styles for this component */
        contextStyles: PropTypes.shape({
            /** Styles for the event container */
            container: PropTypes.object,
        }),
    };

    static defaultProps = {
        contextStyles: {},
    };

    render() {
        const {
            contextStyles,
            defaultDate,
            defaultView,
            onDateChange,
            onPickerChange,
            pickedDate,
            firstDayOfWeek,
        } = this.props;

        locale.options.weekStartsOn = firstDayOfWeek;

        // Generate component styles
        const {
            customDayContainerStyles,
            secondDatePickerCellStyles,
            selectedDayStyles,
        } = getStyles(contextStyles);

        const anchorDate = new Date(defaultDate);
        const nextMonth = new Date(anchorDate);
        nextMonth.setMonth(new Date(anchorDate).getMonth() + 1, 1);

        let firstDay; let
            lastDay;

        if (defaultView === 'Month') {
            // First and last days of the month
            firstDay = new Date(anchorDate.getFullYear(), anchorDate.getMonth(), 1);
            lastDay = new Date(anchorDate.getFullYear(), anchorDate.getMonth() + 1, 0);
        }
        else if (defaultView === 'Week') {
            const first = anchorDate.getDate() - anchorDate.getDay(); // First day is the day of the month - the day of the week
            firstDay = new Date(anchorDate.setDate(first));
            const last = firstDay.getDate() + 6; // last day is the first day + 6
            lastDay = new Date(anchorDate.setDate(last));
        }

        const renderCustomDay = (currentDate, selectedDate, dayInCurrentMonth) => {
            const otherDayStyles = {
                opacity: 0,
            };
            let customDayStyles = {
                ...secondDatePickerCellStyles,
            };
            let isHighlightedDay = false;
            if (new Date(selectedDate).getMonth() === new Date(pickedDate).getMonth() && currentDate - new Date(pickedDate).setHours(0, 0, 0, 0) === 0) {
                customDayStyles = {
                    ...secondDatePickerCellStyles,
                    ...selectedDayStyles,
                };
            }
            if (!dayInCurrentMonth) {
                customDayStyles = {
                    ...secondDatePickerCellStyles,
                    ...otherDayStyles,
                };
            }
            else if ((defaultView === 'Week' || defaultView === 'Month') && (currentDate >= firstDay && currentDate <= lastDay )) {
                isHighlightedDay = true;
            }
            return (
                <div style={ {
                    ...(isHighlightedDay ? customDayContainerStyles : {}),
                } }>
                    <MUIButton
                        style={ { ...customDayStyles } }>
                        <span>
                            { ' ' }
                            { currentDate.getDate() }
                            { ' ' }
                        </span>
                    </MUIButton>
                </div>
            );
        };

        return (
            <MuiPickersUtilsProvider
                utils={ DateFnsUtils }
                locale={ locale }>
                <DatePicker
                    margin="normal"
                    disableToolbar
                    variant="static"
                    value={ anchorDate }
                    onYearChange={ onPickerChange }
                    onMonthChange={ onPickerChange }
                    onChange={ onDateChange }
                    renderDay={ renderCustomDay } />
                <DatePicker
                    margin="normal"
                    disableToolbar
                    leftArrowIcon={ <Fragment /> }
                    rightArrowIcon={ <Fragment /> }
                    variant="static"
                    value={ nextMonth }
                    onYearChange={ onPickerChange }
                    onMonthChange={ onPickerChange }
                    onChange={ onDateChange }
                    renderDay={ renderCustomDay } />
            </MuiPickersUtilsProvider>
        );
    }
}

export default CalendarDatePicker;
