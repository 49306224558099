import React from 'react';
import * as PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { rootStore, workbenchController } from 'cv-react-core';
import IconButton from '../components/base/IconButton';
import Route from './Route';
import Navigator from './Navigator';

/**
 * This is a dummy route to handle the recieving notifications to the system for routing purposes.
 * Currently this focus is on handling notification links and later handle firebase notifications.
 */
@observer
class NotificationsRoute extends Route {
    static propTypes = {
        contextStyles: PropTypes.shape({
            button: PropTypes.object,
            icon: PropTypes.object,
        }),
        navigator: PropTypes.instanceOf(Navigator),
    };
    static defaultProps = {
        contextStyles: {},
    };
    render() {
        const { contextStyles } = this.props;
        const { uiStore } = rootStore;
        const shouldNavigateToNotification = uiStore.notificationReceived;
        if (shouldNavigateToNotification) {
            this.handleOnClick();
        }
        const iconProps = {
            contextStyles,
            onClick: this.handleOnClick.bind(this),
            svgPath: 'M16.5002 0.5V2.1275L1.2302 4.9025C0.880763 4.96639 0.565343 5.15223 0.340099 5.42692C0.114854 5.7016 -0.0055938 6.04732 0.000199665 6.4025V9.6575C-0.0055938 10.0127 0.114854 10.3584 0.340099 10.6331C0.565343 10.9078 0.880763 11.0936 1.2302 11.1575L3.0002 11.42V14C3.0002 14.3978 3.15824 14.7794 3.43954 15.0607C3.72084 15.342 4.10238 15.5 4.5002 15.5H10.5002C10.898 15.5 11.2796 15.342 11.5609 15.0607C11.8422 14.7794 12.0002 14.3978 12.0002 14V13.055L16.5002 13.8725V15.5H18.0002V0.5H16.5002ZM10.5002 14H4.5002V11.6975L10.5002 12.785V14ZM1.5002 9.6275V6.3725L16.5002 3.65V12.35L1.5002 9.6275Z',
            title: 'Notifications',
        };
        return React.createElement(IconButton, iconProps);
    }

    handleOnClick() {
        const {
            sessionStore,
            uiStore,
        } = rootStore;
        const { notificationsAction, selectedWorkbenchStore } = sessionStore;
        uiStore.setNotificationReceived(false);
        workbenchController.performWorkbenchAction(uiStore, selectedWorkbenchStore, this.handleTransition, this.handleError, notificationsAction);
    }
}

export default NotificationsRoute;