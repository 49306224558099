import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { XaltFieldActionButton } from 'xalt-react-atoms';
import RWFieldAction from './RWFieldAction';

/**
 * A platform component for building a client action
 */
class RWFieldActionFilePicker extends PureComponent {
    static propTypes = {
        style: PropTypes.object,
        action: PropTypes.object,
        onFieldAction: PropTypes.func,
        value: PropTypes.string,
        semanticType: PropTypes.string,
        type: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.handleOnActionClick = this.handleOnActionClick.bind(this);
        this.filePickerRef = React.createRef();
    }

    render() {
        const {
            value,
            action,
            ...restProps } = this.props;

        const standardActionPrps = {
            ...restProps,
            action,
            value,
            onFieldAction: this.handleOnActionClick, // override standard action click
        };

        return (
            <>
                <RWFieldAction { ...standardActionPrps } />
                <XaltFieldActionButton
                    inputRef={ this.filePickerRef }
                    type="file"
                    multiple={ action.multiSelectAvailable }
                    accept={ [ 'allFiles' ] }
                    style={ { visibility: 'hidden' } }
                    onChange={ this.handleOnChange } />
            </>
        );
    }

    handleOnChange = (fileObject) => {
        const { action } = this.props;
        const { clientFunction } = action;
        if (clientFunction) {
            clientFunction(fileObject);
        }
    };

    handleOnActionClick() {
        if (this.filePickerRef && this.filePickerRef.current) {
            this.filePickerRef.current.click();
        }
    }
}

export default RWFieldActionFilePicker;
