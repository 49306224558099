const container = {
    // As of now the size of component is not configurable. so used static value from figma, only configurable is BorderColor and BackgroundColor
    width: 60,
    height: 26,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#E0E0E0',
    borderRadius: 32,
    display: 'flex',
    alignItems: 'center',
};
const thumb = {
    backgroundColor: '#006E96',
    borderWidth: 0,
    outline: 0,
    boxShadow: 'none',
    borderRadius: 22,
    marginRight: 3,
    marginLeft: 3,
    // As of now the size of component is not configurable. so used static value from figma, only configurable is BorderColor and BackgroundColor
    width: 22,
    height: 22,
};
const thumbIcon = {
    color: '#fff',
    fontSize: '1.3rem',
};

const getStyles = ({ style = {}, thumbStyle = {}, hoverStyle = {}, focusStyle = {}, thumbIconStyle = {}, checked = false }) => {
    // Set thumb focus and hover backgroundColor. This is the only style we will support.
    const thumbFocus = focusStyle.backgroundColor ? { backgroundColor: focusStyle.backgroundColor } : {};
    const thumbHover = hoverStyle.backgroundColor ? { backgroundColor: hoverStyle.backgroundColor } : {};

    // Set container focus and hover backgroundColor. Need to pop off the backgroundColor
    // so it is not sent to the container background or it will hide the thumb.
    const containerFocus = focusStyle.borderColor ? { ...focusStyle } : {};
    const containerHover = hoverStyle.borderColor ? { ...hoverStyle } : {};
    if (containerFocus.backgroundColor) delete containerFocus.backgroundColor;
    if (containerHover.backgroundColor) delete containerHover.backgroundColor;

    const unCheckedStyle = checked ? {} : { backgroundColor: style.color };

    return {
        container: {
            ...container,
            ...style,
            ...containerHover,
            ...containerFocus,
        },
        thumb: {
            ...thumb,
            ...unCheckedStyle,
            ...thumbStyle,
            ...thumbHover,
            ...thumbFocus,
        },
        thumbIcon: {
            ...thumbIcon,
            ...thumbIconStyle,
        },
    };
};

export default getStyles;
