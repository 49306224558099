import React, { Component } from 'react';

// This function takes a component...
export function withAdditionalStyles(WrappedComponent) {
    // ...and returns another component...
    return class extends Component {
      constructor(props) {
        super(props);
        this.handleOnMouseOverChange = this.handleOnMouseOverChange.bind(this);
        this.handleOnMouseOutChange = this.handleOnMouseOutChange.bind(this);
        this.handleOnFocusChange = this.handleOnFocusChange.bind(this);
        this.handleOnBlurChange = this.handleOnBlurChange.bind(this);
        this.state = {
            focusStyle: {},
            hoverStyle: {},
        };
      }

      handleOnMouseOverChange() {
        const { hoverStyle } = this.props;
        this.setState({
            hoverStyle,
        });
      }

      handleOnMouseOutChange() {
        this.setState({
            hoverStyle: {},
        });
      }

      handleOnFocusChange() {
        const { focusStyle } = this.props;
        this.setState({
            focusStyle,
            hoverStyle: {},
        });
      }

      handleOnBlurChange() {
        this.setState({
            focusStyle: {},
        });
      }

      render() {
        return (
            <WrappedComponent
                { ...this.props }
                focusStyle={ this.state.focusStyle }
                hoverStyle={ this.state.hoverStyle }
                onMouseOver={ this.handleOnMouseOverChange }
                onMouseOut={ this.handleOnMouseOutChange }
                onFocus={ this.handleOnFocusChange }
                onBlur={ this.handleOnBlurChange } />
        );
      }
    };
  }