/*       */

import GmlUtil from '../../GmlUtil';
import { THIS } from '../../gmlConstants';


export default class AbstractLayoutFactory {
    aliasResolver = {};
    aliases = {};

    attributeFor = (json, key, context) => {
        const name = this.resolveName(context, key);
        const fn = this.factory[name];
        return fn ? fn(json, key, context) : null;
    }

    init = (attributesArray) => {
        this.initFactory();
        attributesArray.forEach((e1) => {
            // Each entry is in the form of name:alias1:alias2.  Add each of these to the index.
            const nameAndAliases = e1.split(':');
            const primaryName = nameAndAliases[0];
            nameAndAliases.forEach((e2, i) => {
                this.aliasResolver[e2] = primaryName;
                if (i > 0) this.aliases[e2] = e2;
            });
        });
    }

    initFactory() {
        this.factory = {};
    }

    getAsString = (json, key) => {
        return GmlUtil.getValueForAttribute(json, [ THIS, key ]);
    }

    resolveName = (context, key) => {
        const name = this.aliasResolver[key];
        if (this.aliases[key]) {
            const { version, warnings } = context;
            warnings.addV2AttributeWarning(version, key, name);
        }
        return name;
    }
}
