import React, { Fragment } from 'react';
import * as PropTypes from 'prop-types';
import MUIAppBar from '@material-ui/core/AppBar';
import MUIToolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';

import IconButton from '../IconButton/IconButton';
import TextLabel from '../TextLabel/TextLabel';
import Menu from '../Menu/Menu';
import MenuSeperator from '../MenuSeparator/MenuSeparator';
import MenuItem from '../MenuItem/MenuItem';
import Image from '../Image/Image';

import getStyles from './AppBar.styles';

// eslint-disable-next-line max-params
const getMenu = (menuItemContextStyles, menuButtonStyles, menuIconStyles, menuItems, onMenuItemClick, testID) => (
    <Menu
        button={
            <IconButton
                contextStyles={ {
                    button: menuButtonStyles,
                    icon: menuIconStyles,
                } }
                iconName="more_vert"
                iconSize={ 20 }
                onClick={ () => {} }
                testID={ `${testID}__menu-icon-button` } />
        }
        testID={ `${testID}__menu` }>
        { menuItems.map((menuItem) => {
            if (menuItem.id === null || menuItem.label === undefined) {
                const {
                    label,
                    ...restProps
                } = menuItem;
                return (
                    <MenuSeperator
                        label={ label }
                        { ...restProps } />
                );
            }
            return (
                <MenuItem
                    contextStyles={ menuItemContextStyles }
                    text={ menuItem.label }
                    onClick={ (nativeEvent) => onMenuItemClick(menuItem, nativeEvent) }
                    key={ menuItem.key || menuItem.label }
                    testID={ `${testID}__menu-item` } />
            );
        }) }
    </Menu>
);

const getLogo = (titleContainerStyles, imageStyles, imageLoadingIndicatorStyles, logoSrc, testID) => (
    <Image
        contextStyles={ {
            container: titleContainerStyles,
            image: imageStyles,
            loadingIndicator: imageLoadingIndicatorStyles,
        } }
        showLoadingIndicator={ false }
        imageSrc={ logoSrc }
        testID={ `${testID}__logo-image` } />
);

const getTitle = (titleContainerStyles, titleStyles, title, testID) => (
    <TextLabel
        contextStyles={ {
            container: titleContainerStyles,
            text: titleStyles,
        } }
        testID={ `${testID}__text-label` }>
        { title }
    </TextLabel>
);

/**
 * Description
 */
const AppBar = (props) => {
    const {
        alignMenu,
        contextStyles,
        menu,
        menuItems,
        onMenuItemClick,
        showMenu,
        testID,
        title,
        toolbar,
        logoSrc,
        closeIcon,
    } = props;

    // Override core styles with context styles, separating MUI styles
    const {
        container: containerStyles,
        menuButton: menuButtonStyles,
        menuIcon: menuIconStyles,
        menuItem: menuItemStyles,
        menuItemFocused: menuItemFocusedStyles,
        menuItemSelected: menuItemSelectedStyles,
        title: titleStyles,
        titleContainer: titleContainerStyles,
        image: imageStyles,
        imageLoadingIndicator: imageLoadingIndicatorStyles,
        ...muiStyles
    } = getStyles(contextStyles);

    // Create dynamic class names and injected DOM styles for MUI component
    // * makeStyles returns a function
    const styles = makeStyles(muiStyles)();

    const menuItemContextStyles = {
        container: menuItemStyles,
        focusContainer: menuItemFocusedStyles,
        selected: menuItemSelectedStyles,
    };

    // Render sub-components
    let titleComponent = title;
    if (!React.isValidElement(title)) {
        titleComponent = getTitle(titleContainerStyles, titleStyles, title, logoSrc, imageStyles, testID);
        if (logoSrc) {
            titleComponent = getLogo(titleContainerStyles, imageStyles, imageLoadingIndicatorStyles, logoSrc, testID);
        }
    }

    let menuComponent = null;
    if (showMenu && menuItems.length) {
        // .filter((item) => item.id !== null )
        menuComponent = getMenu(menuItemContextStyles, menuButtonStyles, menuIconStyles, menuItems, onMenuItemClick, testID);
    }

    return (
        <div
            className="c-app-bar__container"
            data-test-id={ `${testID}__app-bar__container` }
            style={ containerStyles }>
            <MUIAppBar
                classes={ {
                    root: styles.bar,
                } }
                data-test-id={ `${testID}__app-bar__bar` }
                position="static">
                <MUIToolbar
                    classes={ {
                        root: styles.toolbar,
                    } }
                    data-test-id={ `${testID}__app-bar__toolbar` }>
                    { (() => {
                        if (alignMenu === 'left') {
                            return (
                                <Fragment>
                                    { menu || menuComponent }
                                    { titleComponent }
                                    { toolbar }
                                    { closeIcon }
                                </Fragment>

                            );
                        }
                        return (
                            <Fragment>
                                { titleComponent }
                                { toolbar }
                                { menu || menuComponent }
                                { closeIcon }
                            </Fragment>
                        );
                    })() }
                </MUIToolbar>
            </MUIAppBar>
        </div>
    );
};

AppBar.propTypes = {
    /** Display position of the menu */
    alignMenu: PropTypes.oneOf([
        'right',
        'left',
    ]),

    /** Menu component which can be directly rendered without any processing */
    menu: PropTypes.node,

    /** Styles for this component */
    contextStyles: PropTypes.shape({
        /** Styling for the main container surrounding the bar */
        container: PropTypes.object,

        /** Styling for the app bar */
        bar: PropTypes.object,

        /** Styling for the app bar menu button */
        menuButton: PropTypes.object,

        /** Styling for the app bar menu button icon */
        menuIcon: PropTypes.object,

        /** Styling for the menu Item */
        menuItem: PropTypes.object,

        /** Styling for the menu Item focused */
        menuItemFocused: PropTypes.object,

        /** Styling for the menu Item selected */
        menuItemSelected: PropTypes.object,

        /** Styling for the title text */
        title: PropTypes.object,

        /** Styling for the title text container */
        titleContainer: PropTypes.object,

        /** Styling for the internal app bar controls container */
        toolbar: PropTypes.object,
    }),

    /** Array of items to render as menu options */
    menuItems: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
        key: PropTypes.string,
    })),

    /**
     * Called after a menu item is selected
     * @param {*} item - The selected item data
     * @returns {void}
     */
    onMenuItemClick: PropTypes.func,

    /** Enables display of menu */
    showMenu: PropTypes.bool,

    /** Id used for testing */
    testID: PropTypes.string,

    /** Title text */
    title: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
    ]),

    /** Additional components placed on the right side of the bar */
    toolbar: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element,
    ]),

    /** Close Component placed on the right end */
    closeIcon: PropTypes.element,

    /** logoSrc is an optional field that will place an image in the app bar */
    logoSrc: PropTypes.string,
};

AppBar.defaultProps = {
    alignMenu: 'left',
    menuItems: [],
    showMenu: true,
    testID: 'AppBar',
    title: '',
};

export default AppBar;
